import React, { useEffect, useState } from "react";
import { axios } from "../../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { useAtom } from "jotai";
import {
  PersonPunishTab_Data,
  PersonPunishTab_addData,
  PersonPunishTab_backupData,
  PersonPunishTab_createMode,
  PersonPunishTab_modifyData,
  PersonPunishTab_sawonId,
} from "../../../../../data/atom/personManage/personMasterManage/PersonPunishTab_atom";

export default function PersonPunishTab({ sawonId, userId }) {
  const defaultURL = "";
  const initialModifyData = {
    bigo: "",
    gubun: "",
    modDate: "",
    modId: "",
    rpDetail: "",
    sawonId: sawonId,
    seqNo: 0,
    teamGu: "",
    userId: userId,
    yearMnth: "",
  };

  const [createMode, setCreateMode] = useAtom(PersonPunishTab_createMode);
  const [data, setData] = useAtom(PersonPunishTab_Data);
  const [modifyData, setModifyData] = useAtom(PersonPunishTab_modifyData);
  const [addData, setAddData] = useAtom(PersonPunishTab_addData);
  const [backupData, setBackupData] = useAtom(PersonPunishTab_backupData);
  const [saveSawonId, setSaveSawonId] = useAtom(PersonPunishTab_sawonId);

  // API - 상벌사항 조회
  const { mutate: mutate_personPunish } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getPunish", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        setData(result);
        setBackupData(result);
        setModifyData([]);
        setCreateMode(false);
        setAddData(null);
      },
    }
  );

  // API - 상벌사항 생성/수정
  const { mutate: mutate_personPunishEdit, isLoading: getLoading } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/savePunish", data),
    {
      onSuccess: res => {
        mutate_personPunish(sawonId);
        setCreateMode(false);
        setAddData(null);
        setModifyData([]);
      },
    }
  );

  // API - 상벌사항 삭제
  const { mutate: mutate_personPunishDelet, isLoading: modifyLoading } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/deletePunish", data),
    {
      onSuccess: res => {
        mutate_personPunish(sawonId);
      },
    }
  );
  useEffect(() => {
    setSaveSawonId(sawonId);
    if (saveSawonId !== sawonId) {
      mutate_personPunish(sawonId);
    }
  }, []);

  const changeHandler = (e, targetData) => {
    const { name, value } = e.target;
    let dataValue = value;
    if (name.indexOf("Date") !== -1) {
      dataValue = new Date(value);
    } else if (name.indexOf("yearMnth") !== -1) {
      dataValue = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
    }
    const newDataList = data.map(item => {
      if (item.seqNo === targetData.seqNo) {
        return { ...item, [name]: dataValue };
      } else {
        return { ...item };
      }
    });
    setData(newDataList);

    // api 통신 데이터
    const isData = element => {
      return element.seqNo === targetData.seqNo;
    };

    const idx = modifyData?.findIndex(isData);
    if (idx === -1) {
      setModifyData([...modifyData, { ...targetData, [name]: dataValue }]);
    } else {
      const newModifyData = modifyData.map(item => {
        if (item.seqNo === targetData.seqNo) {
          return { ...item, [name]: dataValue };
        } else {
          return { ...item };
        }
      });
      setModifyData(newModifyData);
    }
  };
  return (
    <>
      <div className="px-20px py-15px">
        <div className={`table-container table-sticky h-183`}>
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>연번</th>
                <th style={{ width: "8%" }}>상벌구분</th>
                <th style={{ width: "8%" }}>상벌년월</th>
                <th style={{ width: "8%" }}>구분</th>
                <th style={{ width: "8%" }}>상벌내용</th>
                <th style={{ width: "8%" }}>비고</th>
                {decrypt("userInfo").userPermit === "99" && <th style={{ width: "3%" }}>삭제</th>}
              </tr>
            </thead>
            <tbody>
              {createMode && (
                <tr>
                  <td></td>
                  {/* 상벌 구분 */}
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, gubun: e.target.value })}
                      value={addData.gubun}
                    >
                      <option value="1">상</option>
                      <option value="2">벌</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({
                          ...addData,
                          yearMnth: e.target.value
                            .replace(/[^0-9]/g, "")
                            .replace(/(\..*)\./g, "$1"),
                        });
                      }}
                      value={addData.yearMnth.replace(/(.{4})/g, "$1-")}
                      maxLength={7}
                    />
                  </td>
                  {/* 팀구분 */}
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, teamGu: e.target.value })}
                      value={addData.teamGu}
                    >
                      <option value="P">개인</option>
                      <option value="T">단체</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, rpDetail: e.target.value });
                      }}
                      value={addData.rpDetail}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, bigo: e.target.value });
                      }}
                      value={addData.bigo}
                    />
                  </td>
                  {decrypt("userInfo").userPermit === "99" && <td></td>}
                </tr>
              )}
              {data.length > 0
                ? data.map((punishData, index) => (
                    <tr className="cursor-pointer" onClick={() => {}}>
                      <td>{index + 1}</td>
                      <td>
                        <select
                          className="form-select"
                          value={punishData.gubun}
                          name="gubun"
                          onChange={e => {
                            changeHandler(e, punishData);
                          }}
                        >
                          <option value="1">상</option>
                          <option value="2">벌</option>
                        </select>
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={punishData.yearMnth.replace(/(.{4})/g, "$1-")}
                          name="yearMnth"
                          maxLength={7}
                          onChange={e => {
                            changeHandler(e, punishData);
                          }}
                        />
                      </td>

                      {/* 팀인지 개인인지 => getPun */}
                      <td>
                        <select
                          className="form-select"
                          value={punishData.teamGu}
                          name="teamGu"
                          onChange={e => {
                            changeHandler(e, punishData);
                          }}
                        >
                          <option value="P">개인</option>
                          <option value="T">단체</option>
                        </select>
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={punishData.rpDetail}
                          name="rpDetail"
                          onChange={e => {
                            changeHandler(e, punishData);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={punishData.bigo}
                          name="bigo"
                          onChange={e => {
                            changeHandler(e, punishData);
                          }}
                        />
                      </td>
                      {decrypt("userInfo").userPermit === "99" && (
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            const result = window.confirm("해당 기록을 삭제하시겠습니까?");
                            if (result) {
                              mutate_personPunishDelet({
                                sawonId: sawonId,
                                seqNo: punishData.seqNo,
                              });
                            } else {
                            }
                          }}
                        >
                          {/* 인사발령정보 삭제 */}
                          <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                        </td>
                      )}
                    </tr>
                  ))
                : !createMode && (
                    <tr className="cursor-pointer" onClick={() => {}}>
                      <td colSpan={decrypt("userInfo").userPermit === "99" ? 8 : 7}>
                        조회된데이터가 없습니다.
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>

        <div className="d-flex align-items-center justify-content-end mt-10px">
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              if (!createMode) {
                setCreateMode(true);
                setAddData(initialModifyData);
              }
            }}
          >
            추가
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              if (addData) {
                mutate_personPunishEdit([...modifyData, addData]);
              } else {
                if (modifyData.length > 0) {
                  mutate_personPunishEdit(modifyData);
                }
              }
            }}
          >
            저장
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              setCreateMode(false);
              setModifyData([]);
              setData(backupData);
              setAddData(null);
              alert("변경 사항이 취소되었습니다.");
            }}
          >
            취소
          </button>
        </div>
      </div>
    </>
  );
}
