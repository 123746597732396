import moment from "moment";
import { atom } from "jotai/index";

export const searchParamInit = {
  accountSelect: "00",
  cardNo: "A",
  frontDate: moment("2023-02-01").format("YYYY-MM-DD"),
  backDate: moment("2023-12-31").format("YYYY-MM-DD"),
};
// export const searchParamInit = {
//   cardNo: "",
//   currDate: moment().format("YYYY-MM-DD"),
//   currDateBack: moment().format("YYYY-MM-DD"),
//   page: 1,
// };

export const searchParam_atom = atom({ ...searchParamInit });

export const searchedParam_atom = atom({});

export const cardList_atom = atom([]);

export const editedRowList_atom = atom([]);

export const newData_atom = atom({});
