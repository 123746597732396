import React, { useEffect, useState } from "react";
import { useAtom } from "jotai/index";
import {
  clickedRow_atom,
  transactionMain_atom,
} from "../../../data/atom/dataManage/transactionProgress/transactionProgress_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment/moment";
import { useGetBank } from "../../../api/code/useGetBank";
import { blobToString } from "../../../util/blobToString";
import usePrintTab from "../../../components/modal/usePrintTab";
import Select from "react-select-virtualized";

const TransactionProgressMain = props => {
  const {
    getPartner,
    searchParam,
    setSearchParam,
    searchTransactionList,
    searchTransactionMain,
    searchTransactionMainPartnerChange,
    isNewRegister,
    setIsNewRegister,
  } = props;
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [transactionMain, setTransactionMain] = useAtom(transactionMain_atom);

  const [seqnoSelectOptions, setSeqnoSelectOptions] = useState([]);
  const [partnerSelectOptions, setPartnerSelectOptions] = useState([]);

  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  const openPdf = usePrintTab();
  const getBankGubnList = useGetBank();

  const saveTransactionMain = useMutation(
    data => axios.post("/api/data/transactionManage/save", data),
    {
      onSuccess: (res, variables) => {
        if (isNewRegister) {
          setIsNewRegister(false);
          setSearchParam({ ...searchParam, keyword: res.data.result.kname });
        }

        setClickedRow({ ...res.data.result });

        searchTransactionList.mutate({ ...searchParam, keyword: res.data.result.kname });
        searchTransactionMain.mutate({
          seqnNumb: res.data.result.seqnNumb,
          tradDate: res.data.result.tradDate,
          tradSeqn: res.data.result.tradSeqn,
        });

        // setIsNewRegister(false);
      },
    }
  );
  const printTransactionMain = useMutation(
    data => axios.get("/api/data/transactionManage/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `거래진행_내역_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
      onError: e => {
        blobToString(e.response.data).then(data => {
          let err = JSON.parse(data);
          if (err.code === "-1") alert(err.message);
        });
      },
    }
  );

  // 관리번호 select 검색
  const estimateSearchCustId = useMutation(
    data => axios.get("/api/data/estimate/searchCustId", { params: { keyword: data } }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;
        setSeqnoSelectOptions(
          data.map(el => ({ ...el, value: el.custId, label: `${el.company}(${el.kname})` }))
        );
      },
    }
  );

  useEffect(() => {
    if (clickedRow.seqnNumb !== undefined && clickedRow.seqnNumb !== -1) {
      searchTransactionMain.mutate({ ...clickedRow, remark: "", submitDoc: "" });
    }
  }, [clickedRow]);

  const onChangeMainEventHandler = (key, value, option) => {
    let tmp = { ...transactionMain, [key]: value };

    if (key === "partner") {
      searchTransactionMainPartnerChange.mutate({
        jisa: getPartner.data?.find(el => el.key === value)?.jisa,
        userId: value,
      });
    } else if (key === "tradDate") {
      if (value === "" || value === "Invalid date") {
        return;
      }
    } else if (key === "cancelFlag") {
      tmp.cancelDate = value === "Y" ? moment().format("YYYY-MM-DD") : "";
    }

    if (key === "seqNo") {
      if (option) {
        tmp.company = option.company;
        tmp.kname = option.kname;
        tmp.position = option.position;
        tmp.tradSeqn = option.tradSeqn;
        tmp.tradDate = option.tradDate;
        tmp.cellular = option.cellular;
        tmp.cfax = option.cfax;
        tmp.payBank = option.payBank;
        tmp.payAccount = option.payAccount;
        tmp.payName = option.payName;
        tmp.ctel = option.ctel;
        tmp.email = option.email;
        tmp.custId = option.custId;
        tmp.seqNo = option.seqNo;
      }
    }

    setTransactionMain(tmp);
  };

  // const btnDelete = () => {};
  const btnPrint = () => {
    printTransactionMain.mutate({
      seqnNumb: transactionMain.seqnNumb,
      tradSeqn: transactionMain.tradSeqn,
      tradDate: transactionMain.tradDate,
    });
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (transactionMain.tradDate === undefined) {
        alert("거래일자를 입력해주세요.");
        return;
      }

      saveTransactionMain.mutate({
        ...transactionMain,
        tradDate: transactionMain.tradDate.replace(/-/g, ""),
      });
    }
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="card card-main">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="tab1-row1-count w-50">
              관리번호 :{" "}
              {transactionMain.custId
                ? `${transactionMain.custId.substring(0, 4)}-${transactionMain.custId.substring(4)}`
                : ""}
            </div>
            <div className="tab1-row1-field1 w-50 justify-content-end">
              <button className="btn btn-outline-primary" onClick={btnPrint}>
                거래진행 내역 출력
              </button>
              {/*<button className="btn btn-outline-primary ms-8px" onClick={btnDelete}>*/}
              {/*  삭제*/}
              {/*</button>*/}
              <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
                저장
              </button>
              {isNewRegister && (
                <button
                  className="btn btn-outline-primary ms-8px"
                  onClick={() => {
                    if (window.confirm("신규등록을 취소하시겠습니까?")) {
                      setIsNewRegister(false);
                      setTransactionMain({});
                      setClickedRow({});
                    }
                  }}
                >
                  취소
                </button>
              )}
            </div>
          </div>

          <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>관리번호</th>
                <td>
                  <Select
                    className="select-virtual text-left"
                    menuPlacement={"auto"}
                    menuPosition={"fixed"}
                    options={seqnoSelectOptions}
                    value={
                      transactionMain.seqNo
                        ? {
                            value: transactionMain.seqNo,
                            label: `${transactionMain.seqNo.substring(
                              0,
                              4
                            )}-${transactionMain.seqNo.substring(4)}`,
                          }
                        : ""
                    }
                    onChange={e => {
                      if (e) onChangeMainEventHandler("seqNo", e.value, e);
                    }}
                    onKeyDown={e => {
                      if (e.code === "Enter" && window.selectOnKeyDwon) {
                        delete window.selectOnKeyDwon;
                        estimateSearchCustId.mutate(e.target.value);
                      } else window.selectOnKeyDwon = true;
                    }}
                  />

                  {/*<input*/}
                  {/*  type="text"*/}
                  {/*  className="form-control table-input"*/}
                  {/*  value={*/}
                  {/*    transactionMain.custId*/}
                  {/*      ? `${transactionMain.custId.substring(*/}
                  {/*          0,*/}
                  {/*          4*/}
                  {/*        )}-${transactionMain.custId.substring(4)}`*/}
                  {/*      : ""*/}
                  {/*  }*/}
                  {/*  readOnly={true}*/}
                  {/*/>*/}
                </td>
                <th style={{ width: "10%" }}>업체명</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.company}
                    onChange={e => onChangeMainEventHandler("company", e.target.value)}
                  />
                </td>
                <th style={{ width: "10%" }}>성 &nbsp;명</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.kname}
                    onChange={e => onChangeMainEventHandler("kname", e.target.value)}
                  />
                </td>
                <th style={{ width: "10%" }}>직위</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.position}
                    onChange={e => onChangeMainEventHandler("position", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>내역서번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={
                      transactionMain.tradSeqn
                        ? `${transactionMain.tradSeqn.substring(
                            0,
                            2
                          )}-${transactionMain.tradSeqn.substring(
                            2,
                            6
                          )}-${transactionMain.tradSeqn.substring(6)}`
                        : ""
                    }
                    onChange={e => {
                      onChangeMainEventHandler("tradSeqn", e.target.value);
                    }}
                    readOnly={true}
                  />
                </td>
                <th style={{ width: "10%" }}>거래일자</th>
                <td>
                  <input
                    type="date"
                    className="form-control filter-input"
                    value={transactionMain.tradDate}
                    onChange={e => {
                      onChangeMainEventHandler(
                        "tradDate",
                        moment(e.target.value).format("YYYY-MM-DD")
                      );
                    }}
                    min={"1900-01-01"}
                    max={"2099-12-31"}
                  />
                </td>
                <th>휴대폰</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.cellular}
                    onChange={e => onChangeMainEventHandler("cellular", e.target.value)}
                  />
                </td>
                <th>팩스번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.cfax}
                    onChange={e => onChangeMainEventHandler("cfax", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>계좌번호</th>
                <td colSpan={3}>
                  <div className="d-flex">
                    <select
                      className="form-select table-select"
                      value={transactionMain.payBank}
                      onChange={e => onChangeMainEventHandler("payBank", e.target.value)}
                    >
                      {getBankGubnList.data?.map(el => (
                        <option value={el.key}>{el.value}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      className="form-control table-input ms-3px"
                      value={transactionMain.payAccount}
                      onChange={e => onChangeMainEventHandler("payAccount", e.target.value)}
                    />
                  </div>
                </td>
                <th style={{ width: "11.9%" }}>예금주</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.payName}
                    onChange={e => onChangeMainEventHandler("payName", e.target.value)}
                  />
                </td>
                <th>전화번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.ctel}
                    onChange={e => onChangeMainEventHandler("ctel", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.email}
                    onChange={e => onChangeMainEventHandler("email", e.target.value)}
                  />
                </td>
                <th>거래 취소유무</th>
                <td>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={transactionMain.cancelFlag === "Y"}
                    onChange={e =>
                      onChangeMainEventHandler("cancelFlag", e.target.checked ? "Y" : "N")
                    }
                  />
                </td>
                <th>취소일자</th>
                <td>
                  <input
                    type="date"
                    className="form-control filter-input"
                    max={"9999-12-31"}
                    value={transactionMain.cancelDate}
                    onChange={e => onChangeMainEventHandler("cancelDate", e.target.value)}
                    disabled={transactionMain.cancelFlag !== "Y"}
                  />
                </td>
              </tr>
              <tr>
                <th>담당자</th>
                <td>
                  <select
                    className="form-select"
                    value={transactionMain.partner}
                    onChange={e => onChangeMainEventHandler("partner", e.target.value)}
                  >
                    {getPartner.data?.map(el => {
                      return <option value={el.key}>{el.value}</option>;
                    })}
                  </select>
                </td>
                <th style={{ width: "11.9%" }}>작성일시</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.inputDate}
                    readOnly={true}
                    style={{ border: "0" }}
                  />
                </td>
                <th>휴대폰</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.mobile}
                    onChange={e => onChangeMainEventHandler("mobile", e.target.value)}
                  />
                </td>
                <th>직급</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.authority}
                    onChange={e => onChangeMainEventHandler("authority", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>본사/지사</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.jisaName}
                    readOnly={true}
                    style={{ border: "0" }}
                  />
                </td>
                <th>계좌명</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.bankName}
                    readOnly={true}
                    style={{ border: "0" }}
                  />
                </td>
                <th>전화번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.jisaCTel}
                    readOnly={true}
                    style={{ border: "0" }}
                  />
                </td>
                <th>팩스번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.jisaCFax}
                    readOnly={true}
                    style={{ border: "0" }}
                  />
                </td>
              </tr>
              <tr>
                <th>계좌번호</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input float-start"
                    value={`${transactionMain.payNameC ? transactionMain.payNameC : ""}${
                      transactionMain.payNameC && transactionMain.payAccountC ? " | " : ""
                    }${transactionMain.payAccountC ? transactionMain.payAccountC : ""}`}
                    readOnly={true}
                    style={{ border: "0", minWidth: "auto" }}
                  ></input>
                </td>
                <th>주소</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.jusoAddres}
                    readOnly={true}
                    style={{ border: "0" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(TransactionProgressMain);
