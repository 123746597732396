import { atom } from "jotai";
import moment from "moment";

export const golfPrice_searchDataInit_atom = {
  searchType: "AA",
  priceSelect: "",
  item: "117종목",
};

export const golfPrice_mainData_atom = atom(golfPrice_searchDataInit_atom);

export const golfPrice_mainSearchedTableList_atom = atom([]);

export const golfPrice_subDataInit_atom = {
  frontDate: moment().subtract(20, "days").format("YYYY-MM-DD"),
  // frontDate: "2023-01-01",
  backDate: moment().format("YYYY-MM-DD"),
  // backDate: "2023-01-30",
  averageSelect: "1",
  averageSelectTemp: "1",
  dateType: "M",
};

export const golfPrice_subData_atom = atom(golfPrice_subDataInit_atom);

export const golfPrice_subSearchedTableList_atom = atom([]);
