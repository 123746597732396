import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import { decrypt } from "../../../../util/decrypt/decrypt";

export const CallHistory = () => {
  const subDataLength = useRef(0);

  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  const [modifyList, setModifyList] = useState([]);

  // 통화내역 리스트 조회
  const customerGetCallHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getCallHistory", {
        params: {
          custId: data,
        },
      }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
        setModifyList(Array.from({ length: res.data.result.length }, () => false));
        subDataLength.current = res.data.result.length;
      },
    }
  );

  // 통화내역 저장/수정
  const customerSaveCallHistory = useMutation(
    data => axios.post("/api/work/customer/bottom/saveCallHistory", data),
    {
      onSuccess: () => {
        customerGetCallHistory.mutate(mainData.seqNo);
      },
    }
  );

  // 통화내역 삭제
  const customerDeleteCallHistory = useMutation(
    data => axios.post("/api/work/customer/bottom/deleteCallHistory", data),
    {
      onSuccess: () => {
        customerGetCallHistory.mutate(mainData.seqNo);
      },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetCallHistory.mutate(mainData.seqNo);
  }, [mainData.seqNo]);

  useEffect(() => {
    handleResizeHeight();
  }, [subDataLength.current]);

  const btn_new_register = () => {
    if (modifyList.some(el => el === true)) {
      alert("등록 또는 수정중인 데이터가 있습니다.");
      return;
    }

    setSubData(prev => [
      {
        custId: mainData.seqNo,
        ilNo: "",
        remark: "",
        talkDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        talkName: decrypt("userInfo").userName,
        viewChk: "1",
      },
      ...prev,
    ]);
    setModifyList(prev => [true, ...prev]);
  };

  const btn_register = () => {
    if (window.confirm("저장하시겠습니까?")) {
      if (modifyList.every(el => el === false)) {
        alert("변경사항이 없습니다.");
        return;
      }

      if (subData[0].ilNo === "0" || subData[0].viewChk === "") {
        alert("VIEW를 선택해주세요.");
        return;
      }

      if (subData[0].ilNo === "0" || subData[0].remark === "") {
        alert("통화내역을 입력해주세요.");
        return;
      }

      customerSaveCallHistory.mutate(
        subData
          .filter((el, index) => modifyList[index])
          .map(el2 => {
            return {
              ...el2,
              talkDate: moment(el2.talkDate).format("YYYY-MM-DD HH:mm:ss"),
            };
          })
      );
    }
  };

  const btn_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      customerGetCallHistory.mutate(mainData.seqNo);
    }
  };

  const btn_delete = data => {
    if (window.confirm("삭제하시겠습니까?")) {
      if (data.ilNo === "") {
        alert("신규등록 데이터는 삭제할 수 없습니다.");
        return;
      }

      customerDeleteCallHistory.mutate({
        ...data,
        talkDate: moment(data.talkDate).format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  };

  const handleResizeHeight = () => {
    Array.from({ length: subDataLength.current }, (el, idx) => {
      let textarea = document.querySelector(`.textareaHeight${idx}`);
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      }
    });
  };

  const mostRecentDate = subData.reduce((acc, cur) => {
    const currentMoment = moment(cur.talkDate);
    return currentMoment.isAfter(moment(acc)) ? cur.talkDate : acc;
  }, subData[0]?.talkDate); // 배열의 첫 번째 요소를 초기값으로 설정

  return (
    <>
      <div className="tab2-content-container">
        <div className="d-flex align-items-center justify-content-between">
          <div className="table-tabs-sub m-0">
            통화기록 (<span>{subData.length ?? "0"}</span>건)
          </div>

          <div className="d-flex">
            <div>최종 통화 {moment().diff(mostRecentDate, "months")}개월 전</div>
            <div className="d-flex">
              <button className="btn btn-primary btn-table-filter" onClick={btn_new_register}>
                등록
              </button>
              <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_register}>
                저장
              </button>
              <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_cancel}>
                취소
              </button>
            </div>
          </div>
        </div>
        <div className="table-container table-sticky h-528 mt-15px">
          <table className="table table-bordered table-responsive my-table mt-0">
            <colgroup>
              <col width="4%" />
              <col width="11%" />
              <col width="6%" />
              <col width="6%" />
              <col width="50%" />
              {/*<col width="4%" />*/}
            </colgroup>
            <thead>
              <tr>
                <th>연번</th>
                <th>통화일자</th>
                <th>통화자</th>
                <th>VIEW</th>
                <th>통화내용</th>
                {/*<th>삭제</th>*/}
              </tr>
            </thead>
            <tbody>
              {subData.length !== 0 ? (
                subData?.map((el, index) => {
                  return (
                    <tr
                      className={`${el.viewChk === "2" ? "bg-lightgreen" : ""} ${
                        el.viewChk === "N" ? "bg-lightyellow" : ""
                      }`}
                    >
                      <td>{subData.length - index}</td>
                      <td>{moment(el.talkDate).format("YYYY-MM-DD a hh:mm")}</td>
                      <td>{el?.talkName ?? ""}</td>
                      <td>
                        <div className="d-flex">
                          <select
                            className="form-select table-select"
                            value={el?.viewChk ?? ""}
                            onChange={e => {
                              const temp = [...subData];
                              temp[index].viewChk = e.target.value;
                              setSubData(temp);

                              const temp2 = [...modifyList];
                              temp2[index] = true;
                              setModifyList(temp2);
                            }}
                          >
                            <option value="" disabled={true}>
                              VIEW 선택
                            </option>
                            <option value="1">일반</option>
                            <option value="2">상위</option>
                            <option value="N">부재</option>
                            {/*<option value="R">녹취</option>*/}
                          </select>
                        </div>
                      </td>
                      <td>
                        <textarea
                          className={`textareaHeight${index} form-control`}
                          rows={1}
                          value={el?.remark}
                          onChange={e => {
                            const temp = [...subData];
                            temp[index].remark = e.target.value;
                            setSubData(temp);

                            const temp2 = [...modifyList];
                            temp2[index] = true;
                            setModifyList(temp2);

                            handleResizeHeight();
                          }}
                          style={{
                            maxHeight: "200px",
                            overflow: "auto",
                            minHeight: "unset",
                          }}
                          readOnly={
                            moment().format("YYYY-MM-DD") !==
                            moment(el.talkDate).format("YYYY-MM-DD")
                          }
                        />
                      </td>
                      {/*<td className="cursor-pointer" onClick={() => btn_delete(el)}>
                        <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                      </td>*/}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    검색된 통화내역 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
