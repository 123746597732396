import React from "react";
import { useAtomValue } from "jotai";
import { defaultCondition_atom } from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import moment from "moment/moment";

export default function InputYdInput(props) {
  const { keyName, data, setData, type, children } = props;
  const defaultCondition = useAtomValue(defaultCondition_atom);

  const onChangeEventHandler = (value, idx) => {
    if (keyName === "transferAmnt" || keyName === "expenseAmnt" || keyName === "acquireAmnt") {
      value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));
    } else if (keyName === "acquireDate" || keyName === "transferDate") {
      if (value === "") {
        value = "";
      } else {
        value = moment(value).format("YYYY-MM-DD");
      }
    }
    setData(prev => {
      prev = prev.map((el, idx2) => {
        if (idx === idx2) {
          return { ...el, [keyName]: value };
        } else return el;
      });

      return prev;
    });
  };

  return (
    <>
      {type === "select" &&
        keyName === "tradeGubun" &&
        data?.map((el, idx) => {
          return (
            <td>
              <select
                className="form-select"
                value={data[idx]?.tradeGubun}
                onChange={e => onChangeEventHandler(e.target.value, idx)}
                disabled={defaultCondition.yangdo <= idx}
              >
                {children}
              </select>
            </td>
          );
        })}
      {type === "select" &&
        keyName === "objectName" &&
        data?.map((el, idx) => {
          return (
            <td>
              <select
                className="form-select"
                value={data[idx]?.objectName}
                onChange={e => onChangeEventHandler(e.target.value, idx)}
                disabled={defaultCondition.yangdo <= idx}
              >
                {children}
              </select>
            </td>
          );
        })}
      {type === "text" &&
        data?.map((el, idx) => {
          return (
            <td>
              <input
                className={`form-control ${
                  keyName === "transferAmnt" ||
                  keyName === "acquireAmnt" ||
                  keyName === "expenseAmnt"
                    ? "text-end"
                    : ""
                }`}
                type="text"
                value={
                  keyName === "transferAmnt" ||
                  keyName === "acquireAmnt" ||
                  keyName === "expenseAmnt"
                    ? Number(data[idx]?.[keyName]).toLocaleString()
                    : data[idx]?.[keyName]
                }
                onChange={e => onChangeEventHandler(e.target.value, idx)}
                disabled={defaultCondition.yangdo <= idx}
              />
            </td>
          );
        })}
      {type === "date" &&
        data?.map((el, idx) => {
          return (
            <td>
              <input
                className="form-control"
                type="date"
                value={data[idx]?.[keyName]}
                onChange={e => onChangeEventHandler(e.target.value, idx)}
                disabled={defaultCondition.yangdo <= idx}
                max="9999-12-31"
              />
            </td>
          );
        })}
    </>
  );
}
