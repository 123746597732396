import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Tab5 = ({ custId }) => {
  const [pageData, setPageData] = useState(null);
  const [activeKey, setActiveKey] = useState("매도(개인)");

  const bottomGetDoc = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getDoc", {
        params: { custId: data.custId },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setPageData({ ...result, custId: custId });

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );

  const bottomSaveDoc = useMutation(data =>
    axios.post("/api/work/manageGolf/bottom/saveDoc", data)
  );

  useEffect(() => {
    if (custId) bottomGetDoc.mutate({ custId: custId });
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetDoc.mutate({ custId: custId });
  }, []);

  const onChangeTextArea = (key, value) => {
    setPageData({ ...pageData, [key]: value });
  };

  const btn_cancel = () => {
    if (confirm("취소하시겠습니까")) bottomGetDoc.mutate({ custId: custId, type: "cancel" });
  };

  const btn_save = () => {
    bottomSaveDoc.mutate(pageData);
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example2"
        activeKey={activeKey}
        onSelect={k => setActiveKey(k)}
        className="tab-blocks"
      >
        <Tab eventKey="매도(개인)" title="매도(개인)">
          <textarea
            rows="10"
            className="form-control text-start mt-2"
            value={pageData?.yangdoPersonal ? pageData?.yangdoPersonal : ""}
            onChange={e => onChangeTextArea("yangdoPersonal", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="매도(법인)" title="매도(법인)">
          <textarea
            rows="10"
            className="form-control text-start mt-2"
            value={pageData?.yangdoCorp ? pageData?.yangdoCorp : ""}
            onChange={e => onChangeTextArea("yangdoCorp", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="매수(개인)" title="매수(개인)">
          <textarea
            rows="10"
            className="form-control text-start mt-2"
            value={pageData?.yangsuPersonal ? pageData?.yangsuPersonal : ""}
            onChange={e => onChangeTextArea("yangsuPersonal", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="매수(법인)" title="매수(법인)">
          <textarea
            rows="10"
            className="form-control text-start mt-2"
            value={pageData?.yangsuCorp ? pageData?.yangsuCorp : ""}
            onChange={e => onChangeTextArea("yangsuCorp", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="기타사항" title="기타사항">
          <textarea
            rows="10"
            className="form-control text-start mt-2"
            value={pageData?.remark ? pageData?.remark : ""}
            onChange={e => onChangeTextArea("remark", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="모두보기" title="모두보기">
          <div className="table-container mb-3 position-relative">
            <table className="table table-bordered table-responsive my-table">
              <thead>
                <tr>
                  <th style={{ width: "16.66%" }}>매도(개인)</th>
                  <th>매도(법인)</th>
                  <th>매수(개인)</th>
                  <th>매수(법인)</th>
                  <th>기타사항</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "200px" }}>
                  <td>
                    <textarea
                      className="form-control"
                      rows={10}
                      value={pageData?.yangdoPersonal ? pageData?.yangdoPersonal : ""}
                      onChange={e => onChangeTextArea("yangdoPersonal", e.target.value)}
                    ></textarea>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      rows={10}
                      value={pageData?.yangdoCorp ? pageData?.yangdoCorp : ""}
                      onChange={e => onChangeTextArea("yangdoCorp", e.target.value)}
                    ></textarea>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      rows={10}
                      value={pageData?.yangsuPersonal ? pageData?.yangsuPersonal : ""}
                      onChange={e => onChangeTextArea("yangsuPersonal", e.target.value)}
                    ></textarea>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      rows={10}
                      value={pageData?.yangsuCorp ? pageData?.yangsuCorp : ""}
                      onChange={e => onChangeTextArea("yangsuCorp", e.target.value)}
                    ></textarea>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      rows={10}
                      value={pageData?.remark ? pageData?.remark : ""}
                      onChange={e => onChangeTextArea("remark", e.target.value)}
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Tab>
      </Tabs>
      <div className="d-flex align-items-center justify-content-end my-2">
        <button className="btn btn-outline-primary" onClick={btn_save}>
          저장
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btn_cancel}>
          취소
        </button>
      </div>
    </>
  );
};

export default React.memo(Tab5);
