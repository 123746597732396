import { atom } from "jotai";
import moment from "moment";

export const dailyCall_searchData_atom = atom({
  keyword: moment().format("YYYY-MM-DD"),
  // keyword: "2023-05-01",
  type: "N",
});

export const dailyCall_mainData_atom = atom([]);
export const dailyCall_subData1_atom = atom([]);

export const dailyCall_subData2_atom = atom([]);
