import EmpModal from "../../../../../components/modal/Modal";
import { useState } from "react";
import { decrypt } from "../../../../../util/decrypt/decrypt";

export const DuplicateCheckModal = ({
  isDuplicateCheckModalOpen,
  setIsDuplicateCheckModalOpen,
}) => {
  const [searchedTableList, setSearchedTableList] = useState(isDuplicateCheckModalOpen.data);

  const btn_only_my = () => {
    const tempList = [...isDuplicateCheckModalOpen.data];

    setSearchedTableList(tempList.filter(el => el.partner === decrypt("userInfo").loginId));
  };

  const btn_only_manage = () => {
    const tempList = [...isDuplicateCheckModalOpen.data];

    setSearchedTableList(tempList.filter(el => el.jisa === decrypt("userInfo").jisa));
  };

  const btn_total = () => {
    setSearchedTableList(isDuplicateCheckModalOpen.data);
  };

  return (
    <EmpModal
      show={isDuplicateCheckModalOpen.isOpen}
      width="100%"
      minWidth="475px"
      title="중복 검색결과"
      onHide={() => setIsDuplicateCheckModalOpen({ data: null, isOpen: false })}
      strClass="modal-scroll"
      size="md"
    >
      <div className="d-flex align-items-center">
        <button className="btn btn-outline-primary me-2" onClick={btn_only_my}>
          본인꺼만
        </button>
        <button className="btn btn-outline-primary me-2" onClick={btn_only_manage}>
          관리지사 內
        </button>
        <button className="btn btn-outline-primary" onClick={btn_total}>
          전체
        </button>
      </div>
      <div>
        <table className="table table-bordered table-responsive my-table mt-2">
          <thead>
            <tr>
              <th style={{ width: "25%" }}>관리번호</th>
              <th style={{ width: "25%" }}>성명</th>
              <th style={{ width: "25%" }}>직위</th>
              <th style={{ width: "25%" }}>회사명</th>
            </tr>
          </thead>
          <tbody>
            {searchedTableList.length !== 0 ? (
              searchedTableList.map(el => (
                <tr>
                  <td>{el.custId}</td>
                  <td>{el.kname}</td>
                  <td>{el.position}</td>
                  <td>{el.company}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>검색결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </EmpModal>
  );
};
