import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment";
import Modal from "../../../../../components/modal/Modal";
import { Chart } from "react-google-charts";

const options = {
  curveType: "none",
  series: [{ color: "#0000ff" }, { color: "#ff0000" }],
  intervals: { style: "points", pointSize: 6 },
  legend: "none",
};

const Tab3 = ({ custId }) => {
  const [tableData, setTableData] = useState([]);
  const [originGraphData, setOriginGraphData] = useState([]);
  const [clickedWeekIdx, setClickedWeekIdx] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalClickedData, setModalClickedData] = useState(null);
  const bottomGetSiseList = useMutation(
    data => axios.get("/api/work/manageGolf/bottom/getSiseList"),
    {
      onSuccess: res => {
        const { result } = res.data;
        setTableData(result.map(i => i));
      },
    }
  );

  const bottomGetSiseDetail = useMutation(
    data => axios.get("/api/work/manageGolf/bottom/getSiseDetail", { params: { code: data } }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setOriginGraphData({ ...result });
        let cnt = 0;
        let avge = 0;
        for (; cnt < result.length; cnt++) avge += result[cnt].siseAvge;
        avge = avge / cnt;
        let data = [
          [
            { type: "string", label: "x" },
            { type: "number", label: "values" },
            { id: "i0", type: "number", role: "interval" },
            { type: "number", label: "values" },
            { id: "i1", type: "number", role: "interval" },
          ],
        ];
        result.map(i =>
          data.push([
            i.yearMnth.substring(2, 4) + "/" + i.yearMnth.substring(4, 6),
            i.siseAvge,
            i.siseAvge,
            avge,
            avge,
          ])
        );
        setGraphData(data);
      },
    }
  );

  useEffect(() => {
    bottomGetSiseList.mutate();
  }, []);
  useEffect(() => {
    bottomGetSiseList.mutate();
  }, [custId]);

  const handleChartClick = event => {
    if (event.targetID.startsWith("hAxis#0#label")) {
      let splited = event.targetID.split("#");
      let idx = splited[splited.length - 1];
      setClickedWeekIdx(idx);
    }
  };
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        title={`시세정보 - ${modalClickedData?.codename}`}
      >
        <p>* 최근 1년간의 시세변동을 나타냅니다.</p>
        {modalShow && (
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={graphData}
            options={options}
            chartEvents={[
              {
                eventName: "ready",
                callback: ({ chartWrapper, google }) => {
                  const chart = chartWrapper.getChart();
                  google.visualization.events.addListener(chart, "click", handleChartClick);
                },
              },
            ]}
          />
        )}
        {/*<img
          className="d-block m-auto"
          src={process.env.PUBLIC_URL + "/images/chart1.png"}
          alt=""
        />*/}
        <p className="text-center">
          - 연월 부분을 클릭하시면 해당년월의 평균시세를 주단위로 보실 수 있습니다.
        </p>

        <table className="w-80 table table-bordered table-responsive my-table1 m-auto mt-2">
          <tbody>
            <tr>
              <th colSpan={6}>
                2023년 04월 / 평균시세 :{" "}
                {originGraphData[clickedWeekIdx]?.siseAvge.toLocaleString("en-US")}
                만원
              </th>
            </tr>
            <tr>
              <td className="text-center" style={{ width: "16.66%" }}>
                1주차
                <br />
                {originGraphData[clickedWeekIdx]?.week[0]?.minDate &&
                  moment(originGraphData[clickedWeekIdx].week[0].minDate).format("MM.DD")}{" "}
                ~{" "}
                {originGraphData[clickedWeekIdx]?.week[0]?.maxDate &&
                  moment(originGraphData[clickedWeekIdx].week[0].maxDate).format("MM.DD")}
              </td>
              <td className="text-center" style={{ width: "16.66%" }}>
                2주차
                <br />
                {originGraphData[clickedWeekIdx]?.week[1]?.minDate &&
                  moment(originGraphData[clickedWeekIdx].week[1].minDate).format("MM.DD")}{" "}
                ~{" "}
                {originGraphData[clickedWeekIdx]?.week[1]?.maxDate &&
                  moment(originGraphData[clickedWeekIdx].week[1].maxDate).format("MM.DD")}
              </td>
              <td className="text-center" style={{ width: "16.66%" }}>
                3주차
                <br />
                {originGraphData[clickedWeekIdx]?.week[2]?.minDate &&
                  moment(originGraphData[clickedWeekIdx].week[2].minDate).format("MM.DD")}{" "}
                ~{" "}
                {originGraphData[clickedWeekIdx]?.week[2]?.maxDate &&
                  moment(originGraphData[clickedWeekIdx].week[2].maxDate).format("MM.DD")}
              </td>
              <td className="text-center" style={{ width: "16.66%" }}>
                4주차
                <br />
                {originGraphData[clickedWeekIdx]?.week[3]?.minDate &&
                  moment(originGraphData[clickedWeekIdx].week[3].minDate).format("MM.DD")}{" "}
                ~{" "}
                {originGraphData[clickedWeekIdx]?.week[3]?.maxDate &&
                  moment(originGraphData[clickedWeekIdx].week[3].maxDate).format("MM.DD")}
              </td>
              <td className="text-center" style={{ width: "16.66%" }}>
                5주차
                <br />
                {originGraphData[clickedWeekIdx]?.week[4]?.minDate &&
                  moment(originGraphData[clickedWeekIdx].week[4].minDate).format("MM.DD")}{" "}
                ~{" "}
                {originGraphData[clickedWeekIdx]?.week[4]?.maxDate &&
                  moment(originGraphData[clickedWeekIdx].week[4].maxDate).format("MM.DD")}
              </td>
              <td className="text-center">
                6주차
                <br />
                {originGraphData[clickedWeekIdx]?.week[5]?.minDate &&
                  moment(originGraphData[clickedWeekIdx].week[5].minDate).format("MM.DD")}{" "}
                ~{" "}
                {originGraphData[clickedWeekIdx]?.week[5]?.maxDate &&
                  moment(originGraphData[clickedWeekIdx].week[5].maxDate).format("MM.DD")}
              </td>
            </tr>
            <tr>
              <td className="text-center">
                {originGraphData[clickedWeekIdx]?.week[0]?.siseAvge.toLocaleString("en-US")}
              </td>
              <td className="text-center">
                {originGraphData[clickedWeekIdx]?.week[1]?.siseAvge.toLocaleString("en-US")}
              </td>
              <td className="text-center">
                {originGraphData[clickedWeekIdx]?.week[2]?.siseAvge.toLocaleString("en-US")}
              </td>
              <td className="text-center">
                {originGraphData[clickedWeekIdx]?.week[3]?.siseAvge.toLocaleString("en-US")}
              </td>
              <td className="text-center">
                {originGraphData[clickedWeekIdx]?.week[4]?.siseAvge.toLocaleString("en-US")}
              </td>
              <td className="text-center">
                {originGraphData[clickedWeekIdx]?.week[5]?.siseAvge.toLocaleString("en-US")}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
      <div className="tab2-content-container">
        <div className="table-container table-sticky mb-3 h-333">
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>회원권종목</th>
                <th style={{ width: "15%" }}>매도시세</th>
                <th>매수시세</th>
                <th style={{ width: "40%" }}>비고</th>
                <th style={{ width: "10%" }}>최종수정</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((i, idx) => (
                  <tr
                    key={idx}
                    onClick={() => {
                      setModalShow(true);
                      setModalClickedData(i);
                      bottomGetSiseDetail.mutate(i.code);
                    }}
                  >
                    <td>
                      <span>{i.codename}</span>
                    </td>
                    <td className="text-end">{`${i.siseGubun === "2" ? "*" : ""}${(
                      i.mdSise + ""
                    ).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                    <td className="text-end">{`${i.siseGubun === "2" ? "*" : ""}${(
                      i.msSise + ""
                    ).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                    <td>{i.remark}</td>
                    <td>{moment(i.modDate).format("MM-DD HH:mm")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    검색된 결과가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(Tab3);
