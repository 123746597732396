import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SearchDetailBottomTab1 from "./searchDetailBottomTab1";
import SearchDetailBottomTab2 from "./searchDetailBottomTab2";
import SearchDetailBottomTab3 from "./searchDetailBottomTab3";

const searchDetailBottomMain = () => {
  const [key1a, setKey1a] = React.useState("거래내역");

  return (
    <>
      <Tabs activeKey={key1a} onSelect={k => setKey1a(k)} className="tab-blocks">
        <Tab eventKey="거래내역" title="거래내역">
          <SearchDetailBottomTab1 />
        </Tab>
        <Tab eventKey="통화내역" title="통화내역">
          <SearchDetailBottomTab2 />
        </Tab>
        <Tab eventKey="보유회원권" title="보유회원권">
          <SearchDetailBottomTab3 />
        </Tab>
      </Tabs>
    </>
  );
};
export default searchDetailBottomMain;
