import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  annualLeaveManage_detail,
  annualLeaveManage_seasrchData,
  annualLeaveManage_seasrchOption,
  annualLeaveManage_selectedDetailIdx,
} from "../../../../data/atom/goodsManage/annualLeaveManage/annualLeaveManage/annualLeaveManage_atom";
import moment from "moment";
import { useEffect, useState } from "react";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import usePrintTab from "../../../../components/modal/usePrintTab";

export const AnnualLeaveManage = () => {
  const [searchOption, setSearchOption] = useAtom(annualLeaveManage_seasrchOption);
  const [searchData, setSearchData] = useAtom(annualLeaveManage_seasrchData);
  const [detail, setDetail] = useAtom(annualLeaveManage_detail);
  const [selectedDetailIdx, setSelectedDetailIdx] = useAtom(annualLeaveManage_selectedDetailIdx);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        fromDate: moment(new Date()).format("YYYY-MM-DD"),
        jisaGubn: "999", // 지사구분 셀렉트 박스 - 999 ->전체
        kname: "",
        toDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setSearchData([]);
      setDetail({});
    });
  }, []);

  const { data: jisaData } = useQuery(
    ["getJisaGubun"],
    async () =>
      await axios
        .get("/api/schedule/commuting/getJisaGubn")
        .then(res => res.data.result)
        .catch()
  );
  // API - 근태 조회 검색
  const search = useMutation(
    data =>
      axios.get("/api/schedule/commuting/search", {
        params: {
          ...data,
          fromDate: moment(data.fromDate).format("YYYYMMDD"),
          toDate: moment(data.toDate).format("YYYYMMDD"),
        },
      }),
    {
      onSuccess: res => {
        setSelectedDetailIdx("");
        setSearchData(res.data.result);
      },
    }
  );

  // API - 근태 조회 출력
  const print = useMutation(
    data =>
      axios.get("/api/schedule/commuting/print", {
        params: {
          ...data,
          fromDate: moment(data.fromDate).format("YYYYMMDD"),
          toDate: moment(data.toDate).format("YYYYMMDD"),
        },
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `근태내역_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const pressEnter = e => {
    if (e.key === "Enter") {
      callSearchAPi();
    }
  };

  const onChangeHandler = e => {
    const { name, value } = e.target;
    setSearchOption({ ...searchOption, [name]: value });
  };

  const callSearchAPi = () => {
    search.mutate(searchOption);
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">검색</div>
            <div className="filter-buttons"></div>
          </div>
          <div className="filter_fields_container" onKeyDown={e => pressEnter(e)}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1 m-0 p-0">
                  <label className="filter1-label ms-3">검색기간</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchOption.fromDate}
                    name={"fromDate"}
                    onChange={e => onChangeHandler(e)}
                    min="1900-01-01"
                    max="2099-12-31"
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchOption.toDate}
                    name={"toDate"}
                    onChange={e => onChangeHandler(e)}
                    min="1900-01-01"
                    max="2099-12-31"
                  />
                </div>

                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">성명</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchOption.kname}
                    name={"kname"}
                    onChange={e => onChangeHandler(e)}
                  />
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">지사구분</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => onChangeHandler(e)}
                    value={searchOption.jisaGubn}
                    name={"jisaGubn"}
                  >
                    {jisaData?.map(item => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <input
                    type="checkbox"
                    id="chk-2"
                    className="form-check-input"
                    name="checkbox-option"
                    value="2"
                    readOnly
                    checked={searchOption?.outCheck === "Y" ? true : false}
                    onClick={() => {
                      setSearchOption({
                        ...searchOption,
                        outCheck: searchOption?.outCheck === "Y" ? "N" : "Y",
                      });
                    }}
                  />
                  <label className="filter1-label" htmlFor="chk-2">
                    퇴사자 포함
                  </label>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  callSearchAPi();
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        {detail?.userName && (
          <div className="card card-sub mb-1">
            <div className="card-header">
              <h5 className="card-title m-0">상세보기</h5>
            </div>
            <div className="card-body">
              <div className="filter_fields_row">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="filter1-field filter1-field1 m-0 p-0">
                    <label className="filter1-label">기간:</label>
                    <div className="filter1-label">{detail?.entrDate}</div>
                  </div>
                  <div className="filter1-field filter1-field1 ms-4 w-150px">
                    <label className="filter1-label">부서: {detail?.deptName} </label>
                  </div>
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">성명: {detail?.userName} </label>
                  </div>
                  <div className="filter1-field filter1-field2">
                    <label className="filter1-label">사유: {detail?.upBecause} </label>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="card card-sub mt-14px">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0">근태 내역</h5>
            <button
              className="btn btn-outline-primary btn-filter-outline-primary m-0"
              onClick={() => {
                if (searchData?.length <= 0) {
                  alert("출력할 대상이 없습니다.");
                  return;
                }
                print.mutate(searchOption);
              }}
            >
              출력
            </button>
          </div>
          <div className="card-body">
            <div className="table-container table-sticky h-633">
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th>연번</th>
                    <th>일 자</th>
                    <th>소 속</th>
                    <th>부 서</th>
                    <th>성 명</th>
                    <th>출근시간</th>
                    <th>퇴근시간</th>
                    <th>구분</th>
                    <th>기간(시작)</th>
                    <th>기간(종료)</th>
                    <th>사유</th>
                    <th>결정</th>
                    <th>check</th>
                  </tr>
                </thead>
                <tbody>
                  {searchData?.length > 0 ? (
                    searchData.map((data, _idx) => {
                      let isLate = false;
                      if (data?.entrTime) {
                        const enterHour = Number(data?.entrTime?.slice(0, 2));
                        const enterMinute = Number(data?.entrTime?.slice(2));
                        if (enterHour >= 9 && enterMinute > 10) {
                          isLate = true;
                        }
                      }
                      if (data?.entrTime === "") {
                        isLate = true;
                      }
                      return (
                        <tr
                          className={`${data?.chkFlag === "9" && "late_color"}
                            ${selectedDetailIdx !== _idx && data?.mbecause && "bg-lightyellow"}
                            ${data?.mbecause === "" && isLate && "late_color"}
                            ${selectedDetailIdx === _idx && "bg-blue"}
                            ${data?.outFlag === "22" && "bg-red"}
                          `}
                          role="button"
                          onClick={() => {
                            setDetail(data);
                            setSelectedDetailIdx(_idx);
                          }}
                        >
                          <td>{_idx + 1}</td>
                          <td>{data?.entrDate + " " + data?.dayName}</td>
                          <td>{data?.florName}</td>
                          <td>{data?.deptName}</td>
                          <td>{data?.userName}</td>
                          {/* 출근 시간이 9시 10분보다 늦으면 빨강 처리 */}
                          <td>
                            {data?.entrTime &&
                              data?.entrTime.slice(0, 2) + ":" + data?.entrTime.slice(2)}
                          </td>
                          <td>
                            {data?.outtTime &&
                              data?.outtTime.slice(0, 2) + ":" + data?.outtTime.slice(2)}
                          </td>
                          <td>{data?.remark}</td>
                          <td>
                            {data?.mholiDates && moment(data?.mholiDates).format("YYYY-MM-DD")}
                          </td>
                          <td>
                            {data?.mholiDatee && moment(data?.mholiDatee).format("YYYY-MM-DD")}
                          </td>
                          <td>{data?.mbecause}</td>
                          <td>{data?.edecide}</td>
                          <td>{data?.chkFlag}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={13}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
