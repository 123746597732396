import EmpModal from "../../../../../components/modal/Modal";
import React from "react";
import moment from "moment";
import { useGetUserIdQ } from "../../../../../api/code/useGetUserIdQ";
import { useGetTeamList } from "../../../../../api/code/useGetTeamList";
import { useGetEtcMgQ } from "../../../../../api/code/useGetEtcMgQ";

export const SideMessageModal = ({ isSideMessageModalOpen, setIsSideMessageModalOpen }) => {

  const getUserIdQ = useGetUserIdQ();
  const getTeamList = useGetTeamList();
  const getEtcMgQ = useGetEtcMgQ();

  return (
    <EmpModal
      show={isSideMessageModalOpen.isOpen}
      width="98.5%"
      minWidth="475px"
      title="전달사항 세부내용"
      onHide={() => setIsSideMessageModalOpen({ isOpen: false, data: null })}
      strClass="modal-xl"
      size="xl"
    >
      <table className="table table-bordered table-responsive my-table1">
        <tbody>
          <tr>
            <th style={{ width: "8.052%" }}>게시번호</th>
            <td style={{ width: "25.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={isSideMessageModalOpen.data.ilNo}
                readOnly={true}
              />
            </td>
            <th style={{ width: "8.052%" }}>전달일자</th>
            <td style={{ width: "25.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={moment(isSideMessageModalOpen.data.sendDate).format("YYYY-MM-DD")}
                readOnly={true}
              />
            </td>
            <th style={{ width: "8.052%" }}>구분</th>
            <td style={{ width: "25.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={
                  isSideMessageModalOpen.data.gubun === "1"
                    ? "긴급"
                    : isSideMessageModalOpen.data.gubun === "2"
                    ? "일반"
                    : isSideMessageModalOpen.data.gubun === "3" && "공지"
                }
                readOnly={true}
              />
            </td>
          </tr>
          <tr>
            <th style={{ width: "8.052%" }}>전달자</th>
            <td style={{ width: "25.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={
                  getUserIdQ.data?.find(el => el.key === isSideMessageModalOpen.data.userid)
                    ?.value ?? ""
                }
                readOnly={true}
              />
            </td>
            <th style={{ width: "8.052%" }}>전달대상</th>
            <td style={{ width: "25.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={
                  isSideMessageModalOpen.data.receiveGubun === "2"
                    ? getUserIdQ.data?.find(el => el.key === isSideMessageModalOpen.data.receiveId)
                        ?.value
                    : isSideMessageModalOpen.data.receiveGubun === "7"
                    ? getTeamList.data?.find(
                        el2 => el2.key === isSideMessageModalOpen.data.receiveId
                      )?.value
                    : getEtcMgQ.data?.find(
                        el3 => el3.key === isSideMessageModalOpen.data.receiveGubun
                      )?.value
                }
                readOnly={true}
              />
            </td>
          </tr>
          <tr>
            <th style={{ width: "8.052%" }}>제목</th>
            <td colSpan={5}>
              <input
                type="text"
                className="form-control table-input"
                value={isSideMessageModalOpen.data.title}
                readOnly={true}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <textarea
        className="form-control mb-2"
        rows="7"
        value={isSideMessageModalOpen.data.remark}
        readOnly={true}
      />
    </EmpModal>
  );
};
