import React, { useEffect, useRef, useState } from "react";
import EmpModal from "../../../../../components/modal/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useVirtualizer } from "@tanstack/react-virtual";
import useInfiniteScrollWrapper from "../../../../../util/infiniteScroll/useInfiniteScrollWrapper";

/**
 * 문자메세지 전송 모달에서 전송대상 검색 결과 모달
 * @param isSMSSearchTableModalOpen
 * @param setIsSMSSearchTableModalOpen
 * @param selectedTableList
 * @param setSelectedTableList
 * @param searchData
 * @param setSearchData
 * @returns {Element}
 * @constructor
 */

export const SendSMSSearchTableModal = ({
  isSMSSearchTableModalOpen,
  setIsSMSSearchTableModalOpen,
  selectedTableList,
  setSelectedTableList,
  searchData,
  setSearchData,
}) => {
  const [infinityScroll, setInfinityScroll] = useState(null);

  const [searchDataModal, setSearchDataModal] = useState(searchData);
  const [searchedTableList, setSearchedTableList] = useState(isSMSSearchTableModalOpen.data);
  const [checkedList, setCheckedList] = useState(
    Array.from({ length: isSMSSearchTableModalOpen.data.length }, () => false)
  );

  // SMS 고객 조회
  const customerSearchSmsCust = useMutation(data =>
    axios.get("/api/work/customer/searchSmsCust", { params: data })
  );

  // 무한 스크롤 이벤트
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && searchedTableList.length > 99) {
          setSearchDataModal(prev => {
            const newSearchDataModal = { ...prev, page: prev.page + 1 };

            customerSearchSmsCust.mutateAsync(newSearchDataModal).then(res => {
              setSearchedTableList(prev => [...prev, ...res.data.result]);
              setCheckedList(prev => [
                ...prev,
                ...Array.from({ length: res.data.result.length }, () => false),
              ]);
            });
            return newSearchDataModal;
          });
        }
      },
      { threshold: 1 }
    );

    if (infinityScroll) {
      observer.observe(infinityScroll);
    }
  }, [infinityScroll]);

  const btn_add = () => {
    const filter = searchedTableList.filter((el, idx) => checkedList[idx]);

    // if (window.confirm("추가를 하시겠습니까?")) {
    if (filter.length === 0) {
      alert("추가할 대상을 선택해주세요.");
      return;
    }

    setSelectedTableList(prev => [...prev, ...filter.map(el => ({ ...el, isDuplication: "N" }))]);
    setSearchData({ ...searchData, page: 1 });
    setIsSMSSearchTableModalOpen({ data: null, isOpen: false });
    // }
  };

  const btn_close = () => {
    setIsSMSSearchTableModalOpen({ data: null, isOpen: false });
    setSearchData({ ...searchData, page: 1 });
    // setSearchedTableList([]);
  };

  return (
    <EmpModal
      show={isSMSSearchTableModalOpen.isOpen}
      width="100%"
      minWidth="475px"
      title="검색결과 "
      onHide={() => setIsSMSSearchTableModalOpen({ data: null, isOpen: false })}
      strClass="modal-scroll"
      size="md"
      footer={
        <>
          <button className="btn btn-outline-primary" onClick={btn_add}>
            추가
          </button>
          <button className="btn btn-outline-primary" onClick={btn_close}>
            종료
          </button>
        </>
      }
    >
      <div className="table-container table-sticky h-333">
        <table className="table table-bordered table-responsive my-table m-0">
          <thead>
            <tr>
              <th>No.</th>
              <th>성명</th>
              <th>번호</th>
              <th>선택</th>
            </tr>
          </thead>
          <tbody>
            {searchedTableList.map((el, idx) => {
              if (idx === searchedTableList.length - 1) {
                return (
                  <>
                    <div className="m-0 p-0" ref={setInfinityScroll} />
                    <tr key={el.seqNo}>
                      <td>{idx + 1}</td>
                      <td>{el.kname}</td>
                      <td>{el.cellular}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={checkedList[idx]}
                          onChange={e => {
                            const newCheckedList = [...checkedList];
                            newCheckedList[idx] = e.target.checked;
                            setCheckedList(newCheckedList);
                          }}
                        />
                      </td>
                    </tr>
                  </>
                );
              } else {
                return (
                  <tr key={el.seqNo}>
                    <td>{idx + 1}</td>
                    <td>{el.kname}</td>
                    <td>{el.cellular}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={checkedList[idx]}
                        onChange={e => {
                          const newCheckedList = [...checkedList];
                          newCheckedList[idx] = e.target.checked;
                          setCheckedList(newCheckedList);
                        }}
                      />
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </EmpModal>
  );
};
