import { useGetUserIdQ } from "../../api/code/useGetUserIdQ";
import { useEffect, useState } from "react";

const useUserIdQWithout2272 = () => {
  const getUserIdQ = useGetUserIdQ();
  const [arr, setArr] = useState([]);

  useEffect(() => {
    let data = getUserIdQ?.data ?? [];
    data = data.filter(item => item.work1 !== "22" && item.work1 !== "72");
    setArr(data);
  }, [getUserIdQ.data]);

  return arr;
};

export default useUserIdQWithout2272;
