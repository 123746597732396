import React, { useMemo, useState } from "react";
import { useAtom } from "jotai/index";
import {
  accountOption_atom,
  quotationMain_atom,
  quotationMainUserOption_atom,
  quotationSubList_atom,
  registerFlag_atom,
} from "../../../data/atom/dataManage/quotationManagement/quotationManagement_atom";
import ModalCalculator from "./modal/ModalCalculator";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useGetEtccodeDw } from "../../../api/code/useGetEtccodeDw";
import moment from "moment";

const QuotationSub = props => {
  const { searchQuotationSubList } = props;
  //const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  //const [detailFlag, setDetailFlag] = useAtom(detailFlag_atom);
  const [registerFlag, setRegisterFlag] = useAtom(registerFlag_atom);
  //const [userOption, setUserOption] = useAtom(userOption_atom);
  const [accountOption, setAccountOption] = useAtom(accountOption_atom);
  const [quotationMainUserOption, setQuotationMainUserOption] = useAtom(
    quotationMainUserOption_atom
  );
  //const [quotationList, setQuotationList] = useAtom(quotationList_atom);
  //const [quotationListLimit, setQuotationListLimit] = useAtom(quotationListLimit_atom);
  const [quotationMain, setQuotationMain] = useAtom(quotationMain_atom);
  const [quotationSubList, setQuotationSubList] = useAtom(quotationSubList_atom);
  const [modalShow, setModalShow] = useState(false);
  const etccodeGM = useGetEtccodeDw("GM");
  const etccodeCM = useGetEtccodeDw("CM");
  const etccodeSM = useGetEtccodeDw("SM");

  const GMOptions = useMemo(
    () => etccodeGM.data?.map(el => <option value={el.key}>{el.value}</option>),
    [etccodeGM.data]
  );
  const CMOptions = useMemo(
    () => etccodeCM.data?.map(el => <option value={el.key}>{el.value}</option>),
    [etccodeCM.data]
  );
  const SMOptions = useMemo(
    () => etccodeSM.data?.map(el => <option value={el.key}>{el.value}</option>),
    [etccodeSM.data]
  );

  const saveQuotationSubList = useMutation(
    data => axios.post("/api/data/estimate/middle/save", data),
    {
      onSuccess: res => {
        setQuotationSubList(res.data.result);
        searchQuotationSubList.mutate(quotationMain);
      },
    }
  );

  const deleteQuotationSubList = useMutation(
    data => axios.post("/api/data/estimate/middle/delete", data),
    {
      onSuccess: res => {
        searchQuotationSubList.mutate(quotationMain);
      },
    }
  );

  const onChangeEventHandler = async (key, value, idx) => {
    const setGaesuWon = (kind, currentGaesu = 0) => {
      if (kind === "11") {
        return 1 * currentGaesu;
      } else if (kind === "12") {
        return 2 * currentGaesu;
      } else if (kind === "13") {
        return 3 * currentGaesu;
      } else if (kind === "14") {
        return 4 * currentGaesu;
      } else if (kind === "15") {
        return 5 * currentGaesu;
      } else if (kind === "21") {
        return 1 * currentGaesu;
      } else if (kind === "22") {
        return 2 * currentGaesu;
      } else if (kind === "23") {
        return 4 * currentGaesu;
      } else if (kind === "24") {
        return 6 * currentGaesu;
      } else if (kind === "25") {
        return 8 * currentGaesu;
      } else if (kind === "26") {
        return 10 * currentGaesu;
      } else if (kind === "35") {
        return 0;
      } else if (kind === "36") {
        return 0;
      } else {
        return 0;
      }
    };

    const setStampWon = priceWon => {
      let editedValue;
      if (typeof priceWon === "number") {
        editedValue = Number(priceWon);
      } else if (typeof priceWon === "string") {
        editedValue = Number(priceWon.replace(/,/g, ""));
      }

      if (editedValue <= 10000000) {
        return 0;
      } else if (editedValue > 10000000 && editedValue <= 30000000) {
        return 20000;
      } else if (editedValue > 30000000 && editedValue <= 50000000) {
        return 40000;
      } else if (editedValue > 50000000 && editedValue <= 100000000) {
        return 70000;
      } else if (editedValue > 100000000 && editedValue <= 1000000000) {
        return 150000;
      } else {
        return 350000;
      }
    };

    let gaesu = 0;
    if (
      (key === "gubun" && value === "S") ||
      (quotationSubList[idx].gubun === "S" && (key === "kind" || key === "memberNo"))
    ) {
      await axios
        .get(
          `/api/data/estimate/gaesu?code=${
            key === "memberNo" ? value : quotationSubList[idx].memberNo
          }`
        )
        .then(res => (gaesu = res.data?.result?.[0]?.gaesuWon ?? quotationSubList[idx].gaesuWon))
        .catch(() => {
          alert("서버 오류");
        });
    } else {
      gaesu = quotationSubList[idx].gaesuWon;
    }

    setQuotationSubList(prev => {
      prev = quotationSubList.map((el, idx2) => {
        if (idx === idx2) {
          if ((key === "gubun" && value === "S") || (key !== "gubun" && el.gubun === "S")) {
            if (key === "gubun") {
              return {
                ...el,
                [key]: value,
                gaesuWon: setGaesuWon(el.kind, gaesu),
                stampWon: setStampWon(el.priceWon),
              };
            } else if (key === "kind") {
              return {
                ...el,
                [key]: value,
                gaesuWon: setGaesuWon(value, gaesu),
              };
            } else if (key === "priceWon") {
              return {
                ...el,
                [key]: value,
                stampWon: setStampWon(value),
              };
            } else if (key === "memberNo") {
              return {
                ...el,
                [key]: value,
                codename:
                  el.gch === "1"
                    ? etccodeGM.data?.find(i => i.key === value)?.value
                    : el.gch === "2"
                    ? etccodeCM.data?.find(i => i.key === value)?.value
                    : el.gch === "3"
                    ? etccodeSM.data?.find(i => i.key === value)?.value
                    : "",
                gaesuWon: setGaesuWon(el.kind, gaesu),
              };
            } else {
              return { ...el, [key]: value };
            }
          } else if (key === "memberNo") {
            return {
              ...el,
              codename:
                el.gch === "1"
                  ? etccodeGM.data?.find(i => i.key === value)?.value
                  : el.gch === "2"
                  ? etccodeCM.data?.find(i => i.key === value)?.value
                  : el.gch === "3"
                  ? etccodeSM.data?.find(i => i.key === value)?.value
                  : "",
              memberNo: value,
            };
          } else if (key === "gch") {
            let source =
              value === "1"
                ? etccodeGM.data[0]
                : value === "2"
                ? etccodeCM.data[0]
                : value === "3"
                ? etccodeSM.data[0]
                : {};
            return {
              ...el,
              gch: value,
              memberNo: source?.key ?? "",
              codename: source?.value ?? "",
            };
          } else {
            return {
              ...el,
              [key]: value,
              stampWon: setStampWon(0),
              gaesuWon: setGaesuWon(0),
            };
          }
        } else {
          return el;
        }
      });

      return prev;
    });
  };

  const btnAcquisitionTaxCalculator = () => {
    setModalShow(true);
  };
  const btnSave = () => {
    const removeComma = el => {
      if (typeof el.priceWon === "string") {
        el.priceWon = el.priceWon.replace(/,/g, "");
      }
      if (typeof el.gaesuWon === "string") {
        el.gaesuWon = el.gaesuWon.replace(/,/g, "");
      }
      if (typeof el.stampWon === "string") {
        el.stampWon = el.stampWon.replace(/,/g, "");
      }
      if (typeof el.charge === "string") {
        el.charge = el.charge.replace(/,/g, "");
      }
    };

    if (confirm("저장하시겠습니까?")) {
      if (moment().diff(moment(quotationMain.currDate), "days") > 7) {
        alert("견적일자는 7일 이내로 등록해주세요");
        return;
      }
      if (quotationSubList.filter(el => el.memberNo.trim() === "").length !== 0) {
        alert("회원권을 입력해주세요.");
        return;
      } else if (quotationSubList.filter(el => el.codename.trim() === "").length !== 0) {
        alert("종목명을 입력해주세요.");
        return;
      } else if (
        quotationSubList.filter(el => String(el.priceWon).trim() === "" || el.priceWon === 0)
          .length !== 0
      ) {
        alert("매매금액을 입력해주세요.");
        return;
      }

      const list = quotationSubList.map(el => {
        removeComma(el);
        return { ...el, estimaNo: quotationMain.estimaNo };
      });
      saveQuotationSubList.mutate(list);
    }
  };
  const btnAdd = () => {
    if (moment().diff(moment(quotationMain.currDate), "days") > 7) {
      alert("견적일자는 7일 이내로 등록해주세요");
      return;
    }
    setQuotationSubList([
      {
        gubun: "D",
        gch: "1",
        codename: etccodeGM.data[0].value,
        memberNo: etccodeGM.data[0].key,
        kind: "11",
        priceWon: 0,
        gaesuWon: 0,
        stampWon: 0,
        charge: 0,
        newFlag: true,
      },
      ...quotationSubList,
    ]);
  };

  const btnRowDelete = (deleteEl, idx) => {
    if (deleteEl.update === undefined) {
      const list = quotationSubList.filter((el, idx2) => idx !== idx2);
      setQuotationSubList(list);
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteQuotationSubList.mutate(deleteEl);
      }
    }
  };

  return (
    <>
      <ModalCalculator show={modalShow} onHide={() => setModalShow(false)} />
      <div className="card card-main">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="tab1-row1-count w-50">
              견적세부내용
              <br />
              <small className="f-regular">※ 명의개시료/ 인지세는 골프장 관리에서 자동로딩됨</small>
            </div>
            <div className="tab1-row1-field1 w-50 justify-content-end">
              {!registerFlag ? (
                <>
                  <button className="btn btn-outline-primary" onClick={btnAcquisitionTaxCalculator}>
                    취득세 산출기
                  </button>
                  <button className="btn btn-outline-primary ms-8px" onClick={btnAdd}>
                    {" "}
                    추가
                  </button>
                  <button
                    className="btn btn-outline-primary ms-8px"
                    onClick={btnSave}
                    disabled={quotationSubList.length === 0}
                  >
                    저장
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <table className="table table-bordered table-responsive my-table my-3x">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>연번</th>
                <th style={{ width: "7%" }}>매매</th>
                <th style={{ width: "7%" }}>구분</th>
                <th style={{ width: "8%" }}>회원권</th>
                <th style={{ width: "17%" }}>종목명</th>
                <th style={{ width: "15%" }}>구좌</th>
                <th style={{ width: "9.5%" }}>매매금액</th>
                <th style={{ width: "9.5%" }}>개서료</th>
                <th style={{ width: "9.5%" }}>인지세</th>
                <th style={{ width: "9.5%" }}>수수료</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {quotationSubList.length !== 0 ? (
                quotationSubList.map((el, idx) => {
                  return (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>
                        <select
                          className="form-select"
                          value={el.gubun}
                          onChange={e => {
                            onChangeEventHandler("gubun", e.target.value, idx);
                          }}
                        >
                          <option value="D">매도</option>
                          <option value="S">매수</option>
                          <option value="B">분양</option>
                          <option value="E">기타</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={el.gch}
                          onChange={e => {
                            onChangeEventHandler("gch", e.target.value, idx);
                          }}
                        >
                          <option value="1">골프</option>
                          <option value="2">콘도</option>
                          <option value="3">헬스</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={el.memberNo}
                          readOnly={true}
                        />
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={el.memberNo}
                          onChange={e => {
                            onChangeEventHandler("memberNo", e.target.value, idx);
                          }}
                        >
                          {el.gch === "1"
                            ? GMOptions
                            : el.gch === "2"
                            ? CMOptions
                            : el.gch === "3"
                            ? SMOptions
                            : ""}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={el.kind}
                          onChange={e => {
                            onChangeEventHandler("kind", e.target.value, idx);
                          }}
                        >
                          {accountOption.length !== 0 ? (
                            accountOption.map(el => {
                              return <option value={el.key}>{el.value}</option>;
                            })
                          ) : (
                            <></>
                          )}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={String(el.priceWon)
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .replace(/(^0+)/, "")}
                          onChange={e => {
                            onChangeEventHandler("priceWon", e.target.value, idx);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={String(el.gaesuWon)
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .replace(/(^0+)/, "")}
                          onChange={e => {
                            onChangeEventHandler("gaesuWon", e.target.value, idx);
                          }}
                          readOnly={el.gubun !== "S"}
                          style={el.gubun !== "S" ? { border: "0" } : {}}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={String(el.stampWon)
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .replace(/(^0+)/, "")}
                          onChange={e => {
                            onChangeEventHandler("stampWon", e.target.value, idx);
                          }}
                          readOnly={el.gubun !== "S"}
                          style={el.gubun !== "S" ? { border: "0" } : {}}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={String(el.charge)
                            .replace(/[^0-9]/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .replace(/(^0+)/, "")}
                          onChange={e => {
                            onChangeEventHandler("charge", e.target.value, idx);
                          }}
                        />
                      </td>
                      <td>
                        <a onClick={() => btnRowDelete(el, idx)}>
                          <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={11}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(QuotationSub);
