import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import { useAtom } from "jotai/index";
import {
  currentRow_atom,
  medoja_atom,
} from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import { useGetJisaGubunAllQ1 } from "../../../../api/code/useGetJisaGubunAllQ1";
import { useGetPostGubun } from "../../../../api/code/useGetPostGubun";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import ModalEnvelopeAddressSelect from "./ModalEnvelopeLabel";
import usePrintTab from "../../../../components/modal/usePrintTab";

const ModalEnvelope = props => {
  const { type, setType } = props;
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [medoja, setMedoja] = useAtom(medoja_atom);
  const [info, setInfo] = useState({});
  const [list, setList] = useState([]);
  const [showLabelModal, setShowLabelModal] = useState(false);

  const getJisaGubunAllQ1 = useGetJisaGubunAllQ1();
  const getUserIdQ = useGetUserIdQ();
  const getPostGubun = useGetPostGubun();
  const openPdf = usePrintTab();
  const searchInfoParam = {
    custId:
      type === "1"
        ? medoja?.semusu
        : type === "2"
          ? currentRow?.custId
          : type === "3"
            ? medoja?.rbdcode
            : "",
    jisa: currentRow?.jisa,
    partner: "",
    seqNo: currentRow?.seqNo,
    type: type,
  };

  const searchInfo = useMutation(
    data => axios.get("/api/work/transferTax/mail/getInfo", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        if (
          data.rcAddres ||
          data.rcAddresTxt ||
          data.rcZipcode ||
          data.rcAreaCode ||
          data.rcBdcode
        ) {
          setInfo({
            ...data,
            jisa: data.jisa ? data.jisa : "",
            raddres: data.rcAddres,
            raddresTxt: data.rcAddresTxt,
            rzipcode: data.rcZipcode,
            rareaCode: data.rcAreaCode,
            rbdcode: data.rcBdcode,
          });
        } else if (
          data.rhAddres ||
          data.rhAddresTxt ||
          data.rhZipcode ||
          data.rhAreaCode ||
          data.rhBdcode
        ) {
          setInfo({
            ...data,
            jisa: data.jisa ? data.jisa : "",
            raddres: data.rhAddres,
            raddresTxt: data.rhAddresTxt,
            rzipcode: data.rhZipcode,
            rareaCode: data.rhAreaCode,
            rbdcode: data.rhBdcode,
          });
        }
      },
    }
  );
  const searchList = useMutation(
    data => axios.get("/api/work/transferTax/mail/getBottomList", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setList(data);
      },
    }
  );
  const printEnvelope = useMutation(
    data => axios.post("/api/work/mailBox/printEnv", { list: data }, { responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          openPdf(res?.data);
        }
        // fileDownload(res.data, `양도세_소봉투_${moment().format("YY_MM_DD_hh_mm")}.pdf`);
      },
    }
  );
  const saveAndDelete = useMutation(
    data => axios.post("/api/work/transferTax/mail/removeAfterSave", data),
    {
      onSuccess: res => {
        searchList.mutate();
      },
    }
  );
  const apply = useMutation(data => axios.post("/api/work/transferTax/mail/apply", data), {
    onSuccess: res => {
      alert("작업 완료!");
      if (confirm("Print 작업을 바로 수행하시겠습니까?")) {
        btnPrintEnvelope();
      }
      searchList.mutate();
    },
  });

  useEffect(() => {
    if (type !== "0") {
      searchInfo.mutate(searchInfoParam);
      searchList.mutate();
    }
  }, [type]);

  const onChangeUpperEventHandler = (key, value) => {
    if (key === "partner") {
      setInfo({ ...info, [key]: value, jisa: getUserIdQ.data?.find(el => el.key === value).sjisa });
    } else {
      setInfo({ ...info, [key]: value });
    }
  };
  const onChangeLowerEventHandler = (key, value, idx) => {
    setList(prev => {
      prev = prev.map((el, idx2) => {
        if (idx === idx2) {
          return { ...el, [key]: value };
        } else {
          return el;
        }
      });
      return prev;
    });
  };

  const modalHide = () => {
    setType("0");
    setInfo({});
  };
  const btnPrintEnvelope = () => {
    let filtered = list.filter(e => e.printGubun2 === "1");
    if (filtered.length === 0) {
      alert("출력할 소봉투가 없습니다.");
      return;
    }
    printEnvelope.mutate(filtered);
  };
  const btnPrintLabel = () => {
    setShowLabelModal(true);
  };
  const btnSaveAndDelete = () => {
    if (confirm("저장 후 삭제하시겠습니까?")) {
      if (list.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else {
        saveAndDelete.mutate(list);
      }
    }
  };
  const btnApply = () => {
    if (info.jisa === "") {
      alert("지사구분을 선택해주세요.");
    } else {
      apply.mutate(info);
    }
  };

  return (
    <>
      <ModalEnvelopeAddressSelect
        show={showLabelModal}
        setShow={setShowLabelModal}
        list={list}
        getPostGubun={getPostGubun}
      />
      <Modal show={type !== "0"} size={"lg"} centered onHide={modalHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "1" && "출력할 주소지(세무서)"}
            {type === "2" && "출력할 주소지"}
            {type === "3" && "출력할 주소지(관할관청)"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form">
            <div className="card card-main mt-0">
              <table className="table table-bordered table-responsive my-table1">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>지사구분</th>
                    <td className="input-col-2" style={{ width: "13.1%" }}>
                      <select
                        className="form-select"
                        value={info.jisa}
                        onChange={e => onChangeUpperEventHandler("jisa", e.target.value)}
                      >
                        {getJisaGubunAllQ1.data?.map(el => {
                          return <option value={el.key}>{el.value}</option>;
                        })}
                        <option value="" hidden={true}></option>
                      </select>
                    </td>
                    <th style={{ width: "11.9%" }}>거래지사</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={getJisaGubunAllQ1.data?.find(el => el.key === info.jisaTax)?.value}
                        readOnly={true}
                        style={{ border: "0", cursor: "default" }}
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>{info.seqNo}</th>
                    <td style={{ width: "13.1%" }}>
                      <input type="text" className="form-control table-input" value={info.custId} />
                    </td>
                  </tr>
                  <tr>
                    <th>신청자</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={info.userName}
                        onChange={e => onChangeUpperEventHandler("userName", e.target.value)}
                      />
                    </td>
                    <th>발송방법</th>
                    <td>
                      <select
                        className="form-select"
                        value={info.sendGubun}
                        onChange={e => onChangeUpperEventHandler("sendGubun", e.target.value)}
                      >
                        {getPostGubun.data?.map(el => {
                          return <option value={el.key}>{el.value}</option>;
                        })}
                        <option value="" hidden={true}></option>
                      </select>
                    </td>
                    <th>출력방법</th>
                    <td>
                      <select
                        className="form-select"
                        value={info.printGubun2}
                        onChange={e => onChangeUpperEventHandler("printGubun2", e.target.value)}
                      >
                        <option value="1">소봉투</option>
                        <option value="3">대봉투</option>
                        <option value="2">라벨지</option>
                        <option value="" hidden={true}></option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered table-responsive my-table1">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>업체명</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={info.company}
                        onChange={e => onChangeUpperEventHandler("company", e.target.value)}
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>부서명</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={info.devisioin}
                        onChange={e => onChangeUpperEventHandler("devisioin", e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "11.9%" }}>담당자</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={info.kname}
                        onChange={e => onChangeUpperEventHandler("kname", e.target.value)}
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>직위</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={info.position}
                        onChange={e => onChangeUpperEventHandler("position", e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "11.9%" }} rowSpan={2}>
                      도로명주소xxx
                    </th>
                    <td colSpan={3}>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control table-input input-address1"
                          value={info.rareaCode ? info.rareaCode : ""}
                        />
                        <DaumPostcode
                          onCompleteCallback={data => {
                            setInfo({
                              ...info,
                              rareaCode: data.zonecode,
                              raddresTxt: data.fullAddr,
                              rbdcode: data.bdCode,
                              rzipcode: "",
                              raddres: "",
                            });
                          }}
                        />
                        <input
                          type="text"
                          className="form-control table-input input-address3"
                          value={info.raddresTxt ? info.raddresTxt : ""}
                        />
                        <input
                          type="text"
                          className="form-control table-input input-address3"
                          value={info.rzipcode ? info.rzipcode : ""}
                          onChange={e => onChangeUpperEventHandler("rzipcode", e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={info.raddres ? info.raddres : ""}
                        onChange={e => onChangeUpperEventHandler("raddres", e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "11.9%" }}>내용물</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={info.remark}
                        onChange={e => onChangeUpperEventHandler("remark", e.target.value)}
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>담당딜러</th>
                    <td style={{ width: "13.1%" }}>
                      <select
                        className="form-select"
                        value={info.partner}
                        onChange={e => onChangeUpperEventHandler("partner", e.target.value)}
                      >
                        {getUserIdQ.data?.map(el => {
                          return <option value={el.key}>{el.value}</option>;
                        })}
                        <option value="" hidden={true}></option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="post-note-span">
                세무서, 관할 관청으로 발송 시 양도(수)인 이름을 반드시 포함하여 기재할 것. 내용물
                항목에서[재중]은 자동부여 되므로 기재하지 말 것.
              </div>
              <div className="post-print-container">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn me-2"
                  onClick={btnPrintEnvelope}
                >
                  소봉투 출력
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn me-2"
                  onClick={btnPrintLabel}
                >
                  라벨스티커 출력
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn me-2"
                  onClick={btnSaveAndDelete}
                >
                  기록 저장 후 삭제
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn"
                  onClick={btnApply}
                >
                  신청
                </button>
              </div>
            </div>

            <div className="card-body">
              <div className="overflow-auto table-container table-sticky h-333 mt-3">
                <table className="table table-bordered table-responsive my-table mt-0 vw-60">
                  <thead>
                    <tr>
                      <th style={{ width: "3%" }}>연번</th>
                      <th style={{ width: "5%" }}>구분</th>
                      <th style={{ width: "6%" }}>업체명</th>
                      <th style={{ width: "6%" }}>발송방법</th>
                      <th style={{ width: "4%" }}>성명</th>
                      <th style={{ width: "4%" }}>출력방법</th>
                      <th style={{ width: "4%" }}>출력구분</th>
                      <th style={{ width: "12%" }}>내용물</th>
                      <th style={{ width: "4%" }}>담당딜러</th>
                      <th style={{ width: "4%" }}>거래번호</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.length !== 0 ? (
                      list.map((el, idx) => {
                        return (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>
                              {el.gubun === "1" ? "양도세" : el.gubun === "2" ? "취득세" : ""}
                            </td>
                            <td>{el.company}</td>
                            <td>
                              {getPostGubun?.data?.find(el2 => el2.key === el.sendGubun)?.value}
                            </td>
                            <td>{el.kname}</td>
                            <td>
                              {el.printGubun2 === "1"
                                ? "소봉투"
                                : el.printGubun2 === "2"
                                  ? "라벨지"
                                  : el.printGubun2 === "3"
                                    ? "대봉투"
                                    : ""}
                            </td>
                            <td>
                              <select
                                className="form-select"
                                value={el.printGubun}
                                onChange={e =>
                                  onChangeLowerEventHandler("printGubun", e.target.value, idx)
                                }
                              >
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                              </select>
                            </td>
                            <td>{el.remark}</td>
                            <td>{getUserIdQ?.data?.find(el2 => el2.key === el.partner)?.value}</td>
                            <td>{el.seqNo}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={11}>검색된 결과가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(ModalEnvelope);
