import React, { useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

export const Ten = ({ data }) => {
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={
              {
                // width: `${data.tableRows?.length !== 0 ? "1800px" : ""} `,
                // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                // marginBottom: `${
                //   rowVirtualizer.getTotalSize() -
                //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                //   rowVirtualizer.getVirtualItems().length * 30
                // }px`,
              }
            }
          >
            <thead>
              <tr>
                <th>소속</th>
                <th>성명</th>
                <th>입사년월</th>
                <th>팀합계</th>
                <th>계</th>
                {Array.from({ length: 31 }, (_, idx) => (
                  <th>{idx + 1}일</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.result?.length !== 0 ? (
                data.result?.reduce((acc, cur, idx, arr) => {
                  // 소속 합치기
                  if (cur?.jobName !== arr[idx - 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr>
                        <td rowSpan={arr.filter(el => el.jobName === cur.jobName).length}>
                          {cur.jobName}
                        </td>
                        <td>{cur.userName}</td>
                        <td>{cur.ipsaDate}</td>
                        <td rowSpan={arr.filter(el => el.jobName === cur.jobName).length}>
                          {data.teamSum[cur.jobSeq].totalExcCnt}
                        </td>
                        <td>{cur.totalExcCnt}</td>
                        <td>{cur.exCnt01}</td>
                        <td>{cur.exCnt02}</td>
                        <td>{cur.exCnt03}</td>
                        <td>{cur.exCnt04}</td>
                        <td>{cur.exCnt05}</td>
                        <td>{cur.exCnt06}</td>
                        <td>{cur.exCnt07}</td>
                        <td>{cur.exCnt08}</td>
                        <td>{cur.exCnt09}</td>
                        <td>{cur.exCnt10}</td>
                        <td>{cur.exCnt11}</td>
                        <td>{cur.exCnt12}</td>
                        <td>{cur.exCnt13}</td>
                        <td>{cur.exCnt14}</td>
                        <td>{cur.exCnt15}</td>
                        <td>{cur.exCnt16}</td>
                        <td>{cur.exCnt17}</td>
                        <td>{cur.exCnt18}</td>
                        <td>{cur.exCnt19}</td>
                        <td>{cur.exCnt20}</td>
                        <td>{cur.exCnt21}</td>
                        <td>{cur.exCnt22}</td>
                        <td>{cur.exCnt23}</td>
                        <td>{cur.exCnt24}</td>
                        <td>{cur.exCnt25}</td>
                        <td>{cur.exCnt26}</td>
                        <td>{cur.exCnt27}</td>
                        <td>{cur.exCnt28}</td>
                        <td>{cur.exCnt29}</td>
                        <td>{cur.exCnt30}</td>
                        <td>{cur.exCnt31}</td>
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{cur.userName}</td>
                        <td>{cur.ipsaDate}</td>
                        <td>{cur.totalExcCnt}</td>
                        <td>{cur.exCnt01}</td>
                        <td>{cur.exCnt02}</td>
                        <td>{cur.exCnt03}</td>
                        <td>{cur.exCnt04}</td>
                        <td>{cur.exCnt05}</td>
                        <td>{cur.exCnt06}</td>
                        <td>{cur.exCnt07}</td>
                        <td>{cur.exCnt08}</td>
                        <td>{cur.exCnt09}</td>
                        <td>{cur.exCnt10}</td>
                        <td>{cur.exCnt11}</td>
                        <td>{cur.exCnt12}</td>
                        <td>{cur.exCnt13}</td>
                        <td>{cur.exCnt14}</td>
                        <td>{cur.exCnt15}</td>
                        <td>{cur.exCnt16}</td>
                        <td>{cur.exCnt17}</td>
                        <td>{cur.exCnt18}</td>
                        <td>{cur.exCnt19}</td>
                        <td>{cur.exCnt20}</td>
                        <td>{cur.exCnt21}</td>
                        <td>{cur.exCnt22}</td>
                        <td>{cur.exCnt23}</td>
                        <td>{cur.exCnt24}</td>
                        <td>{cur.exCnt25}</td>
                        <td>{cur.exCnt26}</td>
                        <td>{cur.exCnt27}</td>
                        <td>{cur.exCnt28}</td>
                        <td>{cur.exCnt29}</td>
                        <td>{cur.exCnt30}</td>
                        <td>{cur.exCnt31}</td>
                      </tr>,
                    ];
                  }

                  // 본사 총계
                  if (!cur?.jobName.includes("지사") && arr[idx + 1].jobName.includes("지사")) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={4}>본사 계</td>
                        <td>{data.resultSum["A"].totalExcCnt}</td>
                        <td>{data.resultSum["A"].excCnt1}</td>
                        <td>{data.resultSum["A"].excCnt2}</td>
                        <td>{data.resultSum["A"].excCnt3}</td>
                        <td>{data.resultSum["A"].excCnt4}</td>
                        <td>{data.resultSum["A"].excCnt5}</td>
                        <td>{data.resultSum["A"].excCnt6}</td>
                        <td>{data.resultSum["A"].excCnt7}</td>
                        <td>{data.resultSum["A"].excCnt8}</td>
                        <td>{data.resultSum["A"].excCnt9}</td>
                        <td>{data.resultSum["A"].excCnt10}</td>
                        <td>{data.resultSum["A"].excCnt11}</td>
                        <td>{data.resultSum["A"].excCnt12}</td>
                        <td>{data.resultSum["A"].excCnt13}</td>
                        <td>{data.resultSum["A"].excCnt14}</td>
                        <td>{data.resultSum["A"].excCnt15}</td>
                        <td>{data.resultSum["A"].excCnt16}</td>
                        <td>{data.resultSum["A"].excCnt17}</td>
                        <td>{data.resultSum["A"].excCnt18}</td>
                        <td>{data.resultSum["A"].excCnt19}</td>
                        <td>{data.resultSum["A"].excCnt20}</td>
                        <td>{data.resultSum["A"].excCnt21}</td>
                        <td>{data.resultSum["A"].excCnt22}</td>
                        <td>{data.resultSum["A"].excCnt23}</td>
                        <td>{data.resultSum["A"].excCnt24}</td>
                        <td>{data.resultSum["A"].excCnt25}</td>
                        <td>{data.resultSum["A"].excCnt26}</td>
                        <td>{data.resultSum["A"].excCnt27}</td>
                        <td>{data.resultSum["A"].excCnt28}</td>
                        <td>{data.resultSum["A"].excCnt29}</td>
                        <td>{data.resultSum["A"].excCnt30}</td>
                        <td>{data.resultSum["A"].excCnt31}</td>
                      </tr>,
                    ];
                  }

                  // 지사 총계
                  if (cur?.jobName.includes("지사") && arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={4}>지사 계</td>
                        <td>{data.resultSum["B"].totalExcCnt}</td>
                        <td>{data.resultSum["B"].excCnt1}</td>
                        <td>{data.resultSum["B"].excCnt2}</td>
                        <td>{data.resultSum["B"].excCnt3}</td>
                        <td>{data.resultSum["B"].excCnt4}</td>
                        <td>{data.resultSum["B"].excCnt5}</td>
                        <td>{data.resultSum["B"].excCnt6}</td>
                        <td>{data.resultSum["B"].excCnt7}</td>
                        <td>{data.resultSum["B"].excCnt8}</td>
                        <td>{data.resultSum["B"].excCnt9}</td>
                        <td>{data.resultSum["B"].excCnt10}</td>
                        <td>{data.resultSum["B"].excCnt11}</td>
                        <td>{data.resultSum["B"].excCnt12}</td>
                        <td>{data.resultSum["B"].excCnt13}</td>
                        <td>{data.resultSum["B"].excCnt14}</td>
                        <td>{data.resultSum["B"].excCnt15}</td>
                        <td>{data.resultSum["B"].excCnt16}</td>
                        <td>{data.resultSum["B"].excCnt17}</td>
                        <td>{data.resultSum["B"].excCnt18}</td>
                        <td>{data.resultSum["B"].excCnt19}</td>
                        <td>{data.resultSum["B"].excCnt20}</td>
                        <td>{data.resultSum["B"].excCnt21}</td>
                        <td>{data.resultSum["B"].excCnt22}</td>
                        <td>{data.resultSum["B"].excCnt23}</td>
                        <td>{data.resultSum["B"].excCnt24}</td>
                        <td>{data.resultSum["B"].excCnt25}</td>
                        <td>{data.resultSum["B"].excCnt26}</td>
                        <td>{data.resultSum["B"].excCnt27}</td>
                        <td>{data.resultSum["B"].excCnt28}</td>
                        <td>{data.resultSum["B"].excCnt29}</td>
                        <td>{data.resultSum["B"].excCnt30}</td>
                        <td>{data.resultSum["B"].excCnt31}</td>
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td colSpan={4}>총계</td>
                        <td>{data.total.totalExcCnt}</td>
                        <td>{data.total.excCnt1}</td>
                        <td>{data.total.excCnt2}</td>
                        <td>{data.total.excCnt3}</td>
                        <td>{data.total.excCnt4}</td>
                        <td>{data.total.excCnt5}</td>
                        <td>{data.total.excCnt6}</td>
                        <td>{data.total.excCnt7}</td>
                        <td>{data.total.excCnt8}</td>
                        <td>{data.total.excCnt9}</td>
                        <td>{data.total.excCnt10}</td>
                        <td>{data.total.excCnt11}</td>
                        <td>{data.total.excCnt12}</td>
                        <td>{data.total.excCnt13}</td>
                        <td>{data.total.excCnt14}</td>
                        <td>{data.total.excCnt15}</td>
                        <td>{data.total.excCnt16}</td>
                        <td>{data.total.excCnt17}</td>
                        <td>{data.total.excCnt18}</td>
                        <td>{data.total.excCnt19}</td>
                        <td>{data.total.excCnt20}</td>
                        <td>{data.total.excCnt21}</td>
                        <td>{data.total.excCnt22}</td>
                        <td>{data.total.excCnt23}</td>
                        <td>{data.total.excCnt24}</td>
                        <td>{data.total.excCnt25}</td>
                        <td>{data.total.excCnt26}</td>
                        <td>{data.total.excCnt27}</td>
                        <td>{data.total.excCnt28}</td>
                        <td>{data.total.excCnt29}</td>
                        <td>{data.total.excCnt30}</td>
                        <td>{data.total.excCnt31}</td>
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={36}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
