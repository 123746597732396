import { atom } from "jotai";
import moment from "moment";

export const certificationLedger_searchData_atom = atom([]);
export const certificationLedger_searchOption_atom = atom({
  fromDate: moment(new Date()).subtract("1", "M").format("YYYY-MM-DD"),
  gubun: "",
  keyWord: "",
  page: 1,
  toDate: moment(new Date()).format("YYYY-MM-DD"),
});
export const certificationLedger_row_atom = atom("5");
