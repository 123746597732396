import React, { useEffect, useState } from "react";
import QueueTabs from "../../../../../components/QueueTab/QueueTab";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useAtom } from "jotai";
import transactionRegisterManageDetailTab from "./transactionRegisterManageDetailTab";
import { transactionHistoryClickedRow_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";

export const init = {
  seqNo: null,
  custId: null,
  gubun: null,
  kname: null,
  juminno: null,
  saupno: null,
  company: null,
  devision: null,
  position: null,
  cellular: null,
  outCheck: null,
  outDate: null,
  inDate: null,
  remark: null,
  userid: null,
  modDate: null,
  partner: null,
  ilNo: null,
  birthDate: null,
  jisa: null,
  vipCheck: null,
  siteUrl: null,
  upteCode: null,
  payBank: null,
  payAccount: null,
  payName: null,
  postCheck: null,
  dbCode: null,
  dbReal: null,
  faxCheck: null,
  faxNDate: null,
  tipsCheck: null,
  corpCheck: null,
  telCheck: null,
  ownerName: null,
  ownerPosition: null,
  ownerCelTel: null,
  ownerDevision: null,
  ownerRelation: null,
  hpCheck: null,
  hpCheck2: null,
  outModUserid: null,
  outModDate: null,
  oldPartner: null,
  changeDate: null,
  ownerCTel: null,
  ownerEGubun: null,
  ownerEMail: null,
  ownerRemark: null,
  kosdaqCheck: null,
  accessDeny: null,
  rhBdcode: null,
  rhZipcode: null,
  rhAddres: null,
  rcBdcode: null,
  rcZipcode: null,
  rcAddres: null,
  custGroup: null,
  mainCellular: null,
  dagolfMember: null,
  rhAreaCode: null,
  rcAreaCode: null,
  exchangeCnt: null,
  exchangeFlag: null,
  exchangeMine: null,
  compCust: null,
  shopCust: null,
  chkFlag: null,
  allFlag: null,
  viewFlag: null,
  exchangeDate: null,
  donotcallChk: null,
  memberKname: null,
  memberJuminno: null,
  memberAddress: null,
  compJuso: null,
  zippJuso: null,
  userName: null,
  dbBorn: null,
  userId: null,
  codename: null,
  authRemark: null,
  flag: null,
  jobPermit: null,
  jisaProtect: null,
  ownerList: [],
  sequenNo: null,
  bigoRemk: null,
  kindGubn: null,
  moveHope: null,
  procGubn: null,
  inputId: null,
  inputDate: null,
  modId: null,
  modeDate: null,
  procId: null,
  procDate: null,
  exchange02: null,
  opposite: null,
  etccode: null,
  roadZipcode: null,
  caddres: null,
  czipcode: null,
  email: null,
  egubun: null,
  hzipcode: null,
  htel: null,
  ctel: null,
  haddres: null,
  cfax: null,
  ctelChange: null,
};
export const transactionRegisterManageDetail = () => {
  const [detail, setDetail] = useState(init);
  const [clickedRowAtom, setClickedRowAtom] = useAtom(transactionHistoryClickedRow_atom);

  const customerGetDetail = useMutation(
    data =>
      axios.get("/api/work/customer/getDetail", {
        params: { seqNo: data.seqNo },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        if (result.juminno)
          result.juminno = `${result.juminno.substring(0, 6)}${result.juminno.substring(
            6,
            7
          )}******`;
        setDetail({ ...result });
      },
      onError: ({ response }) => {
        setDetail(init);
      },
    }
  );

  useEffect(() => {
    if (clickedRowAtom) {
      if (clickedRowAtom.seqNo != detail.seqNo || clickedRowAtom.custId != detail.custId)
        customerGetDetail.mutate(clickedRowAtom);
    }
  }, [clickedRowAtom]);

  const onSelectEvent = (item, index) => {
    setClickedRowAtom({ ...item });
  };

  const onCloseEvent = (item, index) => {
    if (item) setClickedRowAtom({ ...item });
    else setClickedRowAtom(null);
  };

  return (
    <>
      <div className="table-tabs-wrapper mt-15px">
        <div className="table-tabs-container">
          <div className="tabs-wrapper w-100">
            <QueueTabs
              tabClass="tabs-rectangle"
              data={detail}
              ChildComponent={transactionRegisterManageDetailTab}
              onSelectEvent={onSelectEvent}
              onCloseEvent={onCloseEvent}
            />
          </div>
        </div>
      </div>
    </>
  );
};
