import { useAtom } from "jotai";
import { registeredDeliveryHistory_searchData_atom } from "../../../../../../data/atom/workManage/postManage/registeredDeliveryHistory_atom";
import moment from "moment/moment";
import { useQueryClient } from "@tanstack/react-query";

export const RegisteredDeliveryHistoryFilterPanelAccount = () => {
  const [searchData, setSearchData] = useAtom(registeredDeliveryHistory_searchData_atom);

  const queryClient = useQueryClient();
  const getJisaGubunAllQ1 = queryClient.getQueryData(["getJisaGubunAllQ1"]);

  const onChangeEventHandler = (key, value) => {
    if (key === "jisa") {
      setSearchData({ ...searchData, [key]: value, allDb: value === "" });
    } else if (key === "condition") {
      setSearchData({ ...searchData, [key]: value, keyword: "" });
    } else {
      setSearchData({ ...searchData, [key]: value });
    }
  };

  return (
    <>
      <div className="filter1-field filter1-field1">
        <label className="filter1-label">검색 지사</label>
        <select
          className="form-select filter1-select1"
          value={searchData.jisa}
          onChange={e => onChangeEventHandler("jisa", e.target.value)}
        >
          <option value="">전체</option>
          {getJisaGubunAllQ1?.map(el => (
            <option value={el.key}>{el.value}</option>
          ))}
        </select>
      </div>
      <div className="filter1-field filter1-field2">
        <label className="filter1-label">검색 선택</label>
        <select
          className="form-select filter1-select1"
          value={searchData.condition}
          onChange={e => onChangeEventHandler("condition", e.target.value)}
        >
          <option value="A">발송기간</option>
          <option value="B">업체명,이름</option>
          <option value="C">등기번호</option>
        </select>
      </div>

      {searchData.condition === "A" && (
        <div className="filter1-field filter1-field2">
          <label className="filter1-label">발송일자</label>
          <input
            type="date"
            className="form-control filter-input filter1-input2"
            value={searchData.fromDate}
            onChange={e => onChangeEventHandler("fromDate", e.target.value)}
            max="9999-12-31"
          />
          <span className="filter1-span">~</span>
          <input
            type="date"
            className="form-control filter-input filter1-input2"
            value={searchData.toDate}
            onChange={e => onChangeEventHandler("toDate", e.target.value)}
            max="9999-12-31"
          />
        </div>
      )}

      {searchData.condition === "B" && (
        <div className="filter1-field filter1-field2">
          <label className="filter1-label">업체명,이름</label>
          <input
            type="text"
            className="form-control filter-input filter1-input2"
            value={searchData.keyword}
            onChange={e => onChangeEventHandler("keyword", e.target.value)}
          />
        </div>
      )}

      {searchData.condition === "C" && (
        <div className="filter1-field filter1-field2">
          <label className="filter1-label">등기번호</label>
          <input
            type="text"
            className="form-control filter-input filter1-input2"
            value={searchData.keyword}
            onChange={e => onChangeEventHandler("keyword", e.target.value)}
          />
        </div>
      )}
    </>
  );
};
