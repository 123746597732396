import { atom } from "jotai";

export const searchParamInit = {
  keyword: "",
  keyword2: "",
  page: 1,
  rowCountOnce: "5", // searchParam용은 아니고 그냥 테이블 보기 로우 갯수
  regular: false,
  holsu: false,
  holsuP: false,
  holsuP3: false,
};
export const tabStayDataInit = { tab2: { editedData: {}, addRow: [], tableData: [] } };
export const searchParam_atom = atom({ ...searchParamInit });
export const searchResult_atom = atom([]);
export const clickedRow_atom = atom(null);
export const golfData_atom = atom({});
export const greenFeeData_atom = atom({});

export const bottomTap_atom = atom("특이사항");
export const tabStayData_atom = atom({ ...tabStayDataInit });

export const tableFoldState_atom = atom({ top: false, bottom: false });
