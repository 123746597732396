import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { axios } from "../../../../util/axios/axios";
import EmpModal from "../../../../components/modal/Modal";
import TaxIssueCheck from "./TaxIssueCheck";
import moment from "moment";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import { useAtom } from "jotai";
import {
  InitialPublication_detailRes,
  InitialPublication_issueRes,
  InitialPublication_memExchangeResVoList,
  InitialPublication_row,
  InitialPublication_searchListCount,
  InitialPublication_searchOption,
  InitialPublication_SearchRes,
  InitialPublication_selectedCustId,
  InitialPublication_showType,
  InitialPublication_transActionLst,
} from "../../../../data/atom/personManage/taxManage/TaxInvoiceManage/InitialPublication_atom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import useCopyClipBoard from "../../../../util/useCopyClipBoard/useCopyClipBoard";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function InitialPublication() {
  const transActionForm = {
    custDate: moment(new Date()).format("YYYY-MM-DD"),
    custId: "",
    gubun0: "",
    gubun1: "",
    gubun2: "",
    ilNo: "",
    item: "",
    modDate: "",
    priceAmt: 0,
    priceTax: 0,
    priceTot: 0,
    seqNo: 0,
    userid: "",
  };
  const initialSearchOption = {
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    keyWord: "",
    gubun0: "2",
    page: 1,
    searchType: "1",
  };
  // 검색 조건
  const [searchOption, setSearchOption] = useAtom(InitialPublication_searchOption);
  // 조회 결과
  const [searchRes, setSearchRes] = useAtom(InitialPublication_SearchRes);
  // 상세 결과
  const [detailRes, setDetailRes] = useAtom(InitialPublication_detailRes);
  const [transActionLst, setTransActionLst] = useAtom(InitialPublication_transActionLst);
  const [issueRes, setIssueRes] = useAtom(InitialPublication_issueRes);
  // 테이블 row 개수
  const [searchListNumber, setSearchListNumber] = useAtom(InitialPublication_row);
  // 검색 결과 개수
  const [searchListCount, setSearchListCount] = useAtom(InitialPublication_searchListCount);
  // botoom 탐지
  const [bottom, setBottom] = useState(null);
  const bottomObserver = useRef(null);
  // modal 버튼
  const [isOpen, setIsOpen] = useState(false);
  // 같은 이름 판별 modal 버튼
  const [isSameNameOpen, setIsSameNameOpen] = useState(false);
  const [sameNameList, setSameNameList] = useState([]);
  const [sameNameSelect, setSameNameSelect] = useState(null); // 같은이름 중 선택 체크박스
  // 회원권 거래 내역
  const [memExchangeResVoList, setMemExchangeResVoList] = useAtom(
    InitialPublication_memExchangeResVoList
  );
  // 선택한 row
  const [selectedCustId, setSelectedCustId] = useAtom(InitialPublication_selectedCustId);
  const [showType, setShowType] = useAtom(InitialPublication_showType);
  const { addTabClosedEvent } = useRecentTabEvent();
  const [isCopy, onCopy] = useCopyClipBoard();

  // 탭 닫을때 초기화
  https: useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        fromDate: moment(new Date()).format("YYYY-MM-DD"),
        toDate: moment(new Date()).format("YYYY-MM-DD"),
        keyWord: "",
        gubun0: "2",
        page: 1,
        searchType: "1",
      });
      setSearchRes([]);
      setDetailRes(null);
      setTransActionLst([
        {
          custDate: moment(new Date()).format("YYYY-MM-DD"),
          custId: "",
          gubun0: "",
          gubun1: "",
          ilNo: "",
          item: "",
          modDate: "",
          priceAmt: 0,
          priceTax: 0,
          priceTot: 0,
          seqNo: 0,
          userid: "",
        },
      ]);
      setIssueRes({
        ilNo: "",
        gubun0: "",
        custId: "",
      });
      setSearchListNumber("5");
      setSearchListCount("0");
      setShowType("1");
    });
  }, []);

  // Function - 거래내역 등록
  const submitTransAction = () => {
    const transActionDataList = transActionLst.map((data, idx) => ({
      ...data,
      seqNo: idx + 1,
      ilNo: issueRes?.ilNo,
      gubun0: detailRes?.gubun0,
      gubun1: detailRes?.gubun1,
      custId: detailRes?.seqNo ? detailRes.seqNo : detailRes.custId,
    }));
    issueTransactionApi.mutate(transActionDataList);
  };

  // API - 세금계산서 최초발행 검색
  const searchApi = useMutation(
    data => axios.get("/api/emp/taxFirstIssue/search", { params: data }),
    {
      onSuccess: (res, payload) => {
        setSearchListCount(res?.data?.result?.total);
        setSelectedCustId("");
        if (payload.searchType === "4") {
          setShowType("2");
        } else if (payload.searchType === "5") {
          setShowType("3");
        } else {
          setShowType("1");
        }

        if (payload.page === 1) {
          setSearchRes(res?.data?.result?.searchResVoList);
        } else {
          setSearchRes([...searchRes, ...res?.data?.result?.searchResVoList]);
        }
      },
    }
  );

  // API - 세금계산서 최초발행 상세
  const detailApi = useMutation(
    data =>
      axios.post("/api/emp/taxFirstIssue/getDetail", { ...data, gubun0: searchOption.gubun0 }),
    {
      onSuccess: (res, payload) => {
        if (res?.data?.result?.sameNameList && res?.data?.result?.sameNameList.length > 0) {
          setSameNameList(res?.data?.result?.sameNameList);
          setIsSameNameOpen(true);
        } else {
          const result = res?.data?.result?.Detail;
          setDetailRes({
            ...result,
            saupjaNo:
              result?.saupjaNo.slice(0, 3) +
              "-" +
              result?.saupjaNo.slice(3, 5) +
              "-" +
              result?.saupjaNo.slice(5),
            gubun1: "1",
            gubun2: "1",
            gubun3: "1",
            sinCheck: "S",
            printGubun: "N",
            emailSelect: "0",
          });

          if (searchOption.searchType === "1" && result?.rbdcode !== result?.rbdcode2) {
            alert(
              `다음과 같은 서로 다른 2개의 주소가 존재합니다...!!! \n\n주소1 : ${result?.checkAddres1}\n주소2 : ${result?.checkAddres2}\n확인하시기 바랍니다...!!! `
            );
          }
        }

        if (
          res?.data?.result?.memExchangeResVoList &&
          res?.data?.result?.memExchangeResVoList.length > 0
        ) {
          setMemExchangeResVoList(res?.data?.result?.memExchangeResVoList);
        } else {
          setMemExchangeResVoList([]);
        }
      },
      onError: err => {
        detailRes(null);
        setIssueRes({
          ilNo: "",
          gubun0: "",
          custId: "",
        });
      },
    }
  );

  // API - 세금계산서 발행
  const issueApi = useMutation(
    data =>
      axios.post("/api/emp/taxFirstIssue/issue", {
        ...detailRes,
        saupjaNo: detailRes?.saupjaNo?.replaceAll("-", ""),
      }),
    {
      onSuccess: res => {
        setIssueRes(res?.data?.result);
      },
      onError: () => {
        setIssueRes({
          ilNo: "",
          gubun0: "",
          custId: "",
        });
      },
    }
  );

  // API - 거래내역 등록
  const issueTransactionApi = useMutation(
    data => axios.post("/api/emp/taxFirstIssue/issueTransaction", data),
    {
      onSuccess: res => {
        alert("거래 내역이 등록되었습니다.");
      },
    }
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setSearchOption(prev => {
            searchApi.mutate({ ...searchOption, page: prev.page + 1 });
            return { ...searchOption, page: prev.page + 1 };
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, [searchOption]);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  const pressEnter = e => {
    if (e.key === "Enter") {
      setSearchRes([]);
      setDetailRes(null);
      setSearchOption({ ...searchOption, page: 1 });
      searchApi.mutate({ ...searchOption, page: 1 });
    }
  };

  const handleCopy = () => {
    if (detailRes) {
      onCopy(detailRes?.addresA + " " + detailRes?.raddres);
    }
  };

  useEffect(() => {
    setSameNameSelect(null);
  }, [isSameNameOpen]);

  return (
    <>
      <EmpModal
        show={isOpen}
        // width="98.5%"
        minWidth="475px"
        title={"세금계산서 발행체크"}
        onHide={() => setIsOpen(false)}
        strClass={"modal-xxl"}
        size={"xl"}
      >
        <div>
          <TaxIssueCheck
            setSelectedCustId={setSelectedCustId}
            setSearchRes={setSearchRes}
            setSameNameList={setSameNameList}
            setModalIsOpen={setIsOpen}
            setIsSameNameOpen={setIsSameNameOpen}
            setDetailRes={setDetailRes}
            setMemExchangeResVoList={setMemExchangeResVoList}
          />
        </div>
      </EmpModal>
      <EmpModal
        show={isSameNameOpen}
        title={"세금계산서 도움화면"}
        onHide={() => {
          setIsSameNameOpen(false);
        }}
      >
        <div>
          <div className="card">
            <div>
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "2%" }}>No.</th>
                    <th style={{ width: "10%" }}>성명</th>
                    <th style={{ width: "20%" }}>주민등록번호</th>
                    <th style={{ width: "10%" }}>우편번호</th>
                    <th style={{ width: "40%" }}>주소</th>
                    <th style={{ width: "10%" }}>선택</th>
                  </tr>
                </thead>
                <tbody>
                  {sameNameList.map((data, _idx) => {
                    return (
                      <tr>
                        <td>{_idx + 1}</td>
                        <td>{data?.kname}</td>
                        <td>
                          {data?.remark
                            .replace(/[^0-9]/g, "")
                            .replace(/^(\d{0,6})(\d{0,7})$/g, "$1-$2")
                            .replace(/-{1,2}$/g, "")}
                        </td>
                        <td>{data?.rzipcode}</td>
                        <td>{data?.addresA + " " + data?.raddres}</td>
                        <td>
                          <input
                            type="checkbox"
                            onClick={() => setSameNameSelect(_idx)}
                            checked={sameNameSelect === _idx}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="btn btn-outline-primary btn-filter-outline-primary m-0"
              onClick={() => {
                if (sameNameSelect != null) {
                  setIsSameNameOpen(false);
                  setDetailRes({
                    ...sameNameList[sameNameSelect],
                    saupjaNo:
                      sameNameList[sameNameSelect]?.saupjaNo.slice(0, 3) +
                      "-" +
                      sameNameList[sameNameSelect]?.saupjaNo.slice(3, 5) +
                      "-" +
                      sameNameList[sameNameSelect]?.saupjaNo.slice(5),
                    gubun0: searchOption.gubun0,
                    gubun1: "1",
                    gubun2: "1",
                    gubun3: "1",
                    sinCheck: "S",
                    printGubun: "N",
                  });
                  setIssueRes({
                    ilNo: "",
                    gubun0: "",
                    custId: "",
                  });
                }
              }}
            >
              확인
            </button>
          </div>
        </div>
      </EmpModal>

      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">세금계산서 최초발행</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0"
                onClick={() => setIsOpen(true)}
              >
                세금계산서 발행체크
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row" onKeyDown={pressEnter}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e =>
                      setSearchOption({ ...initialSearchOption, searchType: e.target.value })
                    }
                    value={searchOption.searchType}
                  >
                    <option value="1">샵명</option>
                    <option value="2">기발행-업체명</option>
                    <option value="3">기발행-성명</option>
                    <option value="4">거래기간(개인)</option>
                    <option value="5">사업자등록- 업체명,성명,관리No,사업자 NO</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">검색어</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    onChange={e => setSearchOption({ ...searchOption, keyWord: e.target.value })}
                    value={searchOption.keyWord}
                  />
                </div>
                <select
                  className="form-select filter1-select1 ms-5"
                  onChange={e => setSearchOption({ ...searchOption, gubun0: e.target.value })}
                  value={searchOption.gubun0}
                >
                  <option value="1">개인사업자</option>
                  <option value="2">법인사업자</option>
                </select>
                {searchOption.searchType === "4" && (
                  <div className="d-flex  ms-5">
                    <input
                      min="1900-01-01"
                      max="2099-12-31"
                      type="date"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.fromDate}
                      onChange={e => setSearchOption({ ...searchOption, fromDate: e.target.value })}
                    />
                    <div className="mx-2">~</div>
                    <input
                      type="date"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.toDate}
                      onChange={e => setSearchOption({ ...searchOption, toDate: e.target.value })}
                      min={searchOption.fromDate}
                      max="2099-12-31"
                    />
                  </div>
                )}
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  setSearchRes([]);
                  setDetailRes(null);
                  setSearchOption({ ...searchOption, page: 1 });
                  searchApi.mutate({ ...searchOption, page: 1 });
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-main">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count">
                조회결과 : &nbsp; &nbsp; &nbsp; &nbsp;{searchListCount} 건
              </div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>
            <div
              className={`table-container table-sticky 
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  {showType === "1" ? (
                    <tr>
                      <th style={{ width: "8%" }}>연번</th>
                      <th style={{ width: "46%" }}>샵(거래소)명</th>
                      <th>대표자</th>
                    </tr>
                  ) : showType === "2" ? (
                    <tr>
                      <th style={{ width: "8%" }}>연번</th>
                      <th>성명</th>
                      <th style={{ width: "46%" }}>거래종목</th>
                      <th>거래월일</th>
                    </tr>
                  ) : (
                    <tr>
                      <th style={{ width: "8%" }}>연번</th>
                      <th>고객번호</th>
                      <th style={{ width: "46%" }}>회사명</th>
                      <th>대표이사</th>
                      <th>사업자 No</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {searchRes.length > 0 ? (
                    searchRes.map((data, _idx) => {
                      if (showType === "1") {
                        return (
                          <tr
                            className={`${
                              _idx === selectedCustId && "bg-blue"
                            } tax-initial-submit-cursor`}
                            onClick={() => {
                              setTransActionLst([transActionForm]);
                              setSelectedCustId(_idx);
                              detailApi.mutate({ ...data, gubun0: searchOption.gubun0 });
                              setIssueRes({
                                ilNo: "",
                                gubun0: "",
                                custId: "",
                              });
                            }}
                          >
                            <td>{_idx + 1}</td>
                            <td>{data?.company}</td>
                            <td>{data?.owner}</td>
                          </tr>
                        );
                      } else if (showType === "3") {
                        return (
                          <tr
                            className={`${
                              _idx === selectedCustId && "bg-blue"
                            } tax-initial-submit-cursor`}
                            onClick={() => {
                              setTransActionLst([transActionForm]);
                              setSelectedCustId(_idx);
                              detailApi.mutate({ ...data, gubun0: searchOption.gubun0 });
                              setIssueRes({
                                ilNo: "",
                                gubun0: "",
                                custId: "",
                              });
                            }}
                          >
                            <td>{_idx + 1}</td>
                            <td>{data?.custId.slice(0, 4) + "-" + data?.custId?.slice(4)}</td>
                            <td>{data?.company}</td>
                            <td>{data?.owner}</td>
                            <td>
                              {data?.saupjaNo.slice(0, 3) +
                                "-" +
                                data?.saupjaNo.slice(3, 5) +
                                "-" +
                                data?.saupjaNo.slice(5)}
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr
                            className={`${
                              _idx === selectedCustId && "bg-blue"
                            } tax-initial-submit-cursor`}
                            onClick={() => {
                              setTransActionLst([transActionForm]);
                              setSelectedCustId(_idx);
                              setDetailRes(null);
                              detailApi.mutate(data);
                              setIssueRes({
                                ilNo: "",
                                gubun0: "",
                                custId: "",
                              });
                            }}
                          >
                            <td>{_idx + 1}</td>
                            <td>{data?.kname}</td>
                            <td>{data?.codename}</td>
                            <td>{moment(data?.custDate).format("YYYY-MM-DD")}</td>
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan={showType === "1" ? 3 : 4}>조회결과가 없습니다.</td>
                    </tr>
                  )}
                  {searchRes.length > 0 && <div ref={setBottom} />}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row gx-3">
          <div className="col-4">
            {detailRes !== null && (
              <div className="card card-main">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title  m-0">공급받는자/ 공급자</h5>
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                    onClick={() => {
                      if (issueRes?.ilNo) {
                        alert("발행번호가 이미 존재합니다. 발행마스터만 수정합니다.");
                        return;
                      }

                      if (detailRes && detailRes?.printGubun === "Y") {
                        let confirmRes = false;

                        if (detailRes?.gubun0 === "2") {
                          confirmRes = window.confirm(
                            "공급자 세금계산서 발행을 법인으로 선택했습니다. 맞습니까? "
                          );
                        } else {
                          confirmRes = window.confirm(
                            "공급자 세금계산서 발행을 개인으로 선택했습니다. 맞습니까? "
                          );
                        }
                        if (confirmRes) {
                          const res2 = window.confirm("발행번호를 부여하고 거래내역을 등록합니다.");
                          if (res2) {
                            issueApi.mutate();
                          }
                        }
                      } else {
                        alert(
                          "공급자 세금계산서 발행을 진행하시려면 구분4에서 발행을 선택해주세요."
                        );
                      }
                    }}
                  >
                    발행
                  </button>
                </div>
                <div className="card-body">
                  <table className="table table-bordered table-responsive my-table1 mb-0">
                    <tbody>
                      <tr>
                        <th style={{ width: "15%" }} className="text-start ps-1">
                          사업자번호
                        </th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.saupjaNo}
                            onChange={e => {
                              const targetValue = e.target.value;
                              const newTargetValue = targetValue
                                .split("")
                                .filter(item => item !== "-")
                                .reduce((acc, cur, idx) => {
                                  return (acc + cur).replace("-", "");
                                }, "");
                              const modeTarget =
                                newTargetValue.slice(0, 3) +
                                "-" +
                                newTargetValue.slice(3, 5) +
                                "-" +
                                newTargetValue.slice(5);
                              setDetailRes({
                                ...detailRes,
                                saupjaNo: modeTarget,
                              });
                            }}
                            maxLength={12}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">발행번호</th>
                        <td colSpan={3}>
                          <select
                            className="form-select left w-50x me-2x"
                            onChange={e => setDetailRes({ ...detailRes, gubun0: e.target.value })}
                            value={detailRes?.gubun0}
                          >
                            <option value="2">법인</option>
                            <option value="1">개인</option>
                          </select>
                          <div className="d-flex align-items-center h-100x justify-content-center">
                            <div>{issueRes?.ilNo.slice(0, 4) + "-" + issueRes?.ilNo.slice(4)}</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">법인명</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.company}
                            onChange={e => setDetailRes({ ...detailRes, company: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">대표자</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.owner}
                            onChange={e => setDetailRes({ ...detailRes, owner: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan={2} className="text-start ps-1">
                          주소
                        </th>
                        <td colSpan={3}>{detailRes?.czipcode + " " + detailRes?.addresC}</td>
                      </tr>
                      <tr>
                        <td colSpan={4}>{detailRes?.caddres}</td>
                      </tr>
                      <tr>
                        <th rowSpan={2} className="text-start ps-1">
                          도로명 주소
                        </th>
                        <td>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control table-input input-of-button"
                              value={detailRes?.rzipcode}
                            />
                            <DaumPostcode
                              onCompleteCallback={data => {
                                setDetailRes({
                                  ...detailRes,
                                  rzipcode: data?.zonecode,
                                  addresA: data?.fullAddr,
                                });
                              }}
                            />
                          </div>
                        </td>
                        <td colSpan={2}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.addresA}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input input-of-button"
                            value={detailRes?.raddres}
                            onChange={e => setDetailRes({ ...detailRes, raddres: e.target.value })}
                          />
                          <CopyToClipboard
                            text={`${detailRes?.addresA + " " + detailRes?.raddres}`}
                          >
                            <button
                              className="btn btn-outline-primary table-button button-of-input h-25px float-end"
                              onClick={() => alert("복사되었습니다.")}
                            >
                              복사
                            </button>
                          </CopyToClipboard>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">업태 </th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.upte}
                            onChange={e => setDetailRes({ ...detailRes, upte: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">종목</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.jongmok}
                            onChange={e => setDetailRes({ ...detailRes, jongmok: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">비고 </th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.remark}
                            onChange={e => setDetailRes({ ...detailRes, remark: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">기타사항</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.remark2}
                            onChange={e => setDetailRes({ ...detailRes, remark2: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "13%" }} className="text-start ps-1">
                          부서명
                        </th>
                        <td style={{ width: "37%" }}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.deptName}
                            onChange={e => setDetailRes({ ...detailRes, deptName: e.target.value })}
                          />
                        </td>
                        <th style={{ width: "13%" }} className="text-start ps-1">
                          담당자
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.damdangja}
                            onChange={e =>
                              setDetailRes({ ...detailRes, damdangja: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">더존전송</th>
                        <td>
                          <select className="form-select w-100">
                            <option>미전송</option>
                          </select>
                        </td>
                        <th className="text-start ps-1">연락처</th>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.telNo}
                            onChange={e => setDetailRes({ ...detailRes, telNo: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">핸드폰</th>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.mobileNo}
                            onChange={e => setDetailRes({ ...detailRes, mobileNo: e.target.value })}
                          />
                        </td>
                        <th className="text-start ps-1">전자우편</th>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailRes?.email}
                            onChange={e => setDetailRes({ ...detailRes, email: e.target.value })}
                            readOnly={detailRes?.emailSelect !== "0"}
                          />
                          <select
                            className="form-select w-100"
                            value={detailRes?.emailSelect}
                            onChange={e => {
                              const emailOptions = {
                                0: { email: "", emailSelect: "0" }, // 직접입력
                                1: { email: "dagolftax@bill36524.com", emailSelect: "1" }, // 본사
                                2: { email: "tg1666@bill36524.com", emailSelect: "2" }, // 대구지사
                                3: { email: "ps1666@bill36524.com", emailSelect: "3" }, // 부산지사
                              };

                              setDetailRes({ ...detailRes, ...emailOptions[e.target.value] });
                            }}
                          >
                            <option value="0">직접입력</option>
                            <option value="1">본사</option>
                            <option value="2">대구지사</option>
                            <option value="3">부산지사</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">구분1</th>
                        <td>
                          <select
                            className="form-select w-100"
                            value={detailRes?.gubun1}
                            onChange={e => setDetailRes({ ...detailRes, gubun1: e.target.value })}
                          >
                            <option value="1">매출</option>
                            <option value="2">매입</option>
                          </select>
                        </td>
                        <th className="text-start ps-1">구분2</th>
                        <td>
                          <select
                            className="form-select w-50x me-2x"
                            value={detailRes?.gubun2}
                            onChange={e => setDetailRes({ ...detailRes, gubun2: e.target.value })}
                          >
                            <option value="1">신규</option>
                            {/* <option value="2">재발행</option> */}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">구분3</th>
                        <td>
                          <select
                            className="form-select w-100"
                            value={detailRes?.gubun3}
                            onChange={e => setDetailRes({ ...detailRes, gubun3: e.target.value })}
                          >
                            <option value="1">영수</option>
                            <option value="2">청구</option>
                          </select>
                        </td>
                        <th className="text-start ps-1">구분4</th>
                        {/* 미발행 선택시 거래내역 등록 칸이 안나와야 합니다. */}
                        <td>
                          <select
                            className="form-select w-100"
                            value={detailRes?.printGubun}
                            onChange={e =>
                              setDetailRes({ ...detailRes, printGubun: e.target.value })
                            }
                          >
                            <option value="Y">발행</option>
                            <option value="N">미발행</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <select
                            className="form-select w-100"
                            value={detailRes?.sinCheck}
                            onChange={e => setDetailRes({ ...detailRes, sinCheck: e.target.value })}
                          >
                            <option value="S">신고 대상</option>
                            <option value="D">보류</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="col-8">
            {detailRes !== null && (
              <div className="card card-main">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title  m-0">회원권 거래내역</h5>
                  <div className="d-flex align-items-center justify-content-end"></div>
                </div>
                <div className="card-body">
                  {/* <div > */}
                  {/* 개인물건 - 하늘색, 법인물건 - 노란색 */}
                  <table className="table table-bordered table-responsive my-table mt-0">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>일자</th>
                        <th>거래분류</th>
                        <th>구분1</th>
                        <th>구분2</th>
                        <th>회원권명</th>
                        <th>회원번호</th>
                        <th>서류금액</th>
                        <th>매매금액</th>
                        <th>UP/DOWN</th>
                        <th>수수료</th>
                        <th>거래딜러</th>
                        <th>비고</th>
                      </tr>
                    </thead>
                    <tbody>
                      {memExchangeResVoList.length > 0 ? (
                        memExchangeResVoList.map((data, _idx) => {
                          return (
                            <tr
                              className={`${
                                data?.tradGubn === "개인물건" && "bg-lightyellow"
                              }                                  ${
                                data?.tradGubn === "법인물건" && "bg-lightblue"
                              }
                            `}
                            >
                              <td>{_idx + 1}</td>
                              <td>{moment(data?.custDate).format("YYYY-MM-DD")}</td>
                              <td>{data?.tradGubn}</td>
                              <td>{data?.saleGu + "-" + data.jisaGerae}</td>
                              <td>{data?.gubun2 + "(" + data.gubun1 + ")"}</td>
                              <td>{data?.gmName}</td>
                              <td>{data?.memberNo}</td>
                              <td>
                                {data?.yangdoWon2?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td>
                                {data?.yangdoWon1?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td>{data?.downYn}</td>
                              <td>
                                {data?.commission?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td>{data?.dealer}</td>
                              <td>{data?.remark}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={13}>조회된 데이터가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {issueRes?.ilNo !== "" && detailRes?.printGubun !== "N" && (
              <>
                <div className="card card-main">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title  m-0">
                      거래내역 등록
                      <small className="ms-3">*매출 공급 가액 수정은 재발행에서</small>
                    </h5>
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                        onClick={() => {
                          if (transActionLst.length > 3) {
                            alert("거래내역은 4건 까지 입력 가능합니다.");
                            return;
                          } else {
                            setTransActionLst([...transActionLst, transActionForm]);
                          }
                        }}
                      >
                        추가
                      </button>
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                        onClick={() => {
                          submitTransAction();
                        }}
                      >
                        저장
                      </button>
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                        onClick={() => {
                          alert("변경 사항이 취소되었습니다.");
                          setTransActionLst([
                            {
                              custDate: moment(new Date()).format("YYYY-MM-DD"),
                              custId: "",
                              gubun0: "",
                              gubun1: "",
                              ilNo: "",
                              item: "",
                              modDate: "",
                              priceAmt: 0,
                              priceTax: 0,
                              priceTot: 0,
                              seqNo: 0,
                              userid: "",
                            },
                          ]);
                        }}
                      >
                        취소
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-end">
                      <label className="mb-15px">금액 단위: 원</label>
                    </div>
                    <table className="table table-bordered table-responsive my-table m-0">
                      <thead>
                        <tr>
                          <th style={{ width: "8%" }}>No</th>
                          <th style={{ width: "15%" }}>발행일자</th>
                          <th style={{ width: "20%" }}>품목</th>
                          <th style={{ width: "14%" }}>금액(공급+VAT)</th>
                          <th style={{ width: "14%" }}>공급가액</th>
                          <th style={{ width: "14%" }}>세액</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transActionLst.map((taData, _idx) => {
                          return (
                            <tr>
                              <td>{_idx + 1}</td>
                              <td>
                                <input
                                  type="date"
                                  min="1900-01-01"
                                  max="2099-12-31"
                                  className="form-control table-input"
                                  value={transActionLst[_idx].custDate}
                                  onChange={e => {
                                    const newTransActionList = transActionLst
                                      .map((data, idx) => {
                                        if (idx === _idx) {
                                          return {
                                            ...data,
                                            custDate: e.target.value,
                                          };
                                        } else {
                                          return data;
                                        }
                                      })
                                      .filter(notUndefined => notUndefined !== undefined);

                                    setTransActionLst(newTransActionList);
                                  }}
                                />
                              </td>
                              {/* 품목 */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input "
                                  value={transActionLst[_idx].item}
                                  onChange={e => {
                                    const newTransActionList = transActionLst
                                      .map((data, idx) => {
                                        if (idx === _idx) {
                                          return {
                                            ...data,
                                            item: e.target.value,
                                          };
                                        } else {
                                          return data;
                                        }
                                      })
                                      .filter(notUndefined => notUndefined !== undefined);

                                    setTransActionLst(newTransActionList);
                                  }}
                                />
                              </td>
                              {/* 금액(공급+VAT) */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input text-end"
                                  value={transActionLst[_idx].priceTot
                                    ?.toString()
                                    ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                  onChange={e => {
                                    const newTransActionList = transActionLst
                                      .map((data, idx) => {
                                        if (idx === _idx) {
                                          return {
                                            ...data,
                                            priceTot: Number(e.target.value.replace(/[^0-9]/g, "")),
                                            priceAmt: Math.round(
                                              (Number(e.target.value.replace(/[^0-9]/g, "")) * 10) /
                                                11
                                            ),
                                            priceTax: Math.round(
                                              Number(e.target.value.replace(/[^0-9]/g, "")) -
                                                (Number(e.target.value.replace(/[^0-9]/g, "")) *
                                                  10) /
                                                  11
                                            ),
                                          };
                                        } else {
                                          return data;
                                        }
                                      })
                                      .filter(notUndefined => notUndefined !== undefined);

                                    setTransActionLst(newTransActionList);
                                  }}
                                />
                              </td>
                              {/* 공급가액 */}
                              <td className="text-end">
                                {transActionLst[_idx].priceAmt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              {/* 세액 */}
                              <td className="text-end">
                                {transActionLst[_idx].priceTax
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={3}>
                            <div className="d-flex justify-content-end ">합계</div>
                          </td>
                          <td className="text-end">
                            {transActionLst
                              .reduce((acc, data) => {
                                return acc + Number(data.priceTot);
                              }, 0)
                              .toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {transActionLst
                              .reduce((acc, data) => {
                                return acc + Number(data.priceAmt);
                              }, 0)
                              .toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {transActionLst
                              .reduce((acc, data) => {
                                return acc + Number(data.priceTax);
                              }, 0)
                              .toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
