import React from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { axios } from "../../util/axios/axios";

export const DaumPostcode = ({
  onCompleteCallback,
  className = "btn btn-outline-primary table-button float-start ms-3px",
}) => {
  const CURRENT_URL = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(CURRENT_URL);

  const handleComplete = data => {
    let fullAddress = data.address;
    let extraAddress = "";
    let buildingCode = data.buildingCode;
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    if (onCompleteCallback) {
      axios.post("/api/codeMng/select/roadAddRes", data);

      onCompleteCallback({
        zonecode: data.zonecode,
        fullAddr: fullAddress,
        bdCode: buildingCode,
        roadAddress: data.roadAddress,
        jibunAddress: data.jibunAddress,
      });
    }
  };

  const handleClick = e => {
    e.stopPropagation();
    open({
      onComplete: handleComplete,
      onClose: state => {
        if (state === "FORCE_CLOSE") {
          onCompleteCallback({
            zonecode: "",
            fullAddr: "",
            bdCode: "",
            roadAddress: "",
            jibunAddress: "",
          });
        }
      },
    });
  };

  return (
    <button className={className} onClick={e => handleClick(e)}>
      찾기
    </button>
  );
};
