import React, { useEffect, useState } from "react";
import { transactionHistoryClickedRow_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { DaumPostcode } from "../../../../../components/daumPostcode/daumPostcode";
import { useAtomValue } from "jotai";
import { regex_only_number } from "../../../../../util/validation";
import { useGetUserIdQ } from "../../../../../api/code/useGetUserIdQ";

const init = {
  caddres: "",
  company: "",
  corpNo: "",
  currDate: "",
  czipcode: "",
  inDate: "",
  jongmok: "",
  kname: "",
  modDate: "",
  modid: "",
  number: "",
  openDate: "",
  permit: "",
  rcAddres: "",
  rcAddresTxt: "",
  caddresTxt: "",
  rcAreaCode: "",
  rcBdcode: "",
  rcZipcode: "",
  remark: "",
  saupno: "",
  sekumDamdang: "",
  sekumDirectcall: "",
  sekumEmail: "",
  sekumHandphon: "",
  seqNo: "",
  upte: "",
  userid: "",
  editing: false,
};

const searchResultListRight2 = () => {
  const [myData, setMyData] = useState({ ...init });
  const atomData = useAtomValue(transactionHistoryClickedRow_atom);
  const [listData, setListData] = useState([]);
  const userIdQ = useGetUserIdQ();

  // 조회 API
  const transactionRightGetMemberInfo = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getBizInfo", {
        params: { seqNo: data.seqNo },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setListData(result);
        setMyData({ ...init });
      },
    }
  );

  // 저장 API
  const transactionRightSaveMemberInfo = useMutation(
    data =>
      axios.post("/api/work/customer/bottom/saveBizInfo", {
        caddres: data.caddres,
        company: data.company,
        corpNo: data.corpNo,
        currDate: data.currDate,
        czipcode: data.czipcode,
        jongmok: data.jongmok,
        kname: data.kname,
        number: data.number,
        openDate: data.openDate ? moment(data.openDate).format("YYYY-MM-DD") : "",
        rcAddres: data.rcAddres,
        rcAreaCode: data.rcAreaCode,
        rcBdcode: data.rcBdcode,
        rcZipcode: data.rcZipcode,
        remark: data.remark,
        saupno: data.saupno,
        sekumDamdang: data.sekumDamdang,
        sekumDirectcall: data.sekumDirectcall,
        sekumEmail: data.sekumEmail,
        sekumHandphon: data.sekumHandphon,
        seqNo: data.seqNo,
        upte: data.upte,
      }),
    {
      onSuccess: res => {
        transactionRightGetMemberInfo.mutate(atomData);
      },
    }
  );

  // 삭제 API
  const transactionRightDeleteMemberInfo = useMutation(
    data =>
      axios.post("/api/work/customer/bottom/deleteBizInfo", {
        seqNo: data.seqNo,
        number: data.number,
      }),
    {
      onSuccess: (res, usedData) => {
        if (usedData.number === myData.number) setMyData({ ...init });
        transactionRightGetMemberInfo.mutate(atomData);
      },
    }
  );

  useEffect(() => {
    if (atomData) transactionRightGetMemberInfo.mutate(atomData);
    else {
      setMyData({ ...init });
      setListData([]);
    }
  }, [atomData]);

  // 등록 버튼
  const btn_insert = () => {
    if (atomData && atomData.seqNo) {
      if (listData.length >= 3) alert("사업자 등록은 최대 3개까지만 가능합니다.");
      else if (!myData.editing) {
        setMyData({
          ...init,
          modid: decrypt("userInfo").loginId,
          modDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          userid: decrypt("userInfo").loginId,
          inDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          number: listData.length > 0 ? listData[listData.length - 1].number + 1 : 1,
          seqNo: atomData.seqNo,
          editing: true,
        });
      }
    }
  };

  // 취소 버튼
  const btn_cancel = () => {
    if (myData.editing) {
      setMyData({ ...init });
    }
  };

  // 리스트 클릭 이벤트
  const onListClickEvent = (item, index) => {
    const filterData = listData.filter(el => el?.number === myData?.number);
    // if (myData.editing) {
    //   // if (filterData.length > 0) {
    //   //   alert("수정중입니다.");
    //   // } else {
    //   //   alert("등록중입니다.");
    //   // }
    //   // return;
    // } else
    setMyData({ ...item, editing: true });
  };

  const onChangeEvent = (key, value) => {
    switch (key) {
      case "saupno":
      case "corpNo":
      case "sekumHandphon":
        if (!regex_only_number.test(value)) return;
    }
    setMyData({ ...myData, [key]: value });
  };

  // 저장 버튼
  const btn_save = () => {
    if (myData.seqNo !== null && myData.seqNo !== "") transactionRightSaveMemberInfo.mutate(myData);
    else alert("먼저 등록 또는 리스트에서 선택해 주세요");
  };

  // 삭제 버튼
  const btn_delete = data => {
    if (confirm("정말 삭제 하시겠습니까?")) transactionRightDeleteMemberInfo.mutate(data);
    else alert("취소되었습니다.");
  };

  return (
    <div className="tab-content-container">
      <div className="table-tabs-sub">사업자등록증 리스트</div>
      <table className="table table-bordered table-responsive my-table1">
        <tbody>
          <tr>
            <th>No</th>
            <th>사업자번호</th>
            <th>법인명</th>
            <th>삭제</th>
          </tr>
          {listData.length > 0 ? (
            listData.map((item, index) => (
              <tr
                onClick={e => {
                  if (e.target?.name === "deleteImg") return;
                  onListClickEvent(item, index);
                }}
                role="button"
                className={`${myData?.number === item?.number && "bg-lightblue"}`}
              >
                <td className="text-center">{item?.seqNo && index + 1}</td>
                <td className="text-center">
                  {item?.saupno.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)}
                </td>
                <td className="text-center">{item?.company}</td>
                <td className="text-center">
                  {item?.seqNo && (
                    <a href={void 0}>
                      <img
                        name={"deleteImg"}
                        src={process.env.PUBLIC_URL + "/images/delete.png"}
                        alt=""
                        onClick={() => btn_delete(item)}
                      />
                    </a>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                등록된 사업자등록증이 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="d-flex align-items-center justify-content-end">
        <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_save}>
          저장
        </button>
        <button
          className="btn btn-primary tab1-row1-btn ms-2"
          onClick={btn_insert}
          disabled={myData.editing}
        >
          등록
        </button>
        {myData.editing && (
          <button className="btn btn-outline-primary tab1-row1-btn " onClick={btn_cancel}>
            취소
          </button>
        )}
      </div>
      {myData?.number !== "" && (
        <>
          <div className="table-tabs-sub mt-2">
            사업자 등록증 내용
            <span className="small text-primary">(법인고객일시 필수 입력)</span>
          </div>
          <table className="table table-bordered table-responsive my-table1">
            <tbody>
              <tr>
                <th style={{ width: "13%" }}>사업자번호</th>
                <td style={{ width: "37%" }} className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    // maxLength={10}
                    value={myData?.saupno.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)}
                    onChange={e => onChangeEvent("saupno", e.target.value.replace(/-/g, ""))}
                    maxLength={12}
                    readOnly={!myData.editing}
                  />
                </td>
                <th style={{ width: "13%" }}>법인명</th>
                <td style={{ width: "37%" }} className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.company}
                    onChange={e => onChangeEvent("company", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <th>대표이사</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.kname}
                    onChange={e => onChangeEvent("kname", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
                <th>개업년월</th>
                <td className="text-center">
                  <input
                    type="date"
                    max={"9999-12-31"}
                    className="form-control table-input"
                    value={moment(myData?.openDate).format("YYYY-MM-DD")}
                    onChange={e => onChangeEvent("openDate", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <th>법인등록번호</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.corpNo.replace(/^(\d{6})(\d{7})$/, `$1-$2`)}
                    onChange={e => onChangeEvent("corpNo", e.target.value.replace(/-/g, ""))}
                    maxLength={14}
                    readOnly={!myData.editing}
                  />
                </td>
                <th>업태 </th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.upte}
                    onChange={e => onChangeEvent("upte", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <th rowSpan={2}>사업장소재지</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.czipcode}
                    readOnly={true}
                  />
                </td>
                <td colSpan={2} className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.caddresTxt}
                    readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.caddres}
                    readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <th rowSpan={2}>
                  사업장소재지
                  <br />
                  도로명 주소
                </th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input input-of-button"
                    value={myData?.rcAreaCode}
                    onChange={e => onChangeEvent("rcAreaCode", e.target.value)}
                    readOnly={!myData.editing}
                  />
                  <DaumPostcode
                    onCompleteCallback={data =>
                      setMyData({
                        ...myData,
                        rcAddresTxt: data.fullAddr,
                        rcAreaCode: data.zonecode,
                        rcBdcode: data.bdCode,
                        rcZipcode: "",
                        czipcode: "",
                        caddresTxt: data.jibunAddress,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.rcAddresTxt}
                    onChange={e => onChangeEvent("rcAddresTxt", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.rcAddres}
                    onChange={e => onChangeEvent("rcAddres", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <th>종목</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.jongmok}
                    onChange={e => onChangeEvent("jongmok", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
                <th>휴대폰</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.sekumHandphon}
                    onChange={e => onChangeEvent("sekumHandphon", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <th>담당자 </th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.sekumDamdang}
                    onChange={e => onChangeEvent("sekumDamdang", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
                <th>직통</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.sekumDirectcall}
                    onChange={e => onChangeEvent("sekumDirectcall", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <th>메일주소 </th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.sekumEmail}
                    onChange={e => onChangeEvent("sekumEmail", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
                <th>교부사유 </th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    value={myData?.remark}
                    onChange={e => onChangeEvent("remark", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
              </tr>
              <tr>
                <th>교부일자</th>
                <td className="text-center">
                  <input
                    type="date"
                    max={"9999-12-31"}
                    className="form-control table-input"
                    value={moment(myData?.currDate).format("YYYY-MM-DD")}
                    onChange={e => onChangeEvent("currDate", e.target.value)}
                    readOnly={!myData.editing}
                  />
                </td>
                <th>관할</th>
                <td className="text-center">{myData?.officeName}</td>
              </tr>
              <tr>
                <th>최초입력</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    readOnly={true}
                    value={userIdQ.data?.find(item => item.key === myData.userid)?.value ?? ""}
                  />
                </td>
                <th>최초입력일</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    readOnly={true}
                    value={myData?.inDate && moment(myData.inDate).format("YYYY-MM-DD HH:mm:ss")}
                  />
                </td>
              </tr>
              <tr>
                <th>최종수정</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    readOnly={true}
                    value={userIdQ.data?.find(item => item.key === myData.modid)?.value ?? ""}
                  />
                </td>
                <th>최종수정일</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control table-input"
                    readOnly={true}
                    value={myData?.modDate && moment(myData.modDate).format("YYYY-MM-DD HH:mm:ss")}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default searchResultListRight2;
