import { atom } from "jotai";
import moment from "moment";

export const annualManage_searchData = atom({
  jisa: "99",
  kname: "",
  toDate: moment(new Date()).format("YYYY-MM-DD"),
  yearRange: "1",
});

export const annualManage_data = atom([]);
