import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  individualPerformance_addData,
  individualPerformance_backup,
  individualPerformance_etcAmt,
  individualPerformance_finalAdd,
  individualPerformance_individualPerformance,
  individualPerformance_modify,
  individualPerformance_searchList,
  individualPerformance_searchOption,
  individualPerformance_selectMemberId,
  individualPerformance_unregisterList,
  isShowEtc,
} from "../../../data/atom/personManage/individualPerformance/individualPerformance_atom";
import EmpModal from "../../../components/modal/Modal";
import { Fragment, useEffect, useState } from "react";
import IndividualPerformanceClose from "./individualPerformanceClose";
import moment from "moment";
import { fileDownload } from "../../../util/fileDownload/fileDownload";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { numberFixedFormat } from "../../../util/numberFixedFormat";
import { NumberInput1 } from "../../../components/NumberInput1";

const numberFormat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0, // 소수점 이하 최소 자릿수
  maximumFractionDigits: 2, // 소수점 이하 최대 자릿수
}).format;

const addSum = (data, key) => {
  let past = {};
  let sum = { yymmdd: "소계" };
  let sumArr = [];
  let jobName = ["마 스 터", "회원권사업부", "콘도사업부", null];
  if (data.length > 0) past = data[0];
  data = data.reduce((acc, item) => {
    if (
      past.jobName === item.jobName ||
      (jobName.includes(item.jobName) && jobName.includes(past.jobName))
    ) {
    } else {
      acc.push(sum);
      sumArr.push(sum);
      sum = { yymmdd: "소계" };
    }
    sum.exchangeSum = Number(sum.exchangeSum ?? 0) + Number(item.exchangeSum);
    sum.resultSum = Number(sum.resultSum ?? 0) + Number(item.resultSum);
    sum.ecntSum = Number(sum.ecntSum ?? 0) + Number(item.ecntSum);
    sum.resultEtcsum = Number(sum.resultEtcsum ?? 0) + Number(item.resultEtcsum);
    sum.exchangeCnt = Number(sum.exchangeCnt ?? 0) + Number(item.exchangeCnt);
    sum.resultAmnt = Number(sum.resultAmnt ?? 0) + Number(item.resultAmnt);
    sum.etcCnt = Number(sum.etcCnt ?? 0) + Number(item.etcCnt);
    sum.resultEtc = Number(sum.resultEtc ?? 0) + Number(item.resultEtc);
    sum.exchangeTotal =
      Number(sum.exchangeTotal ?? 0) + Number(item.exchangeCnt) + Number(item.exchangeSum);
    sum.resultTotal =
      Number(sum.resultTotal ?? 0) + Number(item.resultSum) + Number(item.resultAmnt);
    sum.ecntTotal = Number(sum.ecntTotal ?? 0) + Number(item.ecntSum) + Number(item.etcCnt);
    sum.resultEtcTotal =
      Number(sum.resultEtcTotal ?? 0) + Number(item.resultEtc) + Number(item.resultEtcsum);
    let newone = { ...item };
    acc.push(newone);
    past = newone;

    if (item === data[data.length - 1]) {
      acc.push(sum);
      sumArr.push(sum);
      sum = { yymmdd: "합계" };
    }

    return acc;
  }, []);

  for (let item of sumArr) {
    sum.exchangeSum = Number(sum.exchangeSum ?? 0) + Number(item.exchangeSum);
    sum.resultSum = Number(sum.resultSum ?? 0) + Number(item.resultSum);
    sum.ecntSum = Number(sum.ecntSum ?? 0) + Number(item.ecntSum);
    sum.resultEtcsum = Number(sum.resultEtcsum ?? 0) + Number(item.resultEtcsum);
    sum.exchangeCnt = Number(sum.exchangeCnt ?? 0) + Number(item.exchangeCnt);
    sum.resultAmnt = Number(sum.resultAmnt ?? 0) + Number(item.resultAmnt);
    sum.etcCnt = Number(sum.etcCnt ?? 0) + Number(item.etcCnt);
    sum.resultEtc = Number(sum.resultEtc ?? 0) + Number(item.resultEtc);
    sum.exchangeTotal =
      Number(sum.exchangeTotal ?? 0) + Number(item.exchangeCnt) + Number(item.exchangeSum);
    sum.resultTotal =
      Number(sum.resultTotal ?? 0) + Number(item.resultSum) + Number(item.resultAmnt);
    sum.ecntTotal = Number(sum.ecntTotal ?? 0) + Number(item.ecntSum) + Number(item.etcCnt);
    sum.resultEtcTotal =
      Number(sum.resultEtcTotal ?? 0) + Number(item.resultEtc) + Number(item.resultEtcsum);
  }
  data.push(sum);

  return data;
};

const IndividualPerformanceRegister = () => {
  const [searchOption, setSearchOption] = useAtom(individualPerformance_searchOption);
  const [searchList, setSearchList] = useAtom(individualPerformance_searchList);
  const [unregisterList, setUnregisterList] = useAtom(individualPerformance_unregisterList);
  const [backupData, setBackupData] = useAtom(individualPerformance_backup);
  const [modeData, setModeData] = useAtom(individualPerformance_modify);
  const [addData, setAddData] = useAtom(individualPerformance_addData);
  const [finalAdd, setFinalAdd] = useAtom(individualPerformance_finalAdd);
  const [etcDate, setEtcDate] = useAtom(individualPerformance_etcAmt);
  const [isShowEtcData, setIsShowEtcData] = useAtom(isShowEtc);
  const [isOpen, setIsOpen] = useState(false);
  const [selectMember, setSelectMember] = useAtom(individualPerformance_selectMemberId);
  const [individualPerformance, setIndividualPerformanece] = useAtom(
    individualPerformance_individualPerformance
  );
  const { addTabClosedEvent } = useRecentTabEvent();

  const queryClient = useQueryClient();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        again: "",
        yymmdd: moment(new Date()).format("YYYY-MM-DD"),
      });
      setSearchList([]);
      setUnregisterList([]);
      setBackupData([]);
      setModeData([]);
      setAddData([]);
      setFinalAdd([]);
      setEtcDate({ fromDate: "", toDate: "" });
      setIsShowEtcData(false);
      setSelectMember("");
      setIndividualPerformanece(null);
    });
  }, []);

  // API - 검색
  const search = useMutation(
    data =>
      axios
        .get("/api/emp/empPerformance/search", {
          params: data,
        })
        .then(res => {
          setSelectMember("");
          setIndividualPerformanece(null);
          if (searchOption.yymmdd && etcDate.fromDate && etcDate.toDate) {
            const isBetween = moment(searchOption.yymmdd).isBetween(
              etcDate.fromDate,
              etcDate.toDate
            );
            if (isBetween) {
              setIsShowEtcData(true);
            } else {
              setIsShowEtcData(false);
            }
          }
          return res?.data?.result;
        })
        .catch(err => {
          if (err.response.data.code === "-5") {
            const result = window.confirm(err.response.data.message);
            if (result) {
              search.mutate({ yymmdd: searchOption.yymmdd.replaceAll("-", ""), again: "Y" });
            }
          }
        }),
    {
      onSuccess: res => {
        if (res?.firstData) {
          let firstDataWithSum = addSum(res?.firstData);
          setSearchList(firstDataWithSum);
          setBackupData(firstDataWithSum);
          const yymmddEditList = firstDataWithSum.map(data => {
            return {
              ...data,
              yymmdd: data?.yymmdd.replaceAll("-", ""),
              resultEtc: 0,
              etcCnt: 0,
              resultAmnt: 0,
              exchangeCnt: 0,
              ecntSum: 0,
              exchangeSum: 0,
              resultEtcsum: 0,
              resultSum: 0,
            };
          });
          setModeData(yymmddEditList);
        } else if (res?.searchList) {
          let withSum = addSum(res?.searchList);
          setSearchList(withSum);
          setBackupData(withSum);
          setModeData(withSum);
          setUnregisterList(res?.unregisterList);
        }
      },
    }
  );

  // API - 실적 저장, 등록
  const savePerformance = useMutation(data => axios.post("/api/emp/empPerformance/save", data), {
    onSuccess: res => {
      search.mutate({ ...searchOption, yymmdd: searchOption.yymmdd.replaceAll("-", "") });
      // 수정사항 초기화
      setModeData([]);
      setAddData([]);
      setFinalAdd([]);
      setUnregisterList([]);
    },
  });

  // API - 개인별 일자별 내역
  const getDetail = useMutation(
    data =>
      axios
        .get("/api/emp/empPerformance/getDetail", {
          params: data,
        })
        .then(res => {
          return res.data.result;
        }),
    {
      onSuccess: res => {
        setIndividualPerformanece(res);
      },
    }
  );

  // API - 초기자료 생성
  const createFirstData = useMutation(
    data =>
      axios
        .get("/api/emp/empPerformance/createFirstData", {
          params: data,
        })
        .then(res => {
          return res?.data?.result;
        })
        .catch(err => {
          if (err.response.data.code === "-5") {
            const result = window.confirm(err.response.data.message);
            if (result) {
              createFirstData.mutate({
                yymmdd: searchOption.yymmdd.replaceAll("-", ""),
                again: "Y",
              });
            }
          }
        }),
    {
      onSuccess: res => {
        if (res?.firstData) {
          let firstDataWithSum = addSum(res?.firstData);
          setSearchList(firstDataWithSum);
          setBackupData(firstDataWithSum);
          const yymmddEditList = firstDataWithSum.map(data => {
            return { ...data, yymmdd: data?.yymmdd.replaceAll("-", "") };
          });
          setModeData(yymmddEditList);
        }
      },
    }
  );
  // 시세 자료 엑셀 저장
  const excelSave = useMutation(
    data =>
      axios.post("/api/emp/empPerformance/excel", data, {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        fileDownload(res.data, `개인별실적등록_${moment().format("YY_MM_DD_hh_mm")}.xlsx`);
      },
    }
  );

  // API - 기타실적 기간 설정
  const saveEtcDate = useMutation(data => axios.post("/api/emp/empPerformance/saveEtcDate", data), {
    onSuccess: res => {
      queryClient.invalidateQueries(["getEtcDate"]);
    },
  });

  // API - 기타 실적 날짜 조회
  const getEtcDate = useQuery(
    ["getEtcDate"],
    async () =>
      await axios.get("/api/emp/empPerformance/getEtcDate").then(res => {
        const fromDate = res?.data?.result?.fromDate
          ? moment(res?.data?.result?.fromDate).format("YYYY-MM-DD")
          : "";
        const toDate = res?.data?.result?.toDate
          ? moment(res?.data?.result?.toDate).format("YYYY-MM-DD")
          : "";

        if (etcDate.fromDate === "" && etcDate.toDate === "") {
          setEtcDate({
            fromDate: fromDate,
            toDate: toDate,
          });
        }

        const isBetween = moment(searchOption.yymmdd).isBetween(fromDate, toDate);
        if (isBetween) {
          setIsShowEtcData(true);
        } else {
          setIsShowEtcData(false);
        }

        return res?.data?.result;
      })
  );

  const searchChangeHandler = e => {
    const { name, value } = e.target;
    if (name === "yymmdd") {
      setSearchOption({ ...searchOption, [name]: value });
    } else {
      setSearchOption({ ...searchOption, [name]: value });
    }
  };

  const handleModify = (e, data) => {
    const { name, value } = e.target;
    const numValue = Number(value.replace(/[^0-9.-]/g, "").replace(/(\..*)\./g, "$1"));
    let valueDiff = undefined;
    let sumCalced = [false, false];
    const changeData = searchList.map((item, _idx) => {
      if (item.userId === data.userId && item.yymmdd === data.yymmdd) {
        valueDiff = numValue - item[name];
        return { ...item, [name]: numValue };
      } else if (
        valueDiff &&
        ["소계", "합계"].includes(item.yymmdd) &&
        !sumCalced[["소계", "합계"].indexOf(item.yymmdd)]
      ) {
        sumCalced[["소계", "합계"].indexOf(item.yymmdd)] = true;
        return { ...item, [name]: item[name] + valueDiff };
      } else {
        return { ...item };
      }
    });
    const isData = element => {
      return element.userId === data.userId && element.yymmdd === data.yymmdd;
    };
    const idx = modeData.findIndex(isData);
    if (idx === -1) {
      setModeData([
        ...modeData,
        {
          ...data,
          [name]: numValue,
        },
      ]);
    } else {
      const newData = {
        ...data,
        [name]: numValue,
      };
      modeData[idx] = newData;
    }
    setSearchList(changeData);
  };

  const isChecked = (e, data) => {
    const isData = element => {
      return element.userId === data.userId && element.userName === data.userName;
    };
    const idx = addData.findIndex(isData);

    if (idx === -1) {
      // 없으면 추가
      setAddData([
        ...addData,
        {
          ...data,
          yymmdd: searchOption.yymmdd.replaceAll("-", ""),
          resultEtc: 0,
          etcCnt: 0,
          resultAmnt: 0,
          exchangeCnt: 0,
          ecntSum: 0,
          exchangeSum: 0,
          resultEtcsum: 0,
          resultSum: 0,
          jisa: data?.job,
        },
      ]);
      return true;
    } else {
      // 있으면 삭제
      const deleteData = addData.filter((_, _idx) => {
        return idx !== _idx;
      });
      setAddData(deleteData);
      return false;
    }
  };

  const handleAddModify = (e, data) => {
    const { name, value } = e.target;
    const numValue = Number(value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
    const changeData = finalAdd.map((item, _idx) => {
      if (item.userId === data.userId && item.yymmdd === data.yymmdd) {
        return { ...item, [name]: numValue };
      } else {
        return { ...item };
      }
    });

    setFinalAdd(changeData);
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      search.mutate({ ...searchOption, yymmdd: searchOption.yymmdd.replaceAll("-", "") });
    }
  };

  return (
    <>
      <EmpModal
        show={isOpen}
        strClass={"modal-xxl"}
        size={"xl"}
        width="98.5%"
        minWidth="475px"
        onHide={() => setIsOpen(false)}
        title={"마감하기"}
      >
        <div className="vw-60">
          <IndividualPerformanceClose />
        </div>
      </EmpModal>
      <div className="content-inner">
        <div className="row gx-3">
          <div className="col-8">
            <div className="filter1_container">
              <div className="filter_header_row">
                <div className="filter-title">개인별실적등록</div>
                <div className="filter-buttons">
                  <div className="filter1-field filter1-field2 m-0 p-0">
                    <label className="filter1-label ms-3">기타 실적</label>
                    <input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control filter-input filter1-input2"
                      value={etcDate.fromDate}
                      onChange={e => {
                        setEtcDate({
                          ...etcDate,
                          fromDate: moment(e.target.value).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </div>
                  <div className="txt_daterange">~</div>
                  <div className="filter1-field filter1-field3 px-0">
                    <input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control filter-input filter1-input2"
                      value={etcDate.toDate}
                      onChange={e => {
                        setEtcDate({
                          ...etcDate,
                          toDate: moment(e.target.value).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </div>
                  <button
                    className="ms-2 btn btn-outline-primary"
                    onClick={() => {
                      if (searchOption.yymmdd && etcDate.fromDate && etcDate.toDate) {
                        // const isBetween = moment(searchOption.yymmdd).isBetween(
                        //   etcDate.fromDate,
                        //   etcDate.toDate
                        // );
                        // if (isBetween) {
                        //   setIsShowEtcData(true);
                        // } else {
                        //   setIsShowEtcData(false);
                        // }
                        saveEtcDate.mutate({
                          fromDate: moment(etcDate.fromDate).format("YYYYMMDD"),
                          toDate: moment(etcDate.toDate).format("YYYYMMDD"),
                        });
                      }
                    }}
                  >
                    설정
                  </button>
                </div>
              </div>
              <div className="filter_fields_container d-flex flex-row">
                <div className="filter_fields_row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="filter1-field filter1-field1" onKeyDown={pressEnter}>
                      <label className="filter1-label">실적 일자</label>
                      <input
                        type="date"
                        max={"9999-12-31"}
                        className="form-control filter-input filter1-input2 me-2"
                        name="yymmdd"
                        value={searchOption.yymmdd}
                        onChange={searchChangeHandler}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="d-flex">
                        <button
                          className="btn btn-outline-primary btn-filter-outline-primary"
                          onClick={() => {
                            search.mutate({
                              ...searchOption,
                              yymmdd: searchOption.yymmdd.replaceAll("-", ""),
                            });
                          }}
                        >
                          <img
                            className="me-2"
                            src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                            alt=""
                          />
                          검색
                        </button>
                        <button
                          className="btn btn-outline-primary btn-filter-outline-primary"
                          onClick={() => {
                            setIsShowEtcData(false);

                            createFirstData.mutate({
                              again: "N",
                              yymmdd: searchOption.yymmdd.replaceAll("-", ""),
                            });
                          }}
                        >
                          초기 자료 생성
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex ">
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary m-0"
                    onClick={() => setIsOpen(true)}
                  >
                    마감하기
                  </button>
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary ms-8px me-20px"
                    onClick={() => {
                      const excelList = addSum(
                        searchList
                          .filter(e => !["소계", "합계"].includes(e.yymmdd))
                          .map(data => {
                            return {
                              authName: data?.authName,
                              dept: data?.dept,
                              ecntSum: data?.ecntSum,
                              etcCnt: data?.etcCnt,
                              exchangeCnt: data?.exchangeCnt,
                              exchangeSum: data?.exchangeSum,
                              exchangeTotal: data?.exchangeCnt + data?.exchangeSum,
                              jobName: data?.jobName,
                              resultAmnt: data?.resultAmnt,
                              resultEtc: data?.resultEtc,
                              resultEtcsum: Number(data?.resultEtcsum / 1.1).toFixed(2),
                              resultSum: Number(data?.resultSum / 1.1).toFixed(2),
                              resultTotal: Number(
                                (data?.resultSum + data?.resultAmnt) / 1.1
                              ).toFixed(2),
                              userName: data?.userName,
                              yymmdd: data?.yymmdd,
                              ecntTotal: data?.ecntSum + data?.etcCnt,
                              resultEtcTotal: Number(
                                (data?.resultEtc + data?.resultEtcsum) / 1.1
                              ).toFixed(2),
                            };
                          })
                      );
                      excelSave.mutate(excelList);
                    }}
                  >
                    엑셀 저장
                  </button>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-end mb-10px">
                  <button
                    className="btn btn-outline-primary ms-8px"
                    onClick={() => {
                      savePerformance.mutate(
                        modeData.filter(e => !["소계", "합계"].includes(e.yymmdd))
                      );
                    }}
                  >
                    저장
                  </button>
                  <button
                    className="btn btn-outline-primary ms-8px"
                    onClick={() => {
                      setModeData([]);
                      setSearchList(backupData);
                      setFinalAdd([]);
                    }}
                  >
                    취소
                  </button>
                </div>
                <div className="table-container table-container table-sticky h-633">
                  <table className="table table-bordered table-responsive my-table m-0">
                    <thead>
                      <tr>
                        <th rowSpan={3} style={{ width: "3%" }}>
                          No.
                        </th>
                        <th style={{ width: "12.2%" }} rowSpan={3}>
                          소속법인
                        </th>
                        <th style={{ width: "8.2%" }} rowSpan={3}>
                          소속
                        </th>
                        <th style={{ width: "6.2%" }} rowSpan={3}>
                          성명
                        </th>
                        <th style={{ width: "5.2%" }} rowSpan={3}>
                          직급
                        </th>
                        <th style={{ width: "7.2%" }} rowSpan={3}>
                          일자
                        </th>
                        <th style={{ width: "13%" }} colSpan={4}>
                          전일까지 당월누계
                        </th>
                        <th style={{ width: "13%" }} colSpan={4}>
                          금일 실적
                        </th>
                        <th style={{ width: "13%" }} colSpan={4}>
                          당월 합계
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={isShowEtcData ? 2 : 4}>일반실적</th>
                        {isShowEtcData && <th colSpan={2}>기타 실적</th>}
                        <th colSpan={isShowEtcData ? 2 : 4}>일반실적</th>
                        {isShowEtcData && <th colSpan={2}>기타 실적</th>}
                        <th colSpan={isShowEtcData ? 2 : 4}>일반실적</th>
                        {isShowEtcData && <th colSpan={2}>기타 실적</th>}
                      </tr>
                      <tr>
                        <th colSpan={isShowEtcData ? 1 : 2}>건수</th>
                        <th colSpan={isShowEtcData ? 1 : 2}>실적</th>
                        {/* 기타 */}
                        {isShowEtcData && (
                          <>
                            <th colSpan={1}>건수</th>
                            <th colSpan={1}>실적</th>
                          </>
                        )}

                        <th colSpan={isShowEtcData ? 1 : 2}>건수</th>
                        <th colSpan={isShowEtcData ? 1 : 2}>실적</th>

                        {/* 기타 */}
                        {isShowEtcData && (
                          <>
                            <th colSpan={1}>건수</th>
                            <th colSpan={1}>실적</th>
                          </>
                        )}
                        <th colSpan={isShowEtcData ? 1 : 2}>건수</th>
                        <th colSpan={isShowEtcData ? 1 : 2}>실적</th>

                        {/* 기타 */}
                        {isShowEtcData && (
                          <>
                            <th colSpan={1}>건수</th>
                            <th colSpan={1}>실적</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {finalAdd?.map((data, _idx) => {
                        return (
                          <tr
                            role="button"
                            onClick={() => {
                              getDetail.mutate({ yymmdd: data?.yymmdd, userId: data?.userId });
                            }}
                          >
                            <td></td>
                            <td>{data?.dept}</td>
                            <td>{data?.jobName}</td>
                            <td>{data?.userName}</td>
                            <td>{data?.authName}</td>
                            <td>{moment(data?.yymmdd).format("YYYY-MM-DD")}</td>
                            {/* 당월 누계 : 일반실적 - 건수 */}
                            <td colSpan={isShowEtcData ? 1 : 2}>{data?.exchangeSum}</td>
                            {/* 당월 누계 : 일반실적 - 실적 */}
                            <td colSpan={isShowEtcData ? 1 : 2}>
                              {numberFixedFormat((data.resultSum ?? 0) / 1.1)}
                            </td>
                            {isShowEtcData && (
                              <>
                                {/* 당월 누계 : 기타실적 - 건수 */}
                                <td>{data?.ecntSum}</td>
                                {/* 당월 누계 : 기타실적 - 실적 */}
                                <td>{numberFixedFormat((data.resultEtcsum ?? 0) / 1.1)}</td>
                              </>
                            )}
                            <td colSpan={isShowEtcData ? 1 : 2}>
                              <input
                                onClick={e => e.stopPropagation()}
                                type="text"
                                className="form-control table-input "
                                value={data?.exchangeCnt}
                                name="exchangeCnt"
                                onChange={e => handleAddModify(e, data)}
                              />
                            </td>
                            <td colSpan={isShowEtcData ? 1 : 2}>
                              <input
                                onClick={e => e.stopPropagation()}
                                type="text"
                                className="form-control table-input"
                                value={data?.resultAmnt}
                                name="resultAmnt"
                                onChange={e => handleAddModify(e, data)}
                              />
                            </td>
                            {isShowEtcData && (
                              <>
                                <td>
                                  <input
                                    onClick={e => e.stopPropagation()}
                                    type="text"
                                    className="form-control table-input"
                                    value={data?.etcCnt}
                                    name="etcCnt"
                                    onChange={e => handleAddModify(e, data)}
                                  />
                                </td>
                                <td>
                                  <input
                                    onClick={e => e.stopPropagation()}
                                    type="text"
                                    className="form-control table-input"
                                    value={data?.resultEtc}
                                    name="resultEtc"
                                    onChange={e => handleAddModify(e, data)}
                                  />
                                </td>
                              </>
                            )}
                            <td colSpan={isShowEtcData ? 1 : 2}></td>
                            <td colSpan={isShowEtcData ? 1 : 2}></td>
                            {isShowEtcData && (
                              <>
                                <td></td>
                                <td></td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                      {searchList?.length > 0 ? (
                        searchList?.map((data, _idx) => {
                          return (
                            <tr
                              role="button"
                              className={`${selectMember === data?.userId && "bg-blue"} 
                                ${(data.work1 === "22" || data.work1 === "72") && "bg-red"}
                                ${data.yymmdd === "소계" && "bg-lightblue"}
                                ${data.yymmdd === "합계" && "bg-lightyellow"}
                              `}
                              onClick={() => {
                                if (["소계", "합계"].includes(data?.yymmdd)) return;
                                setSelectMember(data?.userId);
                                getDetail.mutate({ yymmdd: data?.yymmdd, userId: data?.userId });
                              }}
                            >
                              <td>{_idx + 1}</td>
                              <td>{data?.dept}</td>
                              <td>{data?.jobName}</td>
                              <td>{data?.userName}</td>
                              <td>{data?.authName}</td>
                              <td>
                                {["소계", "합계"].includes(data?.yymmdd)
                                  ? data.yymmdd
                                  : moment(data?.yymmdd).format("YYYY-MM-DD")}
                              </td>
                              {/* 전일까지 당월 누계 - 일반실적 - 건수 */}
                              <td colSpan={isShowEtcData ? 1 : 2} className="text-end">
                                {data?.exchangeSum &&
                                  data?.exchangeSum
                                    .toString()
                                    ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              {/* 전일까지 당월 누계 - 일반실적 - 실적 */}
                              <td colSpan={isShowEtcData ? 1 : 2} className="text-end">
                                {numberFixedFormat((data.resultSum ?? 0) / 1.1)}
                              </td>
                              {/* 전일까지 당월 누계 - 기타실적 - 건수 */}
                              {isShowEtcData && (
                                <>
                                  <td className="text-end">
                                    {data?.ecntSum &&
                                      data?.ecntSum
                                        .toString()
                                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                  </td>
                                  <td className="text-end">
                                    {numberFixedFormat((data.resultEtcsum ?? 0) / 1.1)}
                                  </td>
                                </>
                              )}
                              {/* 금일실적 - 일반실적 - 건수 */}
                              <td colSpan={isShowEtcData ? 1 : 2}>
                                <NumberInput1
                                  onClick={e => e.stopPropagation()}
                                  defaultValue={data?.exchangeCnt ? data?.exchangeCnt : 0}
                                  name="exchangeCnt"
                                  key={
                                    ["소계", "합계"].includes(data?.yymmdd) &&
                                    (data?.exchangeCnt ?? 0)
                                  }
                                  readonly={["소계", "합계"].includes(data?.yymmdd)}
                                  onBlur={e => {
                                    e.target.value = numberFormat(e.target.value);
                                    handleModify(e, data);
                                  }}
                                />
                              </td>
                              {/* 금일실적 - 일반실적 - 실적 */}
                              <td colSpan={isShowEtcData ? 1 : 2}>
                                <NumberInput1
                                  onClick={e => e.stopPropagation()}
                                  defaultValue={data?.resultAmnt ?? 0}
                                  key={
                                    ["소계", "합계"].includes(data?.yymmdd) &&
                                    (data?.resultAmnt ?? 0)
                                  }
                                  name="resultAmnt"
                                  readonly={["소계", "합계"].includes(data?.yymmdd)}
                                  onBlur={e => handleModify(e, data)}
                                  toFixed={2}
                                />
                              </td>

                              {isShowEtcData && (
                                <>
                                  {/* 금일실적 - 기타실적 - 건수 */}
                                  <td>
                                    <NumberInput1
                                      onClick={e => e.stopPropagation()}
                                      defaultValue={data?.etcCnt ?? 0}
                                      key={
                                        ["소계", "합계"].includes(data?.yymmdd) &&
                                        (data?.etcCnt ?? 0)
                                      }
                                      name="etcCnt"
                                      readonly={["소계", "합계"].includes(data?.yymmdd)}
                                      onBlur={e => handleModify(e, data)}
                                    />
                                  </td>
                                  {/* 금일실적 - 기타실적 - 실적 */}
                                  <td>
                                    <NumberInput1
                                      onClick={e => e.stopPropagation()}
                                      defaultValue={data?.resultEtc ?? 0}
                                      key={
                                        ["소계", "합계"].includes(data?.yymmdd) &&
                                        (data?.resultEtc ?? 0)
                                      }
                                      name="resultEtc"
                                      readonly={["소계", "합계"].includes(data?.yymmdd)}
                                      onBlur={e => handleModify(e, data)}
                                      toFixed={2}
                                    />
                                  </td>
                                </>
                              )}
                              {/* 당월합계 - 일반실적 - 건수 */}
                              <td colSpan={isShowEtcData ? 1 : 2} className="text-end">
                                {numberFormat((data.exchangeCnt ?? 0) + (data.exchangeSum ?? 0))}
                              </td>
                              {/* 당월합계 - 일반실적 - 실적 */}
                              <td colSpan={isShowEtcData ? 1 : 2} className="text-end">
                                {numberFormat(
                                  ((data.resultSum ?? 0) + (data.resultAmnt ?? 0)) / 1.1
                                )}
                              </td>
                              {isShowEtcData && (
                                <>
                                  {/* 당월합계 - 기타실적 - 건수 */}
                                  <td className="text-end">
                                    {numberFormat((data.ecntSum ?? 0) + (data.etcCnt ?? 0))}
                                  </td>
                                  {/* 당월합계 - 기타실적 - 실적 */}
                                  <td className="text-end">
                                    {numberFormat(
                                      ((data.resultEtcsum ?? 0) + (data.resultEtc ?? 0)) / 1.1
                                    )}
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={18}>조회 결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card card-main mt-0">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">미등록자</h5>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="tab1-row1-count">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setFinalAdd(addData);
                      }}
                    >
                      추가
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="overflow-auto table-container table-sticky h-333">
                  <table className="table table-bordered table-responsivex my-table mt-0">
                    <thead>
                      <tr>
                        <th style={{ width: "7%" }}>No.</th>
                        <th style={{ width: "30%" }}>소속법인</th>
                        <th style={{ width: "22%" }}>소속부서</th>
                        <th>성명</th>
                        <th>직급</th>
                        <th style={{ width: "7%" }}>선택</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unregisterList?.length > 0 ? (
                        unregisterList?.map((data, _idx) => {
                          return (
                            <tr>
                              <td className="text-center">{_idx + 1}</td>
                              <td className="text-center">{data?.dept}</td>
                              <td className="text-center">{data?.jobName}</td>
                              <td className="text-center">{data?.userName}</td>
                              <td className="text-center">{data?.authName}</td>
                              <td className="text-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onClick={e => {
                                    isChecked(e, data);
                                  }}
                                  value={e => isChecked(e, data)}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8} className="text-center">
                            조회 결과가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card card-main mt-0x">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">개인별 일자별 내역(멤버십 제외)</h5>
              </div>
              {individualPerformance ? (
                <div className="card-body">
                  <div className="overflow-auto table-container table-sticky table-sticky1 h-528">
                    <table
                      className="table table-bordered table-responsivex my-table mt-0"
                      style={{ width: "2000px" }}
                    >
                      <thead>
                        <tr>
                          <th rowSpan={2} style={{ width: "2%" }}>
                            일자
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".01"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".02"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".03"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".04"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".05"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".06"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".07"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".08"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".09"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".10"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".11"}
                          </th>
                          <th style={{ width: "8%" }} colSpan={2}>
                            {searchOption.yymmdd.slice(0, 4) + ".12"}
                          </th>
                        </tr>
                        <tr>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                          <th style={{ width: "4%" }}>건수</th>
                          <th>실적</th>
                        </tr>
                      </thead>

                      <tbody>
                        {Array(31)
                          .fill(0)
                          .map((_, _idx) => {
                            return (
                              <tr>
                                {/* 일자 */}
                                <td className="text-center">{_idx + 1}</td>
                                {/* 1월 */}
                                <td className="text-center">
                                  {individualPerformance?.JANUARY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.JANUARY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 2월 */}
                                <td className="text-center">
                                  {individualPerformance?.FEBRUARY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.FEBRUARY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 3월 */}
                                <td className="text-center">
                                  {individualPerformance?.MARCH?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.MARCH?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 4월 */}
                                <td className="text-center">
                                  {individualPerformance?.APRIL?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.APRIL?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 5월 */}
                                <td className="text-center">
                                  {individualPerformance?.MAY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.MAY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 6월 */}
                                <td className="text-center">
                                  {individualPerformance?.JUNE?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.JUNE?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 7월 */}
                                <td className="text-center">
                                  {individualPerformance?.JULY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.JULY?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 8월 */}
                                <td className="text-center">
                                  {individualPerformance?.AUGUST?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.AUGUST?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 9월 */}
                                <td className="text-center">
                                  {individualPerformance?.SEPTEMBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.SEPTEMBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 10월 */}
                                <td className="text-center">
                                  {individualPerformance?.OCTOBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.OCTOBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 11월 */}
                                <td className="text-center">
                                  {individualPerformance?.NOVEMBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.NOVEMBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                                {/* 12월 */}
                                <td className="text-center">
                                  {individualPerformance?.DECEMBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{data.exchangeCnt}</div>;
                                    }
                                  })}
                                </td>
                                <td className="text-center">
                                  {individualPerformance?.DECEMBER?.map(data => {
                                    if (Number(data.yymmdd) - 1 === _idx) {
                                      return <div>{numberFixedFormat(data.resultAmnt)}</div>;
                                    }
                                  })}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="card-body text-center">조회 결과가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IndividualPerformanceRegister;
