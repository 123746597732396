import { atom } from "jotai";
import moment from "moment";

export const searchParamInit = {
  fromDate: "",
  toDate: "",
  // fromDate: moment("2023-01-01").format("YYYY-MM-DD"),
  // toDate: moment("2023-01-31").format("YYYY-MM-DD"),
  jisa: "99",
  gubun1: "2",
  gubun2: "1",
  taxTarget: "Y",
  keyword: "",
};
export const conditionSelectInit = {
  jisa: "11",
  maedo: "1",
  singo: "1",
  printType: "S",
  isSanchul: false,
  isMembershipNo: false,
};

export const modalShowInit = {
  flag: false,
  isNewClickRow: false,
};
export const taxInit = {
  radio: "P",
  select: "P",
  down: false,
  idx: -1,
  chpWon: 0,
  sum: 0,
  contWon: 0,
  maesuWon: 0,
  gaesuWon: 0,
  famRegWon: 0,
  charge: 0,
  consultWon: 0,
  code: "",
};
export const resultInit = {
  maemaeWon: 0,
  gaesuWon: 0,
  famRegWon: 0,
  charge: 0,
  consultWon: 0,
  sum: 0,
};

export const tableLimit_atom = atom("5");
export const currentRow_atom = atom({});
export const searchParam_atom = atom(searchParamInit);
export const searchedParam_atom = atom({});
export const acquList_atom = atom([]);
export const conditionSelect_atom = atom(conditionSelectInit);

export const medoMesu_atom = atom({ cgubun: "1", ccode: "", cabout: "1" });
export const medoMesuCost_atom = atom(resultInit);
export const medoSecondInput_atom = atom({ cgubun: "1", ccode: "", cabout: "1" });

export const governGolfKeyword_atom = atom("");
export const governGolfList_atom = atom([]);
export const governCurrentRow_atom = atom({});

export const modalShow_atom = atom(modalShowInit);
export const modalTaxList_atom = atom([]);
export const modalTax_atom = atom(taxInit);
export const modalResult_atom = atom(resultInit);
export const modalGolfData_atom = atom({});
export const modalCurrentGolf_atom = atom("00");
