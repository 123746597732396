import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SidebarWork = ({ func_recentTap, data }) => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const pathName = location.pathname.split("/")[4];
  const pathName2 = location.pathname.split("/")[5];

  const btn_onClick = (name, url) => {
    func_recentTap({
      name: name,
      url: url,
    });
  };

  return (
    <div className="sidebar-wrapper">
      <ul>
        {data?.sub.reduce((acc, cur) => {
          if (cur.sub.length !== 0) {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 2] && "active"
                }
              >
                <div onClick={() => btn_onClick(cur.sub[0].pageName, cur.url)}>
                  {cur.pageName} <span></span>
                </div>

                <ul className="sidebar_subList">
                  {cur.sub.map(el => (
                    <li
                      className={
                        pathName2 === el.url.split("/")[el.url.split("/").length - 1] && "active"
                      }
                      onClick={() => btn_onClick(el.pageName, el.url)}
                    >
                      {el.pageName}
                    </li>
                  ))}
                </ul>
              </li>,
            ];
          } else {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 1] && "active"
                }
                onClick={() => btn_onClick(cur.pageName, cur.url)}
              >
                {cur.pageName}
              </li>,
            ];
          }

          return acc;
        }, [])}
      </ul>
    </div>
  );

  // return (
  //   <div className="sidebar-wrapper">
  //     <ul>
  //       <li
  //         className={pathName === "customerManage" && "active"}
  //         onClick={() => btn_onClick("고객관리", "/work/customer/customerManage")}
  //       >
  //         고객관리
  //       </li>
  //       <li className={pathName === "databaseDistributionStatus" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick(
  //               "DB분표 현황표",
  //               "/work/db/databaseDistributionStatus/databaseDistribution"
  //             )
  //           }
  //         >
  //           부서별관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "databaseDistribution" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "DB분표 현황표",
  //                 "/work/db/databaseDistributionStatus/databaseDistribution"
  //               )
  //             }
  //           >
  //             DB분표 현황표
  //           </li>
  //           <li
  //             className={pathName2 === "newDbStatus" && "active"}
  //             onClick={() =>
  //               btn_onClick("신규DB등록현황", "/work/newdb/databaseDistributionStatus/newDbStatus")
  //             }
  //           >
  //             신규DB 등록현황
  //           </li>
  //         </ul>
  //       </li>
  //       <li className={pathName === "transactionRegisterManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick(
  //               "거래내역 입력/조회",
  //               "/work/transaction/transactionRegisterManage/transactionHistory"
  //             )
  //           }
  //         >
  //           거래등록관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "transactionHistory" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "거래내역 입력/조회",
  //                 "/work/transaction/transactionRegisterManage/transactionHistory"
  //               )
  //             }
  //           >
  //             거래내역 입력/조회
  //           </li>
  //           <li
  //             className={pathName2 === "documentManage" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "거래자 보존서류 관리",
  //                 "/work/transaction/transactionRegisterManage/documentManage"
  //               )
  //             }
  //           >
  //             거래자 보존서류 관리
  //           </li>
  //         </ul>
  //       </li>
  //       <li
  //         className={pathName === "golfManage" && "active"}
  //         onClick={() => btn_onClick("골프장 정보", "/work/manageGolf/golfManage")}
  //       >
  //         골프장 정보
  //       </li>
  //       <li
  //         className={pathName === "shopMasterManage" && "active"}
  //         onClick={() => btn_onClick("샵 마스터 관리", "/work/shop/shopMasterManage")}
  //       >
  //         샵 마스터 관리
  //       </li>
  //       <li
  //         className={pathName === "sportMasterManage" && "active"}
  //         onClick={() => btn_onClick("스포츠 마스터 관리", "/work/sports/sportMasterManage")}
  //       >
  //         스포츠 마스터 관리
  //       </li>
  //       <li
  //         className={pathName === "condoMasterManage" && "active"}
  //         onClick={() => btn_onClick("콘도 마스터 관리", "/work/condo/condoMasterManage")}
  //       >
  //         콘도 마스터 관리
  //       </li>
  //       <li className={pathName === "postManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick("소봉투 / 라벨지 출력", "/work/mailBox/postManage/envelopeLabelPrinting")
  //           }
  //         >
  //           우편관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "envelopeLabelPrinting" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "소봉투 / 라벨지 출력",
  //                 "/work/mailBox/postManage/envelopeLabelPrinting"
  //               )
  //             }
  //           >
  //             소봉투 / 라벨지 출력
  //           </li>
  //           <li
  //             className={pathName2 === "postMailManage" && "active"}
  //             onClick={() => btn_onClick("등기우편 관리", "/work/mail/postManage/postMailManage")}
  //           >
  //             등기우편 관리
  //           </li>
  //           <li
  //             className={pathName2 === "registeredDeliveryHistory" && "active"}
  //             onClick={() =>
  //               btn_onClick("등기발송 내역", "/work/mailReg/postManage/registeredDeliveryHistory")
  //             }
  //           >
  //             등기발송 내역
  //           </li>
  //         </ul>
  //       </li>
  //       <li
  //         className={pathName === "transferManage" && "active"}
  //         onClick={() =>
  //           btn_onClick("양도세 계산 및 고지서 출력", "/work/transferTax/transferManage")
  //         }
  //       >
  //         양도세 계산 및 고지서 출력
  //       </li>
  //       <li
  //         className={pathName === "acquisitionTaxManage" && "active"}
  //         onClick={() =>
  //           btn_onClick("취득세 신고 및 계산서 출력", "/work/acquisitionTax/acquisitionTaxManage")
  //         }
  //       >
  //         취득세 신고 및 계산서 출력
  //       </li>
  //       <li
  //         className={pathName === "deliveryMessage" && "active"}
  //         onClick={() => btn_onClick("전달사항", "/work/info/deliveryMessage")}
  //       >
  //         전달사항
  //       </li>
  //
  //       <li
  //         className={pathName === "smsTransferHistory" && "active"}
  //         onClick={() => btn_onClick("SMS 전송내역", "/work/smsHistory/smsTransferHistory")}
  //       >
  //         SMS 전송내역
  //       </li>
  //       <li
  //         className={pathName === "dailyCall" && "active"}
  //         onClick={() => btn_onClick("일일 call 배분 현황", "/work/callDbd/dailyCall")}
  //       >
  //         일일 call 배분 현황
  //       </li>
  //     </ul>
  //   </div>
  // );
};
