// 하단 정보
import BottomTab from "./BottomTab/BottomTab";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  clickedRow_atom,
  golfData_atom,
  greenFeeData_atom,
  tableFoldState_atom,
} from "../../../../data/atom/workManage/golfManage/golfManage_atom";
import { useAtom } from "jotai";
import { Map, MapMarker, useKakaoLoader } from "react-kakao-maps-sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Modal from "../../../../components/modal/Modal";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";
import * as yup from "yup";
import GreenFeeInfo from "./GreenFeeInfo";
import Collapse from "react-bootstrap/Collapse";
import usePrintTab from "../../../../components/modal/usePrintTab";

const phoneProperty = [
  "directHp",
  "locaTel",
  "locaArs",
  "officeTel",
  "officialHp",
  "officialTel",
  "officialFax",
  "bookingHp",
  "bookingTel",
  "bookingFax",
  "cancelTel",
];
const validationErrorsInit = {
  holsu: "",
  holsuP: "",
  holsuP3: "",
  company: "",
  gaesuPersonal: "",
  gaesuJujung: "",
  gaesuCorp: "",
  gaesuRename: "",
  gaesuInherit: "",
  gaesuDonation: "",
};

const validationSchem = yup.object().shape({
  holsu: yup.number("홀수는 숫자만 입력해 주세요").required("홀수를 입력해 주세요"),
  holsuP: yup.number("홀수는 숫자만 입력해 주세요").required("홀수를 입력해 주세요"),
  holsuP3: yup.number("홀수는 숫자만 입력해 주세요").required("홀수를 입력해 주세요"),
  company: yup.string().required("골프장명을 입력해 주세요"),
  gaesuPersonal: yup
    .number("개서료는 숫자만 입력해 주세요")
    .required("개서료(정회원)을 입력해 주세요"),
  gaesuJujung: yup.number("개서료는 숫자만 입력해 주세요").required("개서료(주중)을 입력해 주세요"),
  gaesuCorp: yup.number("개서료는 숫자만 입력해 주세요").required("개서료(가족)을 입력해 주세요"),
  gaesuRename: yup
    .number("개서료는 숫자만 입력해 주세요")
    .required("개서료(지명인 변경)을 입력해 주세요"),
  gaesuInherit: yup
    .number("개서료는 숫자만 입력해 주세요")
    .required("개서료(상속)을 입력해 주세요"),
  gaesuDonation: yup
    .number("개서료는 숫자만 입력해 주세요")
    .required("개서료(증여)을 입력해 주세요"),
});

const BottomInfo = props => {
  const { refetchAllPage } = props;
  // 상태 변수 정의
  const [modalShow, setModalShow] = useState(false); // 모달 쇼 상태
  const [clickedRow] = useAtom(clickedRow_atom); // 클릭된 로우 상태
  const [golfData, setGolfData] = useAtom(golfData_atom); // 골프 데이터 상태
  const [greenFeeData, setGreenFeeData] = useAtom(greenFeeData_atom); // 골프 데이터 상태
  const [tableFoldState, setTableFoldState] = useAtom(tableFoldState_atom); // 골프장 정보 테이블 접기 상태
  const [phoneObject, setPhoneObject] = useState({}); // 전화 오브젝트 상태
  const [validationErrors, setValidationErrors] = useState({ ...validationErrorsInit });
  const [taxModal, setTaxModal] = useState(false); // 개정인지세법 모달
  const [precautionModal, setPrecautionModal] = useState(true);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const topRef = useRef();

  // 카카오 API 사용 설정
  useKakaoLoader({
    appkey: process.env.REACT_APP_KAKAO_JS_KEY,
  });
  const [coordinates, setCoordinates] = useState(null); // 좌표 상태

  // 쿼리 클라이언트 설정
  const queryClient = useQueryClient(); // 쿼리 클라이언트 훅
  const stampDutyOption = queryClient.getQueryData(["getEtccodeDw", "SD"]); // 옵션 데이터
  const bankOption = queryClient.getQueryData(["getBank"]); // 은행 옵션 데이터

  // 골프 상세 API 호출 설정
  const manageGolfGetDetail = useMutation(
    data =>
      axios.get("/api/work/manageGolf/getDetail", {
        params: { custId: data.custId },
      }),
    {
      // 성공시 콜백
      onSuccess: (res, param) => {
        let tmp = { ...res.data.result.info };
        for (let key in tmp) {
          if (tmp[key] === null) tmp[key] = "";
        }
        setGolfData({ ...tmp }); // 골프 데이터 업데이트

        let tmp2 = { ...res.data.result.greenFee };
        for (let key in tmp2) {
          if (tmp2[key] === null) tmp2[key] = "";
        }
        setGreenFeeData({ ...tmp2 });

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );

  // 골프 저장 API 호출 설정
  const manageGolfSave = useMutation(data => axios.post("/api/work/manageGolf/save", data), {
    // 성공시 콜백
    onSuccess: res => {
      setGolfData(prev => ({ ...prev, registering: false, editing: false }));
      refetchAllPage();
    },
  });

  const pdfSave = useMutation(
    data =>
      axios.post("/api/work/manageGolf/ivCorpPrint", null, {
        params: data,
        responseType: "blob",
      }),
    {
      onSuccess: (res, param) => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `${param.gubun === "P" ? "개인고객용" : "법인고객용"}자료출력_${moment().format(
              "YY_MM_DD_hh_mm"
            )}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  // 결과 테이블 클릭시 이벤트 (클릭된 골프 데이터 상세 획득)
  useEffect(() => {
    if (clickedRow && golfData?.editing) {
      if (confirm("등록 또는 수정중인 데이터가 있습니다.\n진행 하시겠습니까?"))
        manageGolfGetDetail.mutate({ custId: clickedRow.custId });
    } else if (clickedRow) manageGolfGetDetail.mutate({ custId: clickedRow.custId });
  }, [clickedRow]);

  // 전화번호 처리
  // useEffect(() => {
  //   if (golfData) {
  //     let tmp = {};
  //     for (let key of phoneProperty) {
  //       tmp[key] = ["", "", ""];
  //       if (golfData[key]) {
  //         let splited = golfData[key].split("-"); // "-"로 문자열 분리
  //         for (let idx = 0; idx < splited.length; idx++) tmp[key][idx] = splited[idx];
  //       }
  //     }
  //     setPhoneObject(tmp); // 전화번호 오브젝트 업데이트
  //   }
  // }, [
  //   golfData?.directHp,
  //   golfData?.locaTel,
  //   golfData?.locaArs,
  //   golfData?.officeTel,
  //   golfData?.officialHp,
  //   golfData?.officialTel,
  //   golfData?.officialFax,
  //   golfData?.bookingHp,
  //   golfData?.bookingTel,
  //   golfData?.bookingFax,
  //   golfData?.cancelTel,
  // ]);

  // 상태 변경 이벤트 처리함수
  const onChangeGolfData = (key, value) => {
    if (key === "outCheck") {
      setGolfData({
        ...golfData,
        [key]: value,
        outDate: value === "Y" ? moment().format("YYYY-MM-DD") : "",
        editing: true,
      });
      // } else if (key === "payAccount" || key === "ageLimit") {
    } else if (key === "ageLimit") {
      setGolfData({ ...golfData, [key]: value.replace(/[^0-9]/g, ""), editing: true });
    } else if (key.startsWith("gaesu") && !["gaesuRemark", "gaesuEtc"].includes(key)) {
      setGolfData({ ...golfData, [key]: Number(value.replace(/[^0-9]/g, "").replace(/,/g, "")) });
    } else {
      setGolfData({ ...golfData, [key]: value, editing: true });
    }
  };

  // 전화번호를 위한 상태 변경 이벤트 처리 함수
  const onChangeGolfDataForPhone = (key, value, position) => {
    let tmpArr = [...phoneObject[key]];
    tmpArr[position] = value;
    switch (position) {
      case 0:
      case 1:
      case 2:
        setPhoneObject({ ...phoneObject, [key]: tmpArr });
        setGolfData({
          ...golfData,
          [key]: `${tmpArr[0]}-${tmpArr[1]}-${tmpArr[2]}`,
          editing: true,
        });
        break;
      default:
        return;
    }
  };

  // 새 탭 열기 함수
  const openNewTab = () => {
    if (golfData.siteUrl)
      window.open("https://" + golfData.siteUrl, "_blank"); // 지정된 URL로 새 탭열기
    else alert("해당 업체의 인터넷 사이트가 존재하지 않습니다.");
  };

  // 주소변경 사항에 따른 지도 업데이트
  useEffect(() => {
    if (golfData === null) return;
    if (golfData.raddresTxt) {
      fetch(
        `https://dapi.kakao.com/v2/local/search/address?query=${encodeURIComponent(
          golfData.raddresTxt
        )}`,
        {
          headers: { Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_KEY}` },
        }
      )
        .then(response => response.json())
        .then(data => {
          const { x, y } = data.documents[0];
          setCoordinates({ lat: parseFloat(y), lng: parseFloat(x) }); // 좌표 설정
        })
        .catch(error => {});
    } else setCoordinates(null); // 좌표 초기화
  }, [golfData?.raddresTxt]);

  // 수정 버튼 클릭 이벤트 처리 함수
  const btn_update = async () => {
    try {
      await validationSchem.validate(golfData, { abortEarly: false });
      setValidationErrors({ ...validationErrorsInit });
    } catch (e) {
      let tmp = { ...validationErrorsInit };
      let str = "";
      for (let err of e.inner) {
        tmp = { ...tmp, [err.path]: err.errors[0] };
        str += err.errors[0] + "\n";
      }
      setValidationErrors(tmp);
      alert(str);
      return;
    }

    if (golfData) manageGolfSave.mutate(golfData);
  };

  // 취소 버튼 클릭 이벤트 처리 함수
  const btn_cancel = () => {
    if (golfData)
      if (clickedRow && confirm("취소하시겠습니까?")) {
        manageGolfGetDetail.mutate({ custId: clickedRow.custId, type: "cancel" });
      } else if (!clickedRow && confirm("취소하시겠습니까?")) {
        setValidationErrors({ ...validationErrorsInit });
        setGolfData(null); // golfData 상태 초기화
      }
  };

  const daumPostMemo1 = useMemo(
    () => (
      <DaumPostcode
        className={"btn btn-outline-primary btn-filter-outline-primary float-end m-0"}
        onCompleteCallback={data => {
          setGolfData(prev => ({
            ...prev,
            locaZip: "", // daum에서 더이상 구 우편주소를 주지 않음
            locaAddresTxt: data.fullAddr,
            locaAddres: "",
            rareaCode: data.zonecode,
            rzipcode: "",
            raddres: "",
            raddresTxt: data.roadAddress,
            rbdcode: data.bdCode,
            editing: true,
          }));
        }}
      />
    ),
    []
  );

  const daumPostMemo2 = useMemo(
    () => (
      <DaumPostcode
        className={"btn btn-outline-primary btn-filter-outline-primary float-end m-0"}
        onCompleteCallback={data => {
          setGolfData(prev => ({
            ...prev,
            ozipcode: data.zonecode,
            oroadAddres: data.roadAddress,
            oaddres: "",
            obdcode: data.bdCode,
            editing: true,
          }));
        }}
      />
    ),
    []
  );

  return (
    <>
      {modalShow && (
        <Modal
          show={modalShow}
          title={"지도보기"}
          onHide={() => setModalShow(false)}
          strClass={"modal-xl"}
          centered
        >
          {coordinates && (
            <div className="modal-box1" style={{ height: "600px" }}>
              <Map center={coordinates} style={{ width: "100%", height: "100%" }} level={3}>
                <MapMarker position={coordinates} />
              </Map>
            </div>
          )}
        </Modal>
      )}
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <Modal
        size="md"
        show={taxModal}
        title={"수입인지세법"}
        onHide={() => setTaxModal(false)}
        centered
      >
        <p className="p_modal">
          2010/01/01부터 골프, 콘도회원권 명의 개서시 회원권금액에 따라 아래와 같이 차등적용
        </p>

        <ul className="list_pts">
          <li>
            1천만원 초과 ~ 3천만원 이하 <span>2만원</span>
          </li>
          <li>
            3천만원 초과 ~ 5천만원 이하 <span>4만원</span>
          </li>
          <li>
            5천만원 초과 ~ 1억원 이하 <span>7만원</span>
          </li>
          <li>
            1억원 초과 ~ 10억원 이하 <span>15만원</span>
          </li>
          <li>
            10억원 초과 <span>35만원</span>
          </li>
        </ul>
      </Modal>

      <div className="card card-sub">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title  m-0">골프장 정보</h5>
          <div className="btn_group_top">
            {!golfData?.registering && (
              <button
                className="btn btn-outline-primary h-25px"
                onClick={() => pdfSave.mutate({ custId: golfData?.custId, gubun: "P" })}
              >
                개인고객용 자료출력
              </button>
            )}
            {!golfData?.registering && (
              <button
                className="btn btn-outline-primary h-25px"
                onClick={() => pdfSave.mutate({ custId: golfData?.custId, gubun: "C" })}
              >
                법인고객용 자료출력
              </button>
            )}

            <button className="btn btn-outline-primary h-25px" onClick={btn_update}>
              저장
            </button>
            <button className="btn btn-outline-primary h-25px" onClick={btn_cancel}>
              취소
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="tab1-row1-count">관리번호 : {golfData && (golfData?.custId ?? "")}</div>
            <div className="tab1-row1-field1">
              {/* <label>분류 :</label>
                <select className="form-select tab1-row1-form-select">
                    <option></option>
                </select>
                <select className="form-select tab1-row1-form-select">
                    <option></option>
                </select> */}
            </div>
          </div>

          <table className="table table-bordered table-responsive my-table1 mt-2 mb-10px">
            <tbody>
              <tr>
                <th style={{ width: "11.9%" }}>골프장명</th>
                <td style={{ width: "13.1%" }}>
                  <input
                    type="text"
                    className={`${
                      validationErrors.company !== "" ? "" : ""
                    } form-control table-input`}
                    value={golfData && (golfData?.company ?? "")}
                    onChange={e => onChangeGolfData("company", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>舊골프장명</th>
                <td style={{ width: "13.1%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.oldCompany ?? "")}
                    onChange={e => onChangeGolfData("oldCompany", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>회원증No</th>
                <td style={{ width: "13.1%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.identiNo ?? "")}
                    onChange={e => onChangeGolfData("identiNo", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>홀수(회-퍼-파3)</th>
                <td className="input-col-3">
                  <input
                    type="text"
                    className={`${
                      validationErrors.holsu !== "" ? "" : ""
                    } form-control table-input`}
                    value={golfData && (golfData?.holsu ?? "0")}
                    onChange={e => onChangeGolfData("holsu", e.target.value)}
                  />
                  {"-"}
                  <input
                    type="text"
                    className={`${
                      validationErrors.holsuP !== "" ? "" : ""
                    } form-control table-input`}
                    value={golfData && (golfData?.holsuP ?? "0")}
                    onChange={e => onChangeGolfData("holsuP", e.target.value)}
                  />
                  {"-"}
                  <input
                    type="text"
                    className={`${
                      validationErrors.holsuP3 !== "" ? "" : ""
                    } form-control table-input`}
                    value={golfData && (golfData?.holsuP3 ?? "0")}
                    onChange={e => onChangeGolfData("holsuP3", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>개장일</th>
                <td>
                  <input
                    type="date"
                    className="form-control table-input"
                    max={"9999-12-31"}
                    value={
                      golfData &&
                      golfData?.openDate &&
                      moment(golfData.openDate).format("YYYY-MM-DD")
                    }
                    onChange={e => onChangeGolfData("openDate", e.target.value)}
                  />
                </td>
                <th>총 회원수</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.memberTotal ?? "")}
                    onChange={e => onChangeGolfData("memberTotal", e.target.value)}
                  />
                </td>
                <th>총 면적</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.areaTot ?? "")}
                    onChange={e => onChangeGolfData("areaTot", e.target.value)}
                  />
                </td>
                <th>조명시설</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.lightHolsu ?? "")}
                    onChange={e => onChangeGolfData("lightHolsu", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>법인명</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.corpName ?? "")}
                    onChange={e => onChangeGolfData("corpName", e.target.value)}
                  />
                </td>
                <th>모기업</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.groupName ?? "")}
                    onChange={e => onChangeGolfData("groupName", e.target.value)}
                  />
                </td>
                <th>분류별회원수</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.memberDetail ?? "")}
                    onChange={e => onChangeGolfData("memberDetail", e.target.value)}
                  />
                </td>
                <th>연습장</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.drivingRange ?? "")}
                    onChange={e => onChangeGolfData("drivingRange", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>대표자</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.ceoKname ?? "")}
                    onChange={e => onChangeGolfData("ceoKname", e.target.value)}
                  />
                </td>
                <th>직위</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.ceoPosition ?? "")}
                    onChange={e => onChangeGolfData("ceoPosition", e.target.value)}
                  />
                </td>

                <th>부대시설</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.buildRange ?? "")}
                    onChange={e => onChangeGolfData("buildRange", e.target.value)}
                  />
                </td>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <th>책임자</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.directKname ?? "")}
                    onChange={e => onChangeGolfData("directKname", e.target.value)}
                  />
                </td>
                <th>직위</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.directPosition ?? "")}
                    onChange={e => onChangeGolfData("directPosition", e.target.value)}
                  />
                </td>
                <th>휴대폰</th>
                <td>
                  {/*<div className="flex-center-between">*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control table-input col3-dash"*/}
                  {/*    maxLength={4}*/}
                  {/*    value={phoneObject["directHp"] && phoneObject["directHp"][0]}*/}
                  {/*    onChange={e => onChangeGolfDataForPhone("directHp", e.target.value, 0)}*/}
                  {/*  />*/}
                  {/*  <span className="txt_dash">-</span>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control table-input col3-dash"*/}
                  {/*    maxLength={4}*/}
                  {/*    value={phoneObject["directHp"] && phoneObject["directHp"][1]}*/}
                  {/*    onChange={e => onChangeGolfDataForPhone("directHp", e.target.value, 1)}*/}
                  {/*  />*/}
                  {/*  <span className="txt_dash">-</span>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control table-input col3-dash"*/}
                  {/*    maxLength={12}*/}
                  {/*    value={phoneObject["directHp"] && phoneObject["directHp"][2]}*/}
                  {/*    onChange={e => onChangeGolfDataForPhone("directHp", e.target.value, 2)}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData?.directHp ?? ""}
                    onChange={e => onChangeGolfData("directHp", e.target.value, 2)}
                  />
                </td>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </table>

          <div className={`px-2 py-1 mb-2`}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="table-tabs-sub-golfManage-subtitle mb-0">소재지 및 기타 정보</div>
              <div
                aria-controls="example-collapse-text2"
                aria-expanded={tableFoldState.top}
                className="link-expand cursor-pointer"
                onClick={() =>
                  setTableFoldState({ top: !tableFoldState.top, bottom: tableFoldState.bottom })
                }
              >
                {tableFoldState.top ? "접기" : "펼치기"}
                <img
                  className={`ms-2 ${tableFoldState.top && "active"}`}
                  src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                  alt=""
                />
              </div>
            </div>

            <Collapse in={tableFoldState.top}>
              <div id="example-collapse-text2" className="mt-2">
                <table className="table table-bordered table-responsive my-table1">
                  <tbody>
                    <tr>
                      <th rowSpan={2} style={{ width: "11.9%" }}>
                        소재지
                      </th>
                      <td style={{ width: "13.1%" }} colSpan={2}>
                        <input
                          type="text"
                          className="form-control table-input col2-input-btn float-start"
                          value={golfData && (golfData?.locaZip ?? "")}
                          onChange={e => onChangeGolfData("locaZip", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                        {daumPostMemo1}
                      </td>
                      <th rowSpan={2} style={{ width: "11.9%" }}>
                        별도사무실
                      </th>
                      <td style={{ width: "13.1%" }}>
                        <input
                          type="text"
                          className="form-control table-input col2-input-btn float-start"
                          value={golfData && (golfData?.ozipcode ?? "")}
                          onChange={e => onChangeGolfData("ozipcode", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                        {daumPostMemo2}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.oroadAddres ?? "")}
                          onChange={e => onChangeGolfData("oroadAddres", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th style={{ width: "11.9%" }} rowSpan={2}>
                        골프장 대표전화
                      </th>
                      <th style={{ width: "11.9%" }}>현장</th>
                      <td>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["locaTel"] && phoneObject["locaTel"][0]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("locaTel", e.target.value, 0)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["locaTel"] && phoneObject["locaTel"][1]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("locaTel", e.target.value, 1)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["locaTel"] && phoneObject["locaTel"][2]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("locaTel", e.target.value, 2)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData?.locaTel}
                          onChange={e => onChangeGolfData("locaTel", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.locaAddres ?? "")}
                          onChange={e => onChangeGolfData("locaAddres", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <td colSpan={2}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.oaddres ?? "")}
                          onChange={e => onChangeGolfData("oaddres", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th style={{ width: "11.9%" }}>ARS</th>
                      <td>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["locaArs"] && phoneObject["locaArs"][0]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("locaArs", e.target.value, 0)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["locaArs"] && phoneObject["locaArs"][1]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("locaArs", e.target.value, 1)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["locaArs"] && phoneObject["locaArs"][2]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("locaArs", e.target.value, 2)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          maxLength={12}
                          value={golfData?.locaArs}
                          onChange={e => onChangeGolfData("locaArs", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan={2} style={{ width: "11.9%" }}>
                        소재지 도로명 주소
                      </th>
                      <td colSpan={3}>
                        <div className="d-flex float-start">
                          <input
                            type="text"
                            className="form-control table-input w-25"
                            value={golfData && (golfData?.rareaCode ?? "")}
                            onChange={e => onChangeGolfData("rareaCode", e.target.value)}
                            onClick={e => e.stopPropagation()}
                          />
                          <input
                            type="text"
                            className="form-control table-input ms-3px w-75"
                            value={golfData && (golfData?.raddresTxt ?? "")}
                            onChange={e => onChangeGolfData("raddresTxt", e.target.value)}
                            onClick={e => e.stopPropagation()}
                          />
                        </div>
                      </td>
                      <th style={{ width: "11.9%" }}>추천인</th>
                      <td colSpan={4}>
                        <select
                          className={
                            golfData && golfData?.recommandCheck === "Y"
                              ? "form-select table-select text-danger"
                              : "form-select table-select"
                          }
                          // value={
                          //   golfData &&
                          //   (golfData.recommandCheck === "" ||
                          //   golfData.recommandCheck === undefined ||
                          //   golfData.recommandCheck === null
                          //     ? "N"
                          //     : "Y")
                          // }
                          value={golfData?.recommandCheck}
                          onChange={e => onChangeGolfData("recommandCheck", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value="Y" className="text-dark">
                            추천인필요
                          </option>
                          <option value="N" className="text-dark">
                            N
                          </option>
                          <option
                            value={golfData?.recommandCheck !== "Y" ? golfData?.recommandCheck : ""}
                            hidden={true}
                          >
                            N
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.raddres ?? "")}
                          onChange={e => onChangeGolfData("raddres", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <td colSpan={5}>
                        <button
                          className="btn btn-outline-primary btn-filter-outline-primary m-0"
                          onClick={e => {
                            e.stopPropagation();
                            if (golfData?.raddresTxt) {
                              setModalShow(true);
                            } else {
                              alert("주소정보가 없습니다.");
                            }
                          }}
                        >
                          지도보기
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>인터넷</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input float-start col2-input-arrowbtn"
                          value={golfData && (golfData?.siteUrl ?? "")}
                          onChange={e => onChangeGolfData("siteUrl", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                        <a
                          href={void 0}
                          onClick={e => {
                            e.stopPropagation();
                            openNewTab();
                          }}
                        >
                          <img
                            className="h-25px float-end"
                            src={process.env.PUBLIC_URL + "/images/icon-arrow-btn.png"}
                            alt=""
                          />
                        </a>
                      </td>
                      <th>전화</th>
                      <td colSpan={4}>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officeTel"] && phoneObject["officeTel"][0]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("officeTel", e.target.value, 0)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officeTel"] && phoneObject["officeTel"][1]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("officeTel", e.target.value, 1)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["officeTel"] && phoneObject["officeTel"][2]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("officeTel", e.target.value, 2)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          maxLength={12}
                          value={golfData?.officeTel}
                          onChange={e => onChangeGolfData("officeTel", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-bordered table-responsive my-table1 mb-10px">
                  <tbody>
                    <tr>
                      <th style={{ width: "7.93%" }}>명의담당</th>
                      <td style={{ width: "8.73%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.officialKname ?? "")}
                          onChange={e => onChangeGolfData("officialKname", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th style={{ width: "7.93%" }}>직위</th>
                      <td style={{ width: "8.73%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.officialPosition ?? "")}
                          onChange={e => onChangeGolfData("officialPosition", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th style={{ width: "7.93%" }}>휴대폰</th>
                      <td style={{ width: "8.73%" }}>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officialHp"] && phoneObject["officialHp"][0]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialHp", e.target.value, 0)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officialHp"] && phoneObject["officialHp"][1]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialHp", e.target.value, 1)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["officialHp"] && phoneObject["officialHp"][2]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialHp", e.target.value, 2)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData?.officialHp}
                          onChange={e => onChangeGolfData("officialHp", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th className="f-roboto-medium" style={{ width: "7.93%" }}>
                        E-mail
                      </th>
                      <td style={{ width: "8.73%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.officialEMail ?? "")}
                          onChange={e => onChangeGolfData("officialEMail", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th style={{ width: "7.93%" }}>전화</th>
                      <td style={{ width: "8.73%" }}>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officialTel"] && phoneObject["officialTel"][0]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialTel", e.target.value, 0)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officialTel"] && phoneObject["officialTel"][1]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialTel", e.target.value, 1)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["officialTel"] && phoneObject["officialTel"][2]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialTel", e.target.value, 2)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData?.officialTel}
                          onChange={e => onChangeGolfData("officialTel", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th style={{ width: "7.93%" }}>팩스</th>
                      <td>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officialFax"] && phoneObject["officialFax"][0]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialFax", e.target.value, 0)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["officialFax"] && phoneObject["officialFax"][1]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialFax", e.target.value, 1)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["officialFax"] && phoneObject["officialFax"][2]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("officialFax", e.target.value, 2)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData?.officialFax}
                          onChange={e => onChangeGolfData("officialFax", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>예약담당</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.bookingKname ?? "")}
                          onChange={e => onChangeGolfData("bookingKname", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th>직위</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.bookingPosition ?? "")}
                          onChange={e => onChangeGolfData("bookingPosition", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th>휴대폰</th>
                      <td>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["bookingHp"] && phoneObject["bookingHp"][0]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("bookingHp", e.target.value, 0)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["bookingHp"] && phoneObject["bookingHp"][1]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("bookingHp", e.target.value, 1)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["bookingHp"] && phoneObject["bookingHp"][2]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("bookingHp", e.target.value, 2)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          maxLength={12}
                          value={golfData?.bookingHp}
                          onChange={e => onChangeGolfData("bookingHp", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th>예약전화</th>
                      <td colSpan={3}>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["bookingTel"] && phoneObject["bookingTel"][0]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("bookingTel", e.target.value, 0)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["bookingTel"] && phoneObject["bookingTel"][1]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("bookingTel", e.target.value, 1)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["bookingTel"] && phoneObject["bookingTel"][2]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("bookingTel", e.target.value, 2)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input col3-dash"
                          value={golfData?.bookingTel}
                          onChange={e => onChangeGolfData("bookingTel", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th>예약 팩스</th>
                      <td>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["bookingFax"] && phoneObject["bookingFax"][0]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("bookingFax", e.target.value, 0)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["bookingFax"] && phoneObject["bookingFax"][1]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("bookingFax", e.target.value, 1)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["bookingFax"] && phoneObject["bookingFax"][2]}*/}
                        {/*    onChange={e =>*/}
                        {/*      onChangeGolfDataForPhone("bookingFax", e.target.value, 2)*/}
                        {/*    }*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData?.bookingFax}
                          onChange={e => onChangeGolfData("bookingFax", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>예약취소 전화</th>
                      <td>
                        {/*<div className="flex-center-between">*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["cancelTel"] && phoneObject["cancelTel"][0]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("cancelTel", e.target.value, 0)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={4}*/}
                        {/*    value={phoneObject["cancelTel"] && phoneObject["cancelTel"][1]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("cancelTel", e.target.value, 1)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*  <span className="txt_dash">-</span>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control table-input col3-dash"*/}
                        {/*    maxLength={12}*/}
                        {/*    value={phoneObject["cancelTel"] && phoneObject["cancelTel"][2]}*/}
                        {/*    onChange={e => onChangeGolfDataForPhone("cancelTel", e.target.value, 2)}*/}
                        {/*    onClick={e => e.stopPropagation()}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData?.cancelTel}
                          onChange={e => onChangeGolfData("cancelTel", e.target.value, 2)}
                          // onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th>예약취소 방법</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.cancelAbout ?? "")}
                          onChange={e => onChangeGolfData("cancelAbout", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th>패널티</th>
                      <td colSpan={7}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.penalty ?? "")}
                          onChange={e => onChangeGolfData("penalty", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan={2}>부킹방법</th>
                      <th>인터넷</th>
                      <th>전화</th>
                      <th>ARS</th>
                      <th>팩스</th>
                      <th>주중 2인 PLAY</th>
                      <td colSpan={6}>
                        <select
                          className="form-select"
                          value={golfData && (golfData?.twoPlay ?? "Y")}
                          onChange={e => onChangeGolfData("twoPlay", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value={"Y"}>가능</option>
                          <option value={"N"}>불가</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <select
                          className="form-select"
                          value={golfData && (golfData?.bookingWeb ?? "Y")}
                          onChange={e => onChangeGolfData("bookingWeb", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value={"Y"}>가능</option>
                          <option value={"N"}>미정</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={golfData && (golfData?.checkTel ?? "Y")}
                          onChange={e => onChangeGolfData("checkTel", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value={"Y"}>Y</option>
                          <option value={"N"}>N</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={golfData && (golfData?.checkArs ?? "Y")}
                          onChange={e => onChangeGolfData("checkArs", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value={"Y"}>Y</option>
                          <option value={"N"}>N</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={golfData && (golfData?.checkFax ?? "Y")}
                          onChange={e => onChangeGolfData("checkFax", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value={"Y"}>Y</option>
                          <option value={"N"}>N</option>
                        </select>
                      </td>
                      <th>조건</th>
                      <td colSpan={6}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={golfData && (golfData?.twoPlayRemark ?? "")}
                          onChange={e => onChangeGolfData("twoPlayRemark", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Collapse>
          </div>

          <table className="table table-bordered table-responsive my-table1 mt-10px">
            <tbody>
              <tr>
                <th style={{ width: "11.9%" }}>나이제한</th>
                <td style={{ width: "21.43%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.ageLimit ?? "")}
                    onChange={e => onChangeGolfData("ageLimit", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>입회조건</th>
                <td style={{ width: "21.43%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.memberLimit ?? "")}
                    onChange={e => onChangeGolfData("memberLimit", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>전환관련</th>
                <td colSpan={4}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.changeOx ?? "")}
                    onChange={e => onChangeGolfData("changeOx", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>정기 휴장일</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.closeDay ?? "")}
                    onChange={e => onChangeGolfData("closeDay", e.target.value)}
                  />
                </td>
                <th>회원의 날</th>
                <td colSpan={4}>
                  <select
                    className="form-select w-200 float-start"
                    value={golfData && (golfData?.memberDayYn ?? "Y")}
                    onChange={e => onChangeGolfData("memberDayYn", e.target.value)}
                  >
                    <option value={"Y"}>Y</option>
                    <option value={"N"}>N</option>
                    <option value={"C"}>미정</option>
                  </select>
                  <input
                    type="text"
                    className="form-control table-input col2-input-select float-end"
                    value={golfData && (golfData?.memberDay ?? "")}
                    onChange={e => onChangeGolfData("memberDay", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>카트 관련</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.cartSystem ?? "")}
                    onChange={e => onChangeGolfData("cartSystem", e.target.value)}
                  />
                </td>
                <th>캐디관련</th>
                <td colSpan={4}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.caddySystem ?? "")}
                    onChange={e => onChangeGolfData("caddySystem", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>결재기간</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.approvDay ?? "")}
                    onChange={e => onChangeGolfData("approvDay", e.target.value)}
                  />
                </td>
                <th>티업간격</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.intervals ?? "")}
                    onChange={e => onChangeGolfData("intervals", e.target.value)}
                  />
                </td>
                <th>기타 라운드 정보</th>
                <td colSpan={4}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.intervalEtc ?? "")}
                    onChange={e => onChangeGolfData("intervalEtc", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table table-bordered table-responsive my-table1 mt-10px">
            <tbody>
              <tr>
                <th style={{ width: "5%" }} rowSpan={3}>
                  개서료
                </th>
                <th style={{ width: "6.9%" }}>정회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    maxLength={11}
                    value={
                      golfData?.gaesuPersonal
                        ? Number(golfData?.gaesuPersonal).toLocaleString("en-US")
                        : "0"
                    }
                    onChange={e => onChangeGolfData("gaesuPersonal", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>주중</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    maxLength={11}
                    value={
                      golfData?.gaesuJujung
                        ? Number(golfData?.gaesuJujung).toLocaleString("en-US")
                        : "0"
                    }
                    onChange={e => onChangeGolfData("gaesuJujung", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>가족등록</th>
                <td colSpan={4}>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    maxLength={11}
                    value={
                      golfData?.gaesuCorp
                        ? Number(golfData?.gaesuCorp).toLocaleString("en-US")
                        : "0"
                    }
                    onChange={e => onChangeGolfData("gaesuCorp", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>지명인 변경</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    maxLength={11}
                    value={
                      golfData?.gaesuRename
                        ? Number(golfData?.gaesuRename).toLocaleString("en-US")
                        : "0"
                    }
                    onChange={e => onChangeGolfData("gaesuRename", e.target.value)}
                  />
                </td>
                <th>상속</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    maxLength={11}
                    value={
                      golfData?.gaesuInherit
                        ? Number(golfData?.gaesuInherit).toLocaleString("en-US")
                        : "0"
                    }
                    onChange={e => onChangeGolfData("gaesuInherit", e.target.value)}
                  />
                </td>
                <th>증여</th>
                <td colSpan={4}>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    maxLength={11}
                    value={
                      golfData?.gaesuDonation
                        ? Number(golfData?.gaesuDonation).toLocaleString("en-US")
                        : "0"
                    }
                    onChange={e => onChangeGolfData("gaesuDonation", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>기타</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input "
                    maxLength={40}
                    value={golfData && (golfData?.gaesuEtc ?? "")}
                    onChange={e => onChangeGolfData("gaesuEtc", e.target.value)}
                  />
                </td>
                <th>특이사항</th>
                <td colSpan={6}>
                  <input
                    type="text"
                    className="form-control table-input"
                    maxLength={100}
                    value={golfData && (golfData?.gaesuRemark ?? "")}
                    onChange={e => onChangeGolfData("gaesuRemark", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table table-bordered table-responsive my-table1 mt-10px mb-10px">
            <tbody>
              <tr>
                <th colSpan={2} style={{ width: "11.9%" }}>
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={taxModal}
                      id="flexCheckDefault"
                      onChange={e => setTaxModal(e.target.checked)}
                    />
                    개정인지세법
                  </label>
                </th>
                <td>
                  <select
                    className="form-select w-200 float-start"
                    // value={
                    //   golfData &&
                    //   (golfData.stampDuty ?? stampDutyOption ? stampDutyOption[0].key : "")
                    // }
                    value={golfData?.stampDuty}
                    onChange={e => onChangeGolfData("stampDuty", e.target.value)}
                  >
                    {stampDutyOption?.map(item => (
                      <option value={item.key}>{item.value}</option>
                    ))}
                  </select>
                </td>
                <th style={{ width: "5%" }}>계좌</th>
                <td>
                  <select
                    className="form-select w-49 float-start"
                    // value={golfData && (golfData.payBank ?? bankOption ? bankOption[0].key : "")}
                    value={golfData?.payBank}
                    onChange={e => onChangeGolfData("payBank", e.target.value)}
                  >
                    {bankOption?.map(item => (
                      <option value={item.key}>{item.value}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="form-control table-input w-50 float-end"
                    value={golfData && (golfData?.payAccount ?? "")}
                    onChange={e => onChangeGolfData("payAccount", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>예금주</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.payName ?? "")}
                    onChange={e => onChangeGolfData("payName", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>질권설정동의</th>
                <td colSpan={4} style={{ width: "21.43%" }}>
                  <select
                    className="form-select float-start"
                    value={golfData && (golfData?.loanCheck ?? "Y")}
                    onChange={e => onChangeGolfData("loanCheck", e.target.value)}
                  >
                    <option value={"Y"}>가능</option>
                    <option value={"N"}>불가</option>
                    <option value={"C"}>미정(미확인)</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th rowSpan={2}>가족회원제도</th>
                <th style={{ width: "5%" }}>개인</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.familyTargetP ?? "")}
                    onChange={e => onChangeGolfData("familyTargetP", e.target.value)}
                  />
                </td>
                <th>비고</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.familyRemarkP ?? "")}
                    onChange={e => onChangeGolfData("familyRemarkP", e.target.value)}
                  />
                </td>
                <th>무기명회원권</th>
                <td>
                  <select
                    className="form-select float-start"
                    value={golfData && (golfData?.notNameSys ?? "Y")}
                    onChange={e => onChangeGolfData("notNameSys", e.target.value)}
                  >
                    <option value={"Y"}>있다</option>
                    <option value={"N"}>없다</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th style={{ width: "5%" }}>법인</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.familyTargetC ?? "")}
                    onChange={e => onChangeGolfData("familyTargetC", e.target.value)}
                  />
                </td>
                <th>비고</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={golfData && (golfData?.familyRemarkC ?? "")}
                    onChange={e => onChangeGolfData("familyRemarkC", e.target.value)}
                  />
                </td>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </table>

          <div className={`px-2 py-1 mb-2`}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="table-tabs-sub-golfManage-subtitle mb-0">
                제명여부 / 취득 • 양도세 신고 / Web View / 특징 및 전망
              </div>
              <div
                className="link-expand cursor-pointer"
                aria-controls="example-collapse-text"
                aria-expanded={tableFoldState.bottom}
                onClick={() =>
                  setTableFoldState({ top: tableFoldState.top, bottom: !tableFoldState.bottom })
                }
              >
                {tableFoldState.bottom ? "접기" : "펼치기"}
                <img
                  className={`ms-2 ${tableFoldState.bottom && "active"}`}
                  src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                  alt=""
                />
              </div>
            </div>

            <Collapse in={tableFoldState.bottom}>
              <div id="example-collapse-text">
                <table className="table table-bordered table-responsive my-table1 mt-10px">
                  <tbody>
                    <tr>
                      <th>
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault1"
                          onClick={e => e.stopPropagation()}
                        >
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            checked={golfData && golfData?.outCheck === "Y"}
                            onChange={e => {
                              e.stopPropagation();
                              onChangeGolfData("outCheck", e.target.checked ? "Y" : "N");
                            }}
                            id="flexCheckDefault1"
                          />
                          제명여부
                        </label>
                      </th>
                      <td>
                        <input
                          type="date"
                          className="form-control table-input"
                          max={"9999-12-31"}
                          value={
                            golfData?.outDate ? moment(golfData?.outDate).format("YYYY-MM-DD") : ""
                          }
                          onChange={e => onChangeGolfData("outDate", e.target.value)}
                          onClick={e => e.stopPropagation()}
                          readOnly={golfData && golfData?.outCheck !== "Y"}
                        />
                      </td>
                      <th style={{ width: "11.9%" }}>취득/양도세 신고</th>
                      <td style={{ width: "11%" }}>
                        <select
                          className="form-select"
                          value={golfData && (golfData?.shingoTarget ?? "Y")}
                          onChange={e => onChangeGolfData("shingoTarget", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value={"Y"}>대상</option>
                          <option value={"N"}>비대상</option>
                          <option value={"S"}>일부 비대상</option>
                        </select>
                      </td>
                      <th>WEB_VIEW</th>
                      <td>
                        <select
                          className="form-select float-start"
                          value={golfData && (golfData?.webViewChk ?? "Y")}
                          onChange={e => onChangeGolfData("webViewChk", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        >
                          <option value={"Y"}>Y</option>
                          <option value={"N"}>N</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-bordered table-responsive my-table1 mt-10px mb-10px">
                  <tbody>
                    <tr>
                      <th style={{ width: "11.9%" }}>특징 및 전망</th>
                      <td colSpan={7}>
                        <textarea
                          className="form-control table-input my-1"
                          rows={5}
                          value={golfData?.visionRemark ?? ""}
                          onChange={e => onChangeGolfData("visionRemark", e.target.value)}
                          onClick={e => e.stopPropagation()}
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Collapse>
          </div>

          <GreenFeeInfo />

          {!golfData?.registering && <BottomTab custId={golfData && (golfData?.custId ?? "")} />}
        </div>
      </div>
    </>
  );
};

export default BottomInfo;
