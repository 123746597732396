import React, { useEffect, useState } from "react";
import { axios } from "../../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";

export default function PersonYunchaTab({ sawonId, userId }) {
  const defaultURL = "";
  const [data, setData] = useState([]);
  const initialModifyData = {
    bigo: "",
    gubun: "",
    modDate: "",
    modId: "",
    rpDetail: "",
    sawonId: sawonId,
    seqNo: 0,
    teamGu: "",
    userId: userId,
    yearMnth: "",
  };

  // API - 자격/면허증 조회
  const { mutate: mutate_personYuncha, isLoading: getLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getYuncha", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        setData(result);
      },
    }
  );

  useEffect(() => {
    mutate_personYuncha(sawonId);
  }, []);
  return (
    <div className="px-20px py-15px">
      <div className={`table-container table-sticky h-183`}>
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "1%" }} rowSpan={2}>
                No.
              </th>
              <th style={{ width: "3%" }} rowSpan={2}>
                시작기간
              </th>
              <th style={{ width: "3%" }} rowSpan={2}>
                종료기간
              </th>
              <th style={{ width: "3%" }} rowSpan={2}>
                사용계
              </th>
              <th style={{ width: "3%", borderBottom: "1px solid #707070" }} colSpan={8}>
                연차종류
              </th>
            </tr>
            <tr>
              <th style={{ width: "3%" }}>단체휴무</th>
              <th style={{ width: "3%" }}>병가</th>
              <th style={{ width: "3%" }}>반차(조퇴)</th>
              <th style={{ width: "3%" }}>사용촉진</th>
              <th style={{ width: "3%" }}>연차(기타)</th>
              <th style={{ width: "3%" }}>연차(법정)</th>
              <th style={{ width: "3%" }}>연차(무급)</th>
              <th style={{ width: "3%" }}>결근</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <>
                {data.map((YunchData, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <div>{moment(YunchData?.fromDate).format("YYYY-MM-DD")}</div>
                    </td>
                    <td>
                      <div>{moment(YunchData?.toxxDate).format("YYYY-MM-DD")}</div>
                    </td>
                    <td>
                      <div>{YunchData?.yunchaSum}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt11}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt12}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt13}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt14}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt15}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt16}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt17}</div>
                    </td>
                    <td>
                      <div>{YunchData?.ycCnt21}</div>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2}></td>
                  <td>총계</td>
                  <td>
                    {data
                      .reduce((acc, cur) => {
                        return acc + Number(cur?.yunchaSum);
                      }, 0)
                      .toFixed(3)}
                  </td>
                  <td colSpan={8}></td>
                </tr>
              </>
            ) : (
              <tr className="cursor-pointer" onClick={() => {}}>
                <td colSpan={12}>조회된 데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
