import EmpModal from "../../../../../components/modal/Modal";
import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import React, { useState } from "react";
import moment from "moment";

export const SideGolfIssueModal = ({ isSideGolfIssueModalOpen, setIsSideGolfIssueModalOpen }) => {
  const [tap, setTap] = useState(1); // 1 === 상세정보, 2 === 통화내역

  const customerGetGolfIssueDetail = useQuery(["customerGetGolfIssueDetail"], () =>
    axios
      .get("/api/work/customer/right/getGolfIssueDetail", {
        params: {
          ilNo: isSideGolfIssueModalOpen.data.ilNo,
          custId: isSideGolfIssueModalOpen.data.custId,
        },
      })
      .then(res => res.data.result)
  );


  return (
    <EmpModal
      show={isSideGolfIssueModalOpen.isOpen}
      width="98.5%"
      minWidth="475px"
      title="골프장 특이사항"
      onHide={() => setIsSideGolfIssueModalOpen({ isOpen: false, data: null })}
      strClass="modal-xl"
      size="xl"
    >
      <div className="d-flex">
        <button
          className="btn btn-outline-primary tab1-row1-btn"
          onClick={() => setTap(1)}
          style={{ marginLeft: "0" }}
        >
          세부내역
        </button>
        <button className="btn btn-outline-primary tab1-row1-btn" onClick={() => setTap(2)}>
          통화내역
        </button>
      </div>

      {tap === 1 && (
        <div>
          <textarea
            className="w-100 mt-2"
            value={customerGetGolfIssueDetail.data?.detail.detail}
            readOnly={true}
          />
        </div>
      )}
      {tap === 2 && (
        <div className="table-container table-sticky h-333 mt-2">
          <table className="table table-bordered table-responsive my-table mt-0">
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>통화 일자</th>
                <th>통화자</th>
                <th>구분</th>
                <th>통화 내용</th>
              </tr>
            </thead>
            <tbody>
              {customerGetGolfIssueDetail.data.talkingList.length !== 0 ? (
                customerGetGolfIssueDetail.data.talkingList.map(el => (
                  <tr>
                    <td>{el?.talkDate ? moment(el.talkDate).format("YYYY-DD-MM HH:mm") : ""}</td>
                    <td>{el.talkName}</td>
                    <td>
                      {el.viewChk === "1"
                        ? "일반"
                        : el.viewChk === "2"
                        ? "상위"
                        : el.viewChk === "0" && "삭제"}
                    </td>
                    <td>{el.remark}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>통화내역이 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </EmpModal>
  );
};
