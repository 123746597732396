import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../../util/encrypt/encrypt";
import { decrypt } from "../../util/decrypt/decrypt";
import { useAtom } from "jotai";
import { currentSidebar_atom } from "../../data/atom/currentSidebar_atom";
import { EditProfile } from "../../pages/initial/editProfile";

export const Header = ({ data, setIsSidebarFolding }) => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);

  const [sidebarMenuAtom, setSidebarMenuAtom] = useAtom(currentSidebar_atom);

  const btn_onClick = data => {
    setSidebarMenuAtom({ sidebarMenu: data });
    setIsSidebarFolding(true);
  };

  const btn_hamburger = () => {
    setIsSidebarFolding(prev => !prev);
  };

  const btn_logout = () => {
    if (window.confirm("로그아웃을 하시겠습니까?")) {
      sessionStorage.clear();
      navigate(defaultURL + "/");
      window.location.reload();
    }
  };

  return (
    <>
      {isEditProfileModalOpen && (
        <EditProfile state={setIsEditProfileModalOpen} setState={setIsEditProfileModalOpen} />
      )}

      <div className="header-outer">
        <div className="logo-wrapper">
          <img
            className="cursor-pointer"
            src={process.env.PUBLIC_URL + "/images/hamburger.png"}
            alt=""
            onClick={btn_hamburger}
          />
          <img src={process.env.PUBLIC_URL + "/images/donga-logo.png"} alt="" />
        </div>
        <div className="mainmenu-wrapper">
          <ul className="mainmenu">
            {/* {data?.reduce((acc, cur) => {
              if (cur.pageName === "코드관리") {
                if (decrypt("userInfo").userPermit === "99") {
                  return [
                    ...acc,
                    <li
                      key={cur.url}
                      className={sidebarMenuAtom?.sidebarMenu === cur.url && "active"}
                    >
                      <a onClick={() => btn_onClick(cur.url)}>{cur.pageName}</a>
                    </li>,
                  ];
                }
              } else {
                if (cur.sub.length !== 0) {
                  acc = [
                    ...acc,
                    <li
                      key={cur.url}
                      className={sidebarMenuAtom?.sidebarMenu === cur.url && "active"}
                    >
                      <a onClick={() => btn_onClick(cur.url)}>{cur.pageName}</a>
                    </li>,
                  ];
                }
              }

              return acc;
            }, [])} */}

            {data?.map(el => (
              <li key={el.url} className={sidebarMenuAtom?.sidebarMenu === el.url && "active"}>
                <a onClick={() => btn_onClick(el.url)}>{el.pageName}</a>
              </li>
            ))}
          </ul>
          <div className="mainmenu-userinfo">
            <img
              className="me-3 cursor-pointer"
              src={process.env.PUBLIC_URL + "/images/account_circle_black_24dp.svg"}
              onClick={() => setIsEditProfileModalOpen(true)}
            />
            <div>
              <div>{decrypt("userInfo")?.userName}</div>
              <div onClick={btn_logout}>로그아웃</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
