import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom, useAtomValue } from "jotai";
import {
  condoMemberList_atom,
  currentListType_atom,
  editedRowList_atom,
  searchedParam_atom,
  searchParam_atom,
} from "../../../data/atom/dataManage/condoPrice/condoPrice_atom";
import ModalRegister from "./modal/ModalRegister";
import moment from "moment";
import { pdfjs } from "react-pdf";
import usePrintTab from "../../../components/modal/usePrintTab";
import { decrypt } from "../../../util/decrypt/decrypt";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const tableButtonPanel = props => {
  const { searchCondoMember } = props;
  const [condoMemberList, setCondoMemberList] = useAtom(condoMemberList_atom);
  const [editedRowList, setEditedRowList] = useAtom(editedRowList_atom);
  const searchParam = useAtomValue(searchParam_atom);
  const searchedParam = useAtomValue(searchedParam_atom);
  const [currentListType, setCurrentListType] = useAtom(currentListType_atom);
  const [registerModalShow, setRegisterModalShow] = useState(false);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const searchModifiedCondoMember = useMutation(
    data =>
      axios.get("/api/data/condoMember/getmodify", {
        params: data,
      }),
    {
      onSuccess: res => {
        const list = [];
        res.data.result.map(el => {
          list.push({ ...el, originalGubun11: el.gubun11, originalSise11: el.sise11 });
        });

        setCurrentListType(false);
        setEditedRowList([]);
        setCondoMemberList(list);
      },
    }
  );

  const updateCondoMemberList = useMutation(
    data => axios.post("/api/data/condoMember/save", data),
    {
      onSuccess: (res, variables) => {
        if (currentListType) {
          if (confirm("오늘의 시세를 저장하시겠습니까?")) {
            saveTodayCondoMemberList.mutate(variables);
          }
          searchCondoMember.mutate(searchParam);
        } else {
          if (confirm("오늘의 시세를 저장하시겠습니까?")) {
            saveTodayCondoMemberList.mutate(variables);
          }
          searchModifiedCondoMember.mutate(searchParam);
        }
      },
    }
  );

  const saveTodayCondoMemberList = useMutation(
    data => axios.post("/api/data/condoMember/saveToday", data),
    {
      onSuccess: res => {},
    }
  );

  const printSise = useMutation(
    data => axios.get("/api/data/condoMember/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `콘도_회원권_시세정보_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const transferWeb = useMutation(data => axios.post("/api/data/condoMember/saveDB", data), {
    onSuccess: res => {
      alert(res.data.msg);
    },
  });

  const btnSise = () => {
    if (condoMemberList.length === 0) {
      alert("출력할 내역이 없습니다.");
    } else {
      printSise.mutate(searchedParam);
    }
  };

  const btnSearchModified = () => {
    searchModifiedCondoMember.mutate(searchParam);
  };

  const btnDataTransfer = () => {
    let info = decrypt("userInfo");
    if (info.userPermit !== "99" && (info.siseProcess !== "Y" || info.userPermit !== "88")) {
      alert("담당자外에는 사용할 수 없습니다");
      return;
    }

    if (confirm("웹 서버로 이관 하시겠습니까?")) {
      if (condoMemberList.length !== 0) {
        transferWeb.mutate([searchParam]);
      } else {
        alert("이관할 내역이 없습니다.");
      }
    } else {
      alert("취소되었습니다.");
    }
  };

  const btnSave = () => {
    let info = decrypt("userInfo");
    if (info.userPermit !== "99" && (info.siseProcess !== "Y" || info.userPermit !== "88")) {
      alert("담당자外에는 사용할 수 없습니다");
      return;
    }

    if (confirm("저장하시겠습니까?")) {
      if (condoMemberList.length === 0) {
        alert("검색된 결과가 없습니다.");
        return;
      }

      const list = condoMemberList.map(el => {
        const tmp = editedRowList.filter(
          el2 => el2.custId === el.custId && el2.code === el.code && el2.siseDate === el.siseDate
        );
        if (tmp.length !== 0) {
          return {
            ...tmp[0],
            modDate: moment(el.modDate).format("YYYY-MM-DD HH:mm:ss"),
            siseDate: moment(el.siseDate).format("YYYY-MM-DD"),
          };
        } else {
          return {
            ...el,
            modDate: moment(el.modDate).format("YYYY-MM-DD HH:mm:ss"),
            siseDate: moment(el.siseDate).format("YYYY-MM-DD"),
          };
        }
      });
      updateCondoMemberList.mutate(list);
    }
  };

  const btnRegister = () => {
    setRegisterModalShow(true);
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <ModalRegister show={registerModalShow} onHide={() => setRegisterModalShow(false)} />

      <h5 className="card-title  m-0">회원권 시세 정보</h5>
      <div className="d-flex align-items-center justify-content-end">
        <button className="btn btn-outline-primary" onClick={btnSise}>
          시세표
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btnSearchModified}>
          수정/변동종목 조회
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btnDataTransfer}>
          이관
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
          저장
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btnRegister}>
          신규종목 등록
        </button>
      </div>
    </>
  );
};

export default React.memo(tableButtonPanel);
