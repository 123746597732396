import React, { useEffect, useState } from "react";
import { useAtom, useSetAtom } from "jotai/index";
import {
  detailFlag_atom,
  quotationMain_atom,
  quotationMainUserOption_atom,
  quotationSubList_atom,
  registerFlag_atom,
  searchParam_atom,
  searchParamInit,
} from "../../../data/atom/dataManage/quotationManagement/quotationManagement_atom";
import moment from "moment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import usePrintTab from "../../../components/modal/usePrintTab";
import Select from "react-select-virtualized";
import { decrypt } from "../../../util/decrypt/decrypt";

const QuotationMain = props => {
  const { searchQuotationList, searchQuotationMain, searchQuotationMainSpartnerChange } = props;
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const setDetailFlag = useSetAtom(detailFlag_atom);
  const [registerFlag, setRegisterFlag] = useAtom(registerFlag_atom);
  //const [userOption, setUserOption] = useAtom(userOption_atom);
  //const [accountOption, setAccountOption] = useAtom(accountOption_atom);
  const [quotationMainUserOption, setQuotationMainUserOption] = useAtom(
    quotationMainUserOption_atom
  );
  //const [quotationList, setQuotationList] = useAtom(quotationList_atom);
  //const [quotationListLimit, setQuotationListLimit] = useAtom(quotationListLimit_atom);
  const [quotationMain, setQuotationMain] = useAtom(quotationMain_atom);
  const setQuotationSubList = useSetAtom(quotationSubList_atom);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const queryClient = useQueryClient();
  const getBank = queryClient.getQueryData(["getBank"]);
  const [selectOptions, setSelectOptions] = useState([]);

  const saveQuotationMain = useMutation(data => axios.post("/api/data/estimate/save", data), {
    onSuccess: (res, variables) => {
      const data = res.data.result;

      if (registerFlag) {
        setRegisterFlag(false);
      }

      setSearchParam(searchParamInit);
      searchQuotationList.mutate(searchParamInit);
      searchQuotationMain.mutate({
        estimaNo: data.estimaNo,
        ilNo: data.ilNo,
        spartner: data.spartner,
      });
    },
  });

  const deleteQuotationMain = useMutation(data => axios.post("/api/data/estimate/delete", data), {
    onSuccess: res => {
      searchQuotationList.mutate(searchParam);
      setDetailFlag(false);
      setQuotationMain({});
      setQuotationSubList([]);
    },
  });

  const copyQuotationMain = useMutation(data => axios.post("/api/data/estimate/savePatch", data), {
    onSuccess: res => {
      searchQuotationList.mutate(searchParam);
    },
  });

  const printQuotationMain = useMutation(
    [],
    data => axios.get("/api/data/estimate/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `견적서_내역_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const estimateSearchCustId = useMutation(
    data => axios.get("/api/data/estimate/searchCustId", { params: { keyword: data } }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;
        setSelectOptions(
          data.map(el => ({ ...el, value: el.custId, label: `${el.company}(${el.kname})` }))
        );
      },
    }
  );

  const onChangeEventHandler = (key, value, option) => {
    if (key === "custId") {
      value = value.replace(/[^0-9]/g, "").replace(/^(\d{4})(\d{4})$/, `$1-$2`);
    } else if (key === "ctel" || key === "cellular" || key === "sctel") {
      value = value.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    } else if (key === "cfax") {
      if (value.length <= 7) {
        value = value.replace(/[^0-9]/g, "").replace(/^(\d{3})(\d{4})$/, `$1-$2`);
      } else {
        value = value.replace(/[^0-9]/g, "").replace(/^(\d{3})(\d{3})(\d{4})$/, `$1-$2-$3`);
      }
    } else if (key === "etcCall1" || key === "etcCall2" || key === "etcCall3") {
      if (value) {
        value = "Y";
      } else {
        value = "N";
      }
    }

    if (key === "spartner") {
      searchQuotationMainSpartnerChange.mutate({
        userId: value,
        sjisa: quotationMainUserOption.find(el => el.spartner === value).sjisa,
      });
    }

    let tmpQuotationMain = { ...quotationMain, [key]: value };

    if (key === "seqNo") {
      // selectOptions 의 0 인덱스가 갖고 있는 프로퍼티의 갯수가 2개 이상이면 API 통해 조회된 것
      if (option && Object.keys(option).length > 2) {
        tmpQuotationMain.company = option.company;
        tmpQuotationMain.ctel = option.ctel;
        tmpQuotationMain.position = option.position;
        tmpQuotationMain.cellular = option.cellular;
        tmpQuotationMain.cfax = option.cfax;
        tmpQuotationMain.kname = option.kname;
        tmpQuotationMain.email = option.email;
      }
    }

    setQuotationMain(tmpQuotationMain);
  };

  const btnRegisterCancel = () => {
    if (confirm("등록을 취소하시겠습니까?")) {
      setDetailFlag(false);
      setRegisterFlag(false);
      setQuotationMain({});
      setQuotationSubList([]);
    }
  };
  const btnPrint = () => {
    printQuotationMain.mutate({ estimaNo: quotationMain.estimaNo });
  };
  const btnCopy = () => {
    if (confirm("복사하시겠습니까?")) {
      copyQuotationMain.mutate(quotationMain);
    }
  };
  const btnDelete = () => {
    if (confirm("삭제하시겠습니까?")) {
      deleteQuotationMain.mutate(quotationMain);
    }
  };

  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (moment().diff(moment(quotationMain.currDate), "days") > 7) {
        alert("견적일자는 7일 이내로 등록해주세요");
        return;
      }

      if (quotationMain.spartner === "") {
        alert("담당자를 선택해주세요");
        return;
      }

      saveQuotationMain.mutate({
        ...quotationMain,
        sjisa: quotationMainUserOption.find(el => el.spartner === quotationMain.spartner)?.sjisa,
        update: registerFlag ? "N" : "Y",
        userId: quotationMain.spartner,
        currDate: moment(quotationMain.currDate).format("YYYY-MM-DD"),
      });
    }
  };

  // 관리번호 셀렉트박스
  useEffect(() => {
    if (quotationMain.seqNo) {
      setSelectOptions([
        {
          value: quotationMain.seqNo,
          label: `${quotationMain.seqNo.substring(0, 4)}-${quotationMain.seqNo.substring(4)}`,
        },
      ]);
    }
  }, [quotationMain.seqNo]);

  // 담당자 없는 경우 ID 그대로 보이게 option 추가
  const findPartner = () => {
    let partner = quotationMainUserOption.find(el => el.spartner === quotationMain.spartner);
    if (!partner) return <option value={quotationMain.spartner}>{quotationMain.spartner}</option>;
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <>
        <div className="card card-main">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="tab1-row1-count w-50">견적 상세정보</div>
              {registerFlag ? (
                <>
                  <div className="tab1-row1-field1 w-50 justify-content-end">
                    <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
                      저장
                    </button>
                    <button className="btn btn-outline-primary ms-8px" onClick={btnRegisterCancel}>
                      취소
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="tab1-row1-field1 w-50 justify-content-end">
                    <button className="btn btn-outline-primary ms-8px" onClick={btnPrint}>
                      견적서 내역 출력
                    </button>
                    <button className="btn btn-outline-primary ms-8px" onClick={btnCopy}>
                      복사
                    </button>
                    <button className="btn btn-outline-primary ms-8px" onClick={btnDelete}>
                      삭제
                    </button>
                    <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
                      저장
                    </button>
                  </div>
                </>
              )}
            </div>
            <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
              <tbody>
                <tr>
                  <th>관리번호</th>
                  <td>
                    <Select
                      className="select-virtual text-left"
                      menuPlacement={"auto"}
                      menuPosition={"fixed"}
                      options={selectOptions}
                      value={
                        quotationMain.seqNo
                          ? {
                              value: quotationMain.seqNo,
                              label: `${quotationMain.seqNo.substring(
                                0,
                                4
                              )}-${quotationMain.seqNo.substring(4)}`,
                            }
                          : ""
                      }
                      onChange={e => {
                        if (e) onChangeEventHandler("seqNo", e.value, e);
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && window.selectOnKeyDwon) {
                          delete window.selectOnKeyDwon;
                          estimateSearchCustId.mutate(e.target.value);
                        } else window.selectOnKeyDwon = true;
                      }}
                    />
                    {/*<input
                      type="text"
                      className="form-control table-input"
                      maxLength={9}
                      value={quotationMain.seqNo ? quotationMain.seqNo : ""}
                      onChange={e => {
                        onChangeEventHandler("seqNo", e.target.value);
                      }}
                    />*/}
                  </td>
                  <th style={{ width: "8%" }}>업체명</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.company ? quotationMain.company : ""}
                      onChange={e => {
                        onChangeEventHandler("company", e.target.value);
                      }}
                    />
                  </td>
                  <th>전화번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      maxLength={13}
                      value={quotationMain.ctel ? quotationMain.ctel : ""}
                      onChange={e => {
                        onChangeEventHandler("ctel", e.target.value);
                      }}
                    />
                  </td>
                  <th>직 &nbsp;위</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.position ? quotationMain.position : ""}
                      onChange={e => {
                        onChangeEventHandler("position", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>견적일자</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input border-0"
                      value={
                        !registerFlag
                          ? quotationMain.currDate
                            ? moment(quotationMain.currDate).format("YYYY/MM/DD")
                            : ""
                          : quotationMain.currDate
                      }
                      readOnly={true}
                    />
                  </td>
                  <th>견적번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input border-0"
                      value={
                        quotationMain.estimaNo
                          ? `${quotationMain.estimaNo.substring(
                              0,
                              2
                            )}-${quotationMain.estimaNo.substring(
                              2,
                              6
                            )}-${quotationMain.estimaNo.substring(6)}`
                          : ""
                      }
                      readOnly={true}
                    />
                  </td>
                  <th>휴대폰</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      maxLength={13}
                      value={quotationMain.cellular ? quotationMain.cellular : ""}
                      onChange={e => {
                        onChangeEventHandler("cellular", e.target.value);
                      }}
                    />
                  </td>
                  <th>팩스번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      maxLength={12}
                      value={quotationMain.cfax ? quotationMain.cfax : ""}
                      onChange={e => {
                        onChangeEventHandler("cfax", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>성 &nbsp;명</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.kname ? quotationMain.kname : ""}
                      onChange={e => {
                        onChangeEventHandler("kname", e.target.value);
                      }}
                    />
                  </td>
                  <th>이메일</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.email ? quotationMain.email : ""}
                      onChange={e => {
                        onChangeEventHandler("email", e.target.value);
                      }}
                    />
                  </td>
                  <th colSpan={4}></th>
                </tr>

                <tr>
                  <th>담당자</th>
                  <td>
                    <select
                      className="form-select"
                      value={quotationMain.spartner}
                      onChange={e => onChangeEventHandler("spartner", e.target.value)}
                      disabled={decrypt("userInfo").userPermit !== "99"}
                    >
                      <option value="" hidden={true}></option>
                      {quotationMainUserOption.length !== 0 && findPartner()}
                      {quotationMainUserOption.length !== 0 ? (
                        quotationMainUserOption.map(el => {
                          return <option value={el.spartner}>{el.uuuser}</option>;
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                  </td>
                  <th>은행</th>
                  <td className="flex-center-between border-0">
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        quotationMain.spayBank
                          ? getBank?.find(el => el.key === quotationMain.spayBank)?.value
                          : ""
                      }
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                  <td colSpan={4}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.payAccount ? quotationMain.payAccount : ""}
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                </tr>

                <tr>
                  <th>업체명</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.scompany ? quotationMain.scompany : ""}
                      onChange={e => onChangeEventHandler("scompany", e.target.value)}
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>

                  <th>계좌명</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.payName ? quotationMain.payName : ""}
                      onChange={e => onChangeEventHandler("payName", e.target.value)}
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                  <th>전화번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.sctel ? quotationMain.sctel : ""}
                      onChange={e => onChangeEventHandler("sctel", e.target.value)}
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                  <th>직 &nbsp;위</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.sauthority ? quotationMain.sauthority : ""}
                      onChange={e => onChangeEventHandler("sauthority", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>출력시 포함 유무</th>
                  <td colSpan={3}>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label" htmlFor="inlineCheckbox1">
                        시세 변동 가능 문구
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        checked={quotationMain.etcCall1 === "Y"}
                        onChange={e => onChangeEventHandler("etcCall1", e.target.checked)}
                      />
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label" htmlFor="inlineCheckbox2">
                        법인 VAT 문구
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        checked={quotationMain.etcCall2 === "Y"}
                        onChange={e => onChangeEventHandler("etcCall2", e.target.checked)}
                      />
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label" htmlFor="inlineCheckbox3">
                        입금계좌표기 문구
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox3"
                        checked={quotationMain.etcCall3 === "Y"}
                        onChange={e => onChangeEventHandler("etcCall3", e.target.checked)}
                      />
                    </div>
                  </td>
                  <th>휴대폰</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.scellular ? quotationMain.scellular : ""}
                      onChange={e => onChangeEventHandler("scellular", e.target.value)}
                    />
                  </td>
                  <th>팩스번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.scfax ? quotationMain.scfax : ""}
                      onChange={e => onChangeEventHandler("scfax", e.target.value)}
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td colSpan={7}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={quotationMain.hapAddres ? quotationMain.hapAddres : ""}
                      onChange={e => onChangeEventHandler("hapAddres", e.target.value)}
                      style={{ border: "0" }}
                      readOnly={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    </>
  );
};

export default React.memo(QuotationMain);
