import React, { useEffect, useState, useRef } from "react";
import { useAtom } from "jotai";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  membershipFeeManage_dNaHistory,
  membershipFeeManage_detail_sawonId,
  membershipFeeManage_modifyData,
  membershipFeeManage_newData,
  membershipFeeManage_option,
  membershipFeeManage_personal_saveData,
  membershipFeeManage_row,
  membershipFeeManage_saveData,
  membershipFeeManage_sawon_detail,
  membershipFeeManage_searchOption,
  membershipFeeManage_search_data,
  membershipFeeManage_selectDetail,
  membershipFeeManage_selectedMember,
  membershipFeeManage_tab,
} from "../../../../data/atom/personManage/prematureManage/membershipFeeManage/membershipFeeManage_atom";
import { useGetWork } from "../../../../api/code/useGetWork";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";
import { useGetBeneGubun } from "../../../../api/code/useGetBeneGubun";
import { useGetBeneType } from "../../../../api/code/useGetBeneType";
import EmpModal from "../../../../components/modal/Modal";
import ProvisionsPaymentCondolenceMoney from "./provisionsPaymentCondolenceMoney";
import useDidMountEffect from "../../../../util/useDidMount/useDidMountEffect";
import { decrypt } from "../../../../util/decrypt/decrypt";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";

const MembershipFeeManage = () => {
  const initialSearchOption = {
    searchType: "1",
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    gubun: "",
    keyWord: "",
    page: 1,
    subject: "",
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  };
  const [searchOption, setSearchOption] = useAtom(membershipFeeManage_searchOption);
  const [key1, setKey1] = useAtom(membershipFeeManage_tab);
  const [searchData, setSearchData] = useAtom(membershipFeeManage_search_data);
  const [searchListNumber, setSearchListNumber] = useAtom(membershipFeeManage_row);
  const [detailData, setDetailData] = useAtom(membershipFeeManage_sawon_detail); // benefitList, familyList, sawonInfo
  const [modifyData, setModifyData] = useAtom(membershipFeeManage_modifyData);
  const [insertData, setInsertData] = useAtom(membershipFeeManage_newData);
  const [saveData, setSaveData] = useAtom(membershipFeeManage_saveData);
  const [personalSaveData, setPersonalSaveData] = useAtom(membershipFeeManage_personal_saveData);
  const [selectOptionType, setSelectOptionType] = useAtom(membershipFeeManage_option);
  const [detailSawonId, setDetailSawonId] = useAtom(membershipFeeManage_detail_sawonId);
  const [searchHistory, setSearchHistory] = useAtom(membershipFeeManage_dNaHistory);
  const [selectedMember, setSelectedMember] = useAtom(membershipFeeManage_selectedMember);
  const [isOpen, setIsOpen] = useState(false);
  const [bottom, setBottom] = useState(null);
  const bottomObserver = useRef(null);
  const { data: gubunData } = useGetWork();
  const { data: beneGubunData } = useGetBeneGubun();
  const { data: beneTypeData } = useGetBeneType();
  const queryClient = useQueryClient();
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        searchType: "1",
        fromDate: moment(new Date()).format("YYYY-MM-DD"),
        gubun: "11",
        keyWord: "",
        page: 1,
        subject: "",
        toDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setKey1("개인별 내역");
      setSearchData([]);
      setSearchListNumber("5");
      setDetailData(null);
      setModifyData([]);
      setInsertData({
        beneGubn: "",
        currDate: "",
        gubun: "11", // 입금, 출금, 기타
        paymentWon: 0,
        receiptWon: 0,
        remark: "",
        sawonId: "",
        workDetail: "",
      });
      setSaveData([]);
      setPersonalSaveData([]);
      setSelectOptionType("1");
      setDetailSawonId("");
      setSearchHistory([]);
      setSelectedMember("");
    });
  }, []);

  useEffect(() => {
    getBeneSelectBox.mutate("2");
  }, []);

  // API - 상조회비 검색
  const search = useMutation(
    data =>
      axios.get("/api/emp/feeMng/search", {
        params: data,
      }),
    {
      onSuccess: (res, payload) => {
        setSelectOptionType(searchOption.searchType);

        if (payload.searchType === "1") {
          setKey1("개인별 내역");
        }

        if (payload.page === 1) {
          setSearchData(res.data.result);
          setSaveData(res.data.result);
          setSearchHistory(res.data.result);
        } else {
          setSearchData([...searchData, ...res.data.result]);
          setSearchHistory([...searchData, ...res.data.result]);
          setSaveData([...searchData, ...res.data.result]);
        }
      },
    }
  );

  // API - 회비 관리 사원 조회
  const getSawonDetail = useMutation(
    data =>
      axios.get("/api/emp/feeMng/getSawonDetail", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        setDetailData(res.data.result);
        setPersonalSaveData(res.data.result);
      },
    }
  );

  // API - 현재 통장 잔고 조회
  const getRemain = useQuery(
    ["getRemain"],
    async () =>
      await axios.get("/api/emp/feeMng/getRemain").then(res => {
        return res.data.result;
      })
  );

  // API - 입출금 내역 등록
  const insertBenefit = useMutation(data => axios.post("/api/emp/feeMng/insertBenefit", data), {
    onSuccess: res => {
      setSearchOption(prev => {
        return { ...searchOption, page: 1 };
      });
      search.mutate({ ...searchOption, page: 1 });
      queryClient.invalidateQueries(["getRemain"]);
      if (detailSawonId) {
        getSawonDetail.mutate(detailSawonId);
      }
      resetInsert();
      alert("등록되었습니다.");
    },
  });

  // API - 입출금 내역 등록 이름 셀렉트박스
  const getSawonList = useQuery(
    ["getSawonList"],
    async () =>
      await axios.get("/api/emp/feeMng/getSawonList").then(res => {
        return res.data.result;
      })
  );

  // API - 경조금 지급 규정
  const getRule = useQuery(
    ["getRule"],
    async () =>
      await axios.get("/api/emp/feeMng/getRule").then(res => {
        return res.data.result;
      })
  );

  // API - 입출금 내역 수정
  const saveBenefit = useMutation(data => axios.post("/api/emp/feeMng/saveBenefit", data), {
    onSuccess: res => {
      setSearchOption(prev => {
        return { ...searchOption, page: 1 };
      });
      search.mutate({ ...searchOption, page: 1 });
    },
  });

  // API - 입출금 내역 삭제
  const deleteBenefit = useMutation(
    data =>
      axios.post("/api/emp/feeMng/deleteBenefit", {
        ilNo: data,
      }),
    {
      onSuccess: res => {
        setSearchOption(prev => {
          return { ...searchOption, page: 1 };
        });
        search.mutate({ ...searchOption, page: 1 });
      },
    }
  );
  // API - 입출금 내역 계정 셀렉트박스
  const getBeneSelectBox = useMutation(
    data =>
      axios
        .get("/api/emp/feeMng/getBeneSelectBox", {
          params: {
            gubun: data,
          },
        })
        .then(res => {
          return res?.data?.result;
        }),
    {
      onSuccess: res => { },
    }
  );

  const pressEnter = e => {
    if (e.key === "Enter") {
      setSearchData([]);
      setSearchOption(prev => {
        return { ...searchOption, page: 1 };
      });
      search.mutate({ ...searchOption, page: 1 });
    }
  };

  const resetInsert = () => {
    setInsertData({
      beneGubn: "1011", // 계정 본인결혼 - 1011
      currDate: "",
      gubun: "11", // 입금- 11, 출금, 기타
      paymentWon: 0,
      receiptWon: 0,
      remark: "",
      sawonId: "",
      workDetail: "",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setSearchOption(prev => {
            search.mutate({ ...searchOption, page: prev.page + 1 });
            return { ...searchOption, page: prev.page + 1 };
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, [searchOption]);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  return (
    <>
      <EmpModal
        show={isOpen}
        minWidth="475px"
        title={"경조금 지급규정"}
        onHide={() => setIsOpen(false)}
      >
        <ProvisionsPaymentCondolenceMoney data={getRule?.data} />
      </EmpModal>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">회비관리</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() => setIsOpen(true)}
              >
                경조금 지급규정
              </button>
            </div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => {
                      if (e.target.value === "1") {
                        setSearchOption({
                          ...initialSearchOption,
                          searchType: e.target.value,
                          gubun: gubunData[0]?.key,
                        });
                      } else if (e.target.value === "3") {
                        setSearchOption({
                          ...initialSearchOption,
                          searchType: e.target.value,
                          subject: beneGubunData[0]?.key,
                        });
                      } else {
                        setSearchOption({ ...initialSearchOption, searchType: e.target.value });
                      }
                    }}
                    value={searchOption.searchType}
                  >
                    <option value="1">직원명</option>
                    <option value="2">입출금 일자별</option>
                    <option value="3">계정별</option>
                  </select>
                </div>
                {searchOption.searchType == "1" && (
                  <React.Fragment>
                    <div className="filter1-field filter1-field1">
                      <label className="filter1-label">근무구분</label>
                      <select
                        className="form-select filter1-select1"
                        value={searchOption.gubun}
                        onChange={e => setSearchOption({ ...searchOption, gubun: e.target.value })}
                      >
                        {gubunData &&
                          gubunData.map(item => {
                            return <option value={item.key}>{item.value}</option>;
                          })}
                      </select>
                    </div>
                    <div className="filter1-field filter1-field2">
                      <label className="filter1-label">성명</label>
                      <input
                        type="text"
                        className="form-control filter-input filter1-input2"
                        onChange={e =>
                          setSearchOption({ ...searchOption, keyWord: e.target.value })
                        }
                        value={searchOption.keyWord}
                      />
                    </div>
                  </React.Fragment>
                )}
                {searchOption.searchType == "2" && (
                  <React.Fragment>
                    <div className="filter1-field filter1-field2 m-0 p-0">
                      <label className="filter1-label ms-3">발송기간</label>
                      <input
                        type="date"
                        className="form-control filter-input filter1-input2"
                        value={searchOption.fromDate}
                        min="1900-01-01"
                        max="2099-12-31"
                        onChange={e => {
                          setSearchOption({
                            ...searchOption,
                            fromDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="txt_daterange">~</div>
                    <div className="filter1-field filter1-field3 px-0">
                      <input
                        type="date"
                        className="form-control filter-input filter1-input2"
                        value={searchOption.toDate}
                        min="1900-01-01"
                        max="2099-12-31"
                        onChange={e => {
                          setSearchOption({
                            ...searchOption,
                            toDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}
                {searchOption.searchType == "3" && (
                  <React.Fragment>
                    <div className="filter1-field filter1-field1">
                      <label className="filter1-label">계정과목</label>
                      <select
                        className="form-select filter1-select1"
                        value={searchOption.subject}
                        onChange={e =>
                          setSearchOption({ ...searchOption, subject: e.target.value })
                        }
                      >
                        {beneGubunData &&
                          beneGubunData.map(item => {
                            return <option value={item.key}>{item.value}</option>;
                          })}
                      </select>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  setSearchData([]);
                  setSearchOption(prev => {
                    return { ...searchOption, page: 1 };
                  });
                  search.mutate({ ...searchOption, page: 1 });
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-main mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count"></div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>

            <div
              className={`table-container table-sticky 
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "8%" }}>연번</th>
                    {selectOptionType === "1" ? (
                      <>
                        <th style={{ width: "30%" }}>사원번호</th>
                        <th style={{ width: "31%" }}>이름</th>
                        <th style={{ width: "31%" }}>직위</th>
                      </>
                    ) : (
                      <>
                        <th style={{ width: "30%" }}>거래일</th>
                        <th style={{ width: "31%" }}>구분</th>
                        <th style={{ width: "31%" }}>계정</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {searchData?.length > 0 ? (
                    <>
                      {searchData.map((data, _idx) => {
                        return (
                          <tr
                            role="button"
                            onClick={() => {
                              getSawonDetail.mutate(data.sawonId);
                              setDetailSawonId(data.sawonId);
                              if (selectOptionType === "1") {
                                setSelectedMember(data?.sawonId);
                              } else {
                                setSelectedMember(data?.ilNo);
                              }
                            }}
                            className={`${(data?.work1 === "22" || data?.work1 === "72") && "bg-red"
                              }
                            ${selectOptionType === "1" &&
                              data?.sawonId === selectedMember &&
                              "bg-blue"
                              }
                            ${selectOptionType !== "1" && data?.ilNo === selectedMember && "bg-blue"
                              }

                            `}
                          >
                            <td>{_idx + 1}</td>
                            {selectOptionType === "1" ? (
                              <>
                                <td>{data?.sawonId.replace(/(.{6})/g, "$1-")}</td>
                                <td>{data?.kname}</td>
                                <td>{data?.authName}</td>
                              </>
                            ) : (
                              <>
                                <td>{data?.currDate}</td>
                                <td>{data?.gubun}</td>
                                <td>{data?.beneGubn}</td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                      {searchData.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={4}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* {detailData != null && ( */}
        <div className="row gx-3">
          <div className="col-5">
            <div className="card card-main">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">개인/가족정보</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-tabs-subsub w-50 m-0">개인정보</div>

                    <table className="table table-bordered table-responsive my-table1 mt-10px">
                      <tbody>
                        <tr>
                          <th style={{ width: "20%" }}>한글이름</th>
                          <td style={{ width: "30%" }}>{detailData?.sawonInfo?.kname}</td>
                          <th style={{ width: "15%" }}>소속</th>
                          <td>{detailData?.sawonInfo?.dept}</td>
                        </tr>
                        <tr>
                          <th>부서</th>
                          <td>{detailData?.sawonInfo?.job}</td>
                          <th>직책</th>
                          <td>{detailData?.sawonInfo?.partJob}</td>
                        </tr>
                        <tr>
                          <th>직위</th>
                          <td>{detailData?.sawonInfo?.authority}</td>
                          <th>입사구분</th>
                          <td>{detailData?.sawonInfo?.inGubun}</td>
                        </tr>
                        <tr>
                          <th>최초입사일</th>
                          <td>
                            {detailData?.sawonInfo?.inDate &&
                              moment(detailData?.sawonInfo?.inDate).format("YYYY-MM-DD")}
                          </td>
                          <th></th>
                          <td></td>
                        </tr>
                        <tr>
                          <th rowSpan={2}>현주소</th>
                          <td colSpan={3}>{detailData?.sawonInfo?.jusoAddr}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>{detailData?.sawonInfo?.addres2}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* 급여계좌 정보 */}
                <div className="row">
                  <div className="col-12">
                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "20%" }}>급여계좌</th>
                          <td style={{ width: "30%" }}>{detailData?.sawonInfo?.payBank}</td>
                          <th style={{ width: "15%" }}>예금주</th>
                          <td>{detailData?.sawonInfo?.payName}</td>
                        </tr>
                        <tr>
                          <th>계좌번호</th>
                          <td colSpan={3}>{detailData?.sawonInfo?.payAccount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="table-tabs-subsub w-50 m-0">가족정보</div>
                    <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "10%" }}>No.</th>
                          <th style={{ width: "18%" }}>관계</th>
                          <th style={{ width: "18%" }}>이름</th>
                          <th style={{ width: "18%" }}>생년월일</th>
                          <th style={{ width: "18%" }}>동거여부</th>
                          <th>비고</th>
                        </tr>
                        {detailData?.familyList?.length > 0 ? (
                          detailData?.familyList.map((data, _idx) => {
                            return (
                              <tr>
                                <td className="text-center">{_idx + 1}</td>
                                <td className="text-center">{data?.relation}</td>
                                <td className="text-center">{data?.kname}</td>
                                <td className="text-center">{data?.juminno}</td>
                                <td className="text-center">{data?.togeter}</td>
                                <td className="text-center">{data?.job}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              조회 결과가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-7">
            <div className="card card-main">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">입/출금 내역등록</h5>
                <div className="d-flex align-items-center justify-content-end m-0">
                  <div className="f-regular f-15">
                    <span className="txt_highlight_2">
                      1. 수습(2개월): 해당없음 2. 입사 1년 미만 50% 지급
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-responsive my-table1 mb-10px">
                  <tbody>
                    <tr>
                      <th style={{ width: "13%" }}>거래일자</th>
                      <td style={{ width: "37%" }}>
                        <input
                          type="date"
                          className="form-control table-input"
                          min="1900-01-01"
                          max="2099-12-31"
                          value={insertData.currDate}
                          onChange={e =>
                            setInsertData({
                              ...insertData,
                              currDate: e.target.value,
                            })
                          }
                        />
                      </td>
                      <th style={{ width: "13%" }}>구분</th>
                      <td>
                        <select
                          className="form-select w-100"
                          value={insertData.gubun}
                          onChange={e => {
                            setInsertData({
                              ...insertData,
                              gubun: e.target.value,
                              receiptWon: 0,
                              paymentWon: 0,
                              beneGubn: "",
                            });
                            if (e.target.value === "22") {
                              // 출금
                              getBeneSelectBox.mutate("1");
                            } else if (e.target.value === "99") {
                              getBeneSelectBox.mutate("3");
                            } else {
                              getBeneSelectBox.mutate("2");
                            }
                          }}
                        >
                          {beneTypeData?.map(item => {
                            return <option value={item.key}>{item.value}</option>;
                          })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>계정</th>
                      <td>
                        <select
                          className="form-select w-100"
                          value={insertData.beneGubn}
                          onChange={e => setInsertData({ ...insertData, beneGubn: e.target.value })}
                        >
                          <option value=""></option>
                          {getBeneSelectBox.data?.map(item => {
                            return <option value={item.code}>{item.codename}</option>;
                          })}
                        </select>
                      </td>
                      <th>이름</th>
                      <td>
                        <select
                          className="form-select w-100"
                          onChange={e => {
                            const { sawonId, userName, inDate } = JSON.parse(e.target?.value);
                            const date1 = new Date(inDate);
                            const date2 = new Date(insertData?.currDate);
                            if (date1 && date1.getTime() < date2.getTime()) {
                              const diffDate = Math.abs(date1.getTime() - date2.getTime());
                              const daysDiff = Math.ceil(diffDate / (1000 * 3600 * 24));
                              setInsertData({ ...insertData, sawonId: e.target?.value });
                              if (daysDiff < 366) {
                                alert("입사 1년 미만 대상자에겐 50%만 지급됩니다.");
                              }
                            } else {
                              alert("유효하지 않은 거래 일자입니다.");
                              setInsertData({ ...insertData, currDate: "", sawonId: "" });
                            }
                          }}
                          value={insertData.sawonId}
                        >
                          <option value=""></option>
                          {getSawonList?.data?.map(item => {
                            return <option value={JSON.stringify(item)}>{item.userName}</option>;
                          })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>입금액</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          value={insertData.receiptWon
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          disabled={insertData.gubun === "22"}
                          onChange={e =>
                            setInsertData({
                              ...insertData,
                              receiptWon: Number(
                                e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                              ),
                            })
                          }
                        />
                      </td>
                      <th>출금액</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          value={insertData.paymentWon
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          disabled={insertData.gubun === "11"}
                          onChange={e =>
                            setInsertData({
                              ...insertData,
                              paymentWon: Number(
                                e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                              ),
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>적요</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={insertData.workDetail}
                          onChange={e =>
                            setInsertData({ ...insertData, workDetail: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>기타내용</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={insertData.remark}
                          onChange={e => setInsertData({ ...insertData, remark: e.target.value })}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="d-flex align-items-center justify-content-end m-0">
                  <button
                    className="btn btn-outline-primary ms-8px me-0"
                    onClick={async () => {
                      if (insertData?.sawonId) {
                        const newData = {
                          ...insertData,
                          sawonId: JSON.parse(insertData.sawonId).sawonId,
                        };
                        insertBenefit.mutate(newData);
                      } else {
                        alert("내역 등록란의 올바른 값을 입력해주세요.");
                      }
                    }}
                  >
                    등록처리
                  </button>
                  <button
                    className="btn btn-outline-primary ms-8px  me-0"
                    onClick={() => {
                      resetInsert();
                      alert("변경 사항이 취소되었습니다.");
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
            <div className="card card-main">
              <div className="card-body">
                <div className="tabs-side-content">
                  <div className="f-regular f-15 me-2">
                    거래일자, 적요, 기타내용에 대해서만 수정가능
                  </div>

                  <button
                    className="btn btn-outline-primary m-0  "
                    onClick={() => {
                      if (modifyData.length > 0) {
                        setSearchOption(prev => {
                          return { ...searchOption, page: 1 };
                        });
                        setModifyData([]);
                        saveBenefit.mutate(modifyData);
                        getSawonDetail.mutate(detailSawonId);
                      }
                    }}
                  >
                    저장
                  </button>
                  <button
                    className="btn btn-outline-primary m-0 ms-2"
                    onClick={() => {
                      if (key1 === "입출금 내역") {
                        setSearchHistory(saveData);
                      } else {
                        setDetailData(personalSaveData);
                      }
                      alert("변경 사항이 취소되었습니다.");
                    }}
                  >
                    취소
                  </button>
                </div>
                <Tabs
                  className="tab-blocks m-0"
                  id="controlled-tab-example2"
                  activeKey={key1}
                  onSelect={k => {
                    setKey1(k);
                    setModifyData([]);
                    setSearchData(saveData);
                    setDetailData(personalSaveData);
                  }}
                >
                  {selectOptionType !== "1" && (
                    <Tab eventKey="입출금 내역" title="입출금 내역">
                      <div className={`table-container table-sticky h-633`} id="scroll-table-body">
                        <table className="table table-bordered table-responsive my-table mt-0">
                          <thead>
                            <tr>
                              {/* <th style={{ width: "5%" }}>연번</th> */}
                              <th style={{ width: "6%" }}>No.</th>
                              <th style={{ width: "10%" }}>거래일자</th>
                              <th style={{ width: "8%" }}>구분</th>
                              <th style={{ width: "9%" }}>계정</th>
                              <th style={{ width: "9%" }}>이름</th>
                              <th style={{ width: "12%" }}>입금액</th>
                              <th style={{ width: "12%" }}>출금액</th>
                              <th style={{ width: "20%" }}>잔액</th>
                              <th style={{ width: "6%" }}>적요</th>
                              <th style={{ width: "6%" }}>기타내용</th>
                              {/* {decrypt("userInfo").userPermit === "99" && (
                                <th style={{ width: "3%" }}>삭제</th>
                              )} */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* 검색한 모든 사람에 대한 입출금 내역 조회 */}
                            {searchHistory?.length > 0 ? (
                              searchHistory.map((data, _idx) => {
                                return (
                                  <tr
                                    className={` ${selectOptionType !== "1" &&
                                      data?.ilNo === selectedMember &&
                                      "bg-blue"
                                      }`}
                                  >
                                    {/* <td className="text-center">{_idx + 1}</td> */}
                                    <td className="text-center">{data?.ilNo}</td>
                                    <td>
                                      <div className="d-flex">
                                        <input
                                          type="date"
                                          value={data?.currDate}
                                          min="1900-01-01"
                                          max="2099-12-31"
                                          className="form-control filter-input filter1-input2 w-100"
                                          onChange={e => {
                                            const changeData = searchHistory.map(item => {
                                              if (item.ilNo === data.ilNo) {
                                                return { ...item, currDate: e.target.value };
                                              } else {
                                                return { ...item };
                                              }
                                            });

                                            const isData = element => {
                                              return element.ilNo === data.ilNo;
                                            };
                                            const idx = modifyData.findIndex(isData);
                                            if (idx === -1) {
                                              setModifyData([
                                                ...modifyData,
                                                {
                                                  currDate: e.target.value,
                                                  ilNo: data.ilNo,
                                                  remark: data.remark,
                                                  workDetail: data.workDetail,
                                                },
                                              ]);
                                            } else {
                                              const newData = {
                                                currDate: e.target.value,
                                                ilNo: data.ilNo,
                                                remark: data.remark,
                                                workDetail: data.workDetail,
                                              };
                                              modifyData[idx] = newData;
                                            }

                                            setSearchHistory(changeData);
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td className="text-center">{data?.gubun}</td>
                                    <td className="text-center">{data?.beneGubn}</td>
                                    <td className="text-center">{data?.kname}</td>
                                    <td className="text-center">
                                      {data?.receiptWon
                                        ?.toString()
                                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <td className="text-center">
                                      {data?.paymentWon
                                        ?.toString()
                                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <td className="text-center">
                                      {data?.remainderWon
                                        ?.toString()
                                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <td className="text-center">
                                      <input
                                        type="text"
                                        value={data?.workDetail}
                                        className="form-control filter-input filter1-input2"
                                        onChange={e => {
                                          const changeData = searchHistory.map(item => {
                                            if (item.ilNo === data.ilNo) {
                                              return { ...item, workDetail: e.target.value };
                                            } else {
                                              return { ...item };
                                            }
                                          });

                                          const isData = element => {
                                            return element.ilNo === data.ilNo;
                                          };
                                          const idx = modifyData.findIndex(isData);
                                          if (idx === -1) {
                                            setModifyData([
                                              ...modifyData,
                                              {
                                                currDate: data.currDate,
                                                ilNo: data.ilNo,
                                                remark: data.remark,
                                                workDetail: e.target.value,
                                              },
                                            ]);
                                          } else {
                                            const newData = {
                                              currDate: data.currDate,
                                              ilNo: data.ilNo,
                                              remark: data.remark,
                                              workDetail: e.target.value,
                                            };
                                            modifyData[idx] = newData;
                                          }

                                          setSearchHistory(changeData);
                                        }}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <input
                                        type="text"
                                        value={data?.remark}
                                        className="form-control filter-input filter1-input2 "
                                        onChange={e => {
                                          const changeData = searchHistory.map(item => {
                                            if (item.ilNo === data.ilNo) {
                                              return { ...item, remark: e.target.value };
                                            } else {
                                              return { ...item };
                                            }
                                          });

                                          const isData = element => {
                                            return element.ilNo === data.ilNo;
                                          };
                                          const idx = modifyData.findIndex(isData);
                                          if (idx === -1) {
                                            setModifyData([
                                              ...modifyData,
                                              {
                                                currDate: data.currDate,
                                                ilNo: data.ilNo,
                                                workDetail: data.workDetail,
                                                remark: e.target.value,
                                              },
                                            ]);
                                          } else {
                                            const newData = {
                                              currDate: data.currDate,
                                              ilNo: data.ilNo,
                                              workDetail: data.workDetail,
                                              remark: e.target.value,
                                            };
                                            modifyData[idx] = newData;
                                          }

                                          setSearchHistory(changeData);
                                        }}
                                      />
                                    </td>
                                    {/* {decrypt("userInfo").userPermit === "99" && (
                                      <td
                                        className="text-center cursor-pointer"
                                        onClick={() => {
                                          const result =
                                            window.confirm("해당 기록을 삭제하시겠습니까?");
                                          if (result) {
                                            setSearchOption({ ...searchOption, page: 1 });
                                            deleteBenefit.mutate(data.ilNo);
                                            getSawonDetail.mutate(detailSawonId);
                                          } else {
                                          }
                                        }}
                                      >
                                        <img
                                          src={process.env.PUBLIC_URL + "/images/delete.png"}
                                          alt=""
                                        />
                                      </td>
                                    )} */}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={11} className="text-center">
                                  조회 결과가 없습니다.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="f-regular f-15 mt-10px">
                          <span className="txt_highlight_1 ">
                            ▶현재 통장 잔고 :{" "}
                            {getRemain?.data
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            원
                          </span>
                        </div>
                      </div>
                    </Tab>
                  )}
                  <Tab eventKey="개인별 내역" title="개인별 내역">
                    <div className={`table-container table-sticky h-633`} id="scroll-table-body">
                      <table className="table table-bordered table-responsive my-table mt-0">
                        <thead>
                          <tr>
                            <th style={{ width: "6%" }}>No.</th>
                            <th style={{ width: "10%" }}>거래일자</th>
                            <th style={{ width: "8%" }}>구분</th>
                            <th style={{ width: "13.2%" }}>계정</th>
                            <th style={{ width: "17.2%" }}>적요</th>
                            <th style={{ width: "13.2%" }}>입금액</th>
                            <th style={{ width: "13.2%" }}>지출금액</th>
                            <th>기타내용</th>
                            {/* {decrypt("userInfo").userPermit === "99" && (
                              <th style={{ width: "8%" }}>삭제</th>
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {detailData?.benefitList?.length > 0 ? (
                            detailData?.benefitList.map((data, _idx) => {
                              return (
                                <tr>
                                  <td className="text-center">{data?.ilNo}</td>
                                  <td>
                                    <div className="d-flex">
                                      <input
                                        type="date"
                                        value={data?.currDate}
                                        className="form-control filter-input filter1-input2  w-100"
                                        min="1900-01-01"
                                        max="2099-12-31"
                                        onChange={e => {
                                          const changeData = detailData?.benefitList?.map(item => {
                                            if (item.ilNo === data.ilNo) {
                                              return { ...item, currDate: e.target.value };
                                            } else {
                                              return { ...item };
                                            }
                                          });

                                          const isData = element => {
                                            return element.ilNo === data.ilNo;
                                          };
                                          const idx = modifyData.findIndex(isData);
                                          if (idx === -1) {
                                            setModifyData([
                                              ...modifyData,
                                              {
                                                ilNo: data.ilNo,
                                                workDetail: data.workDetail,
                                                remark: data.remark,
                                                currDate: e.target.value,
                                              },
                                            ]);
                                          } else {
                                            const newData = {
                                              ilNo: data.ilNo,
                                              workDetail: data.workDetail,
                                              remark: data.remark,
                                              currDate: e.target.value,
                                            };
                                            modifyData[idx] = newData;
                                          }

                                          setDetailData({
                                            ...detailData,
                                            benefitList: changeData,
                                          });
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-center">{data?.gubun}</td>
                                  <td className="text-center">{data?.beneGubn}</td>
                                  <td className="text-center">
                                    {/* 적요 - workDetail */}
                                    <input
                                      type="text"
                                      value={data?.workDetail}
                                      className="form-control filter-input filter1-input2"
                                      onChange={e => {
                                        const changeData = detailData?.benefitList?.map(item => {
                                          if (item.ilNo === data.ilNo) {
                                            return { ...item, workDetail: e.target.value };
                                          } else {
                                            return { ...item };
                                          }
                                        });

                                        const isData = element => {
                                          return element.ilNo === data.ilNo;
                                        };
                                        const idx = modifyData.findIndex(isData);
                                        if (idx === -1) {
                                          setModifyData([
                                            ...modifyData,
                                            {
                                              currDate: data.currDate,
                                              ilNo: data.ilNo,
                                              remark: data.remark,
                                              workDetail: e.target.value,
                                            },
                                          ]);
                                        } else {
                                          const newData = {
                                            currDate: data.currDate,
                                            ilNo: data.ilNo,
                                            remark: data.remark,
                                            workDetail: e.target.value,
                                          };
                                          modifyData[idx] = newData;
                                        }

                                        setDetailData({ ...detailData, benefitList: changeData });
                                      }}
                                    />
                                  </td>
                                  <td className="text-end">
                                    {data?.receiptWon
                                      ?.toString()
                                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                  </td>
                                  <td className="text-end">
                                    {data?.paymentWon
                                      ?.toString()
                                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                  </td>
                                  <td className="text-center">
                                    <input
                                      type="text"
                                      value={data?.remark}
                                      className="form-control filter-input filter1-input2"
                                      onChange={e => {
                                        const changeData = detailData?.benefitList?.map(item => {
                                          if (item.ilNo === data.ilNo) {
                                            return { ...item, remark: e.target.value };
                                          } else {
                                            return { ...item };
                                          }
                                        });

                                        const isData = element => {
                                          return element.ilNo === data.ilNo;
                                        };
                                        const idx = modifyData.findIndex(isData);
                                        if (idx === -1) {
                                          setModifyData([
                                            ...modifyData,
                                            {
                                              currDate: data.currDate,
                                              ilNo: data.ilNo,
                                              workDetail: data.workDetail,
                                              remark: e.target.value,
                                            },
                                          ]);
                                        } else {
                                          const newData = {
                                            currDate: data.currDate,
                                            ilNo: data.ilNo,
                                            workDetail: data.workDetail,
                                            remark: e.target.value,
                                          };
                                          modifyData[idx] = newData;
                                        }

                                        setDetailData({ ...detailData, benefitList: changeData });
                                      }}
                                    />
                                  </td>
                                  {/* {decrypt("userInfo").userPermit === "99" && (
                                    <td
                                      className="text-center cursor-pointer"
                                      onClick={() => {
                                        const result =
                                          window.confirm("해당 기록을 삭제하시겠습니까?");
                                        if (result) {
                                          setSearchOption({ ...searchOption, page: 1 });
                                          deleteBenefit.mutate(data.ilNo);
                                          getSawonDetail.mutate(detailSawonId);
                                        } else {
                                        }
                                      }}
                                    >
                                      <img
                                        src={process.env.PUBLIC_URL + "/images/delete.png"}
                                        alt=""
                                      />
                                    </td>
                                  )} */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={8} className="text-center">
                                조회 결과가 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="f-regular f-15 mt-10px">
                        <span className="txt_highlight_1">
                          ▶현재 통장 잔고 :{" "}
                          {getRemain?.data
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          원
                        </span>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};
export default MembershipFeeManage;
