import moment from "moment/moment";
import { atom } from "jotai";

export const searchParamInit = {
  // cardNo: "5585269617818805",
  cardNo: "",
  currDate: moment("2023-03-03").format("YYYY-MM-DD"),
  currDateBack: moment("2024-01-31").format("YYYY-MM-DD"),
  page: 1,
};
// export const searchParamInit = {
//   cardNo: "",
//   currDate: moment().format("YYYY-MM-DD"),
//   currDateBack: moment().format("YYYY-MM-DD"),
//   page: 1,
// };

export const searchParam_atom = atom({ ...searchParamInit });

export const cardList_atom = atom([]);

export const newData_atom = atom({});

export const searchedParam_atom = atom({});
