import { atom } from "jotai";
import moment from "moment";

export const membershipTransactionAmount_row = atom("5");
export const membershipTransactionAmount_detail = atom(null);
export const membershipTransactionAmount_showForm = atom(false);
export const membershipTransactionAmount_selectMember = atom("");
export const membershipTransactionAmount_submitData = atom({
  addedAmnt: 0,
  addedGubn: "",
  commiAmnt: 0,
  confmStatus: "",
  confmStatusCode: "",
  custName: "",
  custNo: "",
  dealHistory: "",
  drftSttus: "",
  firstAmnt: 0,
  gubun: "1", // 1 : 개인, 2 : 법인
  inDate: moment(new Date()).format("YYYY-MM-DD"),
  issueDate: "",
  kname: "",
  lockerAmnt: 0,
  lostAmnt: 0,
  middleAmnt: 0,
  modDate: "",
  modId: "",
  partner: "",
  payAccount: "",
  payBank: "",
  payName: "",
  position: "",
  relation: "",
  remainAmnt: 0,
  remark: "",
  sawonId: "",
  seqNo: "",
  situation: "",
  stampAmnt: 0,
  sumAmnt: 0,
  totalAmnt: 0,
  tradClass: "1", // 1 : 골프, 2 : 콘도, 3 : 휘트니스
  tradGubn: "1", // 1 : 계약금, 2 : 중도금, 3 : 잔금, 4 : 기타
  writeAmnt: 0,
  yearAmnt: 0,
  depoDraw: 0, // 0 : 입금, 1 : 출금
  roomNo: 2831,
  maedoMaesu: 0, // 0 : 매도, 1 : 매수
});
