import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAtom } from "jotai/index";
import {
  clientManage_mainData_atom,
  clientManage_mainDataInit_atom,
  clientManage_searchData_atom,
  clientManage_searchDataInit_atom,
  clientManage_searchedTableList_atom,
  clientManage_subData_atom,
  clientManage_subDataModifyList_atom,
  clientManage_tableLimit_atom,
} from "../../../data/atom/dataManage/clientManage/clientManage_atom";
import { useGetSupplier } from "../../../api/code/useGetSupplier";
import { useGetClassSupplier } from "../../../api/code/useGetClassSupplier";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { DaumPostcode } from "../../../components/daumPostcode/daumPostcode";
import moment from "moment";
import { useGetBank } from "../../../api/code/useGetBank";
import { decrypt } from "../../../util/decrypt/decrypt";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";
import {
  customerManage_mainDataInit_atom,
  customerManage_searchDataInit_atom,
  customerManage_searchedTableListInit_atom,
} from "../../../data/atom/workManage/customerManage/customerManage_atom";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const ClientManage = () => {
  const [infinityScroll, setInfinityScroll] = useState(null);
  const { addTabClosedEvent } = useRecentTabEvent();

  const [tableLimit, setTableLimit] = useAtom(clientManage_tableLimit_atom);
  const [searchData, setSearchData] = useAtom(clientManage_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(clientManage_searchedTableList_atom);

  const [mainData, setMainData] = useAtom(clientManage_mainData_atom);

  // 통화내역
  // const [subData, setSubData] = useAtom(clientManage_subData_atom);
  const [subData, setSubData] = useState([]);
  const [subModifyList, setSubModifyList] = useState([]);

  const [addressIsOpen, setAddressIsOpen] = useState([false, false]);

  const getSupplier = useGetSupplier(); // 분류 셀렉트박스
  const getClassSupplier = useGetClassSupplier(); // 등급구분 셀렉트박스
  const getBank = useGetBank(); // 계좌은행 셀렉트박스
  const getUserIdQ = useGetUserIdQ();

  // 거래처 고객 검색
  const supplierSearch = useMutation(data =>
    axios.get("/api/data/supplier/name/search", { params: data })
  );

  // 거래처 제명제외 버튼
  const supplierSearchExclusion = useMutation(
    data => axios.get("/api/data/supplier/top/searchExclusion", { params: data }),
    {
      onSuccess: res => {
        setSearchedTableList(res.data.result);
      },
    }
  );

  // 거래처 고객 상세 조회
  const supplierGetDetail = useMutation(
    data => axios.get("/api/data/supplier/name/getDetail", { params: data }),
    {
      onSuccess: res => {
        const result = res.data.result[0];

        setMainData({
          ...result,
          inDate: moment(result.inDate).format("YYYY-MM-DD"),
          outCheck: result.outCheck === "Y",
          outDate: result.outCheck === "Y" ? moment(result.outDate).format("YYYY-MM-DD") : null,
        });

        supplierSubGetTel.mutate({ custId: result.custId });
      },
    }
  );

  // 거래저 저장(신규등록)
  const supplierGetsave = useMutation(
    data => axios.post("/api/data/supplier/middle/getsave", data),
    {
      onSuccess: res => {
        alert("처리가 완료되었습니다.");
        supplierSearch.mutateAsync(searchData).then(res => {
          setSearchedTableList(res.data.result);
        });
        supplierGetDetail.mutate({ custId: res.data.result.custId });
      },
    }
  );

  // 거래처 수정
  // const supplierGetupdate = useMutation(
  //   data => axios.post("/api/data/supplier/middle/getupdate", data),
  //   {
  //     onSuccess: res => {
  //       supplierSearch.mutateAsync(searchData).then(res => {
  //         setSearchedTableList(res.data.result);
  //       });
  //       supplierGetDetail.mutate({ custId: mainData.custId });
  //     },
  //   }
  // );

  // 거래처 삭제
  const supplierGetdelete = useMutation(
    data => axios.post("/api/data/supplier/middle/getdelete", data),
    {
      onSuccess: res => {
        supplierSearch.mutateAsync(searchData).then(res => {
          setSearchedTableList(res.data.result);
        });
        setMainData({});
        setSubData([]);
      },
    }
  );

  // 통화내역 리스트 조회
  const supplierSubGetTel = useMutation(
    data => axios.get("/api/data/supplier/bottom/getTel", { params: data }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
        setSubModifyList(Array.from({ length: res.data.result.length }, () => false));
      },
    }
  );

  // 통화내역 저장/수정
  const supplierSubSave = useMutation(data => axios.post("/api/data/supplier/bottom/save", data), {
    onSuccess: res => {
      supplierSubGetTel.mutate({ custId: mainData.custId });
    },
  });

  // 통화내역 삭제
  const supplierSubGetTelDelete = useMutation(
    data => axios.post("/api/data/supplier/bottom/delete", data),
    {
      onSuccess: res => {
        // alert("삭제가 완료되었습니다.");
        supplierSubGetTel.mutate({ custId: mainData.custId });
      },
    }
  );

  // 무한 스크롤 이벤트
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && searchedTableList.length > 99) {
          setSearchData(prev => {
            const newSearchData = { ...prev };
            newSearchData.page = newSearchData.page + 1;

            supplierSearch.mutateAsync(newSearchData).then(res => {
              setSearchedTableList(prev => {
                return [...prev, ...res.data.result];
              });
            });

            return newSearchData;
          });
        }
      },
      { threshold: 0.5 }
    );

    if (infinityScroll) {
      observer.observe(infinityScroll);
    }
  }, [infinityScroll]);

  useEffect(() => {
    if (searchData.searchType === "9") {
      setSearchData({
        ...searchData,
        class1: getClassSupplier.data[0].key,
        selectBox: "",
        keyword: "",
      });
    }

    if (searchData.searchType === "2") {
      setSearchData({
        ...searchData,
        selectBox: getSupplier.data[0].key,
        class1: "",
        keyword: "",
      });
    }
  }, [searchData.searchType]);

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setTableLimit("5");
      setSearchData(clientManage_searchDataInit_atom);
      setSearchedTableList([]);
      setMainData(clientManage_mainDataInit_atom);
    });
  }, []);

  // 제명제외 버튼
  const btn_search_exclusion = () => {
    const newSearchData = { ...searchData };
    newSearchData.page = 1;

    supplierSearchExclusion.mutate(newSearchData);

    setSearchData(newSearchData);
  };

  // 검색 버튼
  const btn_search_all = () => {
    const newSearchData = { ...searchData };
    newSearchData.page = 1;

    setSearchedTableList(() => []);

    supplierSearch.mutateAsync(newSearchData).then(res => {
      setSearchedTableList(res.data.result);
      setSearchData(newSearchData);
    });
  };

  // 신규등록 버튼
  const btn_newRegister = () => {
    if (window.confirm("신규등록을 하시겠습니까?")) {
      setMainData({
        caddres: "",
        cellular: "",
        cfax: "",
        classRemark: "",
        classTel: "",
        clazz: "",
        company: "",
        ctel: "",
        custId: "0",
        czipCode: "",
        devision: "",
        email: "",
        etc1: "",
        gubun: "",
        haddres: "",
        hpCheck: "",
        hzipCode: "",
        inDate: moment().format("YYYY-MM-DD"),
        jongmok: "",
        kname: "",
        modDate: null,
        modid: "",
        outCheck: "",
        outDate: null,
        owner: "",
        parSendCheck: "",
        payAccount: "",
        payBank: "",
        payName: "",
        position: "",
        postCheck: "",
        remark: "",
        saupjaNo: "",
        search: "",
        siseSendCheck: "",
        siteUrl: "",
        upte: "",
        userid: "",
        viewChk: "",
        newCheck: true,
      });
      setAddressIsOpen([true, true]);
    }
  };

  const btn_tableRowClick = data => {
    if (mainData.custId === "0") {
      alert("신규등록부터 처리해주세요.");
      return;
    }

    supplierGetDetail.mutate({ custId: data.custId });
  };

  const btn_main_save = () => {
    if (mainData.custId === "0") {
      if (window.confirm("신규등록을 완료하시겠습니까?")) {
        supplierGetsave.mutate({
          caddres: mainData.caddres,
          cbdcode: mainData.cbdcode,
          cellular: mainData.cellular,
          cfax: mainData.cfax,
          classRemark: mainData.classRemark,
          classTel: mainData.classTel,
          clazz: mainData.clazz,
          company: mainData.company,
          ctel: mainData.ctel,
          custId: mainData.custId,
          czipCode: mainData.czipCode,
          devision: mainData.devision,
          email: mainData.email,
          etc1: "",
          gubun: mainData.gubun,
          haddres: mainData.haddres,
          hbdcode: mainData.hbdcode,
          hpCheck: mainData.hpCheck,
          hzipCode: mainData.hzipCode,
          inDate: moment(mainData.inDate).format("YYYY-MM-DD"),
          jongmok: mainData.jongmok,
          kname: mainData.kname,
          modDate: null,
          modid: null,
          outCheck: mainData.outCheck ? "Y" : "N",
          outDate: null,
          owner: mainData.owner,
          parSendCheck: mainData.parSendCheck,
          payAccount: mainData.payAccount,
          payBank: mainData.payBank,
          payName: mainData.payName,
          position: mainData.position,
          postCheck: mainData.postCheck,
          remark: mainData.remark,
          saupjaNo: mainData.saupjaNo,
          search: mainData.search,
          siseSendCheck: mainData.siseSendCheck,
          siteUrl: mainData.siteUrl,
          upte: mainData.upte,
          userid: mainData.userid,
          viewChk: mainData.viewChk,
          newCheck: mainData.newCheck,
        });
      }
    } else {
      if (window.confirm("수정하시겠습니까?")) {
        supplierGetsave.mutate({
          caddres: mainData.caddres,
          cbdcode: mainData.cbdcode,
          cellular: mainData.cellular,
          cfax: mainData.cfax,
          classRemark: mainData.classRemark,
          classTel: mainData.classTel,
          clazz: mainData.clazz,
          company: mainData.company,
          ctel: mainData.ctel,
          custId: mainData.custId,
          czipCode: mainData.czipCode,
          devision: mainData.devision,
          email: mainData.email,
          etc1: "",
          gubun: mainData.gubun,
          haddres: mainData.haddres,
          hbdcode: mainData.hbdcode,
          hpCheck: mainData.hpCheck,
          hzipCode: mainData.hzipCode,
          inDate: moment(mainData.inDate).format("YYYY-MM-DD"),
          jongmok: mainData.jongmok,
          kname: mainData.kname,
          // modDate: moment().format("YYYY-MM-DD"),
          // modid: decrypt("userInfo").loginId,
          outCheck: mainData.outCheck ? "Y" : "N",
          outDate: mainData.outDate,
          owner: mainData.owner,
          parSendCheck: mainData.parSendCheck,
          payAccount: mainData.payAccount,
          payBank: mainData.payBank,
          payName: mainData.payName,
          position: mainData.position,
          postCheck: mainData.postCheck,
          remark: mainData.remark,
          saupjaNo: mainData.saupjaNo,
          search: mainData.search,
          siseSendCheck: mainData.siseSendCheck,
          siteUrl: mainData.siteUrl,
          upte: mainData.upte,
          userid: mainData.userid,
          viewChk: mainData.viewChk,
          newCheck: mainData.newCheck,
        });
      }
    }
  };

  const btn_main_cancel = () => {
    if (mainData.custId === "0") {
      if (window.confirm("신규등록을 취소하시겠습니까?")) {
        setMainData({});
        setSubData([]);
      }
    } else {
      if (window.confirm("수정을 취소하시겠습니까?")) {
        supplierGetDetail.mutate({ custId: mainData.custId });
      }
    }
  };

  const btn_main_delete = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      supplierGetdelete.mutate({ custId: mainData.custId });
    }
  };

  const btn_sub_add = () => {
    if (subData.some(el => el.ilNo === "")) {
      alert("신규등록을 먼저 완료해주세요.");
      return;
    }

    setSubData(prev => [
      {
        custId: mainData.custId,
        ilNo: "",
        remark: "",
        talkDate: moment().format("YYYY-MM-DD"),
        talkName: decrypt("userInfo").loginId,
        newCheck: true,
      },
      ...prev,
    ]);

    setSubModifyList(prev => [true, ...prev]);
  };

  const btn_sub_save = () => {
    if (window.confirm("저장하시겠습니까?")) {
      if (subModifyList.every(el => el === false)) {
        alert("변경사항이 없습니다.");
        return;
      }

      const filter = subData.filter((el, idx) => subModifyList[idx]);

      supplierSubSave.mutate(
        filter.map(el => ({
          custId: el.custId,
          ilNo: el.ilNo,
          remark: el.remark,
          talkDate: moment(el.talkDate).format("YYYY-MM-DD"),
          talkName: el.talkName,
          newCheck: el.newCheck,
        }))
      );
    }
  };

  const btn_sub_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      supplierSubGetTel.mutate({ custId: mainData.custId });
    }
  };

  const btn_sub_delete = data => {
    if (window.confirm("삭제하시겠습니까?")) {
      supplierSubGetTelDelete.mutate([
        {
          custId: data.custId,
          ilNo: data.ilNo,
          talkDate: moment(data.talkDate).format("YYYY-MM-DD"),
          talkName: data.talkName,
        },
      ]);
    }
  };

  const openNewTab = () => {
    if (mainData.siteUrl)
      window.open("https://" + mainData.siteUrl, "_blank"); // 지정된 URL로 새 탭열기
    else alert("해당 업체의 인터넷 사이트가 존재하지 않습니다.");
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search_all();
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">거래처관리</div>
            <button
              className="btn btn-primary btn-table-filter me-20px"
              onClick={btn_newRegister}
              disabled={mainData.newCheck}
            >
              신규등록
            </button>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row" onKeyDown={pressEnter}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.searchType}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        searchType: e.target.value,
                      });
                    }}
                  >
                    <option value="1">업체명/성명</option>
                    <option value="2">분류별</option>
                    <option value="3">검색단어</option>
                    <option value="4">전화/팩스번호</option>
                    {/*<option value="5">조문문자</option>*/}
                    <option value="6">휴대폰</option>
                    {/*<option value="7">잡지발송</option>*/}
                    {/*<option value="8">시세발송</option>*/}
                    <option value="9">등급구분</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">검색어</label>
                  {searchData.searchType === "9" ? (
                    // 등급구분 검색조건
                    <select
                      value={searchData.class1}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          class1: e.target.value,
                        });
                      }}
                    >
                      {getClassSupplier.data?.map(item => {
                        return <option value={item.key}>{item.value}</option>;
                      })}
                    </select>
                  ) : searchData.searchType === "2" ? (
                    // 분류별 검색조건
                    <select
                      value={searchData.selectBox}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          selectBox: e.target.value,
                        });
                      }}
                    >
                      {getSupplier.data?.map(item => {
                        return <option value={item.key}>{item.value}</option>;
                      })}
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          keyword: e.target.value,
                        });
                      }}
                    />
                  )}
                </div>
                <div className="ms-2">
                  <input
                    type="checkbox"
                    id="chk-3"
                    className="form-check-input"
                    name="checkbox-option"
                    checked={searchData.outCheck === "N"}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        outCheck: e.target.checked ? "N" : "Y",
                      });
                    }}
                  />
                  <label className="filter1-label" htmlFor="chk-3">
                    제명제외
                  </label>
                </div>
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                  onClick={btn_search_all}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="tab1-row1-count">
                검색 결과: {searchedTableList[0]?.total ?? 0} 건 수
              </div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={tableLimit}
                  onChange={e => {
                    setTableLimit(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>
            <div
              className={`table-sticky mt-2
                ${tableLimit === "5" && "h-183"}
                ${tableLimit === "10" && "h-333"}
                ${tableLimit === "20" && "h-633"}`}
            >
              <div className="table-container">
                <table className="table table-bordered table-responsive my-table mt-0">
                  <colgroup>
                    <col width="5%" />
                    <col width="40%" />
                    <col width="40%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>업체명</th>
                      <th>성명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchedTableList?.length !== 0 ? (
                      <>
                        {searchedTableList?.map((el, index) => {
                          return (
                            <tr
                              className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
                                mainData?.custId === el?.custId ? "bg-blue" : ""
                              }`}
                              onClick={() => btn_tableRowClick(el)}
                            >
                              <td>{index + 1}</td>
                              <td>{el.company}</td>
                              <td>{el.kname}</td>
                            </tr>
                          );
                        })}
                        <div ref={setInfinityScroll} style={{ height: "1px" }} />
                      </>
                    ) : (
                      <tr>
                        <td colSpan={3}>검색 결과가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {mainData?.custId && (
          <>
            <div className="card card-main">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="tab1-row1-count w-50">
                    관리번호:{" "}
                    {mainData?.custId === "0"
                      ? "신규등록"
                      : mainData?.custId.replace(/(.{4})/, "$1-")}
                  </div>
                  <div className="tab1-row1-field1 w-50 justify-content-end">
                    {mainData?.custId !== "0" && decrypt("userInfo").userPermit === "99" && (
                      <button
                        className="btn btn-outline-danger tab1-row1-btn"
                        onClick={btn_main_delete}
                      >
                        삭제
                      </button>
                    )}

                    <button
                      className="btn btn-outline-primary tab1-row1-btn"
                      onClick={btn_main_save}
                    >
                      저장
                    </button>

                    <button
                      className="btn btn-outline-primary tab1-row1-btn"
                      onClick={btn_main_cancel}
                    >
                      취소
                    </button>
                  </div>
                </div>

                <table className="table table-bordered table-responsive my-table1 mt-2">
                  <tbody>
                    <tr>
                      <th style={{ width: "11.9%" }}>업체명</th>
                      <td colSpan={3} style={{ width: "32.15%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.company}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              company: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th style={{ width: "11.9%" }}>분류</th>
                      <td style={{ width: "16.075%" }}>
                        <select
                          className="form-select"
                          value={mainData.gubun}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              gubun: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled={true} selected={true}>
                            분류 선택
                          </option>
                          {getSupplier.data?.map(item => {
                            return <option value={item.key}>{item.value}</option>;
                          })}
                        </select>
                      </td>
                      <th style={{ width: "11.9%" }}>검색허용</th>
                      <td style={{ width: "16.075%" }}>
                        <select
                          className="form-select"
                          value={mainData.viewChk}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              viewChk: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled={true} selected={true}>
                            검색허용 선택
                          </option>
                          <option value="M">기획/경리팀전용</option>
                          <option value="A">전체공유</option>
                          <option value="D">거부</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>담당자</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.kname ?? ""}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              kname: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>등급</th>
                      <td>
                        <select
                          className="form-select"
                          value={mainData.clazz}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              clazz: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled={true} selected={true}>
                            등급 선택
                          </option>
                          {getClassSupplier.data?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <th>직위</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData.position}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              position: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>직장전화</th>
                      <td style={{ width: "11.12%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData.ctel}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              ctel: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th className="f-roboto-medium" style={{ width: "9.9%" }}>
                        FAX
                      </th>
                      <td style={{ width: "11.12%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData.cfax}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              cfax: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>부서명</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.devision}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              devision: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>휴대폰</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData.cellular}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              cellular: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>자택전화</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData.classTel}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              classTel: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>전자우편</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.email}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              email: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>등록일자</th>
                      <td>
                        <input
                          type="date"
                          max="9999-12-31"
                          className="form-control table-input"
                          value={mainData.inDate}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              inDate: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>우편수취</th>
                      <td colSpan={3}>
                        <select
                          className="form-select"
                          value={mainData.postCheck}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              postCheck: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled={true} selected={true}>
                            우편수취 선택
                          </option>
                          <option value="1">직장</option>
                          <option value="2">자택</option>
                          <option value="N">미지정</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td colSpan={3}>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control table-input"
                            value={mainData?.siteUrl}
                            onChange={e => {
                              setMainData({
                                ...mainData,
                                siteUrl: e.target.value,
                              });
                            }}
                          />
                          <a
                            href={void 0}
                            onClick={e => {
                              e.stopPropagation();
                              openNewTab();
                            }}
                          >
                            <img
                              className="h-25px float-end"
                              src={process.env.PUBLIC_URL + "/images/icon-arrow-btn.png"}
                              alt=""
                            />
                          </a>
                        </div>
                      </td>
                      <th>비고</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.remark}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              remark: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>사업자번호</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.saupjaNo}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              saupjaNo: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>업태</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.upte}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              upte: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>종목</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.jongmok}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              jongmok: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>계좌은행</th>
                      <td>
                        <select
                          className="form-select"
                          value={mainData.payBank}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              payBank: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled={true} selected={true}>
                            계좌은행 선택
                          </option>
                          {getBank.data?.map(el => {
                            return <option value={el.key}>{el.value}</option>;
                          })}
                        </select>
                      </td>
                      <th>계좌번호</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.payAccount}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              payAccount: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>예금주</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.payName}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              payName: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>대표자</th>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.owner}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              owner: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>검색단어</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.search}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              search: e.target.value,
                            });
                          }}
                        />
                      </td>
                      <th>비고</th>
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={mainData?.classRemark}
                          onChange={e => {
                            setMainData({
                              ...mainData,
                              classRemark: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  className="d-flex align-items-center justify-content-between mb-3"
                  onClick={() => {
                    setAddressIsOpen(prev => [!prev[0], prev[1]]);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="table-tabs-sub mb-0">직장주소</div>
                  <div className="link-expand">
                    {addressIsOpen[0] ? "접기" : "펼치기"}
                    <img
                      className={`ms-2 ${addressIsOpen[0] && "active"}`}
                      src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                      alt=""
                    />
                  </div>
                </div>

                {addressIsOpen[0] && (
                  <table className="table table-bordered table-responsive my-table1">
                    <tbody>
                      <tr>
                        <th style={{ width: "11.9%" }}>도로명주소</th>
                        <td style={{ width: "11.12%" }} className="border-end-0">
                          <div className="d-flex">
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.czipCode}
                            />
                            <DaumPostcode
                              onCompleteCallback={data => {
                                setMainData({
                                  ...mainData,
                                  czipCode: data.zonecode,
                                  caddresTxt: data.fullAddr,
                                  cbdcode: data.bdCode,
                                });
                              }}
                            />
                          </div>
                        </td>
                        <td className="border-start-0 ps-0">
                          <div className="flex-center-between">
                            <input
                              type="text"
                              className="form-control table-input input-address2 m-0"
                              value={mainData?.caddresTxt}
                              readOnly={true}
                            />
                            <input
                              type="text"
                              className="form-control table-input input-address2 mx-3px me-0"
                              value={mainData.caddres}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  caddres: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                <div
                  className="d-flex align-items-center justify-content-between mb-3"
                  onClick={() => {
                    setAddressIsOpen(prev => [prev[0], !prev[1]]);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="table-tabs-sub mb-0">자택주소</div>
                  <div className="link-expand">
                    {addressIsOpen[1] ? "접기" : "펼치기"}
                    <img
                      className={`ms-2 ${addressIsOpen[1] && "active"}`}
                      src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                      alt=""
                    />
                  </div>
                </div>

                {addressIsOpen[1] && (
                  <table className="table table-bordered table-responsive my-table1 mt-0">
                    <tbody>
                      <tr>
                        <th style={{ width: "11.9%" }}>도로명주소</th>
                        <td className="border-end-0" style={{ width: "11.12%" }}>
                          <div className="d-flex">
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.hzipCode}
                            />
                            <DaumPostcode
                              onCompleteCallback={data => {
                                setMainData({
                                  ...mainData,
                                  hzipCode: data.zonecode,
                                  haddresTxt: data.fullAddr,
                                  hbdcode: data.bdCode,
                                });
                              }}
                            />
                          </div>
                        </td>
                        <td className="border-start-0 ps-0">
                          <div className="flex-center-between">
                            <input
                              type="text"
                              className="form-control table-input input-address2 m-0"
                              value={mainData?.haddresTxt}
                              readOnly={true}
                            />
                            <input
                              type="text"
                              className="form-control table-input input-address2 mx-3px me-0"
                              value={mainData.haddres}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  haddres: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                <div className="mb-2" style={{ borderTop: "1px solid #c5c5c5" }} />
                <table className="table table-bordered table-responsive my-table1 mb-5">
                  <tbody>
                    <tr>
                      <th style={{ width: "11.9%" }}>
                        <input
                          type="checkbox"
                          className="form-check-input ms-2"
                          name="checkbox-option"
                          checked={mainData.outCheck}
                          onChange={e => {
                            if (e.target.checked) {
                              setMainData({
                                ...mainData,
                                outDate: moment().format("YYYY-MM-DD"),
                                outCheck: true,
                              });
                            } else {
                              setMainData({
                                ...mainData,
                                outDate: null,
                                outCheck: false,
                              });
                            }
                          }}
                        />
                        제명여부 &nbsp;
                      </th>
                      <th style={{ width: "10%" }}>제명일시</th>
                      <td style={{ width: "20%" }}>
                        {mainData.outCheck && (
                          <input
                            type="date"
                            className="form-control table-input"
                            value={mainData?.outDate}
                            onChange={e => {
                              setMainData({
                                ...mainData,
                                outDate: e.target.value,
                              });
                            }}
                            disabled={!mainData.outCheck}
                            max="9999-12-31"
                          />
                        )}
                      </td>
                      <td colSpan={2}>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {mainData.custId !== "0" && (
              <Tabs
                id="controlled-tab-example2"
                activeKey="통화내역"
                // onSelect={k => setKey1(k)}
                className="tab-blocks"
              >
                <Tab eventKey="통화내역" title="통화내역">
                  <div className="tab2-content-container px-20px">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="table-tabs-sub m-0">
                        통화기록 (<span>{subData.length ?? "0"}</span>건)
                      </div>
                      <div className="d-flex">
                        <button className="btn btn-primary" onClick={btn_sub_add}>
                          등록
                        </button>
                        <button className="btn btn-outline-primary ms-8px" onClick={btn_sub_save}>
                          저장
                        </button>
                        <button className="btn btn-outline-primary ms-8px" onClick={btn_sub_cancel}>
                          취소
                        </button>
                      </div>
                    </div>

                    {/*<div className="table-container">*/}
                    <div
                      className="table-container table-sticky mt-15px"
                      style={{ maxHeight: "443px" }}
                    >
                      <table className="table table-bordered table-responsive my-table mt-0">
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}>연번</th>
                            <th style={{ width: "15.38%" }}>통화일자</th>
                            <th style={{ width: "15.38%" }}>통화자</th>
                            <th>통화내용</th>
                            <th style={{ width: "5%" }}>삭제</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subData.length !== 0 ? (
                            subData?.map((el, index) => (
                              <tr>
                                <td>{el.ilNo}</td>
                                <td>
                                  <input
                                    type="date"
                                    className="form-control table-input"
                                    value={el.talkDate}
                                    onChange={e => {
                                      const newSubData = [...subData];
                                      newSubData[index].talkDate = e.target.value;
                                      setSubData(newSubData);

                                      const newModifyList = [...subModifyList];
                                      newModifyList[index] = true;
                                      setSubModifyList(newModifyList);
                                    }}
                                    max="9999-12-31"
                                  />
                                </td>
                                <td>
                                  {getUserIdQ.data?.find(el2 => el2.key === el.talkName)?.value}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={el.remark}
                                    onChange={e => {
                                      const newSubData = [...subData];
                                      newSubData[index].remark = e.target.value;
                                      setSubData(newSubData);

                                      const newModifyList = [...subModifyList];
                                      newModifyList[index] = true;
                                      setSubModifyList(newModifyList);
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    className="cursor-pointer"
                                    src={process.env.PUBLIC_URL + "/images/delete.png"}
                                    onClick={() => btn_sub_delete(el)}
                                    alt=""
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>데이터가 없습니다.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            )}
          </>
        )}
      </div>
    </>
  );
};
