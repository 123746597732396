import React from "react";
import { useAtom } from "jotai/index";
import {
  carDetail_atom,
  carDriveInfo_atom,
  clickedRow_atom,
  isRegisterMode_atom,
} from "../../../../data/atom/dataManage/carManage/driveInput_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment/moment";

const DriveInputDetail = props => {
  const { searchDriveInfo, drveUserList } = props;
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);
  const [driveInfo, setDriveInfo] = useAtom(carDriveInfo_atom);
  const [isRegisterMode, setIsRegisterMode] = useAtom(isRegisterMode_atom);

  const moveGubnOption = [
    { value: "1", label: "업무" },
    { value: "9", label: "출퇴근" },
  ];

  const deleteCarDetail = useMutation(
    data => axios.post("/api/data/carDriveManage/deleteDrive", data),
    {
      onSuccess: res => {
        searchDriveInfo.mutate(clickedRow);
      },
    }
  );
  const saveCarDetail = useMutation(
    data => axios.post("/api/data/carDriveManage/saveDrive", data),
    {
      onSuccess: (res, variables) => {
        if (isRegisterMode) {
          setIsRegisterMode(false);
          searchDriveInfo.mutate(carDetail);
          setClickedRow(carDetail);
        } else {
          searchDriveInfo.mutate(carDetail);
        }
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    if (
      key === "statMile" ||
      key === "enddMile" ||
      key === "drveMile" ||
      key === "gateCost" ||
      key === "passCost" ||
      key === "charAmnt" ||
      key === "highPass"
    ) {
      value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));
    }
    setCarDetail({ ...carDetail, [key]: value });
  };

  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (carDetail.drveStat === "Invalid date" || carDetail.drveStat === "") {
        alert("운행시작일시를 입력해주세요");
      } else if (carDetail.drveEndd === "Invalid date" || carDetail.drveEndd === "") {
        alert("운행종료일시를 입력해주세요");
      } else if (carDetail.drveUser === "") {
        alert("운행자를 선택해주세요");
      } else if (carDetail.statSite?.trim() === "") {
        alert("출발지를 입력해주세요");
      } else if (carDetail.arrvSite?.trim() === "") {
        alert("목적지를 입력해주세요");
      } else if (carDetail.drveTagt?.trim() === "") {
        alert("운행목적을 입력해주세요");
      } else {
        saveCarDetail.mutate([
          {
            ...carDetail,
            drveStat: moment(carDetail.drveStat).format("YYYY-MM-DD HH:mm"),
            drveEndd: moment(carDetail.drveEndd).format("YYYY-MM-DD HH:mm"),
          },
        ]);
      }
      // saveCarDetail.mutate([
      //   {
      //     ...carDetail,
      //     drveStat: moment(carDetail.drveStat).format("YYYY-MM-DD HH:mm"),
      //     drveEndd: moment(carDetail.drveEndd).format("YYYY-MM-DD HH:mm"),
      //   },
      // ]);
    }
  };
  const btnDelete = () => {
    if (confirm("삭제하시겠습니까?")) {
      deleteCarDetail.mutate(carDetail);
    }
  };
  const btnCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      setIsRegisterMode(false);
      searchDriveInfo.mutate(clickedRow);
    }
  };

  return (
    <div className="card card-main mt-4">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <div className="table-tabs-sub w-50 m-0">운행 정보</div>
          <div className="flex-center-end">
            {isRegisterMode ? (
              <button className="btn btn-outline-primary ms-8px" onClick={btnCancel}>
                취소
              </button>
            ) : (
              <button className="btn btn-outline-primary ms-8px" onClick={btnDelete}>
                삭제
              </button>
            )}
            <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
              저장
            </button>
          </div>
        </div>
        <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
          <tbody>
            <tr>
              <th style={{ width: "7%" }}>차량번호</th>
              <td style={{ width: "18%" }}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.carrNumb ? carDetail?.carrNumb : ""}
                  onChange={e => onChangeEventHandler("carrNumb", e.target.value)}
                  readOnly={true}
                  style={{ border: "0" }}
                />
              </td>
              <th style={{ width: "7%" }}>차명</th>
              <td style={{ width: "18%" }}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.carrName ? carDetail?.carrName : ""}
                  onChange={e => onChangeEventHandler("carrName", e.target.value)}
                  readOnly={true}
                  style={{ border: "0" }}
                />
              </td>
              <th style={{ width: "7%" }}>운행시작일시</th>
              <td style={{ width: "18%" }}>
                <input
                  type="datetime-local"
                  className="form-control filter-input h-25px"
                  value={carDetail?.drveStat ? carDetail?.drveStat : ""}
                  onChange={e => onChangeEventHandler("drveStat", e.target.value)}
                  min={"1900-01-01 00:00"}
                  max={"2099-12-31 23:59"}
                  readOnly={!isRegisterMode}
                  style={!isRegisterMode ? { border: "0" } : { borderColor: "#C5C5C5" }}
                />
              </td>
              <th style={{ width: "7%" }}>운행종료일시</th>
              <td>
                <input
                  type="datetime-local"
                  className="form-control filter-input h-25px"
                  value={carDetail?.drveEndd ? carDetail?.drveEndd : ""}
                  onChange={e => onChangeEventHandler("drveEndd", e.target.value)}
                  style={{ borderColor: "#C5C5C5" }}
                  min={"1900-01-01 00:00"}
                  max={"2099-12-31 23:59"}
                />
              </td>
            </tr>
            <tr>
              <th>운행자</th>
              <td>
                <select
                  className="form-select"
                  value={carDetail?.drveUser ? carDetail?.drveUser : ""}
                  onChange={e => onChangeEventHandler("drveUser", e.target.value)}
                >
                  {drveUserList.map(el => (
                    <option value={el.value}>{el.label}</option>
                  ))}
                  <option hidden={true} value=""></option>
                </select>
              </td>
              <th>운행구분</th>
              <td>
                <select
                  className="form-select"
                  value={carDetail?.moveGubn ? carDetail?.moveGubn : ""}
                  onChange={e => onChangeEventHandler("moveGubn", e.target.value)}
                >
                  {moveGubnOption.map(el => (
                    <option value={el.value}>{el.label}</option>
                  ))}
                </select>
              </td>
              <th>출 발 지</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.statSite ? carDetail?.statSite : ""}
                  onChange={e => onChangeEventHandler("statSite", e.target.value)}
                />
              </td>
              <th>목 적 지</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.arrvSite ? carDetail?.arrvSite : ""}
                  onChange={e => onChangeEventHandler("arrvSite", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>운행목적</th>
              <td colSpan={7}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.drveTagt ? carDetail?.drveTagt : ""}
                  onChange={e => onChangeEventHandler("drveTagt", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th colSpan={2}>정산거리계</th>
              <th>출발전</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.statMile ? carDetail?.statMile?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("statMile", e.target.value)}
                />
              </td>
              <th>도착후</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.enddMile ? carDetail?.enddMile?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("enddMile", e.target.value)}
                />
              </td>
              <th>주행거리</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.drveMile ? carDetail?.drveMile?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("drveMile", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>통행료 현금</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.gateCost ? carDetail?.gateCost?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("gateCost", e.target.value)}
                />
              </td>
              <th>통행료 하이패스</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.passCost ? carDetail?.passCost?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("passCost", e.target.value)}
                />
              </td>
              <th className="px-0" style={{ fontSize: "13px" }}>
                하이패스 충전금액
              </th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.charAmnt ? carDetail?.charAmnt?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("charAmnt", e.target.value)}
                />
              </td>
              <th>하이패스 잔액</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.highPass ? carDetail?.highPass?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("highPass", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>비 고</th>
              <td colSpan={7}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.remark ? carDetail?.remark : ""}
                  onChange={e => onChangeEventHandler("remark", e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(DriveInputDetail);
