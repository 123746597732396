import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
export const InfomationChangeHistory = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  const getUserIdQ = useGetUserIdQ();

  // 정보변동내역 조회
  const customerGetInfoHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getInfoHistory", {
        params: {
          seqNo: data,
        },
      }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetInfoHistory.mutate(mainData.seqNo);
  }, [mainData.seqNo]);

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">
          정보변동내역 (<span>{subData.length ?? "0"}</span>건)
        </div>
      </div>
      <div className="overflow-auto table-container table-sticky h-443 mt-10">
        <table
          className="table table-bordered table-responsive my-table mt-0"
          style={{
            width: `${subData.length !== 0 ? "2200px" : ""}`,
          }}
        >
          <colgroup>
            <col width="3%" />
            <col width="6%" />
            <col width="10%" />
            <col width="6%" />
            <col width="5%" />
            <col width="9%" />
            <col width="19%" />
            <col width="12%" />
            <col width="12%" />
            <col width="9%" />
            <col width="9%" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>관리번호</th>
              <th>회사명</th>
              <th>성명</th>
              <th>직위</th>
              <th>수정사유</th>
              <th>항목</th>
              <th>수정 전 data</th>
              <th>수정 후 data</th>
              <th>수정일시</th>
              <th>수정자</th>
            </tr>
          </thead>
          <tbody>
            {subData.length !== 0 ? (
              subData?.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div>{index + 1}</div>
                    </td>
                    <td>{el.custId.replace(/(.{4})/, "$1-")}</td>
                    <td>{el.company}</td>
                    <td>{el.kname}</td>
                    <td>{el.position}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={el?.changeCause}
                        readOnly={true}
                      />
                    </td>
                    <td>{el.columnName}</td>
                    <td>{el.orgValueName}</td>
                    <td>{el.cngValueName}</td>
                    <td>{el?.changeDate ? moment(el.changeDate).format("YYYY-MM-DD") : ""}</td>
                    <td>{getUserIdQ.data?.find(el2 => el2.key === el.modId)?.value}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={11} className="text-center">
                  검색된 정보변동내역이 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
