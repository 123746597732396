import { atom } from "jotai";
import moment from "moment";

export const annualApplicationPreparation_submitData = atom({
  endDate: moment(new Date()).format("YYYY-MM-DD"),
  remark: "",
  sawonId: "",
  workDate: moment(new Date()).format("YYYY-MM-DD"),
  workgubn: "11",
  yunchaDay: 0,
});
export const annualApplicationPreparation_year = atom("");
export const annualApplicationPreparation_assign = atom(0); // 0 - 안보임, 1 - 상신, 2 - 상세
export const annualApplicationPreparation_row_atom = atom("5");
export const annualApplicationPreparation_isAllDay = atom(true);
export const annualApplicationPreparation_isHalfInput = atom(false);
export const annualApplicationPreparation_getDetailData = atom({
  yunchaInfo: {
    drftSn: 0,
    kname: "",
    yunchaType: "",
    remark: "",
    dept: "",
    auth: "",
    job: "",
    confmer: "",
    confmStatus: "",
    yunchaDay: 0,
    workDate: "",
    endDate: "",
    applyDate: "",
  },
  confmList: [],
});
export const annualApplicationPreparation_drftnSn = atom("");
