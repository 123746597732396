import React, { useEffect } from "react";
import { useAtom } from "jotai/index";
import {
  carDetail_atom,
  carDriveInfo_atom,
  carFuelInfo_atom,
  clickedRow_atom,
  isRegisterMode_atom,
  searchParam_atom,
} from "../../../../data/atom/dataManage/carManage/carMaster_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useGetCarMasterClassList } from "../../../../api/code/useGetCarMasterClassList";
import { useGetCarMasterJisaList } from "../../../../api/code/useGetCarMasterJisaList";

const CarMasterDetail = props => {
  const { searchCarList, getCarMasterOilList } = props;
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);
  const [driveInfo, setDriveInfo] = useAtom(carDriveInfo_atom);
  const [fuelInfo, setFuelInfo] = useAtom(carFuelInfo_atom);
  const [isRegisterMode, setIsRegisterMode] = useAtom(isRegisterMode_atom);

  const getCarMasterClassList = useGetCarMasterClassList();
  const getCarMasterJisaList = useGetCarMasterJisaList();
  const searchCarDetail = useMutation(
    data => axios.get("/api/data/carMaster/getDetail", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result?.[0];

        setCarDetail(data);
      },
    }
  );
  const deleteCarDetail = useMutation(data => axios.post("/api/data/carMaster/delete", data), {
    onSuccess: res => {
      searchCarList.mutate(searchParam);
      setClickedRow({});
      setCarDetail({});
      setDriveInfo([]);
      setFuelInfo([]);
    },
  });
  const saveCarDetail = useMutation(data => axios.post("/api/data/carMaster/saveDetail", data), {
    onSuccess: (res, variables) => {
      if (isRegisterMode) {
        setIsRegisterMode(false);
      }

      searchCarList.mutate(searchParam);
      searchCarDetail.mutate(variables);
    },
  });

  useEffect(() => {
    if (clickedRow.carrNumb !== undefined) {
      searchCarDetail.mutate(clickedRow);
    }
  }, [clickedRow]);

  const onChangeEventHandler = (key, value) => {
    if (key === "carrSize" || key === "guipPric") {
      value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));
    } else if (key === "carrYear") {
      value = value.replace(/[^0-9]/g, "");
    } else if (key === "fuelLter") {
      value = value.replace(/,/g, "").replace(/[^0-9.]/g, "");
    }
    setCarDetail({ ...carDetail, [key]: value });
  };

  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (carDetail.carrNumb.trim() === "") {
        alert("차량번호를 입력해주세요.");
      } else if (carDetail.ownrGubn.trim() === "") {
        alert("소유구분을 선택해주세요.");
      } else if (carDetail.carrClas === "") {
        alert("차종을 선택해주세요.");
      } else if (carDetail.engrGubn.trim() === "") {
        alert("연료구분을 선택해주세요.");
      } else if (carDetail.jisaName === "") {
        alert("관리지사를 선택해주세요.");
      } else if (carDetail.usesName.trim() === "") {
        alert("사용자를 입력해주세요.");
      } else {
        saveCarDetail.mutate(carDetail);
      }
    }
  };
  const btnDelete = () => {
    if (confirm("삭제하시겠습니까?")) {
      deleteCarDetail.mutate(carDetail);
    }
  };
  const btnCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      setIsRegisterMode(false);
      setCarDetail({});
    }
  };

  return (
    <>
      <div className="card card-main mt-4">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <div className="table-tabs-sub w-50 m-0">차량 정보</div>
            <div className="flex-center-end">
              {isRegisterMode ? (
                <button className="btn btn-outline-primary ms-8px" onClick={btnCancel}>
                  취소
                </button>
              ) : (
                <button className="btn btn-outline-primary ms-8px" onClick={btnDelete}>
                  삭제
                </button>
              )}
              <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
                저장
              </button>
            </div>
          </div>
          <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
            <tbody>
              <tr>
                <th style={{ width: "7%" }}>차량번호</th>
                <td style={{ width: "18%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={carDetail.carrNumb}
                    readOnly={!isRegisterMode}
                    onChange={e => onChangeEventHandler("carrNumb", e.target.value)}
                    style={!isRegisterMode ? { border: "0" } : {}}
                  />
                </td>
                <th style={{ width: "7%" }}>소유구분</th>
                <td style={{ width: "18%" }}>
                  <select
                    className="form-select"
                    value={carDetail.ownrGubn}
                    onChange={e => onChangeEventHandler("ownrGubn", e.target.value)}
                  >
                    <option value="R">렌트</option>
                    <option value="L">리스</option>
                    <option value="C">법인</option>
                    <option value="O">자차</option>
                    <option value="" hidden={true}></option>
                  </select>
                </td>
                <th style={{ width: "7%" }}>차종</th>
                <td style={{ width: "18%" }}>
                  <select
                    className="form-select"
                    value={carDetail.carrClas}
                    onChange={e => onChangeEventHandler("carrClas", e.target.value)}
                  >
                    {getCarMasterClassList.data?.map(el => {
                      return <option value={el.key}>{el.value}</option>;
                    })}
                    <option value="" hidden={true}></option>
                  </select>
                </td>
                <th style={{ width: "7%" }}>차명</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={carDetail.carrName}
                    onChange={e => onChangeEventHandler("carrName", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>연식</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={carDetail.carrYear}
                    onChange={e => onChangeEventHandler("carrYear", e.target.value)}
                  />
                </td>
                <th>차대번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={carDetail.carrSral}
                    onChange={e => onChangeEventHandler("carrSral", e.target.value)}
                  />
                </td>
                <th>연료구분</th>
                <td>
                  <select
                    className="form-select"
                    value={carDetail.engrGubn}
                    onChange={e => onChangeEventHandler("engrGubn", e.target.value)}
                  >
                    {getCarMasterOilList.data?.map(el => {
                      return <option value={el.key}>{el.value}</option>;
                    })}
                    <option value="" hidden={true}></option>
                  </select>
                </td>
                <th>연비(L당)</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    value={carDetail.fuelLter}
                    onChange={e => onChangeEventHandler("fuelLter", e.target.value)}
                    onBlur={e => {
                      let value = e.target.value.replace(/[^0-9.]/g, "");
                      if (!value.includes(".")) {
                        value += ".00";
                      } else {
                        const [whole, decimal] = value.split(".");
                        if (!decimal) {
                          value = whole + ".00";
                        }
                        value = Number(value).toFixed(2);
                      }
                      setCarDetail({ ...carDetail, fuelLter: value >= 1000 ? "999.99" : value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>배기량(cc)</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    value={carDetail.carrSize?.toLocaleString()}
                    onChange={e => onChangeEventHandler("carrSize", e.target.value)}
                  />
                </td>
                <th>구입일자</th>
                <td>
                  <input
                    type="date"
                    className="form-control table-input"
                    value={carDetail.guipDate}
                    onChange={e => onChangeEventHandler("guipDate", e.target.value)}
                  />
                </td>
                <th>구입가격</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input text-end"
                    value={carDetail.guipPric?.toLocaleString()}
                    onChange={e => onChangeEventHandler("guipPric", e.target.value)}
                  />
                </td>
                <th>사용유무</th>
                <td>
                  <select
                    className="form-select"
                    value={carDetail.usesFlag}
                    onChange={e => onChangeEventHandler("usesFlag", e.target.value)}
                  >
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>관리지사</th>
                <td>
                  <select
                    className="form-select"
                    value={carDetail.jisaName}
                    onChange={e => onChangeEventHandler("jisaName", e.target.value)}
                  >
                    {getCarMasterJisaList.data?.map(el => {
                      return <option value={el.value}>{el.value}</option>;
                    })}
                    <option value="" hidden={true}></option>
                  </select>
                </td>
                <th>사용자</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={carDetail.usesName}
                    onChange={e => onChangeEventHandler("usesName", e.target.value)}
                  />
                </td>
                <th>비고</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={carDetail.remark}
                    onChange={e => onChangeEventHandler("remark", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(CarMasterDetail);
