import { atom } from "jotai";
import moment from "moment";

export const orderManagementStatus_searchData_atom_init = {
  frontDate: moment().subtract(2, "month").format("YYYY-MM-DD"),
  // frontDate: "2023-01-01",
  backDate: moment().format("YYYY-MM-DD"),
  // backDate: "2023-05-30",
  completeKind: "0",
  gubun3: "0",
  jisaGubn: "99",
  member: "",
  statusFlag: "0",
  suppleKind: "3",
  userName: "",
  page: 1,
};

export const orderManagementStatus_searchData_atom = atom(
  orderManagementStatus_searchData_atom_init
);

export const orderManagementStatus_medoTable_atom = atom([]);
export const orderManagementStatus_mesuTable_atom = atom([]);
