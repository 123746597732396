import { atom } from "jotai";
import moment from "moment/moment";

export const searchParamInit = {
  fromDate: moment().set({ month: 0, date: 1 }).format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD"),
  jisa: "99",
  gubun1: "9",
  gubun2: "1",
  taxGubun: "Y",
  kname: "",
  page: 1,
};
export const defaultConditionInit = {
  yangdo: 1,
  gongje: 2500000,
  singo: "A",
  napbu: "",
  sungsilCheck: false,
  sungsil: "",
  gasanCheck: false,
};
export const medojaInit = {
  kname: "",
  juminno: "",
  juso: "",
  jusoTxt: "",
  num1: 0,
  numFrom: 0,
  numTo: 0,
  num2: 0,
  semusu: "",
  semusuYn: false,
  zipcode: "",
};
export const paybackInit = {
  bankName: "99",
  account: "",
};
export const ydDivideInit = {
  flag: false,
  input1: 0,
  input2: 0,
};
export const finalTaxInit = {
  yangdo: 0,
  gasan: 0,
  jumin: 0,
  taxSum: 0,
  napbuEndDate: "",
  overtime: "",
  sinDate: moment().format("YYYY-MM-DD"),
};
export const ydInputInit = {
  tradeGubun: "",
  objectName: "",
  transferDate: "",
  transferAmnt: 0,
  acquireDate: "",
  acquireAmnt: 0,
  expenseAmnt: 0,
  expenseItem: "",
};
export const resultListInit = {
  needCost: 0,
  ydChaik: 0,
};
export const resultInit = {
  resultList: [resultListInit, resultListInit, resultListInit, resultListInit, resultListInit],
  yangdoSum: 0,
  gwasePyojun: 0,
  seyul: 0,
  sanchulCost: 0,
  gammyunCost: 0,
  nujinCost: 0,
  tenGongje: 0,
  gasanCost: 0,
  napbuCost: 0,
  hwangupCost: 0,
  sanchulJuminCost: 0,
  calcReasonText: "",
};

export const currentRow_atom = atom({});
export const searchParam_atom = atom(searchParamInit);
export const searchedParam_atom = atom({});
export const tableRowLimit_atom = atom("5");
export const transferTaxList_atom = atom([]);
export const defaultCondition_atom = atom(defaultConditionInit);
export const medoja_atom = atom(medojaInit);
export const mesuja_atom = atom([]);
export const payback_atom = atom(paybackInit);
export const ydDivide_atom = atom(ydDivideInit);
export const finalTax_atom = atom(finalTaxInit);
export const ydInput_atom = atom([
  // {
  //   tradeGubun: "1",
  //   objectName: "1103",
  //   transferDate: moment("2023-01-01").format("YYYY-MM-DD"),
  //   transferAmnt: 10000000,
  //   acquireDate: moment("1999-01-01").format("YYYY-MM-DD"),
  //   acquireAmnt: 10000,
  //   expenseAmnt: 0,
  //   expenseItem: "",
  // },
  ydInputInit,
  ydInputInit,
  ydInputInit,
  ydInputInit,
  ydInputInit,
]);
export const result_atom = atom(resultInit);
export const ydPrintParam_atom = atom({});
export const ocrPrintParam_atom = atom({});
export const ocrNapbuPrintParam_atom = atom({});
export const juminPrintParam_atom = atom({});
