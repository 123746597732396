import React, { useRef } from "react";
import {
  searchParam_atom,
  searchResult_atom,
} from "../../../../data/atom/workManage/golfManage/golfManage_atom";
import { useAtomValue } from "jotai";
import { useVirtualizer } from "@tanstack/react-virtual";
import SearchResultTableRow from "./SearchResultTableRow/SearchResultTableRow";
import { useAtom } from "jotai/index";

const SearchResultTable = props => {
  const { scrollEndRef } = props;
  const searchResult = useAtomValue(searchResult_atom);
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);

  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: searchResult.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  // 보기갯수 셀렉트 변경 이벤트
  const select_onChangeEvent = value => {
    setSearchParam(prev => ({ ...prev, rowCountOnce: value }));
  };

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="table-container">
          <div className="d-flex align-items-center justify-content-end">
            <select
              className="form-select filter-perpage ms-3"
              value={searchParam.rowCountOnce}
              onChange={e => select_onChangeEvent(e.target.value)}
            >
              <option value="5">5개씩 보기</option>
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
            </select>
          </div>
          <div
            ref={targetRef}
            className={`table-container table-sticky mt-10px
             ${searchParam.rowCountOnce === "5" && "h-183"}
             ${searchParam.rowCountOnce === "10" && "h-333"}
             ${searchParam.rowCountOnce === "20" && "h-633"}`}
          >
            <table
              className="table table-bordered table-responsive my-table"
              style={{
                marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                marginBottom: `${
                  rowVirtualizer.getTotalSize() -
                  (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                  rowVirtualizer.getVirtualItems().length * 30
                }px`,
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>No</th>
                  <th style={{ width: "60%" }}>골프장명</th>
                  <th style={{ width: "5%" }}>회</th>
                  <th style={{ width: "5%" }}>퍼</th>
                  <th style={{ width: "5%" }}>파3</th>
                  <th style={{ width: "20%" }}>소재지</th>
                </tr>
              </thead>
              <tbody>
                {searchResult.length !== 0 ? (
                  rowVirtualizer.getVirtualItems().map(item => {
                    if (item.index !== searchResult.length - 1)
                      return (
                        <SearchResultTableRow
                          data={searchResult[item.index]}
                          index={item.index}
                          key={item.key}
                        />
                      );
                    else {
                      return (
                        <>
                          <div ref={scrollEndRef}></div>
                          <SearchResultTableRow
                            data={searchResult[item.index]}
                            index={item.index}
                            key={item.key}
                          />
                        </>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      검색된 골프장이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SearchResultTable);
