import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import SearchResultTable from "./SearchResultTable/SearchResultTable";
import TableButtonPanel from "./TableButtonPanel/TableButtonPanel";
import { useGetIndividualGolfList } from "../../../../api/code/useGetIndividualGolfList";
import { useGetPartner } from "../../../../api/code/useGetPartner";
import { useGetAcquisition } from "../../../../api/code/useGetAcquisition";
import {
  editedSearchResult_atom,
  originSearchResult_atom,
  searchParam_atom,
  searchParam_init,
} from "../../../../data/atom/dataManage/golfMembershipPriceManage/individualMembershipPrice_atom";
import { useAtom, useSetAtom } from "jotai/index";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { useLocation } from "react-router-dom";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import { decrypt } from "../../../../util/decrypt/decrypt";

const convertSiseProcess = value => {
  switch (value) {
    case "5":
      return "2";
    case "6":
      return "1";
    case "9":
      return "6";
    case "N":
      return "5";
    default:
      return value;
  }
};

export const IndividualMembershipPrice = () => {
  const { addTabClosedEvent } = useRecentTabEvent();
  const location = useLocation();
  const keywordInputRef = React.useRef(null);
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);

  // searchResult data저장 변수들
  const setOriginSearchResult = useSetAtom(originSearchResult_atom);
  const setEditedSearchResult = useSetAtom(editedSearchResult_atom);

  useGetPartner();
  useGetUserIdQ();
  useGetAcquisition();
  const individualGolfList = useGetIndividualGolfList();

  const search = useMutation(
    data =>
      axios.get("/api/data/individual/search", {
        params: data,
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setOriginSearchResult(result);
        setEditedSearchResult(result);
        setSearchParam(prev => ({ ...prev, lastSearchType: prev.searchType }));
      },
    }
  );

  const btn_search = () => {
    let tmp = { ...searchParam, page: 1 };
    setSearchParam(tmp);
    search.mutate(tmp);
  };

  const onChangeSearchParam = (key, value) => {
    setSearchParam({ ...searchParam, [key]: value });
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  useEffect(() => {
    if (!searchParam.init) {
      setSearchParam(prev => ({
        ...prev,
        init: true,
        searchType: convertSiseProcess(decrypt("userInfo").siseProcess),
        lastSearchType: convertSiseProcess(decrypt("userInfo").siseProcess),
      }));
    }
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...searchParam_init });
      setOriginSearchResult([]);
      setEditedSearchResult([]);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">개인회원권시세</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색 조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.searchType}
                    onChange={e => onChangeSearchParam("searchType", e.target.value)}
                  >
                    <option value="5">전체종목</option>
                    <option value="1">중부권</option>
                    <option value="2">호남/제주권</option>
                    <option value="3">대구권</option>
                    <option value="4">부산권</option>
                    <option value="6">종목별</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">기준일</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.date}
                    max={"9999-12-31"}
                    onChange={e => onChangeSearchParam("date", e.target.value)}
                    onKeyDown={e => {
                      if (e.key === "Enter") keywordInputRef.current.focus();
                    }}
                  />
                </div>
                <div className="filter1-field filter1-field3">
                  <label className="filter1-label">회원권명</label>
                  {searchParam.searchType !== "6" ? (
                    <input
                      ref={keywordInputRef}
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchParam.keyword}
                      onChange={e => onChangeSearchParam("keyword", e.target.value)}
                      onKeyDown={pressEnter}
                    />
                  ) : (
                    <select className="form-select filter1-select1">
                      {individualGolfList.data?.map(i => (
                        <option value={i.key}>{i.value}</option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={btn_search}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>

          <div className="card card-main">
            <TableButtonPanel />
            <SearchResultTable />
          </div>
        </div>
      </div>
    </>
  );
};
