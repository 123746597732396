import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import {
  supportTeamSchedulesInquire_searchData_atom,
  supportTeamSchedulesInquire_searchedTableList_atom,
} from "../../../data/atom/goodsManage/supportTeamSchedulesInquire/supportTeamSchedulesInquire_atom";
import { useAtom } from "jotai/index";
import { useRef, useState } from "react";
import usePrintTab from "../../../components/modal/usePrintTab";

export const SupportTeamSchedulesInquire = () => {
  const targetRef = useRef(null);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [searchData, setSearchData] = useAtom(supportTeamSchedulesInquire_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    supportTeamSchedulesInquire_searchedTableList_atom
  );
  const openPdf = usePrintTab();

  // 지원팀 스케줄 검색
  const supportManageCheckSearch = useMutation(
    data => axios.get("/api/schedule/supportManageCheck/search", { params: data }),
    {
      onSuccess: res => {
        setSearchedTableList(res.data.result);
      },
    }
  );

  // 지원팀 스케줄 출력
  const supportManageCheckPrint = useMutation(
    data =>
      axios.get("/api/schedule/supportManageCheck/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `업무_지원팀_스케줄_조회_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const btn_search = () => {
    supportManageCheckSearch.mutate(searchData);
  };

  const btn_print = () => {
    if (searchedTableList?.length <= 0) {
      alert("출력 대상이 없습니다.");
      return;
    }

    if (window.confirm("출력하시겠습니까?")) {
      supportManageCheckPrint.mutate(searchData);
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">업무지원팀-스케줄조회</div>
            <div className="filter-buttons"></div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row filter1-field1">
              <div className="w-100 d-flex align-items-center">
                <div className="filter1-field filter1-field1 p-0" onKeyDown={pressEnter}>
                  <label className="filter1-label">기준일자</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchData.stdtDate}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        stdtDate: e.target.value,
                      });
                    }}
                    max="9999-12-31"
                  />
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                    onClick={btn_search}
                  >
                    조회
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-main">
          <div className="card-body">
            <div className="d-flex justify-content-end mb-10px">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                onClick={btn_print}
              >
                출력
              </button>
            </div>

            <div className="table-container table-sticky h-633">
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    {/*<th style={{ width: "5%" }}>연번</th>*/}
                    <th style={{ width: "10%" }}>지원팀 사원</th>
                    <th style={{ width: "10%" }}>휴대폰</th>
                    <th style={{ width: "10%" }}>담당자</th>
                    <th>진행일정</th>
                  </tr>
                </thead>
                <tbody>
                  {searchedTableList.length !== 0 ? (
                    // searchedTableList.map(el => {
                    //   return (
                    //     <tr>
                    //       <td>{el.kname}</td>
                    //       <td>{el.cellular}</td>
                    //       <td>{el.partner}</td>
                    //       <td>{el.workSchedule}</td>
                    //     </tr>
                    //   );
                    // })

                    searchedTableList.reduce((acc, cur, idx, arr) => {
                      if (cur?.kname === arr[idx - 1]?.kname) {
                        return [
                          ...acc,
                          <tr>
                            {/*<td>{cur.kname}</td>*/}
                            {/*<td>{cur.cellular}</td>*/}
                            <td>{cur.partner}</td>
                            <td className="text-start">{cur.workSchedule}</td>
                          </tr>,
                        ];
                      } else {
                        // 현재 항목부터 시작하여 연속된 같은 kname을 가진 항목의 개수를 계산
                        let consecutiveCount = 1;
                        for (let i = idx + 1; i < arr.length; i++) {
                          if (arr[i].kname === cur.kname) {
                            consecutiveCount++;
                          } else {
                            break;
                          }
                        }

                        return [
                          ...acc,
                          <tr>
                            <td rowSpan={consecutiveCount}>{cur.kname}</td>
                            <td rowSpan={consecutiveCount}>{cur.cellular}</td>
                            <td>{cur.partner}</td>
                            <td className="text-start">{cur.workSchedule}</td>
                          </tr>,
                        ];
                      }
                    }, [])
                  ) : (
                    <tr>
                      <td colSpan={5}>검색결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
