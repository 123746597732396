import React from "react";

export default function ProvisionsPaymentCondolenceMoney({ data }) {
  return (
    <div className="card card-main mt-0">
      <div className="card-body">
        <div className="table-container table-sticky h-443">
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th className="border-bottom-th">구분</th>
                <th className="border-bottom-th">계정명</th>
                <th className="border-bottom-th">금액</th>
                <th className="border-bottom-th">휴가일수</th>
              </tr>
            </thead>
            <tbody>
              {data?.reduce((acc, cur, idx, arr) => {
                if (cur?.gubun === arr[idx - 1]?.gubun) {
                  return [
                    ...acc,
                    <tr>
                      <td>{cur?.codename}</td>
                      <td>
                        {cur?.value1?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{cur?.remark}</td>
                    </tr>,
                  ];
                } else {
                  return [
                    ...acc,
                    <tr>
                      <td rowSpan={arr.filter(el => el.gubun === cur.gubun).length}>{cur.gubun}</td>
                      <td>{cur?.codename}</td>
                      <td>
                        {cur?.value1?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{cur?.remark}</td>
                    </tr>,
                  ];
                }
              }, [])}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
