import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai/index";
import {
  certificationLedger_row_atom,
  certificationLedger_searchData_atom,
  certificationLedger_searchOption_atom,
} from "../../../../data/atom/personManage/certificationManage/certificationLedger/certificationLedger_atom";
import moment from "moment";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import usePrintTab from "../../../../components/modal/usePrintTab";

export const CertificationLedger = () => {
  const [searchData, setSearchData] = useAtom(certificationLedger_searchData_atom);
  const [searchOption, setSearchOption] = useAtom(certificationLedger_searchOption_atom);
  const [searchListNumber, setSearchListNumber] = useAtom(certificationLedger_row_atom);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  // botoom 탐지 - 무한스크롤
  const [bottom, setBottom] = useState(null);
  const bottomObserver = useRef(null);
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchData([]);
      setSearchOption({
        fromDate: moment(new Date()).subtract("1", "M").format("YYYY-MM-DD"),
        gubun: "",
        keyWord: "",
        page: 1,
        toDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setSearchListNumber("5");
    });
  }, []);

  // API - 조회
  const docHistorySearch = useMutation(
    data =>
      axios.get("/api/emp/docHistory/search", {
        params: data,
      }),
    {
      onSuccess: (res, payload) => {
        if (payload.page === 1) {
          setSearchData(res?.data?.result);
        } else {
          setSearchData([...searchData, ...res?.data?.result]);
        }
      },
    }
  );

  // API - 출력
  const print = useMutation(
    data =>
      axios.get("/api/emp/docHistory/print", {
        params: {
          fromDate: data?.fromDate,
          toDate: data?.toDate,
          gubun: data?.gubun,
          keyWord: data?.keyWord,
        },
        responseType: "blob",
      }),
    {
      onSuccess: (res, payload) => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `증명서_발급대장_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const pressEnter = e => {
    if (e.key === "Enter") {
      setSearchData([]);
      docHistorySearch.mutate({ ...searchOption, page: 1 });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setSearchOption(prev => {
            docHistorySearch.mutate({ ...searchOption, page: prev.page + 1 });

            return { ...searchOption, page: prev.page + 1 };
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, [searchOption]);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">증명서 발급대장</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                onClick={() => {
                  if (searchData?.length <= 0) {
                    alert("출력할 대상이 없습니다.");
                    return;
                  }
                  print.mutate(searchOption);
                }}
              >
                출력
              </button>
            </div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchOption.gubun}
                    onChange={e => setSearchOption({ ...searchOption, gubun: e.target.value })}
                  >
                    <option value="">전체</option>
                    <option value="S">일반용</option>
                    <option value="G">개서용</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">발급일자</label>
                  <input
                    type="date"
                    min="1900-01-01"
                    max="2099-12-31"
                    className="form-control filter-input filter1-input2"
                    value={searchOption.fromDate}
                    onChange={e =>
                      setSearchOption({
                        ...searchOption,
                        fromDate: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    max="2099-12-31"
                    min={searchOption.fromDate}
                    className="form-control filter-input filter1-input2"
                    value={searchOption.toDate}
                    onChange={e =>
                      setSearchOption({
                        ...searchOption,
                        toDate: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">성명</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchOption.keyWord}
                    onChange={e =>
                      setSearchOption({
                        ...searchOption,
                        keyWord: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  setSearchOption(prev => {
                    return { ...searchOption, page: 1 };
                  });
                  setSearchData([]);
                  docHistorySearch.mutate({ ...searchOption, page: 1 });
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-main mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count">&nbsp;</div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>

            <div
              className={`table-container table-sticky 
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table  mb-0  mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>연번</th>
                    <th style={{ width: "7%" }}>구분</th>
                    <th style={{ width: "10%" }}>발급번호</th>
                    <th style={{ width: "7%" }}>발급일자</th>
                    <th style={{ width: "8%" }}>발급구분</th>
                    <th style={{ width: "10%" }}>성명</th>
                    <th style={{ width: "10%" }}>직위</th>
                    <th style={{ width: "10%" }}>매수</th>
                    <th style={{ width: "10%" }}>용도</th>
                    <th style={{ width: "10%" }}>담당자</th>
                    <th>발급정보</th>
                  </tr>
                </thead>
                <tbody>
                  {searchData?.length > 0 ? (
                    <>
                      {searchData.map((data, _idx) => {
                        return (
                          <tr>
                            <td>{_idx + 1}</td>
                            <td>{data.gubun}</td>
                            <td>{data.drftSn}</td>
                            <td>{moment(data.issueDate).format("YYYY-MM-DD")}</td>
                            <td>{data.docType}</td>
                            <td>{data.kname}</td>
                            <td>{data.etcAuthority}</td>
                            <td>{data.copies}</td>
                            <td>{data.reason}</td>
                            <td>{data.confmer}</td>
                            <td>{data.modDate && moment(data.modDate).format("YYYY-MM-DD")}</td>
                          </tr>
                        );
                      })}
                      {searchData.length > 0 && <div ref={setBottom} />}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={11}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
