import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  condoMemberList_atom,
  editedRowList_atom,
} from "../../../data/atom/dataManage/condoPrice/condoPrice_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";

const searchResultRow = props => {
  const { data, index } = props;
  const [condoMemberList, setCondoMemberList] = useAtom(condoMemberList_atom);
  const [editedRowList, setEditedRowList] = useAtom(editedRowList_atom);
  const [currentData, setCurrentData] = useState({ ...data });

  const userIdQ = useGetUserIdQ();

  useEffect(() => {
    setCurrentData({ ...data });
  }, [data]);

  const deleteCondoMember = useMutation(data => axios.post("/api/data/condoMember/delete", data), {
    onSuccess: res => { },
  });

  const btnDeleteRow = deleteEl => {
    if (confirm("삭제하시겠습니까?")) {
      deleteCondoMember.mutate({
        code: deleteEl.code,
        custId: deleteEl.custId,
        siseDate: moment(deleteEl.siseDate).format("YYYY-MM-DD"),
      });

      editedRowList?.map(el => {
        if (
          el.custId === deleteEl.custId &&
          el.code === deleteEl.code &&
          el.siseDate === deleteEl.siseDate
        ) {
          setEditedRowList(
            editedRowList.filter(
              el2 =>
                !(el.custId === el2.custId && el.code === el2.code && el.siseDate === el2.siseDate)
            )
          );
        }
      });

      setCondoMemberList(
        condoMemberList.filter(
          el2 =>
            !(
              currentData.custId === el2.custId &&
              currentData.code === el2.code &&
              currentData.siseDate === el2.siseDate
            )
        )
      );
    }
  };

  const onChangeRowEventHandler = (key, value) => {
    if (key === "sise11") {
      value = value.replace(/(^0+)/, "").replace(/[^0-9]/g, "");
    } else if (key === "gubun11") {
      if (value) value = "2";
      else value = "1";
    }

    let newData = { ...currentData, [key]: value };
    setCurrentData(newData);

    setEditedRowList(prev => {
      let flagIdx = -1;
      prev = prev.map((el, idx) => {
        if (data.custId === el.custId && data.code === el.code && data.siseDate === el.siseDate) {
          flagIdx = idx;
          return { ...newData };
        } else return el;
      });
      if (flagIdx === -1) prev.push({ ...newData });

      return prev;
    });
  };

  return (
    <tr>
      <td className="text-center">{index + 1}</td>
      <td>
        <select
          className="form-select"
          value={currentData.viewCheck}
          onChange={e => onChangeRowEventHandler("viewCheck", e.target.value)}
        >
          <option value={"Y"}>Y</option>
          <option value={"N"}>N</option>
        </select>
      </td>
      <td className="text-center">{currentData.codename}</td>
      <td className={`text-center ${currentData.gubun11 === "2" ? "td_pink" : ""}`}>
        <input
          type="checkbox"
          checked={currentData.gubun11 === "2"}
          onChange={e => onChangeRowEventHandler("gubun11", e.target.checked)}
        />
        &nbsp;분
      </td>
      <td className={`${currentData.gubun11 === "2" ? "td_pink" : ""}`}>
        <input
          type="text"
          className="form-control text-right"
          value={Number(currentData.sise11).toLocaleString()}
          maxLength={9}
          onChange={e => onChangeRowEventHandler("sise11", e.target.value.replace(/,/g, ""))}
        />
      </td>
      <td className="text-right">{Number(currentData.sise22).toLocaleString()}</td>
      <td className="text-right">
        {(Number(currentData.sise11) - Number(currentData.sise22)).toLocaleString()}
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={currentData.remark}
          onChange={e => onChangeRowEventHandler("remark", e.target.value)}
        />
      </td>
      <td className="text-center">
        {currentData.gubun1 === "11"
          ? "체인콘도"
          : currentData.gubun1 === "22"
            ? "스키장콘도"
            : "-"}
      </td>
      <td className="text-center" style={{ width: "5%" }}>
        {currentData.modid
          ? currentData.modid === "server"
            ? "server"
            : userIdQ.data?.find(i => i.key === currentData.modid)?.value
          : "-"}
      </td>
      <td className="text-center">
        {moment(currentData.modDate).format("YYYY-MM-DD HH:mm:ss")}
      </td>
      <td>
        <button
          className="btn btn-outline-primary m-auto h-25px"
          onClick={() => btnDeleteRow(currentData)}
        >
          삭제
        </button>
      </td>
    </tr>
  );
};

export default React.memo(searchResultRow);
