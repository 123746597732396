import React from "react";
import { useAtom } from "jotai/index";
import {
  carDetail_atom,
  carFuelInfo_atom,
  clickedRow_atom,
  isRegisterMode_atom,
} from "../../../../data/atom/dataManage/carManage/fuelInput_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";

const FuelInputDetail = props => {
  const { searchFuelInfo, drveUserList, acctList, fuelList } = props;
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);
  const [fuelInfo, setFuelInfo] = useAtom(carFuelInfo_atom);
  const [isRegisterMode, setIsRegisterMode] = useAtom(isRegisterMode_atom);

  const deleteCarDetail = useMutation(
    data => axios.post("/api/data/carOilManage/deleteOil", data),
    {
      onSuccess: res => {
        searchFuelInfo.mutate(clickedRow);
      },
    }
  );
  const saveCarDetail = useMutation(data => axios.post("/api/data/carOilManage/saveOil", data), {
    onSuccess: (res, variables) => {
      if (isRegisterMode) {
        setIsRegisterMode(false);
        searchFuelInfo.mutate(carDetail);
        setClickedRow(carDetail);
      } else {
        searchFuelInfo.mutate(carDetail);
      }
    },
  });

  const onChangeEventHandler = (key, value) => {
    if (key === "juyuAmnt" || key === "runnMile") {
      value = Number(
        value
          .replace(/,/g, "")
          .replace(/-/g, "")
          .replace(/[^0-9]/g, "")
      );
    } else if (key === "crdtNumb") {
      value = value.replace(/-/g, "").replace(/[^0-9]/g, "");
    }
    setCarDetail({ ...carDetail, [key]: value });
  };

  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (carDetail.runnMile === 0) {
        alert("총 주행거리를 입력해주세요.");
      } else if (carDetail.juyuUser === "") {
        alert("주유자를 선택해주세요.");
      } else if (carDetail.juyuDate === "" || carDetail.juyuDate === "Invalid date") {
        alert("주유일시를 입력해주세요.");
      } else if (carDetail.juyuAmnt === 0) {
        alert("주유금액을 입력해주세요.");
      } else if (carDetail.litrCost === 0) {
        alert("단가를 입력해주세요.");
      } else if (carDetail.engrGubn === "") {
        alert("연료를 선택해주세요.");
      } else if ((carDetail.juyuPlac ?? "").trim() === "") {
        alert("주유소명을 입력해주세요.");
      } else if (carDetail.juyuLitr === 0) {
        alert("주유량을 입력해주세요.");
      } else if ((carDetail.crdtNumb ?? "").trim() === "") {
        alert("법인카드번호를 입력해주세요.");
      } else {
        saveCarDetail.mutate([
          {
            ...carDetail,
            litrCost: Number(carDetail.litrCost.replace(",", "")),
          },
        ]);
      }
    }
  };
  const btnDelete = () => {
    if (confirm("삭제하시겠습니까?")) {
      deleteCarDetail.mutate(carDetail);
    }
  };
  const btnCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      setIsRegisterMode(false);
      searchFuelInfo.mutate(clickedRow);
    }
  };

  return (
    <div className="card card-main mt-4">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-end">
          <div className="flex-center-end">
            {isRegisterMode ? (
              <button className="btn btn-outline-primary ms-8px" onClick={btnCancel}>
                취소
              </button>
            ) : (
              <button className="btn btn-outline-primary ms-8px" onClick={btnDelete}>
                삭제
              </button>
            )}
            <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
              저장
            </button>
          </div>
        </div>
        <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
          <tbody>
            <tr>
              <th style={{ width: "7%" }}>차량번호</th>
              <td style={{ width: "18%" }}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.carrNumb ? carDetail?.carrNumb : ""}
                  onChange={e => onChangeEventHandler("carrNumb", e.target.value)}
                  readOnly={true}
                  style={{ border: "0" }}
                />
              </td>
              <th style={{ width: "7%" }}>차명</th>
              <td style={{ width: "18%" }}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.carrName ? carDetail?.carrName : ""}
                  onChange={e => onChangeEventHandler("carrName", e.target.value)}
                  readOnly={true}
                  style={{ border: "0" }}
                />
              </td>
              <th colSpan={2}>※ 총 주행거리: 주유당시 총 누적 km 의미</th>
              <th style={{ width: "7%" }}>총 주행거리</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.runnMile ? carDetail?.runnMile?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("runnMile", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>주유자</th>
              <td>
                <select
                  className="form-select"
                  value={carDetail?.juyuUser ?? ""}
                  onChange={e => onChangeEventHandler("juyuUser", e.target.value)}
                >
                  {drveUserList.map(el => (
                    <option value={el.value}>{el.label}</option>
                  ))}
                  <option hidden={true} value=""></option>
                </select>
              </td>
              <th>주유일시</th>
              <td>
                <input
                  type="datetime-local"
                  className="form-control filter-input h-25px"
                  value={
                    carDetail?.juyuDate
                      ? moment(carDetail?.juyuDate).format("YYYY-MM-DD HH:mm")
                      : ""
                  }
                  onChange={e => onChangeEventHandler("juyuDate", e.target.value)}
                  style={{ borderColor: "#C5C5C5" }}
                  min={"1900-01-01 00:00"}
                  max={"2099-12-31 23:59"}
                />
              </td>
              <th>주유금액</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.juyuAmnt ? carDetail?.juyuAmnt?.toLocaleString() : ""}
                  onChange={e => onChangeEventHandler("juyuAmnt", e.target.value)}
                />
              </td>
              <th>단가(ℓ)</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.litrCost ?? ""}
                  onChange={e => onChangeEventHandler("litrCost", e.target.value)}
                  onBlur={e => {
                    let value = e.target.value.replace(/[^0-9.]/g, "");
                    if (!value.includes(".")) {
                      if (value === "") value += "0.0";
                      else value = Number(value).toLocaleString() + ".0";
                    } else {
                      let [whole, decimal] = value.split(".");
                      whole = whole === "" ? "0" : whole;
                      if (!decimal) {
                        value = whole + ".0";
                      } else value = whole + "." + decimal;
                      value = Number(value).toFixed(1);
                      [whole, decimal] = value.split(".");
                      value = Number(whole).toLocaleString() + "." + decimal;
                    }
                    onChangeEventHandler("litrCost", value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>연료</th>
              <td>
                <select
                  className="form-select"
                  value={carDetail?.engrGubn ?? ""}
                  onChange={e => onChangeEventHandler("engrGubn", e.target.value)}
                >
                  {fuelList.map(el => (
                    <option value={el.value}>{el.label}</option>
                  ))}
                  <option hidden={true} value=""></option>
                </select>
              </td>
              <th>주유소명</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.juyuPlac ? carDetail?.juyuPlac : ""}
                  onChange={e => onChangeEventHandler("juyuPlac", e.target.value)}
                />
              </td>
              <th>주유량(ℓ)</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail.juyuLitr ?? ""}
                  onChange={e => onChangeEventHandler("juyuLitr", e.target.value)}
                  maxLength={5}
                  onBlur={e => {
                    let value = e.target.value.replace(/[^0-9.]/g, "");
                    if (!value.includes(".")) {
                      if (value === "") value += "0.0";
                      else value += ".0";
                    } else {
                      let [whole, decimal] = value.split(".");
                      whole = whole === "" ? "0" : whole;
                      if (!decimal) {
                        value = whole + ".0";
                      }
                      value = Number(value).toFixed(1);
                    }
                    onChangeEventHandler("juyuLitr", value >= 1000 ? "999.9" : value);
                  }}
                />
              </td>
              <th>결제구분</th>
              <td>
                <select
                  className="form-select"
                  value={carDetail?.acctCode}
                  onChange={e => onChangeEventHandler("acctCode", e.target.value)}
                >
                  {acctList.map(el => (
                    <option value={el.value}>{el.label}</option>
                  ))}
                  <option hidden={true} value=""></option>
                </select>
              </td>
            </tr>
            <tr>
              <th>법인카드번호</th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={
                    carDetail?.crdtNumb
                      ? carDetail?.crdtNumb?.replace(
                          /^(\d{4})(\d{4})(\d{4})(\d{4})$/g,
                          "$1-$2-$3-$4"
                        )
                      : ""
                  }
                  onChange={e => onChangeEventHandler("crdtNumb", e.target.value)}
                  maxLength={19}
                />
              </td>
              <th>비고</th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={carDetail?.remark ? carDetail?.remark : ""}
                  onChange={e => onChangeEventHandler("remark", e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(FuelInputDetail);
