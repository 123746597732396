import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SidebarInitial = () => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();

  return <div className="sidebar-wrapper"></div>;
};
