import { atom } from "jotai";

export const sportManage_searchDataInit_atom = {
  condition: "A",
  keyword: "",
};

export const sportManage_searchData_atom = atom(sportManage_searchDataInit_atom);

export const sportManage_tableLimit_atom = atom("5");

export const sportManage_searchedTableList_atom = atom([]);

export const sportManage_mainDataInit_atom = {
  caddres: "",
  cfax: "",
  closeDay: "",
  company: "",
  ctel: "",
  custId: "",
  czipcode: "",
  dept: "",
  dmCheck: "",
  gaesuWon: "",
  kname: "",
  memberInwon: "",
  modDate: "",
  outCheck: "",
  outDate: "",
  payAccount: "",
  payBank: "",
  payName: "",
  priceChiduk1: "",
  priceChiduk2: "",
  priceChiduk3: "",
  priceGaesu1: "",
  priceGaesu2: "",
  priceGaesu3: "",
  priceNonmember: "",
  priceYear1: "",
  priceYear2: "",
  priceYear3: "",
  reason: "",
  siteUrl: "",
  size: "",
  uncompany: "",
  userid: "",
  webViewChk: "",
};

export const sportManage_mainData_atom = atom(sportManage_mainDataInit_atom);

export const sportManage_subData_atom = atom([]);

export const sportManage_prevOutDate_atom = atom("");
