import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import { transactionMain_atom } from "../../../../data/atom/dataManage/transactionProgress/transactionProgress_atom";
import moment from "moment/moment";
import { decrypt } from "../../../../util/decrypt/decrypt";

const ModalSeyul = props => {
  const { show, setShow } = props;
  const [seyulList, setSeyulList] = useState([]);
  const [newData, setNewData] = useState({});

  const getTaxRate = useMutation(
    data => axios.get("/api/work/transferTax/getTaxRate", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;
        const list = [];

        data?.list.map(el => {
          return el.map(el2 => {
            list.push(el2);
          });
        });

        setNewData({});
        setSeyulList(list);
      },
    }
  );

  const changeSeyulState = () => {
    const list = [];
    getTaxRate?.list.map(el => {
      return el.map(el2 => {
        list.push(el2);
      });
    });

    setSeyulList(list);
  };

  const saveSeyul = useMutation(data => axios.post("/api/work/transferTax/saveTaxRate", data), {
    onSuccess: res => {
      getTaxRate.mutate();
    },
  });
  const deleteSeyul = useMutation(data => axios.post("/api/work/transferTax/deleteTaxRate", data), {
    onSuccess: res => {
      getTaxRate.mutate();
    },
  });

  useEffect(() => {
    if (show) {
      getTaxRate.mutate();
    }
  }, [show]);

  const onChangeEventHandler = (key, value, idx) => {
    if (
      key === "aplyAmntF" ||
      key === "aplyAmntT" ||
      key === "progressive" ||
      key === "taxRate" ||
      key === "taxYear"
    ) {
      value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));
    }

    setSeyulList(prev => {
      prev = prev.map((el, idx2) => {
        if (idx === idx2) {
          return { ...el, [key]: value };
        } else return el;
      });
      return prev;
    });
  };

  const onChangeNewDataEventHandler = (key, value) => {
    if (
      key === "aplyAmntF" ||
      key === "aplyAmntT" ||
      key === "progressive" ||
      key === "taxRate" ||
      key === "taxYear"
    ) {
      value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));
    }

    setNewData({ ...newData, [key]: value });
  };

  const modalHide = () => {
    setShow(false);
    setSeyulList([]);
    setNewData({});
  };
  const btnSearch = () => {
    getTaxRate.mutate();
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      saveSeyul.mutate(seyulList);
    }
  };
  const btnDelete = () => {
    if (confirm("삭제하시겠습니까?")) {
      const list = seyulList.filter(el => el.deleteCheck);
      if (list.length === 0) {
        alert("체크된 항목이 없습니다.");
      } else {
        deleteSeyul.mutate(list);
      }
    }
  };
  const btnAdd = () => {
    setNewData({
      aplyAmntF: 0,
      aplyAmntT: 0,
      modDate: moment().format("YYYY-MM-DD"),
      progressive: 0,
      remark: "",
      taxRate: 0,
      taxYear: 0,
      userId: decrypt("userInfo").loginId,
      newCheck: true,
    });
  };
  const btnRegister = () => {
    if (confirm("등록하시겠습니까?")) {
      if (newData.taxYear === 0) {
        alert("적용년도를 입력해주세요.");
      } else {
        saveSeyul.mutate([newData]);
      }
    }
  };
  const btnCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      setNewData({});
    }
  };

  return (
    <>
      <Modal show={show} size={"lg"} centered onHide={modalHide}>
        <Modal.Header closeButton>
          <Modal.Title>양도소득세율 관리</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form">
            <div className="table-container table-sticky overflow-auto h-633">
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>적용년도</th>
                    <th style={{ width: "15%" }}>
                      과세
                      <br />
                      표준구간1
                    </th>
                    <th style={{ width: "15%" }}>
                      과세
                      <br />
                      표준구간2
                    </th>
                    <th style={{ width: "10%" }}>적용세율(%)</th>
                    <th style={{ width: "15%" }}>
                      누진
                      <br />
                      공제금액
                    </th>
                    <th>비고</th>
                    <th style={{ width: "5%" }}>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  {newData.newCheck && (
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          onChange={e => onChangeNewDataEventHandler("taxYear", e.target.value)}
                          value={newData.taxYear?.toLocaleString()}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          onChange={e => onChangeNewDataEventHandler("aplyAmntF", e.target.value)}
                          value={newData.aplyAmntF?.toLocaleString()}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          value={newData.aplyAmntT?.toLocaleString()}
                          onChange={e => onChangeNewDataEventHandler("aplyAmntT", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          value={newData.taxRate?.toLocaleString()}
                          onChange={e => onChangeNewDataEventHandler("taxRate", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          value={newData.progressive?.toLocaleString()}
                          onChange={e => onChangeNewDataEventHandler("progressive", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={newData.remark}
                          onChange={e => onChangeNewDataEventHandler("remark", e.target.value)}
                        />
                      </td>
                      <td>
                        <input type="checkbox" disabled={true} />
                      </td>
                    </tr>
                  )}
                  {seyulList?.length !== 0 ? (
                    seyulList.reduce((acc, cur, idx, arr) => {
                      if (cur?.taxYear !== arr[idx - 1]?.taxYear) {
                        acc = [
                          ...acc,
                          <tr>
                            <td rowSpan={arr.filter(el => el.taxYear === cur.taxYear).length}>
                              {cur.taxYear}
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                onChange={e =>
                                  onChangeEventHandler("aplyAmntF", e.target.value, idx)
                                }
                                value={cur.aplyAmntF?.toLocaleString()}
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                value={cur.aplyAmntT?.toLocaleString()}
                                onChange={e =>
                                  onChangeEventHandler("aplyAmntT", e.target.value, idx)
                                }
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                value={cur.taxRate?.toLocaleString()}
                                onChange={e => onChangeEventHandler("taxRate", e.target.value, idx)}
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                value={cur.progressive?.toLocaleString()}
                                onChange={e =>
                                  onChangeEventHandler("progressive", e.target.value, idx)
                                }
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={cur.remark}
                                onChange={e => onChangeEventHandler("remark", e.target.value, idx)}
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={cur.deleteCheck}
                                onChange={e =>
                                  onChangeEventHandler("deleteCheck", e.target.checked, idx)
                                }
                                disabled={newData.newCheck}
                              />
                            </td>
                          </tr>,
                        ];
                      } else {
                        acc = [
                          ...acc,
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                onChange={e =>
                                  onChangeEventHandler("aplyAmntF", e.target.value, idx)
                                }
                                value={cur.aplyAmntF?.toLocaleString()}
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                value={cur.aplyAmntT?.toLocaleString()}
                                onChange={e =>
                                  onChangeEventHandler("aplyAmntT", e.target.value, idx)
                                }
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                value={cur.taxRate?.toLocaleString()}
                                onChange={e => onChangeEventHandler("taxRate", e.target.value, idx)}
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-end"
                                value={cur.progressive?.toLocaleString()}
                                onChange={e =>
                                  onChangeEventHandler("progressive", e.target.value, idx)
                                }
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={cur.remark}
                                onChange={e => onChangeEventHandler("remark", e.target.value, idx)}
                                disabled={newData.newCheck}
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={cur.deleteCheck}
                                onChange={e =>
                                  onChangeEventHandler("deleteCheck", e.target.checked, idx)
                                }
                                disabled={newData.newCheck}
                              />
                            </td>
                          </tr>,
                        ];
                      }
                      return acc;
                    }, [])
                  ) : (
                    <tr>
                      <td colSpan={7}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex-center-end pt-3">
              {newData.newCheck === undefined ? (
                <>
                  <button className="btn btn-outline-primary me-2" onClick={btnSearch}>
                    재조회
                  </button>
                  <button className="btn btn-outline-primary me-2" onClick={btnAdd}>
                    추가
                  </button>
                  <button className="btn btn-outline-primary me-2" onClick={btnSave}>
                    저장
                  </button>
                  <button className="btn btn-outline-primary" onClick={btnDelete}>
                    삭제
                  </button>
                </>
              ) : (
                <>
                  <button className="btn btn-primary me-2" onClick={btnRegister}>
                    등록
                  </button>
                  <button className="btn btn-outline-primary" onClick={btnCancel}>
                    취소
                  </button>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(ModalSeyul);
