import React from "react";

export const TableHeaderCM = () => {
  return (
    <tr>
      <th style={{ width: "8%" }}>연번</th>
      <th style={{ width: "3%" }}>선택</th>
      <th style={{ width: "15%" }}>콘도명</th>
      <th style={{ width: "10%" }}>코드</th>
      <th style={{ width: "20%" }}>회원권명</th>
      <th style={{ width: "15%" }}>회원권분류</th>
      <th>비고</th>
      <th style={{ width: "5%" }}>삭제</th>
    </tr>
  );
};
