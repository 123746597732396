import { atom } from "jotai";

export const acquisitionTaxManage_mainSearchDataInit_atom = {
  custId: "00",
  searchType: "1",
};

export const acquisitionTaxManage_mainSearchData_atom = atom(
  acquisitionTaxManage_mainSearchDataInit_atom
);

export const acquisitionTaxManage_mainSearchedTableList_atom = atom([]);

export const acquisitionTaxManage_subSearchData_atom = atom(null);

export const acquisitionTaxManage_subSearchedTableList_atom = atom([]);
