import React, { useEffect, useRef, useState } from "react";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  docImgArr_atom,
  documentManageSearchParam_atom,
  previewImgModal_atom,
} from "../../../../../data/atom/workManage/transactionRegisterManage/documentManage_atom";
import { useAtomValue } from "jotai";
import moment from "moment";
import { PreviewImg } from "./modal/previewImg";

const docTextArrInit = [
  "A.거래내역",
  "B.회원증사본",
  "C.매도인감",
  "D.매수인감",
  "E.매매계약서",
  "F.입금표",
  "G.기타-1",
  "H.기타-2",
  "I.기타-3",
  "J.기타-4",
  "K.기타-5",
  "L.기타-6",
  "M.기타-7",
  "N.기타-8",
  "O.기타-9",
  "P.기타-10",
  "Q.기타-11",
  "R.기타-12",
  "S.기타-13",
  "T.기타-14",
  "U.기타-15",
  "V.기타-16",
  "W.기타-17",
  "X.기타-18",
];
const RightDocPanel = ({ autoPreview, myDetailData }) => {
  const inputRef = useRef({});

  const [docBmpArray, setDocBmpArray] = useAtom(docImgArr_atom);
  const searchParam = useAtomValue(documentManageSearchParam_atom);

  const [docTextArr, setDocTextArr] = useState([...docTextArrInit]);

  const [previewImgModal, setPreviewImgModal] = useAtom(previewImgModal_atom);

  // 보존 이미지 불러오기
  const traderDocGetDocFile = useMutation(
    data =>
      axios.get("/api/work/traderDoc/getDocFile", {
        params: {
          jisa: data.jisa,
          pick: data.pick,
          seqNo: data.seqNo,
          year: searchParam.year,
        },
        responseType: "blob",
      }),
    {
      onSuccess: (res, param) => {
        if (res.data.size !== 0)
          res.data.arrayBuffer().then(res => {
            const base64Flag = "data:image/bmp;base64,";
            let binary = "";
            const bytes = new Uint8Array(res);
            const len = bytes.byteLength;

            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            setDocBmpArray(prev => {
              prev[param.pick - 1] = base64Flag + window.btoa(binary);
              return [...prev];
            });
          });
      },
    }
  );

  // const traderDocGetDocFile = useMutation(
  //   data =>
  //     axios.get("/api/work/traderDoc/getDocFile", {
  //       params: {
  //         jisa: data.jisa,
  //         pick: data.pick,
  //         seqNo: data.seqNo,
  //         year: searchParam.year,
  //       },
  //     }),
  //   {
  //     onSuccess: (res, param) => {
  //
  //       // if (res.data.size !== 0)
  //       //   res.data.arrayBuffer().then(res => {
  //       //     const base64Flag = "data:image/bmp;base64,";
  //       //     let binary = "";
  //       //     const bytes = new Uint8Array(res);
  //       //     const len = bytes.byteLength;
  //       //
  //       //     for (let i = 0; i < len; i++) {
  //       //       binary += String.fromCharCode(bytes[i]);
  //       //     }
  //       //     setDocBmpArray(prev => {
  //       //       prev[param.pick - 1] = base64Flag + window.btoa(binary);
  //       //       return [...prev];
  //       //     });
  //       //   });
  //     },
  //   }
  // );

  // 보존 이미지 업로드
  const traderDocUploadfile = useMutation(
    data => axios.post("/api/work/traderDoc/uploadfile", data),
    {
      onSuccess: res => {
        loadAllImage();
      },
    }
  );

  const loadAllImage = () => {
    setDocBmpArray(Array(24).fill(""));
    for (let i = 0; i < 23; i++) {
      let letter = String.fromCharCode("A".charCodeAt(0) + i);
      if (myDetailData[`check${letter}`] === "Y")
        traderDocGetDocFile.mutate({ ...myDetailData, pick: i + 1 });
    }
  };

  const loadImage = index => {
    traderDocGetDocFile.mutate({ ...myDetailData, pick: index + 1 });
  };

  // 아래 함수 내용은 똑같지만 호출 타이밍이 다르므로 수정 시 주의
  // 자동보기 켜면 이미지 전체 불러오기
  useEffect(() => {
    if (
      decrypt("userInfo").secWebConnect === "Y" &&
      autoPreview &&
      myDetailData &&
      myDetailData.seqNo
    ) {
      loadAllImage();
    }
  }, [autoPreview]);
  // 위 함수 내용은 똑같지만 호출 타이밍이 다르므로 수정 시 주의
  // 로우 클릭했는데 자동보기 켜져있으면 전체 가져오기
  useEffect(() => {
    if (
      decrypt("userInfo").secWebConnect === "Y" &&
      autoPreview &&
      myDetailData &&
      myDetailData.seqNo
    ) {
      loadAllImage();
    } else setDocBmpArray(Array(24).fill(""));

    if (myDetailData && myDetailData.seqNo) {
      let yearmonth = moment(myDetailData.seqNo, "YYYYMM");
      if (yearmonth.isAfter(moment("201101", "YYYYMM"))) {
        docTextArr[6] = "G.정보동의-매도";
        docTextArr[7] = "G.정보동의-매수";
      } else {
        docTextArr[6] = "G.기타-1";
        docTextArr[7] = "H.기타-2";
      }
      setDocTextArr([...docTextArr]);
    }

    traderDocGetDocFile.mutate({ ...myDetailData, pick: 1 });
  }, [myDetailData]);

  const radioOnClick = index => {
    let letter = String.fromCharCode("A".charCodeAt(0) + index);

    if (searchParam.autoPreview && myDetailData["check" + letter] === "Y")
      setPreviewImgModal({
        data: docBmpArray[index],
        isOpen: true,
        index: index,
      });
    else
      setPreviewImgModal({
        data: docBmpArray[index],
        isOpen: false,
        index: index,
      });
  };

  const convertImg = index => {
    return (
      <>
        <div className="col">
          <div className="card px-0 card-employeeinfo">
            {docBmpArray[index] !== "" ? (
              <img
                className="bd-placeholder-img card-img-top cursor-pointer"
                width="100%"
                height="180px"
                src={docBmpArray[index]}
                onClick={() => inputRef.current[index].click()}
              />
            ) : (
              <>
                <svg
                  className="bd-placeholder-img card-img-top cursor-pointer"
                  width="100%"
                  height="180"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: Image cap"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  onClick={() => inputRef.current[index].click()}
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#868e96"></rect>
                </svg>
              </>
            )}
            <input
              type="file"
              ref={el => (inputRef.current[index] = el)}
              hidden={true}
              onChange={e => btn_img_upload(inputRef.current[index], index)}
            />

            <div className="card-body px-0 pt-0 pb-1">
              <div className="form-check radio-image">
                <input
                  id={`radio-${index}`} // id 추가
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  onClick={() => radioOnClick(index)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`radio-${index}`} // htmlFor 값 수정
                >
                  {docTextArr[index]}
                </label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const btn_img_upload = (e, index) => {
    const formData = new FormData();

    formData.append("files", e.files[0]);
    formData.append("clickCase", String.fromCharCode(65 + index));
    formData.append("jisa", myDetailData.jisa);
    formData.append("pick", index + 1);
    formData.append("seqNo", myDetailData.seqNo);
    formData.append("year", searchParam.year);

    traderDocUploadfile.mutate(formData);
    e.value = "";
  };

  return (
    <>
      {previewImgModal.isOpen && <PreviewImg />}

      <div className="col-6">
        <div className="card card-main mt-14px">
          <div className="card-header">
            <h5 className="card-title m-0">보존이미지</h5>
          </div>
          <div className="card-body">
            <div className="images-container-scroller">
              <div className="row row-cols-4 row-cols-md-4 g-4 p-0">
                {/*{docBmpArray.map((i, idx) => convertImg(idx))}*/}
                {Array.from({ length: docBmpArray.length }, (_, idx) => convertImg(idx))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(RightDocPanel);
