import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useGetCondoadmin } from "../../../api/code/useGetCondoadmin";
import { useAtom } from "jotai/index";
import {
  condoMasterManage_mainData_atom,
  condoMasterManage_mainDataInit_atom,
  condoMasterManage_prevOutDate,
  condoMasterManage_searchData_atom,
  condoMasterManage_searchDataInit_atom,
  condoMasterManage_searchedTableList_atom,
  condoMasterManage_subData_atom,
  condoMasterManage_tableLimit_atom,
} from "../../../data/atom/workManage/condoMasterManage/condoMasterManage_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { DaumPostcode } from "../../../components/daumPostcode/daumPostcode";
import { useGetBank } from "../../../api/code/useGetBank";
import moment from "moment";
import { regex_only_number } from "../../../util/validation";
import {
  sportManage_mainDataInit_atom,
  sportManage_searchDataInit_atom,
} from "../../../data/atom/workManage/sportMasterManage/sportMasterManage_atom";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const CondoMasterManage = () => {
  const { addTabClosedEvent } = useRecentTabEvent();

  const getCondoadmin = useGetCondoadmin();
  const getBank = useGetBank();

  const [selectedSubTab, setSelectedSubTab] = useState("이용특전(웹)");
  const [prevOutDate, setPrevOutDate] = useAtom(condoMasterManage_prevOutDate);
  const [searchData, setSearchData] = useAtom(condoMasterManage_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    condoMasterManage_searchedTableList_atom
  );
  const [tableLimit, setTableLimit] = useAtom(condoMasterManage_tableLimit_atom);

  // 콘도정보
  const [mainData, setMainData] = useAtom(condoMasterManage_mainData_atom);

  // 사이드 탭
  const [subData, setSubData] = useAtom(condoMasterManage_subData_atom);

  // 콘도마스터 조회
  const condoSearch = useMutation(data => axios.get("/api/work/condo/search", { params: data }), {
    onSuccess: res => {
      setSearchedTableList(res.data.result);
    },
    onError: (err, variables) => {
      if (err.response.data.code === "-5") {
        if (window.confirm(err.response.data.message)) {
          if (searchData.condition === "A") {
            setMainData({
              addres: "",
              caddres1: "",
              cfax: "",
              company: variables.keyword,
              ctel: "",
              custId: "0",
              czipcode1: "",
              dept: "",
              dmCheck: "",
              gtel: "",
              kname: "",
              location: "",
              modDate: "",
              outCheck: "N",
              outDate: "",
              owner: "",
              payAccount: "",
              payBank: "",
              payName: "",
              position: "",
              ptel: "",
              reason: "",
              siteUrl: "",
              ucompany: "",
              userid: "",
              webViewChk: "",
              ctel1: "",
              ctel2: "",
              ctel3: "",
              gtel1: "",
              gtel2: "",
              gtel3: "",
              ptel1: "",
              ptel2: "",
              ptel3: "",
              cfax1: "",
              cfax2: "",
              cfax3: "",
            });
          } else if (searchData.condition === "B") {
            setMainData({
              addres: "",
              caddres1: "",
              cfax: "",
              company: "",
              ctel: "",
              custId: "0",
              czipcode1: "",
              dept: "",
              dmCheck: "",
              gtel: "",
              kname: variables.keyword,
              location: "",
              modDate: "",
              outCheck: "N",
              outDate: "",
              owner: "",
              payAccount: "",
              payBank: "",
              payName: "",
              position: "",
              ptel: "",
              reason: "",
              siteUrl: "",
              ucompany: "",
              userid: "",
              webViewChk: "",
              ctel1: "",
              ctel2: "",
              ctel3: "",
              gtel1: "",
              gtel2: "",
              gtel3: "",
              ptel1: "",
              ptel2: "",
              ptel3: "",
              cfax1: "",
              cfax2: "",
              cfax3: "",
            });
          }
        }
      }
    },
  });

  // 콘도마스터 상세조회
  const condoGetDetail = useMutation(
    data => axios.get("/api/work/condo/getDetail", { params: data }),
    {
      onSuccess: res => {
        // condoGetRemark.mutate({ custId: res.data.result.custId });
        setPrevOutDate(
          res.data?.result?.outDate ? moment(res.data?.result?.outDate).format("YYYY-MM-DD") : ""
        );
        setMainData({
          ...res.data.result,
          caddres1A: res.data.result?.addres,
          caddres1B: res.data.result?.caddres1,
          // ctel1: res.data.result.ctel?.split("-")[0] ?? "",
          // ctel2: res.data.result.ctel?.split("-")[1] ?? "",
          // ctel3: res.data.result.ctel?.split("-")[2] ?? "",
          // gtel1: res.data.result.gtel?.split("-")[0] ?? "",
          // gtel2: res.data.result.gtel?.split("-")[1] ?? "",
          // gtel3: res.data.result.gtel?.split("-")[2] ?? "",
          // ptel1: res.data.result.ptel?.split("-")[0] ?? "",
          // ptel2: res.data.result.ptel?.split("-")[1] ?? "",
          // ptel3: res.data.result.ptel?.split("-")[2] ?? "",
          // cfax1: res.data.result.cfax?.split("-")[0] ?? "",
          // cfax2: res.data.result.cfax?.split("-")[1] ?? "",
          // cfax3: res.data.result.cfax?.split("-")[2] ?? "",
        });
      },
    }
  );

  // 콘도마스터 저장/수정
  const condoSave = useMutation(data => axios.post("/api/work/condo/save", data), {
    onSuccess: (res, variables) => {
      if (variables.custId) {
        condoGetDetail.mutate({ custId: mainData.custId });
      } else {
        setMainData({
          addres: "",
          caddres1: "",
          cfax: "",
          company: "",
          ctel: "",
          custId: "",
          czipcode1: "",
          dept: "",
          dmCheck: "",
          gtel: "",
          kname: "",
          location: "",
          modDate: "",
          outCheck: "N",
          outDate: "",
          owner: "",
          payAccount: "",
          payBank: "",
          payName: "",
          position: "",
          ptel: "",
          reason: "",
          siteUrl: "",
          ucompany: "",
          userid: "",
          webViewChk: "",
          ctel1: "",
          ctel2: "",
          ctel3: "",
          gtel1: "",
          gtel2: "",
          gtel3: "",
          ptel1: "",
          ptel2: "",
          ptel3: "",
          cfax1: "",
          cfax2: "",
          cfax3: "",
        });
      }
      condoSearch.mutate(searchData);
    },
  });

  // 콘도마스터 비고조회
  const condoGetRemark = useMutation(
    data => axios.get("/api/work/condo/getRemark", { params: data }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  // 콘도마스터 비고 저장/수정
  const condoSaveRemark = useMutation(data => axios.post("/api/work/condo/saveRemark", data), {
    onSuccess: res => {
      condoGetDetail.mutate({ custId: mainData.custId });
      condoGetRemark.mutate({ custId: mainData.custId });
    },
  });

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSearchData(condoMasterManage_searchDataInit_atom);
      setSearchedTableList([]);
      setTableLimit("5");
      setMainData(condoMasterManage_mainDataInit_atom);
      setSubData({});
    });
  }, []);

  const btn_search = () => {
    condoSearch.mutate(searchData);
  };

  const btn_tableRowClick = data => {
    condoGetDetail.mutateAsync({ custId: data.custId }).then(res => {
      condoGetRemark.mutate({ custId: res.data.result.custId });
    });
  };

  const btn_newRegister = () => {
    if (window.confirm("신규등록 하시겠습니까?")) {
      setMainData({
        caddres1A: "",
        caddres1B: "",
        addres: "",
        caddres1: "",
        cfax: "",
        company: "",
        ctel: "",
        custId: "0",
        czipcode1: "",
        dept: "",
        dmCheck: "",
        gtel: "",
        kname: "",
        location: "",
        modDate: "",
        outCheck: "N",
        outDate: "",
        owner: "",
        payAccount: "",
        payBank: "",
        payName: "",
        position: "",
        ptel: "",
        reason: "",
        siteUrl: "",
        ucompany: "",
        userid: "",
        webViewChk: "",
        ctel1: "",
        ctel2: "",
        ctel3: "",
        gtel1: "",
        gtel2: "",
        gtel3: "",
        ptel1: "",
        ptel2: "",
        ptel3: "",
        cfax1: "",
        cfax2: "",
        cfax3: "",
      });
    }
  };

  const btn_main_save = () => {
    if (mainData.custId === "0") {
      if (window.confirm("신규등록을 하시겠습니까?")) {
        condoSave.mutate({
          ...mainData,
          caddres1A: mainData?.caddres1A ? mainData?.caddres1A : "",
          caddres1B: mainData?.caddres1B ? mainData?.caddres1B : "",
          caddres1: mainData.caddres1,
          company: mainData.company,
          custId: null,
          czipcode1: mainData.czipcode1,
          dept: mainData.dept,
          dmCheck: mainData.dmCheck,
          kname: mainData.kname,
          location: mainData.location,
          modDate: null,
          outCheck: mainData.outCheck,
          outDate: mainData.outCheck === "Y" ? moment(mainData.outDate).format("YYYY-MM-DD") : null,
          owner: mainData.owner,
          payAccount: mainData.payAccount,
          payBank: mainData.payBank,
          payName: mainData.payName,
          position: mainData.position,
          reason: mainData.reason,
          siteUrl: mainData.siteUrl,
          ucompany: mainData.ucompany,
          userid: mainData.userid,
          webViewChk: mainData.webViewChk,
        });
      }
    } else {
      if (window.confirm("저장하시겠습니까?")) {
        condoSave.mutate({
          ...mainData,
          caddres1A: mainData?.caddres1A ? mainData?.caddres1A : "",
          caddres1B: mainData?.caddres1B ? mainData?.caddres1B : "",
          modDate: moment(mainData.modDate).format("YYYY-MM-DD"),
          // outCheck: mainData.outCheck,
          outDate: mainData.outCheck === "Y" ? moment(mainData.outDate).format("YYYY-MM-DD") : null,
        });
      }
    }
  };

  const btn_main_cancel = () => {
    if (mainData.custId === "0") {
      if (window.confirm("신규등록을 취소하시겠습니까?")) {
        setMainData({
          addres: "",
          caddres1: "",
          cfax: "",
          company: "",
          ctel: "",
          custId: "",
          czipcode1: "",
          dept: "",
          dmCheck: "",
          gtel: "",
          kname: "",
          location: "",
          modDate: "",
          outCheck: "N",
          outDate: "",
          owner: "",
          payAccount: "",
          payBank: "",
          payName: "",
          position: "",
          ptel: "",
          reason: "",
          siteUrl: "",
          ucompany: "",
          userid: "",
          webViewChk: "",
          ctel1: "",
          ctel2: "",
          ctel3: "",
          gtel1: "",
          gtel2: "",
          gtel3: "",
          ptel1: "",
          ptel2: "",
          ptel3: "",
          cfax1: "",
          cfax2: "",
          cfax3: "",
          caddres1A: "",
          caddres1B: "",
        });
      }
    } else {
      if (window.confirm("수정을 취소하시겠습니까?")) {
        condoGetDetail.mutate({ custId: mainData.custId });
      }
    }
  };

  const btn_sub_save = (data, type) => {
    if (window.confirm("저장하시겠습니까?")) {
      condoSaveRemark.mutate({ custId: mainData.custId, remark: data, type: type });
    }
  };

  const btn_sub_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      condoGetRemark.mutate({ custId: mainData.custId });
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">콘도 마스터 관리</div>
            <div className="filter-buttons">
              <button className="btn btn-primary" onClick={btn_newRegister}>
                등록
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row" onKeyDown={pressEnter}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.condition}
                    onChange={e => {
                      if (e.target.value === "D") {
                        setSearchData({ ...searchData, condition: e.target.value, keyword: "11" });
                      } else {
                        setSearchData({ ...searchData, condition: e.target.value, keyword: "" });
                      }
                    }}
                  >
                    <option value="A">콘도명</option>
                    <option value="B">명의담당</option>
                    <option value="C">체인지역</option>
                    <option value="D">스키장/콘도구분</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  {searchData.condition === "D" ? (
                    <>
                      <label className="filter1-label">검색어</label>
                      <select
                        value={searchData.keyword}
                        onChange={e => {
                          setSearchData({ ...searchData, keyword: e.target.value });
                        }}
                      >
                        <option value="11">체인콘도</option>
                        <option value="22">스키장콘도</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <label className="filter1-label">검색어</label>
                      <input
                        type="text"
                        className="form-control filter-input filter1-input2"
                        value={searchData.keyword}
                        onChange={e => {
                          setSearchData({ ...searchData, keyword: e.target.value });
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                  onClick={btn_search}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>

          <div className="card card-sub">
            <div className="card-body">
              <div className="containerx">
                <div className="table-container">
                  <div className="d-flex align-items-center justify-content-end">
                    <select
                      className="form-select filter-perpage ms-3"
                      value={tableLimit}
                      onChange={e => {
                        setTableLimit(e.target.value);
                      }}
                    >
                      <option value="5">5개씩 보기</option>
                      <option value="10">10개씩 보기</option>
                      <option value="20">20개씩 보기</option>
                    </select>
                  </div>
                  <div
                    className={`table-container table-sticky mt-2
                      ${tableLimit === "5" && "h-183"}
                      ${tableLimit === "10" && "h-333"}
                      ${tableLimit === "20" && "h-633"}`}
                  >
                    <table className="table table-bordered table-responsive my-table mt-0">
                      <thead>
                        <tr>
                          <th style={{ width: "8%" }}>연번</th>
                          <th style={{ width: "41%" }}>콘도명</th>
                          <th style={{ width: "41%" }}>명의담당</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchedTableList.length !== 0 ? (
                          searchedTableList.map((el, index) => (
                            <tr
                              className={`cursor-pointer ${el.outCheck === "Y" && "bg-red"}
                              ${mainData?.custId === el?.custId && "bg-blue"}`}
                              onClick={() => btn_tableRowClick(el)}
                            >
                              <td>{index + 1}</td>
                              <td>{el.company}</td>
                              <td>{el.kname}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>검색결과가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {mainData.custId && (
            <div className="two-col-outer mt-14px">
              <div className="col-left">
                <div className="card card-main mt-0 h-493px">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title  m-0">콘도 정보</h5>
                    <div
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "8px" }}
                    >
                      <button className="btn btn-outline-primary" onClick={btn_main_save}>
                        저장
                      </button>
                      <button className="btn btn-outline-primary" onClick={btn_main_cancel}>
                        취소
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="tab1-row1-count">
                        관리번호 : {mainData?.custId === "0" ? "신규등록" : mainData?.custId}
                      </div>
                      <div className="tab1-row1-field1"></div>
                    </div>

                    <table className="table table-bordered table-responsive my-table1 mt-2">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>콘도명</th>
                          <td style={{ width: "30%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.company}
                              onChange={e => {
                                setMainData({ ...mainData, company: e.target.value });
                              }}
                            />
                          </td>
                          <th style={{ width: "15%" }}>대표자</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.owner}
                              onChange={e => {
                                setMainData({ ...mainData, owner: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>운영회사</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.ucompany}
                              onChange={e => {
                                setMainData({ ...mainData, ucompany: e.target.value });
                              }}
                            />
                          </td>
                          <th>근무부서</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.dept}
                              onChange={e => {
                                setMainData({ ...mainData, dept: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>명의담당</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.kname}
                              onChange={e => {
                                setMainData({ ...mainData, kname: e.target.value });
                              }}
                            />
                          </td>
                          <th>직위</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.position}
                              onChange={e => {
                                setMainData({ ...mainData, position: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>본사전화</th>
                          <td>
                            <div className="flex-center-between">
                              <input
                                type="text"
                                className="form-control table-input "
                                value={mainData.ctel}
                                onChange={e => {
                                  setMainData({ ...mainData, ctel: e.target.value });
                                }}
                              />
                              {/* <span className="txt_dash">-</span>
                              <input
                                type="text"
                                className="form-control table-input col3-dash"
                                value={mainData.ctel2}
                                onChange={e => {
                                  if (regex_only_number.test(e.target.value)) {
                                    setMainData({ ...mainData, ctel2: e.target.value });
                                  }
                                }}
                              />
                              <span className="txt_dash">-</span>
                              <input
                                type="text"
                                className="form-control table-input col3-dash"
                                value={mainData.ctel3}
                                onChange={e => {
                                  if (regex_only_number.test(e.target.value)) {
                                    setMainData({ ...mainData, ctel3: e.target.value });
                                  }
                                }}
                              /> */}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>본사주소</th>
                          <td colSpan={3}>
                            <div className="d-flex justify-content-between">
                              <input
                                type="text"
                                className="form-control table-input table-input-inline"
                                value={mainData.czipcode1}
                              />
                              <DaumPostcode
                                onCompleteCallback={data => {
                                  setMainData({
                                    ...mainData,
                                    czipcode1: data.zonecode,
                                    addres: data.fullAddr,
                                    caddres1A: data.fullAddr,
                                  });
                                }}
                              />
                              <input
                                type="text"
                                className="form-control table-input table-input-inline ms-3px"
                                value={mainData.addres}
                              />
                              <input
                                type="text"
                                className="form-control table-input table-input-inline ms-3px"
                                value={mainData.caddres1}
                                onChange={e => {
                                  setMainData({
                                    ...mainData,
                                    caddres1: e.target.value,
                                    caddres1B: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>전화번호</th>
                          <td>
                            <div className="flex-center-between">
                              <input
                                type="text"
                                className="form-control table-input"
                                value={mainData.gtel}
                                onChange={e => {
                                  setMainData({ ...mainData, gtel: e.target.value });
                                }}
                              />
                            </div>
                          </td>
                          <th>팩스번호</th>
                          <td>
                            <div className="flex-center-between">
                              <input
                                type="text"
                                className="form-control table-input"
                                value={mainData.cfax}
                                onChange={e => {
                                  setMainData({ ...mainData, cfax: e.target.value });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>예약전화</th>
                          <td>
                            <div className="flex-center-between">
                              <input
                                type="text"
                                className="form-control table-input"
                                value={mainData.ptel}
                                onChange={e => {
                                  setMainData({ ...mainData, ptel: e.target.value });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>지역</th>
                          <td colSpan={3}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.location}
                              onChange={e => {
                                setMainData({ ...mainData, location: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>인터넷</th>
                          <td style={{ width: "35%" }}>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control table-input"
                                value={mainData.siteUrl}
                                onChange={e => {
                                  setMainData({
                                    ...mainData,
                                    siteUrl: e.target.value.replace(/[^a-zA-Z0-9./]/g, ""),
                                  });
                                }}
                              />
                              <a
                                href={
                                  mainData.siteUrl?.trim() !== ""
                                    ? `http://${mainData.siteUrl}`
                                    : void 0
                                }
                                target="_blank"
                              >
                                <img
                                  className="h-25px float-end"
                                  src={process.env.PUBLIC_URL + "/images/icon-arrow-btn.png"}
                                  alt=""
                                />
                              </a>
                            </div>
                          </td>
                          <th style={{ width: "15%" }}>&nbsp;</th>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>개서료 입금구좌</th>
                          <td style={{ width: "35%" }}>
                            <select
                              className="form-select w-29 float-start"
                              value={mainData.payBank}
                              onChange={e => {
                                setMainData({ ...mainData, payBank: e.target.value });
                              }}
                            >
                              {getBank.data?.map(el => (
                                <option value={el.key}>{el.value}</option>
                              ))}
                            </select>
                            <input
                              type="text"
                              className="form-control table-input w-70 float-end"
                              value={mainData.payAccount}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  payAccount: e.target.value.replace(/[^0-9-]/g, ""),
                                });
                              }}
                            />
                          </td>
                          <th style={{ width: "15%" }}>예금주</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.payName}
                              onChange={e => {
                                setMainData({ ...mainData, payName: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered table-responsive my-table1 mb-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              name="checkbox-option"
                              checked={mainData.outCheck === "Y"}
                              onChange={e => {
                                if (e.target.checked) {
                                  setMainData({
                                    ...mainData,
                                    outCheck: e.target.checked === true ? "Y" : "N",
                                    outDate: moment().format("YYYY-MM-DD"),
                                  });
                                } else {
                                  setMainData({
                                    ...mainData,
                                    outCheck: e.target.checked === true ? "Y" : "N",
                                    outDate: prevOutDate,
                                  });
                                }

                                // setMainData({
                                //   ...mainData,
                                //   outCheck: e.target.checked === true ? "Y" : "N",
                                // });
                              }}
                            />
                            제명여부
                          </th>
                          <th style={{ width: "10%" }}>제명일시</th>
                          <td>
                            <input
                              type="date"
                              className="form-control table-input"
                              value={moment(mainData.outDate).format("YYYY-MM-DD")}
                              readOnly={true}
                            />
                          </td>
                          <th style={{ width: "15%" }}>WEB-VIEW</th>
                          <td style={{ width: "35%" }}>
                            <select
                              className="form-select"
                              value={mainData.webViewChk}
                              onChange={e => {
                                setMainData({ ...mainData, webViewChk: e.target.value });
                              }}
                            >
                              <option value="Y">Y</option>
                              <option value="N">N</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {mainData.custId !== "0" && (
                <div className="col-right">
                  <div className="tabs-sidebar-wrapper w-100">
                    <div className="card card-sub mt-0 h-493px">
                      <div className="card-body">
                        {/*<Tabs className="tab-blocks" activeKey={key1b} onSelect={k => setKey1b(k)}>*/}
                        <Tabs
                          className="tab-blocks"
                          activeKey={selectedSubTab}
                          onSelect={k => setSelectedSubTab(k)}
                        >
                          <Tab eventKey="이용특전(웹)" title="이용특전(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.remark1}
                                onChange={e => {
                                  setSubData({ ...subData, remark1: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.remark1, "A")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="비고" title="비고">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.remark2}
                                onChange={e => {
                                  setSubData({ ...subData, remark2: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.remark2, "B")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="필요서류" title="필요서류">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.tradeMethod}
                                onChange={e => {
                                  setSubData({ ...subData, tradeMethod: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.tradeMethod, "C")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="체인현황(웹)" title="체인현황(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.chain}
                                onChange={e => {
                                  setSubData({ ...subData, chain: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.chain, "D")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="예약방법(웹)" title="예약방법(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.preOrder}
                                onChange={e => {
                                  setSubData({ ...subData, preOrder: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.preOrder, "E")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="이용요금(웹)" title="이용요금(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.useTime}
                                onChange={e => {
                                  setSubData({ ...subData, useTime: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.useTime, "F")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="개서료" title="개서료">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.gaesuWon}
                                onChange={e => {
                                  setSubData({ ...subData, gaesuWon: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.gaesuWon, "H")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="가는길(웹)" title="가는길(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control w-100"
                                style={{ height: "357px" }}
                                value={subData.howtoway}
                                onChange={e => {
                                  setSubData({ ...subData, howtoway: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.howtoway, "G")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
