import React, { useEffect, useRef } from "react";
import FuelInputListRow from "./component/fuelInputListRow";
import { useAtom } from "jotai/index";
import {
  carDetail_atom,
  carFuelInfo_atom,
  clickedRow_atom,
} from "../../../../data/atom/dataManage/carManage/fuelInput_atom";
import { useVirtualizer } from "@tanstack/react-virtual";

const FuelInputList = props => {
  const { searchFuelInfo, drveUserList, acctList, fuelList } = props;
  const targetRef = useRef();
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);
  const [fuelInfo, setFuelInfo] = useAtom(carFuelInfo_atom);

  useEffect(() => {
    if (clickedRow.carrNumb !== undefined) {
      searchFuelInfo.mutate(clickedRow);
    }
  }, [clickedRow]);

  const rowVirtualizer = useVirtualizer({
    count: fuelInfo.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub mt-4">
      <div className="card-body">
        <div
          className="table-container table-sticky h-633"
          style={{ overflow: "auto" }}
          ref={targetRef}
        >
          <table
            className="table table-bordered table-responsivex my-table"
            style={{
              width: "105vw",
              marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              marginBottom: `${
                rowVirtualizer.getTotalSize() -
                (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                rowVirtualizer.getVirtualItems().length * 30
              }px`,
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "3%" }}>연번</th>
                <th style={{ width: "6%" }}>차량번호</th>
                <th style={{ width: "8%" }}>주유일시</th>
                <th style={{ width: "5%" }}>주유자</th>
                <th style={{ width: "5%" }}>주행거리</th>
                <th style={{ width: "4%" }}>연료</th>
                <th style={{ width: "4%" }}>단가(ℓ당)</th>
                <th style={{ width: "4%" }}>주유량(ℓ)</th>
                <th style={{ width: "14%" }}>주유소명</th>

                <th style={{ width: "7%" }}>결제구분</th>
                <th style={{ width: "11%" }}>카드번호</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              {fuelInfo.length !== 0 ? (
                rowVirtualizer.getVirtualItems().map(el => {
                  return (
                    <FuelInputListRow
                      data={fuelInfo[el.index]}
                      index={el.index}
                      drveUserList={drveUserList}
                      acctList={acctList}
                      fuelList={fuelList}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={12}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FuelInputList);
