import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import usePrintTab from "../../../../../components/modal/usePrintTab";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DbStatus = () => {
  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  const openPdf = usePrintTab();
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPrintHandler = useMutation(
    () =>
      axios.get("/api/work/db/print", {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          setPdfData(res?.data);
        }
      },
    }
  );

  useEffect(() => {
    if (!pdfData) {
      onPrintHandler.mutate();
    }
  }, []);

  return (
    <div className="db-status-container">
      <div className="db-status-title">DB분포현황표</div>
      <div className="db-status-filter">
        <div className="db-status-filter-title">출력 선택</div>
        <select name="" id="" className="db-status-filter-select">
          <option value="">거래자별 명단</option>
        </select>
        <div
          className="btn btn-outline-primary btn-filter-outline-primary"
          onClick={() => openPdf(pdfData)}
        >
          출력
        </div>
      </div>
      <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            height={500}
            width={1200}
            key={`page_${index + 1}`}
            renderTextLayer={false}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </div>
  );
};
export default DbStatus;
