import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";

const ModalCodeRegister = props => {
  const { modalShow, setModalShow } = props;
  const [searchFlag, setSearchFlag] = useState("");
  const [codeList, setCodeList] = useState([]);

  const searchCode = useMutation(
    data => axios.get("/api/data/cardMaster/code/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setCodeList(data);
      },
    }
  );
  const saveCode = useMutation(data => axios.post("/api/data/cardMaster/code/save", data), {
    onSuccess: res => {
      searchCode.mutate({ cardCheck: searchFlag });
    },
  });

  useEffect(() => {
    if (modalShow) {
      setSearchFlag("1");
    }
  }, [modalShow]);

  useEffect(() => {
    if (modalShow) {
      searchCode.mutate({ cardCheck: searchFlag });
    }
  }, [searchFlag]);

  const onChangeEventHandler = (key, value, idx) => {
    if (key === "radioA") {
      setSearchFlag("1");
    } else if (key === "radioB") {
      setSearchFlag("2");
    } else {
      const list = codeList.map((el, idx2) => {
        if (idx === idx2) {
          return { ...el, [key]: value };
        } else {
          return el;
        }
      });
      setCodeList(list);
    }
  };

  const btnAdd = () => {
    setCodeList([
      {
        cardCheck: searchFlag,
        clazz: "",
        code: "",
        codename: "",
        remark: "",
      },
      ...codeList,
    ]);
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      saveCode.mutate(codeList);
    }
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => {
        setModalShow(false);
        setSearchFlag("");
        setCodeList([]);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>코드등록</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-check form-check-inline my-0">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            checked={searchFlag === "1"}
            onChange={e => onChangeEventHandler("radioA", e.target.checked)}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            계정코드
          </label>
        </div>
        <div className="form-check form-check-inline my-0 ms-3">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            checked={searchFlag === "2"}
            onChange={e => onChangeEventHandler("radioB", e.target.checked)}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            카드구분
          </label>
        </div>
        <div>
          <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>No.</th>
                <th style={{ width: "30%" }}>코드번호</th>
                <th style={{ width: "30%" }}>코드명</th>
                <th style={{ width: "30%" }}>정렬순서</th>
              </tr>

              {codeList.length !== 0 ? (
                codeList.map((el, idx) => {
                  return (
                    <tr>
                      <th>{idx + 1}</th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={el.code}
                          onChange={e => onChangeEventHandler("code", e.target.value, idx)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={el.codename}
                          onChange={e => onChangeEventHandler("codename", e.target.value, idx)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={el.remark}
                          onChange={e => onChangeEventHandler("remark", e.target.value, idx)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={btnAdd}>
          추가
        </button>
        <button className="btn btn-outline-primary" onClick={btnSave}>
          저장
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ModalCodeRegister);
