import React from "react";
import Select from "react-select-virtualized";
import { useAtom } from "jotai/index";
import { sportsOption_atom } from "../../../../data/atom/dataManage/membershipItem/membershipItem_atom";
import { useAtomValue } from "jotai";

const tableRowSM = props => {
  const { currentData, onChangeRowEventHandler, btnDeleteRow } = props;
  const sportsOption = useAtomValue(sportsOption_atom);

  const gubun1Select = [
    { value: "11", label: "강남권" },
    { value: "22", label: "강북권" },
  ];
  const membershipTypeSelect = [
    { value: "1", label: "개인" },
    { value: "2", label: "부부" },
    { value: "3", label: "법인" },
    { value: "4", label: "가족" },
  ];
  const value2Select = [
    { value: "1", label: "1순위" },
    { value: "2", label: "2순위" },
    { value: "3", label: "3순위" },
    { value: "4", label: "4순위" },
    { value: "5", label: "5순위" },
    { value: "6", label: "6순위" },
    { value: "7", label: "7순위" },
  ];

  return (
    <tr>
      <td className="text-center">{currentData.idx >= 0 ? currentData.idx + 1 : ""}</td>
      <td>
        <input
          type="checkbox"
          checked={currentData.checkYn}
          onChange={e => onChangeRowEventHandler("checkYn", e.target.checked)}
          disabled={currentData.newFlag}
        />
      </td>
      <td>
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          options={sportsOption}
          value={
            sportsOption?.find(option => option.value === currentData?.value1)
              ? sportsOption?.find(option => option.value === currentData?.value1)
              : ""
          }
          onChange={e => onChangeRowEventHandler("value1", e?.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input text-center"
          value={currentData.code ? currentData.code : ""}
          onChange={e => onChangeRowEventHandler("code", e.target.value)}
          disabled={currentData.newFlag}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={currentData.codename ? currentData.codename : ""}
          onChange={e => onChangeRowEventHandler("codename", e.target.value)}
        />
      </td>
      <td>
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          options={value2Select}
          value={value2Select?.find(option => option.value === currentData?.value2)}
          onChange={e => onChangeRowEventHandler("value2", e?.value)}
        />
      </td>
      <td>
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          options={gubun1Select}
          value={gubun1Select?.find(option => option.value === currentData?.gubun1)}
          onChange={e => onChangeRowEventHandler("gubun1", e?.value)}
        />
      </td>
      <td>
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          options={membershipTypeSelect}
          value={membershipTypeSelect?.find(option => option.value === currentData?.membershipType)}
          onChange={e => onChangeRowEventHandler("membershipType", e?.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={currentData.remark ? currentData.remark : ""}
          onChange={e => onChangeRowEventHandler("remark", e.target.value)}
        />
      </td>
      <td>
        <button
          className="btn btn-outline-primary m-auto  h-25px"
          onClick={() => btnDeleteRow(currentData)}
        >
          삭제
        </button>
      </td>
    </tr>
  );
};

export default React.memo(tableRowSM);
