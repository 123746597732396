import React, { useEffect, useRef } from "react";
import { useAtom } from "jotai/index";
import {
  accountOption_atom,
  quotationMain_atom,
  quotationMainUserOption_atom,
  quotationSubList_atom,
} from "../../../data/atom/dataManage/quotationManagement/quotationManagement_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment/moment";

const QuotationMemo = props => {
  const { searchQuotationMain } = props;
  // const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  // const [detailFlag, setDetailFlag] = useAtom(detailFlag_atom);
  // const [registerFlag, setRegisterFlag] = useAtom(registerFlag_atom);
  // const [userOption, setUserOption] = useAtom(userOption_atom);
  const [accountOption, setAccountOption] = useAtom(accountOption_atom);
  const [quotationMainUserOption, setQuotationMainUserOption] = useAtom(
    quotationMainUserOption_atom
  );
  // const [quotationList, setQuotationList] = useAtom(quotationList_atom);
  // const [quotationListLimit, setQuotationListLimit] = useAtom(quotationListLimit_atom);
  const [quotationMain, setQuotationMain] = useAtom(quotationMain_atom);
  const [quotationSubList, setQuotationSubList] = useAtom(quotationSubList_atom);

  const readyPaperRef = useRef();
  const remarkRef = useRef();

  const saveQuotationMemoRemark = useMutation(
    data => axios.post("/api/data/estimate/bottom/saveRight", data),
    {
      onSuccess: res => {
        searchQuotationMain.mutate({ ...quotationMain, memo: quotationMain.remark });
      },
    }
  );

  const saveQuotationMemoReadyPaper = useMutation(
    data => axios.post("/api/data/estimate/bottom/saveLeft", data),
    {
      onSuccess: res => {
        searchQuotationMain.mutate({ ...quotationMain, memo: quotationMain.readyPaper });
      },
    }
  );

  useEffect(() => {
    handleResizeHeight();
  }, [quotationMain.remark, quotationMain.readyPaper]);

  const handleResizeHeight = () => {
    readyPaperRef.current.style.height = "auto";
    remarkRef.current.style.height = "auto";
    if (remarkRef.current.scrollHeight >= readyPaperRef.current.scrollHeight) {
      readyPaperRef.current.style.height = remarkRef.current.scrollHeight + "px";
      remarkRef.current.style.height = remarkRef.current.scrollHeight + "px";
    } else {
      readyPaperRef.current.style.height = readyPaperRef.current.scrollHeight + "px";
      remarkRef.current.style.height = readyPaperRef.current.scrollHeight + "px";
    }
  };

  const onChangeEventHandler = (key, value) => {
    setQuotationMain({ ...quotationMain, [key]: value });
  };

  const btnIndividualSetting = () => {
    if (moment().diff(moment(quotationMain.currDate), "days") > 7) {
      alert("견적일자는 7일 이내로 등록해주세요");
      return;
    }
    setQuotationMain({
      ...quotationMain,
      readyPaper:
        "● 인감증명서 2부\n" +
          "● 주민등록등본 1부\n" +
          "● 회원권 대/소 반납\n" +
          "● 신분증 앞/뒤 사본 1부\n" +
          "● 인감도장\n\n" +
          quotationMain.readyPaper ?? "",
    });
  };
  const btnCompanySetting = () => {
    if (moment().diff(moment(quotationMain.currDate), "days") > 7) {
      alert("견적일자는 7일 이내로 등록해주세요");
      return;
    }
    setQuotationMain({
      ...quotationMain,
      readyPaper:
        "● 법인인감증명서 2부\n" +
        "● 법인등기부등본 2부\n" +
        "● 회원권 대/소 반납\n" +
        "● 사업자등록증 사본 1부\n" +
        "● 매각용 세금계산서 1부\n" +
        "● 법인명판/인감도장\n\n" +
        quotationMain.readyPaper,
    });
  };
  const btnSaveReadyPaper = () => {
    if (confirm("저장하시겠습니까?")) {
      if (moment().diff(moment(quotationMain.currDate), "days") > 7) {
        alert("견적일자는 7일 이내로 등록해주세요");
        return;
      }
      saveQuotationMemoReadyPaper.mutate([
        {
          estimaNo: quotationMain.estimaNo,
          ilNo: quotationMain.ilNo,
          memo: quotationMain?.readyPaper ?? "",
        },
      ]);
    }
  };

  const btnSaveRemark = () => {
    if (confirm("저장하시겠습니까?")) {
      if (moment().diff(moment(quotationMain.currDate), "days") > 7) {
        alert("견적일자는 7일 이내로 등록해주세요");
        return;
      }
      saveQuotationMemoRemark.mutate([
        { estimaNo: quotationMain.estimaNo, ilNo: quotationMain.ilNo, memo: quotationMain?.remark },
      ]);
    }
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col-6">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5 className="card-title m-0">구비서류</h5>
              <div className="w-50 d-flex align-items-center justify-content-end">
                <button
                  className="btn btn-outline-primary"
                  onClick={btnIndividualSetting}
                  style={{ marginRight: "16px" }}
                >
                  개인 셋팅
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={btnCompanySetting}
                  style={{ marginRight: "16px" }}
                >
                  법인 셋팅
                </button>
                <button className="btn btn-outline-primary" onClick={btnSaveReadyPaper}>
                  저장
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex flex-column">
                <textarea
                  className="form-control mb-2"
                  rows="7"
                  value={quotationMain.readyPaper ? quotationMain.readyPaper : ""}
                  onChange={e => {
                    onChangeEventHandler("readyPaper", e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5 className="card-title m-0">기타내용</h5>
              <>
                <button className="btn btn-outline-primary" onClick={btnSaveRemark}>
                  저장
                </button>
              </>
            </div>
            <div className="card-body d-flex align-items-center">
              <textarea
                className="form-control mb-2"
                rows="7"
                value={quotationMain.remark ? quotationMain.remark : ""}
                onChange={e => {
                  onChangeEventHandler("remark", e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      {/* 이하 견적서 Result */}

      <div className="card card-main">
        <div className="card-body">
          <div className="">
            <h3 className="quot_heading">견 적 서</h3>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="quot-row mb-1">
                <label className="quot-label">견적번호 : </label>
                <span className="quot-value">
                  {quotationMain.estimaNo
                    ? quotationMain.estimaNo.replace(/^(\w{2})(\w{4})(\w{4})$/, `$1-$2-$3`)
                    : ""}
                </span>
              </div>
              <div className="quot-row">
                <label className="quot-label">견적일자 : </label>
                <span className="quot-value">
                  {quotationMain.currDate
                    ? moment(quotationMain.currDate).format("YYYY/MM/DD")
                    : ""}
                </span>
              </div>
              <div className="quot-row mb-1">
                <label className="quot-label">수 신 : </label>
                <span className="quot-value">
                  {quotationMain.company ? quotationMain.company : ""}
                </span>
              </div>
              <div className="quot-row mb-1">
                <label className="quot-label">참 조 : </label>
                <span className="quot-value">
                  {quotationMain.kname
                    ? `${quotationMain.kname} ${quotationMain.position}님 귀하`
                    : ""}
                </span>
              </div>
              <div className="quot-row mb-1">
                <label className="quot-label">전 화 : </label>
                <span className="quot-value">{quotationMain.ctel ? quotationMain.ctel : ""}</span>
              </div>
              <div className="quot-row mb-1">
                <label className="quot-label">팩 스 : </label>
                <span className="quot-value">{quotationMain.cfax ? quotationMain.cfax : ""}</span>
              </div>
              <div className="quot-row mb-1">
                <label className="quot-label f-roboto-medium">E-MAIL : </label>
                <span className="quot-value">{quotationMain.email ? quotationMain.email : ""}</span>
              </div>
              <div className="p_quot">의뢰하신 건에 대하여 아래와 같이 견적합니다.</div>
            </div>
            <div className="col-6">
              <div className="quot-row">
                <label className="quot-label">관리번호 : </label>
                <span className="quot-value">
                  {quotationMain.custId
                    ? quotationMain.custId.replace(/^(\w{4})(\w{4})$/, `$1-$2`)
                    : ""}
                </span>
              </div>
              <table className="table table-bordered table-responsive my-table my-3x">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>발 &nbsp; &nbsp;신</th>
                    <th>220 – 86 – 49538</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2} className="text-start px-3 py-3">
                      <div className="quot-co-name mb-2">
                        {quotationMain.scompany ? quotationMain.scompany : ""}
                      </div>
                      <div className="quot-co-addr mb-3">
                        {quotationMain.hapAddres ? quotationMain.hapAddres : ""}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="quot-row-sm">
                            <label className="quot-label-sm">Tel : </label>
                            <span className="quot-value-sm">
                              {quotationMain.sctel ? quotationMain.sctel : ""}
                            </span>
                          </div>
                          <div className="quot-row-sm m-0">
                            <label className="quot-label-sm">담당 : </label>
                            <span className="quot-value-sm">
                              {quotationMain.spartner
                                ? `${quotationMain.sauthority} ${
                                    quotationMain.spartner
                                      ? quotationMainUserOption?.filter(
                                          el => el.spartner === quotationMain.spartner
                                        )?.[0]?.uuuser
                                      : ""
                                  }`
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="quot-row-sm">
                            <label className="f-roboto-medium quot-label-sm">Fax : </label>
                            <span className="quot-value-sm">
                              {quotationMain.scfax ? quotationMain.scfax : ""}
                            </span>
                          </div>
                          <div className="quot-row-sm m-0">
                            <label className="quot-label-sm">HP : </label>
                            <span className="quot-value-sm">
                              {quotationMain.scellular ? quotationMain.scellular : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex-center-end p_quot mt-1">금액 단위 : 원</div>
          <table className="table table-bordered table-responsive my-table my-3x mt-1">
            <thead>
              <tr>
                <th colSpan={2} style={{ width: "10%" }}>
                  구분
                </th>
                <th style={{ width: "20%" }}>회원권명</th>
                <th style={{ width: "10%" }}>구좌</th>
                <th style={{ width: "20%" }}>매매가격</th>
                <th style={{ width: "15%" }}>명의개서료</th>
                <th style={{ width: "15%" }}>인지세</th>
                <th>중개수수료</th>
              </tr>
            </thead>
            <tbody>
              {quotationSubList.length !== 0 ? (
                quotationSubList.map(el => {
                  return (
                    <tr>
                      <td>
                        <select
                          defaultValue={el.gch}
                          value={el.gch}
                          onMouseDown={e => e.preventDefault()}
                          style={{ border: "0", appearance: "none" }}
                        >
                          <option value="1">골프</option>
                          <option value="2">콘도</option>
                          <option value="3">헬스</option>
                        </select>
                      </td>
                      <td>
                        <select
                          defaultValue={el.gubun}
                          value={el.gubun}
                          onMouseDown={e => e.preventDefault()}
                          style={{ border: "0", appearance: "none" }}
                        >
                          <option value="D">매도</option>
                          <option value="S">매수</option>
                          <option value="B">분양</option>
                          <option value="E">기타</option>
                        </select>
                      </td>
                      <td>{el.codename}</td>
                      <td>
                        <select
                          defaultValue={el.gch}
                          onMouseDown={e => e.preventDefault()}
                          style={{ border: "0", appearance: "none" }}
                          value={el.kind}
                        >
                          {accountOption.length !== 0 ? (
                            accountOption.map(el => {
                              return <option value={el.key}>{el.value}</option>;
                            })
                          ) : (
                            <></>
                          )}
                        </select>
                      </td>
                      <td>
                        {el.priceWon
                          ? Number(String(el.priceWon).replaceAll(",", "")).toLocaleString("en-us")
                          : ""}
                      </td>
                      <td>
                        {el.gaesuWon
                          ? Number(String(el.gaesuWon).replaceAll(",", "")).toLocaleString("en-us")
                          : ""}
                      </td>
                      <td>
                        {el.stampWon
                          ? Number(String(el.stampWon).replaceAll(",", "")).toLocaleString("en-us")
                          : ""}
                      </td>
                      <td>
                        {el.charge
                          ? Number(String(el.charge).replaceAll(",", "")).toLocaleString("en-us")
                          : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8}></td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="">
            <h3 className="quot_heading-2">이 하 여 백</h3>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title w-100 m-0 text-center">구비서류</h5>
                </div>
                <div className="card-body">
                  <div className="d-flex flex-column p_quot">
                    <textarea
                      value={quotationMain.readyPaper ? quotationMain.readyPaper : ""}
                      rows={1}
                      ref={readyPaperRef}
                      style={{
                        border: "0",
                        minHeight: "fit-content",
                        resize: "none",
                        overflow: "hidden",
                        outline: "none",
                      }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header">
                  <h5 className="card-title w-100 m-0 text-center">기타내용</h5>
                </div>
                <div className="card-body">
                  <div className="d-flex flex-column p_quot">
                    <textarea
                      value={quotationMain.remark ? quotationMain.remark : ""}
                      rows={1}
                      ref={remarkRef}
                      style={{
                        border: "0",
                        minHeight: "fit-content",
                        resize: "none",
                        overflow: "hidden",
                        outline: "none",
                      }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="quot-footer-wrapper">
            <div className="quot-txt1">감사합니다.</div>
            <div className="quot-separator"></div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="quot-txt2">귀사의 무궁한 발전을 기원합니다.</div>
              <div className="quot-txt3">
                신용과 믿음으로 고객만족을 최우선으로 생각하는 동아회원권
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(QuotationMemo);
