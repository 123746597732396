import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import EmpModal from "../../../../components/modal/Modal";
import { Spinner } from "../../../../components/spinner/spinner";
import { useAtom } from "jotai";
import {
  EmployeeStatus_empStatusDate,
  EmployeeStatus_row,
  EmployeeStatus_searchData,
} from "../../../../data/atom/personManage/EmployeeStatus/EmployeeStatus_atom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";

const EmployeeStatus = () => {
  const defaultURL = "";
  const [isOpen, setIsOpen] = useState(false);
  // const [searchListNumber, setSearchListNumber] = useAtom(EmployeeStatus_row); // 테이블 row 갯수
  const [getSearchData, setGetSearchData] = useState([]);
  const [getEmpStatusData, setGetEmpStatusData] = useState([]);
  const [getProcEmpData, setGetProcEmpData] = useState({});
  // 검색 상태 값
  const [empStatusDate, setEmpStatusDate] = useAtom(EmployeeStatus_empStatusDate);
  const [searchData, setSearchData] = useAtom(EmployeeStatus_searchData);
  const [procEmpData, setProcEmpData] = useState({
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setEmpStatusDate({
        searchDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setSearchData({
        dept: 1,
        searchDate: moment(new Date()).format("YYYY-MM-DD"),
      });
    });
  }, []);

  const pressEnter = e => {
    if (e.key === "Enter") {
      search.mutate(searchData);
    }
  };

  // API - 재직현황 검색
  const getEmpStatus = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empByDay/getEmpStatus", {
        params: data,
      }),
    {
      onSuccess: res => {
        const result = res?.data?.result.empStatusList;
        const newObj = result.reduce((acc, item) => {
          if (acc[item.jobName]) {
            acc[item.jobName].push(item);
          } else {
            acc[item.jobName] = [item];
          }
          return acc;
        }, {});

        let newList = [];
        for (let objKey in newObj) {
          if (newObj.hasOwnProperty(objKey)) {
            newList.push({ key: objKey, value: newObj[objKey], count: newObj[objKey].length });
          }
        }

        const resList = newList.map(list => {
          const modifyObj = list.value.reduce((acc, item) => {
            if (acc[item.deptName]) {
              acc[item.deptName].push(item);
            } else {
              acc[item.deptName] = [item];
            }
            return acc;
          }, {});

          let modifyList = [];

          for (let objKey in modifyObj) {
            if (modifyObj.hasOwnProperty(objKey)) {
              modifyList.push({
                key: objKey,
                value: modifyObj[objKey],
                count: modifyObj[objKey].length,
              });
            }
          }
          return { ...list, value: modifyList };
        });

        // setGetEmpStatusData(res?.data?.result);
        setGetEmpStatusData({ empStatusData: resList, tot: res?.data?.result.tot });
      },
    }
  );

  // API - 상시근로자 수 검색
  const procEmp = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empByDay/procEmp", {
        params: data,
      }),
    {
      onSuccess: res => {
        setGetProcEmpData(res?.data?.result);
      },
    }
  );

  // API - 재직자 검색
  const search = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empByDay/search", {
        params: data,
      }),
    {
      onSuccess: res => {
        setGetSearchData(res?.data?.result);
      },
    }
  );

  useEffect(() => {
    getEmpStatus.mutate(empStatusDate);
    search.mutate(searchData);
  }, []);

  return (
    <>
      {/* 상시 근로자수 모달 */}
      <EmpModal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        width="25%"
        minWidth="475px"
        title={"상시 근로자수"}
      >
        {procEmp.isLoading && <Spinner />}

        <div className="w-100">
          {/* <div className="modal-title ">상시 근로자수</div> */}
          <div
            className="d-flex align-items-center justify-content-between mt-3"
            onKeyDown={e => {
              if (e.key === "Enter") {
                procEmp.mutate(procEmpData);
              }
            }}
          >
            <div>기간</div>
            <input
              type="date"
              max={"9999-12-31"}
              className="form-control filter-input filter1-input2"
              value={procEmpData.fromDate}
              onChange={e =>
                setProcEmpData({
                  ...procEmpData,
                  fromDate: e.target.value,
                })
              }
            />
            <span>~</span>
            <input
              type="date"
              max={"9999-12-31"}
              min={procEmpData.fromDate}
              className="form-control filter-input filter1-input2"
              value={procEmpData.toDate}
              onChange={e =>
                setProcEmpData({
                  ...procEmpData,
                  toDate: e.target.value,
                })
              }
            />
            <button className="btn btn-outline-primary" onClick={() => procEmp.mutate(procEmpData)}>
              계산
            </button>
          </div>
          <table className="table table-bordered table-responsive my-table1 mt-10px">
            <tbody>
              <tr>
                <th style={{ width: "8.052%" }}>연근무 인원(A)</th>
                <td style={{ width: "10.281%" }}>
                  <div className="w-10 d-flex">
                    <div className="w-10 ms-auto me-2">
                      <span className="me-1">{getProcEmpData?.totalEmp}</span>명
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th style={{ width: "8.052%" }}>사업자 가동일수(B)</th>
                <td style={{ width: "10.281%" }}>
                  <div className="w-10 d-flex">
                    <div className="w-10 ms-auto me-2">
                      <span className="me-1">{getProcEmpData?.totalDay}</span>일
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th style={{ width: "8.052%" }}>상시 근로자 수(A/B)</th>
                <td style={{ width: "10.281%" }}>
                  <div className="w-10 d-flex">
                    <div className="w-10 ms-auto me-2">
                      <span className="me-1">{getProcEmpData?.avgEmp}</span>명
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <button className="btn btn-outline-primary ms-auto" onClick={() => closeModal()}>
            닫기
          </button> */}
        </div>
      </EmpModal>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">일자별 재직자 현황</div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">기준일자</label>
                  <input
                    type="date"
                    max={"9999-12-31"}
                    className="form-control filter-input filter1-input2"
                    value={searchData.searchDate}
                    onChange={e =>
                      setSearchData({
                        ...searchData,
                        searchDate: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">출력구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.dept}
                    onChange={e => setSearchData({ ...searchData, dept: e.target.value })}
                  >
                    <option value="1">동아회원권거래소(주)</option>
                    <option value="2">(주)골프팁스코리아</option>
                    <option value="3">DA동아투어(주)</option>
                    <option value="4">동아회원권그룹(주)</option>
                    <option value="5">동아FnB(주)</option>
                    <option value="6">동아회원권(주)</option>
                    <option value="7">D&H미디어(주)</option>
                  </select>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={() => search.mutate(searchData)}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between" style={{ gap: "14px" }}>
          <div className="card card-main person-employ-status-search-result  mt-0">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-10px">
                <div className="tab1-row1-count">
                  조회결과 : &nbsp; &nbsp; &nbsp; &nbsp;{getSearchData?.length} 건
                </div>
                {/* <div className="d-flex align-items-center w-50 justify-content-end">
                  <select
                    className="form-select filter-perpage ms-8px h-31"
                    value={searchListNumber}
                    onChange={e => {
                      setSearchListNumber(e.target.value);
                    }}
                  >
                    <option value="5">5개씩 보기</option>
                    <option value="10">10개씩 보기</option>
                    <option value="20">20개씩 보기</option>
                  </select>
                </div> */}
              </div>
              <div
                className={`table-container table-sticky h-633
                
            
                `}
              >
                <table className="table table-bordered table-responsive my-table mt-0">
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>No.</th>
                      <th>소속</th>
                      <th>근무구분</th>
                      <th>성명</th>
                      <th>직위</th>
                      <th>근무부서</th>
                      <th>직책</th>
                      <th>입사일자</th>
                      <th>퇴사일자</th>
                      <th>재입사일자</th>
                      <th>비고</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getSearchData?.length > 0 ? (
                      getSearchData?.map((data, _idx) => {
                        return (
                          <tr>
                            <td>{_idx + 1}</td>
                            <td>{data?.deptName}</td>
                            <td>{data?.workName}</td>
                            <td>{data?.kname}</td>
                            <td>{data?.authName}</td>
                            <td>{data?.jobName}</td>
                            <td>{data?.partName}</td>
                            <td>{data?.inDate && moment(data?.inDate).format("YYYY-MM-DD")}</td>
                            <td>{data?.outeDate && moment(data?.outeDate).format("YYYY-MM-DD")}</td>
                            <td>{data?.reDate && moment(data?.reDate).format("YYYY-MM-DD")}</td>
                            <td>{data?.susubCheck}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={11}>조회 결과가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="two-col-outer mt-0 person-employ-status-report">
            <div className="col-5 w-100 ">
              <div className="card card-main mt-0" style={{ minHeight: "391px" }}>
                <div className="card-header">
                  <h5 className="card-title m-0">노동부 보고용-재직현황</h5>
                  <button
                    className="btn btn-outline-primary ms-auto w-30 mt-3x me-3x h-25px"
                    onClick={() => setIsOpen(true)}
                  >
                    상시근로자수
                  </button>
                </div>
                <div className="card-body">
                  <div className="w-100 filter_fields_row mb-3">
                    <div className="filter1-field filter1-field2 m-0 p-0">
                      <label className="filter1-label">기준일자</label>
                      <input
                        type="date"
                        max={"9999-12-31"}
                        className="form-control filter-input filter1-input2"
                        value={empStatusDate.searchDate}
                        onChange={e =>
                          setEmpStatusDate({
                            searchDate: e.target.value,
                          })
                        }
                      />
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary me-0 ms-8px"
                        onClick={() => getEmpStatus.mutate(empStatusDate)}
                      >
                        생성
                      </button>
                    </div>
                    <div className="f-regular f-15">재입사 포함</div>
                  </div>

                  <div className="table-container">
                    <table className="table table-bordered table-responsive my-table m-0">
                      <thead>
                        <tr>
                          <th style={{ width: "15.38%" }}>구분</th>
                          <th style={{ width: "35.07%" }}>계열사명</th>
                          <th style={{ width: "15.38%" }}>구분</th>
                          <th style={{ width: "15.38%" }}>성별</th>
                          <th style={{ width: "15.38%" }}>인원</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {getEmpStatusData?.empStatusList?.map(data => {
                          return (
                            <tr>
                              <td>{data?.jobName}</td>
                              <td>{data?.deptName}</td>
                              <td>{data?.workName}</td>
                              <td>{data?.gender === "女" ? "여자" : "남자"}</td>
                              <td>{data?.count}</td>
                            </tr>
                          );
                        })} */}
                        {getEmpStatusData?.empStatusData?.map((data, _dataIdx) => {
                          return data.value.map((item, _itemIdx) => {
                            return item.value.map((res, _idx) => {
                              return (
                                <tr>
                                  {_idx === 0 && _itemIdx === 0 && (
                                    <td rowSpan={data.count}>{data?.key}</td>
                                  )}
                                  {_idx === 0 && <td rowSpan={item.count}>{item?.key}</td>}
                                  <td>{res?.workName}</td>
                                  <td>
                                    {res?.gender === "女" ? "여자" : res?.gender === "男" && "남자"}
                                  </td>
                                  <td>{res?.count}</td>
                                </tr>
                              );
                            });
                          });
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td>계</td>
                          <td></td>
                          <td></td>
                          <td>{getEmpStatusData?.tot}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeStatus;
