import React, { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const Twelve = ({ data }) => {
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const resultSumTitle = data => {
    switch (data) {
      case "A":
        return "본사 - 골프 계";
      case "B":
        return "본사 - 콘도 계";
      case "C":
        return "본사 - 분양 계";
      case "E":
        return "대구 계";
      case "F":
        return "부산 계";
      case "G":
        return "호남 계";
    }
  };

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={
              {
                // width: `${data.tableRows?.length !== 0 ? "1800px" : ""} `,
                // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                // marginBottom: `${
                //   rowVirtualizer.getTotalSize() -
                //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                //   rowVirtualizer.getVirtualItems().length * 30
                // }px`,
              }
            }
          >
            <thead>
              <tr>
                <th rowSpan={2}>구분</th>
                <th colSpan={2}>1월</th>
                <th colSpan={2}>2월</th>
                <th colSpan={2}>3월</th>
                <th colSpan={2}>4월</th>
                <th colSpan={2}>5월</th>
                <th colSpan={2}>6월</th>
                <th colSpan={2}>7월</th>
                <th colSpan={2}>8월</th>
                <th colSpan={2}>9월</th>
                <th colSpan={2}>10월</th>
                <th colSpan={2}>11월</th>
                <th colSpan={2}>12월</th>
                <th colSpan={2}>합계</th>
              </tr>
              <tr>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
                <th>건수</th>
                <th>금액</th>
              </tr>
            </thead>
            <tbody>
              {data.result?.length !== 0 ? (
                data.result?.reduce((acc, cur, idx, arr) => {
                  acc = [
                    ...acc,
                    <tr>
                      <td>{cur.jisaName}</td>
                      <td>{cur.exchangeCnt01}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt01)}</td>
                      <td>{cur.exchangeCnt02}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt02)}</td>
                      <td>{cur.exchangeCnt03}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt03)}</td>
                      <td>{cur.exchangeCnt04}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt04)}</td>
                      <td>{cur.exchangeCnt05}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt05)}</td>
                      <td>{cur.exchangeCnt06}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt06)}</td>
                      <td>{cur.exchangeCnt07}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt07)}</td>
                      <td>{cur.exchangeCnt08}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt08)}</td>
                      <td>{cur.exchangeCnt09}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt09)}</td>
                      <td>{cur.exchangeCnt10}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt10)}</td>
                      <td>{cur.exchangeCnt11}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt11)}</td>
                      <td>{cur.exchangeCnt12}</td>
                      <td className="text-end">{numberFixedFormat(cur.resultAmnt12)}</td>
                      <td>{cur.sumExchange}</td>
                      <td className="text-end">{numberFixedFormat(cur.sumResult)}</td>
                    </tr>,
                  ];

                  if (cur.jisaSeq[0] !== arr[idx + 1]?.jisaSeq[0]) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td>{resultSumTitle(cur.jisaSeq[0])}</td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt01}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt01
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt02}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt02
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt03}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt03
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt04}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt04
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt05}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt05
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt06}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt06
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt07}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt07
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt08}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt08
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt09}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt09
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt10}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt10
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt11}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt11
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.exchangeCnt12}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.resultAmnt12
                          )}
                        </td>
                        <td>{data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.sumExchange}</td>
                        <td className="text-end">
                          {numberFixedFormat(
                            data.resultSum[cur.yearMnth][cur.jisaSeq[0]]?.sumResult
                          )}
                        </td>
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td>총계</td>
                        <td>{data.total[cur.yearMnth].exchangeCnt01}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt01)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt02}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt02)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt03}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt03)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt04}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt04)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt05}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt05)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt06}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt06)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt07}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt07)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt08}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt08)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt09}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt09)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt10}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt10)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt11}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt11)}
                        </td>
                        <td>{data.total[cur.yearMnth].exchangeCnt12}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].resultAmnt12)}
                        </td>
                        <td>{data.total[cur.yearMnth].sumExchange}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.total[cur.yearMnth].sumResult)}
                        </td>
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={26}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
