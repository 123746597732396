import { useAtomValue } from "jotai";
import { transactionHistoryClickedRow_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { axios } from "../../../../../util/axios/axios";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useGetMemberNm } from "../../../../../api/code/useGetMemberNm";
import { useGetPartner } from "../../../../../api/code/useGetPartner";

const searchDetailBottomTab3 = () => {
  const atomData = useAtomValue(transactionHistoryClickedRow_atom);
  const [cacheData, setCacheData] = useState(atomData);
  const [listData, setListData] = useState([]);
  const memberNm = useGetMemberNm();
  const partner = useGetPartner();

  // 조회 API
  const getMemberShip = useMutation(
    data =>
      axios.get("/api/work/transaction/getMemberShip", {
        params: { custId: data.seqNo },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setListData(result);
      },
    }
  );

  useEffect(() => {
    setCacheData(atomData);
    if (atomData) {
      getMemberShip.mutate(atomData);
    } else setListData([]);
  }, [atomData]);

  // const displayGu = gu => {
  //   switch (gu) {
  //     case "1":
  //       return "골프";
  //     case "2":
  //       return "콘도";
  //     case "3":
  //       return "헬스";
  //     default:
  //       return "";
  //   }
  // };

  return (
    <div className="tab2-content-container  px-20px">
      <div className="table-tabs-sub mb-15px">보유회원권</div>
      <div className="table-container">
        <div
          className={`table-container table-sticky 
               h-183`}
          id="scroll-table-body"
        >
          <table className="table table-bordered table-responsive my-table m-0">
            <thead>
              <tr>
                <th style={{ width: "8%" }}>No.</th>
                <th colSpan={2}>구분</th>
                <th>회원권명</th>
                <th>비고</th>
                <th>보유일자</th>
                <th>삭제자</th>
                <th>삭제일시</th>
              </tr>
            </thead>
            <tbody>
              {listData.length > 0 ? (
                listData.map((item, index) => (
                  <tr className={`${item.deleteGubun === "Y" ? "deleted" : ""}`}>
                    <td>{index + 1}</td>
                    <td className={item.deleteGubun === "Y" && "text-danger"}>
                      {item.deleteGubun === "Y" && "前"}
                    </td>
                    <td>{item?.gu}</td>
                    <td>{item?.cardNo}</td>
                    <td>{item.remark}</td>
                    <td>{item.inDate && moment(item.inDate).format("YYYY.MM.DD")}</td>
                    <td>
                      {item.deleteId && partner.data?.find(el => el.key === item.deleteId)?.value}
                    </td>
                    <td>{item.deleteDate && moment(item.deleteDate).format("YYYY.MM.DD")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    보유회원권이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default searchDetailBottomTab3;
