import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../../util/axios/axios";
import { transactionHistoryInquiryPopupEditedRow_atom } from "../../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useAtom } from "jotai";
import moment from "moment";
import useGMCMSMSelect from "../../../../../../components/select/useGMCMSMSelect";

const SearchPanel = props => {
  const { searchParam, setSearchParam, fetchFirstPage } = props;

  const [editedRow, setEditedRow] = useAtom(transactionHistoryInquiryPopupEditedRow_atom);

  const queryClient = useQueryClient();
  const jisaGubun = queryClient.getQueryData(["getJisaGubun"]);
  const txGubun3 = queryClient.getQueryData(["getTxGubun3"]);
  const { returnArr, changeClazz } = useGMCMSMSelect();
  const saveTransaction = useMutation(
    data => axios.post("/api/work/transaction/saveTransaction", data),
    {
      onSuccess: res => {
        setEditedRow({});
        fetchFirstPage();
      },
    }
  );

  const onChangeSearchParam = (key, value) => {
    if (key === "txGubun1") {
      const clazzMap = {
        9: "",
        1: "GM",
        2: "CM",
        3: "SM",
      };
      changeClazz(clazzMap[value] || "");
    }
    setSearchParam(prev => ({ ...prev, [key]: value }));
  };

  const btn_search = () => {
    fetchFirstPage();
  };

  const btn_save = () => {
    let tmp = [];
    for (var key in editedRow) {
      let obj = { ...editedRow[key] };
      // moment를 이용하여 format 변환이 가능한지 체크
      if (moment(obj.custDate, "YYYY-MM-DD").isValid()) {
        obj.custDate = moment(obj.custDate).format("YYYY-MM-DD");
      }
      if (moment(obj.modDate, "YYYY-MM-DD HH:mm:ss").isValid()) {
        obj.modDate = moment(obj.modDate).format("YYYY-MM-DD HH:mm:ss");
      }
      if (moment(obj.sinDate, "YYYY-MM-DD").isValid()) {
        obj.sinDate = moment(obj.sinDate).format("YYYY-MM-DD");
      }
      if (moment(obj.jiroSendDate, "YYYY-MM-DD").isValid()) {
        obj.jiroSendDate = moment(obj.jiroSendDate).format("YYYY-MM-DD");
      }
      if (obj.yangdoWon2 === "") {
        obj.yangdoWon2 = 0;
      }
      tmp.push(obj);
    }

    saveTransaction.mutate(tmp);
  };

  return (
    <>
      <div className="row">
        <div className="col-1 w-6">
          <label>지사구분</label>
        </div>
        <div className="col-1">
          <select
            className="form-select"
            value={searchParam.jisa}
            onChange={e => onChangeSearchParam("jisa", e.target.value)}
          >
            {jisaGubun?.map(item => (
              <option value={item.key}>{item.value}</option>
            ))}
          </select>
        </div>
        <div className="col-1 text-end">
          <label>거래구분</label>
        </div>
        <div className="col-1 p-0">
          <div className="d-flex align-items-center justify-content-between">
            <select
              className="form-select"
              value={searchParam.txGubun1}
              onChange={e => onChangeSearchParam("txGubun1", e.target.value)}
            >
              <option value="9">전체</option>
              <option value="1">골프</option>
              <option value="2">콘도</option>
              <option value="3">헬스</option>
            </select>
            <select
              className="form-select ms-2"
              value={searchParam.txGubun2}
              onChange={e => onChangeSearchParam("txGubun2", e.target.value)}
            >
              <option value="9">전체</option>
              <option value="1">양도</option>
              <option value="2">양수</option>
            </select>
          </div>
        </div>
        <div className="col-1">
          <select
            className="form-select w-100"
            value={searchParam.txGubun3}
            onChange={e => onChangeSearchParam("txGubun3", e.target.value)}
          >
            <option value={""}>전체</option>
            {returnArr.map(item => (
              <option value={item.key}>{item.value}</option>
            ))}
          </select>
        </div>
        <div className="col-1 text-end">
          <label>세무신고</label>
        </div>
        <div className="col-1">
          <select
            className="form-select w-100"
            value={searchParam.taxTarget}
            onChange={e => onChangeSearchParam("taxTarget", e.target.value)}
          >
            <option value="A">전체</option>
            <option value="Y">대상</option>
            <option value="N">비대상</option>
            <option value="R">관청접수</option>
            <option value="S">완료</option>
          </select>
        </div>
        <div className="col-1 text-end">
          <label>거래기간</label>
        </div>
        <div className="col-2">
          <div className="d-flex align-items-center">
            <div className="filter1-field filter1-field2 m-0 p-0">
              <input
                type="date"
                max={"9999-12-31"}
                className="form-control filter-input filter1-input2"
                value={searchParam.fromDate}
                onChange={e => onChangeSearchParam("fromDate", e.target.value)}
              />
            </div>
            <div className="txt_daterange">~</div>
            <div className="filter1-field filter1-field3 px-0">
              <input
                type="date"
                max={"9999-12-31"}
                className="form-control filter-input filter1-input2"
                value={searchParam.toDate}
                onChange={e => onChangeSearchParam("toDate", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row my-3">
        <div className="col-5">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="inlineRadio1"
              name="chk_search"
              value="A"
              checked={searchParam.radio === "A"}
              onChange={e => onChangeSearchParam("radio", e.target.value)}
            />
            <label className="form-check-label" htmlFor="inlineRadio1">
              전체
            </label>
          </div>
          <div className="form-check form-check-inline mx-4">
            <input
              className="form-check-input"
              type="radio"
              id="inlineRadio2"
              name="chk_search"
              value="B"
              checked={searchParam.radio === "B"}
              onChange={e => onChangeSearchParam("radio", e.target.value)}
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              거래번호 누락건
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="inlineRadio3"
              name="chk_search"
              value="C"
              checked={searchParam.radio === "C"}
              onChange={e => onChangeSearchParam("radio", e.target.value)}
            />
            <label className="form-check-label" htmlFor="inlineRadio3">
              샵거래만 조회
            </label>
          </div>
        </div>
        <div className="col-5 col-name">
          <div className="d-flex align-items-center justify-content-end">
            <label className="me-2">성명, 회사명, 회원증No.,거래번호</label>
            <input
              type="text"
              className="form-control table-input input-name-modal"
              value={searchParam.keyword}
              onChange={e => onChangeSearchParam("keyword", e.target.value)}
            />
          </div>
        </div>
        <div className="col-2 col-buttons p-0">
          <div className="d-flex align-items-center justify-content-end">
            <button className="btn btn-outline-primary mx-1" onClick={btn_search}>
              조회
            </button>
            <button className="btn btn-outline-primary mx-1" onClick={btn_save}>
              저장
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SearchPanel);
