import { atom } from "jotai";
import moment from "moment";

export const reissuanceTaxInvoice_row = atom("5");
export const reissuanceTaxInvoice_searchOption = atom({
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: moment(new Date()).format("YYYY-MM-DD"),
  fromIlNo: "",
  toIlNo: "",
  gubun0: "2", // 사업자 구분
  gubun3: "1", // 구분별 겁색 구분
  keyWord: "",
  searchType: "1",
  page: 1,
});
export const reissuanceTaxInvoice_searchData = atom([]);
export const reissuanceTaxInvoice_saveDetailData = atom({});
export const reissuanceTaxInvoice_detailData = atom({
  addresName: "",
  caddres: "",
  company: "",
  czipcode: "",
  damdangja: "",
  deptName: "",
  duzoneTrans: "",
  email: "",
  gubun0: "",
  gubun1: "",
  gubun2: "",
  gubun3: "",
  ilNo: "",
  jongmok: "",
  mobileNo: "",
  owner: "",
  printGubun: "",
  raddres: "",
  remark: "",
  remark2: "",
  rzipcode: "",
  saupjaNo: "",
  sinCheck: "",
  telNo: "",
  upte: "",
});
export const reissuanceTaxInvoice_historyData = atom([]);
export const reissuanceTaxInvoice_saveHistoryData = atom([]);
export const reissuanceTaxInvoice_searchListCount = atom("0");
