import React, { useEffect, useMemo } from "react";
import FuelInputDetail from "./fuelInputDetail";
import FuelInputList from "./fuelInputList";
import { useAtom, useSetAtom } from "jotai/index";
import {
  carDetail_atom,
  carFuelInfo_atom,
  carFuelInfoEditedRowList_atom,
  carList_atom,
  clickedRow_atom,
  isRegisterMode_atom,
  searchParam_atom,
  tableRowLimit_atom,
} from "../../../../data/atom/dataManage/carManage/fuelInput_atom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useGetCarMasterOilList } from "../../../../api/code/useGetCarMasterOilList";
import moment from "moment/moment";
import { searchParamInit } from "../../../../data/atom/dataManage/carManage/carMaster_atom";
import { useGetCarMasterMoveGubnList } from "../../../../api/code/useGetCarMasterMoveGubnList";
import { useGetCarMasterAcctList } from "../../../../api/code/useGetCarMasterAcctList";
import { useGetCarrJisa } from "../../../../api/code/useGetCarrJisa";
import { decrypt } from "../../../../util/decrypt/decrypt";

export const FuelInput = () => {
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [tableRowLimit, setTableRowLimit] = useAtom(tableRowLimit_atom);
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carList, setCarList] = useAtom(carList_atom);
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);
  const setFuelInfo = useSetAtom(carFuelInfo_atom);
  const setFuelInfoEditedRowList = useSetAtom(carFuelInfoEditedRowList_atom);
  const [isRegisterMode, setIsRegisterMode] = useAtom(isRegisterMode_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const getCarMasterMoveGubnList = useGetCarMasterMoveGubnList();
  const getCarrJisa = useGetCarrJisa();

  useEffect(() => {
    if (getCarrJisa.data && !searchParam.init) {
      setSearchParam(prev => ({
        ...prev,
        carrJisa: getCarrJisa.data.find(e => e.key === decrypt("userInfo").jisa).value ?? "00",
        init: true,
      }));
    }
  }, [getCarrJisa]);

  const drveUserList = useMemo(
    () =>
      getCarMasterMoveGubnList.data?.map(el => ({
        value: el.key,
        label: el.value,
      })) ?? [],
    [getCarMasterMoveGubnList]
  );
  const getCarMasterOilList = useGetCarMasterOilList();
  const fuelList = useMemo(
    () =>
      getCarMasterOilList.data?.map(el => ({
        value: el.key,
        label: el.value,
      })) ?? [],
    [getCarMasterOilList]
  );
  const getCarMasterAcctList = useGetCarMasterAcctList();
  const acctList = useMemo(
    () =>
      getCarMasterAcctList.data?.map(el => ({
        value: el.key,
        label: el.value,
      })) ?? [],
    [getCarMasterAcctList]
  );
  const searchCarList = useMutation(
    data => axios.get("/api/data/carOilManage/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setCarList(data);
      },
    }
  );
  const toFloat = num => {
    let [whole, decimal] = parseFloat(num).toFixed(1).split(".");
    return Number(whole).toLocaleString() + "." + decimal;
  };
  const searchFuelInfo = useMutation(
    data => axios.get("/api/data/carOilManage/getOilDetail", { params: data }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;

        if (variables.selectIdx !== undefined) {
          setCarDetail({
            ...data?.[variables.selectIdx],
            litrCost: toFloat(data?.[variables.selectIdx].litrCost.toFixed(1)),
          });
          setFuelInfo(data);
        } else {
          if (data.length === 0) {
            setCarDetail({
              carrNumb: variables.carrNumb,
              carrName: variables.carrName,
            });
          } else {
            setCarDetail({ ...data?.[0], litrCost: toFloat(data?.[0].litrCost.toFixed(1)) });
          }
          setFuelInfo(data);
        }
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    if (key === "tableRowLimit") {
      setTableRowLimit(value);
    } else if (key === "check") {
      if (value) {
        setSearchParam({ ...searchParam, check: "Y" });
      } else {
        setSearchParam({ ...searchParam, check: "N" });
      }
    } else {
      setSearchParam({ ...searchParam, [key]: value });
    }
  };

  const btnRegister = () => {
    setIsRegisterMode(true);
    setCarDetail({
      acctCode: "10",
      carrName: clickedRow.carrName,
      carrNumb: clickedRow.carrNumb,
      crdtNumb: "",
      engrGubn: "",
      juyuAmnt: 0,
      juyuDate: moment().format("YYYY-MM-DD HH:mm"),
      juyuLitr: 0,
      juyuPlac: "",
      juyuUser: "",
      litrCost: 0,
      remark: "",
      runnMile: 0,
      newCheck: true,
    });
  };
  const btnSearch = () => {
    searchCarList.mutate(searchParam);
  };
  const rowClick = el => {
    if (isRegisterMode) {
      alert("등록 작업을 마무리 해주십시오.");
    } else {
      setClickedRow(el);
    }
  };
  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...searchParamInit });
      setTableRowLimit("5");
      setClickedRow({});
      setCarList([]);
      setCarDetail({});
      setFuelInfo([]);
      setFuelInfoEditedRowList([]);
      setIsRegisterMode(false);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">주유 입력 전용</div>
            <div className="filter-buttons">
              <button
                className="btn btn-primary"
                onClick={btnRegister}
                disabled={isRegisterMode || clickedRow.carrNumb === undefined}
              >
                등록
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div
                className="d-flex align-items-center justify-content-between"
                onKeyDown={pressEnter}
              >
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">차량번호</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.carNumberKeyword}
                    onChange={e => onChangeEventHandler("carNumberKeyword", e.target.value)}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">관리지사</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.carrJisa}
                    onChange={e => onChangeEventHandler("carrJisa", e.target.value)}
                    disabled={decrypt("userInfo").userPermit !== "99"}
                  >
                    <option value="00">전체</option>
                    {getCarrJisa.data &&
                      getCarrJisa.data.map(el => {
                        return <option value={el.value}>{el.value}</option>;
                      })}
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">소유구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.ownGubun}
                    onChange={e => onChangeEventHandler("ownGubun", e.target.value)}
                  >
                    <option value="A">전체</option>
                    <option value="R">렌트</option>
                    <option value="L">리스</option>
                    <option value="C">법인</option>
                    <option value="O">자차</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">연료구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.oilGubun}
                    onChange={e => onChangeEventHandler("oilGubun", e.target.value)}
                  >
                    <option value="00">전체</option>
                    {fuelList.map(el => {
                      return <option value={el.value}>{el.label}</option>;
                    })}
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">차 &nbsp; 명</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.carName}
                    onChange={e => onChangeEventHandler("carName", e.target.value)}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <input
                    type="checkbox"
                    id="chk-1"
                    className="form-check-input"
                    name="checkbox-option"
                    checked={searchParam.check === "Y"}
                    onChange={e => onChangeEventHandler("check", e.target.checked)}
                  />
                  <label className="filter1-label" htmlFor="chk-1">
                    사용 안하는 차량 포함
                  </label>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-main">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="tab1-row1-count">검색 결과 : {carList.length} 건</div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={tableRowLimit}
                  onChange={e => onChangeEventHandler("tableRowLimit", e.target.value)}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>
            <div
              className={`table-container table-sticky mt-10px mb-0
                  ${tableRowLimit === "5" && "h-183"}
                  ${tableRowLimit === "10" && "h-333"}
                  ${tableRowLimit === "20" && "h-633"}
                  `}
            >
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>연번</th>
                    <th style={{ width: "15%" }}>관리지사</th>
                    <th style={{ width: "15%" }}>사용자</th>
                    <th style={{ width: "25%" }}>차량번호</th>
                    <th style={{ width: "25%" }}>차명</th>
                    <th>사용유무</th>
                  </tr>
                </thead>
                <tbody>
                  {carList.length !== 0 ? (
                    carList.map((el, idx) => {
                      return (
                        <tr
                          onClick={() => rowClick(el)}
                          className={`cursor-pointer ${
                            clickedRow.carrName === el.carrName &&
                            clickedRow.carrNumb === el.carrNumb &&
                            "bg-blue"
                          } ${el.usesFlag === "N" && "bg-red"}`}
                        >
                          <td>{idx + 1}</td>
                          <td>{el.jisaName}</td>
                          <td>{el.usesName}</td>
                          <td>{el.carrNumb}</td>
                          <td>{el.carrName}</td>
                          <td>{el.usesFlag === "Y" ? "사용 중" : "사용 안함"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {clickedRow.carrNumb !== undefined ? (
          <>
            <FuelInputDetail
              searchFuelInfo={searchFuelInfo}
              drveUserList={drveUserList}
              acctList={acctList}
              fuelList={fuelList}
            />

            <FuelInputList
              searchFuelInfo={searchFuelInfo}
              drveUserList={drveUserList}
              acctList={acctList}
              fuelList={fuelList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
