import React from "react";
import { useLocation } from "react-router-dom";

export const SidebarPerson = ({ func_recentTap, data }) => {
  const location = useLocation();
  const pathName = location.pathname.split("/")[4];
  const pathName2 = location.pathname.split("/")[5];

  const btn_onClick = (name, url) => {
    func_recentTap({
      name: name,
      url: url,
    });
  };

  return (
    <div className="sidebar-wrapper">
      <ul>
        {data?.sub.reduce((acc, cur) => {
          if (cur.sub.length !== 0) {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 2] && "active"
                }
              >
                <div onClick={() => btn_onClick(cur.sub[0].pageName, cur.url)}>
                  {cur.pageName} <span></span>
                </div>

                <ul className="sidebar_subList">
                  {cur.sub.map(el => (
                    <li
                      className={
                        pathName2 === el.url.split("/")[el.url.split("/").length - 1] && "active"
                      }
                      onClick={() => btn_onClick(el.pageName, el.url)}
                    >
                      {el.pageName}
                    </li>
                  ))}
                </ul>
              </li>,
            ];
          } else {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 1] && "active"
                }
                onClick={() => btn_onClick(cur.pageName, cur.url)}
              >
                {cur.pageName}
              </li>,
            ];
          }

          return acc;
        }, [])}
      </ul>
    </div>
  );

  // return (
  //   <div className="sidebar-wrapper">
  //     <ul>
  //       <li className={pathName === "personManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick("인사마스터관리", "/person/empMasterMng/personManage/personMasterManage")
  //           }
  //         >
  //           인사관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "personMasterManage" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "인사마스터관리",
  //                 "/person/empMasterMng/personManage/personMasterManage"
  //               )
  //             }
  //           >
  //             인사마스터관리
  //           </li>
  //           <li
  //             className={pathName2 === "personEmployeeStatus" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "일자별 재직자 현황",
  //                 "/person/empByDay/personManage/personEmployeeStatus"
  //               )
  //             }
  //           >
  //             일자별 재직자 현황
  //           </li>
  //           {/* <li>임직원 정보</li> */}
  //         </ul>
  //       </li>
  //       <li className={pathName === "taxInvoiceManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick(
  //               "세금계산서 최초발행",
  //               "/person/taxFirstIssue/taxInvoiceManage/initialPublication"
  //             )
  //           }
  //         >
  //           세금계산서관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "initialPublication" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "세금계산서 최초발행",
  //                 "/person/taxFirstIssue/taxInvoiceManage/initialPublication"
  //               )
  //             }
  //           >
  //             세금계산서 최초발행
  //           </li>
  //           <li
  //             className={pathName2 === "reIssueTaxInvoice" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "세금계산서 재발행",
  //                 "/person/taxReIssue/taxInvoiceManage/reIssueTaxInvoice"
  //               )
  //             }
  //           >
  //             세금계산서 재발행
  //           </li>
  //         </ul>
  //       </li>
  //       <li className={pathName === "certificationManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick(
  //               "증명서 발급관리",
  //               "/person/docMng/certificationManage/certificationManage"
  //             )
  //           }
  //         >
  //           증명서관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "certificationManage" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "증명서 발급관리",
  //                 "/person/docMng/certificationManage/certificationManage"
  //               )
  //             }
  //           >
  //             증명서 발급관리
  //           </li>
  //           <li
  //             className={pathName2 === "certificationLedger" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "증명서 발급대장",
  //                 "/person/docHistory/certificationManage/certificationLedger"
  //               )
  //             }
  //           >
  //             증명서 발급대장
  //           </li>
  //         </ul>
  //       </li>
  //       <li className={pathName === "prematureManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick("상조회관리", "/person/feeList/prematureManage/membershipFeeManage")
  //           }
  //         >
  //           상조회관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "membershipFeeManage" && "active"}
  //             onClick={() =>
  //               btn_onClick("회비관리", "/person/feeList/prematureManage/membershipFeeManage")
  //             }
  //           >
  //             회비관리
  //           </li>
  //           <li
  //             className={pathName2 === "depositWithDrawTable" && "active"}
  //             onClick={() =>
  //               btn_onClick("입출금 통계표", "/person/feeMng/prematureManage/depositWithDrawTable")
  //             }
  //           >
  //             입출금 통계표
  //           </li>
  //         </ul>
  //       </li>
  //       <li className={pathName === "accountManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick("회사계좌관리", "/person/coAccount/accountManage/companyAccountManage")
  //           }
  //         >
  //           계좌관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList pt-2">
  //           {/*<li>직원계좌관리</li>*/}
  //           <li
  //             className={pathName2 === "companyAccountManage" && "active"}
  //             onClick={() =>
  //               btn_onClick("회사계좌관리", "/person/coAccount/accountManage/companyAccountManage")
  //             }
  //           >
  //             회사계좌관리
  //           </li>
  //         </ul>
  //       </li>
  //       <li
  //         className={pathName === "individualPerformanceRegister" && "active"}
  //         onClick={() =>
  //           btn_onClick("개인별실적등록", "/person/empPerformance/individualPerformanceRegister")
  //         }
  //       >
  //         개인별실적등록
  //       </li>
  //       <li
  //         className={pathName === "annualManage" && "active"}
  //         onClick={() => btn_onClick("연차관리", "/person/yunchaMng/annualManage")}
  //       >
  //         연차관리
  //       </li>
  //     </ul>
  //   </div>
  // );
};
