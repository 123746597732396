import { atom } from "jotai";

export const ipConnectionAuth_ipControlManagerSearchList_atom = atom([]);
export const ipConnectionAuth_ipControlManagerTimeAccessSearchList_atom = atom([]);
export const ipConnectionAuth_ipControlManagerWeekdaySearchList_atom = atom([]);
export const ipConnectionAuth_ipControlManagerWeekendSearchList_atom = atom([]);
export const ipConnectionAuth_frontGubunSelectList_atom = atom([]);
export const ipConnectionAuth_frontGubun_atom = atom("99");
export const ipConnectionAuth_backGubun_atom = atom("A");
export const ipConnectionAuth_ipEnd_atom = atom();
export const ipConnectionAuth_userName_atom = atom();
export const ipConnectionAuth_macAddress_atom = atom();

export const ipConnectionAuth_originalIndex_atom = atom(0);
export const ipConnectionAuth_originalIndexTimeAccess_atom = atom(0);
export const ipConnectionAuth_originalIndexWeekday_atom = atom(0);
export const ipConnectionAuth_originalIndexWeekend_atom = atom(0);

export const ipConnectionAuth_updateArray_atom = atom([]);
export const ipConnectionAuth_updateArrayTimeAccess_atom = atom([]);
export const ipConnectionAuth_updateArrayWeekday_atom = atom([]);
export const ipConnectionAuth_updateArrayWeekend_atom = atom([]);

export const ipConnectionAuth_deleteArray_atom = atom([]);
export const ipConnectionAuth_deleteArrayTimeAccess_atom = atom([]);
export const ipConnectionAuth_deleteArrayWeekday_atom = atom([]);
export const ipConnectionAuth_deleteArrayWeekend_atom = atom([]);