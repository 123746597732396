import CarMasterListFuelRow from "./carMasterListFuelRow";
import React, { useMemo, useRef } from "react";
import { useAtom } from "jotai/index";
import { carFuelInfo_atom } from "../../../../../data/atom/dataManage/carManage/carMaster_atom";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useGetCarMasterMoveGubnList } from "../../../../../api/code/useGetCarMasterMoveGubnList";
import { useGetCarMasterAcctList } from "../../../../../api/code/useGetCarMasterAcctList";
import { useGetCarMasterOilList } from "../../../../../api/code/useGetCarMasterOilList";

const Tab2 = props => {
  const { btnAdd, btnSave, onClickDeleteFuel } = props;
  const targetRefFuel = useRef();
  const [fuelInfo, setFuelInfo] = useAtom(carFuelInfo_atom);

  const rowVirtualizerFuel = useVirtualizer({
    count: fuelInfo.length,
    getScrollElement: () => targetRefFuel.current,
    estimateSize: () => 30,
  });

  const getCarMasterMoveGubnList = useGetCarMasterMoveGubnList();
  const getCarMasterAcctList = useGetCarMasterAcctList();
  const getCarMasterOilList = useGetCarMasterOilList();
  const drveUserList = useMemo(
    () =>
      getCarMasterMoveGubnList.data?.map(el => ({
        value: el.key,
        label: el.value,
      })) ?? [],
    [getCarMasterMoveGubnList.data]
  );
  const acctList = useMemo(
    () =>
      getCarMasterAcctList.data?.map(el => ({
        value: el.key,
        label: el.value,
      })) ?? [],
    [getCarMasterAcctList.data]
  );
  const fuelList = useMemo(
    () =>
      getCarMasterOilList.data?.map(el => ({
        value: el.key,
        label: el.value,
      })) ?? [],
    [getCarMasterOilList.data]
  );

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-end mb-3">
        <button
          className="btn btn-outline-primary w-70px"
          onClick={() => {
            btnAdd("fuel");
            targetRefFuel.current.scrollTo(0, 0);
          }}
          disabled={fuelInfo.filter(el => el.newCheck).length !== 0}
        >
          추가
        </button>
        <button className="btn btn-outline-primary ms-2 w-70px" onClick={() => btnSave("fuel")}>
          저장
        </button>
      </div>
      <div
        className="table-container table-sticky h-633"
        style={{ overflowX: "auto" }}
        ref={targetRefFuel}
      >
        <table
          className="table table-bordered table-responsivex my-table"
          style={{
            width: "110vw",
            marginTop: `${(rowVirtualizerFuel.range?.startIndex ?? 0) * 30}px`,
            marginBottom: `${
              rowVirtualizerFuel.getTotalSize() -
              (rowVirtualizerFuel.range?.startIndex ?? 0) * 30 -
              rowVirtualizerFuel.getVirtualItems().length * 30
            }px`,
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "3%" }}>연번</th>
              <th style={{ width: "5%" }}>차량번호</th>
              <th style={{ width: "6%" }}>주유일시</th>
              <th style={{ width: "7%" }}>주유자</th>
              <th style={{ width: "7%" }}>주행거리</th>
              <th style={{ width: "7%" }}>연료</th>
              <th style={{ width: "3%" }}>단가(ℓ당)</th>
              <th style={{ width: "3%" }}>주유량(ℓ)</th>
              <th style={{ width: "12%" }}>주유소명</th>

              <th style={{ width: "8%" }}>결제구분</th>
              <th style={{ width: "10%" }}>카드번호</th>
              <th>비고</th>
              <th style={{ width: "1%" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {fuelInfo.length !== 0 ? (
              rowVirtualizerFuel.getVirtualItems().map(el => {
                return (
                  <CarMasterListFuelRow
                    data={fuelInfo[el.index]}
                    index={el.index}
                    drveUserList={drveUserList}
                    acctList={acctList}
                    fuelList={fuelList}
                    deleteFuelInfo={onClickDeleteFuel}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={13}>검색된 결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default React.memo(Tab2);
