import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { axios } from "../../../util/axios/axios";
import moment from "moment";
import { useAtom } from "jotai";
import {
  membershipTransactionAmount_detail,
  membershipTransactionAmount_row,
  membershipTransactionAmount_selectMember,
  membershipTransactionAmount_showForm,
  membershipTransactionAmount_submitData,
} from "../../../data/atom/dataManage/membershipTransactionAmount/membershipTransactionAmount_atom";
import { useGetBank } from "../../../api/code/useGetBank";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useGetMemberNm } from "../../../api/code/useGetMemberNm";
import { useGetEtcCmQ } from "../../../api/code/useGetEtcCmQ";
import { useGetEtcSmQ } from "../../../api/code/useGetEtcSmQ";
import MembershipSelect from "../../../components/select/MembershipSelect";
import CustomerSelect from "../../../components/select/CustomerSelect";

export default function MembershipTransactionAmount() {
  const queryClient = useQueryClient();
  const [searchListNumber, setSearchListNumber] = useAtom(membershipTransactionAmount_row);
  const [detailData, setDetailData] = useAtom(membershipTransactionAmount_detail);
  const [showForm, setShowForm] = useAtom(membershipTransactionAmount_showForm);
  const [submitData, setSubmitData] = useAtom(membershipTransactionAmount_submitData);
  const [selectMember, setSelectMember] = useAtom(membershipTransactionAmount_selectMember);
  const initialSubmitData = {
    addedAmnt: 0,
    addedGubn: "",
    commiAmnt: 0,
    confmStatus: "",
    confmStatusCode: "",
    custName: "",
    custNo: "",
    dealHistory: "",
    drftSttus: "",
    firstAmnt: 0,
    gubun: "1", // 1 : 개인, 2 : 법인
    inDate: moment(new Date()).format("YYYY-MM-DD"),
    issueDate: "",
    kname: "",
    lockerAmnt: 0,
    middleAmnt: 0,
    modDate: "",
    modId: "",
    partner: "",
    payAccount: "",
    payBank: "",
    payName: "",
    position: "",
    relation: "",
    remainAmnt: 0,
    remark: "",
    sawonId: "",
    seqNo: "",
    situation: "",
    stampAmnt: 0,
    sumAmnt: 0,
    totalAmnt: 0,
    tradClass: "1", // 1 : 골프, 2 : 콘도, 3 : 휘트니스
    tradGubn: "1", // 1 : 계약금, 2 : 중도금, 3 : 잔금, 4 : 기타
    writeAmnt: 0,
    yearAmnt: 0,
    lostAmnt: 0, // 분실료
    depoDraw: false, // 0 : 입금, 1 : 출금
    roomNo: 2831,
    maedoMaesu: 0, // 0 : 매도, 1 : 매수
  };
  // type 1 (골프)
  const golfSubmitData = { ...initialSubmitData, registAmnt: 0 };
  // type 2 (콘도)
  const condoSubmitData = { ...initialSubmitData, registAmnt: 0, yearAmnt: 0 };
  // type 3 (휘트니스)
  const fitnessSubmitData = { ...initialSubmitData, lockerAmnt: 0 };
  const getMemberNm = useGetMemberNm(); // 골프
  const getEtcCmQ = useGetEtcCmQ(); // 콘도
  const getEtcSmQ = useGetEtcSmQ(); // 헬스
  const getBank = useGetBank();

  const { addTabClosedEvent } = useRecentTabEvent();

  // 탭 닫을때 초기화
  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSelectMember("");
      setSearchListNumber("5");
      setDetailData(null);
      setShowForm(false);
      setSubmitData(initialSubmitData);
    });
  }, []);

  // API - 거래 대금 입출금 조회
  const { data: searchData } = useQuery(
    ["membershipTransactionSearch"],
    async () =>
      await axios.get("/api/data/memberBank/search").then(res => {
        return res?.data?.result;
      })
  );

  // API - 거래 대금 입출금 상세
  const getDetail = useMutation(
    data =>
      axios.get("/api/data/memberBank/getDetail", {
        params: data,
      }),
    {
      onSuccess: (res, payload) => {
        setSelectMember(payload?.seqNo);
        if (!res.data.result.tradeInfo.confmStatusCode) {
          setSubmitData(res?.data?.result.tradeInfo);
          setDetailData(null);
        } else {
          setSubmitData(initialSubmitData);
          setDetailData(res?.data?.result);
        }
      },
    }
  );

  // API - 거래 대금 임시저장
  const saveTemp = useMutation(data => axios.post("/api/data/memberBank/saveTemp", data), {
    onSuccess: res => {
      queryClient.invalidateQueries(["membershipTransactionSearch"]);
      setSubmitData(res.data.result);
    },
  });

  // API - 거래 대금 상신
  const submit = useMutation(data => axios.post("/api/data/memberBank/submit", data), {
    onSuccess: res => {
      alert("입/출금 결의서가 성공적으로 상신되었습니다.");
      queryClient.invalidateQueries(["membershipTransactionSearch"]);
      setSubmitData(initialSubmitData);
    },
  });

  // API - 거래대금 결재라인 조회
  const getConfirmList = useQuery(
    ["getConfirmList"],
    async () =>
      await axios.get("/api/data/memberBank/getConfirmList").then(res => {
        const sortData = res?.data?.result?.sort((a, b) => {
          return a?.confmOrdr - b?.confmOrdr;
        });
        return sortData;
      })
  );

  const handleChange = e => {
    const { value, name } = e.target;
    const numericFields = [
      "sumAmnt",
      "totalAmnt",
      "firstAmnt",
      "middleAmnt",
      "writeAmnt",
      "stampAmnt",
      "commiAmnt",
      "addedAmnt",
      "lostAmnt",
      "lockerAmnt",
      "registAmnt",
      "remainAmnt",
      "yearAmnt",
    ];

    if (numericFields.includes(name)) {
      let formattedValue = value.replace(/[^0-9.-]/g, "").replace(/(\..*)\./g, "$1");
      formattedValue = formattedValue.startsWith("-")
        ? "-" + formattedValue.slice(1).replace(/-/g, "")
        : formattedValue;
      setSubmitData({
        ...submitData,
        [name]: formattedValue,
      });
    } else if (name.includes("payAccount")) {
      setSubmitData({ ...submitData, [name]: value.replace(/[^0-9-]/g, "") });
    } else if (name.includes("tradGubn")) {
      setSubmitData({ ...submitData, [name]: value, addedGubn: "" });
    } else {
      setSubmitData({ ...submitData, [name]: value });
    }
  };

  const getClazzForSelect = () => {
    switch (submitData?.tradClass) {
      case "1":
        return "GM";
      case "2":
        return "CM";
      case "3":
        return "SM";
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">회원권 거래대금 입출금</div>
          </div>
          <div className="d-flex justify-content-end mb-2">
            <div className="filter-buttons">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setDetailData(null);
                  setShowForm(true);
                  setSelectMember("");
                }}
              >
                등록
              </button>
            </div>
          </div>
        </div>

        <div className="card card-main m-0">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="table-tabs-subsub w-50 m-0">입/출금 결의서 요청 내역</div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>
            <div
              className={`table-container table-sticky mt-10px
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>연번</th>
                    <th>지사구분</th>
                    <th>담당자</th>
                    <th>요청일시</th>
                    <th>종목</th>
                    <th>회원권명</th>
                    <th>매매구분</th>
                    <th>계정과목</th>
                    <th>입/출금</th>
                    <th>고객명</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {searchData && searchData.length > 0 ? (
                    searchData?.map((data, _idx) => {
                      return (
                        <tr
                          onClick={() => {
                            if (data.confmStatus === "임시저장") {
                              setShowForm(true);
                            } else {
                              setShowForm(false);
                            }
                            getDetail.mutate({ seqNo: data?.drftSn });
                          }}
                          role="button"
                          className={`${selectMember === data?.drftSn && "bg-blue"}`}
                        >
                          <td>{_idx + 1}</td>
                          <td>{data?.jisaName}</td>
                          <td>{data?.kname}</td>
                          <td>
                            {data?.issueDate && moment(data?.issueDate).format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td>{data?.tradClass}</td>
                          <td>{data?.tradClassNm}</td>
                          <td>{data?.maedoMaesu}</td>
                          <td>{data?.tradGubn}</td>
                          <td>{data?.depoDraw}</td>
                          <td>{data?.custName}</td>
                          <td>{data?.confmStatus}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {showForm && (
          <div className="card card-main">
            <div className="card-header">
              <h5 className="card-title w-50 m-0">입/출금 결의서 요청하기</h5>
              <div className="tab1-row1-field1 w-50 justify-content-end">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    saveTemp.mutate({
                      ...submitData,
                    });
                  }}
                >
                  임시저장
                </button>
                <button
                  className="btn btn-outline-primary ms-8px"
                  onClick={() => {
                    submit.mutate({
                      ...submitData,
                    });
                  }}
                >
                  상신
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="tab1-row1-count">
                  결재선 :
                  {getConfirmList?.data?.reduce((acc, cur, idx) => {
                    if (idx === 0) {
                      return acc + " " + cur?.kname;
                    } else {
                      return acc + "-" + cur?.kname;
                    }
                  }, "")}
                </div>
              </div>
              <div className="table-tabs-subsub w-50 m-0 my-3">거래 금액</div>

              <table className="table table-bordered table-responsive my-table1 mb-20">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>일금</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        onChange={e => handleChange(e)}
                        value={submitData?.sumAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        name="sumAmnt"
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>종목구분</th>
                    <td style={{ width: "13.1%" }}>
                      <div className="d-flex align-items-center justify-content-start w-100 mt-1">
                        <div className="form-check form-check-inline my-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="tradClass"
                            id="tradClassRdo1"
                            value="1"
                            onChange={e => {
                              handleChange(e);
                              setSubmitData(prev => ({
                                ...prev,
                                roomNo: getMemberNm.data[0].code,
                              }));
                            }}
                            defaultChecked={true}
                          />
                          <label className="form-check-label" htmlFor="tradClassRdo1">
                            골프
                          </label>
                        </div>
                        <div className="form-check form-check-inline my-0 ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="tradClass"
                            id="tradClassRdo2"
                            value="2"
                            onChange={e => {
                              handleChange(e);
                              setSubmitData(prev => ({
                                ...prev,
                                roomNo: getEtcCmQ.data[0].key,
                              }));
                            }}
                          />
                          <label className="form-check-label" htmlFor="tradClassRdo2">
                            콘도
                          </label>
                        </div>
                        <div className="form-check form-check-inline my-0 ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="tradClass"
                            id="tradClassRdo3"
                            value="3"
                            onChange={e => {
                              handleChange(e);
                              setSubmitData(prev => ({
                                ...prev,
                                roomNo: getEtcSmQ.data[0].key,
                              }));
                            }}
                          />
                          <label className="form-check-label" htmlFor="tradClassRdo3">
                            피트니스
                          </label>
                        </div>
                      </div>
                    </td>
                    <th style={{ width: "11.9%" }}>회원권명</th>
                    <td style={{ width: "13.1%" }}>
                      <MembershipSelect
                        clazz={getClazzForSelect()}
                        data={submitData?.roomNo}
                        onChangeCallBack={e =>
                          setSubmitData(prev => ({ ...prev, roomNo: e?.value ?? "" }))
                        }
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>매도/매수</th>
                    <td style={{ width: "13.1%" }}>
                      <div className="d-flex align-items-center justify-content-center w-100 mt-1">
                        <div className="form-check form-check-inline my-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="maedoMaesu"
                            id="maedoMaesuRdo0"
                            value="0"
                            onChange={e => handleChange(e)}
                            defaultChecked={true}
                          />
                          <label className="form-check-label" htmlFor="maedoMaesuRdo0">
                            매도
                          </label>
                        </div>
                        <div className="form-check form-check-inline my-0 ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="maedoMaesu"
                            id="maedoMaesuRdo1"
                            value="1"
                            onChange={e => handleChange(e)}
                          />
                          <label className="form-check-label" htmlFor="maedoMaesuRdo1">
                            매수
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "11.9%" }}>입/출금</th>
                    <td style={{ width: "13.1%" }}>
                      <div className="d-flex align-items-center justify-content-center w-100 mt-1">
                        <div className="form-check form-check-inline my-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="depoDraw"
                            id="depoDrawRdo0"
                            value={false}
                            onChange={e => handleChange(e)}
                            defaultChecked={true}
                          />
                          <label className="form-check-label" htmlFor="depoDrawRdo0">
                            입금
                          </label>
                        </div>
                        <div className="form-check form-check-inline my-0 ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="depoDraw"
                            id="depoDrawRdo1"
                            value={true}
                            onChange={e => handleChange(e)}
                          />
                          <label className="form-check-label" htmlFor="depoDrawRdo1">
                            출금
                          </label>
                        </div>
                      </div>
                    </td>
                    <th style={{ width: "11.9%" }}>구분</th>
                    <td style={{ width: "13.1%" }}>
                      <div className="d-flex align-items-center justify-content-start w-100 mt-1">
                        <div className="form-check form-check-inline my-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gubun"
                            id="inlineRadio1"
                            value="1"
                            onChange={e => handleChange(e)}
                            defaultChecked={true}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio1">
                            개인
                          </label>
                        </div>
                        <div className="form-check form-check-inline my-0 ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gubun"
                            id="inlineRadio2"
                            value="2"
                            onChange={e => handleChange(e)}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio2">
                            법인
                          </label>
                        </div>
                        <div className="form-check form-check-inline my-0 ms-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gubun"
                            id="inlineRadio3"
                            value="3"
                            onChange={e => handleChange(e)}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio3">
                            Shop
                          </label>
                        </div>
                      </div>
                    </td>
                    <th>고객명</th>
                    <td>
                      <CustomerSelect
                        gubun={submitData.gubun}
                        onChangeCallBack={e =>
                          setSubmitData(prev => {
                            let tmp = {
                              ...prev,
                              payBank: e.detail.payBank,
                              payAccount: e.detail.payAccount,
                            };

                            switch (prev.gubun) {
                              case "1":
                                tmp.custName = e.detail.kname;
                                break;
                              case "2":
                              case "3":
                                tmp.custName = e.detail.company;
                            }

                            return tmp;
                          })
                        }
                      />
                    </td>
                    <th>거래금액</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="totalAmnt"
                        value={submitData?.totalAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "11.9%" }}>계정과목</th>
                    <td style={{ width: "13.1%" }}>
                      <div className="d-flex">
                        <select
                          className="form-select table-select"
                          name="tradGubn"
                          value={submitData?.tradGubn}
                          onChange={e => handleChange(e)}
                        >
                          <option value="1">계약금</option>
                          <option value="2">중도금</option>
                          <option value="3">잔금</option>
                          <option value="4">기타</option>
                        </select>
                        {submitData?.tradGubn === "4" && (
                          <input
                            type="text"
                            className="form-control table-input"
                            name="addedGubn"
                            value={submitData?.addedGubn}
                            onChange={e => handleChange(e)}
                          />
                        )}
                      </div>
                    </td>
                    <th>계약금</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="firstAmnt"
                        value={submitData?.firstAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>중도금</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="middleAmnt"
                        value={submitData?.middleAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>명의개서료</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="writeAmnt"
                        value={submitData?.writeAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>인지세</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="stampAmnt"
                        value={submitData?.stampAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>중개수수료</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="commiAmnt"
                        value={submitData?.commiAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>기타비용</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="addedAmnt"
                        value={submitData?.addedAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>분실료</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="lostAmnt"
                        value={submitData?.lostAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    {/* 등기비 or 락카비정산 */}
                    <th>{submitData?.tradClass === "3" ? "락카비정산" : "등기비"}</th>
                    <td>
                      {submitData?.tradClass === "3" ? (
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          name="lockerAmnt"
                          value={submitData.lockerAmnt
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          onChange={e => handleChange(e)}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          name="registAmnt"
                          value={submitData.registAmnt
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          onChange={e => handleChange(e)}
                        />
                      )}
                    </td>
                    {/* 콘도일때 연회비 정산 */}
                    {submitData?.tradClass === "2" && <th>연회비정산</th>}
                    {submitData?.tradClass === "2" && (
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-end"
                          name="yearAmnt"
                          value={submitData?.yearAmnt
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          onChange={e => handleChange(e)}
                        />
                      </td>
                    )}
                    <th style={{ width: "11.9%" }}>내역</th>
                    <td style={{ width: "13.1%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="dealHistory"
                        value={submitData?.dealHistory}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered table-responsive my-table1 mb-20">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>잔금</th>
                    <td style={{ width: "21.43%" }}>
                      <input
                        type="text"
                        className="form-control table-input text-end"
                        name="remainAmnt"
                        value={submitData?.remainAmnt
                          ?.toString()
                          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>은행</th>
                    <td style={{ width: "21.43%" }}>
                      <select
                        className="form-select table-select"
                        name="payBank"
                        value={submitData?.payBank}
                        onChange={e => handleChange(e)}
                      >
                        {getBank?.data?.map(item => {
                          return <option value={item.key}>{item.value}</option>;
                        })}
                      </select>
                    </td>
                    <th style={{ width: "11.9%" }}>계좌번호</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="payAccount"
                        value={submitData?.payAccount}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>예금주(매수자)</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="payName"
                        value={submitData?.payName}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>관계</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="relation"
                        value={submitData?.relation}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="table-tabs-subsub w-50 m-0 my-3">회원 확인</div>

              <table className="table table-bordered table-responsive my-table1 mb-0">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>담당자</th>
                    <td style={{ width: "21.43%" }}>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="partner"
                        value={submitData?.partner}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>입회일자</th>
                    <td style={{ width: "21.43%" }}>
                      <input
                        type="date"
                        className="form-control table-input"
                        max="9999-12-31"
                        name="inDate"
                        value={submitData?.inDate}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th style={{ width: "11.9%" }}>회원번호</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="custNo"
                        value={submitData?.custNo}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>회원명</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="custName"
                        value={submitData?.custName}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>이상(유/무)</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="situation"
                        value={submitData?.situation}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                    <th>비고</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="remark"
                        value={submitData?.remark}
                        onChange={e => handleChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {detailData != null && (
          <div className="card card-main">
            <div className="card-header">
              <h5 className="card-title w-50 m-0">상세 보기</h5>
              <div className="tab1-row1-field1 w-50 justify-content-end">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setDetailData(null);
                    setSelectMember("");
                  }}
                >
                  닫기
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="tab1-row1-count">
                결재선 :
                {detailData?.confmList
                  ?.sort((a, b) => {
                    return a?.confmOrdr - b?.confmOrdr;
                  })
                  .reduce((acc, cur, _idx) => {
                    if (_idx === 0) {
                      return acc + " " + cur.kname;
                    } else {
                      return acc + "-" + cur.kname;
                    }
                  }, "")}
              </div>

              <div className="table-tabs-subsub w-50 m-0 my-3">거래 금액</div>

              <table className="table table-bordered table-responsive my-table1 mb-20">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>일금</th>
                    <td className="text-end " style={{ width: "13.1%" }}>
                      {detailData?.tradeInfo?.sumAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th style={{ width: "11.9%" }}>종목구분</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.tradeInfo?.tradClass === "1" && "골프"}
                      {detailData?.tradeInfo?.tradClass === "2" && "콘도"}
                      {detailData?.tradeInfo?.tradClass === "3" && "피트니스"}
                    </td>
                    <th style={{ width: "11.9%" }}>회원권명</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.tradeInfo?.tradClassNm}
                    </td>
                    <th style={{ width: "11.9%" }}>매도/매수</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.tradeInfo?.maedoMaesu === "0" ? "매도" : "매수"}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "11.9%" }}>입/출금</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.tradeInfo?.depoDraw ? "출금" : "입금"}
                    </td>
                    <th style={{ width: "11.9%" }}>구분</th>
                    <td className="text-center">{detailData?.tradeInfo?.gubun}</td>
                    <th>고객명</th>
                    <td className="text-center">{detailData?.tradeInfo?.custName}</td>
                    <th>거래금액</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.totalAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <th>계정과목</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.tradGubn === "1" && "계약금"}
                      {detailData?.tradeInfo?.tradGubn === "2" && "중도금"}
                      {detailData?.tradeInfo?.tradGubn === "3" && "잔금"}
                      {detailData?.tradeInfo?.tradGubn === "4" && detailData?.tradeInfo?.addedGubn}
                    </td>
                    <th>계약금</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.firstAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th>중도금</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.middleAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th>명의개서료</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.writeAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <th>인지세</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.stampAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th>중개수수료</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.commiAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th>기타비용</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.addedAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th>분실료</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.lostAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    {/* 등기비 or 락카비정산 */}
                    <th>{detailData?.tradeInfo?.tradClass === "3" ? "락카비정산" : "등기비"}</th>
                    <td className="text-end">
                      {detailData?.tradeInfo?.tradClass === "3"
                        ? detailData?.tradeInfo?.lockerAmnt
                        : detailData?.tradeInfo?.registAmnt
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th style={{ width: "11.9%" }}>내역</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.tradeInfo?.dealHistory}
                    </td>

                    {/* 콘도일때 연회비 정산 */}
                    {detailData?.tradeInfo?.tradClass === "2" && <th>연회비정산</th>}
                    {detailData?.tradeInfo?.tradClass === "2" && (
                      <td className="text-end">
                        <>
                          {detailData?.tradeInfo?.yearAmnt
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      </td>
                    )}
                    {detailData?.tradeInfo?.tradClass !== "2" && <th></th>}
                    {detailData?.tradeInfo?.tradClass !== "2" && <td></td>}
                    <th></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered table-responsive my-table1 mb-20">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>잔금</th>
                    <td className="text-end" style={{ width: "21.43%" }}>
                      {detailData?.tradeInfo?.remainAmnt
                        ?.toString()
                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <th style={{ width: "11.9%" }}>은행</th>
                    <td className="text-center" style={{ width: "21.43%" }}>
                      {detailData?.tradeInfo?.payBank}
                    </td>
                    <th style={{ width: "11.9%" }}>계좌번호</th>
                    <td className="text-center">{detailData?.tradeInfo?.payAccount}</td>
                  </tr>
                  <tr>
                    <th>예금주(매수자)</th>
                    <td className="text-center">{detailData?.tradeInfo?.payName}</td>
                    <th>관계</th>
                    <td className="text-center">{detailData?.tradeInfo?.relation}</td>
                    <th></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div className="table-tabs-subsub w-50 m-0 my-3">회원 확인</div>

              <table className="table table-bordered table-responsive my-table1 mb-0">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>담당자</th>
                    <td className="text-center" style={{ width: "21.43%" }}>
                      {detailData?.tradeInfo?.partner}
                    </td>
                    <th style={{ width: "11.9%" }}>입회일자</th>
                    <td className="text-center" style={{ width: "21.43%" }}>
                      {detailData?.tradeInfo?.inDate}
                    </td>
                    <th style={{ width: "11.9%" }}>회원번호</th>
                    <td className="text-center">{detailData?.tradeInfo?.custNo}</td>
                  </tr>
                  <tr>
                    <th>회원명</th>
                    <td className="text-center">{detailData?.tradeInfo?.custName}</td>
                    <th>이상(유/무)</th>
                    <td className="text-center">{detailData?.tradeInfo?.situation}</td>
                    <th>비고</th>
                    <td className="text-center">{detailData?.tradeInfo?.remark}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
