import { atom } from "jotai";
import moment from "moment";

export const personMasterManage_table_row = atom("5");
export const personMasterManage_backupData = atom({});
// renewal
export const personMasterManage_memberId = atom("");
export const personMasterManage_searchOption = atom({
  keyWord: "",
  monthInfo: moment(new Date()).format("MM"),
  searchType: "1",
  yearInfo: moment(new Date()).format("YYYY"),
  dateInfo: moment(new Date()).format("YYYY-MM"),
});
export const personMasterManage_bottomTabSelect = atom("");
export const personMasterManage_searchResult = atom([]);
export const personMasterManage_printOption = atom("0");
export const personMasterManage_memberData = atom({
  addres1: "",
  addres2: "",
  addres3: "",
  addres4: "",
  addresA: "",
  addresB: "",
  addresC: "",
  addresD: "",
  authName: "",
  authority: "",
  birthMmdd: "",
  bohoonCheck: "N",
  carCheck: "N",
  cellular: "",
  cellular2: "",
  cname: "",
  delYn: "",
  dept: "",
  duzonId: "",
  eduCheck: "",
  eduLast: "",
  eduName: "",
  email: "",
  emailPassword: "",
  ename: "",
  ext1: "",
  ext2: "",
  ext3: "",
  house: "",
  houseGubun: "",
  inChDate: moment(new Date()),
  inCheck: "",
  inDate: moment(new Date()),
  inGubun: "1",
  job: "",
  jobName: "",
  juminNo: "",
  kname: "",
  lunaCheck: "1",
  mailIssue: "N",
  modDate: moment(new Date()),
  modId: "",
  outDate: moment(new Date()),
  outDateEnd: moment(new Date()),
  outDateStart: moment(new Date()),
  partJob: "",
  perMail: "",
  raddres1: "",
  raddres2: "",
  raddres3: "",
  raddres4: "",
  rank: "",
  rareaCode1: "",
  rareaCode2: "",
  rareaCode3: "",
  rareaCode4: "",
  rbdcode1: "",
  rbdcode2: "",
  rbdcode3: "",
  rbdcode4: "",
  reDate: moment(new Date()),
  remark: "",
  rzipcode1: "",
  rzipcode2: "",
  rzipcode3: "",
  rzipcode4: "",
  sawonId: "",
  sedaejuName: "",
  sedaejuRela: "",
  seqNo1: "",
  seqNo2: "",
  seqNo3: "",
  seqNo4: "",
  sexCheck: "F",
  tel1: "",
  tel2: "",
  tel3: "",
  tel4: "",
  upDate: moment(new Date()),
  userid: "",
  weddingCheck: "N",
  weddingDate: moment(new Date()),
  work1: "",
  zipcode1: "",
  zipcode2: "",
  zipcode3: "",
  zipcode4: "",
  filename: "",
});
export const personMasterManage_split_data = atom({
  tel1_1: "",
  tel1_2: "",
  tel1_3: "",
  tel2_1: "",
  tel2_2: "",
  tel2_3: "",
  tel3_1: "",
  tel3_2: "",
  tel3_3: "",
  tel4_1: "",
  tel4_2: "",
  tel4_3: "",
  mobile2_1: "",
  mobile2_2: "",
  mobile2_3: "",
  personID1: "",
  personID2: "",
});
