import moment from "moment/moment";
import React, { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import {
  registeredDeliveryHistory_searchedData_atom,
  registeredDeliveryHistory_searchedTableList_atom,
} from "../../../../../../data/atom/workManage/postManage/registeredDeliveryHistory_atom";

export const RegisteredDeliveryHistoryTableRow = props => {
  const { virtualRow } = props;
  const [searchedData, setSearchedData] = useAtom(registeredDeliveryHistory_searchedData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    registeredDeliveryHistory_searchedTableList_atom
  );

  const queryClient = useQueryClient();
  const getSpQ = queryClient.getQueryData(["getSpQ"]);
  const getPostGubun = queryClient.getQueryData(["getPostGubun"]);

  const btn_tableRowClick = useCallback(
    data => {
      window.open(
        `http://www.dagolf.co.kr/pop/dong.html?sid=${data.urlSid}`,
        "배송조회",
        "popup=yes"
      );
    },
    [searchedTableList[virtualRow.index]]
  );

  return (
    <>
      {searchedData?.gubun === "A" ? (
        <>
          <tr
            className="cursor-pointer"
            onDoubleClick={() => btn_tableRowClick(searchedTableList[virtualRow.index])}
          >
            <td>{virtualRow.index + 1}</td>
            <td>{searchedTableList[virtualRow.index].userName}</td>
            <td>
              {searchedTableList[virtualRow.index]?.sendDate
                ? moment(searchedTableList[virtualRow.index]?.sendDate).format("YYYY-MM-DD")
                : ""}
            </td>
            <td>
              {
                getPostGubun?.find(el2 => el2.key === searchedTableList[virtualRow.index].sendGubun)
                  ?.value
              }
            </td>
            <td>{searchedTableList[virtualRow.index].createNo}</td>
            <td>
              <input
                type="text"
                className="form-control"
                value={searchedTableList[virtualRow.index].compDev}
                readOnly={true}
                style={{ border: "0" }}
              />
            </td>
            <td>{searchedTableList[virtualRow.index].kname}</td>
            {/* <td>{searchedTableList[virtualRow.index].custId}</td> */}
            <td>
              <input
                type="text"
                className="form-control"
                value={searchedTableList[virtualRow.index].remark}
                readOnly={true}
                style={{ border: "0" }}
              />
            </td>
            {/* <td>
              {searchedTableList[virtualRow.index].processChk === "R"
                ? "접수"
                : searchedTableList[virtualRow.index].processChk === "P"
                ? "우체국"
                : searchedTableList[virtualRow.index].processChk === "D"
                ? "보류"
                : searchedTableList[virtualRow.index].processChk === "C"
                ? "취소"
                : searchedTableList[virtualRow.index].processChk === "E"
                ? "완료"
                : ""}
            </td> */}
            <td>
              {searchedTableList[virtualRow.index].sendPlace
                ? getSpQ?.find(el => el.key === searchedTableList[virtualRow.index].sendPlace)
                    ?.value
                : ""}
            </td>
            <td className="text-end">
              {searchedTableList[virtualRow.index].sendWon.toLocaleString()}
            </td>
            <td>
              {searchedTableList[virtualRow.index]?.returnDate
                ? moment(searchedTableList[virtualRow.index].returnDate).format("YYYY-MM-DD")
                : ""}
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={searchedTableList[virtualRow.index].roadAddres}
                readOnly={true}
                style={{ border: "0" }}
              />
            </td>
          </tr>
          {!moment(searchedTableList[virtualRow.index].sendDate).isSame(
            moment(searchedTableList?.[virtualRow.index + 1]?.sendDate)
          ) && (
            <tr>
              <td className="f-bold text" colSpan={14} style={{ color: "#0b4da2" }}>
                일일 발송량 :{" "}
                {searchedTableList
                  .filter(el => el.sendDate === searchedTableList[virtualRow.index].sendDate)
                  .length?.toLocaleString()}
                건 / 금액 :{" "}
                {searchedTableList
                  .reduce((acc, cur) => {
                    return cur.sendDate === searchedTableList[virtualRow.index].sendDate
                      ? +acc + cur.sendWon
                      : acc;
                  }, 0)
                  ?.toLocaleString()}
                원
              </td>
            </tr>
          )}
        </>
      ) : searchedData?.gubun === "B" ? (
        <tr
          className="cursor-pointer"
          onDoubleClick={() => btn_tableRowClick(searchedTableList[virtualRow.index])}
        >
          <td>{virtualRow.index + 1}</td>
          <td>
            {searchedTableList[virtualRow.index]?.sendDate
              ? moment(searchedTableList[virtualRow.index]?.sendDate).format("YYYY-MM-DD")
              : ""}
          </td>
          <td>
            {
              getPostGubun?.find(el2 => el2.key === searchedTableList[virtualRow.index].sendGubun)
                ?.value
            }
          </td>
          <td>{searchedTableList[virtualRow.index].createNo}</td>
          <td>
            <input
              type="text"
              className="form-control"
              value={searchedTableList[virtualRow.index].compDev}
              readOnly={true}
              style={{ border: "0" }}
            />
          </td>
          <td>{searchedTableList[virtualRow.index].kname}</td>
          <td>
            <input
              type="text"
              className="form-control"
              value={searchedTableList[virtualRow.index].remark}
              readOnly={true}
              style={{ border: "0" }}
            />
          </td>
          <td>{searchedTableList[virtualRow.index].userName}</td>
          <td>
            {searchedTableList[virtualRow.index].sendPlace
              ? getSpQ?.find(el => el.key === searchedTableList[virtualRow.index].sendPlace)?.value
              : ""}
          </td>
          <td>
            {searchedTableList[virtualRow.index]?.returnDate
              ? moment(searchedTableList[virtualRow.index].returnDate).format("YYYY-MM-DD")
              : ""}
          </td>
          <td>
            <input
              type="text"
              className="form-control"
              value={searchedTableList[virtualRow.index].roadAddres}
              readOnly={true}
              style={{ border: "0" }}
            />
          </td>
        </tr>
      ) : (
        searchedData?.gubun === "C" && (
          <tr
            className="cursor-pointer"
            onDoubleClick={() => btn_tableRowClick(searchedTableList[virtualRow.index])}
          >
            <td>{virtualRow.index + 1}</td>
            <td>{searchedTableList[virtualRow.index].userName}</td>
            <td>
              {searchedTableList[virtualRow.index].sendPlace
                ? getSpQ?.find(el => el.key === searchedTableList[virtualRow.index].sendPlace)
                    ?.value
                : ""}
            </td>
            <td>
              {searchedTableList[virtualRow.index]?.sendDate
                ? moment(searchedTableList[virtualRow.index]?.sendDate).format("YYYY-MM-DD")
                : ""}
            </td>
            <td>
              {searchedTableList[virtualRow.index].processChk === "R"
                ? "접수"
                : searchedTableList[virtualRow.index].processChk === "P"
                ? "우체국"
                : searchedTableList[virtualRow.index].processChk === "D"
                ? "보류"
                : searchedTableList[virtualRow.index].processChk === "C"
                ? "취소"
                : searchedTableList[virtualRow.index].processChk === "E"
                ? "완료"
                : ""}
            </td>
            <td>
              {
                getPostGubun?.find(el2 => el2.key === searchedTableList[virtualRow.index].sendGubun)
                  ?.value
              }
            </td>
            <td>{searchedTableList[virtualRow.index].createNo}</td>
            <td>{searchedTableList[virtualRow.index].custId}</td>
            <td>{searchedTableList[virtualRow.index].kname}</td>
            <td>
              <input
                type="text"
                className="form-control"
                value={searchedTableList[virtualRow.index].compDev}
                readOnly={true}
                style={{ border: "0" }}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={searchedTableList[virtualRow.index].remark}
                readOnly={true}
                style={{ border: "0" }}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={searchedTableList[virtualRow.index].roadAddres}
                readOnly={true}
                style={{ border: "0" }}
              />
            </td>
          </tr>
        )
      )}
    </>
  );
};
