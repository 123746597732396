import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAtom } from "jotai";
import { isLoadingSpinner_atom } from "../../data/atom/isLoadingSpinner_atom";
import { queueTab_atom } from "../../data/atom/queueTab_atom";

/* props 설명
   children - Tabs 밑에 Tab 안에 들어갈 내용을 넣어주세요
 */
const QueueTab = props => {
  const { ChildComponent, tabClass = "", data, onSelectEvent, onCloseEvent } = props;
  const [isLoading] = useAtom(isLoadingSpinner_atom);
  const [activeKey, setActiveKey] = useState(-1);
  const [queue, setQueue] = useState([]);
  const tabKey = `${
    window.location.pathname.startsWith(process.env.PUBLIC_URL)
      ? window.location.pathname.replace(process.env.PUBLIC_URL, "")
      : window.location.pathname
  }/queueTab`;
  const [tabAtom, setTabAtom] = useAtom(queueTab_atom);

  // data가 새걸로 바뀌면 호출
  useEffect(() => {
    let stored = tabAtom[tabKey];
    if (stored === null || stored === undefined) stored = { activeKey: activeKey, data: [] };
    stored.data = stored.data.map(item => {
      if (item.seqNo === data.seqNo) return data;
      else return item;
    });
    let lastActiveKey = -1;
    if (stored === null || stored === undefined) {
      stored = { activeKey: activeKey, data: [] };
    } else {
      lastActiveKey = stored.activeKey;
    }
    if (data?.seqNo) {
      let filtered = stored.data.filter((item, index) => {
        if (data.seqNo === item.seqNo && data.custId === item.custId) {
          lastActiveKey = index;
          return true;
        } else return false;
      });
      if (filtered.length === 0) {
        stored.data = [data].concat(stored.data);
        lastActiveKey = 0;
      }
    }

    setQueue(stored.data);
    stored.activeKey = lastActiveKey;
    setActiveKey(lastActiveKey);
    setTabAtom({ ...tabAtom, [tabKey]: stored });
  }, [data]);

  const selectEvent = index => {
    if (isLoading.length > 0) return;
    if (onSelectEvent) onSelectEvent(queue[index], index);
    setActiveKey(index);
    setTabAtom({ ...tabAtom, [tabKey]: { activeKey: index, data: queue } });
  };

  const tabClosed = index => {
    let queueTmp = queue.filter((item, i) => i !== index);
    setQueue(queueTmp);
    let activeKeyTmp = activeKey;
    if (activeKey > queueTmp.length - 1) activeKeyTmp--;
    setActiveKey(activeKeyTmp);
    setTabAtom({ ...tabAtom, [tabKey]: { activeKey: activeKeyTmp, data: queueTmp } });
    if (onCloseEvent) {
      if (activeKeyTmp >= 0) onCloseEvent(queueTmp[activeKeyTmp], activeKeyTmp);
      else onCloseEvent(null);
    }
  };

  return (
    <Tabs activeKey={activeKey} onSelect={selectEvent} className={tabClass}>
      {queue.length > 0 ? (
        queue.map((child, index) => (
          <Tab
            eventKey={index}
            key={"key" + index}
            title={
              <>
                {child &&
                  (child.kname
                    ? child.kname
                    : child.company
                    ? child.company
                    : `${child.custId.substring(0, 4)}-${child.custId.substring(4)}`)}
                <span
                  className="btn-tab-close"
                  onClick={e => {
                    e.stopPropagation();
                    tabClosed(index);
                  }}
                />
              </>
            }
          >
            <ChildComponent data={child} />
          </Tab>
        ))
      ) : (
        <Tab eventKey={-1} title={<span className="btn-tab-close" />}>
          <ChildComponent />
        </Tab>
      )}
    </Tabs>
  );
};

export default QueueTab;
