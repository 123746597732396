import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  searchParam_atom,
  searchResult_atom,
} from "../../../../../data/atom/workManage/departmentManage/databaseDistributionStatus/newDbStatus";
import { useAtom, useSetAtom } from "jotai";
import { axios } from "../../../../../util/axios/axios";
import { decrypt } from "../../../../../util/decrypt/decrypt";

const SearchPanel = () => {
  const queryClient = useQueryClient();
  const jisaGubun = queryClient.getQueryData(["getJisaGubun"]);
  const [searchParamAtom, setSearchParamAtom] = useAtom(searchParam_atom);
  const setSearchResult = useSetAtom(searchResult_atom);

  const search = useMutation(
    data =>
      axios.get("/api/work/newdb/search", {
        params: data,
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        const keys = ["jisa", "codename", "userName"];
        let before = ["", "", ""];
        let tmp = result.map(item => {
          if (item.userName === "소계") return item;
          for (let i = 0; i < 3; i++) {
            if (item[keys[i]] !== before[i]) {
              before[i] = item[keys[i]];
              item[keys[i]] = "show-" + item[keys[i]];
            }
          }
          return item;
        });

        setSearchResult(tmp);
      },
    }
  );

  const onChangeSearchParam = (key, value) => {
    setSearchParamAtom({ ...searchParamAtom, [key]: value });
  };

  const btn_search = () => {
    setSearchResult([]);
    search.mutate(searchParamAtom);
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <div className="filter_fields_container">
      <div className="filter_fields_row">
        <div className="d-flex align-items-center justify-content-between" onKeyDown={pressEnter}>
          <div className="filter1-field filter1-field1">
            <label className="filter1-label">지사구분</label>
            <select
              className="form-select filter1-select1"
              value={searchParamAtom.jisa}
              onChange={e => onChangeSearchParam("jisa", e.target.value)}
              disabled={decrypt("userInfo").userPermit !== "99"}
            >
              {jisaGubun?.map(item => (
                <option value={item.key}>{item.value}</option>
              ))}
            </select>
          </div>

          <div className="filter1-field filter1-field2">
            <label className="filter1-label">수정 기간</label>
            <input
              type="date"
              max={"9999-12-31"}
              className="form-control filter-input filter1-input2"
              value={searchParamAtom.fromDate}
              onChange={e => onChangeSearchParam("fromDate", e.target.value)}
            />
            <span>~</span>
            <input
              type="date"
              max={"9999-12-31"}
              className="form-control filter-input filter1-input2"
              value={searchParamAtom.toDate}
              onChange={e => onChangeSearchParam("toDate", e.target.value)}
            />
          </div>
        </div>
        <button
          className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
          onClick={btn_search}
        >
          <img className="me-2" src={process.env.PUBLIC_URL + "/images/search_icon.png"} alt="" />
          검색
        </button>
      </div>
    </div>
  );
};

export default SearchPanel;
