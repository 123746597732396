import React from "react";
import Select from "react-select-virtualized";
import { useAtom } from "jotai/index";
import { golfOption_atom } from "../../../../data/atom/dataManage/membershipItem/membershipItem_atom";
import { useAtomValue } from "jotai";

const tableRowGC = props => {
  const { currentData, onChangeRowEventHandler, btnDeleteRow } = props;
  const golfOption = useAtomValue(golfOption_atom);

  const gubun1Select = [
    { value: "11", label: "저가대" },
    { value: "22", label: "중가대" },
    { value: "33", label: "고가대" },
    { value: "34", label: "최고가대" },
  ];
  const membershipTypeSelect = [
    { value: "11", label: "일반회원권(특별 VIP포함)" },
    { value: "21", label: "여자 회원권" },
    { value: "31", label: "주중 회원권" },
    { value: "41", label: "무기명회원권" },
  ];

  return (
    <tr>
      <td className="text-center">{currentData.idx >= 0 ? currentData.idx + 1 : ""}</td>
      <td>
        <input
          type="checkbox"
          checked={currentData.checkYn}
          onChange={e => onChangeRowEventHandler("checkYn", e.target.checked)}
          disabled={currentData.newFlag}
        />
      </td>
      <td>
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          options={golfOption}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          value={golfOption?.find(option => option.value === currentData?.value1)}
          onChange={e => onChangeRowEventHandler("value1", e?.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input tc"
          value={currentData.remark ? currentData.remark.replace(/^(\d{3})(\d{3})$/, `$1-$2`) : ""}
          onChange={e => onChangeRowEventHandler("remark", e.target.value.replace(/-/g, ""))}
          maxLength={7}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input text-center"
          value={currentData.code ? currentData.code : ""}
          onChange={e => onChangeRowEventHandler("code", e.target.value)}
          disabled={currentData.newFlag}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={currentData.codename ? currentData.codename : ""}
          onChange={e => onChangeRowEventHandler("codename", e.target.value)}
        />
      </td>
      <td>
        <Select
          className="select-virtual"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          options={gubun1Select}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          value={gubun1Select?.find(option => option.value === currentData?.gubun1)}
          onChange={e => onChangeRowEventHandler("gubun1", e?.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={currentData.value2 ? currentData.value2 : ""}
          onChange={e => onChangeRowEventHandler("value2", e.target.value)}
        />
      </td>
      <td>
        <Select
          className="select-virtual"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          options={membershipTypeSelect}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          value={membershipTypeSelect?.find(option => option.value === currentData?.membershipType)}
          onChange={e => onChangeRowEventHandler("membershipType", e?.value)}
        />
      </td>
      <td>
        <button
          className="btn btn-outline-primary m-auto  h-25px"
          onClick={() => btnDeleteRow(currentData)}
        >
          삭제
        </button>
      </td>
    </tr>
  );
};

export default React.memo(tableRowGC);
