import React, { useEffect } from "react";
import { useAtom } from "jotai";
import {
  addRowIndex_atom,
  condoOption_atom,
  editedRowList_atom,
  gcOption_atom,
  golfOption_atom,
  membershipItemList_atom,
  membershipItemListLength_atom,
  searchParam_atom,
  searchParamInit,
  searchTypeFlag_atom,
  sportsOption_atom,
} from "../../../data/atom/dataManage/membershipItem/membershipItem_atom";
import { axios } from "../../../util/axios/axios";
import SearchResultTable from "./searchResultTable";
import useInfiniteScrollWrapper from "../../../util/infiniteScroll/useInfiniteScrollWrapper";
import { useMutation } from "@tanstack/react-query";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useSetAtom } from "jotai/index";

export const MembershipItem = () => {
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const setSearchTypeFlag = useSetAtom(searchTypeFlag_atom);
  const [membershipItemList, setMembershipItemList] = useAtom(membershipItemList_atom);
  const setEditedRowList = useSetAtom(editedRowList_atom);
  const [membershipItemListLength, setMembershipItemListLength] = useAtom(
    membershipItemListLength_atom
  );
  const setAddRowIndex = useSetAtom(addRowIndex_atom);
  const [golfOption, setGolfOption] = useAtom(golfOption_atom);
  const [gcOption, setGcOption] = useAtom(gcOption_atom);
  const [condoOption, setCondoOption] = useAtom(condoOption_atom);
  const [sportsOption, setSportsOption] = useAtom(sportsOption_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const searchMembershipItemList = async data => {
    let res = await axios.get("/api/data/memberManage/search", { params: data });
    setSearchTypeFlag(searchParam.searchType);
    setEditedRowList([]);
    setAddRowIndex(0);

    return [...res.data.result].map((el, idx) => {
      if (membershipItemListLength < 100) {
        return { ...el, checkYn: false, newFlag: false, idx: idx };
      } else {
        return { ...el, checkYn: false, newFlag: false, idx: membershipItemListLength + idx };
      }
    });
  };

  const searchGolfList = useMutation(
    () => axios.get("/api/codeMng/select/getMemberManageGolfList"),
    {
      onSuccess: res => {
        const tmpList = [];
        res.data.result?.map(el => {
          tmpList.push({ value: el.key, label: el.value, remark: el.remark });
        });

        setGolfOption([
          ...tmpList,
          { value: "DM-S", label: "DM-S", remark: "" },
          { value: "DM-B", label: "DM-B", remark: "" },
          { value: "DM-H", label: "DM-H", remark: "" },
        ]);
      },
    }
  );

  const searchGcList = useMutation(
    () => axios.get("/api/codeMng/select/getMemberManageGcCodeList"),
    {
      onSuccess: res => {
        const tmpList = [];
        res.data.result?.map(el => {
          tmpList.push({ value: el.key, label: el.value });
        });
        setGcOption([...tmpList]);
      },
    }
  );

  const searchCondoList = useMutation(
    data => axios.get("/api/codeMng/select/getMemberManageCondoList", data),
    {
      onSuccess: res => {
        const tmpList = [];
        res.data.result?.map(el => {
          tmpList.push({ value: el.key, label: el.value });
        });
        setCondoOption([...tmpList]);
      },
    }
  );

  const searchSportsList = useMutation(
    data => axios.get("/api/codeMng/select/getMemberManageSportList", data),
    {
      onSuccess: res => {
        const tmpList = [];
        res.data.result?.map(el => {
          tmpList.push({ value: el.key, label: el.value });
        });
        setSportsOption([...tmpList]);
      },
    }
  );

  useEffect(() => {
    if (golfOption.length === 0) searchGolfList.mutate();
    if (gcOption.length === 0) searchGcList.mutate();
    if (condoOption.length === 0) searchCondoList.mutate();
    if (sportsOption.length === 0) searchSportsList.mutate();
  }, []);

  useEffect(() => {
    if (membershipItemList.length !== 0) {
      setMembershipItemListLength(membershipItemList.length);
    }
  }, [membershipItemList]);

  const {
    endRef: scrollRef,
    refetchFromFirstPage,
    afterFetch,
  } = useInfiniteScrollWrapper({
    fetch: searchMembershipItemList,
    fetchKey: ["transactionSearch"],
    searchParam: searchParam,
    resultSetter: setMembershipItemList,
  });

  const onChangeParamEventHandler = (key, value) => {
    setSearchParam({ ...searchParam, [key]: value });
  };

  const btnSearch = async () => {
    await setMembershipItemList([]);
    await setMembershipItemListLength(0);

    refetchFromFirstPage();
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch().then(() => {});
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam(searchParamInit);
      setSearchTypeFlag("1");
      setMembershipItemList([]);
      setMembershipItemListLength(0);
      setEditedRowList([]);
      setAddRowIndex(0);
      setGolfOption([]);
      setGcOption([]);
      setCondoOption([]);
      setSportsOption([]);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">회원권 종목 검색</div>
          </div>

          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.searchType}
                    onChange={e => onChangeParamEventHandler("searchType", e.target.value)}
                    style={{ width: "250px" }}
                  >
                    <option value="1">골프회원권(GM)-종목별 분류</option>
                    <option value="2">골프회원권(GC)-국세청 분류</option>
                    <option value="3">콘도회원권(CM)</option>
                    <option value="4">헬스회원권(SM)</option>
                    <option value="5">ETCCODE2(GM)</option>
                    <option value="6">ETCCODE2(CM)</option>
                    <option value="7">ETCCODE2(SM)</option>
                  </select>
                </div>
                {searchParam.searchType === "1" ||
                searchParam.searchType === "2" ||
                searchParam.searchType === "5" ? (
                  <div className="filter1-field filter1-field1 ps-5">
                    <label className="filter1-label">회원권명</label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      onChange={e => onChangeParamEventHandler("keyword", e.target.value)}
                      onKeyDown={pressEnter}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
            {searchParam.searchType === "1" ||
            searchParam.searchType === "2" ||
            searchParam.searchType === "5" ? (
              <div className="filter_fields_row justify-content-start mt-3">
                <div className="stat-item">
                  저가대 : &nbsp;
                  <div className="stat-num">1억미만</div>
                </div>
                <div className="stat-item">
                  중가대 : &nbsp;
                  <div className="stat-num">1억-2억미만</div>
                </div>
                <div className="stat-item">
                  고가대 : &nbsp;
                  <div className="stat-num">2억~5억미만</div>
                </div>
                <div className="stat-item">
                  최고가대 : &nbsp;
                  <div className="stat-num">5억이상</div>
                </div>
                <div className="stat-item">
                  주중 : &nbsp;
                  <div className="stat-num">주중회원권</div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            <div className="containerx">
              <div className="table-container">
                <SearchResultTable
                  scrollRef={scrollRef}
                  refetchFromFirstPage={refetchFromFirstPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
