import { atom } from "jotai";

export const searchParamInit = {
  searchType: "1",
  keyword: "",
  page: 1,
};

export const searchParam_atom = atom({ ...searchParamInit });

export const searchTypeFlag_atom = atom("1");

export const membershipItemList_atom = atom([]);

export const editedRowList_atom = atom([]);

export const membershipItemListLength_atom = atom(0);

export const addRowIndex_atom = atom(0);

export const golfOption_atom = atom([]);

export const gcOption_atom = atom([]);

export const condoOption_atom = atom([]);

export const sportsOption_atom = atom([]);
