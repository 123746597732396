import { decrypt } from "../../util/decrypt/decrypt";
import { encrypt } from "../../util/encrypt/encrypt";

const wrapper = {
  getItem(key, initialValue) {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) return decrypt(key);
    else return initialValue;
  },
  setItem(key, value) {
    sessionStorage.setItem(key, encrypt(value));
  },
  removeItem(key) {
    sessionStorage.removeItem(key);
  },
};
export default wrapper;
