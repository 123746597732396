const useWindowPrint = () => {
  const handlePrint = data => {
    // 프린트할 내용을 담을 새로운 창 생성
    const printWindow = window.open("", "_blank", "width=800,height=600");

    // 프린트할 내용 작성
    printWindow.document.write(`
      <html>
        <head>
          <title>인쇄</title>
          <style>
            /* 프린트 스타일 정의 */
            @media print {
              img {
                max-width: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
              }
              @page {
                margin: 0;
                size: auto;
              }
            }
          </style>
        </head>
        <body>
          ${Array.isArray(data) ? data.map(item => `<img src="${item}" />`).join("") : `<img src="${data}" />`}
        </body>
      </html>
    `);

    printWindow.document.close();

    // 이미지 로드 완료 후 프린트
    const imgs = printWindow.document.querySelectorAll("img");
    if (imgs.length > 0) {
      let loadedCount = 0;
      imgs.forEach(img => {
        img.onload = () => {
          loadedCount++;
          if (loadedCount === imgs.length) {
            printWindow.focus();
            printWindow.print();
            // 인쇄 다이얼로그가 닫힐 때 창 닫기
            const checkClosedInterval = setInterval(() => {
              if (printWindow.closed) {
                clearInterval(checkClosedInterval);
              }
            }, 1000);
          }
        };
      });
    }
  };

  return handlePrint;
};
export default useWindowPrint;
