import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SidebarCode = ({ func_recentTap, data }) => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const pathName = location.pathname.split("/")[4];

  const btn_onClick = (name, url) => {
    func_recentTap({
      name: name,
      url: url,
    });
  };

  return (
    <div className="sidebar-wrapper">
      <ul>
        {data?.sub.reduce((acc, cur) => {
          if (cur.sub.length !== 0) {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 2] && "active"
                }
              >
                <div onClick={() => btn_onClick(cur.sub[0].pageName, cur.url)}>
                  {cur.pageName} <span></span>
                </div>

                <ul className="sidebar_subList">
                  {cur.sub.map(el => (
                    <li
                      className={
                        pathName2 === el.url.split("/")[el.url.split("/").length - 1] && "active"
                      }
                      onClick={() => btn_onClick(el.pageName, el.url)}
                    >
                      {el.pageName}
                    </li>
                  ))}
                </ul>
              </li>,
            ];
          } else {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 1] && "active"
                }
                onClick={() => btn_onClick(cur.pageName, cur.url)}
              >
                {cur.pageName}
              </li>,
            ];
          }

          return acc;
        }, [])}
      </ul>
    </div>
  );

  // return (
  //   <div className="sidebar-wrapper">
  //     <ul>
  //       <li
  //         className={pathName === "daposUserManager" && "active"}
  //         onClick={() => btn_onClick("DAPOS 사용자관리", "/code/daposUserManage/daposUserManager")}
  //       >
  //         DAPOS 사용자관리
  //       </li>
  //       <li
  //         className={pathName === "ipConnectionAuth" && "active"}
  //         onClick={() =>
  //           btn_onClick("IP 및 접속시간 관리", "/code/ipControlManage/ipConnectionAuth")
  //         }
  //         style={{ marginTop: "10px" }}
  //       >
  //         IP 및 접속시간 관리
  //       </li>
  //       <li
  //         className={pathName === "changePerson" && "active"}
  //         onClick={() => btn_onClick("담당자별 변경", "/code/partnerDivideManage/changePerson")}
  //         style={{ marginTop: "10px" }}
  //       >
  //         담당자별 변경
  //       </li>
  //       <li
  //         className={pathName === "permissionEachMenu" && "active"}
  //         onClick={() =>
  //           btn_onClick("메뉴 팀별 사용 권한", "/code/ipControlManage/permissionEachMenu")
  //         }
  //         style={{ marginTop: "10px" }}
  //       >
  //         메뉴 팀별 사용 권한
  //       </li>
  //     </ul>
  //   </div>
  // );
};
