import React from "react";
import { useAtom, useSetAtom } from "jotai/index";
import {
  clickedRow_atom,
  golfData_atom,
} from "../../../../../data/atom/workManage/golfManage/golfManage_atom";

const SearchResultTableRow = props => {
  const { data, index } = props;
  const setClickedRow = useSetAtom(clickedRow_atom);
  const [golfData, setGolfData] = useAtom(golfData_atom); // 골프 데이터 상태

  const btn_clickRow = () => {
    setClickedRow(data);
  };

  return (
    <tr
      className={`cursor-pointer ${data?.custId === golfData?.custId ? "bg-blue" : ""}  ${
        data.outCheck === "Y" ? "bg-red" : ""
      } `}
      onClick={btn_clickRow}
    >
      <td>{index + 1}</td>
      <td>{data.company}</td>
      <td>{data.holsu}</td>
      <td>{data.holsuP}</td>
      <td>{data.holsuP3}</td>
      <td>{data.gubun1}</td>
    </tr>
  );
};
export default React.memo(SearchResultTableRow);
