import { useAtom } from "jotai";
import {
  personalScheduleManagement_curMonth,
  personalScheduleManagement_searchData,
} from "../../../data/atom/goodsManage/personalScheduleManagement/personalScheduleManagement_atom";
import PersonalSechduleCalendar from "./components/PersonalSechduleCalendar";
import SearchOption from "./components/SearchOption";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment";
import { useState } from "react";

export const PersonalScheduleManagement = () => {
  const [curDate, setCurDate] = useAtom(personalScheduleManagement_curMonth);
  const [searchData, setSearchData] = useAtom(personalScheduleManagement_searchData);
  const [createMode, setCreateMode] = useState(false);

  const queryClient = useQueryClient();

  // API -개인 일정 조회
  const searchCall = useQuery(
    ["calendar_search", curDate],
    async () =>
      await axios
        .get("/api/schedule/individualManage/search", {
          params: {
            yearDate: moment(curDate).format("YYYYMM"),
          },
        })
        .then(res => {
          setSearchData(res?.data?.result);
          return res.data.result;
        })
  );

  // API - 개인 일정 저장/수정
  const saveCalendar = useMutation(
    data => axios.post("/api/schedule/individualManage/save", data),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(["calendar_search"]);
        setCreateMode(false);
      },
    }
  );

  // API - 개인 일정 삭제
  const deleteCalendar = useMutation(
    data => axios.post("/api/schedule/individualManage/delete", data),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(["calendar_search"]);
      },
    }
  );

  return (
    <div className="card card-main mt-14px mx-3">
      <div className="card-header">
        <div className="card-title m-0 w-50">개인일정관리</div>
      </div>
      <div className="card-body">
        <SearchOption curDate={curDate} setCurDate={setCurDate} />
        <PersonalSechduleCalendar
          curDate={curDate}
          searchData={searchData}
          saveCalendar={saveCalendar}
          createMode={createMode}
          setCreateMode={setCreateMode}
          deleteCalendar={deleteCalendar}
        />
      </div>
    </div>
  );
};
