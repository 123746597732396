export function oldToNewPhoneFormat(param) {
  let phone = param ? param : "";

  if (!phone.includes("-")) return alert("'-' 를 포함한 올바른 번호를 입력해주세요.");

  let splitPhone = phone.split("-");

  switch (splitPhone[0]) {
    case "011":
      if (200 <= splitPhone[1] && splitPhone[1] < 500) splitPhone[1] = "5".concat(splitPhone[1]);
      if (500 <= splitPhone[1] && splitPhone[1] < 900) splitPhone[1] = "3".concat(splitPhone[1]);
      if (9500 <= splitPhone[1] && splitPhone[1] < 10000)
        splitPhone[1] = "8".concat(splitPhone[1].substring(1));
      if (1700 <= splitPhone[1] && splitPhone[1] < 1800)
        splitPhone[1] = "71".concat(splitPhone[1].substring(2));
      break;
    case "016":
      if (200 <= splitPhone[1] && splitPhone[1] < 500) splitPhone[1] = "3".concat(splitPhone[1]);
      if (500 <= splitPhone[1] && splitPhone[1] < 900) splitPhone[1] = "2".concat(splitPhone[1]);
      if (9000 <= splitPhone[1] && splitPhone[1] < 9500)
        splitPhone[1] = "7".concat(splitPhone[1].substring(1));
      break;
    case "017":
      if (200 <= splitPhone[1] && splitPhone[1] < 500) splitPhone[1] = "6".concat(splitPhone[1]);
      if (500 <= splitPhone[1] && splitPhone[1] < 900) splitPhone[1] = "4".concat(splitPhone[1]);
      break;
    case "018":
      if (200 <= splitPhone[1] && splitPhone[1] < 500) splitPhone[1] = "4".concat(splitPhone[1]);
      if (500 <= splitPhone[1] && splitPhone[1] < 900) splitPhone[1] = "6".concat(splitPhone[1]);
      break;
    case "019":
      if (200 <= splitPhone[1] && splitPhone[1] < 500) splitPhone[1] = "2".concat(splitPhone[1]);
      if (500 <= splitPhone[1] && splitPhone[1] < 900) splitPhone[1] = "5".concat(splitPhone[1]);
      if (9000 <= splitPhone[1] && splitPhone[1] < 9500)
        splitPhone[1] = "8".concat(splitPhone[1].substring(1));
      if (9500 <= splitPhone[1] && splitPhone[1] < 10000)
        splitPhone[1] = "7".concat(splitPhone[1].substring(1));
      break;
    default:
      return alert("구버전 휴대폰 번호를 입력해주세요.");
  }
  return "010-" + splitPhone[1] + "-" + splitPhone[2];
}
