import { atom } from "jotai";
import moment from "moment/moment";

export const searchParamInit = {
  code: "01",
  keyword: "",
  date: moment().format("YYYY-MM-DD"),
};
export const searchParam_atom = atom({ ...searchParamInit });
export const tableRowLimit_atom = atom("5");
export const transactionList_atom = atom([]);
export const clickedRow_atom = atom({});
export const transactionMain_atom = atom({});
export const transactionSubList_atom = atom([]);

export const transactionProgress_isNewRegister_atom = atom(false);
