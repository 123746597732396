import { atom } from "jotai";

export const companyAccountManage_getData = atom([]);
export const companyAccountManage_isAdd = atom(false);
export const companyAccountManage_backupData = atom([]);
export const companyAccountManage_modeData = atom([]);
export const companyAccountManage_addData = atom({
  gubun: "1001",
  ilNo: 0,
  modDate: "",
  openDate: "",
  outDate: "",
  payAccount: "",
  payBank: "99",
  payName: "",
  remark: "",
  userCheck: "Y",
});
