import { atom } from "jotai";

export const condoMasterManage_searchDataInit_atom = {
  condition: "A",
  keyword: "",
};

export const condoMasterManage_searchData_atom = atom(condoMasterManage_searchDataInit_atom);

export const condoMasterManage_tableLimit_atom = atom("5");

export const condoMasterManage_searchedTableList_atom = atom([]);

export const condoMasterManage_mainDataInit_atom = {
  addres: "",
  caddres1: "",
  cfax: "",
  company: "",
  ctel: "",
  custId: "",
  czipcode1: "",
  dept: "",
  dmCheck: "",
  gtel: "",
  kname: "",
  location: "",
  modDate: "",
  outCheck: "N",
  outDate: "",
  owner: "",
  payAccount: "",
  payBank: "",
  payName: "",
  position: "",
  ptel: "",
  reason: "",
  siteUrl: "",
  ucompany: "",
  userid: "",
  webViewChk: "",
};

export const condoMasterManage_mainData_atom = atom(condoMasterManage_mainDataInit_atom);

export const condoMasterManage_subData_atom = atom({});

export const condoMasterManage_prevOutDate = atom("");
