import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const Tab6 = ({ custId }) => {
  const [pageData, setPageData] = useState(null);
  const [activeKey, setActiveKey] = useState("주중");

  const bottomGetBooking = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getBooking", {
        params: { custId: data.custId },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setPageData({ ...result });

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );

  const bottomSaveBooking = useMutation(data =>
    axios.post("/api/work/manageGolf/bottom/saveBooking", data)
  );

  useEffect(() => {
    if (custId) bottomGetBooking.mutate({ custId: custId });
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetBooking.mutate({ custId: custId });
  }, []);

  const onChangeTextArea = (key, value) => {
    setPageData({ ...pageData, [key]: value });
  };

  const btn_cancel = () => {
    if (confirm("취소하시겠습니까")) bottomGetBooking.mutate({ custId: custId, type: "cancel" });
  };

  const btn_save = () => {
    bottomSaveBooking.mutate(pageData);
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example2"
        activeKey={activeKey}
        onSelect={k => setActiveKey(k)}
        className="tab-blocks"
      >
        <Tab eventKey="주중" title="주중">
          <textarea
            rows={10}
            className="form-control text-start mt-2"
            value={pageData?.normalDay ? pageData?.normalDay : ""}
            onChange={e => onChangeTextArea("normalDay", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="주말" title="주말">
          <textarea
            rows={10}
            className="form-control text-start mt-2"
            value={pageData?.weekendDay ? pageData?.weekendDay : ""}
            onChange={e => onChangeTextArea("weekendDay", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="가족회원" title="가족회원">
          <textarea
            rows={10}
            className="form-control text-start mt-2"
            value={pageData?.familyMember ? pageData?.familyMember : ""}
            onChange={e => onChangeTextArea("familyMember", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="회원의날" title="회원의날">
          <textarea
            rows={10}
            className="form-control text-start mt-2"
            value={pageData?.memberDay ? pageData?.memberDay : ""}
            onChange={e => onChangeTextArea("memberDay", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="단체팀" title="단체팀">
          <textarea
            rows={10}
            className="form-control text-start mt-2"
            value={pageData?.groupTeam ? pageData?.groupTeam : ""}
            onChange={e => onChangeTextArea("groupTeam", e.target.value)}
          ></textarea>
        </Tab>
        <Tab eventKey="기타회원" title="기타회원">
          <textarea
            rows={10}
            className="form-control text-start mt-2"
            value={pageData?.etcMember ? pageData?.etcMember : ""}
            onChange={e => onChangeTextArea("etcMember", e.target.value)}
          ></textarea>
        </Tab>
      </Tabs>
      <div className="d-flex align-items-center justify-content-end my-2">
        <button className="btn btn-outline-primary" onClick={btn_save}>
          저장
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btn_cancel}>
          취소
        </button>
      </div>
    </>
  );
};

export default React.memo(Tab6);
