import React, { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai/index";
import {
  carFuelInfo_atom,
  carFuelInfoEditedRowList_atom,
} from "../../../../../data/atom/dataManage/carManage/carMaster_atom";
import moment from "moment/moment";
import Select from "react-select-virtualized";

const CarMasterListFuelRow = props => {
  const { data, index, drveUserList, acctList, fuelList, deleteFuelInfo } = props;
  const [fuelInfo, setFuelInfo] = useAtom(carFuelInfo_atom);
  const [editedRowList, setEditedRowList] = useAtom(carFuelInfoEditedRowList_atom);
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  const onChangeEventHandler = (key, value) => {
    if (key === "runnMIle" || key === "litrCost" || key === "litrCost" || key === "crdtNumb") {
      value = value
        .replace(/-/g, "")
        .replace(/,/g, "")
        .replace(/[^0-9]/g, "");
    }

    setCurrentData(pre => {
      const newData = { ...pre, [key]: value };

      setEditedRowList(prev => {
        let flagIdx = -2;
        prev = prev.map((el, idx) => {
          if (el.idx === newData.idx) {
            flagIdx = idx;
            return { ...newData };
          } else return el;
        });
        if (flagIdx === -2) prev.push({ ...newData });

        return prev;
      });

      return newData;
    });
  };

  const btnDelete = el => {
    if (el.idx === -1) {
      const list = fuelInfo.filter(el => el.idx !== -1);
      const listEd = editedRowList.filter(el => el.idx !== -1);
      setFuelInfo(list);
      setEditedRowList(listEd);
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteFuelInfo(el);
      }
    }
  };

  const drveOption = useMemo(
    () =>
      (
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          options={drveUserList}
          value={drveUserList?.find(el2 => el2.value === currentData?.juyuUser)}
          onChange={e => onChangeEventHandler("juyuUser", e?.value)}
        />
      ) ?? [],
    [drveUserList, currentData.juyuUser]
  );
  const acctOption = useMemo(
    () =>
      (
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          options={acctList}
          value={acctList?.find(el2 => el2.value === currentData?.acctCode)}
          onChange={e => onChangeEventHandler("acctCode", e?.value)}
        />
      ) ?? [],
    [acctList, currentData.acctCode]
  );
  const fuelOption = useMemo(
    () =>
      (
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          options={fuelList}
          value={fuelList?.find(el2 => el2.value === currentData?.engrGubn)}
          onChange={e => onChangeEventHandler("engrGubn", e?.value)}
        />
      ) ?? [],
    [fuelList, currentData.engrGubn]
  );

  return (
    <tr>
      <td>{index >= 0 ? index + 1 : ""}</td>
      <td>{currentData.carrNumb}</td>
      <td>
        <input
          type="datetime-local"
          className="form-control filter-input h-25px"
          value={moment(currentData.juyuDate).format("YYYY-MM-DD HH:mm")}
          onChange={e => onChangeEventHandler("juyuDate", e.target.value)}
          style={{ borderColor: "#C5C5C5" }}
          min={"1900-01-01 00:00"}
          max={"2099-12-31 23:59"}
        />
      </td>
      <td>{drveOption}</td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.runnMile?.toLocaleString()}
          onChange={e => onChangeEventHandler("runnMile", e.target.value)}
        />
      </td>
      <td>{fuelOption}</td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.litrCost?.toLocaleString()}
          onChange={e => onChangeEventHandler("litrCost", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.juyuLitr}
          onChange={e => onChangeEventHandler("juyuLitr", e.target.value)}
          onBlur={e => {
            let value = e.target.value.replace(/[^0-9.]/g, "");
            if (!value.includes(".")) {
              if (value === "") value += "0.0";
              else value += ".0";
            } else {
              let [whole, decimal] = value.split(".");
              whole = whole === "" ? "0" : whole;
              if (!decimal) {
                value = whole + ".0";
              }
              value = Number(value).toFixed(1);
            }
            onChangeEventHandler("juyuLitr", value >= 1000 ? "999.9" : value);
          }}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={currentData.juyuPlac}
          onChange={e => onChangeEventHandler("juyuPlac", e.target.value)}
        />
      </td>
      <td>{acctOption}</td>
      <td>
        <input
          type="text"
          className="form-control text-center"
          value={currentData.crdtNumb?.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/g, "$1-$2-$3-$4")}
          onChange={e => onChangeEventHandler("crdtNumb", e.target.value)}
          maxLength={19}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={currentData.remark}
          onChange={e => onChangeEventHandler("remark", e.target.value)}
        />
      </td>
      <td>
        <a
          onClick={() => {
            btnDelete(currentData);
          }}
        >
          <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
        </a>
      </td>
    </tr>
  );
};

export default React.memo(CarMasterListFuelRow);
