import React from "react";
import { useAtom } from "jotai/index";
import { registeredDeliveryHistory_searchedData_atom } from "../../../../../../data/atom/workManage/postManage/registeredDeliveryHistory_atom";
import { useAtomValue } from "jotai";

export const RegisteredDeliveryHistoryTableRowHeader = () => {
  const searchedData = useAtomValue(registeredDeliveryHistory_searchedData_atom);

  return (
    <>
      {searchedData?.gubun === "A" ? (
        <tr>
          <th style={{ width: "1%" }}>연번</th>
          <th style={{ width: "3%" }}>발송자</th>
          <th style={{ width: "3%" }}>발송일자</th>
          <th style={{ width: "3%" }}>발송방법</th>
          <th style={{ width: "3%" }}>등기번호</th>
          <th style={{ width: "5%" }}>업체/부서명</th>
          <th style={{ width: "3%" }}>성명(고객명)</th>
          {/* <th style={{ width: "3%" }}>고객번호</th> */}
          <th style={{ width: "8%" }}>내용</th>
          {/* <th style={{ width: "2%" }}>상황</th> */}
          <th style={{ width: "3%" }}>접수우체국</th>
          <th style={{ width: "3%" }}>요금</th>
          <th style={{ width: "3%" }}>배/증회송</th>
          <th style={{ width: "10%" }}>주소</th>
        </tr>
      ) : searchedData?.gubun === "B" ? (
        <tr>
          <th style={{ width: "1%" }}>연번</th>
          <th style={{ width: "3%" }}>발송일자</th>
          <th style={{ width: "3%" }}>발송방법</th>
          <th style={{ width: "3%" }}>등기번호</th>
          <th style={{ width: "5%" }}>업체/부서명</th>
          <th style={{ width: "3%" }}>성명(고객명)</th>
          <th style={{ width: "8%" }}>내용</th>
          <th style={{ width: "3%" }}>발송자</th>
          <th style={{ width: "3%" }}>접수우체국</th>
          <th style={{ width: "3%" }}>배/증회송</th>
          <th style={{ width: "10%" }}>주소</th>
        </tr>
      ) : searchedData?.gubun === "C" ? (
        <tr>
          <th style={{ width: "1%" }}>연번</th>
          <th style={{ width: "3%" }}>발송자</th>
          <th style={{ width: "3%" }}>접수우체국</th>
          <th style={{ width: "3%" }}>발송일자</th>
          <th style={{ width: "2%" }}>상황</th>
          <th style={{ width: "3%" }}>발송방법</th>
          <th style={{ width: "3%" }}>등기번호</th>
          <th style={{ width: "3%" }}>고객번호</th>
          <th style={{ width: "3%" }}>성명(고객명)</th>
          <th style={{ width: "5%" }}>업체/부서명</th>
          <th style={{ width: "8%" }}>내용</th>
          <th style={{ width: "10%" }}>주소</th>
        </tr>
      ) : (
        <tr>
          <th style={{ width: "1%" }}>연번</th>
          <th style={{ width: "3%" }}>발송자</th>
          <th style={{ width: "3%" }}>발송일자</th>
          <th style={{ width: "3%" }}>발송방법</th>
          <th style={{ width: "3%" }}>등기번호</th>
          <th style={{ width: "5%" }}>업체/부서명</th>
          <th style={{ width: "3%" }}>성명(고객명)</th>
          <th style={{ width: "3%" }}>고객번호</th>
          <th style={{ width: "8%" }}>내용</th>
          <th style={{ width: "2%" }}>상황</th>
          <th style={{ width: "3%" }}>접수우체국</th>
          <th style={{ width: "3%" }}>요금</th>
          <th style={{ width: "3%" }}>배/증회송</th>
          <th style={{ width: "10%" }}>주소</th>
        </tr>
      )}
    </>
  );
};
