import {
  profitList_atom,
  profitTotal_atom,
} from "../../../data/atom/goodsManage/weeklyPerformanceAnalysis/weeklyPerformanceAnalysis_atom";
import moment from "moment/moment";
import { useAtomValue } from "jotai";

export const WeeklySales = () => {
  const profitTotal = useAtomValue(profitTotal_atom);
  const profitList = useAtomValue(profitList_atom);

  return (
    <>
      <div className="card-header">
        <h5 className="card-title m-0">■ 주별 매출 현황</h5>
      </div>
      <div className="card-body">
        <div className="table-container table-sticky h-333">
          <table className="table table-bordered table-responsive my-table m-0">
            <thead>
              <tr>
                <th style={{ width: "3%" }} rowSpan={2}>
                  검색
                </th>
                <th rowSpan={2}>주 별 기 간</th>
                <th colSpan={2}>소계</th>
                <th colSpan={2}>본사(골프)</th>
                <th colSpan={2}>본사(콘도)</th>
                <th colSpan={2}>분당지사</th>
                <th colSpan={2}>대전지사</th>
                <th colSpan={2}>대구지사</th>
                <th colSpan={2}>부산지사</th>
                <th colSpan={2}>호남지사</th>
                <th colSpan={2}>분양사업부</th>
              </tr>
              <tr>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
                <th style={{ width: "6%" }}>매출액</th>
                <th style={{ width: "4%" }}>건수</th>
              </tr>
            </thead>
            <tbody>
              {profitList.length !== 0 ? (
                profitList.map((el, idx) => {
                  return (
                    <>
                      <tr>
                        <td>{idx + 1}주</td>
                        <td>
                          {moment(el.fdate).format("MM.DD")}~{moment(el.tdate).format("MM.DD")}
                        </td>
                        <td className="text-end">
                          {el.totalAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.totalCnt}</td>
                        <td className="text-end">
                          {el.sgolfAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.sgolfCnt}</td>
                        <td className="text-end">
                          {el.scondoAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.scondoCnt}</td>
                        <td className="text-end">
                          {el.bundangAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.bundangCnt}</td>
                        <td className="text-end">
                          {el.daejunAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.daejunCnt}</td>
                        <td className="text-end">
                          {el.daeguAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.daeguCnt}</td>
                        <td className="text-end">
                          {el.busanAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.busanCnt}</td>
                        <td className="text-end">
                          {el.honamAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.honamCnt}</td>
                        <td className="text-end">
                          {el.bunyangAmnt
                            .toFixed(1)
                            ?.toString()
                            ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-end">{el.bunyangCnt}</td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={20}>검색된 결과가 없습니다.</td>
                </tr>
              )}
              {profitList.length !== 0 && (
                <tr className="bg-lightyellow">
                  <td colSpan={2}>합계</td>
                  <td className="text-end">
                    {profitTotal.totalAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.totalCnt}</td>
                  <td className="text-end">
                    {profitTotal.sGolfAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.sGlofCnt}</td>
                  <td className="text-end">
                    {profitTotal.sCondoAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.sCondoCnt}</td>
                  <td className="text-end">
                    {profitTotal.bundangAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.bundangCnt}</td>
                  <td className="text-end">
                    {profitTotal.daejunAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.daejunCnt}</td>
                  <td className="text-end">
                    {profitTotal.daeguAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.daeguCnt}</td>
                  <td className="text-end">
                    {profitTotal.busanAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.busanCnt}</td>
                  <td className="text-end">
                    {profitTotal.honamAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.honamCnt}</td>
                  <td className="text-end">
                    {profitTotal.bunyangAmnt
                      .toFixed(1)
                      ?.toString()
                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="text-end">{profitTotal.bunyangCnt}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
