import { useAtom } from "jotai/index";
import {
  profitBottom_atom,
  profitList_atom,
  profitTop_atom,
  searchedParam_atom,
} from "../../../data/atom/goodsManage/weeklyPerformanceAnalysis/weeklyPerformanceAnalysis_atom";
import { useAtomValue } from "jotai";

const modifyListFitCount = (profitList, type) => {
  const modeList =
    profitList.length >= 9
      ? profitList.slice(0, 10)
      : [
          ...profitList,
          ...Array(10 - profitList.length).fill({
            jisaSort: type,
            job: "",
            userName: "",
            authName: "",
          }),
        ];
  return modeList;
};

export const PeriodSales = () => {
  const searchedParam = useAtomValue(searchedParam_atom);
  const tempTop = useAtomValue(profitTop_atom);
  const tempBottom = useAtomValue(profitBottom_atom);

  const tempTopAA = tempTop.filter(el => el.jisaSort === "aa");
  const tempTopBB = tempTop.filter(el => el.jisaSort === "bb");

  const tempBottomAA = tempBottom.filter(el => el.jisaSort === "aa");
  const tempBottomBB = tempBottom.filter(el => el.jisaSort === "bb");

  const resTopA = modifyListFitCount(tempTopAA, "aa");
  const resTopB = modifyListFitCount(tempTopBB, "bb");
  const resBottomA = modifyListFitCount(tempBottomAA, "aa");
  const resBottomB = modifyListFitCount(tempBottomBB, "bb");
  const profitTop = [...resTopA, ...resTopB];
  const profitBottom = [...resBottomA, ...resBottomB];

  return (
    <>
      <div className="card-header">
        <h5 className="card-title m-0">
          ■ 기간 {searchedParam.fromPeriod?.replace(/-/g, ".")}
          {searchedParam.fromPeriod && " ~ "}
          {searchedParam.toPeriod?.replace(/-/g, ".")}
        </h5>
      </div>
      <div className="card-body">
        <div className="row gx-3">
          <div className="col-6">
            <div className="tab1-row1 mb-3">
              <div className="d-flex align-items-center">
                <div className="tab1-row1-count">▲ 기간별 매출 상위</div>
              </div>
            </div>
            <table className="table table-bordered table-responsive my-table m-0">
              <thead>
                <tr>
                  <th style={{ width: "28%" }} colSpan={2}>
                    순위
                  </th>
                  <th>성명</th>
                  <th style={{ width: "20%" }}>실적</th>
                  <th style={{ width: "15%" }}>건수</th>
                </tr>
              </thead>
              <tbody>
                {profitTop.length !== 0 &&
                  profitTop
                    .filter(el => el.jisaSort === "aa")
                    .map((el, idx, arr) => {
                      if (idx === 0) {
                        return (
                          <tr>
                            <td rowSpan={arr.length}>본사 / 분당</td>
                            <td style={{ width: "5%" }}>{el?.rowNumber}</td>
                            <td>{`${el?.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                ?.toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td style={{ width: "5%" }}>{el?.rowNumber}</td>
                            <td>{`${el.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                ?.toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      }
                    })}
                {profitTop.length !== 0 ? (
                  profitTop
                    .filter(el => el.jisaSort === "bb")
                    .map((el, idx, arr) => {
                      if (idx === 0) {
                        return (
                          <tr>
                            <td rowSpan={arr.length}>대전 / 대구 / 부산 / 호남</td>
                            <td style={{ width: "5%" }}>{el?.rowNumber}</td>
                            <td>{`${el?.job} ${el?.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                .toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td style={{ width: "5%" }}>{el?.rowNumber}</td>
                            <td>{`${el?.job} ${el?.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                ?.toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      }
                    })
                ) : (
                  <tr>
                    <td>검색된 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <div className="tab1-row1 mb-3">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="tab1-row1-count">▲ 기간별 매출 하위</div>
                <div className="tab1-row1-field1">
                  <label>입사 6개월 이상</label>
                </div>
              </div>
            </div>
            <table className="table table-bordered table-responsive my-table m-0">
              <thead>
                <tr>
                  <th style={{ width: "28%" }} colSpan={2}>
                    순위
                  </th>
                  <th>성명</th>
                  <th style={{ width: "20%" }}>실적</th>
                  <th style={{ width: "15%" }}>건수</th>
                </tr>
              </thead>
              <tbody>
                {profitBottom.length !== 0 &&
                  profitBottom
                    .filter(el => el.jisaSort === "aa")
                    .map((el, idx, arr) => {
                      if (idx === 0) {
                        return (
                          <tr>
                            <td rowSpan={arr.length}>본사 / 분당</td>
                            <td style={{ width: "5%" }}>{el?.rowNumber}</td>
                            <td>{`${el?.job} ${el?.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                ?.toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td style={{ width: "5%" }}>{el?.rowNumber}</td>

                            <td>{`${el?.job} ${el?.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                ?.toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      }
                    })}
                {profitBottom.length !== 0 ? (
                  profitBottom
                    .filter(el => el.jisaSort === "bb")
                    .map((el, idx, arr) => {
                      if (idx === 0) {
                        return (
                          <tr>
                            <td rowSpan={arr.length}>대전 / 대구 / 부산 / 호남</td>
                            <td style={{ width: "5%" }}>{el?.rowNumber}</td>

                            <td>{`${el?.job} ${el?.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                ?.toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td>{el?.rowNumber}</td>
                            <td>{`${el?.job} ${el?.userName} ${el?.authName}`}</td>
                            <td className="text-end">
                              {el?.rsltAmnt
                                ?.toFixed(1)
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">{el?.exchCnt}</td>
                          </tr>
                        );
                      }
                    })
                ) : (
                  <tr>
                    <td>검색된 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
