import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";

// 세금계산서 발행체크
export default function TaxIssueCheck({
  setIsSameNameOpen,
  setSameNameList,
  setModalIsOpen,
  setDetailRes,
  setMemExchangeResVoList,
  setSelectedCustId,
}) {
  const [searchOption, setSearchOption] = useState({
    diffChk: "N",
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    gijoon: "1",
    jisa: "1",
    keyWord: "",
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    page: 1,
  });
  const [searchData, setSearchData] = useState([]);
  const [showType, setShowType] = useState(1);
  // botoom 탐지
  const [bottom, setBottom] = useState(null);
  const bottomObserver = useRef(null);

  // API - 세금계산서 발행체크 조회
  const checkSearch = useMutation(
    data =>
      axios.get("/api/emp/taxFirstIssue/checkSearch", {
        params: data,
      }),
    {
      onSuccess: (res, payload) => {
        if (searchOption.gijoon === "1") {
          setShowType(1);
        } else {
          setShowType(2);
        }
        if (payload.page === 1) {
          setSearchData(res?.data?.result);
        } else {
          setSearchData([...searchData, ...res?.data?.result]);
        }
      },
    }
  );

  // API - 세금계산서 발행체크에서 상세 (더블클릭시)
  const checkDetail = useMutation(data => axios.post("/api/emp/taxFirstIssue/checkDetail", data), {
    onSuccess: res => {
      setSelectedCustId("");
      const detailValue = res?.data?.result?.Detail;
      if (detailValue) {
        setDetailRes({
          ...detailValue,
          saupjaNo:
            detailValue?.saupjaNo.slice(0, 3) +
            "-" +
            detailValue?.saupjaNo.slice(3, 5) +
            "-" +
            detailValue?.saupjaNo.slice(5),
          gubun0: searchOption.gubun0,
          gubun1: "1",
          gubun2: "1",
          gubun3: "1",
          sinCheck: "S",
          printGubun: "N",
        });
      } else {
        setDetailRes(null);
      }

      if (res?.data?.result?.sameNameList && res?.data?.result?.sameNameList.length > 0) {
        setSameNameList(res?.data?.result?.sameNameList);
        setModalIsOpen(false);
        setIsSameNameOpen(true);
      } else {
        setModalIsOpen(false);
      }

      if (
        res?.data?.result?.memExchangeResVoList &&
        res?.data?.result?.memExchangeResVoList.length > 0
      ) {
        setMemExchangeResVoList(res?.data?.result?.memExchangeResVoList);
        setModalIsOpen(false);
      } else {
        setMemExchangeResVoList([]);
      }
    },
    onError: () => {},
  });

  const pressEnter = e => {
    if (e.key === "Enter") {
      setSearchData([]);
      setSearchOption({ ...searchOption, page: 1 });
      checkSearch.mutate({ ...searchOption, page: 1 });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setSearchOption(prev => {
            checkSearch.mutate({ ...searchOption, page: Number(prev.page) + 1 });

            return { ...searchOption, page: Number(prev.page) + 1 };
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, [searchOption]);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  return (
    <div className="content-inner">
      <div className="modal_float_title">검색</div>
      <div className="filter1_container">
        <div className="filter_fields_container">
          <div className="filter_fields_row" onKeyDown={pressEnter}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="filter1-field filter1-field1">
                <label className="filter1-label">비교기준</label>
                <select
                  className="form-select filter1-select1"
                  value={searchOption.gijoon}
                  onChange={e => setSearchOption({ ...searchOption, gijoon: e.target.value })}
                >
                  <option value="1">거래내역 기준</option>
                  <option value="2">세금계산서 기준</option>
                </select>
              </div>
              <div className="filter1-field filter1-field2">
                <label className="filter1-label">지사구분</label>
                <select
                  className="form-select filter1-select1"
                  value={searchOption.jisa}
                  onChange={e => setSearchOption({ ...searchOption, jisa: e.target.value })}
                >
                  <option value="1">전체</option>
                  <option value="11">서울본사</option>
                  <option value="12">분당지사</option>
                  <option value="13">대전지사</option>
                  <option value="14">대구지사</option>
                  <option value="15">부산지사</option>
                  <option value="18">호남지사</option>
                  <option value="21">동아회원권</option>
                </select>
              </div>
              <div className="filter1-field filter1-field2">
                <label className="filter1-label">거래기간</label>
                <input
                  type="date"
                  max="9999-12-31"
                  className="form-control filter-input filter1-input2"
                  value={searchOption.fromDate}
                  onChange={e => setSearchOption({ ...searchOption, fromDate: e.target.value })}
                />
                <span className="mx-3">~</span>
                <input
                  type="date"
                  min={searchOption.fromDate}
                  max="9999-12-31"
                  className="form-control filter-input filter1-input2"
                  value={searchOption.toDate}
                  onChange={e => setSearchOption({ ...searchOption, toDate: e.target.value })}
                />
              </div>
              <div className="filter1-field filter1-field2 ">
                <label className="filter1-label ">검색어</label>
                <input
                  type="text"
                  className="form-control filter-input filter1-input2"
                  value={searchOption.keyWord}
                  onChange={e => setSearchOption({ ...searchOption, keyWord: e.target.value })}
                />
              </div>
            </div>

            <div>
              <label htmlFor="check" className="d-flex">
                <input
                  type="checkbox"
                  id="check"
                  checked={searchOption.diffChk === "N" ? false : true}
                  onClick={() => {
                    if (searchOption.diffChk === "Y") {
                      setSearchOption(prev => {
                        return { ...prev, diffChk: "N" };
                      });
                      // checkSearch.mutate();
                    } else {
                      setSearchOption(prev => {
                        return { ...prev, diffChk: "Y" };
                      });
                      // checkSearch.mutate();
                    }
                  }}
                />
                <div className="ps-2">세금계산서 미발행건 및 명의 상이자만 보기</div>
              </label>
            </div>
            <div className="filter1-field filter1-field2">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={() => {
                  setSearchData([]);
                  setSearchOption({ ...searchOption, page: 1 });
                  checkSearch.mutate({ ...searchOption, page: 1 });
                }}
              >
                조회
              </button>
            </div>
          </div>
          <div style={{ width: "100%" }}></div>
        </div>
      </div>

      {/* 검색 결과 */}
      <div className="mt-14px">
        <div style={{ maxWidth: "1786px", overflowX: "auto" }}>
          <div className="table-container table-sticky h-663">
            <table className="table table-bordered table-responsive my-table table-fit mt-0">
              <thead>
                {showType === 1 ? (
                  <tr>
                    <th className="border-bottom-th" colSpan={11}>
                      거래내역
                    </th>
                    <th className="border-bottom-th" colSpan={8}>
                      세금계산서 발행내역
                    </th>
                  </tr>
                ) : (
                  <tr>
                    <th className="border-bottom-th" colSpan={9}>
                      세금계산서 발행내역
                    </th>
                    <th className="border-bottom-th" colSpan={10}>
                      거래내역
                    </th>
                  </tr>
                )}
                {showType === 1 ? (
                  <tr>
                    <th style={{ width: "2%" }}>No.</th>
                    <th>지사구분</th>
                    <th>거래분류</th>
                    <th>관리번호</th>
                    <th style={{ width: "3%" }}>성명</th>
                    <th>거래일자</th>
                    <th>종목명</th>
                    <th>거래금액</th>
                    <th>서류금액</th>
                    <th>수수료</th>
                    <th>비고</th>
                    <th style={{ width: "3%" }}>성명</th>
                    <th>계산서일자</th>
                    <th>품목명</th>
                    <th>금액</th>
                    <th>공급가액</th>
                    <th>세액</th>
                    <th>비고</th>
                    <th>기타사항</th>
                  </tr>
                ) : (
                  <tr>
                    <th style={{ width: "2%" }}>No.</th>
                    <th style={{ width: "3%" }}>성명</th>
                    <th>계산서일자</th>
                    <th>품목명</th>
                    <th>금액</th>
                    <th>공급가액</th>
                    <th>세액</th>
                    <th>비고</th>
                    <th>기타사항</th>
                    <th>지사구분</th>
                    <th>거래분류</th>
                    <th>관리번호</th>
                    <th style={{ width: "3%" }}>성명</th>
                    <th>거래일자</th>
                    <th>종목명</th>
                    <th>거래금액</th>
                    <th>서류금액</th>
                    <th>수수료</th>
                    <th>비고</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {searchData.length > 0 ? (
                  searchData.map((data, _idx) => {
                    return (
                      <tr
                        onDoubleClick={() => {
                          checkDetail.mutate(data);
                        }}
                        role="button"
                        className={
                          moment(data?.custDate).format("YYYY-MM-DD") !==
                            moment(data?.ecustDate).format("YYYY-MM-DD") && "bg-red-col"
                        }
                      >
                        <td>{_idx + 1}</td>

                        {showType === 1 ? (
                          <>
                            {/* 거래내역 */}
                            {/* 지사 */}
                            <td>{data.jisaName}</td>
                            {/* 거래분류 */}
                            <td>{data.tradGubnName}</td>
                            {/* 관리번호 */}
                            <td>{data.custId}</td>
                            {/* 성명 */}
                            <td>{data.kname}</td>
                            {/* 거래일자 */}
                            <td>{data.custDate && moment(data.custDate).format("YYYY-MM-DD")}</td>
                            {/* 종목명 */}
                            <td>{data.codename}</td>
                            {/* 거래내역 */}
                            <td className="text-end">
                              {data.custAmnt
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 서류금액 */}
                            <td className="text-end">
                              {data.docuAmnt
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 수수료 */}
                            <td className="text-end">
                              {data.commission
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 비고 */}
                            <td>{data.remark}</td>
                            {/* 세금계산서 발행내역 */}
                            {/* 성명 */}
                            <td className={data?.ekname !== data?.kname && "color-red-col"}>
                              {data.ekname}
                            </td>
                            {/* 계산서일자 */}
                            <td>{data.ecustDate && moment(data.ecustDate).format("YYYY-MM-DD")}</td>
                            {/* 품목명 */}
                            <td>{data.eitem}</td>
                            {/* 금액 */}
                            <td className="text-end">
                              {data.epriceTot
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 공급가액 */}
                            <td className="text-end">
                              {data.epriceAmt
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 세액 */}
                            <td className="text-end">
                              {data.epriceTax
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 비고 */}
                            <td>{data.eremark1}</td>
                            {/* 기타사항 */}
                            <td>{data.eremark2}</td>
                          </>
                        ) : (
                          <>
                            {/* 세금계산서 발행내역 */}
                            {/* 성명 */}
                            <td>{data.ekname}</td>
                            {/* 계산서일자 */}
                            <td>{data.ecustDate && moment(data.ecustDate).format("YYYY-MM-DD")}</td>
                            {/* 품목명 */}
                            <td>{data.eitem}</td>
                            {/* 금액 */}
                            <td className="text-end">
                              {data.epriceTot
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 공급가액 */}
                            <td className="text-end">
                              {data.epriceAmt
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 세액 */}
                            <td className="text-end">
                              {data.epriceTax
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 비고 */}
                            <td>{data.eremark1}</td>
                            {/* 기타사항 */}
                            <td>{data.eremark2}</td>
                            {/* 거래내역 */}
                            {/* 지사 */}
                            <td>{data.jisaName}</td>
                            {/* 거래분류 */}
                            <td>{data.tradGubnName}</td>
                            {/* 관리번호 */}
                            <td>{data.custId}</td>
                            {/* 성명 */}
                            <td>{data.kname}</td>
                            {/* 거래일자 */}
                            <td>{data.custDate && moment(data.custDate).format("YYYY-MM-DD")}</td>
                            {/* 종목명 */}
                            <td>{data.codename}</td>
                            {/* 거래금액 */}
                            <td className="text-end">
                              {data.custAmnt
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 서류금액 */}
                            <td className="text-end">
                              {data.docuAmnt
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 수수료 */}
                            <td className="text-end">
                              {data.commission
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            {/* 비고 */}
                            <td>{data.remark}</td>
                          </>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={20}>조회결과가 없습니다.</td>
                  </tr>
                )}
                {searchData.length > 0 && <div ref={setBottom} style={{ marginBottom: "1px" }} />}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
