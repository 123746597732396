import { useCallback, useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useAtom, useSetAtom } from "jotai/index";
import { isLoadingSpinner_atom } from "../../data/atom/isLoadingSpinner_atom";
import { searchParamCache_atom } from "../../data/atom/useInfiniteScroll_atom";

const useInfiniteScrollWrapper = props => {
  const { fetch, fetchKey, searchParam, resultSetter } = props;
  const [paramCache, setParamCache] = useAtom(searchParamCache_atom);
  const [infinityScroll, setInfinityScroll] = useState(null);
  const [afterFetch, setAfterFetch] = useState(false);
  const setIsLoading = useSetAtom(isLoadingSpinner_atom);

  const fetchPage = async ({ pageParam = 1 }) => {
    if (!fetchKey || !searchParam || !resultSetter || !fetch)
      throw new Error("useInfiniteScrollWrapper doesn't have props");
    let paramData;
    if (pageParam === 1) paramData = { ...searchParam };
    else paramData = { ...paramCache[fetchKey], page: pageParam };
    setParamCache(prev => ({ ...prev, [fetchKey]: paramData }));
    setIsLoading(prev => {
      prev.push(true);
      return [...prev];
    });
    let res;
    try {
      res = await fetch(paramData);
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(prev => {
        prev.pop();
        return [...prev];
      });
    }
    if (pageParam === 1) setAfterFetch(true);
    return { result: res, page: pageParam };
  };

  const transactionSearch = useInfiniteQuery(fetchKey, fetchPage, {
    onSuccess: res => {
      resultSetter(() => {
        let tmp = [];
        res.pages
          .map(page => page.result.map(i => i))
          .map(arr => {
            tmp = tmp.concat(arr);
          });
        return tmp;
      });
    },
    getNextPageParam: res => (res.result.length > 0 ? res.page + 1 : undefined),
    enabled: false,
  });

  // 무한 스크롤 이벤트
  useEffect(() => {
    if (infinityScroll && transactionSearch.hasNextPage) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            transactionSearch.fetchNextPage();
            setAfterFetch(false);
          }
        },
        { threshold: 1 }
      );

      observer.observe(infinityScroll);
    }
  }, [infinityScroll]);

  const refetchFromFirstPage = useCallback(p => {
    transactionSearch.remove();
    transactionSearch.refetch();
  }, []);

  const refetchAllPage = () => {
    transactionSearch.refetch();
  };

  return {
    endRef: setInfinityScroll,
    refetchFromFirstPage: refetchFromFirstPage,
    afterFetch: afterFetch,
    refetchAllPage: refetchAllPage,
  };
};

export default useInfiniteScrollWrapper;
