import { atom } from "jotai";
import { decrypt } from "../../../../util/decrypt/decrypt";

export const envelopeLabelPrinting_searchDataInit_atom = {
  condition: "1",
  gubun: "",
  jisa: "",
  keyword: "",
  my: true,
  page: 1,
};

export const envelopeLabelPrinting_searchData_atom = atom(
  envelopeLabelPrinting_searchDataInit_atom
);

export const envelopeLabelPrinting_tableLimit_atom = atom("5");

export const envelopeLabelPrinting_searchedTableList_atom = atom([]);

export const envelopeLabelPrinting_mainDataInit_atom = {
  company: "",
  custId: "0",
  devision: "",
  gubun: "",
  jisa: "",
  jisaTax: "",
  kname: "",
  partner: "",
  position: "",
  postName: "",
  printGubun: "",
  rcAddres: "",
  rcAddresTxt: "",
  rcAreaCode: "",
  rcBdcode: "",
  rcZipcode: "",
  remark: "",
  rhAddres: "",
  rhAddresTxt: "",
  rhAreaCode: "",
  rhBdcode: "",
  rhZipcode: "",
  sendGubun: "",
  seqNo: "",
  userName: decrypt("userInfo")?.userName,
  userid: decrypt("userInfo")?.loginId,
};

export const envelopeLabelPrinting_mainData_atom = atom(envelopeLabelPrinting_mainDataInit_atom);

export const envelopeLabelPrinting_subData_atom = atom([]);

export const envelopeLabelPrinting_subDeleteList_atom = atom([]);
