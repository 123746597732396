import { atom } from "jotai";
import moment from "moment";

export const depositWithDrawTable_searchOption = atom({
  gubun: "11",
  yearInfo: moment(new Date()).format("YYYY"),
});

export const depositWithDrawTable_searchData = atom([]);
export const depositWithDrawTable_tab = atom("1");
export const depositWithDrawTable_year = atom("");
