import React from "react";
import { useAtom } from "jotai/index";
import { carDetail_atom } from "../../../../../data/atom/dataManage/carManage/driveInput_atom";
import moment from "moment/moment";

const DriveInputListRow = props => {
  const { data, index, drveUserList } = props;
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);

  const moveGubnOption = [
    { value: "1", label: "업무" },
    { value: "9", label: "출퇴근" },
  ];

  const clickRow = (el, idx) => {
    setCarDetail({ ...el, selectIdx: idx });
  };

  return (
    <tr className="cursor-pointer" onClick={() => clickRow(data, index)}>
      <td>{index + 1}</td>
      <td>{data.carrNumb}</td>
      <td>{moment(data.drveStat).format("YYYY-MM-DD HH:mm")}</td>
      <td>{moment(data.drveEndd).format("YYYY-MM-DD HH:mm")}</td>
      <td>{data.drveUser ? drveUserList.find(el => el.value === data.drveUser)?.label : ""}</td>
      <td>{data.moveGubn ? moveGubnOption.find(el => el.value === data.moveGubn).label : ""}</td>
      <td className="text-start">{data.drveTagt}</td>
      <td className="text-start">{data.statSite}</td>
      <td className="text-start">{data.arrvSite}</td>
      <td className="text-end">{data.statMile.toLocaleString()}</td>
      <td className="text-end">{data.enddMile.toLocaleString()}</td>
      <td className="text-end">{data.drveMile.toLocaleString()}</td>
      <td className="text-end">{data.gateCost.toLocaleString()}</td>
      <td className="text-end">{data.passCost.toLocaleString()}</td>
      <td className="text-end">{data.charAmnt.toLocaleString()}</td>
      <td className="text-end">{data.highPass.toLocaleString()}</td>
      <td className="text-start">{data.remark}</td>
    </tr>
  );
};

export default React.memo(DriveInputListRow);
