import moment from "moment/moment";
import { atom } from "jotai";

export const searchParamInit = {
  fromDate: moment("2012-01-01").format("YYYY-MM-DD"),
  toDate: moment("2012-01-31").format("YYYY-MM-DD"),
  fromPeriod: moment("2012-01-01").format("YYYY-MM-DD"),
  toPeriod: moment("2012-01-31").format("YYYY-MM-DD"),
  // fromPeriod: "",
  // toPeriod: "",
};

export const searchParam_atom = atom({ ...searchParamInit });
export const searchedParam_atom = atom({ flag: false });
export const profitTotal_atom = atom({});
export const profitList_atom = atom([]);
export const profitTop_atom = atom([]);
export const profitBottom_atom = atom([]);
