import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  PersonalRecordTab_backupData,
  PersonalRecordTab_modifyData,
} from "../../data/atom/personManage/personMasterManage/PersonalRecordTab_atom";

export default function usePersonMasterTabInitialize() {
  const [recordModify, setRecordModify] = useAtom(PersonalRecordTab_modifyData);
  const [recordBackup, setRecordBackup] = useAtom(PersonalRecordTab_backupData);

  const initialize = () => {
    // 신상기록
    setRecordModify({
      area: "",
      armyClass: "",
      bloodType: "",
      classify: "",
      color: "",
      endClass: "",
      endDate: "",
      etcAccount: "",
      etcBank: "",
      etcName: "",
      etcRemark: "",
      eyeL: "",
      eyeR: "",
      height: 0,
      hobby: "",
      modDate: "",
      modId: "",
      partClass: "",
      payAccount: "",
      payBank: "",
      payName: "",
      rankClass: "",
      religion: "",
      sawonId: "",
      special: "",
      startDate: "",
      userid: "",
      weight: 0,
    });
    setRecordBackup([]);
  };
  return { initialize };
}
