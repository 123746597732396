import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SidebarData = ({ func_recentTap, data }) => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const pathName = location.pathname.split("/")[4];
  const pathName2 = location.pathname.split("/")[5];

  const btn_onClick = (name, url) => {
    func_recentTap({
      name: name,
      url: url,
    });
  };

  return (
    <div className="sidebar-wrapper">
      <ul>
        {data?.sub.reduce((acc, cur) => {
          if (cur.sub.length !== 0) {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 2] && "active"
                }
              >
                <div onClick={() => btn_onClick(cur.sub[0].pageName, cur.url)}>
                  {cur.pageName} <span></span>
                </div>

                <ul className="sidebar_subList">
                  {cur.sub.map(el => (
                    <li
                      className={
                        pathName2 === el.url.split("/")[el.url.split("/").length - 1] && "active"
                      }
                      onClick={() => btn_onClick(el.pageName, el.url)}
                    >
                      {el.pageName}
                    </li>
                  ))}
                </ul>
              </li>,
            ];
          } else {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 1] && "active"
                }
                onClick={() => btn_onClick(cur.pageName, cur.url)}
              >
                {cur.pageName}
              </li>,
            ];
          }

          return acc;
        }, [])}
      </ul>
    </div>
  );

  // return (
  //   <div className="sidebar-wrapper">
  //     <ul>
  //       <li
  //         className={pathName === "acquisitionManage" && "active"}
  //         onClick={() =>
  //           btn_onClick("취득과표/전환유무 관리", "/data/acquisition/acquisitionManage")
  //         }
  //       >
  //         취득과표/전환유무 관리
  //       </li>
  //       <li
  //         className={pathName === "clientManage" && "active"}
  //         onClick={() => btn_onClick("거래처 관리", "/data/supplier/clientManage")}
  //       >
  //         거래처 관리
  //       </li>
  //       <li className={pathName === "golfMembershipPriceManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick(
  //               "개인회원권 시세",
  //               "/data/individual/golfMembershipPriceManage/individualMembershipPrice"
  //             )
  //           }
  //         >
  //           골프회원권 시세 관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "individualMembershipPrice" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "개인회원권 시세",
  //                 "/data/individual/golfMembershipPriceManage/individualMembershipPrice"
  //               )
  //             }
  //           >
  //             개인회원권 시세
  //           </li>
  //           <li
  //             className={pathName2 === "corporateMembershipPrice" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "법인회원권 시세",
  //                 "/data/company/golfMembershipPriceManage/corporateMembershipPrice"
  //               )
  //             }
  //           >
  //             법인회원권 시세
  //           </li>
  //         </ul>
  //       </li>
  //       <li
  //         className={pathName === "golfPrice" && "active"}
  //         onClick={() => btn_onClick("골프시세(각종조회-그래프)", "/data/golfGraph/golfPrice")}
  //       >
  //         골프시세(각종조회-그래프용)
  //       </li>
  //       <li
  //         className={pathName === "analysisTradingItemPeriod" && "active"}
  //         onClick={() =>
  //           btn_onClick("기간별 거래종목 분석", "/data/golfMember/analysisTradingItemPeriod")
  //         }
  //       >
  //         기간별 거래종목 분석
  //       </li>
  //       <li
  //         className={pathName === "condoPrice" && "active"}
  //         onClick={() => btn_onClick("콘도 회원권시세 관리", "/data/condoMember/condoPrice")}
  //       >
  //         콘도 회원권시세 관리
  //       </li>
  //       <li
  //         className={pathName === "sportsPrice" && "active"}
  //         onClick={() => btn_onClick("스포츠 회원권 시세 관리", "/data/sportMember/sportsPrice")}
  //       >
  //         스포츠 회원권시세 관리
  //       </li>
  //       <li
  //         className={pathName === "condoPriceInquiry" && "active"}
  //         onClick={() => btn_onClick("콘도 회원권시세 조회", "/data/condoGraph/condoPriceInquiry")}
  //       >
  //         콘도 회원권시세 조회
  //       </li>
  //       <li
  //         className={pathName === "membershipItem" && "active"}
  //         onClick={() => btn_onClick("회원권 종목 관리", "/data/memberManage/membershipItem")}
  //       >
  //         회원권 종목 관리
  //       </li>
  //       <li className={pathName === "cardManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick("법인카드 사용내역 등록", "/data/card/cardManage/cardInquiry")
  //           }
  //         >
  //           법인카드 사용등록 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "cardInquiry" && "active"}
  //             onClick={() =>
  //               btn_onClick("법인카드 사용내역 등록", "/data/card/cardManage/cardInquiry")
  //             }
  //           >
  //             법인카드 사용내역 등록
  //           </li>
  //           <li
  //             className={pathName2 === "cardMaster" && "active"}
  //             onClick={() =>
  //               btn_onClick("법인카드 관리자용", "/data/cardMaster/cardManage/cardMaster")
  //             }
  //           >
  //             법인카드 관리자용
  //           </li>
  //           <li
  //             className={pathName2 === "cardManager" && "active"}
  //             onClick={() =>
  //               btn_onClick("법인카드 관리", "/data/cardManage/cardManage/cardManager")
  //             }
  //           >
  //             법인카드 관리
  //           </li>
  //         </ul>
  //       </li>
  //       <li
  //         className={pathName === "quotationManagement" && "active"}
  //         onClick={() => btn_onClick("견적서 관리", "/data/estimate/quotationManagement")}
  //       >
  //         견적서 관리
  //       </li>
  //       <li
  //         className={pathName === "transactionProgress" && "active"}
  //         onClick={() =>
  //           btn_onClick("거래진행 내역", "/data/transactionManage/transactionProgress")
  //         }
  //       >
  //         거래진행 내역
  //       </li>
  //       <li
  //         className={pathName === "membershipTransactionAmount" && "active"}
  //         onClick={() =>
  //           btn_onClick("회원권 거래대금 입/출금", "/data/memberBank/membershipTransactionAmount")
  //         }
  //       >
  //         회원권 거래대금 입/출금
  //       </li>
  //       <li className={pathName === "carManage" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick("차량관리 마스터(접근제한)", "/data/carMaster/carManage/car")
  //           }
  //         >
  //           차량관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "car" && "active"}
  //             onClick={() =>
  //               btn_onClick("차량관리 마스터(접근제한)", "/data/carMaster/carManage/car")
  //             }
  //           >
  //             차량관리 마스터(접근제한)
  //           </li>
  //           <li
  //             className={pathName2 === "driveInput" && "active"}
  //             onClick={() =>
  //               btn_onClick("운행 입력전용", "/data/carOilManage/carManage/driveInput")
  //             }
  //           >
  //             운행 입력전용
  //           </li>
  //           <li
  //             className={pathName2 === "fuelInput" && "active"}
  //             onClick={() =>
  //               btn_onClick("주유 입력전용", "/data/carDriveManage/carManage/fuelInput")
  //             }
  //           >
  //             주유 입력전용
  //           </li>
  //         </ul>
  //       </li>
  //       <li
  //         className={pathName === "manageMonthlyText" && "active"}
  //         onClick={() => btn_onClick("문자할당 관리", "/data/smsSendManage/manageMonthlyText")}
  //       >
  //         문자할당 관리
  //       </li>
  //     </ul>
  //   </div>
  // );
};
