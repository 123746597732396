import { atom } from "jotai";
import moment from "moment";

export const reportOutput_searchOption = atom({
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  jisaGubun: "999",
  satSun: "Y",
  toDate: moment(new Date()).format("YYYY-MM-DD"),
  page: 1,
});

export const reportOutput_searchData = atom([]);
export const reportOutpu_row = atom("5");
export const reportOutput_renderData = atom([]);
export const reportOutput_searchedDate = atom({
  fromDate: "",
  toDate: "",
});
