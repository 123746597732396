import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import TableRowGM from "./tableRow/tableRowGM";
import TableRowGC from "./tableRow/tableRowGC";
import TableRowCM from "./tableRow/tableRowCM";
import TableRowSM from "./tableRow/tableRowSM";
import { useAtom, useSetAtom } from "jotai/index";
import {
  editedRowList_atom,
  golfOption_atom,
  membershipItemList_atom,
  searchTypeFlag_atom,
} from "../../../data/atom/dataManage/membershipItem/membershipItem_atom";

const searchResultRow = props => {
  const { data, refetchFromFirstPage } = props;

  const [golfOption, setGolfOption] = useAtom(golfOption_atom);

  const [searchTypeFlag, setSearchTypeFlag] = useAtom(searchTypeFlag_atom);
  const setMembershipItemList = useSetAtom(membershipItemList_atom);
  const [editedRowList, setEditedRowList] = useAtom(editedRowList_atom);
  const [currentData, setCurrentData] = useState({});

  const deleteMembershipItemRow = useMutation(
    data => axios.post("/api/data/memberManage/deleteETCCODEGM", data),
    {
      onSuccess: res => {
        refetchFromFirstPage();
      },
    }
  );

  useEffect(() => {
    if (editedRowList.length === 0) {
      setCurrentData({
        ...data,
        value1: data.value1,
        gcCode: data.gcCode,
        gubun1: data.gubun1,
        membershipType: data.membershipType,
      });
    } else {
      const list = editedRowList.filter(el => el.idx === data.idx);
      if (list.length !== 0) {
        setCurrentData({
          ...list[0],
          value1: data.value1,
          gcCode: data.gcCode,
          gubun1: data.gubun1,
          membershipType: data.membershipType,
        });
      } else {
        setCurrentData({
          ...data,
          value1: data.value1,
          gcCode: data.gcCode,
          gubun1: data.gubun1,
          membershipType: data.membershipType,
        });
      }
    }
  }, [data]);

  const onChangeRowEventHandler = useCallback((key, value) => {
    setCurrentData(pre => {
      let newData;
      if ((searchTypeFlag === "1" || searchTypeFlag === "5") && key === "value1") {
        newData = {
          ...pre,
          [key]: value,
          remark: golfOption?.find(el => el.value === value)?.remark,
        };
      } else {
        newData = { ...pre, [key]: value };
      }

      setEditedRowList(prev => {
        let flagIdx = -1;
        prev = prev.map((el, idx) => {
          if (el.idx === newData.idx) {
            flagIdx = idx;
            return { ...newData };
          } else return el;
        });
        if (flagIdx === -1) prev.push({ ...newData });

        return prev;
      });

      return newData;
    });
  }, []);

  const btnDeleteRow = useCallback(deleteEl => {
    if (deleteEl.newFlag) {
      setMembershipItemList(prev => [...prev].filter(el => !(el.idx === deleteEl.idx)));
      setEditedRowList(prev => [...prev].filter(el => !(el.idx === deleteEl.idx)));
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteMembershipItemRow.mutate([deleteEl]);
      }
    }
  }, []);

  return (
    <>
      {searchTypeFlag === "1" || searchTypeFlag === "5" ? (
        <TableRowGM
          currentData={currentData}
          onChangeRowEventHandler={onChangeRowEventHandler}
          btnDeleteRow={btnDeleteRow}
        />
      ) : searchTypeFlag === "2" ? (
        <TableRowGC
          currentData={currentData}
          onChangeRowEventHandler={onChangeRowEventHandler}
          btnDeleteRow={btnDeleteRow}
        />
      ) : searchTypeFlag === "3" || searchTypeFlag === "6" ? (
        <TableRowCM
          currentData={currentData}
          onChangeRowEventHandler={onChangeRowEventHandler}
          btnDeleteRow={btnDeleteRow}
        />
      ) : searchTypeFlag === "4" || searchTypeFlag === "7" ? (
        <TableRowSM
          currentData={currentData}
          onChangeRowEventHandler={onChangeRowEventHandler}
          btnDeleteRow={btnDeleteRow}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(searchResultRow);
