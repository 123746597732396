import React, { useEffect, useState } from "react";
import {
  condoGraphList_atom,
  condoGraphListHeight_atom,
} from "../../../data/atom/dataManage/condoPriceInquiry/condoPriceInquiry_atom";
import { useAtom, useAtomValue } from "jotai";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { fileDownload } from "../../../util/fileDownload/fileDownload";
import moment from "moment/moment";

const searchResultTable = () => {
  const condoGraphList = useAtomValue(condoGraphList_atom);
  const [dateWithDayList, setDateWithDayList] = useState([]);
  const [codeNameList, setCodeNameList] = useState([]);

  useEffect(() => {
    createDataWithDayList();
    createCodeNameList();
  }, [condoGraphList]);

  const excelCondoGraph = useMutation(
    data =>
      axios.post("/api/data/condoGraph/downloadExcel", data, {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        fileDownload(res.data, `콘도_회원권_시세_${moment().format("YY_MM_DD_hh_mm")}.xlsx`);
      },
    }
  );

  const btnExcel = () => {
    if (condoGraphList.length === 0) {
      alert("검색된 결과가 없습니다.");
      return;
    }
    excelCondoGraph.mutate(condoGraphList);
  };

  const createDataWithDayList = () => {
    const tmpSet = new Set();
    condoGraphList.map(el => {
      tmpSet.add(el.dateWithDay);
    });

    const tmpList = [...tmpSet];
    setDateWithDayList(tmpList.sort());
  };

  const createCodeNameList = () => {
    const tmpSet = new Set();
    condoGraphList.map(el => {
      tmpSet.add(el.codeName);
    });

    const tmpList = [...tmpSet];
    setCodeNameList(tmpList);
  };

  return (
    <>
      <div className="card card-sub mt-3">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="tab1-row1-count">
              검색 결과 :{" "}
              <b style={{ color: "#0b4da2" }}>
                {condoGraphList?.length ? condoGraphList?.length : 0}
              </b>
              {""}건
            </div>
            <div className="flex-center-center">
              <button
                className="btn btn-outline-primary"
                onClick={btnExcel}
                style={{ minWidth: "120px", width: "120px" }}
              >
                시세엑셀저장
              </button>
            </div>
          </div>

          <div className={`table-container table-sticky mt-2`}>
            <table className="table table-bordered table-responsive my-table mt-0">
              <thead>
                <tr>
                  <th style={{ minWidth: "200px" }}>구분</th>
                  {dateWithDayList.length !== 0 &&
                    dateWithDayList.map(el => {
                      return (
                        <th style={{ minWidth: "130px" }}>
                          {el
                            .replace(/\//g, "-")
                            .replace(/Mon/g, "월")
                            .replace(/Tue/g, "화")
                            .replace(/Wed/g, "수")
                            .replace(/Thu/g, "목")
                            .replace(/Fri/g, "금")
                            .replace(/Sat/g, "토")
                            .replace(/Sun/g, "일")}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {condoGraphList.length !== 0 ? (
                  codeNameList.map(codeName => {
                    return (
                      <tr>
                        <td style={{ maxHeight: "30px" }}>{codeName}</td>
                        {dateWithDayList.map(dateWithDay => {
                          const data = condoGraphList.find(
                            el => el.codeName === codeName && el.dateWithDay === dateWithDay
                          );

                          if (data) {
                            return (
                              <td
                                style={{ maxHeight: "30px", minHeight: "30px", textAlign: "right" }}
                              >
                                {Number(data.sise).toLocaleString()}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                style={{ maxHeight: "30px", minHeight: "30px", textAlign: "right" }}
                              >
                                -
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>검색된 결과가 없습니다</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(searchResultTable);
