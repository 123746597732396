import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 발신번호 조회
 */
export const useSelectSender = () => {
  return useQuery(
    ["selectSender"],
    async () =>
      await axios
        .get("/api/codeMng/select/selectSender")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
