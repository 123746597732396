import { useGetDaesangJisa } from "../../../../api/code/useGetDaesangJisa";
import SearchPanel from "./SearchPanel/SearchPanel";
import SearchResultTable from "./SearchResultTable/SearchResultTable";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import LeftInfoTable from "./LeftInfoTable/LeftInfoTable";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { useLocation } from "react-router-dom";
import {
  docImgArr_atom,
  documentManageClickedRow_atom,
  documentManageEtccodeDw_atom,
  documentManageSearchParam_atom,
  documentManageSearchResult_atom,
  previewImgModal_atom,
  previewImgModalInit,
  SearchParamInit,
} from "../../../../data/atom/workManage/transactionRegisterManage/documentManage_atom";
import { useAtom, useSetAtom } from "jotai/index";
import useInfiniteScrollWrapper from "../../../../util/infiniteScroll/useInfiniteScrollWrapper";
import RightDocPanel from "./RightDocPanel/RightDocPanel";
import { useGetEtcCpQ } from "../../../../api/code/useGetEtcCpQ";
import moment from "moment/moment";
import useWindowPrint from "../../../../util/useWindowPrint";

const TransactionRegisterManageDocumentManage = () => {
  const location = useLocation();
  const [searchParam, setSearchParam] = useAtom(documentManageSearchParam_atom);
  const [etccodeDw, setEtccodeDw] = useAtom(documentManageEtccodeDw_atom);
  const setSearchResult = useSetAtom(documentManageSearchResult_atom);
  const [clickedRow, setClickedRow] = useAtom(documentManageClickedRow_atom);
  const [docBmpArray, setDocBmpArray] = useAtom(docImgArr_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const daesangJisa = useGetDaesangJisa().data;
  useGetUserIdQ();
  useGetEtcCpQ();

  const [myDetailData, setMyDetailData] = useState(null);
  const [previewImgModal, setPreviewImgModal] = useAtom(previewImgModal_atom);

  const handlePrint = useWindowPrint();

  // 무한스크롤 API
  const fetchFirstPage = async param => {
    let res = await axios.get("/api/work/traderDoc/search", { params: param });
    return res.data.result.list;
  };

  const { endRef, refetchFromFirstPage, afterFetch } = useInfiniteScrollWrapper({
    fetch: fetchFirstPage,
    fetchKey: ["traderDocSearch"],
    searchParam: searchParam,
    resultSetter: setSearchResult,
  });

  const getEtccodeDwAPI = useMutation(
    data =>
      axios.get("/api/codeMng/select/getEtccodeDw", {
        params: {
          type: data,
        },
      }),
    {
      onSuccess: (res, key) => {
        setEtccodeDw(prev => ({ ...prev, [key]: res.data.result }));
      },
    }
  );

  // 좌측 테이블 상세정보
  const getDetail = useMutation(
    data =>
      axios.get("/api/work/traderDoc/getDetail", {
        params: {
          jisa: data.jisa,
          seqNo: data.seqNo,
          custDate: data.custDate && moment(data.custDate).format("YYYY-MM-DD"),
          custId: data.viewCustId,
          ilNo: data.ilNo,
          year: searchParam.year,
        },
      }),
    {
      onSuccess: res => {
        setMyDetailData(res.data.result);
      },
    }
  );

  // 삭제처리
  const traderDocDelete = useMutation(
    data =>
      axios.post("/api/work/traderDoc/delete", {
        jisa: data.jisa,
        seqNo: data.seqNo,
      }),
    {
      onSuccess: res => {},
    }
  );

  // 삭제복구
  const traderDocRestore = useMutation(
    data =>
      axios.post("/api/work/traderDoc/restore", {
        jisa: data.jisa,
        seqNo: data.seqNo,
      }),
    {
      onSuccess: res => {
        alert("복구가 완료되었습니다.");
      },
    }
  );

  useEffect(() => {
    if (clickedRow) {
      getDetail.mutate({
        ...clickedRow,
        jisa: daesangJisa?.find(
          i => clickedRow.jisaName === i.value.replace("지사", "").replace("본사", "")
        ).key,
      });
    }
  }, [clickedRow]);

  useEffect(() => {
    if (etccodeDw["AA"] === undefined) getEtccodeDwAPI.mutate("AA");
    if (etccodeDw["GM"] === undefined) getEtccodeDwAPI.mutate("GM");
    if (etccodeDw["CM"] === undefined) getEtccodeDwAPI.mutate("CM");
    if (etccodeDw["SM"] === undefined) getEtccodeDwAPI.mutate("SM");
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...SearchParamInit });
      setSearchResult([]);
      setClickedRow(null);
      setDocBmpArray(Array(25).fill(""));
      setPreviewImgModal({ ...previewImgModalInit });
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">고객검색</div>
          </div>
          <SearchPanel refetchFromFirstPage={refetchFromFirstPage} etccodeDw={etccodeDw} />
        </div>

        <div>
          <div className="table-container">
            <SearchResultTable endRef={endRef} afterFetch={afterFetch} />

            {myDetailData?.seqNo && (
              <div className="row mt-3">
                <div className="col-6"></div>
                <div className="col-6">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-check me-5 pe-5">
                      <input
                        id="flexCheckDefault"
                        className="form-check-input"
                        type="checkbox"
                        checked={searchParam.autoPreview}
                        onChange={e =>
                          setSearchParam(prev => ({ ...prev, autoPreview: !prev.autoPreview }))
                        }
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        보존이미지 자동보기
                      </label>
                    </div>
                    <div className="d-flex">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => {
                          let docs = docBmpArray.filter(i => i !== "");
                          if (docs.length === 0) {
                            alert("출력할 이미지가 없습니다.");
                            return;
                          }
                          handlePrint(docs);
                        }}
                      >
                        전체출력
                      </button>
                      <button
                        className="btn btn-outline-primary m-0x ms-8px"
                        onClick={() => {
                          if (previewImgModal.index !== -1) {
                            let letter = String.fromCharCode(
                              "A".charCodeAt(0) + previewImgModal.index
                            );
                            if (myDetailData["check" + letter] === "Y")
                              setPreviewImgModal(prev => ({
                                ...prev,
                                isOpen: true,
                                data: docBmpArray[previewImgModal.index],
                              }));
                          }
                        }}
                      >
                        미리보기
                      </button>
                      <button
                        className="btn btn-outline-primary m-0x ms-8px"
                        onClick={() => {
                          if (myDetailData && confirm("복구하시겠습니까?"))
                            traderDocRestore.mutate(myDetailData);
                        }}
                      >
                        삭제복구
                      </button>
                      <button
                        className="btn btn-outline-danger ms-8px"
                        onClick={() => {
                          if (myDetailData && confirm("삭제하시겠습니까?"))
                            traderDocDelete.mutate(myDetailData);
                        }}
                      >
                        삭제처리
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {myDetailData?.seqNo && (
          <div className="row gx-3">
            <LeftInfoTable myDetailData={myDetailData} />
            <RightDocPanel autoPreview={searchParam.autoPreview} myDetailData={myDetailData} />
          </div>
        )}
      </div>
    </>
  );
};
export default TransactionRegisterManageDocumentManage;
