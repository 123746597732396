import { atom } from "jotai";
import moment from "moment";

export const EmployeeStatus_row = atom("5");
export const EmployeeStatus_empStatusDate = atom({
  searchDate: moment(new Date()).format("YYYY-MM-DD"),
});
export const EmployeeStatus_searchData = atom({
  dept: 1,
  searchDate: moment(new Date()).format("YYYY-MM-DD"),
});
