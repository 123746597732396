import React, { useEffect, useRef } from "react";
import QuotationMain from "./quotationMain";
import QuotationSub from "./quotationSub";
import QuotationMemo from "./quotationMemo";
import moment from "moment";
import { useAtom, useSetAtom } from "jotai/index";
import {
  accountOption_atom,
  clickedRow_atom,
  detailFlag_atom,
  quotationList_atom,
  quotationListLimit_atom,
  quotationMain_atom,
  quotationMainUserOption_atom,
  quotationSubList_atom,
  registerFlag_atom,
  searchParam_atom,
  searchParamInit,
  taxGolfOption_atom,
  userOption_atom,
} from "../../../data/atom/dataManage/quotationManagement/quotationManagement_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useVirtualizer } from "@tanstack/react-virtual";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetBank } from "../../../api/code/useGetBank";
import { decrypt } from "../../../util/decrypt/decrypt";

export const QuotationManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tableRef = useRef(null);
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [detailFlag, setDetailFlag] = useAtom(detailFlag_atom);
  const [registerFlag, setRegisterFlag] = useAtom(registerFlag_atom);
  const [userOption, setUserOption] = useAtom(userOption_atom);
  const [accountOption, setAccountOption] = useAtom(accountOption_atom);
  const [quotationMainUserOption, setQuotationMainUserOption] = useAtom(
    quotationMainUserOption_atom
  );
  const [taxGolfOption, setTaxGolfOption] = useAtom(taxGolfOption_atom);
  const [quotationList, setQuotationList] = useAtom(quotationList_atom);
  const [quotationListLimit, setQuotationListLimit] = useAtom(quotationListLimit_atom);
  const [quotationMain, setQuotationMain] = useAtom(quotationMain_atom);
  const setQuotationSubList = useSetAtom(quotationSubList_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  useGetBank();

  // 검색조건에서 작성자 셀렉트박스 job 검사해서 조정
  useEffect(() => {
    let userInfo = decrypt("userInfo");

    if (!(userInfo.job === "61" || userInfo.job === "62" || userInfo.job === "91"))
      setSearchParam({ ...searchParam, userSelect: userInfo.loginId });
  }, [userOption]);

  const searchUserList = useMutation(
    data => axios.get("/api/codeMng/select/getEstimateUserList", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setUserOption(data);
      },
    }
  );

  const searchAccountList = useMutation(
    data => axios.get("/api/codeMng/select/getEstimateMiddleKind", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setAccountOption(data);
      },
    }
  );

  const searchQuotationDetailUserList = useMutation(
    data => axios.get("/api/data/estimate/middle/partner", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setQuotationMainUserOption(data);
      },
    }
  );

  const searchQuotationList = useMutation(
    data => axios.get("/api/data/estimate/search", { params: data }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;

        if (data.length === 0) {
          if (window.confirm("검색된 결과가 없습니다. 신규등록을 하시겠습니까?")) {
            if (location.state) btnRegister(true);
            else btnRegister(false);
          }
        } else {
          setQuotationList(data);
        }
      },
    }
  );

  const searchQuotationMain = useMutation(
    data => axios.get("/api/data/estimate/getDetail", { params: { estimaNo: data.estimaNo } }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result?.[0];

        if (variables.memo === undefined) {
          setQuotationMain({
            ...data,
            hapAddres: "",
            payAccount: "",
            payName: "",
            scfax: "",
            scompany: "",
            sctel: "",
            spayBank: "",
            ilNo: variables.ilNo,
          });
          if (res.data.result.length !== 0) {
            searchQuotationMainSpartnerChange.mutate({
              ...data,
              readyPaper: "",
              remark: "",
              userId: variables?.spartner,
            });
          }
        } else {
          setQuotationMain({
            ...quotationMain,
            readyPaper: data?.readyPaper,
            remark: data?.remark,
            ilNo: variables.ilNo,
          });
        }
      },
    }
  );

  const searchQuotationMainSpartnerChange = useMutation(
    data =>
      axios.get("/api/data/estimate/middle/partnerDetail", {
        params: { sjisa: data.sjisa, userId: data.userId },
      }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;
        if (data.length === 0) {
          setQuotationMain({
            ...quotationMain,
            hapAddres: "",
            payAccount: "",
            payName: "",
            scfax: "",
            scompany: "",
            sctel: "",
            spayBank: "",
            scellular: "",
            sauthority: "",
          });
        } else {
          setQuotationMain({
            ...quotationMain,
            hapAddres: data?.[0].hapAddres,
            payAccount: data?.[0].payAccount,
            payName: data?.[0].payName,
            scfax: data?.[0].scfax,
            scompany: data?.[0].scompany,
            sctel: data?.[0].sctel,
            spayBank: data?.[0].spayBank,
            scellular: data?.[0].scellular,
            sauthority: data?.[0].sauthority,
          });
        }
      },
      onError: (res, variables) => {
        setQuotationMain({
          ...quotationMain,
          hapAddres: "",
          payAccount: "",
          payName: "",
          scfax: "",
          scompany: "",
          sctel: "",
          spayBank: "",
          scellular: "",
          sauthority: "",
        });
      },
    }
  );

  const searchQuotationSubList = useMutation(
    data => axios.get("/api/data/estimate/middle/getDetail", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;
        const list = data.map(el => {
          return { ...el, update: "Y" };
        });

        setQuotationSubList(list);
        setQuotationList(prev =>
          prev.map(i =>
            i.estimaNo === quotationMain.estimaNo ? { ...i, estimaCnt: list.length } : i
          )
        );
      },
    }
  );

  useEffect(() => {
    if (location.state) {
      setDetailFlag(false);
      setRegisterFlag(false);
      setQuotationMain({});
      setQuotationSubList([]);

      searchQuotationList.mutate({
        keyword: location.state.state.mainData.custId,
        page: 1,
        searchType: "4",
        userSelect: decrypt("userInfo").loginId,
      });
    }

    if (userOption.length === 0) searchUserList.mutate();
    if (accountOption.length === 0) searchAccountList.mutate();
    if (quotationMainUserOption.length === 0) searchQuotationDetailUserList.mutate();
  }, []);

  const rowVirtualizer = useVirtualizer({
    count: quotationList?.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => 30,
  });

  const onChangeEventHandler = (key, value) => {
    if (key === "date" && (value === "" || value === "Invalid date")) {
      return;
    }
    if (key === "quotationListLimit") {
      setQuotationListLimit(Number(value));
    } else if (key === "searchType") {
      setSearchParam({ ...searchParam, [key]: value, keyword: "" });
    } else {
      setSearchParam({ ...searchParam, [key]: value });
    }
  };

  const onClickQuotationListRow = clickedRow => {
    if (registerFlag) {
      alert("등록 작업을 마무리 해주십시오.");
    } else {
      setClickedRow(clickedRow);
      searchQuotationMain.mutate({
        ilNo: clickedRow.ilNo,
        estimaNo: clickedRow.estimaNo,
        spartner: clickedRow.spartner,
        userId: clickedRow.spartner,
      });
      searchQuotationSubList.mutate({ ilNo: clickedRow.ilNo, estimaNo: clickedRow.estimaNo });
      setDetailFlag(true);
    }
  };

  const btnRegister = async flag => {
    const list = await axios.get("/api/data/estimate/middle/partner").then(res => {
      return res.data.result;
    });

    const spartnerData = flag
      ? await axios
          .get("/api/data/estimate/middle/partnerDetail", {
            params: {
              userId: location.state.state.mainData.partner,
              // sjisa: quotationMainUserOption?.find(
              sjisa: list?.find(el => el.spartner === location.state.state.mainData.partner)?.sjisa,
            },
          })
          .then(res => res.data.result)
      : "";

    setDetailFlag(true);
    setRegisterFlag(true);
    setClickedRow({});
    setQuotationMain({
      currDate: flag ? moment().format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), // 견적일자
      estimaNo: flag ? "" : "", // 견적번호
      custId: flag ? location.state.state.mainData.custId : "", // 관리번호(old)
      seqNo: flag ? location.state.state.mainData.seqNo : "", // 관리번호(new)
      kname: flag ? location.state.state.mainData.kname : "", // 성명
      position: flag ? location.state.state.mainData.position : "", // 직위
      company: flag ? location.state.state.mainData.company : "", // 회사명
      ctel: flag ? location.state.state.mainData.ctel : "", // 전화
      cfax: flag ? location.state.state.mainData.cfax : "", // 팩스
      cellular: flag ? location.state.state.mainData.cellular : "", // 휴대폰
      email: flag ? location.state.state.mainData.email : "", // 이메일
      spartner: flag ? location.state.state.mainData.partner : decrypt("userInfo").loginId, // 담당자
      hapAddres: flag ? spartnerData?.[0].hapAddres : "",
      payAccount: flag ? spartnerData?.[0].payAccount : "",
      payName: flag ? spartnerData?.[0].payName : "",
      scfax: flag ? spartnerData?.[0].scfax : "",
      scompany: flag ? spartnerData?.[0].scompany : "",
      sctel: flag ? spartnerData?.[0].sctel : "",
      spayBank: flag ? spartnerData?.[0].spayBank : "",
      etcCall1: "N",
      etcCall2: "N",
      etcCall3: "N",
      readyPaper: "",
      remark: "",
      sauthority: flag ? spartnerData?.[0].sauthority : "",
      scellular: flag ? spartnerData?.[0].scellular : "",
      sjisa: "",
    });
    setQuotationSubList([]);

    navigate(location.pathname, { replace: true });
  };

  const btnSearch = () => {
    if (searchParam.searchType !== "3") {
      searchQuotationList.mutate(searchParam);
    } else {
      searchQuotationList.mutate({
        ...searchParam,
        keyword: moment(searchParam.date).format("YYYY-MM-DD").replace(/-/g, ""),
      });
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...searchParamInit });
      setRegisterFlag(false);
      setDetailFlag(false);
      setUserOption([]);
      setAccountOption([]);
      setQuotationMainUserOption([]);
      setQuotationList([]);
      setQuotationListLimit(5);
      setQuotationMain({});
      setQuotationSubList([]);
      setTaxGolfOption([]);
      setClickedRow({});
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">견적서 관리</div>

            <button
              className="btn btn-primary"
              style={{ marginLeft: "auto" }}
              onClick={() => btnRegister(false)}
              disabled={registerFlag}
            >
              등 록
            </button>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div
                className="d-flex align-items-center justify-content-between"
                onKeyDown={pressEnter}
              >
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => onChangeEventHandler("searchType", e.target.value)}
                  >
                    <option value="1">업체명/성명</option>
                    <option value="2">견적번호</option>
                    <option value="3">견적일자</option>
                    <option value="4">고객관리번호</option>
                    <option value="5">휴대폰</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">검색어</label>
                  {searchParam.searchType !== "3" ? (
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchParam.keyword}
                      onChange={e => onChangeEventHandler("keyword", e.target.value)}
                    />
                  ) : (
                    <input
                      type="date"
                      className="form-control filter-input filter1-input2"
                      value={moment(searchParam.date).format("YYYY-MM-DD")}
                      onChange={e => {
                        onChangeEventHandler("date", e.target.value);
                      }}
                      min={"1900-01-01"}
                      max={"2099-12-31"}
                    />
                  )}
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">작성자</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => onChangeEventHandler("userSelect", e.target.value)}
                    disabled={
                      !(
                        decrypt("userInfo").job === "61" ||
                        decrypt("userInfo").job === "62" ||
                        decrypt("userInfo").job === "91"
                      )
                    }
                    value={searchParam.userSelect}
                  >
                    <option value="00">전체</option>
                    {userOption.length !== 0 ? (
                      userOption.map(el => {
                        return <option value={el.key}>{el.value}</option>;
                      })
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                <div className="txt_daterange">견적 7일후 수정불가</div>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                  onClick={btnSearch}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="containerx">
          <div className="card card-sub">
            <div className="card-body">
              <div className="table-container">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="tab1-row1-count">검색 결과 : {quotationList.length}&nbsp;건</div>
                  <div className="d-flex align-items-center w-50 justify-content-end">
                    <select
                      className="form-select filter-perpage ms-8px h-31"
                      value={quotationListLimit}
                      onChange={e => onChangeEventHandler("quotationListLimit", e.target.value)}
                    >
                      <option value="5">5개씩 보기</option>
                      <option value="10">10개씩 보기</option>
                      <option value="20">20개씩 보기</option>
                    </select>
                  </div>
                </div>
                <div
                  className={`mt-10px table-container table-sticky 
                ${quotationListLimit === 5 && "h-183"}
                ${quotationListLimit === 10 && "h-333"}
                ${quotationListLimit === 20 && "h-633"}`}
                  ref={tableRef}
                  style={{ overflow: "auto" }}
                >
                  <table
                    className={`table table-bordered table-responsive my-table ${
                      quotationList.length === 0 ? "mt-0" : ""
                    }`}
                    style={{
                      marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${
                        rowVirtualizer.getTotalSize() -
                        (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                        rowVirtualizer.getVirtualItems().length * 30
                      }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>연번</th>
                        <th style={{ width: "15%" }}>견적일자</th>
                        <th style={{ width: "15%" }}>견적번호</th>
                        <th style={{ width: "15%" }}>업체명</th>
                        <th style={{ width: "15%" }}>성명</th>
                        <th style={{ width: "15%" }}>작성자</th>
                        <th>건수</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quotationList.length !== 0 ? (
                        rowVirtualizer.getVirtualItems()?.map(el => {
                          return (
                            <tr
                              onClick={() => onClickQuotationListRow(quotationList[el.index])}
                              className={`${
                                clickedRow.ilNo === quotationList[el.index].ilNo &&
                                clickedRow.currDate === quotationList[el.index].currDate &&
                                clickedRow.estimaNo === quotationList[el.index].estimaNo &&
                                "bg-blue"
                              } cursor-pointer`}
                            >
                              <td>{el.index + 1}</td>
                              <td className="text-center">
                                {moment(quotationList[el.index].currDate).format("YYYY-MM-DD")}
                              </td>
                              <td>{`${quotationList[el.index].estimaNo.substring(
                                0,
                                2
                              )}-${quotationList[el.index].estimaNo.substring(
                                2,
                                6
                              )}-${quotationList[el.index].estimaNo.substring(6)}`}</td>
                              <td>{quotationList[el.index].company}</td>
                              <td>{quotationList[el.index].kname}</td>
                              <td>
                                {
                                  userOption?.filter(
                                    el2 => el2.key === quotationList[el.index].spartner
                                  )?.[0]?.value
                                }
                              </td>
                              <td>{quotationList[el.index].estimaCnt}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>검색된 결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {registerFlag ? (
            <>
              <QuotationMain
                searchQuotationList={searchQuotationList}
                searchQuotationMain={searchQuotationMain}
                searchQuotationMainSpartnerChange={searchQuotationMainSpartnerChange}
              />
            </>
          ) : detailFlag ? (
            <>
              <QuotationMain
                searchQuotationList={searchQuotationList}
                searchQuotationMain={searchQuotationMain}
                searchQuotationMainSpartnerChange={searchQuotationMainSpartnerChange}
              />

              <QuotationSub searchQuotationSubList={searchQuotationSubList} />

              <QuotationMemo searchQuotationMain={searchQuotationMain} />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
