import React, { useCallback } from "react";
import moment from "moment/moment";
import { axios } from "../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { clickedRow_atom } from "../../../../data/atom/workManage/golfManage/golfManage_atom";

export default function ModalGreenFeeTableRow(props) {
  const { data, index, getGreenFeeHistory } = props;
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);

  const deleteRow = useMutation(data => axios.post("/api/work/manageGolf/deleteGreenFee", data), {
    onSuccess: res => {
      getGreenFeeHistory.mutate(clickedRow);
    },
  });

  const btnDelete = useCallback(() => {
    if (confirm("삭제하시겠습니까?")) {
      deleteRow.mutate({ ...data, currDate: moment(data.currDate).format("YYYY-MM-DD") });
    }
  }, [data]);

  return (
    <tr style={data.delCheck === "Y" ? { textDecoration: "line-through" } : {}}>
      <td className="text-center">{index + 1}</td>
      <td className="text-center">
        <a onClick={() => btnDelete()} style={data.delCheck === "Y" ? { display: "none" } : {}}>
          <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
        </a>
      </td>
      <td className="text-center">{moment(data.modDate).format("YYYY.MM.DD")}</td>
      <td className="text-center">{Number(data.memberJj).toLocaleString()}</td>
      <td className="text-center">{Number(data.memberJm).toLocaleString()}</td>
      <td className="text-center">{Number(data.familyJj).toLocaleString()}</td>
      <td className="text-center">{Number(data.familyJm).toLocaleString()}</td>
      <td className="text-center">{Number(data.jujungJj).toLocaleString()}</td>
      <td className="text-center">{Number(data.jujungJm).toLocaleString()}</td>
      <td className="text-center">{Number(data.jjFamilyJj).toLocaleString()}</td>
      <td className="text-center">{Number(data.jjFamilyJm).toLocaleString()}</td>
      <td className="text-center">{Number(data.noMemberJj).toLocaleString()}</td>
      <td className="text-center">{Number(data.noMemberJm).toLocaleString()}</td>
      <td className="text-center">{Number(data.woodaeJj).toLocaleString()}</td>
      <td className="text-center">{Number(data.woodaeJm).toLocaleString()}</td>
      <td className="text-center">{Number(data.wooFamilyJj).toLocaleString()}</td>
      <td className="text-center">{Number(data.wooFamilyJm).toLocaleString()}</td>
      <td className="text-center">{data.etcMember}</td>
    </tr>
  );
}

React.memo(ModalGreenFeeTableRow);
