import React, { useEffect } from "react";
import { decrypt } from "../../util/decrypt/decrypt";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";
import { useAtom } from "jotai";
import { stopPopup_atom } from "../../data/atom/initpage_atom";
import Modal from "react-bootstrap/Modal";

export const Initial = () => {
  const [stopPopup, setStopPopup] = useAtom(stopPopup_atom);

  // 통화 알림
  const customerAlertCallBook = useMutation(() => axios.get("/api/work/customer/alertCallBook"));

  // 일정 알림
  const customerAlertSchedule = useMutation(() => axios.get("/api/work/customer/alertSchedule"));

  // 실적 알림
  const initialAlertResult = useQuery(
    ["initialAlertResult"],
    async () =>
      await axios
        .get("/api/work/customer/alertResult")
        .then(res => res.data.result.filter(i => i.confirmYn !== "Y")),
    {
      onSuccess: data => {
        if (data.length > 0) {
          const newWindow = window.open(
            `${window.origin}${process.env.PUBLIC_URL}/initial2`,
            "_blank",
            "left=250,top=700,width=500,height=400"
          );
        }
      },
      enabled: false,
    }
  );

  useEffect(() => {
    const key = "initial";
    const isInitial = sessionStorage.getItem(key);

    if (isInitial) {
      return;
    } else {
      sessionStorage.setItem(key, true);
    }

    // 통화 알림이 있을 경우에만 팝업이 나타나도록
    customerAlertCallBook.mutateAsync().then(res => {
      if (res.data.result.length !== 0) {
        if (decrypt("userInfo") && !stopPopup) {
          const newWindow = window.open(
            `${window.origin}${process.env.PUBLIC_URL}/initial1`,
            "_blank",
            "left=250,top=200,width=500,height=400"
          );
        }
      }
    });

    // 일정 알림이 있을 경우에만 팝업이 나타나도록
    customerAlertSchedule.mutateAsync().then(res => {
      if (res.data.result.length !== 0) {
        if (decrypt("userInfo") && !stopPopup) {
          const newWindow = window.open(
            `${window.origin}${process.env.PUBLIC_URL}/initial3`,
            "_blank",
            "left=650,top=200,width=500,height=400"
          );
        }
      }
    });

    // if (decrypt("userInfo") && !stopPopup) {
    //   const newWindow = window.open(
    //     `${window.origin}${process.env.PUBLIC_URL}/initial1`,
    //     "_blank",
    //     "left=250,top=200,width=500,height=400"
    //   );
    //
    //   const newWindow3 = window.open(
    //     `${window.origin}${process.env.PUBLIC_URL}/initial3`,
    //     "_blank",
    //     "left=650,top=200,width=500,height=400"
    //   );
    //
    //   initialAlertResult.refetch();
    //   setStopPopup(true);
    //   sessionStorage.setItem(key, true);
    // }
  }, []);

  return <></>;
};
