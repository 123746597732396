import React, { useEffect, useState } from "react";
import SearchPanel from "./SearchPanel/SearchPanel";
import SearchResultTable from "./SearchResultTable/SearchResultTable";
import BottomInfo from "./BottomInfo/BottomInfo";
import { useAtom, useSetAtom } from "jotai/index";
import {
  bottomTap_atom,
  clickedRow_atom,
  golfData_atom,
  greenFeeData_atom,
  searchParam_atom,
  searchParamInit,
  searchResult_atom,
  tableFoldState_atom,
  tabStayData_atom,
  tabStayDataInit,
} from "../../../data/atom/workManage/golfManage/golfManage_atom";
import { axios } from "../../../util/axios/axios";
import useInfiniteScrollWrapper from "../../../util/infiniteScroll/useInfiniteScrollWrapper";
import { useGetEtccodeDw } from "../../../api/code/useGetEtccodeDw";
import { useGetBank } from "../../../api/code/useGetBank";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";
import { useGetGosiDayList } from "../../../api/code/useGetGosiDayList";
import { useGetBunyangGubun } from "../../../api/code/useGetBunyangGubun";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fileDownload } from "../../../util/fileDownload/fileDownload";
import moment from "moment";
import { MigrateGolfModal } from "./modal/MigrateGolfModal";

const golfDataInit = {
  custId: "",
  company: "",
  oldCompany: "",
  identiNo: "",
  companyAbout: "",
  holsu: "0",
  holsuP: "0",
  holsuP3: "0",
  memberTotal: "0",
  memberDetail: "",
  corpName: "",
  groupName: "",
  ceoKname: "",
  ceoPosition: "",
  directKname: "",
  directPosition: "",
  directHp: "",
  locaTel: "",
  locaArs: "",
  locaZip: "",
  locaAddres: "",
  locaAddresTxt: "",
  officeTel: "",
  officeZip: "",
  officeAddres: "",
  openDate: "",
  areaTot: "",
  lightHolsu: "0",
  drivingRange: "",
  buildRange: "",
  memberLimit: "",
  ageLimit: "0",
  changeOx: "",
  familyTargetP: "",
  familyRuleP: "",
  familyRemarkP: "",
  familyTargetC: "",
  familyRuleC: "",
  familyRemarkC: "",
  payBank: "99",
  payAccount: "",
  payName: "",
  officialKname: "",
  officialPosition: "",
  officialTel: "",
  officialHp: "",
  officialFax: "",
  officialEMail: "",
  bookingKname: "",
  bookingPosition: "",
  bookingHp: "",
  bookingTel: "",
  bookingFax: "",
  bookingWeb: "Y",
  checkTel: "Y",
  checkArs: "Y",
  checkFax: "Y",
  twoPlay: "Y",
  twoPlayRemark: "",
  closeDay: "",
  penalty: "",
  cancelTel: "",
  cancelAbout: "",
  memberDayYn: "Y",
  memberDay: "",
  approvDay: "",
  caddySystem: "",
  cartSystem: "",
  intervals: "",
  intervalEtc: "",
  gaesuPersonal: "0",
  gaesuJujung: "0",
  gaesuCorp: "0",
  gaesuRename: "0",
  gaesuInherit: "0",
  gaesuDonation: "0",
  gaesuEtc: "",
  gaesuRemark: "",
  siteUrl: "",
  goingHow1: "",
  goingStart1: "",
  goingTime1: "",
  goingHow2: "",
  goingStart2: "",
  goingTime2: "",
  outCheck: "N",
  outDate: "",
  dmCheck: "Y",
  reason: "",
  webViewChk: "Y",
  guideChk: "Y",
  notNameSys: "Y",
  modId: "",
  modDate: "",
  loanCheck: "Y",
  stampDuty: "",
  partner: "",
  shingoTarget: "Y",
  recommandCheck: "N",
  ageLimit2: "",
  gubun1: "",
  addres: "",
  currDate: "",
  memberJj: "",
  memberJm: "",
  familyJj: "",
  familyJm: "",
  jujungJj: "",
  jujungJm: "",
  jjFamilyJj: "",
  jjFamilyJm: "",
  noMemberJj: "",
  noMemberJm: "",
  woodaeJj: "",
  woodaeJm: "",
  wooFamilyJj: "",
  wooFamilyJm: "",
  etcMember: "",
  rzipcode: "",
  rbdcode: "",
  raddres: "",
  raddresTxt: "",
  oroadAddres: "",
  rareaCode: "",
  obdcode: "",
  ozipcode: "",
  oaddres: "",
};

const golfManageMain = () => {
  const { addTabClosedEvent } = useRecentTabEvent();
  const location = useLocation();
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const setSearchResult = useSetAtom(searchResult_atom);
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [golfData, setGolfData] = useAtom(golfData_atom);
  const setBottomTap = useSetAtom(bottomTap_atom);
  const setTabStayData = useSetAtom(tabStayData_atom);
  const setGreenFeeData = useSetAtom(greenFeeData_atom);
  const setTableFoldState = useSetAtom(tableFoldState_atom);

  const queryClient = useQueryClient(); // 쿼리 클라이언트 훅
  const stampDutyOption = queryClient.getQueryData(["getEtccodeDw", "SD"]); // 옵션 데이터
  const bankOption = queryClient.getQueryData(["getBank"]); // 은행 옵션 데이터
  useGetEtccodeDw("SD");
  useGetBank();
  useGetUserIdQ();
  useGetGosiDayList();
  useGetBunyangGubun();

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState([]);

  const excelSave = useMutation(
    () =>
      axios.get("/api/work/manageGolf/greenFeeExcel", {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        fileDownload(res.data, `그린피변동내역_${moment().format("YY_MM_DD_hh_mm")}.xlsx`);
      },
    }
  );

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...searchParamInit });
      setSearchResult([]);
      setClickedRow(null);
      setGolfData(null);
      setBottomTap("특이사항");
      setTabStayData({ ...tabStayDataInit });
      setGreenFeeData({});
      setTableFoldState({ top: false, bottom: false });
    });
  }, []);

  // 조회 API
  const fetchFirstPage = async param => {
    let res = await axios.get("/api/work/manageGolf/search", {
      params: param,
    });
    return res.data.result.list;
  };

  // 웹이관 조회 API
  const selectMigGolf = useMutation(() => axios.get("/api/work/manageGolf/selectMigGolf"), {
    onSuccess: res => {
      setModalData(res.data.result);
      setModalShow(true);
    },
  });

  const { endRef, refetchFromFirstPage, refetchAllPage } = useInfiniteScrollWrapper({
    fetch: fetchFirstPage,
    fetchKey: ["manageGolfSearch"],
    searchParam: searchParam,
    resultSetter: setSearchResult,
  });

  const btn_addNew = () => {
    setGolfData({
      ...golfDataInit,
      stampDuty: stampDutyOption[0].key,
      payBank: bankOption[0].key,
      editing: true,
      registering: true,
    });
  };

  return (
    <>
      <MigrateGolfModal data={modalData} show={modalShow} onHide={() => setModalShow(false)} size={"lg"} />
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">골프장 검색</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() => selectMigGolf.mutate()}
              >
                골프장 웹이관
              </button>
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={excelSave.mutate}
              >
                그린피변동내역 엑셀변환
              </button>
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() =>
                  window.open(
                    window.encodeURI(
                      encodeURI(
                        "https://118.129.121.188/공용자료실/공용자료(전체)/11_본사직원/★★★2017 무기명 회원권★★★"
                      )
                    ),
                    "_blank"
                  )
                }
              >
                무기명자료 바로가기
              </button>
              <button
                className="btn btn-primary h-25px "
                onClick={btn_addNew}
                disabled={golfData?.registering}
              >
                등록
              </button>
            </div>
          </div>
          <SearchPanel fetchFirstPage={refetchFromFirstPage} refetchAllPage={refetchAllPage} />
        </div>

        <div className="containerx">
          <SearchResultTable scrollEndRef={endRef} />
          {(clickedRow || golfData?.custId || golfData?.registering) && (
            <BottomInfo refetchAllPage={refetchAllPage} />
          )}
        </div>
      </div>
    </>
  );
};

export default golfManageMain;
