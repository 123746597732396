import { useGetMemberNm } from "../../api/code/useGetMemberNm";
import { useGetEtcCmQ } from "../../api/code/useGetEtcCmQ";
import { useGetEtcSmQ } from "../../api/code/useGetEtcSmQ";
import { useEffect, useState } from "react";

export default function useGMCMSMSelect() {
  const getMemberNm = useGetMemberNm(); // 골프
  const getEtcCmQ = useGetEtcCmQ(); // 콘도
  const getEtcSmQ = useGetEtcSmQ(); // 헬스

  const [returnArr, setReturnArr] = useState([]);
  const [clazz, setClazz] = useState("GM");

  useEffect(() => {
    let arr = getMemberNm?.data ?? [];
    setReturnArr(arr);
    if (arr.length > 0) setClazz("GM");
  }, []);

  const changeClazz = clazz => {
    setClazz(clazz);
    switch (clazz) {
      case "GM":
        setReturnArr(
          getMemberNm?.data.map(i => ({ ...i, key: i?.code, value: i?.codename })) ?? []
        );
        break;
      case "CM":
        setReturnArr(getEtcCmQ?.data ?? []);
        break;
      case "SM":
        setReturnArr(getEtcSmQ?.data ?? []);
        break;
      default:
        setReturnArr([]);
    }
  };

  return { returnArr, changeClazz, clazz };
}
