import CarMasterListDriveRow from "./carMasterListDriveRow";
import React, { useMemo, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtom } from "jotai/index";
import { carDriveInfo_atom } from "../../../../../data/atom/dataManage/carManage/carMaster_atom";
import { useGetCarMasterMoveGubnList } from "../../../../../api/code/useGetCarMasterMoveGubnList";

const Tab1 = props => {
  const { btnAdd, btnSave, onClickDeleteDrive } = props;
  const [driveInfo, setDriveInfo] = useAtom(carDriveInfo_atom);
  const targetRefDrive = useRef();
  const getCarMasterMoveGubnList = useGetCarMasterMoveGubnList();

  const rowVirtualizerDrive = useVirtualizer({
    count: driveInfo.length,
    getScrollElement: () => targetRefDrive.current,
    estimateSize: () => 30,
  });

  const drveUserList = useMemo(
    () =>
      getCarMasterMoveGubnList.data?.map(el => ({
        value: el.key,
        label: el.value,
      })) ?? [],
    [getCarMasterMoveGubnList.data]
  );

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-end mb-3">
        <button
          className="btn btn-outline-primary w-70px"
          onClick={() => {
            btnAdd("drive");
            targetRefDrive.current.scrollTo(0, 0);
          }}
          disabled={driveInfo.filter(el => el.newCheck).length !== 0}
        >
          추가
        </button>
        <button className="btn btn-outline-primary ms-2 w-70px" onClick={() => btnSave("drive")}>
          저장
        </button>
      </div>
      <div
        className="table-container table-sticky h-633"
        style={{ overflow: "auto" }}
        ref={targetRefDrive}
      >
        <table
          className="table table-bordered table-responsivex my-table"
          style={{
            width: "150vw",
            marginTop: `${(rowVirtualizerDrive.range?.startIndex ?? 0) * 30}px`,
            marginBottom: `${
              rowVirtualizerDrive.getTotalSize() -
              (rowVirtualizerDrive.range?.startIndex ?? 0) * 30 -
              rowVirtualizerDrive.getVirtualItems().length * 30
            }px`,
          }}
        >
          <thead>
            <tr>
              <th rowSpan={2} style={{ width: "3%" }}>
                연번
              </th>
              <th rowSpan={2} style={{ width: "5%" }}>
                차량번호
              </th>
              <th rowSpan={2} style={{ width: "6%" }}>
                운행시작일시
              </th>
              <th rowSpan={2} style={{ width: "6%" }}>
                운행종료일시
              </th>
              <th rowSpan={2} style={{ width: "6%" }}>
                운행자
              </th>
              <th rowSpan={2} style={{ width: "6%" }}>
                운행구분
              </th>
              <th rowSpan={2} style={{ width: "13.86%" }}>
                운행목적
              </th>

              <th rowSpan={2} style={{ width: "4%" }}>
                출발지
              </th>
              <th rowSpan={2} style={{ width: "6%" }}>
                목적지
              </th>
              <th colSpan={2} style={{ width: "8.21%" }}>
                적산거리계
              </th>
              <th rowSpan={2} style={{ width: "5.64%" }}>
                주행거리(km)
              </th>
              <th colSpan={2} style={{ width: "9.95%" }}>
                통행료
              </th>
              <th colSpan={2} style={{ width: "9.95%" }}>
                하이패스
              </th>
              <th rowSpan={2}>비고</th>
              <th rowSpan={2} style={{ width: "1%" }}>
                삭제
              </th>
            </tr>
            <tr>
              <th style={{ width: "4.105%" }}>출발전</th>
              <th>도착후</th>
              <th style={{ width: "4.975%" }}>현 금</th>
              <th>하이패스</th>
              <th style={{ width: "4.975%" }}>충전금액</th>
              <th>잔 액</th>
            </tr>
          </thead>
          <tbody>
            {driveInfo.length !== 0 ? (
              rowVirtualizerDrive.getVirtualItems().map(el => {
                return (
                  <CarMasterListDriveRow
                    data={driveInfo[el.index]}
                    index={el.index}
                    deleteDriveInfo={onClickDeleteDrive}
                    drveUserList={drveUserList}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={18}>검색된 결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(Tab1);
