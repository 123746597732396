import React, { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtom } from "jotai/index";
import { individualPerformance_searchedTableList_atom } from "../../../../data/atom/goodsManage/individualPerformance/individualPerformance_atom";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const One = ({ data }) => {
  const targetRef = useRef(null);

  const [searchedTableList, setSearchedTableList] = useAtom(
    individualPerformance_searchedTableList_atom
  );

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={{
              width: `${
                data.tableColumns?.length !== 0 ? data.tableColumns?.length * 120 + 430 + "px" : ""
              }`,
              // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              // marginBottom: `${
              //   rowVirtualizer.getTotalSize() -
              //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
              //   rowVirtualizer.getVirtualItems().length * 30
              // }px`,
            }}
          >
            <thead>
              <tr>
                <th rowSpan={2} style={{ width: "180px" }}>
                  소속
                </th>
                <th rowSpan={2} style={{ width: "150px" }}>
                  성명
                </th>
                <th rowSpan={2} style={{ width: "100px" }}>
                  직위
                </th>
                {data.tableColumns.length !== 0 &&
                  data.tableColumns
                    .slice(0, data.tableColumns.length - 1)
                    ?.map(el => <th colSpan={2}>{el}</th>)}
                <th colSpan={2}>합계</th>
              </tr>
              <tr>
                {Array.from(
                  { length: data.tableColumns.length !== 0 ? data.tableColumns.length : 1 },
                  () => (
                    <>
                      <th>건수</th>
                      <th>실적</th>
                    </>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {data.tableRows?.length !== 0 ? (
                data.tableRows?.reduce((acc, cur, idx, arr) => {
                  // 소속 합치기
                  if (cur?.jobName !== arr[idx - 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr>
                        <td rowSpan={arr.filter(el => el.jobName === cur.jobName).length}>
                          {cur.jobName}
                        </td>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => {
                          return (
                            <>
                              <td>
                                {data.result[cur.userName]?.[data.tableColumns[idx2]]
                                  ?.exchangeCnt ?? "-"}
                              </td>
                              <td className="text-end">
                                {data.result[cur.userName]?.[
                                  data.tableColumns[idx2]
                                ]?.resultAmnt.toLocaleString() ?? "-"}
                              </td>
                            </>
                          );
                        })}
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => (
                          <>
                            <td>
                              {data.result[cur.userName]?.[data.tableColumns[idx2]]?.exchangeCnt ??
                                "-"}
                            </td>
                            <td className="text-end">
                              {numberFixedFormat(
                                data.result[cur.userName]?.[data.tableColumns[idx2]]?.resultAmnt
                              ) ?? "-"}
                            </td>
                          </>
                        ))}
                      </tr>,
                    ];
                  }

                  // 소속 총계
                  if (cur?.jobName !== arr[idx + 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={3}>{cur.jobName} 계</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => (
                          <>
                            <td>
                              {data.resultSum[cur.role]?.[data.tableColumns[idx2]]?.exchangeCnt ??
                                "-"}
                            </td>
                            <td className="text-end">
                              {numberFixedFormat(
                                data.result[cur.userName]?.[data.tableColumns[idx2]]?.resultAmnt
                              ) ?? "-"}
                            </td>
                          </>
                        ))}
                      </tr>,
                    ];
                  }

                  // 본사 총계
                  if (
                    cur.jobName.slice(-3) === "사업부" &&
                    (arr[idx + 1]?.jobName.slice(-2) === "지사" || arr[idx + 1]?.role == undefined)
                  ) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightblue">
                        <td colSpan={3}>본사 계</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => (
                          <>
                            <td>
                              {data.resultSum["A"]?.[data.tableColumns[idx2]]?.exchangeCnt ?? "-"}
                            </td>
                            <td className="text-end">
                              {numberFixedFormat(
                                data.resultSum["A"]?.[data.tableColumns[idx2]]?.resultAmnt
                              ) ?? "-"}
                            </td>
                          </>
                        ))}
                      </tr>,
                    ];
                  }

                  // 지사 총계
                  if (cur.jobName.slice(-2) === "지사" && arr[idx + 1]?.role == undefined) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightblue">
                        <td colSpan={3}>지사 계</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => (
                          <>
                            <td>
                              {data.resultSum["B"]?.[data.tableColumns[idx2]]?.exchangeCnt ?? "-"}
                            </td>
                            <td className="text-end">
                              {numberFixedFormat(
                                data.resultSum["B"]?.[data.tableColumns[idx2]]?.resultAmnt
                              ) ?? "-"}
                            </td>
                          </>
                        ))}
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td colSpan={3}>총계</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => (
                          <>
                            <td>{data.total[data.tableColumns[idx2]]?.exchangeCnt ?? "-"}</td>
                            <td className="text-end">
                              {numberFixedFormat(data.total[data.tableColumns[idx2]]?.resultAmnt) ??
                                "-"}
                            </td>
                          </>
                        ))}
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={5}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
