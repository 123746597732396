import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const PdfLayout = () => {
    return (
        <>
            <div className="pdf_wrapper">
                <Outlet />
            </div>
        </>
    );
};
