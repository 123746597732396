import React from "react";
import { useAtom, useSetAtom } from "jotai/index";
import { transactionHistoryClickedRow_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { isLoadingSpinner_atom } from "../../../../../data/atom/isLoadingSpinner_atom";
import useTabQueue from "../../../../../components/QueueTab/useTabQueue";

const SearchResultListLeftRow = props => {
  const { data, index } = props;
  const [clickedRow, setClickedRowAtom] = useAtom(transactionHistoryClickedRow_atom);
  const [isLoading] = useAtom(isLoadingSpinner_atom);
  const { hasItem, length } = useTabQueue();

  // 관리번호 포맷
  const custIdFormat = id => {
    return id.substring(0, 4) + "-" + id.substring(4);
  };

  const onClickEvent = () => {
    if (isLoading.length > 0) {
      alert("서버에서 불러오는 중입니다.");
      return;
    } else if (length() >= 10 && hasItem(data.seqNo, data.custId)) {
    } else if (length() >= 10) {
      alert("최대 10개까지만 볼 수 있습니다.");
      return;
    }

    setClickedRowAtom({ ...data });
  };

  return (
    <tr
      onClick={() => onClickEvent()}
      className={`${data.outCheck === "Y" && "bg-red"} ${
        clickedRow?.custId === data?.custId && "bg-blue"
      }`}
      role="button"
    >
      <td>{index + 1}</td>
      <td>{custIdFormat(data.custId)}</td>
      <td>{data.company}</td>
      <td>{data.kname}</td>
      <td>{data.outCheck}</td>
    </tr>
  );
};
export default React.memo(SearchResultListLeftRow);
