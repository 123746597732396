import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import Select from "react-select-virtualized";
import { useAtom } from "jotai/index";
import {
  carDriveInfo_atom,
  carDriveInfoEditedRowList_atom,
} from "../../../../../data/atom/dataManage/carManage/carMaster_atom";

const moveGubnOption = [
  { value: "1", label: "업무" },
  { value: "9", label: "출퇴근" },
];

const CarMasterListDriveRow = props => {
  const { data, index, deleteDriveInfo, drveUserList } = props;
  const [driveInfo, setDriveInfo] = useAtom(carDriveInfo_atom);
  const [editedRowList, setEditedRowList] = useAtom(carDriveInfoEditedRowList_atom);
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  const onChangeEventHandler = (key, value) => {
    if (
      key === "charAmnt" ||
      key === "statMile" ||
      key === "enddMile" ||
      key === "gateCost" ||
      key === "highPass" ||
      key === "passCost"
    ) {
      value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));
    }
    setCurrentData(pre => {
      let newData;
      if (key === "statMile") {
        newData = { ...pre, [key]: value, drveMile: +pre.enddMile - +value };
      } else if (key === "enddMile") {
        newData = { ...pre, [key]: value, drveMile: +value - +pre.statMile };
      } else {
        newData = { ...pre, [key]: value };
      }

      setEditedRowList(prev => {
        let flagIdx = -2;
        prev = prev.map((el, idx) => {
          if (el.idx === newData.idx) {
            if (key === "statMile") {
              flagIdx = idx;
              return { ...newData, drveMile: +pre.enddMile - +value };
            } else if (key === "enddMile") {
              flagIdx = idx;
              return { ...newData, drveMile: +value - +pre.statMile };
            } else {
              flagIdx = idx;
              return { ...newData };
            }
          } else return el;
        });
        if (flagIdx === -2) prev.push({ ...newData });

        return prev;
      });

      return newData;
    });
  };

  const btnDelete = el => {
    if (el.idx === -1) {
      const list = driveInfo.filter(el => el.idx !== -1);
      const listEd = editedRowList.filter(el => el.idx !== -1);
      setDriveInfo(list);
      setEditedRowList(listEd);
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteDriveInfo(el);
      }
    }
  };

  return (
    <tr>
      <td>{index >= 0 ? index + 1 : ""}</td>
      <td>{currentData.carrNumb}</td>
      <td>
        <input
          type="datetime-local"
          className="form-control filter-input h-25px"
          value={moment(currentData.drveStat).format("YYYY-MM-DD HH:mm")}
          onChange={e => onChangeEventHandler("drveStat", e.target.value)}
          style={{ borderColor: "#C5C5C5" }}
          min={"1900-01-01 00:00"}
          max={"2099-12-31 23:59"}
        />
      </td>
      <td>
        <input
          type="datetime-local"
          className="form-control filter-input h-25px"
          value={moment(currentData.drveEndd).format("YYYY-MM-DD HH:mm")}
          onChange={e => onChangeEventHandler("drveEndd", e.target.value)}
          style={{ borderColor: "#C5C5C5" }}
          min={"1900-01-01 00:00"}
          max={"2099-12-31 23:59"}
        />
      </td>
      <td>
        {useMemo(
          () => (
            <Select
              className="select-virtual text-left"
              menuPlacement={"auto"}
              menuPosition={"fixed"}
              styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
              options={drveUserList}
              value={drveUserList?.find(el2 => el2.value === currentData?.drveUser) ?? {}}
              onChange={e => onChangeEventHandler("drveUser", e?.value)}
            />
          ),
          [drveUserList, currentData.drveUser]
        )}
      </td>
      <td>
        {useMemo(
          () => (
            <Select
              className="select-virtual text-left"
              menuPlacement={"auto"}
              menuPosition={"fixed"}
              styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
              options={moveGubnOption}
              value={moveGubnOption?.find(el2 => el2.value === currentData?.moveGubn)}
              onChange={e => onChangeEventHandler("moveGubn", e?.value)}
            />
          ),
          [moveGubnOption, currentData.moveGubn]
        )}
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={currentData.drveTagt}
          onChange={e => onChangeEventHandler("drveTagt", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={currentData.statSite}
          onChange={e => onChangeEventHandler("statSite", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={currentData.arrvSite}
          onChange={e => onChangeEventHandler("arrvSite", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.statMile?.toLocaleString()}
          onChange={e => onChangeEventHandler("statMile", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.enddMile?.toLocaleString()}
          onChange={e => onChangeEventHandler("enddMile", e.target.value)}
        />
      </td>
      <td className="text-end">{currentData.drveMile?.toLocaleString()}</td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.gateCost?.toLocaleString()}
          onChange={e => onChangeEventHandler("gateCost", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.passCost?.toLocaleString()}
          onChange={e => onChangeEventHandler("passCost", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.charAmnt?.toLocaleString()}
          onChange={e => onChangeEventHandler("charAmnt", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control text-end"
          value={currentData.highPass?.toLocaleString()}
          onChange={e => onChangeEventHandler("highPass", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={currentData.remark}
          onChange={e => onChangeEventHandler("remark", e.target.value)}
        />
      </td>
      <td>
        <a
          onClick={() => {
            btnDelete(currentData);
          }}
        >
          <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
        </a>
      </td>
    </tr>
  );
};

export default React.memo(CarMasterListDriveRow);
