import React from "react";
import Select from "react-select-virtualized";
import { useAtom } from "jotai/index";
import { condoOption_atom } from "../../../../data/atom/dataManage/membershipItem/membershipItem_atom";
import { useAtomValue } from "jotai";

const tableRowCM = props => {
  const { currentData, onChangeRowEventHandler, btnDeleteRow } = props;
  const condoOption = useAtomValue(condoOption_atom);

  const gubun1Select = [
    { value: "11", label: "체인콘도" },
    { value: "22", label: "스키장콘도" },
  ];

  return (
    <tr>
      <td className="text-center">{currentData.idx >= 0 ? currentData.idx + 1 : ""}</td>
      <td>
        <input
          type="checkbox"
          checked={currentData.checkYn}
          onChange={e => onChangeRowEventHandler("checkYn", e.target.checked)}
          disabled={currentData.newFlag}
        />
      </td>
      <td>
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          options={condoOption}
          value={condoOption?.find(option => option.value === currentData?.value1)}
          onChange={e => onChangeRowEventHandler("value1", e?.value)}
          maxLength={7}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input text-center"
          value={currentData.code ? currentData.code : ""}
          onChange={e => onChangeRowEventHandler("code", e.target.value)}
          disabled={currentData.newFlag}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={currentData.codename ? currentData.codename : ""}
          onChange={e => onChangeRowEventHandler("codename", e.target.value)}
        />
      </td>
      <td>
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          options={gubun1Select}
          value={gubun1Select?.find(option => option.value === currentData?.gubun1)}
          onChange={e => onChangeRowEventHandler("gubun1", e?.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={currentData.remark ? currentData.remark : ""}
          onChange={e => onChangeRowEventHandler("remark", e.target.value)}
        />
      </td>
      <td>
        <button
          className="btn btn-outline-primary m-auto  h-25px"
          onClick={() => btnDeleteRow(currentData)}
        >
          삭제
        </button>
      </td>
    </tr>
  );
};

export default React.memo(tableRowCM);
