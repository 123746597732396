import Modal from "../../../../../components/modal/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useSelectSender } from "../../../../../api/code/useSelectSender";

export const SendSMSCautionModal = ({
  isSendSMSCautionModalOpen,
  setIsSendSMSCautionModalOpen,
  isSendSMSModalOpen,
  setIsSendSMSModalOpen,
  searchData,
}) => {
  const selectSender = useSelectSender(); // 발신번호 조회

  // SMS 보내기
  const customerSendSms = useMutation(data => axios.post("/api/work/customer/sendSms", data), {
    onSuccess: res => {
      alert(res.data.msg);
      setIsSendSMSCautionModalOpen({ data: null, isOpen: false });
      setIsSendSMSModalOpen({ data: null, isOpen: false });
    },
  });

  const btn_confirm = () => {
    let filter = isSendSMSCautionModalOpen.data.filterList.filter(
      el => el.rcvPhone !== "" && el.rcvPhone !== null && el.rcvPhone != undefined
    );

    filter = filter.map(i => {
      if (i.kname) return i;
      else return { ...i, kname: "" };
    });

    if (isSendSMSCautionModalOpen.data.SMSText === "") {
      alert("전송할 메세지를 입력한 후 전송하세요...!!!");
      setIsSendSMSCautionModalOpen({
        data: null,
        isOpen: false,
      });
      return;
    }

    if (isSendSMSCautionModalOpen.data.SMSText.slice(0, 4) !== "(광고)") {
      if (
        window.confirm(
          "전송문자 내용 첫구절에 '(광고)' 표기가 없습니다. \n\n혹시 전송하는 내용이 광고가 맞습니까....??? "
        )
      ) {
        alert("작성된 문자내용 첫구절에 '(광고)' 문구를 추가한 후 전송하시기 바랍니다....!!!");
        setIsSendSMSCautionModalOpen({
          data: null,
          isOpen: false,
        });
        return;
      } else {
        if (
          window.confirm(
            "광고성 문자의 경우 반드시 '무료수신거부 080-863-5885' 라는 내용을 표기해야 합니다. 자세히 확인바랍니다. \n\n작성된 내용으로 전송하시겠습니까...?"
          )
        ) {
          if (
            window.confirm(
              `입력한 메세지로 ${filter.length}건 전송합니다...!!\n\n전송하시겠습니까...??`
            )
          ) {
            customerSendSms.mutate({
              list: filter,
              sendDate: isSendSMSCautionModalOpen.data.sendDate,
              title: isSendSMSCautionModalOpen.data.title,
              // senderPhone: selectSender.data.find(
              //   el2 => el2.seq === +isSendSMSCautionModalOpen.data.senderPhone
              // ).sendPhoneNo,
              senderPhone: isSendSMSCautionModalOpen.data.senderPhone,
              text: isSendSMSCautionModalOpen.data.SMSText,
            });
          } else {
            setIsSendSMSCautionModalOpen({
              data: null,
              isOpen: false,
            });
            return;
          }
        } else {
          setIsSendSMSCautionModalOpen({
            data: null,
            isOpen: false,
          });
          return;
        }
      }
    } else {
      if (isSendSMSCautionModalOpen.data.SMSText.indexOf("무료수신거부 080-863-5885") === -1) {
        alert(
          "광고문자의 경우 반드시 '무료수신거부 080-863-5885' 라는 내용을 표기해야 합니다.\n\n'무료수신거부 080-863-5885' 추가 후 전송하세요"
        );

        setIsSendSMSCautionModalOpen({
          data: null,
          isOpen: false,
        });
        return;
      } else {
        if (
          window.confirm(
            `입력한 메세지로 ${filter.length}건 전송합니다...!!\n\n전송하시겠습니까...??`
          )
        ) {
          customerSendSms.mutate({
            list: filter,
            title: isSendSMSCautionModalOpen.data.title,
            sendDate: null,
            // senderPhone: selectSender.data.find(
            //   el2 => el2.seq === +isSendSMSCautionModalOpen.data.senderPhone
            // ).sendPhoneNo,
            senderPhone: isSendSMSCautionModalOpen.data.senderPhone,
            text: isSendSMSCautionModalOpen.data.SMSText,
          });
        } else {
          setIsSendSMSCautionModalOpen({
            data: null,
            isOpen: false,
          });
          return;
        }
      }
    }

    // if (isSendSMSCautionModalOpen.data.SMSText.slice(0, 4) === "(광고)") {
    //   if (isSendSMSCautionModalOpen.data.SMSText.indexOf("무료수신거부 080-863-5885") === -1) {
    //     alert(
    //       "광고문자의 경우 반드시 '무료수신거부 080-863-5885' 라는 내용을 표기해야 합니다. \n\n'무료수신거부 080-863-5885' 추가 후 전송하세요"
    //     );
    //
    //     setIsSendSMSCautionModalOpen({
    //       data: null,
    //       isOpen: false,
    //     });
    //     return;
    //   }
    //
    //   if (filter.length === 0) {
    //     alert(
    //       "문자를 전송할 대상이 추가되지 않았습니다...!!! \n\n문자전송을 원하는 핸드폰번호를 추가한 후 전송하시기 바랍니다. ...?"
    //     );
    //     return;
    //   }
    //
    //   if (
    //     window.confirm(
    //       `입력한 메세지로 ${filter.length}건 전송합니다...!!\n\n전송하시겠습니까...??`
    //     )
    //   ) {
    //     customerSendSms.mutate({
    //       list: filter,
    //       sendDate: null,
    //       senderPhone: selectSender.data.find(
    //         el2 => el2.seq === +isSendSMSCautionModalOpen.data.senderPhone
    //       ).sendPhoneNo,
    //       text: isSendSMSCautionModalOpen.data.SMSText,
    //     });
    //   }
    // } else {
    //   if (
    //     window.confirm(
    //       "전송문자 내용 첫구절에 '(광고)' 표기가 없습니다. \n\n혹시 전송하는 내용이 광고가 맞습니까....??? "
    //     )
    //   ) {
    //     alert("작성된 문자내용 첫구절에 '(광고)' 문구를 추가한 후 전송하시기 바랍니다....!!!");
    //   } else {
    //     if (
    //       window.confirm(
    //         "광고성 문자의 경우 반드시 '무료수신거부 080-863-5885' 라는 내용을 표기해야 합니다. 자세히 확인바랍니다. \n\n작성된 내용으로 전송하시겠습니까...?"
    //       )
    //     ) {
    //       if (filter.length === 0) {
    //         alert(
    //           "문자를 전송할 대상이 추가되지 않았습니다...!!! \n\n문자전송을 원하는 핸드폰번호를 추가한 후 전송하시기 바랍니다. ...?"
    //         );
    //         return;
    //       }
    //
    //       customerSendSms.mutate({
    //         list: filter,
    //         sendDate: null,
    //         senderPhone: selectSender.data.find(
    //           el2 => el2.seq === +isSendSMSCautionModalOpen.data.senderPhone
    //         ).sendPhoneNo,
    //         text: isSendSMSCautionModalOpen.data.SMSText,
    //       });
    //     } else {
    //       setIsSendSMSCautionModalOpen({
    //         data: null,
    //         isOpen: false,
    //       });
    //     }
    //   }
    // }
  };

  const btn_cancel = () => {
    alert("문자 전송을 취소하였습니다....!");

    setIsSendSMSCautionModalOpen({
      data: null,
      isOpen: false,
    });
  };

  return (
    <Modal
      size="md"
      show={isSendSMSCautionModalOpen.isOpen}
      title_class={"w-100 text-center"}
      title={"☜ 주  의  사  항 ☞"}
      onHide={() =>
        setIsSendSMSCautionModalOpen({
          data: null,
          isOpen: false,
        })
      }
      centered
      footer={
        <div className="d-flex justify-content-end m-0">
          <button
            className="btn btn-outline-primary btn-filter-outline-primary"
            onClick={btn_confirm}
          >
            예
          </button>
          <button
            className="btn btn-outline-primary btn-filter-outline-primary"
            onClick={btn_cancel}
          >
            아니오
          </button>
        </div>
      }
    >
      <ul className="ps-0 style-none f-regular f-15">
        <li>
          1. 광고 문자인 경우 문자 첫머리에 반드시 <span className="text-danger">"(광고)"</span>{" "}
          표기
        </li>
        <li>
          2. 마지막에 <span className="text-danger">"무료수신거부 080-863-5885"</span> 반드시 표기
        </li>
      </ul>
      <hr />
      <p className="p_modal">
        『중복전송 제외 기능 사용』,『광고문자 거부자 제외 시 클릭』
        <br />
        등의 버튼을 활용하여 고객분들에게 불편을 주지 않도록 생활화
        <br />
        하시기 바랍니다.....!
      </p>
      <hr />
      <p className="p_modal text-center m-0">작성한 내용으로 전송하시겠습니까....?</p>
    </Modal>
  );
};
