import React, { useEffect, useRef } from "react";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  registeredDeliveryHistory_searchData_atom,
  registeredDeliveryHistory_searchData_init,
  registeredDeliveryHistory_searchedData_atom,
  registeredDeliveryHistory_searchedTableList_atom,
} from "../../../../data/atom/workManage/postManage/registeredDeliveryHistory_atom";
import { SMStransferHistory_searchedTableTotalCount_atom } from "../../../../data/atom/workManage/SMStransferHistory/SMStransferHistory_atom";
import { useGetJisaGubunAllQ1 } from "../../../../api/code/useGetJisaGubunAllQ1";
import { useGetSpQ } from "../../../../api/code/useGetSpQ";
import { useGetPostGubun } from "../../../../api/code/useGetPostGubun";
import { RegisteredDeliveryHistoryTableRow } from "./compontents/table/registeredDeliveryHistoryTableRow";
import useInfiniteScrollWrapper from "../../../../util/infiniteScroll/useInfiniteScrollWrapper";
import { RegisteredDeliveryHistoryFilterPanelAccount } from "./compontents/filterPanel/registeredDeliveryHistoryFilterPanelAccount";
import { RegisteredDeliveryHistoryFilterPanelDealer } from "./compontents/filterPanel/registeredDeliveryHistoryFilterPanelDealer";
import { RegisteredDeliveryHistoryFilterPanelCustomer } from "./compontents/filterPanel/registeredDeliveryHistoryFilterPanelCustomer";
import { RegisteredDeliveryHistoryTableRowHeader } from "./compontents/table/registeredDeliveryHistoryTableRowHeader";

export const RegisteredDeliveryHistory = () => {
  const targetRef = useRef(null);
  const [searchData, setSearchData] = useAtom(registeredDeliveryHistory_searchData_atom);
  const [searchedData, setSearchedData] = useAtom(registeredDeliveryHistory_searchedData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    registeredDeliveryHistory_searchedTableList_atom
  );
  const [searchedTableTotalCount, setSearchedTableTotalCount] = useAtom(
    SMStransferHistory_searchedTableTotalCount_atom
  );

  useGetSpQ(); // 우체국
  useGetPostGubun(); // 발송방법
  const getJisaGubunAllQ1 = useGetJisaGubunAllQ1(); // 검색지사
  const fetchFirstPage = async param => {
    let res = await axios.get("/api/work/mailReg/search", { params: param });

    if (param.page === 1) {
      setSearchedTableTotalCount(res.data.result.total);
    }

    setSearchedData(searchData);

    return res.data.result.list;
  };

  useEffect(() => {
    if (getJisaGubunAllQ1.isSuccess) {
      setSearchData({
        ...searchData,
        jisa: getJisaGubunAllQ1.data[0].key,
      });
    }
  }, [getJisaGubunAllQ1.isSuccess]);

  const rowVirtualizer = useVirtualizer({
    count: searchedTableList?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const { endRef, refetchFromFirstPage, refetchAllPage } = useInfiniteScrollWrapper({
    fetch: fetchFirstPage,
    fetchKey: ["mailRegSearch"],
    searchParam: searchData,
    resultSetter: setSearchedTableList,
  });

  const btnSearch = () => {
    if (searchData.gubun !== "C") {
      if (searchData.condition !== "A" && searchData.keyword.trim() === "") {
        alert("전체조회는 할 수 없습니다.");
        return;
      }
    } else {
      if (searchData.keyword.trim() === "") {
        alert("전체조회는 할 수 없습니다.");
        return;
      }
    }

    refetchFromFirstPage();
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  return (
    <div className="post-page">
      <div className="post-page-title">등기발송 내역</div>
      <div className="filter_fields_container">
        <div className="filter_fields_row">
          <div className="d-flex align-items-center justify-content-between" onKeyDown={pressEnter}>
            <div className="filter1-field filter1-field1">
              <label className="filter1-label">조회 구분</label>
              <select
                className="form-select filter1-select1"
                value={searchData.gubun}
                onChange={e =>
                  setSearchData({
                    ...registeredDeliveryHistory_searchData_init,
                    gubun: e.target.value,
                  })
                }
              >
                <option value="A">경리팀용</option>
                <option value="B">담당딜러용</option>
                <option value="C">고객용</option>
              </select>

              {searchData.gubun === "A" && <RegisteredDeliveryHistoryFilterPanelAccount />}
              {searchData.gubun === "B" && <RegisteredDeliveryHistoryFilterPanelDealer />}
              {searchData.gubun === "C" && <RegisteredDeliveryHistoryFilterPanelCustomer />}
            </div>
          </div>

          <div className="d-flex">
            <button
              className="btn btn-outline-primary btn-filter-outline-primary me-20px"
              onClick={btnSearch}
            >
              <img
                className="me-2"
                src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                alt=""
              />
              검색
            </button>
          </div>
        </div>
      </div>

      <div className="card card-main">
        <div className="card-header d-flex align-items-center justify-content-between">
          <div className="d-flex" style={{ gap: "20px" }}>
            <h5 className="card-title m-0">등기우편 접수내역</h5>
            <div className="post-note-span">해당 행 더블클릭 -&gt; 배송조회(최대 3개월 이내)</div>
          </div>
          <div className="d-flex align-items-center justify-content-end" style={{ gap: "10px" }}>
            <div className="post-note-span">검색결과: {searchedTableTotalCount}건</div>
          </div>
        </div>
        <div className="card-body">
          <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
            <table
              className="table table-bordered table-responsive my-table"
              style={{
                width: `${searchedTableList.length !== 0 && "2150px"}`,
                marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                marginBottom: `${
                  rowVirtualizer.getTotalSize() -
                  (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                  rowVirtualizer.getVirtualItems().length * 30
                }px`,
              }}
            >
              <thead>
                <RegisteredDeliveryHistoryTableRowHeader />
              </thead>
              <tbody>
                {searchedTableList?.length !== 0 ? (
                  <>
                    {rowVirtualizer.getVirtualItems()?.map((virtualRow, index) => {
                      if (virtualRow.index !== searchedTableList.length - 1) {
                        return <RegisteredDeliveryHistoryTableRow virtualRow={virtualRow} />;
                      } else {
                        return (
                          <>
                            <RegisteredDeliveryHistoryTableRow virtualRow={virtualRow} />
                            <div ref={endRef} />
                          </>
                        );
                      }
                    })}
                    <tr>
                      <td className="f-bold" colSpan={14} style={{ color: "#0b4da2" }}>
                        총 발송량 : {searchedTableList.length?.toLocaleString()}건 / 총 금액 :{" "}
                        {searchedTableList
                          .reduce((acc, cur) => {
                            return +acc + cur.sendWon;
                          }, 0)
                          ?.toLocaleString()}
                        원
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={
                        searchedData?.gubun === "A"
                          ? 14
                          : searchedData?.gubun === "B"
                          ? 11
                          : searchedData?.gubun === "C"
                          ? 12
                          : 14
                      }
                    >
                      검색된 결과가 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
