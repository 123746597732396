import React from "react";
import { useAtom } from "jotai/index";
import { medoja_atom } from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import { useQueryClient } from "@tanstack/react-query";
import { useGetSemusu } from "../../../../api/code/useGetSemusu";

export default function Medo() {
  const [medoja, setMedoja] = useAtom(medoja_atom);

  const queryClient = useQueryClient();
  const getSemusu = useGetSemusu(); //queryClient.getQueryData(["getSemusu"]);

  const onChangeEventHandler = (key, value) => {
    if (key === "num1" || key === "num2" || key === "numTo" || key === "numFrom") {
      value = value.replace(/[^0-9-]/g, "").replace(/,/g, "");
    }
    setMedoja({ ...medoja, [key]: value });
  };

  return (
    <div className="card card-main w-50 h-100">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title  m-0">매도자 인적사항</h5>
      </div>

      <div className="card-body">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th>성명</th>
              <td className="form-text">{medoja.kname}</td>
              <th style={{ width: "10%" }}>주민번호</th>
              <td className="form-text" style={{ width: "15%" }}>
                {medoja.juminno?.replace(/^(\d{6})(\d{7})$/g, "$1-$2")}
              </td>
            </tr>
            <tr>
              <th style={{ width: "11.9%" }} rowSpan={2}>
                주소
              </th>
              <td className="form-text">{medoja.areaCode}</td>
              <td className="form-text" colSpan={2}>
                {medoja.jusoTxt}
              </td>
            </tr>
            <tr>
              <td className="form-text" colSpan={3}>
                {medoja.juso}
              </td>
            </tr>
            <tr>
              <th>기신고세액</th>
              <td className="form-text" style={{ width: "13.1%" }}>
                <input
                  type="text"
                  className="form-control text-end"
                  value={Number(medoja.num1)?.toLocaleString()}
                  onChange={e => onChangeEventHandler("num1", e.target.value)}
                />
              </td>
              <th>
                기 신고
                <br />
                양도금액
              </th>
              <td className="form-text">
                <input
                  type="text"
                  className="form-control text-end"
                  value={Number(medoja.numFrom)?.toLocaleString()}
                  onChange={e => onChangeEventHandler("numFrom", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>감면세액</th>
              <td className="form-text" style={{ width: "13.1%" }}>
                <input
                  type="text"
                  className="form-control text-end"
                  value={Number(medoja.numTo)?.toLocaleString()}
                  onChange={e => onChangeEventHandler("numTo", e.target.value)}
                />
              </td>
              <th>
                원천징수
                <br /> 세액공제
              </th>
              <td className="form-text">
                <input
                  type="text"
                  className="form-control text-end"
                  value={Number(medoja.num2)?.toLocaleString()}
                  onChange={e => onChangeEventHandler("num2", e.target.value)}
                />
              </td>
            </tr>
            <tr style={{ height: "8px" }}></tr>
            <tr>
              <th>관할 세무서</th>
              {medoja.semusuYn ? (
                <td className="form-text" colSpan={3}>
                  {(getSemusu.data ?? [])?.find(el => el.custId === medoja.semusu)?.company}
                </td>
              ) : (
                <td colSpan={3}>
                  <select
                    className="form-select"
                    value={medoja.semusu ? medoja.semusu : ""}
                    onChange={e => onChangeEventHandler("semusu", e.target.value)}
                  >
                    {(getSemusu.data ?? [])?.map(el => (
                      <option value={el.custId}>{el.company}</option>
                    ))}
                    <option value="" hidden={true}></option>
                  </select>
                </td>
              )}
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
}
