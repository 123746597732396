import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  postManage_checkboxList_atom,
  postManage_modifyList_atom,
  postManage_searchData_atom,
  postManage_searchDataInit_atom,
  postManage_searchedTableList_atom,
} from "../../../../data/atom/workManage/postManage/postManage_atom";
import { useGetJisaGubun } from "../../../../api/code/useGetJisaGubun";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useGetPostGubun } from "../../../../api/code/useGetPostGubun";
import { useGetSpQ } from "../../../../api/code/useGetSpQ";
import { useGetJisaGubunAll } from "../../../../api/code/useGetJisaGubunAll";
import { decrypt } from "../../../../util/decrypt/decrypt";
import { useVirtualizer } from "@tanstack/react-virtual";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { regex_only_number } from "../../../../util/validation";

export const PostManage = () => {
  const targetRef = useRef(null);
  const { addTabClosedEvent } = useRecentTabEvent();

  const [searchData, setSearchData] = useAtom(postManage_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(postManage_searchedTableList_atom);
  const [modifyList, setModifyList] = useAtom(postManage_modifyList_atom);
  const [checkboxList, setCheckboxList] = useAtom(postManage_checkboxList_atom);

  const [postSetting, setPostSetting] = useState({});

  const getJisaGubun = useGetJisaGubun();
  const getJisaGubunAll = useGetJisaGubunAll();
  const getPostGubun = useGetPostGubun();
  const getSpQ = useGetSpQ();

  // 등기우편접수 내역
  const mailSearch = useMutation(data => axios.get("/api/work/mail/search", { params: data }), {
    onSuccess: res => {
      setSearchedTableList(res.data.result);
      setModifyList(Array.from({ length: res.data.result.length }, () => false));
      setCheckboxList(Array.from({ length: res.data.result.length }, () => false));
    },
  });

  // 등기우편접수 내역 저장
  const mailSave = useMutation(data => axios.post("/api/work/mail/save", data), {
    onSuccess: res => {
      mailSearch.mutate({
        jisaGubun: searchData.jisaGubun,
        keyword: searchData.keyword,
      });
    },
  });

  // 등기우편접수 내역 삭제
  const mailDelete = useMutation(data => axios.post("/api/work/mail/delete", data), {
    onSuccess: res => {
      mailSearch.mutate({
        jisaGubun: searchData.jisaGubun,
        keyword: searchData.keyword,
      });
    },
  });

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSearchData(postManage_searchDataInit_atom);
      setSearchedTableList([]);
      setModifyList([]);
      setCheckboxList([]);
    });
  }, []);

  // useEffect(() => {
  //   if (getJisaGubun.isSuccess && searchedTableList.length === 0) {
  //     setSearchData({
  //       ...searchData,
  //       jisaGubun: getJisaGubun.data[1].key,
  //     });
  //   }
  // }, [getJisaGubun.isSuccess]);

  useEffect(() => {
    if (getSpQ.isSuccess) {
      setPostSetting(getSpQ.data[0].key);
    }
  }, [getSpQ.isSuccess]);

  useEffect(() => {
    if (getJisaGubun.isSuccess && searchedTableList.length === 0) {
      mailSearch.mutate({ jisaGubun: searchData.jisaGubun, keyword: searchData.keyword });
    }
  }, [getJisaGubun.isSuccess]);

  const btn_search = () => {
    mailSearch.mutate({ jisaGubun: searchData.jisaGubun, keyword: searchData.keyword });
  };

  // const btn_search_reset = async () => {
  //   await setSearchData(prev => {
  //     return {
  //       ...prev,
  //       keyword: "",
  //       isReSearch: false,
  //     };
  //   });
  //
  //   mailSearch.refetch();
  // };

  const btn_postSetting = () => {
    if (window.confirm("접수우체국을 선택된 우체국으로 일관 변경하시겠습니까?")) {
      const newModifyList = [...modifyList];

      setSearchedTableList(
        searchedTableList.reduce((acc, cur, idx) => {
          if (cur.processChk === "P") {
            newModifyList[idx] = true;
            return [...acc, { ...cur, sendPlace: postSetting }];
          } else {
            newModifyList[idx] = false;
            return [...acc, cur];
          }
        }, [])
      );

      setModifyList(newModifyList);
    }
  };

  const btn_batchProcessing = () => {
    if (window.confirm("우체국 발송 일괄처리를 하시겠습니까?")) {
      const newModifyList = [...modifyList];

      const temp = searchedTableList.reduce((acc, cur, idx) => {
        if (cur.processChk === "R") {
          newModifyList[idx] = true;

          return [...acc, { ...cur, processChk: "P" }];
        } else {
          return [...acc, cur];
        }
      }, []);

      setModifyList(newModifyList);
      setSearchedTableList(temp);
    }
  };

  const btn_save = () => {
    if (window.confirm("저장하시겠습니까?")) {
      let filter = [];

      if (checkboxList.every(el => el === false)) {
        filter = searchedTableList.filter((el, index) => modifyList[index]);
      } else {
        filter = searchedTableList.filter((el, index) => checkboxList[index]);
      }

      if (filter.length === 0) {
        alert("수정할 내용이 없습니다.");
        return;
      }

      const newData = filter.map(el => {
        return {
          areaCode: el.areaCode, // 지역번호
          company: el.company, // 업체명
          createNo: el.createNo, // 등기번호
          custId: el.custId, // 관리번호
          gubun: el.gubun, // 구분
          ilNo: el.ilNo, // 연번
          jisa: el.jisa, // 거래지사
          jisaTax: el.jisaTax, // 거래지사
          jusoAddr: el.jusoAddr, // 주소
          kname: el.kname, // 이름
          printGubun2: "2", // 패스
          processChk: el.processChk, // 우편물위치
          raddres: el.raddres, // 세부주소
          remark: el.remark, // 내용
          requestDate: moment(el.requestDate).format("YYYY-MM-DD"), // 신청일자
          sendGubun: el.sendGubun, // 발송방법
          sendPlace: el.sendPlace, // 접수우체국
          sendWon: el.sendWon ? Number(el?.sendWon.toString().replaceAll(",", "")) : 0, // 우편요금
          seqNo: el.seqNo, // 거래번호
          userName: el.userName, // 신청자
          newCheck: el?.newCheck ? el.newCheck : false,
        };
      });
      mailSave.mutate(newData);
    }
  };

  const btn_add = () => {
    if (searchedTableList.some(el => el.ilNo === "")) {
      alert("이미 신규등록될 내용이 있습니다.");
      return;
    }

    setSearchedTableList(prev => {
      return [
        {
          ilNo: "",
          jisa: "",
          custId: "99999999",
          sendGubun: "11",
          company: "일반우편요금",
          devision: "",
          kname: "",
          position: "",
          zipcode: "99999",
          addres: "",
          requestDate: moment().format("YYYY-MM-DD"),
          printGubun: "Y",
          printGubun2: "2",
          remark: "",
          sendDate: moment().format("YYYY-MM-DD"),
          sortGubun: "12",
          processChk: "R",
          createNo: "99999999",
          sendWon: 0,
          sendPlace: "1J",
          seqNo: "",
          returnDate: "",
          returnCheck: "N",
          jusoAddr: "",
          raddres: "",
          userName: decrypt("userInfo").userName,
          userid: decrypt("userInfo").loginId,
          newCheck: true,
        },
        ...prev,
      ];
    });

    setModifyList(prev => [true, ...prev]);
  };

  const btn_refresh = () => {
    mailSearch.mutate({ jisaGubun: searchData.jisaGubun, keyword: searchData.keyword });
  };

  const btn_delete = data => {
    if (window.confirm("삭제하시겠습니까?")) {
      mailDelete.mutate([
        {
          areaCode: data.areaCode,
          company: data.company,
          createNo: data.createNo,
          custId: data.custId,
          gubun: data.gubun,
          ilNo: data.ilNo,
          jisa: data.jisa,
          jisaTax: data.jisaTax,
          jusoAddr: data.jusoAddr,
          kname: data.kname,
          processChk: data.processChk,
          raddres: data.raddres,
          remark: data.remark,
          requestDate: moment(data.requestDate).format("YYYY-MM-DD"),
          sendGubun: data.sendGubun,
          sendPlace: data.sendPlace,
          sendWon: data.sendWon,
          seqNo: data.seqNo,
          userName: data.userName,
        },
      ]);
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: searchedTableList?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="post-page">
      <div className="post-page-title">등기우편 관리</div>
      <div className="filter_fields_container">
        <div className="filter_fields_row">
          <div className="d-flex align-items-center justify-content-between" onKeyDown={pressEnter}>
            <div className="filter1-field filter1-field1">
              <label className="filter1-label">검색조건</label>
              <select
                className="form-select filter1-select1"
                value={searchData.jisaGubun}
                onChange={e => {
                  setSearchData({
                    ...searchData,
                    jisaGubun: e.target.value,
                    keyword: "",
                  });
                }}
              >
                {getJisaGubun.data
                  ?.filter(el2 => el2.value !== "전체")
                  .map(el => (
                    <option value={el.key}>{el.value}</option>
                  ))}
              </select>
            </div>
            <div className="filter1-field filter1-field2">
              <label className="filter1-label">검색어</label>
              <input
                type="text"
                className="form-control filter-input filter1-input2"
                value={searchData.keyword}
                onChange={e => {
                  setSearchData({
                    ...searchData,
                    keyword: e.target.value,
                  });
                }}
                disabled={!searchData.isReSearch}
              />
            </div>
            <div className="filter1-field filter1-field3">
              <input
                type="checkbox"
                id="chk-1"
                className="form-check-input"
                name="checkbox-option"
                checked={searchData.isReSearch}
                onChange={e => {
                  if (e.target.checked === false) {
                    setSearchData({
                      ...searchData,
                      keyword: "",
                      isReSearch: e.target.checked,
                    });
                  } else {
                    setSearchData({
                      ...searchData,
                      isReSearch: e.target.checked,
                    });
                  }
                }}
              />
              <label className="filter1-label" htmlFor="chk-1">
                결과 內 재검색
              </label>
            </div>
          </div>

          <button
            className="btn btn-outline-primary btn-filter-outline-primary me-20px"
            onClick={btn_search}
          >
            <img className="me-2" src={process.env.PUBLIC_URL + "/images/search_icon.png"} alt="" />
            검색
          </button>
          {/*</div>*/}
        </div>
      </div>
      <div className="post-setting-container">
        <div className="post-setting-select">
          <div className="post-work-label">접수우체국 일괄 세팅</div>
          <select
            className="form-select post-work-setting-box"
            value={postSetting}
            onChange={e => setPostSetting(e.target.value)}
          >
            {getSpQ.data?.map(el => (
              <option key={el.key} value={el.key}>
                {el.value}
              </option>
            ))}
          </select>
          <button
            className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn"
            onClick={btn_postSetting}
          >
            접수우체국 일괄 변경
          </button>
        </div>
      </div>

      <div className="card card-main">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title  m-0">부가설명</h5>
        </div>

        <div className="card-body">
          <div>
            1. 등기번호 및 우편요금 입력을 하기 위한 우체국 발송처리 (권장 사용/ 취소 분 자동 삭제
            됨) : 현재 위치가 접수 → 우체국으로 바뀜.
          </div>
          <div>
            2. 우체국 발송 후 등기번호, 우편요금, 발송 우체국 등에 대해서 입력 후 최종 저장 (저장 후
            재조회 시 화면에서 사라짐)
          </div>
          <div>
            3. 일반우편 발송 시 사용 : 클릭하면 빈 행 나타남-요금은 1일 합산 금액으로 1회만 입력할
            것.
          </div>
          <div>
            4. 처리상황이 접수분에 대한서만 자체 접수양식 출력(우체국 별도 양식 제출시에 사용)
          </div>
          <div>
            5. 우체국 발송분 처리에 대해서 접수우체국 변경시에만 사용 *주의: 등기번호, 요금 입력 시
            우편물의 위치는 ‘우체국‘ 상태 이어야 함.
          </div>
        </div>
      </div>

      <div className="card card-main">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title  m-0">등기우편 접수내역</h5>
          <div className="d-flex align-items-center justify-content-end" style={{ gap: "8px" }}>
            <button
              className="btn btn-outline-primary btn-filter-outline-primary post-manage-btn"
              onClick={btn_batchProcessing}
            >
              우체국 발송 일괄처리(접수-&gt;우체국)
            </button>
            <button
              className="btn btn-outline-primary btn-filter-outline-primary post-manage-btn"
              onClick={btn_save}
            >
              내용저장
            </button>
            <button
              className="btn btn-outline-primary btn-filter-outline-primary post-manage-btn"
              onClick={btn_add}
            >
              일반우편 요금등록
            </button>
            <button
              className="btn btn-outline-primary btn-filter-outline-primary post-manage-btn"
              onClick={btn_refresh}
            >
              화면 재조회
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
            <table
              className="table table-bordered table-responsive my-table"
              style={{
                width: `${searchedTableList.length !== 0 ? "2500px" : ""}`,
                marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                marginBottom: `${
                  rowVirtualizer.getTotalSize() -
                  (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                  rowVirtualizer.getVirtualItems().length * 30
                }px`,
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "2%" }}>
                    <input
                      type="checkbox"
                      checked={checkboxList.length !== 0 && checkboxList.every(el2 => el2 === true)}
                      onChange={e => {
                        const newCheckboxList = [...checkboxList];
                        setCheckboxList(newCheckboxList.map(() => e.target.checked));
                      }}
                    />
                  </th>
                  <th style={{ width: "3%" }}>연번</th>
                  <th style={{ width: "3%" }}>구분</th>
                  <th style={{ width: "4%" }}>신청일자</th>
                  <th style={{ width: "6%" }}>업체명</th>
                  <th style={{ width: "5%" }}>발송방법</th>
                  <th style={{ width: "3%" }}>우편물 위치</th>
                  <th style={{ width: "6%" }}>이름</th>
                  <th style={{ width: "5%" }}>등기번호</th>
                  <th style={{ width: "5%" }}>우편요금</th>
                  <th style={{ width: "6%" }}>접수우체국</th>
                  <th style={{ width: "4%" }}>신청자</th>
                  <th style={{ width: "12%" }}>내용</th>
                  <th style={{ width: "4%" }}>거래번호</th>
                  <th style={{ width: "3%" }}>거래지사</th>
                  <th style={{ width: "4%" }}>구분</th>
                  <th style={{ width: "12%" }}>주소</th>
                  <th style={{ width: "5%" }}>세부주소</th>
                  <th style={{ width: "3%" }}>지역번호</th>
                  <th style={{ width: "2%" }}>삭제</th>
                </tr>
              </thead>
              <tbody>
                {searchedTableList.length !== 0 ? (
                  rowVirtualizer.getVirtualItems()?.map((virtualRow, index) => (
                    <tr
                      key={searchedTableList[virtualRow.index].ilNo}
                      className={`${
                        searchedTableList[virtualRow.index].processChk === "P"
                          ? "bg-lightyellow"
                          : ""
                      }`}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={checkboxList[virtualRow.index] === true}
                          onChange={e => {
                            const newCheckboxList = [...checkboxList];
                            newCheckboxList[virtualRow.index] = e.target.checked;
                            setCheckboxList(newCheckboxList);
                          }}
                        />
                      </td>
                      <td>{searchedTableList[virtualRow.index].ilNo}</td>
                      <td>
                        {
                          getJisaGubunAll.data?.find(
                            el2 => el2.key === searchedTableList[virtualRow.index].jisa
                          )?.value
                        }
                      </td>
                      <td>
                        {/*<input*/}
                        {/*  type="text"*/}
                        {/*  className="form-control"*/}
                        {/*  value={moment(searchedTableList[virtualRow.index].requestDate).format(*/}
                        {/*    "YYYY-MM-DD"*/}
                        {/*  )}*/}
                        {/*  disabled={true}*/}
                        {/*/>*/}
                        {moment(searchedTableList[virtualRow.index].requestDate).format(
                          "YYYY-MM-DD"
                        )}
                      </td>
                      <td>
                        {/*<input*/}
                        {/*  type="text"*/}
                        {/*  className="form-control"*/}
                        {/*  value={searchedTableList[virtualRow.index].company}*/}
                        {/*  disabled={true}*/}
                        {/*/>*/}
                        {searchedTableList[virtualRow.index].company}
                      </td>
                      <td>
                        {/*<input*/}
                        {/*  type="text"*/}
                        {/*  className="form-control"*/}
                        {/*  value={*/}
                        {/*    getPostGubun.data?.find(*/}
                        {/*      el => el.key === searchedTableList[virtualRow.index].sendGubun*/}
                        {/*    )?.value*/}
                        {/*  }*/}
                        {/*  disabled={true}*/}
                        {/*/>*/}
                        {
                          getPostGubun.data?.find(
                            el => el.key === searchedTableList[virtualRow.index].sendGubun
                          )?.value
                        }
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={searchedTableList[virtualRow.index].processChk}
                          onChange={e => {
                            const newSearchedTableList = [...searchedTableList];
                            newSearchedTableList[virtualRow.index].processChk = e.target.value;
                            setSearchedTableList(newSearchedTableList);

                            const newModifyList = [...modifyList];
                            newModifyList[virtualRow.index] = true;
                            setModifyList(newModifyList);
                          }}
                        >
                          <option value="R">접수</option>
                          <option value="P">우체국</option>
                          <option value="D">보류</option>
                          <option value="C">취소</option>
                          <option value="E">처리완료</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchedTableList[virtualRow.index].kname}
                          readOnly={true}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchedTableList[virtualRow.index].createNo}
                          onChange={e => {
                            const newSearchedTableList = [...searchedTableList];
                            newSearchedTableList[virtualRow.index].createNo = e.target.value;
                            setSearchedTableList(newSearchedTableList);

                            const newModifyList = [...modifyList];
                            newModifyList[virtualRow.index] = true;
                            setModifyList(newModifyList);
                          }}
                          onBlur={() => {
                            setModifyList(
                              modifyList.map((el2, index2) => {
                                if (index === index2) {
                                  return true;
                                } else {
                                  return el2;
                                }
                              })
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control text-end"
                          value={searchedTableList[virtualRow.index].sendWon.toLocaleString()}
                          onChange={e => {
                            const newSearchedTableList = [...searchedTableList];
                            newSearchedTableList[virtualRow.index].sendWon = +e.target.value
                              .replace(/,/g, "")
                              .replace(/[^0-9]/g, "");

                            setSearchedTableList(newSearchedTableList);

                            const newModifyList = [...modifyList];
                            newModifyList[virtualRow.index] = true;
                            setModifyList(newModifyList);
                          }}
                        />
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={searchedTableList[virtualRow.index].sendPlace}
                          onChange={e => {
                            const newSearchedTableList = [...searchedTableList];
                            newSearchedTableList[virtualRow.index].sendPlace = e.target.value;
                            setSearchedTableList(newSearchedTableList);

                            const newModifyList = [...modifyList];
                            newModifyList[virtualRow.index] = true;
                            setModifyList(newModifyList);
                          }}
                        >
                          {getSpQ.data?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchedTableList[virtualRow.index].userName}
                          readOnly={true}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchedTableList[virtualRow.index].remark}
                          readOnly={true}
                        />
                      </td>
                      <td>{searchedTableList[virtualRow.index].seqNo.replace(/(.{6})/, "$1-")}</td>
                      <td>
                        {
                          getJisaGubunAll.data?.find(
                            el2 => el2.key === searchedTableList[virtualRow.index].jisaTax
                          )?.value
                        }
                      </td>
                      <td>
                        {searchedTableList[virtualRow.index].gubun === "1"
                          ? "양도세서류"
                          : searchedTableList[virtualRow.index].gubun === "2"
                          ? "취득세지로"
                          : ""}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchedTableList[virtualRow.index].jusoAddr}
                          readOnly={true}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={searchedTableList[virtualRow.index].raddres}
                          readOnly={true}
                        />
                      </td>
                      <td>{searchedTableList[virtualRow.index].areaCode}</td>
                      <td
                        className="cursor-pointer"
                        onClick={() => btn_delete(searchedTableList[virtualRow.index])}
                      >
                        <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={19}>검색결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
