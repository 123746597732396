import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalRegisterRow from "./ModalRegisterRow";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";

const modalRegister = props => {
  const { show, onHide, refetchFromFirstPage } = props;
  const [unEtc2CodeList, setUnEtc2CodeList] = useState([]);
  const [modalEditedRowList, setModalEditedRowList] = useState([]);

  const searchUnEtc2Code = useMutation(
    data => axios.get("/api/data/memberManage/getUnEtc2", { params: data }),
    {
      onSuccess: res => {
        setUnEtc2CodeList(res.data.result);
      },
    }
  );

  const saveUnEtc2Code = useMutation(data => axios.post("/api/data/memberManage/saveEtc2", data), {
    onSuccess: res => {
      setModalEditedRowList([]);
      onHide();
      refetchFromFirstPage();
    },
  });

  useEffect(() => {
    if (show) {
      searchUnEtc2Code.mutate();
    }
  }, [show]);

  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      const list = modalEditedRowList.filter(el => el.check);
      if (list.length === 0) {
        alert("선택된 항목이 없습니다.");
      } else {
        saveUnEtc2Code.mutate(list);
      }
    }
  };

  return (
    <Modal
      show={show}
      size={"lg"}
      centered
      onHide={() => {
        onHide();
        setUnEtc2CodeList([]);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>미등록 회원코드</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-form">
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>No</th>
                <th style={{ width: "8%" }}>code</th>
                <th style={{ width: "25%" }}>회원권명</th>
                <th style={{ width: "5%" }}>
                  저장
                  <br />
                  여부
                </th>
                <th style={{ width: "15%" }}>회원권 분류</th>
                <th style={{ width: "15%" }}>분류</th>
              </tr>
            </thead>
            <tbody>
              {unEtc2CodeList.length !== 0 ? (
                unEtc2CodeList.map((el, idx) => {
                  return (
                    <ModalRegisterRow
                      key={idx}
                      data={el}
                      index={idx}
                      setModalEditedRowList={setModalEditedRowList}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="flex-center-end pt-3">
            <button className="btn btn-outline-primary" onClick={btnSave}>
              저장
            </button>
            <button className="btn btn-outline-primary ms-2" onClick={() => onHide()}>
              닫기
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(modalRegister);
