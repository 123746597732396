import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai/index";
import {
  golfPrice_mainData_atom,
  golfPrice_mainSearchedTableList_atom,
  golfPrice_searchDataInit_atom,
  golfPrice_subData_atom,
  golfPrice_subDataInit_atom,
  golfPrice_subSearchedTableList_atom,
} from "../../../data/atom/dataManage/golfPrice/golfPrice_atom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useVirtualizer } from "@tanstack/react-virtual";
import moment from "moment";
import "moment/locale/ko";
import { fileDownload } from "../../../util/fileDownload/fileDownload";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const GolfPrice = () => {
  const { addTabClosedEvent } = useRecentTabEvent();

  const parentRef = useRef();

  // 골프 시세 검색 조건
  const [mainSearchData, setMainSearchData] = useAtom(golfPrice_mainData_atom);
  const [mainSearchedTableList, setMainSearchedTableList] = useAtom(
    golfPrice_mainSearchedTableList_atom
  );

  // 골프 시세 자료 생성 조건
  const [subSearchData, setSubSearchData] = useAtom(golfPrice_subData_atom);
  const [subSearchedTableList, setSubSearchedTableList] = useAtom(
    golfPrice_subSearchedTableList_atom
  );
  const [excelData, setExcelData] = useState(golfPrice_subSearchedTableList_atom);

  // 골프 시세 검색
  const golfGraphSearch = useQuery(
    ["golfGraphSearch", mainSearchData.searchType, mainSearchData.item, mainSearchData.priceSelect],
    async data => {
      return await axios.get("/api/data/golfGraph/search", { params: mainSearchData });
      // .then(res => res.data.result);
    },
    {
      onSuccess: res => {
        setMainSearchedTableList(res.data.result);
      },
    }
  );

  // 골프 자료 상세 검색
  const golfGraphSearchDetail = useMutation(
    data => axios.get("/api/data/golfGraph/searchDetail", { params: data }),
    {
      onSuccess: (res, variables) => {
        setExcelData(res.data.result);

        if (variables.averageSelect === "1") {
          // 시세자료생성 시세종류가 전체평균시세일 때

          setSubSearchedTableList(res.data.result);
        } else if (variables.averageSelect === "2") {
          // 시세자료생성 시세종류가 개별평균시세일 때

          const tableColumn = [...new Set(res.data.result.map(el => el.dateWithDay))].sort((a, b) =>
            a.localeCompare(b)
          );

          if (variables.searchType === "00") {
            // 검색조건이 지역별일 때

            const gubun = [...new Set(res.data.result.map(el => el.golfName))].sort((a, b) =>
              a.localeCompare(b)
            );

            const temp = Array.from({ length: gubun.length + 1 }, (_, idx) => {
              if (idx === 0) {
                return ["구분", ...tableColumn];
              } else {
                return tableColumn.reduce(
                  (acc, cur) => {
                    const temp =
                      res.data.result.find(
                        el2 => el2.golfName === gubun[idx - 1] && el2.dateWithDay === cur
                      )?.sise ?? 0;
                    return [...acc, temp];
                  },
                  [gubun[idx - 1]]
                );
              }
            });

            setSubSearchedTableList(temp);
          } else if (variables.searchType === "06") {
            // 검색조건이 인터넷제공시세일 때

            const code = [...new Set(res.data.result.map(el => el.code))];

            const golfName = [...new Set(res.data.result.map(el => el.golfName))];

            const temp = Array.from({ length: code.length + 1 }, (_, idx) => {
              if (idx === 0) {
                return ["종목코드", "회원권명", ...tableColumn];
              } else {
                return tableColumn.reduce(
                  (acc, cur) => {
                    const temp =
                      res.data.result.find(
                        el2 => el2.code === code[idx - 1] && el2.dateWithDay === cur
                      )?.sise ?? 0;
                    return [...acc, temp];
                  },
                  [code[idx - 1], golfName[idx - 1]]
                );
              }
            });

            setSubSearchedTableList(temp);
          } else {
            const temp = Array.from({ length: mainSearchedTableList.length + 1 }, (_, idx) => {
              if (idx === 0) {
                return ["구분", ...tableColumn];
              } else {
                return tableColumn.reduce(
                  (acc, cur) => {
                    const temp =
                      res.data.result.find(
                        el2 =>
                          el2.codeName === mainSearchedTableList[idx - 1].codeName &&
                          el2.dateWithDay === cur
                      )?.sise ?? 0;
                    return [...acc, temp];
                  },
                  [mainSearchedTableList[idx - 1].codeName]
                );
              }
            });

            setSubSearchedTableList(temp);
          }
        }

        setSubSearchData({ ...subSearchData, averageSelect: subSearchData.averageSelectTemp });
      },
    }
  );

  // 시세 자료 엑셀 저장
  const golfGraphDownloadExcel = useMutation(
    data =>
      axios.post("/api/data/golfGraph/downloadExcel", data, {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        fileDownload(
          res.data,
          `골프시세(각종조회-그래프)_${moment().format("YY_MM_DD_hh_mm")}.xlsx`
        );
      },
    }
  );

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setMainSearchData(golfPrice_searchDataInit_atom);
      setMainSearchedTableList([]);

      setSubSearchData(golfPrice_subDataInit_atom);
      setSubSearchedTableList([]);
    });
  }, []);

  const btn_subSearch = () => {
    golfGraphSearchDetail.mutate({
      ...mainSearchData,
      ...{
        frontDate: subSearchData.frontDate,
        backDate: subSearchData.backDate,
        dateType: subSearchData.dateType,
        averageSelect: subSearchData.averageSelectTemp,
      },
    });
  };

  const btn_excelDownload = () => {
    // golfGraphDownloadExcel.mutate(golfGraphSearchDetail.data);

    if (subSearchData.averageSelectTemp === "1") {
      golfGraphDownloadExcel.mutate(
        excelData.map(el => ({
          ...el,
          searchType: mainSearchData.searchType,
        }))
      );
    } else if (subSearchData.averageSelectTemp === "2") {
      golfGraphDownloadExcel.mutate(
        excelData.map(el => ({
          ...el,
          searchType: mainSearchData.searchType,
        }))
      );
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: golfGraphSearch.data?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 30, // 각 행의 높이 추정치
  });

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_subSearch();
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">골프회원권 시세</div>
          </div>

          <div className="row gx-3">
            <div className="col-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title m-0">검색조건</h5>
                </div>
                <div className="card-body">
                  <div className="filter_fields_row">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="filter1-field filter1-field1">
                        <label className="filter1-label">검색조건</label>
                        <select
                          className="form-select filter1-select1"
                          value={mainSearchData.searchType}
                          onChange={e => {
                            if (e.target.value === "04") {
                              // 가격대별 검색조건
                              setMainSearchData({
                                ...mainSearchData,
                                searchType: e.target.value,
                                priceSelect: "11",
                                item: "117종목",
                              });
                            } else if (
                              e.target.value === "AA" ||
                              e.target.value === "A0" ||
                              e.target.value === "A1"
                            ) {
                              setMainSearchData({
                                ...mainSearchData,
                                searchType: e.target.value,
                                priceSelect: "",
                                item: "117종목",
                              });
                            } else {
                              setMainSearchData({
                                ...mainSearchData,
                                searchType: e.target.value,
                                priceSelect: "",
                                item: "117종목",
                              });
                            }
                          }}
                        >
                          <option value="AA">전체대상</option>
                          <option value="A0">수도권(서울,경기)</option>
                          <option value="A1">비수도권</option>
                          <option value="00">지역별</option>
                          <option value="01">서울,경기,강원,충청</option>
                          <option value="02">영남,호남,제주</option>
                          {/*<option value="03">매경제공</option>*/}
                          <option value="04">가격대별</option>
                          <option value="05">주중회원권</option>
                          <option value="06">인터넷제공시세</option>
                          <option value="07">영남권</option>
                          <option value="08">주요-67개종목</option>
                          <option value="10">Golf-20 종목</option>
                          <option value="11">언론사제공(100종목)</option>
                          {/*<option value="99">사용자선택</option>*/}
                        </select>
                      </div>
                      {(mainSearchData.searchType === "AA" ||
                        mainSearchData.searchType === "A0" ||
                        mainSearchData.searchType === "A1" ||
                        mainSearchData.searchType === "04") && (
                          <div className="filter1-field filter1-field1 ps-5">
                            <label className="filter1-label">종목</label>
                            <select
                              className="form-select filter1-select1"
                              value={mainSearchData.item}
                              onChange={e => {
                                setMainSearchData({ ...mainSearchData, item: e.target.value });
                              }}
                              disabled={mainSearchData.searchType === "04"}
                            >
                              <option value="117종목">117종목</option>
                              <option value="">전체종목</option>
                            </select>
                          </div>
                        )}
                    </div>
                  </div>

                  {mainSearchData.searchType === "04" && (
                    <div className="filter_fields_row mt-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="filter1-field filter1-field1">
                          <label className="filter1-label">가격대별</label>
                          <select
                            className="form-select filter1-select1"
                            value={mainSearchData.priceSelect}
                            onChange={e => {
                              setMainSearchData({ ...mainSearchData, priceSelect: e.target.value });
                            }}
                          >
                            <option value="11">저가대</option>
                            <option value="21">중가대</option>
                            <option value="31">고가대</option>
                            <option value="41">최고가대</option>
                          </select>
                        </div>
                        {/*<div className="filter1-field filter1-field1">*/}
                        {/*  <label className="filter1-label">기간 단위</label>*/}
                        {/*  <select className="form-select filter1-select1">*/}
                        {/*    <option></option>*/}
                        {/*  </select>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-8">
              <div className="card" onKeyDown={pressEnter}>
                <div className="card-header">
                  <h5 className="card-title m-0">생성조건</h5>
                </div>
                <div className="card-body">
                  <div className="flex-center-between w-100">
                    <div className="d-flex">
                      <div className="filter1-field filter1-field1 ps-0">
                        <label className="filter1-label">검색기간</label>
                        <input
                          type="date"
                          className="form-control filter-input filter1-input2"
                          value={subSearchData?.frontDate ? subSearchData.frontDate : ""}
                          onChange={e => {
                            setSubSearchData({ ...subSearchData, frontDate: e.target.value });
                          }}
                          max="9999-12-31"
                        />
                      </div>
                      <div className="txt_daterange">~</div>
                      <div className="filter1-field filter1-field3 px-0">
                        <input
                          type="date"
                          className="form-control filter-input filter1-input2"
                          value={subSearchData?.backDate ? subSearchData.backDate : ""}
                          onChange={e => {
                            setSubSearchData({ ...subSearchData, backDate: e.target.value });
                          }}
                          max="9999-12-31"
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="filter1-field filter1-field1 ps-0">
                        <label className="filter1-label">시세종류</label>
                        <select
                          className="form-select filter1-select1"
                          value={subSearchData.averageSelectTemp}
                          onChange={e => {
                            setSubSearchData({
                              ...subSearchData,
                              averageSelectTemp: e.target.value,
                            });
                          }}
                        >
                          <option value="1">전체평균시세</option>
                          <option value="2">개별평균시세</option>
                        </select>
                      </div>
                      <div className="filter1-field filter1-field3 px-0 ms-2">
                        <label className="filter1-label">기간단위</label>
                        <select
                          className="form-select filter1-select1"
                          value={subSearchData.dateType}
                          onChange={e => {
                            setSubSearchData({ ...subSearchData, dateType: e.target.value });
                          }}
                        >
                          <option value="D">일간</option>
                          <option value="W">주간</option>
                          <option value="M">월간</option>
                          <option value="Y">연간</option>
                        </select>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={btn_subSearch}
                    >
                      시세자료생성
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="row gx-3">
          <div className="col-4">
            <div className="card card-sub">
              <div className="card-header">
                <h5 className="card-title m-0">조회건수 : {mainSearchedTableList?.length ?? 0}</h5>
              </div>
              <div className="card-body">
                <div className="table-container table-sticky h-633">
                  <table className="table table-bordered table-responsive my-table mt-0">
                    <thead>
                      <tr>
                        <th style={{ width: "18%" }}>No</th>
                        <th>회 원 권 명</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mainSearchedTableList.length !== 0 ? (
                        mainSearchedTableList.map((el, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{el.codeName}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} className="text-center">
                            검색결과가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-8">
            <div className="card card-sub">
              <div className="card-header justify-content-end">
                <h5 className="card-title m-0">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={btn_excelDownload}
                    disabled={subSearchedTableList.length === 0}
                  >
                    시세엑셀저장
                  </button>
                </h5>
              </div>
              <div className="card-body">
                <div className="overflow-auto table-container table-sticky h-633">
                  <table
                    className="table table-bordered table-responsive my-table m-0"
                    style={
                      {
                        // width: "2000px",
                      }
                    }
                  >
                    <thead>
                      {subSearchData.averageSelect === "1" && (
                        <tr>
                          <th style={{ width: "200px" }}>구분</th>
                          <th>{subSearchedTableList[0]?.codeName}</th>
                        </tr>
                      )}

                      {subSearchData.averageSelect === "2" && (
                        <tr>
                          {subSearchedTableList[0]?.map(el => (
                            <th>{el}</th>
                          ))}
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {subSearchedTableList.length !== 0 ? (
                        <>
                          {subSearchData.averageSelect === "1" &&
                            subSearchedTableList.map(el => (
                              <tr>
                                <td>{el.dateWithDay}</td>
                                <td className="text-end">{el.sise}</td>
                              </tr>
                            ))}
                          {subSearchData.averageSelect === "2" &&
                            subSearchedTableList.slice(1).map((el, index) => (
                              <tr>
                                {el.map(el2 => {
                                  return <td className="text-end">{el2}</td>;
                                })}
                              </tr>
                            ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={2}>검색결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
