import React, { useRef } from "react";
import { condoMemberList_atom } from "../../../data/atom/dataManage/condoPrice/condoPrice_atom";
import SearchResultRow from "./searchResultRow";
import TableButtonPanel from "./tableButtonPanel";
import { useAtomValue } from "jotai";
import { useVirtualizer } from "@tanstack/react-virtual";

const searchResultTable = props => {
  const { searchCondoMember } = props;
  const condoMemberList = useAtomValue(condoMemberList_atom);

  return (
    <div className="card card-main">
      <div className="card-header d-flex align-items-center justify-content-between">
        <TableButtonPanel searchCondoMember={searchCondoMember} />
      </div>

      <div className="card-body">
        <table className="table table-bordered table-responsive my-table1 m-0">
          <tbody>
            <tr>
              <th style={{ width: "5%" }}>No</th>
              <th style={{ width: "10%" }}>인터넷 View</th>
              <th style={{ width: "12.5%" }}>회원권 종목</th>
              <th style={{ width: "3%" }}>구분</th>

              <th style={{ width: "7.5%" }}>현재시세</th>
              <th style={{ width: "7.5%" }}>전일시세</th>
              <th style={{ width: "7.5%" }}>등락폭</th>
              <th style={{ width: "15%" }}>비고</th>
              <th style={{ width: "7%" }}>콘도종류</th>
              <th colSpan={2} style={{ width: "15%" }}>
                최종수정
              </th>
              <th style={{ width: "3%" }}>삭제</th>
            </tr>
            {condoMemberList?.length !== 0 ? (
              condoMemberList.map((el, idx) => {
                return <SearchResultRow key={idx} data={el} index={idx} />;
              })
            ) : (
              <tr>
                <td colSpan={12} className="text-center">
                  검색된 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(searchResultTable);
