import React from "react";
import Modal from "../../../../components/modal/Modal";
import VirtualTable from "./VirtualTable";

const ModalRegisterMember = props => {
  const { show, onHide, onShow, onSaveBtnClick, data, setData } = props;

  return (
    <>
      <Modal show={show} size={"lg"} onHide={onHide} title={"미등록 회원종목"} onShow={onShow}>
        <VirtualTable data={data} setData={setData} />
        <div className="flex-center-end pt-3">
          <button className="btn btn-outline-primary" onClick={onSaveBtnClick}>
            저장
          </button>
        </div>
      </Modal>
    </>
  );
};
export default React.memo(ModalRegisterMember);
