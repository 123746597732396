import { atom } from "jotai";
import moment from "moment/moment";

export const searchParamInit = {
  searchType: "1",
  frontDate: moment().format("YYYY-MM-DD"),
  backDate: moment().format("YYYY-MM-DD"),
  keyword: "",
};

export const searchParam_atom = atom({ ...searchParamInit });

export const condoGraphList_atom = atom([]);

export const condoGraphListHeight_atom = atom(5);
