import React, { useCallback, useRef, useState } from "react";
import SearchResultRow from "./SearchResultRow";
import SearchResultTableCaption from "./SearchResultTableCaption";
import { useAtom } from "jotai/index";
import { editedSearchResult_atom } from "../../../../../data/atom/dataManage/golfMembershipPriceManage/corporateMembershipPrice_atom";
import { useVirtualizer } from "@tanstack/react-virtual";
import HistoryModal from "./Modal/HistoryModal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";

const SearchResultTable = () => {
  //const [originData] = useAtom(originSearchResult_atom);
  const [editedData] = useAtom(editedSearchResult_atom);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalList, setModalList] = useState([]);
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: editedData.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const getSiseHistory = useMutation(
    data =>
      axios.get("/api/data/company/getSiseHistory", {
        params: { code: data },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setModalList([...result]);
      },
    }
  );

  const onClickRow = useCallback((code, title) => {
    setModalTitle(title);
    setModalShow(true);
    getSiseHistory.mutate(code);
  }, []);

  return (
    <>
      <HistoryModal
        onHide={() => setModalShow(false)}
        modalShow={modalShow}
        modalTitle={modalTitle}
        data={modalList}
      />
      <div className="card-body">
        <SearchResultTableCaption editedData={editedData} />
        <div
          className="table-container table-sticky mt-10px"
          style={{ maxHeight: "560px" }}
          ref={targetRef}
        >
          <table
            className="table table-bordered table-responsive my-table"
            style={{
              marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              marginBottom: `${rowVirtualizer.getTotalSize() -
                (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                rowVirtualizer.getVirtualItems().length * 30
                }px`,
            }}
          >
            <thead>
              <tr>
                <th rowSpan={2} style={{ width: "3%" }}>
                  No
                </th>
                <th rowSpan={2} style={{ width: "3%" }}>
                  법인시세관리대상
                </th>
                <th rowSpan={2} style={{ width: "4%" }}>
                  시세참조 개인시세*2
                </th>
                <th rowSpan={2} style={{ width: "5%" }}>
                  인터넷 View
                </th>
                <th rowSpan={2} style={{ width: "5%" }}>
                  종목 코드
                </th>
                <th rowSpan={2} style={{ width: "10%" }}>
                  회원권 종목
                </th>
                <th rowSpan={2} style={{ width: "3%" }}>
                  구분
                </th>
                <th colSpan={2} style={{ width: "12.5%" }}>
                  개인회원권 시세
                </th>
                <th colSpan={2} style={{ width: "7%" }}>
                  등락폭
                </th>
                <th rowSpan={2} style={{ width: "4%" }}>
                  비고
                </th>
                <th rowSpan={2} colSpan={2} style={{ width: "10%" }}>
                  최종수정
                </th>
                <th rowSpan={2} colSpan={2} style={{ width: "1%" }}>
                  삭제
                </th>
              </tr>
              <tr>
                <th style={{ width: "6.25%" }}>매도</th>
                <th style={{ width: "6.25%" }}>매수</th>
                <th style={{ width: "3.5%" }}>매도</th>
                <th style={{ width: "3.5%" }}>매수</th>
              </tr>
            </thead>
            <tbody>
              {editedData.length > 0 ? (
                rowVirtualizer
                  .getVirtualItems()
                  .map(item => (
                    <SearchResultRow
                      key={item.key}
                      data={editedData[item.index]}
                      index={item.index}
                      onClick={onClickRow}
                    />
                  ))
              ) : (
                <tr>
                  <td colSpan={15} className="text-center">
                    검색된 결과가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SearchResultTable;
