import React, { useState } from "react";
import { useAtom } from "jotai";
import { finalTax_atom } from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import moment from "moment/moment";

export default function FinalTax() {
  const [finalTax, setFinalTax] = useAtom(finalTax_atom);

  return (
    <div className="card card-main w-50 h-100">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title  m-0">최종 납부해야 할 세액</h5>
      </div>

      <div className="card-body">
        <div className="work_tax_total_box_list">
          <div>양도세</div>
          <div className="work_tax_total_box_list_value">
            <input
              type="text"
              className="form-control text-end"
              value={finalTax.yangdo ? `${Number(finalTax.yangdo).toLocaleString()} 원` : "0 원"}
            />
          </div>
        </div>
        <div>
          <div className="work_tax_total_box_list">
            <div>가산세</div>
            <div className="work_tax_total_box_list_value">
              <input
                type="text"
                className="form-control text-end"
                value={finalTax.gasan ? `${Number(finalTax.gasan).toLocaleString()} 원` : "0 원"}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="work_tax_total_box_list">
            <div>주민세</div>
            <div className="work_tax_total_box_list_value">
              <input
                type="text"
                className="form-control text-end"
                value={finalTax.jumin ? `${Number(finalTax.jumin).toLocaleString()} 원` : "0 원"}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="work_tax_total_box_list">
            <div>세액합계</div>
            <div className="work_tax_total_box_list_value">
              <input
                type="text"
                className="form-control text-end"
                value={finalTax.taxSum ? `${Number(finalTax.taxSum).toLocaleString()} 원` : "0 원"}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="work_tax_total_box_list">
            <div>납부마감일</div>
            <div className="work_tax_total_box_list_value">
              <input
                type="text"
                className="form-control text-end"
                value={
                  finalTax.napbuEndDate ? moment(finalTax.napbuEndDate).format("YYYY-MM-DD") : "-"
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div className="work_tax_total_box_list" style={{ marginBottom: "0px" }}>
            <div>초과일수</div>
            <div className="work_tax_total_box_list_value">
              <input
                type="text"
                className="form-control text-end"
                value={`${finalTax.overtime} 일`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
