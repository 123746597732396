import React, { useRef } from "react";
import { useAtom } from "jotai/index";
import { useAtomValue } from "jotai";
import { sportsMemberList_atom } from "../../../data/atom/dataManage/sportsPrice/sportsPrice_atom";
import TableButtonPanel from "./tableButtonPanel";
import SearchResultRow from "./searchResultRow";
import { useVirtualizer } from "@tanstack/react-virtual";

const searchResultTable = props => {
  const { searchSportsMember } = props;
  const sportsMemberList = useAtomValue(sportsMemberList_atom);

  return (
    <div className="card card-main">
      <div className="card-header d-flex align-items-center justify-content-between">
        <TableButtonPanel searchSportsMember={searchSportsMember} />
      </div>

      <div className="card-body">
        <table className="table table-bordered table-responsive my-table1 table-container table-sticky m-0">
          <tbody>
            <tr>
              <th style={{ width: "5%" }}>No</th>
              <th style={{ width: "10%" }}>인터넷 View</th>
              <th style={{ width: "12.5%" }}>회원권 종목</th>
              <th style={{ width: "3%" }}>구분</th>

              <th style={{ width: "7.5%" }}>현재시세</th>
              <th style={{ width: "7.5%" }}>전일시세</th>
              <th style={{ width: "7.5%" }}>등락폭</th>
              <th style={{ width: "15%" }}>비고</th>
              <th style={{ width: "7%" }}>위치종류</th>
              <th colSpan={2} style={{ width: "15%" }}>
                최종수정
              </th>
              <th style={{ width: "3%" }}>삭제</th>
            </tr>
            {sportsMemberList?.length !== 0 ? (
              sportsMemberList.map((el, idx) => {
                // return (
                //   <SearchResultRow
                //     key={el.key}
                //     data={sportsMemberList[el.index]}
                //     index={el.index}
                //   />
                // );
                return <SearchResultRow key={idx} data={el} index={idx} />;
              })
            ) : (
              <tr>
                <td colSpan={12} className="text-center">
                  검색된 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(searchResultTable);
