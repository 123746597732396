import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";

const Tab8 = ({ custId }) => {
  const [pageData, setPageData] = useState(null);

  const bottomGetCourse = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getCourse", {
        params: { custId: data.custId },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setPageData({ ...result });

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );

  const bottomSaveCourse = useMutation(data =>
    axios.post("/api/work/manageGolf/bottom/saveCourse", data)
  );

  useEffect(() => {
    if (custId) bottomGetCourse.mutate({ custId: custId });
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetCourse.mutate({ custId: custId });
  }, []);

  const onChangePageData = (key, value) => {
    setPageData({ ...pageData, [key]: value });
  };

  const btn_cancel = () => {
    if (confirm("취소하시겠습니까")) bottomGetCourse.mutate({ custId: custId, type: "cancel" });
  };

  const btn_save = () => {
    bottomSaveCourse.mutate(pageData);
  };

  return (
    <div className="table-container mb-3 position-relative">
      <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
        <tbody>
          <tr>
            <th style={{ width: "15%" }}>규모</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                value={pageData?.parSize ?? ""}
                onChange={e => onChangePageData("parSize", e.target.value)}
              />
            </td>
            <th style={{ width: "15%" }}>전장</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                value={pageData?.fieldLength ?? ""}
                onChange={e => onChangePageData("fieldLength", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>잔디종류</th>
            <td colSpan={3}>
              <input
                type="text"
                className="form-control table-input"
                value={pageData?.grassKind ?? ""}
                onChange={e => onChangePageData("grassKind", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>코스설계자</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                value={pageData?.designer ?? ""}
                onChange={e => onChangePageData("designer", e.target.value)}
              />
            </td>
            <th>시공회사</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                value={pageData?.drawCompany ?? ""}
                onChange={e => onChangePageData("drawCompany", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={4} className="p-3">
              <p>간략한 코스특징을 기록해 주세요</p>
              <textarea
                className="form-control"
                rows={10}
                value={pageData?.remark ?? ""}
                onChange={e => onChangePageData("remark", e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex align-items-center justify-content-end my-2">
        <button className="btn btn-outline-primary" onClick={btn_save}>
          저장
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btn_cancel}>
          취소
        </button>
      </div>
    </div>
  );
};

export default React.memo(Tab8);
