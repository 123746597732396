import React, { useMemo, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtom } from "jotai/index";
import { supportTeamSchedulesManage_searchedTableList_atom } from "../../../data/atom/goodsManage/supportTeamSchedulesManage/supportTeamSchedulesManage_atom";
import { useGetSupportManageUserList } from "../../../api/code/useGetSupportManageUserList";
import { useGetPartner } from "../../../api/code/useGetPartner";

const Table = props => {
  const { searchedTableCurrentIdx, setSearchedTableCurrentIdx } = props;
  const targetRef = useRef(null);
  const [searchedTableList, setSearchedTableList] = useAtom(
    supportTeamSchedulesManage_searchedTableList_atom
  );
  const getSupportManageUserList = useGetSupportManageUserList();
  const getPartner = useGetPartner();

  const rowVirtualizer = useVirtualizer({
    count: searchedTableList?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const supportOptionMemo = useMemo(() => {
    let tmp =
      getSupportManageUserList.data?.map(el => <option value={el.key}>{el.value}</option>) ?? [];
    tmp.push(<option value="900000000">지사지원</option>);
    tmp.push(<option value="999999999">기타 및 보류사항</option>);
    tmp.push(<option value="AAAAAAAAA">전일 개서 건</option>);
    tmp.push(<option value="BBBBBBBBB">금일 개서 건</option>);

    return tmp;
  }, [getSupportManageUserList.data]);

  const partnerOptionMemo = useMemo(() => {
    let tmp = getPartner.data?.map(el => <option value={el.value}>{el.value}</option>) ?? [];
    return tmp;
  }, [getPartner.data]);

  return (
    <div className="card-body">
      <div className="table-container table-sticky h-528" ref={targetRef}>
        <table
          className="table table-bordered table-responsive my-table"
          style={{
            width: `${searchedTableList.length !== 0 ? "2500px" : ""}`,
            marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
            marginBottom: `${
              rowVirtualizer.getTotalSize() -
              (rowVirtualizer.range?.startIndex ?? 0) * 30 -
              rowVirtualizer.getVirtualItems().length * 30
            }px`,
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "5%" }}>선택</th>
              <th style={{ width: "5%" }}>연번</th>
              <th style={{ width: "6%" }}>성명</th>
              <th style={{ width: "5%" }}>담당딜러</th>
              <th style={{ width: "40%" }}>스케줄</th>
              <th style={{ width: "50%" }}>비고</th>
            </tr>
          </thead>
          <tbody>
            {searchedTableList.length !== 0 ? (
              rowVirtualizer.getVirtualItems().map((virtualRow, index) => (
                <tr
                  className={`cursor-pointer ${
                    searchedTableCurrentIdx === virtualRow.index ? "bg-lightblue" : ""
                  }`}
                  onClick={() => setSearchedTableCurrentIdx(virtualRow.index)}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={searchedTableList[virtualRow.index].checked ?? false}
                      onChange={e => {
                        const temp = [...searchedTableList];
                        temp[virtualRow.index].checked = e.target.checked;
                        setSearchedTableList(temp);
                      }}
                    />
                  </td>
                  <td>{virtualRow.index + 1}</td>
                  <td>
                    <select
                      className="form-select"
                      value={searchedTableList[virtualRow.index].sawonId}
                      onChange={e => {
                        const temp = [...searchedTableList];
                        temp[virtualRow.index].sawonId = e.target.value;
                        temp[virtualRow.index].sawonName =
                          e.target.options[e.target.selectedIndex].text;
                        setSearchedTableList(temp);
                      }}
                    >
                      {supportOptionMemo}
                    </select>
                  </td>
                  <td>
                    {/*
                    <input
                      type="text"
                      className="form-control table-input"
                      value={searchedTableList[virtualRow.index].partner}
                      onChange={e => {
                        const temp = [...searchedTableList];
                        temp[virtualRow.index].partner = e.target.value;
                        setSearchedTableList(temp);
                      }}
                    />*/}
                    <select
                      className="form-select"
                      value={searchedTableList[virtualRow.index].partner}
                      onChange={e => {
                        const temp = [...searchedTableList];
                        temp[virtualRow.index].partner = e.target.value;
                        setSearchedTableList(temp);
                      }}
                    >
                      {partnerOptionMemo}
                      {getPartner.data.find(
                        el => el.key === searchedTableList[virtualRow.index].partner
                      ) ? (
                        ""
                      ) : (
                        <option value={searchedTableList[virtualRow.index].partner}>
                          {searchedTableList[virtualRow.index].partner}
                        </option>
                      )}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={searchedTableList[virtualRow.index].workSchedule}
                      onChange={e => {
                        const temp = [...searchedTableList];
                        temp[virtualRow.index].workSchedule = e.target.value;
                        setSearchedTableList(temp);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={searchedTableList[virtualRow.index].remark}
                      onChange={e => {
                        const temp = [...searchedTableList];
                        temp[virtualRow.index].remark = e.target.value;
                        setSearchedTableList(temp);
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>검색결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(Table);
