import EmpModal from "../../../../components/modal/Modal";
import { useEffect, useRef } from "react";

export const SMSTransferHistoryModal = ({ isModalOpen, setIsModalOpen }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, []);

  return (
    <EmpModal
      show={isModalOpen.isOpen}
      width="100%"
      minWidth="475px"
      title="상세내용"
      onHide={() => setIsModalOpen({ isOpen: false, data: null })}
      strClass="modal-xl"
      size="xl"
    >
      <div className="card card-main mt-0">
        <textarea
          ref={textareaRef}
          value={isModalOpen.data.msg}
          readOnly={true}
          style={{ border: "none" }}
        />
      </div>
    </EmpModal>
  );
};
