import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  dailyCall_mainData_atom,
  dailyCall_searchData_atom,
  dailyCall_subData1_atom,
  dailyCall_subData2_atom,
  dailyCallDistributionStatus_dateOption,
} from "../../../data/atom/workManage/dailyCall/dailyCall_atom";
import { DailyCallModal } from "./modal/dailyCallModal";

export const DailyCall = () => {
  const [table1, setTable1] = useState("일자별 현황");
  const [table2, setTable2] = useState("개인별-주간현황");

  const [searchData, setSearchData] = useAtom(dailyCall_searchData_atom);
  const [mainData, setMainData] = useAtom(dailyCall_mainData_atom);
  const [subData1, setSubData1] = useAtom(dailyCall_subData1_atom);
  const [subData2, setSubData2] = useAtom(dailyCall_subData2_atom);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => {
  //   setShow(true);
  // };

  // 데이터 생성 버튼
  const callDbdCreateNewData = useMutation(
    data => axios.post("/api/work/callDbd/createNewData", data),
    {
      onSuccess: res => {
        callDbdSearch.mutate({ keyword: searchData.keyword });
        callDbdGetByDay.mutate({ keyword: searchData.keyword });
        callDbdGetPrvWeek.mutate({ keyword: searchData.keyword });
      },
      onError: err => {
        if (err.response.data.code === "-5") {
          if (window.confirm(err.response.data.message)) {
            callDbdCreateNewData.mutate({ ...searchData, type: "Y" });
          } else {
            alert("데이타 생성이 취소되었습니다!");
            callDbdSearch.mutate({ keyword: searchData.keyword });
            callDbdGetByDay.mutate({ keyword: searchData.keyword });
            callDbdGetPrvWeek.mutate({ keyword: searchData.keyword });
          }
        }
      },
    }
  );

  // 조회
  const callDbdSearch = useMutation(
    data => axios.get("/api/work/callDbd/search", { params: data }),
    {
      onSuccess: res => {
        callDbdGetPrvWeek.mutate({ keyword: searchData.keyword });
        callDbdGetByDay.mutate({ keyword: searchData.keyword });

        if (res.data.result.length === 0) {
          setMainData([]);
        } else {
          setMainData(res.data.result);
        }
      },
      onError: err => {},
    }
  );

  // 일자별현황 조회
  const callDbdGetByDay = useMutation(
    data => axios.get("/api/work/callDbd/getByDay", { params: data }),
    {
      onSuccess: res => {
        setSubData1(res.data.result);
      },
      onError: err => {},
    }
  );

  // 월별현황 조회
  const callDbdGetByMonth = useMutation(
    data => axios.get("/api/work/callDbd/getByMonth", { params: data }),
    {
      onSuccess: res => {
        setSubData1(res.data.result);
      },
      onError: err => {},
    }
  );

  // 개인별-주간현황 조회
  const callDbdGetPrvWeek = useMutation(
    data => axios.get("/api/work/callDbd/getPrvWeek", { params: data }),
    {
      onSuccess: res => {
        setSubData2(res.data.result);
      },
      onError: err => {},
    }
  );

  // 개인별-월간현황 조회
  const callDbdGetPrvMonth = useMutation(
    data => axios.get("/api/work/callDbd/getPrvMonth", { params: data }),
    {
      onSuccess: res => {
        setSubData2(res.data.result);
      },
      onError: err => {},
    }
  );

  useEffect(() => {
    if (table1 === "일자별 현황") {
      callDbdGetByDay.mutate({ keyword: searchData.keyword });
    }
    if (table1 === "월별현황") {
      callDbdGetByMonth.mutate({ keyword: searchData.keyword });
    }
  }, [table1]);

  useEffect(() => {
    if (table2 === "개인별-주간현황") {
      callDbdGetPrvWeek.mutate({ keyword: searchData.keyword });
    }
    if (table2 === "개인별-월간현황") {
      callDbdGetPrvMonth.mutate({ keyword: searchData.keyword });
    }
  }, [table2]);

  const btn_createNewData = () => {
    callDbdCreateNewData.mutate(searchData);
    setTable1("일자별 현황");
    setTable2("개인별-주간현황");
  };

  useEffect(() => {
    callDbdSearch.mutate(searchData);
  }, []);

  const pressEnter = e => {
    if (e.key === "Enter") {
      callDbdSearch.mutate(searchData);
    }
  };

  return (
    <>
      {/*<Modal show={show} onHide={handleClose} centered>*/}
      {/*  <Modal.Header closeButton>*/}
      {/*    <Modal.Title>확인</Modal.Title>*/}
      {/*  </Modal.Header>*/}
      {/*  <Modal.Body>*/}
      {/*    <div className="modal-box1">*/}
      {/*      <p>*/}
      {/*        ( )건의 데이터가 존재합니다.*/}
      {/*        <br />*/}
      {/*        삭제 후 재생성 하시겠습니까?*/}
      {/*      </p>*/}
      {/*      <div className="d-flex align-items-center justify-content-center">*/}
      {/*        <button className="btn btn-outline-primary mx-3" onClick={handleClose}>*/}
      {/*          예*/}
      {/*        </button>*/}
      {/*        <button className="btn btn-outline-primary mx-3" onClick={handleClose}>*/}
      {/*          아니오*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Modal.Body>*/}
      {/*</Modal>*/}

      {isModalOpen && <DailyCallModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}

      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">일일 call 배분현황</div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-between" style={{ gap: "14px" }}>
              <div className="w-100">
                <div className="card card-main">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title  m-0">검색 조건</h5>
                  </div>
                  <div
                    className="card-body"
                    onKeyDown={e => {
                      pressEnter(e);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="filter1-field filter1-field1 p-0">
                        <label className="filter1-label">조회 일자</label>
                        <input
                          type="date"
                          className="form-control filter-input filter1-input1"
                          value={searchData.keyword}
                          onChange={e => {
                            setSearchData({ ...searchData, keyword: e.target.value });
                          }}
                          min="1900-01-01"
                          max="2099-12-31"
                        />
                        <button
                          className="btn btn-outline-primary btn-filter-outline-primary ms-2"
                          onClick={() => {
                            callDbdSearch.mutate(searchData);
                          }}
                        >
                          <img
                            className="me-2"
                            src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                            alt=""
                          />
                          검색
                        </button>
                      </div>

                      <div className="d-flex align-items-center justify-content-start">
                        <button
                          className="btn btn-outline-primary btn-filter-outline-primary"
                          onClick={btn_createNewData}
                        >
                          데이터 생성
                        </button>
                        <button
                          className="btn btn-outline-primary btn-filter-outline-primary m-0"
                          onClick={() => setIsModalOpen(true)}
                        >
                          입력전용
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card-main">
                  <div className="card-body">
                    <div className="table-container table-sticky h-528">
                      <table className="table table-bordered table-responsive my-table mt-0">
                        <thead>
                          <tr>
                            {/*<th style={{ width: "10%" }}>No.</th>*/}
                            <th style={{ width: "15%" }}>부서구분</th>
                            <th style={{ width: "15%" }}>성명(부서명)</th>
                            <th style={{ width: "15%" }}>신규문의</th>
                            <th style={{ width: "15%" }}>신규문의 시간</th>
                            <th style={{ width: "15%" }}>고객 문의</th>
                            <th>기타연결</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mainData?.length !== 0 ? (
                            mainData?.map(el => (
                              <tr>
                                <td>{el.gubunName}</td>
                                <td>{el.jobName}</td>
                                <td>{el.callCntNew}</td>
                                <td>{el.callCntTime}</td>
                                <td>{el.callCntOld}</td>
                                <td>{el.callCntEtc}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6}>검색결과가 없습니다.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100">
                <div className="card card-sub">
                  <div className="card-body">
                    <Tabs
                      id="controlled-tab-example2"
                      activeKey={table1}
                      onSelect={k => setTable1(k)}
                      className="tab-blocks m-0"
                    >
                      <Tab eventKey="일자별 현황" title="일자별 현황">
                        <div
                          className={`table-container table-sticky  h-333 `}
                          id="scroll-table-body"
                        >
                          <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                            <thead>
                              <tr>
                                {/*<th rowSpan={2} style={{ width: "10%" }}>*/}
                                {/*  No.*/}
                                {/*</th>*/}
                                <th rowSpan={2} style={{ width: "15%" }}>
                                  처리 일자
                                </th>
                                <th rowSpan={2} style={{ width: "15%" }}>
                                  계
                                </th>
                                <th colSpan={3} style={{ width: "15%" }}>
                                  문의
                                </th>
                                <th rowSpan={2} style={{ width: "15%" }}>
                                  기타
                                </th>
                              </tr>
                              <tr>
                                <th>소계</th>
                                <th>신규</th>
                                <th>고객</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subData1.length !== 0 ? (
                                subData1.map(el => (
                                  <tr>
                                    <td>{el.dayName}</td>
                                    <td>{el.callCntSum}</td>
                                    <td>{el.callHap}</td>
                                    <td>{el.callCntNew}</td>
                                    <td>{el.callCntOld}</td>
                                    <td>{el.callCntEtc}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>검색결과가 없습니다.</td>
                                </tr>
                              )}
                            </tbody>
                            {/*<tfoot>*/}
                            {/*  <tr>*/}
                            {/*    /!*<td></td>*!/*/}
                            {/*    <td>합계</td>*/}
                            {/*    <td>814</td>*/}
                            {/*    <td>762</td>*/}
                            {/*    <td>233</td>*/}
                            {/*    <td>529</td>*/}
                            {/*    <td>52</td>*/}
                            {/*  </tr>*/}
                            {/*</tfoot>*/}
                          </table>
                        </div>
                      </Tab>
                      <Tab eventKey="월별현황" title="월별현황">
                        <div
                          className={`table-container table-sticky  h-333 `}
                          id="scroll-table-body"
                        >
                          <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                            <thead>
                              <tr>
                                {/*<th rowSpan={3}>No.</th>*/}
                                <th rowSpan={3}>년도/월</th>
                                <th rowSpan={3}>계</th>
                                <th colSpan={4}>서울본사</th>
                                <th colSpan={3}>분당지사</th>
                              </tr>
                              <tr>
                                <th colSpan={3}>문의</th>
                                <th rowSpan={2}>기타</th>
                                <th colSpan={3}>문의</th>
                              </tr>
                              <tr>
                                <th>소계</th>
                                <th>신규</th>
                                <th>고객</th>
                                <th>소계</th>
                                <th>신규</th>
                                <th>고객</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subData1.length !== 0 ? (
                                subData1.map(el => (
                                  <tr>
                                    <td>{el.dayName}</td>
                                    <td>{el.callCntSum}</td>
                                    <td>{el.callHap}</td>
                                    <td>{el.callCntNew}</td>
                                    <td>{el.callCntOld}</td>
                                    <td>{el.callCntEtc}</td>
                                    <td>{el.callCntOldBSum}</td>
                                    <td>{el.callCntNewB}</td>
                                    <td>{el.callCntOldB}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>검색결과가 없습니다.</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                <div className="card card-sub mt-14px">
                  <div className="card-body">
                    <Tabs
                      id="controlled-tab-example2"
                      activeKey={table2}
                      onSelect={k => setTable2(k)}
                      className="tab-blocks m-0"
                    >
                      <Tab eventKey="개인별-주간현황" title="개인별-주간현황">
                        <div className="d-flex justify-content-between">
                          <div
                            className={`table-container table-sticky  h-333 `}
                            id="scroll-table-body"
                          >
                            <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                              <thead>
                                <tr>
                                  {/*<th rowSpan={2} style={{ width: "10%" }}>*/}
                                  {/*  No.*/}
                                  {/*</th>*/}
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    성명
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    계
                                  </th>
                                  <th colSpan={3} style={{ width: "15%" }}>
                                    문의
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    기타
                                  </th>
                                </tr>
                                <tr>
                                  <th>소계</th>
                                  <th>신규</th>
                                  <th>고객</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subData2?.left?.length > 0 ? (
                                  subData2?.left.map(item => {
                                    return (
                                      <tr
                                        className={`${
                                          item?.jobName === "소계" && "bg-lightyellow"
                                        }`}
                                      >
                                        <td>{item?.jobName}</td>
                                        <td>{item?.callCntSum}</td>
                                        <td>{item?.callHap}</td>
                                        <td>{item?.callCntNew}</td>
                                        <td>{item?.callCntOld}</td>
                                        <td>{item?.callCntEtc}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>조회 결과가 없습니다.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className={`table-container table-sticky  h-333 `}
                            id="scroll-table-body"
                          >
                            <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                              <thead>
                                <tr>
                                  {/*<th rowSpan={2} style={{ width: "10%" }}>*/}
                                  {/*  No.*/}
                                  {/*</th>*/}
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    성명
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    계
                                  </th>
                                  <th colSpan={3} style={{ width: "15%" }}>
                                    문의
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    기타
                                  </th>
                                </tr>
                                <tr>
                                  <th>소계</th>
                                  <th>신규</th>
                                  <th>고객</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subData2?.right?.length > 0 ? (
                                  subData2?.right.map(item => {
                                    return (
                                      <tr
                                        className={`${
                                          item?.jobName === "소계" && "bg-lightyellow"
                                        }`}
                                      >
                                        <td>{item?.jobName}</td>
                                        <td>{item?.callCntSum}</td>
                                        <td>{item?.callHap}</td>
                                        <td>{item?.callCntNew}</td>
                                        <td>{item?.callCntOld}</td>
                                        <td>{item?.callCntEtc}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>조회 결과가 없습니다.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="개인별-월간현황" title="개인별-월간현황">
                        <div className="d-flex justify-content-between">
                          <div
                            className={`table-container table-sticky  h-333`}
                            id="scroll-table-body"
                          >
                            <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                              <thead>
                                <tr>
                                  {/*<th rowSpan={2} style={{ width: "10%" }}>*/}
                                  {/*  No.*/}
                                  {/*</th>*/}
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    성명
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    계
                                  </th>
                                  <th colSpan={3} style={{ width: "15%" }}>
                                    문의
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    기타
                                  </th>
                                </tr>
                                <tr>
                                  <th>소계</th>
                                  <th>신규</th>
                                  <th>고객</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subData2?.left?.length > 0 ? (
                                  subData2?.left.map(item => {
                                    return (
                                      <tr
                                        className={`${
                                          item?.jobName === "소계" && "bg-lightyellow"
                                        }`}
                                      >
                                        <td>{item?.jobName}</td>
                                        <td>{item?.callCntSum}</td>
                                        <td>{item?.callHap}</td>
                                        <td>{item?.callCntNew}</td>
                                        <td>{item?.callCntOld}</td>
                                        <td>{item?.callCntEtc}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>조회 결과가 없습니다.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className={`table-container table-sticky  h-333`}
                            id="scroll-table-body"
                          >
                            <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                              <thead>
                                <tr>
                                  {/*<th rowSpan={2} style={{ width: "10%" }}>*/}
                                  {/*  No.*/}
                                  {/*</th>*/}
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    성명
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    계
                                  </th>
                                  <th colSpan={3} style={{ width: "15%" }}>
                                    문의
                                  </th>
                                  <th rowSpan={2} style={{ width: "15%" }}>
                                    기타
                                  </th>
                                </tr>
                                <tr>
                                  <th>소계</th>
                                  <th>신규</th>
                                  <th>고객</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subData2?.right?.length > 0 ? (
                                  subData2?.right.map(item => {
                                    return (
                                      <tr
                                        className={`${
                                          item?.jobName === "소계" && "bg-lightyellow"
                                        }`}
                                      >
                                        <td>{item?.jobName}</td>
                                        <td>{item?.callCntSum}</td>
                                        <td>{item?.callHap}</td>
                                        <td>{item?.callCntNew}</td>
                                        <td>{item?.callCntOld}</td>
                                        <td>{item?.callCntEtc}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>조회 결과가 없습니다.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
