import { useRef, useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  reportOutpu_row,
  reportOutput_renderData,
  reportOutput_searchData,
  reportOutput_searchOption,
  reportOutput_searchedDate,
} from "../../../../data/atom/goodsManage/annualLeaveManage/reportOutput/reportOutput_atom";
import moment from "moment";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";

export const ReportOutput = () => {
  const { data: selectBoxData } = useQuery(
    ["getJisaGubn"],
    async () =>
      await axios.get("/api/schedule/commutingReport/getJisaGubn").then(res => {
        return res?.data?.result;
      })
  );
  const [searchOption, setSearchOption] = useAtom(reportOutput_searchOption);
  const [searchData, setSearchData] = useAtom(reportOutput_searchData);
  const [renderData, setRenderData] = useAtom(reportOutput_renderData);
  const [searchedDate, setSearchedDate] = useAtom(reportOutput_searchedDate);

  const [bottom, setBottom] = useState(null);
  const bottomObserver = useRef(null);
  const { addTabClosedEvent } = useRecentTabEvent();

  // state 초기화
  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        fromDate: moment(new Date()).format("YYYY-MM-DD"),
        jisaGubun: "999",
        satSun: "Y",
        toDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 1,
      });
      setSearchData([]);
      setRenderData([]);
    });
  }, []);

  // API - 무장 셋트 해제내역 검색
  const search = useMutation(
    data =>
      axios.get("/api/schedule/commutingReport/search", {
        params: {
          ...data,
          fromDate: moment(data.fromDate).format("YYYYMMDD"),
          toDate: moment(data.toDate).format("YYYYMMDD"),
        },
      }),
    {
      onSuccess: (res, payload) => {
        setSearchedDate({ fromDate: payload?.fromDate, toDate: payload?.toDate });
        let dataList = res?.data?.result;
        if (payload.page === 1) {
          setSearchData(dataList);
        } else {
          setSearchData([...searchData, ...dataList]);
          dataList = [...searchData, ...dataList];
        }

        const newObj = dataList.reduce((acc, item) => {
          if (acc[item?.edate]) {
            acc[item.edate].push(item);
          } else {
            acc[item.edate] = [item];
          }
          return acc;
        }, {});

        let newList = [];
        for (let objKey in newObj) {
          if (newObj.hasOwnProperty(objKey)) {
            newList.push({ key: objKey, value: newObj[objKey], count: newObj[objKey].length });
          }
        }

        const resList = newList.map(lst => {
          const modifyObj = lst.value.reduce((acc, item) => {
            if (acc[item.florName]) {
              acc[item.florName].push(item);
            } else {
              acc[item.florName] = [item];
            }
            return acc;
          }, {});

          let modifyLst = [];

          for (let objKey in modifyObj) {
            if (modifyObj.hasOwnProperty(objKey)) {
              modifyLst.push({
                key: objKey,
                value: modifyObj[objKey],
                count: modifyObj[objKey].length,
              });
            }
          }
          return { ...lst, value: modifyLst };
        });

        setRenderData(resList);
      },
    }
  );

  const changeHandler = e => {
    const { value, name } = e.target;
    setSearchOption({ ...searchOption, [name]: value });
  };
  const pressEnter = e => {
    if (e.key === "Enter") {
      callSearch();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      async entries => {
        if (entries[0].isIntersecting) {
          setSearchOption(prev => {
            search.mutate({ ...searchOption, page: prev.page + 1 });

            return { ...searchOption, page: prev.page + 1 };
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, [searchOption]);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  const callSearch = () => {
    setSearchData([]);
    setRenderData([]);
    setSearchOption(prev => {
      return { ...searchOption, page: 1 };
    });
    search.mutate({ ...searchOption, page: 1 });
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">검색</div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">근무기간</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    min="1900-01-01"
                    max="2099-12-31"
                    name="fromDate"
                    value={searchOption.fromDate}
                    onChange={e => {
                      changeHandler(e);
                    }}
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    min={searchOption.fromDate}
                    max="2099-12-31"
                    name="toDate"
                    value={searchOption.toDate}
                    onChange={e => {
                      changeHandler(e);
                    }}
                  />
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label m-0">출력구분 : 무장 셋트, 해제 내역</label>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">지사구분</label>
                  <select
                    className="form-select filter1-select1"
                    name="jisaGubun"
                    value={searchOption.jisaGubun}
                    onChange={e => {
                      if (e.target.value === "L0" || e.target.value === "L1") {
                        alert("정확한 지사를 선택해주세요.");
                      } else {
                        changeHandler(e);
                      }
                    }}
                  >
                    {selectBoxData?.map(item => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select>
                </div>
                <div className="filter1-field filter1-field1">
                  <input
                    type="checkbox"
                    id="chk-1"
                    className="form-check-input"
                    name="satSun"
                    checked={searchOption?.satSun === "Y" ? true : false}
                    onClick={e => {
                      if (searchOption?.satSun === "Y") {
                        setSearchOption(prev => {
                          return { ...searchOption, satSun: "N" };
                        });
                      } else {
                        setSearchOption(prev => {
                          return { ...searchOption, satSun: "Y" };
                        });
                      }
                    }}
                  />
                  <label className="filter1-label" htmlFor="chk-1">
                    주말,공휴일 포함
                  </label>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={() => {
                  callSearch();
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0">■ 무장 셋트, 해제 내역</h5>
            <div className="d-flex align-items-center justify-content-end">
              <div className="tab1-row1-count">
                {searchData.length > 0 &&
                  (searchedDate?.fromDate &&
                    "기간: " + moment(searchedDate?.fromDate).format("YYYY-MM-DD") + " ~ ") +
                    (searchedDate?.toDate && moment(searchedDate?.toDate).format("YYYY-MM-DD"))}
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="row mt-3">
              <div className="col-12">
                <div className={`table-container table-sticky h-633`} id="scroll-table-body">
                  <table className="table table-bordered table-responsive my-table my-0">
                    <thead>
                      <tr>
                        <th style={{ width: "16.5%" }}>일자</th>
                        <th style={{ width: "23.47%" }}>구 분</th>
                        <th style={{ width: "26%" }}>성명</th>
                        <th style={{ width: "10.43" }}>상태</th>
                        <th>시간</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderData?.length > 0 ? (
                        renderData.map((data, _dataIdx) => {
                          return data.value.map((item, _itemIdx) => {
                            return item.value.map((res, _resIdx) => {
                              return (
                                <tr>
                                  {_resIdx === 0 && _itemIdx === 0 && (
                                    <td rowSpan={data.count}>
                                      {moment(data.key).format("YYYY-MM-DD")}
                                    </td>
                                  )}
                                  {_resIdx === 0 && <td rowSpan={item.count}>{item?.key}</td>}
                                  <td>{res?.ename}</td>
                                  <td
                                    className={
                                      res?.emode === "셋트"
                                        ? "report-output-color-check"
                                        : "report-output-color-uncheck"
                                    }
                                  >
                                    {res?.emode}
                                  </td>
                                  <td>
                                    {res?.etime?.replace(/\B(?<!\.\d*)(?=(\d{2})+(?!\d))/g, ":")}
                                  </td>
                                </tr>
                              );
                            });
                          });
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>조회 결과가 없습니다.</td>
                        </tr>
                      )}
                      {searchData.length > 0 && <div ref={setBottom} />}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
