import React, { useEffect } from "react";
import { useAtom, useSetAtom } from "jotai/index";
import {
  carDetail_atom,
  carDriveInfo_atom,
  carDriveInfoEditedRowList_atom,
  carFuelInfo_atom,
  carFuelInfoEditedRowList_atom,
  carList_atom,
  clickedRow_atom,
  isRegisterMode_atom,
  searchParam_atom,
  searchParamInit,
  tabKey_atom,
  tableRowLimit_atom,
} from "../../../../data/atom/dataManage/carManage/carMaster_atom";
import CarMasterDetail from "./carMasterDetail";
import CarMasterList from "./carMasterList";
import { useGetCarMasterOilList } from "../../../../api/code/useGetCarMasterOilList";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment/moment";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";

export const CarMaster = () => {
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const setTabKey = useSetAtom(tabKey_atom);
  const [tableRowLimit, setTableRowLimit] = useAtom(tableRowLimit_atom);
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carList, setCarList] = useAtom(carList_atom);
  const setCarDetail = useSetAtom(carDetail_atom);
  const setDriveInfo = useSetAtom(carDriveInfo_atom);
  const setDriveInfoEditedRowList = useSetAtom(carDriveInfoEditedRowList_atom);
  const setFuelInfo = useSetAtom(carFuelInfo_atom);
  const setFuelInfoEditedRowList = useSetAtom(carFuelInfoEditedRowList_atom);
  const [isRegisterMode, setIsRegisterMode] = useAtom(isRegisterMode_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const getCarMasterOilList = useGetCarMasterOilList();
  const searchCarList = useMutation(
    data => axios.get("/api/data/carMaster/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setCarList(data);
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    if (key === "tableRowLimit") {
      setTableRowLimit(value);
    } else if (key === "check") {
      if (value) {
        setSearchParam({ ...searchParam, check: "Y" });
      } else {
        setSearchParam({ ...searchParam, check: "N" });
      }
    } else {
      setSearchParam({ ...searchParam, [key]: value });
    }
  };

  const btnRegister = () => {
    setIsRegisterMode(true);
    setClickedRow({});
    setDriveInfo([]);
    setFuelInfo([]);
    setCarDetail({
      carrClas: "",
      carrName: "",
      carrNumb: "",
      carrSize: 0,
      carrSral: "",
      carrYear: "",
      engrGubn: "",
      fuelLter: 0,
      guipDate: moment().format("YYYY-MM-DD"),
      guipPric: 0,
      jisaName: "",
      ownrGubn: "",
      remark: "",
      usesFlag: "Y",
      usesName: "",
      newCheck: true,
    });
  };
  const btnSearch = () => {
    searchCarList.mutate(searchParam);
  };
  const rowClick = el => {
    if (isRegisterMode) {
      alert("등록 작업을 마무리 해주십시오.");
    } else {
      setClickedRow(el);
    }
  };
  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...searchParamInit });
      setTabKey("운행정보");
      setTableRowLimit("5");
      setClickedRow({});
      setCarList([]);
      setCarDetail({});
      setDriveInfo([]);
      setDriveInfoEditedRowList([]);
      setFuelInfo([]);
      setFuelInfoEditedRowList([]);
      setIsRegisterMode(false);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">자동차 관리 검색 조건</div>
            <div className="filter-buttons">
              <button className="btn btn-primary" onClick={btnRegister} disabled={isRegisterMode}>
                등록
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div
                className="d-flex align-items-center justify-content-between"
                onKeyDown={pressEnter}
              >
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">차량번호</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.carNumberKeyword}
                    onChange={e => onChangeEventHandler("carNumberKeyword", e.target.value)}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">소유구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.ownGubun}
                    onChange={e => onChangeEventHandler("ownGubun", e.target.value)}
                  >
                    <option value="A">전체</option>
                    <option value="R">렌트</option>
                    <option value="L">리스</option>
                    <option value="C">법인</option>
                    <option value="O">자차</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">연료구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.oilGubun}
                    onChange={e => onChangeEventHandler("oilGubun", e.target.value)}
                  >
                    <option value="00">전 체</option>
                    {getCarMasterOilList.data?.map(el => {
                      return <option value={el.key}>{el.value}</option>;
                    })}
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">차 &nbsp; 명</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.carName}
                    onChange={e => onChangeEventHandler("carName", e.target.value)}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <input
                    type="checkbox"
                    id="chk-1"
                    className="form-check-input"
                    name="checkbox-option"
                    checked={searchParam.check === "Y"}
                    onChange={e => onChangeEventHandler("check", e.target.checked)}
                  />
                  <label className="filter1-label" htmlFor="chk-1">
                    사용 안하는 차량 포함
                  </label>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-main">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="tab1-row1-count">검색 결과 : {carList.length} 건</div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={tableRowLimit}
                  onChange={e => onChangeEventHandler("tableRowLimit", e.target.value)}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>
            <div
              className={`table-container table-sticky mt-10px mb-0
                  ${tableRowLimit === "5" && "h-183"}
                  ${tableRowLimit === "10" && "h-333"}
                  ${tableRowLimit === "20" && "h-633"}
                  `}
            >
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>연번</th>
                    <th style={{ width: "15%" }}>관리지사</th>
                    <th style={{ width: "15%" }}>사용자</th>
                    <th style={{ width: "25%" }}>차량번호</th>
                    <th style={{ width: "25%" }}>차명</th>
                    <th>사용유무</th>
                  </tr>
                </thead>
                <tbody>
                  {carList.length !== 0 ? (
                    carList.map((el, idx) => {
                      return (
                        <tr
                          className={`cursor-pointer ${
                            clickedRow.carrNumb === el.carrNumb && "bg-blue"
                          }`}
                          onClick={() => rowClick(el)}
                        >
                          <td>{idx + 1}</td>
                          <td>{el.jisaName}</td>
                          <td>{el.usesName}</td>
                          <td>{el.carrNumb}</td>
                          <td>{el.carrName}</td>
                          <td>{el.usesFlag}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {clickedRow.carrNumb !== undefined ? (
          <>
            <CarMasterDetail
              searchCarList={searchCarList}
              getCarMasterOilList={getCarMasterOilList}
            />

            <CarMasterList getCarMasterOilList={getCarMasterOilList} />
          </>
        ) : isRegisterMode ? (
          <CarMasterDetail
            searchCarList={searchCarList}
            getCarMasterOilList={getCarMasterOilList}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
