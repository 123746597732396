import React from "react";
import { useAtom } from "jotai";
import {
  governCurrentRow_atom,
  governGolfKeyword_atom,
  governGolfList_atom,
} from "../../../../data/atom/workManage/acquTax/acquTax_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";

export default function GovernSearch() {
  const [governGolfKeyword, setGovernGolfKeyword] = useAtom(governGolfKeyword_atom);
  const [governGolfList, setGovernGolfList] = useAtom(governGolfList_atom);
  const [governCurrentRow, setGovernCurrentRow] = useAtom(governCurrentRow_atom);

  const searchGovern = useMutation(
    data => axios.get("/api/work/acquisitionTax/searchGuanChung", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setGovernGolfList(data);
        if (data.length !== 0) {
          setGovernCurrentRow(data?.[0]);
        }
      },
    }
  );

  const btnSearch = () => {
    searchGovern.mutate({ company: governGolfKeyword });
  };
  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };
  const clickRow = el => {
    setGovernCurrentRow(el);
  };

  return (
    <div className="col-right">
      <div className="card card-main mt-0">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title  m-0">관할 관청 검색</h5>
        </div>
        <div className="card-body">
          <div className="filter_fields_row d-flex justify-content-between" onKeyDown={pressEnter}>
            <div className="d-flex align-items-center">
              <label className="filter1-label m-0" style={{ minWidth: "55px" }}>
                골프장명
              </label>
              &nbsp;
              <input
                type="text"
                className="form-control filter-input filter1-input1"
                value={governGolfKeyword}
                onChange={e => setGovernGolfKeyword(e.target.value)}
              />
            </div>
            <button
              className="btn btn-outline-primary btn-filter-outline-primzary m-0"
              onClick={btnSearch}
            >
              <img
                className="me-2"
                src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                alt=""
              />
              검색
            </button>
          </div>

          <div className="table-container table-sticky h-183 mt-2">
            <table className="table table-bordered table-responsive my-table mt-0">
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>연번</th>
                  <th>골프장명</th>
                  <th>관할관청명</th>
                </tr>
              </thead>
              <tbody>
                {governGolfList.length !== 0 ? (
                  governGolfList.map((el, idx) => {
                    return (
                      <tr
                        className={`cursor-pointer ${
                          el.custId === governCurrentRow.custId && el.golf === governCurrentRow.golf
                            ? "bg-blue"
                            : ""
                        }`}
                        onClick={() => clickRow(el)}
                      >
                        <td>{idx + 1}</td>
                        <td>{el.golf}</td>
                        <td>{el.company}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>검색된 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="table-tabs-sub pt-4">관할 관청 정보</div>
          <table className="table table-bordered table-responsive my-table1 my-3">
            <tbody>
              <tr>
                <th style={{ width: "20%" }}>관리번호</th>
                <td className="text-center" colSpan={2}>
                  <input
                    type="text"
                    className="form-control border-0"
                    value={governCurrentRow.custId}
                  />
                </td>
                <th style={{ width: "18%" }}>관청명</th>
                <td className="text-center" style={{ width: "32%" }}>
                  <input
                    type="text"
                    className="form-control border-0"
                    value={governCurrentRow.company}
                  />
                </td>
              </tr>
              <tr>
                <th>담당자</th>
                <td className="text-center" colSpan={2}>
                  <input
                    type="text"
                    className="form-control border-0"
                    value={governCurrentRow.kname}
                  />
                </td>
                <th>부서명</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control border-0"
                    value={governCurrentRow.devision}
                  />
                </td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td className="text-center" colSpan={2}>
                  <input
                    type="text"
                    className="form-control border-0"
                    value={governCurrentRow.ctel}
                  />
                </td>
                <th>팩스번호</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control border-0"
                    value={governCurrentRow.cfax}
                  />
                </td>
              </tr>
              <tr>
                <th>인터넷</th>
                <td colSpan={4}>
                  <div className="text-start d-flex justify-content-between">
                    <input
                      type="text"
                      className="form-control border-0"
                      value={governCurrentRow.siteUrl}
                    />
                    <img
                      className="h-25px cursor-pointer"
                      src={process.env.PUBLIC_URL + "/images/icon-arrow-btn.png"}
                      onClick={() =>
                        window.open(
                          `http://${governCurrentRow.siteUrl.replace("http://", "")}`,
                          "_blank"
                        )
                      }
                      alt=""
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>비고</th>
                <td className="text-center" colSpan={4}>
                  <input
                    type="text"
                    className="form-control border-0"
                    value={governCurrentRow.remark}
                  />
                </td>
              </tr>
              <tr>
                <th rowSpan={2}>주소</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input-inline border-0"
                    value={
                      governCurrentRow.areaCode
                        ? governCurrentRow.areaCode
                        : governCurrentRow.zipcode
                    }
                  />
                </td>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input-inline border-0"
                    value={governCurrentRow.addresTxt}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <input
                    type="text"
                    className="form-control border-0"
                    value={
                      governCurrentRow.raddres ? governCurrentRow.raddres : governCurrentRow.addres
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>
                  취득세
                  <br /> 발급
                </th>
                <th style={{ width: "15%" }}>팩스신청</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control border-0 text-center"
                    value={governCurrentRow.requestFax === "Y" ? "가능" : "불가능"}
                  />
                </td>
                <th>우편신청</th>
                <td className="text-center">
                  <input
                    type="text"
                    className="form-control border-0 text-center"
                    value={governCurrentRow.requestDm === "Y" ? "가능" : "불가능"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
