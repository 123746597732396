import ModalTableRow from "./ModalTableRow";
import React, { useMemo, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useQueryClient } from "@tanstack/react-query";

const VirtualTable = props => {
  const { data, setData } = props;
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const queryClient = useQueryClient();
  const individualGolfList = queryClient.getQueryData(["getIndividualGolfList"]);

  const individualGolfListOptions = useMemo(
    () =>
      individualGolfList
        ? individualGolfList.map((i, idx) => ({ label: i.value, value: i.key }))
        : [],
    [individualGolfList]
  );

  return (
    <div className="modal-form table-sticky h-633" ref={targetRef} style={{ overflowY: "auto" }}>
      <table
        className="table table-bordered table-responsive my-table"
        style={{
          marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
          marginBottom: `${
            rowVirtualizer.getTotalSize() -
            (rowVirtualizer.range?.startIndex ?? 0) * 30 -
            rowVirtualizer.getVirtualItems().length * 30
          }px`,
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "7.14%" }} rowSpan={2}>
              No
            </th>
            <th style={{ width: "9.52%" }} rowSpan={2}>
              code
            </th>
            <th style={{ width: "29.76%" }} rowSpan={2}>
              회원권종목
            </th>
            <th style={{ width: "8.33%" }} rowSpan={2}>
              구분
            </th>
            <th style={{ width: "16.66%" }} colSpan={2}>
              개인
            </th>
            <th style={{ width: "10.7%" }} rowSpan={2}>
              저장
              <br />
              여부
            </th>
            <th style={{ width: "8.33%" }} rowSpan={2}>
              시세
              <br />
              관리
              <br />
              종목
            </th>
            <th rowSpan={2}>
              인터넷
              <br />
              View
            </th>
          </tr>
          <tr>
            <th style={{ position: "sticky", top: "30px" }}>매도</th>
            <th style={{ position: "sticky", top: "30px" }}>매수</th>
          </tr>
        </thead>
        <tbody>
          {rowVirtualizer.getVirtualItems().map((i, idx) => (
            <ModalTableRow
              data={data[i.index]}
              setData={setData}
              index={i.index}
              key={i.key}
              individualGolfListOptions={individualGolfListOptions}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

// export default React.memo(VirtualTable);
export default React.memo(VirtualTable);
