import { useEffect, useState } from "react";

export const NumberInput1 = props => {
  const { name = "", readonly = false, onBlur, defaultValue = "0", onClick, toFixed = 0 } = props;

  const displayValue = value => {
    if (toFixed > 0 && value.includes(".")) {
      // 소수점을 기준으로 좌측은 , 표시
      let [integer, decimal] = value.split(".");
      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${integer}.${decimal}`;
    } else {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const defaultValueFunc = () => {
    let temp = defaultValue.toString().replaceAll(",", "");
    if (!Number.isNaN(temp)) return displayValue(Number(temp).toFixed(toFixed));
    else return Number("0").toFixed(toFixed);
  };

  const [value, setValue] = useState(defaultValueFunc());

  useEffect(() => {
    setValue(defaultValueFunc());
  }, [defaultValue]);

  return (
    <input
      type="text"
      className="form-control table-input text-end"
      value={value}
      name={name}
      readOnly={readonly}
      onChange={e => setValue(e.target.value)}
      onBlur={e => {
        e.target.value = Number(e.target.value).toFixed(toFixed);
        setValue(displayValue(e.target.value));
        if (onBlur) onBlur(e);
      }}
      onClick={e => {
        e.target.value = Number(e.target.value.toString().replaceAll(",", "")).toFixed(toFixed);
        if (onClick) onClick(e);
      }}
    />
  );
};
