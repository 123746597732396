import React from "react";

export const TableHeaderGC = () => {
  return (
    <tr>
      <th style={{ width: "8%" }}>연번</th>
      <th style={{ width: "3%" }}>선택</th>
      <th style={{ width: "15%" }}>골프장명</th>
      <th style={{ width: "6%" }}>소재지</th>
      <th style={{ width: "4%" }}>코드</th>
      <th>회원권명</th>
      <th style={{ width: "10%" }}>회원권분류</th>
      <th style={{ width: "12%" }}>기준시가(85이전)</th>
      <th style={{ width: "12%" }}>분류</th>
      <th style={{ width: "5%" }}>삭제</th>
    </tr>
  );
};
