import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { encrypt } from "../../util/encrypt/encrypt";
import { useSetAtom } from "jotai";
import { currentSidebar_atom } from "../../data/atom/currentSidebar_atom";
import { recentTap_atom } from "../../data/atom/recentTap_atom";
import axios from "axios";
import { fileDownload } from "../../util/fileDownload/fileDownload";
import { Spinner } from "../../components/spinner/spinner";

const regex_only_english_number = /^[a-zA-Z0-9]+$/;

export const Login = () => {
  const defaultURL = process.env.PUBLIC_URL;
  const navigate = useNavigate();

  const [isAPILoading, setIsAPILoading] = useState(false);

  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [validation, setValidation] = useState({ isIdEmpty: false, isPwEmpty: false });

  const setSidebarMenuAtom = useSetAtom(currentSidebar_atom);
  const setRecentTap = useSetAtom(recentTap_atom);

  // 캡쳐방지 프로그램
  const captureMutate = useMutation(() => axios.get("http://localhost:5500"));

  // 로그인
  const { mutate, isLoading, isError } = useMutation(
    ({ id, pw }) => axios.post(defaultURL + "/api/auth/login", { loginId: id, password: pw }),
    {
      onSuccess: res => {
        const { capturePermit } = res.data.result; // capturePermit가 "Y"이면 캡쳐방지 확인 필요없음, "N"이면 캡쳐방지 확인 필요

        if (capturePermit === "N") {
          setIsAPILoading(true);

          captureMutate
            .mutateAsync()
            .then(() => {
              setRecentTap([]);
              navigate(defaultURL + "/initial/", { replace: true });
              sessionStorage.setItem("userInfo", encrypt(res.data.result));
            })
            .catch(() => {
              alert("캡쳐방지 프로그램를 실행해주세요.");
              sessionStorage.clear();
              navigate(defaultURL + "/", { replace: true });
            })
            .finally(() => {
              setIsAPILoading(false);
            });
        } else {
          setRecentTap([]);
          navigate(defaultURL + "/initial/", { replace: true });
          sessionStorage.setItem("userInfo", encrypt(res.data.result));
        }
      },
      onError: err => {
        alert(err.response.data.message);
      },
    }
  );

  // 캡쳐 방지 프로그램 다운
  const downloadCaptureFile = useMutation(
    () =>
      axios.get(defaultURL + "/api/auth/downloadCaptureFile", {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        fileDownload(res.data, "DongA.exe");
      },
      onError: err => {},
    }
  );

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const func_validation_check = () => {
    let isIdEmpty = false;
    let isPwEmpty = false;

    if (id === "") {
      isIdEmpty = true;
    }
    if (pw === "") {
      isPwEmpty = true;
    }

    setValidation({
      isIdEmpty: isIdEmpty,
      isPwEmpty: isPwEmpty,
    });

    return !(isIdEmpty || isPwEmpty);
  };

  const btn_login = () => {
    const validation = func_validation_check();

    if (validation) {
      mutate({ id, pw });
    }
  };

  const btn_downloads = () => {
    downloadCaptureFile.mutate();
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_login();
    }
  };

  return (
    <>
      {(isLoading || isAPILoading) && <Spinner />}

      <div className="login-content-outer">
        <div className="login-content-inner">
          <div className="login-content-title">
            <span>Welcome Back!</span>
            <span>Sign in to continue to Admin.</span>
          </div>
          <div className="login-content-input" onKeyDown={pressEnter}>
            <div>
              <input
                className={validation.isIdEmpty && "login-active"}
                type="text"
                value={id}
                onChange={e => setId(e.target.value.toUpperCase())}
                placeholder="아이디를 입력하세요."
                autoFocus={true}
              />
            </div>
            <div>
              <input
                className={validation.isPwEmpty && "login-active"}
                type="password"
                value={pw}
                onChange={e => setPw(e.target.value)}
                placeholder="비밀번호를 입력하세요."
              />
            </div>
          </div>
          <div className="login-content-validation">
            {(validation.isIdEmpty || validation.isPwEmpty) && (
              <div>아이디 또는 비밀번호가 일치하지 않습니다.</div>
            )}
          </div>
          <div className="login-content-button">
            <button onClick={btn_login} disabled={isLoading}>
              로그인
            </button>
          </div>
          {/*<div className="login-content-footer">*/}
          {/*  <span>아이디 찾기</span>*/}
          {/*  <div> / </div>*/}
          {/*  <span>비밀번호 찿기</span>*/}
          {/*</div>*/}

          <div className="login-content-footer">
            <span onClick={btn_downloads}>캡쳐방지 프로그램 다운로드</span>
          </div>
        </div>
      </div>
    </>
  );
};
