import React from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  registerFlag_atom,
  searchedParam_atom,
  shopDetail_atom,
  shopDetailFlag_atom,
} from "../../../data/atom/workManage/shopMasterManage/shopMasterManage_atom";
import moment from "moment/moment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { DaumPostcode } from "../../../components/daumPostcode/daumPostcode";

const leftShopInfo = props => {
  const { searchShop, searchShopList } = props;
  const [shopDetail, setShopDetail] = useAtom(shopDetail_atom);
  const [registerFlag, setRegisterFlag] = useAtom(registerFlag_atom);
  const searchedParam = useAtomValue(searchedParam_atom);
  const setShopDetailFlag = useSetAtom(shopDetailFlag_atom);

  const queryClient = useQueryClient();
  const getEmail = queryClient.getQueryData(["getEmail"]);
  const saveShopDetail = useMutation(data => axios.post("/api/work/shop/save", data), {
    onSuccess: res => {
      if (shopDetail.outCheck === "N") {
      } else {
        alert("저장 되었습니다.\n(제명처리)");
      }
      // setShopDetail(res?.data?.result);
      searchShopList.mutate(searchedParam);
      searchShop.mutate(res?.data?.result);
      setRegisterFlag(false);
    },
  });

  const onChangeInfoEventHandler = (key, value) => {
    if (key === "company" && checkByte(value) > 40) {
      return;
    }
    if (key === "initName" && checkByte(value) > 40) {
      return;
    }
    if (key === "owner" && checkByte(value) > 40) {
      return;
    }
    if (key === "position" && checkByte(value) > 40) {
      return;
    }
    if (key === "cfax" && checkByte(value) > 14) {
      return;
    }
    if (key === "raddresTxt" && checkByte(value) > 400) {
      return;
    }
    if (key === "siteUrl" && checkByte(value) > 100) {
      return;
    }
    if (key === "sekumDamdang" && checkByte(value) > 20) {
      return;
    }
    if (key === "sekumDirectcall" && checkByte(value) > 20) {
      return;
    }
    if (key === "sekumHandphon" && checkByte(value) > 20) {
      return;
    }

    if (key === "sekumEmailDomainKey") {
      if (value === "") {
        setShopDetail({
          ...shopDetail,
          [key]: value,
          sekumEmailId: "",
          sekumEmailDomain: "",
          sekumEmail: "",
        });
      } else {
        setShopDetail({
          ...shopDetail,
          [key]: value,
          sekumEmailDomain: getEmail?.find(el => el.key === value)?.value,
        });
      }
    } else if (key === "documEmailDomainKey") {
      if (value === "") {
        setShopDetail({
          ...shopDetail,
          [key]: value,
          documEmailId: "",
          documEmailDomain: "",
          documEmail: "",
        });
      } else {
        setShopDetail({
          ...shopDetail,
          [key]: value,
          documEmailDomain: getEmail?.find(el => el.key === value)?.value,
        });
      }
    } else {
      setShopDetail({ ...shopDetail, [key]: value });
    }
  };

  const btnSave = () => {
    if (registerFlag) {
      if (confirm("등록하시겠습니까?")) {
        if (shopDetail.company?.trim() === "") {
          alert("샵명을 입력해주세요.");
        } else {
          saveShopDetail.mutate({
            ...shopDetail,
            inDate: shopDetail.inDate ? moment(shopDetail.inDate).format("YYYY-MM-DD") : null,
            modDate: shopDetail.modDate ? moment(shopDetail.modDate).format("YYYY-MM-DD") : null,
            sekumEmail:
              shopDetail.sekumEmailDomainKey === ""
                ? ""
                : shopDetail.sekumEmailDomainKey === "99"
                ? shopDetail.sekumEmail
                : `${shopDetail.sekumEmailId}@${shopDetail.sekumEmailDomain}`,
            documEmail:
              shopDetail.documEmailDomainKey === ""
                ? ""
                : shopDetail.documEmailDomainKey === "99"
                ? shopDetail.documEmail
                : `${shopDetail.documEmailId}@${shopDetail.documEmailDomain}`,
          });
        }
      }
    } else {
      if (confirm("수정하시겠습니까?")) {
        saveShopDetail.mutate({
          ...shopDetail,
          inDate: shopDetail.inDate ? moment(shopDetail.inDate).format("YYYY-MM-DD") : null,
          modDate: shopDetail.modDate ? moment(shopDetail.modDate).format("YYYY-MM-DD") : null,
          sekumEmail:
            shopDetail.sekumEmailDomainKey === ""
              ? ""
              : shopDetail.sekumEmailDomainKey === "99"
              ? shopDetail.sekumEmail
              : `${shopDetail.sekumEmailId}@${shopDetail.sekumEmailDomain}`,
          documEmail:
            shopDetail.documEmailDomainKey === ""
              ? ""
              : shopDetail.documEmailDomainKey === "99"
              ? shopDetail.documEmail
              : `${shopDetail.documEmailId}@${shopDetail.documEmailDomain}`,
        });
      }
    }
  };

  const btnCancel = () => {
    if (registerFlag) {
      if (confirm("취소하시겠습니까?")) {
        setShopDetail({});
        setRegisterFlag(false);
        setShopDetailFlag(false);
      }
    } else {
      if (confirm("취소하시겠습니까?")) {
        searchShop.mutate(shopDetail);
      }
    }
  };

  const checkByte = str => {
    let currentByte = 0;
    for (let i = 0; i < str.length; i++) {
      if (new RegExp(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/).test(str[i])) {
        currentByte += 2;
      } else {
        currentByte += 1;
      }
    }
    return currentByte;
  };

  return (
    <>
      <div className={!registerFlag ? "col-left" : ""}>
        <div className="card card-main h-618px">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title  m-0">업체정보</h5>
            <div className="d-flex align-items-center justify-content-end">
              <>
                <button className="btn btn-outline-primary" onClick={btnSave}>
                  저장
                </button>
                <button className="btn btn-outline-primary ms-lg-1" onClick={btnCancel}>
                  취소
                </button>
              </>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="tab1-row1-count">관리번호 : {shopDetail?.custId}</div>
              <div className="tab1-row1-field1"></div>
            </div>

            <table className="table table-bordered table-responsive my-table1 mt-2">
              <tbody>
                <tr>
                  <th style={{ width: "15%" }}>샵명</th>
                  <td style={{ width: "30%" }}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.company ? shopDetail.company : ""}
                      onChange={e => onChangeInfoEventHandler("company", e.target.value)}
                    />
                  </td>
                  <th style={{ width: "15%" }}>약칭명</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.initName ? shopDetail.initName : ""}
                      onChange={e => onChangeInfoEventHandler("initName", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>대표자</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.owner ? shopDetail.owner : ""}
                      onChange={e => onChangeInfoEventHandler("owner", e.target.value)}
                    />
                  </td>
                  <th>담당자</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.kname ? shopDetail.kname : ""}
                      onChange={e => onChangeInfoEventHandler("kname", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>직위</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.position ? shopDetail.position : ""}
                      onChange={e => onChangeInfoEventHandler("position", e.target.value)}
                    />
                  </td>
                  <th>FAX번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.cfax ? shopDetail.cfax : ""}
                      onChange={e => onChangeInfoEventHandler("cfax", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>등록일자</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        shopDetail.inDate ? moment(shopDetail.inDate).format("YYYY-MM-DD") : ""
                      }
                      disabled={true}
                    />
                  </td>
                  <th>전화번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.ctel ? shopDetail.ctel : ""}
                      onChange={e => onChangeInfoEventHandler("ctel", e.target.value)}
                    />
                  </td>
                </tr>
                <tr className="">
                  <th rowSpan={2}>도로명 주소</th>
                  <td className="flex-center-between border-0">
                    <input
                      type="text"
                      className="form-control table-input col2-input-btn float-start"
                      value={shopDetail.rareaCode ? shopDetail.rareaCode : ""}
                      onChange={e => onChangeInfoEventHandler("rareaCode", e.target.value)}
                      disabled={false}
                    />
                    <DaumPostcode
                      className={"btn btn-outline-primary btn-filter-outline-primary m-0"}
                      onCompleteCallback={data =>
                        setShopDetail({
                          ...shopDetail,
                          rareaCode: data.zonecode,
                          rzipcode: data?.zonecode,
                          raddresTxt: data.fullAddr,
                          rbdcode: data.bdCode,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <div className="d-flex align-items-center">
                      {/* <input
                        type="text"
                        className="form-control table-input"
                        value={shopDetail.rzipcode ? shopDetail.rzipcode : ""}
                        onChange={e => onChangeInfoEventHandler("rzipcode", e.target.value)}
                      /> */}
                      {/* </td>
                  <td> */}
                      <input
                        type="text"
                        className="form-control table-input"
                        value={shopDetail.raddresTxt ? shopDetail.raddresTxt : ""}
                        disabled={false}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.raddres}
                      onChange={e => onChangeInfoEventHandler("raddres", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>회원사</th>
                  <td>
                    <select
                      className="form-select"
                      value={shopDetail.memberCheck}
                      onChange={e => onChangeInfoEventHandler("memberCheck", e.target.value)}
                    >
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>
                  </td>
                  <th>매물장팩스</th>
                  <td>
                    <select
                      className="form-select"
                      value={shopDetail.faxCheck}
                      onChange={e => onChangeInfoEventHandler("faxCheck", e.target.value)}
                    >
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="form-checkx">
                      <input
                        className="form-check-input ms-2"
                        type="checkbox"
                        id="flexCheckDefault"
                        checked={shopDetail.outCheck === "Y"}
                        onChange={e =>
                          onChangeInfoEventHandler("outCheck", e.target.checked ? "Y" : "N")
                        }
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        제명여부
                      </label>
                    </div>
                  </th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        shopDetail.outDate ? moment(shopDetail.outDate).format("YYYY-MM-DD") : ""
                      }
                      disabled={true}
                    />
                  </td>
                  <th>홈페이지</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input float-start col2-input-arrowbtn"
                      value={shopDetail.siteUrl ? shopDetail.siteUrl : ""}
                      onChange={e => onChangeInfoEventHandler("siteUrl", e.target.value)}
                    />
                    <a
                      href={
                        shopDetail.siteUrl?.trim() !== "" ? `http://${shopDetail.siteUrl}` : void 0
                      }
                      target="_blank"
                    >
                      <img
                        className="h-25px float-end"
                        src={process.env.PUBLIC_URL + "/images/icon-arrow-btn.png"}
                        alt=""
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="table-tabs-sub">세금관련담당</div>

            <table className="table table-bordered table-responsive my-table1">
              <tbody>
                <tr>
                  <th style={{ width: "15%" }}>담당자</th>
                  <td style={{ width: "30%" }}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.sekumDamdang ? shopDetail.sekumDamdang : ""}
                      onChange={e => onChangeInfoEventHandler("sekumDamdang", e.target.value)}
                    />
                  </td>
                  <th style={{ width: "15%" }}>직통전화</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.sekumDirectcall ? shopDetail.sekumDirectcall : ""}
                      onChange={e => onChangeInfoEventHandler("sekumDirectcall", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>휴대폰</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.sekumHandphon ? shopDetail.sekumHandphon : ""}
                      onChange={e => onChangeInfoEventHandler("sekumHandphon", e.target.value)}
                    />
                  </td>
                  <th>전자우편</th>
                  <td>
                    <div className="flex-center-center">
                      <input
                        type="text"
                        className="form-control table-input"
                        value={shopDetail?.sekumEmailId}
                        onChange={e => onChangeInfoEventHandler("sekumEmailId", e.target.value)}
                        readOnly={
                          shopDetail.sekumEmailDomainKey === "99" ||
                          shopDetail.sekumEmailDomainKey === ""
                        }
                        style={
                          shopDetail.sekumEmailDomainKey === "99" ||
                          shopDetail.sekumEmailDomainKey === ""
                            ? { backgroundColor: "lightgray" }
                            : {}
                        }
                      />
                      <div className="txt_daterange">@</div>
                      <select
                        className="form-select"
                        value={shopDetail?.sekumEmailDomainKey}
                        onChange={e => {
                          onChangeInfoEventHandler("sekumEmailDomainKey", e.target.value);
                        }}
                      >
                        {getEmail?.map(el => {
                          return <option value={el.key}>{el.value}</option>;
                        })}
                        <option value="">없음</option>
                      </select>
                    </div>
                    {shopDetail.sekumEmailDomainKey === "99" && (
                      <div>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={shopDetail?.sekumEmail ?? ""}
                          onChange={e => onChangeInfoEventHandler("sekumEmail", e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="table-tabs-sub">서류관련담당</div>

            <table className="table table-bordered table-responsive my-table1 mb-0">
              <tbody>
                <tr>
                  <th style={{ width: "15%" }}>담당자</th>
                  <td style={{ width: "30%" }}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.documDamdang ? shopDetail.documDamdang : ""}
                      onChange={e => onChangeInfoEventHandler("documDamdang", e.target.value)}
                    />
                  </td>
                  <th style={{ width: "15%" }}>직통전화</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.documDirectcall ? shopDetail.documDirectcall : ""}
                      onChange={e => onChangeInfoEventHandler("documDirectcall", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>휴대폰</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.documHandphon ? shopDetail.documHandphon : ""}
                      onChange={e => onChangeInfoEventHandler("documHandphon", e.target.value)}
                    />
                  </td>
                  <th>전자우편</th>
                  <td>
                    <div className="flex-center-center">
                      <input
                        type="text"
                        className="form-control table-input"
                        value={shopDetail?.documEmailId}
                        onChange={e => onChangeInfoEventHandler("documEmailId", e.target.value)}
                        readOnly={
                          shopDetail.documEmailDomainKey === "99" ||
                          shopDetail.documEmailDomainKey === ""
                        }
                        style={
                          shopDetail.documEmailDomainKey === "99" ||
                          shopDetail.documEmailDomainKey === ""
                            ? { backgroundColor: "lightgray" }
                            : {}
                        }
                      />
                      <div className="txt_daterange">@</div>
                      <select
                        className="form-select"
                        value={shopDetail?.documEmailDomainKey}
                        onChange={e => {
                          onChangeInfoEventHandler("documEmailDomainKey", e.target.value);
                        }}
                      >
                        {getEmail?.map(el => {
                          return <option value={el.key}>{el.value}</option>;
                        })}
                        <option value="">없음</option>
                      </select>
                    </div>
                    {shopDetail.documEmailDomainKey === "99" && (
                      <div>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={shopDetail?.documEmail ?? ""}
                          onChange={e => onChangeInfoEventHandler("documEmail", e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>비고</th>
                  <td colSpan={3}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={shopDetail.remark ? shopDetail.remark : ""}
                      onChange={e => onChangeInfoEventHandler("remark", e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(leftShopInfo);
