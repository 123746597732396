import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  supportTeamSchedulesManage_searchData_atom,
  supportTeamSchedulesManage_searchedTableList_atom,
} from "../../../data/atom/goodsManage/supportTeamSchedulesManage/supportTeamSchedulesManage_atom";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useGetSupportManageUserList } from "../../../api/code/useGetSupportManageUserList";
import Table from "./Table";
import usePrintTab from "../../../components/modal/usePrintTab";

const initData = {
  checked: false,
  newCheck: true,
  partner: "",
  remark: "",
  sawonId: "",
  sawonName: "",
  seqNo: 0,
  stdtDate: "",
  workSchedule: "",
};

export const SupportTeamSchedulesManage = () => {
  const tableRef = useRef(null);

  const [searchData, setSearchData] = useAtom(supportTeamSchedulesManage_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    supportTeamSchedulesManage_searchedTableList_atom
  );
  const [searchedTableCurrentIdx, setSearchedTableCurrentIdx] = useState(null);

  const [modifyList, setModifyList] = useState([]);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();
  const getSupportManageUserList = useGetSupportManageUserList();

  // 지원팀 스케줄 검색
  const supportManageSearch = useMutation(
    data => axios.get("/api/schedule/supportManage/search", { params: data }),
    {
      onSuccess: res => {
        setSearchedTableList(res.data.result);
        setModifyList(Array.from({ length: res.data.result.length }, () => false));
      },
    }
  );

  // 지원팀스케줄 저장/수정
  const supportManageSave = useMutation(
    data => axios.post("/api/schedule/supportManage/save", data),
    {
      onSuccess: res => {
        supportManageSearch.mutate(searchData);
      },
    }
  );

  // 지원팀스쿠질 삭제
  const supportManageDelete = useMutation(
    data => axios.post("/api/schedule/supportManage/delete", data),
    {
      onSuccess: res => {
        supportManageSearch.mutate(searchData);
      },
    }
  );

  // 지원팀 스케줄 출력
  const supportManageCheckPrint = useMutation(
    data => axios.get("/api/schedule/supportManage/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `업무_지원팀_스케줄_관리_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  useEffect(() => {
    const handleClick = e => {
      if (tableRef.current && !tableRef.current.contains(e.target)) {
        setSearchedTableCurrentIdx(null);
      }
    };

    window.addEventListener("mousedown", handleClick);

    return () => {
      window.removeEventListener("mousedown", handleClick);
    };
  }, [tableRef]);

  const btn_search = () => {
    supportManageSearch.mutate(searchData);
  };

  const btn_up = () => {
    if (searchedTableCurrentIdx == null) {
      alert("선택된 행이 없습니다.");
      return;
    }

    if (searchedTableCurrentIdx === 0) {
      alert("맨 위 행입니다.");
      return;
    }

    let newSearchedTableList = [...searchedTableList];

    const [temp1, temp2] = [
      newSearchedTableList[searchedTableCurrentIdx],
      newSearchedTableList[searchedTableCurrentIdx - 1],
    ];

    if (temp1.sawonId !== temp2.sawonId) {
      temp1.sawonId = temp2.sawonId;
      temp1.sawonName = temp2.sawonName;
    } else {
      newSearchedTableList[searchedTableCurrentIdx] = temp2;
      newSearchedTableList[searchedTableCurrentIdx - 1] = temp1;

      setSearchedTableCurrentIdx(searchedTableCurrentIdx - 1);
    }

    setSearchedTableList(newSearchedTableList);
  };

  const btn_down = () => {
    if (searchedTableCurrentIdx == null) {
      alert("선택된 행이 없습니다.");
      return;
    }

    if (searchedTableCurrentIdx === searchedTableList.length - 1) {
      alert("맨 아래 행입니다.");
      return;
    }

    let newSearchedTableList = [...searchedTableList];

    const [temp1, temp2] = [
      newSearchedTableList[searchedTableCurrentIdx],
      newSearchedTableList[searchedTableCurrentIdx + 1],
    ];

    if (temp1.sawonId !== temp2.sawonId) {
      temp1.sawonId = temp2.sawonId;
      temp1.sawonName = temp2.sawonName;
    } else {
      newSearchedTableList[searchedTableCurrentIdx] = temp2;
      newSearchedTableList[searchedTableCurrentIdx + 1] = temp1;

      setSearchedTableCurrentIdx(searchedTableCurrentIdx + 1);
    }

    setSearchedTableList(newSearchedTableList);
  };

  const btn_add = () => {
    setSearchedTableList([
      {
        newCheck: true,
        partner: "",
        remark: "",
        sawonId: "",
        sawonName: "",
        seqNo: 0,
        stdtDate:
          searchedTableList.length > 0 ? searchedTableList[0].stdtDate : searchData.stdtDate,
        workSchedule: "",
      },
      ...searchedTableList,
    ]);
  };

  const btn_register = () => {
    if (window.confirm("저장하시겠습니까?")) {
      supportManageSave.mutate(
        searchedTableList.map((el, idx) => ({
          newCheck: true,
          partner: el.partner,
          remark: el.remark,
          sawonId: el.sawonId,
          sawonName: el.sawonName,
          seqNo: el.seqNo,
          stdtDate: moment(el.stdtDate).format("YYYY-MM-DD"),
          workSchedule: el.workSchedule,
          idx: (idx + 1).toString(),
        }))
      );
    }
  };

  const btn_delete = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      supportManageSave.mutate(
        searchedTableList
          .filter(el => !el.checked)
          .map((el2, idx2) => ({
            newCheck: true,
            partner: el2.partner,
            remark: el2.remark,
            sawonId: el2.sawonId,
            sawonName: el2.sawonName,
            seqNo: el2.seqNo,
            stdtDate: moment(el2.stdtDate).format("YYYY-MM-DD"),
            workSchedule: el2.workSchedule,
            idx: (idx2 + 1).toString(),
          }))
      );
    }
  };

  const btn_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      supportManageSearch.mutate(searchData);
    }
  };

  const btn_print = () => {
    if (window.confirm("출력하시겠습니까?")) {
      supportManageCheckPrint.mutate(searchData);
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };
  const btn_init = () => {
    const data = [
      { sawonId: "", sawonName: "", count: 18 },
      { sawonId: "900000000", sawonName: "지사지원", count: 3 },
      { sawonId: "999999999", sawonName: "기타 및 보류사항", count: 3 },
      { sawonId: "AAAAAAAAA", sawonName: "전일 개서 건", count: 3 },
      { sawonId: "BBBBBBBBB", sawonName: "금일 개서 건", count: 3 },
    ];

    let tmp = [];
    let i = 1;

    data.forEach(({ sawonId, sawonName, count }) => {
      for (let j = 0; j < count; j++) {
        tmp.push({
          ...initData,
          stdtDate: searchData.stdtDate,
          sawonId,
          sawonName,
          seqNo: i++,
        });
      }
    });

    setSearchedTableList(tmp);
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">업무지원팀-스케줄관리</div>
            <div className="filter-buttons"></div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row filter1-field1">
              <div className="w-100 d-flex align-items-center">
                <div className="filter1-field filter1-field1 p-0" onKeyDown={pressEnter}>
                  <label className="filter1-label">기준일자</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchData.stdtDate}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        stdtDate: e.target.value,
                      });
                    }}
                    max="9999-12-31"
                  />
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                    onClick={btn_search}
                  >
                    조회
                  </button>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                    onClick={btn_init}
                  >
                    재생성
                  </button>
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                    onClick={btn_print}
                  >
                    출력
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-main" ref={tableRef}>
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0"></h5>
            <div className="d-flex align-items-center justify-content-end">
              <a className="ms-8px" href={void 0}>
                <img
                  src={process.env.PUBLIC_URL + "/images/btn-icon-up.png"}
                  alt=""
                  onClick={btn_up}
                />
              </a>
              <a className="ms-8px" href={void 0}>
                <img
                  src={process.env.PUBLIC_URL + "/images/btn-icon-down.png"}
                  alt=""
                  onClick={btn_down}
                />
              </a>
              <button className="btn btn-outline-primary ms-8px" onClick={btn_add}>
                추가
              </button>
              <button className="btn btn-outline-danger ms-8px" onClick={btn_delete}>
                삭제
              </button>
              <button className="btn btn-outline-primary ms-8px" onClick={btn_register}>
                저장
              </button>
              <button className="btn btn-outline-primary ms-8px" onClick={btn_cancel}>
                취소
              </button>
            </div>
          </div>
          <Table
            setSearchedTableCurrentIdx={setSearchedTableCurrentIdx}
            searchedTableCurrentIdx={searchedTableCurrentIdx}
          />
        </div>
      </div>
    </>
  );
};
