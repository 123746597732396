import React, { useEffect, useState } from "react";
import { axios } from "../../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useGetAppGubun } from "../../../../../api/code/useGetAppGubun";
import { useGetAuth } from "../../../../../api/code/useGetAuth";
import { useGetDept } from "../../../../../api/code/useGetDept";
import { useGetJob } from "../../../../../api/code/useGetJob";
import { useGetPartJob } from "../../../../../api/code/useGetPartJob";
import { useGetWork } from "../../../../../api/code/useGetWork";
import { useAtom } from "jotai";
import {
  PersonMoveTab_Data,
  PersonMoveTab_addData,
  PersonMoveTab_backupData,
  PersonMoveTab_createMode,
  PersonMoveTab_modifyData,
  PersonMoveTab_sawonId,
} from "../../../../../data/atom/personManage/personMasterManage/PersonMoveTab_atom";
import { decrypt } from "../../../../../util/decrypt/decrypt";

export default function PersonMoveTab({
  sawonId,
  userId,
  mutate_personSearchDetail,
  searchOption,
  mutate_personSearch,
}) {
  const { data: getAppGubun } = useGetAppGubun();
  const { data: getAuth } = useGetAuth();
  const { data: getDept } = useGetDept();
  const { data: getJob } = useGetJob();
  const { data: getPartJob } = useGetPartJob();
  const { data: getWork } = useGetWork();

  const initialModifyData = {
    appDate: moment(new Date()).format("YYYY-MM-DD"),
    appGubun: "11",
    authority: "11",
    dept: "1",
    job: "40",
    modDate: "",
    modId: "",
    partJob: "7C",
    sawonId: sawonId,
    seqNo: 0,
    userid: userId,
    work1: "11",
  };
  const defaultURL = "";
  const [createMode, setCreateMode] = useAtom(PersonMoveTab_createMode);
  const [data, setData] = useAtom(PersonMoveTab_Data);
  const [modifyData, setModifyData] = useAtom(PersonMoveTab_modifyData); // 인사발령 생성 및 수정
  const [addData, setAddData] = useAtom(PersonMoveTab_addData);
  const [backupData, setBackupData] = useAtom(PersonMoveTab_backupData);
  const [saveSawonId, setSaveSawonId] = useAtom(PersonMoveTab_sawonId);

  // API - 인사발령 조회
  const { mutate: mutate_personnelTransfer, isLoading: getLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getMove", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        const data = result.map(item => {
          return {
            ...item,
            appDate: item?.appDate ? moment(item?.appDate).format("YYYY-MM-DD") : "",
          };
        });

        setData(data);
        setBackupData(data);
        setModifyData([]);
        setCreateMode(false);
        setAddData(null);
      },
    }
  );

  // API - 인사발령 생성/수정
  const { mutate: mutate_personnelTransferModify, isLoading: modifyLoading } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/saveMove", data),
    {
      onSuccess: res => {
        mutate_personnelTransfer(sawonId);
        setCreateMode(false);
        setAddData(null);
        setModifyData([]);
        mutate_personSearchDetail(sawonId);
        mutate_personSearch(searchOption);
      },
    }
  );

  // API - 인사발령 삭제
  const { mutate: mutate_personnelTransferDelete } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/deleteMove", data),
    {
      onSuccess: res => {
        mutate_personnelTransfer(sawonId);
        mutate_personSearchDetail(sawonId);
        mutate_personSearch(searchOption);
      },
    }
  );

  useEffect(() => {
    setSaveSawonId(sawonId);
    if (saveSawonId !== sawonId) {
      mutate_personnelTransfer(sawonId);
    }
  }, []);

  // 값 변경 handler
  const changeHandler = (e, moveData) => {
    const { name, value } = e.target;

    // 보여지는 기존 데이터 값 바꾸기
    const newDataList = data.map(item => {
      if (item.seqNo === moveData.seqNo) {
        if (name === "appGubun") {
          if (value === "22") {
            // 퇴사
            return { ...item, [name]: value, work1: "22" };
          } else if (value === "71") {
            return { ...item, [name]: value, work1: "71" };
          } else if (value === "72") {
            return { ...item, [name]: value, work1: "72" };
          } else if (value === "33" || value === "34" || value === "35") {
            return { ...item, [name]: value, work1: "33" };
          } else if (value === "25") {
            return { ...item, [name]: value, work1: "25" };
          } else {
            return { ...item, [name]: value };
          }
        } else {
          return { ...item, [name]: value };
        }
      } else {
        return { ...item };
      }
    });
    setData(newDataList);
    // api modify data
    const isData = element => {
      return element.seqNo === moveData.seqNo;
    };

    const idx = modifyData?.findIndex(isData);
    if (idx === -1) {
      setModifyData([...modifyData, { ...moveData, [name]: value }]);
    } else {
      const newModData = modifyData.map(item => {
        if (item.seqNo === moveData.seqNo) {
          if (name === "appGubun") {
            if (value === "22") {
              // 퇴사
              return { ...item, [name]: value, work1: "22" };
            } else if (value === "71") {
              return { ...item, [name]: value, work1: "71" };
            } else if (value === "72") {
              return { ...item, [name]: value, work1: "72" };
            } else if (value === "33" || value === "34" || value === "35") {
              return { ...item, [name]: value, work1: "33" };
            } else if (value === "25") {
              return { ...item, [name]: value, work1: "25" };
            } else {
              return { ...item, [name]: value };
            }
          } else {
            return { ...item, [name]: value };
          }
        } else {
          return { ...item };
        }
      });
      setModifyData(newModData);
    }
  };

  return (
    <>
      <div className="px-20px py-15px">
        <div className={`table-container table-sticky h-183`}>
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>연번</th>
                <th style={{ width: "8%" }}>발령구분</th>
                <th style={{ width: "8%" }}>발령일자</th>
                <th style={{ width: "8%" }}>구분</th>
                <th style={{ width: "8%" }}>소속</th>
                <th style={{ width: "8%" }}>근무부서</th>
                <th style={{ width: "8%" }}>직책</th>
                <th style={{ width: "8%" }}>직위</th>
                <th style={{ width: "8%" }}>최종수정</th>
                <th style={{ width: "10%" }}>수정일</th>
                {/* <th style={{ width: "3%" }}>수정</th> */}
                {decrypt("userInfo").userPermit === "99" && <th style={{ width: "3%" }}>삭제</th>}
              </tr>
            </thead>
            <tbody>
              {/* 생성 모드 */}
              {createMode && (
                <tr>
                  <td></td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => {
                        const targetValue = e.target.value;
                        if (targetValue === "22") {
                          // 퇴사
                          setAddData({ ...addData, appGubun: targetValue, work1: "22" });
                        } else if (targetValue === "71") {
                          setAddData({ ...addData, appGubun: targetValue, work1: "71" });
                        } else if (targetValue === "72") {
                          setAddData({ ...addData, appGubun: targetValue, work1: "72" });
                        } else if (
                          targetValue === "33" ||
                          targetValue === "34" ||
                          targetValue === "35"
                        ) {
                          setAddData({ ...addData, appGubun: targetValue, work1: "33" });
                        } else if (targetValue === "25") {
                          setAddData({ ...addData, appGubun: targetValue, work1: "25" });
                        } else {
                          setAddData({ ...addData, appGubun: targetValue });
                        }
                      }}
                      value={addData.appGubun}
                    >
                      {getAppGubun?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, appDate: e.target.value });
                      }}
                      value={addData.appDate}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, work1: e.target.value })}
                      value={addData.work1}
                    >
                      {getWork?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, dept: e.target.value })}
                      value={addData.dept}
                    >
                      {getDept?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, job: e.target.value })}
                      value={addData.job}
                    >
                      {getJob?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, partJob: e.target.value })}
                      value={addData.partJob}
                    >
                      {getPartJob?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, authority: e.target.value })}
                      value={addData.authority}
                    >
                      {getAuth?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td></td>
                  <td></td>
                  {decrypt("userInfo").userPermit === "99" && <td></td>}
                </tr>
              )}
              {data.length > 0
                ? data.map((moveData, index) => (
                    <tr onClick={() => {}}>
                      <td>{index + 1}</td>
                      {/* {updateMode && updateSeqNo === moveData?.seqNo ? ( */}
                      <td>
                        <select
                          className="form-select"
                          name="appGubun"
                          onChange={e => {
                            changeHandler(e, moveData);
                          }}
                          value={moveData?.appGubun}
                        >
                          {getAppGubun?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      </td>

                      <td>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          value={moveData?.appDate}
                          name="appDate"
                          onChange={e => changeHandler(e, moveData)}
                        />
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={moveData?.work1}
                          name="work1"
                          onChange={e => changeHandler(e, moveData)}
                        >
                          {getWork?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={moveData?.dept}
                          name="dept"
                          onChange={e => changeHandler(e, moveData)}
                        >
                          {getDept?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          value={moveData?.job}
                          name="job"
                          onChange={e => changeHandler(e, moveData)}
                        >
                          {getJob?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          name="partJob"
                          onChange={e => changeHandler(e, moveData)}
                          value={moveData?.partJob}
                        >
                          {getPartJob?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          name="authority"
                          onChange={e => changeHandler(e, moveData)}
                          value={moveData?.authority}
                        >
                          {getAuth?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>{moveData?.modId}</td>
                      <td>{moment(moveData?.modDate).format("YYYY-MM-DD MM:SS")}</td>
                      {decrypt("userInfo").userPermit === "99" && (
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            const result = window.confirm("해당 기록을 삭제하시겠습니까?");
                            if (result) {
                              mutate_personnelTransferDelete({
                                sawonId: sawonId,
                                seqNo: moveData?.seqNo,
                              });
                            } else {
                            }
                          }}
                        >
                          {/* 인사발령정보 삭제 */}
                          <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                        </td>
                      )}
                    </tr>
                  ))
                : !createMode && (
                    <tr onClick={() => {}}>
                      <td colSpan={decrypt("userInfo").userPermit === "99" ? 12 : 11}>
                        조회된 데이터가 없습니다.
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>

        <div className="d-flex align-items-center justify-content-end mt-10px">
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              setCreateMode(true);
              setAddData(initialModifyData);
            }}
          >
            <span>추가</span>
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              const lastModeData = modifyData.map(item => {
                return { ...item, appDate: item.appDate === "" ? null : item.appDate };
              });
              if (addData !== null) {
                mutate_personnelTransferModify([
                  ...lastModeData,
                  { ...addData, appDate: addData.appDate === "" ? null : addData.appDate },
                ]);
              } else {
                if (lastModeData.length > 0) {
                  mutate_personnelTransferModify(lastModeData);
                }
              }
            }}
          >
            <span>저장</span>
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              setModifyData([]);
              setData(backupData);
              setCreateMode(false);
              setAddData(null);
              alert("변경 사항이 취소되었습니다.");
            }}
          >
            취소
          </button>
        </div>
      </div>
    </>
  );
}
