import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom, useSetAtom } from "jotai";
import { currentSidebar_atom } from "../../data/atom/currentSidebar_atom";
import { recentTap_atom, recentTapDelete_atom } from "../../data/atom/recentTap_atom";
import useRecentTabEvent from "./useRecentTabEvent";
import { queueTab_atom } from "../../data/atom/queueTab_atom";

/**
 * onTabExit : 다른 탭으로 이동하며 나갈 때
 * onTabRestored : 탭으로 돌아올 때
 * onTabClosed : 탭이 닫힐 때
 */
export const RecentTap = () => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const pathName = location.pathname.split("/");
  const pathNameLast = pathName[pathName.length - 1];

  const setTabAtom = useSetAtom(queueTab_atom);
  const setSidebarMenuAtom = useSetAtom(currentSidebar_atom);
  const [recentTap, setRecentTap] = useAtom(recentTap_atom);
  const deleteTapAtom = useSetAtom(recentTapDelete_atom);

  const { removeTabEvent, recentTabEvent } = useRecentTabEvent();

  const btn_navigate = data => {
    setSidebarMenuAtom({ sidebarMenu: "/" + data.url.split("/")[1] });
    navigate(defaultURL + data.url);
    let withoutPublicUrl = location.pathname.replace(process.env.PUBLIC_URL, "");
    if (recentTabEvent[withoutPublicUrl]?.onTabExit) recentTabEvent[withoutPublicUrl].onTabExit();
    if (recentTabEvent[data.url]?.onTabRestored) recentTabEvent[data.url].onTabRestored();
  };

  const btn_delete = index => {
    let deleteUrl = recentTap[index].url;
    const deleteIndex = index;
    const currentPageIndex = recentTap.findIndex(
      item => item.url.split("/")[item.url.split("/").length - 1] === pathNameLast
    );

    if (deleteIndex === currentPageIndex) {
      if (recentTap.length === 1) {
        // setSidebarMenuAtom({ sidebarMenu: "/work" });
        setRecentTap([]);
        navigate(defaultURL + "/initial");
      } else {
        if (currentPageIndex === deleteIndex && recentTap.length - 1 === deleteIndex) {
          const temp = recentTap[recentTap.length - 2];
          const newRecentTap = recentTap.map(i => i);
          newRecentTap.splice(deleteIndex, 1);

          setRecentTap(newRecentTap);
          setSidebarMenuAtom({ sidebarMenu: "/" + temp.url.split("/")[1] });

          navigate(defaultURL + temp.url);
        } else {
          const temp = recentTap[deleteIndex + 1];
          const newRecentTap = recentTap.map(i => i);
          newRecentTap.splice(deleteIndex, 1);

          setRecentTap(newRecentTap);
          setSidebarMenuAtom({ sidebarMenu: "/" + temp.url.split("/")[1] });

          navigate(defaultURL + temp.url);
        }
      }
    } else {
      const newRecentTap = recentTap.map(i => i);
      newRecentTap.splice(deleteIndex, 1);

      setRecentTap(newRecentTap);
    }

    deleteTapAtom({ ...recentTap[deleteIndex] });

    // 탭을 닫으면 해당 URL의 탭닫기 이벤트 호출 후 삭제
    if (recentTabEvent[deleteUrl]?.onTabClosed) {
      recentTabEvent[deleteUrl].onTabClosed();
      setTabAtom(prev => ({ ...prev, [`${deleteUrl}/queueTab`]: null }));
    }
    removeTabEvent(deleteUrl);
  };

  return (
    <div className="wrapper_points">
      {Array.from({ length: recentTap?.length ?? 0 }, (_, index) => {
        const tempLast =
          recentTap[index].url.split("/")[recentTap[index].url.split("/").length - 1];

        return (
          <div
            className={`box_points ${pathNameLast === tempLast && "active"}`}
            onClick={() => btn_navigate(recentTap[index])}
            onMouseDown={e => {
              if (e.button === 1) {
                e.preventDefault();
                e.stopPropagation();
                btn_delete(index);
              }
            }}
          >
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {recentTap[index].name}
            </div>
            <div
              className="box_points_close"
              onClick={e => {
                e.stopPropagation();
                btn_delete(index);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
