import moment from "moment/moment";
import React, { useState } from "react";
import { useAtom } from "jotai/index";
import {
  acquList_atom,
  conditionSelect_atom,
  currentRow_atom,
  medoMesu_atom,
  medoMesuCost_atom,
  medoSecondInput_atom,
  resultInit,
} from "../../../../data/atom/workManage/acquTax/acquTax_atom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import usePrintTab from "../../../../components/modal/usePrintTab";

export default function InputSection(props) {
  const { refetchFromFirstPage, editedRowList } = props;
  const [acquList, setAcquList] = useAtom(acquList_atom);
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [medoMesu, setMedoMesu] = useAtom(medoMesu_atom);
  const [medoMesuCost, setMedoMesuCost] = useAtom(medoMesuCost_atom);
  const [medoSecondInput, setMedoSecondInput] = useAtom(medoSecondInput_atom);
  const [conditionSelect, setConditionSelect] = useAtom(conditionSelect_atom);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const queryClient = useQueryClient();
  const getGolfCustList = queryClient.getQueryData(["getGolfCustList"]);
  const getEtcGzQ = queryClient.getQueryData(["getEtcGzQ"]);
  const getEtcSmQ = queryClient.getQueryData(["getEtcSmQ"]);
  const getEtcCmQ = queryClient.getQueryData(["getEtcCmQ"]);
  const printSingo = useMutation(
    data => axios.post("/api/work/acquisitionTax/printTax", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `취득세_신고서_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );
  const printWonjang = useMutation(
    data => axios.post("/api/work/acquisitionTax/printTaxCorp", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `취득세_원장_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );
  const saveAcquList = useMutation(data => axios.post("/api/work/acquisitionTax/save", data), {
    onSuccess: res => {
      refetchFromFirstPage();
    },
  });
  const saveCalcResult = useMutation(
    data => axios.post("/api/work/acquisitionTax/saveCalcResult", data),
    {
      onSuccess: (res, variables) => {
        const data = acquList.map(el => {
          if (
            el.ilNo === variables.ilNo &&
            el.custId === variables.custId &&
            el.seqNo === variables.seqNo
          ) {
            return {
              ...el,
              taxTarget: "R",
              sinDate: variables.sinDate,
              taxWon: variables.wonTax + variables.specTax,
            };
          }
        });

        saveAcquList.mutate([{ ...data }]);
      },
    }
  );
  const getAddressByChangeChiduk = useMutation(
    data => axios.get("/api/work/acquisitionTax/getChidukAddress", { params: data }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result?.[0];

        setMedoMesu({
          ...medoMesu,
          ccode: variables.custId,
          cgovern: data.company ?? "",
          czipcode: data.locaZip ?? "",
          caddres:
            data.addres && data.locaAddres
              ? `${data.addres} ${data.locaAddres}`
              : data.addres && !data.locaAddres
              ? `${data.addres}`
              : !data.addres && data.locaAddres
              ? `${data.locaAddres}`
              : "",
        });
      },
    }
  );

  const onChangeAaEventHandler = (key, value) => {
    if (key === "cgubun") {
      setMedoMesu({ ...medoMesu, [key]: value, ccode: "", cgovern: "", czipcode: "", caddres: "" });
    } else if (key === "ccode") {
      getAddressByChangeChiduk.mutate({ cgubun: medoMesu.cgubun, custId: value });
    } else if (key === "rjuminNo" || key === "sjuminNo") {
      value = value.replace(/[^0-9]/g, "").replace(/-/g, "");
      setMedoMesu({ ...medoMesu, [key]: value });
    } else {
      setMedoMesu({ ...medoMesu, [key]: value });
    }
  };

  const onChangeBbEventHandler = (key, value) => {
    value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));

    const tmp = {
      ...medoMesuCost,
      [key]: value,
      sum:
        (key === "maemaeWon" ? value : medoMesuCost.maemaeWon) +
        (key === "gaesuWon" ? value : medoMesuCost.gaesuWon) +
        (key === "charge" ? value : medoMesuCost.charge) +
        (key === "consultWon" ? value : medoMesuCost.consultWon),
    };

    setMedoMesuCost(tmp);
  };

  const onChangeCcEventHandler = (key, value) => {
    if (key === "cgubun") {
      setMedoSecondInput({
        ...medoSecondInput,
        [key]: value,
        ccode: "",
        cgovern: "",
        czipcode: "",
        caddres: "",
      });
    } else if (key === "ccode") {
      getAddressByChangeChiduk.mutate({ cgubun: medoSecondInput.cgubun, custId: value });
    } else if (key === "rjuminNo" || key === "sjuminNo") {
      value = value.replace(/[^0-9]/g, "").replace(/-/g, "");
      setMedoSecondInput({ ...medoSecondInput, [key]: value });
    } else {
      setMedoSecondInput({ ...medoSecondInput, [key]: value });
    }
  };

  const btnSingoPrint = () => {
    if (conditionSelect.printType === "S" && currentRow.custDate === undefined) {
      alert("출력할 내역이 없습니다.");
    } else {
      printSingo.mutate({
        asGovern: medoMesu.cgovern,
        asGubun: medoMesu.cgubun,
        asSinDate: moment(medoMesu.csinDate).format("YYYYMMDD"),
        asSingo: conditionSelect.singo,
        cgubun: "",
        gmAddress: medoMesu.caddres,
        gmName:
          medoMesu.cgubun === "1"
            ? getGolfCustList?.find(el => el.key === medoMesu.ccode)?.value
            : medoMesu.cgubun === "2"
            ? getEtcCmQ?.find(el => el.key === medoMesu.ccode)?.value
            : medoMesu.cgubun === "3"
            ? getEtcSmQ?.find(el => el.key === medoMesu.ccode)?.value
            : medoMesu.cgubun === "4"
            ? getEtcGzQ?.find(el => el.key === medoMesu.ccode)?.value
            : "",
        jisa: conditionSelect.jisa,
        memberNo: conditionSelect.isMembershipNo ? medoMesu.memberNo : "",
        printGubun: conditionSelect.printType,
        rabout: medoMesu.cabout,
        raddress: medoMesu.raddress,
        rdate: moment(medoMesu.cdate).format("YYYYMMDD"),
        rjuminNo: medoMesu.rjuminNo?.replace(/^(\d{6})(\d{7})$/, `$1-$2`),
        rkname: medoMesu.rkname,
        rtax: !conditionSelect.isSanchul ? Math.floor((medoMesuCost.sum * 0.002) / 10) * 10 : null,
        rtel: "",
        rwon: !conditionSelect.isSanchul ? medoMesuCost.sum : null,
        rwonTax: !conditionSelect.isSanchul ? Math.floor(medoMesuCost.sum * 0.002) * 10 : null,
        rwonTotal: !conditionSelect.isSanchul
          ? Math.floor((medoMesuCost.sum * 0.002) / 10) * 10 +
            Math.floor(medoMesuCost.sum * 0.002) * 10
          : null,
        saddress: medoMesu.saddress ?? "",
        saddress2: medoSecondInput.saddress ?? "",
        sellerRelation: medoMesu.sellerRelation === "1" ? "1" : "2",
        sjuminNo: medoMesu.sjuminNo?.replace(/^(\d{6})(\d{7})$/, `$1-$2`),
        sjuminNo2: medoSecondInput.sjuminNo?.replace(/^(\d{6})(\d{7})$/, `$1-$2`) ?? "",
        skname: medoMesu.skname ?? "",
        skname2: medoSecondInput.skname ?? "",
        stel: "",
        stel2: "",
      });
    }
  };
  const btnWonjangPrint = () => {
    if (currentRow.custDate === undefined) {
      alert("출력할 내역이 없습니다.");
    } else {
      printWonjang.mutate({
        commission: medoMesuCost.charge,
        company: medoMesu.rkname,
        consultingWon: medoMesuCost.consultWon,
        currDate: moment(currentRow.custDate).format("YYYY-MM-DD"),
        etcName: medoMesu.cgubun,
        gaesuWon: medoMesuCost.gaesuWon,
        suWon: medoMesuCost.maemaeWon,
      });
    }
  };
  const btnSave = () => {
    if (medoMesu.cdate === undefined || medoMesuCost.maemaeWon === 0) {
      alert("취득일자나 회원권 매매 금액이 입력되지 않았습니다.");
    } else {
      saveCalcResult.mutate({
        custDate: moment(currentRow.custDate).format("YYYY-MM-DD"),
        custId: currentRow.custId,
        gubun1: currentRow.dosu === "수" ? "2" : "",
        ilNo: currentRow.ilNo,
        seqNo: currentRow.seqNo,
        sinDate: moment(medoMesu.csinDate).format("YYYY-MM-DD"),
        specTax: Math.floor((medoMesuCost.sum * 0.002) / 10) * 10,
        taxTarget: currentRow.taxTarget,
        wonTax: Math.floor(medoMesuCost.sum * 0.002) * 10,
        yangdoTaxTarget: currentRow.yangdoTaxTarget,
      });
    }
  };
  const btnReset = () => {
    setMedoMesu({ cgubun: "1" });
    setMedoMesuCost(resultInit);
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="col-left">
        <div className="card card-main mt-0">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title  m-0">입력항목</h5>
            <div className="d-flex align-items-center justify-content-end">
              <button className="btn btn-outline-primary" onClick={btnSingoPrint}>
                취득세 신고서 출력
              </button>
              <button className="btn btn-outline-primary ms-2" onClick={btnWonjangPrint}>
                취득세 원장
              </button>
              <button className="btn btn-outline-primary ms-2" onClick={btnSave}>
                관청 접수 및 계산결과 저장
              </button>
              <button className="btn btn-outline-primary ms-2" onClick={btnReset}>
                초기화
              </button>
            </div>
          </div>
          <div className="card-body">
            {/* 매도자 인적사항*/}
            {/* 매도자 인적사항*/}
            {/* 매도자 인적사항*/}
            <div className="d-flex align-items-center justify-content-between">
              <div className="table-tabs-sub m-0">▶매도자 인적사항</div>
            </div>
            <table className="table table-bordered table-responsive my-table1 mt-2">
              <tbody>
                <tr>
                  <th style={{ width: "10%" }}>회원권 구분</th>
                  <td className="w-15">
                    <select
                      className="form-select"
                      value={medoMesu.sgubun}
                      onChange={e => onChangeAaEventHandler("sgubun", e.target.value)}
                    >
                      <option value="1">개인</option>
                      <option value="2">법인</option>
                    </select>
                  </td>
                  <th style={{ width: "10%" }}>성명</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={medoMesu.skname ?? ""}
                      onChange={e => onChangeAaEventHandler("skname", e.target.value)}
                    />
                  </td>
                  <th style={{ width: "10%" }}>주민등록번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        medoMesu.sjuminNo
                          ? medoMesu.sjuminNo.replace(/^(\d{6})(\d{7})$/, `$1-$2`)
                          : ""
                      }
                      onChange={e => onChangeAaEventHandler("sjuminNo", e.target.value)}
                      maxLength={14}
                    />
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td colSpan={5}>
                    <div className="d-flex justify-content-between">
                      <input
                        type="text"
                        className="form-control table-input table-input-inline w-20"
                        value={medoMesu.szipcode ?? ""}
                        onChange={e => onChangeAaEventHandler("szipcode", e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control table-input table-input-inline w-80"
                        value={medoMesu.saddress ?? ""}
                        onChange={e => onChangeAaEventHandler("saddress", e.target.value)}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {conditionSelect.maedo === "2" && (
              <table className="table table-bordered table-responsive my-table1 mt-2">
                <tbody>
                  <tr>
                    <th style={{ width: "10%" }}>회원권 구분</th>
                    <td className="w-15">
                      <select
                        className="form-select"
                        value={medoSecondInput.sgubun}
                        onChange={e => onChangeCcEventHandler("sgubun", e.target.value)}
                      >
                        <option value="1">개인</option>
                        <option value="2">법인</option>
                      </select>
                    </td>
                    <th style={{ width: "10%" }}>성명</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={medoSecondInput.skname ?? ""}
                        onChange={e => onChangeCcEventHandler("skname", e.target.value)}
                      />
                    </td>
                    <th style={{ width: "10%" }}>주민등록번호</th>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={
                          medoSecondInput.sjuminNo
                            ? medoSecondInput.sjuminNo.replace(/^(\d{6})(\d{7})$/, `$1-$2`)
                            : ""
                        }
                        onChange={e => onChangeCcEventHandler("sjuminNo", e.target.value)}
                        maxLength={14}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan={5}>
                      <div className="d-flex justify-content-between">
                        <input
                          type="text"
                          className="form-control table-input table-input-inline w-20"
                          value={medoSecondInput.szipcode ?? ""}
                          onChange={e => onChangeCcEventHandler("szipcode", e.target.value)}
                        />
                        <input
                          type="text"
                          className="form-control table-input table-input-inline w-80"
                          value={medoSecondInput.saddress ?? ""}
                          onChange={e => onChangeCcEventHandler("saddress", e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {/* 매수자 인적사항*/}
            {/* 매수자 인적사항*/}
            {/* 매수자 인적사항*/}
            <div className="d-flex align-items-center justify-content-between">
              <div className="table-tabs-sub m-0">▶매수자 인적사항</div>
              <div className="d-flex align-items-center justify-content-end">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">회원권 No.</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input1 w-200"
                    value={medoMesu.memberNo ?? ""}
                    onChange={e => onChangeAaEventHandler("memberNo", e.target.value)}
                  />
                </div>
              </div>
            </div>
            <table className="table table-bordered table-responsive my-table1 mt-2">
              <tbody>
                <tr>
                  <th style={{ width: "10%" }}>회원권 구분</th>
                  <td className="w-15">
                    <select
                      className="form-select"
                      value={medoMesu.rgubun}
                      onChange={e => onChangeAaEventHandler("rgubun", e.target.value)}
                    >
                      <option value="1">개인</option>
                      <option value="2">법인</option>
                    </select>
                  </td>
                  <th style={{ width: "10%" }}>성명</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={medoMesu.rkname ?? ""}
                      onChange={e => onChangeAaEventHandler("rkname", e.target.value)}
                    />
                  </td>
                  <th style={{ width: "10%" }}>주민등록번호</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        medoMesu.rjuminNo
                          ? medoMesu.rjuminNo.replace(/^(\d{6})(\d{7})$/, `$1-$2`)
                          : ""
                      }
                      onChange={e => onChangeAaEventHandler("rjuminNo", e.target.value)}
                      maxLength={14}
                    />
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td colSpan={5}>
                    <div className="d-flex justify-content-between">
                      <input
                        type="text"
                        className="form-control table-input table-input-inline w-20"
                        value={medoMesu.rzipcode ?? ""}
                        onChange={e => onChangeAaEventHandler("rzipcode", e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control table-input table-input-inline w-80"
                        value={medoMesu.raddress ?? ""}
                        onChange={e => onChangeAaEventHandler("raddress", e.target.value)}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* 매도자와의 관계*/}
            {/* 매도자와의 관계*/}
            {/* 매도자와의 관계*/}
            <div className="d-flex align-items-center justify-content-start">
              <div className="table-tabs-sub m-0 w-50">매도자와의 관계</div>
              <div className="d-flex align-items-center justify-content-start">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={medoMesu.sellerRelation === "1"}
                    onChange={e => onChangeAaEventHandler("sellerRelation", "1")}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    배우자 또는 직계 존비속
                  </label>
                </div>
                <div className="form-check ms-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={medoMesu.sellerRelation !== "1"}
                    onChange={e => onChangeAaEventHandler("sellerRelation", "2")}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    기타
                  </label>
                </div>
              </div>
            </div>
            <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
              <tbody>
                <tr>
                  <th>취득물건</th>
                  <td>
                    <select
                      className="form-select"
                      value={medoMesu.cgubun}
                      onChange={e => onChangeAaEventHandler("cgubun", e.target.value)}
                    >
                      <option value="1">골프</option>
                      <option value="2">콘도</option>
                      <option value="3">헬스</option>
                      <option value="4">골프텔</option>
                    </select>
                  </td>
                  <td colSpan={3}>
                    <select
                      className="form-select"
                      value={medoMesu.ccode}
                      onChange={e => onChangeAaEventHandler("ccode", e.target.value)}
                    >
                      {medoMesu.cgubun === "1" &&
                        getGolfCustList?.map((el, idx) => {
                          if (idx === getGolfCustList?.length - 1) {
                            return (
                              <>
                                <option value={el.key}>{el.value}</option>;
                                <option value="" hidden={true}></option>
                              </>
                            );
                          } else {
                            return <option value={el.key}>{el.value}</option>;
                          }
                        })}
                      {medoMesu.cgubun === "2" &&
                        getEtcCmQ?.map((el, idx) => {
                          if (idx === getEtcCmQ?.length - 1) {
                            return (
                              <>
                                <option value={el.key}>{el.value}</option>;
                                <option value="" hidden={true}></option>
                              </>
                            );
                          } else {
                            return <option value={el.key}>{el.value}</option>;
                          }
                        })}
                      {medoMesu.cgubun === "3" &&
                        getEtcSmQ?.map((el, idx) => {
                          if (idx === getEtcSmQ?.length - 1) {
                            return (
                              <>
                                <option value={el.key}>{el.value}</option>;
                                <option value="" hidden={true}></option>
                              </>
                            );
                          } else {
                            return <option value={el.key}>{el.value}</option>;
                          }
                        })}
                      {medoMesu.cgubun === "4" &&
                        getEtcGzQ?.map((el, idx) => {
                          if (idx === getEtcGzQ?.length - 1) {
                            return (
                              <>
                                <option value={el.key}>{el.value}</option>;
                                <option value="" hidden={true}></option>
                              </>
                            );
                          } else {
                            return <option value={el.key}>{el.value}</option>;
                          }
                        })}
                      <option value="" hidden={true}></option>
                    </select>
                  </td>
                  <th>취득구분</th>
                  <td colSpan={2}>
                    <select
                      className="form-select"
                      value={medoMesu.cabout}
                      onChange={e => onChangeAaEventHandler("cabout", e.target.value)}
                    >
                      <option value="1">매매</option>
                      <option value="2">분양</option>
                      <option value="3">증여</option>
                      <option value="4">상속</option>
                      <option value="5">명의변경</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>관할관청</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control"
                      value={medoMesu.cgovern ? medoMesu.cgovern : ""}
                      onChange={e => onChangeAaEventHandler("cgovern", e.target.value)}
                    />
                  </td>
                  <th>소재지</th>
                  <td colSpan={4}>
                    <div className="d-flex justify-content-between">
                      <input
                        type="text"
                        className="form-control table-input table-input-inline w-20"
                        value={medoMesu.czipcode ? medoMesu.czipcode : ""}
                        onChange={e => onChangeAaEventHandler("czipcode", e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control table-input table-input-inline w-80"
                        value={medoMesu.caddres ? medoMesu.caddres : ""}
                        onChange={e => onChangeAaEventHandler("caddres", e.target.value)}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>취득일자</th>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      value={medoMesu.cdate ? medoMesu.cdate : 0}
                      onChange={e => onChangeAaEventHandler("cdate", e.target.value)}
                    />
                  </td>
                  <th>회원권 대금</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={medoMesuCost.maemaeWon ? medoMesuCost.maemaeWon.toLocaleString() : 0}
                      onChange={e => onChangeBbEventHandler("maemaeWon", e.target.value)}
                    />
                  </td>
                  <th>취득가액</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={medoMesuCost.sum ? medoMesuCost.sum.toLocaleString() : 0}
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>신고일자</th>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      value={medoMesu.csinDate ? medoMesu.csinDate : ""}
                      onChange={e => onChangeAaEventHandler("csinDate", e.target.value)}
                    />
                  </td>
                  <th>명의 개서료</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={medoMesuCost.gaesuWon ? medoMesuCost.gaesuWon.toLocaleString() : 0}
                      onChange={e => onChangeBbEventHandler("gaesuWon", e.target.value)}
                    />
                  </td>
                  <th>취득세액(2%)</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={
                        medoMesuCost.sum
                          ? (Math.floor(medoMesuCost.sum * 0.002) * 10).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}></td>
                  <th>중개 수수료</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={medoMesuCost.charge ? medoMesuCost.charge?.toLocaleString() : 0}
                      onChange={e => onChangeBbEventHandler("charge", e.target.value)}
                    />
                  </td>
                  <th style={{ fontSize: "12px" }}>농어촌 특별세(10%)</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={
                        medoMesuCost.sum
                          ? (Math.floor((medoMesuCost.sum * 0.002) / 10) * 10).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}></td>
                  <th>컨설팅료</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={medoMesuCost.consultWon ? medoMesuCost.consultWon.toLocaleString() : 0}
                      onChange={e => onChangeBbEventHandler("consultWon", e.target.value)}
                    />
                  </td>
                  <td className="form-text f-12" colSpan={3}>
                    (취득세액과 농어촌 특별세 10원 미만 절사)
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "12.5%", borderRightStyle: "hidden" }}></td>
                  <td style={{ width: "12.5%", borderRightStyle: "hidden" }}></td>
                  <td style={{ width: "12.5%", borderRightStyle: "hidden" }}></td>
                  <td style={{ width: "12.5%", borderRightStyle: "hidden" }}></td>
                  <td style={{ width: "12.5%" }}></td>
                  <th style={{ width: "12.5%", fontSize: "12px" }}>납부해야 할 세액 합계</th>
                  <td colSpan={2}>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={
                        medoMesuCost.sum
                          ? (
                              Math.floor((medoMesuCost.sum * 0.002) / 10) * 10 +
                              Math.floor(medoMesuCost.sum * 0.002) * 10
                            ).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "0" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

React.memo(InputSection);
