import React from "react";
import Select from "react-select-virtualized";
import { getFilteredData, isReadonly } from "./selectPermitCheck";

export default function PartnerSelect(props) {
  const {
    data,
    keyName,
    onChangeEventHandler,
    isDisabled,
    readonlyLevel,
    rangeLevel,
    locked = 1,
  } = props;

  let partnerData = getFilteredData(rangeLevel, locked, data);

  return (
    <>
      <Select
        className="select-virtual text-left"
        menuPlacement={"auto"}
        menuPosition={"fixed"}
        options={partnerData}
        styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
        value={partnerData?.find(option => option.value === data?.[keyName])}
        onChange={e => onChangeEventHandler([keyName], e)}
        isDisabled={isDisabled !== null ? isDisabled : isReadonly(readonlyLevel)}
      />
    </>
  );
}
