import moment from "moment";
import React, { useEffect, useState } from "react";
import EmpModal from "../../../../components/modal/Modal";

const CalenarHeader = () => {
  const days = [
    "일요일(SUN)",
    "월요일(MON)",
    "화요일(TUE)",
    "수요일(WED)",
    "목요일(THU)",
    "금요일(FRI)",
    "토요일(SAT)",
  ];
  return (
    <div className="row mt-3">
      {days.map((day, _idx) => {
        return (
          <div
            className={`col text-center calendar_header 
            ${_idx === 0 && "calenar_red"}
            ${_idx === 6 && "calenar_blue"}
        `}
          >
            <span>{day}</span>
          </div>
        );
      })}
    </div>
  );
};

const CalendarBody = ({ curDate, searchData, setIsShow, setClickDate, clickDate }) => {
  const startOfMonth = moment(curDate).startOf("month"); // 월의 시작일
  const endOfMonth = moment(curDate).endOf("month"); // 월의 마지막일
  const startOfDay = startOfMonth.startOf("week");
  const endOfDay = endOfMonth.endOf("week");

  const rows = [];
  let days = [];
  let day = startOfDay;
  let formmatedDay = "";

  while (day <= endOfDay) {
    for (let i = 0; i < 7; i++) {
      formmatedDay = moment(day).format("D");
      const cloneDay = moment(day).clone();
      const clickDay = moment(day).clone();
      const isCurData = searchData.filter(schedule => {
        if (moment(schedule?.scheduleDate).isSame(cloneDay)) {
          return schedule;
        }
      });

      days.push(
        <div
          key={day}
          className={`col text-start calendar_cell 
          ${cloneDay.isSame(clickDate) && "calendar_focus"}
          ${cloneDay.isSame(new Date(), "day") && "calendar_tody"}
          `}
          role={cloneDay.isSame(moment(curDate), "month") && "button"}
          onClick={() => {
            if (clickDay.isSame(moment(curDate), "month")) {
              setIsShow(true);
              setClickDate(clickDay.format("YYYYMMDD"));
            }
          }}
        >
          <span
            className={`  ${i === 0 && "calenar_red"}
          ${i === 6 && "calenar_blue"}
          ${!cloneDay.isSame(moment(curDate), "month") && "calendar_disabled"} f-bold  
          `}
          >
            {formmatedDay}
          </span>
          <div className="calendar_text_container">
            <div className="calendar_text_box">
              {isCurData?.map(schedule => {
                return <div className="">{schedule.scheduleText}</div>;
              })}
            </div>
          </div>
        </div>
      );
      day = cloneDay.add(1, "days");
    }

    rows.push(
      <div>
        <div className="row">{days}</div>
      </div>
    );
    days = [];
  }

  return <div>{rows}</div>;
};

export default function PersonalSechduleCalendar({
  curDate,
  searchData,
  saveCalendar,
  deleteCalendar,
  createMode,
  setCreateMode,
}) {
  const [isShow, setIsShow] = useState(false);
  const [addData, setAddData] = useState(null);
  const [clickDate, setClickDate] = useState("");
  const [modifyData, setModifyData] = useState([]);
  const [backupData, setBackupData] = useState([]);

  const onChangeHandler = (schedule, e) => {
    const { name, value } = e.target;
    const newScheduleList = modifyData.map(data => {
      if (data?.seqNo === schedule?.seqNo) {
        return { ...data, [name]: value };
      } else {
        return { ...data };
      }
    });
    setModifyData(newScheduleList);
  };

  const onAddHandler = e => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };

  useEffect(() => {
    if (clickDate !== "") {
      const newScheduleData = searchData.filter(data => {
        if (moment(data.scheduleDate).isSame(moment(clickDate), "day")) {
          return data;
        }
      });
      setModifyData(newScheduleData);
      setBackupData(newScheduleData);
    } else {
      setModifyData([]);
      setBackupData([]);
    }
  }, [searchData, clickDate]);

  return (
    <div>
      {isShow && (
        <EmpModal
          show={isShow}
          width="100%"
          minWidth="600px"
          size="lg"
          title="일정등록"
          onHide={() => {
            setIsShow(false);
            setCreateMode(false);
            setClickDate("");
          }}
        >
          <div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary ms-8px me-0"
                onClick={() => {
                  if (!createMode) {
                    setCreateMode(true);
                    setAddData({
                      // modDate: "",
                      // modId: "",
                      repeatFlag: "N", // 반복
                      schGubn: "2", // 구분
                      scheduleDate: clickDate,
                      scheduleText: "",
                      seqNo: 0, // 추가는 0
                      newCheck: true,
                      // userId: "",
                    });
                  }
                }}
              >
                추가
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary ms-8px me-0"
                onClick={() => {
                  if (createMode) {
                    saveCalendar.mutate([...modifyData, addData]);
                  } else {
                    if (modifyData.length > 0) {
                      saveCalendar.mutate([...modifyData]);
                    }
                  }
                }}
              >
                저장
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary ms-8px me-0"
                onClick={() => {
                  setCreateMode(false);
                  setAddData(null);
                  setModifyData(backupData);
                }}
              >
                취소
              </button>
            </div>
          </div>
          <div className={`table-container table-sticky h-633  mt-3`}>
            <table className="table table-bordered table-responsive my-table mt-0">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>연번</th>
                  <th>구분</th>
                  <th>반복</th>
                  <th>일정</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {createMode && (
                  <tr>
                    <td></td>
                    <td style={{ width: "10%" }}>
                      <select
                        className="form-select"
                        name="schGubn"
                        value={addData.schGubn}
                        onChange={e => {
                          onAddHandler(e);
                        }}
                      >
                        <option value="1">팀별</option>
                        <option value="2">개인</option>
                      </select>
                    </td>
                    <td style={{ width: "10%" }}>
                      <select
                        className="form-select"
                        name="repeatFlag"
                        value={addData.repeatFlag}
                        onChange={e => {
                          onAddHandler(e);
                        }}
                      >
                        <option value="N">없음</option>
                        <option value="D">매일</option>
                        <option value="W">매주</option>
                        <option value="M">매월</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        name="scheduleText"
                        value={addData.scheduleText}
                        onChange={e => {
                          onAddHandler(e);
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                )}
                {modifyData?.length > 0
                  ? modifyData?.map((data, _idx) => {
                      return (
                        <tr>
                          <td>{_idx + 1}</td>
                          <td style={{ width: "10%" }}>
                            <select
                              className="form-select"
                              value={data?.schGubn}
                              name="schGubn"
                              onChange={e => {
                                onChangeHandler(data, e);
                              }}
                            >
                              <option value="1">팀별</option>
                              <option value="2">개인</option>
                            </select>
                          </td>
                          <td style={{ width: "10%" }}>
                            <select
                              className="form-select"
                              value={data?.repeatFlag}
                              name="repeatFlag"
                              onChange={e => {
                                onChangeHandler(data, e);
                              }}
                            >
                              <option value="N">없음</option>
                              <option value="D">매일</option>
                              <option value="W">매주</option>
                              <option value="M">매월</option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              name="scheduleText"
                              value={data?.scheduleText}
                              onChange={e => {
                                onChangeHandler(data, e);
                              }}
                            />
                          </td>
                          <td
                            onClick={() => {
                              const res = window.confirm("해당 개인 일정을 삭제하시겠습니까? ");
                              if (res) {
                                deleteCalendar.mutate([
                                  {
                                    scheduleDate: data?.scheduleDate,
                                    seqNo: data?.seqNo,
                                  },
                                ]);
                              }
                            }}
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/images/delete.png"}
                              alt=""
                              role="button"
                            />
                          </td>
                        </tr>
                      );
                    })
                  : !createMode && (
                      <tr>
                        <td colSpan={5}>조회 결과가 없습니다.</td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
        </EmpModal>
      )}
      <CalenarHeader />
      <CalendarBody
        curDate={curDate}
        searchData={searchData}
        clickDate={clickDate}
        setIsShow={setIsShow}
        setClickDate={setClickDate}
      />
    </div>
  );
}
