import EmpModal from "../../../../../components/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useVirtualizer } from "@tanstack/react-virtual";
import moment from "moment";
import { useGetUserIdQ } from "../../../../../api/code/useGetUserIdQ";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { useAtom } from "jotai/index";
import {
  customerManage_selectedCustomer_atom,
  customerManage_tapList_atom,
} from "../../../../../data/atom/workManage/customerManage/customerManage_atom";

export const ProcessingResultModal = ({
  isProcessingResultModalOpen,
  setIsProcessingResultModalOpen,
}) => {
  const virtualTableRef = useRef(null);
  const [infinityScroll, setInfinityScroll] = useState(null);

  const [tapList, setTapList] = useAtom(customerManage_tapList_atom);
  const [selectedCustomer, setSelectedCustomer] = useAtom(customerManage_selectedCustomer_atom);

  const [searchData, setSearchData] = useState({
    radio: "REQSERV",
    page: 1,
  });

  const [searchedTableList, setSearchedTableList] = useState([]);
  // const [searchedTableCount, setSearchedTableCount] = useState(0);
  // const [currentRadio, setCurrentRadio] = useState("REQSERV");

  const [checkedList, setCheckedList] = useState([]);
  const [modifyList, setModifyList] = useState([]);

  const getUserIdQ = useGetUserIdQ();

  // 제명(복구)처리 결과
  const customerSearchDelResProcResult = useMutation(data =>
    axios.get("/api/work/customer/searchDelResProcResult", {
      params: data,
    })
  );

  // 고객정보 제명(삭제) 또는 복구
  const customerSaveDelResProcResult = useMutation(
    data => axios.post("/api/work/customer/saveDelResProcResult", data),
    {
      onSuccess: res => {
        customerSearchDelResProcResult.mutateAsync(searchData).then(res => {
          setSearchedTableList(res.data.result);
          setCheckedList(Array.from({ length: res.data.result.length }, () => false));
          setModifyList(Array.from({ length: res.data.result.length }, () => false));
        });
      },
    }
  );

  useEffect(() => {
    if (
      decrypt("userInfo").userPermit === "99" ||
      decrypt("userInfo").userPermit === "92" ||
      decrypt("userInfo").userPermit === "91" ||
      decrypt("userInfo").userPermit === "55" ||
      decrypt("userInfo").userPermit === "61"
    ) {
      customerSearchDelResProcResult.mutateAsync(searchData).then(res => {
        setSearchedTableList(res.data.result);
        setCheckedList(Array.from({ length: res.data.result.length }, () => false));
        setModifyList(Array.from({ length: res.data.result.length }, () => false));
      });
    } else {
      let newSearchData = { radio: "MYREQ", page: 1 };

      customerSearchDelResProcResult.mutateAsync(newSearchData).then(res => {
        setSearchedTableList(res.data.result);

        setSearchData(newSearchData);
      });
    }
  }, []);

  useEffect(() => {
    if (
      decrypt("userInfo").userPermit === "99" ||
      decrypt("userInfo").userPermit === "92" ||
      decrypt("userInfo").userPermit === "91" ||
      decrypt("userInfo").userPermit === "55" ||
      decrypt("userInfo").userPermit === "61"
    ) {
      let newSearchData = { ...searchData };
      newSearchData.page = 1;

      customerSearchDelResProcResult.mutateAsync(newSearchData).then(res => {
        setSearchedTableList(res.data.result);

        setSearchData(newSearchData);
        setCheckedList(Array.from({ length: res.data.result.length }, () => false));
        setModifyList(Array.from({ length: res.data.result.length }, () => false));
      });
    } else {
      let newSearchData = { radio: "MYREQ", page: 1 };

      customerSearchDelResProcResult.mutateAsync(newSearchData).then(res => {
        setSearchedTableList(res.data.result);

        setSearchData(newSearchData);
      });
    }
  }, [searchData.radio]);

  // 무한 스크롤 이벤트
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && searchedTableList.length > 90) {
          setSearchData(prev => {
            const newSearchData = { ...prev, page: prev.page + 1 };

            customerSearchDelResProcResult.mutateAsync(newSearchData).then(res => {
              setSearchedTableList(prev => [...prev, ...res.data.result]);
              setModifyList(prev => [
                ...prev,
                ...Array.from({ length: res.data.result.length }, () => false),
              ]);

              setCheckedList(prev => [
                ...prev,
                ...Array.from({ length: res.data.result.length }, () => false),
              ]);
            });

            return newSearchData;
          });
        }
      },
      { threshold: 1 }
    );

    if (infinityScroll) {
      observer.observe(infinityScroll);
    }
  }, [infinityScroll]);

  const btn_tableRowClick = data => {
    if (tapList.length > 8) {
      alert("최근탭은 9개까지만 가능합니다.");
      return;
    }
    if (!tapList.some(el => el.seqNo === data.seqNo)) {
      setTapList(prev => {
        return [data, ...prev];
      });
    }

    // if (customerGetDetail.isLoading) {
    //   alert("서버에서 불러오는 중입니다.");
    //   return;
    // }

    setSelectedCustomer(data.seqNo);
  };

  const btn_search = () => {
    let newSearchData = { ...searchData };
    newSearchData.page = 1;

    customerSearchDelResProcResult.mutateAsync(newSearchData).then(res => {
      setSearchedTableList(res.data.result);

      // setCurrentRadio(newSearchData.radio);
      setCheckedList(Array.from({ length: res.data.result.length }, () => false));
      setModifyList(Array.from({ length: res.data.result.length }, () => false));
    });
    setSearchData(newSearchData);
  };

  const btn_approval = () => {
    // if (window.confirm("선택행을 승인하시겠습니까?")) {
    if (checkedList.every(el => el === false)) {
      alert("선택된 행이 없습니다.");
      return;
    }

    let newSearchedTableList = [...searchedTableList];
    let newModifyList = [...modifyList];

    if (searchData.radio === "REQSERV") {
      checkedList.forEach((el, idx) => {
        if (el) {
          newSearchedTableList[idx].processStat = "Y";
          newModifyList[idx] = true;
        } else {
          newSearchedTableList[idx].processStat = searchedTableList[idx].processStat;
          newModifyList[idx] = false;
        }
      });
    } else if (searchData.radio === "PROC") {
      checkedList.forEach((el, idx) => {
        if (el) {
          newSearchedTableList[idx].processStat = "Y";
          newModifyList[idx] = true;
        } else {
          newSearchedTableList[idx].processStat = searchedTableList[idx].processStat;
          newModifyList[idx] = false;
        }
      });
    } else if (searchData.radio === "DENY") {
      checkedList.forEach((el, idx) => {
        if (el) {
          newSearchedTableList[idx].processStat = "N";
          newModifyList[idx] = true;
        } else {
          newSearchedTableList[idx].processStat = searchedTableList[idx].processStat;
          newModifyList[idx] = false;
        }
      });
    } else if (searchData.radio === "HOLD") {
      checkedList.forEach((el, idx) => {
        if (el) {
          newSearchedTableList[idx].processStat = "H";
          newModifyList[idx] = true;
        } else {
          newSearchedTableList[idx].processStat = searchedTableList[idx].processStat;
          newModifyList[idx] = false;
        }
      });
    }

    // checkedList.forEach((el, idx) => {
    //   if (el) {
    //     newSearchedTableList[idx].processStat = "승인";
    //     newModifyList[idx] = true;
    //   } else {
    //     newSearchedTableList[idx].processStat = searchedTableList[idx].processStat;
    //     newModifyList[idx] = false;
    //   }
    // });

    setSearchedTableList(newSearchedTableList);
    setModifyList(newModifyList);
    // }
  };

  const btn_refuse = () => {
    // if (window.confirm("선택행을 거부하시겠습니까?")) {
    if (checkedList.every(el => el === false)) {
      alert("선택된 행이 없습니다.");
      return;
    }

    let newSearchedTableList = [...searchedTableList];
    let newModifyList = [...modifyList];

    if (searchData.radio === "REQSERV") {
      checkedList.forEach((el, idx) => {
        if (el) {
          newSearchedTableList[idx].processStat = "N";
          newModifyList[idx] = true;
        } else {
          newSearchedTableList[idx].processStat = searchedTableList[idx].processStat;
          newModifyList[idx] = false;
        }
      });
    }

    // checkedList.forEach((el, idx) => {
    //   if (el) {
    //     newSearchedTableList[idx].processStat = "수정거부";
    //     newModifyList[idx] = true;
    //   } else {
    //     newSearchedTableList[idx].processStat = searchedTableList[idx].processStat;
    //     newModifyList[idx] = false;
    //   }
    // });

    setSearchedTableList(newSearchedTableList);
    setModifyList(newModifyList);
    // }
  };

  const btn_register = () => {
    if (window.confirm("저장하시겠습니까?")) {
      // if (currentRadio !== searchData.radio) {
      //   alert("조회 후 수정할 수 없습니다. 조회 후 수정하시려면 조회를 다시 해주세요.");
      //   return;
      // }

      const temp = searchedTableList
        .filter((_, idx) => modifyList[idx])
        .map(el => ({
          changeCause: el.changeCause,
          company: el.company,
          custId: el.custId,
          gubun: el.gubun,
          kname: el.kname,
          notCause: el.notCause,
          partnerName: el.partnerName,
          // processStat:
          //   el.processStat === "접수"
          //     ? "R"
          //     : el.processStat === "취소"
          //     ? "C"
          //     : el.processStat === "승인"
          //     ? "Y"
          //     : el.processStat === "수정거부"
          //     ? "N"
          //     : el.processStat === "보류" && "H",
          processStat: el.processStat,
          requestDate: moment(el.requestDate).format("YYYY-MM-DD HH:mm:ss"),
          requestId: el.requestId,
          seqNo: el.seqNo,
          work1: el.work1,
        }));

      if (temp.length === 0) {
        alert("수정된 내용이 없습니다.");
        return;
      }

      customerSaveDelResProcResult.mutate({
        list: temp,
        page: "1",
        radio: searchData.radio,
      });
    }
  };

  const btn_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      // customerSearchDelResProcResult.mutateAsync(searchData).then(res => {
      //   setSearchedTableList(res.data.result);
      //   setCheckedList(Array.from({ length: res.data.result.length }, () => false));
      //   setModifyList(Array.from({ length: res.data.result.length }, () => false));
      // });

      let newSearchData = { ...searchData };
      newSearchData.page = 1;

      customerSearchDelResProcResult.mutateAsync(newSearchData).then(res => {
        setSearchedTableList(res.data.result);

        // setCurrentRadio(newSearchData.radio);
        setCheckedList(Array.from({ length: res.data.result.length }, () => false));
        setModifyList(Array.from({ length: res.data.result.length }, () => false));
      });
      setSearchData(newSearchData);
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: searchedTableList?.length,
    getScrollElement: () => virtualTableRef.current,
    estimateSize: () => 30,
    overscan: 5,
  });

  return (
    <EmpModal
      show={isProcessingResultModalOpen.isOpen}
      width="100%"
      minWidth="475px"
      title="제명/복구 접수 처리결과"
      onHide={() => setIsProcessingResultModalOpen({ data: null, isOpen: false })}
      strClass="modal-scroll"
      size="xl"
      footer={
        <div className="d-flex justify-content-end m-0">
          <button
            className="btn btn-outline-primary btn-filter-outline-primary"
            onClick={() =>
              setIsProcessingResultModalOpen({
                data: null,
                isOpen: false,
              })
            }
          >
            종료
          </button>
        </div>
      }
    >
      <div className="filter_fields_container mb-0">
        <div className="filter_fields_row">
          <div className="w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              {/*<label className="form-check-label me-4">검색건수 : {searchedTableList.length}</label>*/}
              <div className="form-check form-check-inline my-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  checked={searchData.radio === "MYREQ"}
                  onChange={e => {
                    setSearchData({
                      ...searchData,
                      radio: "MYREQ",
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  본인요청분
                </label>
              </div>
              <div className="form-check form-check-inline my-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  checked={searchData.radio === "REQSERV"}
                  onChange={e => {
                    setSearchData({
                      ...searchData,
                      radio: "REQSERV",
                    });
                  }}
                  disabled={
                    decrypt("userInfo").userPermit !== "99" &&
                    decrypt("userInfo").userPermit !== "92" &&
                    decrypt("userInfo").userPermit !== "91" &&
                    decrypt("userInfo").userPermit !== "55" &&
                    decrypt("userInfo").userPermit !== "61"
                  }
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  요청접수
                </label>
              </div>
              <div className="form-check form-check-inline my-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  checked={searchData.radio === "PROC"}
                  onChange={e => {
                    setSearchData({
                      ...searchData,
                      radio: "PROC",
                    });
                  }}
                  disabled={
                    decrypt("userInfo").userPermit !== "99" &&
                    decrypt("userInfo").userPermit !== "92" &&
                    decrypt("userInfo").userPermit !== "91" &&
                    decrypt("userInfo").userPermit !== "55" &&
                    decrypt("userInfo").userPermit !== "61"
                  }
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  정상처리
                </label>
              </div>
              <div className="form-check form-check-inline my-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio4"
                  checked={searchData.radio === "DENY"}
                  onChange={e => {
                    setSearchData({
                      ...searchData,
                      radio: "DENY",
                    });
                  }}
                  disabled={
                    decrypt("userInfo").userPermit !== "99" &&
                    decrypt("userInfo").userPermit !== "92" &&
                    decrypt("userInfo").userPermit !== "91" &&
                    decrypt("userInfo").userPermit !== "55" &&
                    decrypt("userInfo").userPermit !== "61"
                  }
                />
                <label className="form-check-label" htmlFor="inlineRadio4">
                  수정거부
                </label>
              </div>
              <div className="form-check form-check-inline my-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio5"
                  checked={searchData.radio === "HOLD"}
                  onChange={e => {
                    setSearchData({
                      ...searchData,
                      radio: "HOLD",
                    });
                  }}
                  disabled={
                    decrypt("userInfo").userPermit !== "99" &&
                    decrypt("userInfo").userPermit !== "92" &&
                    decrypt("userInfo").userPermit !== "91" &&
                    decrypt("userInfo").userPermit !== "55" &&
                    decrypt("userInfo").userPermit !== "61"
                  }
                />
                <label className="form-check-label" htmlFor="inlineRadio5">
                  보류
                </label>
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btn_search}
              >
                조회
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btn_approval}
                // disabled={currentRadio !== "MYREQ" || searchedTableList.length === 0}
                disabled={searchData.radio === "MYREQ" || searchedTableList.length === 0}
              >
                선택행 승인
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btn_refuse}
                // disabled={currentRadio !== "REQSERV" || searchedTableList.length === 0}
                disabled={searchData.radio !== "REQSERV" || searchedTableList.length === 0}
              >
                선택행 거부
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btn_register}
                disabled={
                  decrypt("userInfo").userPermit !== "99" &&
                  decrypt("userInfo").userPermit !== "92" &&
                  decrypt("userInfo").userPermit !== "91" &&
                  decrypt("userInfo").userPermit !== "55" &&
                  decrypt("userInfo").userPermit !== "61"
                }
              >
                작업처리(저장)
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-sub mt-0">
        <div className="card-body p-0">
          <div className="table-container table-sticky mt-2 h-333" ref={virtualTableRef}>
            <table
              className="table table-bordered table-responsive my-table"
              style={{
                marginBottom: `${
                  rowVirtualizer.getTotalSize() -
                  rowVirtualizer.getVirtualItems().length * 30 -
                  (rowVirtualizer.range?.startIndex ?? 0) * 30
                }px`,
                marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>선택</th>
                  <th style={{ width: "4%" }}>처리상태</th>
                  <th style={{ width: "4%" }}>구분</th>
                  <th style={{ width: "3%" }}>고객번호</th>
                  <th style={{ width: "5%" }}>성명</th>
                  <th style={{ width: "6%" }}>회사명</th>
                  <th style={{ width: "6%" }}>요청사유</th>
                  <th style={{ width: "6%" }}>요청일시</th>
                  <th style={{ width: "6%" }}>요청자</th>
                  <th style={{ width: "6%" }}>담당딜러</th>
                  <th style={{ width: "6%" }}>수정거부 사유</th>
                  <th style={{ width: "6%" }}>처리자</th>
                  <th style={{ width: "6%" }}>처리일시</th>
                  <th style={{ width: "6%" }}>연번호</th>
                </tr>
              </thead>
              <tbody>
                {searchedTableList.length !== 0 ? (
                  <>
                    {rowVirtualizer.getVirtualItems()?.map(virtualRow => (
                      <tr
                        className="cursor-pointer"
                        // onClick={() => btn_tableRowClick(searchedTableList[virtualRow.index])}
                      >
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            let newCheckedList = [...checkedList];
                            newCheckedList[virtualRow.index] = !newCheckedList[virtualRow.index];
                            setCheckedList(newCheckedList);

                            let newModifyList = [...modifyList];
                            newModifyList[virtualRow.index] = !newModifyList[virtualRow.index];
                            setModifyList(newModifyList);
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={checkedList[virtualRow.index]}
                            onChange={e => {
                              let newCheckedList = [...checkedList];
                              newCheckedList[virtualRow.index] = e.target.checked;
                              setCheckedList(newCheckedList);

                              let newModifyList = [...modifyList];
                              newModifyList[virtualRow.index] = e.target.checked;
                              setModifyList(newModifyList);
                            }}
                            // disabled={searchData.radio !== "PROC" && searchData.radio !== "DENY"}
                          />
                        </td>
                        <td>
                          {searchData.radio === "MYREQ" && (
                            <select
                              className="form-select"
                              value={searchedTableList[virtualRow.index].processStat}
                              onChange={e => {
                                let newSearchedTableList = [...searchedTableList];
                                newSearchedTableList[virtualRow.index].processStat = e.target.value;
                                setSearchedTableList(newSearchedTableList);

                                let newModifyList = [...modifyList];
                                newModifyList[virtualRow.index] = true;
                                setModifyList(newModifyList);

                                let newCheckedList = [...checkedList];
                                newCheckedList[virtualRow.index] = true;
                                setCheckedList(newCheckedList);
                              }}
                            >
                              <option value="R">접수</option>
                              <option value="C">취소</option>
                              <option value="Y" disabled={true}>
                                정상처리
                              </option>
                              <option value="N" disabled={true}>
                                수정거부
                              </option>
                              <option value="H" disabled={true}>
                                보류
                              </option>
                            </select>
                          )}

                          {searchData.radio === "REQSERV" && (
                            <select
                              className="form-select"
                              value={searchedTableList[virtualRow.index].processStat}
                              onChange={e => {
                                let newSearchedTableList = [...searchedTableList];

                                if (e.target.value !== "N") {
                                  newSearchedTableList[virtualRow.index].notCause = "";
                                }

                                newSearchedTableList[virtualRow.index].processStat = e.target.value;
                                setSearchedTableList(newSearchedTableList);

                                let newModifyList = [...modifyList];
                                newModifyList[virtualRow.index] = true;
                                setModifyList(newModifyList);

                                let newCheckedList = [...checkedList];
                                newCheckedList[virtualRow.index] = true;
                                setCheckedList(newCheckedList);
                              }}
                            >
                              <option value="R">접수</option>
                              <option value="C">취소</option>
                              <option value="Y">정상처리</option>
                              <option value="N">수정거부</option>
                              <option value="H">보류</option>
                            </select>
                          )}

                          {(searchData.radio === "PROC" || searchData.radio === "DENY") &&
                            (searchedTableList[virtualRow.index].processStat === "R"
                              ? "접수"
                              : searchedTableList[virtualRow.index].processStat === "C"
                              ? "취소"
                              : searchedTableList[virtualRow.index].processStat === "Y"
                              ? "정상처리"
                              : searchedTableList[virtualRow.index].processStat === "N"
                              ? "수정거부"
                              : searchedTableList[virtualRow.index].processStat === "H"
                              ? "보류"
                              : searchedTableList[virtualRow.index].processStat)}

                          {searchData.radio === "HOLD" && (
                            <select
                              className="form-select"
                              value={searchedTableList[virtualRow.index].processStat}
                              onChange={e => {
                                let newSearchedTableList = [...searchedTableList];
                                newSearchedTableList[virtualRow.index].processStat = e.target.value;
                                setSearchedTableList(newSearchedTableList);

                                let newModifyList = [...modifyList];
                                newModifyList[virtualRow.index] = true;
                                setModifyList(newModifyList);

                                let newCheckedList = [...checkedList];
                                newCheckedList[virtualRow.index] = true;
                                setCheckedList(newCheckedList);
                              }}
                            >
                              <option value="R">접수</option>
                              <option value="C">취소</option>
                              <option value="Y">정상처리</option>
                              <option value="N">수정거부</option>
                              <option value="H">보류</option>
                            </select>
                          )}
                        </td>
                        <td>
                          {searchedTableList[virtualRow.index].gubun === "Y"
                            ? "제명"
                            : searchedTableList[virtualRow.index].gubun === "N"
                            ? "복구"
                            : ""}
                        </td>
                        <td>{searchedTableList[virtualRow.index].custId}</td>
                        <td>{searchedTableList[virtualRow.index].kname}</td>
                        <td>{searchedTableList[virtualRow.index].company}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control filter-input"
                            value={searchedTableList[virtualRow.index].changeCause}
                            onChange={e => {
                              let newSearchedTableList = [...searchedTableList];
                              newSearchedTableList[virtualRow.index].changeCause = e.target.value;
                              setSearchedTableList(newSearchedTableList);

                              let newModifyList = [...modifyList];
                              newModifyList[virtualRow.index] = true;
                              setModifyList(newModifyList);
                            }}
                          />
                        </td>
                        <td>
                          {searchedTableList[virtualRow.index]?.requestDate
                            ? moment(searchedTableList[virtualRow.index].requestDate).format(
                                "YYYY-MM-DD"
                              )
                            : ""}
                        </td>
                        {/*<td>{searchedTableList[virtualRow.index].requestId}</td>*/}
                        <td>
                          {
                            getUserIdQ.data?.find(
                              el2 => el2.key === searchedTableList[virtualRow.index].requestId
                            )?.value
                          }
                        </td>
                        <td>{searchedTableList[virtualRow.index].partnerName}</td>
                        {/*<td>{searchedTableList[virtualRow.index].notCause}</td>*/}
                        <td>
                          <input
                            type="text"
                            className="form-control filter-input"
                            value={searchedTableList[virtualRow.index].notCause}
                            onChange={e => {
                              let newSearchedTableList = [...searchedTableList];
                              newSearchedTableList[virtualRow.index].notCause = e.target.value;
                              setSearchedTableList(newSearchedTableList);

                              let newModifyList = [...modifyList];
                              newModifyList[virtualRow.index] = true;
                              setModifyList(newModifyList);
                            }}
                            disabled={searchedTableList[virtualRow.index].processStat !== "N"}
                          />
                        </td>
                        {/*<td>{searchedTableList[virtualRow.index].modId}</td>*/}
                        <td>
                          {
                            getUserIdQ.data?.find(
                              el2 => el2.key === searchedTableList[virtualRow.index].modId
                            )?.value
                          }
                        </td>
                        <td>
                          {searchedTableList[virtualRow.index]?.modDate
                            ? moment(searchedTableList[virtualRow.index].modDate).format(
                                "YYYY-MM-DD"
                              )
                            : ""}
                        </td>
                        <td>{searchedTableList[virtualRow.index].seqNo}</td>
                      </tr>
                    ))}
                    {rowVirtualizer.range?.endIndex === searchedTableList.length - 1 && (
                      <div ref={setInfinityScroll} style={{ height: "1px" }} />
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan={14}>검색결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </EmpModal>
  );
};
