import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  annualManage_data,
  annualManage_searchData,
} from "../../../data/atom/personManage/annualManage/annualManage_atom";
import moment from "moment";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useEffect, useState } from "react";
import usePrintTab from "../../../components/modal/usePrintTab";

const AnnualLeaveRegister = () => {
  const [searchOption, setSearchOption] = useAtom(annualManage_searchData);
  const [searchData, setSearchData] = useAtom(annualManage_data);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        jisa: "99",
        kname: "",
        toDate: moment(new Date()).format("YYYY-MM-DD"),
        yearRange: "1",
      });
      setSearchData([]);
    });
  }, []);

  // API - 연차 검색
  const search = useMutation(
    data =>
      axios.get("/api/emp/yunchaMng/search", {
        params: data,
      }),
    {
      onSuccess: res => {
        setSearchData(res.data.result);
      },
    }
  );

  // API - 지사구분
  const getJisaGubn = useQuery(
    ["getJisaGubn"],
    async () =>
      await axios.get("/api/emp/yunchaMng/getJisaGubn").then(res => {
        return res?.data?.result;
      })
  );

  // API - 연차 출력
  const print = useMutation(
    data =>
      axios.get("/api/emp/yunchaMng/print", {
        responseType: "blob",
        params: data,
      }),
    {
      onSuccess: res => {
        if (res.data) {
          /*setPdfData({
            data: res.data,
            name: `연차 현황 출력_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const pressEnter = e => {
    if (e.key === "Enter") {
      if (searchOption?.toDate === "") {
        alert("기준일자를 설정해주세요.");
        return;
      }
      search.mutate(searchOption);
    }
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">연차 현황</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                onClick={() => {
                  if (searchData?.length <= 0) {
                    alert("출력할 대상이 없습니다.");
                    return;
                  }
                  print.mutate(searchOption);
                }}
              >
                연차현황 출력
              </button>
            </div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">지사구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchOption.jisa}
                    onChange={e => {
                      setSearchOption({ ...searchOption, jisa: e.target.value });
                    }}
                  >
                    {getJisaGubn.data?.map(data => {
                      return <option value={data?.key}>{data?.value}</option>;
                    })}
                  </select>
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">성명</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchOption.kname}
                    onChange={e => {
                      setSearchOption({ ...searchOption, kname: e.target.value });
                    }}
                  />
                </div>
                {/* <div className="filter1-field filter1-field1">
                  <label className="filter1-label">계산기준</label>
                  <select className="form-select filter1-select1">
                    <option>회계년도</option>
                  </select>
                </div> */}
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">기준일자</label>
                  <input
                    type="date"
                    max="9999-12-31"
                    className="form-control filter-input filter1-input2"
                    value={searchOption.toDate}
                    onChange={e => {
                      setSearchOption({
                        ...searchOption,
                        toDate: e.target.value,
                      });
                    }}
                  />
                  {/* <label className="filter1-label ms-2">현재기준</label> */}
                </div>
                {/* <div className="filter1-field filter1-field1">
                  <label className="filter1-label">조회 기간</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchOption.yearRange}
                    onChange={e => {
                      setSearchOption({
                        ...searchOption,
                        yearRange: e.target.value,
                      });
                    }}
                  >
                    <option value="1">1년</option>
                  </select>
                </div> */}
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  if (searchOption?.toDate === "") {
                    alert("기준일자를 설정해주세요.");
                    return;
                  }
                  search.mutate(searchOption);
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-container">
              <div
                className={`table-container table-sticky 
      h-663`}
                id="scroll-table-body"
              >
                <table className="table table-bordered table-responsive my-table mt-0">
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }} rowSpan={2}>
                        No.
                      </th>
                      <th style={{ width: "8.28%" }} rowSpan={2}>
                        소속
                      </th>
                      <th style={{ width: "8.28%" }} rowSpan={2}>
                        성명
                      </th>
                      <th style={{ width: "8.28%" }} rowSpan={2}>
                        직급
                      </th>
                      <th style={{ width: "8.28%" }} rowSpan={2}>
                        입사일자
                      </th>
                      <th style={{ width: "10.28%" }} rowSpan={2}>
                        년도(기간)
                      </th>
                      <th style={{ width: "6.28%" }} rowSpan={2}>
                        발생연차
                      </th>
                      <th style={{ width: "35%" }} colSpan={13}>
                        사용연차
                      </th>
                      <th rowSpan={2}>잔여연차</th>
                    </tr>
                    <tr>
                      <th style={{ width: "2.69%" }}>01월</th>
                      <th style={{ width: "2.69%" }}>02월</th>
                      <th style={{ width: "2.69%" }}>03월</th>
                      <th style={{ width: "2.69%" }}>04월</th>
                      <th style={{ width: "2.69%" }}>05월</th>
                      <th style={{ width: "2.69%" }}>06월</th>
                      <th style={{ width: "2.69%" }}>07월</th>
                      <th style={{ width: "2.69%" }}>08월</th>
                      <th style={{ width: "2.69%" }}>09월</th>
                      <th style={{ width: "2.69%" }}>10월</th>
                      <th style={{ width: "2.69%" }}>11월</th>
                      <th style={{ width: "2.69%" }}>12월</th>
                      <th>계</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchData.length > 0 ? (
                      searchData.map((data, _idx) => {
                        return (
                          <tr>
                            <td>{_idx + 1}</td>
                            <td>{data?.jisaName}</td>
                            <td>{data?.kname}</td>
                            <td>{data?.authName}</td>
                            <td>{moment(data?.inDate).format("YYYY-MM-DD")}</td>
                            <td>{`${moment(data?.fromDate).format("YYYY")}년 (${moment(
                              data?.fromDate
                            ).format("MM-DD")}~${moment(data?.toDate).format("MM-DD")})`}</td>
                            <td>{data?.yunchaIlsu}</td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance1mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance1mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance2mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance2mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance3mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance3mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance4mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance4mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance5mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance5mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance6mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance6mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance7mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance7mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance8mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance8mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance9mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance9mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance10mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance10mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance11mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance11mo}
                            </td>
                            <td>
                              {data?.yunchaWeekListResVO?.leaveBalance12mo === 0
                                ? ""
                                : data?.yunchaWeekListResVO?.leaveBalance12mo}
                            </td>
                            <td>{data?.yunchaWeekListResVO?.totalLeaveBalance}</td>
                            <td>
                              {(
                                (data?.yunchaIlsu ? data?.yunchaIlsu : 0) -
                                (data?.yunchaWeekListResVO?.totalLeaveBalance
                                  ? data?.yunchaWeekListResVO?.totalLeaveBalance
                                  : 0)
                              ).toFixed(3)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={21}>조회 결과가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnnualLeaveRegister;
