import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { axios } from "../../../util/axios/axios";
import moment from "moment";

export default function IndividualPerformanceClose() {
  const [page, setPage] = useState(1);
  const [unClosedData, setUnclosedData] = useState([]);
  const [closedData, setClosedData] = useState([]);
  const [addData, setAddData] = useState([]);
  // botoom 탐지
  const [bottom, setBottom] = useState(null);
  const bottomObserver = useRef(null);

  // API - 마감하기 데이터 불러오기
  const closeData = useMutation(
    data =>
      axios.get("/api/emp/empPerformance/close", {
        params: {
          page: data,
        },
      }),
    {
      onSuccess: (res, variables) => {
        const unClosedList = res?.data?.result?.unclosedList;
        const newUnClosedList = unClosedList.map(data => {
          return {
            ...data,
            closedFlag: false,
            startDate: data?.startDate ? moment(data?.startDate).format("YYYY-MM-DD") : "",
            endDate: data?.endDate ? moment(data?.endDate).format("YYYY-MM-DD") : "",
          };
        });
        setUnclosedData(newUnClosedList);
        if (variables === 1) {
          setClosedData(res?.data?.result?.closedList);
        } else {
          setClosedData([...closedData, ...res?.data?.result?.closedList]);
        }
      },
    }
  );

  // API - 최종 마감
  const finalClose = useMutation(data => axios.post("/api/emp/empPerformance/finalClose", data), {
    onSuccess: res => {
      setPage(1);
      setAddData([]);

      closeData.mutate(1);
      alert("최종 마감처리 되었습니다.");
    },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setPage(prev => {
            closeData.mutate(prev + 1);
            return prev + 1;
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, []);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  // 날짜 변환
  const dateModify = date => {
    // 연도, 월, 일 추출
    var year = date.substring(0, 4);
    var month = date.substring(4, 6);
    var day = date.substring(6, 8);
    return year + "년 " + month + "월 " + day + "일";
  };

  // 실적 시작일 종료일 change handler
  const changeHandler = (e, _idx) => {
    const { name, value } = e.target;
    const modifyUnclosedData = unClosedData.map((data, idx) => {
      if (idx === _idx) {
        return { ...data, [name]: value };
      } else {
        return data;
      }
    });
    setUnclosedData(modifyUnclosedData);
  };

  useEffect(() => {
    closeData.mutate(page);
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div style={{ width: "49.4%" }}>
          <div
            className={`table-container table-sticky 
      h-333`}
            id="scroll-table-body"
          >
            <table className="table table-bordered table-responsive my-table mt-0">
              <thead>
                <tr>
                  <th style={{ width: "3%" }}>선택</th>
                  {/* <th style={{ width: "8%" }}>실적년월</th> */}
                  <th style={{ width: "8%" }}>지사</th>
                  <th style={{ width: "8%" }}>실적시작일</th>
                  <th style={{ width: "8%" }}>실적종료일</th>
                </tr>
              </thead>
              <tbody>
                {/* 마감 현황*/}
                {unClosedData?.length > 0 ? (
                  <>
                    {unClosedData.map((data, _idx) => {
                      return (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              checked={data?.closedFlag}
                              onClick={() => {
                                const copyList = unClosedData;
                                if (copyList[_idx].closedFlag) {
                                  copyList[_idx].closedFlag = false;
                                } else {
                                  copyList[_idx].closedFlag = true;
                                }
                                setUnclosedData(prev => {
                                  return [...copyList];
                                });
                              }}
                            />
                          </td>
                          <td>{data?.jisaName}</td>
                          <td>
                            <input
                              type="date"
                              className="form-control filter-input filter1-input2 w-100"
                              value={data?.startDate}
                              name="startDate"
                              onChange={e => changeHandler(e, _idx)}
                              max={"9999-12-31"}
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className="form-control filter-input filter1-input2 w-100"
                              value={data?.endDate}
                              name="endDate"
                              onChange={e => changeHandler(e, _idx)}
                              max={"9999-12-31"}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5}>조회 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-primary btn-filter-outline-primary mt-2 me-0"
              onClick={() => {
                let tempAddData = [];
                const newUnClosedData = unClosedData.filter(data => {
                  if (!data.closedFlag) {
                    return { ...data, closedFlag: false };
                  } else if (data.closedFlag) {
                    const nowDate = new Date();
                    const year = nowDate.getFullYear();
                    const month = (nowDate.getMonth() + 1).toString().padStart(2, "0");
                    const yymmddT = data?.endDate ? moment(data.endDate).format("YYYYMMDD") : "";
                    const yeartMnth = yymmddT !== "" ? yymmddT.substring(0, 6) : year + month;

                    tempAddData.push({
                      ...data,
                      yeartMnth: yeartMnth,
                      yymmddF: data?.startDate ? moment(data.startDate).format("YYYYMMDD") : "",
                      yymmddT: yymmddT,
                    });
                  }
                });
                setUnclosedData(newUnClosedData);
                setAddData(tempAddData);
              }}
            >
              마감처리
            </button>
          </div>
        </div>

        <div style={{ width: "49.4%" }}>
          <div
            className={`table-container table-sticky 
      h-333`}
            id="scroll-table-body"
          >
            <table className="table table-bordered table-responsive my-table mt-0">
              <thead>
                <tr>
                  <th style={{ width: "3%" }}>선택</th>
                  <th style={{ width: "8%" }}>실적년월</th>
                  <th style={{ width: "8%" }}>지사</th>
                  <th style={{ width: "8%" }}>실적시작일</th>
                  <th style={{ width: "8%" }}>실적종료일</th>
                </tr>
              </thead>
              <tbody>
                {/* 마감 현황*/}
                {/* 마감 처리 추가 */}
                {closedData?.length > 0 ? (
                  <>
                    {addData.map((data, _idx) => {
                      return (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              checked
                              onClick={() => {
                                const copyList = addData;
                                let removeData = [];
                                if (copyList[_idx].closedFlag) {
                                  removeData.push({ ...copyList[_idx], closedFlag: false });
                                  copyList.splice(_idx, 1);
                                }
                                setUnclosedData(prev => {
                                  return [...unClosedData, ...removeData];
                                });
                                setAddData(prev => {
                                  return [...copyList];
                                });
                              }}
                            />
                          </td>
                          <td>{data?.yeartMnth}</td>
                          <td>{data?.jisaName}</td>
                          <td>{data?.yymmddF}</td>
                          <td>{data?.yymmddT}</td>
                        </tr>
                      );
                    })}
                    {closedData.map((data, _idx) => {
                      return (
                        <tr className="individualPerformanceClose_tr_disabled">
                          <td>
                            <input type="checkbox" checked />
                          </td>
                          <td>{data?.yearMnth}</td>
                          <td>{data?.jisaName}</td>
                          <td>{data?.yymmddF}</td>
                          <td>{data?.yymmddT}</td>
                        </tr>
                      );
                    })}
                    <div ref={setBottom} style={{ height: "1px", width: "100%" }} />
                  </>
                ) : (
                  <tr>
                    <td colSpan={5}>조회 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-primary btn-filter-outline-primary mt-2 me-0"
              onClick={() => {
                const sendCloseData = addData.map(data => {
                  const startDate = dateModify(data.yymmddF);
                  const endDate = dateModify(data.yymmddT);
                  const result = window.confirm(
                    `${startDate}부터 ${endDate}까지의 실적을 ${
                      data?.jisaName != null ? data?.jisaName : ""
                    } ${
                      data.yeartMnth
                    } 확정하여 마감처리를 합니다. 마감 처리 작업을 진행하시겠습니까? ${endDate} 이후의 실적은 다음달에 편입됩니다.`
                  );
                  if (result) {
                    finalClose.mutate({
                      jisa: data.jisa,
                      yymmddF: data.yymmddF,
                      yymmddT: data.yymmddT,
                      yearMnth: data.yymmddF.substring(0, 6),
                    });
                  }
                  return {
                    jisa: data.jisa,
                    yymmddF: data.yymmddF,
                    yymmddT: data.yymmddT,
                    yearMnth: data.yymmddF.substring(0, 6),
                  };
                });
              }}
            >
              마감 최종 처리
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
