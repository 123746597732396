import { pdfjs } from "react-pdf";

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ModalCodeRegister from "./cardMaster/modalCodeRegister";
import CardMasterRow from "./cardMaster/cardMasterRow";
import { useAtom } from "jotai/index";
import {
  cardList_atom,
  editedRowList_atom,
  newData_atom,
  searchedParam_atom,
  searchParam_atom,
  searchParamInit,
} from "../../../data/atom/dataManage/cardInquiry/cardMaster_atom";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useGetAccountList } from "../../../api/code/useGetAccountList";
import { useGetCardSelectList } from "../../../api/code/useGetCardSelectList";
import { useGetCardUserList } from "../../../api/code/useGetCardUserList";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { decrypt } from "../../../util/decrypt/decrypt";
import { fileDownload } from "../../../util/fileDownload/fileDownload";
import { useVirtualizer } from "@tanstack/react-virtual";
import usePrintTab from "../../../components/modal/usePrintTab";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const CardMaster = () => {
  const targetRef = useRef();
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [searchedParam, setSearchedParam] = useAtom(searchedParam_atom);
  const [editedRowList, setEditedRowList] = useAtom(editedRowList_atom);
  const [cardList, setCardList] = useAtom(cardList_atom);
  const [newData, setNewData] = useAtom(newData_atom);
  const [modalShow, setModalShow] = useState(false);
  const { addTabClosedEvent } = useRecentTabEvent();
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();
  const getCardUserList = useGetCardUserList();
  const getCardSelectList = useGetCardSelectList();
  const getAccountList = useGetAccountList();
  const searchCard = useMutation(
    data => axios.get("/api/data/cardMaster/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data?.result;

        setNewData({});
        setEditedRowList([]);
        setSearchedParam({
          cardType: searchParam.cardNo,
          ...searchParam,
          fromDate: moment(searchParam.frontDate).format("YYYY-MM-DD"),
          toDate: moment(searchParam.backDate).format("YYYY-MM-DD"),
        });

        setCardList(
          data.map((el, idx) => {
            return {
              ...el,
              idx: idx,
              userName: getCardUserList?.data?.find(e => e.key === el.userId)?.value ?? "",
              accountName: getAccountList?.data?.find(e => e.key === el.account)?.value ?? "",
            };
          })
        );
      },
    }
  );
  const saveCard = useMutation(data => axios.post("/api/data/cardMaster/save", data), {
    onSuccess: res => {
      searchCard.mutate(searchParam);
    },
  });
  const print1Card = useMutation(
    data => axios.get("/api/data/cardMaster/printSubject", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `법인카드_계정과목별_사용합계표_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );
  const print2Card = useMutation(
    data => axios.get("/api/data/cardMaster/printEvery", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `법인카드_사용내역_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );
  const print3Card = useMutation(
    data => axios.get("/api/data/cardMaster/printSum", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `법인카드_사용누계표_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );
  const excelCard = useMutation(
    data => axios.post("/api/data/cardMaster/downloadExcel", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        fileDownload(res.data, `법인카드_사용내역_엑셀_${moment().format("YY_MM_DD_hh_mm")}.xlsx`);
      },
    }
  );

  const rowVirtualizer = useVirtualizer({
    count: cardList.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const onChangeParamEventHandler = (key, value) => {
    if (key === "frontDate" || key === "backDate") {
      if (value === "" || value === "Invalid date") {
        return;
      }
      value = moment(value).format("YYYY-MM-DD");
    }
    setSearchParam({ ...searchParam, [key]: value });
  };

  const btnSearch = () => {
    if (searchParam.frontDate === "" || searchParam.backDate === "") {
      alert("사용일자를 입력해주세요.");
    } else {
      searchCard.mutate(searchParam);
    }
  };

  const btnPrint1 = () => {
    if (cardList.filter(el => !el.newCheck).length === 0) {
      alert("출력할 내역이 없습니다.");
    } else {
      print1Card.mutate(searchedParam);
    }
  };
  const btnPrint2 = () => {
    if (cardList.filter(el => !el.newCheck).length === 0) {
      alert("출력할 내역이 없습니다.");
    } else {
      print2Card.mutate(searchedParam);
    }
  };
  const btnPrint3 = () => {
    if (cardList.filter(el => !el.newCheck).length === 0) {
      alert("출력할 내역이 없습니다.");
    } else {
      print3Card.mutate(searchedParam);
    }
  };
  const btnExcel = () => {
    excelCard.mutate(cardList);
  };
  const btnCodeRegister = () => setModalShow(true);
  const btnAdd = () => {
    setNewData({
      account: "",
      cardName: "",
      cardNo: "",
      currDate: moment().format("YYYY-MM-DD HH:mm"),
      inputDate: moment().format("YYYY-MM-DD"),
      inputId: decrypt("userInfo").loginId,
      modDate: "",
      modId: "",
      page: 0,
      priceWon: 0,
      remark: "",
      saupno: "",
      seqNo: 0,
      storeName: "",
      userId: "",
      newCheck: true,
      idx: -1,
    });
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (editedRowList.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else if (
        editedRowList.filter(el => el.cardNo.trim() === "" || el.cardNo.length !== 16).length !== 0
      ) {
        alert("카드번호를 입력해주세요.");
      } else if (editedRowList.filter(el => el.currDate.trim() === "").length !== 0) {
        alert("사용일자를 입력해주세요.");
      } else if (editedRowList.filter(el => el.userId === "").length !== 0) {
        alert("사용자를 입력해주세요.");
      } else if (editedRowList.filter(el => el.account === "").length !== 0) {
        alert("계정과목을 입력해주세요.");
      } else if (editedRowList.filter(el => el.priceWon === 0).length !== 0) {
        alert("영수금액을 입력해주세요.");
      } else {
        let list = editedRowList.map(el => {
          return {
            ...el,
            cardNo: el.cardNo.replace(/-/g, ""),
            currDate: moment(el.currDate).format("YYYY-MM-DD HH:mm"),
            modId: decrypt("userInfo").loginId,
          };
        });
        if (newData.newFlag) {
          list = [newData, ...list];
        }

        saveCard.mutate(list);
      }
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam(searchParamInit);
      setCardList([]);
      setEditedRowList([]);
    });
  }, []);

  const calcSogae = (cardNo, index) => {
    let sum = 0;
    for (let i = index; i >= 0; i--) {
      if (cardList[i].cardNo === cardNo) {
        sum += cardList[i].priceWon;
      } else break;
    }
    return sum.toLocaleString();
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <ModalCodeRegister modalShow={modalShow} setModalShow={setModalShow} />
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">검색</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={btnCodeRegister}
              >
                코드등록
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">카드선택</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.cardNo}
                    onChange={e => {
                      onChangeParamEventHandler("cardNo", e.target.value);
                    }}
                    style={{ width: "170px" }}
                  >
                    <option value="A">전체</option>
                    <option value="B">거래소:전체</option>
                    <option value="C">회원권:전체</option>
                    <option value="D">팁스:전체</option>
                    <option value="E">거래소-KB카드</option>
                    <option value="F">거래소-BC카드</option>
                    <option value="G">거래소-신한카드</option>
                    {getCardSelectList?.data?.map((el, index) => {
                      return (
                        <option
                          style={
                            el.outDate !== null
                              ? { backgroundColor: "red", textDecoration: "line-through" }
                              : {}
                          }
                          value={el.cardNo}
                        >
                          {el.cardNo
                            ? el.cardNo.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/g, "$1-$2-$3-$4")
                            : ""}
                          {el.corpGubn ? " | " + el.corpGubn : ""}
                          {el.gubun ? " | " + el.gubun : ""}
                          {el.jisa ? " | " + el.jisa : ""}
                          {el.kind ? " | " + el.kind : ""}
                          {el.kname ? " | " + el.kname : ""}
                          {el.remark ? " | " + el.remark : ""}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">계정과목</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.accountSelect}
                    onChange={e => onChangeParamEventHandler("accountSelect", e.target.value)}
                  >
                    <option value="00">전 체</option>
                    {getAccountList?.data?.map(el => {
                      return <option value={el.key}>{el.value}</option>;
                    })}
                  </select>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label ms-3">검색 기간</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.frontDate}
                    onChange={e => onChangeParamEventHandler("frontDate", e.target.value)}
                    onKeyDown={pressEnter}
                    style={{ width: "200px", borderColor: "#C5C5C5" }}
                    min="1900-01-01"
                    max="2099-12-31"
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.backDate}
                    onChange={e => onChangeParamEventHandler("backDate", e.target.value)}
                    onKeyDown={pressEnter}
                    style={{ width: "200px", borderColor: "#C5C5C5" }}
                    min="1900-01-01"
                    max="2099-12-31"
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="d-flex">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btnPrint1}
                >
                  카드별 계정과목별 사용합계표
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btnPrint2}
                >
                  사용내역 출력
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btnPrint3}
                >
                  카드사용 누계표
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btnExcel}
                >
                  엑셀저장
                </button>
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btnAdd}
                  disabled={newData.newCheck}
                >
                  추가
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary m-0"
                  onClick={btnSave}
                >
                  저장
                </button>
              </div>
            </div>
            <div className="table-container table-sticky h-333" ref={targetRef}>
              <table
                className="table table-bordered table-responsive my-table"
                style={{
                  marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                  marginBottom: `${
                    rowVirtualizer.getTotalSize() -
                    (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                    rowVirtualizer.getVirtualItems().length * 30
                  }px`,
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "4.9%" }}>No</th>
                    <th style={{ width: "14.04%" }}>카드번호</th>
                    <th style={{ width: "12.64%" }}>사용일자</th>
                    <th style={{ width: "6.32%" }}>사용자</th>
                    <th style={{ width: "8.42%" }}>계정과목</th>
                    <th style={{ width: "12.64%" }}>영수금액(원)</th>
                    <th style={{ width: "10.53%" }}>비고</th>
                    <th style={{ width: "9.8%" }}>가맹점사업번호</th>
                    <th style={{ width: "8.42%" }}>가맹점명(사용처)</th>
                    <th style={{ width: "7%" }}>입력자</th>
                    <th>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  {cardList.length !== 0 ? (
                    rowVirtualizer.getVirtualItems().map((el, idx) => {
                      if (cardList[el.index]?.cardNo !== cardList[el.index + 1]?.cardNo) {
                        return (
                          <>
                            <CardMasterRow
                              data={cardList[el.index]}
                              index={el.index}
                              getCardSelectList={getCardSelectList}
                              getCardUserList={getCardUserList}
                              getAccountList={getAccountList}
                              searchCard={searchCard}
                            />
                            <tr className="bg-lightyellow">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-center">소 계</td>
                              <td className="text-end">
                                {calcSogae(cardList[el.index].cardNo, el.index)}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </>
                        );
                      } else {
                        return el.index === 0 && newData.newCheck ? (
                          <>
                            <CardMasterRow
                              data={newData}
                              index={-1}
                              getCardSelectList={getCardSelectList}
                              getCardUserList={getCardUserList}
                              getAccountList={getAccountList}
                              searchCard={searchCard}
                              isNew={true}
                            />
                            <CardMasterRow
                              data={cardList[el.index]}
                              index={el.index}
                              getCardSelectList={getCardSelectList}
                              getCardUserList={getCardUserList}
                              getAccountList={getAccountList}
                              searchCard={searchCard}
                            />
                          </>
                        ) : (
                          <CardMasterRow
                            data={cardList[el.index]}
                            index={el.index}
                            getCardSelectList={getCardSelectList}
                            getCardUserList={getCardUserList}
                            getAccountList={getAccountList}
                            searchCard={searchCard}
                          />
                        );
                      }
                    })
                  ) : newData.newCheck && cardList.length === 0 ? (
                    <CardMasterRow
                      data={newData}
                      index={-1}
                      getCardSelectList={getCardSelectList}
                      getCardUserList={getCardUserList}
                      getAccountList={getAccountList}
                      searchCard={searchCard}
                      isNew={true}
                    />
                  ) : (
                    <tr>
                      <td colSpan={11}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                  {cardList.length !== 0 ? (
                    <tr className="bg-lightblue">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-center">누 계</td>
                      <td className="text-end">
                        {cardList
                          .reduce((acc, cur) => {
                            return +acc + cur.priceWon;
                          }, 0)
                          .toLocaleString()}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
