import React, { useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const Eleven = ({ data, years }) => {
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={
              {
                // width: `${data.tableRows?.length !== 0 ? "1800px" : ""} `,
                // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                // marginBottom: `${
                //   rowVirtualizer.getTotalSize() -
                //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                //   rowVirtualizer.getVirtualItems().length * 30
                // }px`,
              }
            }
          >
            <thead>
              <tr>
                <th rowSpan={2}>연번</th>
                <th rowSpan={2}>자사구분</th>
                <th rowSpan={2}>딜러명</th>
                <th rowSpan={2}>직급</th>
                <th rowSpan={2}>입사일자</th>
                {Array.from({ length: 12 }, (_, idx) => (
                  <th colSpan={2}>{`${years}/${idx + 1}`}</th>
                ))}
                <th colSpan={2}>합계</th>
              </tr>
              <tr>
                {Array.from({ length: 13 }, (_, idx) => (
                  <>
                    <th>건수</th>
                    <th>매출</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.result?.length !== 0 ? (
                data.result?.reduce((acc, cur, idx, arr) => {
                  // 자사구분 합치기
                  if (cur?.deptName !== arr[idx - 1]?.deptName) {
                    acc = [
                      ...acc,
                      <tr className={`${cur.work1 === "22" ? "bg-red" : ""}`}>
                        <td>{idx + 1}</td>
                        <td
                          rowSpan={arr.filter(el => el.deptName === cur.deptName).length}
                          style={{ backgroundColor: "white", textDecoration: "none" }}
                        >
                          {cur.deptName}
                        </td>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.inDate}</td>
                        <td>{cur.exchangeCnt01}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt01)}</td>
                        <td>{cur.exchangeCnt02}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt02)}</td>
                        <td>{cur.exchangeCnt03}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt03)}</td>
                        <td>{cur.exchangeCnt04}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt04)}</td>
                        <td>{cur.exchangeCnt05}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt05)}</td>
                        <td>{cur.exchangeCnt06}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt06)}</td>
                        <td>{cur.exchangeCnt07}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt07)}</td>
                        <td>{cur.exchangeCnt08}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt08)}</td>
                        <td>{cur.exchangeCnt09}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt09)}</td>
                        <td>{cur.exchangeCnt10}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt10)}</td>
                        <td>{cur.exchangeCnt11}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt11)}</td>
                        <td>{cur.exchangeCnt12}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt12)}</td>
                        <td>{cur.sumExchange}</td>
                        <td className="text-end">{numberFixedFormat(cur.sumResult)}</td>
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr className={`${cur.work1 === "22" ? "bg-red" : ""}`}>
                        <td>{idx + 1}</td>
                        {/*<td rowSpan={arr.filter(el => el.deptName === cur.deptName).length}>*/}
                        {/*  {cur.deptName}*/}
                        {/*</td>*/}
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.inDate}</td>
                        <td>{cur.exchangeCnt01}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt01)}</td>
                        <td>{cur.exchangeCnt02}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt02)}</td>
                        <td>{cur.exchangeCnt03}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt03)}</td>
                        <td>{cur.exchangeCnt04}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt04)}</td>
                        <td>{cur.exchangeCnt05}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt05)}</td>
                        <td>{cur.exchangeCnt06}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt06)}</td>
                        <td>{cur.exchangeCnt07}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt07)}</td>
                        <td>{cur.exchangeCnt08}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt08)}</td>
                        <td>{cur.exchangeCnt09}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt09)}</td>
                        <td>{cur.exchangeCnt10}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt10)}</td>
                        <td>{cur.exchangeCnt11}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt11)}</td>
                        <td>{cur.exchangeCnt12}</td>
                        <td className="text-end">{numberFixedFormat(cur.resultAmnt12)}</td>
                        <td>{cur.sumExchange}</td>
                        <td className="text-end">{numberFixedFormat(cur.sumResult)}</td>
                      </tr>,
                    ];
                  }

                  // 자사구분 총계
                  if (cur?.deptName !== arr[idx + 1]?.deptName) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={5}>{cur.deptName} 계</td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt01}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt01)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt02}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt02)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt03}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt03)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt04}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt04)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt05}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt05)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt06}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt06)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt07}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt07)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt08}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt08)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt09}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt09)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt10}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt10)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt11}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt11)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.exchangeCnt12}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.resultAmnt12)}
                        </td>
                        <td>{data.resultSum[cur.deptName]?.sumExchange}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.deptName]?.sumResult)}
                        </td>
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td colSpan={5}>총계</td>
                        <td>{data.total.exchangeCnt01}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt01)}</td>
                        <td>{data.total.exchangeCnt02}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt02)}</td>
                        <td>{data.total.exchangeCnt03}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt03)}</td>
                        <td>{data.total.exchangeCnt04}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt04)}</td>
                        <td>{data.total.exchangeCnt05}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt05)}</td>
                        <td>{data.total.exchangeCnt06}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt06)}</td>
                        <td>{data.total.exchangeCnt07}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt07)}</td>
                        <td>{data.total.exchangeCnt08}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt08)}</td>
                        <td>{data.total.exchangeCnt09}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt09)}</td>
                        <td>{data.total.exchangeCnt10}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt10)}</td>
                        <td>{data.total.exchangeCnt11}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt11)}</td>
                        <td>{data.total.exchangeCnt12}</td>
                        <td className="text-end">{numberFixedFormat(data.total.resultAmnt12)}</td>
                        <td>{data.total.sumExchange}</td>
                        <td className="text-end">{numberFixedFormat(data.total.sumResult)}</td>
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={29}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
