import { atom } from "jotai";
import moment from "moment";

export const searchParamInit = {
  searchType: "1",
  date: moment().format("YYYY-MM-DD"),
  keyword: "",
  codeSelect: "",
  page: 1,
};

export const searchParam_atom = atom({ ...searchParamInit });

export const searchedParam_atom = atom({});

export const condoMemberList_atom = atom([]);

export const editedRowList_atom = atom([]);

export const currentListType_atom = atom(false); // false = 수정/변동종목 조회, true = 일반 검색
