import React, { useCallback, useMemo } from "react";
import { useAtom } from "jotai/index";
import { SidebarWork } from "./sidebarWork";
import { SidebarData } from "./sidebarData";
import { SidebarInitial } from "./sidebarInitial";
import { useLocation, useNavigate } from "react-router-dom";
import { currentSidebar_atom } from "../../../data/atom/currentSidebar_atom";
import { SidebarPerson } from "./sidebarPerson";
import { SidebarGoods } from "./sidebarGoods";
import { SidebarCode } from "./sidebarCode";
import { recentTap_atom } from "../../../data/atom/recentTap_atom";
import { SidebarApproval } from "./sidebarApproval";

export const SidebarMain = ({ data }) => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();

  const [sidebarMenuAtom, setSidebarMenuAtom] = useAtom(currentSidebar_atom);
  const [recentTap, setRecentTap] = useAtom(recentTap_atom);

  const func_recentTap = data => {
    const recentTapTmp = recentTap.map(i => i);

    if (recentTapTmp?.length >= 8) {
      recentTapTmp.pop();
    }

    if (recentTapTmp?.some(item => item.url === data.url)) {
      recentTapTmp.splice(
        recentTapTmp.findIndex(item => item.url === data.url),
        1
      );
    }

    setRecentTap([data, ...recentTapTmp]);
    navigate(defaultURL + data.url);
  };

  const sidebarList = useMemo(() => {
    // return data?.filter(el => el.pageName === "업무관리");

    return {
      work: data?.filter(el => el.pageName === "업무관리")[0],
      data: data?.filter(el => el.pageName === "자료관리")[0],
      person: data?.filter(el => el.pageName === "인사관리")[0],
      goods: data?.filter(el => el.pageName === "매물관리")[0],
      code: data?.filter(el => el.pageName === "코드관리")[0],
      approval: data?.filter(el => el.pageName === "결재관리")[0],
    };
  }, [data]);

  const selectSidebar = () => {
    switch (sidebarMenuAtom?.sidebarMenu ?? "/initial") {
      case "/initial":
        return <SidebarInitial />;
      case "/work":
        return <SidebarWork func_recentTap={func_recentTap} data={sidebarList.work} />;
      case "/data":
        return <SidebarData func_recentTap={func_recentTap} data={sidebarList.data} />;
      case "/person":
        return <SidebarPerson func_recentTap={func_recentTap} data={sidebarList.person} />;
      case "/goods":
        return <SidebarGoods func_recentTap={func_recentTap} data={sidebarList.goods} />;
      case "/code":
        return <SidebarCode func_recentTap={func_recentTap} data={sidebarList.code} />;
      case "/approval":
        return <SidebarApproval func_recentTap={func_recentTap} data={sidebarList.approval} />;
      default:
        return;
    }
  };

  return selectSidebar();
};
