import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import ModalRegisterMember from "../../addNewJongmokModal/ModalRegisterMember";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import moment from "moment";
import { useAtom } from "jotai/index";
import {
  editedSearchResult_atom,
  originSearchResult_atom,
  searchParam_atom,
} from "../../../../../data/atom/dataManage/golfMembershipPriceManage/individualMembershipPrice_atom";
import { useAtomValue } from "jotai";
import { blobToString } from "../../../../../util/blobToString";
import usePrintTab from "../../../../../components/modal/usePrintTab";

const TableButtonPanel = () => {
  const searchParam = useAtomValue(searchParam_atom);
  const [originData, setOriginData] = useAtom(originSearchResult_atom);
  const [editedData, setEditedData] = useAtom(editedSearchResult_atom);
  const [modalShow, setModalShow] = useState(null);

  const openPdf = usePrintTab();
  // 신규종목등록버튼 모달에서 사용하는 데이터
  const [editedModalData, setEditedModalData] = useState([]);
  const [originModalData, setOriginModalData] = useState([]);

  const update = useMutation(data => axios.post("/api/data/individual/update", data), {
    onSuccess: () => {
      search.mutate(searchParam);
    },
  });

  const search = useMutation(
    data =>
      axios.get("/api/data/individual/search", {
        params: data,
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setOriginData(result);
        setEditedData(result);
      },
    }
  );

  // 신규종목등록 버튼 모달의 데이터 불러오기
  const getUnMemberShip = useMutation(
    () =>
      axios.get("/api/data/individual/getUnMemberShip", {
        params: { searchType: decrypt("userInfo").userPermit },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        let tmp = result.map(i =>
          i.gubun21 === "" || i.gubun21 === null ? { ...i, gubun21: "1" } : i
        );
        setOriginModalData(tmp);
        setEditedModalData(tmp);
      },
      onError: e => {
        if (e.response.data.code === "-1") setModalShow(false);
      },
    }
  );

  // 신규종목등록 버튼 모달의 데이터 저장하기
  const saveUnMemberShip = useMutation(
    data => axios.post("/api/data/individual/saveUnMemberShip", data),
    {
      onSuccess: () => {
        // getUnMemberShip.mutate();
        search.mutate(searchParam);
        setModalShow(false);
      },
    }
  );

  // 이관 API
  const individualMigrateDB = useMutation(
    () =>
      axios.post("/api/data/individual/migrateDB", {
        ...searchParam,
        searchType: searchParam.lastSearchType,
      }),
    {
      onSuccess: res => {
        alert(res.data.msg);
      },
    }
  );

  // 시세표 중부권 pdf 다운로드
  const btn_middlePrint = useMutation(
    () =>
      axios.get("/api/data/individual/print/middle", {
        responseType: "blob",
        params: { siseDate: moment(searchParam.date).format("YYYY-MM-DD") },
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `시세표-중부권_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
      onError: e => {
        blobToString(e.response.data).then(data => {
          let err = JSON.parse(data);
          if (err.code === "-1") alert(err.message);
        });
      },
    }
  );

  // 시세표 남부권 pdf 다운로드
  const btn_bottomPrint = useMutation(
    () =>
      axios.get("/api/data/individual/print/bottom", {
        responseType: "blob",
        params: { siseDate: moment(searchParam.date).format("YYYY-MM-DD") },
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `시세표-남부권_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
      onError: e => {
        blobToString(e.response.data).then(data => {
          let err = JSON.parse(data);
          if (err.code === "-1") alert(err.message);
        });
      },
    }
  );

  // 수정/변동종목 조회
  const individualGetmodify = useMutation(
    data => axios.get("/api/data/individual/getmodify", { params: data }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setOriginData(result);
        setEditedData(result);
      },
    }
  );

  const btn_filterEdited = () => {
    // let filtered = editedData.filter((i, idx) => {
    //   let origin = "";
    //   let edited = "";
    //   // i와 originData[idx]의 모든 키값을 연결한 string을 만들어서 비교
    //   for (let key in i) {
    //     origin += originData[idx][key];
    //     edited += i[key];
    //   }
    //
    //   return origin !== edited || (i.gubun11 === i.gubun21 && i.sise12 - i.sise11 !== 0);
    // });
    //
    // if (filtered.length === 0) {
    //   alert("수정항목이 없습니다.");
    //   return;
    // }
    // setOriginData(filtered);
    // setEditedData(filtered.map(i => ({ ...i })));

    individualGetmodify.mutate(searchParam);
  };

  const btn_save = () => {
    let filtered = [];
    if (
      editedData.length > 0 &&
      moment(editedData[0].siseDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
    ) {
      filtered = editedData;
    } else {
      //editedData, originData 비교해서 수정된 데이터만 update API 호출
      filtered = editedData.filter((i, idx) => {
        let origin = "";
        let edited = "";
        // i와 originData[idx]의 모든 키값을 연결한 string을 만들어서 비교
        for (let key in i) {
          origin += originData[idx][key];
          edited += i[key];
        }

        return origin !== edited || (i.gubun11 === i.gubun21 && i.sise12 - i.sise11 !== 0);
      });
    }

    if (filtered.length === 0) {
      alert("저장할 데이터가 없습니다.");
      return;
    }

    update.mutate(
      filtered.map(i => ({
        searchType: searchParam.searchType,
        adminCheck: i.adminCheck,
        allGubn: i.allGubn,
        code: i.code,
        custId: i.custId,
        dayNum: i.dayNum,
        gubun11: i.gubun11,
        remark: i.remark,
        sise12: i.sise12,
        sise22: i.sise22,
        sise11: i.sise11,
        sise21: i.sise21,
        gubun21: i.gubun21,
        oldSiseDate: i.oldSiseDate ? moment(i.oldSiseDate).format("YYYY-MM-DD") : "",
        siseDate: moment(i.siseDate).format("YYYY-MM-DD"),
        viewCheck: i.viewCheck,
      }))
    );
  };

  // 신규몽족등록 버튼 모달 열기
  const btn_addNewOne = () => {
    setModalShow(true);
    getUnMemberShip.mutate();
  };

  // 신규몽족등록 모달 닫기
  const btn_onModalHide = () => {
    setModalShow(false);
    setOriginModalData([]);
    setEditedModalData([]);
  };

  // 신규몽족등록 모달 저장버튼
  const btn_onSaveBtnClick = () => {
    let filtered = editedModalData.filter(i => i.insertChk);
    saveUnMemberShip.mutate(filtered);
  };

  const btn_migrate = () => {
    individualMigrateDB.mutate();
  };

  return (
    <div className="card-header d-flex align-items-center justify-content-between">
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <ModalRegisterMember
        show={modalShow}
        data={editedModalData}
        setData={setEditedModalData}
        onHide={btn_onModalHide}
        onSaveBtnClick={btn_onSaveBtnClick}
      />
      <h5 className="card-title  m-0">회원권 시세 정보</h5>
      <div className="d-flex align-items-center justify-content-end">
        <button className="btn btn-outline-primary" onClick={() => btn_middlePrint.mutate()}>
          시세표-중부권
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={() => btn_bottomPrint.mutate()}>
          시세표-남부권
        </button>

        <button className="btn btn-outline-primary ms-8px" onClick={btn_filterEdited}>
          수정/변동종목 조회
        </button>

        <button className="btn btn-outline-primary ms-8px" onClick={btn_save}>
          저장
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btn_migrate}>
          이관
        </button>

        <button className="btn btn-outline-primary ms-8px" onClick={btn_addNewOne}>
          신규종목 등록
        </button>
      </div>
    </div>
  );
};

export default TableButtonPanel;
