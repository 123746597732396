import EmpModal from "../../../../../components/modal/Modal";
import React, { useState } from "react";
import { useGetPostGubun } from "../../../../../api/code/useGetPostGubun";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import usePrintTab from "../../../../../components/modal/usePrintTab";

export const LabelModal = ({ isLabelModalOpen, setIsLabelModalOpen }) => {
  const [row, setRow] = useState(1); // 행
  const [col, setCol] = useState(1); // 열

  const getPostGubun = useGetPostGubun(); // 발송방법

  const openPdf = usePrintTab();

  // 라벨지 출력
  const mailBoxPrintLabel = useMutation(
    data => axios.post("/api/work/mailBox/printLabel", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          openPdf(res?.data);
        }
        //fileDownload(res.data, `라벨지_${moment().format("YY_MM_DD_hh_mm")}.pdf`);
      },
    }
  );

  const btn_print = () => {
    if (window.confirm("라벨지를 출력하시겠습니까?")) {
      if (isLabelModalOpen.data.length === 0) {
        alert("출력할 데이터가 없습니다.");
        return;
      }

      mailBoxPrintLabel.mutate({
        row: row,
        column: col,
        list: isLabelModalOpen.data.map(el => ({
          asAddres: el.roadAddres,
          asCompany: el.company,
          asIlNo: el.ilNo.toString(),
          asKname: el.kname + el.position,
          asZipcode: el.zipcode,
          custId: el.custId,
        })),
      });
    }
  };

  return (
    <EmpModal
      show={isLabelModalOpen.isOpen}
      width="100%"
      // minWidth="475px"
      title="라벨지 출력"
      onHide={() => setIsLabelModalOpen({ data: null, isOpen: false })}
      // strClass="modal-xl"
      // size="xl"
      footer={
        <div className="d-flex justify-content-end m-0">
          <button
            className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn"
            onClick={btn_print}
          >
            출력
          </button>
          <button
            className="btn btn-outline-primary btn-filter-outline-primary ms-2"
            onClick={() =>
              setIsLabelModalOpen({
                data: null,
                isOpen: false,
              })
            }
          >
            종료
          </button>
        </div>
      }
    >
      <div className="card card-sub mt-0">
        <div className="card-body p-0">
          <table className="table table-bordered table-responsive my-table1">
            <tbody>
              <tr>
                <th style={{ width: "11.9%" }}>행</th>
                <td style={{ width: "13.1%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={row}
                    onChange={e => {
                      const { value } = e.target;

                      if (/^[1-8]*$/.test(value)) {
                        setRow(value);
                      }
                    }}
                    maxLength={1}
                  />
                </td>
                <th style={{ width: "11.9%" }}>열</th>
                <td style={{ width: "13.1%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={col}
                    onChange={e => {
                      const { value } = e.target;

                      if (/^[1-2]*$/.test(value)) {
                        setCol(value);
                      }
                    }}
                    maxLength={1}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="table-container table-sticky h-333 mt-2">
            <table className="table table-bordered table-responsive my-table mt-0">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>연번</th>
                  <th style={{ width: "4%" }}>업체명</th>
                  <th style={{ width: "4%" }}>발송방법</th>
                  <th style={{ width: "3%" }}>성명</th>
                  <th style={{ width: "5%" }}>출력방법</th>
                  <th style={{ width: "6%" }}>출력구분</th>
                </tr>
              </thead>
              <tbody>
                {isLabelModalOpen.data.length !== 0 ? (
                  isLabelModalOpen.data.map((el, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{el.company}</td>
                      <td> {getPostGubun.data?.find(el2 => el2.key === el.sendGubun)?.value}</td>
                      <td>{el.kname}</td>
                      <td>{el.printGubun2 === "1" ? "소봉투" : "라벨지"}</td>
                      <td>{el.printGubun === "Y" ? "Y" : "N"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>검색결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </EmpModal>
  );
};
