import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 이메일 셀렉트박스
 */
export const useGetEmail = () => {
  return useQuery(
    ["getEmail"],
    async () =>
      await axios
        .get("/api/codeMng/select/getEmail")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
