import moment from "moment/moment";
import { atom } from "jotai";

export const searchParam_init = {
  code: "",
  date: moment().format("YYYY-MM-DD"),
  keyword: "",
  searchType: "5",
  lastSearchType: "5",
  page: 1,
  init: false,
};

export const searchParam_atom = atom({ ...searchParam_init });
export const originSearchResult_atom = atom([]);
export const editedSearchResult_atom = atom([]);
