import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  approvalInbox_approveContent,
  approvalInbox_detailData,
  approvalInbox_drftsn,
  approvalInbox_searchData,
  approvalInbox_searchOption,
  approvalInbox_table_row,
} from "../../../data/atom/approvalManage/approvalInbox/approvalInbox_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import usePrintTab from "../../../components/modal/usePrintTab";
// 결재 수신함
export default function ApprovalInbox() {
  const [searchOption, setSearchOption] = useAtom(approvalInbox_searchOption);
  const [searchListNumber, setSearchListNumber] = useAtom(approvalInbox_table_row); // 테이블 row 갯수
  const [searchData, setSearchData] = useAtom(approvalInbox_searchData);
  const [detailData, setDetailData] = useAtom(approvalInbox_detailData);
  const [approveContent, setApproveContent] = useAtom(approvalInbox_approveContent);
  const [selectedData, setSelectedData] = useAtom(approvalInbox_drftsn);
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        confmResultCode: "0", // 결재상태
        drafter: "",
        drftType: "0", // 결재 구분
        fromDate: moment(new Date()).format("YYYY-MM-DD"),
        toDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setSearchListNumber("5");
      setSearchData([]);
      setDetailData(null);
      setApproveContent({
        confmOpinion: "",
        confmOrdr: "",
        drftSn: "",
        drftType: "",
      });
      setSelectedData({
        drftType: "",
        drftSn: "",
      });
    });
  }, []);

  // API - 수신함 조회
  const searchConfirm = useMutation(
    data =>
      axios.get("/api/approval/approvalMain/searchConfirm", {
        params: data,
      }),
    {
      onSuccess: res => {
        setSearchData(res?.data?.result);
      },
    }
  );

  // API - 결재 상세보기
  const getDetail = useMutation(
    data => axios.get("/api/approval/approvalMain/getDetail", { params: data }),
    {
      onSuccess: res => {
        setDetailData(res?.data?.result);
      },
    }
  );

  // API - 결재 승인
  const confirmSubmit = useMutation(
    data => axios.post("/api/approval/approvalMain/confirmSubmit", data),
    {
      onSuccess: res => {
        searchConfirm.mutate(searchOption);
        getDetail.mutate(selectedData);
        setApproveContent({ confmOpinion: "", confmOrdr: "", drftSn: "", drftType: "" });
        alert("결재가 승인되었습니다.");
      },
    }
  );

  // API - 결재 반려
  const returnSubmit = useMutation(
    data => axios.post("/api/approval/approvalMain/returnSubmit", data),
    {
      onSuccess: res => {
        searchConfirm.mutate(searchOption);
        getDetail.mutate(selectedData);
        setApproveContent({ confmOpinion: "", confmOrdr: "", drftSn: "", drftType: "" });
        alert("결재가 반려되었습니다.");
      },
    }
  );

  // API - 증명서 출력
  const docPrint = useMutation(
    data => axios.get("/api/emp/docMng/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `증명서_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const changeHandler = e => {
    const { name, value } = e.target;
    setSearchOption({ ...searchOption, [name]: value });
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      setSelectedData({
        drftType: "",
        drftSn: "",
      });
      searchConfirm.mutate(searchOption);
    }
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">결재 수신함</div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">상신일</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    name="fromDate"
                    onChange={e => changeHandler(e)}
                    max="9999-12-31"
                    value={searchOption.fromDate}
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    max="9999-12-31"
                    min={searchOption.fromDate}
                    name="toDate"
                    onChange={e => changeHandler(e)}
                    value={searchOption.toDate}
                  />
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">상신자</label>
                  <input
                    type="text"
                    className="form-control filter1-input1"
                    name="drafter"
                    onChange={e => changeHandler(e)}
                    value={searchOption.drafter}
                  />
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">결재구분</label>
                  <select
                    className="form-select filter1-select1"
                    name="drftType"
                    onChange={e => changeHandler(e)}
                    value={searchOption.drftType}
                  >
                    <option value="0">전체</option>
                    <option value="1">증명서</option>
                    <option value="2">연차관리</option>
                    <option value="3">거래대금 입출금</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">결재상태</label>
                  <select
                    className="form-select filter1-select1"
                    name="confmResultCode"
                    onChange={e => changeHandler(e)}
                    value={searchOption.confmResultCode}
                  >
                    <option value="0">전체</option>
                    <option value="1">결재완료</option>
                    <option value="2">반려</option>
                    <option value="">미결재</option>
                  </select>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                  onClick={() => {
                    setSelectedData({
                      drftType: "",
                      drftSn: "",
                    });
                    searchConfirm.mutate(searchOption);
                  }}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-main mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="tab1-row1-count">결재 수신 내역</div>
              <div className="d-flex align-items-center w-50 justify-content-end ">
                <select
                  className="form-select table-filter-select"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>
            <div
              className={`table-container table-sticky mt-10px
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
            >
              <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "6.9%" }}>연번</th>
                    <th style={{ width: "10.9%" }}>결재구분</th>
                    <th style={{ width: "15.5%" }}>상신자</th>
                    <th style={{ width: "13.2%" }}>기안일시</th>
                    <th style={{ width: "14.3%" }}>결재상태</th>
                    <th style={{ width: "18.4%" }}>결재자</th>
                    <th>결재일시</th>
                  </tr>
                </thead>
                <tbody>
                  {searchData?.length > 0 ? (
                    searchData.map((data, _idx) => {
                      return (
                        <tr
                          role="button"
                          onClick={() => {
                            setDetailData({});
                            const type =
                              data?.drftType === "연차관리"
                                ? "2"
                                : data?.drftType === "증명서"
                                ? "1"
                                : "3";
                            getDetail.mutate({
                              drftType: type,
                              drftSn: data?.drftSn,
                            });
                            setSelectedData({
                              drftType: type,
                              drftSn: data?.drftSn,
                            });
                          }}
                          className={`${selectedData?.drftSn === data?.drftSn && "bg-blue"}`}
                        >
                          <td>{_idx + 1}</td>
                          <td>{data?.drftType}</td>
                          <td>{data?.drafter}</td>
                          <td>{data?.drftDt && moment(data?.drftDt).format("YYYY-MM-DD")}</td>
                          <td>{data?.confmSttus}</td>
                          <td>{data?.confmer}</td>
                          <td>{data?.confmDt && moment(data?.confmDt).format("YYYY-MM-DD")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {(detailData?.yunchaInfo || detailData?.tradeInfo || detailData?.docInfo) &&
          selectedData.drftSn !== "" && (
            <div className="card card-main mt-14px">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="tab1-row1-count">상세 보기</div>
                  <div className="d-flex align-items-center w-50 justify-content-end">
                    {/* 증명서 출력 */}
                    {selectedData.drftType === "1" &&
                      detailData?.docInfo?.confmStatusCode === "2" && (
                        <button
                          className="btn btn-outline-primary ms-2 me-3"
                          onClick={() => {
                            docPrint.mutate({
                              drftSn: selectedData.drftSn,
                            });
                          }}
                        >
                          출력
                        </button>
                      )}
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setDetailData({});
                        setSelectedData({
                          drftType: "",
                          drftSn: "",
                        });
                      }}
                    >
                      닫기
                    </button>
                  </div>
                </div>

                <div className="w-80 m-auto">
                  <div className="row mt-3">
                    <div className="col-6">
                      {selectedData.drftType === "1" ? (
                        <h1 className="heading1">증 명 서</h1>
                      ) : selectedData.drftType === "2" ? (
                        <h1 className="heading1">휴 가 신 청 서</h1>
                      ) : (
                        <h1 className="heading1">
                          {selectedData.depoDraw ? "출" : "입"}금 결 의 서(
                          {`${detailData?.tradeInfo?.tradClassNm}-
                          ${detailData?.tradeInfo?.maedoMaesu === "0" ? "매도" : "매수"}`}
                          )
                        </h1>
                      )}
                    </div>
                    <div className="col-6">
                      <table className="table table-bordered table-responsive my-table1">
                        <tbody>
                          <tr>
                            <th style={{ width: "10%" }} rowSpan={2}>
                              결<br />재
                            </th>
                            {detailData?.confmList
                              .sort((a, b) => {
                                return a?.confmOrdr - b?.confmOrdr;
                              })
                              .map(data => {
                                return <th style={{ width: "23.5%" }}>{data?.kname}</th>;
                              })}
                          </tr>
                          <tr>
                            {detailData?.confmList
                              .sort((a, b) => {
                                return a?.confmOrdr - b?.confmOrdr;
                              })
                              .map(data => {
                                return <td className="text-center">{data?.confmSttus}</td>;
                              })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {detailData?.yunchaInfo && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <table className="table table-bordered table-responsive my-table1">
                          <tbody>
                            <tr>
                              <th style={{ width: "15%" }}>법 &nbsp; 인 &nbsp;명</th>
                              <td colSpan={3} className="text-center">
                                {detailData?.yunchaInfo?.dept}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "15%" }}>성 &nbsp; &nbsp; &nbsp;명</th>
                              <td className="text-center" style={{ width: "35%" }}>
                                {detailData?.yunchaInfo?.kname}
                              </td>
                              <th style={{ width: "15%" }}>직&nbsp; &nbsp; &nbsp;급</th>
                              <td className="text-center"> {detailData?.yunchaInfo?.auth}</td>
                            </tr>
                            <tr>
                              <th>연차구분</th>
                              <td className="text-center">{detailData?.yunchaInfo?.yunchaType}</td>
                              <th>소속</th>
                              <td className="text-center">{detailData?.yunchaInfo?.job}</td>
                            </tr>
                            <tr>
                              <th>기 &nbsp; &nbsp; &nbsp;간</th>
                              <td colSpan={3} className="text-center">
                                {detailData?.yunchaInfo?.workDate &&
                                  moment(detailData?.yunchaInfo?.workDate).format(
                                    "YYYY년 MM월 DD일"
                                  )}
                                &nbsp; &nbsp; &nbsp; - &nbsp; &nbsp; &nbsp;
                                {detailData?.yunchaInfo?.endDate &&
                                  moment(detailData?.yunchaInfo?.endDate).format(
                                    "YYYY년 MM월 DD일"
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <th>사 &nbsp; &nbsp; &nbsp;유</th>
                              <td colSpan={3} className="text-center">
                                {detailData?.yunchaInfo?.remark}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="f-15 f-bold text-center mt-3">
                          {detailData?.yunchaInfo?.applyDate &&
                            moment(detailData?.yunchaInfo?.applyDate).format("YYYY년 MM월 DD일")}
                        </div>
                        <div className="f-15 f-bold text-center mt-2">
                          신청자 : &nbsp; &nbsp;{detailData?.yunchaInfo?.kname} &nbsp;(인)
                        </div>
                      </div>
                    </div>
                  )}

                  {/* 상세보기 테이블 - 증명서 발급 신청서 */}
                  {detailData?.docInfo && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <table className="table table-bordered table-responsive my-table1">
                          <tbody>
                            <tr>
                              <th style={{ width: "13%" }}>성 &nbsp; &nbsp; &nbsp; &nbsp;명</th>
                              <td style={{ width: "20%" }} className="text-center">
                                {/* kname */}
                                {detailData?.docInfo?.kname}
                              </td>
                              <th style={{ width: "13%" }}>주민등록번호</th>
                              <td style={{ width: "20%" }} colSpan={3} className="text-center">
                                {/* juminNo */}
                                {detailData?.docInfo?.juminNo}
                              </td>
                            </tr>
                            <tr>
                              <th>부 &nbsp; &nbsp; &nbsp;서</th>
                              <td className="text-center">
                                {/* etcJob */} {detailData?.docInfo?.etcJob}
                              </td>
                              <th>직 &nbsp; &nbsp; &nbsp; &nbsp;위</th>
                              <td className="text-center">
                                {/* etcAuthority */} {detailData?.docInfo?.etcAuthority}
                              </td>
                              <th style={{ width: "13%" }}>소 &nbsp; &nbsp; &nbsp; &nbsp;속</th>
                              <td style={{ width: "20%" }} className="text-center">
                                {detailData?.docInfo?.etcDept}
                              </td>
                            </tr>
                            <tr>
                              <th>현 주 소</th>
                              <td colSpan={3} className="text-center">
                                {/* address1 */}
                                {/* address2 */}
                                {detailData?.docInfo?.addres1 + " " + detailData?.docInfo?.addres2}
                              </td>
                              <th>입사일자</th>
                              <td className="text-center">
                                {moment(detailData?.docInfo?.inDate).format("YYYY년 MM월 DD일")}
                              </td>
                            </tr>
                            <tr>
                              <th>용도</th>
                              <td className="text-center">
                                {/* reason */} {detailData?.docInfo?.reason}
                              </td>
                              <th>종류</th>
                              {/* remark */}
                              <td className="text-center">{detailData?.docInfo?.remark}</td>
                              <th>출력매수</th>
                              <td className="text-center">
                                {/* copies */}
                                {detailData?.docInfo?.copies}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="f-15 f-bold text-center mt-3">
                          {detailData?.docInfo?.issueDate &&
                            moment(detailData?.docInfo?.issueDate).format("YYYY년 MM월 DD일")}
                        </div>
                        <div className="f-15 f-bold text-center mt-2">
                          신청자 : &nbsp; &nbsp;{detailData?.docInfo?.kname} &nbsp;(인)
                        </div>
                      </div>
                    </div>
                  )}

                  {detailData?.tradeInfo && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="table-tabs-subsub w-50 m-0 my-3">거래 금액</div>

                        <table className="table table-bordered table-responsive my-table1 mb-20">
                          <tbody>
                            <tr>
                              <th style={{ width: "11.9%" }}>일금</th>
                              <td style={{ width: "13.1%" }} className="text-end">
                                {detailData?.tradeInfo?.sumAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th style={{ width: "11.9%" }}>종목구분</th>
                              <td style={{ width: "13.1%" }} className="text-center">
                                {detailData?.tradeInfo?.tradClass === "1" && "골프"}
                                {detailData?.tradeInfo?.tradClass === "2" && "콘도"}
                                {detailData?.tradeInfo?.tradClass === "3" && "피트니스"}
                              </td>
                              <th style={{ width: "11.9%" }}>회원권명</th>
                              <td className="text-center" style={{ width: "13.1%" }}>
                                {detailData?.tradeInfo?.tradClassNm}
                              </td>
                              <th style={{ width: "11.9%" }}>매도/매수</th>
                              <td className="text-center" style={{ width: "13.1%" }}>
                                {detailData?.tradeInfo?.maedoMaesu === "0" ? "매도" : "매수"}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "11.9%" }}>입/출금</th>
                              <td className="text-center" style={{ width: "13.1%" }}>
                                {detailData?.tradeInfo?.depoDraw ? "출금" : "입금"}
                              </td>
                              <th style={{ width: "11.9%" }}>구분</th>
                              <td className="text-center">{detailData?.tradeInfo?.gubun}</td>
                              <th>고객명</th>
                              <td className="text-center">{detailData?.tradeInfo?.custName}</td>
                              <th>거래금액</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.totalAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                            </tr>
                            <tr>
                              <th>계정과목</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.tradGubn === "1" && "계약금"}
                                {detailData?.tradeInfo?.tradGubn === "2" && "중도금"}
                                {detailData?.tradeInfo?.tradGubn === "3" && "잔금"}
                                {detailData?.tradeInfo?.tradGubn === "4" &&
                                  detailData?.tradeInfo?.addedGubn}
                              </td>
                              <th>계약금</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.firstAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th>중도금</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.middleAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th>명의개서료</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.writeAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                            </tr>
                            <tr>
                              <th>인지세</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.stampAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th>중개수수료</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.commiAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th>기타비용</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.addedAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th>분실료</th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.lostAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                            </tr>
                            <tr>
                              {/* 등기비 or 락카비정산 */}
                              <th>
                                {detailData?.tradeInfo?.tradClass === "3" ? "락카비정산" : "등기비"}
                              </th>
                              <td className="text-end">
                                {detailData?.tradeInfo?.tradClass === "3"
                                  ? detailData?.tradeInfo?.lockerAmnt
                                  : detailData?.tradeInfo?.registAmnt
                                      ?.toString()
                                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th style={{ width: "11.9%" }}>내역</th>
                              <td className="text-center" style={{ width: "13.1%" }}>
                                {detailData?.tradeInfo?.dealHistory}
                              </td>

                              {/* 콘도일때 연회비 정산 */}
                              {detailData?.tradeInfo?.tradClass === "2" && <th>연회비정산</th>}
                              {detailData?.tradeInfo?.tradClass === "2" && (
                                <td className="text-end">
                                  <>
                                    {detailData?.tradeInfo?.yearAmnt
                                      ?.toString()
                                      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                  </>
                                </td>
                              )}
                              {detailData?.tradeInfo?.tradClass !== "2" && <th></th>}
                              {detailData?.tradeInfo?.tradClass !== "2" && <td></td>}
                              <th></th>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>

                        <table className="table table-bordered table-responsive my-table1 mb-20">
                          <tbody>
                            <tr>
                              <th style={{ width: "11.9%" }}>잔금</th>
                              <td style={{ width: "21.43%" }} className="text-end">
                                {detailData?.tradeInfo?.remainAmnt
                                  ?.toString()
                                  ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <th style={{ width: "11.9%" }}>은행</th>
                              <td style={{ width: "21.43%" }}>{detailData?.tradeInfo?.payBank}</td>
                              <th style={{ width: "11.9%" }}>계좌번호</th>
                              <td>{detailData?.tradeInfo?.payAccount}</td>
                            </tr>
                            <tr>
                              <th>예금주(매수자)</th>
                              <td>{detailData?.tradeInfo?.payName}</td>
                              <th>관계</th>
                              <td>{detailData?.tradeInfo?.relation}</td>
                              <th></th>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="table-tabs-subsub w-50 m-0 my-3">회원 확인</div>
                        <table className="table table-bordered table-responsive my-table1 mb-0">
                          <tbody>
                            <tr>
                              <th style={{ width: "11.9%" }}>담당자</th>
                              <td style={{ width: "21.43%" }}>{detailData?.tradeInfo?.partner}</td>
                              <th style={{ width: "11.9%" }}>입회일자</th>
                              <td style={{ width: "21.43%" }}>{detailData?.tradeInfo?.inDate}</td>
                              <th style={{ width: "11.9%" }}>회원번호</th>
                              <td>{detailData?.tradeInfo?.custNo}</td>
                            </tr>
                            <tr>
                              <th>회원명</th>
                              <td>{detailData?.tradeInfo?.custName}</td>
                              <th>이상(유/무)</th>
                              <td>{detailData?.tradeInfo?.situation}</td>
                              <th>비고</th>
                              <td>{detailData?.tradeInfo?.remark}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="f-15 f-bold text-center mt-3">
                          {detailData?.tradeInfo?.issueDate &&
                            moment(detailData?.tradeInfo?.issueDate).format("YYYY년 MM월 DD일")}
                        </div>
                        <div className="f-15 f-bold text-center mt-2">
                          신청자 : &nbsp; &nbsp;{detailData?.tradeInfo?.kname} &nbsp;(인)
                        </div>
                      </div>
                    </div>
                  )}

                  {/* 1 - 결재중
                      2 - 결재완료
                      3 - 반려  */}
                  <div className="click_box">
                    {detailData?.loginInfo?.confmResultCode === "" ||
                    detailData?.loginInfo?.confmResultCode == null ? (
                      <div className="part-2 w-100">
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-outline-primary me-2"
                            onClick={() => {
                              confirmSubmit.mutate({
                                confmOpinion: approveContent.confmOpinion,
                                confmOrdr: detailData?.loginInfo.confmOrdr,
                                drftSn: selectedData?.drftSn,
                                drftType: selectedData?.drftType,
                              });
                            }}
                          >
                            승인
                          </button>
                          <button
                            className="btn btn-outline-primary ms-2"
                            onClick={() => {
                              returnSubmit.mutate({
                                confmOpinion: approveContent.confmOpinion,
                                confmOrdr: detailData?.loginInfo.confmOrdr,
                                drftSn: selectedData?.drftSn,
                                drftType: selectedData?.drftType,
                              });
                            }}
                          >
                            반려
                          </button>
                        </div>
                        <div className="px-2 mt-3">
                          <textarea
                            className="form-control w-100"
                            rows={3}
                            cols={20}
                            placeholder="사유:"
                            onChange={e => {
                              setApproveContent({
                                ...approveContent,
                                confmOpinion: e.target.value,
                              });
                            }}
                            value={approveContent.confmOpinion}
                          ></textarea>
                        </div>
                      </div>
                    ) : (
                      <>
                        {(detailData?.docInfo?.confmStatusCode === "1" ||
                          detailData?.yunchaInfo?.confmStatusCode === "1" ||
                          detailData?.tradeInfo?.confmStatusCode === "1") && (
                          <div className="part-1 w-100 text-center">
                            <h3 className="f-bold">결재중</h3>
                          </div>
                        )}
                        {(detailData?.docInfo?.confmStatusCode === "2" ||
                          detailData?.yunchaInfo?.confmStatusCode === "2" ||
                          detailData?.tradeInfo?.confmStatusCode === "2") && (
                          <div className="part-1 w-100 text-center">
                            <h3 className="f-bold">결재완료</h3>
                          </div>
                        )}
                        {(detailData?.docInfo?.confmStatusCode === "3" ||
                          detailData?.yunchaInfo?.confmStatusCode === "3" ||
                          detailData?.tradeInfo?.confmStatusCode === "3") && (
                          <div className="part-1 w-100 text-center">
                            <h3 className="f-bold">반려</h3>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
}
