import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 거래처 관리 등급구분 셀렉트박스
 */
export const useGetClassSupplier = () => {
  return useQuery(
    ["getClassSupplier"],
    async () =>
      await axios
        .get("/api/codeMng/select/getClassSupplier")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
