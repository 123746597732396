export const TableHead = ({ searchData }) => {
  if (
    searchData.condition === "1" ||
    searchData.condition === "2" ||
    searchData.condition === "3" ||
    searchData.condition === "5" ||
    searchData.condition === "11"
  ) {
    return (
      <tr>
        <th style={{ width: "32%" }}>관리번호</th>
        <th>회사명</th>
        <th>성명</th>
      </tr>
    );
  }

  if (searchData.condition === "4") {
    return (
      <tr>
        <th>관리번호</th>
        <th>회사명</th>
        <th>성명</th>
        <th>발송</th>
      </tr>
    );
  }

  if (searchData.condition === "6" || searchData.condition === "7") {
    return (
      <tr>
        <th>지사</th>
        <th>거래일자</th>
        <th>성명</th>
        <th>관청신고일</th>
        <th>거래번호</th>
        <th>거래종목</th>
      </tr>
    );
  }

  if (searchData.condition === "9") {
    return (
      <tr>
        <th>관리번호</th>
        <th>시도명</th>
        <th>관청명</th>
        <th>부서</th>
        <th>담당자</th>
      </tr>
    );
  }

  if (searchData.condition === "10" || searchData.condition === "14") {
    return (
      <tr>
        <th>회사명</th>
        <th>성명</th>
      </tr>
    );
  }

  if (searchData.condition === "11") {
    return (
      <tr>
        <th>관리번호</th>
        <th>회사명</th>
        <th>성명</th>
      </tr>
    );
  }

  if (searchData.condition === "12") {
    return (
      <tr>
        <th>세무사명</th>
        <th>관할국세청</th>
      </tr>
    );
  }

  if (searchData.condition === "13") {
    return (
      <tr>
        <th>성명</th>
        <th>업체명</th>
      </tr>
    );
  }

  if (searchData.condition === "15") {
    return (
      <tr>
        <th>연습장명</th>
        <th>이름</th>
        <th>직위</th>
      </tr>
    );
  }

  if (searchData.condition === "16") {
    return (
      <tr>
        <th>골프장명</th>
        <th>명의담당자</th>
      </tr>
    );
  }

  if (searchData.condition === "17") {
    return (
      <tr>
        <th>콘도명</th>
        <th>명의담당자</th>
      </tr>
    );
  }

  if (searchData.condition === "18") {
    return (
      <tr>
        <th>회사명</th>
        <th>명의담당</th>
      </tr>
    );
  }
};
