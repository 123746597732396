import EmpModal from "../../../../components/modal/Modal";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";

const regex_only_number = /^$|^\d+$/;

export const DailyCallModal = ({ isModalOpen, setIsModalOpen }) => {
  const [searchData, setSearchData] = useState({
    filter: "S",
    keyword: moment().format("YYYY-MM-DD"),
    // keyword: "2023-01-30",
  });

  const [searchedTableList, setSearchedTableList] = useState([]);
  const [modifyList, setModifyList] = useState([]);

  // 입력전용 조회
  const callDbdSearchInput = useMutation(
    data => axios.get("/api/work/callDbd/searchInput", { params: data }),
    {
      onSuccess: res => {
        setSearchedTableList(res.data.result);
        setModifyList(Array.from({ length: res.data.result.length }, () => false));
      },
      onError: err => {},
    }
  );

  // 입력전용 저장
  const callDbdSaveInput = useMutation(data => axios.post("/api/work/callDbd/saveInput", data), {
    onSuccess: res => {
      callDbdSearchInput.mutate(searchData);
    },
    onError: err => {},
  });

  useEffect(() => {
    callDbdSearchInput.mutate(searchData);
  }, []);

  const btn_search = () => {
    callDbdSearchInput.mutate(searchData);
  };

  const btn_add = () => {
    setSearchedTableList(prev => [
      {
        authority: "",
        callCntEtc: 0,
        callCntEtc1: 0,
        callCntNew: 0,
        callCntNew1: 0,
        callCntOld: 0,
        callCntOld1: 0,
        callCntSum: 0,
        callCntTime: "",
        callDate: "",
        gubun: "",
        gubun2: "",
        gubunName: "",
        ilNo: 0,
        job: "",
        jobName: "",
        monthCntNew: 0,
        sawonId: "",
        weekCntNew: 0,
      },
      ...prev,
    ]);

    setModifyList(prev => [true, ...prev]);
  };

  const btn_save = () => {
    callDbdSaveInput.mutate(searchedTableList.filter((el, index) => modifyList[index]));
  };

  const btn_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      callDbdSearchInput.mutate(searchData);
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <EmpModal
      show={isModalOpen}
      width="100%"
      minWidth="475px"
      title="입력전용"
      onHide={() => setIsModalOpen(false)}
      strClass="modal-scroll w-1200px"
      size="xl"
    >
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <div className="filter1-field filter1-field1 p-0" onKeyDown={pressEnter}>
            <label className="filter1-label">조회 일자</label>
            <input
              type="date"
              className="form-control filter-input filter1-input1"
              value={searchData.keyword}
              onChange={e => {
                setSearchData({ ...searchData, keyword: e.target.value });
              }}
              min="1900-01-01"
              max="2099-12-31"
            />
          </div>
          <div className="d-flex align-items-center justify-content-start">
            <button
              className="btn btn-outline-primary btn-filter-outline-primary"
              onClick={btn_search}
            >
              조회
            </button>
          </div>
        </div>
      </div>

      <div className="table-container table-sticky h-528 mt-2">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th rowSpan={2} style={{ width: "3%" }}>
                No.
              </th>
              <th rowSpan={2} style={{ width: "5%" }}>
                부서구분
              </th>
              <th rowSpan={2} style={{ width: "6%" }}>
                서명(부서명)
              </th>
              <th colSpan={2} style={{ width: "6%" }}>
                신규
              </th>
              <th rowSpan={2} style={{ width: "5%" }}>
                일일합계
              </th>
              <th colSpan={3} style={{ width: "8%" }}>
                신규문의전화
              </th>
              <th colSpan={2} style={{ width: "8%" }}>
                DB고객
              </th>
              <th colSpan={2} style={{ width: "8%" }}>
                기타연결
              </th>
            </tr>
            <tr>
              <th>월간</th>
              <th>주간</th>
              <th style={{ width: "3%" }}>변경전</th>
              <th style={{ width: "3%" }}>금일</th>
              <th style={{ width: "3%" }}>문의시간</th>
              <th style={{ width: "5%" }}>변경전</th>
              <th style={{ width: "5%" }}>금일</th>
              <th style={{ width: "5%" }}>변경전</th>
              <th style={{ width: "5%" }}>금일</th>
            </tr>
          </thead>
          <tbody>
            {searchedTableList.length !== 0 ? (
              searchedTableList.reduce((acc, cur, idx, arr) => {
                if (cur?.gubunName === arr[idx - 1]?.gubunName) {
                  acc = [
                    ...acc,
                    <tr>
                      <td>{idx + 1}</td>
                      {/* 부서명 */}
                      <td>{cur?.jobName}</td>
                      <td>
                        <div>
                          {/* 신규 - 월간 */}
                          {parseInt(cur?.monthCntNew ?? 0, 10)}
                        </div>
                      </td>
                      {/* 신규 - 주간 */}
                      <td>{cur.weekCntNew}</td>
                      {/* 일일합계 = 신규문의 전화 금일 + db고객 금일 + 기타연결 금일*/}
                      <td>
                        {parseInt(cur?.callCntNew ?? 0, 10) +
                          parseInt(cur?.callCntOld ?? 0, 10) +
                          parseInt(cur?.callCntEtc ?? 0, 10)}
                      </td>
                      {/* 신규문의전화 변경전 */}
                      <td>{cur.callCntNew1}</td>
                      <td>
                        {/* 신규문의전화 금일 */}
                        {!cur.gubunName.includes("기타부서") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntNew}
                            onChange={e => {
                              if (regex_only_number.test(e.target.value)) {
                                setSearchedTableList(
                                  searchedTableList.map((el2, index2) => {
                                    if (idx === index2) {
                                      setModifyList(prev => {
                                        prev[idx] = true;
                                        return [...prev];
                                      });

                                      return {
                                        ...el2,
                                        callCntNew: +e.target.value,
                                      };
                                    } else {
                                      return el2;
                                    }
                                  })
                                );
                              }
                            }}
                          />
                        ) : (
                          <div>{cur.callCntNew}</div>
                        )}
                      </td>
                      <td>
                        {/* 신규문의전화 문의시간 */}
                        {!cur.gubunName.includes("기타부서") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntTime}
                            onChange={e => {
                              setSearchedTableList(
                                searchedTableList.map((el2, index2) => {
                                  if (idx === index2) {
                                    setModifyList(prev => {
                                      prev[idx] = true;
                                      return [...prev];
                                    });

                                    return {
                                      ...el2,
                                      callCntTime: e.target.value,
                                    };
                                  } else {
                                    return el2;
                                  }
                                })
                              );
                            }}
                          />
                        ) : (
                          <div>{cur.callCntTime}</div>
                        )}
                      </td>
                      {/* Db고객 변경전 */}
                      <td>{cur.callCntOld1}</td>
                      {/* Db고객 금일*/}
                      <td>
                        {!cur.gubunName.includes("기타부서") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntOld}
                            onChange={e => {
                              if (regex_only_number.test(e.target.value)) {
                                setSearchedTableList(
                                  searchedTableList.map((el2, index2) => {
                                    if (idx === index2) {
                                      setModifyList(prev => {
                                        prev[idx] = true;
                                        return [...prev];
                                      });

                                      return {
                                        ...el2,
                                        callCntOld: +e.target.value,
                                      };
                                    } else {
                                      return el2;
                                    }
                                  })
                                );
                              }
                            }}
                          />
                        ) : (
                          <div>{cur.callCntOld}</div>
                        )}
                      </td>
                      {/* 기타연결 변경전*/}
                      <td>{cur.callCntEtc1}</td>
                      {/* 기타연결 금일*/}
                      <td>
                        {!cur.gubunName.includes("골프") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntEtc}
                            onChange={e => {
                              if (regex_only_number.test(e.target.value)) {
                                setSearchedTableList(
                                  searchedTableList.map((el2, index2) => {
                                    if (idx === index2) {
                                      setModifyList(prev => {
                                        prev[idx] = true;
                                        return [...prev];
                                      });

                                      return {
                                        ...el2,
                                        callCntEtc: +e.target.value,
                                      };
                                    } else {
                                      return el2;
                                    }
                                  })
                                );
                              }
                            }}
                          />
                        ) : (
                          <div>{cur.callCntEtc}</div>
                        )}
                      </td>
                    </tr>,
                  ];
                } else {
                  acc = [
                    ...acc,
                    <tr>
                      <td>{idx + 1}</td>
                      <td rowSpan={arr.filter(el => el.gubunName === cur.gubunName).length}>
                        {cur?.gubunName}
                      </td>
                      <td>{cur?.jobName}</td>
                      <td>
                        <div>
                          {/* 신규 - 월간 */}
                          {parseInt(cur?.monthCntNew ?? 0, 10)}
                        </div>
                      </td>
                      {/* 신규 - 주간 */}
                      <td>{cur.weekCntNew}</td>
                      {/* 일일합계 = 신규문의 전화 금일 + db고객 금일 + 기타연결 금일*/}
                      <td>
                        {parseInt(cur?.callCntNew ?? 0, 10) +
                          parseInt(cur?.callCntOld ?? 0, 10) +
                          parseInt(cur?.callCntEtc ?? 0, 10)}
                      </td>
                      {/* 신규문의전화 변경전 */}
                      <td>{cur.callCntNew1}</td>
                      <td>
                        {/* 신규문의전화 금일 */}
                        {!cur.gubunName.includes("기타부서") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntNew}
                            onChange={e => {
                              if (regex_only_number.test(e.target.value)) {
                                setSearchedTableList(
                                  searchedTableList.map((el2, index2) => {
                                    if (idx === index2) {
                                      setModifyList(prev => {
                                        prev[idx] = true;
                                        return [...prev];
                                      });

                                      return {
                                        ...el2,
                                        callCntNew: +e.target.value,
                                      };
                                    } else {
                                      return el2;
                                    }
                                  })
                                );
                              }
                            }}
                          />
                        ) : (
                          <div>{cur.callCntNew}</div>
                        )}
                      </td>
                      <td>
                        {/* 신규문의전화 문의시간 */}
                        {!cur.gubunName.includes("기타부서") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntTime}
                            onChange={e => {
                              setSearchedTableList(
                                searchedTableList.map((el2, index2) => {
                                  if (idx === index2) {
                                    setModifyList(prev => {
                                      prev[idx] = true;
                                      return [...prev];
                                    });

                                    return {
                                      ...el2,
                                      callCntTime: e.target.value,
                                    };
                                  } else {
                                    return el2;
                                  }
                                })
                              );
                            }}
                          />
                        ) : (
                          <div>{cur.callCntTime}</div>
                        )}
                      </td>
                      {/* Db고객 변경전 */}
                      <td>{cur.callCntOld1}</td>
                      {/* Db고객 금일*/}
                      <td>
                        {!cur.gubunName.includes("기타부서") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntOld}
                            onChange={e => {
                              if (regex_only_number.test(e.target.value)) {
                                setSearchedTableList(
                                  searchedTableList.map((el2, index2) => {
                                    if (idx === index2) {
                                      setModifyList(prev => {
                                        prev[idx] = true;
                                        return [...prev];
                                      });

                                      return {
                                        ...el2,
                                        callCntOld: +e.target.value,
                                      };
                                    } else {
                                      return el2;
                                    }
                                  })
                                );
                              }
                            }}
                          />
                        ) : (
                          <div>{cur.callCntOld}</div>
                        )}
                      </td>
                      {/* 기타연결 변경전*/}
                      <td>{cur.callCntEtc1}</td>
                      {/* 기타연결 금일*/}
                      <td>
                        {!cur.gubunName.includes("골프") ? (
                          <input
                            type="text"
                            className="form-control"
                            value={cur.callCntEtc}
                            onChange={e => {
                              if (regex_only_number.test(e.target.value)) {
                                setSearchedTableList(
                                  searchedTableList.map((el2, index2) => {
                                    if (idx === index2) {
                                      setModifyList(prev => {
                                        prev[idx] = true;
                                        return [...prev];
                                      });

                                      return {
                                        ...el2,
                                        callCntEtc: +e.target.value,
                                      };
                                    } else {
                                      return el2;
                                    }
                                  })
                                );
                              }
                            }}
                          />
                        ) : (
                          <div>{cur.callCntEtc}</div>
                        )}
                      </td>
                    </tr>,
                  ];
                }
                // * 소계 *
                if (cur?.gubunName !== arr[idx + 1]?.gubunName) {
                  const filterArr = arr.filter(el => el.gubunName === cur.gubunName);
                  acc = [
                    ...acc,
                    <tr className="bg-lightyellow">
                      <td></td>
                      <td></td>
                      <td>소계</td>
                      {/* 신규 월간 소계  */}
                      <td>
                        {filterArr.reduce((acc, cur) => {
                          return acc + Number(cur.monthCntNew);
                        }, 0)}
                      </td>
                      {/* 신규 주간 소계 */}
                      <td>
                        {filterArr.reduce((acc, cur) => {
                          return acc + Number(cur.weekCntNew);
                        }, 0)}
                      </td>
                      {/* 일일 합계 */}
                      <td>
                        {filterArr.reduce((acc, cur) => {
                          return (
                            acc +
                            Number(cur.callCntOld) +
                            Number(cur.callCntNew) +
                            Number(cur.callCntEtc)
                          );
                        }, 0)}
                      </td>
                      {/* 신규 문의전화 변경전 */}
                      <td>-</td>
                      {/* 신규 문의전화 금일 */}
                      <td>
                        {cur?.gubunName !== "기타부서"
                          ? filterArr.reduce((acc, cur) => {
                              return acc + Number(cur.callCntNew);
                            }, 0)
                          : "-"}
                      </td>
                      {/* 신규 문의전화 문의시간 */}
                      <td>-</td>
                      {/* DB고객 변경전 */}
                      <td>-</td>
                      {/* DB고객 금일 */}
                      <td>
                        {cur?.gubunName !== "기타부서"
                          ? filterArr.reduce((acc, cur) => {
                              return acc + Number(cur.callCntOld);
                            }, 0)
                          : "-"}
                      </td>
                      {/* 기타연결 변경전 */}
                      <td>-</td>
                      {/* 기타연결 금일 */}
                      <td>
                        {!cur?.gubunName.includes("골프")
                          ? filterArr.reduce((acc, cur) => {
                              return acc + Number(cur.callCntEtc);
                            }, 0)
                          : "-"}
                      </td>
                    </tr>,
                  ];
                }
                // 총계
                if (arr.length === idx + 1) {
                  acc = [
                    ...acc,
                    <tr className="bg-lightblue">
                      <td></td>
                      <td></td>
                      <td>총계</td>
                      {/* 신규 월간 소계  */}
                      <td>
                        {arr.reduce((acc, cur) => {
                          return acc + Number(cur.monthCntNew);
                        }, 0)}
                      </td>
                      {/* 신규 주간 소계 */}
                      <td>
                        {arr.reduce((acc, cur) => {
                          return acc + Number(cur.weekCntNew);
                        }, 0)}
                      </td>
                      {/* 일일 합계 */}
                      <td>
                        {arr.reduce((acc, cur) => {
                          return (
                            acc +
                            Number(cur.callCntOld) +
                            Number(cur.callCntNew) +
                            Number(cur.callCntEtc)
                          );
                        }, 0)}
                      </td>
                      {/* 신규 문의전화 변경전 */}
                      <td>-</td>
                      {/* 신규 문의전화 금일 */}
                      <td>
                        {arr.reduce((acc, cur) => {
                          return acc + Number(cur.callCntNew);
                        }, 0)}
                      </td>
                      {/* 신규 문의전화 문의시간 */}
                      <td>-</td>
                      {/* DB고객 변경전 */}
                      <td>-</td>
                      {/* DB고객 금일 */}
                      <td>
                        {arr.reduce((acc, cur) => {
                          return acc + Number(cur.callCntOld);
                        }, 0)}
                      </td>
                      {/* 기타연결 변경전 */}
                      <td>-</td>
                      {/* 기타연결 금일 */}
                      <td>
                        {arr.reduce((acc, cur) => {
                          return acc + Number(cur.callCntEtc);
                        }, 0)}
                      </td>
                    </tr>,
                  ];
                }

                return acc;
              }, [])
            ) : (
              <tr>
                <td colSpan={13}>검색결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2">
        {/* <button
          className="btn btn-outline-primary btn-filter-outline-primary"
          onClick={btn_add}
          disabled={searchedTableList.length === 0}
        >
          추가
        </button> */}
        <button className="btn btn-outline-primary btn-filter-outline-primary" onClick={btn_save}>
          저장
        </button>
        <button className="btn btn-outline-primary btn-filter-outline-primary" onClick={btn_cancel}>
          취소
        </button>
        {/* <button
          className="btn btn-outline-primary btn-filter-outline-primary"
          onClick={() => setIsModalOpen(false)}
        >
          닫기
        </button> */}
      </div>
    </EmpModal>
  );
};
