import React from "react";
import moment from "moment";

export default function SearchOption({ curDate, setCurDate }) {
  return (
    <div className="row">
      <div className="col"></div>
      <div className="col text-center f-bold ">{moment(curDate).format("YYYY년 MM월")}</div>
      <div className="col d-flex justify-content-end">
        <button
          className="btn btn-outline-primary btn-filter-outline-primary me-0 ms-8px"
          onClick={() => {
            setCurDate(moment(new Date()).format("YYYY-MM"));
          }}
        >
          오늘
        </button>
        <button
          className="btn btn-outline-primary btn-filter-outline-primary me-0 ms-8px"
          onClick={() => {
            setCurDate(moment(curDate).subtract(1, "month").format("YYYY-MM"));
          }}
        >
          전날
        </button>
        <input
          type="month"
          className="form-control filter1-input1 me-0 ms-8px"
          value={curDate}
          onChange={e => {
            setCurDate(e.target.value);
          }}
          max="9999-12"
          min="1950-01"
        />
        <button
          className="btn btn-outline-primary btn-filter-outline-primary me-0 ms-8px"
          onClick={() => {
            setCurDate(moment(curDate).add(1, "month").format("YYYY-MM"));
          }}
        >
          다음날
        </button>
      </div>
    </div>
  );
}
