import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  currentRow_atom,
  medoMesu_atom,
  medoMesuCost_atom,
  modalCurrentGolf_atom,
  modalGolfData_atom,
  modalResult_atom,
  modalShow_atom,
  modalTax_atom,
  modalTaxList_atom,
  resultInit,
  taxInit,
} from "../../../../data/atom/workManage/acquTax/acquTax_atom";

export default function ModalCalculator() {
  const [modalShow, setModalShow] = useAtom(modalShow_atom);
  const [medoMesuCost, setMedoMesuCost] = useAtom(medoMesuCost_atom);
  const [medoMesu, setMedoMesu] = useAtom(medoMesu_atom);
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [taxList, setTaxList] = useAtom(modalTaxList_atom);
  const [tax, setTax] = useAtom(modalTax_atom);
  const [result, setResult] = useAtom(modalResult_atom);
  const [golfData, setGolfData] = useAtom(modalGolfData_atom);
  const [currentGolf, setCurrentGolf] = useAtom(modalCurrentGolf_atom);

  const queryClient = useQueryClient();
  const getChangeOx = queryClient.getQueryData(["getChangeOx"]);
  const getGolfCustList = queryClient.getQueryData(["getGolfCustList"]);
  const searchGolfAddress = useMutation(
    data => axios.get("/api/work/acquisitionTax/addres", { params: data }),
    {
      onSuccess: res => {
        const data = res.data?.result?.[0];

        setGolfData(data);
      },
    }
  );
  const searchTaxList = useMutation(
    data => axios.get("/api/work/acquisitionTax/getCal", { params: data }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;
        setTax({ ...taxInit });
        setResult({ ...resultInit });
        setTaxList(data);

        searchGolfAddress.mutate(variables);

        let select = "P";
        let radio = "P";

        if (medoMesu.sgubun === "2" && modalShow.isNewClickRow) {
          if (
            confirm(
              "매도법인이\n『 과세법인 』이면 → 확인(Y)\n『 면세법인 』이면 → 취소(N)를 선택하세요."
            )
          ) {
            select = "C";
          } else {
            select = "D";
          }
        }

        if (medoMesu.rgubun === "2" && modalShow.isNewClickRow) {
          if (
            confirm(
              "매수법인이\n『 과세법인 』이면 → 확인(Y)\n『 면세법인 』이면 → 취소(N)를 선택하세요."
            )
          ) {
            radio = "C";
          } else {
            radio = "D";
          }
        }

        if (modalShow.isNewClickRow) {
          let flag = false;
          data?.map((el, idx) => {
            if (medoMesu.code === el.code) {
              flag = true;
              let tmpTax = { ...taxInit };

              if (
                radio === "C" ||
                radio === "D" ||
                golfData?.governCustId === "1016" ||
                golfData?.governCustId === "1122" ||
                golfData?.governCustId === "1124" ||
                (radio !== "P" && golfData?.addres?.includes("기흥구")) ||
                golfData?.addres?.includes("처인구") ||
                golfData?.addres?.includes("남양주시")
              ) {
                tmpTax = {
                  ...tmpTax,
                  famRegWon: Math.round((el.gaesuCorp * 10) / 11),
                };

                if (el.etcType === "31") {
                  if (el.gaesuJujung !== 0) {
                    tmpTax = {
                      ...tmpTax,
                      gaesuWon: Math.round((el.gaesuPersonal * 10) / 11),
                    };
                  } else {
                    tmpTax = {
                      ...tmpTax,
                      gaesuWon: Math.round((el.gaesuJujung * 10) / 11),
                    };
                  }
                } else {
                  tmpTax = {
                    ...tmpTax,
                    gaesuWon: Math.round((el.gaesuPersonal * 10) / 11),
                  };
                }
              }

              if (radio === "P") {
                if (select === "P" || select === "D") {
                  const consultWon = currentRow.downYn
                    ? 0
                    : currentRow.yangdoWon1 * 10000 > el.chpWon &&
                      currentRow.yangdoWon1 * 10000 > currentRow.yangdoWon2 * 10000
                    ? currentRow.yangdoWon1 * 10000 - currentRow.yangdoWon2 * 10000
                    : 0;

                  tmpTax = {
                    ...tmpTax,
                    sum:
                      currentRow.yangdoWon2 * 10000 +
                      consultWon +
                      tmpTax.gaesuWon +
                      tmpTax.famRegWon,
                    contWon: currentRow.yangdoWon2 * 10000,
                    maesuWon: currentRow.yangdoWon1 * 10000,
                    consultWon: consultWon,
                    chpWon: el.chpWon,
                    idx: idx,
                    down:
                      currentRow.downYn === "Y" && currentRow.yangdoWon1 >= currentRow.yangdoWon2,
                    code: el.code,
                    select: select,
                    radio: radio,
                  };
                } else {
                  const consultWon = currentRow.downYn
                    ? 0
                    : currentRow.yangdoWon1 * 10000 > el.chpWon &&
                      currentRow.yangdoWon1 * 10000 > currentRow.yangdoWon2 * 10000
                    ? currentRow.yangdoWon1 * 10000 - currentRow.yangdoWon2 * 10000
                    : 0;

                  tmpTax = {
                    ...tmpTax,
                    sum:
                      Math.round((currentRow.yangdoWon2 * 10000 * 10) / 11) +
                      consultWon +
                      tmpTax.gaesuWon +
                      tmpTax.famRegWon,
                    contWon: currentRow.yangdoWon2 * 10000,
                    maesuWon: currentRow.yangdoWon1 * 10000,
                    consultWon: consultWon,
                    chpWon: el.chpWon,
                    idx: idx,
                    down:
                      currentRow.downYn === "Y" && currentRow.yangdoWon1 >= currentRow.yangdoWon2,
                    code: el.code,
                    select: select,
                    radio: radio,
                  };
                }
              } else {
                if (select === "P" || select === "D") {
                  tmpTax = {
                    ...tmpTax,
                    sum:
                      currentRow.yangdoWon1 * 10000 +
                      Math.round((currentRow.commission * 10000 * 10) / 11) +
                      tmpTax.gaesuWon +
                      tmpTax.famRegWon,
                    contWon: currentRow.yangdoWon2 * 10000,
                    maesuWon: currentRow.yangdoWon1 * 10000,
                    charge: Math.round((currentRow.commission * 10000 * 10) / 11),
                    chpWon: el.chpWon,
                    idx: idx,
                    code: el.code,
                    select: select,
                    radio: radio,
                  };
                } else {
                  tmpTax = {
                    ...tmpTax,
                    sum:
                      Math.round((currentRow.yangdoWon1 * 10000 * 10) / 11) +
                      currentRow.commission * 10000 * 10 +
                      tmpTax.gaesuWon +
                      tmpTax.famRegWon,
                    contWon: currentRow.yangdoWon2 * 10000,
                    maesuWon: currentRow.yangdoWon1 * 10000,
                    charge: Math.round((currentRow.commission * 10000 * 10) / 11),
                    chpWon: el.chpWon,
                    idx: idx,
                    code: el.code,
                    select: select,
                    radio: radio,
                  };
                }
              }

              setTax(tmpTax);
            }
          });

          if (!flag) {
            alert(
              "회원권 종목명에 맞는 시가표준액 코드를 찾지 못했습니다. \n회원권 종목을 직접 선택해주세요."
            );

            let tmpTax = { ...taxInit };

            if (radio === "P" && select === "P") {
              tmpTax = {
                ...tmpTax,
                sum: currentRow.yangdoWon1 * 10000 + tmpTax.gaesuWon + tmpTax.famRegWon,
                contWon: currentRow.yangdoWon2 * 10000,
                maesuWon: currentRow.yangdoWon1 * 10000,
                consultWon:
                  currentRow.yangdoWon1 * 10000 > currentRow.yangdoWon2 * 10000
                    ? currentRow.yangdoWon1 * 10000 - currentRow.yangdoWon2 * 10000
                    : 0,
                select: select,
                radio: radio,
              };
            } else {
              if (select === "P") {
                tmpTax = {
                  ...tmpTax,
                  sum:
                    currentRow.yangdoWon1 * 10000 +
                    Math.round((currentRow.commission * 10000 * 10) / 11) +
                    tmpTax.gaesuWon +
                    tmpTax.famRegWon,
                  contWon: currentRow.yangdoWon2 * 10000,
                  maesuWon: currentRow.yangdoWon1 * 10000,
                  charge: Math.round((currentRow.commission * 10000 * 10) / 11),
                  select: select,
                  radio: radio,
                };
              } else {
                tmpTax = {
                  ...tmpTax,
                  sum:
                    Math.round((currentRow.yangdoWon1 * 10000 * 10) / 11) +
                    Math.round((currentRow.commission * 10000 * 10) / 11) +
                    tmpTax.gaesuWon +
                    tmpTax.famRegWon,
                  contWon: currentRow.yangdoWon2 * 10000,
                  maesuWon: currentRow.yangdoWon1 * 10000,
                  charge: Math.round((currentRow.commission * 10000 * 10) / 11),
                  select: select,
                  radio: radio,
                };
              }
            }

            setTax(tmpTax);
          }
        }
      },
    }
  );

  useEffect(() => {
    if (taxList.length === 0) {
      if (getGolfCustList?.length !== 0 && modalShow.flag && medoMesu.cgubun === "1") {
        searchTaxList.mutate({ custId: currentGolf });
      }
    } else {
      if (
        getGolfCustList?.length !== 0 &&
        modalShow.flag &&
        modalShow.isNewClickRow &&
        medoMesu.cgubun === "1"
      ) {
        searchTaxList.mutate({ custId: currentGolf });
      }
    }
  }, [modalShow]);

  const onChangeEventHandler = (key, value) => {
    if (key === "golfSelect") {
      setCurrentGolf(value);
      searchTaxList.mutate({ custId: value });
    }
  };

  const onChangeRadio = key => {
    let tmpTax = { ...tax };
    let clickedRow = taxList.find(e => e.code === tmpTax.code);

    if (key === "radioP") {
      tmpTax.radio = "P";
    } else if (key === "radioC") {
      tmpTax.radio = "C";
    } else if (key === "radioD") {
      tmpTax.radio = "D";
    }

    if (clickedRow) {
      tmpTax = wf_gaesu_chk(clickedRow, tmpTax);
    }

    wf_won1_1_set(tmpTax);
  };

  const wf_gaesu_chk = (row, tmpTax) => {
    if (
      golfData?.governCustId === "1016" ||
      golfData?.governCustId === "1122" ||
      golfData?.governCustId === "1124" ||
      tmpTax.radio === "C" ||
      tmpTax.radio === "D"
    ) {
      if (row.etcType === "31") {
        if (row.gaesuJujung !== 0) {
          tmpTax.gaesuWon = Math.round((row.gaesuJujung * 10) / 11);
        } else {
          tmpTax.gaesuWon = Math.round((row.gaesuPersonal * 10) / 11);
        }
      } else {
        tmpTax.gaesuWon = Math.round((row.gaesuPersonal * 10) / 11);
      }

      if (row.gaesuCorp > 0) tmpTax.famRegWon = Math.round((row.gaesuCorp * 10) / 11);
      else tmpTax.famRegWon = row.gaesuCorp;
    } else {
      tmpTax.gaesuWon = 0;
      tmpTax.famRegWon = 0;
    }

    tmpTax.chpWon = row.chpWon;
    return tmpTax;
  };
  const wf_won1_1_set = tmpTax => {
    if (tmpTax.contWon > 0 && tmpTax.maesuWon === 0) {
      if (tmpTax.radio === "P") tmpTax.maesuWon = tmpTax.contWon;
      else if (tmpTax.radio === "C" || tmpTax.radio === "D") {
        if (tmpTax.select === "P" || tmpTax.select === "D") tmpTax.maesuWon = tmpTax.contWon;
        else tmpTax.maesuWon = Math.round((tmpTax.contWon * 10) / 11);
      }
    }

    if ((tmpTax.radio === "C" || tmpTax.radio === "D") && tmpTax.contWon > tmpTax.maesuWon) {
      alert("장부가액은 계약서 금액보다 작을 수 없습니다.");
      return;
    }

    if (tmpTax.contWon >= tmpTax.maesuWon) {
      tmpTax.consultWon = 0;
    } else {
      switch (tmpTax.radio) {
        case "P":
          if (tmpTax.chpWon > tmpTax.maesuWon) {
            tmpTax.consultWon = 0;
          } else {
            if (tmpTax.select === "C")
              tmpTax.consultWon = Math.round(((tmpTax.maesuWon - tmpTax.contWon) * 10) / 11);
            else tmpTax.consultWon = tmpTax.maesuWon - tmpTax.contWon;
          }
          break;
        case "C":
        case "D":
          tmpTax.consultWon = Math.round(((tmpTax.maesuWon - tmpTax.contWon) * 10) / 11);
      }
    }

    onChangeLeftPanelEventHandler2("down", false, tmpTax);
  };

  const onChangeLeftPanelEventHandler2 = (key, value, paramTax) => {
    let tmpTax;
    if (paramTax) tmpTax = paramTax;
    else tmpTax = { ...tax };

    tmpTax = { ...tmpTax, [key]: value };

    switch (key) {
      case "down":
        switch (tmpTax.radio) {
          case "P":
            if (value === true) {
              tmpTax.consultWon = 0;
            } else {
              if (tmpTax.contWon < tmpTax.maesuWon) {
                if (tmpTax.select === "P" && tmpTax.chpWon > tmpTax.maesuWon) {
                  tmpTax.down = true;
                  tmpTax.consultWon = 0;
                } else {
                  if (tmpTax.select === "P" || tmpTax.select === "D")
                    tmpTax.consultWon = tmpTax.maesuWon - tmpTax.contWon;
                  else
                    tmpTax.consultWon = Math.round(((tmpTax.maesuWon - tmpTax.contWon) * 10) / 11);
                }
              }
            }
            break;
          case "C":
          case "D":
            if (tmpTax.contWon < tmpTax.maesuWon)
              tmpTax.consultWon = Math.round(((tmpTax.maesuWon - tmpTax.contWon) * 10) / 11);
        }
        setTax({ ...tmpTax, sum: calcSum(tmpTax, "contWon", tmpTax.contWon) });
        break;
      case "contWon":
      case "maesuWon":
      case "select":
        wf_won1_1_set(tmpTax);
    }
  };

  const onChangeLeftPanelEventHandler3 = (key, value) => {
    setTax({ ...tax, [key]: value });
  };

  const calcSum = (tmpTax, key, value) => {
    let tmpValue = tmpTax.gaesuWon + tmpTax.famRegWon + tmpTax.charge + tmpTax.consultWon;
    let resultKey = tmpTax[key];
    let won1 = tmpTax.maemaeWon;
    if (key === "maemaeWon" || key === "contWon") {
      value = Math.round((value * 10) / 11);
      resultKey = value;
      won1 = tmpTax[key];
    }

    if (tmpTax.select === "C") {
      tmpValue += Math.round(won1 * 10) / 11 - resultKey + value;
    } else {
      tmpValue += won1 - resultKey + value;
    }

    return Math.round(tmpValue);
  };

  const onChangeRightPanelEventHandler = (key, value) => {
    if (new RegExp(/[^0-9]/).test(value)) {
      return;
    }

    setResult({
      ...result,
      [key]: value,
      sum: calcSum(result, key, value),
    });
  };

  const btnReset = () => {
    setResult({ ...resultInit });
  };
  const btnCaculate = () => {
    if (tax.sum === 0) {
      alert("취득가액이 존재하지 않습니다.");
      return;
    }

    if (tax.radio === "P") {
      if (tax.select === "P" || tax.select === "D") {
        if (tax.chpWon > tax.maesuWon) {
          setResult({
            ...result,
            maemaeWon: tax.chpWon,
            sum: tax.chpWon,
            isCalculated: true,
          });
        } else {
          setResult({
            ...result,
            maemaeWon: tax.contWon,
            gaesuWon: tax.gaesuWon,
            famRegWon: tax.famRegWon,
            consultWon: tax.consultWon,
            sum: tax.contWon + tax.gaesuWon + tax.famRegWon + tax.consultWon,
            isCalculated: true,
          });
        }
      } else {
        const maemaeWon = Math.round((tax.contWon * 10) / 11);
        setResult({
          ...result,
          maemaeWon: maemaeWon,
          gaesuWon: tax.gaesuWon,
          famRegWon: tax.famRegWon,
          consultWon: tax.consultWon,
          sum: maemaeWon + tax.gaesuWon + tax.famRegWon + tax.consultWon,
          isCalculated: true,
        });
      }
    } else if (tax.radio === "C" || tax.radio === "D") {
      if (tax.select === "P" || tax.select === "D") {
        setResult({
          ...result,
          maemaeWon: tax.contWon,
          gaesuWon: tax.gaesuWon,
          famRegWon: tax.famRegWon,
          charge: tax.charge,
          sum: tax.contWon + tax.gaesuWon + tax.famRegWon + tax.charge,
          isCalculated: true,
        });
      } else {
        const maemaeWon = Math.round((tax.contWon * 10) / 11);
        setResult({
          ...result,
          maemaeWon: maemaeWon,
          gaesuWon: tax.gaesuWon,
          famRegWon: tax.famRegWon,
          charge: tax.charge,
          sum: maemaeWon + tax.gaesuWon + tax.famRegWon + tax.charge,
          isCalculated: true,
        });
      }
    }
  };
  const btnSetting = () => {
    if (result.isCalculated) {
      setMedoMesuCost(result);
      hide();
    } else {
      alert("셋팅할 결과가 없습니다.");
    }
  };

  const clickRow = (el, idx) => {
    let tmpTax = { ...tax };

    tmpTax = wf_gaesu_chk(el, tmpTax);

    setTax({
      ...tmpTax,
      chpWon: el.chpWon,
      idx: idx,
      code: el.code,
      sum:
        (tmpTax.select === "C" ? Math.round((tmpTax.contWon * 10) / 11) : tmpTax.contWon) +
        tmpTax.gaesuWon +
        tmpTax.famRegWon +
        tmpTax.charge +
        tmpTax.consultWon,
    });
  };

  const hide = () => {
    setModalShow({ flag: false, isNewClickRow: false });
  };

  const pressEnterCalc = e => {
    if (e.key === "Enter") {
      btnCaculate();
    }
  };

  return (
    <Modal className="modal-xl" show={modalShow.flag} centered size="xl" onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>취득세 산출</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {medoMesu.cgubun === "1" && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title m-0">▶ 취득과표</h5>
              <p className="p_quot mb-0">
                기흥구, 처인구, 남양주시 관할 골프장은 VAT를 제외한 개서료+가족등록비를 포함해야
                한다.
              </p>
            </div>
            <div className="filter_fields_container m-0 mt-2">
              <div className="filter_fields_row">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="filter1-field filter1-field1 ps-0">
                    <label className="filter1-label">골프장</label>
                    <select
                      className="form-select filter1-select1"
                      value={currentGolf}
                      onChange={e => onChangeEventHandler("golfSelect", e.target.value)}
                    >
                      <option value="00">전체</option>
                      {getGolfCustList?.length !== 0 ? (
                        getGolfCustList?.map(el => {
                          return <option value={el.key}>{el.value}</option>;
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                  <div className="filter1-field filter1-field2">
                    <label className="filter1-label">
                      {golfData?.addres !== undefined ? `소재지 : ${golfData?.addres}` : ""}
                    </label>
                    <label className="filter1-label">
                      {golfData?.governCustId === "1016" ||
                      golfData?.governCustId === "1122" ||
                      golfData?.governCustId === "1124" ? (
                        <>(개서료, 가족등록비 포함대상 골프장)</>
                      ) : (
                        <></>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-container table-sticky" style={{ maxHeight: "267px" }}>
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }} rowSpan={2}>
                      연번
                    </th>
                    <th style={{ width: "10%" }} rowSpan={2}>
                      회원권종목명
                    </th>
                    <th style={{ width: "10%" }} rowSpan={2}>
                      전환유무
                    </th>
                    <th style={{ width: "9%" }} rowSpan={2}>
                      고시일자
                    </th>
                    <th style={{ width: "9%" }} rowSpan={2}>
                      취득과표
                    </th>
                    <th colSpan={7}>골프장 개서료(VAT포함)</th>
                  </tr>
                  <tr>
                    <th style={{ width: "7%" }}>정회원</th>
                    <th style={{ width: "7%" }}>주중회원</th>
                    <th style={{ width: "7%" }}>가족등록비</th>
                    <th style={{ width: "7%" }}>지명인변경</th>
                    <th style={{ width: "7%" }}>상속</th>
                    <th style={{ width: "7%" }}>중여</th>
                    <th>기타</th>
                  </tr>
                </thead>
                <tbody>
                  {taxList.length !== 0 ? (
                    taxList.map((el, idx) => {
                      return (
                        <tr
                          className={`cursor-pointer ${el.code === tax.code ? "bg-blue" : ""}`}
                          onClick={() => clickRow(el, idx)}
                        >
                          <td>{idx + 1}</td>
                          <td>{el.codename}</td>
                          <td>
                            {el.changeOx
                              ? getChangeOx?.find(el2 => el2.key === el.changeOx)?.value
                              : ""}
                          </td>
                          <td>{el.chpDate}</td>
                          <td>{Number(el.chpWon).toLocaleString()}</td>
                          <td>{Number(el.gaesuPersonal).toLocaleString()}</td>
                          <td>{Number(el.gaesuJujung).toLocaleString()}</td>
                          <td>{Number(el.gaesuCorp).toLocaleString()}</td>
                          <td>{Number(el.gaesuRename).toLocaleString()}</td>
                          <td>{Number(el.gaesuInherit).toLocaleString()}</td>
                          <td>{Number(el.gaesuDonation).toLocaleString()}</td>
                          <td>{el.gaesuEtc}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={12}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className={`row ${medoMesu.cgubun === "1" && "mt-3"}`}>
          <div className="col-6">
            <h5 className="card-title m-0 mb-3">▶ 조건선택</h5>
            <div className="filter_fields_row mb-2">
              <div className="d-flex align-items-center justify-content-start">
                <div className="form-check form-check-inline my-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={tax.radio === "P"}
                    onChange={e => onChangeRadio("radioP", true)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    개인매수 취득세 산출
                  </label>
                </div>
              </div>
            </div>
            <div className="filter_fields_row mb-2">
              <div className="d-flex align-items-center justify-content-start">
                <div className="form-check form-check-inline my-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    checked={tax.radio === "C"}
                    onChange={e => onChangeRadio("radioC", true)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    과세법인 매수 취득세산출
                  </label>
                </div>
                <div className="form-check form-check-inline my-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    checked={tax.radio === "D"}
                    onChange={e => onChangeRadio("radioD", true)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    면세법인 매수 취득세산출
                  </label>
                </div>
              </div>
            </div>

            <hr className="m-0 p-0" />

            <div className="d-flex mt-2">
              <div className="d-flex align-items-center justify-content-center w-50">
                <div className="filter1-field filter1-field1 p-0">
                  <label className="filter1-label">매도는</label>
                  <select
                    className="form-select filter1-select1"
                    value={tax.select}
                    onChange={e => onChangeLeftPanelEventHandler2("select", e.target.value)}
                  >
                    <option value="P">개인</option>
                    <option value="C">과세법인</option>
                    <option value="D">면세법인</option>
                  </select>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-centerx w-50">
                <div className="filter1-field filter1-field1 p-0">
                  <label className="filter1-label">
                    &nbsp; &nbsp;{" "}
                    {tax.radio === "P" && tax.select === "P" ? (
                      <>취득과표 : {tax.chpWon ? Number(tax.chpWon).toLocaleString() : "-"}</>
                    ) : (
                      <></>
                    )}
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
              </div>
            </div>

            <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
              <tbody onKeyDown={pressEnterCalc}>
                <tr>
                  <th style={{ width: "50%" }} colSpan={2}>
                    VAT 포함금액
                  </th>
                  <th colSpan={2}>VAT 제외금액</th>
                </tr>
                <tr>
                  <th style={{ width: "15%" }}>취득가액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={tax.sum ? tax.sum.toLocaleString() : 0}
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                  <th style={{ width: "15%" }}>명의개서료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={tax.gaesuWon ? tax.gaesuWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler3("gaesuWon", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>계약서금액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={tax.contWon ? tax.contWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler2("contWon", e.target.value);
                      }}
                    />
                  </td>
                  <th>가족등록비</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={tax.famRegWon ? tax.famRegWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler3("famRegWon", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>실매수금액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={tax.maesuWon ? tax.maesuWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler2("maesuWon", e.target.value);
                      }}
                    />
                  </td>
                  <th>중개수수료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={tax.charge ? tax.charge.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler3("charge", e.target.value);
                      }}
                      readOnly={tax.radio === "P"}
                      style={tax.radio === "P" ? { backgroundColor: "lightgray" } : {}}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Down 여부</th>
                  <td className="text-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="downRadioOptions"
                      id="isDownRadio01"
                      checked={tax.down}
                      onChange={e => onChangeLeftPanelEventHandler2("down", true)}
                      disabled={
                        tax.radio !== "P" || tax.chpWon > tax.maesuWon || tax.contWon > tax.maesuWon
                      }
                    />
                    <label className="form-check-label" htmlFor="isDownRadio01">
                      인증
                    </label>
                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    <input
                      className="form-check-input"
                      type="radio"
                      name="downRadioOptions"
                      id="isDownRadio02"
                      checked={!tax.down}
                      onChange={e => onChangeLeftPanelEventHandler2("down", false)}
                      disabled={
                        tax.radio !== "P" || tax.chpWon > tax.maesuWon || tax.contWon > tax.maesuWon
                      }
                    />
                    <label className="form-check-label" htmlFor="isDownRadio02">
                      안됨
                    </label>
                  </td>
                  <th style={{ borderBottom: "1px solid #c5c5c5" }}>컨설팅료</th>
                  <td style={{ borderBottom: "1px solid #c5c5c5" }}>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={tax.consultWon >= 0 ? tax.consultWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler3("consultWon", e.target.value);
                      }}
                      readOnly={
                        tax.down ||
                        (tax.select === "P" &&
                          tax.chpWon > tax.maesuWon &&
                          tax.maesuWon > tax.contWon)
                      }
                      style={
                        tax.down ||
                        (tax.select === "P" &&
                          tax.chpWon > tax.maesuWon &&
                          tax.maesuWon > tax.contWon)
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-end mt-2">
              <button className="btn btn-outline-primary" onClick={btnCaculate}>
                취득세 산출
              </button>
            </div>
          </div>

          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}

          <div className="col-6">
            <div className="tab1-row1-count mt-5 mb-4">
              납부해야 할 취득세액(납부기한: 계약서일로부터 60일 이내)
            </div>
            <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
              <tbody>
                <tr>
                  <th style={{ width: "17%" }}>message</th>
                  <td className="text-center" colSpan={3}>
                    {tax.radio === "P" && result.maemaeWon === 0 ? (
                      <>취득과표와 계약서 금액 비교</>
                    ) : tax.radio === "P" && result.maemaeWon !== 0 ? (
                      <>개인 매도 : 취득과표 > 취득가액 → 취득과표로만 산출</>
                    ) : tax.radio === "C" ? (
                      <>부가세(VAT)를 제외한 장부가액을 매매금액으로 적용</>
                    ) : tax.radio === "D" ? (
                      <>매매가액 자체가 공급가액, 기타항목은 부가세 제외</>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "17%" }}>매매금액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={result.maemaeWon ? result.maemaeWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "maemaeWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                    />
                  </td>
                  <th style={{ width: "17%" }}>취득가액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={result.sum ? Number(result.sum).toLocaleString() : 0}
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>명의개서료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={result.gaesuWon ? result.gaesuWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "gaesuWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                      }
                      style={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                  <th>취득세액(2%)</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={
                        result.sum
                          ? (Math.floor((result.sum * 0.02) / 10) * 10).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>가족등록비</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={result.famRegWon ? result.famRegWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "famRegWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                      }
                      style={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                  <th>
                    농어촌특별세
                    <br />
                    (10%)
                  </th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={
                        result.sum
                          ? (Math.floor((result.sum * 0.002) / 10) * 10).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>중개수수료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={result.charge ? result.charge.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "charge",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={tax.radio === "P"}
                      style={tax.radio === "P" ? { backgroundColor: "lightgray" } : {}}
                    />
                  </td>
                  <td style={{ border: "none" }}></td>
                  <td style={{ border: "none" }}></td>
                </tr>
                <tr>
                  <th>컨설팅료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={result.consultWon ? result.consultWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "consultWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={
                        tax.down || (tax.chpWon > tax.maesuWon && tax.maesuWon > tax.contWon)
                      }
                      style={
                        tax.down || (tax.chpWon > tax.maesuWon && tax.maesuWon > tax.contWon)
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                  <th>납부 세액 합계</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input text-end"
                      value={
                        result.sum
                          ? (
                              Math.floor((result.sum * 0.02) / 10) * 10 +
                              Math.floor((result.sum * 0.002) / 10) * 10
                            ).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="d-flex align-items-center justify-content-end mt-2">
              <button className="btn btn-outline-primary" onClick={btnReset}>
                초기화
              </button>
              <button className="btn btn-outline-primary ms-2" onClick={btnSetting}>
                계산 결과 취득세 셋팅
              </button>
            </div>
          </div>
        </div>

        <hr className="my-2 p-0" />

        <ul className="table-help-list f-regular m-0 ps-4">
          <li>기한 내 미신고시 가산세: 납부해야 할 세액의 20%+3/10,000을 1할 계산</li>
          <li>기한 내 신고 후 미납 시 가산세: 납부해야 할 세액의 3/10,000을 1할 계산</li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
