import { atom } from "jotai";

export const searchParamInit = {
  carNumberKeyword: "",
  carrJisa: "00",
  ownGubun: "A",
  oilGubun: "01",
  carName: "",
  check: "N",
  page: 1,
  init: false,
};

const carDetail_init = {
  carNumber: "",
  runnMile: 0,
  juyuUser: "",
  juyuDate: "",
  juyuAmnt: 0,
  litrCost: 0,
  engrGubn: "",
  juyuPlac: "",
  juyuLitr: 0,
  crdtNumb: "",
  remark: "",
};

export const tableRowLimit_atom = atom("5");
export const clickedRow_atom = atom({});
export const isRegisterMode_atom = atom(false);
export const searchParam_atom = atom({ ...searchParamInit });
export const carList_atom = atom([]);
export const carDetail_atom = atom(carDetail_init);
export const carFuelInfo_atom = atom([]);
export const carFuelInfoEditedRowList_atom = atom([]);
