import React, { useEffect, useState } from "react";
import { init } from "./transactionRegisterManageDetail";
import { useGetCustGubun } from "../../../../../api/code/useGetCustGubun";
import { useGetVip } from "../../../../../api/code/useGetVip";
import { useGetEmail } from "../../../../../api/code/useGetEmail";
import { useGetUserIdQ } from "../../../../../api/code/useGetUserIdQ";
import { useGetJisaGubun } from "../../../../../api/code/useGetJisaGubun";
import { useGetAsCode127Keyword } from "../../../../../api/code/useGetAsCode127Keyword";

const AccessDeny = [
  { key: "N", value: "지속관리" },
  { key: "O", value: "전화번호오류(이관희망)" },
  { key: "P", value: "전화거부고객(이관희망)" },
  { key: "Q", value: "이름없는고객(이관희망)" },
  { key: "R", value: "장기 미관리(이관희망)" },
  { key: "X", value: "폐기대상" },
];
const DagolfMember = [
  { key: "A", value: "신규고객" },
  { key: "B", value: "기존고객" },
  { key: "C", value: "계약해지" },
  { key: "N", value: "-" },
];
const transactionRegisterManageDetailTab = props => {
  const { data = init } = props;
  const [myData, setMyData] = useState(data);
  const custGubun = useGetCustGubun();
  const vip = useGetVip();
  const email = useGetEmail();
  const userIdQ = useGetUserIdQ();
  const jisaGubun = useGetJisaGubun();
  const asCode127Keyword = useGetAsCode127Keyword();

  useEffect(() => {
    setMyData({
      ...data,
      juminno1: data.juminno ? data.juminno.substring(0, 6) : "",
      juminno2: data.juminno ? data.juminno.substring(6) : "",
      juminno2Temp: data.juminno ? data.juminno.substring(6) : "",
    });
  }, [data]);

  return (
    <div className="tab-content-container">
      <div className="tab1-row1 mb-2">
        <div className="d-flex align-items-center">
          <div className="tab1-row1-count">
            관리번호 : {myData.custId && myData.custId.substring(0, 4)}-
            {myData.custId && myData.custId.substring(4)}
            {myData.outCheck === "Y" && (
              <span className="text-danger text-decoration-line-through ms-2">제명고객</span>
            )}
          </div>
          &nbsp;
          {/*<div className="tab1-row1-field1">
            <label>분류 :</label>
            <select
              className="form-select tab1-row1-form-select"
              value={myData && myData.gubun}
              disabled={true}
            >
              {decrypt("getCustGubun").map(el => {
                return <option value={el.key}>{el.value}</option>;
              })}
            </select>
            <select
              className="form-select tab1-row1-form-select"
              value={myData.vipCheck}
              onChange={e => onChange("vipCheck", e.target.value)}
            >
              {decrypt("getVip").map(el => {
                return <option value={el.key}>{el.value}</option>;
              })}
            </select>
          </div>*/}
        </div>
        {/*<div className="d-flex align-items-center">
          <button className="btn btn-outline-primary tab1-row1-btn">중복체크</button>
          <button className="btn btn-outline-danger tab1-row1-btn">삭제요청</button>
          <button className="btn btn-outline-danger tab1-row1-btn">삭제</button>
          <button className="btn btn-outline-primary tab1-row1-btn">저장</button>
        </div>*/}
      </div>

      <table className="table table-bordered table-responsive my-table1">
        <tbody>
          <tr>
            <th style={{ width: "11.9%" }}>특이사항</th>
            <td style={{ width: "38.1%" }} colSpan={3}>
              {myData.bigoRemk ? myData.bigoRemk : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.bigoRemk}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      bigoRemk: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
            <th style={{ width: "11.9%" }}>고객분류</th>
            <td style={{ width: "19.05%" }}>
              {myData.gubun ? custGubun.data?.find(item => item.key === myData.gubun)?.value : "-"}
              {/*<select*/}
              {/*  className="form-select table-select"*/}
              {/*  value={myData.gubun}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      gubun: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {custGubun.data?.map(el => (*/}
              {/*    <option value={el.key}>{el.value}</option>*/}
              {/*  ))}*/}
              {/*</select>*/}
            </td>
            <td style={{ width: "19.05%" }}>
              {myData.vipCheck ? vip.data?.find(item => item.key === myData.vipCheck)?.value : "-"}
              {/*<select*/}
              {/*  className="form-select table-select"*/}
              {/*  value={myData.vipCheck}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      vipCheck: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {vip.data?.map(el => (*/}
              {/*    <option value={el.key}>{el.value}</option>*/}
              {/*  ))}*/}
              {/*</select>*/}
            </td>
          </tr>
          <tr>
            <th>업체명</th>
            <td colSpan={3}>
              {myData.company ? myData.company : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.company}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      company: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
            <th>성명</th>
            <td colSpan={2}>
              {myData.kname ? myData.kname : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.kname}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      kname: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
          </tr>
          <tr>
            <th>주민번호</th>
            <td colSpan={3}>
              {myData.juminno
                ? `${myData.juminno.substring(0, 6)}-${myData.juminno.substring(6)}`
                : "-"}
              {/*<div className="d-flex">*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    className="form-control table-input table-input-inline"*/}
              {/*    value={myData.juminno1}*/}
              {/*    onChange={e => {*/}
              {/*      setMyData({*/}
              {/*        ...myData,*/}
              {/*        juminno1: e.target.value,*/}
              {/*      });*/}
              {/*    }}*/}
              {/*    maxLength={6}*/}
              {/*  />*/}
              {/*  <span className="txt_dash">-</span>*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    className="form-control table-input table-input-inline"*/}
              {/*    value={myData.juminno2Temp}*/}
              {/*    onChange={e => {*/}
              {/*      setMyData({*/}
              {/*        ...myData,*/}
              {/*        juminno2: e.target.value,*/}
              {/*        juminno2Temp: e.target.value,*/}
              {/*      });*/}
              {/*    }}*/}
              {/*    onFocus={() => {*/}
              {/*      setMyData({*/}
              {/*        ...myData,*/}
              {/*        juminno2Temp: myData.juminno2,*/}
              {/*      });*/}
              {/*    }}*/}
              {/*    onBlur={() => {*/}
              {/*      setMyData({*/}
              {/*        ...myData,*/}
              {/*        juminno2Temp:*/}
              {/*          myData.juminno2.slice(0, 2) +*/}
              {/*          myData.juminno2.slice(2, 7).replace(/[0-9]/g, "*"),*/}
              {/*      });*/}
              {/*    }}*/}
              {/*    maxLength={7}*/}
              {/*  />*/}
              {/*</div>*/}
            </td>
            <th>교환</th>
            <td colSpan={2}>
              {myData.ctelChange ? myData.ctelChange : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.ctelChange}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      ctelChange: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
          </tr>
          <tr>
            <th>직장전화</th>
            <td style={{ width: "13.1%" }}>
              {myData.ctel ? myData.ctel : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.ctel}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      ctel: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
            <th className="f-roboto-medium" style={{ width: "11.9%" }}>
              FAX
            </th>
            <td style={{ width: "13.1%" }}>
              {myData.cfax ? myData.cfax : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.cfax}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      cfax: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
            <th>부서명</th>
            <td colSpan={2}>
              {myData.devision ? myData.devision : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.devision}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      devision: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
          </tr>
          <tr>
            <th>자택전화</th>
            <td colSpan={3}>
              {myData.htel ? myData.htel : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.htel}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      htel: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
            <th>직위</th>
            <td colSpan={2}>
              {myData.position ? myData.position : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.position}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      position: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
          </tr>
          <tr>
            <th className="f-roboto-medium">E-MAIL</th>
            <td colSpan={3}>
              {myData.email ? (
                <a>{`${myData.email}${
                  myData.egubun !== "99"
                    ? email.data?.find(item => item.key === myData.egubun)?.value
                    : ""
                }`}</a>
              ) : (
                "-"
              )}
            </td>
            <th>휴대폰</th>
            <td colSpan={2}>
              {myData.cellular ? myData.cellular : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.cellular}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      cellular: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
          </tr>
          <tr>
            <th>계좌번호</th>
            <td colSpan={3}>{myData.payAccount ? myData.payAccount : "-"}</td>
            <th>구담당</th>
            <td colSpan={2}>
              {myData.oldPartner
                ? userIdQ.data?.find(item => item.key === myData.oldPartner)?.value
                : "-"}
              {/*<select*/}
              {/*  className="form-select table-select"*/}
              {/*  value={myData.oldPartner}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      oldPartner: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {userIdQ.data?.map(el => (*/}
              {/*    <option value={el.key}>{el.value}</option>*/}
              {/*  ))}*/}
              {/*</select>*/}
            </td>
          </tr>
          <tr>
            <th>예금주</th>
            <td>
              {myData.payName ? myData.payName : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.payName}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      payName: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
            <td colSpan={2}>
              {myData.accessDeny
                ? AccessDeny.filter(item => item.key === myData.accessDeny)[0]?.value
                : "-"}
              {/*<select*/}
              {/*  className="form-select table-select"*/}
              {/*  value={myData.accessDeny}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      accessDeny: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {AccessDeny?.map(el => (*/}
              {/*    <option value={el.key}>{el.value}</option>*/}
              {/*  ))}*/}
              {/*</select>*/}
            </td>
            <th>담당딜러</th>
            <td colSpan={2}>
              {myData.partner
                ? userIdQ.data?.find(item => item.key === myData.partner)?.value
                : "-"}{" "}
              {myData.jisa ? jisaGubun.data?.find(item => item.key === myData.jisa)?.value : "-"}
              {/*<div className="d-flex">*/}
              {/*  <select*/}
              {/*    className="form-select table-select"*/}
              {/*    value={myData.partner}*/}
              {/*    onChange={e => {*/}
              {/*      setMyData({*/}
              {/*        ...myData,*/}
              {/*        partner: e.target.value,*/}
              {/*      });*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {userIdQ.data?.map(el => (*/}
              {/*      <option value={el.key}>{el.value}</option>*/}
              {/*    ))}*/}
              {/*  </select>*/}
              {/*  <select*/}
              {/*    className="form-select table-select"*/}
              {/*    value={myData.jisa}*/}
              {/*    onChange={e => {*/}
              {/*      setMyData({*/}
              {/*        ...myData,*/}
              {/*        jisa: e.target.value,*/}
              {/*      });*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {jisaGubun.data?.map(el => (*/}
              {/*      <option value={el.key}>{el.value}</option>*/}
              {/*    ))}*/}
              {/*  </select>*/}
              {/*</div>*/}
            </td>
          </tr>
          <tr>
            <th>홈페이지</th>
            <td colSpan={3}>
              {myData.siteUrl ? myData.siteUrl : "-"}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.siteUrl}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      siteUrl: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
            <th>업종</th>
            <td colSpan={2}>
              {myData.upteCode
                ? asCode127Keyword.data?.find(item => item.key === myData.upteCode)?.value
                : "-"}
              {/*<select*/}
              {/*  className="form-select table-select"*/}
              {/*  value={myData.upteCode}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      upteCode: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {asCode127Keyword.data?.map(el => (*/}
              {/*    <option value={el.key}>{el.value}</option>*/}
              {/*  ))}*/}
              {/*</select>*/}
            </td>
          </tr>
          <tr>
            <th>다골프</th>
            <td colSpan={3}>
              {myData.dagolfMember
                ? DagolfMember.filter(item => item.key === myData.dagolfMember)[0]?.value
                : "-"}
              {/*<select*/}
              {/*  className="form-select table-select"*/}
              {/*  value={myData.dagolfMember}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      dagolfMember: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {DagolfMember?.map(el => (*/}
              {/*    <option value={el.key}>{el.value}</option>*/}
              {/*  ))}*/}
              {/*</select>*/}
            </td>
            <th>비고</th>
            <td colSpan={2}>
              {myData.remark}
              {/*<input*/}
              {/*  type="text"*/}
              {/*  className="form-control table-input"*/}
              {/*  value={myData.remark}*/}
              {/*  onChange={e => {*/}
              {/*    setMyData({*/}
              {/*      ...myData,*/}
              {/*      remark: e.target.value,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub">담당자</div>
        {/*<button className="btn btn-outline-primary btn-tbl-add">추가</button>*/}
      </div>

      {myData.ownerList.length > 0 ? (
        myData.ownerList.map((owner, index) => (
          <table
            className={`table table-bordered table-responsive my-table1 ${index === 0 && "mb-40"}`}
          >
            <tbody>
              <tr>
                <th style={{ width: "11.9%" }}>담당자</th>
                <td style={{ width: "13.095%" }}>{owner.ownerName}</td>
                <th style={{ width: "11.9%" }}>직위</th>
                <td style={{ width: "13.095%" }}>{owner.ownerPosition}</td>
                <th style={{ width: "11.9%" }}>고객과의 관계</th>
                <td style={{ width: "13.095%" }}>{owner.ownerRelation}</td>
                <th style={{ width: "11.9%" }}>휴대폰</th>
                <td style={{}} colSpan={3}>
                  {owner.ownerCelTel}
                </td>
              </tr>
              <tr>
                <th>부서</th>
                <td>{owner.ownerDevision}</td>
                <th className="f-roboto-medium">E-MAIL</th>
                <td>{owner.ownerEMail}</td>
                <th>주민번호</th>
                <td>{owner.ownerJuminno}</td>
                <th>기타</th>
                <td>{owner.ownerRemark}</td>
              </tr>
            </tbody>
          </table>
        ))
      ) : (
        <table className="table table-bordered table-responsive my-table1 mb-40">
          <tbody>
            <tr>
              <th style={{ width: "11.9%" }}>담당자</th>
              <td style={{ width: "13.1%" }}>-</td>
              <th style={{ width: "11.9%" }}>직위</th>
              <td style={{ width: "13.1%" }}>-</td>
              <th style={{ width: "11.9%" }}>고객과의 관계</th>
              <td style={{ width: "13.1%" }}>-</td>
              <th style={{ width: "11.9%" }}>휴대폰</th>
              <td style={{}} colSpan={3}>
                -
              </td>
            </tr>
            <tr>
              <th>부서</th>
              <td>-</td>
              <th className="f-roboto-medium">E-MAIL</th>
              <td>-</td>
              <th>주민번호</th>
              <td>-</td>
              <th>기타</th>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      )}

      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub">직장 주소</div>
      </div>

      <table className="table table-bordered table-responsive my-table1">
        <tbody>
          <tr>
            <th style={{ width: "11.9%" }} rowSpan={2}>
              직장주소
            </th>
            <td>
              <div className="w-50 float-start">{myData.rcAreaCode}</div>
              <div className="w-50 float-start text-end text-primary"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="w-50 float-start">{myData.rcAddresTxt}</div>
              <div className="w-50 float-start">{myData.rcAddres}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub">자택 주소</div>
      </div>

      <table className="table table-bordered table-responsive my-table1 mb-40">
        <tbody>
          <tr>
            <th style={{ width: "11.9%" }} rowSpan={2}>
              자택주소
            </th>
            <td>
              <div className="w-50 float-start">{myData.rhAreaCode}</div>
              <div className="w-50 float-start text-end text-primary"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="w-50 float-start">{myData.rhAddresTxt}</div>
              <div className="w-50 float-start">{myData.rhAddres}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default transactionRegisterManageDetailTab;
