import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useState } from "react";
import { bottomTap_atom } from "../../../../../data/atom/workManage/golfManage/golfManage_atom";
import { useAtom } from "jotai";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import Tab5 from "./Tab5";
import Tab6 from "./Tab6";
import Tab7 from "./Tab7";
import Tab8 from "./Tab8";
import Tab9 from "./Tab9";
import Tab10 from "./Tab10";
import Tab11 from "./Tab11";

const BottomTab = ({ custId }) => {
  const [activeKey, setActiveKey] = useAtom(bottomTap_atom);
  const [tab1Reg, setTab1Reg] = useState(false);

  return (
    <>
      {activeKey === "특이사항" && (
        <button
          className="btn btn-outline-primary float-end h-25px btn-tabs-right"
          onClick={() => setTab1Reg(true)}
        >
          등록
        </button>
      )}
      <Tabs
        id="controlled-tab-example2"
        activeKey={activeKey}
        onSelect={k => setActiveKey(k)}
        className="tab-blocks"
      >
        <Tab eventKey="특이사항" title="특이사항">
          <Tab1 custId={custId} reg={tab1Reg} setReg={setTab1Reg} />
        </Tab>
        <Tab eventKey="통화내역" title="통화내역">
          <Tab2 custId={custId} />
        </Tab>
        <Tab eventKey="시세정보" title="시세정보">
          <Tab3 custId={custId} />
        </Tab>
        <Tab eventKey="취득과표" title="취득과표">
          <Tab4 custId={custId} />
        </Tab>
        <Tab eventKey="매매서류" title="매매서류">
          <Tab5 custId={custId} />
        </Tab>
        <Tab eventKey="부킹방법(웹)" title="부킹방법(웹)">
          <Tab6 custId={custId} />
        </Tab>
        <Tab eventKey="비고사항" title="비고사항">
          <Tab7 custId={custId} />
        </Tab>
        <Tab eventKey="코스정보(웹)" title="코스정보(웹)">
          <Tab8 custId={custId} />
        </Tab>
        <Tab eventKey="골프뉴스" title="골프뉴스">
          <Tab9 custId={custId} />
        </Tab>
        <Tab eventKey="분양정보" title="분양정보">
          <Tab10 custId={custId} />
        </Tab>
        <Tab eventKey="실거래가" title="실거래가">
          <Tab11 custId={custId} />
        </Tab>
      </Tabs>
    </>
  );
};

export default React.memo(BottomTab);
