import { atom } from "jotai";

export const certificationManage_showType = atom(null);
export const certificationManage_row_atom = atom("5");
export const certificationManage_submitData = atom({
  certifyClass: "11",
  copies: 1,
  reason: "",
  juminCheck: "N",
});
export const certificationManage_assign_list = atom("");
export const certificationManage_drftnSn = atom("");
