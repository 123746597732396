import React, { useEffect, useState, useRef } from "react";
import { axios } from "../../../util/axios/axios";
import { MutationCache, useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useVirtualizer } from "@tanstack/react-virtual";

import { ipConnectionAuth_ipControlManagerTimeAccessSearchList_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_ipControlManagerSearchList_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_ipControlManagerWeekdaySearchList_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_ipControlManagerWeekendSearchList_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";

import { ipConnectionAuth_originalIndex_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_originalIndexTimeAccess_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_originalIndexWeekday_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_originalIndexWeekend_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";

import { ipConnectionAuth_updateArray_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_updateArrayTimeAccess_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_updateArrayWeekday_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_updateArrayWeekend_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";

import { ipConnectionAuth_deleteArray_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_deleteArrayTimeAccess_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_deleteArrayWeekday_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_deleteArrayWeekend_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";

import { ipConnectionAuth_frontGubun_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_backGubun_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_ipEnd_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_macAddress_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";
import { ipConnectionAuth_userName_atom } from "../../../data/atom/codeManage/ipConnectionAuth/ipConnectionAuth_atom";

import { useGetIpControlManageUserList } from "../../../api/code/useGetIpControlManageUserList";
import { useGetIpControlManagePassWordJobList } from "../../../api/code/useGetIpControlManagePassWordJobList";
import { useGetIpControlManageJisaList } from "../../../api/code/useGetIpControlManageJisaList";
import { useGetIpControlManagePassWordUserPermitList } from "../../../api/code/useGetIpControlManagePasswordUserPermitList";
import { useGetIpControlManageRightUserList } from "../../../api/code/useGetIpControlManageRightUserList";
import code from "../../../util/code/code";

export const IpConnectionAuth = () => {
  const [frontGubun, setFrontGubun] = useAtom(ipConnectionAuth_frontGubun_atom);
  const [backGubun, setBackGubun] = useAtom(ipConnectionAuth_backGubun_atom);
  const [ipEnd, setIpEnd] = useAtom(ipConnectionAuth_ipEnd_atom);
  const [userName, setUserName] = useAtom(ipConnectionAuth_userName_atom);
  const [macAddress, setMacAddress] = useAtom(ipConnectionAuth_macAddress_atom);

  const [modifyList, setModifyList] = useState([]);

  const userList = useGetIpControlManageUserList().data;
  const passwordJobList = useGetIpControlManagePassWordJobList().data;
  const frontGubunSelectList = useGetIpControlManageJisaList().data;
  const passwordUserPermitList = useGetIpControlManagePassWordUserPermitList().data;
  const rightUserList = useGetIpControlManageRightUserList().data;

  const targetRef = useRef(null);

  const [ipControlManagerSearchList, setIpControlManagerSearchList] = useAtom(
    ipConnectionAuth_ipControlManagerSearchList_atom
  );
  const [ipControlManagerTimeAccessSearchList, setIpControlManagerTimeAccessSearchList] = useAtom(
    ipConnectionAuth_ipControlManagerTimeAccessSearchList_atom
  );
  const [ipControlManagerWeekdaySearchList, setIpControlManagerWeekdaySearchList] = useAtom(
    ipConnectionAuth_ipControlManagerWeekdaySearchList_atom
  );
  const [ipControlManagerWeekendSearchList, setIpControlManagerWeekendSearchList] = useAtom(
    ipConnectionAuth_ipControlManagerWeekendSearchList_atom
  );

  const [originalIndex, setOriginalIndex] = useAtom(ipConnectionAuth_originalIndex_atom);
  const [originalIndexTimeAccess, setOriginalIndexTimeAccess] = useAtom(
    ipConnectionAuth_originalIndexTimeAccess_atom
  );
  const [originalIndexWeekday, setOriginalIndexWeekday] = useAtom(
    ipConnectionAuth_originalIndexWeekday_atom
  );
  const [originalIndexWeekend, setOriginalIndexWeekend] = useAtom(
    ipConnectionAuth_originalIndexWeekend_atom
  );

  const [updateArray, setUpdateArray] = useAtom(ipConnectionAuth_updateArray_atom);
  const [updateArrayTimeAccess, setUpdateArrayTimeAccess] = useAtom(
    ipConnectionAuth_updateArrayTimeAccess_atom
  );
  const [updateArrayWeekday, setUpdateArrayWeekday] = useAtom(
    ipConnectionAuth_updateArrayWeekday_atom
  );
  const [updateArrayWeekend, setUpdateArrayWeekend] = useAtom(
    ipConnectionAuth_updateArrayWeekend_atom
  );

  const [deleteArray, setDeleteArray] = useAtom(ipConnectionAuth_deleteArray_atom);
  const [deleteArrayTimeAccess, setDeleteArrayTimeAccess] = useAtom(
    ipConnectionAuth_deleteArrayTimeAccess_atom
  );
  const [deleteArrayWeekday, setDeleteArrayWeekday] = useAtom(
    ipConnectionAuth_deleteArrayWeekday_atom
  );
  const [deleteArrayWeekend, setDeleteArrayWeekend] = useAtom(
    ipConnectionAuth_deleteArrayWeekend_atom
  );

  var regex = /^[0-9:]*$/;

  const rowVirtualizer = useVirtualizer({
    count: ipControlManagerSearchList?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  useEffect(() => {
    initIpControlManagerWeekdaySearchList();
    initIpControlManagerWeekendSearchList();
    initIpControlManagerTimeAccessSearchList();
  }, []);

  const ipControlManageSearch = () => {
    initIpControlManagerSearchList();
    //initIpControlManagerTimeAccessSearchList();
    // initIpControlManagerWeekdaySearchList();
    // initIpControlManagerWeekendSearchList();
  };

  const initIpControlManagerSearchList = () => {
    setIpControlManagerSearchList([]);
    setUpdateArray([]);
    setDeleteArray([]);
    ipControlMutate.mutate();
  };

  const initIpControlManagerTimeAccessSearchList = () => {
    setIpControlManagerTimeAccessSearchList([]);
    setModifyList([]);
    setUpdateArrayTimeAccess([]);
    setDeleteArrayTimeAccess([]);
    ipControlMiddleMutate.mutate();
  };

  const initIpControlManagerWeekdaySearchList = () => {
    setIpControlManagerWeekdaySearchList([]);
    setUpdateArrayWeekday([]);
    setDeleteArrayWeekday([]);
    ipControlWeekdayMutate.mutate();
  };

  const initIpControlManagerWeekendSearchList = () => {
    setIpControlManagerWeekendSearchList([]);
    setUpdateArrayWeekend([]);
    setDeleteArrayWeekend([]);
    ipControlWeekendMutate.mutate();
  };

  const ipControlMutate = useMutation(
    data =>
      axios.get("/api/code/ipControlManage/search", {
        params: {
          frontGubun: frontGubun,
          backGubun: backGubun,
          ipKeyword: ipEnd,
          macKeyword: macAddress,
          userKeyword: userName,
        },
      }),
    {
      onSuccess: res => {
        setIpControlManagerSearchList(res?.data?.result);
        setOriginalIndex(res?.data?.result.length);
      },
    }
  );

  const ipControlMiddleMutate = useMutation(
    data =>
      axios.get("/api/code/ipControlManage/middle/search", {
        params: {
          frontGubun: frontGubun,
          backGubun: backGubun,
          ipKeyword: ipEnd,
          macKeyword: userName,
          userKeyword: macAddress,
        },
      }),
    {
      onSuccess: res => {
        const codeArray = [];

        for (let i in res.data.result) {
          codeArray.push(i);
        }

        setIpControlManagerTimeAccessSearchList(
          Array.from({ length: codeArray.length }, (_, idx) => {
            setModifyList(prev => [...prev, Array.from({ length: 7 }, () => false)]);

            return {
              jisaKey: codeArray[idx],
              jisaName: frontGubunSelectList?.find(el => el.key === codeArray[idx])?.value,
              time: Object.values(res.data.result[codeArray[idx]]).map(el => el),
            };
          })
        );
        // setOriginalIndexTimeAccess(completeArray.length);
      },
    }
  );

  const ipControlWeekdayMutate = useMutation(
    data =>
      axios.get("/api/code/ipControlManage/left/search", {
        params: {},
      }),
    {
      onSuccess: res => {
        setIpControlManagerWeekdaySearchList(res?.data?.result);
        setOriginalIndexWeekday(res?.data?.result.length);
      },
    }
  );

  const ipControlWeekendMutate = useMutation(
    data =>
      axios.get("/api/code/ipControlManage/right/search", {
        params: {},
      }),
    {
      onSuccess: res => {
        setIpControlManagerWeekendSearchList(res?.data?.result);
        setOriginalIndexWeekend(res?.data?.result.length);
      },
    }
  );

  const searchManagerMutate = useMutation(
    data => {
      const { userId, index } = data; // Destructure userId and index here
      return axios.get("/api/code/ipControlManage/search/user", {
        params: {
          userId: userId,
        },
      });
    },
    {
      onSuccess: (res, data) => {
        const { index, userId } = data;
        setIpControlManagerSearchList(prevList => {
          const updatedList = [...prevList];
          const updatedItem = {
            ...updatedList[index],
            userName: userId,
            passWordUserPermit: res.data.result[0].passWordJobCode,
            passWordJob: res.data.result[0].passWordUserPermitCode,
            index: index,
          };
          updatedList[index] = updatedItem;

          setUpdateArray(prevArray => {
            const newArray = [...prevArray];
            const existingObjectIndex = newArray.findIndex(item => item.index === index);

            if (existingObjectIndex !== -1) {
              newArray[existingObjectIndex] = updatedItem;
            } else {
              newArray.push(updatedItem);
            }

            return newArray;
          });

          return updatedList;
        });
      },
    }
  );

  // =======================로우 추가 버튼===============================

  const addRow = type => {
    let newItem = {};
    switch (type) {
      case 1:
        if (ipControlManagerSearchList.length > originalIndex) return;

        if (updateArray.length > 0) {
          alert("수정중인 데이터가 있습니다.\n저장완료 또는 재검색 후 다시시도해주세요.");
          return;
        }

        newItem = {
          // 초기값을 여기에 추가하십시오.
          loca: "99", // 예시 초기값
          ipAddress: "",
          userName: "",
          passWrodJob: "",
          passWordUserPermit: "",
          macAddres: "",
          pcName: "",
          logName: "",
          logPazz: "",
          gubun: "N",
          webConnect: "N",
          daposWeekOpen: "N",
          senstel: "N",
          remark: "",
        };

        // 새 항목을 목록의 맨 앞에 추가
        setIpControlManagerSearchList(prevList => [newItem, ...prevList]);
        break;

      case 2:
        if (ipControlManagerTimeAccessSearchList.length > originalIndexTimeAccess) return;
        if (updateArrayTimeAccess.length > 0) {
          alert("수정중인 데이터가 있습니다.\n저장완료 또는 재검색 후 다시시도해주세요.");
          return;
        }

        newItem = {
          holiDate: "",
          codename: "",
          codenamePermit: "",
          userId: "",
          startTime: "",
          endTime: "",
          remark: "",
          newCheck: false,
        };

        // 새 항목을 목록의 맨 앞에 추가
        setIpControlManagerTimeAccessSearchList(prevList => [newItem, ...prevList]);
        break;

      case 3:
        if (ipControlManagerWeekdaySearchList.length > originalIndexWeekday) return;
        if (updateArrayWeekday.length > 0) {
          alert("수정중인 데이터가 있습니다.\n저장완료 또는 재검색 후 다시시도해주세요.");
          return;
        }

        newItem = {
          // 초기값을 여기에 추가하십시오.
          authority: "",
          dept: "",
          startTime: "",
          endTime: "",
          userId: "",
        };

        // 새 항목을 목록의 맨 앞에 추가
        setIpControlManagerWeekdaySearchList(prevList => [newItem, ...prevList]);
        break;

      case 4:
        if (ipControlManagerWeekendSearchList.length > originalIndexWeekend) return;
        if (updateArrayWeekend.length > 0) {
          alert("수정중인 데이터가 있습니다.\n저장완료 또는 재검색 후 다시시도해주세요.");
          return;
        }

        newItem = {
          holiDate: "",
          codename: "",
          codenamePermit: "",
          userId: "",
          startTime: "",
          endTime: "",
          remark: "",
        };

        // 새 항목을 목록의 맨 앞에 추가
        setIpControlManagerWeekendSearchList(prevList => [newItem, ...prevList]);
        break;
    }
  };

  // =======================검색 조건===============================

  const frontGubunHandler = e => {
    setFrontGubun(e.target.value);
  };

  const backGubunHandler = e => {
    setBackGubun(e.target.value);
  };

  const ipEndHandler = e => {
    e.target.value === "" ? setIpEnd() : setIpEnd(e.target.value);
  };

  const userNameHandler = e => {
    e.target.value === "" ? setUserName() : setUserName(e.target.value);
  };

  const macAddressHandler = e => {
    e.target.value === "" ? setMacAddress() : setMacAddress(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      ipControlMutate.mutate();
    }
  };

  // =======================IP 접속인증 관리===============================

  const locaHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];

      if (i >= 0 && i < updatedList.length) {
        const updatedItem = { ...updatedList[i], loca: e.target.value, index: i };
        updatedList[i] = updatedItem;

        setUpdateArray(prevArray => {
          const newArray = [...prevArray];
          const existingObjectIndex = newArray.findIndex(item => item.index === i);

          if (existingObjectIndex !== -1) {
            newArray[existingObjectIndex] = updatedItem;
          } else {
            // 필요에 따라 조건을 업데이트하십시오
            if (updatedItem.userName !== "0") {
              newArray.push(updatedItem);
            }
          }

          return newArray;
        });

        return updatedList;
      } else {
        return prevList;
      }
    });
  };

  const accessIpHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], ipAddress: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const userHandler = (e, i) => {
    if (e.target.value !== "") {
      let user = {};
      user.userId = e.target.value;
      user.index = i;
      searchManagerMutate.mutate(user);
    } else {
      setIpControlManagerSearchList(prevList => {
        const updatedList = [...prevList];
        const updatedItem = {
          ...updatedList[i],
          userName: e.target.value,
          index: i,
          passWordUserPermit: "",
          passWordJob: "",
        };
        updatedList[i] = updatedItem;

        setUpdateArray(prevArray => {
          const newArray = [...prevArray];
          const existingObjectIndex = newArray.findIndex(item => item.index === i);

          if (existingObjectIndex !== -1) {
            newArray[existingObjectIndex] = updatedItem;
          } else {
            newArray.push(updatedItem);
          }

          return newArray;
        });

        return updatedList;
      });
    }
  };

  const macHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], macAddres: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const pcNameHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], pcName: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const logNameHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], logName: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const gubunHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], gubun: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const logPazzHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], logPazz: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const webConnectHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], webConnect: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const daposWeekHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], daposWeekOpen: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const senstelHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], senstel: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const remarkHandler = (e, i) => {
    setIpControlManagerSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], remark: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArray(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  // =======================평일 접속시간 관리===============================

  const weekdayIdHandler = (e, i) => {
    setIpControlManagerWeekdaySearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], userId: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekday(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const weekdayStartTimeHandler = (e, i) => {
    const str = e.target.value;
    const sanitizedStr = str.replace(/[^0-9:]/g, "0");

    setIpControlManagerWeekdaySearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], startTime: sanitizedStr, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekday(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const weekdayEndTimeHandler = (e, i) => {
    const str = e.target.value;
    const sanitizedStr = str.replace(/[^0-9:]/g, "0");

    setIpControlManagerWeekdaySearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], endTime: sanitizedStr, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekday(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  // =======================주말 접속시간 관리===============================

  const holiDateHandler = (e, i) => {
    setIpControlManagerWeekendSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], holiDate: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekend(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const weekendIdHandler = (e, i) => {
    setIpControlManagerWeekendSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], userId: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekend(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const weekendStartTimeHandler = (e, i) => {
    const str = e.target.value;
    const sanitizedStr = str.replace(/[^0-9:]/g, "0");

    setIpControlManagerWeekendSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], startTime: sanitizedStr, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekend(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const weekendEndTimeHandler = (e, i) => {
    const str = e.target.value;
    const sanitizedStr = str.replace(/[^0-9:]/g, "0");

    setIpControlManagerWeekendSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], endTime: sanitizedStr, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekend(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const weekendRemarkHandler = (e, i) => {
    setIpControlManagerWeekendSearchList(prevList => {
      const updatedList = [...prevList];
      const updatedItem = { ...updatedList[i], remark: e.target.value, index: i };
      updatedList[i] = updatedItem;

      setUpdateArrayWeekend(prevArray => {
        const newArray = [...prevArray];
        const existingObjectIndex = newArray.findIndex(item => item.index === i);

        if (existingObjectIndex !== -1) {
          newArray[existingObjectIndex] = updatedItem;
        } else {
          newArray.push(updatedItem);
        }

        return newArray;
      });

      return updatedList;
    });
  };

  const deleteRow = (index, type) => {
    let array = [];
    let dArray = [];
    let deletedArray = [];
    let object = {};
    let deleteMutateArray = [];
    if (confirm("삭제하시겠습니까?")) {
      switch (type) {
        case 1:
          array = deleteArray;
          dArray = ipControlManagerSearchList.splice(index, 1);
          setDeleteArray(array);
          deletedArray = [
            ...ipControlManagerSearchList.slice(0, index),
            ...ipControlManagerSearchList.slice(index),
          ];

          object = {};
          if (dArray[0].ipAddress !== "") object.ipAddress = dArray[0].ipAddress;
          if (dArray[0].loca !== "") object.loca = dArray[0].loca;
          if (dArray[0].userName !== "")
            object.userName = userList?.find(el => el.key === dArray[0].userName).key;

          deleteMutateArray = [];
          deleteMutateArray.push(object);

          if (!(ipControlManagerSearchList.length < originalIndex) && index === 0) {
            initIpControlManagerSearchList();
            return;
          } else {
            deleteRowMutate.mutate({
              deletedMutateArray: deletedArray,
              deleteMutateArray: deleteMutateArray,
            });
          }

          break;
        case 2:
          break;

        case 3:
          array = deleteArrayWeekday;
          dArray = ipControlManagerWeekdaySearchList.splice(index, 1);
          setDeleteArrayWeekday(array);
          deletedArray = [
            ...ipControlManagerWeekdaySearchList.slice(0, index),
            ...ipControlManagerWeekdaySearchList.slice(index),
          ];

          object = {};
          object.newCheck = true;
          if (dArray[0].userId !== "") object.userId = dArray[0].userId;

          deleteMutateArray = [];
          deleteMutateArray.push(object);

          if (!(ipControlManagerWeekdaySearchList.length < originalIndexWeekday) && index === 0) {
            initIpControlManagerWeekdaySearchList();
            return;
          } else {
            deleteRowWeekdayMutate.mutate({
              deletedMutateArray: deletedArray,
              deleteMutateArray: deleteMutateArray,
            });
          }

          break;

        case 4:
          array = deleteArrayWeekend;
          dArray = ipControlManagerWeekendSearchList.splice(index, 1);
          setDeleteArrayWeekend(array);
          deletedArray = [
            ...ipControlManagerWeekendSearchList.slice(0, index),
            ...ipControlManagerWeekendSearchList.slice(index),
          ];

          object = {};
          if (dArray[0].holiDate !== "") object.holiDate = dArray[0].holiDate;
          if (dArray[0].userId !== "") object.userId = dArray[0].userId;

          deleteMutateArray = [];
          deleteMutateArray.push(object);

          if (!(ipControlManagerWeekendSearchList.length < originalIndexWeekend) && index === 0) {
            initIpControlManagerWeekendSearchList();
            return;
          } else {
            deleteRowWeekendMutate.mutate({
              deletedMutateArray: deletedArray,
              deleteMutateArray: deleteMutateArray,
            });
          }

          break;
      }
    }
  };

  const deleteRowMutate = useMutation(
    data => {
      const { deletedMutateArray, deleteMutateArray } = data;
      return axios.post("/api/code/ipControlManage/delete", deleteMutateArray);
    },
    {
      onSuccess: (res, data) => {
        const { deletedMutateArray } = data;
        // 삭제된 항목을 제외한 나머지 항목을 유지하도록 업데이트
        if (res.code === "0000") ipControlMutate.mutate();
      },
    }
  );

  const deleteRowWeekdayMutate = useMutation(
    data => {
      const { deletedMutateArray, deleteMutateArray } = data;
      return axios.post("/api/code/ipControlManage/left/delete", deleteMutateArray);
    },
    {
      onSuccess: (res, data) => {
        const { deletedMutateArray } = data;
        // 삭제된 항목을 제외한 나머지 항목을 유지하도록 업데이트
        if (res.code === "0000") ipControlMutate.mutate();
      },
    }
  );

  const deleteRowWeekendMutate = useMutation(
    data => {
      const { deletedMutateArray, deleteMutateArray } = data;
      return axios.post("/api/code/ipControlManage/right/delete", deleteMutateArray);
    },
    {
      onSuccess: (res, data) => {
        const { deletedMutateArray } = data;
        // 삭제된 항목을 제외한 나머지 항목을 유지하도록 업데이트
        if (res.code === "0000") ipControlMutate.mutate();
      },
    }
  );

  const saveArray = type => {
    let saveArray = [];
    switch (type) {
      case 1:
        if (!updateArray.length > 0) {
          alert("수정된 내용이 없습니다.");
          return;
        }
        for (let i = 0; i < updateArray.length; i++) {
          let saveObject = {};
          saveObject.daposWeekOpen = updateArray[i].daposWeekOpen;
          saveObject.gubun = updateArray[i].gubun;
          saveObject.ipAddress = updateArray[i].ipAddress;
          saveObject.loca = updateArray[i].loca;
          saveObject.logName = updateArray[i].logName;
          saveObject.logPazz = updateArray[i].logPazz;
          saveObject.macAddres = updateArray[i].macAddres;
          saveObject.pcName = updateArray[i].pcName;
          saveObject.remark = updateArray[i].remark;
          saveObject.senstel = updateArray[i].senstel;
          saveObject.userName = updateArray[i].userName;
          saveObject.webConnect = updateArray[i].webConnect;
          if (ipControlManagerSearchList.length > originalIndex && i === 0) {
            saveObject.newCheck = true;
          } else {
            saveObject.newCheck = false;
          }
          saveArray.push(saveObject);
        }
        saveArrayMutate.mutate(saveArray);
        break;
      case 2:
        if (modifyList.map(el => el.every(el2 => el2 === false)).every(el3 => el3 === true)) {
          alert("수정된 내용이 없습니다.");
          return;
        }

        let modifyListArray = modifyList.map((el, idx) => {
          return el.reduce((acc, cur, idx2) => {
            if (cur) {
              return [
                ...acc,
                {
                  bottomJisa: ipControlManagerTimeAccessSearchList[idx].jisaKey,
                  bottomStartTime: ipControlManagerTimeAccessSearchList[idx].time[idx2].startTime,
                  bottomEndTime: ipControlManagerTimeAccessSearchList[idx].time[idx2].endTime,
                  dayNum: idx2 + 1,
                },
              ];
            } else {
              return acc;
            }
          }, []);
        });

        let completeArray = [];
        for (let i = 0; i < modifyListArray.length; i++) {
          if (modifyListArray[i].length === 0) {
            continue;
          } else {
            for (let j = 0; j < modifyListArray[i].length; j++) {
              completeArray.push(modifyListArray[i][j]);
            }
          }
        }
        saveArrayTimeAccessMutate.mutate(completeArray);
        break;
      case 3:
        if (!updateArrayWeekday.length > 0) {
          alert("수정된 내용이 없습니다.");
          return;
        }
        for (let i = 0; i < updateArrayWeekday.length; i++) {
          let saveObject = {};
          saveObject.startTime = updateArrayWeekday[i].startTime;
          saveObject.endTime = updateArrayWeekday[i].endTime;
          saveObject.userId = updateArrayWeekday[i].userId;
          if (ipControlManagerWeekdaySearchList.length > originalIndexWeekday && i === 0) {
            saveObject.newCheck = true;
          } else {
            saveObject.newCheck = false;
          }
          saveArray.push(saveObject);
        }
        saveArrayWeekdayMutate.mutate(saveArray);
        break;
      case 4:
        if (!updateArrayWeekend.length > 0) {
          alert("수정된 내용이 없습니다.");
          return;
        }
        for (let i = 0; i < updateArrayWeekend.length; i++) {
          let saveObject = {};
          saveObject.holiDate = updateArrayWeekend[i].holiDate;
          saveObject.codename = updateArrayWeekend[i].codename;
          saveObject.codenamePermit = updateArrayWeekend[i].codenamePermit;
          saveObject.remark = updateArrayWeekend[i].remark;
          saveObject.startTime = updateArrayWeekend[i].startTime;
          saveObject.endTime = updateArrayWeekend[i].endTime;
          saveObject.userId = updateArrayWeekend[i].userId;
          if (ipControlManagerWeekendSearchList.length > originalIndexWeekend && i === 0) {
            saveObject.newCheck = true;
          } else {
            saveObject.newCheck = false;
          }
          saveArray.push(saveObject);
        }
        saveArrayWeekendMutate.mutate(saveArray);
        break;
    }
  };

  const saveArrayMutate = useMutation(
    data => {
      return axios.post("/api/code/ipControlManage/save", data);
    },
    {
      onSuccess: res => {
        initIpControlManagerSearchList();
      },
    }
  );

  const saveArrayTimeAccessMutate = useMutation(
    data => {
      return axios.post("/api/code/ipControlManage/middle/save", data);
    },
    {
      onSuccess: res => {
        initIpControlManagerTimeAccessSearchList();
      },
    }
  );

  const saveArrayWeekdayMutate = useMutation(
    data => {
      return axios.post("/api/code/ipControlManage/left/save", data);
    },
    {
      onSuccess: res => {
        initIpControlManagerWeekdaySearchList();
      },
    }
  );

  const saveArrayWeekendMutate = useMutation(
    data => {
      return axios.post("/api/code/ipControlManage/right/save", data);
    },
    {
      onSuccess: res => {
        initIpControlManagerWeekendSearchList();
      },
    }
  );

  const initTable = type => {
    switch (type) {
      case 1:
        //검색결과 초기화
        if (updateArray.length > 0) {
          if (!confirm("수정중인 데이터가 있습니다.\n그래도 초기화 하시겠습니까?")) {
            return;
          }
        }
        initIpControlManagerSearchList();
        break;
      case 2:
        //접속 시간관리 초기화
        if (!modifyList.map(el => el.every(el2 => el2 === false)).every(el3 => el3 === true)) {
          if (!confirm("수정중인 데이터가 있습니다.\n그래도 초기화 하시겠습니까?")) {
            return;
          }
        }
        initIpControlManagerTimeAccessSearchList();
        break;
      case 3:
        //평일 접속시간관리 초기화
        if (updateArrayWeekday.length > 0) {
          if (!confirm("수정중인 데이터가 있습니다.\n그래도 초기화 하시겠습니까?")) {
            return;
          }
        }
        initIpControlManagerWeekdaySearchList();
        break;
      case 4:
        //주말 접속시간관리 초기화
        if (updateArrayWeekend.length > 0) {
          if (!confirm("수정중인 데이터가 있습니다.\n그래도 초기화 하시겠습니까?")) {
            return;
          }
        }
        initIpControlManagerWeekendSearchList();
        break;
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">IP 및 접속시간 관리</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">구분</label>
                  {frontGubunSelectList && frontGubunSelectList.length > 0 ? (
                    <select
                      className="form-select filter1-select1"
                      value={frontGubun}
                      onChange={e => frontGubunHandler(e)}
                    >
                      {frontGubunSelectList.map(frontGubunList => (
                        <option key={frontGubunList.value} value={frontGubunList.key}>
                          {frontGubunList.value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    ""
                  )}
                  <select
                    className="form-select filter1-select1 ms-2"
                    value={backGubun}
                    onChange={e => backGubunHandler(e)}
                  >
                    <option key="전체" value="A">
                      전체
                    </option>
                    <option key="장비인증_DB불가" value="S">
                      장비인증_DB불가
                    </option>
                    <option key="인증_DB접속허용" value="Y">
                      인증_DB접속허용
                    </option>
                    <option key="비인증_DB접속차단" value="N">
                      비인증_DB접속차단
                    </option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">IP끝자리</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    onChange={e => ipEndHandler(e)}
                    onKeyPress={e => handleKeyPress(e)}
                    value={ipEnd}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">사용자명</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    onChange={e => userNameHandler(e)}
                    onKeyPress={e => handleKeyPress(e)}
                    value={userName}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">MAC_ADDRES</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    onChange={e => macAddressHandler(e)}
                    onKeyPress={e => handleKeyPress(e)}
                    value={macAddress}
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => ipControlManageSearch()}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
            <div className="filter_fields_row mt-2">
              <div className="d-flex align-items-center justify-content-start">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">
                    * IP 변경시 주의사항: 파일서버 Active/Directory 권한, 방화벽 까지 동시 변경할 것
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count">
                검색결과 : [
                <span style={{ color: "blue" }}>{ipControlManagerSearchList.length}</span>] 건
              </div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <button className="btn btn-outline-primary h-25px mr-8" onClick={() => addRow(1)}>
                  추가
                </button>
                <button
                  className="btn btn-outline-primary h-25px mr-8"
                  onClick={() => saveArray(1)}
                >
                  저장
                </button>
                <button className="btn btn-outline-primary h-25px" onClick={() => initTable(1)}>
                  취소
                </button>
              </div>
            </div>
            <div className="table-container table-sticky h-225" ref={targetRef}>
              <table
                className="table table-bordered table-responsive my-table"
                style={{
                  width: "2000px",
                  marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                  marginBottom: `${
                    rowVirtualizer.getTotalSize() -
                    (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                    rowVirtualizer.getVirtualItems().length * 30
                  }px`,
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "3.04%" }} rowSpan={2}>
                      연번
                    </th>
                    <th style={{ width: "5.73%" }} rowSpan={2}>
                      위치
                    </th>
                    <th style={{ width: "7.78%" }} rowSpan={2}>
                      접속인증IP
                    </th>
                    <th style={{ width: "5.73%" }} rowSpan={2}>
                      사용자
                    </th>
                    <th style={{ width: "5.73%" }} rowSpan={2}>
                      부서
                    </th>
                    <th style={{ width: "6.14%" }} rowSpan={2}>
                      사용권한
                    </th>
                    <th style={{ width: "9.01%" }} rowSpan={2}>
                      MAC_ADDRES
                    </th>
                    <th style={{ width: "6.55%" }} rowSpan={2}>
                      PC Name
                    </th>
                    <th style={{ width: "6.14%" }} rowSpan={2}>
                      로그온명
                    </th>
                    <th style={{ width: "4.1%" }} rowSpan={2}>
                      로그온암호
                    </th>
                    <th style={{ width: "8.6%" }} rowSpan={2}>
                      인증
                    </th>
                    <th style={{ width: "5.73%" }} rowSpan={2}>
                      이미지교체
                    </th>
                    <th style={{ width: "4.91%" }} rowSpan={2}>
                      휴일허용
                    </th>
                    <th style={{ width: "4.91%" }} rowSpan={2}>
                      녹취인증
                    </th>
                    <th style={{ width: "5.73%" }} rowSpan={2}>
                      비고
                    </th>
                    <th rowSpan={2}>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  {ipControlManagerSearchList && ipControlManagerSearchList.length > 0 ? (
                    rowVirtualizer.getVirtualItems().map((item, index) => (
                      <tr key={index}>
                        <td>{item.index + 1}</td>
                        <td>
                          {ipControlManagerSearchList.length > originalIndex && item.index === 0 ? (
                            frontGubunSelectList && frontGubunSelectList.length > 0 ? (
                              <select
                                className="form-select filter1-select1"
                                value={
                                  frontGubunSelectList?.find(
                                    gubun =>
                                      gubun.key === ipControlManagerSearchList[item.index].loca
                                  )?.key
                                }
                                onChange={e => locaHandler(e, item.index)}
                              >
                                {frontGubunSelectList.map(el => (
                                  <option key={el.value} value={el.key}>
                                    {el.value}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              ""
                            )
                          ) : (
                            frontGubunSelectList?.find(
                              gubun => gubun.key === ipControlManagerSearchList[item.index].loca
                            )?.value
                          )}
                        </td>
                        <td>
                          {ipControlManagerSearchList.length > originalIndex && item.index === 0 ? (
                            <input
                              type="text"
                              className="form-control table-input"
                              value={ipControlManagerSearchList[item.index].ipAddress}
                              onChange={e => accessIpHandler(e, item.index)}
                            />
                          ) : (
                            ipControlManagerSearchList[item.index].ipAddress
                          )}
                        </td>
                        <td>
                          {userList && userList.length > 0 ? (
                            ipControlManagerSearchList.length > originalIndex &&
                            item.index === 0 ? (
                              <select
                                className="form-select filter1-select1"
                                value={ipControlManagerSearchList[item.index].userName}
                                onChange={e => userHandler(e, item.index)}
                              >
                                <option key="" value="">
                                  사용자선택
                                </option>
                                {userList.map(el => (
                                  <option key={el.value} value={el.key}>
                                    {el.value}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              userList?.find(
                                el => el.key === ipControlManagerSearchList[item.index].userName
                              )?.value
                            )
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {
                            passwordJobList?.find(
                              el => el.key === ipControlManagerSearchList[item.index].passWordJob
                            )?.value
                          }
                        </td>
                        <td>
                          {
                            passwordUserPermitList?.find(
                              el =>
                                el.key === ipControlManagerSearchList[item.index].passWordUserPermit
                            )?.value
                          }
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            onChange={e => macHandler(e, item.index)}
                            value={ipControlManagerSearchList[item.index].macAddres}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            onChange={e => pcNameHandler(e, item.index)}
                            value={ipControlManagerSearchList[item.index].pcName}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            onChange={e => logNameHandler(e, item.index)}
                            value={ipControlManagerSearchList[item.index].logName}
                          />
                        </td>
                        <td>
                          <input
                            type="password"
                            className="form-control table-input"
                            style={{ borderColor: "#c5c5c5" }}
                            onChange={e => logPazzHandler(e, item.index)}
                            value={ipControlManagerSearchList[item.index].logPazz}
                          />
                        </td>
                        <td>
                          <select
                            className="form-select filter1-select1 ms-2"
                            value={ipControlManagerSearchList[item.index].gubun}
                            onChange={e => gubunHandler(e, item.index)}
                          >
                            <option key="전체" value="A">
                              전체
                            </option>
                            <option key="장비인증_DB불가" value="S">
                              장비인증_DB불가
                            </option>
                            <option key="인증_DB접속허용" value="Y">
                              인증_DB접속허용
                            </option>
                            <option key="비인증_DB접속차단" value="N">
                              비인증_DB접속차단
                            </option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-select filter1-select1"
                            value={ipControlManagerSearchList[item.index].webConnect}
                            onChange={e => webConnectHandler(e, item.index)}
                          >
                            <option key="Y" value="Y">
                              허용
                            </option>
                            <option key="N" value="N">
                              불가
                            </option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-select filter1-select1"
                            value={ipControlManagerSearchList[item.index].daposWeekOpen}
                            onChange={e => daposWeekHandler(e, item.index)}
                          >
                            <option key="Y" value="Y">
                              Y
                            </option>
                            <option key="N" value="N">
                              N
                            </option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-select filter1-select1"
                            value={ipControlManagerSearchList[item.index].senstel}
                            onChange={e => senstelHandler(e, item.index)}
                          >
                            <option key="Y" value="Y">
                              전직원
                            </option>
                            <option key="O" value="O">
                              본인
                            </option>
                            <option key="N" value="N">
                              사용안함
                            </option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            onChange={e => remarkHandler(e, item.index)}
                            value={ipControlManagerSearchList[item.index].remark}
                          />
                        </td>
                        <td>
                          <a href={void 0}>
                            <img
                              src={process.env.PUBLIC_URL + "/images/icon-trash.png"}
                              alt=""
                              onClick={() => deleteRow(item.index, 1)}
                            />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={"23"}>데이터를 검색해주세요.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count">접속 시간 관리</div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <button
                  className="btn btn-outline-primary h-25px mr-8"
                  onClick={() => saveArray(2)}
                >
                  저장
                </button>
                <button className="btn btn-outline-primary h-25px" onClick={() => initTable(2)}>
                  취소
                </button>
              </div>
            </div>
            <div className="table-container table-sticky h-333">
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th style={{ width: "7.82%" }} rowSpan={2}>
                      구분
                    </th>
                    <th style={{ width: "12.36%" }} colSpan={2}>
                      일요일
                    </th>
                    <th style={{ width: "12.36%" }} colSpan={2}>
                      월요일
                    </th>
                    <th style={{ width: "12.36%" }} colSpan={2}>
                      화요일
                    </th>
                    <th style={{ width: "12.36%" }} colSpan={2}>
                      수요일
                    </th>
                    <th style={{ width: "12.36%" }} colSpan={2}>
                      목요일
                    </th>
                    <th style={{ width: "12.36%" }} colSpan={2}>
                      금요일
                    </th>
                    <th style={{ width: "12.36%" }} colSpan={2}>
                      토요일
                    </th>
                  </tr>
                  <tr>
                    <th style={{ width: "6.18%%" }}>시작</th>
                    <th style={{ width: "6.18%%" }}>마감</th>
                    <th style={{ width: "6.18%%" }}>시작</th>
                    <th style={{ width: "6.18%%" }}>마감</th>
                    <th style={{ width: "6.18%%" }}>시작</th>
                    <th style={{ width: "6.18%%" }}>마감</th>
                    <th style={{ width: "6.18%%" }}>시작</th>
                    <th style={{ width: "6.18%%" }}>마감</th>
                    <th style={{ width: "6.18%%" }}>시작</th>
                    <th style={{ width: "6.18%%" }}>마감</th>
                    <th style={{ width: "6.18%%" }}>시작</th>
                    <th style={{ width: "6.18%%" }}>마감</th>
                    <th style={{ width: "6.18%%" }}>시작</th>
                    <th style={{ width: "6.18%%" }}>마감</th>
                  </tr>
                </thead>
                <tbody>
                  {/***/}
                  {ipControlManagerTimeAccessSearchList.length !== 0 ? (
                    ipControlManagerTimeAccessSearchList.map((el, idx) => (
                      <tr className="time_center">
                        <td>{el?.jisaName ?? "-"}</td>
                        {el.time.map((el2, idx2) => (
                          <>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input time"
                                value={el2.startTime}
                                maxLength={"5"}
                                onChange={e => {
                                  const newIpControlManagerTimeAccessSearchList = [
                                    ...ipControlManagerTimeAccessSearchList,
                                  ];
                                  newIpControlManagerTimeAccessSearchList[idx].time[
                                    idx2
                                  ].startTime = e.target.value.replace(/[^0-9:]/g, "0");
                                  setIpControlManagerTimeAccessSearchList(
                                    newIpControlManagerTimeAccessSearchList
                                  );

                                  const newModifyList = [...modifyList];
                                  newModifyList[idx][idx2] = true;
                                  setModifyList(newModifyList);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input time"
                                value={el2.endTime}
                                maxLength={"5"}
                                onChange={e => {
                                  const newIpControlManagerTimeAccessSearchList = [
                                    ...ipControlManagerTimeAccessSearchList,
                                  ];
                                  newIpControlManagerTimeAccessSearchList[idx].time[idx2].endTime =
                                    e.target.value.replace(/[^0-9:]/g, "0");
                                  setIpControlManagerTimeAccessSearchList(
                                    newIpControlManagerTimeAccessSearchList
                                  );

                                  const newModifyList = [...modifyList];
                                  newModifyList[idx][idx2] = true;
                                  setModifyList(newModifyList);
                                }}
                              />
                            </td>
                          </>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={"16"}>검색결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row gx-3">
          <div className="col-6">
            <div className="card card-sub mt-14px">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-10px">
                  <div className="tab1-row1-count">평일(월~금) 개인별 접속 시간 관리</div>
                  <div className="d-flex align-items-center w-50 justify-content-end">
                    <button
                      className="btn btn-outline-primary h-25px mr-8"
                      onClick={() => addRow(3)}
                    >
                      추가
                    </button>
                    <button
                      className="btn btn-outline-primary h-25px mr-8"
                      onClick={() => saveArray(3)}
                    >
                      저장
                    </button>
                    <button className="btn btn-outline-primary h-25px" onClick={() => initTable(3)}>
                      취소
                    </button>
                  </div>
                </div>
                <div className="table-container table-sticky h-333">
                  <table className="table table-bordered table-responsive my-table m-0">
                    <thead>
                      <tr>
                        <th style={{ width: "7.93%" }}>No</th>
                        <th style={{ width: "15.75%" }}>부서명</th>
                        <th style={{ width: "15.32%" }}>직위</th>
                        <th style={{ width: "20.86%" }}>성명</th>
                        <th style={{ width: "12.7%" }}>시작시간</th>
                        <th style={{ width: "12.7%" }}>종료시간</th>
                        <th style={{ width: "7.93%" }}>삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ipControlManagerWeekdaySearchList &&
                      ipControlManagerWeekdaySearchList.length > 0 ? (
                        ipControlManagerWeekdaySearchList.map((item, index) => {
                          return (
                            <tr key={index} className="time_center">
                              <td>{index + 1}</td>
                              <td>{item.dept}</td>
                              <td>{item.authority}</td>
                              <td>
                                {ipControlManagerWeekdaySearchList.length > originalIndexWeekday &&
                                index === 0 ? (
                                  <select
                                    className="form-select filter1-select1"
                                    value={item.userId}
                                    onChange={e => weekdayIdHandler(e, index)}
                                  >
                                    <option key="" value="">
                                      사용자선택
                                    </option>
                                    {rightUserList.map(el => (
                                      <option key={el.value} value={el.key}>
                                        {el.value}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  rightUserList?.find(el => el.key === item?.userId)?.value
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input time"
                                  onChange={e => weekdayStartTimeHandler(e, index)}
                                  value={item.startTime}
                                  maxLength={"5"}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input time"
                                  onChange={e => weekdayEndTimeHandler(e, index)}
                                  value={item.endTime}
                                  maxLength={"5"}
                                />
                              </td>
                              <td>
                                <a href={void 0}>
                                  <img
                                    src={process.env.PUBLIC_URL + "/images/icon-trash.png"}
                                    alt=""
                                    onClick={() => deleteRow(index, 3)}
                                  />
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={"8"}>데이터가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card card-sub mt-14px">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-10px">
                  <div className="tab1-row1-count">일요일/공휴일 개인별 접속 시간 관리</div>
                  <div className="d-flex align-items-center w-50 justify-content-end">
                    <button
                      className="btn btn-outline-primary h-25px mr-8"
                      onClick={() => addRow(4)}
                    >
                      추가
                    </button>
                    <button
                      className="btn btn-outline-primary h-25px mr-8"
                      onClick={() => saveArray(4)}
                    >
                      저장
                    </button>
                    <button className="btn btn-outline-primary h-25px" onClick={() => initTable(4)}>
                      취소
                    </button>
                  </div>
                </div>
                <div className="table-container table-sticky h-333">
                  <table className="table table-bordered table-responsive my-table m-0">
                    <thead>
                      <tr>
                        <th style={{ width: "5.93%" }}>No</th>
                        <th style={{ width: "13.86%" }}>지정일</th>
                        <th style={{ width: "15.75%" }}>부서명</th>
                        <th style={{ width: "15.32%" }}>직위</th>
                        <th style={{ width: "10.86%" }}>성명</th>
                        <th style={{ width: "9.7%" }}>시작시간</th>
                        <th style={{ width: "9.7%" }}>종료시간</th>
                        <th style={{ width: "10.06%" }}>비고</th>
                        <th>삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ipControlManagerWeekendSearchList &&
                      ipControlManagerWeekendSearchList.length > 0 ? (
                        ipControlManagerWeekendSearchList.map((item, index) => {
                          return (
                            <tr key={index} className="time_center">
                              <td>{index + 1}</td>
                              <td>
                                {ipControlManagerWeekendSearchList.length > originalIndexWeekend &&
                                index === 0 ? (
                                  <input
                                    type="date"
                                    className="form-control table-input"
                                    value={item.holiDate}
                                    max={"9999-12-31"}
                                    onChange={e => holiDateHandler(e, index)}
                                  />
                                ) : (
                                  item.holiDate
                                )}
                              </td>
                              <td>{item.codename}</td>
                              <td>{item.codenamePermit}</td>
                              <td>
                                {ipControlManagerWeekendSearchList.length > originalIndexWeekend &&
                                index === 0 ? (
                                  <select
                                    className="form-select filter1-select1"
                                    value={item.userId}
                                    onChange={e => weekendIdHandler(e, index)}
                                  >
                                    <option key="" value="">
                                      사용자선택
                                    </option>
                                    {rightUserList.map(el => (
                                      <option key={el.value} value={el.key}>
                                        {el.value}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  rightUserList?.find(el => el.key === item.userId)?.value
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input time"
                                  value={item.startTime}
                                  onChange={e => weekendStartTimeHandler(e, index)}
                                  maxLength={"5"}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input time"
                                  value={item.endTime}
                                  onChange={e => weekendEndTimeHandler(e, index)}
                                  maxLength={"5"}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input"
                                  value={item.remark}
                                  onChange={e => weekendRemarkHandler(e, index)}
                                />
                              </td>
                              <td>
                                <a href={void 0}>
                                  <img
                                    src={process.env.PUBLIC_URL + "/images/icon-trash.png"}
                                    alt=""
                                    onClick={() => deleteRow(index, 4)}
                                  />
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={"9"}>데이터가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-sub mt-14px">
          <div className="card-body">
            <div className="tab1-row1-count" style={{ marginBottom: "10px" }}>
              IP 가이드
            </div>
            <ul>
              <li>서브넷 마스크 끝자리가 0(1클래스): 0~255 / 128(2등분): 0~127 or 128~255</li>
              <li>192(4등분): 0~63, 64~127, 128~191, 192~255</li>
              <li>224(8등분): 0~31, 32~63, 64~95, 96~127, 128~159, 160~191, 192~223, 224~255</li>
            </ul>

            <hr />

            <div className="d-flex align-items-start justify-content-between">
              <div className="w-50 border-end">
                <ul>
                  <li>
                    <strong>웹서버 : </strong> 222.122.118.5
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>서브넷마스크</strong>
                    <ul>
                      <li>전지사 : 255.255.255.0</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>DNS</strong>
                    <ul>
                      <li>본사: 118.129.121.141</li>
                      <li>코넷: 168.126.63.1</li>
                      <li>데이콤: 164. 124.101.2</li>
                    </ul>
                  </li>
                </ul>
                <ul className="m-0">
                  <li>
                    <strong>지사별 IP 대역</strong>
                    <ul>
                      <li>서울1: 211. 192.163.0 ~ 255</li>
                      <li>서울2: 118.129.121.141 ~</li>
                      <li>대구: 112.217.181.210 ~ 214</li>
                      <li>부산: 106.248.239.202 ~ 204</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="w-50">
                <ul>
                  <li>
                    <strong>GateWay</strong>
                    <ul>
                      <li>서울(12층): 211.192.163.254</li>
                      <li>서울(1층): 192.168.5.1</li>
                      <li>대구: 192.168.0.1</li>
                      <li>부산: 192.168.2.1</li>
                      <li>광주: 192.168.15.1</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>방화벽</strong>
                    <ul>
                      <li>12층(사용자): 211.192.163.254</li>
                      <li>12층(서버): 118.129.121.130</li>
                      <li>1층: 192.168.5.1</li>
                      <li>대구: 112.217.181.211</li>
                      <li>부산: 106.248.239.202</li>
                      <li>광주: 106.254.240.138</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
