import React, { useState } from "react";
import ModalCalculator from "../modal/ModalCalculator";
import { useAtom } from "jotai";
import {
  conditionSelect_atom,
  medoSecondInput_atom,
  modalShow_atom,
} from "../../../../data/atom/workManage/acquTax/acquTax_atom";
import { useQueryClient } from "@tanstack/react-query";

export default function ConditionSelect() {
  const [modalShow, setModalShow] = useAtom(modalShow_atom);
  const [conditionSelect, setConditionSelect] = useAtom(conditionSelect_atom);
  const [medoSecondInput, setMedoSecondInput] = useAtom(medoSecondInput_atom);

  const queryClient = useQueryClient();
  const getJisaGubun = queryClient.getQueryData(["getJisaGubun"]);

  const onChangeEventHandler = (key, value) => {
    if (key === "maedo") {
      if (value === "1") {
        setMedoSecondInput({ cgubun: "1", ccode: "", cabout: "1" });
      }
      setConditionSelect({ ...conditionSelect, [key]: value });
    } else {
      setConditionSelect({ ...conditionSelect, [key]: value });
    }
  };

  return (
    <>
      <ModalCalculator />
      <div className="card card-main mt-14px">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title  m-0">조건선택</h5>
          <div className="d-flex align-items-center justify-content-end">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                checked={conditionSelect.isSanchul}
                onChange={e => onChangeEventHandler("isSanchul", e.target.checked)}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                산출세액 미표기
              </label>
            </div>
            <div className="form-check ms-5 me-4">
              <input
                className="form-check-input"
                type="checkbox"
                id="membershipNo"
                checked={conditionSelect.isMembershipNo}
                onChange={e => onChangeEventHandler("isMembershipNo", e.target.checked)}
              />
              <label className="form-check-label" htmlFor="membershipNo">
                회원증No. 출력
              </label>
            </div>
            <button
              className="btn btn-outline-primary ms-3"
              onClick={() => setModalShow({ flag: true, isNewClickRow: false })}
            >
              취득세 산출기
            </button>
          </div>
        </div>

        <div className="card-body">
          <table className="table table-bordered table-responsive my-table1 m-0">
            <tbody>
              <tr>
                <th style={{ width: "10%" }}>지사구분</th>
                <td style={{ width: "15%" }}>
                  <select
                    className="form-select"
                    value={conditionSelect.jisa}
                    onChange={e => onChangeEventHandler("jisa", e.target.value)}
                  >
                    {getJisaGubun?.map(el => {
                      return <option value={el.key}>{el.value}</option>;
                    })}
                  </select>
                </td>
                <th style={{ width: "10%" }}>매도건수</th>
                <td style={{ width: "15%" }}>
                  <select
                    className="form-select"
                    value={conditionSelect.maedo}
                    onChange={e => onChangeEventHandler("maedo", e.target.value)}
                  >
                    <option value="1">1건</option>
                    <option value="2">2건</option>
                  </select>
                </td>
                <th style={{ width: "10%" }}>신고유형</th>
                <td style={{ width: "15%" }}>
                  <select
                    className="form-select"
                    value={conditionSelect.singo}
                    onChange={e => onChangeEventHandler("singo", e.target.value)}
                  >
                    <option value="1">기한 내 신고</option>
                    <option value="2">기한 후 신고</option>
                  </select>
                </td>
                <th style={{ width: "10%" }}>출력형태</th>
                <td style={{ width: "15%" }}>
                  <select
                    className="form-select"
                    value={conditionSelect.printType}
                    onChange={e => onChangeEventHandler("printType", e.target.value)}
                  >
                    <option value="Y">내용만</option>
                    <option value="S">양식+내용</option>
                    <option value="N">빈양식만</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
