import React, { useEffect, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import {
  unregisteredCondoMemberList_atom,
  unregisteredEditedRowList_atom,
} from "../../../../data/atom/dataManage/condoPrice/condoPriceRegisterModal_atom";

const modalRegisterRow = props => {
  const { data, index } = props;
  const [unregisteredCondoMemberList, setUnregisteredCondoMemberList] = useAtom(
    unregisteredCondoMemberList_atom
  );
  const setUnregisteredEditedRowList = useSetAtom(unregisteredEditedRowList_atom);
  const [currentData, setCurrentData] = useState({ ...data });

  useEffect(() => {
    setCurrentData({ ...data });
  }, [data]);

  const onChangeModalEventHandler = (key, value) => {
    if (key === "sise11") {
      value = value.replace(/(^0+)/, "").replace(/[^0-9]/g, "");
    } else if (key === "gubun11") {
      if (value) value = "2";
      else value = "1";
    } else if (key === "gubun22") {
      if (value) value = "1";
      else value = "2";
    } else if (key === "viewCheck") {
      if (value) value = "Y";
      else value = "N";
    }

    let newData = { ...currentData, [key]: value };
    setCurrentData(newData);

    setUnregisteredEditedRowList(prev => {
      let flagIdx = -1;
      prev = prev.map((el, idx) => {
        if (
          data.clazz === currentData.clazz &&
          data.code === currentData.code &&
          data.codename === currentData.codename
        ) {
          flagIdx = idx;
          return { ...newData };
        } else return el;
      });
      if (flagIdx === -1) prev.push({ ...newData });

      return prev;
    });

    unregisteredCondoMemberList.map((el, idx) => {
      if (
        newData.clazz === el.clazz &&
        newData.code === el.code &&
        newData.codename === el.codename
      ) {
        unregisteredCondoMemberList.splice(idx, 1, newData);
      }
    });
    setUnregisteredCondoMemberList([...unregisteredCondoMemberList]);
  };

  return (
    <tr className={currentData.gubun11 === "2" ? "bg-blue" : ""}>
      <td>{index + 1}</td>
      <td>{currentData.code}</td>
      <td>{currentData.codename}</td>
      <td>
        <div className="flex-center-center">
          <input
            type="checkbox"
            onChange={e => onChangeModalEventHandler("gubun11", e.target.checked)}
          />
          &nbsp;분
        </div>
      </td>
      <td>
        <div className="flex-center-between">
          <input
            type="text"
            value={Number(currentData.sise11).toLocaleString()}
            className="form-control table-input "
            onChange={e => onChangeModalEventHandler("sise11", e.target.value.replace(/,/g, ""))}
            style={{ textAlign: "right" }}
          />
        </div>
      </td>
      <td>
        <div className="flex-center-center">
          <input
            type="checkbox"
            checked={currentData.gubun22 === "1"}
            onChange={e => onChangeModalEventHandler("gubun22", e.target.checked)}
          />
          &nbsp;선택
        </div>
      </td>
      <td>
        <div className="flex-center-center">
          <input
            type="checkbox"
            checked={currentData.viewCheck === "Y"}
            onChange={e => onChangeModalEventHandler("viewCheck", e.target.checked)}
          />
          &nbsp;Y
        </div>
      </td>
    </tr>
  );
};

export default React.memo(modalRegisterRow);
