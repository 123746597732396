import React, { useEffect, useState, useRef } from "react";
import { axios } from "../../../util/axios/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { changeByPersonIncharge_row_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_callHistory_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_divisionListArray_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_divisionList_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_fromNumber_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_indivisionResult_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_manager_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_memberShipHistory_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_numberChange_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_orderHistory_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_searchManagerList_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_toNumber_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_totalCnt_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import { changeByPersonIncharge_transaction_atom } from "../../../data/atom/codeManage/changeByPersonIncharge/changeByPersonIncharge_atom";
import moment from "moment";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useGetIpControlManageRightUserList } from "../../../api/code/useGetIpControlManageRightUserList";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";
import { useGetProcessChk } from "../../../api/code/useGetProcessChk";
import { useGetEtcSmQ } from "../../../api/code/useGetEtcSmQ";
import { useGetEtcCmQ } from "../../../api/code/useGetEtcCmQ";
import { useGetMemberNm } from "../../../api/code/useGetMemberNm";
import { useGetIpControlManageJisaList } from "../../../api/code/useGetIpControlManageJisaList";

export const ChangeByPerson = () => {
  const targetRef = useRef(null);

  const getProcessChk = useGetProcessChk();

  const getUserIdQ = useGetUserIdQ();

  const getEtcCmQ = useGetEtcCmQ();

  const getEtcSmQ = useGetEtcSmQ();

  const getMemberNm = useGetMemberNm();

  const jisaGubunList = useGetIpControlManageJisaList().data;

  const [manager, setManager] = useAtom(changeByPersonIncharge_manager_atom);
  const [searchManagerList, setSearchManagerList] = useAtom(
    changeByPersonIncharge_searchManagerList_atom
  );
  const [divisionList, setDivisionList] = useAtom(changeByPersonIncharge_divisionList_atom);
  const [divisionListArray, setDivisionListArray] = useAtom(
    changeByPersonIncharge_divisionListArray_atom
  );

  //고객별 내역조회
  const [transactionHistory, setTransactionHistory] = useAtom(
    changeByPersonIncharge_transaction_atom
  );
  const [callHistory, setCallHistory] = useAtom(changeByPersonIncharge_callHistory_atom);
  const [orderHistory, setOrderHistory] = useAtom(changeByPersonIncharge_orderHistory_atom);
  const [memberShipHistory, setMemberShipHistory] = useAtom(
    changeByPersonIncharge_memberShipHistory_atom
  );
  const [totalCnt, setTotalCnt] = useAtom(changeByPersonIncharge_totalCnt_atom);

  const [searchListNumber, setSearchListNumber] = useAtom(changeByPersonIncharge_row_atom);

  const [numberChange, setNumberChange] = useAtom(changeByPersonIncharge_numberChange_atom);

  const [fromNumber, setFromNumber] = useAtom(changeByPersonIncharge_fromNumber_atom);
  const [toNumber, setToNumber] = useAtom(changeByPersonIncharge_toNumber_atom);
  const [indivisionResult, setIndivisionResult] = useAtom(
    changeByPersonIncharge_indivisionResult_atom
  );

  const managerList = useGetIpControlManageRightUserList().data;

  const rowVirtualizer = useVirtualizer({
    count: searchManagerList?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const searchManager = () => {
    if (manager === "0") {
      alert("담당자를 선택해주세요");
      return;
    }

    setIndivisionResult({});
    setTransactionHistory([]);
    setOrderHistory([]);
    setMemberShipHistory([]);
    setCallHistory([]);

    searchManagerMutate.mutate();
  };

  const searchManagerMutate = useMutation(
    data =>
      axios.get("/api/code/partnerDivideManage/search", {
        params: {
          userName: manager,
        },
      }),
    {
      onSuccess: res => {
        setSearchManagerList(res?.data?.result);
      },
    }
  );

  const handleDivisionSelectChange = (e, i) => {
    if (e.target.value === "0") {
      return;
    }
    getManagerDetail.mutate({ value: e.target.value, index: i });
  };

  const handleSelectChange = value => {
    setManager(value);
  };

  const saveManagerMutate = useMutation(
    data => axios.post("/api/code/partnerDivideManage/save", data),
    {
      onSuccess: res => {
        if (res?.data?.code !== "0000") {
          alert(error);
          return;
        } else {
          setIndivisionResult(res?.data);
        }
      },
    }
  );

  const saveManager = () => {
    if (searchManagerList.length === 0) {
      alert("담당자를 검색해주세요.");
      return;
    }
    if (divisionListArray.length === 0) {
      alert("인원배분 데이터를 입력해주세요.");
      return;
    }
    if (fromNumber === "") {
      alert("인원배분 데이터를 입력해주세요.");
      return;
    }
    if (toNumber === "") {
      alert("인원배분 데이터를 입력해주세요.");
      return;
    }

    let cnt = 0;
    for (let j = 0; j < divisionListArray.length; j++) {
      if (divisionListArray[j].userId && divisionListArray[j].userId !== "") {
        cnt++;
      }
    }
    if (divisionListArray.length !== cnt) {
      alert("인원배분 데이터를 입력해주세요.");
    }
    let array = [];
    for (let i = fromNumber; i < toNumber + 1; i++) {
      array.push(searchManagerList[i - 1]);
    }

    let arrayString = [];
    let divisionString = [];

    for (let j = 0; j < divisionListArray.length; j++) {
      let division = {};
      division.initName = divisionListArray[j].initName;
      division.jisa = divisionListArray[j].jisa;
      division.job = divisionListArray[j].job;
      division.userId = divisionListArray[j].userId;
      divisionString.push(division);
    }

    for (let i = 0; i < array.length; i++) {
      arrayString.push(array[i].seqNo.toString());
    }

    let object = {};
    object.to = toNumber;
    object.from = fromNumber;
    object.items = divisionString;
    object.seqNos = arrayString;

    setTotalCnt(array.length);

    saveManagerMutate.mutate(object);
  };

  const getManagerDetail = useMutation(
    // data.value와 data.index로부터 값을 추출하여 사용
    data =>
      axios.get("/api/code/partnerDivideManage/getDetail", {
        params: {
          userId: data.value,
        },
      }),
    {
      onSuccess: (res, variables) => {
        const { index } = variables;
        // 이전 배열을 복사하여 새로운 배열을 생성하고 업데이트
        setDivisionListArray(prevList => {
          const updatedList = [...prevList];
          updatedList[index] = res.data.result[0];
          return updatedList;
        });
      },
    }
  );

  const getCustDataHistory = seqNo => {
    getTransactionHistory.mutate({ custId: seqNo });
  };

  const getTransactionHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getTransactionHistory", {
        params: {
          custId: data.custId,
        },
      }),
    {
      onSuccess: (res, variables) => {
        const { custId } = variables;
        setTransactionHistory([...res?.data?.result]);
        getCallHistory.mutate({ custId });
      },
    }
  );

  const getCallHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getCallHistory", {
        params: {
          custId: data.custId,
        },
      }),
    {
      onSuccess: (res, variables) => {
        const { custId } = variables;
        setCallHistory(res?.data?.result);
        getOrderHistory.mutate({ custId });
      },
    }
  );

  const getOrderHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getOrderHistory", {
        // 수정된 부분
        params: {
          custId: data.custId,
        },
      }),
    {
      onSuccess: (res, variables) => {
        const { custId } = variables;
        setOrderHistory(res?.data?.result);
        getMemberShipHistory.mutate({ custId });
      },
    }
  );

  const getMemberShipHistory = useMutation(
    data =>
      axios.get("/api/schedule/orderManageStatus/getMemberShip", {
        params: {
          custId: data.custId,
        },
      }),
    {
      onSuccess: res => {
        setMemberShipHistory(res?.data?.result);
      },
    }
  );

  const numberChangeHandler = e => {
    const { value } = e.target;
    // value의 값이 숫자가 아닐경우 빈문자열로 replace 해버림.
    const onlyNumber = value.replace(/[^0-9]/g, "");
    if (onlyNumber > 20) {
      alert("20명이상 등록할 수 없습니다.");
      setNumberChange("");
      return;
    }
    setNumberChange(onlyNumber);
  };

  const createRow = () => {
    if (numberChange === "0" || numberChange === "") {
      alert("배분인원을 입력해주세요.");
      return;
    }

    let array = [];
    for (let i = 0; i < numberChange; i++) {
      array.push(divisionList);
    }
    setDivisionListArray(array);
  };

  const fromChangeHandler = e => {
    if (e !== "") {
      e = Number(e);
    }
    setFromNumber(e);
  };

  const toChangeHandler = e => {
    if (e !== "") {
      e = Number(e);
    }
    setToNumber(e);
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">담당자 변경작업</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">조건</label>
                  {managerList && managerList.length > 0 ? (
                    <select
                      className="form-select filter1-select1"
                      value={manager}
                      onChange={e => handleSelectChange(e.target.value)}
                    >
                      <option key="0" value="0">
                        담당자 선택
                      </option>
                      {managerList.map(manager => (
                        <option key={manager.value} value={manager.key}>
                          {manager.value}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <option value="0">조회대상</option>
                  )}
                </div>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-0 ms-8px"
                  onClick={() => searchManager()}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row gx-3">
          <div className="col-8">
            <div className="card card-sub m-0">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-10px">
                  <div className="tab1-row1-count">
                    검색 결과 : [
                    <React.Fragment>
                      <span style={{ color: "blue" }}>
                        {searchManagerList.length > 0 ? searchManagerList.length : "0"}
                      </span>
                    </React.Fragment>
                    ] 건
                  </div>
                  <select
                    className="form-select filter-perpage ms-8px h-31"
                    value={searchListNumber}
                    onChange={e => {
                      setSearchListNumber(e.target.value);
                    }}
                  >
                    <option value="5">5개씩 보기</option>
                    <option value="10">10개씩 보기</option>
                    <option value="20">20개씩 보기</option>
                  </select>
                </div>
                <div
                  className={`overflow-auto table-container table-sticky 
                                        ${searchListNumber === "5" && "h-196"}
                                        ${searchListNumber === "10" && "h-350"}
                                        ${searchListNumber === "20" && "h-650"}`}
                  id="scroll-table-body"
                  ref={targetRef}
                >
                  <table
                    className="table table-bordered table-responsive my-table"
                    style={{
                      width: `${searchManagerList.length !== 0 ? "2000px" : ""}`,
                      marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${rowVirtualizer.getTotalSize() -
                        (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                        rowVirtualizer.getVirtualItems().length * 30
                        }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "4%" }}>연번</th>
                        <th style={{ width: "7%" }}>관리번호</th>
                        <th style={{ width: "5%" }}>등급</th>
                        <th style={{ width: "15%" }}>회사명</th>
                        <th style={{ width: "7%" }}>성명</th>
                        <th style={{ width: "7%" }}>전화</th>
                        <th style={{ width: "7%" }}>휴대폰</th>
                        <th style={{ width: "30%" }}>우편수취주소</th>
                        <th style={{ width: "4%" }}>거래건수</th>
                        <th style={{ width: "4%" }}>주문건수</th>
                        <th style={{ width: "4%" }}>보유회원권</th>
                        <th style={{ width: "7%" }}>이전담당</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchManagerList.length > 0 ? (
                        rowVirtualizer.getVirtualItems().map((item, index) => (
                          <tr
                            className={`cursor-pointer ${searchManagerList[item.index]?.seqNo ===
                              +transactionHistory[0]?.custId
                              ? "bg-lightblue"
                              : ""
                              }`}
                            key={searchManagerList[item.index].seqNo}
                            onClick={() => getCustDataHistory(searchManagerList[item.index].seqNo)}
                          >
                            <td>{item.index + 1}</td>
                            <td>
                              {searchManagerList[item.index].custId?.substring(0, 4) +
                                "-" +
                                searchManagerList[item.index].custId?.substring(4, 8)}
                            </td>
                            <td>{searchManagerList[item.index].codename}</td>
                            <td>{searchManagerList[item.index].company}</td>
                            <td>
                              {searchManagerList[item.index].kname} {item.position}
                            </td>
                            <td>{searchManagerList[item.index].ctel}</td>
                            <td>{searchManagerList[item.index].cellular}</td>
                            <td>{searchManagerList[item.index].hapAddres}</td>
                            <td>{searchManagerList[item.index].exchangeCnt}</td>
                            <td>{searchManagerList[item.index].orderCnt}</td>
                            <td>{searchManagerList[item.index].membershipCnt}</td>
                            <td>{searchManagerList[item.index].oldPartner}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12">담당자를 검색해주세요.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card card-sub  m-0">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title m-0 w-50">인원배분</h5>
                <div className="d-flex align-items-center justify-content-end w-80">
                  <label className="form-label m-0 me-2">배분인원</label>
                  <input
                    type="text"
                    className="form-control filter1-input1 me-2"
                    onChange={e => numberChangeHandler(e)}
                    value={numberChange}
                  />
                  <button className="btn btn-outline-primary h-25px" onClick={() => createRow()}>
                    생성
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div
                  className={`overflow-auto table-container table-sticky h-650`}
                  id="scroll-table-body"
                >
                  <table className="table table-bordered table-responsive my-table m-0">
                    <thead>
                      <tr>
                        <th style={{ width: "11.53%" }}>연번</th>
                        <th style={{ width: "29.48%" }}>성명</th>
                        <th style={{ width: "19.23%" }}>할당량</th>
                        <th style={{ width: "16.66%" }}>약칭</th>
                        <th>소속지사</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {divisionListArray.length > 0 ? (
                          managerList && managerList.length > 0 ? (
                            divisionListArray.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <select
                                      className="form-select filter1-select1"
                                      value={item.userId}
                                      onChange={e => handleDivisionSelectChange(e, i)}
                                    >
                                      <option key="0" value="0" selected>
                                        담당자 선택
                                      </option>
                                      {managerList.map(manager => (
                                        <option key={manager.value} value={manager.key}>
                                          {manager.value}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <>
                                    {indivisionResult?.result?.division ? (
                                      <td>{indivisionResult?.result?.division[i]}</td>
                                    ) : (
                                      <td></td>
                                    )}
                                  </>
                                  <td>{item?.initName}</td>
                                  <td>{item?.jisaName}</td>
                                </tr>
                              );
                            })
                          ) : (
                            ""
                          )
                        ) : (
                          <tr>
                            <td colSpan="5">배분인원을 입력하고 생성해주세요.</td>
                          </tr>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <label className="form-label m-0 me-2">From</label>
                  <input
                    type="text"
                    className="form-control filter1-input1"
                    value={fromNumber}
                    onChange={e => fromChangeHandler(e.target.value)}
                    maxLength={"7"}
                  />
                  <label className="form-label my-0 mx-2">To</label>
                  <input
                    type="text"
                    className="form-control filter1-input1 me-2"
                    value={toNumber}
                    onChange={e => toChangeHandler(e.target.value)}
                    maxLength={"7"}
                  />
                  <button className="btn btn-outline-primary h-25px" onClick={() => saveManager()}>
                    배분시작
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="filter1_container mt-14px">
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="table-stat f-20">배분결과</div>
              <div className="table-stat">
                {indivisionResult?.code === "0000" ? (
                  indivisionResult?.result?.code === "-1" ? (
                    <span style={{ color: "red" }}>처리실패</span>
                  ) : (
                    <span style={{ color: "blue" }}>처리성공</span>
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="table-stat">
                총건수 :{" "}
                <span className="table-stat-count">
                  {indivisionResult?.result?.code !== "-1" &&
                    indivisionResult?.result?.division.length > 0
                    ? totalCnt
                    : 0}{" "}
                  건
                </span>
              </div>
              <div className="table-stat">
                처리건수 :{" "}
                <span className="table-stat-count">
                  {indivisionResult?.result?.code !== "-1" &&
                    indivisionResult?.result?.division.length > 0
                    ? indivisionResult?.result?.success
                    : 0}{" "}
                  건
                </span>
              </div>
              <div className="table-stat">
                제외건수 :{" "}
                <span className="table-stat-count">
                  {indivisionResult?.result?.code === "-1" ? totalCnt : 0} 건
                </span>
              </div>
              <div className="table-stat">
                실패건수 :{" "}
                <span className="table-stat-count">
                  {indivisionResult?.result?.code !== "-1" &&
                    indivisionResult?.result?.division.length > 0
                    ? indivisionResult?.result?.fail
                    : 0}{" "}
                  건
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0 w-50">거래내역</h5>
          </div>
          <div className="card-body">
            <div
              className={`overflow-auto table-container table-sticky h-196`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>일자</th>
                    <th>거래분류</th>
                    <th>구분1</th>
                    <th>구분2</th>
                    <th>회원권명</th>
                    <th>회원증No</th>
                    <th>서류금액</th>
                    <th>매매금액</th>
                    <th>UpDown</th>
                    <th>수수료</th>
                    <th>거래딜러</th>
                    <th>名상이</th>
                    <th>기타(샵명, 회원증名)</th>
                    <th>소속지사</th>
                    <th>세무신고</th>
                    <th>신고일자</th>
                    <th>고객등기</th>
                    <th>등기발송일</th>
                    <th>납부세액(원)</th>
                    <th>주민세(원)</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionHistory.length > 0 ? (
                    transactionHistory.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.custDate ? moment(item.custDate).format("YYYY-MM-DD") : ""}</td>
                        <td>
                          {item?.tradGubn === "3"
                            ? "개인"
                            : item?.tradGubn === "4"
                              ? "법인"
                              : "타샵"}
                        </td>
                        <td>
                          {item?.saleGu === "1"
                            ? "실거래"
                            : item?.saleGu === "2"
                              ? "분양"
                              : item?.saleGu === "3"
                                ? "증여"
                                : item?.saleGu === "4"
                                  ? "상속"
                                  : "명의변경"}
                          {"-"}
                          {item?.jisaGerae === "Y"
                            ? "타지사"
                            : item?.jisaGerae === "N"
                              ? "지사內"
                              : "샵거래"}
                        </td>
                        <td>
                          {item?.gubun2 === "1"
                            ? "골프"
                            : item?.gubun2 === "2"
                              ? "콘도"
                              : item?.gubun2 === "3"
                                ? "헬스"
                                : ""}
                          {"("}
                          {item?.gubun1 === "1" ? "양도" : "양수"}
                          {")"}
                        </td>
                        <td>
                          {item?.gubun2 === "1"
                            ? getMemberNm.data?.find(item2 => item2.code === item.roomNo)?.codename
                            : item?.gubun2 === "2"
                              ? getEtcCmQ.data?.find(item2 => item2.key === item.roomNo)?.value
                              : item?.gubun2 === "3"
                                ? getEtcSmQ.data?.find(item2 => item2.key === item.roomNo)?.value
                                : ""}
                        </td>
                        <td>{item?.memberNo}</td>
                        <td>{item?.yangdoWon2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td>{item?.yangdoWon1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td>{item?.downYn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td>{item?.commission}</td>
                        <td>{item?.partner}</td>
                        <td>{item?.differCheck === "Y" ? "상이" : "비상이"}</td>
                        <td>{item?.remark}</td>
                        <td>{jisaGubunList?.find(el => el.key === item?.jisa)?.value}</td>
                        <td>
                          {item?.taxTarget === "Y" || item?.taxTarget === "N"
                            ? "세무신고대상"
                            : item?.taxTarget === "S"
                              ? "완료"
                              : "관청발송"}
                        </td>
                        <td>{item?.sinDate ? moment(item.sinDate).format("YYYY-MM-DD") : ""}</td>
                        <td>{item?.yangdoTaxTarget === "Y" ? "대상" : "비대상"}</td>
                        <td>
                          {item?.jiroSendDate ? moment(item.jiroSendDate).format("YYYY-MM-DD") : ""}
                        </td>
                        <td>{item?.taxWon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td>{item?.taxJumin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={"21"}>담당자를 선택해주세요.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0 w-50">주문내역</h5>
          </div>
          <div className="card-body">
            <div
              className={`overflow-auto table-container table-sticky h-196`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th style={{ width: "4.5%" }}>연번</th>
                    <th style={{ width: "8.5%" }}>주문일자</th>
                    <th style={{ width: "5.22%" }}>구분1</th>
                    <th style={{ width: "5.85%" }}>구분2</th>
                    <th style={{ width: "19.25%" }}>회원권명</th>
                    <th style={{ width: "5.92%" }}>희망</th>
                    <th style={{ width: "7.14%" }}>제시</th>
                    <th style={{ width: "6.66%" }}>상황</th>
                    <th style={{ width: "7.4%" }}>상담자</th>
                    <th style={{ width: "18.66%" }}>비고</th>
                    <th>최종수정</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {orderHistory && orderHistory.length > 0 ? (
                      orderHistory.map((item, index) => (
                        <tr key={index}>
                          <td>{item.ilNo}</td>
                          <td>
                            {item.requestDate !== null
                              ? moment(item.requestDate).format("YYYY-MM-DD")
                              : ""}
                          </td>
                          <td>
                            {item.gubun3 === "1"
                              ? "골프"
                              : item.gubun3 === "2"
                                ? "콘도"
                                : item.gubun3 === "3"
                                  ? "헬스"
                                  : ""}
                          </td>
                          <td>
                            {item.gubun2 === "1"
                              ? "매도"
                                ? item.gubun2 === "2"
                                : "매수"
                              : item.gubun2 === "3"
                                ? "분양"
                                : ""}
                          </td>
                          <td>{item.etcCodename}</td>
                          <td>
                            {item.requireWon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {item.quotationWon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {getProcessChk.data?.find(el => el.key === item.processCheck)?.value}
                          </td>
                          <td>{getUserIdQ.data?.find(el => el.key === item.counselorId)?.value}</td>
                          <td>{item.remark}</td>
                          <td>{getUserIdQ?.data?.find(el => el.key === item.modId)?.value}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={"11"}>담당자를 선택해주세요.</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0 w-50">보유회원권내역</h5>
          </div>
          <div className="card-body">
            <div
              className={`overflow-auto table-container table-sticky h-350`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th style={{ width: "4.81%" }}>연번</th>
                    <th style={{ width: "7.22%" }}>구분</th>
                    <th style={{ width: "25.3%" }}>회원권명</th>
                    <th style={{ width: "16.86%" }}>비고</th>
                    <th style={{ width: "13.25%" }}>보유일자</th>
                    <th style={{ width: "9.63%" }}>삭제자</th>
                    <th style={{ width: "12.04%" }}>삭제일시</th>
                    <th colSpan={"2"} style={{ width: "8%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {memberShipHistory && memberShipHistory.length > 0 ? (
                      memberShipHistory.map((item, index) => (
                        <tr key={index} className={item.deleteGubun === "Y" ? "deleted" : ""}>
                          <td style={item.deleteGubun === "Y" ? { color: "red" } : {}}>
                            {item.deleteGubun === "Y" ? "前" : index + 1}
                          </td>
                          <td>
                            {item.gu === "1"
                              ? "골프"
                              : item.gu === "2"
                                ? "콘도"
                                : item.gu === "3"
                                  ? "헬스"
                                  : ""}
                          </td>
                          <td>
                            {item?.gu === "1"
                              ? getMemberNm.data?.find(el => el.code === item.cardNo)?.codename
                              : item?.gu === "2"
                                ? getEtcCmQ.data?.find(el => el.key === item.cardNo)?.value
                                : item?.gu === "3"
                                  ? getEtcSmQ.data?.find(el => el.key === item.cardNo)?.value
                                  : ""}
                          </td>
                          <td>{item.remark}</td>
                          <td>
                            {item.inDate !== null ? moment(item.inDate).format("YYYY-MM-DD") : ""}
                          </td>
                          <td>
                            {getUserIdQ.data?.find(item2 => item2.key === item.deleteId)?.value}
                          </td>
                          <td>
                            {item.deleteDate !== null
                              ? moment(item.deleteDate).format("YYYY-MM-DD")
                              : ""}
                          </td>
                          <td style={item.deleteGubun !== "Y" ? { backgroundColor: "yellow" } : {}}>
                            {item.ilNo}
                          </td>
                          <td>9</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={"8"}>담당자를 선택해주세요.</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0 w-50">통화내역</h5>
          </div>
          <div className="card-body">
            <div
              className={`overflow-auto table-container table-sticky h-196`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th style={{ width: "6.7%" }}>연번</th>
                    <th style={{ width: "11.2%" }}>통화일자</th>
                    <th style={{ width: "8.2%" }}>통화자</th>
                    <th style={{ width: "6.7%" }}>VIEW</th>
                    <th>통화내용</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {callHistory && callHistory.length > 0 ? (
                      callHistory.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.talkDate !== null
                              ? moment(item.talkDate).format("YYYY-MM-DD")
                              : ""}
                          </td>
                          <td>{item.talkName}</td>
                          <td>
                            {item.viewChk === "1"
                              ? "일반"
                              : item.viewChk === "2"
                                ? "상위"
                                : item.viewChk === "N"
                                  ? "부재"
                                  : ""}
                          </td>
                          <td>{item.remark}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={"5"}>담당자를 선택해주세요.</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangeByPerson;
