import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { transactionHistoryInquiryPopupEditedRow_atom } from "../../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useSetAtom } from "jotai";
import { regex_only_number } from "../../../../../../util/validation";

const InquiryRow = props => {
  const { data, index } = props;
  const [myData, setMyData] = useState({ ...data });

  const setEditedRowAtom = useSetAtom(transactionHistoryInquiryPopupEditedRow_atom);

  const queryClient = useQueryClient();
  const userIdQ = queryClient.getQueryData(["getUserIdQ"]);
  const jisaGubun = queryClient.getQueryData(["getJisaGubunAllQ1"]);
  const partner = queryClient.getQueryData(["getPartner"]);

  const displayGubun2 = useCallback(value => {
    switch (value) {
      case "1":
        return "골";
      case "2":
        return "콘";
      case "3":
        return "헬";
      default:
        return "";
    }
  }, []);

  const displayGubun1 = useCallback(value => {
    switch (value) {
      case "1":
        return "양도";
      case "2":
        return "양수";
      default:
        return "";
    }
  }, []);

  const onChangeEvent = (key, value) => {
    let newMyData = { ...myData, [key]: value };
    setMyData(newMyData);
    setEditedRowAtom(prev => {
      let tmp = { ...prev };
      tmp[newMyData.seqNo] = newMyData;
      return tmp;
    });
  };

  useEffect(() => {
    setMyData(data);
  }, [data]);

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <input
          type="date"
          max={"9999-12-31"}
          className="form-control table-input"
          value={data.custDate && (moment(data.custDate).format("YYYY-MM-DD") ?? "")}
          onChange={e => onChangeEvent("custDate", e.target.value)}
        />
      </td>
      <td>
        <select
          className="form-select"
          value={myData.tradGubn && (myData.tradGubn ?? "3")}
          onChange={e => onChangeEvent("tradGubn", e.target.value)}
        >
          <option value="3">개인물건</option>
          <option value="4">법인물건</option>
          <option value="8">타샵물건</option>
        </select>
      </td>
      <td>{displayGubun2(myData.gubun2)}</td>

      <td>{displayGubun1(myData.gubun1)}</td>
      <td>
        <select
          className="form-select"
          value={myData.jisaGerae && (myData.jisaGerae ?? "N")}
          onChange={e => onChangeEvent("jisaGerae", e.target.value)}
        >
          <option value="N">지사內</option>
          <option value="Y">타지사</option>
          <option value="S">샵거래</option>
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={myData.saleGu && (myData.saleGu ?? "1")}
          onChange={e => onChangeEvent("saleGu", e.target.value)}
        >
          <option value="1">실거래</option>
          <option value="2">분양</option>
          <option value="3">증여</option>
          <option value="4">상속</option>
          <option value="5">명의변경</option>
        </select>
      </td>
      <td>{myData.custId && `${myData.custId.substring(0, 4)}-${myData.custId.substring(4)}`}</td>
      <td>{myData.company ?? ""}</td>

      <td>{myData.kname ?? ""}</td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={myData.exchangeSeqNo ?? ""}
          onChange={e => onChangeEvent("exchangeSeqNo", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={Number(myData.yangdoWon2).toLocaleString("en-US")}
          onChange={e =>
            regex_only_number.test(e.target.value.replaceAll(",", ""))
              ? onChangeEvent("yangdoWon2", e.target.value.replaceAll(",", ""))
              : false
          }
        />
      </td>
      <td>{myData.yangdoWon1 && Number(myData.yangdoWon1).toLocaleString("en-US")}</td>
      <td>
        <select
          className="form-select"
          value={myData.downYn ?? "Y"}
          onChange={e => onChangeEvent("downYn", e.target.value)}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
          <option value="D"></option>
        </select>
      </td>

      <td>
        <input
          type="text"
          className="form-control table-input"
          value={myData.memberNo ?? ""}
          onChange={e => onChangeEvent("memberNo", e.target.value)}
        />
      </td>
      <td>{myData.codename ?? ""}</td>
      <td>
        <select
          className="form-select"
          value={myData.partner ?? "KDP6306"}
          onChange={e => onChangeEvent("partner", e.target.value)}
        >
          {partner?.map(item => (
            <option value={item.key}>{item.value}</option>
          ))}
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={myData.jisa ?? "11"}
          onChange={e => onChangeEvent("jisa", e.target.value)}
        >
          {jisaGubun?.map(item => (
            <option value={item.key}>{item.value}</option>
          ))}
        </select>
      </td>

      <td>
        <select
          className="form-select"
          value={myData.taxTarget ?? "Y"}
          onChange={e => onChangeEvent("taxTarget", e.target.value)}
        >
          <option value="Y">대상</option>
          <option value="N">비대상</option>
          <option value="R">관청접수</option>
          <option value="S">완료</option>
        </select>
      </td>
      <td>
        <input
          type="date"
          max={"9999-12-31"}
          className="form-control table-input w-125px"
          value={myData.sinDate && moment(myData?.sinDate).format("YYYY-MM-DD")}
          onChange={e => onChangeEvent("sinDate", e.target.value)}
        />
      </td>
      <td>
        <select
          className="form-select"
          value={myData.differCheck ?? "Y"}
          onChange={e => onChangeEvent("differCheck", e.target.value)}
        >
          <option value="Y">Y</option>
          <option value="N"></option>
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={myData.remark ?? ""}
          onChange={e => onChangeEvent("remark", e.target.value)}
        />
      </td>
      <td>
        <select
          className="form-select"
          value={myData.yangdoTaxTarget ?? "Y"}
          onChange={e => onChangeEvent("yangdoTaxTarget", e.target.value)}
        >
          <option value="Y">대상</option>
          <option value="N">비대상</option>
          <option value="H">발송대기</option>
          <option value="S">완료</option>
        </select>
      </td>

      <td>
        <input
          type="date"
          max={"9999-12-31"}
          className="form-control table-input w-125px"
          value={myData.jiroSendDate && moment(myData?.jiroSendDate).format("YYYY-MM-DD")}
          onChange={e => onChangeEvent("jiroSendDate", e.target.value)}
        />
      </td>
      <td>{userIdQ?.find(item => item.key === myData?.userid)?.value}</td>
      <td>{myData.modDate && moment(myData?.modDate).format("YYYY-MM-DD")}</td>
    </tr>
  );
};

export default React.memo(InquiryRow);
