import React, { useEffect, useState } from "react";
import LeftShopInfo from "./leftShopInfo";
import RightShopMemo from "./rightShopMemo";
import { useAtom, useSetAtom } from "jotai";
import {
  accountList_atom,
  bigo_atom,
  bizInfo_atom,
  key1b_atom,
  registerFlag_atom,
  searchedParam_atom,
  searchParam_atom,
  searchParamInit,
  shopDetail_atom,
  shopDetailFlag_atom,
  shopList_atom,
  tableLimit_atom,
} from "../../../data/atom/workManage/shopMasterManage/shopMasterManage_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useGetEmail } from "../../../api/code/useGetEmail";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import usePrintTab from "../../../components/modal/usePrintTab";

export const ShopMasterManage = () => {
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [searchedParam, setSearchedParam] = useAtom(searchedParam_atom);
  const [shopList, setShopList] = useAtom(shopList_atom);
  const [tableLimit, setTableLimit] = useAtom(tableLimit_atom);
  const [shopDetailFlag, setShopDetailFlag] = useAtom(shopDetailFlag_atom);
  const [registerFlag, setRegisterFlag] = useAtom(registerFlag_atom);
  const setBigo = useSetAtom(bigo_atom);
  const setBizInfo = useSetAtom(bizInfo_atom);
  const setAccountList = useSetAtom(accountList_atom);
  const [shopDetail, setShopDetail] = useAtom(shopDetail_atom);
  const setKey1b = useSetAtom(key1b_atom);
  const { addTabClosedEvent } = useRecentTabEvent();
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const getEmail = useGetEmail();
  const searchShopList = useMutation(data => axios.get("/api/work/shop/search", { params: data }), {
    onSuccess: res => {
      const data = res.data.result;
      setSearchedParam(searchParam);
      setShopList(data);
    },
  });

  const searchShop = useMutation(data => axios.get("/api/work/shop/getDetail", { params: data }), {
    onSuccess: res => {
      const data = res.data.result;

      setShopDetailFlag(true);
      setShopDetail({
        ...data,
        inDate: data.inDate ? data.inDate : null,
        outDate: data.outDate ? data.outDate : null,
        documEmailId: data.documEmail?.substring(0, data.documEmail?.indexOf("@")) ?? "",
        documEmailDomain: data.documEmail?.substring(data.documEmail?.indexOf("@") + 1) ?? "",
        documEmailDomainKey:
          data.documEmail === null || data.documEmail === ""
            ? ""
            : getEmail.data?.find(
                el => el.value === data.documEmail?.substring(data.documEmail?.indexOf("@") + 1)
              )?.key ?? "99",
        sekumEmailId: data.sekumEmail?.substring(0, data.sekumEmail?.indexOf("@")) ?? "",
        sekumEmailDomain: data.sekumEmail?.substring(data.sekumEmail?.indexOf("@") + 1) ?? "",
        sekumEmailDomainKey:
          data.sekumEmail === null || data.sekumEmail === ""
            ? ""
            : getEmail.data?.find(
                el => el.value === data.sekumEmail?.substring(data.sekumEmail?.indexOf("@") + 1)
              )?.key ?? "99",
      });
    },
  });

  const print = useMutation(
    () =>
      axios.get("/api/work/shop/print", {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          openPdf(res?.data);
          /*setPdfData({
            data: res.data,
            name: `샵_마스터_대장_출력_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
        }
      },
    }
  );

  const onChangeParamEventHandler = (key, value) => {
    setSearchParam({ ...searchParam, [key]: value });
  };

  const onChangeTableLimitEventHandler = value => {
    setTableLimit(value);
  };

  const btnSearch = () => {
    searchShopList.mutate(searchParam);
  };

  const btnSelectShop = clickedRowData => {
    if (registerFlag) {
      alert("등록 작업을 마무리 해주십시오.");
      return;
    }

    searchShop.mutate(clickedRowData);
  };

  const btnPrint = () => {
    print.mutate();
  };

  const btnRegister = () => {
    setShopDetail({});
    setShopDetailFlag(true);
    setRegisterFlag(true);
    setBigo({ remark: "" });
    setBizInfo({});
    setAccountList([]);
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam(searchParamInit);
      setShopList([]);
      setShopDetail({});
      setTableLimit("5");
      setShopDetailFlag(false);
      setRegisterFlag(false);
      setKey1b("비고사항");
      setBigo({ remark: "" });
      setBizInfo({});
      setAccountList([]);
    });
  }, []);

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">샵마스터 검색</div>
            <div className="filter-buttons">
              <button className="btn btn-outline-dark my-btn-outline-dark" onClick={btnPrint}>
                샵 마스터 대장 출력
              </button>
              <button
                className="btn btn-outline-dark my-btn-outline-dark"
                onClick={() => {
                  window.open("about:blank").location.href = "http://www.kgmda.com/";
                }}
              >
                회원권협회
              </button>
              <button className="btn btn-primary" onClick={btnRegister} disabled={registerFlag}>
                등 록
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.radio}
                    onChange={e => onChangeParamEventHandler("radio", e.target.value)}
                  >
                    <option value="A">샵명</option>
                    <option value="B">담당자</option>
                    <option value="C">전화(팩스)번호</option>
                    <option value="D">관리번호</option>
                    <option value="E">지역별</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">검색어</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.keyword}
                    onChange={e => onChangeParamEventHandler("keyword", e.target.value)}
                    onKeyDown={pressEnter}
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>

          <div className="card card-sub">
            <div className="card-body">
              <div className="containerx">
                <div className="table-container">
                  <div className="d-flex align-items-center justify-content-end mb-10px">
                    <select
                      className="form-select filter-perpage ms-3"
                      onChange={e => onChangeTableLimitEventHandler(e.target.value)}
                    >
                      <option value="5">5개씩 보기</option>
                      <option value="10">10개씩 보기</option>
                      <option value="20">20개씩 보기</option>
                    </select>
                  </div>

                  <div
                    className={`table-container table-sticky ${
                      tableLimit === "5"
                        ? "h-183"
                        : tableLimit === "10"
                        ? "h-333"
                        : tableLimit === "20"
                        ? "h-633"
                        : "h-183"
                    }`}
                  >
                    <table className="table table-bordered table-responsive my-table mt-0">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>연번</th>
                          <th style={{ width: "20%" }}>관리번호</th>
                          <th>샵명</th>
                          <th style={{ width: "15%" }}>담당자</th>
                          <th style={{ width: "15%" }}>대표자</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shopList?.length !== 0 ? (
                          shopList.map((el, idx) => {
                            return (
                              <tr
                                className={`cursor-pointer ${
                                  el.custId === shopDetail?.custId && "bg-blue"
                                }
                                ${el.outCheck === "Y" && "bg-red"}
                                
                                `}
                                onClick={() => btnSelectShop(el)}
                              >
                                <td>{idx + 1}</td>
                                <td>{el.custId}</td>
                                <td>{el.company}</td>
                                <td>{el.kname ? el.kname : "-"}</td>
                                <td>{el.owner ? el.owner : "-"}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={5}>검색된 결과가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={!registerFlag ? "two-col-outer" : ""}>
            {shopDetailFlag ? (
              <>
                <LeftShopInfo searchShop={searchShop} searchShopList={searchShopList} />
                {!registerFlag ? (
                  <>
                    <RightShopMemo />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
