import React from "react";
import { regex_only_number } from "../../../../util/validation";

const ModalTableRow = props => {
  const { data, index, individualGolfListOptions, setData } = props;

  const onChangeEvent = (key, value, txt) => {
    switch (key) {
      case "viewCheck":
      case "adminCheck":
        if (value === true) value = "Y";
        else value = "N";
        break;
      case "gubun11":
      case "gubun":
        if (value === true) value = "2";
        else value = "1";
        break;
      case "sise12":
      case "sise22":
      case "siseD":
      case "siseS":
    }

    setData(prev =>
      prev.map((i, idx) => {
        if (idx === index) {
          let tmp;
          if (txt) tmp = { ...i, [key]: value, codename: txt };
          else tmp = { ...i, [key]: value };

          return tmp;
        }
        return i;
      })
    );
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <input type="text" className="form-control table-input" value={data.code} readOnly={true} />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={individualGolfListOptions.find(option => option.value === data?.code)?.label}
          readOnly={true}
        />
      </td>
      <td>
        <div className="flex-center-center">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={"chk1-" + index}
              onChange={e => {
                if (data.hasOwnProperty("gubun11")) onChangeEvent("gubun11", e.target.checked);
                else if (data.hasOwnProperty("gubun")) onChangeEvent("gubun", e.target.checked);
              }}
              checked={data?.gubun11 === "2" || data?.gubun === "2"}
            />
            <label className="form-check-label nowrap" htmlFor={"chk1-" + index}>
              분
            </label>
          </div>
        </div>
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input text-end"
          value={data.sise12 || data.csiseD}
          onChange={e => {
            if (regex_only_number.test(e.target.value)) {
              onChangeEvent("sise12", e.target.value);
              onChangeEvent("csiseD", e.target.value);
            }
          }}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input text-end"
          value={data.sise22 || data.csiseS}
          onChange={e => {
            if (regex_only_number.test(e.target.value)) {
              onChangeEvent("sise22", e.target.value);
              onChangeEvent("csiseS", e.target.value);
            }
          }}
        />
      </td>
      <td>
        <div className="flex-center-between">
          <input
            className="form-check-input"
            type="checkbox"
            checked={data.insertChk ?? false}
            onChange={e => onChangeEvent("insertChk", e.target.checked)}
          />
          <div className="nowrap mx-1">선택</div>
        </div>
      </td>
      <td>
        <div className="flex-center-center">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={"chk2-" + index}
              checked={data?.adminCheck === "Y"}
              onChange={e => onChangeEvent("adminCheck", e.target.checked)}
            />
            <label className="form-check-label nowrap" htmlFor={"chk2-" + index}>
              Y
            </label>
          </div>
        </div>
      </td>
      <td>
        <div className="flex-center-center">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={"chk3-" + index}
              checked={data?.viewCheck === "Y"}
              onChange={e => onChangeEvent("viewCheck", e.target.checked)}
            />
            <label className="form-check-label nowrap" htmlFor={"chk3-" + index}>
              Y
            </label>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default React.memo(ModalTableRow);
