import EmpModal from "../../../../../components/modal/Modal";
import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import React, { useState } from "react";
import moment from "moment";
import { useGetUserIdQ } from "../../../../../api/code/useGetUserIdQ";
import { useGetProcessChk } from "../../../../../api/code/useGetProcessChk";

export const SideSearchOrderModal = ({
  isSideSearchOrderModalOpen,
  setIsSideSearchOrderModalOpen,
}) => {
  const { isOpen, data } = isSideSearchOrderModalOpen;

  const [searchedTablelList, setSearchedTableList] = useState({
    mesu: [],
    medo: [],
  });

  const getUserIdQ = useGetUserIdQ();
  const getProcessChk = useGetProcessChk();

  const customerGetOrderDetail = useQuery(
    [],
    () =>
      axios.get("/api/work/customer/right/getOrderDetail", {
        params: {
          memberNo: data.memberNo,
          process: data.procCode,
          saleGubn: data.classCode === "GM" ? "1" : data.classCode === "CM" ? "2" : "3",
          startDate: data.startDate,
        },
      }),
    // .then(res => res.data.result),
    {
      onSuccess: res => {
        setSearchedTableList(res.data.result);
      },
    }
  );

  return (
    <EmpModal
      show={isSideSearchOrderModalOpen.isOpen}
      width="100%"
      minWidth="475px"
      title={`회원권 명: ${isSideSearchOrderModalOpen.data.codename}`}
      onHide={() => setIsSideSearchOrderModalOpen({ isOpen: false, data: null })}
      strClass="modal-xl"
      size="xl"
    >
      <div className="row gx-3">
        <div className="col-6">
          <div
            className={`table-container table-sticky ${
              searchedTablelList.medo.length !== 0 ? "h-333" : "h-100"
            } `}
          >
            <table className="table table-bordered table-responsive my-table mt-0">
              <colgroup>
                {/*<col width="15%" />*/}
                {/*<col width="15%" />*/}
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={6}>매도</th>
                </tr>
                <tr>
                  <th colSpan={3}>종목</th>
                  <th colSpan={3}>골프</th>
                </tr>
                <tr>
                  {/*<th>연번</th>*/}
                  <th>주문일</th>
                  <th>희망가</th>
                  <th>제시가</th>
                  <th>상태</th>
                  <th>상담자</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                {/*{customerGetOrderDetail.data && customerGetOrderDetail.data.medo?.length !== 0 ? (*/}
                {/*  customerGetOrderDetail.data.medo?.map(el => (*/}
                {/*    <tr>*/}
                {/*      <td>{el?.requestDate ? moment(el.requestDate).format("YYYY-MM-DD") : ""}</td>*/}
                {/*      <td className="text-end">{el.requireWon.toLocaleString()}</td>*/}
                {/*      <td className="text-end">{el.quotationWon.toLocaleString()}</td>*/}
                {/*      <td>{getProcessChk.data?.find(el2 => el2.key == el.processCheck)?.value}</td>*/}
                {/*      <td>{getUserIdQ.data?.find(el2 => el2.key === el.counselorId)?.value}</td>*/}
                {/*      <td>{el.bigo}</td>*/}
                {/*    </tr>*/}
                {/*  ))*/}
                {/*) : (*/}
                {/*  <tr>*/}
                {/*    <td colSpan={6}>검색된 매도가 없습니다.</td>*/}
                {/*  </tr>*/}
                {/*)}*/}

                {searchedTablelList.medo.length !== 0 ? (
                  searchedTablelList.medo.map((el, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {el?.requestDate ? moment(el.requestDate).format("YYYY-MM-DD") : ""}
                        </td>
                        <td className="text-end">{el.requireWon.toLocaleString()}</td>
                        <td className="text-end">{el.quotationWon.toLocaleString()}</td>
                        <td>
                          {getProcessChk.data?.find(el2 => el2.key === el.processCheck)?.value}
                        </td>
                        <td>{getUserIdQ.data?.find(el2 => el2.key === el.counselorId)?.value}</td>
                        <td>{el.remark}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>검색된 매도가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6">
          <div
            className={`table-container table-sticky ${
              searchedTablelList.mesu.length !== 0 ? "h-333" : "h-100"
            } `}
          >
            <table className="table table-bordered table-responsive my-table mt-0">
              <colgroup>
                {/*<col width="15%" />*/}
                {/*<col width="15%" />*/}
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={6}>매수</th>
                </tr>
                <tr>
                  <th colSpan={3}>종목</th>
                  <th colSpan={3}>골프</th>
                </tr>
                <tr>
                  {/*<th>연번</th>*/}
                  <th>주문일</th>
                  <th>희망가</th>
                  <th>제시가</th>
                  <th>상태</th>
                  <th>상담자</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                {/*{customerGetOrderDetail?.data && customerGetOrderDetail.data.mesu?.length !== 0 ? (*/}
                {/*  customerGetOrderDetail.data.mesu?.map(el => (*/}
                {/*    <tr>*/}
                {/*      <td>{el?.requestDate ? moment(el.requestDate).format("YYYY-MM-DD") : ""}</td>*/}
                {/*      <td className="text-end">{el.requireWon.toLocaleString()}</td>*/}
                {/*      <td className="text-end">{el.quotationWon.toLocaleString()}</td>*/}
                {/*      <td>{getProcessChk.data?.find(el2 => el2.key == el.processCheck)?.value}</td>*/}
                {/*      <td>{getUserIdQ.data?.find(el2 => el2.key === el.counselorId)?.value}</td>*/}
                {/*      <td>{el.bigo}</td>*/}
                {/*    </tr>*/}
                {/*  ))*/}
                {/*) : (*/}
                {/*  <tr>*/}
                {/*    <td colSpan={6}>검색된 매수가 없습니다.</td>*/}
                {/*  </tr>*/}
                {/*)}*/}

                {searchedTablelList.mesu.length !== 0 ? (
                  searchedTablelList.mesu.map((el, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {el?.requestDate ? moment(el.requestDate).format("YYYY-MM-DD") : ""}
                        </td>
                        <td className="text-end">{el.requireWon.toLocaleString()}</td>
                        <td className="text-end">{el.quotationWon.toLocaleString()}</td>
                        <td>
                          {getProcessChk.data?.find(el2 => el2.key === el.processCheck)?.value}
                        </td>
                        <td>{getUserIdQ.data?.find(el2 => el2.key === el.counselorId)?.value}</td>
                        <td>{el.remark}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>검색된 매수가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </EmpModal>
  );
};
