import { WeeklySales } from "./weeklySales";
import { PeriodSales } from "./periodSales";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom, useSetAtom } from "jotai/index";
import {
  profitList_atom,
  profitTop_atom,
  profitBottom_atom,
  searchParam_atom,
  searchedParam_atom,
  searchParamInit,
  profitTotal_atom,
} from "../../../data/atom/goodsManage/weeklyPerformanceAnalysis/weeklyPerformanceAnalysis_atom";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useEffect } from "react";

export const WeeklyPerformanceAnalysis = () => {
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [searchedParam, setSearchedParam] = useAtom(searchedParam_atom);
  const setProfitTotal = useSetAtom(profitTotal_atom);
  const setProfitList = useSetAtom(profitList_atom);
  const setProfitTop = useSetAtom(profitTop_atom);
  const setProfitBottom = useSetAtom(profitBottom_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const search = useMutation(
    data => axios.get("/api/schedule/profitTable/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setSearchedParam({ ...searchParam, flag: true });
        setProfitTotal(data.total);
        setProfitList(data.profitByWeek);
        setProfitTop(data.topProfit);
        setProfitBottom(data.bottomProfit);
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    setSearchParam({ ...searchParam, [key]: value });
  };

  const btnSearch = () => {
    search.mutate({
      fromDate: searchParam.fromDate.replace(/-/g, ""),
      toDate: searchParam.toDate.replace(/-/g, ""),
      fromPeriod: searchParam.fromPeriod.replace(/-/g, ""),
      toPeriod: searchParam.toPeriod.replace(/-/g, ""),
    });
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...searchParamInit });
      setSearchedParam({ flag: false });
      setProfitTotal({});
      setProfitList([]);
      setProfitTop([]);
      setProfitBottom([]);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">검색</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row" onKeyDown={pressEnter}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1 m-0 p-0">
                  <label className="filter1-label ms-3">주별기간</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.fromDate}
                    onChange={e => onChangeEventHandler("fromDate", e.target.value)}
                    min={"1900-01-01"}
                    max={"2099-12-31"}
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.toDate}
                    onChange={e => onChangeEventHandler("toDate", e.target.value)}
                    min={"1900-01-01"}
                    max={"2099-12-31"}
                  />
                </div>
                <div className="filter1-field filter1-field2 m-0 p-0">
                  <label className="filter1-label ms-4">기간별 매출</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.fromPeriod}
                    onChange={e => onChangeEventHandler("fromPeriod", e.target.value)}
                    min={"1900-01-01"}
                    max={"2099-12-31"}
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.toPeriod}
                    onChange={e => onChangeEventHandler("toPeriod", e.target.value)}
                    min={"1900-01-01"}
                    max={"2099-12-31"}
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          {searchedParam.flag ? (
            <>
              <WeeklySales />
              <PeriodSales />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
