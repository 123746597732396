import React from "react";
import { useLocation } from "react-router-dom";

export const SidebarGoods = ({ func_recentTap, data }) => {
  const location = useLocation();
  const pathName = location.pathname.split("/")[4];
  const pathName2 = location.pathname.split("/")[5];

  const btn_onClick = (name, url) => {
    func_recentTap({
      name: name,
      url: url,
    });
  };

  return (
    <div className="sidebar-wrapper">
      <ul>
        {data?.sub.reduce((acc, cur) => {
          if (cur.sub.length !== 0) {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 2] && "active"
                }
              >
                <div onClick={() => btn_onClick(cur.sub[0].pageName, cur.url)}>
                  {cur.pageName} <span></span>
                </div>

                <ul className="sidebar_subList">
                  {cur.sub.map(el => (
                    <li
                      className={
                        pathName2 === el.url.split("/")[el.url.split("/").length - 1] && "active"
                      }
                      onClick={() => btn_onClick(el.pageName, el.url)}
                    >
                      {el.pageName}
                    </li>
                  ))}
                </ul>
              </li>,
            ];
          } else {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 1] && "active"
                }
                onClick={() => btn_onClick(cur.pageName, cur.url)}
              >
                {cur.pageName}
              </li>,
            ];
          }

          return acc;
        }, [])}
      </ul>
    </div>
  );

  // return (
  //   <div className="sidebar-wrapper">
  //     <ul>
  //       <li
  //         className={pathName === "personalScheduleManagement" && "active"}
  //         onClick={() =>
  //           btn_onClick("개인일정 관리", "/goods/individualManage/personalScheduleManagement")
  //         }
  //       >
  //         개인일정 관리
  //       </li>
  //       <li
  //         className={pathName === "supportTeamSchedulesManage" && "active"}
  //         onClick={() =>
  //           btn_onClick(
  //             "업무 지원팀 스케줄 관리",
  //             "/goods/supportManage/supportTeamSchedulesManage"
  //           )
  //         }
  //       >
  //         업무 지원팀 스케줄 관리
  //       </li>
  //       <li
  //         className={pathName === "supportTeamSchedulesInquire" && "active"}
  //         onClick={() =>
  //           btn_onClick(
  //             "업무 지원팀 스케줄 조회",
  //             "/goods/supportManageCheck/supportTeamSchedulesInquire"
  //           )
  //         }
  //       >
  //         업무 지원팀 스케줄 조회
  //       </li>
  //       <li
  //         className={pathName === "callReservationManagement" && "active"}
  //         onClick={() =>
  //           btn_onClick(
  //             "통화예약관리 및\n 통화내역 조회",
  //             "/goods/callReserveManage/callReservationManagement"
  //           )
  //         }
  //       >
  //         통화예약관리 및 통화내역조회
  //       </li>
  //       <li
  //         className={pathName === "orderManage" && "active"}
  //         onClick={() => btn_onClick("주문관리현황", "/goods/orderManageStatus/orderManage")}
  //       >
  //         주문관리현황
  //       </li>
  //       <li
  //         className={pathName === "individualPerformance" && "active"}
  //         onClick={() => btn_onClick("개인(팀)별 실적", "/goods/profit/individualPerformance")}
  //       >
  //         개인(팀)별 실적
  //       </li>
  //       <li
  //         className={pathName === "weeklyPerformanceAnalysis" && "active"}
  //         onClick={() =>
  //           btn_onClick("주간실적 분석표", "/goods/profitTable/weeklyPerformanceAnalysis")
  //         }
  //       >
  //         주간실적 분석표
  //       </li>
  //       <li className={pathName === "annualLeave" && "active"}>
  //         <div
  //           onClick={() =>
  //             btn_onClick("근태관리", "/goods/commuting/annualLeave/annualLeaveManage")
  //           }
  //         >
  //           근태관리 <span></span>
  //         </div>
  //         <ul className="sidebar_subList">
  //           <li
  //             className={pathName2 === "annualLeaveManage" && "active"}
  //             onClick={() =>
  //               btn_onClick("근태관리", "/goods/commuting/annualLeave/annualLeaveManage")
  //             }
  //           >
  //             근태관리
  //           </li>
  //           <li
  //             className={pathName2 === "reportOutput" && "active"}
  //             onClick={() =>
  //               btn_onClick("리포트출력", "/goods/commutingReport/annualLeave/reportOutput")
  //             }
  //           >
  //             리포트출력
  //           </li>
  //           <li
  //             className={pathName2 === "annualApplicationPreparation" && "active"}
  //             onClick={() =>
  //               btn_onClick(
  //                 "연차신청작성",
  //                 "/goods/yunchaRegist/annualLeave/annualApplicationPreparation"
  //               )
  //             }
  //           >
  //             연차신청작성
  //           </li>
  //         </ul>
  //       </li>
  //     </ul>
  //   </div>
  // );
};
