import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";

const Tab7 = ({ custId }) => {
  const [pageData, setPageData] = useState(null);

  const bottomGetRemark = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getRemark", {
        params: { custId: data.custId },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setPageData({ ...result });

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );

  const bottomSaveRemark = useMutation(data =>
    axios.post("/api/work/manageGolf/bottom/saveRemark", data)
  );

  useEffect(() => {
    if (custId) bottomGetRemark.mutate({ custId: custId });
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetRemark.mutate({ custId: custId });
  }, []);

  const onChangeTextArea = (key, value) => {
    setPageData({ ...pageData, [key]: value });
  };

  const btn_cancel = () => {
    if (confirm("취소하시겠습니까")) bottomGetRemark.mutate({ custId: custId, type: "cancel" });
  };

  const btn_save = () => {
    bottomSaveRemark.mutate(pageData);
  };

  return (
    <div className="table-container mb-3 position-relative">
      <table className="table table-bordered table-responsive my-table">
        <tbody>
          <tr style={{ height: "200px" }}>
            <td className="v-align-top" colSpan={6}>
              <textarea
                rows={10}
                className="form-control my-1"
                value={pageData?.remark}
                onChange={e => onChangeTextArea("remark", e.target.value)}
              />

              <div className="d-flex align-items-center justify-content-end my-2">
                <button className="btn btn-outline-primary" onClick={btn_save}>
                  저장
                </button>
                <button className="btn btn-outline-primary ms-8px" onClick={btn_cancel}>
                  취소
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Tab7);
