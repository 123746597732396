import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";
import moment from "moment/moment";
import { decrypt } from "../../util/decrypt/decrypt";

// 실적 알림
export const Initial2 = () => {
  const [data, setData] = useState([]);
  // 실적 알림
  const initialAlertResult = useQuery(
    ["initialAlertResult"],
    async () =>
      await axios
        .get("/api/work/customer/alertResult")
        .then(res => res.data.result.filter(i => i.confirmYn !== "Y")),
    {}
  );

  const initialConfirmResult = useMutation(
    ["initialConfirmResult"],
    async data => await axios.post("/api/work/customer/confirmResult", data),
    {
      onSuccess: (data, param) => {
        setData(prev => prev.filter(item => item.yymmdd !== param.yymmdd));
      },
    }
  );

  useEffect(() => {
    if (initialAlertResult.isSuccess) {
      setData([...initialAlertResult.data]);
    }
  }, [initialAlertResult.isSuccess]);

  const displayAmnt = obj => {
    let tmp = obj.resultAmnt ?? 0;
    if (moment(obj.yymmdd, "YYYYMMDD").isAfter(moment("20090601", "YYYYMMDD"))) {
      return (tmp / 1.1).toFixed(1);
    } else {
      return tmp.toFixed(1);
    }
  };

  const onChangeCheck = yymmdd => {
    // remove idx in data array
    initialConfirmResult.mutate({ yymmdd: yymmdd });
  };

  const sumTrTag = () => {
    let column = [0, 0, 0, 0];
    data.map(i => {
      column[0] += i.exchangeCnt;
      column[1] += i.resultAmnt;
      column[2] += i.etcCnt;
      column[3] += i.resultEtc;
    });
    column[1] = (column[1] / 1.1).toFixed(1);
    column[3] = (column[3] / 1.1).toFixed(1);
    return (
      <tr>
        <td className="text-center">합계</td>
        <td className="text-center">{column[0]}</td>
        <td className="text-center">{column[1]}</td>
        <td className="text-center">{column[2]}</td>
        <td className="text-center">{column[3]}</td>
        <td className="text-center"></td>
      </tr>
    );
  };

  return (
    <div className="card card-sub ">
      <div className="filter1_container">
        <div className="filter_header_row">
          <div className="filter-title mt-10 ms-2 me-2">나의 실적</div>
        </div>
      </div>
      <div className="text-center">
        <label className="form-check-label">문의 사항 : 내선 607번</label>
      </div>
      이상 없을 시 확인항목을 체크하세요.
      <br />
      {data.length > 0
        ? `${data[0].deptName ?? ""} ${data[0].authName ?? ""} ${
            data[0].userName ?? ""
          } [${moment().year()}년 ${moment().month() + 1}월 실적]`
        : ""}
      <div className="col-left mt-10 ms-2 me-2 table-sticky h-633">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>일자</th>
              <th style={{ width: "5%" }}>건수</th>
              <th style={{ width: "5%" }}>실적(만원)</th>
              <th style={{ width: "5%" }}>기타건수</th>
              <th style={{ width: "5%" }}>기타실적</th>
              <th style={{ width: "5%" }}>확인</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((i, idx) => (
                <tr key={i.yymmdd}>
                  <td className="text-center">
                    {i.yymmdd && moment(i.yymmdd, "YYYYMMDD").format("YYYY.MM.DD")}
                  </td>
                  <td className="text-center">{i.exchangeCnt}</td>
                  <td className="text-center">{displayAmnt(i)}</td>
                  <td className="text-center">{i.etcCnt && i.etcCnt}</td>

                  <td className="text-center">{(i.resultEtc / 1.1).toFixed(1)}</td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={moment(i.yymmdd, "YYYYMMDD").isAfter(moment(i.toDate))}
                      onChange={() => onChangeCheck(i.yymmdd)}
                    />
                    확인
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  검색된 실적이 없습니다.
                </td>
              </tr>
            )}
            {data.length > 0 && sumTrTag()}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2 mb-2 me-2">
        <button className="btn btn-outline-primary ms-8px" onClick={() => window.close()}>
          닫기
        </button>
      </div>
    </div>
  );
};
