import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";

const Tab11 = ({ custId }) => {
  const [tableData, setTableData] = useState([]);

  const bottomGetTransaction = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getTransaction", {
        params: { custId: data },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setTableData(result.map(i => i));
      },
    }
  );

  useEffect(() => {
    if (custId) bottomGetTransaction.mutate(custId);
  }, []);
  useEffect(() => {
    if (custId) bottomGetTransaction.mutate(custId);
  }, [custId]);

  return (
    <div className="tab2-content-container">
      <p className="text-primary text-end">최근 6개월 이내 거래건에 대해서만 보여짐.</p>
      <div className="table-container mb-3">
        <table className="table table-bordered table-responsive my-table">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>No.</th>
              <th style={{ width: "23.33%" }}>거래일자</th>
              <th style={{ width: "23.33%" }}>회원권명</th>
              <th style={{ width: "23.33%" }}>매도/매수</th>
              <th>매매가</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.custDate}</td>
                  <td>{item.codename}</td>
                  <td className={"text-end"}>{`${item?.yangdoWon1.toLocaleString(
                    "en-US"
                  )}/${item.yangsuWon1.toLocaleString("en-US")}`}</td>
                  <td className={"text-end"}>{item?.maemae.toLocaleString("en-US")}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  검색된 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(Tab11);
