import React, { useEffect, useState } from "react";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { axios } from "../../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useGetBeneGubun } from "../../../../../api/code/useGetBeneGubun";
import { useGetBeneType } from "../../../../../api/code/useGetBeneType";

export default function PersonBenefiTab({ sawonId }) {
  const defaultURL = "";
  const [data, setData] = useState([]);

  const getBeneGubun = useGetBeneGubun();
  const getBeneType = useGetBeneType();

  const { mutate: mutate_personBenefi } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getBenefi", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        setData(result);
      },
    }
  );

  useEffect(() => {
    mutate_personBenefi(sawonId);
  }, []);
  return (
    <div className="px-20px py-15px">
      <div className={`table-container table-sticky h-183`}>
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "4%" }}>No.</th>
              <th style={{ width: "8%" }}>거래일</th>
              <th style={{ width: "8%" }}>구분</th>
              <th style={{ width: "8%" }}>회비구분</th>
              <th style={{ width: "8%" }}>내용</th>
              <th style={{ width: "8%" }}>입금액</th>
              <th style={{ width: "8%" }}>지출금액</th>
              <th style={{ width: "8%" }}>기타내용</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((beneData, index) => (
                <tr className="cursor-pointer" onClick={() => {}}>
                  <td>{index + 1}</td>
                  <td>
                    <div>{moment(beneData?.currDate).format("YYYY-MM-DD")}</div>
                  </td>
                  <td>
                    {getBeneGubun?.data?.map(data => {
                      if (beneData?.beneGubn === data.key) {
                        return <div value={data.key}>{data.value}</div>;
                      }
                    })}
                  </td>
                  <td>
                    {getBeneType?.data?.map(data => {
                      if (beneData?.gubun === data?.key) {
                        return <div value={data.key}>{data.value}</div>;
                      }
                    })}
                  </td>
                  <td>
                    <div>{beneData?.workDetail}</div>
                  </td>
                  <td>
                    <div>{beneData?.receiptWon}</div>
                  </td>
                  <td>
                    <div>{beneData?.paymentWon}</div>
                  </td>
                  <td>
                    <div>{beneData?.remark}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="cursor-pointer" onClick={() => {}}>
                <td colSpan={8}>조회된 데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
