import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SidebarApproval = ({ func_recentTap, data }) => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const pathName = location.pathname.split("/")[3];
  const pathName2 = location.pathname.split("/")[5];

  const btn_onClick = (name, url) => {
    func_recentTap({
      name: name,
      url: url,
    });
  };

  return (
    <div className="sidebar-wrapper">
      <ul>
        {data?.sub.reduce((acc, cur) => {
          if (cur.sub.length !== 0) {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 2] && "active"
                }
              >
                <div onClick={() => btn_onClick(cur.sub[0].pageName, cur.url)}>
                  {cur.pageName} <span></span>
                </div>

                <ul className="sidebar_subList">
                  {cur.sub.map(el => (
                    <li
                      className={
                        pathName2 === el.url.split("/")[el.url.split("/").length - 1] && "active"
                      }
                      onClick={() => btn_onClick(el.pageName, el.url)}
                    >
                      {el.pageName}
                    </li>
                  ))}
                </ul>
              </li>,
            ];
          } else {
            acc = [
              ...acc,
              <li
                className={
                  pathName === cur.url.split("/")[cur.url.split("/").length - 1] && "active"
                }
                onClick={() => btn_onClick(cur.pageName, cur.url)}
              >
                {cur.pageName}
              </li>,
            ];
          }

          return acc;
        }, [])}
      </ul>
    </div>
  );

  // return (
  //   <div className="sidebar-wrapper">
  //     <ul>
  //       <li
  //         className={pathName === "approvalIn" && "active"}
  //         onClick={() => btn_onClick("결재 수신함", "/approval/approvalMain/approvalIn")}
  //       >
  //         결재 수신함
  //       </li>
  //       <li
  //         className={pathName === "approvalSub" && "active"}
  //         onClick={() => btn_onClick("결재 상신함", "/approval/approvalMain/approvalSub")}
  //       >
  //         결재 상신함
  //       </li>
  //       <li
  //         className={pathName === "approvalLine" && "active"}
  //         onClick={() => btn_onClick("결재선 설정", "/approval/approvalLine/approvalLine")}
  //       >
  //         결재선 설정
  //       </li>
  //     </ul>
  //   </div>
  // );
};
