import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  daposUserManage_addData,
  daposUserManage_backupData,
  daposUserManage_createMode,
  daposUserManage_modifyData,
  searchParam_atom,
  textList_atom,
} from "../../../data/atom/codeManage/daposUserManage/daposUserManage_atom";
import moment from "moment/moment";
import { useGetDaposUserManageList } from "../../../api/code/useGetDaposUserManageList";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useVirtualizer } from "@tanstack/react-virtual";
import { decrypt } from "../../../util/decrypt/decrypt";

export const DaposUserManage = () => {
  const [searchOption, setSearchOption] = useAtom(searchParam_atom);
  const [searchData, setSearchData] = useAtom(textList_atom); // 사용자 ID가 기본값
  const [modifyData, setModifyData] = useAtom(daposUserManage_modifyData); // 서버에 보낼 수정 데이터
  const [addData, setAddData] = useAtom(daposUserManage_addData); // 서버에 보낼 추가 데이터
  const [createMode, setCreateMode] = useAtom(daposUserManage_createMode);
  const [backupData, setBackupData] = useAtom(daposUserManage_backupData);
  const targetRef = useRef(null);
  const [bottom, setBottom] = useState(null);
  const [capturePermit, setCapturePermit] = useState(decrypt("userInfo").capturePermit);
  const [captureRatio, setCaptureRatio] = useState(decrypt("userInfo").captureRatio);
  const bottomObserver = useRef(null);
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        delCheck: "N",
        keyword: "",
        workUser: "0",
        page: 1,
      });
      setSearchData([]);
      setModifyData([]);
      setAddData(null);
      setCreateMode(false);
      setBackupData([]);
    });
  }, []);

  const { data: manageListData } = useGetDaposUserManageList();
  const optionHandler = e => {
    const { name, value } = e.target;
    setSearchOption({ ...searchOption, [name]: value });
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      setSearchData([]);
      setSearchOption({ ...searchOption, page: 1 });
      search.mutate({ ...searchOption, page: 1 });
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: searchData?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  // modify
  const userOnchangeHandler = (e, data) => {
    const { name, value } = e.target;
    let dataValue = value;
    if (name.includes("inDate") || name.includes("outDate") || name.includes("passChDate")) {
      dataValue = moment(value).format("YYYY-MM-DD");
    }

    const newShowData = searchData.map(item => {
      if (item.userId === data.userId) {
        return { ...item, [name]: dataValue };
      } else {
        return item;
      }
    });
    setSearchData(newShowData);

    // modify data
    const isData = element => {
      return element.userId === data.userId;
    };

    const idx = modifyData?.findIndex(isData);
    if (idx === -1) {
      setModifyData([...modifyData, { ...data, [name]: dataValue, newCheck: false }]);
    } else {
      const newModifyData = modifyData.map(item => {
        if (item.userId === data.userId) {
          if (name.includes("passWord")) {
            return { ...item, [name]: dataValue, passWordCh: "Y" };
          } else {
            return { ...item, [name]: dataValue };
          }
        } else {
          return item;
        }
      });
      setModifyData(newModifyData);
    }
  };

  // modify - radioInput
  const userOnchangeHandlerRadio = (e, data, name) => {
    const { value } = e.target;
    let dataValue = value;
    if (name.includes("inDate") || name.includes("outDate") || name.includes("passChDate")) {
      dataValue = moment(value).format("YYYY-MM-DD");
    }
    const newShowData = searchData.map(item => {
      if (item.userId === data.userId) {
        return { ...item, [name]: dataValue };
      } else {
        return item;
      }
    });
    setSearchData(newShowData);

    // modify data
    const isData = element => {
      return element.userId === data.userId;
    };

    const idx = modifyData?.findIndex(isData);
    if (idx === -1) {
      setModifyData([...modifyData, { ...data, [name]: dataValue, newCheck: false }]);
    } else {
      const newModifyData = modifyData.map(item => {
        if (item.userId === data.userId) {
          return { ...item, [name]: dataValue };
        } else {
          return item;
        }
      });
      setModifyData(newModifyData);
    }
  };

  // add
  const addChangeHandler = e => {
    const { name, value } = e.target;
    let dataValue = value;
    if (name.includes("inDate") || name.includes("outDate") || name.includes("passChDate")) {
      dataValue = moment(value).format("YYYY-MM-DD");
    }

    if (name.includes("passWord")) {
      setAddData({ ...addData, [name]: dataValue, passWordCh: "Y" });
    } else {
      setAddData({ ...addData, [name]: dataValue });
    }
  };

  const initialUserData = {
    authority: "11",
    cardPermit: "A",
    certifyChk: "N",
    chk: "Y",
    daposFix: "N",
    dbJisaView: "N",
    dept: "1",
    dmProcess: "N",
    exchangeCheck: "N",
    golfMasterUpdate: "N",
    gtView: "N",
    inDate: null, // 입사일자
    initName: "",
    insaView: "N",
    job: "40",
    juminPermit: "N",
    letterView: "N",
    localTaxPermit: "N",
    msgReceive: "N",
    orderCustView: "N",
    outDate: null, // 퇴사일자
    pageStart: 0,
    partnerChk: "N",
    passChDate: "",
    passEncrypt: "",
    passWord: "",
    printNameChk: "N",
    remark: "",
    sangjoView: "N",
    sawonId: "",
    scanView: "N",
    secomView: "N",
    siseProcess: "1",
    taxView: "N",
    timeCheck: "N",
    timeHalt: "N",
    userId: "", // primary key
    userName: "",
    userPermit: "7C",
    viewCheck: "N",
    webConnect: "N",
    webOrder: "N",
    work1: "11",
    newCheck: true,
  };

  // API - 검색
  const search = useMutation(
    data =>
      axios.get("/api/code/daposUserManage/search", {
        params: data,
      }),
    {
      onSuccess: (res, payload) => {
        if (payload.page === 1) {
          setSearchData(res?.data?.result);
          setBackupData(res?.data?.result);
        } else {
          setSearchData([...searchData, ...res?.data?.result]);
          setBackupData([...searchData, ...res?.data?.result]);
        }
      },
    }
  );

  // API - 삭제
  const dataDelete = useMutation(data => axios.post("/api/code/daposUserManage/delete", data), {
    onSuccess: res => {
      search.mutate({ ...searchOption, page: 1 });
    },
  });

  // API - 수정/저장
  const getSave = useMutation(data => axios.post("/api/code/daposUserManage/getSave", data), {
    onSuccess: res => {
      setAddData(null);
      setModifyData([]);
      setCreateMode(false);
      setSearchData([]);
      search.mutate({ ...searchOption, page: 1 });
    },
  });

  // API - 비밀번호 업데이트
  const passwordUpdate = useMutation(
    data => axios.post("/api/code/daposUserManage/passwordUpdate", data),
    {
      onSuccess: res => {
        alert("수정되었습니다");
      },
    }
  );

  // API - 근무구분 select box
  const { data: getDeptList } = useQuery(
    ["getDeptList"],
    async () =>
      await axios.get("/api/codeMng/select/getDaposuserDeptList").then(res => {
        return res?.data?.result;
      })
  );

  // API - 부서구분 select box
  const { data: getJobList } = useQuery(
    ["getJobList"],
    async () =>
      await axios.get("/api/codeMng/select/getDaposuserJobList").then(res => {
        return res?.data?.result;
      })
  );

  // API - 작업허용 select box
  const { data: userPermitList } = useQuery(
    ["userPermitList"],
    async () =>
      await axios.get("/api/codeMng/select/getDaposUserPermitList").then(res => {
        return res?.data?.result;
      })
  );

  // API - 시세생성권한 select box
  const { data: getDaposSiseProcessList } = useQuery(
    ["getDaposSiseProcessList"],
    async () =>
      await axios.get("/api/codeMng/select/getDaposSiseProcessList").then(res => {
        return res?.data?.result;
      })
  );

  // API - 직위 select box
  const { data: authList } = useQuery(
    ["getAuth"],
    async () =>
      await axios.get("/api/codeMng/select/getAuth").then(res => {
        return res?.data?.result;
      })
  );

  // 캡쳐 전체 방지
  const capturePrevention = useMutation(
    data => axios.post("/api/code/daposUserManage/capturePrevention", data),
    {
      onSuccess: res => {
        alert(res.data.msg);
      },
    }
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      async entries => {
        if (entries[0].isIntersecting) {
          setSearchOption(prev => {
            search.mutate({ ...searchOption, page: prev.page + 1 });

            return { ...searchOption, page: prev.page + 1 };
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, [searchOption]);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  const btn_captureSetting = () => {
    // captureRatio 숫자인지 검사
    if (isNaN(captureRatio)) {
      alert("주기는 숫자만 입력해 주세요.");
      return;
    } else if (captureRatio < 1) {
      alert("주기는 0보다 큰 숫자만 입력해 주세요.");
      return;
    }

    if (window.confirm("전체 캡처방지를 저장하시겠습니까?")) {
      capturePrevention.mutate({
        captureYn: capturePermit,
        ratio: captureRatio,
      });
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">사용자 관리</div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="w-100 d-flex">
              <div className="filter1-field filter1-field1">
                <select
                  className="form-select filter1-select1"
                  name="workUser"
                  onChange={e => {
                    optionHandler(e);
                  }}
                  value={searchOption?.workUser}
                >
                  <option value="0">전체 재직자</option>
                  <option value="1">서울 본사</option>
                  <option value="2">강북 지사</option>
                  <option value="3">분당 지사</option>
                  <option value="4">대전 지사</option>
                  <option value="5">대구 지사</option>
                  <option value="6">부산 지사</option>
                  <option value="7">호남 지사</option>
                  <option value="8">동아회원권</option>
                </select>
              </div>
              <div className="filter1-field filter1-field1">
                <input
                  type="checkbox"
                  id="chk-1"
                  className="form-check-input"
                  name="checkbox-option"
                  checked={searchOption.delCheck === "Y" ? true : false}
                  readOnly
                  onClick={() => {
                    if (searchOption.delCheck === "N") {
                      setSearchOption({ ...searchOption, delCheck: "Y" });
                    } else {
                      setSearchOption({ ...searchOption, delCheck: "N" });
                    }
                  }}
                />
                <label className="filter1-label" htmlFor="chk-1">
                  퇴사자포함
                </label>
              </div>
              <div className="filter1-field filter1-field1">
                <label className="filter1-label">검색어</label>
                <input
                  type="text"
                  className="form-control filter-input filter1-input2"
                  placeholder="사용자명"
                  name="keyword"
                  onChange={e => {
                    optionHandler(e);
                  }}
                  value={searchOption?.keyword}
                />
              </div>
              <div className="filter1-field filter1-field1">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={() => {
                    setSearchOption({ ...searchOption, page: 1 });
                    setSearchData([]);
                    search.mutate({ ...searchOption, page: 1 });
                  }}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
              <div>
                {searchData?.length > 0 && (
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label m-0">
                      최종사번 : {searchData[0]?.lastSawonId}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            <div className="w-100 mb-10px">
              <div>
                <div className="d-flex justify-content-between">
                  <div className="tab1-row1-count">검색 결과: [{searchData?.length}]건 검색</div>
                  <div className="d-flex">
                    <button
                      className="btn btn-outline-primary h-25px"
                      onClick={() => {
                        setCreateMode(true);
                        setAddData(initialUserData);
                      }}
                    >
                      추가
                    </button>
                    <button
                      className="btn btn-outline-primary h-25px mx-2"
                      onClick={() => {
                        const selectDelData = searchData.filter(item => {
                          if (item.chk === "Y") {
                            return item;
                          }
                        });
                        setSearchOption({ ...searchOption, page: 1 });
                        dataDelete.mutate(selectDelData);
                      }}
                    >
                      삭제
                    </button>
                    <button
                      className="btn btn-outline-primary h-25px me-2"
                      onClick={() => {
                        setCreateMode(false);
                        setAddData(null);
                        setModifyData([]);
                        setSearchData(backupData);
                      }}
                    >
                      취소
                    </button>
                    <button
                      className="btn btn-outline-primary h-25px"
                      onClick={() => {
                        if (addData != null) {
                          if (addData.sawonId.length !== 9) {
                            alert("사원번호를 9자리까지 입력해주세요.");
                            return;
                          }
                          getSave.mutate([...modifyData, addData]);
                        } else {
                          if (modifyData.length > 0) {
                            for (var i = 0; i < modifyData.length; i++) {
                              if (modifyData[i].sawonId.length !== 9) {
                                alert("사원번호를 9자리까지 입력해주세요.");
                                return;
                              }
                            }

                            getSave.mutate(modifyData);
                          } else {
                            alert("저장할 데이터가 업습니다.");
                          }
                        }
                      }}
                    >
                      저장
                    </button>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-10 ">
                  <label className="filter1-label">전체 캡처방지</label>
                  <select
                    className="form-select filter1-select1"
                    name="workUser"
                    value={capturePermit}
                    onChange={e => {
                      setCapturePermit(e.target.value);
                    }}
                    style={{ marginRight: "10px", width: "140px" }}
                  >
                    <option value="C">미적용</option>
                    <option value="Y">허용</option>
                    <option value="N">방지</option>
                  </select>
                  <label className="filter1-label">주기(초)</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={captureRatio}
                    name="ratio"
                    onChange={e => {
                      // 숫자를 제외한 문자 제거
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      setCaptureRatio(e.target.value);
                    }}
                  />
                  &nbsp;
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary me-0"
                    onClick={btn_captureSetting}
                    style={{ backgroundColor: "#0b4da2", color: "white" }}
                  >
                    설정
                  </button>
                </div>
              </div>
            </div>
            <div className="table-container overflow-auto table-sticky h-633" ref={targetRef}>
              <table
                className="table table-bordered table-responsive my-table scrollable-table"
                style={{
                  width: "4600px",
                  marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                  marginBottom: `${
                    rowVirtualizer.getTotalSize() -
                    (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                    rowVirtualizer.getVirtualItems().length * 30
                  }px`,
                }}
              >
                <thead>
                  {/*<Text/>*/}
                  <tr>
                    <th style={{ width: "1%", zIndex: 3 }}>
                      <input
                        className="form-check-input m-auto"
                        type="checkbox"
                        checked={searchData.every(el => el.chk === "Y")}
                        onClick={e => {
                          if (searchData.every(el => el.chk === "Y")) {
                            const newSearchData = searchData.map(item => {
                              return { ...item, chk: "N" };
                            });
                            setSearchData(newSearchData);
                          } else {
                            const newSearchData = searchData.map(item => {
                              return { ...item, chk: "Y" };
                            });
                            setSearchData(newSearchData);
                          }
                        }}
                        readOnly
                      />
                    </th>
                    <th style={{ width: "1%", zIndex: 3 }}>연번</th>
                    <th style={{ width: "2%", zIndex: 3 }}>사원번호</th>
                    <th style={{ width: "2%", zIndex: 3 }}>구분</th>
                    <th style={{ width: "2%", zIndex: 3 }}>사용자명</th>
                    <th style={{ width: "3%" }}>사용자ID</th>
                    <th style={{ width: "2%" }}>암호</th>
                    <th style={{ width: "1%" }}>약칭</th>
                    <th style={{ width: "3%" }}>근무구분</th>
                    <th style={{ width: "2%" }}>부서명</th>
                    <th style={{ width: "2%" }}>작업허용</th>
                    <th style={{ width: "1.5%" }}>직위</th>
                    <th style={{ width: "2%" }}>입사일자</th>
                    <th style={{ width: "2%" }}>퇴사일자</th>
                    <th style={{ width: "3%" }}>휴대폰</th>
                    <th style={{ width: "2%" }}>암호변경일</th>
                    <th style={{ width: "2%" }}>전달사항</th>
                    <th style={{ width: "1%" }}>웹연결</th>
                    <th style={{ width: "2%" }}>웹오더접수</th>
                    <th style={{ width: "2%" }}>시세생성권한</th>
                    <th style={{ width: "2.16%" }}>DB오픈</th>
                    <th style={{ width: "3%" }}>연습장DB오픈</th>
                    <th style={{ width: "3%" }}>등기우편DB오픈</th>
                    <th style={{ width: "3%" }}>거래입력메뉴</th>
                    <th style={{ width: "3%" }}>스캔VIEW</th>
                    <th style={{ width: "3%" }}>고객등급변경대상</th>
                    <th style={{ width: "4%" }}>라벨출력시_담당이름표기</th>
                    <th style={{ width: "4%" }}>고객Trigger/DM생성권한</th>
                    <th style={{ width: "2%" }}>인사기록VIEW</th>
                    <th style={{ width: "3%" }}>자동종료대상</th>
                    <th style={{ width: "2%" }}>근태관리</th>
                    <th style={{ width: "3%" }}>지사별DB현황_VIEW</th>
                    <th style={{ width: "3%" }}>다포스자리이동허용</th>
                    <th style={{ width: "3%" }}>상조회비메뉴</th>
                    <th style={{ width: "3%" }}>주문고객정보_VIEW</th>
                    <th style={{ width: "3%" }}>세금계산서_VIEW</th>
                    <th style={{ width: "2%" }}>증명서발급</th>
                    <th style={{ width: "3%" }}>골프장정보수정</th>
                    <th style={{ width: "2%" }}>법인카드관리</th>
                    {/*<th style={{ width: "1.83%" }}>30분_Halt</th>*/}
                    <th>고객관리_주민번호조회</th>
                    <th>캡처허용</th>
                    {/*<th style={{ width: "2%" }}>지방세위임장</th>*/}
                  </tr>
                </thead>

                <tbody>
                  {/* 추가 */}
                  {createMode && (
                    <tr>
                      <td className="text-center">
                        <input
                          className="form-check-input m-auto"
                          type="checkbox"
                          value=""
                          checked={addData?.chk === "Y" ? true : false}
                          readOnly
                        />
                      </td>
                      <td className="text-center"></td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input text-center"
                          value={addData?.sawonId}
                          // style={{ width: "100px" }}
                          name="sawonId"
                          onChange={e => addChangeHandler(e)}
                          maxLength={9}
                        />
                      </td>
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData?.work1}
                          name="work1"
                          onChange={e => addChangeHandler(e)}
                        >
                          {manageListData?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={addData?.userName}
                          name="userName"
                          onChange={e => addChangeHandler(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={addData?.userId}
                          name="userId"
                          onChange={e => addChangeHandler(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="password"
                          className="form-control table-input"
                          value={addData?.passWord}
                          name="passWord"
                          onChange={e => addChangeHandler(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={addData?.initName}
                          name="initName"
                          onChange={e => addChangeHandler(e)}
                          maxLength="2"
                        />
                      </td>
                      <td className="text-center">
                        <select
                          className="form-select table-select"
                          value={addData?.dept}
                          name="dept"
                          onChange={e => addChangeHandler(e)}
                        >
                          {getDeptList?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <td className="text-center">
                        <select
                          className="form-select table-select"
                          value={addData?.job}
                          name="job"
                          onChange={e => addChangeHandler(e)}
                        >
                          {getJobList?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <td className="text-center">
                        <select
                          className="form-select table-select"
                          value={addData?.userPermit}
                          name="userPermit"
                          onChange={e => addChangeHandler(e)}
                        >
                          {userPermitList?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <td className="text-center">
                        <select
                          className="form-select table-select"
                          value={addData?.authority}
                          name="authority"
                          onChange={e => addChangeHandler(e)}
                        >
                          {authList?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-control table-input w-100px"
                          value={addData?.inDate && moment(addData?.inDate).format("YYYY-MM-DD")}
                          name="inDate"
                          onChange={e => addChangeHandler(e)}
                          max="9999-12-31"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-control table-input w-100px"
                          value={addData?.outDate && moment(addData?.outDate).format("YYYY-MM-DD")}
                          name="outDate"
                          onChange={e => addChangeHandler(e)}
                          max="9999-12-31"
                          min={addData?.inDate}
                        />
                      </td>
                      <td>
                        {/* 휴대폰 */}
                        <input
                          type="text"
                          className="form-control table-input"
                          value={addData?.remark}
                          name="remark"
                          onChange={e => addChangeHandler(e)}
                        />
                      </td>
                      <td>
                        {/* 암호변경일 */}
                        <input
                          type="date"
                          className="form-control table-input w-100px"
                          value={
                            addData?.passChDate && moment(addData?.passChDate).format("YYYY-MM-DD")
                          }
                          name="passChDate"
                          onChange={e => addChangeHandler(e)}
                          max="9999-12-31"
                        />
                      </td>
                      <td>
                        {/* 전달사항 */}
                        <select
                          className="form-select table-select"
                          value={addData?.msgReceive}
                          name="msgReceive"
                          onChange={e => addChangeHandler(e)}
                        >
                          <option value="Y">수신</option>
                          <option value="N">미수신</option>
                        </select>
                      </td>
                      <td>
                        {/* 웹 연결 */}
                        <select
                          className="form-select table-select"
                          value={addData?.webConnect}
                          name="webConnect"
                          onChange={e => addChangeHandler(e)}
                        >
                          <option value="Y">허용</option>
                          <option value="N">불가</option>
                        </select>
                      </td>
                      <td>
                        {/* 웹오더접수 */}
                        <select
                          className="form-select table-select"
                          value={addData?.webOrder}
                          name="webOrder"
                          onChange={e => addChangeHandler(e)}
                        >
                          <option value="Y">허용</option>
                          <option value="N">불가</option>
                        </select>
                      </td>
                      <td>
                        {/* 시세 생성 권한 */}
                        <select
                          className="form-select table-select"
                          value={addData?.siseProcess}
                          name="siseProcess"
                          onChange={e => addChangeHandler(e)}
                        >
                          {getDaposSiseProcessList?.map(item => {
                            return <option value={item.key}>{item.value}</option>;
                          })}
                        </select>
                      </td>
                      {/* DB 오픈 */}
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData?.viewCheck}
                          name="viewCheck"
                          onChange={e => addChangeHandler(e)}
                        >
                          <option value="Y">전체오픈</option>
                          <option value="A">본사+분당+대전</option>
                          <option value="B">대구+부산</option>
                          <option value="N">해당지사만</option>
                          <option value="P">본인할당만</option>
                        </select>
                      </td>
                      {/* 연습장 DB 오픈 */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox21"
                            value="Y"
                            name="gtView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.gtView === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox21">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox22"
                            value="N"
                            name="gtView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.gtView === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox22">
                            No
                          </label>
                        </div>
                      </td>
                      {/* 등기우편db오픈 */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox21"
                            value="Y"
                            name="letterView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.letterView === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox21">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox22"
                            value="N"
                            name="letterView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.letterView === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox22">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {/* 거래 입력 메뉴 */}
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox31"
                            value="Y"
                            name="exchangeCheck"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.exchangeCheck === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox31">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox32"
                            value="N"
                            name="exchangeCheck"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.exchangeCheck === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox32">
                            No
                          </label>
                        </div>
                      </td>
                      {/* 스캔View */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox41"
                            value="Y"
                            name="scanView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.scanView === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox41">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox42"
                            value="N"
                            name="scanView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.scanView === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox42">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {/* 고객등급변경대상 */}
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox51"
                            value="Y"
                            name="partnerChk"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.partnerChk === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox51">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox52"
                            value="N"
                            name="partnerChk"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.partnerChk === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox52">
                            No
                          </label>
                        </div>
                      </td>
                      {/* 라벨출력시 담당 이름 표기  */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox61"
                            value="Y"
                            name="printNameChk"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.printNameChk === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox61">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox62"
                            value="N"
                            name="printNameChk"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.printNameChk === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox62">
                            No
                          </label>
                        </div>
                      </td>
                      {/* 고객 trigger/dm 생성 권한 */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox71"
                            value="Y"
                            name="dmProcess"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.dmProcess === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox71">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox72"
                            value="N"
                            name="dmProcess"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.dmProcess === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox72">
                            No
                          </label>
                        </div>
                      </td>
                      {/* 인사기록 view */}
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData?.insaView}
                          name="insaView"
                          onChange={e => {
                            addChangeHandler(e);
                          }}
                        >
                          <option value="Y">전체권한</option>
                          <option value="R">읽기전용</option>
                          <option value="N">접근불가</option>
                        </select>
                      </td>
                      <td>
                        {/* 자동 종료 대상 */}
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox81"
                            value="Y"
                            name="timeCheck"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.timeCheck === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox81">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox82"
                            value="N"
                            name="timeCheck"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.timeCheck === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox82">
                            No
                          </label>
                        </div>
                      </td>
                      {/* 근태관리 */}
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData?.secomView}
                          name="secomView"
                          onChange={e => {
                            addChangeHandler(e);
                          }}
                        >
                          <option value="N">불가</option>
                          <option value="5">부서</option>
                          <option value="9">전체</option>
                        </select>
                      </td>
                      {/* 지사별 DB현황 VIEW */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox91"
                            value="Y"
                            name="dbJisaView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.dbJisaView === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox91">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox92"
                            value="N"
                            name="dbJisaView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.dbJisaView === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox92">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {/* 다포스 자리이동허용 */}
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox101"
                            value="Y"
                            name="daposFix"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.daposFix === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox101">
                            허용
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox102"
                            value="N"
                            name="daposFix"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.daposFix === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox102">
                            불가
                          </label>
                        </div>
                      </td>
                      {/* 상조회비 메뉴 */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox111"
                            value="Y"
                            name="sangjoView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.sangjoView === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox111">
                            허용
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox112"
                            value="N"
                            name="sangjoView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.sangjoView === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox112">
                            불가
                          </label>
                        </div>
                      </td>
                      {/* 주문고객정보 */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox121"
                            value="Y"
                            name="orderCustView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.orderCustView === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox121">
                            허용
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="N"
                            name="orderCustView"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.orderCustView === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox122">
                            불가
                          </label>
                        </div>
                      </td>
                      {/* 세금 계산서 VIEW */}
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData?.taxView}
                          name="taxView"
                          onChange={e => {
                            addChangeHandler(e);
                          }}
                        >
                          <option value="A">전체VIEW</option>
                          <option value="R">읽기전용</option>
                          <option value="N">불가</option>
                        </select>
                      </td>
                      <td>
                        {/* 증명서 발급 */}
                        <select
                          className="form-select table-select"
                          value={addData?.certifyChk}
                          name="certifyChk"
                          onChange={e => {
                            addChangeHandler(e);
                          }}
                        >
                          <option value="N">권한없음</option>
                          <option value="G">명의개서용</option>
                          <option value="F">전체권한</option>
                        </select>
                      </td>
                      {/* 골프장 정보 수정 */}
                      <td>
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox131"
                            value="Y"
                            name="golfMasterUpdate"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.golfMasterUpdate === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox131">
                            허용
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox132"
                            value="N"
                            name="golfMasterUpdate"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.golfMasterUpdate === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox132">
                            불가
                          </label>
                        </div>
                      </td>
                      <td>
                        {/* 법인 카드 관리 */}
                        <select
                          className="form-select table-select"
                          value={addData?.cardPermit}
                          name="cardPermit"
                          onChange={e => {
                            addChangeHandler(e);
                          }}
                        >
                          <option value="A">전체권한</option>
                          <option value="J">지사권한</option>
                          <option value="M">본인+공용</option>
                          <option value="P">공용</option>
                        </select>
                      </td>
                      {/* 30분 Halt */}
                      {/*<td>
                        <select
                          className="form-select table-select"
                          value={addData?.timeHalt}
                          name="timeHalt"
                          onChange={e => {
                            addChangeHandler(e);
                          }}
                        >
                          <option value="Y">적용</option>
                          <option value="N">미적용</option>
                        </select>
                      </td>*/}
                      <td className="p-0">
                        {/* 고객관리 주민번호 조회 */}
                        <div className="form-check form-check-inline me-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox141"
                            value="Y"
                            name="juminPermit"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.juminPermit === "Y"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox141">
                            허용
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineCheckbox142"
                            value="N"
                            name="juminPermit"
                            onClick={e => {
                              addChangeHandler(e);
                            }}
                            readOnly
                            checked={addData.juminPermit === "N"}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox142">
                            불가
                          </label>
                        </div>
                      </td>
                      {/* 지방세 위임장 */}
                      {/*<td>
                        <select
                          className="form-select table-select"
                          value={addData?.localTaxPermit}
                          name="localTaxPermit"
                          onChange={e => {
                            addChangeHandler(e);
                          }}
                        >
                          <option value="Y">허용</option>
                          <option value="N">불가</option>
                        </select>
                      </td>*/}
                    </tr>
                  )}
                  {/* 조회 - 수정 */}
                  {searchData?.length > 0
                    ? rowVirtualizer.getVirtualItems()?.map((virtualRow, index) => {
                        return (
                          <tr
                            className={searchData[virtualRow.index]?.work1 === "22" ? "bg-red" : ""}
                          >
                            <td className="text-center">
                              <input
                                className="form-check-input m-auto"
                                type="checkbox"
                                value=""
                                readOnly
                                checked={searchData[virtualRow.index]?.chk === "Y" ? true : false}
                                onClick={e => {
                                  if (searchData[virtualRow.index]?.chk === "Y") {
                                    const newShowData = searchData.map(item => {
                                      if (item.userId === searchData[virtualRow.index]?.userId) {
                                        return { ...item, chk: "N" };
                                      } else {
                                        return item;
                                      }
                                    });
                                    setSearchData(newShowData);
                                  } else {
                                    const newShowData = searchData.map(item => {
                                      if (item.userId === searchData[virtualRow.index]?.userId) {
                                        return { ...item, chk: "Y" };
                                      } else {
                                        return item;
                                      }
                                    });
                                    setSearchData(newShowData);
                                  }
                                }}
                              />
                            </td>
                            <td className="text-center">{virtualRow.index + 1}</td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input text-center"
                                value={searchData[virtualRow.index]?.sawonId}
                                style={{ width: "100px" }}
                                name="sawonId"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                readOnly={true}
                              />
                            </td>
                            <td>
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.work1}
                                name="work1"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              >
                                {manageListData?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={searchData[virtualRow.index]?.userName}
                                name="userName"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={searchData[virtualRow.index]?.userId}
                                name="userId"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              />
                            </td>
                            <td>
                              {/*<input
                                type="password"
                                className="form-control table-input"
                                value={searchData[virtualRow.index]?.passWord}
                                name="passWord"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              />*/}
                              <button
                                className="btn btn-outline-primary"
                                onClick={() => {
                                  // 텍스트 입력받는 alert
                                  const passWord = prompt("변경할 비밀번호를 입력해주세요.");
                                  if (passWord && passWord.length < 4) {
                                    alert("비밀번호는 4자리 이상 입력해 주세요");
                                    return;
                                  } else {
                                    passwordUpdate.mutate({
                                      password: passWord,
                                      userId: searchData[virtualRow.index]?.userId,
                                    });
                                  }
                                }}
                              >
                                비밀번호수정
                              </button>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={searchData[virtualRow.index]?.initName}
                                name="initName"
                                maxLength="2"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              />
                            </td>
                            <td className="text-center">
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.dept}
                                name="dept"
                                // onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                disabled={true}
                              >
                                {getDeptList?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                              </select>
                            </td>
                            <td className="text-center">
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.job}
                                name="job"
                                // onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                disabled={true}
                              >
                                {getJobList?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                              </select>
                            </td>
                            <td className="text-center">
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.userPermit}
                                name="userPermit"
                                // onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                disabled={true}
                              >
                                {userPermitList?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                              </select>
                            </td>
                            <td className="text-center">
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.authority}
                                name="authority"
                                // onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                disabled={true}
                              >
                                {authList?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="date"
                                className="form-control table-input w-100px"
                                value={
                                  searchData[virtualRow.index]?.inDate &&
                                  moment(searchData[virtualRow.index]?.inDate).format("YYYY-MM-DD")
                                }
                                name="inDate"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                max="9999-12-31"
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                className="form-control table-input w-100px"
                                value={
                                  searchData[virtualRow.index]?.outDate &&
                                  moment(searchData[virtualRow.index]?.outDate).format("YYYY-MM-DD")
                                }
                                name="outDate"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                max="9999-12-31"
                              />
                            </td>
                            <td>
                              {/* 휴대폰 */}
                              <input
                                type="text"
                                className="form-control table-input"
                                value={searchData[virtualRow.index]?.remark}
                                name="remark"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              />
                            </td>
                            <td>
                              {/* 암호변경일 */}
                              <input
                                type="date"
                                className="form-control table-input w-100px"
                                value={
                                  searchData[virtualRow.index]?.passChDate &&
                                  moment(searchData[virtualRow.index]?.passChDate).format(
                                    "YYYY-MM-DD"
                                  )
                                }
                                name="passChDate"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                                max="9999-12-31"
                              />
                            </td>
                            <td>
                              {/* 전달사항 */}
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.msgReceive}
                                name="msgReceive"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              >
                                <option value="Y">수신</option>
                                <option value="N">미수신</option>
                              </select>
                            </td>
                            <td>
                              {/* 웹 연결 */}
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.webConnect}
                                name="webConnect"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              >
                                <option value="Y">허용</option>
                                <option value="N">불가</option>
                              </select>
                            </td>
                            <td>
                              {/* 웹오더접수 */}
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.webOrder}
                                name="webOrder"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              >
                                <option value="Y">허용</option>
                                <option value="N">불가</option>
                              </select>
                            </td>
                            <td>
                              {/* 시세 생성 권한 */}
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.siseProcess}
                                name="siseProcess"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              >
                                {getDaposSiseProcessList?.map(item => {
                                  return <option value={item.key}>{item.value}</option>;
                                })}
                              </select>
                            </td>
                            {/* DB 오픈 */}
                            <td>
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.viewCheck}
                                name="viewCheck"
                                onChange={e => userOnchangeHandler(e, searchData[virtualRow.index])}
                              >
                                <option value="Y">전체오픈</option>
                                <option value="A">본사+분당+대전</option>
                                <option value="B">대구+부산</option>
                                <option value="N">해당지사만</option>
                                <option value="P">본인할당만</option>
                              </select>
                            </td>
                            {/* 연습장 DB 오픈 */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox21"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "gtView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].gtView === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox21">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox22"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "gtView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].gtView === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox22">
                                  No
                                </label>
                              </div>
                            </td>
                            {/* 등기우편db오픈 */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox21"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "letterView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].letterView === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox21">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox22"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "letterView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].letterView === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox22">
                                  No
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* 거래 입력 메뉴 */}
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox31"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "exchangeCheck"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].exchangeCheck === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox31">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox32"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "exchangeCheck"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].exchangeCheck === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox32">
                                  No
                                </label>
                              </div>
                            </td>
                            {/* 스캔View */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox41"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "scanView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].scanView === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox41">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox42"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "scanView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].scanView === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox42">
                                  No
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* 고객등급변경대상 */}
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox51"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "partnerChk"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].partnerChk === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox51">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox52"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "partnerChk"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].partnerChk === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox52">
                                  No
                                </label>
                              </div>
                            </td>
                            {/* 라벨출력시 담당 이름 표기  */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox61"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "printNameChk"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].printNameChk === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox61">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox62"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "printNameChk"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].printNameChk === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox62">
                                  No
                                </label>
                              </div>
                            </td>
                            {/* 고객 trigger/dm 생성 권한 */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox71"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "dmProcess"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].dmProcess === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox71">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox72"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "dmProcess"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].dmProcess === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox72">
                                  No
                                </label>
                              </div>
                            </td>
                            {/* 인사기록 view */}
                            <td>
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.insaView}
                                name="insaView"
                                onChange={e => {
                                  userOnchangeHandler(e, searchData[virtualRow.index]);
                                }}
                              >
                                <option value="Y">전체권한</option>
                                <option value="R">읽기전용</option>
                                <option value="N">접근불가</option>
                              </select>
                            </td>
                            <td>
                              {/* 자동 종료 대상 */}
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox81"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "timeCheck"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].timeCheck === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox81">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox82"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "timeCheck"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].timeCheck === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox82">
                                  No
                                </label>
                              </div>
                            </td>
                            {/* 근태관리 */}
                            <td>
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.secomView}
                                name="secomView"
                                onChange={e => {
                                  userOnchangeHandler(e, searchData[virtualRow.index]);
                                }}
                              >
                                <option value="N">불가</option>
                                <option value="5">부서</option>
                                <option value="9">전체</option>
                              </select>
                            </td>
                            {/* 지사별 DB현황 VIEW */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox91"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "dbJisaView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].dbJisaView === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox91">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox92"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "dbJisaView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].dbJisaView === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox92">
                                  No
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* 다포스 자리이동허용 */}
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox101"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "daposFix"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].daposFix === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox101">
                                  허용
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox102"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "daposFix"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].daposFix === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox102">
                                  불가
                                </label>
                              </div>
                            </td>
                            {/* 상조회비 메뉴 */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox111"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "sangjoView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].sangjoView === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox111">
                                  허용
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox112"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "sangjoView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].sangjoView === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox112">
                                  불가
                                </label>
                              </div>
                            </td>
                            {/* 주문고객정보 */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox121"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "orderCustView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].orderCustView === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox121">
                                  허용
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "orderCustView"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].orderCustView === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox122">
                                  불가
                                </label>
                              </div>
                            </td>
                            {/* 세금 계산서 VIEW */}
                            <td>
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.taxView}
                                name="taxView"
                                onChange={e => {
                                  userOnchangeHandler(e, searchData[virtualRow.index]);
                                }}
                              >
                                <option value="A">전체VIEW</option>
                                <option value="R">읽기전용</option>
                                <option value="N">불가</option>
                              </select>
                            </td>
                            <td>
                              {/* 증명서 발급 */}
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.certifyChk}
                                name="certifyChk"
                                onChange={e => {
                                  userOnchangeHandler(e, searchData[virtualRow.index]);
                                }}
                              >
                                <option value="N">권한없음</option>
                                <option value="G">명의개서용</option>
                                <option value="F">전체권한</option>
                              </select>
                            </td>
                            {/* 골프장 정보 수정 */}
                            <td>
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox131"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "golfMasterUpdate"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].golfMasterUpdate === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox131">
                                  허용
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox132"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "golfMasterUpdate"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].golfMasterUpdate === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox132">
                                  불가
                                </label>
                              </div>
                            </td>
                            <td>
                              {/* 법인 카드 관리 */}
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.cardPermit}
                                name="cardPermit"
                                onChange={e => {
                                  userOnchangeHandler(e, searchData[virtualRow.index]);
                                }}
                              >
                                <option value="A">전체권한</option>
                                <option value="J">지사권한</option>
                                <option value="M">본인+공용</option>
                                <option value="P">공용</option>
                              </select>
                            </td>
                            {/* 30분 Halt */}
                            {/*<td>
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.timeHalt}
                                name="timeHalt"
                                onChange={e => {
                                  userOnchangeHandler(e, searchData[virtualRow.index]);
                                }}
                              >
                                <option value="Y">적용</option>
                                <option value="N">미적용</option>
                              </select>
                            </td>*/}
                            <td className="p-0">
                              {/* 고객관리 주민번호 조회 */}
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox141"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "juminPermit"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].juminPermit === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox141">
                                  허용
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox142"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "juminPermit"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].juminPermit === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox142">
                                  불가
                                </label>
                              </div>
                            </td>
                            <td className="p-0">
                              {/* 고객관리 주민번호 조회 */}
                              <div className="form-check form-check-inline me-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox141"
                                  value="Y"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "capturePermit"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].capturePermit === "Y"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox141">
                                  허용
                                </label>
                              </div>
                              <div className="form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineCheckbox142"
                                  value="N"
                                  onClick={e => {
                                    userOnchangeHandlerRadio(
                                      e,
                                      searchData[virtualRow.index],
                                      "capturePermit"
                                    );
                                  }}
                                  readOnly
                                  checked={searchData[virtualRow.index].capturePermit === "N"}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox142">
                                  불가
                                </label>
                              </div>
                            </td>
                            {/* 지방세 위임장 */}
                            {/*<td>
                              <select
                                className="form-select table-select"
                                value={searchData[virtualRow.index]?.localTaxPermit}
                                name="localTaxPermit"
                                onChange={e => {
                                  userOnchangeHandler(e, searchData[virtualRow.index]);
                                }}
                              >
                                <option value="Y">허용</option>
                                <option value="N">불가</option>
                              </select>
                            </td>*/}
                          </tr>
                        );
                      })
                    : !createMode && (
                        <tr>
                          <td colSpan={42}>검색된 결과가 없습니다.</td>
                        </tr>
                      )}
                  {searchData.length > 0 && <div ref={setBottom} />}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
