import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useEffect, useState } from "react";

export default function ApprovalLineManage({
  member,
  setMember,
  certApprovalLineList,
  setCertApprovalLineList,
  annualApprovalLineList,
  setAnnualApprovalLineList,
  paymentApprovalLineList,
  setPaymentApprovalLineList,
}) {
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(1);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const initialForm = {
    absenceYn: "",
    confmOrdr: "",
    confmer: "",
    drftType: "",
    sawonId: "",
    subCheck: "1",
  };

  // 리스트 재배열 함수
  const modifyListF = (originList, compareList) => {
    const modifyDocData = originList?.map(data => {
      const tempList = compareList?.filter(certData => {
        if (data?.confmOrdr === certData?.confmOrdr && data?.subCheck === certData?.subCheck) {
          return certData;
        }
      });
      if (tempList.length > 0) {
        return tempList[0];
      } else {
        return data;
      }
    });

    return modifyDocData;
  };

  const modiftyApproveLine = (data, e) => {
    if (data.drftType === "1") {
      const modeCert = certApprovalLineList.map(item => {
        if (item.confmOrdr === data.confmOrdr) {
          return { ...data, confmer: e.target.value };
        } else {
          return item;
        }
      });
      setCertApprovalLineList(modeCert);
    } else if (data.drftType === "2") {
      const modeCert = annualApprovalLineList.map(item => {
        if (item.confmOrdr === data.confmOrdr) {
          return { ...data, confmer: e.target.value };
        } else {
          return item;
        }
      });
      setAnnualApprovalLineList(modeCert);
    } else if (data.drftType === "3") {
      const modeCert = paymentApprovalLineList.map(item => {
        if (item.confmOrdr === data.confmOrdr && item.subCheck === data.subCheck) {
          return { ...data, confmer: e.target.value };
        } else {
          return item;
        }
      });
      setPaymentApprovalLineList(modeCert);
    }
  };

  // API - 결재선 조회
  const getApprovalLine = useMutation(
    data =>
      axios.get("/api/approval/approvalLine/getApprovalLine", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        // 증명서 list form 설정
        const docLine = res?.data?.result?.DocLine;
        const annualLine = res?.data?.result?.YunchaLine;
        const paymentLine = res?.data?.result?.ProfitLine;

        if (docLine) {
          const docTemp = modifyListF(certApprovalLineList, docLine);
          setCertApprovalLineList(docTemp);
        }

        if (annualLine) {
          const annualTemp = modifyListF(annualApprovalLineList, annualLine);
          setAnnualApprovalLineList(annualTemp);
        }

        if (paymentLine) {
          const paymentTemp = modifyListF(paymentApprovalLineList, paymentLine);
          setPaymentApprovalLineList(paymentTemp);
        }
      },
    }
  );

  // API - 결재선 사원 셀렉트박스
  const { data: getSawonListData } = useQuery(
    ["getWorkSawonList"],
    async () =>
      await axios.get("/api/approval/approvalLine/getWorkSawonList").then(res => {
        return res?.data?.result;
      })
  );

  // API - 결재선 지정
  const saveApprovalLine = useMutation(
    data => axios.post("/api/approval/approvalLine/saveApprovalLine", data),
    {
      onSuccess: res => {
        getApprovalLine.mutate(member);
      },
    }
  );

  const initApproveLine = () => {
    const certTemp = Array(3)
      .fill(0)
      .map((_, _idx) => {
        return { ...initialForm, confmOrdr: (_idx + 2).toString(), drftType: "1", sawonId: member };
      });
    setCertApprovalLineList(certTemp);
    const annualTemp = Array(3)
      .fill(0)
      .map((_, _idx) => {
        return { ...initialForm, confmOrdr: (_idx + 2).toString(), drftType: "2", sawonId: member };
      });
    setAnnualApprovalLineList(annualTemp);
    const paymentTemp = Array(3)
      .fill(0)
      .map((_, _idx) => {
        return { ...initialForm, confmOrdr: (_idx + 2).toString(), drftType: "3", sawonId: member };
      });

    setPaymentApprovalLineList([
      ...paymentTemp,
      {
        absenceYn: "",
        confmOrdr: "3",
        confmer: "",
        drftType: "3",
        sawonId: member,
        subCheck: "2",
      },
    ]);
  };

  useEffect(() => {
    if (member === "") {
      initApproveLine();
    }
  }, []);

  return (
    <>
      {/* <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-box1">
            <p>결재선이 변경되었습니다.</p>
            <div className="d-flex align-items-center justify-content-center">
              <button className="btn btn-outline-primary" onClick={handleClose}>
                확인
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <div className="row">
        <div className="col-6">
          <div className="card card-main">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-start">
                <table className="table table-bordered table-responsive my-table1 w-50 m-0">
                  <tbody>
                    <tr>
                      <th style={{ width: "30%" }}>상신자</th>
                      <td>
                        <select
                          className="form-select table-select"
                          value={member}
                          onChange={e => {
                            setMember(e.target.value);
                          }}
                        >
                          <option value=""></option>
                          {getSawonListData?.map(item => {
                            return <option value={item.sawonId}>{item.kname}</option>;
                          })}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  className="btn btn-outline-primary ms-2"
                  onClick={() => {
                    initApproveLine();
                    getApprovalLine.mutate(member);
                  }}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
              {/* 증명서 발급 결재선 */}
              <table className="table table-bordered table-responsive my-table1 mt-10px">
                <tbody>
                  <tr>
                    <th style={{ width: "200px" }}>구분</th>
                    {certApprovalLineList?.map((data, _idx) => {
                      if (_idx === certApprovalLineList.length - 1) {
                        return <th>최종결재</th>;
                      } else {
                        return <th>결재자{_idx + 1}</th>;
                      }
                    })}
                  </tr>
                  <tr>
                    <td className="text-center">증명서발급</td>
                    {certApprovalLineList?.map((data, _idx) => {
                      return (
                        <td>
                          <select
                            className="form-select table-select"
                            value={data.confmer}
                            onChange={e => {
                              modiftyApproveLine(data, e);
                            }}
                          >
                            <option value=""></option>
                            {getSawonListData?.map(item => {
                              return <option value={item.sawonId}>{item.kname}</option>;
                            })}
                          </select>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
              {/* 연차관리 결재선 */}
              <table className="table table-bordered table-responsive my-table1 mt-10px">
                <tbody>
                  <tr>
                    <th style={{ width: "200px" }}>구분</th>
                    {annualApprovalLineList?.map((data, _idx) => {
                      if (_idx === annualApprovalLineList.length - 1) {
                        return <th>최종결재</th>;
                      } else {
                        return <th>결재자{_idx + 1}</th>;
                      }
                    })}
                  </tr>
                  <tr>
                    <td className="text-center">연차작성</td>
                    {annualApprovalLineList?.map((data, _idx) => {
                      return (
                        <td>
                          <select
                            className="form-select table-select"
                            value={data.confmer}
                            onChange={e => {
                              modiftyApproveLine(data, e);
                            }}
                          >
                            <option value=""></option>
                            {getSawonListData?.map(item => {
                              return <option value={item.sawonId}>{item.kname}</option>;
                            })}
                          </select>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered table-responsive my-table1 mt-10px">
                <tbody>
                  <tr>
                    <th style={{ width: "200px" }}>구분</th>
                    {paymentApprovalLineList?.map((data, _idx) => {
                      if (data?.subCheck === "1") {
                        if (_idx === paymentApprovalLineList?.length - 1) {
                          return <th>최종결재</th>;
                        } else {
                          return <th>결재자{_idx + 1}</th>;
                        }
                      }
                    })}
                  </tr>
                  <tr>
                    <td className="text-center" rowSpan={2}>
                      회원권 거래대금 입/출금
                    </td>
                    {paymentApprovalLineList?.map(data => {
                      if (data?.subCheck === "1") {
                        return (
                          <td>
                            <select
                              className="form-select table-select"
                              value={data.confmer}
                              onChange={e => {
                                modiftyApproveLine(data, e);
                              }}
                            >
                              <option value=""></option>
                              {getSawonListData?.map(item => {
                                return <option value={item.sawonId}>{item.kname}</option>;
                              })}
                            </select>
                          </td>
                        );
                      }
                    })}
                  </tr>
                  <tr>
                    {/* 결재대금 :subcheck2 */}
                    <td></td>
                    {paymentApprovalLineList?.map(data => {
                      if (data?.subCheck === "2") {
                        return (
                          <td>
                            <select
                              className="form-select table-select"
                              onChange={e => {
                                modiftyApproveLine(data, e);
                              }}
                              value={data.confmer}
                            >
                              <option value=""></option>;
                              {getSawonListData?.map(item => {
                                return <option value={item.sawonId}>{item.kname}</option>;
                              })}
                            </select>
                          </td>
                        );
                      }
                    })}
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div className="d-flex align-items-center justify-content-end">
                {/* <button className="btn btn-outline-primary">추가</button> */}
                <button
                  className="btn btn-outline-primary ms-2"
                  onClick={() => {
                    if (member === "") {
                      alert("결재선을 지정해주세요.");
                      return;
                    }
                    saveApprovalLine.mutate([
                      ...certApprovalLineList,
                      ...annualApprovalLineList,
                      ...paymentApprovalLineList,
                    ]);
                  }}
                >
                  설정
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
