import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useAtom } from "jotai/index";
import { documentManageSearchParam_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/documentManage_atom";

const SearchPanel = props => {
  const { refetchFromFirstPage, etccodeDw } = props;
  const [searchParam, setSearchParam] = useAtom(documentManageSearchParam_atom);
  const queryClient = useQueryClient();
  const daesangJisa = queryClient.getQueryData(["getDaesangJisa"]);

  const onChangeEvent = (key, value) => {
    if (key === "jongmok1")
      setSearchParam({ ...searchParam, [key]: value, jongmok2: etccodeDw[value][0].key });
    else setSearchParam({ ...searchParam, [key]: value });
  };

  const btn_jongmokInit = () => {
    setSearchParam(prev => ({ ...prev, jongmok2: "" }));
  };

  const btn_search = () => {
    refetchFromFirstPage();
  };

  return (
    <div className="filter_fields_container">
      <div className="filter_fields_row">
        <div
          className="w-100 d-flex align-items-start justify-content-between px-20"
          onKeyDown={e => {
            if (e.key === "Enter") {
              btn_search();
            }
          }}
        >
          <div className="filter1-field filter1-field1 ps-0">
            <label className="filter1-label">검색년도</label>
            <select
              className="form-select filter1-select1"
              value={searchParam.year}
              onChange={e => onChangeEvent("year", e.target.value)}
            >
              <option value="1">2011년 이전</option>
              <option value="3">2011~2013년</option>
              <option value="4">2014~2016년</option>
              <option value="2">2017년 이후</option>
            </select>
          </div>
          <div className="filter1-field filter1-field2 ps-0">
            <label className="filter1-label">대상지사</label>
            <select
              className="form-select filter1-select1"
              value={searchParam.jisa}
              onChange={e => onChangeEvent("jisa", e.target.value)}
            >
              {daesangJisa?.map((i, idx) => (
                <option value={i.key} key={idx}>
                  {i.value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter1-field filter1-field2 ps-0">
            <label className="filter1-label">종목</label>
            <select
              className="form-select filter1-select1 w-100px"
              value={searchParam.jongmok1}
              onChange={e => onChangeEvent("jongmok1", e.target.value)}
            >
              <option value="AA">전체</option>
              <option value="GM">골프</option>
              <option value="CM">콘도</option>
              <option value="SM">헬스</option>
            </select>
            <select
              className="form-select filter1-select1 ms-1"
              value={searchParam.jongmok2}
              onChange={e => onChangeEvent("jongmok2", e.target.value)}
            >
              <option value={""}></option>
              {etccodeDw[searchParam.jongmok1]?.map((i, idx) => (
                <option value={i.key} key={idx}>
                  {i.value}
                </option>
              ))}
            </select>
          </div>
          <button
            className="btn btn-outline-primary btn-filter-outline-primary m-0"
            onClick={btn_jongmokInit}
          >
            종목초기화
          </button>
          <div className="d-flex flex-column">
            <div className="filter1-field filter1-field1 ps-0">
              <label className="filter1-label">이름</label>
              <input
                type="text"
                className="form-control filter-input filter1-input2"
                value={searchParam.keyword}
                onChange={e => onChangeEvent("keyword", e.target.value)}
              />
            </div>
            <small className="form-text text-help text-primary">
              거래번호, 회사명, 회원증No. 포함
            </small>
          </div>
          <div className="d-flex align-items-center">
            <div className="filter1-field filter1-field2 p-0">
              <label className="filter1-label ms-0">거래기간</label>
              <input
                type="date"
                className="form-control filter-input filter1-input2"
                value={searchParam?.fromDate}
                onChange={e => onChangeEvent("fromDate", e.target.value)}
                max="9999-12-31"
              />
            </div>
            <div className="txt_daterange">~</div>
            <div className="filter1-field filter1-field3 px-0">
              <input
                type="date"
                className="form-control filter-input filter1-input2"
                value={searchParam?.toDate}
                onChange={e => onChangeEvent("toDate", e.target.value)}
                max="9999-12-31"
              />
            </div>
          </div>
          <button
            className="btn btn-outline-primary btn-filter-outline-primary m-0"
            onClick={btn_search}
          >
            <img
              className="me-2"
              src={process.env.PUBLIC_URL + "/images/search_icon.png"}
              alt=""
            />
            검색
          </button>
        </div>
      </div>
      <div className="filter_fields_row">
        <div className="w-50 d-flex align-items-center justify-content-end"></div>
        <div className="w-50 d-flex align-items-center justify-content-start"></div>
      </div>
    </div>
  );
};

export default React.memo(SearchPanel);
