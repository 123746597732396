import React from "react";
import Modal from "react-bootstrap/Modal";

const EmpModal = props => {
  const {
    show,
    onHide,
    onShow,
    width,
    minWidth,
    title,
    children,
    strClass,
    size,
    footer,
    header_buttons,
    title_class,
  } = props;

  return (
    <Modal
      show={show}
      className={strClass}
      onHide={() => {
        if (onHide) onHide();
      }}
      onShow={() => {
        if (onShow) onShow();
      }}
      centered
      size={size}
    >
      <Modal.Header closeButton>
        <Modal.Title className={title_class}>{title}</Modal.Title>
        {typeof header_buttons != "undefined" && <React.Fragment>{header_buttons}</React.Fragment>}
      </Modal.Header>
      <Modal.Body
        style={{
          width: width,
          minWidth: minWidth,
        }}
      >
        {children}
      </Modal.Body>
      {typeof footer != "undefined" && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};
export default EmpModal;
