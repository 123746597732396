import { axios } from "../../../../util/axios/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { weborderList_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { useGetPartner } from "../../../../api/code/useGetPartner";
import React, { useState } from "react";
import { WebOrderModal } from "./modal/WebOrderModal";
import { WebOrderModal2 } from "./modal/WebOrderModal2";

export const WebOrder = () => {
  const [list, setList] = useAtom(weborderList_atom);
  const getPartner = useGetPartner(); // 담당딜러

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalData2, setModalData2] = useState([]);
  const [procIdx, setProcIdx] = useState(-1); // 처리할 인덱스

  // 고객관리-웹오더 조회
  const selectWebOrder = useQuery(
    ["bottomSelectWebOrder"],
    async () => await axios.get("/api/work/customer/bottom/selectWebOrder"),
    {
      onSuccess: res => {
        setList(res.data.result);
      },
      refetchInterval: 1000 * 60 * 2, // 2분마다 재조회
    }
  );

  // 고객관리-웹오더 딜러 조회
  const selectWebOrderPartner = useMutation(
    ["bottomSelectWebOrderPartner"],
    async data =>
      await axios.get("/api/work/customer/bottom/selectWebOrderPartner", { params: data }),
    {
      onSuccess: res => {
        if (res.data.result) {
          setModalShow2(true);
          setModalData2(res.data.result);
        }
      },
      enabled: false,
    }
  );

  // 고객관리-웹오더 할당처리
  const procWebOrderPartner = useMutation(
    ["bottomProcWebOrderPartner"],
    async data => await axios.post("/api/work/customer/bottom/procWebOrderPartner", data),
    {
      onSuccess: res => {
        selectWebOrder.refetch();
        alert("처리되었습니다.");
      },
      enabled: false,
    }
  );

  // onProc of modal2
  const onProc = param => {
    if (getPartner.data.find(el => el.key === param.PARTNER) === undefined) {
      alert("없거나 삭제된 담당딜러 입니다.");
    } else {
      setModalShow2(false);
      setList(prev =>
        prev.map((i, idx) => {
          if (idx === procIdx) return { ...i, category: param.PARTNER };
          else return i;
        })
      );
    }
  };

  return (
    <>
      <WebOrderModal data={modalData} show={modalShow} onHide={() => setModalShow(false)} />
      <WebOrderModal2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        data={modalData2}
        onProc={onProc}
      />
      <div className="tab2-content-container">
        <div className="table-container table-sticky h-443 mt-10">
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th colSpan={2}>미접수 : {list.length ?? 0}건</th>
                <th colSpan={9}>
                  ▶1.담당딜러 검색 후 타지사 관할 or 기존고객일경우 배정양보하기 2. 접수된 웹오더는
                  내역을 주문관리에 반드시 입력할 것 ◀
                </th>
                <th>
                  <button
                    className="btn btn-primary m-auto"
                    onClick={() =>
                      procWebOrderPartner.mutate(
                        list
                          .filter(i => {
                            if (i.category === "") return false;
                            else return true;
                          })
                          .map(i => ({ uid: i.uid, partner: i.category }))
                      )
                    }
                  >
                    할당처리
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-primary m-auto"
                    onClick={() => selectWebOrder.refetch()}
                  >
                    새조회
                  </button>
                </th>
              </tr>
              <tr>
                <th style={{ width: "2.27%" }}>No.</th>
                <th style={{ width: "14.5%" }}>신청일시</th>
                <th style={{ width: "6.81%" }} colSpan={2}>
                  구분
                </th>
                <th style={{ width: "17.61%" }} colSpan={2}>
                  회원권명
                </th>
                <th style={{ width: "6.25%" }}>주문자명</th>
                <th style={{ width: "6.25%" }}>직장명</th>
                <th style={{ width: "14.34%" }}>희망가격</th>
                <th style={{ width: "14.34%" }}>관할지사</th>
                <th style={{ width: "9.65%" }}>배정딜러</th>
                <th style={{ width: "8.352%" }}>담당검색</th>
                <th style={{ width: "8.352%" }}>주문자정보</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map((el, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{el.wsdate}</td>
                    <td>{el.option6}</td>
                    <td>{el.option5}</td>
                    <td style={{ width: "3.4%" }}>{el.option4}</td>
                    <td>{el.option10}</td>
                    <td>{el.name}</td>
                    <td>{el.subject}</td>
                    <td>{Number(el.option7).toLocaleString()}</td>
                    <td>{el.jisaProcess}</td>
                    <td>
                      <select
                        className="form-select"
                        value={el.category}
                        onChange={e =>
                          setList(prev =>
                            prev.map((el2, idx2) => {
                              if (idx2 === idx) return { ...el2, category: e.target.value };
                              return el2;
                            })
                          )
                        }
                      >
                        <option value="">담당자선택</option>
                        {getPartner.data &&
                          getPartner.data.map((i, idx) => <option value={i.key}>{i.value}</option>)}
                      </select>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary m-auto"
                        onClick={() => {
                          selectWebOrderPartner.mutate({
                            phone: el.phone,
                            email: el.email,
                          });
                          setProcIdx(idx);
                        }}
                      >
                        담당검색
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary m-auto"
                        onClick={() => {
                          setModalData(el);
                          setModalShow(true);
                        }}
                      >
                        정보조회
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={13}>조회 내용이 없습니다</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
