import React, { useEffect } from "react";
import {
  unregisteredEditedRowList_atom,
  unregisteredSportsMemberList_atom,
} from "../../../../data/atom/dataManage/sportsPrice/sportsPriceRegisterModal_atom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { axios } from "../../../../util/axios/axios";
import Modal from "react-bootstrap/Modal";
import ModalRegisterRow from "./ModalRegisterRow";
import moment from "moment";

const modalRegister = props => {
  const [unregisteredSportsMemberList, setUnregisteredSportsMemberList] = useAtom(
    unregisteredSportsMemberList_atom
  );
  const [unregisteredEditedRowList, setUnregisteredEditedRowList] = useAtom(
    unregisteredEditedRowList_atom
  );

  const searchUnregisteredSportsMember = useMutation(
    async data => await axios.get("/api/data/sportMember/getUnMemberShip", { params: data }),
    {
      onSuccess: res => {
        const list = [];
        res.data.result?.map(el => {
          list.push({
            ...el,
            custId: el.value1,
            gubun11: "1",
            gubun22: "2",
            sise11: "0",
            sise22: "0",
            siseDate: moment().format("YYYY-MM-DD 00:00:00"),
            viewCheck: "N",
          });
        });
        setUnregisteredSportsMemberList(list);
      },
      onError: () => {
        props.onHide();
      },
    }
  );

  useEffect(() => {
    if (props.show) {
      searchUnregisteredSportsMember.mutate();
    }
  }, [props.show]);

  const clientQuery = useQueryClient();
  const registerSportsMemberList = useMutation(
    data => axios.post("/api/data/sportMember/saveUnMemberShip", data),
    {
      onSuccess: res => {
        clientQuery.invalidateQueries(["getSportMemberadmin"]);
      },
    }
  );

  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      const registerList = unregisteredSportsMemberList.filter(el => el.gubun22 === "1");
      if (registerList.length === 0) {
        alert("저장 여부가 체크된 항목이 없습니다.");
        return;
      }
      registerSportsMemberList.mutate(registerList);
      props.onHide();
      setUnregisteredSportsMemberList([]);
      setUnregisteredEditedRowList([]);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        size={"lg"}
        centered
        onHide={() => {
          props.onHide();
          setUnregisteredSportsMemberList([]);
          setUnregisteredEditedRowList([]);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>미등록 회원종목</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form">
            <div className="table-container table-sticky mt-2 h-633">
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "7.14%" }}>No</th>
                    <th style={{ width: "8%" }}>code</th>
                    <th style={{ width: "30%" }}>회원권종목</th>
                    <th style={{ width: "7%" }}>구분</th>
                    <th style={{ width: "24%" }}>시세</th>
                    <th style={{ width: "7%" }}>
                      저장
                      <br />
                      여부
                    </th>
                    <th style={{ width: "7%" }}>
                      인터넷
                      <br />
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {unregisteredSportsMemberList?.length !== 0 ? (
                    unregisteredSportsMemberList?.map((el, idx) => {
                      return <ModalRegisterRow key={idx} data={el} index={idx} />;
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        검색된 결과가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex-center-end pt-3">
              <button className="btn btn-outline-primary" onClick={btnSave}>
                저장
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(modalRegister);
