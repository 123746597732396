import { atom } from "jotai";

export const PersonalRecordTab_modifyData = atom({
  area: "",
  armyClass: "",
  bloodType: "",
  classify: "",
  color: "",
  endClass: "",
  endDate: "",
  etcAccount: "",
  etcBank: "",
  etcName: "",
  etcRemark: "",
  eyeL: "",
  eyeR: "",
  height: 0,
  hobby: "",
  modDate: "",
  modId: "",
  partClass: "",
  payAccount: "",
  payBank: "",
  payName: "",
  rankClass: "",
  religion: "",
  sawonId: "",
  special: "",
  startDate: "",
  userid: "",
  weight: 0,
});

export const PersonalRecordTab_backupData = atom([]);
