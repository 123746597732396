import { atom } from "jotai";
import moment from "moment";

export const analysisTrading_searchDataInit_atom = {
  backDate: moment().add(20, "days").format("YYYY-MM-DD"),
  // backDate: "2023-01-30",
  frontDate: moment().format("YYYY-MM-DD"),
  // frontDate: "2023-01-01",
  gubun: "99",
  jisa: "",
  member: "0",
  memberShip: "",
  typeGubun: "9",
  userid: "00",
};

export const analysisTrading_searchData_atom = atom(analysisTrading_searchDataInit_atom);

export const analysisTrading_isOrderDetail_atom = atom(false);

export const analysisTrading_isOrderDetailVisible_atom = atom(false);

export const analysisTrading_tableList1_atom = atom([]);

export const analysisTrading_tableList2_atom = atom([]);

export const analysisTrading_tableList3_atom = atom([]);
