import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";

export const OldSMS = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  const getUserIdQ = useGetUserIdQ();

  // old문자 조회
  const customerGetOldSms = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getOldSms", {
        params: {
          seqNo: data,
        },
      }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetOldSms.mutate(mainData.seqNo);
  }, [mainData.seqNo]);


  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">
          Old 문자 (<span>{subData.length ?? "0"}</span>건)
        </div>
      </div>
      <div className="table-container table-sticky h-443 mt-10">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th>연번</th>
              <th>전송일시</th>
              <th>전송자</th>
              <th>수신번호</th>
              <th>전송결과</th>
              <th>전송내용</th>
            </tr>
          </thead>
          <tbody>
            {subData.length !== 0 ? (
              subData?.map((el, index) => {
                return (
                  <tr>
                    <td>
                      <div>{subData.length - index}</div>
                    </td>
                    <td>{el?.sendDate ? moment(el.sendDate).format("YYYY-MM-DD") : ""}</td>
                    <td>{getUserIdQ.data?.find(el2 => el2.key === el?.userName)?.value}</td>
                    <td>{el?.rcvPhoneNumber}</td>
                    <td>{el?.resultsMsg}</td>
                    <td>
                      <input type="text" className="form-control" value={el?.msg} readOnly={true} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  검색된 Old 문자 내역이 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
