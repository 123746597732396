import React, { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const Thirteen = ({ data, years }) => {
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={
              {
                // width: `${data.tableRows?.length !== 0 ? "1800px" : ""} `,
                // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                // marginBottom: `${
                //   rowVirtualizer.getTotalSize() -
                //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                //   rowVirtualizer.getVirtualItems().length * 30
                // }px`,
              }
            }
          >
            <thead>
              <tr>
                <th rowSpan={2}>연번</th>
                <th rowSpan={2}>소속</th>
                <th rowSpan={2}>성명</th>
                <th rowSpan={2}>직위</th>
                <th rowSpan={2}>입사일자</th>
                {Array.from({ length: 5 }, (_, idx) => (
                  <th colSpan={3}>{+years + idx} 년</th>
                ))}
                <th colSpan={3}>합계</th>
              </tr>
              <tr>
                {Array.from({ length: 6 }, (_, idx) => (
                  <>
                    <th>건수</th>
                    <th>매출</th>
                    <th>비고</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.result?.length !== 0 ? (
                data.result?.reduce((acc, cur, idx, arr) => {
                  // 소속 합치기
                  if (cur?.jobName !== arr[idx - 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{idx + 1}</td>
                        <td rowSpan={arr.filter(el => el.jobName === cur.jobName).length}>
                          {cur.jobName}
                        </td>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.inDate}</td>
                        <td>{cur.exchCnt01}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt01)}</td>
                        <td></td>
                        <td>{cur.exchCnt02}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt02)}</td>
                        <td></td>
                        <td>{cur.exchCnt03}</td>
                        <td className="text-end">{numberFixedFormat(ur.rsltAmnt03)}</td>
                        <td></td>
                        <td>{cur.exchCnt04}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt04)}</td>
                        <td></td>
                        <td>{cur.exchCnt05}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt05)}</td>
                        <td></td>
                        <td>{cur.sumCnt}</td>
                        <td className="text-end">{numberFixedFormat(cur.sumAmnt)}</td>
                        <td></td>
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{idx + 1}</td>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.inDate}</td>
                        <td>{cur.exchCnt01}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt01)}</td>
                        <td></td>
                        <td>{cur.exchCnt02}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt02)}</td>
                        <td></td>
                        <td>{cur.exchCnt03}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt03)}</td>
                        <td></td>
                        <td>{cur.exchCnt04}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt04)}</td>
                        <td></td>
                        <td>{cur.exchCnt05}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt05)}</td>
                        <td></td>
                        <td>{cur.sumCnt}</td>
                        <td className="text-end">{numberFixedFormat(cur.sumAmnt)}</td>
                        <td></td>
                      </tr>,
                    ];
                  }

                  // 자사구분 총계
                  if (cur?.jobName !== arr[idx + 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={5}>{cur.jobName} 계</td>
                        <td>{data.resultSum[cur.jobName].exchCnt01}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.jobName].rsltAmnt01)}
                        </td>
                        <td></td>
                        <td>{data.resultSum[cur.jobName].exchCnt02}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.jobName].rsltAmnt02)}
                        </td>
                        <td></td>
                        <td>{data.resultSum[cur.jobName].exchCnt03}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.jobName].rsltAmnt03)}
                        </td>
                        <td></td>
                        <td>{data.resultSum[cur.jobName].exchCnt04}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.jobName].rsltAmnt04)}
                        </td>
                        <td></td>
                        <td>{data.resultSum[cur.jobName].exchCnt05}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.jobName].rsltAmnt05)}
                        </td>
                        <td></td>
                        <td>{data.resultSum[cur.jobName].sumCnt}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.jobName].sumAmnt)}
                        </td>
                        <td></td>
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td colSpan={5}>총계</td>
                        <td>{data.total.exchCnt01}</td>
                        <td className="text-end">{numberFixedFormat(data.total.rsltAmnt01)}</td>
                        <td></td>
                        <td>{data.total.exchCnt02}</td>
                        <td className="text-end">{numberFixedFormat(data.total.rsltAmnt02)}</td>
                        <td></td>
                        <td>{data.total.exchCnt03}</td>
                        <td className="text-end">{numberFixedFormat(data.total.rsltAmnt03)}</td>
                        <td></td>
                        <td>{data.total.exchCnt04}</td>
                        <td className="text-end">{numberFixedFormat(data.total.rsltAmnt04)}</td>
                        <td></td>
                        <td>{data.total.exchCnt05}</td>
                        <td className="text-end">{numberFixedFormat(data.total.rsltAmnt05)}</td>
                        <td></td>
                        <td>{data.total.sumCnt}</td>
                        <td className="text-end">{numberFixedFormat(data.total.sumAmnt)}</td>
                        <td></td>
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={23}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
