import React, { useEffect, useRef, useState } from "react";
import { useGetDaesangJisa } from "../../../api/code/useGetDaesangJisa";
import { useGetGolfMember } from "../../../api/code/useGetGolfMember";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment";
import { useAtom } from "jotai/index";
import { fileDownload } from "../../../util/fileDownload/fileDownload";
import {
  analysisTrading_isOrderDetail_atom,
  analysisTrading_isOrderDetailVisible_atom,
  analysisTrading_searchData_atom,
  analysisTrading_searchDataInit_atom,
  analysisTrading_tableList1_atom,
  analysisTrading_tableList2_atom,
  analysisTrading_tableList3_atom,
} from "../../../data/atom/dataManage/analysisTradingItemPeriod/analysisTradingItemPeriod_atom";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  customerManage_mainDataInit_atom,
  customerManage_searchDataInit_atom,
  customerManage_searchedTableListInit_atom,
} from "../../../data/atom/workManage/customerManage/customerManage_atom";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const AnalysisTradingItemPeriod = () => {
  const { addTabClosedEvent } = useRecentTabEvent();

  const tableList1Ref = useRef(null);
  const tableList2Ref = useRef(null);
  const tableList3Ref = useRef(null);

  const [searchData, setSearchData] = useAtom(analysisTrading_searchData_atom);
  const [isOrderDetail, setIsOrderDetail] = useAtom(analysisTrading_isOrderDetail_atom);
  const [isOrderDetailVisible, setIsOrderDetailVisible] = useAtom(
    analysisTrading_isOrderDetailVisible_atom
  );
  const [tableList1, setTableList1] = useAtom(analysisTrading_tableList1_atom);
  const [tableList2, setTableList2] = useAtom(analysisTrading_tableList2_atom);
  const [tableList3, setTableList3] = useAtom(analysisTrading_tableList3_atom);

  const getDaesangJisa = useGetDaesangJisa();
  const getGolfMember = useGetGolfMember();

  // 거래내역 검색
  const golfMemberSearch = useMutation(
    ["golfMemberSearch"],
    data => axios.get("/api/data/golfMember/search", { params: data }),
    {
      onSuccess: res => {
        setTableList1(res.data.result);
      },
    }
  );

  // 종목별 거래 통계표
  const golfMemberSearchDetail = useMutation(
    ["golfMemberSearchDetail"],
    data => axios.get("/api/data/golfMember/searchDetail", { params: data }),
    {
      onSuccess: res => {
        setTableList2(res.data.result);
      },
    }
  );

  // 주문내역 팝업창
  const golfMemberOrderDetail = useMutation(
    ["golfMemberOrderDetail"],
    data => axios.get("/api/data/golfMember/orderDetail", { params: data }),
    {
      onSuccess: res => {
        setTableList3(res.data.result);
      },
    }
  );

  // 거래 자료 엑셀 저장
  const golfMemberDownloadExcel = useMutation(
    [],
    data =>
      axios.post("/api/data/golfMember/downloadExcel", data, {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        fileDownload(res.data, `거래종목분석_${moment().format("YY_MM_DD_hh_mm")}.xlsx`);
      },
    }
  );

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSearchData(analysisTrading_searchDataInit_atom);
      setIsOrderDetail(false);
      setIsOrderDetailVisible(false);

      setTableList1([]);
      setTableList2([]);
      setTableList3([]);
    });
  }, []);

  useEffect(() => {
    if (getDaesangJisa.isSuccess) {
      setSearchData({
        ...searchData,
        jisa: getDaesangJisa.data[0].key,
        // jisa: "11",
      });
    }
  }, [getDaesangJisa.isSuccess]);

  const btn_search = () => {
    golfMemberSearch.mutate(searchData);
    golfMemberSearchDetail.mutate(searchData);

    if (isOrderDetail) {
      golfMemberOrderDetail.mutate(searchData);
      setIsOrderDetailVisible(true);
    } else {
      setTableList3([]);
      setIsOrderDetailVisible(false);
    }
  };

  const func_calculatePersent = (todaySise, siseAvg) => {
    let result = (((todaySise - siseAvg) / (todaySise === 0 ? 1 : siseAvg)) * 100).toFixed();

    // if (result === "Infinity") {
    //   return "0";
    // } else {
    //   return result;
    // }

    // result = Math.abs(+result);

    if (result.includes("-") && result.length > 3) {
      result = -100;
    }

    return result;
  };

  const btn_excelDown = () => {
    golfMemberDownloadExcel.mutate(tableList2);
  };

  const tableList1Virtualizer = useVirtualizer({
    count: tableList1?.length,
    getScrollElement: () => tableList1Ref.current,
    estimateSize: () => 30,
  });

  const tableList2Virtualizer = useVirtualizer({
    count: tableList2?.length,
    getScrollElement: () => tableList2Ref.current,
    estimateSize: () => 30,
  });

  const tableList3Virtualizer = useVirtualizer({
    count: tableList3?.length,
    getScrollElement: () => tableList3Ref.current,
    estimateSize: () => 30,
  });

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">거래 종목 분석</div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">지사</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.jisa}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        jisa: e.target.value,
                      });
                    }}
                  >
                    {getDaesangJisa.data?.map(el => (
                      <option value={el.key}>{el.value}</option>
                    ))}
                  </select>
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">거래유형</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.typeGubun}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        typeGubun: e.target.value,
                      });
                    }}
                  >
                    <option value="9">전체</option>
                    <option value="1">당사거래-전체(분양포함)</option>
                    <option value="2">당사거래-증여,상속</option>
                    <option value="3">당사거래-명의변경</option>
                    <option value="4">샵거래</option>
                    <option value="5">분양거래-전체</option>
                    <option value="6">당사거래(샵,분양미포함)</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">거래직원</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.userid}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        userid: e.target.value,
                      });
                    }}
                  >
                    <option value="00">전체</option>
                    {getGolfMember.data?.map(el => (
                      <option value={el.key}>{el.value}</option>
                    ))}
                  </select>
                </div>
                <div className="filter1-field filter1-field3">
                  <input
                    type="checkbox"
                    id="chk-1"
                    className="form-check-input"
                    name="checkbox-option"
                    checked={isOrderDetail}
                    onChange={e => {
                      setIsOrderDetail(e.target.checked);
                    }}
                  />
                  <label className="filter1-label" htmlFor="chk-1">
                    주문내역 포함
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label" style={{ whiteSpace: "nowrap" }}>
                    회원권
                  </label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input1 me-3 w-100"
                    value={searchData.memberShip}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        memberShip: e.target.value,
                      });
                    }}
                  />
                </div>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btn_search}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
            <div className="filter_fields_row mt-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field2 m-0 p-0">
                  <label className="filter1-label ms-3">검색 기간</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchData.frontDate}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        frontDate: e.target.value,
                      });
                    }}
                    max="9999-12-31"
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchData.backDate}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        backDate: e.target.value,
                      });
                    }}
                    max="9999-12-31"
                  />
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.member}
                    onChange={e => {
                      if (e.target.value === "1") {
                        setSearchData({
                          ...searchData,
                          member: e.target.value,
                        });
                      } else {
                        setSearchData({
                          ...searchData,
                          member: e.target.value,
                          gubun: "99",
                        });
                      }
                    }}
                  >
                    <option value="0">전체</option>
                    <option value="1">골프</option>
                    <option value="2">콘도</option>
                    <option value="3">헬스</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">종목분류</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.gubun}
                    onChange={e => {
                      setSearchData({
                        ...searchData,
                        gubun: e.target.value,
                      });
                    }}
                    disabled={searchData.member !== "1"}
                  >
                    <option value="99">전체</option>
                    <option value="34">초고가대(5억 이상)</option>
                    <option value="33">고가대(2억~5억 미만)</option>
                    <option value="22">중가대(1억~2억 미만)</option>
                    <option value="11">저가대(1억 미만)</option>
                    <option value="44">주중회원권</option>
                  </select>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btn_excelDown}
              >
                시세엑셀저장
              </button>
            </div>
          </div>
        </div>

        <div className="row gx-3">
          <div className="col-7">
            <div className="card card-main m-0">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">거래내역</h5>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="tab1-row1-count">조회된 수 : {tableList1?.length ?? 0}</div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-container table-sticky h-633">
                  <table className="table table-bordered table-responsive my-table mt-0">
                    <thead>
                      <tr>
                        <th style={{ width: "8%" }}>No</th>
                        <th>서류일자</th>
                        <th>구분</th>
                        <th>분류</th>
                        <th>회원권종목명</th>
                        <th>구분</th>
                        <th>매매 금액</th>
                        <th style={{ width: "15%" }}>비고</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableList1.length !== 0 ? (
                        tableList1.reduce((acc, cur, idx, arr) => {
                          // 구분, 분류 합치기
                          if (
                            cur.gubunName2 !== arr[idx - 1]?.gubunName2 ||
                            cur.etcTypename !== arr[idx - 1]?.etcTypename
                          ) {
                            acc = [
                              ...acc,
                              <tr>
                                <td>{idx + 1}</td>
                                <td>
                                  {cur.custDate ? moment(cur.custDate).format("YYYY-MM-DD") : ""}
                                </td>
                                <td
                                  rowSpan={
                                    arr.filter(
                                      el =>
                                        el.gubunName2 === cur.gubunName2 &&
                                        el.etcTypename === cur.etcTypename
                                    ).length
                                  }
                                >
                                  {cur.gubunName2}
                                </td>
                                <td
                                  rowSpan={
                                    arr.filter(
                                      el =>
                                        el.gubunName2 === cur.gubunName2 &&
                                        el.etcTypename === cur.etcTypename
                                    ).length
                                  }
                                >
                                  {cur.etcTypename}
                                </td>
                                <td>{cur.etcCodename}</td>
                                <td>{cur.gubunName1}</td>
                                <td className="text-end">{(+cur.priceWon).toLocaleString()}</td>
                                <td>{cur.remark}</td>
                              </tr>,
                            ];
                          } else {
                            acc = [
                              ...acc,
                              <tr>
                                <td>{idx + 1}</td>
                                <td>
                                  {cur.custDate ? moment(cur.custDate).format("YYYY-MM-DD") : ""}
                                </td>
                                {/*<td>{cur.gubunName2}</td>*/}
                                {/*<td>{cur.etcTypename}</td>*/}
                                <td>{cur.etcCodename}</td>
                                <td>{cur.gubunName1}</td>
                                <td className="text-end">{(+cur.priceWon).toLocaleString()}</td>
                                <td>{cur.remark}</td>
                              </tr>,
                            ];
                          }

                          // 분류별 합계
                          if (cur.etcTypename !== arr[idx + 1]?.etcTypename) {
                            acc = [
                              ...acc,
                              <tr className="bg-lightgrey">
                                <td></td>
                                <td>
                                  {
                                    arr.filter(
                                      el =>
                                        el.etcTypename === cur.etcTypename &&
                                        el.gubunName2 === cur.gubunName2
                                    ).length
                                  }
                                  건
                                </td>
                                <td></td>
                                <td>분류별 소개</td>
                                <td>
                                  매도:
                                  {
                                    arr.filter(
                                      el =>
                                        el.etcTypename === cur.etcTypename &&
                                        el.gubunName1 === "매도" &&
                                        el.gubunName2 === cur.gubunName2
                                    ).length
                                  }
                                  건 매수:
                                  {
                                    arr.filter(
                                      el =>
                                        el.etcTypename === cur.etcTypename &&
                                        el.gubunName1 === "매수" &&
                                        el.gubunName2 === cur.gubunName2
                                    ).length
                                  }
                                  건
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>,
                            ];
                          }

                          // 구분 합계
                          if (cur.gubunName2 !== arr[idx + 1]?.gubunName2) {
                            acc = [
                              ...acc,
                              <tr className="bg-green">
                                <td></td>
                                <td>
                                  {arr.filter(el => el.gubunName2 === cur.gubunName2).length}건
                                </td>
                                <td>{cur.gubunName2}</td>
                                <td>계</td>
                                <td>
                                  매도:
                                  {
                                    arr.filter(
                                      el =>
                                        el.gubunName2 === cur.gubunName2 && el.gubunName1 === "매도"
                                    ).length
                                  }
                                  건 매수:
                                  {
                                    arr.filter(
                                      el =>
                                        el.gubunName2 === cur.gubunName2 && el.gubunName1 === "매수"
                                    ).length
                                  }
                                  건
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>,
                            ];
                          }

                          // 전체 합계
                          if (arr.length === idx + 1) {
                            acc = [
                              ...acc,
                              <tr className="bg-blue">
                                <td></td>
                                <td>
                                  {arr.filter(el => cur.etcTypename === cur.etcTypename).length}건
                                </td>
                                <td>전체</td>
                                <td>합계</td>
                                <td>
                                  매도:
                                  {arr.filter(el => el.gubunName1 === "매도").length}건 매수:
                                  {arr.filter(el => el.gubunName1 === "매수").length}건
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>,
                            ];
                          }

                          return acc;
                        }, [])
                      ) : (
                        <tr>
                          <td colSpan={8}>검색결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="card card-main m-0">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">종목별 거래 통계표</h5>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="tab1-row1-count">조회된 수 : {tableList2?.length ?? 0}</div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-container table-sticky h-633">
                  <table className="table table-bordered table-responsive my-table mt-0">
                    <thead>
                      <tr>
                        <th style={{ width: "8%" }}>No</th>
                        <th style={{ width: "8%" }}>구분</th>
                        <th style={{ width: "20%" }}>회원권종목명</th>
                        <th style={{ width: "8%" }}>매도</th>
                        <th style={{ width: "8%" }}>매수</th>
                        <th style={{ width: "8%" }}>계</th>
                        <th style={{ width: "15%" }}>소재지</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableList2.length !== 0 ? (
                        tableList2.reduce((acc, cur, idx, arr) => {
                          // 구분 합치기
                          if (cur.gubunName2 !== arr[idx - 1]?.gubunName2) {
                            acc = [
                              ...acc,

                              <tr>
                                <td>{idx + 1}</td>
                                <td
                                  rowSpan={
                                    arr.filter(el => el.gubunName2 === cur.gubunName2).length
                                  }
                                >
                                  {cur.gubunName2}
                                </td>
                                <td>{cur.etcCodename}</td>
                                <td>{cur.cntDo}</td>
                                <td>{cur.cntSu}</td>
                                <td>{cur.cntSum}</td>
                                <td>{cur.addres}</td>
                              </tr>,
                            ];
                          } else {
                            acc = [
                              ...acc,
                              <tr>
                                <td>{idx + 1}</td>
                                {/*<td>{cur.gubunName2}</td>*/}
                                <td>{cur.etcCodename}</td>
                                <td>{cur.cntDo}</td>
                                <td>{cur.cntSu}</td>
                                <td>{cur.cntSum}</td>
                                <td>{cur.addres}</td>
                              </tr>,
                            ];
                          }

                          // 구분 합계
                          if (cur.gubunName2 !== arr[idx + 1]?.gubunName2) {
                            acc = [
                              ...acc,
                              <tr className="bg-green">
                                <td></td>
                                <td>{cur.gubunName2}</td>
                                <td>계</td>
                                <td>
                                  {arr.reduce((acc2, cur2) => {
                                    if (cur2.gubunName2 === cur.gubunName2) {
                                      return acc2 + cur2.cntDo;
                                    } else {
                                      return acc2;
                                    }
                                  }, 0)}
                                </td>
                                <td>
                                  {arr.reduce((acc2, cur2) => {
                                    if (cur2.gubunName2 === cur.gubunName2) {
                                      return acc2 + cur2.cntSu;
                                    } else {
                                      return acc2;
                                    }
                                  }, 0)}
                                </td>
                                <td>
                                  {arr.reduce((acc2, cur2) => {
                                    if (cur2.gubunName2 === cur.gubunName2) {
                                      return acc2 + cur2.cntSum;
                                    } else {
                                      return acc2;
                                    }
                                  }, 0)}
                                </td>
                                <td></td>
                              </tr>,
                            ];
                          }

                          // 전체 합계
                          if (arr.length === idx + 1) {
                            acc = [
                              ...acc,
                              <tr className="bg-blue">
                                <td></td>
                                <td></td>
                                <td>전체합계</td>
                                <td>
                                  {arr.reduce((acc2, cur2) => {
                                    return acc2 + cur2.cntDo;
                                  }, 0)}
                                </td>
                                <td>
                                  {arr.reduce((acc2, cur2) => {
                                    return acc2 + cur2.cntSu;
                                  }, 0)}
                                </td>
                                <td>
                                  {arr.reduce((acc2, cur2) => {
                                    return acc2 + cur2.cntSum;
                                  }, 0)}
                                </td>
                                <td></td>
                              </tr>,
                            ];
                          }

                          return acc;
                        }, [])
                      ) : (
                        <tr>
                          <td colSpan={8}>검색결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {isOrderDetailVisible && (
            <div className="col-12">
              <div className="card card-main">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title  m-0">주문내역</h5>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="tab1-row1-count">조회된 수 : {tableList3?.length ?? 0}</div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-container table-sticky h-443">
                    <table className="table table-bordered table-responsive my-table m-0">
                      <colgroup>
                        <col width="5%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>순위</th>
                          <th>code</th>
                          <th>회원권종목명</th>
                          <th>평균시세</th>
                          <th>현재시세</th>
                          <th>변동금액</th>
                          <th>변동률(%)</th>
                          <th>계</th>
                          <th>매도</th>
                          <th>매수</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableList3.length !== 0 ? (
                          tableList3.reduce((acc, cur, idx, arr) => {
                            acc = [
                              ...acc,
                              <tr>
                                <td>{idx + 1}</td>
                                <td>{cur.rank}</td>
                                <td>{cur.code}</td>
                                <td>{cur.codename}</td>
                                <td>{cur.siseAvg}</td>
                                <td>{cur.todaySise}</td>
                                <td>{cur.up}</td>
                                <td>{func_calculatePersent(cur.todaySise, cur.siseAvg)}</td>
                                <td>{cur.cnt}</td>
                                <td>{cur.cntDo}</td>
                                <td>{cur.cntSu}</td>
                              </tr>,
                            ];

                            if (arr.length === idx + 1) {
                              acc = [
                                ...acc,
                                <tr className="bg-green">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>계</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {arr.reduce((acc2, cur2) => {
                                      acc2 = acc2 + cur2.cnt;
                                      return acc2;
                                    }, 0)}
                                  </td>
                                  <td>
                                    {arr.reduce((acc2, cur2) => {
                                      acc2 = acc2 + cur2.cntDo;
                                      return acc2;
                                    }, 0)}
                                  </td>
                                  <td>
                                    {arr.reduce((acc2, cur2) => {
                                      acc2 = acc2 + cur2.cntSu;
                                      return acc2;
                                    }, 0)}
                                  </td>
                                </tr>,
                              ];
                            }

                            return acc;
                          }, [])
                        ) : (
                          <tr>
                            <td colSpan="11" className="text-center">
                              검색된 결과가 없습니다.
                            </td>
                          </tr>
                        )}

                        {/*{tableList3?.length !== 0 ? (*/}
                        {/*  tableList3?.map((el, index) => {*/}
                        {/*    return (*/}
                        {/*      <tr>*/}
                        {/*        <td>{index + 1}</td>*/}
                        {/*        <td>{el.rank}</td>*/}
                        {/*        <td>{el.code}</td>*/}
                        {/*        <td>{el.codename}</td>*/}
                        {/*        <td>{el.siseAvg}</td>*/}
                        {/*        <td>{el.todaySise}</td>*/}
                        {/*        <td>{el.up}</td>*/}
                        {/*        <td>{func_calculatePersent(el.todaySise, el.siseAvg)}</td>*/}
                        {/*        <td>{el.cnt}</td>*/}
                        {/*        <td>{el.cntDo}</td>*/}
                        {/*        <td>{el.cntSu}</td>*/}
                        {/*      </tr>*/}
                        {/*    );*/}
                        {/*  })*/}
                        {/*) : (*/}
                        {/*  <tr>*/}
                        {/*    <td colSpan="11" className="text-center">*/}
                        {/*      검색된 결과가 없습니다.*/}
                        {/*    </td>*/}
                        {/*  </tr>*/}
                        {/*)}*/}
                      </tbody>
                      {/*<tfoot>*/}
                      {/*  <tr>*/}
                      {/*    <td></td>*/}
                      {/*    <td></td>*/}
                      {/*    <td></td>*/}
                      {/*    <td>계</td>*/}
                      {/*    <td></td>*/}
                      {/*    <td></td>*/}
                      {/*    <td></td>*/}
                      {/*    <td></td>*/}
                      {/*    <td>*/}
                      {/*      {tableList3.reduce((acc, cur) => {*/}
                      {/*        return acc + cur.cnt;*/}
                      {/*      }, 0)}*/}
                      {/*    </td>*/}
                      {/*    <td>*/}
                      {/*      {tableList3.reduce((acc, cur) => {*/}
                      {/*        return acc + cur.cntDo;*/}
                      {/*      }, 0)}*/}
                      {/*    </td>*/}
                      {/*    <td>*/}
                      {/*      {tableList3.reduce((acc, cur) => {*/}
                      {/*        return acc + cur.cntSu;*/}
                      {/*      }, 0)}*/}
                      {/*    </td>*/}
                      {/*  </tr>*/}
                      {/*  /!*<tr>*!/*/}
                      {/*  /!*  <td>&nbsp;</td>*!/*/}
                      {/*  /!*  <td>&nbsp;</td>*!/*/}
                      {/*  /!*  <td>전체 합계</td>*!/*/}
                      {/*  /!*  <td>2</td>*!/*/}
                      {/*  /!*  <td>2</td>*!/*/}
                      {/*  /!*  <td>4</td>*!/*/}
                      {/*  /!*  <td>&nbsp;</td>*!/*/}
                      {/*  /!*</tr>*!/*/}
                      {/*</tfoot>*/}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
