import React, { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const Four = ({ data }) => {
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={{
              width: `${
                data.tableColumns?.length !== 0 ? data.tableColumns?.length * 120 + 200 + "px" : ""
              } `,
              // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              // marginBottom: `${
              //   rowVirtualizer.getTotalSize() -
              //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
              //   rowVirtualizer.getVirtualItems().length * 30
              // }px`,
            }}
          >
            <thead>
              <tr>
                <th rowSpan={2} style={{ width: "200px" }}>
                  팀 구분
                </th>
                {data.tableColumns.length !== 0 &&
                  data.tableColumns
                    .slice(0, data.tableColumns.length - 1)
                    ?.map(el => <th colSpan={2}>{el}</th>)}
                <th colSpan={2}>합계</th>
              </tr>
              <tr>
                {Array.from({ length: data.tableColumns.length }, () => (
                  <>
                    <th>건수</th>
                    <th>금액</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.tableRows?.length !== 0 ? (
                data.tableRows?.reduce((acc, cur, idx, arr) => {
                  // 팀 구분 합치기
                  if (cur?.jobName !== arr[idx - 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{cur.jobName}</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => {
                          return (
                            <>
                              <td>
                                {data.result[cur.jobName]?.[data.tableColumns[idx2]]?.exchangeCnt ??
                                  "-"}
                              </td>
                              <td className="text-end">
                                {/*{data.result[cur.jobName]?.[*/}
                                {/*  data.tableColumns[idx2]*/}
                                {/*]?.resultAmnt.toLocaleString() ?? "-"}*/}
                                {numberFixedFormat(
                                  data.result[cur.jobName]?.[data.tableColumns[idx2]]?.resultAmnt
                                ) ?? "-"}
                              </td>
                            </>
                          );
                        })}
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{cur.jobName}</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => (
                          <>
                            <td>
                              {data.result[cur.jobName]?.[data.tableColumns[idx2]]?.exchangeCnt ??
                                "-"}
                            </td>
                            <td className="text-end">
                              {/*{data.result[cur.jobName]?.[*/}
                              {/*  data.tableColumns[idx2]*/}
                              {/*]?.resultAmnt.toLocaleString() ?? "-"}*/}
                              {numberFixedFormat(
                                data.result[cur.jobName]?.[data.tableColumns[idx2]]?.resultAmnt
                              ) ?? "-"}
                            </td>
                          </>
                        ))}
                      </tr>,
                    ];
                  }

                  if (cur.jobName[0] !== arr[idx + 1]?.jobName[0]) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td>소개</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => {
                          return (
                            <>
                              <td>
                                {data.resultSum[cur.jobName[0]]?.[data.tableColumns[idx2]]
                                  ?.exchangeCnt ?? "-"}
                              </td>
                              <td className="text-end">
                                {/*{data.resultSum[cur.jobName[0]]?.[*/}
                                {/*  data.tableColumns[idx2]*/}
                                {/*]?.resultAmnt.toLocaleString() ?? "-"}*/}
                                {numberFixedFormat(
                                  data.resultSum[cur.jobName[0]]?.[data.tableColumns[idx2]]
                                    ?.resultAmnt
                                ) ?? "-"}
                              </td>
                            </>
                          );
                        })}
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td>총계</td>
                        {Array.from({ length: data.tableColumns.length }, (_, idx2) => (
                          <>
                            <td>{data.total[data.tableColumns[idx2]]?.exchangeCnt ?? "-"}</td>
                            <td className="text-end">
                              {/*{data.total[data.tableColumns[idx2]]?.resultAmnt.toLocaleString() ??*/}
                              {/*  "-"}*/}
                              {numberFixedFormat(data.total[data.tableColumns[idx2]]?.resultAmnt) ??
                                "-"}
                            </td>
                          </>
                        ))}
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={3}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
