import { atom } from "jotai";
import moment from "moment";

export const InitialPublication_row = atom("5");
export const InitialPublication_searchOption = atom({
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: moment(new Date()).format("YYYY-MM-DD"),
  keyWord: "",
  gubun0: "2",
  page: 1,
  searchType: "1",
});
export const InitialPublication_SearchRes = atom([]);
export const InitialPublication_detailRes = atom(null);
export const InitialPublication_transActionLst = atom([
  {
    custDate: moment(new Date()).format("YYYY-MM-DD"),
    custId: "",
    gubun0: "",
    gubun1: "",
    ilNo: "",
    item: "",
    modDate: "",
    priceAmt: 0,
    priceTax: 0,
    priceTot: 0,
    seqNo: 0,
    userid: "",
  },
]);
export const InitialPublication_issueRes = atom({
  ilNo: "",
  gubun0: "",
  custId: "",
});
export const InitialPublication_memExchangeResVoList = atom([]);
export const InitialPublication_searchListCount = atom("0");
export const InitialPublication_selectedCustId = atom("");
export const InitialPublication_showType = atom("1");
