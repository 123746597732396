import { atom } from "jotai";
import moment from "moment";
import { decrypt } from "../../../../../util/decrypt/decrypt";
export const searchParamInit = {
  fromDate: moment().subtract(3, "days").format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD"),
  jisa: decrypt("userInfo")?.userPermit === "99" ? "99" : decrypt("userInfo")?.jisa,
  type: "A",
};
export const searchParam_atom = atom({ ...searchParamInit });

export const searchResult_atom = atom([]);
