import React, { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const Nine = ({ data }) => {
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={
              {
                // width: `${data.tableRows?.length !== 0 ? "1800px" : ""} `,
                // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                // marginBottom: `${
                //   rowVirtualizer.getTotalSize() -
                //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                //   rowVirtualizer.getVirtualItems().length * 30
                // }px`,
              }
            }
          >
            <thead>
              <tr>
                <th rowSpan={2}>소속</th>
                <th rowSpan={2}>성명</th>
                <th rowSpan={2}>직위</th>
                <th rowSpan={2}>입사일자</th>
                {data?.weekGubun?.map(el => (
                  <th colSpan={2}>{`${el.weekGubn}주 (${el.minDate}) ~ (${el.maxDate})`}</th>
                ))}

                <th colSpan={2}>합계</th>
              </tr>
              <tr>
                {Array.from({ length: data?.weekGubun?.length + 1 }, () => (
                  <>
                    <th>건수</th>
                    <th>실적</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.result?.length !== 0 ? (
                data.result?.reduce((acc, cur, idx, arr) => {
                  // 소속 합치기
                  if (cur?.jobName !== arr[idx - 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr>
                        <td rowSpan={arr.filter(el => el.jobName === cur.jobName).length}>
                          {cur.jobName}
                        </td>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.ipsaDate}</td>
                        <td>{cur.exchTot1}</td>
                        <td>{numberFixedFormat(cur.rsltTot1)}</td>
                        <td>{cur.exchTot2}</td>
                        <td>{numberFixedFormat(cur.rsltTot2)}</td>
                        <td>{cur.exchTot3}</td>
                        <td>{numberFixedFormat(cur.rsltTot3)}</td>
                        <td>{cur.exchTot4}</td>
                        <td>{numberFixedFormat(cur.rsltTot4)}</td>
                        <td>{cur.exchTot5}</td>
                        <td>{numberFixedFormat(cur.rsltTot5)}</td>
                        <td>{cur.totalCnt}</td>
                        <td>{numberFixedFormat(cur.totalRslt)}</td>
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.ipsaDate}</td>
                        <td>{cur.exchTot1}</td>
                        <td>{numberFixedFormat(cur.rsltTot1)}</td>
                        <td>{cur.exchTot2}</td>
                        <td>{numberFixedFormat(cur.rsltTot2)}</td>
                        <td>{cur.exchTot3}</td>
                        <td>{numberFixedFormat(cur.rsltTot3)}</td>
                        <td>{cur.exchTot4}</td>
                        <td>{numberFixedFormat(cur.rsltTot4)}</td>
                        <td>{cur.exchTot5}</td>
                        <td>{numberFixedFormat(cur.rsltTot5)}</td>
                        <td>{cur.totalCnt}</td>
                        <td>{numberFixedFormat(cur.totalRslt)}</td>
                      </tr>,
                    ];
                  }

                  // 소속 총계
                  if (cur?.jobName !== arr[idx + 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={4}>{cur.jobName} 계</td>
                        <td>{data.sumByJobSeq[cur.jobSeq]?.exch_tot1}</td>
                        <td>{numberFixedFormat(data.sumByJobSeq[cur.jobSeq]?.rslt_tot1)}</td>
                        <td>{data.sumByJobSeq[cur.jobSeq]?.exch_tot2}</td>
                        <td>{numberFixedFormat(data.sumByJobSeq[cur.jobSeq]?.rslt_tot2)}</td>
                        <td>{data.sumByJobSeq[cur.jobSeq]?.exch_tot3}</td>
                        <td>{numberFixedFormat(data.sumByJobSeq[cur.jobSeq]?.rslt_tot3)}</td>
                        <td>{data.sumByJobSeq[cur.jobSeq]?.exch_tot4}</td>
                        <td>{numberFixedFormat(data.sumByJobSeq[cur.jobSeq]?.rslt_tot4)}</td>
                        <td>{data.sumByJobSeq[cur.jobSeq]?.exch_tot5}</td>
                        <td>{numberFixedFormat(data.sumByJobSeq[cur.jobSeq]?.rslt_tot5)}</td>
                        <td>{data.sumByJobSeq[cur.jobSeq]?.totalCnt}</td>
                        <td>{numberFixedFormat(data.sumByJobSeq[cur.jobSeq]?.totalRslt)}</td>
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td colSpan={4}>총계</td>
                        <td>{data.totalSum.exch_tot1}</td>
                        <td>{numberFixedFormat(data.totalSum.rslt_tot1)}</td>
                        <td>{data.totalSum.exch_tot2}</td>
                        <td>{numberFixedFormat(data.totalSum.rslt_tot2)}</td>
                        <td>{data.totalSum.exch_tot3}</td>
                        <td>{numberFixedFormat(data.totalSum.rslt_tot3)}</td>
                        <td>{data.totalSum.exch_tot4}</td>
                        <td>{numberFixedFormat(data.totalSum.rslt_tot4)}</td>
                        <td>{data.totalSum.exch_tot5}</td>
                        <td>{numberFixedFormat(data.totalSum.rslt_tot5)}</td>
                        <td>{data.totalSum.totalCnt}</td>
                        <td>{numberFixedFormat(data.totalSum.totalRslt)}</td>
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={6}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
