import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 업무지원팀 스케줄관리 사용자 셀렉트박스
 */
export const useGetSupportManageUserList = () => {
  return useQuery(
    ["getSupportManageUserList"],
    async () =>
      await axios
        .get("/api/codeMng/select/getSupportManageUserList")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
