import React, { useEffect, useState } from "react";
import EmpModal from "../../../../../components/modal/Modal";

export const SendSMSPreviewModal = ({ isPreviewModalOpen, setIsPreviewModalOpen }) => {
  const [text, setText] = useState("");
  const [number, setNumber] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (isPreviewModalOpen.data.selectedTableList.length !== 0) {
      setText(isPreviewModalOpen.data.SMSText);
      setNumber(isPreviewModalOpen.data.selectedTableList);
    }
  }, []);

  return (
    <EmpModal
      show={isPreviewModalOpen.isOpen}
      width="100%"
      minWidth="475px"
      title="미리보기"
      onHide={() => setIsPreviewModalOpen({ data: null, isOpen: false })}
      strClass="modal-scroll"
      size="md"
      footer={
        <div className="d-flex align-items-center justify-content-center w-100 m-0">
          <div className="">
            <button
              className="btn btn-default"
              onClick={() => setPage(prev => prev - 1)}
              disabled={page <= 1}
            >
              &lt;
            </button>
          </div>
          <div className="smsStepText">{`${page}/${number.length}`}</div>
          <div className="">
            <button
              className="btn btn-default"
              onClick={() => setPage(prev => prev + 1)}
              disabled={page >= number.length}
            >
              &gt;
            </button>
          </div>
        </div>
      }
    >
      <textarea
        className="form-control"
        rows="6"
        value={text
          .replace(/{이름}/g, number[page - 1]?.kname)
          .replace(/{직위}/g, number[page - 1]?.position)}
        readOnly={true}
      />
    </EmpModal>
  );
};
