import React, { useCallback, useEffect, useRef } from "react";
import SearchResultTableRow from "./SearchResultTableRow";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtom, useSetAtom } from "jotai/index";
import {
  documentManageClickedRow_atom,
  documentManageSearchParam_atom,
  documentManageSearchResult_atom,
} from "../../../../../data/atom/workManage/transactionRegisterManage/documentManage_atom";
import { useAtomValue } from "jotai";

const SearchResultTable = props => {
  const { endRef, afterFetch } = props;
  const [searchParam, setSearchParam] = useAtom(documentManageSearchParam_atom);
  const searchResult = useAtomValue(documentManageSearchResult_atom);
  const [clickedRow, setClickedRow] = useAtom(documentManageClickedRow_atom);
  const tableRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: searchResult.length,
    getScrollElement: () => tableRef.current,
    estimateSize: data => 30,
  });

  useEffect(() => {
    if (afterFetch) tableRef.current.scrollTo(0, 0);
  }, [afterFetch]);

  const onRowClick = useCallback(item => setClickedRow(item), []);

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-end mb-3">
          <select
            className="form-select filter-perpage ms-3"
            value={searchParam.tableLimit}
            onChange={e => setSearchParam(prev => ({ ...prev, tableLimit: e.target.value }))}
          >
            <option value="5">5개씩 보기</option>
            <option value="10">10개씩 보기</option>
            <option value="20">20개씩 보기</option>
          </select>
        </div>
        <div
          className={`table-container table-sticky ${searchParam.tableLimit === "5" && "h-183"}
                ${searchParam.tableLimit === "10" && "h-333"}
                ${searchParam.tableLimit === "20" && "h-633"}`}
          ref={tableRef}
        >
          <table
            className="table table-bordered table-responsive my-table"
            style={{
              marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              marginBottom: `${
                rowVirtualizer.getTotalSize() -
                (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                rowVirtualizer.getVirtualItems().length * 30
              }px`,
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "5%" }}>No.</th>
                <th style={{ width: "5%" }}>지사</th>
                <th style={{ width: "20%" }}>회원권종목명</th>
                <th style={{ width: "5%" }}>구분</th>
                <th style={{ width: "15%" }}>양도자</th>
                <th style={{ width: "15%" }}>양수자</th>
                <th style={{ width: "7%" }}>서류일자</th>
                <th style={{ width: "7%" }}>거래번호</th>
                <th style={{ width: "7%" }}>회원증No.</th>
                <th style={{ width: "5%" }}>관리번호</th>
              </tr>
            </thead>
            <tbody>
              {searchResult.length !== 0 ? (
                rowVirtualizer.getVirtualItems().map(item => {
                  if (item.index !== searchResult.length - 1)
                    return (
                      <SearchResultTableRow
                        data={searchResult[item.index]}
                        index={item.index}
                        key={item.key}
                        onClick={onRowClick}
                        clickedRow={clickedRow}
                      />
                    );
                  else
                    return (
                      <>
                        <SearchResultTableRow
                          data={searchResult[item.index]}
                          index={item.index}
                          key={item.key}
                          onClick={onRowClick}
                          clickedRow={clickedRow}
                        />
                        <div ref={endRef}></div>
                      </>
                    );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    검색된 결과가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SearchResultTable);
