import React, { useState } from "react";
import ModalEnvelope from "../modal/ModalEnvelope";
import { useAtom } from "jotai";
import {
  currentRow_atom,
  finalTax_atom,
  juminPrintParam_atom,
  medoja_atom,
  ocrNapbuPrintParam_atom,
  ocrPrintParam_atom,
  ydPrintParam_atom,
} from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment/moment";
import usePrintTab from "../../../../components/modal/usePrintTab";

export default function Print(props) {
  const { refetchFromFirstPage } = props;
  const openPdf = usePrintTab();

  const [type, setType] = useState("0");
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [medoja, setMedoja] = useAtom(medoja_atom);
  const [finalTax, setFinalTax] = useAtom(finalTax_atom);
  const [ydPrintParam, setYdPrintParam] = useAtom(ydPrintParam_atom);
  const [ocrPrintParam, setOcrPrintParam] = useAtom(ocrPrintParam_atom);
  const [ocrNapbuPrintParam, setOcrNapbuPrintParam] = useAtom(ocrNapbuPrintParam_atom);
  const [juminPrintParam, setJuminPrintParam] = useAtom(juminPrintParam_atom);

  const queryClient = useQueryClient();
  const getSemusu = queryClient.getQueryData(["getSemusu"]);

  const [refType, setRefType] = useState("0"); // 세무신고용, 고객참고용 라디오버튼 값

  const saveCalc = useMutation(data => axios.post("/api/work/transferTax/saveCalc", data), {
    onSuccess: res => {
      refetchFromFirstPage();
    },
  });
  const printYangdo = useMutation(
    data => axios.post("/api/work/transferTax/printTax1", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        // fileDownload(res.data, `양도세_명세서_${moment().format("YY_MM_DD_hh_mm")}.pdf`);
        openPdf(res?.data);
      },
      onError: err => {
        // err.response.data 가 blob인데 json객체로 파싱하기
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            try {
              const json = JSON.parse(reader.result);
              resolve(json);
            } catch (error) {
              reject(error);
            }
          };

          reader.onerror = () => {
            reject(new Error("Blob 읽기 오류"));
          };

          reader.readAsText(err.response.data);
        }).then(jsonobj => {
          if (confirm(jsonobj.message)) {
            let comp = getSemusu?.find(el => el.custId === medoja.semusu)?.company;
            printYangdo.mutate({
              ...ydPrintParam,
              company: comp,
              jusoAddr: ydPrintParam.jusoAddr + " " + medoja.juso,
              refType: refType,
              mulgaCalc: true,
            });
          }
        });
      },
    }
  );
  const printNapbu = useMutation(
    data => axios.post("/api/work/transferTax/printTax3", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        // fileDownload(res.data, `납부_안내문_${moment().format("YY_MM_DD_hh_mm")}.pdf`);
        openPdf(res?.data);
      },
    }
  );
  const printOcr = useMutation(
    data => axios.post("/api/work/transferTax/printTax2", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        // fileDownload(res.data, `OCR_고지서_${moment().format("YY_MM_DD_hh_mm")}.pdf`);
        openPdf(res?.data);

        printNapbu.mutate({
          ...ocrNapbuPrintParam,
          asCompany: getSemusu?.find(el => el.custId === medoja.semusu)?.company,
        });
      },
    }
  );
  const printJumin = useMutation(
    data => axios.post("/api/work/transferTax/printTax4", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        // fileDownload(res.data, `주민세_고지서_${moment().format("YY_MM_DD_hh_mm")}.pdf`);
        openPdf(res?.data);
      },
      onError: err => {
        // err.response.data가 blob인데 json객체로 파싱하기
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            try {
              const json = JSON.parse(reader.result);
              resolve(json);
            } catch (error) {
              reject(error);
            }
          };

          reader.onerror = () => {
            reject(new Error("Blob 읽기 오류"));
          };

          reader.readAsText(err.response.data);
        }).then(jsonobj => {
          alert(jsonobj.message);
        });
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    if (key === "sinDate") {
      setFinalTax({ ...finalTax, [key]: value });
    }
  };

  const btnJuminse = () => {
    if (ydPrintParam.isSetPrintParam) {
      printJumin.mutate(juminPrintParam);
    } else {
      alert("프린트 할 내역이 없습니다.");
    }
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (currentRow.custId === undefined) {
        alert("양도신고대상을 선택해주세요.");
      } else if (finalTax.sinDate === "") {
        alert("신고일자를 입력해주세요.");
      } else {
        saveCalc.mutate({
          custDate: moment(currentRow.custDate).format("YYYY-MM-DD"),
          custId: currentRow.custId,
          ilNo: currentRow.ilNo,
          additionalTax: finalTax.gasan,
          juminTax: finalTax.jumin,
          limitDate: finalTax.napbuEndDate,
          overDay: finalTax.overtime,
          sinDate: finalTax.sinDate,
          yangdoTax: finalTax.yangdo,
        });
      }
    }
  };
  const btnYangdo = () => {
    if (ydPrintParam.isSetPrintParam) {
      let comp = getSemusu?.find(el => el.custId === medoja.semusu)?.company;
      printYangdo.mutate({
        ...ydPrintParam,
        company: comp,
        jusoAddr: ydPrintParam.jusoAddr + " " + medoja.juso,
        refType: refType,
      });
    } else {
      alert("프린트 할 내역이 없습니다.");
    }
  };
  const btnEnvelopeSemu = () => {
    if (currentRow.custId === undefined) {
      alert("양도신고대상을 선택해주세요.");
    } else if (medoja.semusu === "") {
      alert("매도자 인적사항에서 관할 세무서를 선택해주세요.");
    } else {
      setType("1");
    }
  };
  const btnOCR = () => {
    if (ocrPrintParam.isSetPrintParam) {
      printOcr.mutate(ocrPrintParam);
    } else {
      alert("프린트 할 내역이 없습니다.");
    }
  };
  const btnEnvelopeCustomer = () => {
    if (currentRow.custId === undefined) {
      alert("양도신고대상을 선택해주세요.");
    } else {
      setType("2");
    }
  };
  const btnEnvelopeGwanchung = () => {
    if (currentRow.custId === undefined) {
      alert("양도신고대상을 선택해주세요.");
    } else if (medoja.rbdcode === undefined) {
      alert("매도자 인적사항이 없습니다.");
    } else {
      setType("3");
    }
  };

  const refTypeHandler = e => {
    setRefType(e.target.value);
  };

  return (
    <>
      <ModalEnvelope type={type} setType={setType} />
      <div className="card card-main w-50 h-100">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title  m-0">출력기능</h5>
        </div>

        <div className="card-body">
          <fieldset>
            <input
              type="radio"
              id="radio_type1"
              name="radios_type"
              className="form-check-input"
              value="0"
              defaultChecked={true}
              onChange={refTypeHandler}
            />
            <label htmlFor="radio_type1" className="filter1-label">
              세무신고용
            </label>
            <input
              type="radio"
              id="radio_type2"
              name="radios_type"
              className="form-check-input"
              value="1"
              onChange={refTypeHandler}
            />
            <label htmlFor="radio_type2" className="filter1-label">
              고객참고용
            </label>
          </fieldset>
          <div className="work_tax_note_sapn">
            (납부세액 3,000원 미만은 고지서 출력이 불가능합니다.)
          </div>
          <div className="work_tax_print_box">
            <div className="work_tax_print_list">
              <div className="work_tax_print_btn" onClick={btnJuminse}>
                주민세 고지서
              </div>
              <div className="work_tax_print_btn" style={{ color: "red" }} onClick={btnSave}>
                계산내역 저장
              </div>
            </div>
            <div className="work_tax_print_list">
              <div className="work_tax_print_btn" onClick={btnYangdo}>
                양도세 명세서
              </div>
              <div className="work_tax_print_btn" onClick={btnEnvelopeSemu}>
                봉투(세무서)
              </div>
            </div>
            <div className="work_tax_print_list">
              <div className="work_tax_print_btn" onClick={btnOCR}>
                OCR 고지서
              </div>
              <div className="work_tax_print_btn" onClick={btnEnvelopeCustomer}>
                봉투(고객)
              </div>
            </div>
            <div className="work_tax_print_list">
              <div className="work_tax_print_date">
                <div className="date" style={{ minWidth: "60px" }}>
                  <span>신고일자</span>
                </div>
                <input
                  type="date"
                  className="form-control mx-1"
                  value={finalTax.sinDate}
                  onChange={e => onChangeEventHandler("sinDate", e.target.value)}
                  max="9999-12-31"
                />
              </div>
              <div className="work_tax_print_btn" onClick={btnEnvelopeGwanchung}>
                봉투(관할관청)
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
