import React, { useEffect, useRef, useState } from "react";
import { useGetGolfCustList } from "../../../api/code/useGetGolfCustList";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment";
import { useGetAcquisition } from "../../../api/code/useGetAcquisition";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useGetChangeOx } from "../../../api/code/useGetChangeOx";
import {
  acquisitionTaxManage_mainSearchData_atom,
  acquisitionTaxManage_mainSearchDataInit_atom,
  acquisitionTaxManage_mainSearchedTableList_atom,
  acquisitionTaxManage_subSearchData_atom,
  acquisitionTaxManage_subSearchedTableList_atom,
} from "../../../data/atom/dataManage/acquisitionTaxManage/acquisitionTaxManage_atom";
import { useAtom } from "jotai/index";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useLocation } from "react-router-dom";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";
import usePrintTab from "../../../components/modal/usePrintTab";

export const AcquisitionTaxManage = () => {
  const { addTabClosedEvent } = useRecentTabEvent();
  const location = useLocation();
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  // main: 골프장/종목별 가장 최근 취득과료 검색
  const [mainSearchData, setMainSearchData] = useAtom(acquisitionTaxManage_mainSearchData_atom);
  const [mainSearchedTableList, setMainSearchedTableList] = useAtom(
    acquisitionTaxManage_mainSearchedTableList_atom
  );
  const [mainModifyList, setMainModifyList] = useState([]);
  const [currentMainIdx, setCurrentMainIdx] = useState(0);

  // sub: 종목별 전체 취득과표 검색
  const [subSearchData, setSubSearchData] = useAtom(acquisitionTaxManage_subSearchData_atom);
  const [subSearchedTableList, setSubSearchedTableList] = useAtom(
    acquisitionTaxManage_subSearchedTableList_atom
  );
  const [subModifyList, setSubModifyList] = useState([]);
  const [subDeleteList, setSubDeleteList] = useState([]);

  const mainRef = useRef(null);
  const subRef = useRef(null);

  const getGolfCustList = useGetGolfCustList();
  const getAcquisition = useGetAcquisition();
  const getChangeOx = useGetChangeOx();
  const getUserIdQ = useGetUserIdQ();

  // 골프장/종목별 가장 최근 취득과표 검색
  const acquisitionSearch = useQuery(
    ["acquisitionSearch", mainSearchData.custId, mainSearchData.searchType],
    async () => await axios.get("/api/data/acquisition/search", { params: mainSearchData }),
    {
      onSuccess: res => {
        setMainSearchedTableList(res.data.result.map(el => ({ ...el, newCheck: false })));
        // setMainModifyList(Array.from({ length: res.data.result.length }, () => false));

        if (subSearchData === null) {
          setSubSearchData(res.data.result[0]?.code);
        }
      },
    }
  );

  // 골프장/종목별 가장 최근 취득과료 저장/수정
  const acquisitionSaveGolf = useMutation(
    data => axios.post("/api/data/acquisition/saveGolf", data),
    {
      onSuccess: res => {
        acquisitionSearch.refetch();
        acquisitionGetDetail.mutate({ code: subSearchData });
        setCurrentMainIdx(currentMainIdx - 1);
      },
    }
  );

  // 골프장/종목별 가장 최근 취득과표 삭제
  const acquisitionDeleteGolf = useMutation(
    data =>
      axios
        .post("/api/data/acquisition/deleteGolf", data)
        .then(res => {
          return res.data.result;
        })
        .catch(),
    {
      onSuccess: () => {
        acquisitionSearch.refetch();
        // acquisitionGetDetail.mutate({ code: subSearchData });
      },
    }
  );

  // 종목별 전체 취득과표 상세
  const acquisitionGetDetail = useMutation(
    data => axios.get("/api/data/acquisition/getDetail", { params: data }),
    {
      onSuccess: res => {
        setSubSearchedTableList(
          res.data.result.map(el => ({
            ...el,
            chpDate: moment(el.chpDate).format("YYYY-MM-DD"),
            modDate: moment(el.modDate).format("YYYY-MM-DD"),
          }))
        );

        setSubDeleteList(
          Array.from({ length: res.data.result.length }, (_, idx) =>
            res.data.result[idx].delCheck === "Y" ? true : false
          )
        );
        setSubModifyList(Array.from({ length: res.data.result.length }, () => false));
      },
    }
  );

  // 종목별 전체 취득과표 저장/수정
  const acquisitionSaveGolfDetail = useMutation(
    data => axios.post("/api/data/acquisition/saveGolfDetail", data),
    {
      onSuccess: res => {
        acquisitionSearch.refetch();
        acquisitionGetDetail.mutate({ code: subSearchData });
      },
    }
  );

  // 종목별 전체 취득과표 삭제
  const acquisitionDeleteGolfDetail = useMutation(
    data => axios.post("/api/data/acquisition/deleteGolfDetail", data),
    {
      onSuccess: () => {
        acquisitionSearch.refetch();
        acquisitionGetDetail.mutate({ code: subSearchData });
      },
    }
  );

  // 취득과표 및 전환유무관리확인 대장 출력
  const acquisitionPrint = useMutation(
    () => axios.get("/api/data/acquisition/print", { responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `취드고가표/전환유무_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  // 탭 닫으면 atom reset
  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setMainSearchData(acquisitionTaxManage_mainSearchDataInit_atom);
      setMainSearchedTableList([]);
      setSubSearchData(null);
      setSubSearchedTableList([]);
    });
  }, []);

  useEffect(() => {
    if (subSearchData != null) {
      acquisitionGetDetail.mutate({ code: subSearchData });
    }
  }, [subSearchData]);

  const btn_recent_register = () => {
    if (window.confirm("저장하시겠습니까?")) {
      acquisitionSaveGolf.mutate(
        mainModifyList.map(el => {
          return {
            ...el,
            page: 1,
          };
        })
      );
    }
  };

  const btn_mainTableRowClick = data => {
    setSubSearchData(data.code);
    // acquisitionGetDetail.mutate({ code: data.code });
  };

  const btn_mainTable_print = () => {
    if (window.confirm("출력을 하시겠습니까?")) {
      acquisitionPrint.mutate();
    }
  };

  const btn_mainTable_new_register = () => {
    if (mainSearchedTableList.some(el => el.newCheck === true)) {
      alert("이미 추가된 항목이 있습니다.");
      return;
    }

    setMainSearchedTableList(prev => [
      {
        changeOx: getChangeOx.data[0].key,
        chpDate: moment().format("YYYY-MM-DD"),
        chpTax: 0,
        chpWon: 0,
        code: getAcquisition.data[0].key,
        custId: getAcquisition.data[0].custId,
        delCheck: "N",
        newCheck: true,
        page: 1,
      },
      ...prev,
    ]);

    setCurrentMainIdx(currentMainIdx + 1);
  };

  const btn_mainTable_register = () => {
    if (window.confirm("저장하시겠습니까?")) {
      if (!mainSearchedTableList[0].newCheck) {
        alert("저장할 데이터가 없습니다.");
        return;
      }

      acquisitionSaveGolf.mutate([mainSearchedTableList[0]]);
    }
  };

  const btn_mainTable_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      acquisitionSearch.refetch();
      setMainModifyList([]);
      setCurrentMainIdx(currentMainIdx - 1);
    }
  };

  const btn_mainTable_delete = data => {
    if (window.confirm("삭제하시겠습니까?")) {
      acquisitionDeleteGolf.mutate([
        {
          chpDate: data.chpDate,
          code: data.code,
          custId: data.custId,
        },
      ]);
    }
  };

  const btn_subTable_new_register = () => {
    setSubSearchedTableList(prev => [
      {
        changeOx: getChangeOx.data[0].key,
        chpDate: moment().format("YYYY-MM-DD"),
        chpTax: 0,
        chpWon: 0,
        code: subSearchedTableList[0].code,
        custId: subSearchedTableList[0].custId,
        delCheck: "N",
        newCheck: true,
        page: 1,
      },
      ...prev,
    ]);

    setSubModifyList(prev => [true, ...prev]);
  };

  const btn_subTable_register = () => {
    if (window.confirm("추가하시겠습니까?")) {
      if (subModifyList.every(el => el === false)) {
        alert("추가할 항목이 없습니다.");
        return;
      }

      const filter = subSearchedTableList.filter((el, idx) => subModifyList[idx]);

      acquisitionSaveGolfDetail.mutate(
        filter.map(el => ({
          changeOx: el.changeOx,
          chpDate: moment(el.chpDate).format("YYYY-MM-DD"),
          chpTax: el.chpTax,
          chpWon: el.chpWon,
          code: el.code,
          custId: el.custId,
          delCheck: el.delCheck,
          newCheck: el.newCheck,
          page: 1,
        }))
      );
    }
  };

  const btn_subTable_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      acquisitionGetDetail.mutate({ code: subSearchData });

      // acquisitionGetDetail.mutate({ code: subSearchData });
      // setSubModifyList([]);
      // setSubDeleteList()
    }
  };

  const btn_subTable_delete = data => {
    if (window.confirm("삭제하시겠습니까?")) {
      if (subDeleteList.every(el => el === false)) {
        alert("삭제할 항목을 선택해주세요.");
        return;
      }

      const filter = subSearchedTableList.filter((el, idx) => subDeleteList[idx]);

      acquisitionDeleteGolfDetail.mutate(
        filter.map(el => ({
          chpDate: moment(el.chpDate).format("YYYY-MM-DD"),
          code: el.code,
          custId: el.custId,
          delCheck: el.delCheck,
        }))
      );
    }
  };

  const mainRowVirtualizer = useVirtualizer({
    count: mainSearchedTableList?.length,
    getScrollElement: () => mainRef.current,
    estimateSize: () => 30,
  });

  const subRowVirtualizer = useVirtualizer({
    count: subSearchedTableList?.length,
    getScrollElement: () => subRef.current,
    estimateSize: () => 30,
  });

  const onChangeJisaCheckBox = val => {
    if (mainSearchData.searchType === val)
      setMainSearchData({
        ...mainSearchData,
        searchType: "",
      });
    else
      setMainSearchData({
        ...mainSearchData,
        searchType: val,
      });
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">취득과표/전환유무 관리</div>
          </div>
          <div className="row gx-3">
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title m-0">골프장/종목별 가장 최근 취득과표 검색</h5>
                </div>
                <div className="card-body">
                  <div className="filter_fields_row justify-content-start">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="filter1-field filter1-field1 ps-0">
                        <label className="filter1-label">골프장</label>
                        <select
                          className="form-select filter1-select1"
                          value={mainSearchData.custId}
                          onChange={e =>
                            setMainSearchData({
                              ...mainSearchData,
                              custId: e.target.value,
                              searchType: "",
                            })
                          }
                        >
                          <option value="00">전체-00</option>
                          {getGolfCustList.data?.map(el => {
                            return <option value={el.key}>{el.value}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="d-flex ps-20px" style={{ gap: "20px" }}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="chk1"
                          checked={mainSearchData.searchType === "1"}
                          onChange={e => onChangeJisaCheckBox("1")}
                        />
                        <label className="form-check-label" htmlFor="chk1">
                          서울본사
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk2"
                          checked={mainSearchData.searchType === "2"}
                          onChange={e => onChangeJisaCheckBox("2")}
                        />
                        <label className="form-check-label" htmlFor="chk2">
                          대전지사
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk3"
                          checked={mainSearchData.searchType === "3"}
                          onChange={e => onChangeJisaCheckBox("3")}
                        />
                        <label className="form-check-label" htmlFor="chk3">
                          대구지사
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk4"
                          checked={mainSearchData.searchType === "4"}
                          onChange={e => onChangeJisaCheckBox("4")}
                        />
                        <label className="form-check-label" htmlFor="chk4">
                          부산지사
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      type="text"
                      className="form-control table-input mt-3"
                      value={
                        mainSearchData.searchType === ""
                          ? "전체 지사"
                          : mainSearchData.searchType === "1"
                            ? "서울,경기,인천,강원지역 소재골프장"
                            : mainSearchData.searchType === "2"
                              ? "충청,대전,전북지역 소재골프장"
                              : mainSearchData.searchType === "3"
                                ? "대구,경북,전남,광주지역 소재골프장"
                                : mainSearchData.searchType === "4"
                                  ? "부산,울산,경남,제주지역 소재골프장"
                                  : ""
                      }
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="card-footer">
                  <div className="w-100 d-flex justify-content-end" style={{ gap: "8px" }}>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={btn_mainTable_print}
                    >
                      출력
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={btn_mainTable_new_register}
                    >
                      추가
                    </button>
                    {mainSearchedTableList[0]?.newCheck && (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={btn_mainTable_register}
                        >
                          저장
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={btn_mainTable_cancel}
                        >
                          취소
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-14px">
                <div className="card">
                  <div className="card-header">
                    <div className="flex-center-between w-100">
                      <h5 className="card-title m-0">골프장/종목별 가장 최근 취득과표</h5>
                      <div className="footer-txt2">금액단위 : 원</div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="overflow-auto table-container table-sticky h-633" ref={mainRef}>
                      <table
                        className="table table-bordered table-responsive my-table"
                        style={{
                          width: `${mainSearchedTableList.length !== 0 ? "1200px" : ""}`,
                          marginTop: `${(mainRowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                          marginBottom: `${mainRowVirtualizer.getTotalSize() -
                            (mainRowVirtualizer.range?.startIndex ?? 0) * 30 -
                            mainRowVirtualizer.getVirtualItems().length * 30
                            }px`,
                        }}
                      >
                        <colgroup>
                          <col width="2%" />
                          <col width="2%" />
                          <col width="2%" />
                          <col width="10%" />
                          <col width="4%" />
                          <col width="4%" />
                          <col width="4%" />
                          <col width="4%" />
                          <col width="4%" />
                          <col width="3%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>제명</th>
                            <th>제명유무</th>
                            <th>회원권명</th>
                            <th>전환유무</th>
                            <th>고시일자</th>
                            <th>취득과표</th>
                            <th>취득세 (2.2%)</th>
                            <th>수정자</th>
                            <th>수정일시</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mainSearchedTableList?.length !== 0 ? (
                            mainRowVirtualizer.getVirtualItems()?.map((virtualRow, index) => {
                              return (
                                <tr
                                  className={`cursor-pointer ${mainSearchedTableList[virtualRow.index].delCheck === "Y"
                                    ? "bg-red"
                                    : ""
                                    } ${currentMainIdx === virtualRow.index ? "bg-blue" : ""}`}
                                  onClick={() => {
                                    if (mainSearchedTableList.some(el2 => el2.newCheck === true)) {
                                      alert("추가부터 처리해주세요");
                                      return;
                                    }

                                    btn_mainTableRowClick(mainSearchedTableList[virtualRow.index]);
                                    setCurrentMainIdx(virtualRow.index);
                                  }}
                                >
                                  <td>{virtualRow.index + 1}</td>
                                  <td
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {!mainSearchedTableList[virtualRow.index].newCheck &&
                                      mainSearchedTableList[virtualRow.index].delCheck === "N" && (
                                        <img
                                          src={process.env.PUBLIC_URL + "/images/delete.png"}
                                          onClick={() =>
                                            btn_mainTable_delete(
                                              mainSearchedTableList[virtualRow.index]
                                            )
                                          }
                                          alt=""
                                        />
                                      )}
                                  </td>
                                  <td>
                                    {mainSearchedTableList[virtualRow.index].delCheck === "Y"
                                      ? "Y"
                                      : "N"}
                                  </td>
                                  <td
                                    className="text-start"
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {mainSearchedTableList[virtualRow.index].newCheck ? (
                                      <select
                                        className="form-select"
                                        value={mainSearchedTableList[virtualRow.index].code}
                                        onChange={e => {
                                          const newTotalSearchedData = [...mainSearchedTableList];
                                          newTotalSearchedData[virtualRow.index].code =
                                            e.target.value;
                                          newTotalSearchedData[virtualRow.index].custId =
                                            getAcquisition.data?.find(
                                              el => el.key === e.target.value
                                            )?.custId;
                                          setMainSearchedTableList(newTotalSearchedData);
                                        }}
                                      >
                                        {getAcquisition.data?.map(el => (
                                          <option value={el.key}>{el.value}</option>
                                        ))}
                                      </select>
                                    ) : (
                                      getAcquisition.data?.find(
                                        el =>
                                          el.key === mainSearchedTableList[virtualRow.index].code
                                      )?.value
                                    )}
                                  </td>
                                  <td
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {mainSearchedTableList[virtualRow.index].newCheck ? (
                                      <select
                                        className="form-select"
                                        value={mainSearchedTableList[virtualRow.index].changeOx}
                                        onChange={e => {
                                          const newTotalSearchedData = [...mainSearchedTableList];
                                          newTotalSearchedData[virtualRow.index].changeOx =
                                            e.target.value;
                                          setMainSearchedTableList(newTotalSearchedData);
                                        }}
                                      >
                                        {getChangeOx.data?.map(el => (
                                          <option value={el.key}>{el.value}</option>
                                        ))}
                                      </select>
                                    ) : (
                                      getChangeOx.data?.find(
                                        el2 =>
                                          el2.key ===
                                          mainSearchedTableList[virtualRow.index].changeOx
                                      )?.value
                                    )}
                                  </td>
                                  <td
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {mainSearchedTableList[virtualRow.index].newCheck ? (
                                      <input
                                        type="date"
                                        className="form-control"
                                        value={mainSearchedTableList[virtualRow.index].chpDate}
                                        onChange={e => {
                                          const newTotalSearchedData = [...mainSearchedTableList];
                                          newTotalSearchedData[virtualRow.index].chpDate =
                                            e.target.value;
                                          setMainSearchedTableList(newTotalSearchedData);
                                        }}
                                        max="9999-12-31"
                                      />
                                    ) : (
                                      moment(
                                        mainSearchedTableList[virtualRow.index].chpDate
                                      ).format("YYYY-MM-DD")
                                    )}
                                  </td>
                                  <td
                                    className="text-end"
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {mainSearchedTableList[virtualRow.index].newCheck ? (
                                      <input
                                        type="text"
                                        className="form-control filter-input text-end"
                                        value={mainSearchedTableList[
                                          virtualRow.index
                                        ].chpWon.toLocaleString()}
                                        onChange={e => {
                                          const newTotalSearchedData = [...mainSearchedTableList];
                                          newTotalSearchedData[virtualRow.index].chpWon =
                                            +e.target.value
                                              .replace(/,/g, "")
                                              .replace(/[^0-9]/g, "");
                                          newTotalSearchedData[virtualRow.index].chpTax =
                                            Math.floor(
                                              (+e.target.value
                                                .replace(/,/g, "")
                                                .replace(/[^0-9]/g, "") *
                                                0.022) /
                                              10
                                            ).toFixed() * 10;

                                          setMainSearchedTableList(newTotalSearchedData);
                                        }}
                                      />
                                    ) : (
                                      mainSearchedTableList[
                                        virtualRow.index
                                      ].chpWon.toLocaleString()
                                    )}
                                  </td>
                                  <td
                                    className="text-end"
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {(
                                      Math.floor(
                                        (+mainSearchedTableList[virtualRow.index].chpWon * 0.022) /
                                        10
                                      ).toFixed() * 10
                                    ).toLocaleString()}
                                  </td>
                                  <td
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {
                                      getUserIdQ.data?.find(
                                        el2 =>
                                          el2.key === mainSearchedTableList[virtualRow.index].modid
                                      )?.value
                                    }
                                  </td>
                                  <td
                                    onClick={e =>
                                      mainSearchedTableList[virtualRow.index].newCheck &&
                                      e.stopPropagation()
                                    }
                                  >
                                    {mainSearchedTableList[virtualRow.index].modDate
                                      ? moment(
                                        mainSearchedTableList[virtualRow.index].modDate
                                      ).format("YYYY-MM-DD")
                                      : ""}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={9}>검색된 결과가 없습니다.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title m-0">종목별 전체 취득과표 검색</h5>
                </div>

                <div className="card-body">
                  <div className="filter_fields_row">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="filter1-field filter1-field1 ps-0">
                        <label className="filter1-label" style={{ whiteSpace: "nowrap" }}>
                          회원권종목명
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={getAcquisition.data?.find(el => el.key === subSearchData)?.value}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <div className="footer-txt1">
                        기존종목의 새로운 취득과표 발표는 여기에서 추가하세요
                      </div>
                      <div className="footer-txt2">금액단위 : 원</div>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="w-100 d-flex justify-content-end" style={{ gap: "8px" }}>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={btn_subTable_new_register}
                    >
                      추가
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={btn_subTable_delete}
                    >
                      삭제
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={btn_subTable_register}
                    >
                      저장
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={btn_subTable_cancel}
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>

              <div className="card mt-14px">
                <div className="card-header">
                  <div className="flex-center-between w-100">
                    <h5 className="card-title m-0">종목별 전체 취득과표</h5>
                    <div className="footer-txt2">금액단위 : 원</div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="overflow-auto table-container table-sticky h-633" ref={subRef}>
                    <table
                      className="table table-bordered table-responsive my-table"
                      style={{
                        width: `${subSearchedTableList.length !== 0 ? "1200px" : ""}`,
                        marginTop: `${(subRowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                        marginBottom: `${subRowVirtualizer.getTotalSize() -
                          (subRowVirtualizer.range?.startIndex ?? 0) * 30 -
                          subRowVirtualizer.getVirtualItems().length * 30
                          }px`,
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "3%" }}>삭제</th>
                          <th style={{ width: "4%" }}>No.</th>
                          <th style={{ width: "5%" }}>고시일자</th>
                          <th style={{ width: "10%" }}>취득과표</th>
                          <th style={{ width: "5%" }}>취득세</th>
                          <th style={{ width: "10%" }}>전환유무</th>
                          <th style={{ width: "5%" }}>수정자</th>
                          <th style={{ width: "5%" }}>수정일시</th>
                          {/*<th style={{ width: "10%" }}>삭제</th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {subSearchedTableList.length !== 0 ? (
                          subRowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                            return (
                              <>
                                <tr
                                  className={`${subSearchedTableList[virtualRow.index].delCheck === "Y"
                                    ? "bg-red"
                                    : ""
                                    }`}
                                // onClick={() =>
                                //   btn_mainTableRowClick(subSearchedTableList[virtualRow.index])
                                // }
                                >
                                  <td
                                    onClick={e => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={subDeleteList[virtualRow.index]}
                                      onChange={e => {
                                        const newModifyList = [...subDeleteList];
                                        newModifyList[virtualRow.index] = e.target.checked;
                                        setSubDeleteList(newModifyList);
                                      }}
                                      readOnly={true}
                                    />
                                  </td>
                                  <td>{virtualRow.index + 1}</td>
                                  <td>
                                    {subSearchedTableList[virtualRow.index].newCheck === true ? (
                                      <input
                                        type="date"
                                        className="form-control"
                                        value={subSearchedTableList[virtualRow.index].chpDate}
                                        onChange={e => {
                                          const newTotalSearchedData = [...subSearchedTableList];
                                          newTotalSearchedData[virtualRow.index].chpDate =
                                            e.target.value;
                                          setSubSearchedTableList(newTotalSearchedData);

                                          const newModifyList = [...subModifyList];
                                          newModifyList[virtualRow.index] = true;
                                          setSubModifyList(newModifyList);
                                        }}
                                        max="9999-12-31"
                                      />
                                    ) : (
                                      subSearchedTableList[virtualRow.index].chpDate
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control text-end"
                                      value={subSearchedTableList[
                                        virtualRow.index
                                      ].chpWon.toLocaleString()}
                                      onChange={e => {
                                        const newTotalSearchedData = [...subSearchedTableList];
                                        newTotalSearchedData[virtualRow.index].chpWon =
                                          +e.target.value.replace(/,/g, "").replace(/[^0-9]/g, "");
                                        setSubSearchedTableList(newTotalSearchedData);

                                        newTotalSearchedData[virtualRow.index].chpTax =
                                          Math.floor(
                                            (+e.target.value
                                              .replace(/,/g, "")
                                              .replace(/[^0-9]/g, "") *
                                              0.022) /
                                            10
                                          ).toFixed() * 10;
                                        setSubSearchedTableList(newTotalSearchedData);

                                        const newModifyList = [...subModifyList];
                                        newModifyList[virtualRow.index] = true;
                                        setSubModifyList(newModifyList);
                                      }}
                                    />
                                  </td>
                                  <td className="text-end">
                                    {(
                                      Math.floor(
                                        (+subSearchedTableList[virtualRow.index].chpWon * 0.022) /
                                        10
                                      ).toFixed() * 10
                                    ).toLocaleString()}
                                  </td>
                                  <td>
                                    <select
                                      className="form-select"
                                      value={subSearchedTableList[virtualRow.index].changeOx}
                                      onChange={e => {
                                        const newTotalSearchedData = [...subSearchedTableList];
                                        newTotalSearchedData[virtualRow.index].changeOx =
                                          e.target.value;
                                        setSubSearchedTableList(newTotalSearchedData);

                                        const newModifyList = [...subModifyList];
                                        newModifyList[virtualRow.index] = true;
                                        setSubModifyList(newModifyList);
                                      }}
                                    >
                                      {getChangeOx.data?.map(el => {
                                        return <option value={el.key}>{el.value}</option>;
                                      })}
                                    </select>
                                  </td>
                                  <td>
                                    {
                                      getUserIdQ.data?.find(
                                        el2 =>
                                          el2.key === subSearchedTableList[virtualRow.index].modid
                                      )?.value
                                    }
                                  </td>
                                  <td>
                                    {subSearchedTableList[virtualRow.index].modDate
                                      ? moment(
                                        subSearchedTableList[virtualRow.index].modDate
                                      ).format("YYYY-MM-DD")
                                      : ""}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={9}>검색된 결과가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-3">
            {/*<div className="col-6 mt-14px">*/}
            {/*  <div className="card">*/}
            {/*    <div className="card-header">*/}
            {/*      <h5 className="card-title m-0">골프장/종목별 가장 최근 취득과표</h5>*/}
            {/*    </div>*/}

            {/*    <div className="card-body">*/}
            {/*      <div className="overflow-auto table-container table-sticky h-633" ref={mainRef}>*/}
            {/*        <table*/}
            {/*          className="table table-bordered table-responsive my-table"*/}
            {/*          style={{*/}
            {/*            width: `${mainSearchedTableList.length !== 0 ? "1200px" : ""}`,*/}
            {/*            marginTop: `${(mainRowVirtualizer.range?.startIndex ?? 0) * 30}px`,*/}
            {/*            marginBottom: `${*/}
            {/*              mainRowVirtualizer.getTotalSize() -*/}
            {/*              (mainRowVirtualizer.range?.startIndex ?? 0) * 30 -*/}
            {/*              mainRowVirtualizer.getVirtualItems().length * 30*/}
            {/*            }px`,*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <colgroup>*/}
            {/*            <col width="2%" />*/}
            {/*            <col width="2%" />*/}
            {/*            <col width="2%" />*/}
            {/*            <col width="10%" />*/}
            {/*            <col width="4%" />*/}
            {/*            <col width="4%" />*/}
            {/*            <col width="4%" />*/}
            {/*            <col width="4%" />*/}
            {/*            <col width="4%" />*/}
            {/*            <col width="3%" />*/}
            {/*          </colgroup>*/}
            {/*          <thead>*/}
            {/*            <tr>*/}
            {/*              <th>No</th>*/}
            {/*              <th>제명</th>*/}
            {/*              <th>제명유무</th>*/}
            {/*              <th>회원권명</th>*/}
            {/*              <th>전환유무</th>*/}
            {/*              <th>고시일자</th>*/}
            {/*              <th>취득과표</th>*/}
            {/*              <th>취득세 (2.2%)</th>*/}
            {/*              <th>수정자</th>*/}
            {/*              <th>수정일시</th>*/}
            {/*            </tr>*/}
            {/*          </thead>*/}
            {/*          <tbody>*/}
            {/*            {mainSearchedTableList?.length !== 0 ? (*/}
            {/*              mainRowVirtualizer.getVirtualItems()?.map((virtualRow, index) => {*/}
            {/*                return (*/}
            {/*                  <tr*/}
            {/*                    className={`cursor-pointer ${*/}
            {/*                      mainSearchedTableList[virtualRow.index].delCheck === "Y"*/}
            {/*                        ? "bg-red"*/}
            {/*                        : ""*/}
            {/*                    } ${currentMainIdx === virtualRow.index ? "bg-blue" : ""}`}*/}
            {/*                    onClick={() => {*/}
            {/*                      if (mainSearchedTableList.some(el2 => el2.newCheck === true)) {*/}
            {/*                        alert("추가부터 처리해주세요");*/}
            {/*                        return;*/}
            {/*                      }*/}

            {/*                      btn_mainTableRowClick(mainSearchedTableList[virtualRow.index]);*/}
            {/*                      setCurrentMainIdx(virtualRow.index);*/}
            {/*                    }}*/}
            {/*                  >*/}
            {/*                    <td>{virtualRow.index + 1}</td>*/}
            {/*                    <td*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {!mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        mainSearchedTableList[virtualRow.index].delCheck === "N" && (*/}
            {/*                          <img*/}
            {/*                            src={process.env.PUBLIC_URL + "/images/delete.png"}*/}
            {/*                            onClick={() =>*/}
            {/*                              btn_mainTable_delete(*/}
            {/*                                mainSearchedTableList[virtualRow.index]*/}
            {/*                              )*/}
            {/*                            }*/}
            {/*                            alt=""*/}
            {/*                          />*/}
            {/*                        )}*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                      {mainSearchedTableList[virtualRow.index].delCheck === "Y"*/}
            {/*                        ? "Y"*/}
            {/*                        : "N"}*/}
            {/*                    </td>*/}
            {/*                    <td*/}
            {/*                      className="text-start"*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {mainSearchedTableList[virtualRow.index].newCheck ? (*/}
            {/*                        <select*/}
            {/*                          className="form-select"*/}
            {/*                          value={mainSearchedTableList[virtualRow.index].code}*/}
            {/*                          onChange={e => {*/}
            {/*                            const newTotalSearchedData = [...mainSearchedTableList];*/}
            {/*                            newTotalSearchedData[virtualRow.index].code =*/}
            {/*                              e.target.value;*/}
            {/*                            newTotalSearchedData[virtualRow.index].custId =*/}
            {/*                              getAcquisition.data?.find(*/}
            {/*                                el => el.key === e.target.value*/}
            {/*                              )?.custId;*/}
            {/*                            setMainSearchedTableList(newTotalSearchedData);*/}
            {/*                          }}*/}
            {/*                        >*/}
            {/*                          {getAcquisition.data?.map(el => (*/}
            {/*                            <option value={el.key}>{el.value}</option>*/}
            {/*                          ))}*/}
            {/*                        </select>*/}
            {/*                      ) : (*/}
            {/*                        getAcquisition.data?.find(*/}
            {/*                          el => el.key === mainSearchedTableList[virtualRow.index].code*/}
            {/*                        )?.value*/}
            {/*                      )}*/}
            {/*                    </td>*/}
            {/*                    <td*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {mainSearchedTableList[virtualRow.index].newCheck ? (*/}
            {/*                        <select*/}
            {/*                          className="form-select"*/}
            {/*                          value={mainSearchedTableList[virtualRow.index].changeOx}*/}
            {/*                          onChange={e => {*/}
            {/*                            const newTotalSearchedData = [...mainSearchedTableList];*/}
            {/*                            newTotalSearchedData[virtualRow.index].changeOx =*/}
            {/*                              e.target.value;*/}
            {/*                            setMainSearchedTableList(newTotalSearchedData);*/}
            {/*                          }}*/}
            {/*                        >*/}
            {/*                          {getChangeOx.data?.map(el => (*/}
            {/*                            <option value={el.key}>{el.value}</option>*/}
            {/*                          ))}*/}
            {/*                        </select>*/}
            {/*                      ) : (*/}
            {/*                        getChangeOx.data?.find(*/}
            {/*                          el2 =>*/}
            {/*                            el2.key === mainSearchedTableList[virtualRow.index].changeOx*/}
            {/*                        )?.value*/}
            {/*                      )}*/}
            {/*                    </td>*/}
            {/*                    <td*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {mainSearchedTableList[virtualRow.index].newCheck ? (*/}
            {/*                        <input*/}
            {/*                          type="date"*/}
            {/*                          className="form-control"*/}
            {/*                          value={mainSearchedTableList[virtualRow.index].chpDate}*/}
            {/*                          onChange={e => {*/}
            {/*                            const newTotalSearchedData = [...mainSearchedTableList];*/}
            {/*                            newTotalSearchedData[virtualRow.index].chpDate =*/}
            {/*                              e.target.value;*/}
            {/*                            setMainSearchedTableList(newTotalSearchedData);*/}
            {/*                          }}*/}
            {/*                          max="9999-12-31"*/}
            {/*                        />*/}
            {/*                      ) : (*/}
            {/*                        moment(mainSearchedTableList[virtualRow.index].chpDate).format(*/}
            {/*                          "YYYY-MM-DD"*/}
            {/*                        )*/}
            {/*                      )}*/}
            {/*                    </td>*/}
            {/*                    <td*/}
            {/*                      className="text-end"*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {mainSearchedTableList[virtualRow.index].newCheck ? (*/}
            {/*                        <input*/}
            {/*                          type="text"*/}
            {/*                          className="form-control filter-input text-end"*/}
            {/*                          value={mainSearchedTableList[*/}
            {/*                            virtualRow.index*/}
            {/*                          ].chpWon.toLocaleString()}*/}
            {/*                          onChange={e => {*/}
            {/*                            const newTotalSearchedData = [...mainSearchedTableList];*/}
            {/*                            newTotalSearchedData[virtualRow.index].chpWon =*/}
            {/*                              +e.target.value.replace(/,/g, "").replace(/[^0-9]/g, "");*/}
            {/*                            newTotalSearchedData[virtualRow.index].chpTax =*/}
            {/*                              +e.target.value.replace(/,/g, "").replace(/[^0-9]/g, "") **/}
            {/*                              0.022;*/}

            {/*                            setMainSearchedTableList(newTotalSearchedData);*/}
            {/*                          }}*/}
            {/*                        />*/}
            {/*                      ) : (*/}
            {/*                        mainSearchedTableList[virtualRow.index].chpWon.toLocaleString()*/}
            {/*                      )}*/}
            {/*                    </td>*/}
            {/*                    <td*/}
            {/*                      className="text-end"*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {(+mainSearchedTableList[virtualRow.index]*/}
            {/*                        .chpTax).toLocaleString()}*/}
            {/*                    </td>*/}
            {/*                    <td*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {*/}
            {/*                        getUserIdQ.data?.find(*/}
            {/*                          el2 =>*/}
            {/*                            el2.key === mainSearchedTableList[virtualRow.index].modid*/}
            {/*                        )?.value*/}
            {/*                      }*/}
            {/*                    </td>*/}
            {/*                    <td*/}
            {/*                      onClick={e =>*/}
            {/*                        mainSearchedTableList[virtualRow.index].newCheck &&*/}
            {/*                        e.stopPropagation()*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      {mainSearchedTableList[virtualRow.index].modDate*/}
            {/*                        ? moment(*/}
            {/*                            mainSearchedTableList[virtualRow.index].modDate*/}
            {/*                          ).format("YYYY-MM-DD")*/}
            {/*                        : ""}*/}
            {/*                    </td>*/}
            {/*                  </tr>*/}
            {/*                );*/}
            {/*              })*/}
            {/*            ) : (*/}
            {/*              <tr>*/}
            {/*                <td colSpan={9}>검색된 결과가 없습니다.</td>*/}
            {/*              </tr>*/}
            {/*            )}*/}
            {/*          </tbody>*/}
            {/*        </table>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="col-6 mt-14px">*/}
            {/*  <div className="card">*/}
            {/*    <div className="card-header">*/}
            {/*      <h5 className="card-title m-0">종목별 전체 취득과표</h5>*/}
            {/*    </div>*/}

            {/*    <div className="card-body">*/}
            {/*      <div className="overflow-auto table-container table-sticky h-633" ref={subRef}>*/}
            {/*        <table*/}
            {/*          className="table table-bordered table-responsive my-table"*/}
            {/*          style={{*/}
            {/*            width: `${subSearchedTableList.length !== 0 ? "1200px" : ""}`,*/}
            {/*            marginTop: `${(subRowVirtualizer.range?.startIndex ?? 0) * 30}px`,*/}
            {/*            marginBottom: `${*/}
            {/*              subRowVirtualizer.getTotalSize() -*/}
            {/*              (subRowVirtualizer.range?.startIndex ?? 0) * 30 -*/}
            {/*              subRowVirtualizer.getVirtualItems().length * 30*/}
            {/*            }px`,*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <thead>*/}
            {/*            <tr>*/}
            {/*              <th style={{ width: "3%" }}>삭제</th>*/}
            {/*              <th style={{ width: "4%" }}>No.</th>*/}
            {/*              <th style={{ width: "5%" }}>고시일자</th>*/}
            {/*              <th style={{ width: "10%" }}>취득과표</th>*/}
            {/*              <th style={{ width: "5%" }}>취득세</th>*/}
            {/*              <th style={{ width: "10%" }}>전환유무</th>*/}
            {/*              <th style={{ width: "5%" }}>수정자</th>*/}
            {/*              <th style={{ width: "5%" }}>수정일시</th>*/}
            {/*              /!*<th style={{ width: "10%" }}>삭제</th>*!/*/}
            {/*            </tr>*/}
            {/*          </thead>*/}
            {/*          <tbody>*/}
            {/*            {subSearchedTableList.length !== 0 ? (*/}
            {/*              subRowVirtualizer.getVirtualItems().map((virtualRow, index) => {*/}
            {/*                return (*/}
            {/*                  <>*/}
            {/*                    <tr*/}
            {/*                      className={`${*/}
            {/*                        subSearchedTableList[virtualRow.index].delCheck === "Y"*/}
            {/*                          ? "bg-red"*/}
            {/*                          : ""*/}
            {/*                      }`}*/}
            {/*                      // onClick={() =>*/}
            {/*                      //   btn_mainTableRowClick(subSearchedTableList[virtualRow.index])*/}
            {/*                      // }*/}
            {/*                    >*/}
            {/*                      <td*/}
            {/*                        onClick={e => {*/}
            {/*                          e.stopPropagation();*/}
            {/*                        }}*/}
            {/*                      >*/}
            {/*                        <input*/}
            {/*                          type="checkbox"*/}
            {/*                          checked={subDeleteList[virtualRow.index]}*/}
            {/*                          onChange={e => {*/}
            {/*                            const newModifyList = [...subDeleteList];*/}
            {/*                            newModifyList[virtualRow.index] = e.target.checked;*/}
            {/*                            setSubDeleteList(newModifyList);*/}
            {/*                          }}*/}
            {/*                          readOnly={true}*/}
            {/*                        />*/}
            {/*                      </td>*/}
            {/*                      <td>{virtualRow.index + 1}</td>*/}
            {/*                      <td>*/}
            {/*                        {subSearchedTableList[virtualRow.index].newCheck === true ? (*/}
            {/*                          <input*/}
            {/*                            type="date"*/}
            {/*                            className="form-control"*/}
            {/*                            value={subSearchedTableList[virtualRow.index].chpDate}*/}
            {/*                            onChange={e => {*/}
            {/*                              const newTotalSearchedData = [...subSearchedTableList];*/}
            {/*                              newTotalSearchedData[virtualRow.index].chpDate =*/}
            {/*                                e.target.value;*/}
            {/*                              setSubSearchedTableList(newTotalSearchedData);*/}

            {/*                              const newModifyList = [...subModifyList];*/}
            {/*                              newModifyList[virtualRow.index] = true;*/}
            {/*                              setSubModifyList(newModifyList);*/}
            {/*                            }}*/}
            {/*                            max="9999-12-31"*/}
            {/*                          />*/}
            {/*                        ) : (*/}
            {/*                          subSearchedTableList[virtualRow.index].chpDate*/}
            {/*                        )}*/}
            {/*                      </td>*/}
            {/*                      <td>*/}
            {/*                        <input*/}
            {/*                          type="text"*/}
            {/*                          className="form-control text-end"*/}
            {/*                          value={subSearchedTableList[*/}
            {/*                            virtualRow.index*/}
            {/*                          ].chpWon.toLocaleString()}*/}
            {/*                          onChange={e => {*/}
            {/*                            const newTotalSearchedData = [...subSearchedTableList];*/}
            {/*                            newTotalSearchedData[virtualRow.index].chpWon =*/}
            {/*                              +e.target.value.replace(/,/g, "").replace(/[^0-9]/g, "");*/}
            {/*                            setSubSearchedTableList(newTotalSearchedData);*/}

            {/*                            newTotalSearchedData[virtualRow.index].chpTax =*/}
            {/*                              +e.target.value.replace(/,/g, "").replace(/[^0-9]/g, "") **/}
            {/*                              0.022;*/}
            {/*                            setSubSearchedTableList(newTotalSearchedData);*/}

            {/*                            const newModifyList = [...subModifyList];*/}
            {/*                            newModifyList[virtualRow.index] = true;*/}
            {/*                            setSubModifyList(newModifyList);*/}
            {/*                          }}*/}
            {/*                        />*/}
            {/*                      </td>*/}
            {/*                      <td className="text-end">*/}
            {/*                        {(+subSearchedTableList[virtualRow.index]*/}
            {/*                          .chpTax).toLocaleString()}*/}
            {/*                      </td>*/}
            {/*                      <td>*/}
            {/*                        <select*/}
            {/*                          className="form-select"*/}
            {/*                          value={subSearchedTableList[virtualRow.index].changeOx}*/}
            {/*                          onChange={e => {*/}
            {/*                            const newTotalSearchedData = [...subSearchedTableList];*/}
            {/*                            newTotalSearchedData[virtualRow.index].changeOx =*/}
            {/*                              e.target.value;*/}
            {/*                            setSubSearchedTableList(newTotalSearchedData);*/}

            {/*                            const newModifyList = [...subModifyList];*/}
            {/*                            newModifyList[virtualRow.index] = true;*/}
            {/*                            setSubModifyList(newModifyList);*/}
            {/*                          }}*/}
            {/*                        >*/}
            {/*                          {getChangeOx.data?.map(el => {*/}
            {/*                            return <option value={el.key}>{el.value}</option>;*/}
            {/*                          })}*/}
            {/*                        </select>*/}
            {/*                      </td>*/}
            {/*                      <td>*/}
            {/*                        {*/}
            {/*                          getUserIdQ.data?.find(*/}
            {/*                            el2 =>*/}
            {/*                              el2.key === subSearchedTableList[virtualRow.index].modid*/}
            {/*                          )?.value*/}
            {/*                        }*/}
            {/*                      </td>*/}
            {/*                      <td>*/}
            {/*                        {subSearchedTableList[virtualRow.index].modDate*/}
            {/*                          ? moment(*/}
            {/*                              subSearchedTableList[virtualRow.index].modDate*/}
            {/*                            ).format("YYYY-MM-DD")*/}
            {/*                          : ""}*/}
            {/*                      </td>*/}
            {/*                    </tr>*/}
            {/*                  </>*/}
            {/*                );*/}
            {/*              })*/}
            {/*            ) : (*/}
            {/*              <tr>*/}
            {/*                <td colSpan={9}>검색된 결과가 없습니다.</td>*/}
            {/*              </tr>*/}
            {/*            )}*/}
            {/*          </tbody>*/}
            {/*        </table>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  );
};
