import React, { useEffect } from "react";
import {
  individualPerformance_currentTable_atom,
  individualPerformance_filteredJisaGubun_atom,
  individualPerformance_filteredSawonList_atom,
  individualPerformance_searchData_atom,
  individualPerformance_searchDataInit_atom,
  individualPerformance_searchedTableList_atom,
  individualPerformance_searchedTableListInit_atom,
} from "../../../data/atom/goodsManage/individualPerformance/individualPerformance_atom";
import { useAtom } from "jotai/index";
import moment from "moment";
import { useGetProfitJisaGubun } from "../../../api/code/useGetProfitJisaGubun";
import { useGetSawonList } from "../../../api/code/useGetSawonList";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { One } from "./page/one";
import { Two } from "./page/two";
import { Three } from "./page/three";
import { Four } from "./page/four";
import { Five } from "./page/five";
import { Six } from "./page/six";
import { Seven } from "./page/seven";
import { Eight } from "./page/eight";
import { Nine } from "./page/nine";
import { Ten } from "./page/ten";
import { Eleven } from "./page/eleven";
import { regex_only_number } from "../../../util/validation";
import { Twelve } from "./page/twelve";
import { Thirteen } from "./page/thirteen";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import usePrintTab from "../../../components/modal/usePrintTab";
import { decrypt } from "../../../util/decrypt/decrypt";
import lockAuth from "../../../util/lockAuth";

export const IndividualPerformance = () => {
  const { addTabClosedEvent } = useRecentTabEvent();
  const openPdf = usePrintTab();

  const [searchData, setSearchData] = useAtom(individualPerformance_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    individualPerformance_searchedTableList_atom
  );
  const [currentTable, setCurrentTable] = useAtom(individualPerformance_currentTable_atom);

  const getProfitJisaGubun = useGetProfitJisaGubun();
  const getSawonList = useGetSawonList();

  const [filteredSawonList, setFilteredSawonList] = useAtom(
    individualPerformance_filteredSawonList_atom
  );
  const [filteredJisaGubun, setFilteredJisaGubun] = useAtom(
    individualPerformance_filteredJisaGubun_atom
  );

  // 실적현황 검색
  const scheduleSearch = useMutation(
    data => axios.get("/api/schedule/profit/search", { params: data }),
    {
      onSuccess: res => {
        const newSet = new Set();

        // 테이블 컬럼 추출
        for (const i in res.data.result.result) {
          for (const j in res.data.result.result[i]) {
            newSet.add(j);
          }
        }

        // 테이블 로우 추출
        const rows = res.data.result?.memberList?.map(
          (el, index) =>
            ({
              jobName: el?.jobName,
              userName: el.userName,
              authName: el.authName,
              role:
                el.jobName === "본사"
                  ? "A"
                  : el.jobName === "마 스 터"
                  ? "AA"
                  : el.jobName === "회원권사업부"
                  ? "AB"
                  : el.jobName === "콘도사업부"
                  ? "AC"
                  : el.jobName === "분양사업부"
                  ? "AD"
                  : el.jobName === "지사"
                  ? "B"
                  : el.jobName === "대구지사"
                  ? "BE"
                  : el.jobName === "부산지사"
                  ? "BF"
                  : el.jobName === "호남지사" && "BG",
            }) ?? null
        );

        setSearchedTableList({
          ...res.data.result,
          tableColumns: [...newSet].sort((a, b) => a.localeCompare(b)),
          tableRows: rows,
        });

        setCurrentTable(searchData.searchType);
        setSearchData({ ...searchData, year: searchData.yearMnth ?? searchData.fromDate });
      },
    }
  );

  // 본사 및 지사별 실적현황 출력
  const jisaProfitMonthPrint = useMutation(
    data =>
      axios.get("/api/schedule/profit/jisaProfitMonthPrint", {
        params: data,
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        openPdf(res?.data);
      },
    }
  );

  // 주간실적현황 출력
  const lastWeekProfitPrint = useMutation(
    data =>
      axios.get("/api/schedule/profit/lastWeekProfitPrint", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        openPdf(res?.data);
      },
    }
  );

  // 개인별 월별 실적현황 출력
  const monthProfitPrint = useMutation(
    data =>
      axios.get("/api/schedule/profit/monthProfitPrint", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        openPdf(res?.data);
      },
    }
  );

  // 개인별 연간 실적현황 출력
  const yearProfitPrint = useMutation(
    data =>
      axios.get("/api/schedule/profit/yearProfitPrint", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        openPdf(res?.data);
      },
    }
  );

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSearchData(individualPerformance_searchDataInit_atom);
      setSearchedTableList(individualPerformance_searchedTableList_atom);
      setCurrentTable(individualPerformance_currentTable_atom);
      setFilteredSawonList([]);
      setFilteredJisaGubun([]);
    });
  }, []);

  // 성명 로그인 사용자로 설정
  useEffect(() => {
    if (searchData.userId === "" && getSawonList.data) {
      const userInfo = decrypt("userInfo");
      const id = userInfo.loginId;
      const jisa = userInfo.jisa;
      setSearchData(prev => ({ ...prev, userId: id }));

      if (lockAuth() === "1" || lockAuth() === "2") {
        // 전체 지사 조회 가능
        setFilteredSawonList(getSawonList.data);
      } else if (lockAuth() === "0") {
        // 본인이 속한 지사만
        setFilteredSawonList(getSawonList.data.filter(el => el.jisa === jisa));
      } else {
        // 본인 job만
        setFilteredSawonList(getSawonList.data.filter(el => el.userId === id));
      }
    }
  }, [getSawonList]);

  // 지사구분 로그인 사용자로 설정
  useEffect(() => {
    if (searchData.job === "" && getProfitJisaGubun.data) {
      const userInfo = decrypt("userInfo");
      const job = userInfo.job;
      const jisa = userInfo.jisa;

      let jisaArr;
      if (lockAuth() === "1" || lockAuth() === "2") {
        // 전체 지사 조회 가능
        jisaArr = getProfitJisaGubun.data;
      } else if (lockAuth() === "0") {
        // 본인이 속한 지사만
        jisaArr = getProfitJisaGubun.data.filter(el => el.jisa === jisa);
      } else {
        // 본인 job만
        jisaArr = getProfitJisaGubun.data.filter(el => el.key === job);
      }

      setFilteredJisaGubun(jisaArr);
      setSearchData(prev => ({ ...prev, job: jisaArr[0].key }));
    }
  }, [getProfitJisaGubun]);

  const btn_search = () => {
    if (searchData.searchType === "9") {
      if (moment(searchData.toDate).diff(searchData.fromDate, "weeks") > 5) {
        alert("검색기간이 5주간을 넘어가는 겨웅 검색시작일로부터 5주까지만 표기됩니다.!");
      }
    }

    setSearchedTableList(individualPerformance_searchedTableListInit_atom);

    scheduleSearch.mutate({
      ...searchData,
      isUserIdEnabled: null,
      yearMnth: searchData.yearMnth?.replaceAll("-", "") ?? null,
      fromDate: searchData.fromDate?.replaceAll("-", "") ?? null,
      toDate: searchData.toDate?.replaceAll("-", "") ?? null,
      // 개인조회가 비활성화일 때 lockAuth가 본인만 조회해야하면 셀렉트에 있는 id로 조회
      // 개인조회가 켜져있으면 무조건 셀렉트에 있는 id로 조회
      userId: !searchData.isUserIdEnabled
        ? lockAuth() === "-1"
          ? searchData.userId
          : ""
        : searchData.userId,
    });
  };

  const btn_print = () => {
    if (window.confirm("출력하시겠습니까?")) {
      if (searchData.searchType === "5") {
        lastWeekProfitPrint.mutate(searchData);
      } else if (searchData.searchType === "11") {
        monthProfitPrint.mutate(searchData);
      } else if (searchData.searchType === "12") {
        jisaProfitMonthPrint.mutate(searchData);
      } else if (searchData.searchType === "13") {
        yearProfitPrint.mutate(searchData);
      }
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <div className="content-inner">
      <div className="filter1_container">
        <div className="filter_header_row">
          <div className="filter-title">실적현황 검색</div>
          <div className="filter-buttons"></div>
        </div>
        <div className="filter_fields_container">
          <div className="filter_fields_row">
            <div
              className="d-flex align-items-center justify-content-between"
              onKeyDown={pressEnter}
            >
              <div className="filter1-field filter1-field2">
                <label className="filter1-label">실적조건</label>
                <select
                  className="form-select filter1-select1 w-200"
                  value={searchData.searchType}
                  onChange={e => {
                    if (e.target.value === "1" || e.target.value === "11") {
                      // 개일별 일별실적
                      // 개인별 월별 매출 현황
                      setSearchData({
                        ...searchData,
                        fromDate: null,
                        toDate: null,
                        searchType: e.target.value,
                        //job: getProfitJisaGubun.data[0].key,
                        userId: "",
                        isUserIdEnabled: false,
                        outCheck: "Y",
                        vatCheck: "Y",
                        weekGubn: null,
                        yearMnth:
                          e.target.value === "11"
                            ? moment().format("YYYY")
                            : moment().format("YYYY-MM"),
                      });
                    } else if (e.target.value === "2" || e.target.value === "3") {
                      // 요일별 일별실적
                      // 직급별 실적현황
                      setSearchData({
                        ...searchData,
                        fromDate: null,
                        toDate: null,
                        searchType: e.target.value,
                        //job: getProfitJisaGubun.data[0].key,
                        userId: null,
                        isUserIdEnabled: null,
                        outCheck: "Y",
                        vatCheck: "Y",
                        weekGubn: null,
                        yearMnth: moment().format("YYYY-MM"),
                      });
                    } else if (
                      e.target.value === "4" ||
                      e.target.value === "5" ||
                      e.target.value === "8" ||
                      e.target.value === "9"
                    ) {
                      // 팀별 실적현황
                      // 전 주 대비 금주 실적
                      // 월별/주간단위 실적
                      // 자사별 월별 매출 현황
                      setSearchData({
                        ...searchData,
                        fromDate:
                          e.target.value === "5" || e.target.value === "8" || e.target.value === "9"
                            ? moment().subtract(1, "week").format("YYYY-MM-DD")
                            : moment().subtract(1, "month").format("YYYY-MM"),
                        toDate:
                          e.target.value === "5" || e.target.value === "8" || e.target.value === "9"
                            ? moment().format("YYYY-MM-DD")
                            : moment().format("YYYY-MM"),
                        searchType: e.target.value,
                        //job: getProfitJisaGubun.data[0].key,
                        userId: null,
                        isUserIdEnabled: null,
                        outCheck: "Y",
                        vatCheck: "Y",
                        weekGubn: null,
                        yearMnth: null,
                      });
                    } else if (e.target.value === "6" || e.target.value === "13") {
                      // 팀별 연간 실적
                      // 개인별 연간 매출현황
                      setSearchData({
                        ...searchData,
                        fromDate:
                          e.target.value === "13"
                            ? moment().format("YYYY")
                            : moment().subtract(2, "month").format("YYYY-MM"),
                        toDate:
                          e.target.value === "13"
                            ? moment().add(4, "years").format("YYYY")
                            : moment().format("YYYY-MM"),
                        searchType: e.target.value,
                        //job: getProfitJisaGubun.data[0].key,
                        userId: "",
                        isUserIdEnabled: false,
                        outCheck: "Y",
                        vatCheck: "Y",
                        weekGubn: null,
                        yearMnth: null,
                      });
                    } else if (e.target.value === "10") {
                      // 딜러별 거래건수 현황
                      setSearchData({
                        ...searchData,
                        fromDate: null,
                        toDate: null,
                        searchType: e.target.value,
                        //job: getProfitJisaGubun.data[0].key,
                        userId: null,
                        isUserIdEnabled: null,
                        outCheck: "Y",
                        vatCheck: null,
                        weekGubn: null,
                        yearMnth: moment().format("YYYY-MM"),
                      });
                    } else if (e.target.value === "12") {
                      // 자사별 월별 매출 현황
                      setSearchData({
                        ...searchData,
                        fromDate: null,
                        toDate: null,
                        searchType: e.target.value,
                        //job: null,
                        userId: null,
                        isUserIdEnabled: null,
                        outCheck: "Y",
                        vatCheck: "Y",
                        weekGubn: null,
                        yearMnth: moment().format("YYYY"),
                      });
                    } else if (e.target.value === "7") {
                      // 개인 주차별 실적
                      setSearchData({
                        ...searchData,
                        fromDate: moment().subtract(2, "month").format("YYYY-MM-DD"),
                        toDate: moment().format("YYYY-MM-DD"),
                        searchType: e.target.value,
                        //job: getProfitJisaGubun.data[0].key,
                        userId: "",
                        isUserIdEnabled: false,
                        outCheck: "Y",
                        vatCheck: "Y",
                        weekGubn: null,
                        yearMnth: null,
                      });
                    }
                  }}
                >
                  <option value="1">개인별 일별실적</option>
                  <option value="2">요일별 일별실적</option>
                  <option value="3">직급별 실적현황</option>
                  <option value="4">팀별 실적현황</option>
                  <option value="5">전 주 대비 금주 실적</option>
                  <option value="6">팀별 연간 실적</option>
                  <option value="7">개인 주차별 실적</option>
                  <option value="8">문자/통화/주문/실적</option>
                  <option value="9">월별/주간단위 실적</option>
                  <option value="10">딜러별 거래건수 현황</option>
                  <option value="11">개인별 월별 매출 현황</option>
                  <option value="12">자사별 월별 매출 현황</option>
                  <option value="13">개인별 연간 매출현황</option>
                </select>
              </div>

              {searchData.yearMnth != null ? (
                <div className="filter1-field filter1-field2 m-0 p-0">
                  <label className="filter1-label ms-3">실적년월</label>

                  {(searchData.searchType === "1" ||
                    searchData.searchType === "2" ||
                    searchData.searchType === "3" ||
                    searchData.searchType === "10") && (
                    <input
                      type="month"
                      className="form-control filter-input filter1-input2"
                      value={searchData?.yearMnth}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          yearMnth: e.target.value,
                        });
                      }}
                      max="9999-12"
                    />
                  )}

                  {(searchData.searchType === "11" || searchData.searchType === "12") && (
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchData?.yearMnth}
                      onChange={e => {
                        if (regex_only_number.test(e.target.value)) {
                          setSearchData({
                            ...searchData,
                            yearMnth: e.target.value,
                          });
                        }
                      }}
                      maxLength={4}
                    />
                  )}
                </div>
              ) : (
                <div className="filter1-field filter1-field2 m-0 p-0">
                  <label className="filter1-label ms-3">실적년월</label>
                  {(searchData.searchType === "4" || searchData.searchType === "6") && (
                    <>
                      <input
                        type="month"
                        className="form-control filter-input filter1-input2"
                        value={searchData?.fromDate}
                        onChange={e => {
                          setSearchData({
                            ...searchData,
                            fromDate: e.target.value,
                          });
                        }}
                        max="9999-12"
                      />
                      <div className="txt_daterange">~</div>
                      <input
                        type="month"
                        className="form-control filter-input filter1-input2"
                        value={searchData?.toDate}
                        onChange={e => {
                          setSearchData({
                            ...searchData,
                            toDate: e.target.value,
                          });
                        }}
                        max="9999-12"
                      />
                    </>
                  )}

                  {/*{(searchData.searchType === "5" || searchData.searchType === "7") && (*/}
                  {/*  <>*/}
                  {/*    <input*/}
                  {/*      type="date"*/}
                  {/*      className="form-control filter-input filter1-input2"*/}
                  {/*      value={searchData?.fromDate}*/}
                  {/*      onChange={e => {*/}
                  {/*        setSearchData({*/}
                  {/*          ...searchData,*/}
                  {/*          fromDate: e.target.value,*/}
                  {/*        });*/}
                  {/*      }}*/}
                  {/*      max="9999-12-31"*/}
                  {/*    />*/}
                  {/*    <div className="txt_daterange">~</div>*/}
                  {/*    <input*/}
                  {/*      type="date"*/}
                  {/*      className="form-control filter-input filter1-input2"*/}
                  {/*      value={searchData?.toDate}*/}
                  {/*      onChange={e => {*/}
                  {/*        setSearchData({*/}
                  {/*          ...searchData,*/}
                  {/*          toDate: e.target.value,*/}
                  {/*        });*/}
                  {/*      }}*/}
                  {/*      max="9999-12-31"*/}
                  {/*    />*/}
                  {/*  </>*/}
                  {/*)}*/}

                  {(searchData.searchType === "5" ||
                    searchData.searchType === "7" ||
                    searchData.searchType === "8" ||
                    searchData.searchType === "9") && (
                    <>
                      <input
                        type="date"
                        className="form-control filter-input filter1-input2"
                        value={searchData?.fromDate}
                        onChange={e => {
                          setSearchData({
                            ...searchData,
                            fromDate: e.target.value,
                          });
                        }}
                        max="9999-12-31"
                      />
                      <div className="txt_daterange">~</div>
                      <input
                        type="date"
                        className="form-control filter-input filter1-input2"
                        value={searchData?.toDate}
                        onChange={e => {
                          setSearchData({
                            ...searchData,
                            toDate: e.target.value,
                          });
                        }}
                        max="9999-12-31"
                      />
                    </>
                  )}

                  {searchData.searchType === "13" && (
                    <>
                      <input
                        type="text"
                        className="form-control filter-input filter1-input2"
                        value={searchData?.fromDate}
                        onChange={e => {
                          if (regex_only_number.test(e.target.value)) {
                            setSearchData({
                              ...searchData,
                              fromDate: e.target.value,
                              toDate: (+e.target.value + 4).toString(),
                            });
                          }
                        }}
                        maxLength={4}
                      />
                      <div className="txt_daterange">~</div>
                      <input
                        type="text"
                        className="form-control filter-input filter1-input2"
                        value={searchData?.toDate}
                        readOnly={true}
                        maxLength={4}
                      />
                    </>
                  )}
                </div>
              )}

              <div className="filter1-field filter1-field1">
                <label className="filter1-label">지사구분</label>
                <select
                  className="form-select filter1-select1"
                  value={searchData.job}
                  onChange={e => {
                    setSearchData({ ...searchData, job: e.target.value });
                  }}
                  disabled={!(lockAuth() === "1" || lockAuth() === "0" || lockAuth() === "2")}
                >
                  {filteredJisaGubun?.map(el => (
                    <option value={el.key}>{el.value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="filter_fields_row mt-2">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <div className="d-flex" style={{ minWidth: "240px" }}>
                  {searchData.userId != null && (
                    <>
                      {(lockAuth() === "1" || lockAuth() === "0" || lockAuth() === "2") && (
                        <div className="filter1-field filter1-field1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheck1"
                            checked={searchData.isUserIdEnabled}
                            onChange={e => {
                              if (e.target.checked) {
                                setSearchData({ ...searchData, isUserIdEnabled: e.target.checked });
                              } else {
                                setSearchData({
                                  ...searchData,
                                  isUserIdEnabled: e.target.checked,
                                  userId: decrypt("userInfo").loginId,
                                });
                              }
                            }}
                          />
                          <label className="filter1-label" htmlFor="customCheck1">
                            개인조회
                          </label>
                        </div>
                      )}
                      <div className="filter1-field filter1-field1">
                        <label className="filter1-label" style={{ whiteSpace: "nowrap" }}>
                          성명
                        </label>
                        <select
                          className="form-select"
                          value={searchData.userId}
                          onChange={e => {
                            setSearchData({ ...searchData, userId: e.target.value });
                          }}
                          disabled={!searchData.isUserIdEnabled}
                        >
                          <option value="" disabled={true}></option>
                          {filteredSawonList?.map(el => (
                            <option value={el.userId}>{el.userName}</option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                </div>

                <div className="filter1-field filter1-field1">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="outCheck"
                    checked={searchData.outCheck === "Y"}
                    onChange={e => {
                      setSearchData({ ...searchData, outCheck: e.target.checked ? "Y" : "N" });
                    }}
                  />
                  <label className="filter1-label" htmlFor="outCheck">
                    퇴사자 포함
                  </label>
                </div>

                {searchData.vatCheck != null && (
                  <div className="filter1-field filter1-field1">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="vatCheck"
                      checked={searchData.vatCheck === "Y"}
                      onChange={e => {
                        setSearchData({ ...searchData, vatCheck: e.target.checked ? "Y" : "N" });
                      }}
                    />
                    <label className="filter1-label" htmlFor="vatCheck">
                      VAT 포함
                    </label>
                  </div>
                )}
              </div>
              <div>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btn_search}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end">
        {(searchData.searchType === "5" ||
          searchData.searchType === "11" ||
          searchData.searchType === "12" ||
          searchData.searchType === "13") && (
          <div className="tab1-row1-count">
            <button className="btn btn-outline-primary" onClick={btn_print}>
              출력
            </button>
          </div>
        )}
      </div>

      {currentTable === "1" && <One data={searchedTableList} />}
      {currentTable === "2" && <Two data={searchedTableList} />}
      {currentTable === "3" && <Three data={searchedTableList} />}
      {currentTable === "4" && <Four data={searchedTableList} />}
      {currentTable === "5" && <Five data={searchedTableList} />}
      {currentTable === "6" && <Six data={searchedTableList} />}
      {currentTable === "7" && <Seven data={searchedTableList} />}
      {currentTable === "8" && <Eight data={searchedTableList} />}
      {currentTable === "9" && <Nine data={searchedTableList} />}
      {currentTable === "10" && <Ten data={searchedTableList} />}
      {currentTable === "11" && <Eleven data={searchedTableList} years={searchData.year} />}
      {currentTable === "12" && <Twelve data={searchedTableList} />}
      {currentTable === "13" && <Thirteen data={searchedTableList} years={searchData.year} />}
    </div>
  );
};
