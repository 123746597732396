import React, { useEffect, useState } from "react";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import moment from "moment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useGetReligion } from "../../../../../api/code/useGetReligion";
import { useGetClassify } from "../../../../../api/code/useGetClassify";
import { useGetArmy } from "../../../../../api/code/useGetArmy";
import { useGetEndClass } from "../../../../../api/code/useGetEndClass";
import { useGetRankClass } from "../../../../../api/code/useGetRankClass";
import { useGetBlood } from "../../../../../api/code/useGetBlood";
import { useGetBank } from "../../../../../api/code/useGetBank";
import { useAtom } from "jotai";
import {
  PersonalRecordTab_backupData,
  PersonalRecordTab_modifyData,
} from "../../../../../data/atom/personManage/personMasterManage/PersonalRecordTab_atom";

export default function PersonalRecordTab({ data, sawonId, userId }) {
  const defaultURL = "";
  const [updateMode, setUpdateMode] = useState(true);
  const [modifyData, setModifyData] = useAtom(PersonalRecordTab_modifyData);
  const [backupData, setBackupData] = useAtom(PersonalRecordTab_backupData);

  //select box api
  const getReligion = useGetReligion();
  const getClassify = useGetClassify();
  const getArmy = useGetArmy();
  const getEndClass = useGetEndClass();
  const getRankClass = useGetRankClass();
  const getBlood = useGetBlood();
  const getBank = useGetBank();

  // API - 신상기록 생성/수정
  const { mutate: mutate_personRecordDetailModify } = useMutation(
    data =>
      axios.post(defaultURL + "/api/emp/empMasterMng/bottom/saveInfo", {
        ...modifyData,
        startDate: modifyData?.startDate === "" ? null : modifyData?.startDate,
        endDate: modifyData?.endDate === "" ? null : modifyData?.endDate,
      }),
    {
      onSuccess: res => {
        mutate_personRecordDetail(sawonId);
      },
    }
  );

  // API - 신상기록 조회
  const { mutate: mutate_personRecordDetail, isLoading: recordLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getInfo", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        if (result) {
          const data = {
            ...result,
            startDate: result?.startDate ? moment(result?.startDate).format("YYYY-MM-DD") : "",
            endDate: result?.endDate ? moment(result?.endDate).format("YYYY-MM-DD") : "",
          };
          setModifyData(data);
          setBackupData(data);
        } else {
          setModifyData({
            area: "",
            armyClass: "",
            bloodType: "",
            classify: "",
            color: "",
            endClass: "",
            endDate: "",
            etcAccount: "",
            etcBank: "",
            etcName: "",
            etcRemark: "",
            eyeL: "",
            eyeR: "",
            height: 0,
            hobby: "",
            modDate: "",
            modId: "",
            partClass: "",
            payAccount: "",
            payBank: "",
            payName: "",
            rankClass: "",
            religion: "",
            sawonId: sawonId,
            special: "",
            startDate: "",
            userid: "",
            weight: 0,
          });
          setBackupData({
            area: "",
            armyClass: "",
            bloodType: "",
            classify: "",
            color: "",
            endClass: "",
            endDate: "",
            etcAccount: "",
            etcBank: "",
            etcName: "",
            etcRemark: "",
            eyeL: "",
            eyeR: "",
            height: 0,
            hobby: "",
            modDate: "",
            modId: "",
            partClass: "",
            payAccount: "",
            payBank: "",
            payName: "",
            rankClass: "",
            religion: "",
            sawonId: sawonId,
            special: "",
            startDate: "",
            userid: "",
            weight: 0,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (sawonId !== modifyData?.sawonId) {
      mutate_personRecordDetail(sawonId);
    }
  }, [sawonId]);

  return (
    <div className="px-20px py-15px">
      <table className="table table-bordered table-responsive my-table1">
        <tbody>
          <tr>
            <th style={{ width: "8.052%" }}>신장</th>
            <td style={{ width: "8.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.height}
                onChange={e =>
                  setModifyData({ ...modifyData, height: e.target.value.replace(/[^0-9]/g, "") })
                }
              />
            </td>
            <th style={{ width: "8.052%" }}>몸무게</th>
            <td style={{ width: "8.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.weight}
                onChange={e =>
                  setModifyData({ ...modifyData, weight: e.target.value.replace(/[^0-9]/g, "") })
                }
              />
            </td>
            <th style={{ width: "8.052%" }}>시력</th>
            <td style={{ width: "8.281%" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <input
                    type="text"
                    className="form-control table-input me-3px"
                    value={modifyData?.eyeL}
                    onChange={e =>
                      setModifyData({
                        ...modifyData,
                        eyeL: e.target.value.replace(/[^0-9.]/g, ""),
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control table-input"
                    value={modifyData?.eyeR}
                    onChange={e =>
                      setModifyData({
                        ...modifyData,
                        eyeR: e.target.value.replace(/[^0-9.]/g, ""),
                      })
                    }
                  />
                </div>
              </div>
            </td>
            <th style={{ width: "8.052%" }}>색맹</th>
            <td style={{ width: "8.281%" }}>
              <select
                className="form-select"
                value={modifyData?.color}
                onChange={e => setModifyData({ ...modifyData, color: e.target.value })}
              >
                <option value=""></option>
                <option value="Y">색맹</option>
                <option value="N">정상</option>
              </select>
            </td>
            <th style={{ width: "8.052%" }}>혈액형</th>
            <td style={{ width: "8.281%" }}>
              <select
                className="form-select"
                value={modifyData?.bloodType}
                onChange={e => setModifyData({ ...modifyData, bloodType: e.target.value })}
              >
                <option value=""></option>
                {getBlood?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
          </tr>
          <tr>
            <th>종교</th>
            <td>
              <select
                className="form-select"
                value={modifyData?.religion}
                onChange={e => setModifyData({ ...modifyData, religion: e.target.value })}
              >
                <option value=""></option>
                {getReligion?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
            <th>취미</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                onChange={e => setModifyData({ ...modifyData, hobby: e.target.value })}
                value={modifyData?.hobby}
              />
            </td>
            <th>특기</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                onChange={e => setModifyData({ ...modifyData, special: e.target.value })}
                value={modifyData?.special}
              />
            </td>
            <th>역종</th>
            <td colSpan={3}>
              <select
                className="form-select"
                value={modifyData?.classify}
                onChange={e => setModifyData({ ...modifyData, classify: e.target.value })}
              >
                <option value=""></option>
                {getClassify?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
          </tr>
          <tr>
            <th>군별</th>
            <td>
              <select
                className="form-select"
                value={modifyData?.armyClass}
                onChange={e => setModifyData({ ...modifyData, armyClass: e.target.value })}
              >
                <option value=""></option>
                {getArmy?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
            <th>전역구분</th>
            <td>
              <select
                className="form-select"
                value={modifyData?.endClass}
                onChange={e => setModifyData({ ...modifyData, endClass: e.target.value })}
              >
                <option value=""></option>
                {getEndClass?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
            <th>계급</th>
            <td>
              <select
                className="form-select"
                value={modifyData?.rankClass}
                onChange={e => setModifyData({ ...modifyData, rankClass: e.target.value })}
              >
                <option value=""></option>
                {getRankClass?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
            <th>입대일자</th>
            <td colSpan={3}>
              <input
                type="date"
                max={"9999-12-31"}
                className="form-control table-input"
                value={modifyData?.startDate}
                onChange={e => setModifyData({ ...modifyData, startDate: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <th>전역일자</th>
            <td>
              <input
                type="date"
                max={"9999-12-31"}
                className="form-control table-input"
                value={modifyData.endDate}
                onChange={e => setModifyData({ ...modifyData, endDate: e.target.value })}
              />
            </td>
            <th>보직</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.partClass}
                onChange={e => setModifyData({ ...modifyData, partClass: e.target.value })}
              />
            </td>
            <th>근무지역</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.area}
                onChange={e => setModifyData({ ...modifyData, area: e.target.value })}
              />
            </td>
            <th>면제사유</th>
            <td colSpan={3}>
              <input
                type="text"
                className="form-control table-input"
                onChange={e => setModifyData({ ...modifyData, etcRemark: e.target.value })}
                value={modifyData?.etcRemark}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table table-bordered table-responsive my-table1 mb-0">
        <tbody>
          <tr>
            <th style={{ width: "4.552%" }}>급여구좌</th>
            <td style={{ width: "6.281%" }}>
              <select
                className="form-select"
                value={modifyData?.payBank}
                onChange={e => setModifyData({ ...modifyData, payBank: e.target.value })}
              >
                {getBank?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
            <th style={{ width: "4.052%" }}>계좌번호</th>
            <td style={{ width: "10.281%" }} colSpan={3}>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.payAccount}
                onChange={e =>
                  setModifyData({
                    ...modifyData,
                    payAccount: e.target.value.replace(/[^0-9-]/g, ""),
                  })
                }
              />
            </td>
            <th style={{ width: "4.552%" }}>예금주</th>
            <td style={{ width: "6.281%" }}>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.payName}
                onChange={e => setModifyData({ ...modifyData, payName: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <th>기타구좌</th>
            <td>
              <select
                className="form-select"
                value={modifyData?.etcBank}
                onChange={e => setModifyData({ ...modifyData, etcBank: e.target.value })}
              >
                {getBank?.data?.map(data => {
                  return <option value={data.key}>{data.value}</option>;
                })}
              </select>
            </td>
            <th>계좌번호</th>
            <td colSpan={3}>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.etcAccount}
                onChange={e =>
                  setModifyData({
                    ...modifyData,
                    etcAccount: e.target.value.replace(/[^0-9-]/g, ""),
                  })
                }
              />
            </td>
            <th>예금주</th>
            <td>
              <input
                type="text"
                className="form-control table-input"
                value={modifyData?.etcName}
                onChange={e => setModifyData({ ...modifyData, etcName: e.target.value })}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex align-items-center justify-content-end mt-10px">
        <button
          className="btn btn-outline-primary tab1-row1-btn"
          onClick={() => {
            updateMode && mutate_personRecordDetailModify();
          }}
        >
          <span>저장</span>
        </button>
        <button
          className="btn btn-outline-primary tab1-row1-btn"
          onClick={() => {
            setModifyData(backupData);
            alert("변경 사항이 취소되었습니다.");
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
}
