import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { useAtom } from "jotai/index";
import {
  certificationManage_assign_list,
  certificationManage_drftnSn,
  certificationManage_row_atom,
  certificationManage_showType,
  certificationManage_submitData,
} from "../../../../data/atom/personManage/certificationManage/certificationManage/certificationManage_atom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { decrypt } from "../../../../util/decrypt/decrypt";
import usePrintTab from "../../../../components/modal/usePrintTab";

export const CertificationManage = () => {
  const queryClient = useQueryClient();
  // 하단 탭 보여주는 정보 1 - 기본(상세보기) 2 - 등록 3-명의개서용
  const [showType, setShowType] = useAtom(certificationManage_showType);
  const [searchListNumber, setSearchListNumber] = useAtom(certificationManage_row_atom);
  const [submitData, setSubmitData] = useAtom(certificationManage_submitData);
  const [assignList, setAssignList] = useAtom(certificationManage_assign_list);
  const [drftnSn, setDrftnSn] = useAtom(certificationManage_drftnSn);
  const { addTabClosedEvent } = useRecentTabEvent();
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  // 탭 닫을때 초기화
  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setShowType(null);
      setSearchListNumber("5");
      setSubmitData({
        certifyClass: "11",
        copies: 1,
        reason: "",
        juminCheck: "N",
      });
      setAssignList("");
      setDrftnSn("");
    });
  }, []);

  // API - 증명서 목록 조회
  const getDocList = useQuery(
    ["getDocList"],
    async () =>
      await axios.get("/api/emp/docMng/getDocList").then(res => {
        return res.data.result;
      })
  );

  // API - 등록, 명의개서 버튼시 자동 입력 사항
  const getRegistInfo = useMutation(data =>
    axios.get("/api/emp/docMng/getRegistInfo").then(res => {
      return res?.data?.result;
    })
  );

  // API - 증명서 상세
  const getDetail = useMutation(
    data =>
      axios
        .get("/api/emp/docMng/getDetail", {
          params: {
            drftnSn: data,
          },
        })
        .then(res => {
          return res.data.result;
        }),
    {
      onSuccess: res => {
        const sortList = res.confmList.sort((a, b) => {
          if (a.confmOrdr > b.confmOrdr) {
            return 1;
          } else {
            return -1;
          }
        });
        const assignOrder = sortList.reduce((acc, cur, currentIndex) => {
          if (currentIndex === 0) {
            return acc + cur.kname;
          } else {
            if (cur?.confmSttus === "반려") {
              return acc + "-" + cur.kname + "(반려)";
            } else {
              return acc + "-" + cur.kname;
            }
          }
        }, "");
        setAssignList(assignOrder);
      },
    }
  );

  // API - 명의개서용
  const printNameChange = useMutation(data => axios.post("/api/emp/docMng/printNameChange", data), {
    onSuccess: res => {
      docPrint.mutate({ drftSn: res?.data?.msg });
    },
  });

  // API - 증명서 출력
  const docPrint = useMutation(
    data => axios.get("/api/emp/docMng/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `증명서__${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  // API - 증명서 결재 라인
  const getDocConfirmLineInfo = useQuery(
    ["getDocConfirmLineInfo"],
    async () =>
      await axios.get("/api/emp/docMng/getDocConfirmLineInfo").then(res => {
        const sortData = res?.data?.result.sort((a, b) => {
          return a.confmOrdr - b.confmOrdr;
        });
        return sortData;
      })
  );

  // API - 증명서 상신
  const submitDoc = useMutation(data => axios.post("/api/emp/docMng/submit", data), {
    onSuccess: () => {
      setSubmitData({ certifyClass: "11", copies: 1, reason: "" });

      queryClient.invalidateQueries(["getDocList"]);
      alert("증명서 발급 신청이 상신되었습니다.");
    },
  });

  useEffect(() => {
    if (showType && showType !== 1) {
      getRegistInfo.mutate();
    } else if (showType && showType === 1) {
      getDetail.mutate(drftnSn);
    }
  }, []);

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">증명서 발급 신청 내역</div>
            <div className="filter-buttons">
              {decrypt("userInfo")?.userPermit === "99" && (
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setShowType(3);
                    setDrftnSn("");
                    setSubmitData({ certifyClass: "11", copies: 1, reason: "", juminCheck: "N" });
                    getRegistInfo.mutate();
                  }}
                >
                  명의개서용
                </button>
              )}
              <button
                className="btn btn-primary ms-8px me-20px"
                onClick={() => {
                  setShowType(2);
                  setDrftnSn("");
                  setSubmitData({ certifyClass: "11", copies: 1, reason: "", juminCheck: "N" });
                  getRegistInfo.mutate();
                }}
              >
                등록
              </button>
            </div>
          </div>
        </div>
        <div className="card card-main mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count">증명서 발급 신청 내역</div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>

            <div
              className={`table-container table-sticky 
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table  mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "8%" }}>연번</th>
                    <th>종류</th>
                    <th style={{ width: "15%" }}>매수</th>
                    <th>용도</th>
                    <th>담당자</th>
                    <th style={{ width: "20%" }}>발급신청일시</th>
                    <th style={{ width: "20%" }}>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {getDocList.data && getDocList.data.length > 0 ? (
                    getDocList.data.map((data, _idx) => {
                      return (
                        <tr
                          role="button"
                          onClick={() => {
                            setDrftnSn(data.drftSn);
                            getDetail.mutate(data.drftSn);
                            setShowType(1);
                          }}
                          className={`${drftnSn === data?.drftSn && "bg-blue"}`}
                        >
                          <td>{_idx + 1}</td>
                          <td>{data?.docType}</td>
                          <td>{data?.copies}</td>
                          <td>{data?.reason}</td>
                          <td>{data?.confmer}</td>
                          <td>{data?.issueDate && moment(data?.issueDate).format("YYYY-MM-DD")}</td>
                          <td>{data?.confmStatus}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {showType != null && (
          <div className="card card-main mt-14px">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {showType === 1 && assignList ? (
                  <div className="d-flex flex-column">
                    <div className="tab1-row1-count">상세보기</div>
                    <div className="mt-2 tab1-row1-count">지정된 결재선: {assignList}</div>
                  </div>
                ) : showType === 2 && getDocConfirmLineInfo?.data.length > 0 ? (
                  <div>
                    <div className="tab1-row1-count">증명서 발급 신청 하기</div>
                    <div className="mt-2 tab1-row1-count">
                      지정된 결재선 :
                      {getDocConfirmLineInfo?.data?.reduce((acc, cur, _idx) => {
                        if (_idx === 0) {
                          return acc + " " + cur?.kname;
                        } else {
                          return acc + "-" + cur?.kname;
                        }
                      }, "")}
                    </div>
                  </div>
                ) : (
                  <div className="tab1-row1-count">
                    명의개서용 {submitData.certifyClass === "11" ? "재직증명서" : "경력증명서"} 출력
                  </div>
                )}
                <div className="d-flex align-items-center w-50 justify-content-end">
                  {showType === 1 ? (
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0"
                      onClick={() => {
                        setShowType(null);
                        setDrftnSn("");
                      }}
                    >
                      닫기
                    </button>
                  ) : showType === 2 ? (
                    <>
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary "
                        onClick={() => submitDoc.mutate(submitData)}
                      >
                        상신
                      </button>
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary"
                        onClick={() =>
                          setSubmitData({
                            certifyClass: "11",
                            copies: 1,
                            reason: "",
                            juminCheck: "N",
                          })
                        }
                      >
                        취소
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary "
                        onClick={() => {
                          printNameChange.mutate(submitData);
                        }}
                      >
                        출력
                      </button>
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary"
                        onClick={() => setShowType(null)}
                      >
                        취소
                      </button>
                    </>
                  )}
                </div>
              </div>
              <table className="table table-bordered table-responsive my-table1">
                <tbody>
                  <tr>
                    <th style={{ width: "13%" }}>성 &nbsp; &nbsp; &nbsp; &nbsp;명</th>
                    <td style={{ width: "20%" }}>
                      {/* kname */}
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.kname}</div>
                      ) : (
                        <div>{getRegistInfo?.data?.kname}</div>
                      )}
                    </td>
                    <th style={{ width: "13%" }}>주민등록번호</th>
                    <td style={{ width: "20%" }}>
                      {/* juminNo */}
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.juminNo}</div>
                      ) : (
                        <div>
                          {submitData.juminCheck === "Y"
                            ? getRegistInfo?.data?.juminNo &&
                              getRegistInfo?.data?.juminNo.slice(0, 6) +
                                "-" +
                                getRegistInfo?.data?.juminNo.slice(6, 7) +
                                "*".repeat(6)
                            : getRegistInfo?.data?.juminNo &&
                              getRegistInfo?.data?.juminNo.slice(0, 6) +
                                "-" +
                                getRegistInfo?.data?.juminNo.slice(6)}
                        </div>
                      )}
                    </td>
                    <th colSpan={2}>
                      {showType !== 1 && (
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="me-1">주민등록번호 일부 감추기</div>
                          <input
                            type="checkbox"
                            onClick={() => {
                              if (submitData.juminCheck === "N") {
                                setSubmitData({ ...submitData, juminCheck: "Y" });
                              } else {
                                setSubmitData({ ...submitData, juminCheck: "N" });
                              }
                            }}
                            checked={submitData.juminCheck === "Y" ? true : false}
                          />
                        </div>
                      )}
                    </th>
                  </tr>
                  <tr>
                    <th>부 &nbsp; &nbsp; &nbsp; &nbsp;서</th>
                    <td>
                      {/* etcJob */}
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.etcJob}</div>
                      ) : (
                        <div>{getRegistInfo?.data?.etcJob}</div>
                      )}
                    </td>
                    <th>직 &nbsp; &nbsp; &nbsp; &nbsp;위</th>
                    <td>
                      {/* etcAuthority */}
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.etcAuthority}</div>
                      ) : (
                        <div>{getRegistInfo?.data?.etcAuthority}</div>
                      )}
                    </td>
                    <th style={{ width: "13%" }}>소 &nbsp; &nbsp; &nbsp; &nbsp;속</th>
                    <td style={{ width: "20%" }}>
                      {/* etcDept */}
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.etcDept}</div>
                      ) : (
                        <div>{getRegistInfo?.data?.etcDept}</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>현 주 소</th>
                    <td colSpan={3}>
                      {/* address1 */}
                      {/* address2 */}
                      {showType === 1 ? (
                        <div>
                          {getDetail?.data?.docInfo?.addres1}&nbsp;
                          {getDetail?.data?.docInfo?.addres2}
                        </div>
                      ) : (
                        <div>
                          {getRegistInfo?.data?.addres1}&nbsp;{getRegistInfo?.data?.addres2}
                        </div>
                      )}
                    </td>
                    <th>입사일자</th>
                    <td>
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.inDate}</div>
                      ) : (
                        <div>{getRegistInfo?.data?.inDate}</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>용&nbsp; &nbsp; &nbsp; &nbsp;도</th>
                    <td>
                      {/* reason */}
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.reason}</div>
                      ) : (
                        <input
                          type="text"
                          className="form-control table-input"
                          value={submitData.reason}
                          onChange={e => setSubmitData({ ...submitData, reason: e.target.value })}
                        />
                      )}
                    </td>
                    <th>종&nbsp; &nbsp; &nbsp; &nbsp;류</th>
                    {/* remark */}
                    <td>
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.remark}</div>
                      ) : (
                        <select
                          className="form-select w-100"
                          value={submitData.certifyClass}
                          onChange={e =>
                            setSubmitData({ ...submitData, certifyClass: e.target.value })
                          }
                        >
                          <option value="11">재직증명서</option>
                          <option value="12">경력증명서</option>
                        </select>
                      )}
                    </td>
                    <th>출력매수</th>
                    <td>
                      {/* copies */}
                      {showType === 1 ? (
                        <div>{getDetail?.data?.docInfo?.copies}</div>
                      ) : (
                        <select
                          className="form-select w-100"
                          value={submitData.copies}
                          onChange={e => setSubmitData({ ...submitData, copies: e.target.value })}
                        >
                          <option value={1}>1매</option>
                          <option value={2}>2매</option>
                          <option value={3}>3매</option>
                          <option value={4}>4매</option>
                          <option value={5}>5매</option>
                        </select>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
