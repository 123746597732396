import { atom } from "jotai";
import moment from "moment";

export const deliveryMessage_searchDataInit_atom = {
  keyword: "",
  able: false,
};

export const deliveryMessage_searchData_atom = atom(deliveryMessage_searchDataInit_atom);

export const deliveryMessage_searchedTableList_atom = atom([]);

export const deliveryMessage_subSearchDataInit_atom = {
  fromDate: moment().format("YYYY-MM-DD"),
  keyword: "",
};

export const deliveryMessage_subSearchData_atom = atom(deliveryMessage_subSearchDataInit_atom);

export const deliveryMessage_subSearchedTableList_atom = atom([]);

export const deliveryMessage_subSelectedTableDataInit_atom = {
  currDate: "",
  gubun: "",
  ilNo: "",
  ilNoSeq: "",
  ilNoTotal: "",
  receiveGubun: "",
  receiveId: "",
  receiveName: "",
  remark: "",
  sendDate: "",
  title: "",
  userid: "",
  viewCheck: "",
};

export const deliveryMessage_subSelectedTableData_atom = atom(
  deliveryMessage_subSelectedTableDataInit_atom
);

export const deliveryMessage_selectRowInit_atom = {
  searchType1: "",
  searchType2: "",
  searchType3: "",
};

export const deliveryMessage_selectRow_atom = atom(deliveryMessage_selectRowInit_atom);
