import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import React, { useState } from "react";
import ModalRegisterMember from "../../addNewJongmokModal/ModalRegisterMember";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { useAtom, useAtomValue } from "jotai/index";
import {
  editedSearchResult_atom,
  originSearchResult_atom,
  searchParam_atom,
} from "../../../../../data/atom/dataManage/golfMembershipPriceManage/corporateMembershipPrice_atom";
import moment from "moment";
import { blobToString } from "../../../../../util/blobToString";
import usePrintTab from "../../../../../components/modal/usePrintTab";

const TableButtonPanel = () => {
  const searchParam = useAtomValue(searchParam_atom);
  const [originData, setOriginData] = useAtom(originSearchResult_atom);
  const [editedData, setEditedData] = useAtom(editedSearchResult_atom);
  const [modalShow, setModalShow] = useState(null);

  const openPdf = usePrintTab();

  // 신규종목등록 버튼 모달에서 사용하는 데이터
  const [editedModalData, setEditedModalData] = useState([]);
  const [originModalData, setOriginModalData] = useState([]);

  const update = useMutation(data => axios.post("/api/data/company/update", data));

  const getUnMemberShip = useMutation(
    () =>
      axios.get("/api/data/company/getUnMemberShip", {
        params: { admin: decrypt("userInfo").userPermit },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        let tmp = result.map(i =>
          i.gubun21 === "" || i.gubun21 === null ? { ...i, gubun21: "1" } : i
        );
        setOriginModalData(tmp);
        setEditedModalData(tmp);
      },
      onError: e => {
        if (e.response.data.code === "-1") setModalShow(false);
      },
    }
  );

  // 시세표 pdf 다운로드
  const btn_Print = useMutation(
    () =>
      axios.get("/api/data/company/print", {
        responseType: "blob",
        params: { siseDate: moment(searchParam.date).format("YYYY-MM-DD") },
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `법인시세표_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
      onError: e => {
        blobToString(e.response.data).then(data => {
          let err = JSON.parse(data);
          if (err.code === "-1") alert(err.message);
        });
      },
    }
  );

  const saveUnMemberShip = useMutation(data =>
    axios.post("/api/data/company/saveUnMemberShip", data)
  );

  // 이관 API
  const companyMigrateDB = useMutation(
    () =>
      axios.post("/api/data/company/migrateDB", {
        ...searchParam,
        searchType: searchParam.lastSearchType,
      }),
    {
      onSuccess: res => {
        alert(res.data.msg);
      },
    }
  );

  // 수정/변동종목 조회
  const companyGetmodify = useMutation(
    data => axios.get("/api/data/company/getmodify", { params: data }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setOriginData([...result]);
        setEditedData([...result]);
      },
    }
  );

  const btn_filterEdited = () => {
    // let filtered = editedData.filter((i, idx) => {
    //   let origin = "";
    //   let edited = "";
    //   // i와 originData[idx]의 모든 키값을 연결한 string을 만들어서 비교
    //   for (let key in i) {
    //     origin += originData[idx][key];
    //     edited += i[key];
    //   }
    //
    //   return origin !== edited || (i.gubun === i.gubunOld && i.csiseD - i.csiseDOld !== 0);
    // });
    //
    // if (filtered.length === 0) {
    //   alert("수정항목이 없습니다.");
    //   return;
    // }
    // setOriginData(filtered);
    // setEditedData(filtered.map(i => ({ ...i })));

    companyGetmodify.mutate(searchParam);
  };

  const btn_save = () => {
    update.mutate(
      editedData.map(i => ({
        ...i,
        searchType: searchParam.lastSearchType,
        adminCheck: i.adminCheck,
        code: i.code,
        corpSiseYn: i.corpSiseYn,
        csiseD: i.csiseD,
        csiseS: i.csiseS,
        custId: i.custId,
        cviewCheck: i.cviewCheck,
        gubun: i.gubun,
        remark: i.remark,
        siseDate: moment(i.siseDate).format("YYYY-MM-DD"),
      }))
    );
  };

  // 신규몽족등록 버튼 모달 열기
  const btn_addNewOne = () => {
    setModalShow(true);
    getUnMemberShip.mutate();
  };

  // 신규몽족등록 모달 닫기
  const btn_onModalHide = () => {
    setModalShow(false);
    setOriginModalData([]);
    setEditedModalData([]);
  };

  // 신규몽족등록 모달 저장버튼
  const btn_onSaveBtnClick = () => {
    let filtered = editedModalData.filter(i => i.insertChk);
    saveUnMemberShip.mutate(filtered);
  };

  const btn_migrate = () => {
    companyMigrateDB.mutate();
  };

  return (
    <div className="card-header d-flex align-items-center justify-content-between">
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <ModalRegisterMember
        show={modalShow}
        data={editedModalData}
        setData={setEditedModalData}
        onHide={btn_onModalHide}
        onSaveBtnClick={btn_onSaveBtnClick}
      />
      <h5 className="card-title  m-0">회원권 시세 정보</h5>
      <div className="d-flex align-items-center justify-content-end">
        <button className="btn btn-outline-primary" onClick={() => btn_Print.mutate()}>
          시세표
        </button>

        <button className="btn btn-outline-primary ms-8px" onClick={btn_filterEdited}>
          수정/변동종목 조회
        </button>

        <button className="btn btn-outline-primary ms-8px" onClick={btn_save}>
          저장
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btn_migrate}>
          이관
        </button>

        <button className="btn btn-outline-primary ms-8px" onClick={btn_addNewOne}>
          신규종목 등록
        </button>
      </div>
    </div>
  );
};

export default TableButtonPanel;
