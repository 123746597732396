import Modal from "../../../../../components/modal/Modal";
import React from "react";

/**
 * 웹상담 신청자 정보 모달
 */

export function WebOrderModal(props) {
  const { data, show, onHide = () => {} } = props;

  return (
    <Modal title="웹상담 신청자 정보" show={show} onHide={onHide}>
      <div>
        <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
          <tbody>
            <tr>
              <th style={{ width: "15%" }} colSpan={1}>
                직장명
              </th>
              <td colSpan={3}>{data?.subject ?? ""}</td>
            </tr>
            <tr>
              <th style={{ width: "15%" }}>신청자명</th>
              <td>{data?.name ?? ""}</td>
              <th style={{ width: "15%" }}>직 위</th>
              <td>{data?.option3 ?? ""}</td>
            </tr>
            <tr>
              <th style={{ width: "15%" }}>연락처</th>
              <td>{data?.phone ?? ""}</td>
              <th style={{ width: "15%" }}>전자우편</th>
              <td>{data?.email ?? ""}</td>
            </tr>
            <tr>
              <th style={{ width: "15%" }}>상담방법</th>
              <td>{data?.option1 ?? ""}</td>
              <th style={{ width: "15%" }}>희망시간</th>
              <td>{data?.option2 ?? ""}</td>
            </tr>
            <tr>
              <th style={{ width: "15%" }}>분류구분</th>
              <td>{data?.option4 ?? ""}</td>
              <th style={{ width: "15%" }}>종 류</th>
              <td>{data?.option5 ?? ""}</td>
            </tr>
            <tr>
              <th style={{ width: "15%" }}>거래구분</th>
              <td>{data?.option6 ?? ""}</td>
              <th style={{ width: "15%" }}>회 원 권</th>
              <td>{data?.option10 ?? ""}</td>
            </tr>
            <tr>
              <th style={{ width: "15%" }}>희망가격</th>
              <td colSpan={3}>{data?.option7 ? Number(data?.option7).toLocaleString() : ""}</td>
            </tr>
            <tr>
              <th style={{ width: "15%" }}>비고</th>
              <td colSpan={3} className="p-3">
                <textarea
                  className="form-control"
                  rows={10}
                  value={data?.option7 ?? ""}
                  readOnly={true}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
