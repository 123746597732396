import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  addRowCount_atom,
  textList_atom,
} from "../../../data/atom/dataManage/monthlyTextManage/monthlyTextManage_atom";
import moment from "moment/moment";
import { useGetsmsSendManageUserList } from "../../../api/code/useGetsmsSendManageUserList";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";

export const MonthlyTextManage = () => {
  const [textList, setTextList] = useAtom(textList_atom);
  const [addRowCount, setAddRowCount] = useAtom(addRowCount_atom);
  const { addTabClosedEvent } = useRecentTabEvent();
  const sum = textList
    .reduce((acc, cur, idx) => {
      if (idx === 0 && cur.authority === "") return acc;

      return acc + cur.smsTarget;
    }, 0)
    .toLocaleString();

  const getsmsSendManageUserList = useGetsmsSendManageUserList();
  const userIdQ = useGetUserIdQ();

  const searchText = useMutation(
    data => axios.get("/api/data/smsSendManage/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setAddRowCount(0);
        setTextList(data);
      },
    }
  );

  const saveText = useMutation(data => axios.post("/api/data/smsSendManage/saveSms", data), {
    onSuccess: res => {
      setTextList(textList.slice(1));
      searchText.mutate();
    },
  });

  const deleteText = useMutation(data => axios.post("/api/data/smsSendManage/deleteSms", data), {
    onSuccess: res => {
      searchText.mutate();
    },
  });

  useEffect(() => {
    if (textList.length === 0 || textList[0].authority !== "") searchText.mutate();
  }, []);

  const onChangeEventHandler = (key, value, idx) => {
    if (key === "smsTarget" && new RegExp(/[^0-9]/).test(value)) {
      return;
    }

    const list = [...textList].map((el, idx2) => {
      if (idx === idx2) {
        return { ...el, [key]: value, updateFlag: true };
      } else return el;
    });

    setTextList(list);
  };

  const btnAdd = () => {
    setTextList([
      {
        authority: "",
        dept: "",
        job: "",
        lmsTarget: 0,
        smsTarget: 0,
        userName: "",
        userPermit: "",
        userId: "",
        newCheck: true,
      },
      ...textList,
    ]);

    setAddRowCount(prev => {
      return prev + 1;
    });
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      const list = textList
        .filter(el => el.updateFlag || el.newCheck)
        .map(el2 => {
          return { ...el2, modDate: moment().format("YYYY-MM-DD HH:mm:ss") };
        });
      if (list.length !== 0) {
        saveText.mutate(list);
      } else {
        alert("저장할 내역이 없습니다.");
      }
    }
  };
  const btnDelete = (deleteEl, idx) => {
    if (deleteEl.newCheck !== undefined) {
      const list = textList.filter((el, idx2) => idx !== idx2);
      setTextList(list);
      setAddRowCount(prev => {
        return prev - 1;
      });
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteText.mutate([deleteEl]);
      }
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setTextList([]);
      setAddRowCount(0);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="card card-main">
          <div className="card-header">
            <h5 className="card-title m-0">개인별 월 사용량 관리 </h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered table-responsive my-table1 mt-10px mb-0">
              <tbody>
                <tr>
                  <th style={{ width: "31.67%" }} rowSpan={2}>
                    SNS LMS 서비스
                  </th>
                  <th>할당량</th>
                </tr>
                <tr>
                  <td className="text-center">{sum}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card card-main">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="tab1-row1-count">
                검색 결과 : {textList.filter(el => !el.newCheck).length} 건
              </div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <button
                  className="btn btn-outline-primary"
                  onClick={btnAdd}
                  disabled={textList.filter(el => el.newCheck).length !== 0}
                >
                  추가
                </button>
                <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
                  저장
                </button>
              </div>
            </div>

            <div className="table-container table-sticky h-515px mt-10px">
              <table className="table table-bordered table-responsivex my-table mt-0 mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "4.05%" }} rowSpan={2}>
                      No
                    </th>
                    <th style={{ width: "7.43%" }} rowSpan={2}>
                      사용자
                    </th>
                    <th style={{ width: "15.54%" }}>SMS,LMS</th>
                    {/*<th style={{ width: "4.72%" }} rowSpan={2}>*/}
                    {/*  문자*/}
                    {/*  <br />*/}
                    {/*  제한*/}
                    {/*</th>*/}
                    <th style={{ width: "10.81%" }} rowSpan={2}>
                      최종수정
                    </th>
                    <th style={{ width: "13.51%" }} rowSpan={2}>
                      근무구분
                    </th>
                    <th style={{ width: "10.1%" }} rowSpan={2}>
                      부 서 명
                    </th>
                    <th style={{ width: "10.1%" }} rowSpan={2}>
                      작업허용
                    </th>
                    <th style={{ width: "7.43%" }} rowSpan={2}>
                      직위
                    </th>
                    <th rowSpan={2}>입사일자</th>
                    <th rowSpan={2}>삭제</th>
                  </tr>
                  <tr>
                    <th>할당량</th>
                  </tr>
                </thead>
                <tbody>
                  {textList.length !== 0 ? (
                    textList.map((el, idx) => {
                      return (
                        <tr>
                          <td>{idx + 1 - addRowCount > 0 ? idx + 1 - addRowCount : ""}</td>
                          {/*<td>{idx + 1 - addRowCount > 0 ? idx + 1 - addRowCount : ""}</td>*/}
                          <td>
                            {el.newCheck !== undefined ? (
                              <select
                                className="form-select"
                                value={el.userId ? el.userId : ""}
                                onChange={e => onChangeEventHandler("userId", e.target.value, idx)}
                              >
                                {getsmsSendManageUserList.data?.map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                                <option value={el.userId} hidden={true}></option>
                              </select>
                            ) : (
                              <>
                                <div style={{ color: "black" }}>
                                  {el.userId
                                    ? userIdQ.data?.find(el2 => el2.key === el.userId)?.value
                                    : ""}
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control text-center"
                              value={el.smsTarget ? el.smsTarget.toLocaleString() : 0}
                              onChange={e =>
                                onChangeEventHandler(
                                  "smsTarget",
                                  Number(e.target.value.replace(/,/g, "")),
                                  idx
                                )
                              }
                              maxLength={7}
                            />
                          </td>
                          {/*<td>*/}
                          {/*  <input type="checkbox" className="" />*/}
                          {/*</td>*/}
                          <td>
                            {el.modDate ? moment(el.modDate).format("YYYY-MM-DD hh:mm:ss") : ""}
                          </td>
                          <td>{el.dept}</td>
                          <td>{el.job}</td>
                          <td>{el.userPermit}</td>
                          <td>{el.authority}</td>
                          <td>{el.inDate ? moment(el.inDate).format("YYYY-MM-DD") : ""}</td>
                          <td
                            className="text-center cursor-pointer"
                            onClick={() => btnDelete(el, idx)}
                          >
                            <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-center" colSpan={2}>
                      계
                    </td>
                    <td>{sum}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
