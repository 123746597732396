import EmpModal from "../../../../../components/modal/Modal";
import React from "react";

export const SmsHistoryModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <EmpModal
      show={isModalOpen.isOpen}
      width="98.5%"
      minWidth="475px"
      title="SMS 상세내용"
      onHide={() => setIsModalOpen({ isOpen: false, data: null })}
      strClass="modal-xl"
      size="xl"
    >
      <div>
        <div>{isModalOpen.data.msg}</div>
      </div>

      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-outline-primary btn-filter-outline-primary me-0"
          onClick={() =>
            setIsModalOpen({
              data: null,
              isOpen: false,
            })
          }
        >
          닫기
        </button>
      </div>
    </EmpModal>
  );
};
