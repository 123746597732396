import React, { useCallback, useEffect, useState } from "react";
import { useAtom } from "jotai/index";
import {
  conditionSelect_atom,
  conditionSelectInit,
  currentRow_atom,
  governCurrentRow_atom,
  governGolfKeyword_atom,
  governGolfList_atom,
  medoMesu_atom,
  medoMesuCost_atom,
  modalCurrentGolf_atom,
  modalGolfData_atom,
  modalResult_atom,
  modalShow_atom,
  modalShowInit,
  modalTax_atom,
  modalTaxList_atom,
  resultInit,
  taxInit,
} from "../../../../data/atom/workManage/acquTax/acquTax_atom";
import moment from "moment/moment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";

export default function AcquListRow(props) {
  const { el, idx, setEditedRowList } = props;
  const [modalShow, setModalShow] = useAtom(modalShow_atom);
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [medoMesu, setMedoMesu] = useAtom(medoMesu_atom);
  const [currentGolf, setCurrentGolf] = useAtom(modalCurrentGolf_atom);
  const [conditionSelect, setConditionSelect] = useAtom(conditionSelect_atom);
  const [medoMesuCost, setMedoMesuCost] = useAtom(medoMesuCost_atom);
  const [governGolfKeyword, setGovernGolfKeyword] = useAtom(governGolfKeyword_atom);
  const [governGolfList, setGovernGolfList] = useAtom(governGolfList_atom);
  const [governCurrentRow, setGovernCurrentRow] = useAtom(governCurrentRow_atom);
  const [taxList, setTaxList] = useAtom(modalTaxList_atom);
  const [tax, setTax] = useAtom(modalTax_atom);
  const [result, setResult] = useAtom(modalResult_atom);
  const [golfData, setGolfData] = useAtom(modalGolfData_atom);

  const [currentData, setCurrentData] = useState({});

  const queryClient = useQueryClient();
  const getUserIdQ = queryClient.getQueryData(["getUserIdQ"]);
  const getJisaGubun = queryClient.getQueryData(["getJisaGubun"]);
  const getGolfCustList = queryClient.getQueryData(["getGolfCustList"]);
  const searchAcquMedoMesu = useMutation(
    data => axios.get("/api/work/acquisitionTax/getMedoMesu", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;
        const msg = res.data.msg;

        if (data.code == 0 || data.code >= 2) {
          alert(msg);
        }

        setConditionSelect(conditionSelectInit);
        setMedoMesuCost(resultInit);
        setGovernGolfKeyword("");
        setGovernGolfList([]);
        setGovernCurrentRow({});

        setTax({ ...taxInit });
        setTaxList([]);
        setResult({ ...resultInit });
        setGolfData({});

        setMedoMesu({
          ...data,
          cdate: data.cdate ? moment(data.cdate).format("YYYY-MM-DD") : "",
          csinDate: data.csinDate ? moment(data.csinDate).format("YYYY-MM-DD") : "",
        });

        setCurrentGolf(data?.ccode);
        setModalShow({ flag: true, isNewClickRow: true });
      },
    }
  );

  useEffect(() => {
    setCurrentData({ ...el });
  }, [el]);

  const onChangeEventHandler = (key, value) => {
    let newData;
    if (key === "seqNo") {
      value = value.replace(/-/g, "");
      newData = { ...currentData, [key]: value };
    } else if (key === "taxTarget") {
      if (
        currentRow.custId === currentData.custId &&
        currentRow.custDate === currentData.custDate &&
        currentRow.ilNo === currentData.ilNo
      ) {
        setCurrentRow({
          ...currentRow,
          taxTarget: value,
          yangdoTaxTarget: value === "Y" ? "Y" : value === "N" ? "N" : currentRow.yangdoTaxTarget,
        });
      }
      newData = {
        ...currentData,
        [key]: value,
        yangdoTaxTarget: value === "Y" ? "Y" : value === "N" ? "N" : el.yangdoTaxTarget,
      };
    } else if (key === "yangdoTaxTarget") {
      if (
        currentRow.custId === currentData.custId &&
        currentRow.custDate === currentData.custDate &&
        currentRow.ilNo === currentData.ilNo
      ) {
        setCurrentRow({ ...currentRow, yangdoTaxTarget: value });
      }
    } else {
      newData = { ...currentData, [key]: value };
    }

    setCurrentData(newData);

    setEditedRowList(prev => {
      let flag = -1;
      prev = prev.map(el => {
        if (idx === el.idx) {
          flag = idx;
          return newData;
        } else {
          return el;
        }
      });
      if (flag === -1) {
        prev.push(newData);
      }

      return prev;
    });
  };

  const rowClick = useCallback(() => {
    let tmpData = el;

    if (tmpData.taxTarget === "N") {
      if (!confirm("세무신고 『 비대상 』건입니다! 그래도 계속 작업하시겠습니까?")) {
        return;
      }
    } else if (tmpData.taxTarget === "S") {
      if (!confirm("세무신고 『 완료 』건입니다! 그래도 계속 작업하시겠습니까?")) {
        return;
      }
    }

    if (tmpData.saleGu === "2") {
      if (!confirm("『 분양거래 』건입니다!\n그래도 계속 작업하시겠습니까?")) {
        return;
      }
    } else if (tmpData.saleGu === "3") {
      if (!confirm("『 증여거래 』건입니다!\n그래도 계속 작업하시겠습니까?")) {
        return;
      }
    } else if (tmpData.saleGu === "4") {
      if (!confirm("『 상속거래 』건입니다!\n그래도 계속 작업하시겠습니까?")) {
        return;
      }
    } else if (tmpData.saleGu === "5") {
      if (!confirm("『 명의변경거래 』건입니다!\n그래도 계속 작업하시겠습니까?")) {
        return;
      }
    }

    if (tmpData.tradGubn === "8" && tmpData.gubun1 === "1") {
      if (confirm("『 매도 』가 \n개인거래이면 → 확인(Y) \n법인거래이면 → 취소(N)를 선택하세요.")) {
        tmpData = { ...tmpData, choose: "1" };
        setMedoMesu({ ...medoMesu, sgubun: "1" });
      } else {
        tmpData = { ...tmpData, choose: "2" };
        setMedoMesu({ ...medoMesu, sgubun: "2" });
      }
    } else if (tmpData.tradGubn === "8" && tmpData.gubun1 !== "1") {
      if (confirm("『 매수 』가 \n개인거래이면 → 확인(Y) \n법인거래이면 → 취소(N)를 선택하세요.")) {
        tmpData = { ...tmpData, choose: "1" };
        setMedoMesu({ ...medoMesu, rgubun: "1" });
      } else {
        tmpData = { ...tmpData, choose: "2" };
        setMedoMesu({ ...medoMesu, rgubun: "2" });
      }
    } else {
      tmpData = { ...tmpData, choose: "1" };
    }

    if (tmpData.opposite.tradGubn === "8" && tmpData.opposite.gubun1 === "1") {
      if (
        confirm("『 매도 』가 \n개인거래이면 → 확인(Y) \n법인거래이면 →  취소(N) 를 선택하세요.")
      ) {
        tmpData = { ...tmpData, opposite: { ...tmpData.opposite, choose: "1" } };
        setMedoMesu({ ...medoMesu, sgubun: "1" });
      } else {
        tmpData = { ...tmpData, opposite: { ...tmpData.opposite, choose: "2" } };
        setMedoMesu({ ...medoMesu, sgubun: "2" });
      }
    } else if (tmpData.opposite.tradGubn === "8" && tmpData.opposite.gubun1 !== "1") {
      if (
        confirm("『 매수 』가 \n개인거래이면 → 확인(Y) \n법인거래이면 →  취소(N) 를 선택하세요.")
      ) {
        tmpData = { ...tmpData, opposite: { ...tmpData.opposite, choose: "1" } };
        setMedoMesu({ ...medoMesu, rgubun: "1" });
      } else {
        tmpData = { ...tmpData, opposite: { ...tmpData.opposite, choose: "2" } };
        setMedoMesu({ ...medoMesu, rgubun: "2" });
      }
    } else {
      tmpData = { ...tmpData, opposite: { ...tmpData.opposite, choose: "1" } };
    }

    setCurrentRow(tmpData);
    searchAcquMedoMesu.mutate({
      choose: tmpData.choose ?? null,
      custDate: tmpData.custDate,
      ilNo: tmpData.ilNo,
      "opposite.choose": tmpData.opposite.choose ?? null,
      "opposite.custDate": tmpData.opposite.custDate,
      "opposite.custId": tmpData.opposite.custId,
      "opposite.ilNo": tmpData.opposite.ilNo,
      viewCustMasterSeqNo: tmpData.viewCustMasterSeqNo,
    });
  }, [el]);

  return (
    <tr
      className={`cursor-pointer m-0 ${
        currentRow.custId === currentData.custId &&
        currentRow.custDate === currentData.custDate &&
        currentRow.ilNo === currentData.ilNo
          ? "bg-blue"
          : currentData?.jisaGerae !== "N"
          ? "row-bg-acquTax-color1"
          : ""
      }`}
      onClick={rowClick}
    >
      <td className="text-center">{idx + 1}</td>
      <td>{currentData?.custDate ? currentData.custDate : ""}</td>
      <td>
        {currentData?.tradGubn === "3"
          ? "개인물건"
          : currentData?.tradGubn === "4"
          ? "법인물건"
          : currentData?.tradGubn === "8"
          ? "타샵물건"
          : ""}
      </td>
      <td>{currentData?.dosu ? currentData.dosu : ""}</td>
      <td>
        {currentData?.viewCustMasterCustId
          ? currentData.viewCustMasterCustId.replace(/^(\d{4})(\d{4})$/g, "$1-$2")
          : ""}
      </td>
      <td>
        <input
          type="text"
          className="p-0 m-0 form-control text-center cursor-pointer"
          value={currentData?.company ? currentData.company : ""}
          style={
            currentRow.custId === currentData.custId &&
            currentRow.custDate === currentData.custDate &&
            currentRow.ilNo === currentData.ilNo
              ? { backgroundColor: "#4472c4", color: "#fff", border: "0" }
              : currentData?.jisaGerae !== "N"
              ? { backgroundColor: "#deffde", border: "0" }
              : { border: "0" }
          }
          readOnly={true}
        />
      </td>
      <td>
        {currentData?.saleGu === "1"
          ? ""
          : currentData?.saleGu === "2"
          ? "분양"
          : currentData?.saleGu === "3"
          ? "증여"
          : currentData?.saleGu === "4"
          ? "상속"
          : currentData?.saleGu === "5"
          ? "명의변경"
          : ""}
      </td>
      <td>{currentData?.differCheck ? currentData.differCheck : ""}</td>
      <td>{currentData?.kname ? currentData.kname : ""}</td>
      <td>
        <select
          className="p-0 m-0 form-select"
          value={currentData?.taxTarget}
          onChange={e => onChangeEventHandler("taxTarget", e.target.value)}
          onClick={e => e.stopPropagation()}
        >
          <option value="Y">대상</option>
          <option value="N">비대상</option>
          <option value="R">관청접수</option>
          <option value="S">완료</option>
        </select>
      </td>
      <td>
        <select
          className="p-0 m-0 form-select"
          value={currentData?.yangdoTaxTarget}
          onChange={e => onChangeEventHandler("yangdoTaxTarget", e.target.value)}
          onClick={e => e.stopPropagation()}
        >
          <option value="Y">대상</option>
          <option value="N">비대상</option>
          <option value="H">발송대기</option>
          <option value="S">완료</option>
        </select>
      </td>
      <td>
        <input
          type="date"
          className="p-0 m-0 form-control text-center"
          value={currentData?.sinDate ? currentData.sinDate : ""}
          max="9999-12-31"
          onChange={e => onChangeEventHandler("sinDate", e.target.value)}
          onClick={e => e.stopPropagation()}
        />
      </td>
      <td>
        <input
          type="date"
          className="p-0 m-0 form-control text-center"
          value={currentData?.jiroSendDate ? currentData.jiroSendDate : ""}
          max="9999-12-31"
          onChange={e => onChangeEventHandler("jiroSendDate", e.target.value)}
          onClick={e => e.stopPropagation()}
        />
      </td>
      <td>
        <input
          type="text"
          className="p-0 m-0 form-control text-center"
          value={currentData?.seqNo ? currentData.seqNo.replace(/^(\w{6})(\w{3})$/g, "$1-$2") : ""}
          onChange={e => onChangeEventHandler("seqNo", e.target.value)}
          onClick={e => e.stopPropagation()}
        />
      </td>
      <td>
        <input
          type="text"
          className="p-0 m-0 form-control text-start cursor-pointer"
          value={currentData?.codename ? currentData.codename : ""}
          style={
            currentRow.custId === currentData.custId &&
            currentRow.custDate === currentData.custDate &&
            currentRow.ilNo === currentData.ilNo
              ? { backgroundColor: "#4472c4", color: "#fff", border: "0" }
              : currentData?.jisaGerae !== "N"
              ? { backgroundColor: "#deffde", border: "0" }
              : { border: "0" }
          }
          readOnly={true}
        />
      </td>
      <td className="text-end">
        {currentData?.yangdoWon2 ? currentData.yangdoWon2?.toLocaleString() : 0}
      </td>
      <td>
        <select
          className="p-0 m-0 form-select"
          value={currentData?.downYn}
          onChange={e => onChangeEventHandler("downYn", e.target.value)}
          onClick={e => e.stopPropagation()}
        >
          <option value="D"></option>
          <option value="Y">Y</option>
          <option value="N">N</option>
          <option value="" hidden={true}></option>
        </select>
      </td>
      <td className="text-end">
        {currentData?.commission ? currentData.commission?.toLocaleString() : 0}
      </td>
      <td className="text-end">{currentData?.taxWon ? currentData.taxWon?.toLocaleString() : 0}</td>
      <td>
        <input
          type="text"
          className="p-0 m-0 form-control text-start cursor-pointer"
          value={currentData?.memberNo ? currentData.memberNo : ""}
          style={
            currentRow.custId === currentData.custId &&
            currentRow.custDate === currentData.custDate &&
            currentRow.ilNo === currentData.ilNo
              ? { backgroundColor: "#4472c4", color: "#fff", border: "0" }
              : currentData?.jisaGerae !== "N"
              ? { backgroundColor: "#deffde", border: "0" }
              : { border: "0" }
          }
          readOnly={true}
        />
      </td>
      <td>
        <input
          type="text"
          className="p-0 m-0 form-control text-start cursor-pointer"
          value={currentData?.remark ? currentData.remark : ""}
          style={
            currentRow.custId === currentData.custId &&
            currentRow.custDate === currentData.custDate &&
            currentRow.ilNo === currentData.ilNo
              ? { backgroundColor: "#4472c4", color: "#fff", border: "0" }
              : currentData?.jisaGerae !== "N"
              ? { backgroundColor: "#deffde", border: "0" }
              : { border: "0" }
          }
          readOnly={true}
        />
      </td>
      <td>
        <select
          className="p-0 m-0 form-select"
          value={currentData?.jisaGerae}
          onChange={e => onChangeEventHandler("jisaGerae", e.target.value)}
          onClick={e => e.stopPropagation()}
        >
          <option value="Y">타지사</option>
          <option value="S">샵거래</option>
          <option value="N"></option>
        </select>
      </td>
      <td>
        {currentData?.partner
          ? getUserIdQ?.find(el2 => el2.key === currentData.partner)?.value
          : ""}
      </td>
      <td>
        <select
          className="p-0 m-0 form-select"
          value={currentData?.jisa}
          onChange={e => onChangeEventHandler("jisa", e.target.value)}
          onClick={e => e.stopPropagation()}
        >
          {getJisaGubun?.map(el => {
            return <option value={el.key}>{el.value}</option>;
          })}
        </select>
      </td>
      <td>
        {currentData?.userid ? getUserIdQ?.find(el2 => el2.key === currentData.userid)?.value : ""}
      </td>
      <td>{currentData?.modDate ? currentData.modDate : ""}</td>
    </tr>
  );
}
