import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 차량관리 관리지사 셀렉트박스
 */
export const useGetCarrJisa = () => {
  return useQuery(
    ["getCarrJisa"],
    async () =>
      await axios
        .get("/api/codeMng/select/getCarrJisa")
        .then(res => res.data.result.filter(item => item.key !== "99"))
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
