import { atom } from "jotai";
import moment from "moment";

export const submissionInbox_searchOption = atom({
  drftSttusCode: "0",
  drftType: "0",
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: moment(new Date()).format("YYYY-MM-DD"),
});

export const submissionInblox_table_row = atom("5");
export const submissionInblox_search_data = atom([]);
export const submissionInblox_detailData = atom({});
export const submissionInblox_selectData = atom({
  drftType: "",
  drftSn: "",
});
