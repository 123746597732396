import React from "react";
import { useSelectEtcTaQ } from "../../../../api/code/useSelectEtcTaQ";
import { useGetJisaGubun } from "../../../../api/code/useGetJisaGubun";
import { useAtom } from "jotai/index";
import { envelopeLabelPrinting_mainData_atom } from "../../../../data/atom/workManage/postManage/envelopeLabelPrinting_atom";

export const TableBody = ({
  mainData,
  searchData,
  searchedTableList,
  btn_tableRowClick,
  setInfinityScroll,
}) => {
  // const selectEtcTaQ = useSelectEtcTaQ();
  const getJisaData = useGetJisaGubun();

  if (
    searchData.condition === "1" ||
    searchData.condition === "2" ||
    searchData.condition === "3" ||
    searchData.condition === "5"
  ) {
    // 1. 고객-업체명
    // 2. 고객-이름
    // 3. 고객-휴대폰
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.seqNo}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.seqNo === mainData.seqNo ? "bg-lightblue" : ""
            }`}
            // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.custId.replace(/(.{4})/, "$1-")}</td>
            <td>{el.company}</td>
            <td>{el.kname}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "4") {
    // 4. 고객-다골프멤버쉽
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.seqNo}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.seqNo === mainData.seqNo ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.custId.replace(/(.{4})/, "$1-")}</td>
            <td>{el.company}</td>
            <td>{el.kname}</td>
            <td>{el.cnt}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "6" || searchData.condition === "7") {
    // 6. 취득세 지로용지 고객발송
    // 7. 양도세 서류 고객발송
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.seqNo}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.seqNo === mainData.seqNo ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>
              {getJisaData?.data?.map(item => {
                if (item?.key === el?.jisa) {
                  return item.value;
                }
              })}
            </td>
            <td>{el.custDate}</td>
            <td>{el.kname}</td>
            <td>{el.sinDate ? el.sinDate : ""}</td>
            <td>{el?.seqNo.slice(0, 6) + "-" + el?.seqNo.slice(6)}</td>
            <td>{el.codename}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "9") {
    // 9. 관할관청(양도세-지방세 신고용)
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.custId}</td>
            <td>{el.sidoName}</td>
            <td>{el.company}</td>
            <td>{el.devision}</td>
            <td>{el.kname}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "10" || searchData.condition === "14") {
    // 10. 샵명
    // 14. 골프팁스
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.company}</td>
            <td>{el.kname}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "11") {
    // 11. 관할관청명
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.custId}</td>
            <td>{el.company}</td>
            <td>{el.kname}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "12") {
    // 12. 세무서명
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.company}</td>
            <td>{el.gubun}</td>
            {/*<td>{selectEtcTaQ.data.find(el2 => el2.key === el.gubun).value}</td>*/}
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "13") {
    // 13. 전화번호부
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.kname}</td>
            <td>{el.company}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "15") {
    // 15. 골프연습장
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.company}</td>
            <td>{el.kname}</td>
            <td>{el.position}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "16") {
    // 16. 골프장명
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.company}</td>
            <td>{el.kname}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "17") {
    // 17. 콘도마스터
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.company}</td>
            <td>{el.kname}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }

  if (searchData.condition === "18") {
    // 18. 스포츠마스터
    return (
      <>
        {searchedTableList.map((el, idx) => (
          <tr
            key={el.custId}
            className={`cursor-pointer ${el.outCheck === "Y" ? "bg-red" : ""} ${
              el.custId === mainData.custId ? "bg-lightblue" : ""
            }`} // ref={idx === searchedTableList.length - 1 ? setInfinityScroll : null}
            onClick={() => btn_tableRowClick(el)}
          >
            <td>{el.company}</td>
            <td>{el.kname}</td>
          </tr>
        ))}
        <div ref={setInfinityScroll} style={{ height: "1px" }} />
      </>
    );
  }
};
