import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Modal from "../../../../../../components/modal/Modal";
import { useGetJisaGubun } from "../../../../../../api/code/useGetJisaGubun";
import { useGetTxGubun3 } from "../../../../../../api/code/useGetTxGubun3";
import InquiryRow from "./InquiryRow";
import { useGetUserIdQ } from "../../../../../../api/code/useGetUserIdQ";
import { useGetJisaGubunAllQ1 } from "../../../../../../api/code/useGetJisaGubunAllQ1";
import { useGetPartner } from "../../../../../../api/code/useGetPartner";
import SearchPanel from "./SearchPanel";
import { transactionHistoryInquiryPopupEditedRow_atom } from "../../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useSetAtom } from "jotai";
import useInfiniteScrollWrapper from "../../../../../../util/infiniteScroll/useInfiniteScrollWrapper";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../../util/axios/axios";
const searchParamInit = {
  fromDate: moment().subtract(3, "month").format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD"),
  jisa: "99",
  keyword: "",
  page: 1,
  print: "",
  radio: "A",
  taxTarget: "A",
  txGubun1: "9",
  txGubun2: "9",
  txGubun3: "",
};

const ModalTransactionHistoryInquiry = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [searchParam, setSearchParam] = useState({ ...searchParamInit });
  const [searchResult, setSearchResult] = useState([]);
  const tableRef = useRef(null);
  const setEditedRow = useSetAtom(transactionHistoryInquiryPopupEditedRow_atom);

  const fetchFirstPage = async param => {
    let res = await axios.get("/api/work/transaction/searchTransaction", {
      params: param,
    });
    return res.data.result.list;
  };

  const { endRef, refetchFromFirstPage, afterFetch } = useInfiniteScrollWrapper({
    fetch: fetchFirstPage,
    fetchKey: ["transactionSearchTransaction"],
    searchParam: searchParam,
    resultSetter: setSearchResult,
  });

  useImperativeHandle(ref, () => ({
    showModal: () => {
      setShow(true);
    },

    hideModal: onHide,
  }));

  useGetJisaGubun();
  useGetTxGubun3();
  useGetPartner();
  useGetJisaGubunAllQ1();
  useGetUserIdQ();

  const onHide = () => {
    setShow(false);
    setEditedRow([]);
  };

  return (
    <>
      <Modal strClass={"modal-xxl"} title="거래내역 조회" size={"xl"} show={show} onHide={onHide}>
        <SearchPanel
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          fetchFirstPage={refetchFromFirstPage}
        />
        <div className="modal-form">
          <div className="table-container table-sticky h-633">
            <table
              className="table table-bordered table-responsive my-table m-0"
              ref={tableRef}
              style={{ minWidth: "1836px" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "3.7%" }}>연번</th>
                  <th style={{ width: "3.7%" }}>거래일자</th>
                  <th style={{ width: "3.7%" }}>거래분류</th>
                  <th style={{ width: "3.7%" }}>&nbsp;</th>

                  <th style={{ width: "3.7%" }}>구분</th>
                  <th style={{ width: "3.7%" }}>거래구분</th>
                  <th style={{ width: "3.7%" }}>구분</th>
                  <th style={{ width: "3.7%" }}>고객번호</th>
                  <th style={{ width: "3.7%" }}>회사명</th>

                  <th style={{ width: "3.7%" }}>성명</th>
                  <th style={{ width: "3.7%" }}>거래번호</th>
                  <th style={{ width: "3.7%" }}>서류금액</th>
                  <th style={{ width: "3.7%" }}>매매금액</th>
                  <th style={{ width: "3.7%" }}>Up Down</th>

                  <th style={{ width: "3.7%" }}>회원증No.</th>
                  <th style={{ width: "3.7%" }}>거래종목</th>
                  <th style={{ width: "3.7%" }}>거래딜러</th>
                  <th style={{ width: "3.7%" }}>소속지사</th>
                  <th style={{ width: "3.7%" }}>세무신고</th>

                  <th style={{ width: "3.7%" }}>신고일자</th>
                  <th style={{ width: "3.7%" }}>명 상이</th>
                  <th style={{ width: "3.7%" }}>비고</th>
                  <th style={{ width: "3.7%" }}>고객등기 </th>
                  <th style={{ width: "3.7%" }}>등기발송일</th>

                  <th style={{ width: "3.7%" }}>User id</th>
                  <th>Mod date</th>
                </tr>
              </thead>
              <tbody>
                {searchResult.length > 0 ? (
                  searchResult.map((item, index) => (
                    <InquiryRow data={item} index={index} key={index} />
                  ))
                ) : (
                  <tr>
                    <td colSpan="26" className="text-center">
                      검색된 고객이 없습니다.
                    </td>
                  </tr>
                )}

                {searchResult.length > 0 && <tr ref={endRef} style={{ height: 0 }} />}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default ModalTransactionHistoryInquiry;
