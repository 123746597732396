import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment";
import {
  documentManageClickedRow_atom,
  documentManageSearchParam_atom,
} from "../../../../../data/atom/workManage/transactionRegisterManage/documentManage_atom";
import { useAtomValue } from "jotai";

const LeftInfoTable = ({ myDetailData }) => {
  const clickedRow = useAtomValue(documentManageClickedRow_atom);

  const queryClient = useQueryClient();
  const userIdQ = queryClient.getQueryData(["getUserIdQ"]);
  const etcCpQ = queryClient.getQueryData(["getEtcCpQ"]);

  const displaySeqNo = useCallback(value => {
    if (value !== null && value !== undefined && value !== "") {
      return `${value.substring(0, 6)}-${value.substring(6)}`;
    } else return "";
  }, []);

  const displayCustId = useCallback(value => {
    if (value !== null && value !== undefined && value !== "") {
      return `${value.substring(0, 4)}-${value.substring(4)}`;
    } else return "";
  }, []);

  const displayGubun = useCallback(value => {
    switch (value) {
      case "GM":
        return "골프";
      case "CM":
        return "콘도";
      case "SM":
        return "헬스";
      default:
        return "";
    }
  }, []);

  return (
    <div className="col-6">
      <div className="card card-main mt-14px">
        <div className="card-header">
          <h5 className="card-title m-0">거래정보</h5>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-responsive my-table1 m-0">
            <tbody>
              <tr>
                <th style={{ width: "11.9%" }}>관리구분</th>
                <td colSpan={2} style={{ width: "38.1%" }}>
                  {myDetailData?.jisaName}
                </td>
                <td colSpan={2}>{clickedRow?.memberNo}</td>
              </tr>
              <tr>
                <th>거래번호</th>
                <td colSpan={2} style={{ width: "38.1%" }}>
                  {displaySeqNo(myDetailData?.seqNo)}
                </td>
                <th style={{ width: "11.9%" }}>표지구문</th>
                <td>{etcCpQ?.find(i => i.key === myDetailData?.gubun)?.value}</td>
              </tr>
              <tr>
                <th>종목</th>
                <td colSpan={2}>{displayGubun(myDetailData?.clazz)}</td>
                <td colSpan={2}>{clickedRow?.codename}</td>
              </tr>
              <tr>
                <th rowSpan={3} className="cell-bg-blue">
                  매도정보
                </th>
                <td colSpan={4}>{myDetailData?.yangdoCustId}</td>
              </tr>
              <tr>
                <th>고객고유번호</th>
                <td>{displayCustId(myDetailData?.yangdoSeqNo)}</td>
                <td colSpan={2} style={{ fontFamily: "NotoSansKR-Bold" }}>
                  {myDetailData?.yangdoName}
                </td>
              </tr>
              <tr>
                <th>매도일</th>
                <td>
                  {myDetailData?.yangdoDate &&
                    moment(myDetailData?.yangdoDate).format("YYYY-MM-DD")}
                </td>
                <th style={{ width: "11.9%" }}>양도금액</th>
                <td>
                  <div className="float-start text-primary">
                    {myDetailData &&
                      (myDetailData.gubun1 === "1"
                        ? Number(myDetailData?.yangdoWon1)?.toLocaleString("en-US")
                        : myDetailData.gubun1 === "2"
                        ? Number(myDetailData?.yangdoWon)?.toLocaleString("en-US")
                        : "0")}
                  </div>
                  <div className="float-end">만원</div>
                </td>
              </tr>

              <tr>
                <th rowSpan={3} className="cell-bg-pink">
                  매수정보
                </th>
                <td colSpan={4}>{myDetailData?.yangsuCustId}</td>
              </tr>
              <tr>
                <th>고객고유번호</th>
                <td>{displayCustId(myDetailData?.yangsuSeqNo)}</td>
                <td colSpan={2} style={{ fontFamily: "NotoSansKR-Bold" }}>
                  {myDetailData?.yangsuName}
                </td>
              </tr>
              <tr>
                <th>매수일</th>
                <td>
                  {myDetailData?.yangsuDate &&
                    moment(myDetailData?.yangsuDate).format("YYYY-MM-DD")}
                </td>
                <th style={{ width: "11.9%" }}>양수금액</th>
                <td>
                  <div className="float-start text-danger">
                    {myDetailData?.yangsuWon
                      ? Number(myDetailData?.yangsuWon)?.toLocaleString("en-US")
                      : "0"}
                  </div>
                  <div className="float-end">만원</div>
                </td>
              </tr>
              <tr>
                <th>기타내용</th>
                <td colSpan={4}>{myDetailData?.remark}</td>
              </tr>
              <tr>
                <th>최초 입력</th>
                <td colSpan={2} style={{ width: "38.1%" }}>
                  {userIdQ?.find(i => i.key === myDetailData?.userid)?.value}
                </td>
                <th style={{ width: "11.9%" }}>최초입력일자</th>
                <td>
                  {myDetailData?.inDate &&
                    moment(myDetailData?.inDate).format("YYYY-MM-DD HH:mm:ss")}
                </td>
              </tr>
              <tr>
                <th>최종수정</th>
                <td colSpan={2} style={{ width: "38.1%" }}>
                  {userIdQ?.find(i => i.key === myDetailData?.modid)?.value}
                </td>
                <th style={{ width: "11.9%" }}>최종수정일자</th>
                <td>
                  {myDetailData?.modDate &&
                    moment(myDetailData?.modDate).format("YYYY-MM-DD HH:mm:ss")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default React.memo(LeftInfoTable);
