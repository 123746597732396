import React, { useEffect } from "react";
import SearchPanel from "./searchResult/searchPanel";
import SearchDetailBottom from "./searchDetailBottom/searchDetailBottomMain";
import { transactionRegisterManageDetail as SearchDetail } from "./transactionRegisterManageDetail/transactionRegisterManageDetail";
import SearchResultList from "./searchResult/searchResultListLeft";
import SearchResultListRight from "./searchResult/searchResultListRight";
import useInfiniteScrollWrapper from "../../../../util/infiniteScroll/useInfiniteScrollWrapper";
import {
  searchParamInit,
  transactionHistoryClickedRow_atom,
  transactionHistoryInquiryPopupEditedRow_atom,
  transactionHistoryResult_atom,
  transactionHistorySearchParam_atom,
} from "../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useAtom, useSetAtom } from "jotai/index";
import { useLocation } from "react-router-dom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { axios } from "../../../../util/axios/axios";
import { useGetGolfList2 } from "../../../../api/code/useGetGolfList2";

const transactionRegisterManageMain = () => {
  const setResData = useSetAtom(transactionHistoryResult_atom);
  const [searchParam, setSearchParam] = useAtom(transactionHistorySearchParam_atom);
  const setEditedRow = useSetAtom(transactionHistoryInquiryPopupEditedRow_atom);
  const [clickedRow, setClickedRow] = useAtom(transactionHistoryClickedRow_atom);
  const { addTabClosedEvent } = useRecentTabEvent();
  const location = useLocation();
  useGetGolfList2();

  const fetchFirstPage = async param => {
    let res = await axios.get("/api/work/transaction/search", { params: param });

    if (res.data.result.length > 0) {
      setClickedRow({ ...res.data.result[0] });
    }

    return res.data.result;
  };

  const { endRef, refetchFromFirstPage, afterFetch } = useInfiniteScrollWrapper({
    fetch: fetchFirstPage,
    fetchKey: ["transactionSearch"],
    searchParam: searchParam,
    resultSetter: setResData,
  });

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setClickedRow(null);
      setResData([]);
      setSearchParam({ ...searchParamInit });
      setEditedRow({});
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <SearchPanel API={refetchFromFirstPage} />
        <div className="two-col-outer">
          <SearchResultList
            endRef={endRef}
            filterChk={searchParam.filterChk}
            afterFetch={afterFetch}
          />
          {clickedRow?.custId && <SearchResultListRight />}
        </div>
        {clickedRow?.custId && <SearchDetail />}
        {clickedRow?.custId && <SearchDetailBottom />}
      </div>
    </>
  );
};

export default transactionRegisterManageMain;
