import TransactionProgressMain from "./transactionProgressMain";
import TransactionProgressSub from "./transactionProgressSub";
import TransactionProgressReport from "./transactionProgressReport";
import { useAtom } from "jotai/index";
import {
  clickedRow_atom,
  searchParam_atom,
  searchParamInit,
  tableRowLimit_atom,
  transactionList_atom,
  transactionMain_atom,
  transactionProgress_isNewRegister_atom,
  transactionSubList_atom,
} from "../../../data/atom/dataManage/transactionProgress/transactionProgress_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { useEffect, useRef } from "react";
import { useGetPartner } from "../../../api/code/useGetPartner";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import moment from "moment";
import { decrypt } from "../../../util/decrypt/decrypt";

export const TransactionProgress = () => {
  const targetRef = useRef();
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [tableRowLimit, setTableRowLimit] = useAtom(tableRowLimit_atom);
  const [transactionList, setTransactionList] = useAtom(transactionList_atom);
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [transactionMain, setTransactionMain] = useAtom(transactionMain_atom);
  const [transactionSubList, setTransactionSubList] = useAtom(transactionSubList_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const [isNewRegister, setIsNewRegister] = useAtom(transactionProgress_isNewRegister_atom);

  const getPartner = useGetPartner();
  const searchTransactionList = useMutation(
    data => axios.get("/api/data/transactionManage/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setTransactionList(data);
      },
    }
  );
  const searchTransactionMain = useMutation(
    data =>
      axios.get("/api/data/transactionManage/getDetail", {
        params: {
          seqnNumb: data.seqnNumb ?? "",
          tradDate: data.tradDate ?? "",
          tradSeqn: data.tradSeqn ?? "",
        },
      }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;

        if (variables.isSaveReportTop) {
          setTransactionMain({
            ...transactionMain,
            submitDoc: data.submitDoc,
          });
        } else if (variables.isSaveReportBottom) {
          setTransactionMain({
            ...transactionMain,
          });
        } else {
          setTransactionMain({
            ...data,
            bankName: "",
            jisaCFax: "",
            jisaCTel: "",
            jisaName: "",
            jusoAddres: "",
            payAccountC: "",
            payNameC: "",
          });

          searchTransactionMainPartnerChange.mutate({
            jisa: getPartner.data?.find(el => el.key === data.partner).jisa,
            // jisa: data.jisa,
            userId: data.partner,
          });
        }
      },
    }
  );
  const searchTransactionMainPartnerChange = useMutation(
    data => axios.get("/api/data/transactionManage/partnerDetail", { params: data }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result?.[0];

        setTransactionMain({
          ...transactionMain,
          partner: variables.userId,
          bankName: data.bankName,
          jisaCFax: data.jisaCFax,
          jisaCTel: data.jisaCTel,
          jisaName: data.jisaName,
          jusoAddres: data.jusoAddres,
          payAccountC: data.payAccountC,
          payNameC: data.payNameC,
          authority: data.authority,
          mobile: data.mobile,
        });
      },
    }
  );

  const rowVirtualizer = useVirtualizer({
    count: transactionList.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const onChangeEventHandler = (key, value) => {
    if (key === "code" || key === "date") {
      if (value === "Invalid date" || value === "") {
      } else {
        setSearchParam({ ...searchParam, [key]: value });
      }
    } else if (key === "tableRowLimit") {
      setTableRowLimit(value);
    } else {
      setSearchParam({ ...searchParam, [key]: value });
    }
  };

  const btnSearch = () => {
    if (searchParam.code !== "03") {
      searchTransactionList.mutate({ ...searchParam });
    } else {
      searchTransactionList.mutate({ ...searchParam, keyword: searchParam.date.replace(/-/g, "") });
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam({ ...searchParamInit });
      setTableRowLimit("5");
      setTransactionList([]);
      setClickedRow({});
      setTransactionMain({});
      setTransactionSubList([]);
      setIsNewRegister(false);
    });
  }, []);

  const displayTradSeqn = value => {
    return `${value.substring(0, 2)}-${value.substring(2, 6)}-${value.substring(6)}`;
  };

  const btn_newRegister = () => {
    if (window.confirm("신규등록을 하시겠습니까?")) {
      // setTransactionMain({ partner: decrypt("userInfo").loginId });

      searchTransactionMainPartnerChange.mutate({
        jisa: getPartner.data?.find(el => el.key === decrypt("userInfo").loginId)?.jisa,
        userId: decrypt("userInfo").loginId,
      });

      setClickedRow({
        seqnNumb: -1,
      });
      setIsNewRegister(true);
      setTransactionMain({
        partner: "",
        bankName: "",
        jisaCFax: "",
        jisaCTel: "",
        jisaName: "",
        jusoAddres: "",
        payAccountC: "",
        payNameC: "",
        company: "",
        kname: "",
        position: "",
        tradSeqn: "",
        tradDate: "",
        cellular: "",
        cfax: "",
        payBank: "",
        payAccount: "",
        payName: "",
        ctel: "",
        email: "",
        custId: "",
        seqNo: "",
      });
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">거래 진행내역 조건</div>
            <div className="filter-buttons">
              <button
                className="btn btn-primary btn-table-filter"
                onClick={btn_newRegister}
                disabled={isNewRegister}
              >
                신규등록
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div
                className="d-flex align-items-center justify-content-between"
                onKeyDown={pressEnter}
              >
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.code}
                    onChange={e => onChangeEventHandler("code", e.target.value)}
                  >
                    <option value="01">업체명,이름</option>
                    <option value="02">거래내역서 번호</option>
                    <option value="03">거래일자</option>
                    <option value="04">고객관리번호</option>
                    <option value="05">휴대폰</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">검색어</label>
                  {searchParam.code !== "03" ? (
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchParam.keyword}
                      onChange={e => onChangeEventHandler("keyword", e.target.value)}
                    />
                  ) : (
                    <input
                      type="date"
                      className="form-control filter-input filter1-input2"
                      value={moment(searchParam.date).format("YYYY-MM-DD")}
                      onChange={e => {
                        onChangeEventHandler("date", e.target.value);
                      }}
                      min={"1900-01-01"}
                      max={"2099-12-31"}
                    />
                  )}
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            <div className="containerx">
              <div className="table-container">
                <div className="d-flex align-items-center justify-content-between mb-10px">
                  <div className="tab1-row1-count">
                    검색 결과 : &nbsp;<b style={{ color: "#0b4da2" }}>{transactionList.length}</b>{" "}
                    건
                  </div>
                  <div className="d-flex align-items-center w-50 justify-content-end ">
                    <select
                      className="form-select filter-perpage ms-8px h-31"
                      value={tableRowLimit}
                      onChange={e => onChangeEventHandler("tableRowLimit", e.target.value)}
                    >
                      <option value="5">5개씩 보기</option>
                      <option value="10">10개씩 보기</option>
                      <option value="20">20개씩 보기</option>
                    </select>
                  </div>
                </div>
                <div
                  className={`table-container table-sticky
                  ${tableRowLimit === "5" && "h-183"}
                  ${tableRowLimit === "10" && "h-333"}
                  ${tableRowLimit === "20" && "h-633"}
                  `}
                  style={{ overflowY: "auto" }}
                  ref={targetRef}
                >
                  <table
                    className="table table-bordered table-responsive my-table"
                    style={{
                      marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${
                        rowVirtualizer.getTotalSize() -
                        (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                        rowVirtualizer.getVirtualItems().length * 30
                      }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "7%" }}>연번</th>
                        <th style={{ width: "10%" }}>내역서번호</th>
                        <th style={{ width: "10%" }}>거래일자</th>
                        <th style={{ width: "28%" }}>업체명</th>
                        <th style={{ width: "15%" }}>성명</th>
                        <th style={{ width: "15%" }}>직위</th>
                        <th>작성자</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionList.length !== 0 ? (
                        rowVirtualizer.getVirtualItems().map(el => {
                          return (
                            <tr
                              onClick={() => {
                                if (isNewRegister) {
                                  alert("신규등록을 완료해주세요.");
                                  return;
                                }

                                setClickedRow(transactionList[el.index]);
                              }}
                              className={`${
                                clickedRow.seqnNumb === transactionList[el.index].seqnNumb &&
                                clickedRow.tradDate === transactionList[el.index].tradDate &&
                                clickedRow.tradSeqn === transactionList[el.index].tradSeqn &&
                                "bg-blue"
                              } cursor-pointer`}
                            >
                              <td>{el.index + 1}</td>
                              <td>{displayTradSeqn(transactionList[el.index].tradSeqn)}</td>
                              <td>
                                {moment(
                                  transactionList[el.index].tradDate.trim(),
                                  "YYYYMMDD",
                                  true
                                ).isValid()
                                  ? `${transactionList[el.index].tradDate
                                      .trim()
                                      .substring(2, 4)}.${transactionList[el.index].tradDate
                                      .trim()
                                      .substring(4, 6)}.${transactionList[el.index].tradDate
                                      .trim()
                                      .substring(6)}`
                                  : `${transactionList[el.index].tradDate
                                      .trim()
                                      .substring(0, 2)}.${transactionList[el.index].tradDate
                                      .trim()
                                      .substring(2, 4)}.${transactionList[el.index].tradDate
                                      .trim()
                                      .substring(4)}`}
                              </td>
                              <td>{transactionList[el.index].company}</td>
                              <td>{transactionList[el.index].kname}</td>
                              <td>{transactionList[el.index].position}</td>
                              <td>
                                {transactionList[el.index].inputId
                                  ? getPartner.data?.find(
                                      el2 => el2.key === transactionList[el.index]?.inputId
                                    )?.value
                                  : ""}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>검색된 결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {clickedRow.seqnNumb !== undefined &&
          (clickedRow.seqnNumb === -1 ? (
            <TransactionProgressMain
              getPartner={getPartner}
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              searchTransactionList={searchTransactionList}
              searchTransactionMain={searchTransactionMain}
              searchTransactionMainPartnerChange={searchTransactionMainPartnerChange}
              isNewRegister={isNewRegister}
              setIsNewRegister={setIsNewRegister}
            />
          ) : (
            <>
              <TransactionProgressMain
                getPartner={getPartner}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
                searchTransactionList={searchTransactionList}
                searchTransactionMain={searchTransactionMain}
                searchTransactionMainPartnerChange={searchTransactionMainPartnerChange}
                isNewRegister={isNewRegister}
                setIsNewRegister={setIsNewRegister}
              />

              <TransactionProgressSub />

              <TransactionProgressReport searchTransactionMain={searchTransactionMain} />
            </>
          ))}
      </div>
    </>
  );
};
