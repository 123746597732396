import React, { useCallback } from "react";

const SearchResultTableRow = props => {
  const { data, index, onClick, clickedRow } = props;

  const displaySaleGu = useCallback(value => {
    switch (value) {
      case "1":
        return "실거래";
      case "2":
        return "분양";
      case "3":
        return "증여";
      case "4":
        return "상속";
      case "5":
        return "명의변경";
      default:
        return "";
    }
  }, []);

  const displaySeqNo = useCallback(value => {
    if (value !== null && value !== undefined && value !== "") {
      return `${value.substring(0, 6)}-${value.substring(6)}`;
    } else return "-";
  }, []);

  const displayViewCustId = useCallback(value => {
    if (value !== null && value !== undefined && value !== "") {
      return `${value.substring(0, 4)}-${value.substring(4)}`;
    } else return "";
  }, []);

  const btn_onClick = () => {
    if (onClick) onClick(data);
  };

  return (
    <tr
      onClick={btn_onClick}
      role="button"
      className={`${
        clickedRow?.seqNo === data?.seqNo &&
        clickedRow?.viewCustId === data?.viewCustId &&
        "bg-blue"
      }`}
    >
      <td>{index + 1}</td>
      <td>{data.jisaName}</td>
      <td>{data.codename}</td>
      <td>{displaySaleGu(data.saleGu)}</td>
      <td>{data.yangdoName}</td>
      <td>{data.yangsuName}</td>
      <td>{data.custDate}</td>
      <td>{displaySeqNo(data.seqNo)}</td>
      <td>{data.memberNo}</td>
      <td>{displayViewCustId(data.viewCustId)}</td>
    </tr>
  );
};

export default React.memo(SearchResultTableRow);
