import React from "react";

export default function AcquListRowHeader() {
  return (
    <tr>
      <th style={{ width: "2%" }}>연번</th>
      <th style={{ width: "4%" }}>거래일자</th>
      <th style={{ width: "3.5%" }}>거래분류</th>
      <th style={{ width: "1.2%" }}>양</th>
      <th style={{ width: "4%" }}>고객번호</th>
      <th style={{ width: "6%" }}>회사명</th>
      <th style={{ width: "3.5%" }}>구분</th>
      <th style={{ width: "2.5%" }}>명 상이</th>
      <th style={{ width: "3.5%" }}>성명</th>
      <th style={{ width: "4%" }}>세무신고</th>
      <th style={{ width: "4%" }}>고객등기</th>
      <th style={{ width: "4%" }}>관청신고일</th>
      <th style={{ width: "4%" }}>고객발송일</th>
      <th style={{ width: "4%" }}>거래번호</th>
      <th style={{ width: "6%" }}>거래종목</th>
      <th style={{ width: "3.5%" }}>서류금액</th>
      <th style={{ width: "4%" }}>UP-DOWN</th>
      <th style={{ width: "3.5%" }}>수수료</th>
      <th style={{ width: "3.5%" }}>취득세액</th>
      <th style={{ width: "4.5%" }}>회원권 No</th>
      <th>기타내용</th>
      <th style={{ width: "3.7%" }}>거래구분1</th>
      <th style={{ width: "3.5%" }}>거래딜러</th>
      <th style={{ width: "4.2%" }}>지사</th>
      <th style={{ width: "3.5%" }}>User ID</th>
      <th style={{ width: "4%" }}>최종수정일자</th>
    </tr>
  );
}
