import React from "react";
import { useAtom } from "jotai/index";
import { mesuja_atom } from "../../../../data/atom/workManage/transferTax/transferTax_atom";

export default function Mesu() {
  const [mesuja, setMesuja] = useAtom(mesuja_atom);

  return (
    <div className="card card-main w-50 h-100">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title  m-0">매수자 인적사항</h5>
      </div>

      <div className="card-body">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "11.9%" }}>매수자</th>
              <th style={{ width: "13.1%" }}>주민번호</th>
            </tr>
          </thead>
          <tbody>
            {mesuja.length !== 0 ? (
              mesuja?.map(el => {
                return (
                  <tr>
                    <td>{el.kname}</td>
                    <td>
                      {el.juminno?.length === 13
                        ? el.juminno?.replace(/^(\d{6})(\d{7})$/g, "$1-$2")
                        : el.juminno?.replace(/^(\d{3})(\d{2})(\d{5})$/g, "$1-$2-$3")}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={2}>검색된 결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
