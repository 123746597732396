import { atom } from "jotai";
import moment from "moment";

export const membershipFeeManage_searchOption = atom({
  searchType: "1",
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  gubun: "11",
  keyWord: "",
  page: 1,
  subject: "",
  toDate: moment(new Date()).format("YYYY-MM-DD"),
});
export const membershipFeeManage_row = atom("5");
export const membershipFeeManage_tab = atom("개인별 내역");
export const membershipFeeManage_search_data = atom([]);
export const membershipFeeManage_sawon_detail = atom(null);
export const membershipFeeManage_selectDetail = atom({
  authName: "",
  beneGubn: "",
  currDate: moment(new Date()).format("YYYY-MM-DD"),
  gubun: "",
  ilNo: "",
  kname: "",
  paymentWon: "",
  receiptWon: "",
  remark: "",
  sawonId: "",
  workDetail: "",
});
export const membershipFeeManage_option = atom("1");
export const membershipFeeManage_newData = atom({
  beneGubn: "",
  currDate: moment(new Date()).format("YYYY-MM-DD"),
  gubun: "11", // 입금, 출금, 기타
  paymentWon: 0,
  receiptWon: 0,
  remark: "",
  sawonId: "",
  workDetail: "",
});
export const membershipFeeManage_modifyData = atom([]);
export const membershipFeeManage_saveData = atom([]);
export const membershipFeeManage_personal_saveData = atom([]);
export const membershipFeeManage_detail_sawonId = atom("");
export const membershipFeeManage_dNaHistory = atom([]);
export const membershipFeeManage_selectedMember = atom("");
