import { useEffect, useState, useRef } from "react";
import { axios } from "../../../../util/axios/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import moment from "moment";
import EmpModal from "../../../../components/modal/Modal";
import DownloadToExcel from "./DownloadToExcel";
import { useAtom } from "jotai";
import {
  reissuanceTaxInvoice_detailData,
  reissuanceTaxInvoice_historyData,
  reissuanceTaxInvoice_row,
  reissuanceTaxInvoice_saveDetailData,
  reissuanceTaxInvoice_saveHistoryData,
  reissuanceTaxInvoice_searchData,
  reissuanceTaxInvoice_searchListCount,
  reissuanceTaxInvoice_searchOption,
} from "../../../../data/atom/personManage/taxManage/reissuanceTaxInvoice/reissuanceTaxInvoice_atom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import useCopyClipBoard from "../../../../util/useCopyClipBoard/useCopyClipBoard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from "lodash";

//  세금 재발행
const ReIssueTaxInvoice = () => {
  const initialDetailData = {
    addresName: "",
    caddres: "",
    company: "",
    czipcode: "",
    damdangja: "",
    deptName: "",
    duzoneTrans: "",
    email: "",
    gubun0: "",
    gubun1: "",
    gubun2: "",
    gubun3: "",
    ilNo: "",
    jongmok: "",
    mobileNo: "",
    owner: "",
    printGubun: "",
    raddres: "",
    remark: "",
    remark2: "",
    rzipcode: "",
    saupjaNo: "",
    sinCheck: "",
    telNo: "",
    upte: "",
  };
  const initialSearchOption = {
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    fromIlNo: "",
    toIlNo: "",
    gubun0: "2", // 사업자 구분
    gubun3: "1", // 구분별 겁색 구분
    keyWord: "",
    searchType: "1",
    page: 1,
  };

  // 검색
  const [searchOption, setSearchOption] = useAtom(reissuanceTaxInvoice_searchOption);
  // 검색 결과
  const [searchData, setSearchData] = useAtom(reissuanceTaxInvoice_searchData);
  // 상세 정보 - 공급받는자/ 공급자 정보
  const [saveDetilData, setSaveDetailData] = useAtom(reissuanceTaxInvoice_saveDetailData);
  const [detailData, setDetailData] = useAtom(reissuanceTaxInvoice_detailData);
  // 상세 정보 - 거래내역등록
  const [historyData, setHistoryData] = useAtom(reissuanceTaxInvoice_historyData);
  const [saveHistoryData, setSaveHistoryData] = useAtom(reissuanceTaxInvoice_saveHistoryData);
  // 테이블 row 개수
  const [searchListNumber, setSearchListNumber] = useAtom(reissuanceTaxInvoice_row);
  // 조회 리스트 개수
  const [searchListCount, setSearchListCount] = useAtom(reissuanceTaxInvoice_searchListCount);
  // botoom 탐지 - 무한스크롤
  const [bottom, setBottom] = useState(null);
  const bottomObserver = useRef(null);
  // 세금계산서 발행내역 엑셀 모달창 open
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addTabClosedEvent } = useRecentTabEvent();
  const [isCopy, onCopy] = useCopyClipBoard();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        fromDate: moment(new Date()).format("YYYY-MM-DD"),
        toDate: moment(new Date()).format("YYYY-MM-DD"),
        fromIlNo: "",
        toIlNo: "",
        gubun0: "2", // 사업자 구분
        gubun3: "1", // 구분별 겁색 구분
        keyWord: "",
        searchType: "1",
        page: 1,
      });
      setSearchData([]);
      setSaveDetailData([]);
      setDetailData({
        addresName: "",
        caddres: "",
        company: "",
        czipcode: "",
        damdangja: "",
        deptName: "",
        duzoneTrans: "",
        email: "",
        gubun0: "",
        gubun1: "",
        gubun2: "",
        gubun3: "",
        ilNo: "",
        jongmok: "",
        mobileNo: "",
        owner: "",
        printGubun: "",
        raddres: "",
        remark: "",
        remark2: "",
        rzipcode: "",
        saupjaNo: "",
        sinCheck: "",
        telNo: "",
        upte: "",
      });
      setSaveHistoryData([]);
      setHistoryData([]);
      setSearchListCount("0");
      setSearchListNumber("5");
    });
  }, []);

  // API - 세금 계산서 재발행 검색
  const search = useMutation(data => axios.get("/api/emp/taxReIssue/search", { params: data }), {
    onSuccess: (res, paylaod) => {
      setSearchListCount(res?.data?.result?.total);

      if (paylaod.page === 1) {
        setSearchData(res?.data?.result?.searchList);
      } else {
        setSearchData([...searchData, ...res?.data?.result?.searchList]);
      }
    },
  });

  // API - 세금계산서 재발행 상세 정보
  const detail = useMutation(
    data =>
      axios.get("/api/emp/taxReIssue/getDetail", { params: data }).then(res => {
        return res?.data?.result;
      }),
    {
      onSuccess: res => {
        // 상세정보 - 발행처
        const resData = res?.taxInfoResVo;
        const modifySaupjaNo =
          resData?.saupjaNo.slice(0, 3) +
          "-" +
          resData?.saupjaNo.slice(3, 5) +
          "-" +
          resData?.saupjaNo.slice(5);

        setDetailData({
          ...resData,
          saupjaNo: modifySaupjaNo,
        });
        setSaveDetailData({
          ...resData,
          saupjaNo: modifySaupjaNo,
        });
        // 상세정보 - 거래내역 등록 (리스트 조회)
        const taxList = res?.taxExchangeResVoList;
        const modTaxList = taxList.map(item => {
          return {
            ...item,
            custDate: item?.custDate === "" ? "" : moment(item?.custDate).format("YYYY-MM-DD"),
          };
        });
        const deepCopyTaxList = _.cloneDeep(modTaxList);
        setHistoryData([...deepCopyTaxList]);
        setSaveHistoryData(modTaxList);
      },
    }
  );

  // API - 세금 발행서 삭제
  const deleteTaxIssue = useMutation(data => axios.post("/api/emp/taxReIssue/delete", data), {
    onSuccess: res => {
      search.mutate({ ...searchOption, page: 1 });
      setSearchOption(prev => {
        return { ...searchOption, page: 1 };
      });
      setSearchData([]);
      setDetailData(initialDetailData);
    },
  });

  // API - 세금 발행서 정보 수정/저장
  const saveTaxIssue = useMutation(
    data =>
      axios.post("/api/emp/taxReIssue/save", {
        ...data,
        saupjaNo: data?.saupjaNo.replaceAll("-", ""),
      }),
    {
      onSuccess: res => {},
    }
  );

  // API - 거래 내역 정보 수정/저장
  const saveTransAct = useMutation(
    data => axios.post("/api/emp/taxReIssue/saveTransaction", data),
    {
      onSuccess: res => {
        const selecteSearchData = {
          custId: detailData.custId,
          gubun0: searchOption.gubun0,
          ilNo: detailData.ilNo,
        };
        detail.mutate(selecteSearchData);
      },
    }
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setSearchOption(prev => {
            search.mutate({ ...searchOption, page: prev.page + 1 });
            return { ...searchOption, page: prev.page + 1 };
          });
        }
      },
      { threshold: 0.25, rootMargin: "80px" }
    );
    bottomObserver.current = observer;
  }, [searchOption]);

  useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }

    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  const pressEnter = e => {
    if (e.key === "Enter") {
      setSearchOption(prev => {
        return { ...searchOption, page: 1 };
      });
      setDetailData({
        addresName: "",
        caddres: "",
        company: "",
        czipcode: "",
        damdangja: "",
        deptName: "",
        duzoneTrans: "",
        email: "",
        gubun0: "",
        gubun1: "",
        gubun2: "",
        gubun3: "",
        ilNo: "",
        jongmok: "",
        mobileNo: "",
        owner: "",
        printGubun: "",
        raddres: "",
        remark: "",
        remark2: "",
        rzipcode: "",
        saupjaNo: "",
        sinCheck: "",
        telNo: "",
        upte: "",
      });
      setSearchData([]);
      search.mutate({ ...searchOption, page: 1 });
    }
  };

  const handleCopy = () => {
    if (detailData) {
      onCopy(detailData?.addresName + " " + detailData?.raddres);
    }
  };

  return (
    <>
      <EmpModal
        show={isModalOpen}
        title={"세금계산서 - 엑셀로 내리기"}
        onHide={() => setIsModalOpen(false)}
        strClass={"modal-scroll"}
        size="xl"
      >
        <DownloadToExcel />
      </EmpModal>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">세금계산서 재발행</div>
            <div className="filter-buttons">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0"
                onClick={() => setIsModalOpen(true)}
              >
                세금계산서 발행내역 엑셀로 내리기
              </button>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row" onKeyDown={pressEnter}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchOption.searchType}
                    onChange={e =>
                      setSearchOption({ ...initialSearchOption, searchType: e.target.value })
                    }
                  >
                    <option value="1">발행번호</option>
                    <option value="2">발행기간</option>
                    <option value="3">업체명</option>
                    <option value="4">성명</option>
                    <option value="5">구분별</option>
                  </select>
                </div>
                {(searchOption.searchType === "3" || searchOption.searchType === "4") && (
                  <div className="filter1-field filter1-field2">
                    {/* 업체명, 성명 */}
                    <label className="filter1-label">검색어</label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      onChange={e => {
                        setSearchOption({ ...searchOption, keyWord: e.target.value });
                      }}
                      value={searchOption.keyWord}
                    />
                  </div>
                )}
                {searchOption.searchType === "1" && (
                  <div className="filter1-field filter1-field2">
                    {/* 발행번호 검색조건 - 숫자만 입력 가능  */}
                    <label className="filter1-label">검색어</label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.fromIlNo}
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          fromIlNo: e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1"),
                        })
                      }
                    />
                    <span>~</span>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.toIlNo}
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          toIlNo: e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"),
                        })
                      }
                    />
                  </div>
                )}
                {searchOption.searchType === "5" && (
                  <div className="filter1-field filter1-field2">
                    {/* 구분 검색 조건 => 발행연도 4자리 제한 숫자만*/}
                    <label className="filter1-label">발행연도</label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.keyWord}
                      maxlength="4"
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          keyWord: e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1"),
                        })
                      }
                    />
                  </div>
                )}
                {searchOption.searchType === "5" && (
                  <div className="filter1-field filter1-field2">
                    {/* 구분 검색 조건*/}
                    <label className="filter1-label">구분</label>
                    <select
                      className="form-select filter1-select1"
                      value={searchOption.gubun3}
                      onChange={e => {
                        setSearchOption({ ...searchOption, gubun3: e.target.value });
                      }}
                    >
                      <option value="1">영수</option>
                      <option value="2">청구</option>
                    </select>
                  </div>
                )}
                {searchOption.searchType === "2" && (
                  <div className="filter1-field filter1-field2">
                    {/* 발행기간 */}
                    <label className="filter1-label">발행기간</label>
                    <input
                      type="date"
                      min="1900-01-01"
                      max="9999-12-31"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.fromDate}
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          fromDate: e.target.value,
                        })
                      }
                    />
                    <span>~</span>
                    <input
                      type="date"
                      min={searchOption.fromDate}
                      max="2099-12-31"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.toDate}
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          toDate: e.target.value,
                        })
                      }
                    />
                  </div>
                )}
                <div className="filter1-field filter1-field2">
                  {/* all */}
                  <label className="filter1-label">사업자 구분</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e =>
                      setSearchOption({
                        ...searchOption,
                        gubun0: e.target.value,
                      })
                    }
                    value={searchOption.gubun0}
                  >
                    <option value="1">개인사업자</option>
                    <option value="2">법인사업자</option>
                  </select>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  setSearchData([]);
                  setDetailData({
                    addresName: "",
                    caddres: "",
                    company: "",
                    czipcode: "",
                    damdangja: "",
                    deptName: "",
                    duzoneTrans: "",
                    email: "",
                    gubun0: "",
                    gubun1: "",
                    gubun2: "",
                    gubun3: "",
                    ilNo: "",
                    jongmok: "",
                    mobileNo: "",
                    owner: "",
                    printGubun: "",
                    raddres: "",
                    remark: "",
                    remark2: "",
                    rzipcode: "",
                    saupjaNo: "",
                    sinCheck: "",
                    telNo: "",
                    upte: "",
                  });
                  setSearchOption(prev => {
                    return { ...searchOption, page: 1 };
                  });
                  search.mutate({ ...searchOption, page: 1 });
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-main">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count">
                조회결과 : &nbsp; &nbsp; &nbsp; &nbsp;{searchListCount ? searchListCount : 0} 건
              </div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>

            <div
              className={`table-container table-sticky 
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "7%" }}>연번</th>
                    <th style={{ width: "31%" }}>발행번호</th>
                    <th style={{ width: "31%" }}>대표자</th>
                    <th>업체명</th>
                  </tr>
                </thead>
                <tbody>
                  {searchData.length > 0 ? (
                    <>
                      {searchData.map((data, _idx) => {
                        return (
                          <tr
                            role="button"
                            onClick={() => {
                              const selecteSearchData = {
                                custId: data.custId,
                                gubun0: searchOption.gubun0,
                                ilNo: data.ilNo,
                              };
                              detail.mutate(selecteSearchData);
                            }}
                            className={`${
                              detailData && detailData?.ilNo === data?.ilNo && "bg-blue"
                            }`}
                          >
                            <td>{_idx + 1}</td>
                            <td>{data?.ilNo.slice(0, 4) + "-" + data?.ilNo.slice(4)}</td>
                            <td>{data?.owner}</td>
                            <td>{data?.company}</td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={4}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                  {searchData.length > 0 && <div ref={setBottom} />}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {detailData.ilNo && (
          <div className="row gx-3">
            <div className="col-4">
              <div className="card card-main">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title  m-0">공급받는자/ 공급자</h5>
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      className="btn btn-outline-danger m-0 ms-2"
                      onClick={() => {
                        const result = window.confirm("해당 세금 발행서를 삭제하시겠습니까?");
                        if (result) {
                          deleteTaxIssue.mutate({
                            gubun0: detailData.gubun0,
                            ilNo: detailData.ilNo,
                          });
                        } else {
                        }
                      }}
                    >
                      삭제
                    </button>
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                      onClick={() => {
                        // 세이브 데이터 변경
                        saveTaxIssue.mutate(detailData);
                        setSaveDetailData(detailData);
                      }}
                    >
                      저장
                    </button>
                    {/* 수정 취소 */}
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                      onClick={() => {
                        setDetailData(saveDetilData);
                        alert("변경 사항이 취소되었습니다.");
                      }}
                    >
                      취소
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered table-responsive my-table1">
                    <tbody>
                      <tr>
                        <th style={{ width: "15%" }} className="text-start ps-1">
                          사업자번호
                        </th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData.saupjaNo}
                            // onChange={e =>
                            //   setDetailData({
                            //     ...detailData,
                            //     saupjaNo: e.target.value,
                            //   })
                            // }
                            onChange={e => {
                              const targetValue = e.target.value;
                              const newTargetValue = targetValue
                                .split("")
                                .filter(item => item !== "-")
                                .reduce((acc, cur, idx) => {
                                  return (acc + cur).replace("-", "");
                                }, "");
                              const modeTarget =
                                newTargetValue.slice(0, 3) +
                                "-" +
                                newTargetValue.slice(3, 5) +
                                "-" +
                                newTargetValue.slice(5);
                              setDetailData({
                                ...detailData,
                                saupjaNo: modeTarget,
                              });
                            }}
                            maxLength={12}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">발행번호</th>
                        <td colSpan={3}>
                          <select
                            className="form-select left w-50x me-2x"
                            value={detailData?.gubun0}
                            onChange={e => setDetailData({ ...detailData, gubun0: e.target.value })}
                          >
                            <option value="1">개인사업자</option>
                            <option value="2">법인사업자</option>
                          </select>

                          <div className="d-flex align-items-center h-100x justify-content-center">
                            <div>
                              {detailData?.ilNo.slice(0, 4) + "-" + detailData?.ilNo.slice(4)}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">법인명</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.company}
                            onChange={e =>
                              setDetailData({ ...detailData, company: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">대표자</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.owner}
                            onChange={e => setDetailData({ ...detailData, owner: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan={2} className="text-start ps-1">
                          주소
                        </th>
                        <td colSpan={3}>{detailData?.czipcode + " " + detailData?.addresC}</td>
                      </tr>
                      <tr>
                        <td colSpan={4}>{detailData?.caddres}</td>
                      </tr>
                      <tr>
                        <th rowSpan={2} className="text-start ps-1">
                          도로명 주소
                        </th>
                        <td className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control table-input input-of-button"
                            value={detailData?.rzipcode}
                          />
                          <DaumPostcode
                            onCompleteCallback={data => {
                              setDetailData({
                                ...detailData,
                                rzipcode: data?.zonecode,
                                addresName: data?.fullAddr,
                              });
                            }}
                          />
                        </td>
                        <td colSpan={2}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.addresName}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input input-of-button"
                            value={detailData?.raddres}
                            onChange={e =>
                              setDetailData({ ...detailData, raddres: e.target.value })
                            }
                          />
                          <CopyToClipboard
                            text={`${detailData?.addresName + " " + detailData?.raddres}`}
                          >
                            <button
                              className="btn btn-outline-primary table-button button-of-input h-25px float-end"
                              onClick={() => alert("복사되었습니다.")}
                            >
                              복사
                            </button>
                          </CopyToClipboard>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">업태 </th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.upte}
                            onChange={e => setDetailData({ ...detailData, upte: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">종목</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.jongmok}
                            onChange={e =>
                              setDetailData({ ...detailData, jongmok: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">비고 </th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.remark}
                            onChange={e => setDetailData({ ...detailData, remark: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">기타사항</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.remark2}
                            onChange={e =>
                              setDetailData({ ...detailData, remark2: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "13%" }} className="text-start ps-1">
                          부서명
                        </th>
                        <td style={{ width: "37%" }}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.deptName}
                            onChange={e =>
                              setDetailData({ ...detailData, deptName: e.target.value })
                            }
                          />
                        </td>
                        <th style={{ width: "13%" }} className="text-start ps-1">
                          담당자
                        </th>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.damdangja}
                            onChange={e =>
                              setDetailData({ ...detailData, damdangja: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">더존전송</th>
                        <td>
                          <select
                            className="form-select w-100"
                            value={detailData?.duzoneTrans}
                            onChange={e =>
                              setDetailData({ ...detailData, duzoneTrans: e.target.value })
                            }
                          >
                            <option value="N">N</option>
                            <option value="Y">Y</option>
                          </select>
                        </td>
                        <th className="text-start ps-1">연락처</th>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.telNo}
                            onChange={e => setDetailData({ ...detailData, telNo: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">핸드폰</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.mobileNo}
                            onChange={e =>
                              setDetailData({ ...detailData, mobileNo: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">전자우편</th>
                        <td colSpan={3}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={detailData?.email}
                            onChange={e => setDetailData({ ...detailData, email: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">구분1</th>
                        <td>
                          <select
                            className="form-select w-100"
                            value={detailData?.gubun1}
                            onChange={e => setDetailData({ ...detailData, gubun1: e.target.value })}
                          >
                            <option value="1">매출</option>
                            <option value="2">매입</option>
                          </select>
                        </td>
                        <th className="text-start ps-1">구분2</th>
                        <td>
                          <select
                            className="form-select w-50x me-2x"
                            value={detailData?.gubun2}
                            onChange={e => setDetailData({ ...detailData, gubun2: e.target.value })}
                          >
                            <option value="1">신규</option>
                            <option value="2">재발행</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-start ps-1">구분3</th>
                        <td>
                          <select
                            className="form-select w-100"
                            value={detailData?.gubun3}
                            onChange={e => setDetailData({ ...detailData, gubun3: e.target.value })}
                          >
                            <option value="1">영수</option>
                            <option value="2">청구</option>
                          </select>
                        </td>
                        <th className="text-start ps-1">구분4</th>
                        <td>
                          <select
                            className="form-select w-100"
                            value={detailData?.printGubun}
                            onChange={e =>
                              setDetailData({ ...detailData, printGubun: e.target.value })
                            }
                          >
                            <option value="Y">발행</option>
                            <option value="N">미발행</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>&nbsp;</td>
                        <td colSpan={2}>
                          <select
                            className="form-select w-100"
                            value={detailData?.sinCheck}
                            onChange={e =>
                              setDetailData({ ...detailData, sinCheck: e.target.value })
                            }
                          >
                            <option value="S">신고</option>
                            <option value="D">보류</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-8">
              <div className="card card-main">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title  m-0">회원권 거래내역</h5>
                  <div className="d-flex align-items-center justify-content-end"></div>
                </div>
                <div className="p-3">
                  <div className="table-container table-sticky h-183" id="scroll-table-body">
                    <table className="table table-bordered table-responsive my-table m-0">
                      <thead>
                        <tr>
                          <th style={{ width: "1.5%" }}>No.</th>
                          <th style={{ width: "10%" }}>일자</th>
                          <th style={{ width: "8%" }}>거래분류</th>
                          <th style={{ width: "8%" }}>구분1</th>
                          <th style={{ width: "8%" }}>구분2</th>
                          <th style={{ width: "10%" }}>회원권명</th>
                          <th style={{ width: "10%" }}>회원번호</th>
                          <th style={{ width: "8%" }}>서류금액</th>
                          <th style={{ width: "8%" }}>매매금액</th>
                          <th style={{ width: "2%" }}>UP/DOWN</th>
                          <th style={{ width: "8%" }}>수수료</th>
                          <th style={{ width: "8%" }}>거래딜러</th>
                          <th style={{ width: "15%" }}>비고</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detail.data?.memExchangeResVoList?.length > 0 ? (
                          detail.data?.memExchangeResVoList.map((data, _idx) => {
                            return (
                              <tr
                                className={`
                                ${data?.tradGubn === "개인물건" && "bg-lightyellow"}
                                ${data?.tradGubn === "법인물건" && "bg-lightblue"}
                                `}
                              >
                                <td>{_idx + 1}</td>
                                <td>
                                  {data?.custDate !== "" &&
                                    moment(data.custDate).format("YYYY-MM-DD")}
                                </td>
                                <td>{data?.tradGubn}</td>
                                <td>{data?.saleGu + "-" + data?.jisaGerae}</td>
                                <td>{data?.gubun2 + `(${data?.gubun1})`}</td>
                                <td>{data?.gmName}</td>
                                <td>{data?.memberNo}</td>
                                <td>
                                  {data?.yangdoWon2?.replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </td>
                                <td>
                                  {data?.yangdoWon1?.replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </td>
                                <td>{data?.downYn}</td>
                                <td>
                                  {data?.commission?.replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </td>
                                <td>{data?.dealer}</td>
                                <td>{data?.remark}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={13}>조회 결과가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card card-main">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h5 className="card-title  m-0">
                    거래내역 등록
                    {/* <small className="ms-3">*매출 공급 가액 수정은 재발행에서</small> */}
                  </h5>
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                      onClick={() => {
                        if (historyData.length > 3) {
                          alert("거래내역은 4건 까지 입력 가능합니다.");
                          return;
                        }
                        setHistoryData([
                          ...historyData,
                          {
                            custDate: moment(new Date()).format("YYYY-MM-DD"),
                            item: "",
                            modDate: "",
                            priceAmt: 0,
                            priceTax: 0,
                            priceTot: 0,
                            seqNo: 0,
                            userid: "",
                          },
                        ]);
                      }}
                    >
                      추가
                    </button>
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                      onClick={() => {
                        const sendData = historyData.map(data => {
                          return {
                            ...data,
                            gubun0: detailData.gubun0,
                            gubun1: detailData.gubun1,
                            ilNo: detailData.ilNo,
                            custDate: moment(data.custDate).format("YYYY-MM-DD"),
                            modDate: "",
                            userid: "",
                            priceAmt: Number(data.priceAmt),
                            priceTax: Number(data.priceTax),
                            priceTot: Number(data.priceTot),
                            seqNo: Number(data.seqNo),
                          };
                        });
                        saveTransAct.mutate(sendData);
                        setSaveHistoryData(historyData);
                      }}
                    >
                      저장
                    </button>
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                      onClick={() => {
                        setHistoryData(saveHistoryData);
                        alert("변경 사항이 취소되었습니다.");
                      }}
                    >
                      취소
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-end">
                    <label className="mb-15px">금액 단위: 원</label>
                  </div>
                  <table className="table table-bordered table-responsive my-table m-0">
                    <thead>
                      <tr>
                        <th style={{ width: "8%" }}>No</th>
                        <th style={{ width: "16%" }}>발행일자</th>
                        <th style={{ width: "16%" }}>품목</th>
                        <th style={{ width: "16%" }}>금액(공급+VAT)</th>
                        <th style={{ width: "16%" }}>공급가액</th>
                        <th style={{ width: "16%" }}>세액</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyData.length > 0 ? (
                        <>
                          <>
                            {historyData?.map((data, _idx) => {
                              return (
                                <tr>
                                  <td>{_idx + 1}</td>
                                  <td>
                                    <input
                                      type="date"
                                      min="1900-01-01"
                                      max="2099-12-31"
                                      className="form-control table-input"
                                      value={data.custDate}
                                      onChange={e => {
                                        const newHistory = _.cloneDeep(historyData);
                                        newHistory[_idx].custDate = e.target.value;
                                        setHistoryData(prev => {
                                          return [...newHistory];
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control table-input"
                                      value={data.item}
                                      onChange={e => {
                                        const newHistory = _.cloneDeep(historyData);
                                        newHistory[_idx].item = e.target.value;
                                        setHistoryData(prev => {
                                          return [...newHistory];
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control table-input text-end"
                                      value={data.priceTot
                                        ?.toString()
                                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                      onChange={e => {
                                        const newHistory = _.cloneDeep(historyData);
                                        newHistory[_idx].priceTot = Number(
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*)\./g, "$1")
                                        );
                                        newHistory[_idx].priceAmt = Math.round(
                                          (Number(e.target.value.replace(/[^0-9]/g, "")) * 10) / 11
                                        );
                                        newHistory[_idx].priceTax = (
                                          Number(data?.priceTot) - Number(data?.priceAmt)
                                        )
                                          .toString()
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*)\./g, "$1");

                                        setHistoryData(prev => {
                                          return [...newHistory];
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control table-input text-end"
                                      value={data.priceAmt
                                        ?.toString()
                                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                      onChange={e => {
                                        const newHistory = _.cloneDeep(historyData);
                                        const targetValue =
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*)\./g, "$1") === ""
                                            ? 0
                                            : e.target.value
                                                .replace(/[^0-9.]/g, "")
                                                .replace(/(\..*)\./g, "$1");
                                        if (Number(data?.priceTot) - targetValue < 0) {
                                          alert("유효하지 않은 값입니다.");
                                          return;
                                        }
                                        newHistory[_idx].priceAmt = Number(targetValue);
                                        newHistory[_idx].priceTax = (
                                          Number(data?.priceTot) - Number(targetValue)
                                        )
                                          .toString()
                                          .replace(/[^0-9.]/g, "")
                                          .replace(/(\..*)\./g, "$1");
                                        setHistoryData(prev => {
                                          return [...newHistory];
                                        });
                                      }}
                                    />
                                  </td>
                                  {/* 세액 */}
                                  <td className="text-end">
                                    <div>
                                      {(Number(data?.priceTot) - Number(data?.priceAmt))
                                        .toString()
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    {/* <input
                                      type="text"
                                      className="form-control table-input text-end"
                                      value={data.priceTax
                                        ?.toString()
                                        ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                      onChange={e => {
                                        const newHistory = historyData;
                                        (newHistory[_idx].priceTax = Number(
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*)\./g, "$1")
                                        )),
                                          setHistoryData(prev => {
                                            return [...newHistory];
                                          });
                                      }}
                                    /> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                          <tr>
                            <td colSpan={3}>
                              <div className="d-flex justify-content-end ">합계</div>
                            </td>
                            <td className="text-end">
                              {historyData
                                .reduce((acc, data) => {
                                  return acc + Number(data.priceTot);
                                }, 0)
                                .toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {historyData
                                .reduce((acc, data) => {
                                  return acc + Number(data.priceAmt);
                                }, 0)
                                .toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {historyData
                                .reduce((acc, data) => {
                                  return acc + Number(data.priceTax);
                                }, 0)
                                .toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td colSpan={6}>조회 결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ReIssueTaxInvoice;
