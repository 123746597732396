import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import moment from "moment/moment";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { useGetProcessChk } from "../../../../api/code/useGetProcessChk";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import { decrypt } from "../../../../util/decrypt/decrypt";
import { useGetMemberNm } from "../../../../api/code/useGetMemberNm";
import { useGetEtcCmQ } from "../../../../api/code/useGetEtcCmQ";
import { useGetEtcSmQ } from "../../../../api/code/useGetEtcSmQ";
import MembershipItemSelect from "../../../../components/select/MembershipItemSelect";

export const OrderManagement = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);
  const [modifyList, setModifyList] = useState([]);

  const getProcessChk = useGetProcessChk();
  const getUserIdQ = useGetUserIdQ();
  const getMemberNm = useGetMemberNm(); // 골프
  const getEtcCmQ = useGetEtcCmQ(); // 콘도
  const getEtcSmQ = useGetEtcSmQ(); // 헬스

  // 주문관리 조회
  const customerGetOrderHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getOrderHistory", {
        params: {
          custId: data,
        },
      }),
    {
      onSuccess: res => {
        setSubData(
          res.data.result.map(el => ({
            ...el,
            bigo: "",
            custGubun: "1",
            pastCounselorId: el.counselorId,
            pastCustId: mainData.seqNo,
            pastIlNo: el.ilNo,
            pastMemberNo: el.memberNo,
            pastRequestDate: el.requestDate,
          }))
        );

        setModifyList(Array.from({ length: res.data.result.length }, () => false));
      },
    }
  );

  // 주문관리 저장/수정
  const customerSaveOrderHistory = useMutation(
    data => axios.post("/api/work/customer/bottom/saveOrderHistory", data),
    {
      onSuccess: res => {
        for (const el of res.data.result) {
          if (el.msg != null) {
            if (window.confirm(el.msg)) {
              customerDeleteMembership.mutate({
                custId: el.order.custId,
                ilNo: el.order.ilNo,
                memberNo: el.order.memberNo,
                gubun3: el.order.gubun3,
              });
            }
          }
        }

        customerGetOrderHistory.mutate(mainData.seqNo);
      },
    }
  );

  // 주문관리 삭제
  const customerDeleteOrderHistory = useMutation(
    data => axios.post("/api/work/customer/bottom/deleteOrderHistory", data),
    {
      onSuccess: res => {
        customerGetOrderHistory.mutate(mainData.seqNo);
      },
    }
  );

  // 주문관리 저장/수정 이후 보유회원권 삭제
  const customerDeleteMembership = useMutation(
    data => axios.post("/api/work/customer/bottom/deleteMembership", data),
    {
      onSuccess: res => {
        // customerGetMembership.mutate(mainData.custId);
      },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetOrderHistory.mutate(mainData.seqNo);
  }, [mainData.seqNo]);

  const btn_new_register = () => {
    if (modifyList.some(el => el === true)) {
      alert("수정중인 데이터가 있습니다.");
      return;
    }

    setSubData([
      {
        bigo: "",
        company: mainData.company,
        kname: mainData.kname,
        position: mainData.position,
        tel: mainData?.cellular ?? mainData?.ctel ?? mainData?.htel ?? "",
        custId: mainData.custId,
        requestDate: moment().format("YYYY-MM-DD"),
        gubun1: "1",
        gubun3: "1",
        gubun2: "0",
        ilNo: mainData.ilNo,
        jisa: "",
        etcCodename: "0",
        requireWon: 0,
        quotationWon: 0,
        // processCheck: getProcessChk.data[0].key,
        processCheck: "0",
        // counselorId: getUserIdQ.data[0].key,
        counselorId: decrypt("userInfo").loginId,
        remark: "",
        memberNo: "0",
      },
      ...subData,
    ]);

    setModifyList([true, ...modifyList]);
  };

  const btn_register = () => {
    if (window.confirm("저장하시겠습니까?")) {
      const filterList = subData.filter((el, index) => modifyList[index]);

      if (filterList.length === 0) {
        alert("수정된 데이터가 없습니다.");
        return;
      }

      if (filterList.some(el => el.gubun3 === "0")) {
        alert("구분1을 선택해주세요.");
        return;
      }

      if (filterList.some(el => el.gubun2 === "0")) {
        alert("구분2를 선택해주세요.");
        return;
      }

      if (filterList.some(el => el.memberNo === "0")) {
        alert("회원권명을 입력해주세요.");
        return;
      }

      if (filterList.some(el => el.processCheck === "0")) {
        alert("상황을 선택해주세요.");
        return;
      }

      if (filterList.some(el => el.counselorId === "0")) {
        alert("상담자를 선택해주세요.");
        return;
      }

      customerSaveOrderHistory.mutate(
        filterList.map(el => ({
          ...el,
          company: mainData.company,
          kname: mainData.kname,
          position: mainData.position,
          tel: mainData?.cellular ?? mainData?.ctel ?? mainData?.htel ?? "",
          custId: mainData.seqNo,
        }))
      );
    }
  };

  const btn_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      customerGetOrderHistory.mutate(mainData.seqNo);
    }
  };

  const btn_delete = data => {
    if (window.confirm("삭제하시겠습니까?")) {
      customerDeleteOrderHistory.mutate({
        custId: mainData.custId,
        requestDate: data.requestDate,
        ilNo: data.ilNo,
        memberNo: data.memberNo,
        // gubun3: data.gubun3,
        counselorId: data.counselorId,
      });
    }
  };

  const selectOption_getMemberNm = useMemo(() => {
    return (
      <>
        <option value="0" disabled={true} selected={true}>
          회원권명 선택
        </option>
        {getMemberNm.data?.map(el => (
          <option value={el.code}>{el.codename}</option>
        ))}
      </>
    );
  }, [getMemberNm.data]);

  const selectOption_getUserIdQ = useMemo(() => {
    return (
      <>
        <option value="0" disabled={true} selected={true}>
          상담자 선택
        </option>
        {getUserIdQ.data?.map(el => (
          <option value={el.key}>{el.value}</option>
        ))}
      </>
    );
  }, [getUserIdQ.data]);

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">
          주문관리 (<span>{subData.length ?? "0"}</span>건)
        </div>
        <div className="d-flex">
          <button className="btn btn-primary btn-table-filter" onClick={btn_new_register}>
            등록
          </button>
          <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_register}>
            저장
          </button>
          <button className="btn btn-outline-primary tab1-row1-btn" onClick={btn_cancel}>
            취소
          </button>
        </div>
      </div>
      <div className="table-container table-sticky h-528 mt-15px">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>No.</th>
              <th style={{ width: "7%" }}>주문일자</th>
              <th style={{ width: "6%" }}>구분1</th>
              <th style={{ width: "6%" }}>구분2</th>
              <th style={{ width: "20%" }}>회원권명</th>
              <th style={{ width: "9%" }}>희망</th>
              <th style={{ width: "9%" }}>제시</th>
              <th style={{ width: "7%" }}>상황</th>
              <th style={{ width: "7%" }}>상담자</th>
              <th style={{ width: "12%" }}>비고</th>
              <th style={{ width: "7%" }}>최종수정</th>
              <th style={{ width: "5%" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {subData.length !== 0 ? (
              subData?.map((el, index) => {
                return (
                  <tr>
                    <td>{subData.length - index}</td>
                    <td>
                      <input
                        type="date"
                        className="form-control"
                        value={el?.requestDate ? el.requestDate : ""}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].requestDate = e.target.value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                        max="9999-12-31"
                      />
                    </td>
                    <td>
                      <select
                        className="form-select"
                        value={el?.gubun3}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].gubun3 = e.target.value;
                          newSubData[index].etcCodename = "0";
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      >
                        <option value="0" disabled={true} selected={true}>
                          구분1 선택
                        </option>
                        <option value="1">골프</option>
                        <option value="2">콘도</option>
                        <option value="3">헬스</option>
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-select"
                        value={el?.gubun2}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].gubun2 = e.target.value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      >
                        <option value="0" disabled={true} selected={true}>
                          구분2 선택
                        </option>
                        <option value="1">매도</option>
                        <option value="2">매수</option>
                        <option value="S">분양</option>
                      </select>
                    </td>
                    <td>
                      {el.gubun3 === "1" && (
                        // <select
                        //   className="form-select"
                        //   value={el?.memberNo}
                        //   onChange={e => {
                        //     const newSubData = [...subData];
                        //     newSubData[index].memberNo = e.target.value;
                        //     setSubData(newSubData);
                        //
                        //     const newModifyList = [...modifyList];
                        //     newModifyList[index] = true;
                        //     setModifyList(newModifyList);
                        //   }}
                        // >
                        //   <option value="0" disabled={true} selected={true}>
                        //     회원권명 선택
                        //   </option>
                        //   {getMemberNm.data?.map(el => (
                        //     <option value={el.code}>{el.codename}</option>
                        //   ))}
                        // </select>
                        <MembershipItemSelect
                          gubun={el?.gubun3}
                          data={el?.memberNo}
                          keyName={"memberNo"}
                          onChangeEventHandler={(key, value) => {
                            const newSubData = [...subData];
                            newSubData[index].memberNo = value;
                            setSubData(newSubData);

                            const newModifyList = [...modifyList];
                            newModifyList[index] = true;
                            setModifyList(newModifyList);
                          }}
                        />
                      )}

                      {el.gubun3 === "2" && (
                        // <select
                        //   className="form-select"
                        //   value={el?.memberNo}
                        //   onChange={e => {
                        //     const newSubData = [...subData];
                        //     newSubData[index].memberNo = e.target.value;
                        //     setSubData(newSubData);
                        //
                        //     const newModifyList = [...modifyList];
                        //     newModifyList[index] = true;
                        //     setModifyList(newModifyList);
                        //   }}
                        // >
                        //   <option value="0" disabled={true} selected={true}>
                        //     회원권명 선택
                        //   </option>
                        //   {getEtcCmQ.data?.map(el => (
                        //     <option value={el.key}>{el.value}</option>
                        //   ))}
                        // </select>
                        <MembershipItemSelect
                          gubun={el?.gubun3}
                          data={el?.memberNo}
                          keyName={"memberNo"}
                          onChangeEventHandler={(key, value) => {
                            const newSubData = [...subData];
                            newSubData[index].memberNo = value;
                            setSubData(newSubData);

                            const newModifyList = [...modifyList];
                            newModifyList[index] = true;
                            setModifyList(newModifyList);
                          }}
                        />
                      )}

                      {el.gubun3 === "3" && (
                        // <select
                        //   className="form-select"
                        //   value={el?.memberNo}
                        //   onChange={e => {
                        //     const newSubData = [...subData];
                        //     newSubData[index].memberNo = e.target.value;
                        //     setSubData(newSubData);
                        //
                        //     const newModifyList = [...modifyList];
                        //     newModifyList[index] = true;
                        //     setModifyList(newModifyList);
                        //   }}
                        // >
                        //   <option value="0" disabled={true} selected={true}>
                        //     회원권명 선택
                        //   </option>
                        //   {getEtcSmQ.data?.map(el => (
                        //     <option value={el.key}>{el.value}</option>
                        //   ))}
                        // </select>
                        <MembershipItemSelect
                          gubun={el?.gubun3}
                          data={el?.memberNo}
                          keyName={"memberNo"}
                          onChangeEventHandler={(key, value) => {
                            const newSubData = [...subData];
                            newSubData[index].memberNo = value;
                            setSubData(newSubData);

                            const newModifyList = [...modifyList];
                            newModifyList[index] = true;
                            setModifyList(newModifyList);
                          }}
                        />
                      )}
                    </td>
                    <td>
                      <input
                        className="form-control text-end"
                        type="text"
                        value={el?.requireWon.toLocaleString()}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].requireWon = +e.target.value
                            .replace(/,/g, "")
                            .replace(/[^0-9]/g, "");
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control text-end"
                        type="text"
                        value={el?.quotationWon.toLocaleString()}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].quotationWon = +e.target.value
                            .replace(/,/g, "")
                            .replace(/[^0-9]/g, "");
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      />
                    </td>
                    <td>
                      <select
                        className="form-select"
                        value={el?.processCheck}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].processCheck = e.target.value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      >
                        <option value="0" disabled={true} selected={true}>
                          상황 선택
                        </option>
                        {getProcessChk.data.map(el => (
                          <option value={el.key}>{el.value}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-select"
                        value={el?.counselorId}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].counselorId = e.target.value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      >
                        {/*<option value="0" disabled={true} selected={true}>*/}
                        {/*  상담자 선택*/}
                        {/*</option>*/}
                        {/*{getUserIdQ.data?.map(el => (*/}
                        {/*  <option value={el.key}>{el.value}</option>*/}
                        {/*))}*/}
                        {selectOption_getUserIdQ}
                      </select>
                    </td>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={el?.remark}
                        onChange={e => {
                          const newSubData = [...subData];
                          newSubData[index].remark = e.target.value;
                          setSubData(newSubData);

                          const newModifyList = [...modifyList];
                          newModifyList[index] = true;
                          setModifyList(newModifyList);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={getUserIdQ.data?.find(el2 => el2.key === el.modId)?.value}
                        readOnly={true}
                      />
                    </td>
                    <td className="cursor-pointer" onClick={() => btn_delete(el)}>
                      <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={13} className="text-center">
                  검색된 주문관리 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
