import { useAtom } from "jotai/index";
import {
  SMStransferHistory_deleteList_atom,
  SMStransferHistory_searchData_atom,
  SMStransferHistory_searchedTableList_atom,
  SMStransferHistory_searchedTableTotalCount_atom,
} from "../../../data/atom/workManage/SMStransferHistory/SMStransferHistory_atom";
import { useGetJisaGubun } from "../../../api/code/useGetJisaGubun";
import React, { useEffect, useRef, useState } from "react";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment";
import { SMSTransferHistoryModal } from "./modal/SMSTransferHistoryModal";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useSidebarOnClickEvent } from "../../../util/sidebarOnClickEvent/sidebarOnClickEvent";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import useInfiniteScrollWrapper from "../../../util/infiniteScroll/useInfiniteScrollWrapper";
import { decrypt } from "../../../util/decrypt/decrypt";
import { useGetUserList } from "../../../api/code/useGetUserList";

export const SMSTransferHistory = () => {
  const userPermit = decrypt("userInfo").userPermit;
  const userJisa = decrypt("userInfo").jisa;
  const userJob = decrypt("userInfo").job;

  const [infinityScroll, setInfinityScroll] = useState(null);

  const { sidebarOnClickEvent } = useSidebarOnClickEvent();
  const { addTabClosedEvent } = useRecentTabEvent();

  const [searchData, setSearchData] = useAtom(SMStransferHistory_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    SMStransferHistory_searchedTableList_atom
  );
  const [searchedTableTotalCount, setSearchedTableTotalCount] = useAtom(
    SMStransferHistory_searchedTableTotalCount_atom
  );
  const [deleteList, setDeleteList] = useAtom(SMStransferHistory_deleteList_atom);

  // 전송내역 상세정보 모달
  const [isModalOpen, setIsModalOpen] = useState({ data: null, isOpen: false });

  const getJisaGubun = useGetJisaGubun();
  const getUserIdQ = useGetUserIdQ();
  const getUserList = useGetUserList();

  // SMS 전송 내역 스팸 처리
  const smsHistoryProcSpam = useMutation(
    data => axios.post("/api/work/smsHistory/procSpam", data),
    {
      onSuccess: res => {
        let newSearchData = { ...searchData, spamText: "", page: 1 };

        setSearchedTableList(() => []);

        setSearchData({ ...searchData, spamText: "" });
        // smsHistorySearch.mutateAsync(newSearchData).then(res => {
        //   setSearchedTableList(res.data.result.list);
        //   setDeleteList(Array.from({ length: res.data.result.length }, () => false));
        //   setSearchData(newSearchData);
        // });
        refetchAllPage();
      },
      onError: err => {},
    }
  );

  // SMS 전송 내역 삭제
  const smsHistoryDelete = useMutation(data => axios.post("/api/work/smsHistory/delete", data), {
    onSuccess: res => {
      let newSearchData = { ...searchData, spamText: "", page: 1 };

      setSearchedTableList(() => []);

      // smsHistorySearch.mutateAsync(newSearchData).then(res => {
      //   setSearchedTableList(res.data.result.list);
      //   setDeleteList(Array.from({ length: res.data.result.length }, () => false));
      //   setSearchData(newSearchData);
      // });
      refetchAllPage();
    },
    onError: err => {},
  });

  // 최종일 가져오기
  const smsHistoryGetMaxDate = useMutation(() => axios.get("/api/work/smsHistory/getMaxDate"), {
    onSuccess: res => {
      setSearchData(prev => {
        return {
          ...prev,
          fromDate: moment(res.data).format("YYYY-MM-DD"),
          toDate: moment(res.data).format("YYYY-MM-DD"),
        };
      });
    },
  });

  // 고객 상세 검색
  const customerSearchDetail = useMutation(
    data => axios.get("/api/work/customer/getDetail", { params: { seqNo: data.seqNo } }),
    {
      onSuccess: (res, variables) => {
        sidebarOnClickEvent({
          name: "고객관리",
          url: "/work/customer/customerManage",
          seqNo: variables.seqNo,
          custId: variables.custId,
          kname: variables.kname,
          company: variables.company,
        });
      },
      onError: (err, variables) => {},
    }
  );

  useEffect(() => {
    if (getJisaGubun.isSuccess) {
      if (
        userPermit !== "99" &&
        userPermit !== "98" &&
        userPermit !== "91" &&
        userPermit !== "61"
      ) {
        setSearchData(prev => {
          return {
            ...prev,
            jisaGubun: userJisa,
            // sendName: decrypt("userInfo")?.loginId,
          };
        });
      } else {
        setSearchData(prev => {
          return {
            ...prev,
            jisaGubun: getJisaGubun.data[0].key,
            // sendName: decrypt("userInfo")?.userPermit === "99" ? "00" : decrypt("userInfo")?.loginId,
          };
        });
      }

      // setSearchData(prev => {
      //   return {
      //     ...prev,
      //     jisaGubun: getJisaGubun.data[0].key,
      //     // sendName: decrypt("userInfo")?.userPermit === "99" ? "00" : decrypt("userInfo")?.loginId,
      //   };
      // });
    }
  }, [getJisaGubun.isSuccess]);

  useEffect(() => {
    if (getUserList.isSuccess) {
      if (
        userPermit === "99" ||
        userPermit === "98" ||
        userPermit === "91" ||
        userPermit === "61"
      ) {
        // 전산관리자, 개인딜러마스터
        setSearchData(prev => {
          return {
            ...prev,
            sendName: "00",
          };
        });
      } else {
        setSearchData(prev => {
          return {
            ...prev,
            sendName: decrypt("userInfo")?.loginId,
          };
        });
      }
    }
  }, [getUserList.isSuccess]);

  // useEffect(() => {
  //   if (getUserIdQ.isSuccess) {
  //     setSearchData(prev => {
  //       return { ...prev, sendName: getUserIdQ.data[0].key };
  //     });
  //   }
  // }, [getUserIdQ.isSuccess]);

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSearchData([]);
      setSearchedTableTotalCount(0);
      setSearchedTableList([]);
      setDeleteList([]);
    });
  }, []);

  // 무한 스크롤 이벤트
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     entries => {
  //       if (entries[0].isIntersecting && searchedTableList.length > 90) {
  //         setSearchData(prev => {
  //           const newSearchData = { ...prev, page: prev.page + 1 };
  //
  //           smsHistorySearch.mutateAsync(newSearchData).then(res => {
  //             setSearchedTableList(prev2 => [...prev2, ...res.data.result.list]);
  //             setDeleteList(prev3 => [
  //               ...prev3,
  //               ...Array.from({ length: res.data.result.list.length }, () => false),
  //             ]);
  //           });
  //           return newSearchData;
  //         });
  //       }
  //     },
  //     { threshold: 0.5 }
  //   );
  //
  //   if (infinityScroll) {
  //     observer.observe(infinityScroll);
  //   }
  // }, [infinityScroll]);

  const btn_getMaxDate = () => {
    smsHistoryGetMaxDate.mutate();
  };

  const btn_search = () => {
    let newSearchData = { ...searchData, spamText: "", page: 1 };

    setSearchedTableList(() => []);

    // smsHistorySearch.mutateAsync(newSearchData).then(res => {
    //   setSearchedTableList(res.data.result.list);
    //   setDeleteList(Array.from({ length: res.data.result.length }, () => false));
    //   setSearchData(newSearchData);
    // });

    refetchFromFirstPage(newSearchData.data);
  };

  const btn_spam = () => {
    if (window.confirm("정말 스팸처리 하시겠습니까?")) {
      if (deleteList.every(el => el === false)) {
        alert("스팸처리할 행을 선택해주세요.");
        return;
      }

      if (searchData.spamText === "") {
        alert("스팸처리할 내용을 입력해주세요.");
        return;
      }

      if (window.confirm("해당 사용자에게 패널티를 적용하겠습니까?")) {
        smsHistoryProcSpam.mutate({
          reason: searchData.spamText,
          items: searchedTableList.filter((el, index) => deleteList[index]),
        });
      } else {
        smsHistoryProcSpam.mutate({
          reason: searchData.spamText,
          items: searchedTableList
            .filter((el, index) => deleteList[index])
            .map(el => ({
              ...el,
              userId: null,
            })),
        });
      }
    }
  };

  const btn_tableRowClick = data => {
    setIsModalOpen({ data: data, isOpen: true });
  };

  const btn_delete = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      if (deleteList.every(el => el === false)) {
        alert("삭제할 행을 선택해주세요.");
        return;
      }

      smsHistoryDelete.mutate(searchedTableList.filter((el, index) => deleteList[index]));
    }
  };

  const func_disabled_sendName = () => {
    if (userPermit === "99" || userPermit === "98" || userPermit === "91" || userPermit === "61") {
      // 전산관리자, 개인딜러마스터
      return false;
    } else if (
      userPermit === "35" ||
      userPermit === "55" ||
      userPermit === "65" ||
      userPermit === "88" ||
      userPermit === "92"
    ) {
      // 팀장-경리, 딜러, 지원팀, 연습장, 분양, 콘도, 홍도
      return false;
    } else if (userPermit === "44") {
      if (userJob === "74" || userJob === "78") {
        return false;
      } else {
        return true;
      }
    } else {
      // 기타, 팀원
      return true;
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: searchedTableList?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const fetchFirstPage = async param => {
    let res = await axios.get("/api/work/smsHistory/search", { params: param });

    if (param.page === 1) {
      setSearchedTableTotalCount(res.data.result.totalCnt);
    }

    // setDeleteList(prev => [
    //   ...prev,
    //   ...Array.from({ length: res.data.result.list.length }, () => false),
    // ]);

    setDeleteList(Array.from({ length: res.data.result.list.length }, () => false));

    return res.data.result.list;
  };

  const { endRef, refetchFromFirstPage, refetchAllPage } = useInfiniteScrollWrapper({
    fetch: fetchFirstPage,
    fetchKey: ["smsHistorySearch"],
    searchParam: searchData,
    resultSetter: setSearchedTableList,
  });

  return (
    <>
      {isModalOpen.isOpen && (
        <SMSTransferHistoryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}

      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">SMS 전송내역 조회</div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="flex-center-between w-100">
                <div className="d-flex">
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">검색조건</label>
                    <input
                      type="date"
                      className="form-control filter-input filter1-input2"
                      value={searchData.fromDate}
                      onChange={e => {
                        setSearchData({ ...searchData, fromDate: e.target.value });
                      }}
                      max="9999-12-31"
                    />
                  </div>
                  <div className="txt_daterange">~</div>
                  <div className="filter1-field filter1-field3 px-0">
                    <input
                      type="date"
                      className="form-control filter-input filter1-input2"
                      value={searchData.toDate}
                      onChange={e => {
                        setSearchData({ ...searchData, toDate: e.target.value });
                      }}
                      max="9999-12-31"
                    />
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary float-start ms-12px"
                      onClick={btn_getMaxDate}
                    >
                      최종일 선택
                    </button>
                  </div>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">지사구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.jisaGubun}
                    onChange={e => {
                      setSearchData({ ...searchData, jisaGubun: e.target.value });
                    }}
                    disabled={
                      userPermit !== "99" &&
                      userPermit !== "98" &&
                      userPermit !== "91" &&
                      userPermit !== "61"
                    }
                  >
                    {getJisaGubun.data?.map(el => (
                      <option value={el.key}>{el.value}</option>
                    ))}
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">송신구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.li_send_gubun}
                    onChange={e => {
                      setSearchData({ ...searchData, li_send_gubun: e.target.value });
                    }}
                  >
                    <option value="9">전체</option>
                    <option value="1">전송대기</option>
                    <option value="2">전송성공</option>
                    <option value="3">전송실패</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">전송구분</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.li_gubun}
                    onChange={e => {
                      setSearchData({ ...searchData, li_gubun: e.target.value });
                    }}
                  >
                    <option value="1">개별</option>
                    <option value="2">동보</option>
                    <option value="9">전체</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">전송자</label>
                  <select
                    className={`form-select filter1-select1 me-20px`}
                    value={searchData.sendName}
                    onChange={e => {
                      const temp = getUserList.data?.find(el2 => el2.userId === e.target.value);

                      if (temp?.authority === "ZZ") {
                        setSearchData(prev => {
                          return {
                            ...prev,
                            sendName: e.target.value,
                            rb: "team",
                          };
                        });
                      } else {
                        setSearchData(prev => ({ ...prev, sendName: e.target.value }));
                      }
                    }}
                    // disabled={decrypt("userInfo").userPermit !== "99"}
                    disabled={func_disabled_sendName()}
                  >
                    <option
                      value="00"
                      disabled={
                        userPermit !== "99" &&
                        userPermit !== "98" &&
                        userPermit !== "61" &&
                        userPermit !== "91"
                      }
                    >
                      전체
                    </option>
                    {getUserList.data?.map(el => (
                      <option
                        value={el.userId}
                        style={{
                          color: `${el.work1 === "22" || el.work1 === "72" ? "red" : ""}`,
                          textDecoration: `${el.work1 === "22" || el.work1 === "72" ? "line" : ""}`,
                        }}
                      >
                        {el.userName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="filter_fields_row mt-10px">
              <div className="flex-center-between w-100">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary float-start me-3px"
                      onClick={btn_spam}
                    >
                      스팸처리
                    </button>
                  </label>
                  <input
                    type="text"
                    className="form-control filter1-input1"
                    value={searchData.spamText}
                    onChange={e => {
                      setSearchData({ ...searchData, spamText: e.target.value });
                    }}
                    style={{ width: "410px" }}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      checked={searchData.rb === "mine"}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          rb: "mine",
                          sendName: decrypt("userInfo").loginId,
                        });
                      }}
                    />
                    본인것만
                  </label>
                </div>

                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox2"
                      checked={searchData.rb === "team"}
                      onChange={e => {
                        setSearchData({ ...searchData, rb: "team", sendName: "00" });
                      }}
                      disabled={decrypt("userInfo").userPermit !== "99"}
                    />
                    팀원or지사전체
                  </label>
                </div>

                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox3"
                      checked={searchData.rb === "all"}
                      onChange={e => {
                        // setSearchData({ ...searchData, rb: "all", sendName: "00" });
                        setSearchData({ ...searchData, rb: "all" });
                      }}
                      disabled={decrypt("userInfo").userPermit !== "99"}
                    />
                    전체
                  </label>
                </div>

                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">조회건수</label>
                  <input
                    type="text"
                    className="form-control filter1-input1"
                    value={searchedTableTotalCount}
                    readOnly={true}
                  />
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary ms-12px me-20px"
                    onClick={btn_search}
                  >
                    조회
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerx">
          <div className="card card-main">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5 className="card-title  m-0">SMS 전송내역</h5>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                  onClick={btn_delete}
                  disabled={searchedTableList.length === 0}
                >
                  선택행 일괄삭제
                </button>
              </div>
            </div>
            <div className="card-body">
              <div style={{ maxWidth: "1786px", overflowX: "auto" }}>
                <div className="table-container table-sticky h-633" ref={targetRef}>
                  <table
                    className="table table-bordered table-fit table-responsive my-table"
                    style={{
                      width: `${searchedTableList.length !== 0 ? "2400px" : ""}`,
                      marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${
                        rowVirtualizer.getTotalSize() -
                        (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                        rowVirtualizer.getVirtualItems().length * 30
                      }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "1.62%" }} rowSpan={2}>
                          선택
                        </th>
                        <th style={{ width: "2.5%" }} rowSpan={2}>
                          연번
                        </th>
                        <th style={{ width: "4.55%" }} rowSpan={2}>
                          구분
                        </th>
                        <th style={{ width: "6.47%" }} rowSpan={2}>
                          전송일시
                        </th>
                        <th style={{ width: "6.47%" }} rowSpan={2}>
                          예약일시
                        </th>
                        <th style={{ width: "3.04%" }} rowSpan={2}>
                          식별
                        </th>
                        <th style={{ width: "2.43%" }} rowSpan={2}>
                          전송자
                        </th>
                        <th style={{ width: "4.26%" }} rowSpan={2}>
                          제목
                        </th>
                        <th style={{ width: "5.69%" }} rowSpan={2}>
                          전송내용
                          <br />
                          (클릭-상세내용조회)
                        </th>
                        <th style={{ width: "9.72%" }} colSpan={3}>
                          수신자(클릭-고객정보 조회)
                        </th>
                        <th style={{ width: "6.86%" }} rowSpan={2}>
                          발신자번호
                        </th>
                        <th style={{ width: "3.23%" }} rowSpan={2}>
                          전송여부
                        </th>
                        <th style={{ width: "4.79%" }} rowSpan={2}>
                          전송결과
                        </th>
                        <th rowSpan={2} style={{ width: "4.79%" }}>
                          패널티 적용 사유
                          <br />
                          (클릭되는 필드)
                        </th>
                        <th style={{ width: "3.86%" }} rowSpan={2}>
                          Msg_id
                        </th>
                        <th style={{ width: "12.19%" }} colSpan={2}>
                          수 신 자 정 보
                        </th>
                        <th style={{ width: "7.31%" }} rowSpan={2}>
                          전송구분
                        </th>
                        <th style={{ width: "10.43%" }} rowSpan={2}>
                          Seq_no
                        </th>
                      </tr>
                      <tr>
                        <th>구분</th>
                        <th>성명</th>
                        <th>휴대폰번호</th>
                        <th>관리번호</th>
                        <th>업체명</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchedTableList.length !== 0 ? (
                        <>
                          {rowVirtualizer.getVirtualItems()?.map((virtualRow, index) => {
                            return (
                              <tr
                                className={`${
                                  searchedTableList[virtualRow.index].penaltyCheck === "Y"
                                    ? "bg-red"
                                    : ""
                                }`}
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={deleteList[virtualRow.index]}
                                    onChange={e => {
                                      const newDeleteList = [...deleteList];
                                      newDeleteList[virtualRow.index] = e.target.checked;
                                      setDeleteList(newDeleteList);
                                    }}
                                  />
                                </td>
                                <td>{virtualRow.index + 1}</td>
                                <td>{searchedTableList[virtualRow.index].sendType}</td>
                                <td>
                                  {searchedTableList[virtualRow.index]?.sendDate
                                    ? moment(searchedTableList[virtualRow.index].sendDate).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""}
                                </td>
                                <td>
                                  {searchedTableList[virtualRow.index]?.optionTime
                                    ? moment(searchedTableList[virtualRow.index].optionTime).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    : ""}
                                </td>
                                <td>
                                  {searchedTableList[virtualRow.index].penaltyCheck === "N"
                                    ? "정상"
                                    : searchedTableList[virtualRow.index].penaltyCheck === "Y" &&
                                      "스팸"}
                                </td>
                                <td>{searchedTableList[virtualRow.index].userName}</td>
                                <td>{searchedTableList[virtualRow.index].title}</td>
                                <td
                                  onClick={() =>
                                    btn_tableRowClick(searchedTableList[virtualRow.index])
                                  }
                                >
                                  <input
                                    type="text"
                                    className="form-control filter-input cursor-pointer"
                                    value={searchedTableList[virtualRow.index].msg}
                                    readOnly={true}
                                  />
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() => {
                                    customerSearchDetail.mutate({
                                      seqNo: searchedTableList[virtualRow.index].rcvSeqno,
                                      custId: searchedTableList[virtualRow.index].custId,
                                      kname: searchedTableList[virtualRow.index].kname,
                                      company: searchedTableList[virtualRow.index].company,
                                    });
                                  }}
                                >
                                  {searchedTableList[virtualRow.index].gubunStar}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() => {
                                    customerSearchDetail.mutate({
                                      seqNo: searchedTableList[virtualRow.index].rcvSeqno,
                                      custId: searchedTableList[virtualRow.index].custId,
                                      kname: searchedTableList[virtualRow.index].kname,
                                      company: searchedTableList[virtualRow.index].company,
                                    });
                                  }}
                                >
                                  {searchedTableList[virtualRow.index].kname}
                                </td>
                                <td
                                  className="cursor-pointer"
                                  onClick={() => {
                                    customerSearchDetail.mutate({
                                      seqNo: searchedTableList[virtualRow.index].rcvSeqno,
                                      custId: searchedTableList[virtualRow.index].custId,
                                      kname: searchedTableList[virtualRow.index].kname,
                                      company: searchedTableList[virtualRow.index].company,
                                    });
                                  }}
                                >
                                  {searchedTableList[virtualRow.index].rcvPhoneNumber}
                                </td>
                                <td>{searchedTableList[virtualRow.index].sndPhoneNumber}</td>
                                <td>
                                  {searchedTableList[virtualRow.index].flag === "1"
                                    ? "전송중"
                                    : searchedTableList[virtualRow.index].flag === "2"
                                    ? "송신완료"
                                    : searchedTableList[virtualRow.index].flag === "0" && "미전송"}
                                </td>
                                <td>{searchedTableList[virtualRow.index].resultsMsg}</td>
                                <td>
                                  {/* {searchedTableList[virtualRow.index].penaltyCheck === "N"
                                  ? ""
                                  : searchedTableList[virtualRow.index].penaltyCheck === "Y" &&
                                    "스팸"} */}
                                  {searchedTableList[virtualRow.index].penaltyRemark}
                                </td>
                                <td>{searchedTableList[virtualRow.index].seq}</td>
                                <td>
                                  {searchedTableList[virtualRow.index].custId &&
                                    searchedTableList[virtualRow.index].custId.slice(0, 4) +
                                      "-" +
                                      searchedTableList[virtualRow.index].custId.slice(4)}
                                </td>
                                <td>{searchedTableList[virtualRow.index].company}</td>
                                <td>
                                  {searchedTableList[virtualRow.index].gubun === "1"
                                    ? "개별-즉시"
                                    : searchedTableList[virtualRow.index].gubun === "2"
                                    ? "개별-예약"
                                    : searchedTableList[virtualRow.index].gubun === "3"
                                    ? "동보-즉시"
                                    : searchedTableList[virtualRow.index].gubun === "4" &&
                                      "동보-예약"}
                                </td>
                                <td>
                                  {searchedTableList[virtualRow.index].rcvSeqno &&
                                    searchedTableList[virtualRow.index].rcvSeqno.slice(0, 4) +
                                      "-" +
                                      searchedTableList[virtualRow.index].rcvSeqno.slice(4)}
                                </td>
                              </tr>
                            );
                          })}
                          {rowVirtualizer.range?.endIndex === searchedTableList.length - 1 && (
                            <div ref={endRef} style={{ height: "1px" }} />
                          )}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={21}>검색결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
