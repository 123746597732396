import { atom } from "jotai";
import moment from "moment/moment";
import { useGetEmail } from "../../../../api/code/useGetEmail";

export const searchParamInit = {
  radio: "A",
  keyword: "",
};

export const searchParam_atom = atom({ ...searchParamInit });

export const searchedParam_atom = atom({});

export const shopList_atom = atom([]);

export const shopDetail_atom = atom({});

export const tableLimit_atom = atom("5");

export const shopDetailFlag_atom = atom(false);

export const registerFlag_atom = atom(false);

export const key1b_atom = atom("비고사항");

export const bigo_atom = atom({ remark: "" });

export const bizInfo_atom = atom({});

export const accountList_atom = atom([]);
