import React, { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  clickedRow_atom,
  golfData_atom,
  greenFeeData_atom,
} from "../../../../data/atom/workManage/golfManage/golfManage_atom";
import ModalGreenFee from "../modal/ModalGreenFee";
import moment from "moment/moment";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";

export default function GreenFeeInfo() {
  const [greenFeeData, setGreenFeeData] = useAtom(greenFeeData_atom);
  const clickedRow = useAtomValue(clickedRow_atom);
  const [golfData, setGolfData] = useAtom(golfData_atom); // 골프 데이터 상태
  const [showGreenFeeModal, setShowGreenFeeModal] = useState(false);

  const manageGreenFeeGetDetail = useMutation(
    data =>
      axios.get("/api/work/manageGolf/getDetail", {
        params: { custId: data.custId },
      }),
    {
      // 성공시 콜백
      onSuccess: (res, param) => {
        let tmp2 = { ...res.data.result.greenFee };
        for (let key in tmp2) {
          if (tmp2[key] === null) tmp2[key] = "";
        }
        setGreenFeeData({ ...tmp2 });

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );
  const manageGreenFeeSaveDetail = useMutation(
    data => axios.post("/api/work/manageGolf/saveGreenFee", data),
    {
      onSuccess: res => {
        manageGreenFeeGetDetail.mutate({ custId: clickedRow.custId });
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    if (key !== "etcMember") {
      value = Number(value.replace(/[^0-9]/g, "").replace(/,/g, ""));
    }
    setGreenFeeData({ ...greenFeeData, [key]: value });
  };

  const btnHistory = () => {
    setShowGreenFeeModal(true);
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      manageGreenFeeSaveDetail.mutate([
        {
          ...greenFeeData,
          currDate: moment(greenFeeData.currDate).format("YYYY-MM-DD"),
        },
      ]);
    }
  };
  const btnCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      manageGreenFeeGetDetail.mutate({ custId: clickedRow.custId, type: "cancel" });
    }
  };

  useEffect(() => {
    if (golfData?.registering) {
      setGreenFeeData({});
    }
  }, [golfData]);

  return (
    <>
      <ModalGreenFee show={showGreenFeeModal} setShow={setShowGreenFeeModal} />
      <div className="mt-4">
        <div className="d-flex justify-content-between ">
          <div className="tab1-row1-count f-20">그린피 정보</div>
          <div className="d-flex">
            <div className="form-text f-bold" style={{ color: "#0b4da2" }}>
              {greenFeeData.modDate
                ? "수정일자: " + moment(greenFeeData.modDate).format("YYYY.MM.DD")
                : ""}
            </div>
            {!golfData?.registering && (
              <button className="btn btn-outline-primary h-25px ms-4 me-2" onClick={btnHistory}>
                그린피 히스토리 조회
              </button>
            )}
            {!golfData?.registering && (
              <button className="btn btn-outline-primary h-25px me-2" onClick={btnSave}>
                저장
              </button>
            )}
            {!golfData?.registering && (
              <button className="btn btn-outline-primary h-25px" onClick={btnCancel}>
                취소
              </button>
            )}
          </div>
        </div>
        <table className="table table-bordered table-responsive my-table1 mt-10px">
          <tbody>
            <tr>
              <th></th>
              <th style={{ width: "13%" }}>정회원</th>
              <th style={{ width: "13%" }}>가족회원</th>
              <th style={{ width: "13%" }}>주중회원</th>
              <th style={{ width: "13%" }}>주중가족</th>
              <th style={{ width: "13%" }}>비회원</th>
              <th style={{ width: "13%" }}>우대회원</th>
              <th style={{ width: "13%" }}>우대가족</th>
            </tr>
            <tr>
              <th>주중</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.memberJj ? Number(greenFeeData.memberJj).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("memberJj", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.familyJj ? Number(greenFeeData.familyJj).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("familyJj", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.jujungJj ? Number(greenFeeData.jujungJj).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("jujungJj", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={
                    greenFeeData.jjFamilyJj ? Number(greenFeeData.jjFamilyJj).toLocaleString() : 0
                  }
                  onChange={e => onChangeEventHandler("jjFamilyJj", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={
                    greenFeeData.noMemberJj ? Number(greenFeeData.noMemberJj).toLocaleString() : 0
                  }
                  onChange={e => onChangeEventHandler("noMemberJj", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.woodaeJj ? Number(greenFeeData.woodaeJj).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("woodaeJj", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={
                    greenFeeData.wooFamilyJj ? Number(greenFeeData.wooFamilyJj).toLocaleString() : 0
                  }
                  onChange={e => onChangeEventHandler("wooFamilyJj", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>주말</th>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.memberJm ? Number(greenFeeData.memberJm).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("memberJm", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.familyJm ? Number(greenFeeData.familyJm).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("familyJm", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.jujungJm ? Number(greenFeeData.jujungJm).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("jujungJm", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={
                    greenFeeData.jjFamilyJm ? Number(greenFeeData.jjFamilyJm).toLocaleString() : 0
                  }
                  onChange={e => onChangeEventHandler("jjFamilyJm", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={
                    greenFeeData.noMemberJm ? Number(greenFeeData.noMemberJm).toLocaleString() : 0
                  }
                  onChange={e => onChangeEventHandler("noMemberJm", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={greenFeeData.woodaeJm ? Number(greenFeeData.woodaeJm).toLocaleString() : 0}
                  onChange={e => onChangeEventHandler("woodaeJm", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={
                    greenFeeData.wooFamilyJm ? Number(greenFeeData.wooFamilyJm).toLocaleString() : 0
                  }
                  onChange={e => onChangeEventHandler("wooFamilyJm", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>기타회원</th>
              <td colSpan={7}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={greenFeeData.etcMember ? greenFeeData.etcMember : ""}
                  onChange={e => onChangeEventHandler("etcMember", e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
