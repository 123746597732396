import React from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  defaultCondition_atom,
  result_atom,
  ydInput_atom,
  ydInputInit,
} from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import moment from "moment/moment";

export default function DefaultCondition() {
  const [defaultCondition, setDefaultCondition] = useAtom(defaultCondition_atom);
  const [ydInput, setYdInput] = useAtom(ydInput_atom);
  const result = useAtomValue(result_atom);

  const onChangeEventHandler = (key, value) => {
    if (key === "singo") {
      if (value === "D") {
        setDefaultCondition({
          ...defaultCondition,
          [key]: value,
          napbu: moment().format("YYYY-MM-DD"),
        });
      } else {
        setDefaultCondition({
          ...defaultCondition,
          [key]: value,
          napbu: "",
          sungsilCheck: false,
          sungsil: "",
        });
      }
    } else if (key === "sungsilCheck") {
      if (value) {
        setDefaultCondition({ ...defaultCondition, [key]: value });
      } else {
        setDefaultCondition({ ...defaultCondition, [key]: value, sungsil: "" });
      }
    } else if (key === "yangdo") {
      setDefaultCondition({ ...defaultCondition, [key]: value });
      if (value === 1) {
        setYdInput([{ ...ydInput[0] }, ydInputInit, ydInputInit, ydInputInit, ydInputInit]);
      } else if (value === 2) {
        setYdInput([{ ...ydInput[0] }, { ...ydInput[1] }, ydInputInit, ydInputInit, ydInputInit]);
      } else if (value === 3) {
        setYdInput([
          { ...ydInput[0] },
          { ...ydInput[1] },
          { ...ydInput[2] },
          ydInputInit,
          ydInputInit,
        ]);
      } else if (value === 4) {
        setYdInput([
          { ...ydInput[0] },
          { ...ydInput[1] },
          { ...ydInput[2] },
          { ...ydInput[3] },
          ydInputInit,
        ]);
      } else if (value === 5) {
        setYdInput([
          { ...ydInput[0] },
          { ...ydInput[1] },
          { ...ydInput[2] },
          { ...ydInput[3] },
          { ...ydInput[4] },
        ]);
      }
    } else {
      setDefaultCondition({ ...defaultCondition, [key]: value });
    }
  };

  const btnCalcReason = () => {
    let fileName = `환산_거래_산출_근거_${moment().format("YY_MM_DD_hh_mm")}.txt`;
    let output = result.calcReasonText;
    const element = document.createElement("a");
    const file = new Blob([output], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="card card-main">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title  m-0">기본조건</h5>
        <div className="d-flex align-items-center justify-content-end">
          <button
            className="btn btn-outline-primary ms-8px"
            onClick={btnCalcReason}
            disabled={result.calcReasonText === ""}
          >
            환산 거래시 산출근거 보기
          </button>
        </div>
      </div>
      <div className="card-body">
        <table className="table table-bordered table-responsive my-table mt-2">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>양도건수</th>
              <td style={{ width: "10%" }}>
                <select
                  className="form-select"
                  value={String(defaultCondition.yangdo)}
                  onChange={e => onChangeEventHandler("yangdo", Number(e.target.value))}
                >
                  <option value="1">1건</option>
                  <option value="2">2건</option>
                  <option value="3">3건</option>
                  <option value="4">4건</option>
                  <option value="5">5건</option>
                </select>
              </td>
              <th style={{ width: "10%" }}>기본공제</th>
              <td style={{ width: "10%" }}>
                <div className="flex-center-between">
                  <input
                    type="text"
                    className="form-control text-end"
                    value={defaultCondition.gongje.toLocaleString()}
                    onChange={e =>
                      onChangeEventHandler("gongje", Number(e.target.value.replace(/,/g, "")))
                    }
                  />
                  &nbsp;원
                </div>
              </td>
              <th style={{ width: "10%" }}>신고구분</th>
              <td style={{ width: "10%" }}>
                <select
                  className="form-select"
                  value={defaultCondition.singo}
                  onChange={e => onChangeEventHandler("singo", e.target.value)}
                >
                  <option value="A">예정</option>
                  <option value="B">확정</option>
                  <option value="C">수정</option>
                  <option value="D">기한 후</option>
                </select>
              </td>
              <th style={{ width: "10%" }}>납부예정일</th>
              <td style={{ width: "10%" }}>
                <input
                  type="date"
                  className="form-control"
                  value={defaultCondition.napbu}
                  onChange={e => onChangeEventHandler("napbu", e.target.value)}
                  max="9999-12-31"
                  disabled={defaultCondition.singo !== "D"}
                />
              </td>

              <th style={{ width: "10%" }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={defaultCondition.sungsilCheck}
                  onChange={e => onChangeEventHandler("sungsilCheck", e.target.checked)}
                  disabled={defaultCondition.singo !== "D"}
                />
                납부 불성실
              </th>
              <td style={{ width: "10%" }}>
                <select
                  className="form-select"
                  value={defaultCondition.sungsil}
                  onChange={e => onChangeEventHandler("sungsil", e.target.value)}
                  disabled={defaultCondition.singo !== "D" || !defaultCondition.sungsilCheck}
                >
                  <option value="A">무신고</option>
                  <option value="B">과소신고</option>
                  <option value="" hidden={true}></option>
                </select>
              </td>
            </tr>
          </thead>
        </table>
        <div className="d-flex mt-10px">
          <input
            type="checkbox"
            className="form-check-input"
            checked={defaultCondition.singo === "D"}
            id="napbu03mul"
          />
          <label className="f-regular f-15 cursor-pointer" htmlFor="napbu03mul">
            납부 기한 후 양도세 산출 시에는 계산 후에 반드시 체크(가산세:1일에 0.022%)
          </label>
        </div>
      </div>
    </div>
  );
}
