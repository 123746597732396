import React, { useState } from "react";
import "./assets/font/Font.css";
import "./css/common.css";
import { RootRouter } from "./routes/rootRouter";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useSetAtom } from "jotai/index";
import { isLoadingSpinner_atom } from "./data/atom/isLoadingSpinner_atom";

function App() {
  const setIsLoading = useSetAtom(isLoadingSpinner_atom);
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0,
          refetchOnWindowFocus: false,
          // staleTime: 1800000,
        },
        mutations: {
          onMutate: () =>
            setIsLoading(prev => {
              prev.push(true);
              return [...prev];
            }),
          onSettled: () =>
            setIsLoading(prev => {
              prev.pop();
              return [...prev];
            }),
        },
      },
    })
  );

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <BrowserRouter>
            <RootRouter />
          </BrowserRouter>
        </CookiesProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
