import { atom } from "jotai";
import moment from "moment/moment";

export const searchParamInit = {
  keyword: "",
  page: "1",
  searchType: "1",
  userSelect: "00",
  date: moment().format("YYYY-MM-DD"),
};

export const searchParam_atom = atom({ ...searchParamInit });

export const registerFlag_atom = atom(false);

export const detailFlag_atom = atom(false);

export const userOption_atom = atom([]);

export const accountOption_atom = atom([]);

export const quotationMainUserOption_atom = atom([]);

export const quotationList_atom = atom([]);

export const quotationListLimit_atom = atom(5);

export const quotationMain_atom = atom({});

export const quotationSubList_atom = atom([]);

export const taxGolfOption_atom = atom([]);

export const clickedRow_atom = atom({});
