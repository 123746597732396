import { useState } from "react";

function useCopyClipBoard() {
  const [isCopy, setIsCopy] = useState(false);

  const onCopy = async text => {
    try {
      await navigator.clipboard.writeText(text);
      alert("복사되었습니다.");
      setIsCopy(prve => {
        return true;
      });

      return true;
    } catch (error) {
      setIsCopy(prve => {
        return false;
      });
      return false;
    }
  };

  return [isCopy, onCopy];
}

export default useCopyClipBoard;
