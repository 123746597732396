import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment";
import Modal from "../../../../../components/modal/Modal";

const Tab9 = ({ custId }) => {
  const [tableData, setTableData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const bottomGetNews = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getNews", {
        params: { custId: data.custId },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setTableData([...result]);

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );

  const bottomSaveNews = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/saveNews", data),
    {
      onSuccess: () => {
        bottomGetNews.mutate({ custId: custId });
        setModalShow(false);
      },
    }
  );

  const bottomDeleteNews = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/deleteNews", data),
    {
      onSuccess: () => {
        bottomGetNews.mutate({ custId: custId });
      },
    }
  );

  useEffect(() => {
    if (custId) bottomGetNews.mutate({ custId: custId });
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetNews.mutate({ custId: custId });
  }, []);

  const onChangeModalData = (key, value) => {
    setModalData({ ...modalData, [key]: value });
  };

  const btn_add = () => {
    setModalShow(true);
    setModalData({
      custId: custId,
      newsDate: moment().format("YYYY-MM-DD"),
      gubun: "",
      title: "",
      detail: "",
    });
  };

  const btn_remove = seqNo => {
    if (confirm("정말 삭제 하시겠습니까?"))
      bottomDeleteNews.mutate({ custId: custId, seqNo: seqNo });
  };

  const btn_save = () => {
    bottomSaveNews.mutate({
      ...modalData,
      newsDate: moment(modalData.newsDate).format("YYYY-MM-DD"),
    });
  };

  return (
    <>
      <Modal
        title="골프뉴스"
        show={modalShow}
        onHide={() => setModalShow(false)}
        footer={
          <>
            <button className="btn btn-outline-primary" onClick={() => setModalShow(false)}>
              취소
            </button>
            <button className="btn btn-outline-primary" onClick={btn_save}>
              저장
            </button>
          </>
        }
      >
        <div>
          <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
            <tbody>
              <tr>
                <th style={{ width: "15%" }}>뉴스일자</th>
                <td>
                  <input
                    type="date"
                    className="form-control table-input"
                    max="9999-12-31"
                    value={modalData?.newsDate && moment(modalData.newsDate).format("YYYY-MM-DD")}
                    onChange={e =>
                      onChangeModalData("newsDate", moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </td>
                <th style={{ width: "15%" }}>출처</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={modalData?.gubun}
                    onChange={e => onChangeModalData("gubun", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={modalData?.title}
                    onChange={e => onChangeModalData("title", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="p-3">
                  <textarea
                    className="form-control"
                    rows={10}
                    value={modalData?.detail}
                    onChange={e => onChangeModalData("detail", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="tab2-content-container">
        <div className="table-container mb-3">
          <table className="table table-bordered table-responsive my-table">
            <thead>
              <tr>
                <th>뉴스일자</th>
                <th style={{ width: "15%" }}>출처</th>
                <th style={{ width: "60%" }}>제목</th>
                <th style={{ width: "5%" }}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      setModalShow(true);
                      setModalData(item);
                    }}
                  >
                    <td>{item?.newsDate && moment(item.newsDate).format("YYYY/MM/DD")}</td>
                    <td>{item?.gubun}</td>
                    <td>{item?.title}</td>
                    <td
                      onClick={e => {
                        e.stopPropagation();
                        btn_remove(item.seqNo);
                      }}
                    >
                      <a href={void 0}>
                        <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    검색된 결과가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-end my-2">
          <button className="btn btn-outline-primary" onClick={btn_add}>
            추가
          </button>
        </div>
      </div>
    </>
  );
};

export default React.memo(Tab9);
