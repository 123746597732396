import React, { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import Modal from "../../../../../../components/modal/Modal";
import ModalRow from "./ModalRow";

const HistoryModal = ({ modalShow, modalTitle, onHide, data = [] }) => {
  const targetRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <Modal size={"lg"} show={modalShow} title={modalTitle} onHide={onHide}>
      <div className="table-container table-sticky h-633" ref={targetRef}>
        <table
          className="table table-bordered table-responsive my-table"
          style={{
            marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
            marginBottom: `${
              rowVirtualizer.getTotalSize() -
              (rowVirtualizer.range?.startIndex ?? 0) * 30 -
              rowVirtualizer.getVirtualItems().length * 30
            }px`,
          }}
        >
          <thead>
            <tr>
              <th rowSpan={2} style={{ width: "5%" }}>
                No
              </th>
              <th rowSpan={2} style={{ width: "7.5%" }}>
                인터넷 View
              </th>
              <th rowSpan={2} style={{ width: "7.5%" }}>
                시세 관리 종목
              </th>
              <th rowSpan={2} style={{ width: "15%" }}>
                기준일자
              </th>
              <th colSpan={3} style={{ width: "25%" }}>
                시세
              </th>
              <th colSpan={2} style={{ width: "20%" }}>
                등락폭
              </th>
              <th rowSpan={2} style={{ width: "20%" }}>
                비고
              </th>
            </tr>
            <tr>
              <th style={{ width: "5%" }}></th>
              <th style={{ width: "10%" }}>매도시세</th>
              <th style={{ width: "10%" }}>매수시세</th>
              <th style={{ width: "10%" }}>매도</th>
              <th style={{ width: "10%" }}>매수</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              rowVirtualizer
                .getVirtualItems()
                .map(item => <ModalRow key={item.key} data={data[item.index]} index={item.index} />)
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  검색된 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
export default HistoryModal;
