import React from "react";
import { searchParam_atom } from "../../../../data/atom/workManage/golfManage/golfManage_atom";
import { useAtom } from "jotai/index";

const SearchPanel = props => {
  const { fetchFirstPage, refetchAllPage } = props;
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);

  const onEnterEvent = e => {
    if (e.key === "Enter") fetchFirstPage();
  };

  return (
    <div className="filter_fields_container">
      <div className="filter_fields_row  pe-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="filter1-field filter1-field1">
            <input
              type="checkbox"
              id="chk-1"
              className="form-check-input"
              checked={searchParam.holsu}
              onChange={e => setSearchParam(prev => ({ ...prev, holsu: e.target.checked }))}
            />
            <label className="filter1-label me-15px" htmlFor="chk-1">
              회원제
            </label>
            <input
              type="checkbox"
              id="chk-2"
              className="form-check-input"
              checked={searchParam.holsuP}
              onChange={e => setSearchParam(prev => ({ ...prev, holsuP: e.target.checked }))}
            />
            <label className="filter1-label me-15px" htmlFor="chk-2">
              퍼블릭
            </label>
            <input
              type="checkbox"
              id="chk-3"
              className="form-check-input"
              checked={searchParam.holsuP3}
              onChange={e => setSearchParam(prev => ({ ...prev, holsuP3: e.target.checked }))}
            />
            <label className="filter1-label" htmlFor="chk-3">
              퍼3
            </label>
          </div>
          <div className="filter1-field filter1-field1">
            <label className="filter1-label" htmlFor="keyword1">
              골프장명
            </label>
            <input
              type="text"
              id="keyword1"
              className="form-control filter-input filter1-input1 w-200"
              value={searchParam.keyword}
              onChange={e => setSearchParam(prev => ({ ...prev, keyword: e.target.value }))}
              onKeyDown={e => onEnterEvent(e)}
            />
          </div>
          <div className="filter1-field filter1-field1">
            <label className="filter1-label" htmlFor="keyword2">
              지 역 명
            </label>
            <input
              type="text"
              id="keyword2"
              className="form-control filter-input filter1-input1 w-200"
              value={searchParam.keyword2}
              onChange={e => setSearchParam(prev => ({ ...prev, keyword2: e.target.value }))}
              onKeyDown={e => onEnterEvent(e)}
            />
            <button
              className="btn btn-outline-primary btn-filter-outline-primary me-0 ms-8px"
              onClick={fetchFirstPage}
            >
              <img
                className="me-2"
                src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                alt=""
              />
              검색
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchPanel;
