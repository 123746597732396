import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  sportManage_mainData_atom,
  sportManage_mainDataInit_atom,
  sportManage_prevOutDate_atom,
  sportManage_searchData_atom,
  sportManage_searchDataInit_atom,
  sportManage_searchedTableList_atom,
  sportManage_subData_atom,
  sportManage_tableLimit_atom,
} from "../../../data/atom/workManage/sportMasterManage/sportMasterManage_atom";
import { DaumPostcode } from "../../../components/daumPostcode/daumPostcode";
import { useGetBank } from "../../../api/code/useGetBank";
import moment from "moment";
import { regex_only_number } from "../../../util/validation";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const SportsMasterManage = () => {
  const { addTabClosedEvent } = useRecentTabEvent();

  const [tableLimit, setTableLimit] = useAtom(sportManage_tableLimit_atom);
  const [searchData, setSearchData] = useAtom(sportManage_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(sportManage_searchedTableList_atom);
  const [mainData, setMainData] = useAtom(sportManage_mainData_atom);
  const [subData, setSubData] = useAtom(sportManage_subData_atom);
  const [prevOutDate, setPrevOutDate] = useAtom(sportManage_prevOutDate_atom);
  const [selectedSubTab, setSelectedSubTab] = useState("구분 및 자격(웹)");
  const getBank = useGetBank();

  // 스포츠마스터 목록 조회
  const sportsSearch = useMutation(data => axios.get("/api/work/sports/search", { params: data }), {
    onSuccess: res => {
      setSearchedTableList(res.data.result);
    },
    onError: (err, variables) => {
      if (err.response.data.code === "-5") {
        if (window.confirm(err.response.data.message)) {
          if (searchData.condition === "A") {
            setMainData({
              caddres: "",
              cfax: "",
              closeDay: "",
              company: variables.keyword,
              ctel: "",
              custId: "0",
              czipcode: "",
              dept: "",
              dmCheck: "",
              gaesuWon: "",
              kname: "",
              memberInwon: "",
              modDate: "",
              outCheck: "N",
              outDate: "",
              payAccount: "",
              payBank: "",
              payName: "",
              priceChiduk1: "",
              priceChiduk2: "",
              priceChiduk3: "",
              priceGaesu1: "",
              priceGaesu2: "",
              priceGaesu3: "",
              priceNonmember: "",
              priceYear1: "",
              priceYear2: "",
              priceYear3: "",
              reason: "",
              siteUrl: "",
              size: "",
              uncompany: "",
              userid: "",
              webViewChk: "",
            });
          } else if (searchData.condition === "B") {
            setMainData({
              caddres: "",
              cfax: "",
              closeDay: "",
              company: "",
              ctel: "",
              custId: "0",
              czipcode: "",
              dept: "",
              dmCheck: "",
              gaesuWon: "",
              kname: variables.keyword,
              memberInwon: "",
              modDate: "",
              outCheck: "N",
              outDate: "",
              payAccount: "",
              payBank: "",
              payName: "",
              priceChiduk1: "",
              priceChiduk2: "",
              priceChiduk3: "",
              priceGaesu1: "",
              priceGaesu2: "",
              priceGaesu3: "",
              priceNonmember: "",
              priceYear1: "",
              priceYear2: "",
              priceYear3: "",
              reason: "",
              siteUrl: "",
              size: "",
              uncompany: "",
              userid: "",
              webViewChk: "",
            });
          }
        }
      }
    },
  });

  /// 스포츠마스터 상세조회
  const sportsGetDetail = useMutation(
    data => axios.get("/api/work/sports/getDetail", { params: data }),
    {
      onSuccess: res => {
        setMainData({
          ...res.data.result,
          outDate: res.data.result?.outDate
            ? moment(res.data.result?.outDate).format("YYYY-MM-DD")
            : "",
          caddresA: res.data.result?.addres,
          caddresB: res.data.result?.caddres,
        });
        setPrevOutDate(
          res.data.result?.outDate ? moment(res.data.result?.outDate).format("YYYY-MM-DD") : ""
        );
      },
    }
  );

  // 스포츠마스터 저장/수정
  const sportsSave = useMutation(data => axios.post("/api/work/sports/save", data), {
    onSuccess: (res, variables) => {
      if (variables.custId) {
        sportsGetDetail.mutate({ custId: mainData.custId });
      } else {
        setMainData({
          caddres: "",
          cfax: "",
          closeDay: "",
          company: "",
          ctel: "",
          custId: "",
          czipcode: "",
          dept: "",
          dmCheck: "",
          gaesuWon: "",
          kname: "",
          memberInwon: "",
          modDate: "",
          outCheck: "N",
          outDate: "",
          payAccount: "",
          payBank: "",
          payName: "",
          priceChiduk1: "",
          priceChiduk2: "",
          priceChiduk3: "",
          priceGaesu1: "",
          priceGaesu2: "",
          priceGaesu3: "",
          priceNonmember: "",
          priceYear1: "",
          priceYear2: "",
          priceYear3: "",
          reason: "",
          siteUrl: "",
          size: "",
          uncompany: "",
          userid: "",
          webViewChk: "",
        });
      }

      setSearchedTableList(() => []);

      sportsSearch.mutate({ condition: searchData.condition, keyword: searchData.keyword });
    },
  });

  // 스포츠마스터 우측 탭 조회
  const sportsGetRemark = useMutation(
    data => axios.get("/api/work/sports/getRemark", { params: data }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  // 스포츠마스터 우측 탭 저장/수정
  const sportsSaveRemark = useMutation(data => axios.post("/api/work/sports/saveRemark", data), {
    onSuccess: res => {
      // sportsGetDetail.mutate({ custId: mainData.custId });
      sportsGetRemark.mutate({ custId: mainData.custId });
    },
  });

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setTableLimit("5");
      setSearchData(sportManage_searchDataInit_atom);
      setSearchedTableList([]);
      setMainData(sportManage_mainDataInit_atom);
      setSubData([]);
    });
  }, []);

  const btn_search = () => {
    setSearchedTableList(() => []);

    sportsSearch.mutate(searchData);
  };

  const btn_tableRowClick = data => {
    sportsGetDetail
      .mutateAsync({ custId: data.custId })
      .then(res => sportsGetRemark.mutate({ custId: res.data.result.custId }));
  };

  const btn_newRegister = () => {
    if (window.confirm("신규 등록하시겠습니까?")) {
      setMainData({
        addres: "",
        caddres: "",
        cfax: "",
        closeDay: "",
        company: "",
        ctel: "",
        custId: "0",
        czipcode: "",
        dept: "",
        dmCheck: "",
        gaesuWon: "",
        kname: "",
        memberInwon: "",
        modDate: "",
        outCheck: "N",
        outDate: "",
        payAccount: "",
        payBank: "",
        payName: "",
        priceChiduk1: "",
        priceChiduk2: "",
        priceChiduk3: "",
        priceGaesu1: "",
        priceGaesu2: "",
        priceGaesu3: "",
        priceNonmember: "",
        priceYear1: "",
        priceYear2: "",
        priceYear3: "",
        reason: "",
        siteUrl: "",
        size: "",
        uncompany: "",
        userid: "",
        webViewChk: "",
        caddresA: "",
        caddresB: "",
      });
    }
  };

  const btn_main_save = () => {
    if (mainData.custId === "0") {
      if (window.confirm("신규등록을 하시겠습니까?")) {
        sportsSave.mutate({
          ...mainData,
          caddres: mainData.caddres,
          caddresA: mainData?.caddresA ? mainData?.caddresA : "",
          caddresB: mainData?.caddresB ? mainData?.caddresB : "",
          closeDay: mainData.closeDay,
          company: mainData.company,
          custId: null,
          czipcode: mainData.czipcode,
          dept: mainData.dept,
          dmCheck: mainData.dmCheck,
          gaesuWon: mainData.gaesuWon,
          kname: mainData.kname,
          memberInwon: mainData.memberInwon,
          modDate: null,
          outCheck: mainData.outCheck,
          outDate: mainData.outDate,
          payAccount: mainData.payAccount,
          payBank: mainData.payBank,
          payName: mainData.payName,
          priceChiduk1: mainData.priceChiduk1,
          priceChiduk2: mainData.priceChiduk2,
          priceChiduk3: mainData.priceChiduk3,
          priceGaesu1: mainData.priceGaesu1,
          priceGaesu2: mainData.priceGaesu2,
          priceGaesu3: mainData.priceGaesu3,
          priceNonmember: mainData.priceNonmember,
          priceYear1: mainData.priceYear1,
          priceYear2: mainData.priceYear2,
          priceYear3: mainData.priceYear3,
          reason: mainData.reason,
          siteUrl: mainData.siteUrl,
          size: mainData.size,
          uncompany: mainData.uncompany,
          userid: mainData.userid,
          webViewChk: mainData.webViewChk,
        });
      }
    } else {
      if (window.confirm("수정하시겠습니까?")) {
        sportsSave.mutate({
          ...mainData,
          caddres: mainData.caddres,
          caddresA: mainData?.caddresA ? mainData?.caddresA : "",
          caddresB: mainData?.caddresB ? mainData?.caddresB : "",
          closeDay: mainData.closeDay,
          company: mainData.company,
          custId: mainData.custId,
          czipcode: mainData.czipcode,
          dept: mainData.dept,
          dmCheck: mainData.dmCheck,
          gaesuWon: mainData.gaesuWon,
          kname: mainData.kname,
          memberInwon: mainData.memberInwon,
          outCheck: mainData.outCheck,
          outDate: mainData.outDate,
          payAccount: mainData.payAccount,
          payBank: mainData.payBank,
          payName: mainData.payName,
          priceChiduk1: mainData.priceChiduk1,
          priceChiduk2: mainData.priceChiduk2,
          priceChiduk3: mainData.priceChiduk3,
          priceGaesu1: mainData.priceGaesu1,
          priceGaesu2: mainData.priceGaesu2,
          priceGaesu3: mainData.priceGaesu3,
          priceNonmember: mainData.priceNonmember,
          priceYear1: mainData.priceYear1,
          priceYear2: mainData.priceYear2,
          priceYear3: mainData.priceYear3,
          reason: mainData.reason,
          siteUrl: mainData.siteUrl,
          size: mainData.size,
          uncompany: mainData.uncompany,
          userid: mainData.userid,
          webViewChk: mainData.webViewChk,
        });
      }
    }
  };

  const btn_main_cancel = () => {
    if (mainData.custId === "0") {
      if (window.confirm("신규등록을 취소하시겠습니까?")) {
        setMainData({
          caddres: "",
          cfax: "",
          cfax1: "",
          cfax2: "",
          cfax3: "",
          closeDay: "",
          company: "",
          ctel: "",
          ctel1: "",
          ctel2: "",
          ctel3: "",
          custId: "",
          czipcode: "",
          dept: "",
          dmCheck: "",
          gaesuWon: "",
          kname: "",
          memberInwon: "",
          modDate: "",
          outCheck: "N",
          outDate: "",
          payAccount: "",
          payBank: "",
          payName: "",
          priceChiduk1: "",
          priceChiduk2: "",
          priceChiduk3: "",
          priceGaesu1: "",
          priceGaesu2: "",
          priceGaesu3: "",
          priceNonmember: "",
          priceYear1: "",
          priceYear2: "",
          priceYear3: "",
          reason: "",
          siteUrl: "",
          size: "",
          uncompany: "",
          userid: "",
          webViewChk: "",

          caddresA: "",
          caddresB: "",
        });
      }
    } else {
      if (window.confirm("수정을 취소하시겠습니까?")) {
        sportsGetDetail.mutate({ custId: mainData.custId });
      }
    }
  };

  const btn_sub_save = (data, type) => {
    if (window.confirm("저장하시겠습니까?")) {
      sportsSaveRemark.mutate({ custId: mainData.custId, remark: data, type: type });
    }
  };

  const btn_sub_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      sportsGetRemark.mutate({ custId: mainData.custId });
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">스포츠 마스터 관리</div>
            <div className="filter-buttons">
              <button className="btn btn-primary btn-table-filter" onClick={btn_newRegister}>
                등록
              </button>
            </div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchData.condition}
                    onChange={e => {
                      if (e.target.value === "C") {
                        setSearchData({ ...searchData, condition: e.target.value, keyword: "11" });
                      } else {
                        setSearchData({ ...searchData, condition: e.target.value, keyword: "" });
                      }
                    }}
                  >
                    <option value="A">헬스장명</option>
                    <option value="B">명의담당</option>
                    <option value="C">지역</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">검색어</label>
                  {searchData.condition === "C" ? (
                    <select
                      className="form-select filter1-select1"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    >
                      <option value="11">강남권</option>
                      <option value="22">강북권</option>
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({ ...searchData, keyword: e.target.value });
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                  onClick={btn_search}
                >
                  <img
                    className="me-2"
                    src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                    alt=""
                  />
                  검색
                </button>
              </div>
            </div>
          </div>

          <div className="card card-sub">
            <div className="card-body">
              <div className="containerx">
                <div className="table-container">
                  <div className="d-flex align-items-center justify-content-end">
                    <select
                      className="form-select filter-perpage ms-3"
                      value={tableLimit}
                      onChange={e => {
                        setTableLimit(e.target.value);
                      }}
                    >
                      <option value="5">5개씩 보기</option>
                      <option value="10">10개씩 보기</option>
                      <option value="20">20개씩 보기</option>
                    </select>
                  </div>
                  <div
                    className={`table-sticky mt-2
                      ${tableLimit === "5" && "h-183"}
                      ${tableLimit === "10" && "h-333"}
                      ${tableLimit === "20" && "h-633"}`}
                  >
                    <table className="table table-bordered table-responsive my-table mt-0">
                      <thead>
                        <tr>
                          <th style={{ width: "8%" }}>연번</th>
                          <th style={{ width: "41%" }}>회사명</th>
                          <th style={{ width: "41%" }}>명의담당</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchedTableList.length !== 0 ? (
                          searchedTableList.map((el, index) => {
                            return (
                              <tr
                                className={`cursor-pointer ${el.outCheck === "Y" && "bg-red"}
                                ${mainData?.custId === el?.custId && "bg-blue"}
                                `}
                                onClick={() => btn_tableRowClick(el)}
                              >
                                <td>{index + 1}</td>
                                <td>{el.company}</td>
                                <td>{el.kname}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3}>검색결과가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {mainData?.custId && (
            <div className="two-col-outer mt-14px">
              <div className="col-left">
                <div className="card card-main mt-0">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title  m-0">스포츠장 정보</h5>
                    <div
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "8px" }}
                    >
                      <button className="btn btn-outline-primary" onClick={btn_main_save}>
                        저장
                      </button>
                      <button className="btn btn-outline-primary" onClick={btn_main_cancel}>
                        취소
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="tab1-row1-count">
                        관리번호: {mainData?.custId === "0" ? "신규등록" : mainData?.custId}
                      </div>
                      <div className="tab1-row1-field1"></div>
                    </div>

                    <table className="table table-bordered table-responsive my-table1 mt-2">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>회사명</th>
                          <td style={{ width: "30%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.company}
                              onChange={e => {
                                setMainData({ ...mainData, company: e.target.value });
                              }}
                            />
                          </td>
                          <th style={{ width: "15%" }}>운영회사</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.uncompany}
                              onChange={e => {
                                setMainData({ ...mainData, uncompany: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th rowSpan={2}>주소</th>
                          <td>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control table-input w-100"
                                value={mainData.czipcode}
                              />
                              <DaumPostcode
                                className="ms-3px btn btn-outline-primary"
                                onCompleteCallback={data =>
                                  setMainData({
                                    ...mainData,
                                    czipcode: data.zonecode,
                                    addres: data.fullAddr,
                                    caddresA: data.fullAddr,
                                  })
                                }
                              />
                            </div>
                          </td>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input w-100 m-0"
                              value={mainData.addres}
                              // onChange={e => {
                              //   setMainData({ ...mainData, addres: e.target.value });
                              // }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <input
                              type="text"
                              className="form-control table-input w-100 m-0"
                              value={mainData.caddres}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  caddres: e.target.value,
                                  caddresB: e.target.value,
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>명의담당</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.kname}
                              onChange={e => {
                                setMainData({ ...mainData, kname: e.target.value });
                              }}
                            />
                          </td>
                          <th>근무부서</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.dept}
                              onChange={e => {
                                setMainData({ ...mainData, dept: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>회원수</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.memberInwon}
                              onChange={e => {
                                if (regex_only_number.test(e.target.value)) {
                                  setMainData({ ...mainData, memberInwon: +e.target.value });
                                }
                              }}
                            />
                          </td>
                          <th>전화번호</th>
                          <td>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control table-input "
                                value={mainData.ctel}
                                onChange={e => {
                                  setMainData({ ...mainData, ctel: e.target.value });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>팩스번호</th>
                          <td>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control table-input "
                                value={mainData.cfax}
                                onChange={e => {
                                  setMainData({ ...mainData, cfax: e.target.value });
                                }}
                              />
                            </div>
                          </td>
                          <td colSpan={2}>&nbsp;</td>
                        </tr>
                        <tr>
                          <th>규모</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.size}
                              onChange={e => {
                                setMainData({ ...mainData, size: e.target.value });
                              }}
                            />
                          </td>
                          <th>정기휴장</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.closeDay}
                              onChange={e => {
                                setMainData({ ...mainData, closeDay: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>인터넷</th>
                          <td>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control table-input "
                                value={mainData.siteUrl}
                                onChange={e => {
                                  setMainData({
                                    ...mainData,
                                    siteUrl: e.target.value.replace(/[^a-zA-Z0-9./]/g, ""),
                                  });
                                }}
                              />
                              <a
                                href={
                                  mainData.siteUrl?.trim() !== ""
                                    ? `http://${mainData.siteUrl}`
                                    : void 0
                                }
                                target="_blank"
                              >
                                <img
                                  className="h-25px float-end"
                                  src={process.env.PUBLIC_URL + "/images/icon-arrow-btn.png"}
                                  alt=""
                                />
                              </a>
                            </div>
                          </td>
                          <th>비회원요금</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.priceNonmember}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceNonmember: e.target.value,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>연회비</th>
                          <th style={{ width: "10%" }}>개인</th>
                          <td style={{ width: "30%" }}>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceYear1
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceYear1: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                          <th style={{ width: "10%" }}>부부</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceYear2
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceYear2: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                          <th style={{ width: "10%" }}>법인</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceYear3
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceYear3: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>개서료</th>
                          <th>개인</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceGaesu1
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceGaesu1: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                          <th>부부</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceGaesu2
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceGaesu2: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                          <th>법인</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceGaesu3
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceGaesu3: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>취득과표</th>
                          <th>개인</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceChiduk1
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceChiduk1: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                          <th>부부</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceChiduk2
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceChiduk2: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                          <th>법인</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input text-end"
                              value={mainData.priceChiduk3
                                .toString()
                                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  priceChiduk3: Number(e.target.value.replace(/[^0-9]/g, "")),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>기타</th>
                          <td colSpan={6}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.gaesuWon}
                              onChange={e => {
                                setMainData({ ...mainData, gaesuWon: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>개서료 입금구좌</th>
                          <td style={{ width: "35%" }}>
                            <select
                              className="form-select w-29 float-start"
                              value={mainData.payBank}
                              onChange={e => {
                                setMainData({ ...mainData, payBank: e.target.value });
                              }}
                            >
                              <option value="" disabled={true} selected={true}>
                                계좌번호 선택
                              </option>
                              {getBank?.data?.map(el => {
                                return <option value={el.key}>{el.value}</option>;
                              })}
                            </select>
                            <input
                              type="text"
                              className="form-control table-input w-70 float-end"
                              value={mainData.payAccount}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  payAccount: e.target.value.replace(/[^0-9-]/g, ""),
                                });
                              }}
                            />
                          </td>
                          <th style={{ width: "15%" }}>예금주</th>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData.payName}
                              onChange={e => {
                                setMainData({ ...mainData, payName: e.target.value });
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered table-responsive my-table1 mb-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              name="checkbox-option"
                              checked={mainData.outCheck === "Y"}
                              onChange={e => {
                                if (mainData.outCheck === "N") {
                                  setMainData({
                                    ...mainData,
                                    outCheck: "Y",
                                    outDate: moment().format("YYYY-MM-DD"),
                                  });
                                } else {
                                  setMainData({
                                    ...mainData,
                                    outCheck: "N",
                                    outDate: prevOutDate,
                                  });
                                }
                              }}
                            />
                            제명여부
                          </th>
                          <th style={{ width: "10%" }}>제명일시</th>
                          <td>
                            <input
                              type="date"
                              className="form-control table-input"
                              value={moment(mainData.outDate).format("YYYY-MM-DD")}
                              readOnly={true}
                            />
                          </td>
                          <th style={{ width: "15%" }}>WEB-VIEW</th>
                          <td style={{ width: "35%" }}>
                            <select
                              className="form-select"
                              value={mainData.webViewChk}
                              onChange={e => {
                                setMainData({ ...mainData, webViewChk: e.target.value });
                              }}
                            >
                              <option value="Y">Y</option>
                              <option value="N">N</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {mainData.custId !== "0" && (
                <div className="col-right">
                  <div className="card card-sub m-0 h-553px">
                    <div className="card-body">
                      <div className="tabs-sidebar-wrapper w-100">
                        <Tabs
                          className="tab-blocks"
                          activeKey={selectedSubTab}
                          onSelect={k => setSelectedSubTab(k)}
                        >
                          <Tab eventKey="구분 및 자격(웹)" title="구분 및 자격(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                className="form-control"
                                value={subData.remark1}
                                style={{ height: "446px" }}
                                onChange={e => {
                                  setSubData({ ...subData, remark1: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary"
                                    onClick={() => btn_sub_save(subData.remark1, "A")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="주요시설(웹)" title="주요시설(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                style={{ height: "446px" }}
                                className="form-control"
                                value={subData.remark2}
                                onChange={e => {
                                  setSubData({ ...subData, remark2: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.remark2, "B")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="이용특전(웹)" title="이용특전(웹)">
                            <div className="tab-content-container pb-0">
                              <textarea
                                style={{ height: "446px" }}
                                className="form-control"
                                value={subData.remark3}
                                onChange={e => {
                                  setSubData({ ...subData, remark3: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.remark3, "C")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="비고" title="비고">
                            <div className="tab-content-container pb-0">
                              <textarea
                                style={{ height: "446px" }}
                                className="form-control"
                                value={subData.remark4}
                                onChange={e => {
                                  setSubData({ ...subData, remark4: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.remark4, "D")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="구비서류" title="구비서류">
                            <div className="tab-content-container pb-0">
                              <textarea
                                style={{ height: "446px" }}
                                className="form-control"
                                value={subData.tradeType}
                                onChange={e => {
                                  setSubData({ ...subData, tradeType: e.target.value });
                                }}
                              />
                              <div className="table-tabs-sub d-flex justify-content-end mt-10px mb-0">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={() => btn_sub_save(subData.tradeType, "E")}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn btn-outline-primary tab1-row1-btn"
                                    onClick={btn_sub_cancel}
                                  >
                                    취소
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
