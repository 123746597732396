import { useMutation } from "@tanstack/react-query";
import React, { useMemo, useRef, useState } from "react";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";
import { fileDownload } from "../../../../util/fileDownload/fileDownload";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useVirtualizer } from "@tanstack/react-virtual";

export default function DownloadToExcel() {
  const initialSearchOption = {
    duzonTrans: "N", // 더존
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    fromIlNo: "",
    gubun0: "0", // 법인 구분
    gubun1: "0",
    keyWord: "", // 성명, 업체명
    searchType: "1", // 검색타입
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    toIlNo: "",
  };
  const [searchOption, setSearchOption] = useState({
    duzonTrans: "N", // 더존
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    fromIlNo: "",
    gubun0: "0", // 법인 구분
    gubun1: "0",
    keyWord: "", // 성명, 업체명
    searchType: "1", // 검색타입
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    toIlNo: "",
  });

  const [taxData, setTaxData] = useState([]);
  const targetRef = useRef(null);

  // API - 발행내역 검색
  const searchHistory = useMutation(
    data =>
      axios.get("/api/emp/taxReIssue/searchHistory", { params: data }).then(res => {
        return res?.data?.result;
      }),
    {
      onSuccess: res => {
        // 기본조건 => 처음 렌더링 되자마자 checking
        const newData = res.map(data => {
          return { ...data, isCheck: true };
        });
        setTaxData(newData);
      },
    }
  );

  // API - 엑셀로 내리기
  const excelDownload = useMutation(
    data =>
      axios.post("/api/emp/taxReIssue/excel", data, {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        fileDownload(res.data, `세금_계산서_${moment().format("YY_MM_DD_hh_mm")}.xlsx`);
        alert("『더존이관』 상태로 설정되었습니다..!!");
        searchHistory.mutate(searchOption);
      },
    }
  );

  const pressEnter = e => {
    if (e.key === "Enter") {
      searchHistory.mutate(searchOption);
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: taxData?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const taDatas = useMemo(() => {
    return taxData.length > 0 ? (
      rowVirtualizer.getVirtualItems()?.map((virtualRow, _idx) => {
        return (
          <tr>
            <td style={{ width: "0.5%" }}>{_idx + 1}</td>
            <td style={{ width: "0.5%" }}>
              <input
                type="checkbox"
                checked={taxData[virtualRow.index]?.isCheck}
                onChange={() => {
                  const newData = [...taxData];
                  newData[_idx].isCheck = !taxData[virtualRow.index].isCheck;
                  setTaxData(newData);
                }}
              />
            </td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.ilNo}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.trNm}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.ceoNm}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.coCd}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.inDivCd}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.acctTy}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.acctFg}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.refDt}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.trCd}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.regNb}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.taxFg}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.clsgAm}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.clsvAm}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.clshAm}</td>
            <td style={{ width: "3%" }}>{taxData[virtualRow.index]?.cashAm}</td>
            <td style={{ width: "3%" }}>{taxData[virtualRow.index]?.bankAm}</td>
            <td style={{ width: "3%" }}>{taxData[virtualRow.index]?.misuAm}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.baNb}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.isuDoc}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.rmkDc}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.ctDept}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.pjtCd}</td>
            <td style={{ width: "3%" }}>{taxData[virtualRow.index]?.approKey}</td>
            <td style={{ width: "3%" }}>{taxData[virtualRow.index]?.pplNb}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.business}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.jongmok}</td>
            <td style={{ width: "4%" }}>
              <div
                className="overflow-scroll h-20px tax-reissue-download-address f-regular"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={taxData[virtualRow.index]?.divAddr1}
              >
                <div className="d-flex">
                  {taxData[virtualRow.index]?.divAddr1}
                  <Tooltip id="my-tooltip" />
                </div>
              </div>
            </td>
            <td style={{ width: "3%" }}>{taxData[virtualRow.index]?.tel}</td>
            <td style={{ width: "3%" }}>{taxData[virtualRow.index]?.fax}</td>
            <td style={{ width: "4%" }}>
              <div className="d-flex justify-content-around">
                <div className="d-flex me-2">
                  <input
                    type="checkbox"
                    className="me-1"
                    checked={taxData[virtualRow.index]?.jeonjaYn === "Y"}
                  />
                  <div>예</div>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    className="me-1"
                    checked={taxData[virtualRow.index]?.jeonjaYn === "N"}
                  />
                  <div>아니오</div>
                </div>
              </div>
            </td>
            <td style={{ width: "4%" }}>{taxData[virtualRow.index]?.email}</td>
            <td style={{ width: "2%" }}>{taxData[virtualRow.index]?.damdangja}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={34}>조회 결과가 없습니다.</td>
      </tr>
    );
  });

  return (
    <div className="content-inner">
      {/* {checkSearch.isLoading && <Spinner />} */}
      <div className="modal_float_title">검색</div>
      <div className="filter1_container">
        <div className="filter_fields_container">
          <div className="filter_fields_row" onKeyDown={pressEnter}>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e =>
                      setSearchOption({ ...initialSearchOption, searchType: e.target.value })
                    }
                    value={searchOption.searchType}
                  >
                    <option value="1">발행번호</option>
                    <option value="2">거래기간</option>
                    <option value="3">성명</option>
                    <option value="4">업체명</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">매매구분</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => setSearchOption({ ...searchOption, gubun1: e.target.value })}
                    value={searchOption.gubun1}
                  >
                    <option value="0">전체</option>
                    <option value="1">매출</option>
                    <option value="2">매입</option>
                  </select>
                </div>
                {/* 검색조건에 따라 달라집니다.
                발행번호 -> 발행번호 - 1
                거래기간 -> 발행기간 - 2
                성명 -> 성명 - 3
                업체명 -> 업체명 - 4
              */}
                {searchOption.searchType === "2" && (
                  <div className="filter1-field filter1-field2">
                    <label className="filter1-label">발행기간</label>
                    <input
                      type="date"
                      className="form-control filter-input filter1-input2"
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          fromDate: e.target.value,
                        })
                      }
                      max="9999-12-31"
                      value={searchOption.fromDate}
                    />
                    <span className="mx-3">~</span>
                    <input
                      type="date"
                      className="form-control filter-input filter1-input2"
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          toDate: e.target.value,
                        })
                      }
                      value={searchOption.toDate}
                      max="9999-12-31"
                    />
                  </div>
                )}
                {(searchOption.searchType === "3" || searchOption.searchType === "4") && (
                  <div className="filter1-field filter1-field2 ">
                    <label className="filter1-label ">
                      {searchOption.searchType === "3" ? <span>성명</span> : <span>업체명</span>}
                    </label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      onChange={e => setSearchOption({ ...searchOption, keyWord: e.target.value })}
                      value={searchOption.keyWord}
                    />
                  </div>
                )}
                {searchOption.searchType === "1" && (
                  <div className="filter1-field filter1-field2 ">
                    <label className="filter1-label ">발행번호</label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2 ms-5"
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          fromIlNo: e.target.value.replace(/[^0-9]/g, ""),
                        })
                      }
                      value={searchOption.fromIlNo}
                    />
                    <span className="mx-1">~</span>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      onChange={e =>
                        setSearchOption({
                          ...searchOption,
                          toIlNo: e.target.value.replace(/[^0-9]/g, ""),
                        })
                      }
                      value={searchOption.toIlNo}
                    />
                  </div>
                )}
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">법인구분</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => setSearchOption({ ...searchOption, gubun0: e.target.value })}
                    value={searchOption.gubun0}
                  >
                    <option value="0">전체</option>
                    <option value="1">개인사업자</option>
                    <option value="2">법인사업자</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">더존이관</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => setSearchOption({ ...searchOption, duzonTrans: e.target.value })}
                    value={searchOption.duzonTrans}
                  >
                    <option value="A">전체</option>
                    <option value="Y">전송완료</option>
                    <option value="N">미전송</option>
                    <option value="D">전송안함</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="filter1-field filter1-field2">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={() => {
                  const newData = taxData.filter(item => {
                    if (item.isCheck) {
                      return item;
                    }
                  });
                  if (newData.length > 0) {
                    excelDownload.mutate(newData);
                  } else {
                    alert("데이터를 선택해주세요.");
                  }
                }}
              >
                엑셀파일로 만들기
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={() => {
                  searchHistory.mutate(searchOption);
                }}
              >
                조회
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 검색 결과 */}
      {/* {searchHistory.data && ( */}
      <>
        <div className="d-flex justify-content-end"></div>
        <div className="overflow-auto table-container table-sticky h-528">
          <table
            className="table table-bordered table-responsive my-table mt-0"
            ref={targetRef}
            style={{
              width: "4000px",
              marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              marginBottom: `${
                rowVirtualizer.getTotalSize() -
                (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                rowVirtualizer.getVirtualItems().length * 30
              }px`,
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "0.5%" }}>No.</th>
                <th style={{ width: "0.5%" }}>
                  <input
                    type="checkbox"
                    checked={taxData.every(el => el.isCheck === true)}
                    onChange={e => {
                      setTaxData(
                        Array.from({ length: taxData.length }, (data, _idx) => {
                          return {
                            ...taxData[_idx],
                            isCheck: e.target.checked,
                          };
                        })
                      );
                    }}
                  />
                </th>
                <th>발행번호</th>
                <th style={{ width: "180px", minWidth: "180px", maxWidth: "180px" }}>거래처명</th>
                <th>대표자명</th>
                <th>회사코드</th>
                <th>사업장코드</th>
                <th>자료구분</th>
                <th>전표유형</th>
                <th>발행일자</th>
                <th>거래처코드</th>
                <th>사업자번호</th>
                <th>과세구분</th>
                <th>공급가액</th>
                <th>부가세</th>
                <th>합계액</th>
                <th>현금 수금/지급</th>
                <th>은행 수금/지급</th>
                <th>외상/미지급금</th>
                <th>계좌번호</th>
                <th style={{ width: "165px", minWidth: "165px", maxWidth: "165px" }}>품의내역</th>
                <th style={{ width: "165px", minWidth: "165px", maxWidth: "165px" }}>적요내역</th>
                <th>사용부서</th>
                <th>프로젝트</th>
                <th>외부연동번호</th>
                <th>주민등록번호</th>
                <th>업태</th>
                <th style={{ width: "150px", minWidth: "150px", maxWidth: "150px" }}>종목명</th>
                <th>주소</th>
                <th>전화번호</th>
                <th>팩스번호</th>
                <th>전자세금계산서여부</th>
                <th>Email</th>
                <th>담당자</th>
              </tr>
            </thead>
            <tbody>{taDatas}</tbody>
          </table>
        </div>
      </>
      {/* )} */}
    </div>
  );
}
