import { atom } from "jotai";
import moment from "moment";

export const registeredDeliveryHistory_searchData_init = {
  allDb: false,
  condition: "A",
  jisa: "11",
  gubun: "A",
  fromDate: "2022-01-01",
  toDate: "2023-01-30",
  // fromDate: "",
  // toDate: "",
  keyword: "",
  page: 1,
};

export const registeredDeliveryHistory_searchData_atom = atom(
  registeredDeliveryHistory_searchData_init
);
export const registeredDeliveryHistory_searchedData_atom = atom({});

export const registeredDeliveryHistory_searchedTableList_atom = atom([]);
