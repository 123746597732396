import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAtom, useSetAtom } from "jotai/index";
import {
  currentRow_atom,
  defaultCondition_atom,
  defaultConditionInit,
  finalTax_atom,
  finalTaxInit,
  medoja_atom,
  medojaInit,
  mesuja_atom,
  ocrPrintParam_atom,
  payback_atom,
  paybackInit,
  result_atom,
  resultInit,
  searchedParam_atom,
  searchParam_atom,
  searchParamInit,
  tableRowLimit_atom,
  transferTaxList_atom,
  ydDivide_atom,
  ydDivideInit,
  ydInput_atom,
  ydInputInit,
  ydPrintParam_atom,
} from "../../../data/atom/workManage/transferTax/transferTax_atom";
import DefaultCondition from "./section/defaultCondition";
import InputFilter from "./component/InputFilter";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useGetJisaGubun } from "../../../api/code/useGetJisaGubun";
import moment from "moment/moment";
import useInfiniteScrollWrapper from "../../../util/infiniteScroll/useInfiniteScrollWrapper";
import { useVirtualizer } from "@tanstack/react-virtual";
import TransferTaxListRow from "./component/transferTaxListRow";
import Medo from "./section/medo";
import Mesu from "./section/mesu";
import Payback from "./section/payback";
import YdDivide from "./section/ydDivide";
import Print from "./section/print";
import FinalTax from "./section/finalTax";
import YdInput from "./section/ydInput";
import Result from "./section/result";
import ModalSeyul from "./modal/ModalSeyul";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { useGetSportMemberadmin } from "../../../api/code/useGetSportMemberadmin";
import { useGetCondoadmin } from "../../../api/code/useGetCondoadmin";
import { useGetGolfList3 } from "../../../api/code/useGetGolfList3";
import { decrypt } from "../../../util/decrypt/decrypt";

export default function TransferTax() {
  const tableRef = useRef();
  const [tableRowLimit, setTableRowLimit] = useAtom(tableRowLimit_atom);
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [transferTaxList, setTransferTaxList] = useAtom(transferTaxList_atom);
  const setCurrentRow = useSetAtom(currentRow_atom);
  const setSearchedParam = useSetAtom(searchedParam_atom);
  const setDefaultCondition = useSetAtom(defaultCondition_atom);
  const setMedoja = useSetAtom(medoja_atom);
  const setMesuja = useSetAtom(mesuja_atom);
  const setPayback = useSetAtom(payback_atom);
  const setYdDivide = useSetAtom(ydDivide_atom);
  const setFinalTax = useSetAtom(finalTax_atom);
  const setYdInput = useSetAtom(ydInput_atom);
  const setResult = useSetAtom(result_atom);
  const setYdPrintParam = useSetAtom(ydPrintParam_atom);
  const setOcrPrintParam = useSetAtom(ocrPrintParam_atom);
  const setOcrNapbuPrintParam = useSetAtom(ocrPrintParam_atom);

  const [modalShow, setModalShow] = useState(false);
  const { addTabClosedEvent } = useRecentTabEvent();

  const getJisaGubun = useGetJisaGubun();
  const getGolfList = useGetGolfList3();
  const getCondoadmin = useGetCondoadmin();
  const getSportMemberadmin = useGetSportMemberadmin();

  const searchTransferTax = async data => {
    let res = await axios.get("/api/work/transferTax/search", { params: data });

    setSearchedParam(searchParam);
    return [...res.data.result];
  };
  const searchMedojaInfo = useMutation(
    data => axios.get("/api/work/transferTax/getMedojaInfo", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        searchGetSemusu.mutate({ ...data, bdCode: data.rbdcode, zipcode: data.zip | "" });
      },
      onError: res => {
        setMedoja({ ...medojaInit });
      },
    }
  );
  const searchMesujaInfo = useMutation(
    data => axios.get("/api/work/transferTax/getMesujaInfo", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setMesuja(data);
      },
      onError: (err, variables) => {
        const error = err.response.data;
        setMesuja([]);

        if (error.code === "-5") {
          if (confirm(error.message)) {
            searchMesujaInfo.mutate({ ...variables, again: true });
          }
        }
      },
    }
  );
  const searchGetSemusu = useMutation(
    data => axios.get("/api/work/transferTax/getSemusu", { params: data }),
    {
      onSuccess: (res, variables) => {
        const data = res.data.result;
        setMedoja({
          ...variables,
          semusu: data?.[0]?.custId || "",
          semusuYn: Boolean(data?.[0]?.custId),
          semusuName: data?.[0]?.company || "",
        });
      },
      onError: (res, variables) => {
        setMedoja({ ...variables, semusu: "", semusuYn: false });
      },
    }
  );

  const saveTransferTax = useMutation(data => axios.post("/api/work/transferTax/save", data), {
    onSuccess: res => {
      refetchFromFirstPage();
    },
  });

  const {
    endRef: scrollRef,
    refetchFromFirstPage,
    afterFetch,
  } = useInfiniteScrollWrapper({
    fetch: searchTransferTax,
    fetchKey: ["transactionSearch"],
    searchParam: {
      ...searchParam,
      jisa: decrypt("userInfo").userPermit !== "99" ? decrypt("userInfo").jisa : searchParam.jisa,
    },
    resultSetter: setTransferTaxList,
  });

  const rowVirtualizer = useVirtualizer({
    count: transferTaxList?.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => 30,
  });

  const rowClick = useCallback(
    el => {
      setDefaultCondition(defaultConditionInit);
      setPayback(paybackInit);
      setYdDivide(ydDivideInit);
      setFinalTax(finalTaxInit);
      setYdInput([
        {
          tradeGubun: el.saleGu,
          objectName:
            el.clazz === "GM"
              ? getGolfList.data?.find(el2 => el2.code === el.roomNo)?.code
              : el.clazz === "CM"
                ? getCondoadmin.data?.find(el2 => el2.key === el.roomNo)?.key
                : el.clazz === "SM"
                  ? getSportMemberadmin.data?.find(el2 => el2.key === el.roomNo)?.key
                  : "",
          transferDate: moment(el.custDate).format("YYYY-MM-DD"),
          transferAmnt: el.yangdoWon2 * 10000,
          acquireDate: "",
          acquireAmnt: 0,
          expenseAmnt: 0,
          expenseItem: "",
        },
        ydInputInit,
        ydInputInit,
        ydInputInit,
        ydInputInit,
      ]);
      setResult(resultInit);
      setYdPrintParam({});
      setOcrPrintParam({});
      setOcrNapbuPrintParam({});
      setCurrentRow(el);

      searchMedojaInfo.mutate({
        custDate: moment(el.custDate).format("YYYY-MM-DD"),
        custId: el.custId,
        ilNo: el.ilNo,
        again: false,
      });

      if (el.jisaGerae === "Y") {
        alert("지사간 거래자입니다. 양수자를 정확히 확인 바랍니다.");
        setMesuja([]);
      } else {
        searchMesujaInfo.mutate({
          custDate: moment(el.custDate).format("YYYY-MM-DD"),
          custId: el.custId,
          ilNo: el.ilNo,
          again: false,
        });
      }
    },
    [transferTaxList]
  );
  const btnSearch = () => {
    refetchFromFirstPage();
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (transferTaxList.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else {
        const list = transferTaxList.map(el => {
          return {
            ...el,
            custDate: moment(el.custDate).format("YYYY-MM-DD"),
            sinDate: moment(el.sinDate).format("YYYY-MM-DD"),
          };
        });
        saveTransferTax.mutate(list);
      }
    }
  };
  const btnSeyul = () => {
    setModalShow(true);
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    if (searchParam === searchParamInit) {
      let userInfo = decrypt("userInfo");
      let userPermit = userInfo.userPermit;
      if (userPermit !== "99") {
        setSearchParam({ ...searchParam, jisa: userInfo.jisa });
      }
    }

    addTabClosedEvent(location.pathname, () => {
      setCurrentRow({});
      setSearchParam(searchParamInit);
      setSearchedParam({});
      setTableRowLimit("5");
      setTransferTaxList([]);
      setDefaultCondition(defaultConditionInit);
      setMedoja(medojaInit);
      setMesuja([]);
      setPayback(paybackInit);
      setYdDivide(ydDivideInit);
      setFinalTax(finalTaxInit);
      setYdInput([ydInputInit, ydInputInit, ydInputInit, ydInputInit, ydInputInit]);
      setResult(resultInit);
      setYdPrintParam({});
      setOcrPrintParam({});
      setOcrNapbuPrintParam({});
    });
  }, []);

  return (
    <div className="work-page">
      <ModalSeyul show={modalShow} setShow={setModalShow} />
      <div>
        <div className="work-tax-header">
          <div className="work-page-title">양도세 계산 및 고지서 출력</div>
          <div className="work-tax-btn-container">
            <div className="work-tax-btn" onClick={btnSeyul}>
              세율관리
            </div>
          </div>
        </div>
      </div>
      <div className="filter_fields_container">
        <div className="filter_fields_row d-flex justify-content-between" onKeyDown={pressEnter}>
          <div className="d-flex">
            <div className="filter1-field filter1-field1">
              <label className="filter1-label" style={{ minWidth: "fit-content" }}>
                지사구분
              </label>
              <InputFilter
                type="select"
                size="l"
                keyName={"jisa"}
                data={searchParam}
                setData={setSearchParam}
                disabled={
                  !(
                    decrypt("userInfo").userPermit === "99" ||
                    decrypt("userInfo").userPermit === "55" ||
                    decrypt("userInfo").userPermit === "81" ||
                    decrypt("userInfo").userPermit === "88"
                  )
                }
              >
                {getJisaGubun.data?.map(el => {
                  return <option value={el.key}>{el.value}</option>;
                })}
              </InputFilter>
            </div>
            <div className="filter1-field filter1-field1">
              <label className="filter1-label" style={{ minWidth: "fit-content" }}>
                구분
              </label>
              <div className="d-flex" style={{ width: "150px" }}>
                <InputFilter
                  type="select"
                  size="m"
                  keyName={"gubun1"}
                  data={searchParam}
                  setData={setSearchParam}
                >
                  <option value="9">전체</option>
                  <option value="1">골프</option>
                  <option value="2">콘도</option>
                  <option value="3">헬스</option>
                </InputFilter>
                <span>&nbsp;</span>
                <InputFilter
                  type="select"
                  size="m"
                  keyName={"gubun2"}
                  data={searchParam}
                  setData={setSearchParam}
                >
                  <option value="9">전체</option>
                  <option value="1">양도</option>
                  <option value="2">양수</option>
                </InputFilter>
              </div>
            </div>
            <div className="filter1-field filter1-field1">
              <label className="filter1-label" style={{ minWidth: "fit-content" }}>
                세무신고
              </label>
              <InputFilter
                type="select"
                size="l"
                keyName={"taxGubun"}
                data={searchParam}
                setData={setSearchParam}
              >
                <option value="A">전체</option>
                <option value="Y">대상</option>
                <option value="N">비대상</option>
                <option value="R">관청접수</option>
                <option value="S">완료</option>
              </InputFilter>
            </div>
            <div className="filter1-field filter1-field1">
              <label className="filter1-label" style={{ minWidth: "fit-content" }}>
                거래 기간
              </label>
              <InputFilter
                type="date"
                keyName={"fromDate"}
                data={searchParam}
                setData={setSearchParam}
              />
              <span>&nbsp;~&nbsp;</span>
              <InputFilter
                type="date"
                keyName={"toDate"}
                data={searchParam}
                setData={setSearchParam}
              />
            </div>
            <div className="filter1-field filter1-field1" style={{ marginRight: "100px" }}>
              <label className="filter1-label" style={{ minWidth: "fit-content" }}>
                성명
              </label>
              <InputFilter
                type="text"
                keyName={"kname"}
                data={searchParam}
                setData={setSearchParam}
              />
            </div>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-outline-primary btn-filter-outline-primary"
              onClick={btnSearch}
            >
              조회
            </button>
            <button
              className="btn btn-outline-primary btn-filter-outline-primary me-20px"
              onClick={btnSave}
            >
              저장
            </button>
          </div>
        </div>
      </div>

      <div className="card card-sub">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title  m-0">양도신고대상</h5>
          <select
            className="form-select table-filter-select"
            value={tableRowLimit}
            onChange={e => setTableRowLimit(e.target.value)}
          >
            <option value="5">5개씩 보기</option>
            <option value="10">10개씩 보기</option>
            <option value="20">20개씩 보기</option>
          </select>
        </div>
        <div className="card-body">
          <div
            className={`overflow-auto table-container table-sticky
                ${tableRowLimit === "5" && "h-183"}
                ${tableRowLimit === "10" && "h-333"}
                ${tableRowLimit === "20" && "h-633"}`}
            ref={tableRef}
          >
            <table
              className="table table-bordered table-responsive my-table"
              style={{
                marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                marginBottom: `${
                  rowVirtualizer.getTotalSize() -
                  (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                  rowVirtualizer.getVirtualItems().length * 30
                }px`,
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "3%" }}>연번</th>
                  <th style={{ width: "4%" }}>신고완료</th>
                  <th style={{ width: "4%" }}>거래구분</th>
                  <th style={{ width: "3%" }}>구분</th>
                  <th style={{ width: "5.5%" }}>거래일자</th>
                  <th style={{ width: "4%" }}>거래분류</th>
                  <th style={{ width: "5.5%" }}>관리번호</th>
                  <th style={{ width: "4%" }}>성명</th>
                  <th>거래종목</th>
                  <th style={{ width: "3.5%" }}>구분</th>
                  <th style={{ width: "5%" }}>금액</th>
                  <th style={{ width: "6%" }}>거래번호</th>
                  <th style={{ width: "6%" }}>세무신고</th>
                  <th style={{ width: "6%" }}>진행상태</th>
                  <th style={{ width: "5.5%" }}>신고일자</th>
                  <th style={{ width: "4%" }}>거래딜러</th>
                  <th style={{ width: "4%" }}>소속지사</th>
                  <th style={{ width: "5%" }}>명,주소상이</th>
                  <th style={{ width: "5.5%" }}>세금</th>
                </tr>
              </thead>
              <tbody>
                {transferTaxList?.length !== 0 ? (
                  rowVirtualizer.getVirtualItems().map(el => {
                    if (el.index !== transferTaxList.length - 1) {
                      return (
                        <TransferTaxListRow
                          el={transferTaxList[el.index]}
                          idx={el.index}
                          rowClick={rowClick}
                        />
                      );
                    } else {
                      return (
                        <>
                          <div className="m-0 p-0" ref={scrollRef} />
                          <TransferTaxListRow
                            el={transferTaxList[el.index]}
                            idx={el.index}
                            rowClick={rowClick}
                          />
                        </>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={19}>검색된 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <DefaultCondition />

      <div className="d-flex" style={{ gap: "14px" }}>
        <div className="w-50">
          <div className="d-flex" style={{ gap: "14px" }}>
            <Medo />
            <Mesu />
          </div>
          <div className="d-flex" style={{ gap: "14px" }}>
            <Payback />
            <YdDivide />
          </div>
          <div className="d-flex" style={{ gap: "14px" }}>
            <Print refetchFromFirstPage={refetchFromFirstPage} />
            <FinalTax />
          </div>
        </div>
        <div className="w-50">
          <div>
            <YdInput />
          </div>
          <div>
            <Result />
          </div>
        </div>
      </div>
    </div>
  );
}
