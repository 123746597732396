import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import moment from "moment/moment";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import { useGetPostGubun } from "../../../../api/code/useGetPostGubun";
export const RegisteredDelivery = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  const getPostGubun = useGetPostGubun();

  // 등기발송 조회
  const customerGetMailHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getMailHistory", {
        params: data,
      }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  useEffect(() => {
    mainData.custId && customerGetMailHistory.mutate({ seqNo: mainData.seqNo });
  }, [mainData.custId]);

  const btn_rowClick = data => {
    window.open(
      `http://www.dagolf.co.kr/pop/dong.html?sid=${data.urlSid}`,
      "배송조회",
      "popup=yes"
    );
  };

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">
          등기발송 (<span>{subData.length ?? "0"}</span>건)
        </div>
      </div>
      <div className="table-container table-sticky h-528 mt-15px">
        <table className="table table-bordered table-responsive my-table mt-0">
          <colgroup>
            <col width="5%" />
            <col width="8%" />
            <col width="8%" />
            <col width="30%" />
            <col width="8%" />
            <col width="30%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>발송일자</th>
              <th>수신자</th>
              <th>내용</th>
              <th>진행상황</th>
              <th>발송처</th>
              <th>발송방법</th>
            </tr>
          </thead>
          <tbody>
            {subData?.length !== 0 ? (
              subData?.map((el, index) => {
                return (
                  <tr className="cursor-pointer" onClick={() => btn_rowClick(el)}>
                    <td>{subData.length - index}</td>
                    <td>{el?.sendDate ? moment(el?.sendDate).format("YYYY-MM-DD") : ""}</td>
                    <td>
                      {el?.kname ?? ""}
                      {el?.position ?? ""}
                    </td>
                    <td>{el?.remark ?? ""}</td>
                    <td>
                      {el?.processChk === "E" ? "완료" : el?.processChk === "R" ? "접수" : "우체국"}
                    </td>
                    <td>{el?.juso}</td>
                    <td>{getPostGubun.data?.find(el2 => el2.key === el.sendGubun)?.value}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  검색된 등기발송 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
