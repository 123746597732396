import { atom } from "jotai";
export const changeByPersonIncharge_row_atom = atom("5");

export const changeByPersonIncharge_transaction_atom = atom([]);
export const changeByPersonIncharge_manager_atom = atom ("");
export const changeByPersonIncharge_managerList_atom = atom ([]);
export const changeByPersonIncharge_searchManagerList_atom = atom([]);
export const changeByPersonIncharge_divisionList_atom = atom({});
export const changeByPersonIncharge_divisionListArray_atom = atom([]);

//고객별 내역조회
export const changeByPersonIncharge_callHistory_atom = atom([]);
export const changeByPersonIncharge_orderHistory_atom = atom([]);
export const changeByPersonIncharge_memberShipHistory_atom = atom([]);
export const changeByPersonIncharge_totalCnt_atom = atom(0);
export const changeByPersonIncharge_searchListNumber_atom = atom([]);
export const changeByPersonIncharge_numberChange_atom = atom("");
export const changeByPersonIncharge_fromNumber_atom = atom();
export const changeByPersonIncharge_toNumber_atom = atom();
export const changeByPersonIncharge_indivisionResult_atom = atom([]);