import { atom } from "jotai";

export const postManage_searchDataInit_atom = {
  jisaGubun: "11",
  keyword: "",
  isReSearch: false,
};

export const postManage_searchData_atom = atom(postManage_searchDataInit_atom);

export const postManage_searchedTableList_atom = atom([]);

export const postManage_modifyList_atom = atom([]);

export const postManage_checkboxList_atom = atom([]);
