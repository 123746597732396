import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { axios } from "../../../../util/axios/axios";
import { useGetBeneType } from "../../../../api/code/useGetBeneType";
import { useAtom } from "jotai";
import {
  depositWithDrawTable_searchData,
  depositWithDrawTable_searchOption,
  depositWithDrawTable_tab,
  depositWithDrawTable_year,
} from "../../../../data/atom/personManage/prematureManage/depositWithDrawTable/depositWithDrawTable_atom";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import moment from "moment";

const DepositWithdrawalStats = () => {
  const [tblOption, setTblOption] = useAtom(depositWithDrawTable_tab);
  const [searchOption, setSearchOption] = useAtom(depositWithDrawTable_searchOption);
  const [searchData, setSearchData] = useAtom(depositWithDrawTable_searchData);
  const [yearOption, setYearOption] = useAtom(depositWithDrawTable_year);
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchOption({
        gubun: "11",
        yearInfo: moment(new Date()).format("YYYY"),
      });
      setTblOption("1");
      setSearchData([]);
      setYearOption("");
    });
  }, []);

  // API - 입출금 통계표 조회
  const search = useMutation(
    data =>
      axios.get("/api/emp/feeList/search", {
        params: searchOption,
      }),
    {
      onSuccess: res => {
        setSearchData(res?.data?.result);
        setYearOption(searchOption.yearInfo);
        if (searchOption.gubun === "11") {
          // 입금
          setTblOption("2");
        } else if (searchOption.gubun === "22") {
          // 출금
          setTblOption("1");
        }
      },
    }
  );

  const pressEnter = e => {
    if (e.key === "Enter") {
      const currDate = new Date();
      const year = currDate.getFullYear();

      if (1800 <= searchOption.yearInfo && searchOption.yearInfo <= year) {
        search.mutate();
      } else {
        alert("유효하지 않은 연도입니다.");
      }
    }
  };


  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">입출금 통계표</div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">년도</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    maxLength={4}
                    value={searchOption.yearInfo}
                    onChange={e => {
                      const inputValue = Number(
                        e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                      );
                      setSearchOption({
                        ...searchOption,
                        yearInfo: inputValue,
                      });
                    }}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">구분</label>
                  <select
                    className="form-select filter1-select1"
                    onChange={e => {
                      // setTblOption(e.target.value);
                      setSearchOption({ ...searchOption, gubun: e.target.value });
                    }}
                    value={searchOption.gubun}
                  >
                    <option value="11">입금</option>
                    <option value="22">출금</option>
                  </select>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  const currDate = new Date();
                  const year = currDate.getFullYear();

                  if (1800 <= searchOption.yearInfo && searchOption.yearInfo <= year) {
                    search.mutate();
                  } else {
                    alert("유효하지 않은 연도입니다.");
                  }
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        {searchData?.resultList?.length > 0 && (
          <div className="card card-main">
            <div className="card-body">
              {tblOption == "1" && (
                <h1 className="h1_tbl_depositwithdraw mt-1">{yearOption}년 계정별 출금 통계표</h1>
              )}
              {tblOption == "2" && (
                <h1 className="h1_tbl_depositwithdraw mt-1">{yearOption}년 계정별 입금 통계표</h1>
              )}

              <table className="table table-bordered table-responsive my-table mt-3 mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "7.142%" }}>계정</th>
                    <th style={{ width: "7.142%" }}>1월</th>
                    <th style={{ width: "7.142%" }}>2월</th>
                    <th style={{ width: "7.142%" }}>3월</th>
                    <th style={{ width: "7.142%" }}>4월</th>
                    <th style={{ width: "7.142%" }}>5월</th>
                    <th style={{ width: "7.142%" }}>6월</th>
                    <th style={{ width: "7.142%" }}>7월</th>
                    <th style={{ width: "7.142%" }}>8월</th>
                    <th style={{ width: "7.142%" }}>9월</th>
                    <th style={{ width: "7.142%" }}>10월</th>
                    <th style={{ width: "7.142%" }}>11월</th>
                    <th style={{ width: "7.142%" }}>12월</th>
                    <th style={{ width: "7.142%" }}>합계</th>
                  </tr>
                </thead>
                <tbody>
                  {searchData?.resultList?.length > 0 ? (
                    <>
                      {searchData?.resultList?.map(data => {
                        return (
                          <tr>
                            <td className="text-center">
                              {data?.beneName
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth01
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth02
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth03
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth04
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth05
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth06
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth07
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth08
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth09
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth10
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth11
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {data?.yearMnth12
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-end">
                              {(
                                data?.yearMnth01 +
                                data?.yearMnth02 +
                                data?.yearMnth03 +
                                data?.yearMnth04 +
                                data?.yearMnth05 +
                                data?.yearMnth06 +
                                data?.yearMnth07 +
                                data?.yearMnth08 +
                                data?.yearMnth09 +
                                data?.yearMnth10 +
                                data?.yearMnth11 +
                                data?.yearMnth12
                              )
                                ?.toString()
                                ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        );
                      })}
                      {
                        <tr>
                          <td>합계</td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth01
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth02
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth03
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth04
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth05
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth06
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth07
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth08
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth09
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth10
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth11
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {searchData?.totalMonthResVO?.totalYearMnth12
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-end">
                            {(
                              searchData?.totalMonthResVO?.totalYearMnth01 +
                              searchData?.totalMonthResVO?.totalYearMnth02 +
                              searchData?.totalMonthResVO?.totalYearMnth03 +
                              searchData?.totalMonthResVO?.totalYearMnth04 +
                              searchData?.totalMonthResVO?.totalYearMnth05 +
                              searchData?.totalMonthResVO?.totalYearMnth06 +
                              searchData?.totalMonthResVO?.totalYearMnth07 +
                              searchData?.totalMonthResVO?.totalYearMnth08 +
                              searchData?.totalMonthResVO?.totalYearMnth09 +
                              searchData?.totalMonthResVO?.totalYearMnth10 +
                              searchData?.totalMonthResVO?.totalYearMnth11 +
                              searchData?.totalMonthResVO?.totalYearMnth12
                            )
                              ?.toString()
                              ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      }
                    </>
                  ) : (
                    <tr>
                      <td colSpan={14}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default DepositWithdrawalStats;
