import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import { transactionMain_atom } from "../../../../data/atom/dataManage/transactionProgress/transactionProgress_atom";

const ModalTextPreset = props => {
  const { show, setShow } = props;
  const [transactionMain, setTransactionMain] = useAtom(transactionMain_atom);
  const [textPresetList, setTextPresetList] = useState([]);
  const isAdd = textPresetList.filter(el => el.newCheck).length !== 0;

  const searchTextPresetList = useMutation(
    data => axios.get("/api/data/transactionManage/bottom/getLeftDetail", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setTextPresetList(data);
      },
    }
  );
  const saveTextPreset = useMutation(
    data => axios.post("/api/data/transactionManage/bottom/saveLeftDetail", data),
    {
      onSuccess: res => {
        searchTextPresetList.mutate();
      },
    }
  );
  const deleteTextPreset = useMutation(
    data =>
      axios.post("/api/data/transactionManage/bottom/deleteLeftDetail", data, {
        headers: { "Content-type": "text/plain;charset=UTF-8" },
      }),
    {
      onSuccess: res => {
        searchTextPresetList.mutate();
      },
    }
  );

  useEffect(() => {
    if (show) {
      searchTextPresetList.mutate();
    }
  }, [show]);

  const onChangeEventHandler = (key, value, idx) => {
    const list = textPresetList.map((el, idx2) => {
      if (idx === idx2) {
        return { ...el, [key]: value };
      } else {
        return el;
      }
    });

    setTextPresetList(list);
  };

  const modalHide = () => {
    setShow(false);
    setTextPresetList([]);
  };
  const btnAdd = () => {
    setTextPresetList([{ code: "", codename: "", remark: "", newCheck: true }, ...textPresetList]);
  };
  const btnSavePreset = () => {
    if (confirm("저장하시겠습니까?")) {
      if (textPresetList.filter(el => el.newCheck && el.code.trim() === "").length !== 0) {
        alert("코드를 입력해주세요.");
      } else if (textPresetList.filter(el => el.codename.trim() === "").length !== 0) {
        alert("관련서류를 입력해주세요.");
      } else {
        saveTextPreset.mutate(textPresetList);
      }
    }
  };
  const btnSaveTextToList = () => {
    if (textPresetList.filter(el => el.isCheck).length === 0) {
      alert("등록할 필요문구를 선택해주세요.");
    } else {
      let totalTxt = "";
      textPresetList.map(el => {
        if (el.isCheck) {
          totalTxt += `${el.codename} ${el.remark}\n`;
        }
      });
      setTransactionMain({
        ...transactionMain,
        submitDoc: `${totalTxt}\n${transactionMain.submitDoc}`,
      });
      modalHide();
    }
  };
  const btnDelete = (deleteEl, deleteIdx) => {
    if (deleteEl.newCheck) {
      const list = [...textPresetList].filter((_, idx) => idx !== deleteIdx);
      setTextPresetList(list);
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteTextPreset.mutate(deleteEl.code);
      }
    }
  };

  return (
    <>
      <Modal show={show} size={"lg"} centered onHide={modalHide}>
        <Modal.Header closeButton>
          <Modal.Title>필요문구</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form">
            <div className="flex-center-end pb-2">
              <button className="btn btn-outline-primary" onClick={btnAdd} disabled={isAdd}>
                추가
              </button>
              <button className="btn btn-outline-primary ms-2" onClick={btnSavePreset}>
                저장
              </button>
            </div>
            <table className="table table-bordered table-responsive my-table mt-0">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>연번</th>
                  <th style={{ width: "5%" }}>선택</th>
                  <th style={{ width: "10%" }}>코드</th>
                  <th style={{ width: "25%" }}>관련서류</th>
                  <th style={{ width: "10%" }}>부수</th>
                  <th style={{ width: "5%" }}>삭제</th>
                </tr>
              </thead>
              <tbody>
                {textPresetList?.length !== 0 ? (
                  textPresetList?.map((el, idx) => {
                    return (
                      <tr>
                        <td>{isAdd ? (idx > 0 ? idx : "") : idx + 1}</td>
                        <td>
                          <div className="flex-center-center">
                            <input
                              type="checkbox"
                              checked={el.isCheck}
                              onChange={e => onChangeEventHandler("isCheck", e.target.checked, idx)}
                              disabled={el.newCheck}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="flex-center-between">
                            <input
                              type="text"
                              className="form-control table-input text-center"
                              value={el.code}
                              onChange={e => onChangeEventHandler("code", e.target.value, idx)}
                              disabled={!el.newCheck}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="flex-center-between">
                            <input
                              type="text"
                              className="form-control table-input"
                              value={el.codename}
                              onChange={e => onChangeEventHandler("codename", e.target.value, idx)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="flex-center-between">
                            <input
                              type="text"
                              className="form-control table-input"
                              value={el.remark}
                              onChange={e => onChangeEventHandler("remark", e.target.value, idx)}
                            />
                          </div>
                        </td>
                        <td>
                          <a onClick={() => btnDelete(el, idx)}>
                            <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
                          </a>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>검색된 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="flex-center-end pt-3">
              <button className="btn btn-primary" onClick={btnSaveTextToList}>
                등록
              </button>
              <button className="btn btn-outline-primary ms-2" onClick={modalHide}>
                닫기
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(ModalTextPreset);
