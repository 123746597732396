import React, { useEffect, useState } from "react";
import { Header } from "./header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RecentTap } from "./recentTap";
import { SidebarMain } from "./sidebar/sidebarMain";
import { Spinner } from "../spinner/spinner";
import { isLoadingSpinner_atom } from "../../data/atom/isLoadingSpinner_atom";
import { useAtomValue } from "jotai";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { axios as Axios } from "../../util/axios/axios";
import { decrypt } from "../../util/decrypt/decrypt";

const flattenURLs = data => {
  let urls = [];
  const extractURLs = nodes => {
    nodes.forEach(node => {
      if (node.url) urls.push(node.url);
      if (node.sub && node.sub.length > 0) extractURLs(node.sub);
    });
  };
  extractURLs(data);
  return urls;
};

const checkAndNavigate = (data, currentPath, navigate, defaultURL) => {
  const allURLs = flattenURLs(data);
  const processedPath = currentPath.replace(process.env.PUBLIC_URL, "");

  if (processedPath.startsWith("/initial")) return;
  if (!allURLs.includes(processedPath)) {
    alert("페이지 접근 권한이 없습니다.");
    navigate(defaultURL + "/initial");
  }
};

export const Layout = () => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const isLoading = useAtomValue(isLoadingSpinner_atom);

  const [isSidebarFolding, setIsSidebarFolding] = useState(true);

  const loginUrl = useQuery(
    ["authLoginUrl"],
    () => Axios.get("/api/auth/loginUrl").then(res => res.data),
    {}
  );

  useEffect(() => {
    if (!sessionStorage.getItem("userInfo")) {
      alert("로그인 후 이용");
      navigate(defaultURL + "/");
    }
  }, []);

  useEffect(() => {
    if (loginUrl.data) {
      checkAndNavigate(loginUrl.data, location.pathname, navigate, defaultURL);
    }
  }, [loginUrl.data, location]);

  // 캡쳐방지 프로그램
  const { error: captureError } = useQuery([], () => axios.get("http://localhost:5500"), {
    retry: 0,
    retryDelay: attemptIndex => decrypt("userInfo").captureRatio * 1000,
    refetchInterval: decrypt("userInfo").captureRatio * 1000,
    refetchIntervalInBackground: true,
    enabled: decrypt("userInfo").capturePermit === "N",
  });

  if (captureError) {
    navigate(defaultURL + "/");
    sessionStorage.clear();

    setTimeout(() => {
      alert("캡쳐방지 프로그램를 실행해주세요.");
      window.location.reload();
    }, 500);
  }

  return (
    <>
      {isLoading.length > 0 && <Spinner />}
      <div className="page-wrapper" onCopy={e => e.preventDefault()}>
        <Header data={loginUrl.data} setIsSidebarFolding={setIsSidebarFolding} />
        <div className="content-wrapper">
          {isSidebarFolding && <SidebarMain data={loginUrl.data} />}
          <div className={`content-outer ${isSidebarFolding ? "active" : ""}`}>
            {location.pathname.split("/")[2] !== "initial" && <RecentTap />}
            <div className="mt-5">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
