import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 전체, 골프, 콘도, 헬스에 따른 셀렉트박스
 */
export const useGetEtccodeDw = data => {
  return useQuery(
    ["getEtccodeDw", data],
    async () =>
      await axios
        .get("/api/codeMng/select/getEtccodeDw", { params: { type: data } })
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
