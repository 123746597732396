import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../components/layout/layout";
import { Initial } from "../pages/initial/initial";
import { Initial1 } from "../pages/initial/initial1";
import { Initial2 } from "../pages/initial/initial2";
import { Initial3 } from "../pages/initial/initial3";
import { Login } from "../pages/login/login";
import { CustomerManage } from "../pages/workManage/customerManage/customerManage/customerManage";
import { AcquisitionTaxManage } from "../pages/dataManage/acquisitionTaxManage/acquisitionTaxManage";
import { ClientManage } from "../pages/dataManage/clientManage/clientManage";
import { IndividualMembershipPrice } from "../pages/dataManage/golfMembershipPriceManage/individualMembershipPrice/individualMembershipPrice";
import { CorporateMembershipPrice } from "../pages/dataManage/golfMembershipPriceManage/corporateMembershipPrice/corporateMembershipPrice";
import { GolfPrice } from "../pages/dataManage/golfPrice/golfPrice";
import { AnalysisTradingItemPeriod } from "../pages/dataManage/analysisTradingItemPeriod/analysisTradingItemPeriod";
import { PersonMasterManage } from "../pages/personManage/personManage/personMasterManage/personMasterManage";
import DbStatus from "../pages/workManage/departmentManage/DBDistributionStatus/dbStatus/dbStatus";
import NewDbStatus from "../pages/workManage/departmentManage/DBDistributionStatus/newDbStatus/newDbStatus";
import WorkTransactionHistory from "../pages/workManage/transactionRegisterManage/transactionInputIquire/transactionRegisterManageMain";
import { EnvelopeLabelPrinting } from "../pages/workManage/postManage/envelopeLabelPrinting/envelopeLabelPrinting";
import { PostManage } from "../pages/workManage/postManage/postManage/postManage";
import TransferTaxManage from "../pages/workManage/transferTax/transferTax";
import { PdfLayout } from "../components/layout/PdfPlayout";
import EmployeeStatus from "../pages/personManage/personManage/employByDate/EmployeeStatus";
import InitialPublication from "../pages/personManage/taxManage/TaxInvoiceManage/InitialPublication";
import { CertificationManage } from "../pages/personManage/certificationManage/certificationManage/certificationManage";
import { CertificationLedger } from "../pages/personManage/certificationManage/certificationLedger/certificationLedger";
import { ManagingAnnualSchedule } from "../pages/goodsManage/managingAnnualSchedule/managingAnnualSchedule";
import { PersonalScheduleManagement } from "../pages/goodsManage/personalScheduleManagement/personalScheduleManagement";
import { SupportTeamSchedulesManage } from "../pages/goodsManage/supportTeamSchedulesManage/supportTeamSchedulesManage";
import { SupportTeamSchedulesInquire } from "../pages/goodsManage/supportTeamSchedulesInquire/supportTeamSchedulesInquire";
import { CallReservationManagement } from "../pages/goodsManage/callReservationManagement/callReservationManagement";
import { OrderManagementStatus } from "../pages/goodsManage/orderManagementStatus/orderManagementStatus";
import { IndividualCallHistory } from "../pages/goodsManage/callHistoryTeam/individualCallHistory/individualCallHistory";
import { HourlyDayAggregation } from "../pages/goodsManage/callHistoryTeam/hourlyDayAggregation/hourlyDayAggregation";
import { IndividualPerformance } from "../pages/goodsManage/individualPerformance/individualPerformance";
import { WeeklyPerformanceAnalysis } from "../pages/goodsManage/weeklyPerformanceAnalysis/weeklyPerformanceAnalysis";
import { AnnualLeaveManage } from "../pages/goodsManage/annualLeaveManage/annualLeaveManage/annualLeaveManage";
import { ReportOutput } from "../pages/goodsManage/annualLeaveManage/reportOutput/reportOutput";
import { AnnualApplicationPreparation } from "../pages/goodsManage/annualLeaveManage/annualApplicationPreparation/annualApplicationPreparation";
import { ApprovedHeadAnnualDepartment } from "../pages/goodsManage/annualLeaveManage/approvedHeadAnnualDepartment/approvedHeadAnnualDepartment";
import { ApprovedMaster } from "../pages/goodsManage/annualLeaveManage/approvedMaster/approvedMaster";
import { ApprovedRepresentativeDirector } from "../pages/goodsManage/annualLeaveManage/approvedRepresentativeDirector/approvedRepresentativeDirector";
import { AnnualDetailsManagers } from "../pages/goodsManage/annualLeaveManage/AnnualDetailsManagers/annualDetailsManagers";
import ReIssueTaxInvoice from "../pages/personManage/taxManage/reissuanceTaxInvoice/ReIssueTaxInvoice";
import TransactionRegisterManageDocumentManage from "../pages/workManage/transactionRegisterManage/documentManage/TransactionRegisterManageDocumentManage";
import { CondoPrice } from "../pages/dataManage/condoPrice/condoPrice";
import { SportsPrice } from "../pages/dataManage/sportsPrice/sportsPrice";
import { CondoPriceInquiry } from "../pages/dataManage/condoPriceInquiry/condoPriceInquiry";
import { MembershipItem } from "../pages/dataManage/membershipItem/membershipItem";
import { ShopMasterManage } from "../pages/workManage/shopMasterManage/shopMasterManage";
import MembershipFeeManage from "../pages/personManage/prematureManage/membershipFeeManage/membershipFeeManage";
import { CondoMasterManage } from "../pages/workManage/condoMasterManage/condoMasterManage";
import DepositWithdrawalStats from "../pages/personManage/prematureManage/depositWithDrawTable/depositWithDrawTable";
import CompanyAccountManage from "../pages/personManage/accountManage/companyAccountManage/companyAccountManage";
import GolfManageMain from "../pages/workManage/golfManage/golfManageMain";
import { SportsMasterManage } from "../pages/workManage/sportMasterManage/sportMasterManage";
import IndividualPerformanceRegister from "../pages/personManage/individualPerformance/individualPerformance";
import AnnualLeaveRegister from "../pages/personManage/annualManage/annualManage";
import { DeliveryMessage } from "../pages/workManage/deliveryMessage/deliveryMessage";
import { QuotationManagement } from "../pages/dataManage/quotationManagement/quotationManagement";
import { RegisteredDeliveryHistory } from "../pages/workManage/postManage/registeredDeliveryHistory/registeredDeliveryHistory";
import { DailyCall } from "../pages/workManage/dailyCall/dailyCall";
import { SMSTransferHistory } from "../pages/workManage/SMSTransferHistory/SMSTransferHistory";
import { CardInquiry } from "../pages/dataManage/cardManage/cardInquiry";
import { CardMaster } from "../pages/dataManage/cardManage/cardMaster";
import { CardManager } from "../pages/dataManage/cardManage/cardManager";
import { MonthlyTextManage } from "../pages/dataManage/monthlyTextManage/monthlyTextManage";

import ApprovalInbox from "../pages/approvalManage/approvalInbox/approvalInbox";
import SubmissionInbox from "../pages/approvalManage/submissionInbox/submissionInbox";

import { DaposUserManage } from "../pages/codeManage/daposUserManage/daposUserManage";
import { ChangeByPerson } from "../pages/codeManage/changeByPersonIncharge/changeByPerson";
import { IpConnectionAuth } from "../pages/codeManage/ipAccessAuthManage/ipConnectionAuth";

import { TransactionProgress } from "../pages/dataManage/transactionProgress/transactionProgress";
import MembershipTransactionAmount from "../pages/dataManage/membershipTransactionAmount/membershipTransactionAmount";
import { DriveInput } from "../pages/dataManage/carManage/driveInput/driveInput";
import { FuelInput } from "../pages/dataManage/carManage/fuelInput/fuelInput";
import { CarMaster } from "../pages/dataManage/carManage/carMaster/carMaster";
import ApprovalLinePage from "../pages/approvalManage/approvalLineManage/approvalLineManagePage";
import AcquTax from "../pages/workManage/acquTax/acquTax";
import { PermissionEachMenu } from "../pages/codeManage/permissionEachMenu/permissionEachMenu";
import { EditProfile } from "../pages/initial/editProfile";

export const RootRouter = () => {
  const defaultURL = process.env.PUBLIC_URL;

  return (
    //prettier-ignore
    <Routes>
      <Route path={defaultURL + "/"} element={<Login />} />
        <Route element={<PdfLayout />}>
      </Route>

      <Route path={defaultURL + "/initial1"} element={<Initial1 />} />
      <Route path={defaultURL + "/initial2"} element={<Initial2 />} />
      <Route path={defaultURL + "/initial3"} element={<Initial3 />} />

      <Route element={<Layout />}>
        {/* 초기 화면 */}
        <Route path={defaultURL + "/initial"} element={<Initial />} />

        {/* 업무 관리 */}
        <Route path={defaultURL + "/work/customer/customerManage"} element={<CustomerManage />} />
        <Route path={defaultURL + "/work/db/databaseDistributionStatus/databaseDistribution"} element={<DbStatus />} />
        <Route path={defaultURL + "/work/newdb/databaseDistributionStatus/newDbStatus"} element={<NewDbStatus />} />
        <Route path={defaultURL + "/work/transaction/transactionRegisterManage/transactionHistory"} element={<WorkTransactionHistory />} />
        <Route path={defaultURL + "/work/transaction/transactionRegisterManage/documentManage"} element={<TransactionRegisterManageDocumentManage />} />
        <Route path={defaultURL + "/work/mailBox/postManage/envelopeLabelPrinting"} element={<EnvelopeLabelPrinting />} />
        <Route path={defaultURL + "/work/mail/postManage/postMailManage"} element={<PostManage />} />
        <Route path={defaultURL + "/work/mailReg/postManage/registeredDeliveryHistory"} element={<RegisteredDeliveryHistory />} />
        <Route path={defaultURL + "/work/transferTax/transferManage"} element={<TransferTaxManage />} />
        <Route path={defaultURL + "/work/acquisitionTax/acquisitionTaxManage"} element={<AcquTax />} />
        <Route path={defaultURL + "/work/shop/shopMasterManage"} element={<ShopMasterManage />} />
        <Route path={defaultURL + "/work/condo/condoMasterManage"} element={<CondoMasterManage />} />
        <Route path={defaultURL + "/work/manageGolf/golfManage"} element={<GolfManageMain />} />
        <Route path={defaultURL + "/work/sports/sportMasterManage"} element={<SportsMasterManage />} />
        <Route path={defaultURL + "/work/info/deliveryMessage"} element={<DeliveryMessage />} />
        <Route path={defaultURL + "/work/callDbd/dailyCall"} element={<DailyCall />} />
        <Route path={defaultURL + "/work/smsHistory/smsTransferHistory"} element={<SMSTransferHistory />} />

        {/* 자료 관리 */}
        <Route path={defaultURL + "/data/acquisition/acquisitionManage"} element={<AcquisitionTaxManage />} />
        <Route path={defaultURL + "/data/supplier/clientManage"} element={<ClientManage />} />
        <Route path={defaultURL + "/data/individual/golfMembershipPriceManage/individualMembershipPrice"} element={<IndividualMembershipPrice />} />
        <Route path={defaultURL + "/data/company/golfMembershipPriceManage/corporateMembershipPrice"} element={<CorporateMembershipPrice />} />
        <Route path={defaultURL + "/data/golfGraph/golfPrice"} element={<GolfPrice />} />
        <Route path={defaultURL + "/data/golfMember/analysisTradingItemPeriod"} element={<AnalysisTradingItemPeriod />} />
        <Route path={defaultURL + "/data/condoMember/condoPrice"} element={<CondoPrice />} />
        <Route path={defaultURL + "/data/sportMember/sportsPrice"} element={<SportsPrice />} />
        <Route path={defaultURL + "/data/condoGraph/condoPriceInquiry"} element={<CondoPriceInquiry />} />
        <Route path={defaultURL + "/data/memberManage/membershipItem"} element={<MembershipItem />} />
        <Route path={defaultURL + "/data/card/cardManage/cardInquiry"} element={<CardInquiry />} />
        <Route path={defaultURL + "/data/cardMaster/cardManage/cardMaster"} element={<CardMaster />} />
        <Route path={defaultURL + "/data/cardManage/cardManage/cardManager"} element={<CardManager />} />
        <Route path={defaultURL + "/data/estimate/quotationManagement"} element={<QuotationManagement />} />
        <Route path={defaultURL + "/data/smsSendManage/manageMonthlyText"} element={<MonthlyTextManage />} />
        <Route path={defaultURL + "/data/transactionManage/transactionProgress"} element={<TransactionProgress />} />
        <Route path={defaultURL + "/data/memberBank/membershipTransactionAmount"} element={<MembershipTransactionAmount />} />
        <Route path={defaultURL + "/data/carOilManage/carManage/driveInput"} element={<DriveInput />} />
        <Route path={defaultURL + "/data/carDriveManage/carManage/fuelInput"} element={<FuelInput />} />
        <Route path={defaultURL + "/data/carMaster/carManage/car"} element={<CarMaster />} />

        {/* 인사 관리 */}
        <Route path={defaultURL + "/person/empMasterMng/personManage/personMasterManage"} element={<PersonMasterManage />} />
        <Route path={defaultURL + "/person/empByDay/personManage/personEmployeeStatus"} element={<EmployeeStatus />} />
        <Route path={defaultURL + "/person/taxFirstIssue/taxInvoiceManage/initialPublication"} element={<InitialPublication />} />
        <Route path={defaultURL + "/person/taxReIssue/taxInvoiceManage/reIssueTaxInvoice"} element={<ReIssueTaxInvoice />} />
        <Route path={defaultURL + "/person/docMng/certificationManage/certificationManage"} element={<CertificationManage />} />
        <Route path={defaultURL + "/person/docHistory/certificationManage/certificationLedger"} element={<CertificationLedger />} />
        <Route path={defaultURL + "/person/feeList/prematureManage/membershipFeeManage"} element={<MembershipFeeManage />} />
        <Route path={defaultURL + "/person/feeMng/prematureManage/depositWithDrawTable"} element={<DepositWithdrawalStats />} />
        <Route path={defaultURL + "/person/coAccount/accountManage/companyAccountManage"} element={<CompanyAccountManage />} />
        <Route path={defaultURL + "/person/empPerformance/individualPerformanceRegister"} element={<IndividualPerformanceRegister />} />
        <Route path={defaultURL + "/person/yunchaMng/annualManage"} element={<AnnualLeaveRegister />} />

        {/* 매물 관리*/}
        <Route path={defaultURL + "/goods/managing_annual_schedule"} element={<ManagingAnnualSchedule />} />
        <Route path={defaultURL + "/goods/individualManage/personalScheduleManagement"} element={<PersonalScheduleManagement />} />
        <Route path={defaultURL + "/goods/supportManage/supportTeamSchedulesManage"} element={<SupportTeamSchedulesManage />} />
        <Route path={defaultURL + "/goods/supportManageCheck/supportTeamSchedulesInquire"} element={<SupportTeamSchedulesInquire />} />
        <Route path={defaultURL + "/goods/callReserveManage/callReservationManagement"} element={<CallReservationManagement />} />
        <Route path={defaultURL + "/goods/orderManageStatus/orderManage"} element={<OrderManagementStatus />} />
        <Route path={defaultURL + "/goods/call_history/individual_callHistory"} element={<IndividualCallHistory />} />
        <Route path={defaultURL + "/goods/call_history/hourlyDay_aggregation"} element={<HourlyDayAggregation />} />
        <Route path={defaultURL + "/goods/profit/individualPerformance"} element={<IndividualPerformance />} />
        <Route path={defaultURL + "/goods/profitTable/weeklyPerformanceAnalysis"} element={<WeeklyPerformanceAnalysis />} />
        <Route path={defaultURL + "/goods/commuting/annualLeave/annualLeaveManage"} element={<AnnualLeaveManage />} />
        <Route path={defaultURL + "/goods/commutingReport/annualLeave/reportOutput"} element={<ReportOutput />} />
        <Route path={defaultURL + "/goods/yunchaRegist/annualLeave/annualApplicationPreparation"} element={<AnnualApplicationPreparation />} />
        <Route path={defaultURL + "/goods/annual_leave/approved_headAnnual_department"} element={<ApprovedHeadAnnualDepartment />} />
        <Route path={defaultURL + "/goods/annual_leave/approved_master"} element={<ApprovedMaster />} />
        <Route path={defaultURL + "/goods/annual_leave/approved_representative_director"} element={<ApprovedRepresentativeDirector />} />
        <Route path={defaultURL + "/goods/annual_leave/annual_details_managers"} element={<AnnualDetailsManagers />} />

        {/* 코드 관리 */}
        <Route path={defaultURL + "/code/daposUserManage/daposUserManager"} element={<DaposUserManage />} />
        <Route path={defaultURL + "/code/partnerDivideManage/changePerson"} element={<ChangeByPerson />} />
        <Route path={defaultURL + "/code/ipControlManage/ipConnectionAuth"} element={<IpConnectionAuth />} />
        <Route path={defaultURL + "/code/authManage/permissionEachMenu"} element={<PermissionEachMenu />} />


        {/* 결재 관리 */}
        <Route path={defaultURL + "/approval/approvalMain/approvalIn"} element={<ApprovalInbox />} />
        <Route path={defaultURL + "/approval/approvalLine/approvalLine"} element={<ApprovalLinePage />} />
        <Route path={defaultURL + "/approval/approvalMain/approvalSub"} element={<SubmissionInbox />} />
      </Route>
    </Routes>
  );
};
