import React, { useEffect, useState } from "react";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { axios } from "../../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useGetRelation } from "../../../../../api/code/useGetRelation";
import { useAtom } from "jotai";
import {
  PersonFamilyTab_Data,
  PersonFamilyTab_addData,
  PersonFamilyTab_backupData,
  PersonFamilyTab_createMode,
  PersonFamilyTab_modifyData,
  PersonFamilyTab_sawonId,
} from "../../../../../data/atom/personManage/personMasterManage/PersonFamilyTab_atom";

export default function PersonFamilyTab({ sawonId, userId }) {
  const { data: getRelation } = useGetRelation();
  const initialModifyData = {
    job: "",
    juminno: "",
    kname: "",
    modDate: moment(new Date()),
    modId: "",
    relation: "11",
    sawonId: sawonId,
    seqNo: 0,
    togeter: "",
    userid: userId,
  };
  const defaultURL = "";
  const [createMode, setCreateMode] = useAtom(PersonFamilyTab_createMode);
  const [data, setData] = useAtom(PersonFamilyTab_Data);
  const [modifyData, setModifyData] = useAtom(PersonFamilyTab_modifyData);
  const [backupData, setBackupData] = useAtom(PersonFamilyTab_backupData);
  const [addData, setAddData] = useAtom(PersonFamilyTab_addData);
  const [saveSawonId, setSaveSawonId] = useAtom(PersonFamilyTab_sawonId);
  // API - 가족사항 조회
  const { mutate: mutate_personFamily, isLoading: getLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getFamily", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        setData(result);
        setBackupData(result);
        setModifyData([]);
        setCreateMode(false);
        setAddData(null);
      },
    }
  );

  // API - 가족사항 생성/수정
  const { mutate: mutate_personFamilyEdit, isLoading: modifyLoading } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/saveFamily", data),
    {
      onSuccess: res => {
        mutate_personFamily(sawonId);
        setModifyData([]);
        setAddData(null);
        setCreateMode(false);
      },
    }
  );

  // API - 가족사항 삭제
  const { mutate: mutate_personFamilyDelete } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/deleteFamily", data),
    {
      onSuccess: res => {
        mutate_personFamily(sawonId);
      },
    }
  );

  useEffect(() => {
    setSaveSawonId(sawonId);
    if (saveSawonId !== sawonId) {
      mutate_personFamily(sawonId);
    }
  }, []);

  const changeHandler = (e, targetData) => {
    const { name, value } = e.target;
    let dataValue = value;

    if (name.includes("juminno")) {
      dataValue = value.replace(/[^0-9-]/g, "");
    }

    const newDataList = data.map(item => {
      if (item.seqNo === targetData.seqNo) {
        return { ...item, [name]: dataValue };
      } else {
        return { ...item };
      }
    });
    setData(newDataList);

    // api 통신 데이터
    const isData = element => {
      return element.seqNo === targetData.seqNo;
    };

    const idx = modifyData?.findIndex(isData);
    if (idx === -1) {
      setModifyData([...modifyData, { ...targetData, [name]: dataValue }]);
    } else {
      const newModifyData = modifyData.map(item => {
        if (item.seqNo === targetData.seqNo) {
          return { ...item, [name]: dataValue };
        } else {
          return { ...item };
        }
      });
      setModifyData(newModifyData);
    }
  };

  return (
    <>
      <div className="px-20px py-15px">
        <div className={`table-container table-sticky h-183`}>
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>연번</th>
                <th style={{ width: "8%" }}>관계</th>
                <th style={{ width: "8%" }}>이름</th>
                <th style={{ width: "8%" }}>주민등록번호</th>
                <th style={{ width: "8%" }}>동거여부</th>
                <th style={{ width: "8%" }}>비고</th>
                {decrypt("userInfo").userPermit === "99" && <th style={{ width: "3%" }}>삭제</th>}
              </tr>
            </thead>
            <tbody>
              {createMode && (
                <tr>
                  <td></td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, relation: e.target.value })}
                      value={addData?.relation}
                    >
                      {getRelation?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => setAddData({ ...addData, kname: e.target.value })}
                      value={addData?.kname}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e =>
                        setAddData({ ...addData, juminno: e.target.value.replace(/[^0-9-]/g, "") })
                      }
                      value={addData?.juminno}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, togeter: e.target.value })}
                      value={addData.togeter}
                    >
                      <option value="Y">동거</option>
                      <option value="N">비동거</option>
                      <option value="D">사망</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => setAddData({ ...addData, job: e.target.value })}
                      value={addData?.job}
                    />
                  </td>
                  {decrypt("userInfo").userPermit === "99" && <td></td>}
                </tr>
              )}
              {data.length > 0
                ? data.map((familyData, index) => (
                    <tr className="cursor-pointer" onClick={() => {}}>
                      <td>{index + 1}</td>
                      <td>
                        <select
                          className="form-select"
                          value={familyData?.relation}
                          name="relation"
                          onChange={e => changeHandler(e, familyData)}
                        >
                          {getRelation?.map(item => {
                            return <option value={item.key}>{item.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={familyData?.kname}
                          name="kname"
                          onChange={e => changeHandler(e, familyData)}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={familyData?.juminno}
                          name="juminno"
                          onChange={e => changeHandler(e, familyData)}
                        />
                      </td>

                      <td>
                        <select
                          className="form-select"
                          value={familyData?.togeter}
                          name="togeter"
                          onChange={e => changeHandler(e, familyData)}
                        >
                          <option value="Y">동거</option>
                          <option value="N">비동거</option>
                          <option value="D">사망</option>
                        </select>
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={familyData?.job}
                          name="job"
                          onChange={e => changeHandler(e, familyData)}
                        />
                      </td>
                      {decrypt("userInfo").userPermit === "99" && (
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            const result = window.confirm("해당 기록을 삭제하시겠습니까?");
                            if (result) {
                              mutate_personFamilyDelete({
                                sawonId: sawonId,
                                seqNo: familyData.seqNo,
                              });
                            } else {
                            }
                          }}
                        >
                          <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                        </td>
                      )}
                    </tr>
                  ))
                : !createMode && (
                    <tr className="cursor-pointer" onClick={() => {}}>
                      <td colSpan={decrypt("userInfo").userPermit === "99" ? 8 : 7}>
                        조회된 데이터가 없습니다.
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-10px">
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              setCreateMode(true);
              setAddData(initialModifyData);
            }}
          >
            추가
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              if (addData) {
                mutate_personFamilyEdit([...modifyData, addData]);
              } else {
                if (modifyData.length > 0) {
                  mutate_personFamilyEdit(modifyData);
                }
              }
            }}
          >
            저장
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              setCreateMode(false);
              setModifyData([]);
              setAddData(null);
              setData(backupData);
              alert("변경 사항이 취소되었습니다.");
            }}
          >
            취소
          </button>
        </div>
      </div>
    </>
  );
}
