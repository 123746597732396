import React from "react";
import style from "./spinner.module.css";
import { SyncLoader } from "react-spinners";

export const Spinner = () => {
  return (
    <div className={style.background}>
      <SyncLoader className={style.spinner} color="#000000" />
    </div>
  );
};
