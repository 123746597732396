import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  callReservationManagement_callCountList,
  callReservationManagement_callHistory,
  callReservationManagement_callHistoryOption,
  callReservationManagement_callHistoryOptionInit,
  callReservationManagement_callReserveDetailList,
  callReservationManagement_customerDetail,
  callReservationManagement_row_atom,
  callReservationManagement_selectRow1,
  callReservationManagement_selectRow2,
  callReservationManagement_selectType,
} from "../../../data/atom/goodsManage/callReservationManagement/callReservationManagement_atom";
import moment from "moment";
import { useGetCustGubun } from "../../../api/code/useGetCustGubun";
import { useGetBank } from "../../../api/code/useGetBank";
import { useGetUp } from "../../../api/code/useGetUp";
import { decrypt } from "../../../util/decrypt/decrypt";
import useInfiniteScrollWrapper from "../../../util/infiniteScroll/useInfiniteScrollWrapper";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

const jisaOptions = () => {
  return (
    <>
      <option value={"99"}>전체</option>
      <option value={"11"}>서울본사</option>
      <option value={"12"}>분당지사</option>
      <option value={"13"}>대전지사</option>
      <option value={"14"}>대구지사</option>
      <option value={"15"}>부산지사</option>
      <option value={"18"}>호남지사</option>
      <option value={"21"}>동아회원권</option>
    </>
  );
};

// userPermit 44,31,61 은 지사장 또는 마스터
// 지사장 또는 마스터인 경우에는 해당 지사에 해당하는 통화자만 조회가능
// 99 전산관리자는 전체 조회가능
// 일반 이용자는 본인만 조회가능
const callerOptions = getCallReserveUserList => {
  const userInfo = decrypt("userInfo");
  const { userPermit, jisa, userName } = userInfo;

  const filteredUsers = getCallReserveUserList?.filter(item => {
    const isPermit44_31_61 = ["44", "31", "61"].includes(userPermit); // 지사장 또는 마스터
    return (
      userPermit === "99" || item.value === userName || (isPermit44_31_61 && item.jisa === jisa)
    );
  });

  return filteredUsers?.map(item => (
    <option value={item?.value} data-userid={item.key}>
      {item?.value}
    </option>
  ));
};

export const CallReservationManagement = () => {
  const { addTabClosedEvent } = useRecentTabEvent();

  const [selectOption, setSelectOption] = useAtom(callReservationManagement_selectType);
  const [callHistorySearchOption, setCallHistorySearchOption] = useAtom(
    callReservationManagement_callHistoryOption
  );
  const [callReservationList, setCallReservationList] = useAtom(
    callReservationManagement_callCountList
  );
  const [callReservationDetailList, setCallReservationDetailList] = useAtom(
    callReservationManagement_callReserveDetailList
  );
  const [customerDetail, setCustomerDetail] = useAtom(callReservationManagement_customerDetail);
  const [searchListNumber, setSearchListNumber] = useAtom(callReservationManagement_row_atom);
  const [callHistory, setCallHistory] = useAtom(callReservationManagement_callHistory);
  const [addressIsOpen, setAddressIsOpen] = useState([false, false]);
  const [selectRow1, setSelectRow1] = useAtom(callReservationManagement_selectRow1);
  const [selectRow2, setSelectRow2] = useAtom(callReservationManagement_selectRow2);

  const getCustGubun = useGetCustGubun();
  const getBank = useGetBank();
  const getUp = useGetUp();

  useEffect(() => {
    if (!callHistorySearchOption.init) {
      const userInfo = decrypt("userInfo");
      setCallHistorySearchOption(prev => ({
        ...prev,
        jisa: userInfo.jisa,
        person: userInfo.userName,
        personId: userInfo.loginId,
        init: true,
      }));
    }
  }, [callHistorySearchOption]);

  // API - 출력 지사
  const { data: getCarMasterJisaList } = useQuery(
    ["getCarMasterJisaList"],
    async () =>
      await axios("/api/codeMng/select/getCarMasterJisaList").then(res => {
        return res?.data?.result;
      })
  );

  const { data: getCallReserveUserList } = useQuery(
    ["getCallReserveUserList"],
    async () =>
      await axios("/api/codeMng/select/getCallReserveUserList").then(res => {
        return res?.data?.result;
      })
  );

  // API - 통화 예약 건수 리스트 가져오기
  const search = useMutation(
    data =>
      axios.get("/api/schedule/callReserveManage/search", {
        params: data,
      }),
    {
      onSuccess: res => {
        // 통화 예약일때
        setCallReservationList(res?.data?.result);
      },
    }
  );

  // API - 고객 상세 가져오기
  const getCustomerDetail = useMutation(
    data =>
      axios.get("/api/schedule/callReserveManage/getCustomerDetail", {
        params: data,
      }),
    {
      onSuccess: (res, variables) => {
        getCustomerCallDetail.mutate({
          custId: variables?.seqNo,
        });
        setSelectRow1(variables?.seqNo);
        setCustomerDetail(res?.data?.result);
      },
    }
  );

  // API - 통화예약 건수 리스트 상세조회
  const getDetail = useMutation(
    data =>
      axios.get("/api/schedule/callReserveManage/getDetail", {
        params: data,
      }),
    {
      onSuccess: (res, variables) => {
        setSelectRow2(variables);
        setCallReservationDetailList(res?.data?.result);
      },
    }
  );

  // API - 통화내역 리스트 가져오기
  const getCustomerCallDetail = useMutation(
    data =>
      axios.get("/api/schedule/callReserveManage/getCustomerCallDetail", {
        params: data,
      }),
    {
      onSuccess: res => {
        setCallHistory(res?.data?.result);
      },
    }
  );

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSelectOption("1");
      setCallHistorySearchOption(callReservationManagement_callHistoryOptionInit);
      setCallReservationList([]);
      setCallReservationDetailList([]);
      setCustomerDetail({});
      setSearchListNumber("5");
      setCallHistory([]);
      setSelectRow1("");
      setSelectRow2("");
    });
  }, []);

  const pressEnter = e => {
    if (e.key === "Enter") {
      callSearch();
    }
  };

  // 조회 클릭 또는 엔터 할때 검색
  const callSearch = () => {
    setSelectRow1("");
    if (callHistorySearchOption.search === "2") {
      // search.mutate({
      //   ...callHistorySearchOption,
      //   search: "통화예약",
      //   page: 1, // page 1로 고정
      // });

      refetchFromFirstPage({
        ...callHistorySearchOption,
        search: "통화예약",
        page: 1, // page 1로 고정
      });

      setSelectOption("2");
    } else {
      // search.mutate({
      //   ...callHistorySearchOption,
      //   search: "통화내역",
      //   page: 1, // page 1로 고정
      // });

      refetchFromFirstPage({
        ...callHistorySearchOption,
        search: "통화내역",
        page: 1, // page 1로 고정
      });

      setSelectOption("1");
    }
  };

  // 검색 조건 handle change
  const searchOptionHandleChange = e => {
    const { name, value } = e.target;
    if (name === "person")
      setCallHistorySearchOption({
        ...callHistorySearchOption,
        [name]: value,
        personId: e.target.options[e.target.selectedIndex].dataset.userid,
      });
    else setCallHistorySearchOption({ ...callHistorySearchOption, [name]: value });
  };

  const fetchFirstPage = async param => {
    let res = await axios.get("/api/schedule/callReserveManage/search", {
      params: param,
    });

    return res.data.result;
  };

  const { refetchFromFirstPage, refetchAllPage } = useInfiniteScrollWrapper({
    fetch: fetchFirstPage,
    fetchKey: ["callReserveManageSearch"],
    searchParam: {
      ...callHistorySearchOption,
      search: callHistorySearchOption.search === "2" ? "통화예약" : "통화내역",
      page: 1, // page 1로 고정
    },
    resultSetter: setCallReservationList,
  });

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">통화예약관리 및 통화내역 조회</div>
            <div className="filter-buttons"></div>
          </div>
          <div className="filter_fields_container" onKeyDown={pressEnter}>
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select filter1-select1"
                    name="search"
                    value={callHistorySearchOption.search}
                    onChange={e => {
                      searchOptionHandleChange(e);
                    }}
                  >
                    <option value="1">통화내역</option>
                    <option value="2">통화예약</option>
                  </select>
                </div>
                <div className="filter1-field filter1-field2 m-0 p-0">
                  <label className="filter1-label ms-3">통화일자</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    name="frontDate"
                    value={callHistorySearchOption.frontDate}
                    onChange={e => {
                      searchOptionHandleChange(e);
                    }}
                    min="1900-01-01"
                    max="2099-12-31"
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field3 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    name="backDate"
                    min={callHistorySearchOption.frontDate}
                    max="2099-12-31"
                    value={callHistorySearchOption.backDate}
                    onChange={e => {
                      searchOptionHandleChange(e);
                    }}
                  />
                </div>
                {callHistorySearchOption.search === "1" && (
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">관리지사</label>
                    <select
                      className="form-select filter1-select1"
                      name="jisa"
                      value={callHistorySearchOption.jisa}
                      onChange={e => searchOptionHandleChange(e)}
                    >
                      {jisaOptions()}
                    </select>
                  </div>
                )}
                {
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">통화자</label>
                    <select
                      className="form-select filter1-select1"
                      name="person"
                      value={callHistorySearchOption.person}
                      onChange={e => searchOptionHandleChange(e)}
                      disabled={
                        !["31", "44", "61", "99"].includes(decrypt("userInfo").userPermit) ||
                        (callHistorySearchOption.search === "2" &&
                          decrypt("userInfo").userPermit != "99")
                      }
                    >
                      {callerOptions(getCallReserveUserList)}
                    </select>
                  </div>
                }
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={() => {
                  callSearch();
                }}
              >
                조회
              </button>
            </div>
          </div>
        </div>

        <div className="x">
          <div className="x">
            {/*  통화 내역 */}
            {selectOption == "1" && (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="tab1-row1-count">
                          검색 결과 : &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          {callReservationList ? callReservationList.length : 0} 건
                        </div>
                        <div className="d-flex align-items-center w-50 justify-content-end">
                          <select
                            className="form-select filter-perpage ms-8px h-31"
                            value={searchListNumber}
                            onChange={e => {
                              setSearchListNumber(e.target.value);
                            }}
                          >
                            <option value="5">5개씩 보기</option>
                            <option value="10">10개씩 보기</option>
                            <option value="20">20개씩 보기</option>
                          </select>
                        </div>
                      </div>
                      <div
                        className={`table-container table-sticky 
                            ${searchListNumber === "5" && "h-183"}
                            ${searchListNumber === "10" && "h-333"}
                            ${searchListNumber === "20" && "h-633"} mt-10px`}
                        id="scroll-table-body"
                      >
                        <table className="table table-bordered table-responsive my-table my-0">
                          <thead>
                            <tr>
                              <th style={{ width: "7%" }}>연번</th>
                              <th>거래</th>
                              <th>등급</th>
                              <th>관리번호</th>
                              <th>업체명</th>
                              <th>성명</th>
                              <th>직위</th>
                              <th>기록건수</th>
                              <th>통화시간</th>
                            </tr>
                          </thead>
                          <tbody>
                            {callReservationList?.length > 0 ? (
                              <>
                                {callReservationList.map((data, _idx) => {
                                  return (
                                    <tr
                                      role="button"
                                      onClick={() => {
                                        getCustomerDetail.mutate({
                                          seqNo: data?.seqNo,
                                        });
                                      }}
                                      className={`${selectRow1 === data?.seqNo && "bg-blue"}`}
                                    >
                                      <td>{_idx + 1}</td>
                                      <td>{data?.mgubun}</td>
                                      <td>{data?.mvipCheck}</td>
                                      <td>
                                        {data?.custId2 &&
                                          data?.custId2.slice(0, 4) + "-" + data?.custId2.slice(4)}
                                      </td>
                                      <td>{data?.company}</td>
                                      <td>{data?.kname}</td>
                                      <td>{data?.position}</td>
                                      <td>{data?.mcount}</td>
                                      <td>
                                        {data?.inDate2 &&
                                          moment(data?.inDate2).format("YYYY-MM-DD HH:mm:ss")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={9}>조회 결과가 없습니다.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/*  통화 예약 */}
            {selectOption == "2" && (
              <div className="card card-main">
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <div className="d-flex align-items-center justify-content-between mt-0">
                        <div className="tab1-row1-count">
                          검색 결과 : &nbsp; &nbsp; &nbsp; &nbsp;
                          {callReservationList ? callReservationList.length : 0} 건
                        </div>
                      </div>
                      <div
                        className={`table-container table-sticky h-183 mt-3`}
                        id="scroll-table-body"
                      >
                        <table className="table table-bordered table-responsive my-table mb-0 mt-0">
                          <thead>
                            <tr>
                              <th style={{ width: "14%" }}>No</th>
                              <th>예약일시</th>
                              <th>건수</th>
                            </tr>
                          </thead>
                          <tbody>
                            {callReservationList?.length > 0 ? (
                              <>
                                {callReservationList?.map((data, _idx) => {
                                  return (
                                    <tr
                                      role="button"
                                      onClick={() => {
                                        getDetail.mutate({
                                          searchDate: moment(data?.searchDate).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                          ),
                                          userId: data?.userId,
                                        });
                                      }}
                                      className={`${
                                        selectRow2.searchDate ===
                                          moment(data?.searchDate).format("YYYY-MM-DD HH:mm:ss") &&
                                        selectRow2?.userId === data?.userId &&
                                        "bg-blue"
                                      }`}
                                    >
                                      <td>{_idx + 1}</td>
                                      <td>
                                        {moment(data?.searchDate).format("YYYY-MM-DD HH:mm:ss")}
                                      </td>
                                      <td>{data?.count}</td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={3}>조회 결과가 없습니다.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-9">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="tab1-row1-count">
                          검색 결과 : &nbsp; &nbsp; &nbsp; &nbsp;
                          {callReservationDetailList ? callReservationDetailList.length : 0} 건
                        </div>
                      </div>
                      <div
                        className={`table-container table-sticky 
                    h-183 mt-3`}
                        id="scroll-table-body"
                      >
                        <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "7%" }}>No</th>
                              <th style={{ width: "15%" }}>예약일시</th>
                              <th style={{ width: "10%" }}>관리번호</th>
                              <th>회사명</th>
                              <th style={{ width: "15%" }}>신청일자</th>
                            </tr>
                          </thead>
                          <tbody>
                            {callReservationDetailList.length > 0 ? (
                              callReservationDetailList?.map((data, _idx) => {
                                return (
                                  <tr
                                    role="button"
                                    onClick={() => {
                                      setCustomerDetail([]);
                                      setCallHistory([]);
                                      getCustomerDetail.mutate({
                                        seqNo: data?.custId,
                                      });
                                    }}
                                    className={`${selectRow1 === data?.custId && "bg-lightblue"}`}
                                  >
                                    <td>{_idx + 1}</td>
                                    <td>
                                      {data?.requestDate &&
                                        moment(data?.requestDate).format("YYYY-MM-DD HH:mm:ss")}
                                    </td>
                                    {/* 관리번호 */}
                                    <td>
                                      {data?.ccustId.slice(0, 4) + "-" + data?.ccustId.slice(4)}
                                    </td>
                                    <td className="text-center">{data?.company}</td>
                                    <td>
                                      {data?.modDate &&
                                        moment(data?.modDate).format("YYYY-MM-DD HH:mm:ss")}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={5}>조회 결과가 없습니다.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {customerDetail?.seqNo && decrypt("userInfo").userPermit === "99" && (
          <>
            <div className="card card-main">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="tab1-row1-count">
                    관리번호 :
                    {customerDetail?.custId.slice(0, 4) + "-" + customerDetail?.custId.slice(4)}
                  </div>
                  <div className="tab1-row1-field1">
                    <label>분류 :</label>
                    {customerDetail?.kindGubn}
                    {customerDetail?.moveHope}
                  </div>
                </div>

                <table className="table table-bordered table-responsive my-table1 mt-10px">
                  <tbody>
                    <tr>
                      <th style={{ width: "11.9%" }}>특이사항</th>
                      <td style={{ width: "38.09%" }} colSpan={3}>
                        {customerDetail?.bigoRemk}
                      </td>
                      <th style={{ width: "11.9%" }}>고객분류</th>
                      <td style={{ width: "19.045%" }}>
                        {getCustGubun?.data?.map(el => {
                          if (el.key === customerDetail?.gubun) {
                            return <span>{el.value}</span>;
                          }
                        })}
                      </td>
                      <td style={{ width: "19.045%" }}>{customerDetail?.vipCheck}</td>
                    </tr>
                    <tr>
                      <th>업체명</th>
                      <td colSpan={3}>{customerDetail?.company}</td>
                      <th>성명</th>
                      <td colSpan={2}>{customerDetail?.kname}</td>
                    </tr>
                    <tr>
                      <th>주민번호</th>
                      <td colSpan={3}>{customerDetail?.juminno1}</td>
                      <th>교환</th>
                      <td colSpan={2}>{customerDetail?.ctelChange}</td>
                    </tr>
                    <tr>
                      <th>직장전화</th>
                      <td style={{ width: "11.9%" }}>{customerDetail?.ctel}</td>
                      <th className="f-roboto-medium" style={{ width: "11.9%" }}>
                        FAX
                      </th>
                      <td style={{ width: "11.9%" }}>{customerDetail?.cfax}</td>
                      <th>부서명</th>
                      <td colSpan={2}>{customerDetail?.devision}</td>
                    </tr>
                    <tr>
                      <th>자택전화</th>
                      <td colSpan={3}>{customerDetail?.htel}</td>
                      <th>직위</th>
                      <td colSpan={2}>{customerDetail?.position}</td>
                    </tr>
                    <tr>
                      <th className="f-roboto-medium">E-MAIL</th>
                      <td colSpan={3}>{customerDetail?.email}</td>
                      <th>휴대폰</th>
                      <td colSpan={2}>{customerDetail?.cellular}</td>
                    </tr>
                    <tr>
                      <th>계좌번호</th>
                      <td colSpan={3}>
                        {getBank?.data?.map(el => {
                          if (el.key === customerDetail?.payBank) {
                            return <span>{el.value + " "}</span>;
                          }
                        })}
                        {customerDetail?.payAccount}
                      </td>
                      <th>구담당</th>
                      <td colSpan={2}>{customerDetail?.oldPartner}</td>
                    </tr>
                    <tr>
                      <th>예금주</th>
                      <td colSpan={3}>{customerDetail?.payName}</td>
                      {/* <td colSpan={2}>{customerDetail?.payName}</td> */}
                      <th>담당딜러</th>
                      <td colSpan={2}>{customerDetail?.partner}</td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td colSpan={3}>{customerDetail?.siteUrl}</td>
                      <th>업종</th>
                      <td colSpan={2}>
                        {getUp?.data?.map(el => {
                          if (el.key === customerDetail?.upteCode) return <span>{el.value}</span>;
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>다골프</th>
                      <td colSpan={3}>{customerDetail?.dagolfMember}</td>
                      <th>비고</th>
                      <td colSpan={2}>{customerDetail?.remark}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-bordered table-responsive my-table1 mt-40">
                  <tbody>
                    {customerDetail?.ownerList?.map(data => {
                      return (
                        <>
                          <tr>
                            <th style={{ width: "11.9%" }}>담당자</th>
                            <td style={{ width: "13.1%" }}>{data?.ownerName}</td>
                            <th style={{ width: "11.9%" }}>직위</th>
                            <td style={{ width: "13.1%" }}>{data?.ownerPosition}</td>
                            <th style={{ width: "11.9%" }}>고객과의 관계</th>
                            <td style={{ width: "13.1%" }}>{data?.ownerRelation}</td>
                            <th style={{ width: "11.9%" }}>휴대폰</th>
                            <td style={{}} colSpan={3}>
                              {data?.ownerCelTel}
                            </td>
                          </tr>
                          <tr>
                            <th>부서</th>
                            <td>{data?.ownerDevision}</td>
                            <th className="f-roboto-medium">E-MAIL</th>
                            <td>{data?.ownerEMail}</td>
                            <th>주민번호</th>
                            <td>{data?.ownerJuminno}</td>
                            <th>기타</th>
                            <td>{data?.ownerRemark}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>

                {/* 고객정보 직장주소 */}
                <div
                  className={`d-flex justify-content-between align-items-center ${
                    addressIsOpen[0] ? "mb-2" : "mb-4"
                  }`}
                  onClick={() => {
                    setAddressIsOpen(prev => [!prev[0], prev[1]]);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="table-tabs-sub mb-0">직장주소</div>
                  <img
                    className={`${addressIsOpen[0] && "active"}`}
                    src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                    alt=""
                  />
                </div>
                {addressIsOpen[0] && (
                  <div className="address-table mt-2 mb-2">
                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "11.9%" }}>도로명주소</th>
                          <td>
                            <div className="flex-center-between">
                              {customerDetail?.rcZipcode +
                                " " +
                                (customerDetail?.rcAddresTxt ? customerDetail?.rcAddresTxt : "") +
                                " " +
                                customerDetail?.rcAddres}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {/* 고객정보 자택주소 */}
                <div
                  className={`d-flex align-items-center justify-content-between ${
                    addressIsOpen[1] ? "mb-2" : "mb-4"
                  }`}
                  onClick={() => {
                    setAddressIsOpen(prev => [prev[0], !prev[1]]);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="table-tabs-sub mb-0">자택주소</div>
                  <img
                    className={`${addressIsOpen[1] && "active"}`}
                    src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                    alt=""
                  />
                </div>
                {addressIsOpen[1] && (
                  <div className="address-table mt-2 mb-2">
                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "11.9%" }}>도로명주소</th>
                          <td>
                            <div className="flex-center-between">
                              {customerDetail?.rhZipcode +
                                " " +
                                (customerDetail?.rhAddresTxt ? customerDetail?.rhAddresTxt : "") +
                                " " +
                                customerDetail?.rhAddres}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {/* <table className="table table-bordered table-responsive my-table1 mt-10px">
                <tbody>
                  <tr>
                    <th style={{ width: "6.9%" }}>회원증</th>
                    <th style={{ width: "4.9%" }}>성명</th>
                    <td style={{ width: "13%" }}></td>
                    <th style={{ width: "11.9%" }}>주민번호</th>
                    <td></td>
                    <th style={{ width: "11.9%" }}>주소</th>
                    <td style={{ width: "38.09%" }}></td>
                  </tr>
                </tbody>
              </table> */}
              </div>
            </div>
            {/* 통화 내역 */}
            <div className="card card-main">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">통화내역</h5>
              </div>
              <div className="card-body">
                <div
                  className={`table-container table-sticky
               h-183`}
                  id="scroll-table-body"
                >
                  <table className="table table-bordered table-responsive my-table m-0">
                    <thead>
                      <tr>
                        <th style={{ width: "7%" }}>연번</th>
                        <th>통화일자</th>
                        <th>통화자</th>
                        <th>VIEW</th>
                        <th>통화내용</th>
                      </tr>
                    </thead>
                    <tbody>
                      {callHistory.length > 0 ? (
                        callHistory.map((data, _idx) => {
                          return (
                            <tr>
                              <td>{_idx + 1}</td>
                              <td>{moment(data?.talkDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                              <td>{data?.talkName}</td>
                              <td>
                                {data?.viewChk === "1" && "일반"}
                                {data?.viewChk === "2" && "상위"}
                                {data?.viewChk === "R" && "녹취"}
                                {data?.viewChk === "N" && "부재"}
                              </td>
                              <td>{data?.remark}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>조회 결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
