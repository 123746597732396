import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 취득과표 회원권종목명 셀렉트박스
 */
export const useGetAcquisition = () => {
  return useQuery(
    ["getAcquisition"],
    async () =>
      await axios
        .get("/api/codeMng/select/getAcquisition")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
