import React, { useEffect, useState } from "react";
import Select from "react-select-virtualized";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

export default function CustomerSelect(props) {
  const { gubun = 1, onChangeCallBack } = props;

  const searchForSelect = useMutation(
    data => axios.get("/api/work/customer/searchForSelect", { params: data }),
    {
      onSuccess: res => {
        setOptions(prev => {
          return res.data.result.map(e => ({
            label: `${e.kname}/${e.company}/${e.position}`,
            value: e.seqNo,
            detail: e,
          }));
        });
      },
    }
  );
  const [target, setTarget] = useState({});
  const [options, setOptions] = useState([]);

  const switchGubun = () => {
    switch (gubun) {
      case "개인":
        return 1;
      case "법인":
        return 2;
      case "SHOP":
        return "3";
      default:
        return gubun;
    }
  };

  useEffect(() => {
    searchForSelect.mutate({ gubun: switchGubun(), keyword: "" });
  }, []);

  return (
    <>
      <Select
        className="select-virtual text-left"
        menuPlacement={"auto"}
        menuPosition={"fixed"}
        options={options}
        styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
        value={target}
        onChange={e => {
          setTarget(e);
          if (onChangeCallBack) onChangeCallBack(e);
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            const activeElement = document.activeElement;
            if (activeElement.tagName === "INPUT") {
              e.preventDefault();
              searchForSelect.mutate({ gubun: switchGubun(), keyword: e.target.value });
            }
          }
        }}
      />
    </>
  );
}
