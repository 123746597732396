import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment";

const Tab4 = ({ custId }) => {
  const [tableData, setTableData] = useState([]);
  const clientQuery = useQueryClient();
  const gosiDayList = clientQuery.getQueryData(["getGosiDayList"]);

  const bottomGetGain = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getGain", {
        params: { custId: data },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setTableData(result.map(i => i));
      },
    }
  );

  useEffect(() => {
    if (custId) bottomGetGain.mutate(custId);
  }, []);
  useEffect(() => {
    if (custId) bottomGetGain.mutate(custId);
  }, [custId]);

  const displayChangeOx = value => {
    switch (value) {
      case "01":
        return "○";
      case "02":
        return "△";
      case "03":
        return "×";
      case "04":
        return "▲";
      default:
        return "";
    }
  };

  return (
    <div className="tab2-content-container">
      <p>
        <ul>
          <li>
            전환ㅣ O : 개인 -&gt; 법인, △: 법인 -&gt; 개인, ▲: 개인 -&gt; 법인, X : 개인 -&gt;
            법인(X), 법대법 O
          </li>
        </ul>
      </p>
      <div className="table-container table-sticky mb-3 h-333">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>회원권명</th>
              <th>전환</th>
              <th>취득과표</th>
              <th>취득세</th>
              <th>고시일자</th>
              <th>기준시가</th>
              <th>고시일자</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((item, index) => (
                <tr key={index}>
                  <td>{item.codename}</td>
                  <td>{displayChangeOx(item.changeOx)}</td>
                  <td className="text-end">{item.chpWon?.toLocaleString("en-US")}</td>
                  <td className="text-end">
                    {Math.round(item.chpWon * 0.022)?.toLocaleString("en-US")}
                  </td>
                  <td>{item.chpDate && moment(item.chpDate).format("YY/MM/DD")}</td>
                  <td className="text-end">{item.price?.toLocaleString("en-US")}</td>
                  <td>
                    {item.gubunKey &&
                      moment(
                        gosiDayList?.find(k => k.key === item.gubunKey)?.value,
                        "YYYY년 MM월 DD일"
                      ).format("YY/MM/DD")}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  검색된 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(Tab4);
