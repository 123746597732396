import React from "react";
import { useAtom } from "jotai";
import { payback_atom } from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import { useGetBank } from "../../../../api/code/useGetBank";

export default function Payback() {
  const [payback, setPayback] = useAtom(payback_atom);

  const getBank = useGetBank();

  const onChangeEventHandler = (key, value) => {
    setPayback({ ...payback, [key]: value });
  };

  return (
    <div className="card card-main w-50 h-100">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title  m-0">환급계좌</h5>
      </div>

      <div className="card-body">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "35%" }}>은행명</th>
              <td>
                <select
                  className="form-select"
                  value={payback.bankName}
                  onChange={e => onChangeEventHandler("bankName", e.target.value)}
                >
                  {getBank.data?.map(el => (
                    <option value={el.key}>{el.value}</option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>계좌번호</th>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={payback.account}
                  onChange={e => onChangeEventHandler("account", e.target.value)}
                />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
}
