import { atom } from "jotai";

export const approvalLineManage_selectMember = atom("");
export const approvalLineManage_certApprovalLineList = atom([]);
export const approvalLineManage_annualApprovalLineList = atom([]);
export const approvalLineManage_paymentApprovalLineList = atom([]);

export const approvalLineManage_selectMember1 = atom("");
export const approvalLineManage_certApprovalLineList1 = atom([]);
export const approvalLineManage_annualApprovalLineList1 = atom([]);
export const approvalLineManage_paymentApprovalLineList1 = atom([]);
