import React, { useEffect, useState } from "react";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { axios } from "../../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useGetDegree } from "../../../../../api/code/useGetDegree";
import { useGetGrade } from "../../../../../api/code/useGetGrade";
import { useAtom } from "jotai";
import {
  PersonSchoolTab_addData,
  PersonSchoolTab_backupData,
  PersonSchoolTab_createMode,
  PersonSchoolTab_Data,
  PersonSchoolTab_modifyData,
  PersonSchoolTab_sawonId,
} from "../../../../../data/atom/personManage/personMasterManage/PersonSchoolTab_atom";

export default function PersonSchoolTab({ sawonId, userId, mutate_personSearchDetail }) {
  const initialModifyData = {
    degreeGubun: "11",
    eduName: "",
    enterDate: moment(new Date()),
    gradeDate: moment(new Date()),
    gradeGubun: "11",
    kindName: "",
    modDate: moment(new Date()),
    modId: "",
    sawonId: sawonId,
    seqNo: 0,
    specName: "",
    userid: userId,
  };
  const defaultUrl = "";
  const [data, setData] = useAtom(PersonSchoolTab_Data);
  const [editMode, setEditMode] = useState(true);
  const [createMode, setCreateMode] = useAtom(PersonSchoolTab_createMode);
  const [modifyData, setModifyData] = useAtom(PersonSchoolTab_modifyData);
  const [backupData, setBackupData] = useAtom(PersonSchoolTab_backupData);
  const [addData, setAddData] = useAtom(PersonSchoolTab_addData);
  const [saveSawonId, setSaveSawonId] = useAtom(PersonSchoolTab_sawonId);
  const getDegree = useGetDegree();
  const getGrade = useGetGrade();

  // API - 학력사항 조회
  const { mutate: mutate_personSchool, isLoading: getLoading } = useMutation(
    data =>
      axios.get(defaultUrl + "/api/emp/empMasterMng/bottom/getSchool", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        const data = result.map(item => {
          return {
            ...item,
            enterDate: item?.enterDate ? moment(item?.enterDate).format("YYYY-MM-DD") : "",
            gradeDate: item?.gradeDate ? moment(item?.gradeDate).format("YYYY-MM-DD") : "",
          };
        });

        setData(data);
        setBackupData(data);
        setModifyData([]);
        setCreateMode(false);
        setAddData(null);
      },
    }
  );

  // API - 학력사항 생성/수정
  const { mutate: mutate_personSchoolEdit, isLoading: modifyLoading } = useMutation(
    data => axios.post(defaultUrl + "/api/emp/empMasterMng/bottom/saveSchool", data),
    {
      onSuccess: res => {
        mutate_personSchool(sawonId);
        setModifyData([]);
        setAddData(null);
        setCreateMode(false);
        mutate_personSearchDetail(sawonId);
      },
    }
  );

  // API - 학력사항 삭제
  const { mutate: mutate_personSchoolDelete } = useMutation(
    data => axios.post(defaultUrl + "/api/emp/empMasterMng/bottom/deleteSchool", data),
    {
      onSuccess: res => {
        mutate_personSchool(sawonId);
        mutate_personSearchDetail(sawonId);
      },
    }
  );

  useEffect(() => {
    setSaveSawonId(sawonId);
    if (saveSawonId !== sawonId) {
      mutate_personSchool(sawonId);
    }
  }, []);

  // 값 변경 handler
  const changeHandler = (e, targetData) => {
    const { name, value } = e.target;

    // 보여지는 기존 데이터 값 바꾸기
    const newDataList = data.map(item => {
      if (item.seqNo === targetData.seqNo) {
        return { ...item, [name]: value };
      } else {
        return { ...item };
      }
    });
    setData(newDataList);
    //  modify data
    const isData = element => {
      return element.seqNo === targetData.seqNo;
    };

    const idx = modifyData?.findIndex(isData);
    if (idx === -1) {
      setModifyData([...modifyData, { ...targetData, [name]: value }]);
    } else {
      const newModData = modifyData.map(item => {
        if (item.seqNo === targetData.seqNo) {
          return { ...item, [name]: value };
        } else {
          return { ...item };
        }
      });
      setModifyData(newModData);
    }
  };

  return (
    <>
      <div className="px-20px py-15px">
        <div className={`table-container table-sticky h-183`}>
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>연번</th>
                <th style={{ width: "8%" }}>입학일자</th>
                <th style={{ width: "8%" }}>졸업일자</th>
                <th style={{ width: "8%" }}>학교명</th>
                <th style={{ width: "8%" }}>계열명</th>
                <th style={{ width: "8%" }}>전공명</th>
                <th style={{ width: "8%" }}>졸업구분</th>
                <th style={{ width: "8%" }}>학위구분</th>
                {decrypt("userInfo").userPermit === "99" && <th style={{ width: "3%" }}>삭제</th>}
              </tr>
            </thead>
            <tbody>
              {createMode && (
                <tr>
                  <td></td>
                  <td>
                    <input
                      type="date"
                      max="9999-12-31"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, enterDate: e.target.value });
                      }}
                      value={addData?.enterDate}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      max="9999-12-31"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, gradeDate: e.target.value });
                      }}
                      value={addData?.gradeDate}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, eduName: e.target.value });
                      }}
                      value={addData?.eduName}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, kindName: e.target.value });
                      }}
                      value={addData?.kindName}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, specName: e.target.value });
                      }}
                      value={addData?.specName}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, gradeGubun: e.target.value })}
                      value={addData?.gradeGubun}
                    >
                      {getGrade?.data?.map(data => {
                        return <option value={data.key}>{data.value}</option>;
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-select"
                      onChange={e => setAddData({ ...addData, degreeGubun: e.target.value })}
                      value={addData?.degreeGubun}
                    >
                      {/*{getDegree?.data?.map(data => {*/}
                      {/*  return <option value={data.key}>{data.value}</option>;*/}
                      {/*})}*/}
                      <option value="22">준학사</option>
                      <option value="11">학사</option>
                      <option value="33">석사</option>
                      <option value="44">박사</option>
                      <option value="99"></option>
                    </select>
                  </td>
                  {decrypt("userInfo").userPermit === "99" && <td></td>}
                </tr>
              )}
              {data.length > 0
                ? data.map((schoolData, index) => (
                    <tr onClick={() => {}}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          name="enterDate"
                          onChange={e => {
                            changeHandler(e, schoolData);
                          }}
                          value={schoolData.enterDate}
                        />
                      </td>

                      <td>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          name="gradeDate"
                          onChange={e => {
                            changeHandler(e, schoolData);
                          }}
                          value={schoolData.gradeDate}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          name="eduName"
                          onChange={e => {
                            changeHandler(e, schoolData);
                          }}
                          value={schoolData?.eduName}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          name="kindName"
                          onChange={e => {
                            changeHandler(e, schoolData);
                          }}
                          value={schoolData?.kindName}
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          name="specName"
                          onChange={e => {
                            changeHandler(e, schoolData);
                          }}
                          value={schoolData?.specName}
                        />
                      </td>

                      <td>
                        <select
                          className="form-select"
                          value={schoolData?.gradeGubun}
                          name="gradeGubun"
                          onChange={e => {
                            changeHandler(e, schoolData);
                          }}
                        >
                          {getGrade?.data?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      </td>

                      <td>
                        <select
                          className="form-select"
                          value={schoolData?.degreeGubun}
                          name="degreeGubun"
                          onChange={e => {
                            changeHandler(e, schoolData);
                          }}
                        >
                          {/*{getDegree?.data?.map(data => {*/}
                          {/*  return <option value={data.key}>{data.value}</option>;*/}
                          {/*})}*/}
                          <option value="22">준학사</option>
                          <option value="11">학사</option>
                          <option value="33">석사</option>
                          <option value="44">박사</option>
                          <option value="99"></option>
                        </select>
                      </td>
                      {decrypt("userInfo").userPermit === "99" && (
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            const result = window.confirm("해당 기록을 삭제하시겠습니까?");
                            if (result) {
                              mutate_personSchoolDelete({
                                sawonId: sawonId,
                                seqNo: schoolData.seqNo,
                              });
                            }
                          }}
                        >
                          <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                        </td>
                      )}
                    </tr>
                  ))
                : !createMode && (
                    <tr onClick={() => {}}>
                      <td colSpan={decrypt("userInfo").userPermit === "99" ? 10 : 9}>
                        조회된 데이터가 없습니다.
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-10px">
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              setCreateMode(!createMode);
              setEditMode(!editMode);
              // setEditSeqNo("");
              setAddData(initialModifyData);
            }}
          >
            추가
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              const lastModeData = modifyData.map(item => {
                return {
                  ...item,
                  enterDate:
                    item?.enterDate === "" ? null : moment(item?.enterDate).format("YYYY-MM-DD"),
                  gradeDate:
                    item?.gradeDate === "" ? null : moment(item?.gradeDate).format("YYYY-MM-DD"),
                };
              });
              if (addData) {
                mutate_personSchoolEdit([
                  ...lastModeData,
                  {
                    ...addData,
                    enterDate:
                      addData?.enterDate === ""
                        ? null
                        : moment(addData?.enterDate).format("YYYY-MM-DD"),
                    gradeDate:
                      addData?.gradeDate === ""
                        ? null
                        : moment(addData?.gradeDate).format("YYYY-MM-DD"),
                  },
                ]);
              } else {
                if (lastModeData.length > 0) {
                  mutate_personSchoolEdit(lastModeData);
                }
              }
              // setCreateMode(false);
            }}
          >
            저장
          </button>
          <button
            onClick={() => {
              setAddData(null);
              setModifyData([]);
              setData(backupData);
              setCreateMode(false);
              alert("변경 사항이 취소되었습니다.");
            }}
            className="btn btn-outline-primary tab1-row1-btn"
          >
            취소
          </button>
        </div>
      </div>
    </>
  );
}
