import { useCallback } from "react";
import { useAtom } from "jotai";
import { recentTabEvent_atom } from "../../data/atom/recentTap_atom";
import { queueTab_atom } from "../../data/atom/queueTab_atom";
import { useSetAtom } from "jotai/index";

/**
 * onTabExit : 다른 탭으로 이동하며 나갈 때
 * onTabRestored : 탭으로 돌아올 때
 * onTabClosed : 탭이 닫힐 때
 */
const useRecentTabEvent = () => {
  const [recentTabEvent, setRecentTabEvent] = useAtom(recentTabEvent_atom);

  const addTabExitEvent = useCallback((key, func) => {
    key = trimPublicUrl(key);
    setRecentTabEvent(prev => {
      if (prev[key]) return { ...prev, [key]: { ...prev[key], onTabExit: func } };
      else return { ...prev, [key]: { onTabExit: func } };
    });
  }, []);

  const addTabRestoredEvent = useCallback((key, func) => {
    key = trimPublicUrl(key);
    setRecentTabEvent(prev => {
      if (prev[key]) return { ...prev, [key]: { ...prev[key], onTabRestored: func } };
      else return { ...prev, [key]: { onTabRestored: func } };
    });
  }, []);

  const addTabClosedEvent = useCallback((key, func) => {
    key = trimPublicUrl(key);
    setRecentTabEvent(prev => {
      if (prev[key]) return { ...prev, [key]: { ...prev[key], onTabClosed: func } };
      else return { ...prev, [key]: { onTabClosed: func } };
    });
  }, []);

  const removeTabEvent = useCallback(key => {
    key = trimPublicUrl(key);
    setRecentTabEvent(prev => {
      let tmp = {};
      for (let forkey in prev) {
        if (forkey === key) continue;
        else tmp[forkey] = prev[forkey];
      }
      return tmp;
    });
  }, []);

  const trimPublicUrl = useCallback(url => {
    if (url.startsWith(process.env.PUBLIC_URL)) url = url.replace(process.env.PUBLIC_URL, "");
    return url;
  }, []);

  const clearTabEvent = useCallback(() => setRecentTabEvent({}), []);
  return {
    recentTabEvent,
    addTabRestoredEvent,
    addTabClosedEvent,
    addTabExitEvent,
    removeTabEvent,
    clearTabEvent,
  };
};

export default useRecentTabEvent;
