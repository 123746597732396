import React, { useRef } from "react";
import SearchResultRow from "./searchResultRow";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtomValue } from "jotai";
import { searchResult_atom } from "../../../../../data/atom/workManage/departmentManage/databaseDistributionStatus/newDbStatus";

const SearchResultTable = () => {
  const searchResult = useAtomValue(searchResult_atom);
  const targetRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: searchResult.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub m-0" style={{
      padding: '0 20px 10px 20px'
    }}>
      <div
        style={{ maxHeight: "648px", overflowY: "auto", marginTop: "10px" }}
        ref={targetRef}
        className={"table-container table-sticky"}
      >
        <table
          className="table table-bordered table-responsive my-table"
          style={{
            marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
            marginBottom: `${rowVirtualizer.getTotalSize() -
              (rowVirtualizer.range?.startIndex ?? 0) * 30 -
              rowVirtualizer.getVirtualItems().length * 30
              }px`,
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "2%" }}>NO.</th>
              <th style={{ width: "5%" }}>지사구분</th>
              <th style={{ width: "5%" }}>부서명</th>
              <th style={{ width: "5%" }}>입력자</th>
              <th style={{ width: "5%" }}>관리번호</th>
              <th style={{ width: "5%" }}>회사명</th>
              <th style={{ width: "5%" }}>부서명</th>
              <th style={{ width: "5%" }}>성명</th>
              <th style={{ width: "5%" }}>직위명</th>
              <th style={{ width: "5%" }}>회사전화</th>
              <th style={{ width: "5%" }}>자택전화</th>
              <th style={{ width: "5%" }}>휴대폰</th>
              <th style={{ width: "5%" }}>DB출처</th>
              <th style={{ width: "5%" }}>등록일자</th>
            </tr>
          </thead>
          <tbody>
            {searchResult.length > 0 ? (
              rowVirtualizer
                .getVirtualItems()
                .map((item, index) => (
                  <SearchResultRow
                    key={item.key}
                    data={searchResult[item.index]}
                    index={item.index}
                    virtualIndex={index}
                  />
                ))
            ) : (
              <tr>
                <td colSpan={14} className="text-center">
                  검색된 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SearchResultTable;
