import React from "react";
import { useSetAtom } from "jotai/index";
import { carDetail_atom } from "../../../../../data/atom/dataManage/carManage/fuelInput_atom";
import moment from "moment/moment";

const FuelInputListRow = props => {
  const { data, index, drveUserList, acctList, fuelList } = props;
  const setCarDetail = useSetAtom(carDetail_atom);

  const clickRow = (el, idx) => {
    setCarDetail({ ...el, selectIdx: idx, litrCost: toFloat(el.litrCost) });
  };

  const toFloat = num => {
    let [whole, decimal] = parseFloat(num).toFixed(1).split(".");
    return Number(whole).toLocaleString() + "." + decimal;
  };

  return (
    <tr className="cursor-pointer" onClick={() => clickRow(data, index)}>
      <td>{index + 1}</td>
      <td>{data.carrNumb}</td>
      <td>{moment(data.juyuDate).format("YYYY-MM-DD HH:mm")}</td>
      <td>{data.juyuUser ? drveUserList.find(el => el.value === data.juyuUser)?.label : ""}</td>
      <td className="text-end">{data.runnMile?.toLocaleString()}</td>
      <td>{data.engrGubn ? fuelList.find(el => el.value === data.engrGubn)?.label : ""}</td>
      <td className="text-end">{toFloat(data.litrCost ?? "0")}</td>
      <td className="text-end">{toFloat(data.juyuLitr ?? "0")}</td>
      <td className="text-start">{data.juyuPlac}</td>
      <td>{data.acctCode ? acctList.find(el => el.value === data.acctCode)?.label : ""}</td>
      <td>{data.crdtNumb?.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/g, "$1-$2-$3-$4")}</td>
      <td>{data.remark}</td>
    </tr>
  );
};

export default React.memo(FuelInputListRow);
