import moment from "moment";
import React from "react";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import { useGetPartner } from "../../../../api/code/useGetPartner";
import { useAtom } from "jotai";
import {
  currentRow_atom,
  transferTaxList_atom,
} from "../../../../data/atom/workManage/transferTax/transferTax_atom";

export default function TransferTaxListRow(props) {
  const { el, idx, rowClick } = props;
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [transferTaxList, setTransferTaxList] = useAtom(transferTaxList_atom);

  const getUserIdQ = useGetUserIdQ();
  const getPartner = useGetPartner();

  const onChangeEventHandler = (key, value, idx) => {
    setTransferTaxList(prev => {
      return prev.map((el, idx2) => {
        if (idx === idx2) {
          return { ...el, [key]: value };
        } else return el;
      });
    });
  };

  return (
    <tr
      className={`cursor-pointer ${
        currentRow?.custId === el.custId &&
        currentRow?.seqNo === el.seqNo &&
        currentRow?.ilNo === el.ilNo
          ? "bg-blue"
          : ""
      }`}
      onClick={() => rowClick(el)}
    >
      <td>{idx + 1}</td>
      <td>{el.singoWanryo}</td>
      <td>{el.jisaGerae === "Y" ? "타지사" : el.jisaGerae === "S" ? "샵거래" : ""}</td>
      <td>{el.gubun1 === "1" ? "양도" : "양수"}</td>
      <td>{moment(el.custDate).format("YYYY/MM/DD")}</td>
      <td>
        {el.tradGubn === "3"
          ? "개인물건"
          : el.tradGubn === "4"
          ? "법인물건"
          : el.tradGubn === "8"
          ? "타샵물건"
          : ""}
      </td>
      <td>{el.viewCustId.replace(/^(\d{4})(\d{4})$/g, "$1-$2")}</td>
      <td>{el.kname}</td>
      <td className="text-start">{el.codename}</td>
      <td>
        {el.saleGu === "1"
          ? "실거래"
          : el.saleGu === "2"
          ? "분양"
          : el.saleGu === "3"
          ? "증여"
          : el.saleGu === "4"
          ? "상속"
          : el.saleGu === "5"
          ? "명의변경"
          : ""}
      </td>
      <td className="text-end">{el.yangdoWon2.toLocaleString()}</td>
      <td>{el.seqNo.replace(/^(\w{6})(\w{3})$/g, "$1-$2")}</td>
      <td>
        <select
          className="form-select"
          value={el.taxTarget}
          onChange={e => onChangeEventHandler("taxTarget", e.target.value, idx)}
          onClick={e => e.stopPropagation()}
        >
          <option value="N">비대상</option>
          <option value="Y">대상</option>
          <option value="S">완료</option>
          <option value="R">관청접수</option>
          <option value="" hidden={true}></option>
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={el.yangdoTaxTarget}
          onChange={e => onChangeEventHandler("yangdoTaxTarget", e.target.value, idx)}
          onClick={e => e.stopPropagation()}
        >
          <option value="N">해당없음</option>
          <option value="Y">신고대상</option>
          <option value="S">신고완료</option>
          <option value="R">발송대기</option>
          <option value="" hidden={true}></option>
        </select>
      </td>
      <td>{el.sinDate ? moment(el.sinDate).format("YYYY/MM/DD") : ""}</td>
      <td>{el.partner ? getUserIdQ.data?.find(el2 => el2.key === el.partner)?.value : ""}</td>
      <td>{el.jisa ? getPartner.data?.find(el2 => el2.jisa === el.jisa)?.jisaName : ""}</td>
      <td>{el.differCheck === "Y" ? "Y" : ""}</td>
      <td className="text-end">{el.taxWon.toLocaleString()}</td>
    </tr>
  );
}
