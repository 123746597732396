import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment";
import Modal from "../../../../../components/modal/Modal";
import { decrypt } from "../../../../../util/decrypt/decrypt";

const Tab1 = ({ custId, reg, setReg }) => {
  const [tableData, setTableData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const clientQuery = useQueryClient();
  const userIds = clientQuery.getQueryData(["getUserIdQ"]);
  const bottomGetIssue = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getIssue", {
        params: { custId: data },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setTableData(result);
      },
    }
  );

  const bottomSaveIssue = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/saveIssue", data),
    {
      onSuccess: res => {
        bottomGetIssue.mutate(custId);
        setModalShow(false);
      },
    }
  );

  const bottomDeleteIssue = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/deleteIssue", data),
    {
      onSuccess: res => {
        bottomGetIssue.mutate(custId);
      },
    }
  );

  useEffect(() => {
    if (custId) bottomGetIssue.mutate(custId);
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetIssue.mutate(custId);
  }, []);

  useEffect(() => {
    if (reg) {
      setReg(false);
      if (custId)
        btn_onClickDetail({
          partner: decrypt("userInfo").loginId,
          modDate: "",
          viewChk: "1",
          remark: "",
          detail: "",
          custId: custId,
        });
    }
  }, [reg]);

  const btn_onClickDetail = data => {
    setModalShow(true);
    setModalData(data);
  };

  const btn_ModalSave = () => {
    bottomSaveIssue.mutate(modalData);
  };
  const btn_ModalDelete = data => {
    if (confirm("정말 삭제 하시겠습니까?")) bottomDeleteIssue.mutate(data);
    else alert("취소 되었습니다.");
  };

  const onChangeModalData = (key, value) => {
    setModalData({ ...modalData, [key]: value });
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={"특이사항"}
        footer={
          <>
            <button className="btn btn-outline-primary" onClick={() => setModalShow(false)}>
              취소
            </button>
            <button className="btn btn-primary" onClick={btn_ModalSave}>
              저장
            </button>
          </>
        }
      >
        <div>
          <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
            <tbody>
              <tr>
                <th style={{ width: "15%" }}>작성자</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={
                      modalData?.partner && userIds?.find(i => modalData.partner === i.key)?.value
                    }
                    readOnly={true}
                  />
                </td>
                <th style={{ width: "15%" }}>수정일시</th>
                <td>
                  <input
                    type="date"
                    className="form-control table-input"
                    value={modalData?.modDate && moment(modalData.modDate).format("YYYY-MM-DD")}
                    readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <th>구분</th>
                <td>
                  <select
                    className="form-select"
                    value={modalData?.viewChk && modalData.viewChk}
                    onChange={e => onChangeModalData("viewChk", e.target.value)}
                  >
                    <option value={"1"}>일반</option>
                    <option value={"2"}>필독</option>
                  </select>
                </td>
                <th>단문</th>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={modalData?.remark && modalData.remark}
                    onChange={e => onChangeModalData("remark", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="p-3">
                  <textarea
                    className="form-control"
                    rows={5}
                    onChange={e => onChangeModalData("detail", e.target.value)}
                  >
                    {modalData?.detail && modalData.detail}
                  </textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="tab2-content-container">
        <div className="table-container table-sticky mb-3 h-333">
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>No.</th>
                <th style={{ width: "45%" }}>내용</th>
                <th>구분</th>
                <th>작성자</th>
                <th style={{ width: "10%" }}>등록일</th>
                <th style={{ width: "10%" }}>수정일</th>
                <th style={{ width: "5%" }}>상세</th>
                <th style={{ width: "5%" }}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((item, index) => (
                  <tr className={item.viewChk !== "1" ? "bg-green" : ""} key={index}>
                    <td>{index + 1}</td>
                    <td>{item.remark}</td>
                    <td>{item.viewChk === "1" ? "일반" : "필독"}</td>
                    <td>{userIds?.find(i => item.partner === i.key)?.value}</td>
                    <td>{moment(item.inDate).format("YYYY/MM/DD")}</td>
                    <td>{moment(item.modDate).format("YYYY/MM/DD")}</td>
                    <td>
                      <a href={void 0}>
                        <img
                          src={process.env.PUBLIC_URL + "/images/icon-view-btn.png"}
                          alt=""
                          onClick={() => btn_onClickDetail(item)}
                        />
                      </a>
                    </td>
                    <td>
                      <a href={void 0}>
                        <img
                          src={process.env.PUBLIC_URL + "/images/icon-trash.png"}
                          alt=""
                          onClick={() => btn_ModalDelete(item)}
                        />
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    검색된 결과가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(Tab1);
