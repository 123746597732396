import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { tabStayData_atom } from "../../../../../data/atom/workManage/golfManage/golfManage_atom";
import moment from "moment/moment";
import { useAtom } from "jotai";
import { decrypt } from "../../../../../util/decrypt/decrypt";

const Tab2 = ({ custId }) => {
  const [tabData, setTabData] = useAtom(tabStayData_atom);

  const bottomGetCallHistory = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getCallHistory", {
        params: { custId: data.custId },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;

        setTabData(prev => {
          let tmp = { ...prev };
          tmp.tab2.tableData = result.map(i => i);

          if (param.type === "cancel") {
            tmp.tab2.addRow = [];
          }

          return tmp;
        });

        if (param.type === "cancel") {
          alert("취소되었습니다.");
        }
      },
    }
  );

  const bottomSaveCallHistory = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/saveCallHistory", data),
    {
      onSuccess: res => {
        setTabData(prev => ({
          ...prev,
          tab2: { editedData: {}, addRow: [], tableData: prev.tab2.tableData },
        }));
        bottomGetCallHistory.mutate({ custId: custId });
      },
    }
  );

  const bottomDeleteCallHistory = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/deleteCallHistory", data),
    {
      onSuccess: (res, param) => {
        setTabData(prev => {
          prev.tab2.editedData = { ...prev.tab2.editedData };
          delete prev.tab2.editedData[
            `${param.custId}|${moment(param.talkDate).format("YYYY-MM-DDHH:mm:ss")}|${
              param.talkName
            }|${param.ilNo}`
          ];
          return { ...prev };
        });
        bottomGetCallHistory.mutate({ custId: custId });
      },
    }
  );

  useEffect(() => {
    if (custId) bottomGetCallHistory.mutate({ custId: custId });
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetCallHistory.mutate({ custId: custId });
  }, []);

  const onChangeTable = (index, key, value) => {
    let data = { ...tabData.tab2.tableData[index], [key]: value };
    setTabData(tabData => {
      let editKey = `${data.custId}|${moment(data.talkDate).format("YYYY-MM-DDHH:mm:ss")}|${
        data.talkName
      }|${data.ilNo}`;
      let find = tabData.tab2.editedData[editKey];
      if (find) find[key] = value;
      else find = data;

      tabData.tab2.editedData = { ...tabData.tab2.editedData, [editKey]: find };

      return tabData;
    });
    let tmp = { ...tabData };
    tmp.tab2.tableData = tmp.tab2.tableData.map((i, idx) =>
      idx === index ? { ...data } : { ...i }
    );
    setTabData(tmp);
  };

  const btn_delete = data => {
    if (confirm("정말 삭제하시겠습니까?"))
      bottomDeleteCallHistory.mutate({
        ...data,
        talkDate: moment(data.talkDate).format("YYYY-MM-DD HH:mm:ss"),
      });
    else alert("취소되었습니다.");
  };

  const btn_add = () => {
    setTabData(prev => {
      return {
        ...prev,
        tab2: {
          ...prev.tab2,
          addRow: prev.tab2.addRow.concat({
            talkName: decrypt("userInfo").userName,
            talkDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            viewChk: "1",
          }),
        },
      };
    });
  };
  const btn_save = () => {
    let sum = [...tabData.tab2.addRow];
    for (let tmp in tabData.tab2.editedData) sum.push(tabData.tab2.editedData[tmp]);

    bottomSaveCallHistory.mutate(
      sum.map(i => ({
        ...i,
        talkDate: moment(i.talkDate).format("YYYY-MM-DD HH:mm:ss"),
        custId: custId,
      }))
    );
  };

  const btn_cancel = () => {
    bottomGetCallHistory.mutate({ custId: custId, type: "cancel" });
  };

  const onChangeAdd = (index, key, value) => {
    setTabData(prev => {
      prev.tab2.addRow = prev.tab2.addRow.map((item, idx) =>
        index === idx ? { ...item, [key]: value } : item
      );
      return { ...prev };
    });
  };

  const onDeleteAddRow = index => {
    if (confirm("정말 삭제하시겠습니까?"))
      setTabData(prev => {
        let tmp = prev;
        tmp.tab2.addRow = prev.tab2.addRow.filter((item, idx) => index !== idx);

        return { ...tmp };
      });
    else alert("취소되었습니다.");
  };

  return (
    <div className="tab2-content-container">
      <div className="table-container table-sticky mb-3 h-333">
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>연번</th>
              <th style={{ width: "15%" }}>통화일자</th>
              <th style={{ width: "15%" }}>통화자</th>
              <th style={{ width: "15%" }}>View</th>
              <th style={{ width: "40%" }}>통화 내용</th>
              <th style={{ width: "5%" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {tabData.tab2.addRow.map((item, index) => (
              <tr>
                <td></td>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={item.talkDate}
                    readOnly={true}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={item.talkName}
                    readOnly={true}
                  />
                </td>
                <td>
                  <select
                    className="form-select"
                    value={item.viewChk}
                    onChange={e => onChangeAdd(index, "viewChk", e.target.value)}
                  >
                    <option value={"1"}>일반</option>
                    <option value={"2"}>상위</option>
                    <option value={"0"}>삭제</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={item.remark}
                    onChange={e => onChangeAdd(index, "remark", e.target.value)}
                  />
                </td>
                <td>
                  <a href={void 0}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon-trash.png"}
                      alt=""
                      onClick={() => onDeleteAddRow(index)}
                    />
                  </a>
                </td>
              </tr>
            ))}
            {tabData.tab2.tableData.length > 0 ? (
              tabData.tab2.tableData.map((item, index) => (
                <tr
                  key={index}
                  className={
                    item.viewChk === "0" ? "bg-red" : item.viewChk === "2" ? "bg-green" : ""
                  }
                >
                  <td>{index + 1}</td>
                  <td>{moment(item.talkDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                  <td>{item.talkName}</td>
                  <td>
                    <select
                      className="form-select"
                      value={item.viewChk}
                      onChange={e => onChangeTable(index, "viewChk", e.target.value)}
                      disabled={
                        moment(item.talkDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
                      }
                    >
                      <option value={"1"}>일반</option>
                      <option value={"2"}>상위</option>
                      <option value={"0"}>삭제</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={item.remark && item.remark}
                      disabled={
                        moment(item.talkDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
                      }
                      onChange={e => onChangeTable(index, "remark", e.target.value)}
                    />
                  </td>
                  <td>
                    <a href={void 0} onClick={() => btn_delete(item)}>
                      <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  검색된 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-3">
        <button className="btn btn-outline-primary" onClick={btn_add}>
          추가
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btn_save}>
          저장
        </button>
        <button className="btn btn-outline-primary ms-8px" onClick={btn_cancel}>
          취소
        </button>
      </div>
    </div>
  );
};

export default React.memo(Tab2);
