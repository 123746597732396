import React, { useEffect, useRef, useState } from "react";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai";
import moment from "moment/moment";
import {
  acquList_atom,
  conditionSelect_atom,
  conditionSelectInit,
  currentRow_atom,
  governCurrentRow_atom,
  governGolfKeyword_atom,
  governGolfList_atom,
  medoMesu_atom,
  medoMesuCost_atom,
  medoSecondInput_atom,
  modalCurrentGolf_atom,
  modalGolfData_atom,
  modalResult_atom,
  modalShow_atom,
  modalShowInit,
  modalTax_atom,
  modalTaxList_atom,
  resultInit,
  searchedParam_atom,
  searchParam_atom,
  searchParamInit,
  tableLimit_atom,
  taxInit,
} from "../../../data/atom/workManage/acquTax/acquTax_atom";
import useInfiniteScrollWrapper from "../../../util/infiniteScroll/useInfiniteScrollWrapper";
import InputFilter from "./component/InputFilter";
import AcquListRow from "./component/AcquListRow";
import ConditionSelect from "./section/conditionSelect";
import InputSection from "./section/inputSection";
import GovernSearch from "./section/governSearch";
import AcquListRowHeader from "./component/AcquListRowHeader";
import { useGetJisaGubun } from "../../../api/code/useGetJisaGubun";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";
import { useMutation } from "@tanstack/react-query";
import { useGetGolfCustList } from "../../../api/code/useGetGolfCustList";
import { useGetChangeOx } from "../../../api/code/useGetChangeOx";
import { useGetEtcGzQ } from "../../../api/code/useGetEtcGzQ";
import { useGetEtcSmQ } from "../../../api/code/useGetEtcSmQ";
import { useGetEtcCmQ } from "../../../api/code/useGetEtcCmQ";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { decrypt } from "../../../util/decrypt/decrypt";

export default function AcquTax() {
  const tableRef = useRef();
  const [tableLimit, setTableLimit] = useAtom(tableLimit_atom);
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [searchedParam, setSearchedParam] = useAtom(searchedParam_atom);
  const [acquList, setAcquList] = useAtom(acquList_atom);
  const [conditionSelect, setConditionSelect] = useAtom(conditionSelect_atom);

  const [medoMesu, setMedoMesu] = useAtom(medoMesu_atom);
  const [medoMesuCost, setMedoMesuCost] = useAtom(medoMesuCost_atom);
  const [medoSecondInput, setMedoSecondInput] = useAtom(medoSecondInput_atom);

  const [governGolfKeyword, setGovernGolfKeyword] = useAtom(governGolfKeyword_atom);
  const [governGolfList, setGovernGolfList] = useAtom(governGolfList_atom);
  const [governCurrentRow, setGovernCurrentRow] = useAtom(governCurrentRow_atom);

  const [modalShow, setModalShow] = useAtom(modalShow_atom);
  const [taxList, setTaxList] = useAtom(modalTaxList_atom);
  const [tax, setTax] = useAtom(modalTax_atom);
  const [result, setResult] = useAtom(modalResult_atom);
  const [golfData, setGolfData] = useAtom(modalGolfData_atom);
  const [currentGolf, setCurrentGolf] = useAtom(modalCurrentGolf_atom);

  const [editedRowList, setEditedRowList] = useState([]);
  const { addTabClosedEvent } = useRecentTabEvent();

  const getGolfCustList = useGetGolfCustList();
  const getJisaGubun = useGetJisaGubun();
  const getUserIdQ = useGetUserIdQ();
  const getChangeOx = useGetChangeOx();
  const getEtcGzQ = useGetEtcGzQ();
  const getEtcSmQ = useGetEtcSmQ();
  const getEtcCmQ = useGetEtcCmQ();
  const searchAcquList = async data => {
    let res = await axios.get("/api/work/acquisitionTax/search", { params: data });

    return [...res.data.result].map((el, idx) => {
      return {
        ...el,
        idx: idx,
        custDate: el.custDate ? moment(el.custDate).format("YYYY-MM-DD") : "",
        sinDate: el.sinDate ? moment(el.sinDate).format("YYYY-MM-DD") : "",
        jiroSendDate: el.jiroSendDate ? moment(el.jiroSendDate).format("YYYY-MM-DD") : "",
        modDate: el.modDate ? moment(el.modDate).format("YYYY-MM-DD") : "",
        opposite: {
          ...el.opposite,
          custDate: el.opposite.custDate ? moment(el.opposite.custDate).format("YYYY-MM-DD") : null,
        },
      };
    });
  };

  const saveAcquList = useMutation(data => axios.post("/api/work/acquisitionTax/save", data), {
    onSuccess: res => {
      refetchFromFirstPage();
    },
  });

  const {
    endRef: scrollRef,
    refetchFromFirstPage,
    afterFetch,
  } = useInfiniteScrollWrapper({
    fetch: searchAcquList,
    fetchKey: ["acquisitionTaxSearch"],
    searchParam: searchParam,
    resultSetter: setAcquList,
  });

  const rowVirtualizer = useVirtualizer({
    count: acquList.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => 30,
  });

  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (editedRowList.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else {
        saveAcquList.mutate(editedRowList);
      }
    }
  };
  const btnSearch = () => {
    setSearchedParam(searchParam);

    refetchFromFirstPage();
  };
  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    if (searchParam === searchParamInit) {
      let userInfo = decrypt("userInfo");
      let userPermit = userInfo.userPermit;
      if (userPermit !== "99") {
        setSearchParam({ ...searchParam, jisa: userInfo.jisa });
      }
    }

    addTabClosedEvent(location.pathname, () => {
      setTableLimit("5");
      setCurrentRow({});
      setSearchParam(searchParamInit);
      setSearchedParam({});
      setAcquList([]);
      setConditionSelect(conditionSelectInit);
      setMedoMesu({ cgubun: "1", ccode: "", cabout: "1" });
      setMedoMesuCost({ ...resultInit });
      setMedoSecondInput({ cgubun: "1", ccode: "", cabout: "1" });

      setGovernGolfKeyword("");
      setGovernGolfList([]);
      setGovernCurrentRow({});

      setModalShow(modalShowInit);
      setTax({ ...taxInit });
      setTaxList([]);
      setResult({ ...resultInit });
      setGolfData({});
      setCurrentGolf("00");
    });
  }, []);

  return (
    <>
      <div className="work-page">
        <div>
          <div className="work-tax-header">
            <div className="work-page-title">취득세 신고 계산서 출력</div>
          </div>
        </div>
        <div className="filter_fields_container">
          <div className="filter_fields_row d-flex justify-content-between" onKeyDown={pressEnter}>
            <div className="d-flex">
              <div className="filter1-field filter1-field1">
                <InputFilter
                  label="지사구분"
                  type="select"
                  size="l"
                  keyName="jisa"
                  data={searchParam}
                  setData={setSearchParam}
                  disabled={
                    !(
                      decrypt("userInfo").userPermit === "99" ||
                      decrypt("userInfo").userPermit === "55" ||
                      decrypt("userInfo").userPermit === "81" ||
                      decrypt("userInfo").userPermit === "88"
                    )
                  }
                >
                  {getJisaGubun.data?.map(el => {
                    return <option value={el.key}>{el.value}</option>;
                  })}
                </InputFilter>
              </div>
              <div className="filter1-field filter1-field1">
                <div className="d-flex" style={{ width: "150px" }}>
                  <InputFilter
                    label="구분"
                    type="select"
                    size="m"
                    keyName="gubun2"
                    data={searchParam}
                    setData={setSearchParam}
                  >
                    <option value="9">전체</option>
                    <option value="1">골프</option>
                    <option value="2">콘도</option>
                    <option value="3">헬스</option>
                  </InputFilter>
                  &nbsp;
                  <InputFilter
                    type="select"
                    size="m"
                    keyName="gubun1"
                    data={searchParam}
                    setData={setSearchParam}
                  >
                    <option value="9">전체</option>
                    <option value="1">양도</option>
                    <option value="2">양수</option>
                  </InputFilter>
                </div>
              </div>
              <div className="filter1-field filter1-field1">
                <InputFilter
                  label="세무신고"
                  type="select"
                  size="l"
                  keyName="taxTarget"
                  data={searchParam}
                  setData={setSearchParam}
                >
                  <option value="A">전체</option>
                  <option value="Y">대상</option>
                  <option value="N">비대상</option>
                  <option value="R">관청접수</option>
                  <option value="S">완료</option>
                </InputFilter>
              </div>
              <div className="filter1-field filter1-field1">
                <InputFilter
                  label="거래 기간"
                  type="date"
                  keyName="fromDate"
                  data={searchParam}
                  setData={setSearchParam}
                />
                <span>&nbsp;~&nbsp;</span>
                <InputFilter
                  type="date"
                  keyName="toDate"
                  data={searchParam}
                  setData={setSearchParam}
                />
              </div>
              <div className="filter1-field filter1-field1" style={{ marginRight: "100px" }}>
                <InputFilter
                  label="성명"
                  type="text"
                  keyName="keyword"
                  data={searchParam}
                  setData={setSearchParam}
                />
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btnSearch}
              >
                조회
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={btnSave}
              >
                저장
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end mb-2">
              <select
                className="form-select table-filter-select"
                value={tableLimit}
                onChange={e => {
                  setTableLimit(e.target.value);
                }}
              >
                <option value="5">5개씩 보기</option>
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
              </select>
            </div>
            <div
              className={`overflow-auto table-container table-sticky`}
              ref={tableRef}
              style={
                tableLimit === "5"
                  ? { maxHeight: "210px" }
                  : tableLimit === "10"
                  ? { maxHeight: "360px" }
                  : tableLimit === "20"
                  ? { maxHeight: "660px" }
                  : ""
              }
            >
              <table
                className="table table-bordered table-responsive my-table"
                style={{
                  width: "120vw",
                  marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                  marginBottom: `${
                    rowVirtualizer.getTotalSize() -
                    (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                    rowVirtualizer.getVirtualItems().length * 30
                  }px`,
                }}
              >
                <thead>
                  <AcquListRowHeader />
                </thead>
                <tbody>
                  {acquList.length !== 0 ? (
                    rowVirtualizer.getVirtualItems().map(el => {
                      if (el.index !== acquList.length - 1) {
                        return (
                          <AcquListRow
                            el={acquList[el.index]}
                            idx={el.index}
                            setEditedRowList={setEditedRowList}
                          />
                        );
                      } else {
                        return (
                          <>
                            <div className="m-0 p-0 b-0" ref={scrollRef} />
                            <AcquListRow
                              el={acquList[el.index]}
                              idx={el.index}
                              setEditedRowList={setEditedRowList}
                            />
                          </>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan={26}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ConditionSelect />

        <div className="two-col-outer mt-14px">
          <InputSection refetchFromFirstPage={refetchFromFirstPage} editedRowList={editedRowList} />
          <GovernSearch />
        </div>
      </div>
    </>
  );
}
