import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { axios } from "../../../../util/axios/axios";
import Modal from "react-bootstrap/Modal";
import { useAtom } from "jotai";
import {
  annualApplicationPreparation_assign,
  annualApplicationPreparation_drftnSn,
  annualApplicationPreparation_getDetailData,
  annualApplicationPreparation_isAllDay,
  annualApplicationPreparation_isHalfInput,
  annualApplicationPreparation_row_atom,
  annualApplicationPreparation_submitData,
  annualApplicationPreparation_year,
} from "../../../../data/atom/goodsManage/annualLeaveManage/annualApplicationPreparation/annualApplicationPreparation_atom";
import moment from "moment";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
//  매물관리 - 연차 신청 작성
export const AnnualApplicationPreparation = () => {
  const queryClient = useQueryClient();
  const [show1, setShow1] = useAtom(annualApplicationPreparation_assign); // 하단 탭 보이기 0 - 안보임, 1 - 상신, 2 - 상세
  const [show, setShow] = useState(false);
  const [searchYear, setSearchYear] = useAtom(annualApplicationPreparation_year);
  const [submitData, setSubmitData] = useAtom(annualApplicationPreparation_submitData);
  const [searchListNumber, setSearchListNumber] = useAtom(annualApplicationPreparation_row_atom);
  const [detailData, setDetailData] = useAtom(annualApplicationPreparation_getDetailData);
  const [isAllDay, setIsAllDay] = useAtom(annualApplicationPreparation_isAllDay);
  const [isHalfInput, setIsHalfInput] = useAtom(annualApplicationPreparation_isHalfInput);
  const [selectDrftnSn, setSelectDrftnSn] = useAtom(annualApplicationPreparation_drftnSn);
  const handleClose = () => setShow(false);
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setShow1(0);
      setSearchYear("");
      setSubmitData({
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        remark: "",
        sawonId: "",
        workDate: moment(new Date()).format("YYYY-MM-DD"),
        workgubn: "11",
        yunchaDay: 0,
      });
      setSearchListNumber("5");
      setDetailData({
        yunchaInfo: {
          drftSn: 0,
          kname: "",
          yunchaType: "",
          remark: "",
          dept: "",
          auth: "",
          job: "",
          confmer: "",
          confmStatus: "",
          yunchaDay: 0,
          workDate: "",
          endDate: "",
          applyDate: "",
        },
        confmList: [],
      });
      setIsAllDay(true);
      setIsHalfInput(false);
      setSelectDrftnSn("");
    });
  }, []);
  const handleShow = () => {
    if (!moment(submitData?.workDate).isSameOrBefore(moment(submitData?.endDate))) {
      alert("연차 일자를 올바르게 작성해주세요.");
      return;
    }
    submit.mutate(submitData);
  };

  const curYear = new Date().getFullYear();
  const yearList = Array(Number(curYear) - 1990 + 1)
    .fill(Number(curYear))
    .map((v, i) => v - i);

  // API - 연차 등록 정보
  const { data: submitDefaultData } = useQuery(["getDefaultData"], async () =>
    axios.get("/api/schedule/yunchaRegist/getRegistInfo").then(res => {
      return res?.data?.result;
    })
  );

  // API - 연차 등록 검색
  const search = useMutation(data =>
    axios
      .get("/api/schedule/yunchaRegist/search", {
        params: {
          toDate: data.toString(),
        },
      })
      .then(res => {
        return res.data.result;
      })
  );

  // API - 연차 상신
  const submit = useMutation(data => axios.post("/api/schedule/yunchaRegist/submit", data), {
    onSuccess: res => {
      setShow(true);
      search.mutate(curYear);
      setSubmitData({
        remark: "",
        sawonId: "",
        workDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        workgubn: "11",
        yunchaDay: 0,
      });
    },
  });

  // API - 연차 유형셀렉트 박스
  const { data: getYunchaData } = useQuery(["getYunchaSelect"], async () =>
    axios.get("/api/schedule/yunchaRegist/getYunchaGubun").then(res => {
      return res?.data?.result;
    })
  );

  // API - 연차 상세
  const getDetail = useMutation(
    data =>
      axios.get("/api/schedule/yunchaRegist/getDetail", {
        params: {
          drftnSn: data,
        },
      }),
    {
      onSuccess: (res, payload) => {
        setSelectDrftnSn(payload);
        const confirmList = res?.data?.result?.confmList;
        const modifyConfirmList = confirmList.sort((a, b) => {
          return a?.confmOrdr - b?.confmOrdr;
        });

        setDetailData({ ...res?.data?.result, confmList: modifyConfirmList });
      },
    }
  );

  // API - 연차 결재자 정보
  const getYunchaConfmListInfo = useQuery(
    ["getYunchaConfmListInfo"],
    async () =>
      await axios.get("/api/schedule/yunchaRegist/getYunchaConfmListInfo").then(res => {
        let tempData = [];
        if (res?.data?.result) {
          tempData = res?.data?.result;
        }
        const sortData = res?.data?.result?.sort((a, b) => {
          return a?.confmOrdr - b?.confmOrdr;
        });
        return sortData;
      })
  );

  const selectYear = e => {
    setSearchYear(e.target.value);
    search.mutate(e.target.value);
  };

  const onChangeSubmitHandler = e => {
    const { name, value } = e.target;
    if (!isAllDay && name === "workDate") {
      setSubmitData({ ...submitData, [name]: value, endDate: value });
    } else if (isHalfInput && name === "yunchaDay") {
      if (value > 0.99999999999) {
        setSubmitData({ ...submitData, [name]: 0.9 });
      } else if (value < 0) {
        setSubmitData({ ...submitData, [name]: 0 });
      } else {
        setSubmitData({ ...submitData, [name]: value });
      }

      // setSubmitData({ ...submitData, [name]: value });
    } else {
      setSubmitData({ ...submitData, [name]: value });
    }
  };

  // 연차 구분 라디오 선택시
  const onClickSubmitHandler = e => {
    setIsAllDay(false);
    const { value } = e.target;
    setSubmitData(prev => ({
      ...prev,
      workgubn: value,
      workDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    }));
  };

  useEffect(() => {
    if (searchYear === "") {
      const curYear = new Date().getFullYear();
      search.mutate(curYear);
      setSearchYear(Number(curYear));
    } else {
      search.mutate(searchYear);
    }
  }, []);

  useEffect(() => {
    if (isAllDay && submitData.workDate && submitData.endDate) {
      const start = new Date(submitData.workDate);
      const end = new Date(submitData.endDate);
      // 일수 차이 계산
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      let weekdays = 0;
      for (let i = 0; i <= daysDiff; i++) {
        const currentDate = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
        const currentDay = currentDate.getDay(); // 0: 일요일, 1: 월요일, ..., 6: 토요일
        if (currentDay !== 0 && currentDay !== 6) {
          weekdays++;
        }
      }
      setSubmitData({ ...submitData, yunchaDay: weekdays });
    }
  }, [submitData.workDate, submitData.endDate]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-box1">
            <p>상신되었습니다</p>
            <div className="d-flex align-items-center justify-content-center">
              <button className="btn btn-outline-primary" onClick={handleClose}>
                확인
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">검색</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label m-0">
                    발생 연차 : {search?.data?.totalYuncha ? search?.data?.totalYuncha : 0} 일
                  </label>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label m-0">
                    사용 연차 :{search?.data?.usedYuncha ? search?.data?.usedYuncha : 0} 일
                  </label>
                </div>
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label m-0">
                    잔여 연차 : {search?.data?.remainYuncha ? search?.data?.remainYuncha : 0} 일
                  </label>
                </div>
              </div>
              <button
                className="btn btn-primary me-20px"
                onClick={() => {
                  setShow1(1);
                  setSelectDrftnSn("");
                }}
              >
                등록
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="card-title m-0">연차 사용 신청 내역</h5>
            <div className="d-flex align-items-center justify-content-end w-50">
              <select
                className="form-select filter-perpage ms-8px h-31"
                value={searchYear}
                onChange={e => selectYear(e)}
              >
                {yearList.map(item => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
              <select
                className="form-select filter-perpage ms-8px h-31"
                value={searchListNumber}
                onChange={e => {
                  setSearchListNumber(e.target.value);
                }}
              >
                <option value="5">5개씩 보기</option>
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
              </select>
            </div>
          </div>
          <div className="card-body">
            <div
              className={`table-container table-sticky 
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
              id="scroll-table-body"
            >
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th>연번</th>
                    <th>성 명</th>
                    <th>연차시작일</th>
                    <th>연차종료일</th>
                    <th>연차구분</th>
                    <th>사유</th>
                    <th>상태</th>
                    <th>상신일자</th>
                  </tr>
                </thead>
                <tbody>
                  {search?.data?.yunchaHistoryList.length > 0 ? (
                    search?.data?.yunchaHistoryList.map((data, _idx) => {
                      return (
                        <tr
                          role="button"
                          onClick={() => {
                            setShow1(2);
                            getDetail.mutate(data?.drftSn);
                          }}
                          className={`${selectDrftnSn === data?.drftSn && "bg-blue"}`}
                        >
                          <td>{_idx + 1}</td>
                          <td>{data?.kname}</td>
                          <td>{moment(data?.workDate).format("YYYY-MM-DD")}</td>
                          <td>{moment(data?.endDate).format("YYYY-MM-DD")}</td>
                          <td>{data?.yunchaType}</td>
                          <td>{data?.remark}</td>
                          <td>{data?.confmStatus}</td>
                          <td>{moment(data?.applyDate).format("YYYY-MM-DD")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>조회 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {show1 === 1 && (
          <div className="card card-sub mt-14px">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5 className="card-title m-0">연차 사용 신청하기</h5>
              <div className="d-flex align-items-center justify-content-end w-50">
                {show1 === 1 ? (
                  <button className="btn btn-outline-primary h-25" onClick={handleShow}>
                    상신
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary h-25"
                    onClick={() => {
                      setShow1(0);
                    }}
                  >
                    닫기
                  </button>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="tab1-row1-count">
                지정된 결재선 :
                {getYunchaConfmListInfo?.data?.reduce((acc, cur, idx) => {
                  if (idx === 0) {
                    return acc + " " + cur.kname;
                  } else {
                    return acc + "-" + cur.kname;
                  }
                }, "")}
              </div>
              <table className="table table-bordered table-responsive my-table1 m-0 mt-3">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>법 인 명</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {submitDefaultData?.etcDept}
                    </td>
                    <th style={{ width: "11.9%" }}>소 속</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {submitDefaultData?.etcJob}
                    </td>
                    <th style={{ width: "11.9%" }}>성 명</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {submitDefaultData?.kname}
                    </td>
                    <th style={{ width: "11.9%" }}>사원번호</th>
                    <td className="text-center"> {submitDefaultData?.sawonId}</td>
                  </tr>
                  <tr>
                    <th>연차구분</th>
                    <td colSpan={3}>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio1"
                            name="workgubn"
                            checked={isAllDay}
                            onClick={() => {
                              setIsAllDay(true);
                              setIsHalfInput(false);
                              setSubmitData({
                                ...submitData,
                                yunchaDay: 1.0,
                                workgubn: getYunchaData[0].key,
                                workDate: moment().format("YYYY-MM-DD"),
                                endDate: moment().format("YYYY-MM-DD"),
                              });
                            }}
                          />
                          {/* 전일 차감 -> 1.0 */}
                          <label className="form-check-label" htmlFor="inlineRadio1">
                            전 일
                          </label>
                        </div>
                        <div className="form-check form-check-inline ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio2"
                            name="workgubn"
                            value="18"
                            onClick={e => {
                              onClickSubmitHandler(e);
                              setIsHalfInput(false);
                              setSubmitData(prev => ({ ...prev, yunchaDay: 0.4 }));
                            }}
                          />
                          {/* 반차(오전) 차감 ->  0.4 */}
                          <label className="form-check-label" htmlFor="inlineRadio2">
                            반차(오전)
                          </label>
                        </div>
                        <div className="form-check form-check-inline ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio3"
                            name="workgubn"
                            value="19"
                            onClick={e => {
                              onClickSubmitHandler(e);
                              setIsHalfInput(false);
                              setSubmitData(prev => ({ ...prev, yunchaDay: 0.6 }));
                            }}
                          />
                          {/* 반차(오전) 차감 ->  0.6 */}
                          <label className="form-check-label" htmlFor="inlineRadio3">
                            반차(오후)
                          </label>
                        </div>
                        <div className="form-check form-check-inline ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio4"
                            name="workgubn"
                            value="13"
                            onClick={e => {
                              onClickSubmitHandler(e);
                              setIsHalfInput(false);
                              setSubmitData(prev => ({ ...prev, yunchaDay: 0.5 }));
                            }}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio4">
                            반 차
                          </label>
                        </div>
                        <div className="form-check form-check-inline ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="inlineRadio5"
                            name="workgubn"
                            value="13"
                            onClick={e => {
                              onClickSubmitHandler(e);
                              setIsHalfInput(true);
                              setSubmitData(prev => ({ ...prev, yunchaDay: 0 }));
                            }}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio5">
                            반차입력
                          </label>
                        </div>
                      </div>
                    </td>
                    <th>반차입력</th>
                    <td>
                      {isHalfInput && (
                        <input
                          type="number"
                          name="yunchaDay"
                          value={submitData.yunchaDay}
                          onChange={e => onChangeSubmitHandler(e)}
                          className="form-control table-input"
                          max="0.999"
                          min="0"
                          step="0.1"
                        />
                      )}
                    </td>
                    <th>연차유형</th>
                    <td>
                      {isAllDay && (
                        <select
                          className="form-select table-select"
                          value={submitData.workgubn}
                          onChange={e => {
                            setSubmitData({ ...submitData, workgubn: e.target.value });
                          }}
                        >
                          {getYunchaData?.map(data => {
                            return <option value={data.key}>{data.value}</option>;
                          })}
                        </select>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>기간</th>
                    <td colSpan={3}>
                      <input
                        type="date"
                        className="form-control table1-email1"
                        name="workDate"
                        value={submitData.workDate}
                        onChange={e => {
                          onChangeSubmitHandler(e);
                        }}
                        min="1900-01-01"
                        max="2099-12-31"
                      />
                      <span className="txt_at">~</span>
                      <input
                        type="date"
                        className="form-control table1-email2"
                        name="endDate"
                        value={submitData.endDate}
                        onChange={e => {
                          if (isAllDay) {
                            onChangeSubmitHandler(e);
                          }
                        }}
                        min={submitData.workDate}
                        max="2099-12-31"
                      />
                    </td>
                    <th>차감연차</th>
                    <td className="text-center" colSpan={3}>
                      <div>{submitData.yunchaDay}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>신청사유</th>
                    <td colSpan={7}>
                      <input
                        type="text"
                        className="form-control table-input"
                        name={"remark"}
                        value={submitData.remark}
                        onChange={e => onChangeSubmitHandler(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {show1 === 2 && (
          <div className="card card-sub mt-14px">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h5 className="card-title m-0">상세보기</h5>
              <div className="d-flex align-items-center justify-content-end w-50">
                <button
                  className="btn btn-outline-primary h-25"
                  onClick={() => {
                    setShow1(0);
                    setSelectDrftnSn("");
                  }}
                >
                  닫기
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="tab1-row1-count">
                지정된 결재선 :
                {detailData?.confmList?.map((item, _idx) => {
                  if (_idx === 0) {
                    return <span> </span>;
                  }
                  if (_idx === detailData?.confmList.length - 1) {
                    return <span>{item?.kname}</span>;
                  } else {
                    if (item?.confmSttus === "반려") {
                      return <span>{item?.kname + "(반려) - "}</span>;
                    } else {
                      return <span>{item?.kname + " - "}</span>;
                    }
                  }
                })}
              </div>
              <table className="table table-bordered table-responsive my-table1 m-0 mt-3">
                <tbody>
                  <tr>
                    <th style={{ width: "11.9%" }}>법 인 명</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.yunchaInfo?.dept}
                    </td>
                    <th style={{ width: "11.9%" }}>소 속</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.yunchaInfo?.job}
                    </td>
                    <th style={{ width: "11.9%" }}>성 명</th>
                    <td className="text-center" style={{ width: "13.1%" }}>
                      {detailData?.yunchaInfo?.kname}
                    </td>
                    <th style={{ width: "11.9%" }}>사원번호</th>
                    <td className="text-center">{detailData?.yunchaInfo?.sawonId}</td>
                  </tr>
                  <tr>
                    <th>연차구분</th>
                    <td colSpan={3}>
                      <div className="d-flex align-items-center justify-content-center">
                        {detailData?.yunchaInfo?.yunchaType}
                      </div>
                    </td>
                    <th>직위</th>
                    <td className="text-center"> {detailData?.yunchaInfo?.auth}</td>
                    <th>상신일자</th>
                    <td className="text-center">
                      {moment(detailData?.yunchaInfo?.applyDate).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                  <tr>
                    <th>기간</th>
                    <td colSpan={3}>
                      <div className="d-flex justify-content-center">
                        <div>{moment(detailData?.yunchaInfo?.workDate).format("YYYY-MM-DD")}</div>
                        <span className="txt_at">~</span>
                        <div>{moment(detailData?.yunchaInfo?.endDate).format("YYYY-MM-DD")}</div>
                      </div>
                    </td>
                    <th>차감연차</th>
                    <td className="text-center" colSpan={3}>
                      <div>{detailData?.yunchaInfo?.yunchaDay}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>신청사유</th>
                    <td colSpan={7}>
                      <div>{detailData?.yunchaInfo?.remark}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
