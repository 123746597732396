import React, { useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai/index";
import { useAtomValue } from "jotai";
import {
  accountList_atom,
  bigo_atom,
  bizInfo_atom,
  key1b_atom,
  shopDetail_atom,
} from "../../../data/atom/workManage/shopMasterManage/shopMasterManage_atom";
import { useGetBank } from "../../../api/code/useGetBank";
import { DaumPostcode } from "../../../components/daumPostcode/daumPostcode";

const rightShopMemo = () => {
  const [key1b, setKey1b] = useAtom(key1b_atom);
  const [bigo, setBigo] = useAtom(bigo_atom);
  const [bizInfo, setBizInfo] = useAtom(bizInfo_atom);
  const [accountList, setAccountList] = useAtom(accountList_atom);
  const shopDetail = useAtomValue(shopDetail_atom);

  const bankList = useGetBank();
  const searchShopMemoBigo = useMutation(
    data => axios.get("/api/work/shop/right/getBigo", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;
        setBigo({ custId: shopDetail.custId, remark: data });
      },
    }
  );
  const searchShopMemoBizInfo = useMutation(
    data => axios.get("/api/work/shop/right/getBizInfo", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;
        setBizInfo({
          ...data,
          saupjaNo: data?.saupjaNo
            ? data?.saupjaNo.slice(0, 3) +
              "-" +
              data?.saupjaNo.slice(3, 5) +
              "-" +
              data?.saupjaNo.slice(5)
            : "",
        });
      },
    }
  );
  const searchShopMemoAccount = useMutation(
    data => axios.get("/api/work/shop/right/getAccount", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result?.map(el => {
          return { ...el, newFlag: false };
        });

        setAccountList(data);
      },
    }
  );

  const saveShopMemoBigo = useMutation(data => axios.post("/api/work/shop/right/saveBigo", data), {
    onSuccess: res => {
      searchShopMemoBigo.mutate(shopDetail);
    },
  });
  const saveShopMemoBizInfo = useMutation(
    data => axios.post("/api/work/shop/right/saveBizInfo", data),
    {
      onSuccess: res => {
        searchShopMemoBizInfo.mutate({ ...shopDetail });
      },
    }
  );
  const saveShopMemoAccount = useMutation(
    data => axios.post("/api/work/shop/right/saveAccount", data),
    {
      onSuccess: res => {
        searchShopMemoAccount.mutate(shopDetail);
      },
    }
  );

  const deleteShopMemoAccount = useMutation(
    data => axios.post("/api/work/shop/right/deleteAccount", data),
    {
      onSuccess: res => {
        searchShopMemoAccount.mutate(shopDetail);
      },
    }
  );

  useEffect(() => {
    if (shopDetail.custId !== "") {
      searchShopMemoBigo.mutate(shopDetail);
      searchShopMemoBizInfo.mutate(shopDetail);
      searchShopMemoAccount.mutate(shopDetail);
    }
  }, [shopDetail.custId]);

  const onChangeBigoEventHandler = value => {
    if (checkByte(value) <= 500) setBigo({ ...bigo, remark: value });
  };

  const onChangeBizInfoEventHandler = (key, value) => {
    if (key === "saupjaNo") {
      const newTargetValue = value
        .split("")
        .filter(item => item !== "-")
        .reduce((acc, cur, idx) => {
          return (acc + cur).replace("-", "");
        }, "");
      const modeTarget = newTargetValue
        ? newTargetValue.slice(0, 3) +
          "-" +
          newTargetValue.slice(3, 5) +
          "-" +
          newTargetValue.slice(5)
        : "";
      setBizInfo({ ...bizInfo, [key]: modeTarget });
    } else if (key === "상호") {
      if (checkByte(value) <= 200) setBizInfo({ ...bizInfo, [key]: value });
    } else if (key === "대표자") {
      if (checkByte(value) <= 40) setBizInfo({ ...bizInfo, [key]: value });
    } else if (key === "corpNo") {
      if (checkByte(value) <= 13)
        setBizInfo({ ...bizInfo, [key]: value.replace(/(^0+)/, "").replace(/[^0-9]/g, "") });
    } else if (key === "rdetail") {
      if (checkByte(value) <= 400) setBizInfo({ ...bizInfo, [key]: value });
    } else if (key === "upte") {
      if (checkByte(value) <= 100) setBizInfo({ ...bizInfo, [key]: value });
    } else if (key === "jongmok") {
      if (checkByte(value) <= 100) setBizInfo({ ...bizInfo, [key]: value });
    } else if (key === "remark") {
      if (checkByte(value) <= 200) setBizInfo({ ...bizInfo, [key]: value });
    } else {
      setBizInfo({ ...bizInfo, [key]: value });
    }
  };

  const onChangeAccountEventHandler = (key, value, idx) => {
    if (key === "bankNo") {
      if (checkByte(value) <= 16) {
        const newData = {
          ...accountList[idx],
          [key]: value.replace(/[^0-9-]/g, ""),
        };
        const newList = [...accountList];
        newList.splice(idx, 1, newData);
        setAccountList(newList);
      }
    } else if (key === "kname") {
      if (checkByte(value) <= 40) {
        const newData = {
          ...accountList[idx],
          [key]: value,
        };
        const newList = [...accountList];
        newList.splice(idx, 1, newData);
        setAccountList(newList);
      }
    } else if (key === "remark") {
      if (checkByte(value) <= 200) {
        const newData = {
          ...accountList[idx],
          [key]: value,
        };
        const newList = [...accountList];
        newList.splice(idx, 1, newData);
        setAccountList(newList);
      }
    } else {
      const newData = {
        ...accountList[idx],
        [key]: value,
      };
      const newList = [...accountList];
      newList.splice(idx, 1, newData);
      setAccountList(newList);
    }
  };

  const btnBigoSave = () => {
    if (confirm("저장하시겠습니까?")) {
      saveShopMemoBigo.mutate(bigo);
    }
  };
  const btnBigoCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      searchShopMemoBigo.mutate(shopDetail);
    }
  };

  const btnBizInfoSave = () => {
    if (confirm("저장하시겠습니까?")) {
      saveShopMemoBizInfo.mutate({ ...bizInfo, saupjaNo: bizInfo?.saupjaNo.replaceAll("-", "") });
    }
  };
  const btnBizInfoCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      searchShopMemoBizInfo.mutate(shopDetail);
    }
  };

  const btnAccountSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (accountList.filter(account => account.bankGubun === "").length > 0) {
        alert("은행명을 입력해주세요.");
        return;
      }
      saveShopMemoAccount.mutate(accountList);
    }
  };
  const btnAccountCancel = () => {
    if (confirm("취소하시겠습니까?")) {
      searchShopMemoAccount.mutate(shopDetail);
    }
  };
  const btnAccountAddRow = () => {
    setAccountList([
      {
        bankGubun: "",
        bankNo: "",
        custId: shopDetail.custId,
        ilNo: 0,
        kname: "",
        remark: "",
        userCheck: "N",
        userid: "",
        newFlag: true,
      },
      ...accountList,
    ]);
  };
  const btnAccountDeleteRow = (deleteEl, deleteIdx) => {
    if (!deleteEl.newFlag) {
      if (confirm("삭제하시겠습니까?")) {
        deleteShopMemoAccount.mutate(deleteEl);
      }
    } else {
      const list = [...accountList].filter((row, rowIdx) => {
        if (rowIdx !== deleteIdx) return row;
      });
      setAccountList(list);
    }
  };

  const checkByte = str => {
    let currentByte = 0;
    for (let i = 0; i < str.length; i++) {
      if (new RegExp(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/).test(str[i])) {
        currentByte += 3;
      } else {
        currentByte += 1;
      }
    }
    return currentByte;
  };

  return (
    <>
      <div className="col-right">
        <div className="card card-sub h-618px">
          <div className="card-body pb-0">
            <div className="tabs-sidebar-wrapper w-100">
              <Tabs className="tab-blocks" activeKey={key1b} onSelect={k => setKey1b(k)}>
                <Tab eventKey="비고사항" title={<>비고사항</>}>
                  <div className="tab-content-container">
                    <div className="table-tabs-sub">비고사항</div>
                    <textarea
                      className="form-control"
                      rows={5}
                      value={bigo.remark}
                      onChange={e => onChangeBigoEventHandler(e.target.value)}
                      style={{ height: "480px" }}
                    ></textarea>
                    <div className="d-flex align-items-center justify-content-end mt-10px">
                      <button
                        className="btn btn-outline-primary tab1-row1-btn"
                        onClick={btnBigoSave}
                      >
                        저장
                      </button>
                      <button
                        className="btn btn-outline-primary tab1-row1-btn"
                        onClick={btnBigoCancel}
                      >
                        취소
                      </button>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="사업자정보" title={<>사업자정보</>}>
                  <div className="tab-content-container">
                    <table className="table table-bordered table-responsive my-table1 m-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "20%" }}>사업자번호</th>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.saupjaNo ? bizInfo.saupjaNo : ""}
                              onChange={e =>
                                onChangeBizInfoEventHandler("saupjaNo", e.target.value)
                              }
                              maxLength={12}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>상호</th>
                          <td colSpan={2}>{bizInfo?.company}</td>
                        </tr>
                        <tr>
                          <th>대표자</th>
                          <td colSpan={2}>{bizInfo?.owner}</td>
                        </tr>
                        <tr>
                          <th>법인등록번호</th>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.corpNo ? bizInfo.corpNo : ""}
                              onChange={e => onChangeBizInfoEventHandler("corpNo", e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th rowSpan={2}>주소</th>
                          <td className="w-25">
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.czipcode ? bizInfo.czipcode : ""}
                              onChange={e =>
                                onChangeBizInfoEventHandler("czipcode", e.target.value)
                              }
                              disabled={true}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.caddresTxt ? bizInfo.caddresTxt : ""}
                              onChange={e =>
                                onChangeBizInfoEventHandler("caddresTxt", e.target.value)
                              }
                              disabled={true}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.caddress ? bizInfo.caddress : ""}
                              onChange={e =>
                                onChangeBizInfoEventHandler("caddress", e.target.value)
                              }
                              disabled={true}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th rowSpan={2}>도로명 주소</th>
                          <td className="w-25">
                            <input
                              type="text"
                              className="form-control table-input w-45 float-start bordered"
                              value={bizInfo?.rzipcode ? bizInfo.rzipcode : ""}
                              onChange={e =>
                                onChangeBizInfoEventHandler("rzipcode", e.target.value)
                              }
                              disabled={true}
                            />
                            <DaumPostcode
                              onCompleteCallback={data =>
                                setBizInfo({
                                  ...bizInfo,
                                  rzipcode: data.zonecode,
                                  raddresTxt: data.fullAddr,
                                  rbdcode: data.bdCode,
                                })
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.raddresTxt ? bizInfo.raddresTxt : ""}
                              onChange={e =>
                                onChangeBizInfoEventHandler("raddresTxt", e.target.value)
                              }
                              disabled={true}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.raddres ? bizInfo.raddres : ""}
                              onChange={e => onChangeBizInfoEventHandler("raddres", e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>업태</th>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.upte ? bizInfo.upte : ""}
                              onChange={e => onChangeBizInfoEventHandler("upte", e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>종목</th>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.jongmok ? bizInfo.jongmok : ""}
                              onChange={e => onChangeBizInfoEventHandler("jongmok", e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>비고</th>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.remark ? bizInfo.remark : ""}
                              onChange={e => onChangeBizInfoEventHandler("remark", e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>관할 세무서</th>
                          <td colSpan={2}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={bizInfo?.semusu ? bizInfo.semusu : ""}
                              onChange={e => onChangeBizInfoEventHandler("semusu", e.target.value)}
                              disabled={true}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="d-flex align-items-center justify-content-end mt-10px">
                      <button
                        className="btn btn-outline-primary tab1-row1-btn"
                        onClick={btnBizInfoSave}
                      >
                        저장
                      </button>
                      <button
                        className="btn btn-outline-primary tab1-row1-btn"
                        onClick={btnBizInfoCancel}
                      >
                        취소
                      </button>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="구좌번호" title={<>구좌번호</>}>
                  <div className="tab-content-container">
                    <table className="table table-bordered table-responsive my-table1 m-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "8%" }}>연번</th>
                          <th style={{ width: "16.8%" }}>은행명</th>
                          <th style={{ width: "22.8%" }}>계좌번호</th>
                          <th style={{ width: "16.8%" }}>예금주</th>
                          <th style={{ width: "14.8%" }}>사용여부</th>
                          <th style={{ width: "12.8%" }}>비고</th>
                          <th style={{ width: "8%" }}>삭제</th>
                        </tr>
                        {accountList.length !== 0 ? (
                          accountList.map((el, idx) => {
                            return (
                              <tr>
                                <td className="text-center">{el.ilNo !== 0 && el.ilNo}</td>
                                <td>
                                  <select
                                    className="form-select"
                                    value={el.bankGubun}
                                    onChange={e =>
                                      onChangeAccountEventHandler("bankGubun", e.target.value, idx)
                                    }
                                  >
                                    {bankList.data ? (
                                      bankList.data.map(el => {
                                        return <option value={el.key}>{el.value}</option>;
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={el.bankNo}
                                    onChange={e =>
                                      onChangeAccountEventHandler("bankNo", e.target.value, idx)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={el.kname}
                                    onChange={e =>
                                      onChangeAccountEventHandler("kname", e.target.value, idx)
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-select"
                                    value={el.userCheck}
                                    onChange={e =>
                                      onChangeAccountEventHandler("userCheck", e.target.value, idx)
                                    }
                                  >
                                    <option value="Y">사용</option>
                                    <option value="N">중지</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={el.remark}
                                    onChange={e =>
                                      onChangeAccountEventHandler("remark", e.target.value, idx)
                                    }
                                  />
                                </td>
                                <td className="text-center">
                                  <a
                                    onClick={() => {
                                      if (el.ilNo === 0) {
                                        btnAccountDeleteRow(el, idx);
                                      } else {
                                        if (accountList.filter(el => el.ilNo === 0).length === 0) {
                                          btnAccountDeleteRow(el, idx);
                                        } else {
                                          alert("구좌번호 등록을 진행해주세요.");
                                        }
                                      }
                                    }}
                                  >
                                    <img
                                      src={process.env.PUBLIC_URL + "/images/icon-trash.png"}
                                      alt=""
                                    />
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7} className="text-center">
                              조회 결과가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="d-flex align-items-center justify-content-end mt-2">
                      <button
                        className="btn btn-primary tab1-row1-btn"
                        onClick={() => {
                          if (accountList.filter(el => el.ilNo === 0).length === 0) {
                            btnAccountAddRow();
                          }
                        }}
                      >
                        등록
                      </button>
                      <button
                        className="btn btn-outline-primary tab1-row1-btn"
                        onClick={btnAccountSave}
                      >
                        저장
                      </button>
                      <button
                        className="btn btn-outline-primary tab1-row1-btn"
                        onClick={btnAccountCancel}
                      >
                        취소
                      </button>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(rightShopMemo);
