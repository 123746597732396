import { atom } from "jotai";

export const clientManage_searchDataInit_atom = {
  class1: "",
  keyword: "",
  outCheck: "N",
  page: 1,
  searchType: "1",
  selectBox: "",
  isExclusive: false,
};

export const clientManage_searchData_atom = atom(clientManage_searchDataInit_atom);

export const clientManage_tableLimit_atom = atom("5");

export const clientManage_searchedTableList_atom = atom([]);

export const clientManage_mainDataInit_atom = {
  caddres: "",
  cellular: "",
  cfax: "",
  classRemark: "",
  classTel: "",
  clazz: "",
  company: "",
  ctel: "",
  custId: "",
  czipCode: "",
  devision: "",
  email: "",
  etc1: "",
  gubun: "",
  haddres: "",
  hpCheck: "",
  hzipCode: "",
  inDate: "",
  jongmok: "",
  kname: "",
  modDate: "",
  modid: "",
  outCheck: "",
  outDate: "",
  owner: "",
  parSendCheck: "",
  payAccount: "",
  payBank: "",
  payName: "",
  position: "",
  postCheck: "",
  remark: "",
  saupjaNo: "",
  search: "",
  siseSendCheck: "",
  siteUrl: "",
  upte: "",
  userid: "",
  viewChk: "",
  newCheck: false,
};

export const clientManage_mainData_atom = atom(clientManage_mainDataInit_atom);

export const clientManage_subData_atom = atom([]);

export const clientManage_subDataModifyList_atom = atom([]);
