import moment from "moment/moment";
import EmpModal from "../../../../../components/modal/Modal";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai/index";
import {
  customerManage_mainData_atom,
  customerManage_searchData_atom,
  customerManage_selectedCustomer_atom,
  customerManage_tapList_atom,
} from "../../../../../data/atom/workManage/customerManage/customerManage_atom";
import { decrypt } from "../../../../../util/decrypt/decrypt";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";

export const ReasonChangeRequestModal = ({
  isMainDataDeleteModalOpen,
  setIsMainDataDeleteModalOpen,
  isMainDataRegisterModalOpen,
  setIsMainDataRegisterModalOpen,
  btn_search,
  customerGetDetail,
}) => {
  const [searchData, setSearchData] = useAtom(customerManage_searchData_atom);
  // const [changeHistory, setChangeHistory] = useAtom(customerManage_changeHistory_atom); // 고객 제명 사유 목록
  const [selectedCustomer, setSelectedCustomer] = useAtom(customerManage_selectedCustomer_atom);
  const [tapList, setTapList] = useAtom(customerManage_tapList_atom);

  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);

  const [changeHistory, setChangeHistory] = useState([]); // 고객 제명 사유 목록

  // 고객정보 제명 사유 목록 가져오기
  const customerGetDelReqHistory = useMutation(
    data => axios.get("/api/work/customer/getDelReqHistory", { params: data }),
    {
      onSuccess: res => {
        setChangeHistory(res.data.result);
      },
    }
  );

  // 고객정보 수정 사유 목록 가져오기
  const customerGetCauseHistory = useMutation(
    data => axios.get("/api/work/customer/getCauseHistory", { params: data }),
    {
      onSuccess: res => {
        setChangeHistory(res.data.result);
      },
    }
  );

  // 고객정보 저장/수정
  const customerSave = useMutation(data => axios.post("/api/work/customer/save", data), {
    onSuccess: res => {
      setIsMainDataRegisterModalOpen({
        data: null,
        isOpen: false,
      });

      if (selectedCustomer === "-1") {
        customerGetDetail.mutate(res.data.result.seqNo);
      } else {
        customerGetDetail.mutate(selectedCustomer);
        setMainData(res.data.result);
      }

      setSelectedCustomer(res.data.result.seqNo);

      if (tapList[0].seqNo === "-1") {
        let newTapList = [...tapList];
        newTapList = newTapList.filter(el => el.seqNo !== "-1");
        newTapList.unshift({
          seqNo: res.data.result.seqNo,
          kname: res.data.result.kname,
          company: res.data.result.company,
          custId: res.data.result.custId,
        });
        setTapList(newTapList);
      }
    },
  });

  // 고객정보 제명(삭제) 또는 복구, SJKSPACE 아이디 일때
  const customerDeleteRestore = useMutation(
    data => axios.post("/api/work/customer/restoreDelete", data),
    {
      onSuccess: res => {
        alert("처리완료");

        customerGetDetail.mutate(selectedCustomer);
        setIsMainDataDeleteModalOpen({
          data: null,
          isOpen: false,
        });
      },
    }
  );

  // 고객정보 제명(삭제) 요청, SJKSPACE 아이디 아닐때
  const customerRequestDeleteCustomer = useMutation(
    data => axios.post("/api/work/customer/requestDeleteCustomer", data),
    {
      onSuccess: res => {
        alert("처리완료");

        customerGetDetail.mutate(selectedCustomer);
        setIsMainDataDeleteModalOpen({
          data: null,
          isOpen: false,
        });
      },
    }
  );

  useEffect(() => {
    if (isMainDataDeleteModalOpen.isOpen) {
      customerGetDelReqHistory.mutate({ seqNo: selectedCustomer });
    }

    if (isMainDataRegisterModalOpen.isOpen) {
      if (isMainDataRegisterModalOpen.data.text === "신규저장") {
        btn_mainData_save();
        setIsMainDataRegisterModalOpen({
          data: null,
          isOpen: false,
        });
      } else {
        customerGetCauseHistory.mutate({ seqNo: selectedCustomer });
      }
    }
  }, [isMainDataDeleteModalOpen.isOpen, isMainDataRegisterModalOpen.isOpen]);

  const btn_mainData_save = () => {
    if (window.confirm("저장하시겠습니까?")) {
      if (mainData.seqNo !== "-1" && (mainData.reason === "" || mainData.reason === undefined)) {
        alert("변경사유를 입력해주세요.");
        return;
      }

      // if (mainData.juminno1 !== "" && mainData.juminno1.length !== 6) {
      //   alert("주민번호 글자수는 6글자입니다.");
      //   return;
      // }
      //
      // if (mainData.juminno2 !== "" && mainData.juminno2.length !== 7) {
      //   alert("주민번호 글자수는 7글자입니다.");
      //   return;
      // }

      // if (mainData.memberJuminno1 !== "" && mainData.memberJuminno1?.length !== 6) {
      //   alert("회원증 주민번호 글자수는 6글자입니다.");
      //   return;
      // }

      // if (mainData.memberJuminno2 !== "" && mainData.memberJuminno2?.length !== 7) {
      //   alert("회원증 주민번호 글자수는 7글자입니다.");
      //   return;
      // }

      // if (
      //   mainData.ownerList
      //     .map(el => el.ownerJuminno1 !== "" && el.ownerJuminno1?.length !== 6)
      //     .includes(true)
      // ) {
      //   alert("담당자 주민번호 글자수는 6글자입니다.");
      //   return;
      // }

      // if (
      //   mainData.ownerList
      //     .map(el => el.ownerJuminno2 !== "" && el.ownerJuminno2?.length !== 7)
      //     .includes(true)
      // ) {
      //   alert("담당자 주민번호 글자수는 7글자입니다.");
      //   return;
      // }

      customerSave.mutate({
        ...mainData,
        accessDeny: mainData.accessDeny ?? "",
        asCode: searchData.asCode ?? "",
        birthDate: mainData?.birthDate
          ? moment(mainData.birthDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
        caddres: mainData.caddres ?? "",
        cellular: mainData.cellular ?? "",
        cfax: mainData.cfax ?? "",
        changeCause: mainData.reason ?? "",
        changeDate: mainData?.changeDate
          ? moment(mainData.changeDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
        company: mainData.company ?? "",
        ctel: mainData.ctel ?? "",
        custGroup: mainData.custGroup ?? "",
        custId: mainData.custId ?? "",
        userid: mainData.userId ?? "",
        czipcode: mainData.czipcode ?? "",
        dbCode: mainData.dbCode ?? "",
        egubun: mainData.egubun ?? "",
        email: mainData.email ?? "",
        faxCheck: mainData.faxCheck ?? "",
        faxNDate: mainData?.faxNDate ? moment(mainData.faxNDate).format("YYYY-MM-DD HH:mm:ss") : "",
        gubun: mainData.gubun ?? "",
        remark: mainData.remark ?? "",
        haddres: mainData.haddres ?? "",
        hpCheck: mainData.hpCheck ?? "",
        hpCheck2: mainData.hpCheck2 ?? "",
        htel: mainData.htel ?? "",
        hzipcode: mainData.hzipcode ?? "",
        inDate: mainData?.inDate ? moment(mainData.inDate).format("YYYY-MM-DD HH:mm:ss") : "",
        jisa: mainData.jisa ?? "",
        juminno: `${mainData?.juminno1 ? mainData.juminno1 : ""}${
          mainData?.juminno2 ? mainData.juminno2 : ""
        }`,
        saupno: mainData.saupno ?? "",
        ctelChange: mainData.ctelChange ?? "",
        devision: mainData.devision ?? "",
        kname: mainData.kname ?? "",
        mainCellular: mainData.mainCellular ?? "",
        memberJuminno: `${mainData?.memberJuminno1 ? mainData.memberJuminno1 : ""}${
          mainData?.memberJuminno2 ? mainData.memberJuminno2 : ""
        }`,
        modDate: mainData?.modDate ? moment(mainData.modDate).format("YYYY-MM-DD HH:mm:ss") : "",
        modId: decrypt("userInfo").loginId ?? "",
        oldPartner: mainData.oldPartner ?? "",
        outCheck: mainData.outCheck ?? "",
        outDate: mainData?.outDate ? moment(mainData.outDate).format("YYYY-MM-DD HH:mm:ss") : "",
        outModDate: mainData.outModDate
          ? moment(mainData.outModDate).format("YYYY-MM-DD HH:mm:ss")
          : "",
        ownerCTel: mainData.ownerCTel ?? "",
        ownerCelTel: mainData.ownerCelTel ?? "",
        ownerPosition: mainData.ownerPosition ?? "",
        partner: mainData.partner ?? "",
        position: mainData.position ?? "",
        seqNo: mainData.seqNo === "-1" ? "" : (mainData?.seqNo ?? ""),
        telCheck: mainData.telCheck ?? "",
        upteCode: mainData.upteCode ?? "",
        ownerList: mainData.ownerList.map(el => ({
          ilNo: el.ilNo ?? "",
          ownerCTel: el.ownerCTel ?? "",
          ownerCelTel: el.ownerCelTel ?? "",
          ownerDevision: el.ownerDevision ?? "",
          ownerEGubun: el.ownerEGubun ?? "",
          ownerEMail: el.ownerEMail ?? "",
          ownerJuminno: `${el?.ownerJuminno1 ? el.ownerJuminno1 : ""}${
            el?.ownerJuminno2 ? el.ownerJuminno2 : ""
          }`,
          ownerName: el.ownerName ?? "",
          ownerPosition: el.ownerPosition ?? "",
          ownerRelation: el.ownerRelation ?? "",
          ownerRemark: el.ownerRemark ?? "",
          seqNo: el.seqNo ?? "",
        })),
      });
    }
  };

  const btn_mainData_delete = () => {
    if (window.confirm("저장하시겠습니까?")) {
      if (mainData.reason === "" || mainData.reason === undefined) {
        alert("변경사유를 입력해주세요.");
        return;
      }

      if (decrypt("userInfo").userPermit === "99") {
        if (mainData.outCheck === "Y") {
          // 관리자 이면서 삭제 복구
          customerDeleteRestore.mutate({
            reason: mainData.reason,
            outCheck: "N",
            seqNo: mainData.seqNo,
          });
        } else {
          // 관리자 이면서 삭제
          customerDeleteRestore.mutate({
            reason: mainData.reason,
            outCheck: "Y",
            seqNo: mainData.seqNo,
          });
        }
      } else {
        if (mainData.outCheck === "Y") {
          // 관리자 아니면서 삭제 복구 요청
          customerRequestDeleteCustomer.mutate({
            changeCause: mainData.reason,
            gubun: "N",
            seqNo: mainData.seqNo,
          });
        } else {
          // 관리자 아니면서 삭제 요청
          customerRequestDeleteCustomer.mutate({
            changeCause: mainData.reason,
            gubun: "Y",
            seqNo: mainData.seqNo,
          });
        }
      }
    }
  };

  const modal_change_customerInformation_cancel = () => {
    alert("고객정보 변경사유를 작성하지 않았습니다.\n\n접수 되지 않았습니다....!!!");

    setIsMainDataDeleteModalOpen({
      data: null,
      isOpen: false,
    });
    setIsMainDataRegisterModalOpen({
      data: null,
      isOpen: false,
    });
    setMainData({
      ...mainData,
      reason: "",
    });
  };

  return (
    <EmpModal
      show={isMainDataDeleteModalOpen.isOpen || isMainDataRegisterModalOpen.isOpen}
      width="98.5%"
      minWidth="475px"
      title={selectedCustomer !== "-1" ? "고객정보 변경사유" : "고객정보 신규등록"}
      onHide={() => {
        setIsMainDataDeleteModalOpen({
          data: null,
          isOpen: false,
        });
        setIsMainDataRegisterModalOpen({
          data: null,
          isOpen: false,
        });
        setMainData({
          ...mainData,
          reason: "",
        });
      }}
      strClass="modal-xl"
      size="xl"
      footer={
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-primary btn-filter-outline-primary"
            onClick={() => {
              if (isMainDataDeleteModalOpen.isOpen) {
                btn_mainData_delete();
              } else if (isMainDataRegisterModalOpen.isOpen) {
                btn_mainData_save();
              }
            }}
          >
            저장
          </button>
          <button
            className="btn btn-outline-primary btn-filter-outline-primary"
            onClick={modal_change_customerInformation_cancel}
          >
            취소
          </button>
        </div>
      }
    >
      <div className="table-container table-sticky h-333">
        <table className="table table-bordered table-responsive my-table mt-0">
          <colgroup>
            <col width="5%" />
            <col width="20%" />
            <col width="40%" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>요청일시</th>
              <th>변경사유(변경사유를 정확히 입력하시길 바랍니다)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{moment().format("YYYY-MM-DD HH:mm:ss")}</td>
              <td>
                <input
                  type="text"
                  className="form-control table-input"
                  value={mainData?.reason}
                  onChange={e => {
                    setMainData({
                      ...mainData,
                      reason: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            {changeHistory.length !== 0 &&
              changeHistory.map((el, index) => {
                return (
                  <tr>
                    <td>{index + 2}</td>
                    <td>
                      {isMainDataDeleteModalOpen.isOpen
                        ? moment(el.requestDate).format("YYYY-MM-DD HH:mm:ss")
                        : isMainDataRegisterModalOpen.isOpen &&
                          moment(el.modDate).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control table-input"
                        value={el.changeCause}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </EmpModal>
  );
};
