import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

// 일정 알림
export const Initial3 = () => {
  const [data, setData] = useState([]);
  // 일정 알림
  const initialAlertSchedule = useQuery(
    ["initialAlertSchedule"],
    async () => await axios.get("/api/work/customer/alertSchedule").then(res => res.data.result),
    {}
  );
  useEffect(() => {
    if (initialAlertSchedule.isSuccess) {
      setData([...initialAlertSchedule.data]);
    }
  }, [initialAlertSchedule.isSuccess]);

  const displayRepeat = value => {
    switch (value) {
      case "N":
        return "-";
      case "D":
        return "매일";
      case "W":
        return "매주";
      case "M":
        return "매월";
      default:
        return "";
    }
  };

  return (
    <div className="card card-sub ">
      <div className="filter1_container">
        <div className="filter_header_row">
          <div className="filter-title mt-10 ms-2 me-2">오늘의 일정</div>
        </div>
      </div>
      <div className="col-left mt-10 ms-2 me-2">
        <table className="table table-bordered table-responsive my-table">
          <thead>
            <tr>
              <th style={{ width: "15.38%" }}>No.</th>
              <th style={{ width: "23.07%" }}>등록자</th>
              <th style={{ width: "30.76%" }}>일정</th>
              <th style={{ width: "15.38%" }}>반복</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((i, idx) => (
                <tr>
                  <td className="text-center">{idx + 1}</td>
                  <td className="text-center">{i.userName && i.userName}</td>
                  <td className="text-center">{i.scheduleText && i.scheduleText}</td>
                  <td className="text-center">{displayRepeat(i.repeatFlag)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  검색된 일정이 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2 mb-2 me-2">
        <button className="btn btn-outline-primary ms-8px" onClick={() => window.close()}>
          닫기
        </button>
      </div>
    </div>
  );
};
