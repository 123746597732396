import React from "react";
import moment from "moment";

const SearchResultRow = props => {
  const { data, index, virtualIndex } = props;

  const filterDisplay = text => {
    if (text && text.startsWith("show-")) {
      return text.replace("show-", "");
    } else if (text && virtualIndex === 0) return text.replace("show-", "");
    else return "";
  };

  const stickyStyle = () => {
    if (virtualIndex === 0)
      return { position: "sticky", top: 30, backgroundColor: "white", zIndex: 10 };
    return {};
  };

  return (
    <tr
      className={`${data.userName === "소계" && "bg-lightyellow"} ${
        data.jisaName === "합계" && "bg-lightblue"
      }`}
    >
      <td>{index + 1}</td>
      <td style={stickyStyle()}>{filterDisplay(data.jisaName)}</td>
      <td style={stickyStyle()}>{filterDisplay(data.codename)}</td>
      <td style={stickyStyle()}>
        {data.userName !== "소계" ? filterDisplay(data.userName) : data.userName}
      </td>
      <td>
        {data?.userName !== "소계"
          ? data?.jisaName !== "합계"
            ? data?.custId.substring(0, 4) + "-" + data?.custId.substring(4)
            : data?.custId
          : data?.custId}
      </td>
      <td>{data.company}</td>
      <td>{data.devision}</td>
      <td>{data.kname}</td>
      <td>{data.position}</td>
      <td>{data.ctel}</td>
      <td>{data.htel}</td>
      <td>{data.cellular}</td>
      <td>{data.dbBorn}</td>
      <td>{data.inDate && moment(data.inDate).format("YYYY-MM-DD")}</td>
    </tr>
  );
};
export default React.memo(SearchResultRow);
