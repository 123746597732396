import React, { useCallback, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAtom, useSetAtom } from "jotai/index";
import {
  carDetail_atom,
  carDriveInfo_atom,
  carDriveInfoEditedRowList_atom,
  carFuelInfo_atom,
  carFuelInfoEditedRowList_atom,
  clickedRow_atom,
  tabKey_atom,
} from "../../../../data/atom/dataManage/carManage/carMaster_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment/moment";
import Tab1 from "./component/Tab1";
import { decrypt } from "../../../../util/decrypt/decrypt";
import Tab2 from "./component/Tab2";

const CarMasterList = () => {
  const [tabKey, setTabKey] = useAtom(tabKey_atom);
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);
  const [driveInfo, setDriveInfo] = useAtom(carDriveInfo_atom);
  const [editedRowListDrive, setEditedRowListDrive] = useAtom(carDriveInfoEditedRowList_atom);
  const [editedRowListFuel, setEditedRowListFuel] = useAtom(carFuelInfoEditedRowList_atom);
  const setFuelInfo = useSetAtom(carFuelInfo_atom);

  const searchDriveInfo = useMutation(
    data => axios.get("/api/data/carMaster/bottom/getDriveDetail", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setDriveInfo(
          data.map((el, idx) => {
            return { ...el, idx: idx };
          })
        );
      },
    }
  );
  const searchFuelInfo = useMutation(
    data => axios.get("/api/data/carMaster/bottom/getOilDetail", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setFuelInfo(
          data.map((el, idx) => {
            return { ...el, idx: idx };
          })
        );
      },
    }
  );

  const saveDriveInfo = useMutation(
    data => axios.post("/api/data/carMaster/bottom/saveDrive", data),
    {
      onSuccess: res => {
        searchDriveInfo.mutate(clickedRow);
      },
    }
  );
  const saveFuelInfo = useMutation(data => axios.post("/api/data/carMaster/bottom/saveOil", data), {
    onSuccess: res => {
      searchFuelInfo.mutate(clickedRow);
    },
  });

  const deleteDriveInfo = useMutation(
    data => axios.post("/api/data/carMaster/bottom/deleteDrive", data),
    {
      onSuccess: res => {
        searchDriveInfo.mutate(clickedRow);
      },
    }
  );
  const deleteFuelInfo = useMutation(
    data => axios.post("/api/data/carMaster/bottom/deleteOil", data),
    {
      onSuccess: res => {
        searchFuelInfo.mutate(clickedRow);
      },
    }
  );

  useEffect(() => {
    if (clickedRow.carrNumb !== undefined) {
      searchDriveInfo.mutate(clickedRow);
      searchFuelInfo.mutate(clickedRow);
    }
  }, [clickedRow]);

  const btnAdd = useCallback(
    key => {
      if (key === "drive") {
        setDriveInfo(prev => [
          {
            arrvSite: "",
            carrNumb: carDetail.carrNumb,
            charAmnt: 0,
            drveEndd: moment().format("YYYY-MM-DD HH:mm"),
            drveMile: 0,
            drveStat: moment().format("YYYY-MM-DD HH:mm"),
            drveTagt: "",
            drveUser: decrypt("userInfo").loginId,
            enddMile: 0,
            gateCost: 0,
            highPass: 0,
            moveGubn: "",
            passCost: 0,
            remark: "",
            statMile: 0,
            statSite: "",
            newCheck: true,
            idx: -1,
          },
          ...prev,
        ]);
        setEditedRowListDrive(prev => [
          {
            arrvSite: "",
            carrNumb: carDetail.carrNumb,
            charAmnt: 0,
            drveEndd: moment().format("YYYY-MM-DD HH:mm"),
            drveMile: 0,
            drveStat: moment().format("YYYY-MM-DD HH:mm"),
            drveTagt: "",
            drveUser: decrypt("userInfo").loginId,
            enddMile: 0,
            gateCost: 0,
            highPass: 0,
            moveGubn: "",
            passCost: 0,
            remark: "",
            statMile: 0,
            statSite: "",
            newCheck: true,
            idx: -1,
          },
          ...prev,
        ]);
      } else if (key === "fuel") {
        setFuelInfo(prev => [
          {
            acctCode: "",
            carrNumb: carDetail.carrNumb,
            crdtNumb: "",
            engrGubn: "",
            juyuAmnt: 0,
            juyuDate: moment().format("YYYY-MM-DD HH:mm"),
            juyuLitr: 0,
            juyuPlac: "",
            juyuUser: decrypt("userInfo").loginId,
            litrCost: 0,
            remark: "",
            runnMile: 0,
            newCheck: true,
            idx: -1,
          },
          ...prev,
        ]);
        setEditedRowListFuel(prev => [
          {
            acctCode: "",
            carrNumb: carDetail.carrNumb,
            crdtNumb: "",
            engrGubn: "",
            juyuAmnt: 0,
            juyuDate: moment().format("YYYY-MM-DD HH:mm"),
            juyuLitr: 0,
            juyuPlac: "",
            juyuUser: decrypt("userInfo").loginId,
            litrCost: 0,
            remark: "",
            runnMile: 0,
            newCheck: true,
            idx: -1,
          },
          ...prev,
        ]);
      }
    },
    [carDetail]
  );
  const btnSave = key => {
    if (key === "drive") {
      if (editedRowListDrive.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else {
        if (confirm("저장하시겠습니까?")) {
          saveDriveInfo.mutate(
            editedRowListDrive.map(el => {
              return {
                ...el,
                drveStat: moment(el.drveStat).format("YYYY-MM-DD HH:mm"),
                drveEndd: moment(el.drveEndd).format("YYYY-MM-DD HH:mm"),
              };
            })
          );
        }
      }
    } else if (key === "fuel") {
      if (editedRowListFuel.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else {
        if (confirm("저장하시겠습니까?")) {
          saveFuelInfo.mutate(
            editedRowListFuel.map(el => {
              return {
                ...el,
                juyuDate: moment(el.juyuDate).format("YYYY-MM-DD HH:mm"),
              };
            })
          );
        }
      }
    }
  };

  const onClickDeleteDrive = useCallback(data => deleteDriveInfo.mutate(data), []);
  const onClickDeleteFuel = useCallback(data => deleteFuelInfo.mutate(data), []);

  return (
    <>
      <div className="card card-sub mt-4">
        <div className="card-body">
          <Tabs
            id="controlled-tab-example2"
            activeKey={tabKey}
            onSelect={k => setTabKey(k)}
            className="tab-blocks"
          >
            <Tab eventKey="운행정보" title="운행정보">
              <Tab1 btnAdd={btnAdd} onClickDeleteDrive={onClickDeleteDrive} btnSave={btnSave} />
            </Tab>

            <Tab eventKey="주유정보" title="주유정보">
              <Tab2 onClickDeleteFuel={onClickDeleteFuel} btnAdd={btnAdd} btnSave={btnSave} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default React.memo(CarMasterList);
