import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai";
import {
  permissionEachMenu_mainData_atom,
  permissionEachMenu_mainDataModifyList_atom,
} from "../../../data/atom/codeManage/permissionEachMenu/permissionEachMenu_atom";
import { useEffect } from "react";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const PermissionEachMenu = () => {
  const { addTabClosedEvent } = useRecentTabEvent();

  const [mainData, setMainData] = useAtom(permissionEachMenu_mainData_atom);
  const [mainDataModifyList, setMainDataModifyList] = useAtom(
    permissionEachMenu_mainDataModifyList_atom
  );

  const authManageSearch = useQuery([], () => axios.get("/api/code/authManage/search"), {
    onSuccess: res => {
      setMainData(
        res.data.result.map(el => ({
          ...el,
          roleDbmaster: el?.roleDbmaster ?? "0",
          roleDealer: el?.roleDealer ?? "0",
          roleDealerMaster: el?.roleDealerMaster ?? "0",
          roleFinancial: el?.roleFinancial ?? "0",
          roleMarketing: el?.roleMarketing ?? "0",
          roleWorkSupport: el?.roleWorkSupport ?? "0",
        }))
      );
      setMainDataModifyList(Array.from({ length: res.data.result.length }, () => false));
    },
  });

  const authManageSave = useMutation(data => axios.post("/api/code/authManage/save", data), {
    onSuccess: res => {
      authManageSearch.refetch();
    },
  });

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setMainData([]);
      setMainDataModifyList([]);
    });
  }, []);

  const btn_register = () => {
    if (window.confirm("저장하시겠습니까?")) {
      const filter = mainData.filter((el, idx) => mainDataModifyList[idx]);

      if (filter.length === 0) {
        alert("변경된 내용이 없습니다.");
        return;
      }

      authManageSave.mutate(filter);
    }
  };

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">팀별 메뉴 사용권한</div>
          </div>
        </div>

        <div className="card card-sub mt-2">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end mb-2">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btn_register}
              >
                저장
              </button>
            </div>
            <div className="table-container table-sticky h-633">
              <table className="table table-bordered table-responsive my-table m-0">
                <thead>
                  <tr>
                    <th colSpan={3}>
                      보기 : ○ - 사용권한有 / X – 권한없음 / ▲ - 조건부 권한 부여 / V- View 기능
                    </th>
                    <th style={{ width: "8.33%" }}>딜러</th>
                    <th style={{ width: "8.33%" }}>딜러마스터</th>
                    <th style={{ width: "8.33%" }}>업무지원팀</th>
                    <th style={{ width: "8.33%" }}>마케팅팀</th>
                    <th style={{ width: "8.33%" }}>재경팀</th>
                    <th>전산관리자</th>
                  </tr>
                </thead>
                <tbody>
                  {mainData.length !== 0 ? (
                    mainData.reduce((acc, cur, idx, arr) => {
                      if (acc[acc.length - 1]?.props.id?.trim() === arr[idx + 1]?.pageName.trim()) {
                        return acc;
                      }

                      if (acc[acc.length - 1]?.props.id?.trim() === cur.pageName.trim()) {
                        return acc;
                      }

                      if (
                        +cur.level + 1 === +arr[idx + 1]?.level &&
                        +cur.level + 2 === +arr[idx + 2]?.level
                      ) {
                        acc = [
                          ...acc,
                          <tr id={arr[idx + 2].pageName}>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "1" && cur.pageName}
                            </td>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "1" && arr[idx + 1].pageName}
                              {cur.level === "2" && cur.pageName}
                            </td>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "1" && arr[idx + 2].pageName}
                              {cur.level === "2" && arr[idx + 1].pageName}
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 2].roleDealer}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 2].roleDealer = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 2] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 2].roleDealerMaster}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 2].roleDealerMaster = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 2] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 2].roleWorkSupport}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 2].roleWorkSupport = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 2] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 2].roleMarketing}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 2].roleMarketing = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 2] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 2].roleFinancial}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 2].roleFinancial = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 2] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 2].roleDbmaster}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 2].roleDbmaster = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 2] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                          </tr>,
                        ];
                      } else if (+cur.level + 1 === +arr[idx + 1]?.level) {
                        acc = [
                          ...acc,
                          <tr id={arr[idx + 1].pageName}>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "1" && cur.pageName}
                            </td>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "1" && arr[idx + 1].pageName}
                              {cur.level === "2" && cur.pageName}
                            </td>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "2" && arr[idx + 1].pageName}
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 1].roleDealer}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 1].roleDealer = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 1] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 1].roleDealerMaster}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 1].roleDealerMaster = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 1] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 1].roleWorkSupport}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 1].roleWorkSupport = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 1] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 1].roleMarketing}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 1].roleMarketing = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 1] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 1].roleFinancial}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 1].roleFinancial = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 1] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={arr[idx + 1].roleDbmaster}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx + 1].roleDbmaster = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx + 1] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                          </tr>,
                        ];
                      } else {
                        acc = [
                          ...acc,
                          <tr>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "1" && cur.pageName}
                            </td>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "2" && cur.pageName}
                            </td>
                            <td style={{ width: "16.66%" }} className="text-center">
                              {cur.level === "3" && cur.pageName}
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={cur.roleDealer}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx].roleDealer = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={cur.roleDealerMaster}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx].roleDealerMaster = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={cur.roleWorkSupport}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx].roleWorkSupport = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={cur.roleMarketing}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx].roleMarketing = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={cur.roleFinancial}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx].roleFinancial = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select text-center"
                                value={cur.roleDbmaster}
                                onChange={e => {
                                  const newMainData = [...mainData];
                                  newMainData[idx].roleDbmaster = e.target.value;
                                  setMainData(newMainData);

                                  const newMainDataModifyList = [...mainDataModifyList];
                                  newMainDataModifyList[idx] = true;
                                  setMainDataModifyList(newMainDataModifyList);
                                }}
                              >
                                <option value="0">○</option>
                                <option value="1">X</option>
                                <option value="2">▲</option>
                                <option value="3">V</option>
                              </select>
                            </td>
                          </tr>,
                        ];
                      }

                      return acc;
                    }, [])
                  ) : (
                    <tr>
                      <td colSpan={10}>검색결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
