import { useAtom } from "jotai/index";
import { recentTap_atom } from "../../data/atom/recentTap_atom";
import { useNavigate } from "react-router-dom";

export const useSidebarOnClickEvent = () => {
  const navigate = useNavigate();
  const defaultURL = process.env.PUBLIC_URL;

  const [recentTap, setRecentTap] = useAtom(recentTap_atom);

  const recentTapTmp = [...recentTap];

  const sidebarOnClickEvent = data => {
    if (recentTapTmp?.length >= 8) {
      recentTapTmp.pop();
    }

    if (recentTapTmp?.some(item => item.url === data.url)) {
      recentTapTmp.splice(
        recentTapTmp.findIndex(item => item.url === data.url),
        1
      );
    }

    setRecentTap([data, ...recentTapTmp]);
    navigate(defaultURL + data.url, {
      state: data,
    });
  };

  return { sidebarOnClickEvent };
};
