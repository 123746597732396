import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import ModalGreenFeeTableRowHeader from "./ModalGreenFeeTableRowHeader";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom, useAtomValue } from "jotai";
import { clickedRow_atom } from "../../../../data/atom/workManage/golfManage/golfManage_atom";
import ModalGreenFeeTableRow from "./ModalGreenFeeTableRow";

export default function ModalGreenFee(props) {
  const { show, setShow } = props;
  const clickedRow = useAtomValue(clickedRow_atom);
  const [greenFeeHistory, setGreenFeeHistory] = useState([]);

  const getGreenFeeHistory = useMutation(
    data => axios.get("/api/work/manageGolf/getGreenFeeDetail", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setGreenFeeHistory(data);
      },
    }
  );

  useEffect(() => {
    if (show && greenFeeHistory.length === 0) {
      getGreenFeeHistory.mutate(clickedRow);
    }
  }, [show]);

  const btnHide = () => {
    setGreenFeeHistory([]);
    setShow(false);
  };

  return (
    <Modal show={show} size={"xl"} centered onHide={btnHide}>
      <Modal.Header closeButton>
        <Modal.Title>기간별 그린피 관리</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-auto table-container table-sticky border-0 mb-3 h-633">
          <table
            className="table table-bordered table-responsive my-table1 mb-0"
            style={{ width: "90vw" }}
          >
            <tbody>
              <ModalGreenFeeTableRowHeader />
              {greenFeeHistory.length !== 0 ? (
                greenFeeHistory.map((el, idx) => {
                  return (
                    <ModalGreenFeeTableRow
                      data={el}
                      index={idx}
                      getGreenFeeHistory={getGreenFeeHistory}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={18}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-outline-primary" onClick={btnHide}>
            닫기
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

React.memo(ModalGreenFee);
