import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 최종학력 졸업 셀렉트박스
 */
export const useGetGrade = () => {
  return useQuery(
    ["getGrade"],
    async () =>
      await axios
        .get("/api/codeMng/select/getGrade")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
