import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 인사발령 셀렉트박스
 */
export const useGetWork = () => {
  return useQuery(
    ["getWork"],
    async () =>
      await axios
        .get("/api/codeMng/select/getWork")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
