import moment from "moment/moment";
import React, { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useAtomValue, useSetAtom } from "jotai/index";
import {
  editedSearchResult_atom,
  originSearchResult_atom,
  searchParam_atom,
} from "../../../../../data/atom/dataManage/golfMembershipPriceManage/individualMembershipPrice_atom";

const SearchResultRow = props => {
  const { data, index, onClick = () => {} } = props;
  const searchParam = useAtomValue(searchParam_atom);
  const setOriginData = useSetAtom(originSearchResult_atom);
  const setEditedData = useSetAtom(editedSearchResult_atom);

  const queryClient = useQueryClient();

  const individualGolfList = queryClient.getQueryData(["getIndividualGolfList"]);
  const userIdQ = queryClient.getQueryData(["getUserIdQ"]);

  const individualDelete = useMutation(data => axios.post("/api/data/individual/delete", data), {
    onSuccess: () => {
      if (onDeleteEvent) onDeleteEvent(index);
    },
  });

  const onChangeLocalEvent = (key, value) => {
    switch (key) {
      case "sise12":
      case "sise22":
        value = value.replaceAll(",", "");
        if (isNaN(Number(value))) return;
    }

    setEditedData(prev =>
      prev.map((i, idx) => {
        if (idx === index) return { ...data, [key]: value };
        else return i;
      })
    );
  };

  const btn_delete = () => {
    if (confirm("정말 삭제 하시겠습니까?"))
      individualDelete.mutate({
        searchType: searchParam.searchType,
        code: data.code,
        custId: data.custId,
        siseDate: moment(data.siseDate).format("YYYY-MM-DD HH:mm:ss"),
      });
  };

  // 테이블 로우의 삭제 이벤트
  const onDeleteEvent = useCallback(index => {
    setOriginData(prev => prev.filter((i, idx) => index !== idx));
    setEditedData(prev => prev.filter((i, idx) => index !== idx));
  }, []);

  const getCodename = useMemo(() => {
    return individualGolfList?.find(i => i.key === data.code)?.value;
  }, [individualGolfList, data.code]);

  return (
    <tr
      onClick={e => {
        if (e.target.tagName.toLowerCase() !== "td") return;
        onClick(data.code, getCodename);
      }}
      className={"cursor-pointer"}
    >
      <td>{index + 1}</td>
      <td>
        <select
          className="form-select"
          value={data.adminCheck}
          onChange={e => onChangeLocalEvent("adminCheck", e.target.value)}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.allGubn}
          onChange={e => onChangeLocalEvent("allGubn", e.target.value)}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
        </select>
      </td>
      <td>
        <select
          className="form-select"
          value={data.viewCheck}
          onChange={e => onChangeLocalEvent("viewCheck", e.target.value)}
        >
          <option value="Y">Y</option>
          <option value="N">N</option>
        </select>
      </td>
      <td>{data.code}</td>
      <td className={"text-start"}>{getCodename}</td>
      <td className={`${data.gubun11 === "2" ? "td_pink" : ""}`}>
        <input
          className="form-check-input"
          type={"checkbox"}
          checked={data.gubun11 && data.gubun11 === "2"}
          onChange={e => onChangeLocalEvent("gubun11", e.target.checked ? "2" : "1")}
        />
        분
      </td>
      <td className={`${data.gubun11 === "2" ? "td_pink" : ""}`}>
        <input
          type="text"
          className="form-control"
          style={{ textAlign: "right" }}
          value={data.sise12 && Number(data.sise12).toLocaleString("en-US")}
          onChange={e => onChangeLocalEvent("sise12", e.target.value)}
        />
      </td>
      <td className={`${data.gubun11 === "2" ? "td_pink" : ""}`}>
        <input
          type="text"
          className="form-control"
          style={{ textAlign: "right" }}
          value={data.sise22 && Number(data.sise22).toLocaleString("en-US")}
          onChange={e => onChangeLocalEvent("sise22", e.target.value)}
        />
      </td>
      <td
        style={{
          textAlign: "right",
          color: `${
            data.gubun11 === data.gubun21
              ? data.sise12 - data.sise11 > 0
                ? "red"
                : data.sise12 - data.sise11 < 0
                ? "blue"
                : ""
              : ""
          }`,
        }}
      >
        {data.gubun11 === data.gubun21
          ? `${data.sise12 - data.sise11 > 0 ? "▲" : data.sise12 - data.sise11 < 0 ? "▼" : ""} ${(
              data.sise12 - data.sise11
            ).toLocaleString("en-US")}`
          : 0}
      </td>
      <td
        style={{
          textAlign: "right",
          color: `${
            data.gubun11 === data.gubun21
              ? data.sise22 - data.sise21 > 0
                ? "red"
                : data.sise22 - data.sise21 < 0
                ? "blue"
                : ""
              : ""
          }`,
        }}
      >
        {data.gubun11 === data.gubun21
          ? `${data.sise22 - data.sise21 > 0 ? "▲" : data.sise22 - data.sise21 < 0 ? "▼" : ""} ${(
              data.sise22 - data.sise21
            ).toLocaleString("en-US")}`
          : 0}
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={data.remark}
          onChange={e => onChangeLocalEvent("remark", e.target.value)}
        />
      </td>
      <td>
        {data.modid === "server" ? "server" : userIdQ?.find(el => el.key === data.modid)?.value}
      </td>
      <td>{data.modDate && moment(data.modDate).format("YY/MM/DD HH:mm:ss")}</td>
      <td>
        <button className="btn btn-outline-secondary table-button" onClick={btn_delete}>
          삭제
        </button>
      </td>
    </tr>
  );
};

export default React.memo(SearchResultRow);
