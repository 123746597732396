import React, { useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const Five = ({ data }) => {
  const [tableData, setTableData] = useState({
    result: [],
    tableColumns: [],
    total: {},
    resultSum: {},
  });

  useEffect(() => {
    const newData = { ...data };

    newData.tableColumns = {
      minYymmdd: newData.result[0]?.minYymmdd ?? "",
      maxYymmdd: newData.result[0]?.maxYymmdd ?? "",
      minYymmddA: newData.result[0]?.minYymmddA ?? "",
      maxYymmddA: newData.result[0]?.maxYymmddA ?? "",
      minYymmddB: newData.result[0]?.minYymmddB ?? "",
      maxYymmddB: newData.result[0]?.maxYymmddB ?? "",
    };

    setTableData(newData);
  }, [data.result[0]]);

  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={
              {
                // width: `${
                // data.tableColumns?.length !== 0 ? data.tableColumns?.length * 120 + 500 + "px" : ""
                // }`,
                // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                // marginBottom: `${
                //   rowVirtualizer.getTotalSize() -
                //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                //   rowVirtualizer.getVirtualItems().length * 30
                // }px`,
              }
            }
          >
            <thead>
              <tr>
                <th rowSpan={2} style={{ width: "100px" }}>
                  연번
                </th>
                <th rowSpan={2} style={{ width: "150px" }}>
                  소속
                </th>
                <th rowSpan={2} style={{ width: "150px" }}>
                  성명
                </th>
                <th rowSpan={2} style={{ width: "100px" }}>
                  직위
                </th>
                <th colSpan={2}>
                  당월 <br />
                  {`${tableData.tableColumns.minYymmdd} ~ ${tableData.tableColumns.maxYymmdd}`}
                </th>
                <th colSpan={2}>
                  지난주 <br />
                  {`${tableData.tableColumns.minYymmddA} ~ ${tableData.tableColumns.maxYymmddA}`}
                </th>
                <th colSpan={2}>
                  금주 <br />
                  {`${tableData.tableColumns.minYymmddB} ~ ${tableData.tableColumns.maxYymmddB}`}
                </th>
              </tr>
              <tr>
                <th>건수</th>
                <th>실적</th>
                <th>건수</th>
                <th>실적</th>
                <th>건수</th>
                <th>실적</th>
              </tr>
            </thead>
            <tbody>
              {tableData.result?.length !== 0 ? (
                tableData.result.reduce((acc, cur, idx, arr) => {
                  if (cur?.deptName !== arr[idx - 1]?.deptName) {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{idx + 1}</td>
                        <td rowSpan={arr.filter(el => el.deptName === cur.deptName).length}>
                          {cur.deptName}
                        </td>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.excCnt}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt)}</td>
                        <td>{cur.excCntA}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmntA)}</td>
                        <td>{cur.excCntB}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmntB)}</td>
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{idx + 1}</td>
                        {/*<td>{cur.deptName}</td>*/}
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.excCnt}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt)}</td>
                        <td>{cur.excCntA}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmntA)}</td>
                        <td>{cur.excCntB}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmntB)}</td>
                      </tr>,
                    ];
                  }

                  // 소속 총계
                  if (cur?.deptName !== arr[idx + 1]?.deptName) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={4}>{cur.deptName} 계</td>
                        <td>{tableData?.resultSum?.[cur.job]?.excCnt ?? "-"}</td>
                        <td className="text-end">
                          {numberFixedFormat(tableData?.resultSum?.[cur.job]?.rsltAmnt)}
                        </td>
                        <td>{tableData?.resultSum?.[cur.job]?.excCntA ?? "-"}</td>
                        <td className="text-end">
                          {numberFixedFormat(tableData?.resultSum?.[cur.job]?.rsltAmntA)}
                        </td>
                        <td>{tableData?.resultSum?.[cur.job]?.excCntB ?? "-"}</td>
                        <td className="text-end">
                          {numberFixedFormat(tableData?.resultSum?.[cur.job]?.rsltAmntB)}
                        </td>
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td colSpan={4}>총계</td>
                        <td>{tableData?.total?.excCnt ?? "-"}</td>
                        <td className="text-end">
                          {numberFixedFormat(tableData?.total?.rsltAmnt)}
                        </td>
                        <td>{tableData?.total?.excCntA}</td>
                        <td className="text-end">
                          {numberFixedFormat(tableData?.total?.rsltAmntA)}
                        </td>
                        <td>{tableData?.total?.excCntB}</td>
                        <td className="text-end">
                          {numberFixedFormat(tableData?.total?.rsltAmntB)}
                        </td>
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={10}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
