import EmpModal from "../../../../../components/modal/Modal";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useVirtualizer } from "@tanstack/react-virtual";

export const SendSMSConfirmModal = ({
  isSendSMSConfirmModalOpen,
  setIsSendSMSConfirmModalOpen,
}) => {
  const targetRef = useRef(null);

  const [searchData, setSearchData] = useState({
    fromDate: moment().subtract(2, "month").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    viewFlag: "A",
  });
  const [searchedTableList, setSearchedTableList] = useState({ list: [] });

  const customerSearchSendSms = useMutation(
    data => axios.get("/api/work/customer/right/searchSendSms", { params: data }),
    {
      onSuccess: res => {
        const { list, msg } = res.data.result;

        // if (list.length === 0) {
        alert(msg);
        // }
        setSearchedTableList(res.data.result);
      },
    }
  );

  useEffect(() => {
    if (!isSendSMSConfirmModalOpen.isOpen) {
      setSearchData({
        fromDate: moment().subtract(2, "month").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        viewFlag: "A",
      });

      setSearchedTableList({ list: [] });
    }
  }, [isSendSMSConfirmModalOpen.isOpen]);

  const btn_search = () => {
    customerSearchSendSms.mutate(searchData);
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: searchedTableList.list?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });


  return (
    <EmpModal
      show={isSendSMSConfirmModalOpen.isOpen}
      width="100%"
      minWidth="475px"
      title="문자 전송 확인"
      onHide={() => setIsSendSMSConfirmModalOpen({ data: null, isOpen: false })}
      strClass="modal-scroll"
      size="xl"
      footer={
        <div className="d-flex justify-content-end m-0">
          <button
            className="btn btn-outline-primary btn-filter-outline-primary"
            onClick={() =>
              setIsSendSMSConfirmModalOpen({
                data: null,
                isOpen: false,
              })
            }
          >
            닫기
          </button>
        </div>
      }
    >
      <div className="d-flex justify-content-between" onKeyDown={pressEnter}>
        <div className="d-flex align-items-center">
          <label>전송일자</label>
          <input
            type="date"
            max="9999-12-31"
            className="form-control filter-input filter1-input2"
            value={searchData.fromDate}
            onChange={e => {
              setSearchData({ ...searchData, fromDate: e.target.value });
            }}
          />
          <div className="txt_daterange">~</div>
          <input
            type="date"
            max="9999-12-31"
            className="form-control filter-input filter1-input2"
            value={searchData.toDate}
            onChange={e => {
              setSearchData({ ...searchData, toDate: e.target.value });
            }}
          />

          <div className="d-flex ms-4">
            <label className="filter1-label form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked={searchData.viewFlag === "A"}
                onChange={e => {
                  setSearchData({ ...searchData, viewFlag: "A" });
                }}
              />
              전체조회
            </label>
            <label className="filter1-label form-check ms-4">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked={searchData.viewFlag === "T"}
                onChange={e => {
                  setSearchData({ ...searchData, viewFlag: "T" });
                }}
              />
              성공건만
            </label>
            <label className="filter1-label form-check ms-4">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked={searchData.viewFlag === "F"}
                onChange={e => {
                  setSearchData({ ...searchData, viewFlag: "F" });
                }}
              />
              실패건만
            </label>
          </div>
        </div>

        <div>
          <button
            className="btn btn-outline-primary btn-filter-outline-primary me-0"
            onClick={btn_search}
          >
            조회
          </button>
        </div>
      </div>

      <div className="table-container table-sticky h-633 mt-2" ref={targetRef}>
        <table
          className="table table-bordered table-responsive my-table"
          style={{
            marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
            marginBottom: `${
              rowVirtualizer.getTotalSize() -
              (rowVirtualizer.range?.startIndex ?? 0) * 30 -
              rowVirtualizer.getVirtualItems().length * 30
            }px`,
          }}
        >
          <thead>
            <tr>
              <th rowSpan={2}>구분</th>
              <th rowSpan={2}>전송일시</th>
              <th rowSpan={2}>예약전송 일시</th>
              <th colSpan={4}>고객정보</th>
              <th rowSpan={2}>전송여부</th>
              <th rowSpan={2}>전송결과</th>
              <th rowSpan={2}>전송자</th>
              <th rowSpan={2}>발신번호</th>
              <th rowSpan={2}>전송내용</th>
            </tr>
            <tr>
              <th>관리번호</th>
              <th>고객명</th>
              <th>업체명</th>
              <th>수신번호</th>
            </tr>
          </thead>
          <tbody>
            {searchedTableList.list?.length !== 0 ? (
              // (searchedTableList.list?.map(el => (
              //   <tr>
              //     <td>{el.sendType}</td>
              //     <td>{el?.sendDate ? moment(el.sendDate).format("YYYY-MM-DD") : ""}</td>
              //     <td>{el?.optionTime ? moment(el.optionTime).format("YYYY-MM-DD") : ""}</td>
              //     <td>{el.custId}</td>
              //     <td>{el.kname}</td>
              //     <td>{el.company}</td>
              //     <td>{el.rcvPhoneNumber}</td>
              //     <td>{el.flag}</td>
              //     <td>{el.msg}</td>
              //     <td>{el.userName}</td>
              //     <td>{el.sndPhoneNumber}</td>
              //     <td>{el.sendMsg}</td>
              //   </tr>
              // )))

              rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                return (
                  <tr key={searchedTableList.list[virtualRow.index].seqNo}>
                    <td>{searchedTableList.list[virtualRow.index].sendType}</td>
                    <td>
                      {searchedTableList.list[virtualRow.index]?.sendDate
                        ? moment(searchedTableList.list[virtualRow.index].sendDate).format(
                            "YYYY-MM-DD"
                          )
                        : ""}
                    </td>
                    <td>
                      {searchedTableList.list[virtualRow.index]?.optionTime
                        ? moment(searchedTableList.list[virtualRow.index].optionTime).format(
                            "YYYY-MM-DD"
                          )
                        : ""}
                    </td>
                    <td>{searchedTableList.list[virtualRow.index].custId}</td>
                    <td>{searchedTableList.list[virtualRow.index].kname}</td>
                    <td>{searchedTableList.list[virtualRow.index].company}</td>
                    <td>{searchedTableList.list[virtualRow.index].rcvPhoneNumber}</td>
                    <td>
                      {searchedTableList.list[virtualRow.index].flag === "0"
                        ? "미전송"
                        : searchedTableList.list[virtualRow.index].flag === "1"
                        ? "전송 中"
                        : searchedTableList.list[virtualRow.index].flag === "2" && "전송 완료"}
                    </td>
                    <td>{searchedTableList.list[virtualRow.index].msg}</td>
                    <td>{searchedTableList.list[virtualRow.index].userName}</td>
                    <td>{searchedTableList.list[virtualRow.index].sndPhoneNumber}</td>
                    <td>{searchedTableList.list[virtualRow.index].sendMsg}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={12}>검색결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </EmpModal>
  );
};
