import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import {
  companyAccountManage_addData,
  companyAccountManage_backupData,
  companyAccountManage_getData,
  companyAccountManage_isAdd,
  companyAccountManage_modeData,
} from "../../../../data/atom/personManage/accountManage/companyAccountManage/companyAccountManage_atom";
import { axios } from "../../../../util/axios/axios";
import { useGetBank } from "../../../../api/code/useGetBank";
import { useGetAccountGubun } from "../../../../api/code/useGetAccountGubun";
import moment from "moment";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { useEffect } from "react";

const CompanyAccountManage = () => {
  const queryClient = useQueryClient();
  const [accountData, setAccountData] = useAtom(companyAccountManage_getData);
  const [isAdd, setIsAdd] = useAtom(companyAccountManage_isAdd);
  const [backupData, setBackupData] = useAtom(companyAccountManage_backupData); // 수정 저장 취소시 백업 데이터
  const [modeData, setModeData] = useAtom(companyAccountManage_modeData);
  const [addData, setAddData] = useAtom(companyAccountManage_addData);
  const { data: getBankData } = useGetBank();
  const { data: getAccountGubunData } = useGetAccountGubun();
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setAccountData([]);
      setIsAdd(false);
      setBackupData([]);
      setModeData([]);
      setAddData({
        gubun: "1001",
        ilNo: 0,
        modDate: "",
        openDate: "",
        outDate: "",
        payAccount: "",
        payBank: "99",
        payName: "",
        remark: "",
        userCheck: "Y",
      });
    });
  }, []);

  // API - 회사 계좌 조회
  const getAccount = useQuery(
    ["getAccount"],
    async () =>
      await axios.get("/api/emp/coAccount/getAccount").then(res => {
        const resData = res?.data?.result;
        const modData = resData?.map(data => {
          const { openDate, outDate } = data;
          const isOpenDate = openDate === null ? "" : openDate;
          const isOutDate = outDate === null ? "" : outDate;

          return { ...data, openDate: isOpenDate, outDate: isOutDate };
        });
        if (modeData.length === 0 && !isAdd) {
          setAccountData(modData);
          setBackupData(modData);
        }
        return modData;
      })
  );

  // API - 회사 계좌 삭제
  const deleteAccount = useMutation(data => axios.post("/api/emp/coAccount/delete", data), {
    onSuccess: res => {
      queryClient.invalidateQueries(["getAccount"]);
    },
  });

  // API - 회사 계좌 수정 / 저장
  const saveAccount = useMutation(data => axios.post("/api/emp/coAccount/save", data), {
    onSuccess: res => {
      queryClient.invalidateQueries(["getAccount"]);
      setIsAdd(false);
      setModeData([]);
      setAddData({
        gubun: "1001",
        ilNo: 0,
        modDate: "",
        openDate: "",
        outDate: "",
        payAccount: "",
        payBank: "99",
        payName: "",
        remark: "",
        userCheck: "Y",
      });
    },
  });

  return (
    <>
      <div className="content-inner">
        <div className="filter_header_row">
          <div className="filter-title">회사계좌관리 </div>
        </div>
        <div className="card mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end mb-10px">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                onClick={() => {
                  if (!isAdd) {
                    setIsAdd(true);
                  }
                }}
              >
                추가
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                onClick={() => {
                  if (isAdd) {
                    saveAccount.mutate([...modeData, addData]);
                  } else {
                    saveAccount.mutate(modeData);
                  }
                }}
              >
                저장
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0 ms-2"
                onClick={() => {
                  setIsAdd(false);
                  setAccountData(backupData);
                  setModeData([]);
                  setAddData({
                    gubun: "1001",
                    ilNo: 0,
                    modDate: "",
                    openDate: "",
                    outDate: "",
                    payAccount: "",
                    payBank: "99",
                    payName: "",
                    remark: "",
                    userCheck: "Y",
                  });
                }}
              >
                취소
              </button>
            </div>
            <div className="overflow-auto table-container table-sticky h-633">
              <table className="table table-bordered table-responsive my-table mt-0 mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>연번</th>
                    <th style={{ width: "11.27%" }}>관리구분</th>
                    <th style={{ width: "11.27%" }}>은행</th>
                    <th style={{ width: "11.27%" }}>구좌번호</th>
                    <th style={{ width: "11.27%" }}>예금주</th>
                    <th style={{ width: "11.27%" }}>사용여부</th>
                    <th style={{ width: "11.27%" }}>개설일자</th>
                    <th style={{ width: "11.27%" }}>폐기일자</th>
                    <th style={{ width: "11.27%" }}>비고</th>
                    <th style={{ width: "6%" }}>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  {isAdd && (
                    <tr>
                      {/* 끝번호 */}
                      <td></td>
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData.gubun}
                          onChange={e => {
                            setAddData({ ...addData, gubun: e.target.value });
                          }}
                        >
                          {getAccountGubunData?.map(item => {
                            return <option value={item?.key}>{item?.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData.payBank}
                          onChange={e => {
                            setAddData({ ...addData, payBank: e.target.value });
                          }}
                        >
                          {getBankData?.map(item => {
                            return <option value={item?.key}>{item?.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={addData.payAccount}
                          onChange={e => {
                            setAddData({
                              ...addData,
                              payAccount: e.target.value.replace(/[^0-9-]/g, ""),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={addData.payName}
                          onChange={e => {
                            setAddData({ ...addData, payName: e.target.value });
                          }}
                        />
                      </td>
                      <td>
                        <select
                          className="form-select table-select"
                          value={addData.userCheck}
                          onChange={e => {
                            setAddData({ ...addData, userCheck: e.target.value });
                          }}
                        >
                          <option value="Y">사용</option>
                          <option value="N">중지</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          value={addData.openDate}
                          onChange={e => {
                            setAddData({
                              ...addData,
                              openDate: moment(e.target.value).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          value={addData.outDate}
                          onChange={e => {
                            setAddData({
                              ...addData,
                              outDate: moment(e.target.value).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={addData.remark}
                          onChange={e => {
                            setAddData({ ...addData, remark: e.target.value });
                          }}
                        />
                      </td>
                      <td
                        role="button"
                        onClick={() => {
                          setIsAdd(false);
                          setAddData({
                            gubun: "1001",
                            ilNo: 0,
                            modDate: "",
                            openDate: "",
                            outDate: "",
                            payAccount: "",
                            payBank: "99",
                            payName: "",
                            remark: "",
                            userCheck: "Y",
                          });
                        }}
                      >
                        취소
                      </td>
                    </tr>
                  )}
                  {accountData?.length > 0
                    ? accountData.map((data, _idx) => {
                        return (
                          <tr className={data?.userCheck === "N" && "bg-red"}>
                            <td>{_idx + 1}</td>
                            <td>
                              <select
                                className="form-select table-select"
                                value={data?.gubun}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return { ...item, gubun: e.target.value };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        gubun: e.target.value,
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      gubun: e.target.value,
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              >
                                {getAccountGubunData?.map(item => {
                                  return <option value={item?.key}>{item?.value}</option>;
                                })}
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select table-select"
                                value={data?.payBank}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return { ...item, payBank: e.target.value };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        payBank: e.target.value,
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      payBank: e.target.value,
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              >
                                {getBankData?.map(item => {
                                  return <option value={item?.key}>{item?.value}</option>;
                                })}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={data?.payAccount}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return {
                                        ...item,
                                        payAccount: e.target.value.replace(/[^0-9-]/g, ""),
                                      };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        payAccount: e.target.value,
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      payAccount: e.target.value,
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={data?.payName}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return { ...item, payName: e.target.value };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        payName: e.target.value,
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      payName: e.target.value,
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              />
                            </td>
                            <td>
                              <select
                                className="form-select table-select"
                                value={data?.userCheck}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return { ...item, userCheck: e.target.value };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        userCheck: e.target.value,
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      userCheck: e.target.value,
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              >
                                <option value="Y">사용</option>
                                <option value="N">중지</option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="date"
                                max={"9999-12-31"}
                                className="form-control table-input"
                                value={data?.openDate}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return {
                                        ...item,
                                        openDate: moment(e.target.value).format("YYYY-MM-DD"),
                                      };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        openDate: moment(e.target.value).format("YYYY-MM-DD"),
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      openDate: moment(e.target.value).format("YYYY-MM-DD"),
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                max={"9999-12-31"}
                                className="form-control table-input"
                                value={data?.outDate}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return {
                                        ...item,
                                        outDate: moment(e.target.value).format("YYYY-MM-DD"),
                                      };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        outDate: moment(e.target.value).format("YYYY-MM-DD"),
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      outDate: moment(e.target.value).format("YYYY-MM-DD"),
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input"
                                value={data?.remark}
                                onChange={e => {
                                  const changeData = accountData.map(item => {
                                    if (item.ilNo === data.ilNo) {
                                      return { ...item, remark: e.target.value };
                                    } else {
                                      return { ...item };
                                    }
                                  });

                                  const isData = element => {
                                    return element.ilNo === data.ilNo;
                                  };
                                  const idx = modeData.findIndex(isData);
                                  if (idx === -1) {
                                    setModeData([
                                      ...modeData,
                                      {
                                        ...data,
                                        remark: e.target.value,
                                      },
                                    ]);
                                  } else {
                                    const newData = {
                                      ...data,
                                      remark: e.target.value,
                                    };
                                    modeData[idx] = newData;
                                  }
                                  setAccountData(changeData);
                                }}
                              />
                            </td>
                            <td
                              role="button"
                              onClick={() => {
                                deleteAccount.mutate({ ilNo: data?.ilNo });
                              }}
                            >
                              <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />{" "}
                            </td>
                          </tr>
                        );
                      })
                    : !isAdd && (
                        <tr>
                          <td colSpan={10}>조회결과가 없습니다.</td>
                        </tr>
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyAccountManage;
