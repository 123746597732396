import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment/moment";
import Modal from "../../../../../components/modal/Modal";

const Tab10 = ({ custId }) => {
  const [tableData, setTableData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const clientQuery = useQueryClient();
  const bunyangGubun = clientQuery.getQueryData(["getBunyangGubun"]);

  const bottomGetSale = useMutation(
    data =>
      axios.get("/api/work/manageGolf/bottom/getSale", {
        params: { custId: data.custId },
      }),
    {
      onSuccess: (res, param) => {
        const { result } = res.data;
        setTableData([...result]);

        if (param.type === "cancel") alert("취소되었습니다.");
      },
    }
  );

  const bottomSaveSale = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/saveSale", data),
    {
      onSuccess: () => {
        bottomGetSale.mutate({ custId: custId });
        setModalShow(false);
      },
    }
  );

  const bottomDeleteSale = useMutation(
    data => axios.post("/api/work/manageGolf/bottom/deleteSale", data),
    {
      onSuccess: () => {
        bottomGetSale.mutate({ custId: custId });
      },
    }
  );

  useEffect(() => {
    if (custId) bottomGetSale.mutate({ custId: custId });
  }, [custId]);
  useEffect(() => {
    if (custId) bottomGetSale.mutate({ custId: custId });
  }, []);

  const onChangeModalData = (key, value) => {
    setModalData({ ...modalData, [key]: value });
  };

  const btn_add = () => {
    setModalShow(true);
    setModalData({
      custId: custId,
      gubun: "1",
      saleName: "",
      saleDate: "",
      returnTime: "",
      saleWon: 0,
      saleInwon: 0,
      remark: "",
      detail: "",
    });
  };

  const btn_remove = seqNo => {
    if (confirm("정말 삭제 하시겠습니까?"))
      bottomDeleteSale.mutate({ custId: custId, seqNo: seqNo });
  };

  const btn_save = () => {
    bottomSaveSale.mutate({
      ...modalData,
      saleDate: modalData?.saleDate && moment(modalData.saleDate).format("YYYYMMDD"),
      modDate: "",
    });
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={"분양정보"}
        footer={
          <>
            <button className="btn btn-outline-primary" onClick={() => setModalShow(false)}>
              취소
            </button>
            <button className="btn btn-outline-primary" onClick={btn_save}>
              저장
            </button>
          </>
        }
      >
        <div>
          <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
            <tbody>
              <tr>
                <th style={{ width: "15%" }}>구분</th>
                <td>
                  <select
                    className="form-select"
                    value={modalData?.gubun}
                    onChange={e => onChangeModalData("gubun", e.target.value)}
                  >
                    {bunyangGubun?.map(i => (
                      <option value={i.key}>{i.value}</option>
                    ))}
                  </select>
                </td>
                <th style={{ width: "15%" }}>모집일자</th>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    max={"9999-12-31"}
                    value={modalData?.saleDate && moment(modalData.saleDate).format("YYYY-MM-DD")}
                    onChange={e =>
                      onChangeModalData("saleDate", moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>회원권명</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={modalData?.saleName}
                    onChange={e => onChangeModalData("saleName", e.target.value)}
                  />
                </td>
                <th>인원</th>
                <td>
                  <input
                    type="text"
                    className="form-control text-end"
                    value={modalData?.saleInwon}
                    onChange={e => onChangeModalData("saleInwon", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  분양금액
                  <br />
                  (만원)
                </th>
                <td>
                  <input
                    type="text"
                    className="form-control text-end"
                    value={modalData?.saleWon ? (+modalData.saleWon).toLocaleString("en-US") : ""}
                    onChange={e =>
                      onChangeModalData(
                        "saleWon",
                        e.target.value.replace(/,/g, "").replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </td>
                <th>반환기간</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={modalData?.returnTime}
                    onChange={e => onChangeModalData("returnTime", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>비고</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control"
                    value={modalData?.remark}
                    onChange={e => onChangeModalData("remark", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>상세</th>
                <td colSpan={3}>
                  <textarea
                    className="form-control my-1"
                    rows={5}
                    value={modalData?.detail}
                    onChange={e => onChangeModalData("detail", e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="tab2-content-container">
        <div className="table-container table-sticky mb-3 h-333">
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>순번</th>
                <th style={{ width: "5%" }}>구분</th>
                <th style={{ width: "10%" }}>모집일자</th>
                <th style={{ width: "10%" }}>회원권명</th>
                <th style={{ width: "10%" }}>인원</th>
                <th style={{ width: "10%" }}>분양금액0(만원)</th>
                <th>반환기간</th>
                <th style={{ width: "35%" }}>비고</th>
                <th style={{ width: "5%" }}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      setModalShow(true);
                      setModalData({
                        ...item,
                        saleDate:
                          item.saleDate &&
                          (item.saleDate.length == 6
                            ? moment(item.saleDate, "YYYYMM").format("YYYY-MM")
                            : moment(item.saleDate, "YYYYMMDD").format("YYYY-MM-DD")),
                      });
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{bunyangGubun?.find(i => i.key === item.gubun)?.value}</td>
                    <td>
                      {item.saleDate &&
                        (item.saleDate.length == 6
                          ? moment(item.saleDate, "YYYYMM").format("YYYY/MM/")
                          : moment(item.saleDate, "YYYYMMDD").format("YYYY/MM/DD"))}
                    </td>
                    <td>{item?.saleName}</td>
                    <td className="text-end">{item?.saleInwon}</td>
                    <td className="text-end">{(item?.saleWon ?? 0).toLocaleString("en-US")}</td>
                    <td>{item?.returnTime}</td>
                    <td>{item?.remark}</td>
                    <td
                      onClick={e => {
                        e.stopPropagation();
                        btn_remove(item.seqNo);
                      }}
                    >
                      <a href={void 0}>
                        <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    검색된 결과가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-end my-2">
          <button className="btn btn-outline-primary" onClick={btn_add}>
            작성
          </button>
        </div>
      </div>
    </>
  );
};

export default React.memo(Tab10);
