const usePrintTab = () => {
  const openPDf = async data => {
    const blob = new Blob([data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const win = window.open(url, "_blank");

    if (win) {
      win.document.title = "your new title";
      win.onload = function () {
        // win.print();
      };
    }
  };

  return openPDf;

  // return (
  //   <Modal
  //     // show={show}
  //     className={strClass}
  //     onHide={() => {
  //       if (onHide) onHide();
  //     }}
  //     onShow={() => {
  //       if (onShow) onShow();
  //     }}
  //     centered
  //     size={size}
  //   >
  //     <Modal.Header closeButton>
  //       <Modal.Title className={title_class}>출력 미리보기</Modal.Title>
  //       {typeof header_buttons != "undefined" && <React.Fragment>{header_buttons}</React.Fragment>}
  //     </Modal.Header>
  //     <Modal.Body
  //       style={{
  //         width: width,
  //         minWidth: minWidth,
  //         display: "flex",
  //         height: "100vh",
  //         overflow: "scroll",
  //         flexDirection: "column",
  //       }}
  //     >
  //       <button
  //         className="btn btn-outline-primary btn-filter-outline-primary ms-auto ms-8px"
  //         onClick={() => {
  //           // fileDownload(pdfData?.data, pdfData?.name);
  //           openPDf();
  //         }}
  //       >
  //         출력
  //       </button>
  //       <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
  //         {Array.from(new Array(numPages), (el, index) => (
  //           <Page
  //             height={500}
  //             width={1200}
  //             key={`page_${index + 1}`}
  //             renderTextLayer={false}
  //             pageNumber={index + 1}
  //             renderAnnotationLayer={false}
  //           />
  //         ))}
  //       </Document>
  //     </Modal.Body>
  //     {typeof footer != "undefined" && <Modal.Footer>{footer}</Modal.Footer>}
  //   </Modal>
  // );
};
export default usePrintTab;
