import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";
import moment from "moment";

// 통화알림 팝업
export const Initial1 = () => {
  const [data, setData] = useState([]);

  // 통화 알림
  const initialAlertCallBook = useQuery(
    ["initialAlertCallBook"],
    async () => await axios.get("/api/work/customer/alertCallBook").then(res => res.data.result)
  );

  useEffect(() => {
    if (initialAlertCallBook.isSuccess) {
      setData([...initialAlertCallBook.data]);
    }
  }, [initialAlertCallBook.isSuccess]);

  const displayCustId = value => {
    if (value && value.length > 4) {
      return value.substring(0, 4) + "-" + value.substring(4);
    } else return "";
  };

  return (
    <div className="card card-sub ">
      <div className="filter1_container">
        <div className="filter_header_row">
          <div className="filter-title mt-10 ms-2 me-2">통화예약알림</div>
        </div>
      </div>
      <div className="col-left mt-10 ms-2 me-2">
        <div className="text-center">
          <label className="form-check-label">오늘 {data.length}건의 통화예약이 있습니다.</label>
        </div>

        <table className="table table-bordered table-responsive my-table">
          <thead>
            <tr>
              <th style={{ width: "15.38%" }}>예약통화일시</th>
              <th style={{ width: "23.07%" }}>관리번호</th>
              <th style={{ width: "30.76%" }}>회사명</th>
              <th style={{ width: "15.38%" }}>성명</th>
              <th>직위</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map(i => (
                <tr>
                  <td className="text-center">
                    {i.requestDate && moment(i.requestDate).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td className="text-center">{displayCustId(i.custId)}</td>
                  <td className="text-center">{i.company && i.company}</td>
                  <td className="text-center">{i.kname && i.kname}</td>
                  <td className="text-center">{i.position && i.position}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  검색된 통화알림이 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2 mb-2 me-2">
        <button className="btn btn-outline-primary ms-8px" onClick={() => window.close()}>
          닫기
        </button>
      </div>
    </div>
  );
};
