import React, { useState } from "react";
import { useAtom } from "jotai/index";
import { transactionMain_atom } from "../../../data/atom/dataManage/transactionProgress/transactionProgress_atom";
import ModalTextPreset from "./modal/ModalTextPreset";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import moment from "moment";

const TransactionProgressReport = props => {
  const { searchTransactionMain } = props;
  const [transactionMain, setTransactionMain] = useAtom(transactionMain_atom);
  const [modalShow, setModalShow] = useState(false);

  const saveTransactionReportTop = useMutation(
    data => axios.post("/api/data/transactionManage/bottom/saveLeft", data),
    {
      onSuccess: (res, variables) => {
        searchTransactionMain.mutate({
          ...variables,
          isSaveReportTop: true,
          remark: "",
          submitDoc: "",
        });
      },
    }
  );
  const saveTransactionReportBottom = useMutation(
    data => axios.post("/api/data/transactionManage/bottom/saveRight", data),
    {
      onSuccess: (res, variables) => {
        searchTransactionMain.mutate({
          ...variables,
          isSaveReportBottom: true,
          remark: "",
          submitDoc: "",
        });
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    if (key === "writeFlag" || key === "commiFlag" || key === "yangdFlag" || key === "chidkFlag") {
      let tmp = "";
      if (
        (key === "writeFlag" && value === true) ||
        (key !== "writeFlag" && transactionMain.writeFlag === "Y")
      ) {
        tmp +=
          "※ 상기내역 중 명의개서료는 골프장 측에서 관할관청의 허가를 득한 후, 취득한 물건의 명의를 매도자에서 매수자로 변경 처리할 때 발생되는 부대비용입니다.\n\n";
      }
      if (
        (key === "chidkFlag" && value === true) ||
        (key !== "chidkFlag" && transactionMain.chidkFlag === "Y")
      ) {
        tmp +=
          "※ 취득세는 일정한 자산의 취득에 대하여 부과하는 조세를 말합니다. 국세 중 지방세로 취급되며 이중 골프회원권 및 콘도미니엄회원권 등은 지방세법 제 105조에 의거하여 취득한 자산에 대하여 취득가액 2%와 취득세액의 10%인 농어촌특별세를 포함하여 총 취득가액의 2.2%를 부과 받게되며, 취득신고 및 고지서 발행 업무까지 당사 세무지원 파트에서 전담 대행하여 신고 및 발행합니다.\n\n";
      }
      if (
        (key === "commiFlag" && value === true) ||
        (key !== "commiFlag" && transactionMain.commiFlag === "Y")
      ) {
        tmp +=
          "※ 중개 수수료는 당사 동아회원권㈜에서 매도자와 매수자의 중개를하고 매매 관련 서류인수 및 양수 신고대행, 취득 신고 등 회원권 거래에 대한 전반적인 사후처리를 전담하게 되며, 이에 따른 수수료 비용을 산출하여 책정된 부대비용 입니다.\n\n";
      }
      if (
        (key === "yangdFlag" && value === true) ||
        (key !== "yangdFlag" && transactionMain.yangdFlag === "Y")
      ) {
        tmp +=
          "※ 양도세는 국세로 분류되며 일정한 자산의 매각에 따른 양도소득에 대하여 부과되는 조세입니다. 추정계산기간은 당해년도 1월 1일부터 매각일까지 부동산 또는 회원권 관련하여 첫 매각의 기준으로 기본공제 250만원을 받는 조건으로 계산되며, 혹시 이번 회원권 매각하기 전, 당해년도 기간안에 부동산이나 다른 회원권을 매각 하셨다면 기신고된 양도소득세 내역서를 보내주시면 합산하여 신고하여 드리겠습니다.\n\n" +
          "※ 양도세 관련하여 당사 세무지원 파트에서 관련서류를 취합하여 거래 시점으로부터 약 3개월 이내에 자진 신고대행 및 양도세 고지세를 보내드리게 되며, 해당 고지서를 수령하신 후 가까운 금융기관에 납부하시면 됩니다.\n\n";
      }
      setTransactionMain({ ...transactionMain, [key]: value ? "Y" : "N", remark: tmp });
    } else {
      setTransactionMain({ ...transactionMain, [key]: value });
    }
  };

  const btnTextPreset = () => {
    setModalShow(true);
  };
  const btnSaveTop = () => {
    if (confirm("저장하시겠습니까?")) {
      saveTransactionReportTop.mutate(transactionMain);
    }
  };
  const btnSaveBottom = () => {
    if (confirm("저장하시겠습니까?")) {
      saveTransactionReportBottom.mutate({
        ...transactionMain,
      });
    }
  };

  return (
    <>
      <ModalTextPreset show={modalShow} setShow={setModalShow} />
      <div className="card card-main">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="table-tabs-sub w-50 m-0">필요서류</div>
            <div className="tab1-row1-field1 w-50 justify-content-end">
              <button className="btn btn-outline-primary mx-3" onClick={btnTextPreset}>
                필요 문구 셋팅
              </button>
              <button className="btn btn-outline-primary" onClick={btnSaveTop}>
                저장
              </button>
            </div>
          </div>

          <table className="table table-bordered table-responsive my-table mt-3 mb-0">
            <thead>
              <tr>
                <th>필요서류</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-1">
                  <textarea
                    className="form-control w-100"
                    rows={1}
                    value={transactionMain.submitDoc}
                    onChange={e => {
                      onChangeEventHandler("submitDoc", e.target.value);
                    }}
                    style={{ height: "150px" }}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="table-tabs-sub m-0">서류인수 일정</div>
            <button className="btn btn-outline-primary" onClick={btnSaveBottom}>
              저장
            </button>
          </div>

          <table className="table table-bordered table-responsive my-table1 mt-10px">
            <tbody>
              <tr>
                <th style={{ width: "11.9%" }}>날짜</th>
                <td>
                  <input
                    type="date"
                    max={"9999-12-31"}
                    className="form-control table-input"
                    value={moment(transactionMain.schDate).format("YYYY-MM-DD")}
                    onChange={e => onChangeEventHandler("schDate", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>시간</th>
                <td>
                  <input
                    type="time"
                    className="form-control table-input"
                    value={
                      transactionMain.schTime
                        ? moment(transactionMain.schTime, "HH:mm").format("HH:mm")
                        : ""
                    }
                    onChange={e => onChangeEventHandler("schTime", e.target.value)}
                  />
                </td>
                <th style={{ width: "11.9%" }}>비고</th>
                <td style={{ width: "38.09%" }} colSpan={4}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.schRemark}
                    onChange={e => onChangeEventHandler("schRemark", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ width: "11.9%" }}>장소</th>
                <td colSpan={3} style={{ width: "38.09%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={transactionMain.schPlace}
                    onChange={e => onChangeEventHandler("schPlace", e.target.value)}
                  />
                </td>
                <th>기타 개서료 선택</th>
                <td className="text-center">
                  <label htmlFor="chk-a" className="chk-sm me-1">
                    <input
                      id="chk-a"
                      className="me-2"
                      type="checkbox"
                      checked={transactionMain.writeFlag === "Y"}
                      onChange={e => onChangeEventHandler("writeFlag", e.target.checked)}
                    />
                    명의
                  </label>
                </td>
                <td className="text-center">
                  <label htmlFor="chk-b" className="chk-sm me-1">
                    <input
                      id="chk-b"
                      className="me-2"
                      type="checkbox"
                      checked={transactionMain.chidkFlag === "Y"}
                      onChange={e => onChangeEventHandler("chidkFlag", e.target.checked)}
                    />
                    취득세
                  </label>
                </td>
                <td className="text-center">
                  <label htmlFor="chk-c" className="chk-sm me-1">
                    <input
                      id="chk-c"
                      className="me-2"
                      type="checkbox"
                      checked={transactionMain.commiFlag === "Y"}
                      onChange={e => onChangeEventHandler("commiFlag", e.target.checked)}
                    />
                    중개
                  </label>
                </td>
                <td className="text-center">
                  <label htmlFor="chk-d" className="chk-sm me-1">
                    <input
                      id="chk-d"
                      className="me-2"
                      type="checkbox"
                      checked={transactionMain.yangdFlag === "Y"}
                      onChange={e => onChangeEventHandler("yangdFlag", e.target.checked)}
                    />
                    양도
                  </label>
                </td>
              </tr>
              <tr>
                <th>기타사항</th>
                <td className="py-1" colSpan={8}>
                  <textarea
                    className="form-control w-100"
                    rows={1}
                    value={transactionMain.remark}
                    onChange={e => {
                      onChangeEventHandler("remark", e.target.value);
                    }}
                    style={{ height: "300px" }}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(TransactionProgressReport);
