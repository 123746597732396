import { useAtomValue } from "jotai";
import { transactionHistoryClickedRow_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment/moment";

const init = {
  ilNo: "",
  custDate: "",
  custId: "",
  tradGubn: "3",
  gubun1: "",
  gubun2: "",
  roomNo: "",
  memberNo: "",
  yangdoWon2: "",
  yangdoWon1: "",
  downYn: "Y",
  commission: "",
  partner: "",
  differCheck: "Y",
  remark: "",
  jisa: "",
  taxTarget: "Y",
  sinDate: "",
  yangdoTaxTarget: "Y",
  jiroSendDate: "",
  taxWon: "",
  taxJumin: "",
  saleGu: "1",
  jisaGerae: "N",
  seqNo: "",
};
const searchDetailBottomTab1 = () => {
  const atomData = useAtomValue(transactionHistoryClickedRow_atom);
  const [cacheData, setCacheData] = useState(atomData);
  const [listData, setListData] = useState([]);
  const clientQuery = useQueryClient();
  const memberNm = clientQuery.getQueryData(["getMemberNm"]);
  const userIdQ = clientQuery.getQueryData(["getUserIdQ"]);
  const jisaGubunAllQ1 = clientQuery.getQueryData(["getJisaGubunAllQ1"]);
  const etcCmQ = clientQuery.getQueryData(["getEtcCmQ"]);
  const etcSmQ = clientQuery.getQueryData(["getEtcSmQ"]);

  // 조회 API
  const getTransactionHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getTransactionHistory", {
        params: { custId: data.seqNo },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setListData(result);
      },
    }
  );

  // 수정 API
  const saveTransactionHistory = useMutation(data =>
    axios.post("/api/work/customer/bottom/saveTransactionHistory", {
      commission: data.commission,
      custDate: formatDate(data.custDate),
      custId: data.custId,
      differCheck: data.differCheck,
      downYn: data.downYn,
      gubun1: data.gubun1,
      gubun2: data.gubun2,
      ilNo: data.ilNo,
      jisa: data.jisa,
      memberNo: data.memberNo,
      partner: data.partner,
      remark: data.remark,
      roomNo: data.roomNo,
      sinDate: formatDate(data.sinDate),
      taxJumin: data.taxJumin,
      taxTarget: data.taxTarget,
      taxWon: data.taxWon,
      tradGubn: data.tradGubn,
      yangdoTaxTarget: data.yangdoTaxTarget,
      yangdoWon1: data.yangdoWon1,
      yangdoWon2: data.yangdoWon2,
      saleGu: data.saleGu,
      seqNo: data.seqNo,
      jisaGerae: data.jisaGerae,
    })
  );

  // 삭제 API
  const deleteTransactionHistory = useMutation(
    data =>
      axios.post("/api/work/customer/bottom/deleteTransactionHistory", {
        commission: data.commission,
        custDate: formatDate(data.custDate),
        custId: data.custId,
        differCheck: data.differCheck,
        downYn: data.downYn,
        gubun1: data.gubun1,
        gubun2: data.gubun2,
        ilNo: data.ilNo,
        jisa: data.jisa,
        memberNo: data.memberNo,
        partner: data.partner,
        remark: data.remark,
        roomNo: data.roomNo,
        sinDate: formatDate(data.sinDate),
        taxJumin: data.taxJumin,
        taxTarget: data.taxTarget,
        taxWon: data.taxWon,
        tradGubn: data.tradGubn,
        yangdoTaxTarget: data.yangdoTaxTarget,
        yangdoWon1: data.yangdoWon1,
        yangdoWon2: data.yangdoWon2,
        seqNo: data.seqNo,
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        alert("삭제 되었습니다.");
      },
      onError: ({ response }) => {
        let res = response.data.result;
        alert("삭제가 실패했습니다.");
      },
    }
  );

  const formatDate = date => {
    if (date !== null && date !== "" && date !== undefined)
      return moment(date).format("YYYY-MM-DD");
    else return null;
  };

  useEffect(() => {
    setCacheData(atomData);

    if (atomData) {
      getTransactionHistory.mutate(atomData);
    } else setListData([]);
  }, [atomData]);

  const onChangeEvent = (index, key, value) => {
    let tmp = listData.map(i => i);
    tmp[index][key] = value;
    setListData(tmp);
  };

  const btn_update = data => {
    saveTransactionHistory.mutate(data);
  };

  const btn_delete = data => {
    if (confirm(" 삭제 하시겠습니까?")) deleteTransactionHistory.mutate(data);
    else alert("취소 되었습니다.");
  };

  const displayTradGubn = value => {
    switch (value) {
      case "3":
        return "개인물건";
      case "4":
        return "법인물건";
      case "8":
        return "타샵물건";
      default:
        return "";
    }
  };
  const displayGubun1 = (value1, value2) => {
    let str = "";
    switch (value1) {
      case "1":
        str += "실거래";
        break;
      case "2":
        str += "분양";
        break;
      case "3":
        str += "증여";
        break;
      case "4":
        str += "상속";
        break;
      case "5":
        str += "명의변경";
        break;
      default:
        str += "";
    }
    str += "-";

    switch (value2) {
      case "N":
        str += "지사內";
        break;
      case "Y":
        str += "타지사";
        break;
      case "S":
        str += "샵거래";
        break;
      default:
        str += "";
    }

    return str;
  };

  const displayGubun2 = (gubun2, gubun1) => {
    let str = "";
    switch (gubun2) {
      case "1":
        str += "골프";
        break;
      case "2":
        str += "콘도";
        break;
      case "3":
        str += "헬스";
        break;
      default:
    }
    switch (gubun1) {
      case "1":
        str += "(" + "양도" + ")";
        break;
      case "2":
        str += "(" + "양수" + ")";
    }
    return str;
  };

  const displayRoomNo = (gubun, value) => {
    switch (gubun) {
      case "1":
        return (memberNm ?? []).find(item => item.code === value)?.codename ?? "";
      case "2":
        return (etcCmQ ?? []).find(item => item.key === value)?.value ?? "";
      case "3":
        return (etcSmQ ?? []).find(item => item.key === value)?.value ?? "";
    }
  };

  const displayPartner = value => (userIdQ ?? []).find(item => item.key === value)?.value ?? "";
  const displayJisa = value =>
    (jisaGubunAllQ1.data ?? []).find(item => item.key === value)?.value ?? "";

  const displayDownYn = value => {
    switch (value) {
      case "Y":
        return "인증";
      case "B":
        return "인정안됨";
      case "D":
        return "해당無";
      default:
        return "";
    }
  };

  const displayTaxTarget = value => {
    switch (value) {
      case "Y":
        return "대상";
      case "N":
        return "비대상";
      case "R":
        return "관청접수";
      case "S":
        return "완료";
      default:
        return "";
    }
  };

  const displayYangdoTaxTarget = value => {
    switch (value) {
      case "Y":
        return "대상";
      case "N":
        return "비대상";
      case "H":
        return "발송대기";
      case "S":
        return "완료";
      default:
        return "";
    }
  };

  const displayDifferCheck = value => {
    switch (value) {
      case "Y":
        return "Y";
      default:
        return "";
    }
  };
  return (
    <div className="table-container">
      <div className="tab2-content-container px-20px">
        <div
          className={`table-container table-sticky 
               h-183`}
          id="scroll-table-body"
        >
          <table className="table table-bordered table-responsive my-table table-fit m-0">
            <thead>
              <tr>
                <th style={{ width: "2.55%" }}>No.</th>
                <th>일자</th>
                <th>거래분류</th>
                <th>구분1</th>
                <th>구분2</th>

                <th>회원권명</th>
                <th>회원증No.</th>
                <th>서류금액</th>
                <th>매매금액</th>
                <th>UP DOWN</th>

                <th>수수료</th>
                <th>거래 딜러</th>
                <th>名상이</th>
                <th>기타(샵 명, 회원증명)</th>
                <th>소속지사</th>

                <th>세무신고</th>
                <th>신고일자</th>
                <th>고객등기</th>
                <th>등기 발송일</th>
                <th>납부세액(원)</th>

                <th>주민세(원)</th>
                {/*<th className="px-0" style={{ width: "2.55%" }}>
                수정
              </th>
              <th style={{ width: "2.55%" }}>삭제</th>*/}
              </tr>
            </thead>
            <tbody>
              {listData.length > 0 ? (
                listData.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {/*<input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control table-input w-135px px-1"
                      value={moment(item?.custDate).format("YYYY-MM-DD")}
                      onChange={e => onChangeEvent(index, "custDate", e.target.value)}
                    />*/}
                      {item.custDate && moment(item.custDate).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      {displayTradGubn(item.tradGubn)}
                      {/*<select
                      className="form-select"
                      value={item?.tradGubn}
                      onChange={e => onChangeEvent(index, "tradGubn", e.target.value)}
                    >
                      <option value="3">개인물건</option>
                      <option value="4">법인물건</option>
                      <option value="8">타샵물건</option>
                    </select>*/}
                    </td>
                    <td>
                      {/*<select
                      className="form-select"
                      value={item?.jisaGerae}
                      onChange={e => onChangeEvent(index, "jisaGerae", e.target.value)}
                    >
                      <option value="N">지사內</option>
                      <option value="Y">타지사</option>
                      <option value="S">샵거래</option>
                    </select>*/}
                      {displayGubun1(item.saleGu, item.jisaGerae)}
                    </td>
                    <td>
                      {displayGubun2(item.gubun2, item.gubun1)}
                      {/*<select
                      className="form-select"
                      value={item?.saleGu}
                      onChange={e => onChangeEvent(index, "saleGu", e.target.value)}
                    >
                      <option value="1">실거래</option>
                      <option value="2">분양</option>
                      <option value="3">증여</option>
                      <option value="4">상속</option>
                      <option value="5">명의변경</option>
                    </select>*/}
                    </td>
                    <td>
                      {displayRoomNo(item.gubun2, item.roomNo)}
                      {/*<select
                      className="form-select"
                      value={item?.roomNo}
                      onChange={e => onChangeEvent(index, "roomNo", e.target.value)}
                    >
                      {memberNm.data?.map(item => (
                        <option value={item.code}>{item.codename}</option>
                      ))}
                    </select>*/}
                    </td>
                    <td>
                      {/*<input
                      type="text"
                      className="form-control table-input"
                      value={item?.memberNo}
                      onChange={e => onChangeEvent(index, "memberNo", e.target.value)}
                    />*/}
                      {item.memberNo ?? ""}
                    </td>
                    <td>
                      {/*<input
                      type="text"
                      className="form-control table-input"
                      value={item?.yangdoWon2}
                      onChange={e => onChangeEvent(index, "yangdoWon2", e.target.value)}
                    />*/}
                      {Number(item.yangdoWon2 ?? "0").toLocaleString("en-US")}
                    </td>
                    <td>
                      {/*<input
                      type="text"
                      className="form-control table-input"
                      value={item?.yangdoWon1}
                      onChange={e => onChangeEvent(index, "yangdoWon1", e.target.value)}
                    />*/}
                      {Number(item.yangdoWon1 ?? "0").toLocaleString("en-US")}
                    </td>
                    <td>
                      {displayDownYn(item.downYn)}
                      {/*<select
                      className="form-select"
                      value={item?.downYn}
                      onChange={e => onChangeEvent(index, "downYn", e.target.value)}
                    >
                      <option value="Y">인증</option>
                      <option value="B">인정안됨</option>
                      <option value="D">해당無</option>
                    </select>*/}
                    </td>
                    <td>
                      {/*<input
                      type="text"
                      className="form-control table-input"
                      value={item?.commission}
                      onChange={e => onChangeEvent(index, "commission", e.target.value)}
                    />*/}
                      {Number(item.commission ?? "0").toLocaleString("en-US")}
                    </td>
                    <td>
                      {displayPartner(item.partner)}
                      {/*
                    <select
                      className="form-select"
                      value={item?.partner}
                      onChange={e => onChangeEvent(index, "partner", e.target.value)}
                    >
                      {userIdQ?.map(item => (
                        <option value={item.key}>{item.value}</option>
                      ))}
                    </select>*/}
                    </td>
                    <td>
                      {/*<select
                      className="form-select"
                      value={item?.differCheck}
                      onChange={e => onChangeEvent(index, "differCheck", e.target.value)}
                    >
                      <option value="Y">Y</option>
                      <option value="N"></option>
                    </select>*/}
                      {displayDifferCheck(item.differCheck)}
                    </td>
                    <td>
                      {/*<input
                      type="text"
                      className="form-control table-input"
                      value={item?.remark}
                      onChange={e => onChangeEvent(index, "remark", e.target.value)}
                    />*/}
                      {item.remark ?? ""}
                    </td>
                    <td>
                      {/*<select
                      className="form-select"
                      value={item?.jisa}
                      onChange={e => onChangeEvent(index, "jisa", e.target.value)}
                    >
                      {jisaGubunAllQ1.data?.map(item => (
                        <option value={item.key}>{item.value}</option>
                        ))}
                    </select>*/}
                      {displayJisa(item.jisa)}
                    </td>
                    <td>
                      {displayTaxTarget(item.taxTarget)}
                      {/*<select
                      className="form-select"
                      value={item?.taxTarget}
                      onChange={e => onChangeEvent(index, "taxTarget", e.target.value)}
                    >
                      <option value="Y">대상</option>
                      <option value="N">비대상</option>
                      <option value="R">관청접수</option>
                      <option value="S">완료</option>
                    </select>*/}
                    </td>
                    <td>
                      {/*<input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control table-input"
                      value={item?.sinDate && moment(item.sinDate).format("YYYY-MM-DD")}
                      onChange={e => onChangeEvent(index, "sinDate", e.target.value)}
                    />*/}
                      {item.sinDate && moment(item.sinDate).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      {/*<select
                      className="form-select"
                      value={item?.yangdoTaxTarget}
                      onChange={e => onChangeEvent(index, "yangdoTaxTarget", e.target.value)}
                    >
                      <option value="Y">대상</option>
                      <option value="N">비대상</option>
                      <option value="H">발송대기</option>
                      <option value="S">완료</option>
                    </select>*/}
                      {displayYangdoTaxTarget(item.yangdoTaxTarget)}
                    </td>
                    <td>
                      {/*<input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control table-input"
                      disabled={true}
                      value={item?.jiroSendDate && moment(item.jiroSendDate).format("YYYY-MM-DD")}
                    />*/}
                      {item.jiroSendDate && moment(item.jiroSendDate).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      {/*<input
                      type="text"
                      className="form-control table-input"
                      value={item?.taxWon}
                      onChange={e => onChangeEvent(index, "taxWon", e.target.value)}
                    />*/}
                      {Number(item.taxWon ?? "0").toLocaleString("en-US")}
                    </td>

                    <td>
                      {/*<input
                      type="text"
                      className="form-control table-input"
                      value={item?.taxJumin}
                      onChange={e => onChangeEvent(index, "taxJumin", e.target.value)}
                    />*/}
                      {Number(item.taxJumin ?? "0").toLocaleString("en-US")}
                    </td>
                    {/*<td>
                    <a href={void 0}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/icon-edit.png"}
                        alt=""
                        onClick={() => btn_update(item)}
                      />
                    </a>
                  </td>
                  <td>
                    <a href={void 0}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/icon-trash.png"}
                        alt=""
                        onClick={() => btn_delete(item)}
                      />
                    </a>
                  </td>*/}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="21" className="text-center">
                    검색된 고객이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default searchDetailBottomTab1;
