import { useAtomValue } from "jotai";
import { transactionHistoryClickedRow_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import moment from "moment";

const searchDetailBottomTab2 = () => {
  const atomData = useAtomValue(transactionHistoryClickedRow_atom);
  const [cacheData, setCacheData] = useState(atomData);
  const [listData, setListData] = useState([]);

  // 조회 API
  const getCallHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getCallHistory", {
        params: { custId: data.seqNo },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        setListData(result);
      },
    }
  );

  useEffect(() => {
    setCacheData(atomData);
    if (atomData) {
      getCallHistory.mutate(atomData);
    } else setListData([]);
  }, [atomData]);

  const displayViewChk = viewChk => {
    switch (viewChk) {
      case "1":
        return "일반";
      case "2":
        return "상위";
      case "R":
        return "녹취";
      case "N":
        return "부재";
      default:
        return "";
    }
  };

  return (
    <div className="tab2-content-container px-20px">
      <div className="table-tabs-sub mb-15px">통화기록 ({listData.length}건)</div>
      <div className="table-container">
        <div
          className={`table-container table-sticky 
               h-183`}
          id="scroll-table-body"
        >
          <table className="table table-bordered table-responsive my-table m-0">
            <thead>
              <tr>
                <th style={{ width: "8%" }}>연번</th>
                <th style={{ width: "25%" }}>통화일자</th>
                <th style={{ width: "15%" }}>통화자</th>
                <th style={{ width: "10%" }}>View</th>
                <th>통화 내용</th>
              </tr>
            </thead>
            <tbody>
              {listData.length > 0 ? (
                listData.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{moment(item.talkDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{item.talkName}</td>
                    <td>{displayViewChk(item.viewChk)}</td>
                    <td className="text-start">{item.remark}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    통화내역이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default searchDetailBottomTab2;
