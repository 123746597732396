import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useAtom } from "jotai/index";
import { taxGolfOption_atom } from "../../../../data/atom/dataManage/quotationManagement/quotationManagement_atom";
import { useGetChangeOx } from "../../../../api/code/useGetChangeOx";

const ModalCalculator = props => {
  const { show, onHide } = props;
  const taxInit = {
    radio: "P",
    select: "P",
    down: false,
    idx: -1,
    chpWon: 0,
    sum: 0,
    contWon: 0,
    maesuWon: 0,
    gaesuWon: 0,
    famRegWon: 0,
    charge: 0,
    consultWon: 0,
  };
  const resultInit = {
    maemaeWon: 0,
    gaesuWon: 0,
    famRegWon: 0,
    charge: 0,
    consultWon: 0,
    sum: 0,
  };

  const getChangeOx = useGetChangeOx();
  const [taxGolfOption, setTaxGolfOption] = useAtom(taxGolfOption_atom);
  const [taxList, setTaxList] = useState([]);
  const [tax, setTax] = useState({ ...taxInit });
  const [result, setResult] = useState({});
  const [golfData, setGolfData] = useState({});
  const [currentGolf, setCurrentGolf] = useState("00");

  const searchGolfList = useMutation(
    data => axios.get("/api/codeMng/select/getGolfCustList", { params: data }),
    {
      onSuccess: res => {
        const data = res.data?.result;
        setTaxGolfOption(data);
      },
    }
  );
  const searchTaxList = useMutation(
    data => axios.get("/api/data/estimate/middle/getCal", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;
        setTax({ ...taxInit });
        setResult({ ...resultInit });
        setTaxList(data);
      },
    }
  );
  const searchGolfAddress = useMutation(
    data => axios.get("/api/data/estimate/addres", { params: data }),
    {
      onSuccess: res => {
        const data = res.data?.result?.[0];

        setGolfData(data);
      },
    }
  );

  useEffect(() => {
    if (taxGolfOption.length === 0) searchGolfList.mutate();
  }, []);

  useEffect(() => {
    if (taxGolfOption.length !== 0 && show) {
      searchTaxList.mutate({ custId: "00" });
    }
  }, [show]);

  const onChangeEventHandler = (key, value) => {
    if (key === "golfSelect") {
      setCurrentGolf(value);
      searchTaxList.mutate({ custId: value });
      searchGolfAddress.mutate({ custId: value });
    }
  };

  const onChangeLeftPanelEventHandler = (key, value) => {
    const calc = (tmpTax, key, value) => {
      let itax = { ...tmpTax };
      if (tmpTax.radio === "P" && (tmpTax.select === "P" || tmpTax.select === "D")) {
        const consultWon =
          (key === "down" && value) || tmpTax.down
            ? 0
            : key === "consultWon"
            ? value
            : tmpTax.maesuWon >= tmpTax.contWon && tmpTax.maesuWon >= tmpTax.chpWon
            ? tmpTax.maesuWon - tmpTax.contWon
            : 0;
        const gfSum = tmpTax.gaesuWon + tmpTax.famRegWon;

        itax = {
          ...tmpTax,
          charge: 0,
          consultWon: consultWon,
          sum: tmpTax.contWon + consultWon + gfSum,
          maesuWon:
            tax.maesuWon === tax.contWon && key === "contWon" ? tmpTax.contWon : tmpTax.maesuWon,
        };
      } else if (tmpTax.radio === "P" && tmpTax.select === "C") {
        const consultWon =
          (key === "down" && value) || tmpTax.down
            ? 0
            : key === "consultWon"
            ? value
            : tmpTax.maesuWon >= tmpTax.contWon
            ? Math.round(((tmpTax.maesuWon - tmpTax.contWon) * 10) / 11)
            : 0;
        const gfSum = tmpTax.gaesuWon + tmpTax.famRegWon;

        itax = {
          ...tmpTax,
          charge: 0,
          consultWon: consultWon,
          sum: Math.round((tmpTax.contWon * 10) / 11) + consultWon + gfSum,
          maesuWon:
            tax.maesuWon === tax.contWon && key === "contWon"
              ? Math.round((tmpTax.contWon * 10) / 11)
              : tmpTax.maesuWon,
        };
      } else if (
        (tmpTax.radio === "C" && (tmpTax.select === "P" || tmpTax.select === "D")) ||
        (tmpTax.radio === "D" && (tmpTax.select === "P" || tmpTax.select === "D"))
      ) {
        const consultWon =
          key === "consultWon"
            ? value
            : tmpTax.maesuWon >= tmpTax.contWon
            ? Math.round(((tmpTax.maesuWon - tmpTax.contWon) * 10) / 11)
            : 0;
        const gfcSum = tmpTax.gaesuWon + tmpTax.famRegWon + tmpTax.charge;

        itax = {
          ...tmpTax,
          consultWon: consultWon,
          sum: tmpTax.contWon + consultWon + gfcSum,
        };
      } else if (
        (tmpTax.radio === "C" && tmpTax.select === "C") ||
        (tmpTax.radio === "D" && tmpTax.select === "C")
      ) {
        const consultWon =
          key === "consultWon"
            ? value
            : tmpTax.maesuWon >= tmpTax.contWon
            ? Math.round(((tmpTax.maesuWon - tmpTax.contWon) * 10) / 11)
            : 0;
        const gfcSum = tmpTax.gaesuWon + tmpTax.famRegWon + tmpTax.charge;

        itax = {
          ...tmpTax,
          consultWon: consultWon,
          sum: Math.round((tmpTax.contWon * 10) / 11) + consultWon + gfcSum,
        };
      }

      return itax;
    };

    if (
      key !== "radioP" &&
      key !== "radioC" &&
      key !== "radioD" &&
      key !== "select" &&
      key !== "down"
    ) {
      value = Number(value.replace(/,/g, "").replace(/[^0-9]/g, ""));
    }

    let tmpTax = { ...tax };

    if (key === "radioP") {
      tmpTax = { ...tmpTax, radio: "P" };
    } else if (key === "radioC") {
      tmpTax = { ...tmpTax, radio: "C" };
    } else if (key === "radioD") {
      tmpTax = { ...tmpTax, radio: "D" };
    } else {
      if (
        (tmpTax.radio === "C" || tmpTax.radio === "D") &&
        ((key === "contWon" && value > tmpTax.maesuWon) ||
          (key === "maesuWon" && value < tmpTax.contWon))
      ) {
        alert("장부가액은 계약서 금액보다 작을 수 없습니다.");
      } else {
        tmpTax = { ...tmpTax, [key]: value };
      }
    }

    tmpTax = calc(tmpTax, key, value);
    setTax(tmpTax);
  };

  const onChangeRightPanelEventHandler = (key, value) => {
    if (new RegExp(/[^0-9]/).test(value)) {
      return;
    }

    if (key === "maemaeWon") {
      setResult({
        ...result,
        [key]: value,
        sum: value + result.gaesuWon + result.famRegWon + result.charge + result.consultWon,
      });
    } else if (key === "gaesuWon") {
      setResult({
        ...result,
        [key]: value,
        sum: result.maemaeWon + value + result.famRegWon + result.charge + result.consultWon,
      });
    } else if (key === "famRegWon") {
      setResult({
        ...result,
        [key]: value,
        sum: result.maemaeWon + result.gaesuWon + value + result.charge + result.consultWon,
      });
    } else if (key === "charge") {
      setResult({
        ...result,
        [key]: value,
        sum: result.maemaeWon + result.gaesuWon + result.famRegWon + value + result.consultWon,
      });
    } else if (key === "consultWon") {
      setResult({
        ...result,
        [key]: value,
        sum: result.maemaeWon + result.gaesuWon + result.famRegWon + result.charge + value,
      });
    }
  };

  const btnReset = () => {
    setResult({ ...resultInit });
  };

  const btnCaculate = () => {
    if (tax.sum === 0) {
      alert("취득가액이 존재하지 않습니다.");
      return;
    }
    if (tax.radio === "P" && (tax.select === "P" || tax.select === "D")) {
      if (tax.chpWon > tax.maesuWon) {
        setResult({
          ...result,
          maemaeWon: tax.chpWon,
          sum: tax.chpWon,
        });
      } else {
        setResult({
          ...result,
          maemaeWon: tax.contWon,
          gaesuWon: tax.gaesuWon,
          famRegWon: tax.famRegWon,
          consultWon: tax.consultWon,
          sum: tax.contWon + tax.gaesuWon + tax.famRegWon + tax.consultWon,
        });
      }
    } else if (
      (tax.radio === "P" && tax.select === "C") ||
      tax.radio === "C" ||
      tax.radio === "D"
    ) {
      if (tax.maesuWon >= tax.contWon) {
        setResult({
          ...result,
          maemaeWon: tax.contWon,
          gaesuWon: tax.gaesuWon,
          famRegWon: tax.famRegWon,
          charge: tax.charge,
          consultWon: tax.consultWon,
          sum: tax.contWon + tax.gaesuWon + tax.famRegWon + tax.charge + tax.consultWon,
        });
      }
    }
  };

  const clickRow = (el, idx) => {
    let tmpTax = { ...taxInit };

    if (
      golfData?.governCustId === "1016" ||
      golfData?.governCustId === "1122" ||
      golfData?.governCustId === "1124"
    ) {
      tmpTax = {
        ...tmpTax,
        famRegWon: Math.round((el.gaesuCorp * 10) / 11),
      };

      if (el.etcType === "31") {
        if (el.gaesuJujung !== 0) {
          tmpTax = {
            ...tmpTax,
            gaesuWon: Math.round((el.gaesuPersonal * 10) / 11),
          };
        } else {
          tmpTax = {
            ...tmpTax,
            gaesuWon: Math.round((el.gaesuJujung * 10) / 11),
          };
        }
      } else {
        tmpTax = {
          ...tmpTax,
          gaesuWon: Math.round((el.gaesuPersonal * 10) / 11),
        };
      }
    }

    setTax({
      ...tmpTax,
      chpWon: el.chpWon,
      idx: idx,
      code: el.code,
      custId: el.custId,
    });

    // if (tax.radio === "P" && tax.select === "P") {
    //   setTax({ ...taxInit, chpWon: clickedRow.chpWon, idx: idx });
    // } else if (
    //   (tax.radio === "C" || tax.radio === "D") &&
    //   (golfData?.governCustId === "1016" ||
    //     golfData?.governCustId === "1122" ||
    //     golfData?.governCustId === "1124" ||
    //     golfData?.addres?.includes("기흥구") ||
    //     golfData?.addres?.includes("처인구") ||
    //     golfData?.addres?.includes("남양주시"))
    // ) {
    //   if (clickedRow.etcType === "31" && clickedRow.gaesuJujung === 0) {
    //     setTax({
    //       ...taxInit,
    //       gaesuWon: clickedRow.gaesuPersonal,
    //       famRegWon: clickedRow.gaesuCorp,
    //       idx: idx,
    //     });
    //   } else if (clickedRow.etcType === "31" && clickedRow.gaesuJujung !== 0) {
    //     setTax({
    //       ...taxInit,
    //       gaesuWon: clickedRow.gaesuJujung,
    //       famRegWon: clickedRow.gaesuCorp,
    //       idx: idx,
    //     });
    //   } else {
    //     setTax({
    //       ...taxInit,
    //       gaesuWon: clickedRow.gaesuPersonal,
    //       famRegWon: clickedRow.gaesuCorp,
    //       idx: idx,
    //     });
    //   }
    // } else {
    //   setTax({ ...taxInit, idx: idx });
    // }
  };

  const pressEnterCalc = e => {
    if (e.key === "Enter") {
      btnCaculate();
    }
  };

  return (
    <Modal
      className="modal-xl"
      show={show}
      centered
      size="xl"
      onHide={() => {
        setTax({ ...taxInit });
        setTaxList([]);
        setResult({ ...resultInit });
        setGolfData({});
        setCurrentGolf("00");
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>취득세 산출</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title m-0">▶ 취득과표</h5>
          <p className="p_quot mb-0">
            기흥구, 처인구, 남양주시 관할 골프장은 VAT를 제외한 개서료+가족등록비를 포함해야 한다.
          </p>
        </div>
        <div className="filter_fields_container m-0 mt-2">
          <div className="filter_fields_row">
            <div className="d-flex align-items-center justify-content-between">
              <div className="filter1-field filter1-field1 ps-0">
                <label className="filter1-label">골프장</label>
                <select
                  className="form-select filter1-select1"
                  value={currentGolf}
                  onChange={e => onChangeEventHandler("golfSelect", e.target.value)}
                >
                  <option value="00">전체</option>
                  {taxGolfOption.length !== 0 ? (
                    taxGolfOption.map(el => {
                      return <option value={el.key}>{el.value}</option>;
                    })
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div className="filter1-field filter1-field2">
                <label className="filter1-label">
                  {currentGolf !== "00" ? `소재지 : ${golfData?.addres}` : ""}
                </label>
                <label className="filter1-label">
                  {golfData?.governCustId === "1016" ||
                  golfData?.governCustId === "1122" ||
                  golfData?.governCustId === "1124" ? (
                    <>(개서료, 가족등록비 포함대상 골프장)</>
                  ) : (
                    <></>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="table-container table-sticky" style={{ maxHeight: "267px" }}>
          <table className="table table-bordered table-responsive my-table m-0">
            <thead>
              <tr>
                <th style={{ width: "4%" }} rowSpan={2}>
                  연번
                </th>
                <th style={{ width: "10%" }} rowSpan={2}>
                  회원권종목명
                </th>
                <th style={{ width: "10%" }} rowSpan={2}>
                  전환유무
                </th>
                <th style={{ width: "9%" }} rowSpan={2}>
                  고시일자
                </th>
                <th style={{ width: "9%" }} rowSpan={2}>
                  취득과표
                </th>
                <th colSpan={7}>골프장 개서료(vat포함)</th>
              </tr>
              <tr>
                <th style={{ width: "7%" }}>정회원</th>
                <th style={{ width: "7%" }}>주중회원</th>
                <th style={{ width: "7%" }}>가족등록비</th>
                <th style={{ width: "7%" }}>지명인변경</th>
                <th style={{ width: "7%" }}>상속</th>
                <th style={{ width: "7%" }}>중여</th>
                <th>기타</th>
              </tr>
            </thead>
            <tbody>
              {taxList.length !== 0 ? (
                taxList.map((el, idx) => {
                  return (
                    <tr
                      className={el.code === tax.code && el.custId === tax.custId && "bg-blue"}
                      onClick={() => clickRow(el, idx)}
                    >
                      <td>{idx + 1}</td>
                      <td>{el.codename}</td>
                      <td>
                        {el.changeOx
                          ? getChangeOx.data?.find(el2 => el2.key === el.changeOx)?.value
                          : ""}
                      </td>
                      <td>{el.chpDate}</td>
                      <td>{Number(el.chpWon).toLocaleString()}</td>
                      <td>{Number(el.gaesuPersonal).toLocaleString()}</td>
                      <td>{Number(el.gaesuJujung).toLocaleString()}</td>
                      <td>{Number(el.gaesuCorp).toLocaleString()}</td>
                      <td>{Number(el.gaesuRename).toLocaleString()}</td>
                      <td>{Number(el.gaesuInherit).toLocaleString()}</td>
                      <td>{Number(el.gaesuDonation).toLocaleString()}</td>
                      <td>{el.gaesuEtc}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={12}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="row mt-3">
          <div className="col-6">
            <h5 className="card-title m-0 mb-3">▶ 조건선택</h5>
            <div className="filter_fields_row mb-2">
              <div className="d-flex align-items-center justify-content-start">
                <div className="form-check form-check-inline my-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={tax.radio === "P"}
                    onChange={e => onChangeLeftPanelEventHandler("radioP", e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    개인매수 취득세 산출
                  </label>
                </div>
              </div>
            </div>
            <div className="filter_fields_row mb-2">
              <div className="d-flex align-items-center justify-content-start">
                <div className="form-check form-check-inline my-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    checked={tax.radio === "C"}
                    onChange={e => onChangeLeftPanelEventHandler("radioC", e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    과세법인 매수 취득세산출
                  </label>
                </div>
                <div className="form-check form-check-inline my-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    checked={tax.radio === "D"}
                    onChange={e => onChangeLeftPanelEventHandler("radioD", e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    면세법인 매수 취득세산출
                  </label>
                </div>
              </div>
            </div>

            <hr className="m-0 p-0" />

            <div className="d-flex mt-2">
              <div className="d-flex align-items-center justify-content-center w-50">
                <div className="filter1-field filter1-field1 p-0">
                  <label className="filter1-label">매도는</label>
                  <select
                    className="form-select filter1-select1"
                    value={tax.select}
                    onChange={e => onChangeLeftPanelEventHandler("select", e.target.value)}
                  >
                    <option value="P">개인</option>
                    <option value="C">과세법인</option>
                    <option value="D">면세법인</option>
                  </select>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-centerx w-50">
                <div className="filter1-field filter1-field1 p-0">
                  <label className="filter1-label">
                    &nbsp; &nbsp;{" "}
                    {tax.radio === "P" && tax.select === "P" ? (
                      <>취득과표 : {tax.chpWon ? Number(tax.chpWon).toLocaleString() : "-"}</>
                    ) : (
                      <></>
                    )}
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
              </div>
            </div>

            <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
              <tbody onKeyDown={pressEnterCalc}>
                <tr>
                  <th style={{ width: "50%" }} colSpan={2}>
                    Vat 포함금액
                  </th>
                  <th colSpan={2}>Vat 제외금액</th>
                </tr>
                <tr>
                  <th style={{ width: "15%" }}>취득가액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={tax.sum ? tax.sum.toLocaleString() : 0}
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                  <th style={{ width: "15%" }}>명의개서료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={tax.gaesuWon ? tax.gaesuWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler("gaesuWon", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>계약서금액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={tax.contWon ? tax.contWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler("contWon", e.target.value);
                      }}
                    />
                  </td>
                  <th>가족등록비</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={tax.famRegWon ? tax.famRegWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler("famRegWon", e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>실매수금액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={tax.maesuWon ? tax.maesuWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler("maesuWon", e.target.value);
                      }}
                    />
                  </td>
                  <th>중개수수료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={tax.charge ? tax.charge.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler("charge", e.target.value);
                      }}
                      readOnly={tax.radio === "P"}
                      style={tax.radio === "P" ? { backgroundColor: "lightgray" } : {}}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Down 여부</th>
                  <td>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="downRadioOptions"
                      id="isDownRadio01"
                      checked={tax.down}
                      onChange={e => onChangeLeftPanelEventHandler("down", true)}
                      disabled={
                        tax.radio !== "P" || tax.chpWon > tax.maesuWon || tax.contWon > tax.maesuWon
                      }
                    />
                    <label className="form-check-label" htmlFor="isDownRadio01">
                      인증
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <input
                      className="form-check-input"
                      type="radio"
                      name="downRadioOptions"
                      id="isDownRadio02"
                      checked={!tax.down}
                      onChange={e => onChangeLeftPanelEventHandler("down", false)}
                      disabled={
                        tax.radio !== "P" || tax.chpWon > tax.maesuWon || tax.contWon > tax.maesuWon
                      }
                    />
                    <label className="form-check-label" htmlFor="isDownRadio02">
                      안됨
                    </label>
                  </td>
                  <th style={{ borderBottom: "1px solid #c5c5c5" }}>컨설팅료</th>
                  <td style={{ borderBottom: "1px solid #c5c5c5" }}>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={tax.consultWon >= 0 ? tax.consultWon.toLocaleString() : 0}
                      onChange={e => {
                        onChangeLeftPanelEventHandler("consultWon", e.target.value);
                      }}
                      readOnly={
                        tax.down ||
                        (tax.select === "P" &&
                          tax.chpWon > tax.maesuWon &&
                          tax.maesuWon > tax.contWon)
                      }
                      style={
                        tax.down ||
                        (tax.select === "P" &&
                          tax.chpWon > tax.maesuWon &&
                          tax.maesuWon > tax.contWon)
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-end mt-2">
              <button className="btn btn-outline-primary" onClick={btnCaculate}>
                취득세 산출
              </button>
            </div>
          </div>

          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}
          {/*======================================================================================================*/}

          <div className="col-6">
            <div className="tab1-row1-count mt-5 mb-4">
              납부해야 할 취득세액(납부기한: 계약서일로부터 60일 이내)
            </div>
            <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
              <tbody>
                <tr>
                  <th style={{ width: "17%" }}>message</th>
                  <td className="text-center" colSpan={3}>
                    {tax.radio === "P" && result.maemaeWon === 0 ? (
                      <>취득과표와 계약서 금액 비교</>
                    ) : tax.radio === "P" && result.maemaeWon !== 0 ? (
                      <>개인 매도 : 취득과표 > 취득가액 → 취득과표로만 산출</>
                    ) : tax.radio === "C" ? (
                      <>부가세(VAT)를 제외한 장부가액을 매매금액으로 적용</>
                    ) : tax.radio === "D" ? (
                      <>매매가액 자체가 공급가액, 기타항목은 부가세 제외</>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "17%" }}>매매금액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={result.maemaeWon ? result.maemaeWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "maemaeWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                    />
                  </td>
                  <th style={{ width: "17%" }}>취득가액</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={result.sum ? result.sum.toLocaleString() : 0}
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>명의개서료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={result.gaesuWon ? result.gaesuWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "gaesuWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                      }
                      style={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                  <th>취득세액(2%)</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        result.sum
                          ? (Math.floor((result.sum * 0.02) / 10) * 10).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>가족등록비</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={result.famRegWon ? result.famRegWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "famRegWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                      }
                      style={
                        tax.radio === "P" && tax.select === "P" && tax.chpWon > tax.maesuWon
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                  <th>
                    농어촌특별세
                    <br />
                    (10%)
                  </th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        result.sum
                          ? (Math.floor((result.sum * 0.002) / 10) * 10).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>중개수수료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={result.charge ? result.charge.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "charge",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={tax.radio === "P"}
                      style={tax.radio === "P" ? { backgroundColor: "lightgray" } : {}}
                    />
                  </td>
                  <td style={{ border: "none" }}></td>
                  <td style={{ border: "none" }}></td>
                </tr>
                <tr>
                  <th>컨설팅료</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={result.consultWon ? result.consultWon.toLocaleString() : 0}
                      onChange={e =>
                        onChangeRightPanelEventHandler(
                          "consultWon",
                          Number(e.target.value.replace(/,/g, ""))
                        )
                      }
                      readOnly={
                        tax.down || (tax.chpWon > tax.maesuWon && tax.maesuWon > tax.contWon)
                      }
                      style={
                        tax.down || (tax.chpWon > tax.maesuWon && tax.maesuWon > tax.contWon)
                          ? { backgroundColor: "lightgray" }
                          : {}
                      }
                    />
                  </td>
                  <th>납부 세액 합계</th>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      value={
                        result.sum
                          ? (
                              Math.floor((result.sum * 0.02) / 10) * 10 +
                              Math.floor((result.sum * 0.002) / 10) * 10
                            ).toLocaleString()
                          : 0
                      }
                      readOnly={true}
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="d-flex align-items-center justify-content-center mt-2">
              <button className="btn btn-outline-primary" onClick={btnReset}>
                초기화
              </button>
            </div>
          </div>
        </div>

        <hr className="my-2 p-0" />

        <ul className="table-help-list f-regular m-0 ps-4">
          <li>기한 내 미신고시 가산세: 납부해야 할 세액의 20%+3/10,000을 1할 계산</li>
          <li>기한 내 신고 후 미납 시 가산세: 납부해야 할 세액의 3/10,000을 1할 계산</li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(ModalCalculator);
