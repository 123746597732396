import React, { useEffect } from "react";
import ApprovalLineManage from "./approvalLineManage";
import { useAtom } from "jotai";
import {
  approvalLineManage_annualApprovalLineList,
  approvalLineManage_annualApprovalLineList1,
  approvalLineManage_certApprovalLineList,
  approvalLineManage_certApprovalLineList1,
  approvalLineManage_paymentApprovalLineList,
  approvalLineManage_paymentApprovalLineList1,
  approvalLineManage_selectMember,
  approvalLineManage_selectMember1,
} from "../../../data/atom/approvalManage/approvalLineManage/approvalLineManage";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export default function ApprovalLinePage() {
  const [member1, setMember1] = useAtom(approvalLineManage_selectMember);
  const [certApprovalLineList1, setCertApprovalLineList1] = useAtom(
    approvalLineManage_certApprovalLineList
  );
  const [annualApprovalLineList1, setAnnualApprovalLineList1] = useAtom(
    approvalLineManage_annualApprovalLineList
  );
  const [paymentApprovalLineList1, setPaymentApprovalLineList1] = useAtom(
    approvalLineManage_paymentApprovalLineList
  );
  const [member2, setMember2] = useAtom(approvalLineManage_selectMember1);
  const [certApprovalLineList2, setCertApprovalLineList2] = useAtom(
    approvalLineManage_certApprovalLineList1
  );
  const [annualApprovalLineList2, setAnnualApprovalLineList2] = useAtom(
    approvalLineManage_annualApprovalLineList1
  );
  const [paymentApprovalLineList2, setPaymentApprovalLineList2] = useAtom(
    approvalLineManage_paymentApprovalLineList1
  );
  const { addTabClosedEvent } = useRecentTabEvent();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setMember1("");
      setCertApprovalLineList1([]);
      setAnnualApprovalLineList1([]);
      setPaymentApprovalLineList1([]);
      setMember2("");
      setCertApprovalLineList2([]);
      setAnnualApprovalLineList2([]);
      setPaymentApprovalLineList2([]);
    });
  }, []);

  return (
    <div className="content-inner">
      <div className="filter1_container">
        <div className="filter_header_row">
          <div className="filter-title">결재선 설정</div>
        </div>
      </div>
      <ApprovalLineManage
        member={member1}
        setMember={setMember1}
        certApprovalLineList={certApprovalLineList1}
        annualApprovalLineList={annualApprovalLineList1}
        paymentApprovalLineList={paymentApprovalLineList1}
        setPaymentApprovalLineList={setPaymentApprovalLineList1}
        setAnnualApprovalLineList={setAnnualApprovalLineList1}
        setCertApprovalLineList={setCertApprovalLineList1}
      />
      <ApprovalLineManage
        member={member2}
        setMember={setMember2}
        certApprovalLineList={certApprovalLineList2}
        annualApprovalLineList={annualApprovalLineList2}
        paymentApprovalLineList={paymentApprovalLineList2}
        setPaymentApprovalLineList={setPaymentApprovalLineList2}
        setAnnualApprovalLineList={setAnnualApprovalLineList2}
        setCertApprovalLineList={setCertApprovalLineList2}
      />
    </div>
  );
}
