import React from "react";

const SearchResultTableCaption = props => {
  //const { originData, editedData } = props;
  const { editedData } = props;
  //const [mergedData, setMergedData] = useState([]);

  /*useEffect(() => {
    const merged = new Map();

    originData.forEach(item => merged.set(item.custId, item));
    editedData.forEach(item => merged.set(item.custId, item));
    setMergedData(Array.from(merged.values()));
  }, [originData, editedData]);*/

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="tab1-row1-count"></div>
      <div className="tab1-row1-field1">
        <div className="d-flex align-items-center justify-content-end">
          <div className="table-stat">
            전체 종목<span className="table-stat-count"></span>
          </div>
          <div className="table-stat">
            상승 :{" "}
            <span className="table-stat-count">
              {
                // editedData.filter(item => {
                //   let res1 = item.gubun === item.gubunOld ? item.csiseD - item.csiseDOld : 0;
                //   let res2 = item.gubun === item.gubunOld ? item.csiseS - item.csiseSOld : 0;
                //   if ((res1 + res2) / 2 > 0) return true;
                //   else return false;
                // }).length

                editedData[0]?.highTotal ?? 0
              }
            </span>
          </div>
          <div className="table-stat">
            하락 :{" "}
            <span className="table-stat-count">
              {
                // editedData.filter(item => {
                //   let res1 = item.gubun === item.gubunOld ? item.csiseD - item.csiseDOld : 0;
                //   let res2 = item.gubun === item.gubunOld ? item.csiseS - item.csiseSOld : 0;
                //   if ((res1 + res2) / 2 < 0) return true;
                //   else return false;
                // }).length

                editedData[0]?.lowTotal ?? 0
              }
            </span>
          </div>
          <div className="table-stat">
            웹 View 종목 <span className="table-stat-count"></span>
          </div>
          <div className="table-stat">
            상승 :{" "}
            <span className="table-stat-count">
              {
                // editedData.filter(item => {
                //   let res1 = item.gubun === item.gubunOld ? item.csiseD - item.csiseDOld : 0;
                //   let res2 = item.gubun === item.gubunOld ? item.csiseS - item.csiseSOld : 0;
                //   if ((res1 + res2) / 2 > 0 && item.cviewCheck === "Y") return true;
                //   else return false;
                // }).length

                editedData[0]?.highWeb ?? 0
              }
            </span>
          </div>
          <div className="table-stat">
            하락 :{" "}
            <span className="table-stat-count">
              {
                // editedData.filter(item => {
                //   let res1 = item.gubun === item.gubunOld ? item.csiseD - item.csiseDOld : 0;
                //   let res2 = item.gubun === item.gubunOld ? item.csiseS - item.csiseSOld : 0;
                //   if ((res1 + res2) / 2 < 0 && item.cviewCheck === "Y") return true;
                //   else return false;
                // }).length

                editedData[0]?.lowWeb ?? 0
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultTableCaption;
