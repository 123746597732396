import { atom } from "jotai";
import moment from "moment";
import { decrypt } from "../../../../util/decrypt/decrypt";

export const callReservationManagement_callHistoryOptionInit = {
  frontDate: moment(new Date()).format("YYYY-MM-DD"),
  backDate: moment(new Date()).format("YYYY-MM-DD"),
  jisaname: "서울본사", // getCarMasterJisaList 셀렉트 박스 - jisaname
  jisa: "99",
  person: decrypt("userInfo") ? decrypt("userInfo").userName : "", // getCallReserveUserList 셀렉트 박스 - 통화자 첫번째 - KDP6306
  search: "1", // 검색 조건 - 통화예약 / 통화내역
  page: 1,
  init: false,
};

export const callReservationManagement_callHistoryOption = atom(
  callReservationManagement_callHistoryOptionInit
);
export const callReservationManagement_selectType = atom("1");
export const callReservationManagement_searchData = atom([]);

export const callReservationManagement_callCountList = atom([]);

export const callReservationManagement_callReserveDetailList = atom([]);

export const callReservationManagement_customerDetail = atom({});

export const callReservationManagement_row_atom = atom("5");

export const callReservationManagement_callHistory = atom([]);

export const callReservationManagement_selectRow1 = atom("");
export const callReservationManagement_selectRow2 = atom("");
