import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai/index";
import {
  envelopeLabelPrinting_mainData_atom,
  envelopeLabelPrinting_mainDataInit_atom,
  envelopeLabelPrinting_searchData_atom,
  envelopeLabelPrinting_searchDataInit_atom,
  envelopeLabelPrinting_searchedTableList_atom,
  envelopeLabelPrinting_subData_atom,
  envelopeLabelPrinting_subDeleteList_atom,
  envelopeLabelPrinting_tableLimit_atom,
} from "../../../../data/atom/workManage/postManage/envelopeLabelPrinting_atom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useGetPostGubun } from "../../../../api/code/useGetPostGubun";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import { useGetPartner } from "../../../../api/code/useGetPartner";
import { TableHead } from "./tableHead";
import { TableBody } from "./tableBody";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import { useVirtualizer } from "@tanstack/react-virtual";
import moment from "moment/moment";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";
import { useGetJisaGubunAll } from "../../../../api/code/useGetJisaGubunAll";
import { useGetJisaGubunAllQ1 } from "../../../../api/code/useGetJisaGubunAllQ1";
import { LabelModal } from "./modal/labelModal";
import usePrintTab from "../../../../components/modal/usePrintTab";

export const EnvelopeLabelPrinting = () => {
  const [infinityScroll, setInfinityScroll] = useState(null);
  const { addTabClosedEvent } = useRecentTabEvent();

  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const [searchData, setSearchData] = useAtom(envelopeLabelPrinting_searchData_atom);
  const [tableLimit, setTableLimit] = useAtom(envelopeLabelPrinting_tableLimit_atom);
  const [searchedTableList, setSearchedTableList] = useAtom(
    envelopeLabelPrinting_searchedTableList_atom
  );

  // 출력할 주소지
  const [mainData, setMainData] = useAtom(envelopeLabelPrinting_mainData_atom);

  // 출력 대상 보기
  const [subData, setSubData] = useAtom(envelopeLabelPrinting_subData_atom);
  const [subDeleteList, setSubDeleteList] = useAtom(envelopeLabelPrinting_subDeleteList_atom); // 삭제할 출력대상 리스트

  const [postName, setPostName] = useState("1"); // 주소지 회사="1", 집="2"
  const [selectedPrint, setSelectedPrint] = useState("2"); // 선택된 작업 선택 라벨스티커="1", 소봉투="2"
  const [isLabelModalOpen, setIsLabelModalOpen] = useState({
    data: null,
    isOpen: false,
  }); // 라벨지 모달창

  // const queryClient = useQueryClient();

  const getJisaGubunAllQ1 = useGetJisaGubunAllQ1(); // 발송지사
  const getJisaGubunAll = useGetJisaGubunAll(); // 거래지사
  const getPostGubun = useGetPostGubun(); // 발송방법
  const getPartner = useGetPartner();
  const getUserIdQ = useGetUserIdQ();

  // 조회
  const mailBoxSearch = useMutation(
    data => axios.get("/api/work/mailBox/search", { params: data }),
    {
      // onError: err => {
      //   if (err.response.data.code === "-1") {
      //     alert(err.response.data.message);
      //   }
      // },
    }
  );

  // 출력할 주소지 조회
  const mailBoxGetAddres = useMutation(
    data => axios.get("/api/work/mailBox/getAddres", { params: data }),
    {
      onSuccess: (res, variables) => {
        // setMainData(
        //   res.data.result.map(el => ({
        //     ...el,
        //     userName: getPartner.find(el2 => el2.key === el.partner).value,
        //   }))
        // );

        // setMainData({
        //   ...res.data.result,
        //   userName: getPartner.data.find(el => el.key === res.data.result.partner).value,
        // });

        setMainData({ ...mainData, ...res.data.result });

        // isState: 1이면 테이블 클릭 하고 조회, 2이면 출력신청하고 재조회
        if (variables.isState === "1") {
          if (res.data.result.rcBdcode && res.data.result.rhBdcode) {
            if (
              window.confirm(
                // `회사와 집주소 모두 존재하며, 고객마스터의 우편수취는 ${res.data.result.postName}(으)로 분류되어있습니다. 회사주소로 신청하시겠습니까?`
                `회사와 집주소 모두 존재하며, 회사주소로 신청하시겠습니까?`
              )
            ) {
              setPostName("1");
            } else {
              setPostName("2");
            }
          } else if (res.data.result.rcBdcode) {
            setPostName("1");
          } else if (res.data.result.rhBdcode) {
            setPostName("2");
          }
        } else {
          if (res.data.result.rcBdcode) {
            setPostName("1");
          } else if (res.data.result.rhBdcode) {
            setPostName("2");
          }
        }
      },
    }
  );

  // 출력대상 가저오기
  const mailBoxGetPrintList = useQuery(
    ["mailBoxGetPrintList"],
    async () => await axios.get("/api/work/mailBox/getPrintList"),
    {
      onSuccess: res => {
        setSubData(res.data.result);
        setSubDeleteList(Array.from({ length: res.data.result.length }, () => true));
      },
    }
  );

  // 출력신청 버튼
  const mailBoxReqPrint = useMutation(data => axios.post("/api/work/mailBox/reqPrint", data), {
    onSuccess: () => {
      alert("출력신청이 완료 되었습니다.");
      // mailBoxGetAddres.mutate({
      //   company: mainData.company,
      //   condition: searchData.condition,
      //   custId: mainData.custId,
      //   gubun: mainData.gubun,
      //   page: 1,
      //   seqNo: mainData.seqNo,
      //   isState: "2", // 1: 테이블 클릭하고 조회할 때, 2: 출력신청하고 재조회할 때
      //   ilNo: mainData.ilNo,
      // });
      mailBoxGetPrintList.refetch();
    },
  });

  // 데이터 이관 후 삭제
  const mailBoxMigDelData = useMutation(data => axios.post("/api/work/mailBox/migDelData", data), {
    onSuccess: () => {
      alert("데이터 이관 후 삭제가 완료 되었습니다.");
      mailBoxGetPrintList.refetch();
    },
  });

  // 소봉투 출력
  const mailBoxPrintEnv = useMutation(
    data =>
      axios.post("/api/work/mailBox/printEnv", data, {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `소봉투_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  // 라벨지 출력
  const mailBoxPrintLabel = useMutation(
    data => axios.post("/api/work/mailBox/printLabel", data, { responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `라벨지_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  useEffect(() => {
    // 컴포넌트 종료할 때 atom 데이터 초기화
    addTabClosedEvent(location.pathname, () => {
      setSearchData(envelopeLabelPrinting_searchDataInit_atom);
      setTableLimit("5");
      setSearchedTableList([]);
      setMainData(envelopeLabelPrinting_mainDataInit_atom);
      setSubData([]);
    });
  }, []);

  // 무한 스크롤 이벤트
  useEffect(() => {
    // if (infinityScroll) {
    //   const observer = new IntersectionObserver(
    //     entries => {
    //       if (entries[0].isIntersecting) {
    //         mailBoxSearch.mutateAsync({ ...searchData, page: searchData.page + 1 }).then(res => {
    //           setSearchedTableList(prev => [...prev, ...res.data.result]);
    //         });
    //
    //         setSearchData(prev => {
    //           return {
    //             ...prev,
    //             page: prev.page + 1,
    //           };
    //         });
    //       }
    //     },
    //     { threshold: 1 }
    //   );
    //
    //   observer.observe(infinityScroll);
    // }

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && searchedTableList.length > 99) {
          setSearchData(prev => {
            const newSearchData = { ...prev };
            newSearchData.page = newSearchData.page + 1;

            mailBoxSearch.mutateAsync(newSearchData).then(res => {
              setSearchedTableList(prev2 => {
                return [
                  ...prev2,
                  ...res.data.result.map(el2 => ({
                    ...el2,
                    custDate: el2.custDate
                      ? moment(el2.custDate).format("YYYY-MM-DD")
                      : "0000-00-00",
                    sinDate: el2.sinDate ? moment(el2.sinDate).format("YYYY-MM-DD") : "0000-00-00",
                  })),
                ];
              });
            });

            return newSearchData;
          });
        }
      },
      { threshold: 0.5 }
    );

    if (infinityScroll) {
      observer.observe(infinityScroll);
    }
  }, [infinityScroll]);

  const btn_search = () => {
    // if (
    //   +searchData.condition <= 3 ||
    //   searchData.condition === "5" ||
    //   (+searchData.condition >= 9 && +searchData.condition <= 15)
    // ) {
    //   if (searchData.keyword === "") {
    //     alert("검색어를 입력해주세요.");
    //     return;
    //   }
    // }

    if (
      searchData.condition === "4" ||
      searchData.condition === "6" ||
      searchData.condition === "7" ||
      searchData.condition === "8"
    ) {
      if (searchData.keyword === "") {
        alert("검색어를 입력해주세요.");
        return;
      }
    }

    const newSearchData = { ...searchData };
    newSearchData.page = 1;

    setSearchedTableList(() => []);

    mailBoxSearch
      .mutateAsync(newSearchData)
      .then(res => {
        setSearchData(newSearchData);

        setSearchedTableList(
          res.data.result.map(el => ({
            ...el,
            custDate: el.custDate ? moment(el.custDate).format("YYYY-MM-DD") : "0000-00-00",
            sinDate: el.sinDate ? moment(el.sinDate).format("YYYY-MM-DD") : "0000-00-00",
          }))
        );
      })
      .catch(err => {
        if (err.response.data.code === "-1") {
        }
      });
  };

  const btn_tableRowClick = data => {
    mailBoxGetAddres.mutate({
      company: data.company,
      condition: searchData.condition,
      custId: data.custId,
      gubun: data.gubun,
      page: 1,
      seqNo: data.seqNo,
      custDate: data.custDate,
      isState: "1", // 1: 테이블 클릭하고 조회할 때, 2: 출력신청하고 재조회할 때
      ilNo: data.ilNo,
    });
  };

  // const btn_main_save = () => {
  //   alert("api 준비중");
  // };

  // const btn_main_cancel = () => {
  //   if (window.confirm("수정을 취소하시겠습니까?")) {
  //     mailBoxGetAddres.mutate({
  //       company: mainData.company,
  //       condition: searchData.condition,
  //       custId: mainData.custId,
  //       gubun: mainData.gubun,
  //       page: 1,
  //       seqNo: mainData.seqNo,
  //       ilNo: mainData.ilNo,
  //     });
  //   }
  // };

  const btn_main_reqPrint = () => {
    if (window.confirm("출력신청을 하시겠습니까?")) {
      mailBoxReqPrint.mutate({
        company: mainData?.company,
        custId: mainData?.custId,
        devision: mainData?.devision,
        gubun: mainData?.gubun,
        jisa: mainData?.jisa,
        jisaTax: mainData?.jisaTax,
        kname: mainData?.kname,
        partner: mainData?.partner,
        position: mainData?.position,
        printGubun2: mainData?.printGubun,
        raddres: postName === "1" ? mainData?.rcAddres : mainData?.rhAddres,
        rbdcode: postName === "1" ? mainData?.rcBdcode : mainData?.rhBdcode,
        remark: mainData?.remark,
        rzipcode: postName === "1" ? mainData?.rcAreaCode : mainData?.rhAreaCode,
        sendGubun: mainData?.sendGubun,
        seqNo: mainData?.seqNo,
        userid: mainData?.userid,
        userName: mainData?.userName,
      });
    }
  };

  const btn_sub_delete = () => {
    if (window.confirm("데이터 이관 후 삭제를 하시겠습니까?")) {
      // const temp = deletePrintList.reduce((acc, cur, index) => {
      //   if (cur) {
      //     return [...acc, mailBoxGetPrintList.data[index]];
      //   } else {
      //     return acc;
      //   }
      // }, []);
      const temp = subData.filter((el, idx) => subDeleteList[idx]);

      if (temp.length === 0) {
        alert("선택된 데이터가 없습니다.");
        return;
      }

      mailBoxMigDelData.mutate(
        temp.map(el => ({
          ...el,
          ilNo: el.ilNo,
          addres: el.addres,
          company: el.company,
          custId: el.custId,
          devision: el.devision,
          gubun: el.gubun,
          jisa: el.jisa,
          jisaTax: el.jisaTax,
          kname: el.kname,
          partner: el.partner,
          position: el.position,
          printGubun: el.printGubun,
          printGubun2: el.printGubun2,
          raddres: el.raddres,
          rbdcode: el.rbdcode,
          remark: el.remark,
          roadAddres: el.roadAddres,
          rzipcode: el.rzipcode,
          sendGubun: el.sendGubun,
          seqNo: el.seqNo,
          userid: el.userid,
          zipcode: el.zipcode,
          modDate: "",
        }))
      );
    }
  };

  const btn_print = () => {
    if (subData.length === 0) {
      alert("출력할 데이터가 없습니다.");
      return;
    }

    if (selectedPrint === "1") {
      setIsLabelModalOpen({
        data: subData
          .filter(el => el.printGubun === "Y" && el.printGubun2 === "2")
          .map(el => ({
            ...el,
            zipcode: el?.rzipcode,
          })),
        isOpen: true,
      });
    } else if (selectedPrint === "2") {
      const filter = subData
        .filter(el => el.printGubun === "Y" && el.printGubun2 === "1")
        .map(el => ({
          ...el,
          userId: el.userid,
          sendGubun: getPostGubun.data.find(el2 => el2.key === el.sendGubun).value,
        }));

      if (filter.length === 0) {
        alert("출력할 소봉투가 없습니다.");
        return;
      }

      mailBoxPrintEnv.mutate({ list: filter });
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: subData?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <>
      {isLabelModalOpen.isOpen && (
        <LabelModal isLabelModalOpen={isLabelModalOpen} setIsLabelModalOpen={setIsLabelModalOpen} />
      )}
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="post-page">
        <div className="post-page-title">소봉투/라벨지 출력</div>
        <div className="filter_fields_container">
          <div className="filter_fields_row" onKeyDown={pressEnter}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="filter1-field filter1-field1">
                <label className="filter1-label">검색조건</label>
                <select
                  className="form-select filter1-select1"
                  value={searchData.condition}
                  onChange={e => {
                    setSearchData(prev => {
                      return {
                        ...prev,
                        condition: e.target.value,
                      };
                    });

                    // 취득세 지로용지 고객발송, 양도세 서류 고객발송 대상지사
                    if (e.target.value === "6" || e.target.value === "7") {
                      setSearchData(prev => {
                        return { ...prev, jisa: getJisaGubunAll.data[0].key };
                      });
                    } else {
                      setSearchData(prev => {
                        return { ...prev, jisa: "" };
                      });
                    }

                    // 취득세 지로용지 고객발송, 양도세 서류 고객발송 구분
                    if (e.target.value === "6" || e.target.value === "7") {
                      setSearchData(prev => {
                        return { ...prev, gubun: "1" };
                      });
                    } else {
                      setSearchData(prev => {
                        return { ...prev, gubun: "" };
                      });
                    }

                    // 본인관리 내 검색 체크박스 활성화 여부
                    if (+e.target.value <= 8 && +e.target.value !== 5) {
                      setSearchData(prev => {
                        return { ...prev, my: true };
                      });
                    } else {
                      setSearchData(prev => {
                        return { ...prev, my: false };
                      });
                    }

                    setSearchedTableList([]);
                    setSearchData(prev => {
                      return { ...prev, keyword: "" };
                    });
                  }}
                >
                  <option value="1">고객-업체명</option>
                  <option value="2">고객-이름</option>
                  <option value="3">고객-휴대폰</option>
                  <option value="4">고객-다골프멤버쉽</option>
                  <option value="5">고객-관리번호</option>
                  <option value="6">취득세 지로용지 고객발송</option>
                  <option value="7">양도세 서류 고객발송</option>
                  <option value="9">관할관청(양도세-지방세 신고용)</option>
                  <option value="10">샵명</option>
                  <option value="11">관할관청명</option>
                  <option value="12">세무서명</option>
                  <option value="13">전화번호부</option>
                  <option value="14">골프팁스</option>
                  <option value="15">골프연습장</option>
                  <option value="16">골프장명</option>
                  <option value="17">콘도마스터</option>
                  <option value="18">스포츠마스터</option>
                </select>
              </div>
              {searchData.condition === "6" || searchData.condition === "7" ? (
                <>
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">대상지사</label>
                    <select
                      className="form-select filter1-select1"
                      value={searchData.jisa}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          jisa: e.target.value,
                        });
                      }}
                    >
                      {getJisaGubunAll.data?.map(el => (
                        <option value={el.key}>{el.value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">구분</label>
                    <select
                      className="form-select filter1-select1"
                      value={searchData.gubun}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          gubun: e.target.value,
                        });
                      }}
                    >
                      <option value="1">골프</option>
                      <option value="2">콘도</option>
                      <option value="3">헬스</option>
                    </select>
                  </div>
                  <div className="filter1-field filter1-field1">
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchData.condition === "6" ? "관청접수건" : "신고대상"}
                      disabled={true}
                    />
                  </div>
                  <div className="filter1-field filter1-field1">
                    <label className="filter1-label">성명</label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          keyword: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="filter1-field filter1-field2">
                    <label className="filter1-label">검색어</label>
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2"
                      value={searchData.keyword}
                      onChange={e => {
                        setSearchData({
                          ...searchData,
                          keyword: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}

              {+searchData.condition <= 8 && +searchData.condition !== 5 && (
                <div className="filter1-field filter1-field3">
                  <input
                    type="checkbox"
                    id="chk-1"
                    className="form-check-input"
                    name="checkbox-option"
                    checked={searchData.my}
                    onChange={e => {
                      setSearchData({ ...searchData, my: e.target.checked });
                    }}
                  />
                  <label className="filter1-label" htmlFor="chk-1">
                    본인관리 내 검색
                  </label>
                </div>
              )}
            </div>

            <div className="d-flex">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={btn_search}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            <div className="table-outer">
              <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center">
                  <select
                    className="form-select table-filter-select"
                    value={tableLimit}
                    onChange={e => {
                      setTableLimit(e.target.value);
                    }}
                  >
                    <option value="5">5개씩 보기</option>
                    <option value="10">10개씩 보기</option>
                    <option value="20">20개씩 보기</option>
                  </select>
                </div>
              </div>
              <div
                className={`table-container table-sticky mt-2
                ${tableLimit === "5" && "h-183"}
                ${tableLimit === "10" && "h-333"}
                ${tableLimit === "20" && "h-633"}`}
              >
                <table className="table table-bordered table-responsive my-table mt-0">
                  <thead>
                    <TableHead searchData={searchData} searchedTableList={searchedTableList} />
                  </thead>
                  <tbody>
                    {searchedTableList.length !== 0 ? (
                      <TableBody
                        mainData={mainData}
                        searchData={searchData}
                        searchedTableList={searchedTableList}
                        btn_tableRowClick={btn_tableRowClick}
                        setInfinityScroll={setInfinityScroll}
                      />
                    ) : (
                      <tr>
                        <td colSpan={10}>검색결과가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row gx-3">
          <div className="col-6">
            {mainData.custId !== "0" && (
              <>
                <div className="card card-main">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0">출력할 주소지</h5>
                  </div>

                  <div className="card-body">
                    <div className="post-address-select">
                      <label className="filter1-label">주소지</label>
                      <select
                        className="form-select filter1-select1"
                        value={postName}
                        onChange={e => {
                          setPostName(e.target.value);
                        }}
                      >
                        <option value="1">회사</option>
                        <option value="2">집</option>
                      </select>
                    </div>
                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "11.9%" }}>지사구분</th>
                          <td className="input-col-2" style={{ width: "13.1%" }}>
                            <select
                              className="form-select "
                              value={mainData.jisa}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  jisa: e.target.value,
                                });
                              }}
                            >
                              {getJisaGubunAllQ1.data
                                ?.filter(el => el.key !== "99")
                                .map(el => (
                                  <option value={el.key}>{el.value}</option>
                                ))}
                            </select>
                          </td>
                          <th style={{ width: "11.9%" }}>거래지사</th>
                          <td style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={
                                getJisaGubunAll.data?.find(el => el.key === mainData?.jisaTax)
                                  ?.value
                              }
                              readOnly={true}
                            />
                          </td>
                          <th style={{ width: "11.9%" }}>관리번호</th>
                          <td style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.custId.replace(/(.{4})/, "$1-")}
                              disabled={true}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>신청자</th>
                          <td>
                            {/*<input*/}
                            {/*  type="text"*/}
                            {/*  className="form-control table-input"*/}
                            {/*  value={mainData?.userName}*/}
                            {/*  onChange={e => {*/}
                            {/*    setMainData({*/}
                            {/*      ...mainData,*/}
                            {/*      userName: e.target.value,*/}
                            {/*    });*/}
                            {/*  }}*/}
                            {/*  // readOnly={true}*/}
                            {/*/>*/}

                            <select
                              className="form-select"
                              value={mainData.userid}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  userid: e.target.value,
                                  userName: getPartner.data.find(el2 => el2.key === e.target.value)
                                    .value,
                                });
                              }}
                            >
                              {getPartner.data.map(el => (
                                <option value={el.key}>{el.value}</option>
                              ))}
                            </select>
                          </td>
                          <th>발송방법</th>
                          <td>
                            <select
                              className="form-select "
                              value={mainData.sendGubun}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  sendGubun: e.target.value,
                                });
                              }}
                            >
                              {getPostGubun.data?.map(el => (
                                <option value={el.key}>{el.value}</option>
                              ))}
                            </select>
                          </td>
                          <th>출력방법</th>
                          <td>
                            <select
                              className="form-select"
                              value={mainData.printGubun}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  printGubun: e.target.value,
                                });
                              }}
                            >
                              <option value="1">소봉투</option>
                              <option value="2">라벨지</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered table-responsive my-table1">
                      <tbody>
                        <tr>
                          <th style={{ width: "11.9%" }}>업체명</th>
                          <td style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.company ?? ""}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  company: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <th style={{ width: "11.9%" }}>부서명</th>
                          <td style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.devision ?? ""}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  devision: e.target.value,
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "11.9%" }}>담당자</th>
                          <td style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.kname ?? ""}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  kname: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <th style={{ width: "11.9%" }}>직위</th>
                          <td style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.position ?? ""}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  position: e.target.value,
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "11.9%" }} rowSpan={2}>
                            도로명주소
                          </th>
                          <td>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control table-input input-address1 w-100"
                                value={
                                  postName === "1"
                                    ? mainData?.rcAreaCode ?? mainData.rcZipcode
                                    : mainData?.rhAreaCode ?? mainData.rhZipcode
                                }
                                // disabled={true}
                              />
                              <DaumPostcode
                                onCompleteCallback={data => {
                                  if (postName === "1") {
                                    setMainData({
                                      ...mainData,
                                      rcZipcode: data.zonecode,
                                      rcAreaCode: data.zonecode,
                                      rcAddresTxt: data.fullAddr,
                                      rcBdcode: data.bdCode,
                                    });
                                  } else if (postName === "2") {
                                    setMainData({
                                      ...mainData,
                                      rhZipcode: data.zonecode,
                                      rhAreaCode: data.zonecode,
                                      rhAddresTxt: data.fullAddr,
                                      rhBdcode: data.bdCode,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td colSpan={3}>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control table-input input-address3"
                                value={
                                  postName === "1"
                                    ? mainData?.rcAddresTxt ?? ""
                                    : mainData?.rhAddresTxt ?? ""
                                }
                                readOnly={true}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={
                                postName === "1"
                                  ? mainData?.rcAddres ?? ""
                                  : mainData?.rhAddres ?? ""
                              }
                              onChange={e => {
                                if (postName === "1") {
                                  setMainData({
                                    ...mainData,
                                    rcAddres: e.target.value,
                                  });
                                } else if (postName === "2") {
                                  setMainData({
                                    ...mainData,
                                    rhAddres: e.target.value,
                                  });
                                }
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "11.9%" }}>내용물</th>
                          <td style={{ width: "13.1%" }}>
                            <input
                              type="text"
                              className="form-control table-input"
                              value={mainData?.remark ?? ""}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  remark: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <th style={{ width: "11.9%" }}>담당딜러</th>
                          <td stylep={{ width: "13.1%" }}>
                            <select
                              className="form-select"
                              value={mainData.partner}
                              onChange={e => {
                                setMainData({
                                  ...mainData,
                                  partner: e.target.value,
                                });
                              }}
                            >
                              {getPartner.data?.map(el => (
                                <option value={el.key}>{el.value}</option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="post-note-span">
                      세무서, 관할 관청으로 발송 시 양도(수)인 이름을 반드시 포함하여 기재할 것.
                      내용물 항목에서[재중]은 자동부여 되므로 기재하지 말 것.
                    </div>
                    <div className="post-print-container">
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn"
                        onClick={btn_main_reqPrint}
                      >
                        출력신청
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-6">
            <div className="card card-sub">
              <div className="card-body">
                <div className="post-work-select">
                  <div className="d-flex align-items-center w-50">
                    <label className="filter1-label">작업선택</label>
                    <select
                      className="form-select post-work-select-box"
                      value={selectedPrint}
                      onChange={e => {
                        setSelectedPrint(e.target.value);
                      }}
                    >
                      <option value="1">라벨스티커</option>
                      <option value="2">소봉투</option>
                    </select>
                  </div>
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn"
                    onClick={btn_print}
                  >
                    출력
                  </button>
                </div>
              </div>
            </div>

            <div className="card card-main ">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title m-0">출력 대상 보기</h5>
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{ gap: "8px" }}
                >
                  {/*<button className="btn btn-outline-danger tab1-row1-btn" >삭제</button>*/}
                  <button className="btn btn-outline-danger tab1-row1-btn" onClick={btn_sub_delete}>
                    데이터 이관 후 삭제
                  </button>
                </div>
              </div>

              <div className="card-body">
                <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
                  <table
                    className="table table-bordered table-responsive my-table mt-0"
                    style={{
                      width: `${subData.length !== 0 ? "2000px" : ""}`,
                      marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${
                        rowVirtualizer.getTotalSize() -
                        (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                        rowVirtualizer.getVirtualItems().length * 30
                      }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "4%" }}>
                          <input
                            type="checkbox"
                            checked={
                              subDeleteList.length !== 0 && subDeleteList.every(el => el === true)
                            }
                            onChange={e => {
                              setSubDeleteList(
                                Array.from({ length: subDeleteList.length }, () => e.target.checked)
                              );
                            }}
                          />
                        </th>
                        <th style={{ width: "4%" }}>연번</th>
                        <th style={{ width: "6%" }}>발송지사</th>
                        <th style={{ width: "6%" }}>거래지사</th>
                        <th style={{ width: "4%" }}>구분</th>
                        <th style={{ width: "6%" }}>거래번호</th>
                        <th style={{ width: "6%" }}>발송방법</th>
                        <th style={{ width: "8%" }}>성명</th>
                        <th style={{ width: "10%" }}>내용물</th>
                        <th style={{ width: "4%" }}>출력방법</th>
                        <th style={{ width: "4%" }}>출력구분</th>
                        <th style={{ width: "10%" }}>주소</th>
                        <th style={{ width: "6%" }}>업체명</th>
                        <th style={{ width: "6%" }}>담당딜러</th>
                        <th style={{ width: "6%" }}>신청자</th>
                        <th style={{ width: "8%" }}>신청일자</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subData?.length !== 0 ? (
                        subData?.map((el, index) => {
                          return (
                            <tr>
                              <td
                                className="cursor-pointer"
                                onClick={() => {
                                  setSubDeleteList(prev => {
                                    const temp = [...prev];
                                    temp[index] = !temp[index];
                                    return temp;
                                  });
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={subDeleteList[index]}
                                  onChange={e => {
                                    setSubDeleteList(prev => {
                                      const temp = [...prev];
                                      temp[index] = e.target.checked;
                                      return temp;
                                    });
                                  }}
                                />
                              </td>
                              <td>{index + 1}</td>
                              <td>
                                {getJisaGubunAllQ1.data?.find(el2 => el2.key === el.jisa)?.value}
                              </td>
                              <td>
                                {getJisaGubunAll.data?.find(el2 => el2.key === el.jisaTax)?.value}
                              </td>
                              <td>
                                {el.gubun === "1"
                                  ? "양도세"
                                  : el.gubun === "2"
                                  ? "취득" + "세"
                                  : ""}
                              </td>
                              <td>{(el.seqNo ?? "").replace(/(.{5})/, "$1-")}</td>
                              <td>
                                {getPostGubun.data?.find(el2 => el2.key === el.sendGubun)?.value}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control filter-input"
                                  value={el.kname}
                                  readOnly={true}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control filter-input"
                                  value={el.remark}
                                  readOnly={true}
                                />
                              </td>
                              {/*<td>{el.printGubun2 === "1" ? "소봉투" : "라벨지"}</td>*/}
                              <td>
                                <select
                                  className="form-select"
                                  value={el.printGubun2}
                                  onChange={e => {
                                    const newSubData = [...subData];
                                    newSubData[index].printGubun2 = e.target.value;
                                    setSubData(newSubData);
                                  }}
                                >
                                  <option value="1">소봉투</option>
                                  <option value="2">라벨지</option>
                                  <option value="3">대봉투</option>
                                </select>
                              </td>
                              {/*<td>{el.printGubun === "Y" ? "Y" : "N"}</td>*/}
                              <td>
                                <select
                                  className="form-select"
                                  value={el.printGubun}
                                  onChange={e => {
                                    const newSubData = [...subData];
                                    newSubData[index].printGubun = e.target.value;
                                    setSubData(newSubData);
                                  }}
                                >
                                  <option value="Y">Y</option>
                                  <option value="N">N</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control filter-input"
                                  value={el.roadAddres}
                                  readOnly={true}
                                />
                              </td>
                              {/*<td>{el.company}</td>*/}
                              <td>
                                <input
                                  type="text"
                                  className="form-control filter-input"
                                  value={el.company}
                                  readOnly={true}
                                />
                              </td>
                              <td>{getUserIdQ.data?.find(el2 => el2.key === el.partner)?.value}</td>
                              <td>{getUserIdQ.data?.find(el2 => el2.key === el.userid)?.value}</td>
                              <td>{moment(el.modDate).format("YYYY-MM-DD")}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={16}>출력대상이 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
