import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import usePrintTab from "../../../../components/modal/usePrintTab";

const ModalEnvelopeLabel = props => {
  const { show, setShow, list, getPostGubun } = props;
  const [rc, setRc] = useState({ column: 0, row: 0 });
  const [labelList, setLabelList] = useState([]);
  const openPdf = usePrintTab();
  const printLabel = useMutation(
    data =>
      axios.post(
        "/api/work/mailBox/printLabel",
        { row: data?.row, column: data?.column, list: data?.list },
        {
          responseType: "blob",
        }
      ),
    {
      onSuccess: res => {
        if (res?.data) {
          openPdf(res?.data);
        }
        //fileDownload(res.data, `양도세_라벨지_${moment().format("YY_MM_DD_hh_mm")}.pdf`);
      },
    }
  );

  const onChangeEventHandler = (key, value) => {
    value = Number(value.replace(/[^0-9]/g, ""));

    if (
      (key === "row" && value >= 0 && value <= 8) ||
      (key === "column" && value >= 0 && value <= 2)
    ) {
      setRc({ ...rc, [key]: value });
    }
  };

  const modalHide = () => {
    setShow(false);
    setRc({ column: 1, row: 1 });
    setLabelList([]);
  };
  const btnSearch = () => {
    setLabelList(list.filter(el => el.printGubun2 === "2" && el.printGubun === "Y"));
  };
  const btnPrint = () => {
    if (labelList.length === 0) {
      alert("출력할 내역이 없습니다.");
    } else if (rc.row === 0) {
      alert("행을 입력해주세요.");
    } else if (rc.column === 0) {
      alert("열을 입력해주세요.");
    } else {
      printLabel.mutate({
        column: rc.column,
        list: list
          .filter(el => el.printGubun2 === "2" && el.printGubun === "Y")
          .map(el => {
            return {
              asAddres: el.roadAddres,
              asCompany: el.company,
              asIlNo: el.ilNo,
              asKname: el.kname,
              asZipcode: el.rzipcode,
            };
          }),
        row: rc.row,
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={modalHide} centered>
        <div style={{ boxShadow: "rgba(0,0,0,0.5) 0 0 0 9999px" }}>
          <Modal.Header closeButton>
            <Modal.Title>라벨지</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-form">
              <div className="card card-main mt-0">
                <div className="tab1-row1-count mb-1">출력 위치</div>
                <table className="table table-bordered table-responsive my-table1 mb-2">
                  <tbody>
                    <tr>
                      <th style={{ width: "24%" }}>행</th>
                      <td style={{ width: "24%" }}>
                        <input
                          type="text"
                          className="form-control text-center"
                          value={rc.row}
                          onChange={e => onChangeEventHandler("row", e.target.value)}
                        />
                      </td>
                      <th style={{ width: "24%" }}>열</th>
                      <td style={{ width: "24%" }}>
                        <input
                          type="text"
                          className="form-control text-center"
                          value={rc.column}
                          onChange={e => onChangeEventHandler("column", e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="post-print-container">
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn me-2"
                    onClick={btnSearch}
                  >
                    조회
                  </button>
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary post-delete-btn"
                    onClick={btnPrint}
                  >
                    출력
                  </button>
                </div>
                <div className="tab1-row1-count mt-4 mb-1">
                  검색 결과 : &nbsp;<b style={{ color: "#0b4da2" }}>{labelList.length}</b> 건
                </div>
                <div
                  className="overflow-auto table-container table-sticky h-333"
                  style={{ borderBottomWidth: "0" }}
                >
                  <table className="table table-bordered table-responsive my-table1">
                    <tbody>
                      <tr>
                        <th style={{ width: "5%" }}>연번</th>
                        <th style={{ width: "15%" }}>업체명</th>
                        <th style={{ width: "10%" }}>발송방법</th>
                        <th style={{ width: "10%" }}>성명</th>
                        <th style={{ width: "10%" }}>출력방법</th>
                        <th style={{ width: "10%" }}>출력구분</th>
                      </tr>
                      {labelList.length !== 0 ? (
                        labelList.map((el, idx) => {
                          return (
                            <tr>
                              <td className="text-center">{idx + 1}</td>
                              <td className="text-center">{el.company}</td>
                              <td className="text-center">
                                {getPostGubun?.data?.find(el2 => el2.key === el.sendGubun)?.value}
                              </td>
                              <td className="text-center">{el.kname}</td>
                              <td className="text-center">
                                {el.printGubun2 === "1"
                                  ? "소봉투"
                                  : el.printGubun2 === "2"
                                  ? "라벨지"
                                  : el.printGubun2 === "3"
                                  ? "대봉투"
                                  : ""}
                              </td>
                              <td className="text-center">{el.printGubun}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={6}>
                            검색된 결과가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ModalEnvelopeLabel);
