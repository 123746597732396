import { useAtom } from "jotai/index";
import {
  cardList_atom,
  searchCheck_atom,
} from "../../../data/atom/dataManage/cardInquiry/cardManager_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import React, { useEffect, useRef } from "react";
import CardManagerRow from "./cardManager/cardManagerRow";
import { useGetCorpGubnList } from "../../../api/code/useGetCorpGubnList";
import { useGetBankGubnList } from "../../../api/code/useGetBankGubnList";
import { useGetJisaGubnList } from "../../../api/code/useGetJisaGubnList";
import { useVirtualizer } from "@tanstack/react-virtual";
import moment from "moment/moment";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const CardManager = () => {
  const targetRef = useRef();
  const [searchCheck, setSearchCheck] = useAtom(searchCheck_atom);
  const [cardList, setCardList] = useAtom(cardList_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const getCorpGubnList = useGetCorpGubnList();
  const getBankGubnList = useGetBankGubnList();
  const getJisaGubnList = useGetJisaGubnList();
  const searchCardList = useMutation(
    data => axios.get("/api/data/cardManage/search", { params: data }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setCardList(
          data?.map(el => {
            return { ...el, originalOutDate: el.outDate, outDate: "" };
          })
        );
      },
    }
  );
  const saveCardList = useMutation(data => axios.post("/api/data/cardManage/save", data), {
    onSuccess: res => {
      searchCardList.mutate({ useGubn: "Y" });
    },
  });

  useEffect(() => {
    if (searchCheck) {
      searchCardList.mutate({ useGubn: "Y" });
    } else {
      searchCardList.mutate({ useGubn: "N" });
    }
  }, [searchCheck]);

  const rowVirtualizer = useVirtualizer({
    count: cardList.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const btnAdd = () => {
    setCardList([
      {
        cardNo: "",
        corpGubn: "",
        gubun: "",
        kname: "",
        modDate: moment().format("YYYY-MM-DD"),
        modId: "",
        openDate: moment().format("YYYY-MM-DD"),
        ordrSeq1: 0,
        ordrSeq2: 0,
        outDate: null,
        page: 1,
        payDay: 0,
        remark: "",
        userCheck: "",
        passWord: "",
        jisa: "",
        kind: "",
        validThru: moment().format("YYYYMM"),
        newFlag: true,
        originalOutDate: null,
      },
      ...cardList,
    ]);
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (cardList.filter(el => el.newFlag || el.updateCheck === "Y").length === 0) {
        alert("저장할 내역이 없습니다.");
      } else if (cardList.filter(el => el.corpGubn === "").length !== 0) {
        alert("발급법인을 입력해주세요.");
      } else if (cardList.filter(el => el.gubun === "").length !== 0) {
        alert("구분을 선택해주세요.");
      } else if (cardList.filter(el => el.newFlag && el.jisa === "").length !== 0) {
        alert("지사구분을 선택해주세요.");
      } else if (cardList.filter(el => el.cardNo === "").length !== 0) {
        alert("카드번호를 입력해주세요.");
      } else if (cardList.filter(el => el.newFlag && el.passWord === "").length !== 0) {
        alert("비밀번호를 입력해주세요.");
      } else if (cardList.filter(el => el.newFlag && el.kind === "").length !== 0) {
        alert("용도를 선택해주세요.");
      } else if (cardList.filter(el => el.userCheck === "").length !== 0) {
        alert("사용여부를 선택해주세요.");
      } else {
        saveCardList.mutate(cardList.filter(el => el.updateCheck === "Y" || el.newFlag));
      }
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchCheck(true);
      setCardList([]);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">검색</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <input
                    type="checkbox"
                    id="chk-1"
                    className="form-check-input"
                    checked={searchCheck}
                    onChange={e => setSearchCheck(e.target.checked)}
                  />
                  <label className="filter1-label" htmlFor="chk-1">
                    사용중인 카드만 보기
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count">
                검색 결과 :&nbsp;
                <b style={{ color: "#0b4da2" }}>{cardList.filter(el => !el.newFlag).length}</b>건
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary"
                  onClick={btnAdd}
                  disabled={cardList.filter(el => el.newFlag).length !== 0}
                >
                  추가
                </button>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary m-0"
                  onClick={btnSave}
                >
                  저장
                </button>
              </div>
            </div>
            <div
              className="table-container table-sticky h-333"
              ref={targetRef}
              style={{ overflow: "auto" }}
            >
              <table
                className="table table-bordered table-responsive my-table"
                style={{
                  marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                  marginBottom: `${
                    rowVirtualizer.getTotalSize() -
                    (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                    rowVirtualizer.getVirtualItems().length * 30
                  }px`,
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "3%" }}>No</th>
                    <th style={{ width: "12.5%" }}>발급법인</th>
                    <th style={{ width: "7%" }}>구분</th>
                    <th style={{ width: "7%" }}>지사구분</th>
                    <th style={{ width: "14%" }}>카드번호</th>
                    <th style={{ width: "4.5%" }}>비밀번호</th>
                    <th style={{ width: "5%" }}>용도</th>
                    <th style={{ width: "12%" }}>카드명</th>
                    <th style={{ width: "3%" }}>순서1</th>
                    <th style={{ width: "3%" }}>순서2</th>
                    <th style={{ width: "4%" }}>유효기간</th>
                    <th style={{ width: "3%" }}>결재일</th>
                    <th style={{ width: "7%" }}>사용여부</th>
                    <th style={{ width: "3%" }}>발급일자</th>
                    <th style={{ width: "6%" }}>폐기일자</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>
                  {cardList.length !== 0 ? (
                    rowVirtualizer.getVirtualItems().map((el, idx) => {
                      return (
                        <CardManagerRow
                          key={el.key}
                          data={cardList[el.index]}
                          index={el.index}
                          getCorpGubnList={getCorpGubnList}
                          getBankGubnList={getBankGubnList}
                          getJisaGubnList={getJisaGubnList}
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={15}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
