import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 차량관리 마스터 운행정보 운행자 셀렉트박스
 */
export const useGetCarMasterMoveGubnList = () => {
  return useQuery(
    ["getCarMasterMoveGubnList"],
    async () =>
      await axios
        .get("/api/codeMng/select/getCarMasterMoveGubnList")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
