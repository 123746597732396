import { useQuery } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";

/**
 * 성명 셀렉트박스
 */
export const useGetIpControlManageRightUserList = () => {
  return useQuery(
    ["getIpControlManageRightUserList"],
    async () =>
      await axios
        .get("/api/codeMng/select/getIpControlManageRightUserList")
        .then(res => res.data.result)
        .catch(),
    { staleTime: 1000 * 60 * 10 }
  );
};
