import React, { useEffect, useRef, useState } from "react";
import SearchResultListLeftRow from "./searchResultListLeftRow";
import { useVirtualizer } from "@tanstack/react-virtual";
import { transactionHistoryResult_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useAtomValue } from "jotai";

const searchResultListLeft = props => {
  const { endRef, filterChk, afterFetch } = props;
  const resData = useAtomValue(transactionHistoryResult_atom);
  const [rowCountOnce, setRowCountOnce] = useState("10");

  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: resData.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  // 보기갯수 셀렉트 변경 이벤트
  const select_onChangeEvent = value => {
    setRowCountOnce(value);
  };

  useEffect(() => {
    if (afterFetch) targetRef.current.scrollTo(0, 0);
  }, [afterFetch]);

  return (
    <>
      <div className="col-left">
        <div className="card card-sub m-0">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-10px">
              <div className="tab1-row1-count"></div>
              <div className="d-flex align-items-center w-50 justify-content-end">
                <select
                  className="form-select filter-perpage ms-8px h-31"
                  value={rowCountOnce && rowCountOnce}
                  onChange={e => select_onChangeEvent(e.target.value)}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>
            <div
              className={`table-container table-sticky               
             ${rowCountOnce === "5" && "h-183"}
             ${rowCountOnce === "10" && "h-333"}
             ${rowCountOnce === "20" && "h-633"}`}
              ref={targetRef}
            >
              <table
                className="table table-bordered table-responsive my-table"
                style={{
                  marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                  marginBottom: `${rowVirtualizer.getTotalSize() -
                    (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                    rowVirtualizer.getVirtualItems().length * 30
                    }px`,
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "15.38%" }}>No.</th>
                    <th style={{ width: "23.07%" }}>관리번호</th>
                    <th style={{ width: "30.76%" }}>회사명</th>
                    <th style={{ width: "15.38%" }}>성명</th>
                    <th>제명여부</th>
                  </tr>
                </thead>
                <tbody>
                  {resData.length !== 0 ? (
                    rowVirtualizer.getVirtualItems().map(item => {
                      if (item.index !== resData.length - 1 || filterChk)
                        return (
                          <SearchResultListLeftRow
                            data={resData[item.index]}
                            index={item.index}
                            key={item.key}
                          />
                        );
                      else {
                        return (
                          <>
                            <SearchResultListLeftRow
                              data={resData[item.index]}
                              index={item.index}
                              key={item.key}
                            />
                            <div ref={endRef}></div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        검색된 고객이 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(searchResultListLeft);
