import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "../../../../../../components/modal/Modal";
import TableRow from "./TableRow";
import {
  transactionHistoryClickedRow_atom,
  transactionHistoryResult_atom,
} from "../../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useGetPartner } from "../../../../../../api/code/useGetPartner";
import { useGetJisaGubunAllQ1 } from "../../../../../../api/code/useGetJisaGubunAllQ1";
import moment from "moment";
import { useGetUserIdQ } from "../../../../../../api/code/useGetUserIdQ";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../../../util/axios/axios";
import { useGetMemberNm } from "../../../../../../api/code/useGetMemberNm";
import { useGetEtcSmQ } from "../../../../../../api/code/useGetEtcSmQ";
import { useGetEtcCmQ } from "../../../../../../api/code/useGetEtcCmQ";
import { decrypt } from "../../../../../../util/decrypt/decrypt";
import { useAtom, useSetAtom } from "jotai/index";

const initRow = {
  again: "",
  commission: 0,
  custDate: moment().format("YYYY-MM-DD"),
  custId: "",
  differCheck: "N",
  doneChk: "",
  downYn: "D",
  gubun1: "1",
  gubun2: "1",
  ilNo: "",
  jiroSendDate: "",
  jisa: "11",
  jisaGerae: "N",
  kname: "",
  memberNo: "",
  modDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  partner: "KDP6306",
  remark: "",
  roomNo: "",
  saleGu: "1",
  seqNo: "",
  sinDate: "",
  taxJumin: 0,
  taxTarget: "Y",
  taxWon: 0,
  tradGubn: "3",
  userid: "",
  yangdoShop: "",
  yangdoTaxTarget: "Y",
  yangdoWon1: 0,
  yangdoWon2: 0,
  yangsuShop: "",
  yangsuWon1: 0,
  yangsuWon2: 0,
  codename: "0",
  golfId: "",
  delete: false,
};

const ModalEnterTransactionDetails = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [tableRowList, setTableRowList] = useState([]);
  const queryClient = useQueryClient();

  const [clickedRow, setClickedRow] = useAtom(transactionHistoryClickedRow_atom);

  const setListData = useSetAtom(transactionHistoryResult_atom);
  const memberNm = useGetMemberNm();
  const etcCmQ = useGetEtcCmQ();
  const etcSmQ = useGetEtcSmQ();
  useGetPartner();
  useGetJisaGubunAllQ1();
  useGetUserIdQ();

  useImperativeHandle(ref, () => ({
    showModal: () => {
      if (clickedRow === null || clickedRow === undefined) {
        alert("고객을 먼저 선택해 주세요.");
        return;
      }
      setShow(true);
      searchTransactionInput.mutate(clickedRow.seqNo);
    },

    hideModal: onHide,
  }));

  const searchTransactionInput = useMutation(
    data =>
      axios.get("/api/work/transaction/searchTransactionInput", {
        params: { custId: data },
      }),
    {
      onSuccess: res => {
        let result = res.data.result;
        setTableRowList(result);
      },
    }
  );

  const saveTransactionInput = useMutation(
    data =>
      axios.post(
        "/api/work/transaction/saveTransactionInput",
        data.map(i => ({
          ...i,
          custDate: moment(i.custDate).format("YYYY-MM-DD"),
          modDate: moment(i.modDate).format("YYYY-MM-DD HH:mm:ss"),
          custId: clickedRow.seqNo,
          roomNo: i.roomNo,
          sinDate: i.sinDate ? moment(i.sinDate).format("YYYY-MM-DD") : null,
          jiroSendDate: i.jiroSendDate ? moment(i.jiroSendDate).format("YYYY-MM-DD") : null,
        }))
      ),
    {
      onSuccess: data => {
        // custId가 달라지면 값들을 새로고침
        if (data.data.result !== clickedRow.custId) {
          setClickedRow(prev => ({ ...prev, custId: data.data.result }));
          setListData(prev => {
            let tmp = [...prev];
            let idx = tmp.findIndex(i => i.seqNo === clickedRow.seqNo);
            tmp[idx].custId = data.data.result;
            return tmp;
          });
        }
        searchTransactionInput.mutate(clickedRow.seqNo);
      },
      onError: err => {
        let data = err.response.data;
        if (data?.code === "-5") {
          if (confirm(err.response.data.message))
            saveTransactionInput.mutate(tableRowList.map(i => ({ ...i, again: "Y" })));
          else saveTransactionInput.mutate(tableRowList.map(i => ({ ...i, again: "N" })));
        }
      },
    }
  );

  const onHide = () => {
    setShow(false);
    setTableRowList([]);
  };

  const btn_add = () => {
    let userInfo = decrypt("userInfo");
    let roomNo;
    switch (initRow.gubun2) {
      case "1":
        roomNo = memberNm.data[0].code;
        break;
      case "2":
        roomNo = etcCmQ.data[0].key;
        break;
      case "3":
        roomNo = etcSmQ.data[0].key;
        break;
    }

    setTableRowList([
      ...tableRowList,
      {
        ...initRow,
        partner: userInfo.loginId,
        userid: userInfo.loginId,
        jisa: userInfo.jisa,
        roomNo: roomNo,
      },
    ]);
  };

  const btn_save = () => {
    let tmp = tableRowList.filter(i => {
      if (!i.ilNo && i.delete) return false; // 추가눌렀다가 삭제한 로우
      else if (i.edited) return true; // 수정한 로우
      else if (i.ilNo && i.delete) return true; // 원래있던 로우를 삭제한 로우
      else return false;
    });
    if (tmp.length === 0) return alert("저장할 내용이 없습니다.");

    saveTransactionInput.mutate(tmp);
  };

  return (
    <>
      <Modal
        title="거래내역 기초자료 입력"
        show={show}
        setModalIsOpen={setShow}
        strClass={"modal-xxl"}
        size={"xl"}
        onHide={onHide}
      >
        <div className="modal-form">
          <div className="d-flex align-items-center justify-content-center">
            <div className="w-50 f-12 text-primary text-h1">
              구분1: 지사간 거래시-&gt;둘다 타지사 구분2: 한쪽이 샵일경우-&gt; 샵물건만 샵거래로
            </div>
            <div className="w-50 f-12 text-danger text-h2">
              샵명 or 회원증 명 다를경우-&gt;서류상 명의 이름 기재
            </div>
          </div>

          <table className="table table-bordered table-responsive my-table mt-1">
            <thead>
              <tr>
                <th style={{ width: "3.3%" }}>No.</th>
                <th style={{ width: "4.39%" }}>구분1</th>
                <th style={{ width: "4.39%" }}>구분2</th>
                <th style={{ width: "4.39%" }}>
                  거래
                  <br />
                  일자
                </th>
                <th style={{ width: "4.39%" }}>
                  거래
                  <br />
                  분류
                </th>

                <th style={{ width: "3.39%" }}>
                  거래
                  <br />
                  연번
                </th>
                <th style={{ width: "3.39%" }}>구분</th>
                <th style={{ width: "3.39%" }}>구분</th>
                <th style={{ width: "3.39%" }}>골프장명</th>
                <th style={{ width: "8.39%" }}>회원권명</th>
                <th style={{ width: "5.39%" }}>
                  회원증
                  <br />
                  No
                </th>

                <th style={{ width: "4.39%" }}>
                  서류
                  <br />
                  금액
                </th>
                <th style={{ width: "4.39%" }}>
                  매매
                  <br />
                  금액
                </th>
                <th style={{ width: "3.39%" }}>
                  Up
                  <br />
                  down
                </th>
                <th style={{ width: "4.39%" }}>수수료</th>
                <th style={{ width: "3.39%" }}>
                  명,주소
                  <br />
                  상이
                </th>

                <th style={{ width: "4.39%" }}>
                  기타
                  <br />
                  내용
                </th>
                <th style={{ width: "4.39%" }}>
                  거래
                  <br />
                  직원
                </th>
                <th style={{ width: "4.39%" }}>
                  소속
                  <br />
                  지사
                </th>
                <th style={{ width: "4.39%" }}>
                  세무
                  <br />
                  신고
                </th>
                <th style={{ width: "4.39%" }}>
                  고객
                  <br />
                  등기
                </th>

                <th style={{ width: "3.39%" }}>
                  최종
                  <br />
                  수정자
                </th>
                <th style={{ width: "4.39%" }}>
                  최종
                  <br />
                  수정일시
                </th>
                <th style={{ width: "2.39%" }}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {tableRowList.length > 0 ? (
                tableRowList.every(item => item?.delete) ? (
                  <tr>
                    <td colSpan={24}>조회 결과가 없습니다.</td>
                  </tr>
                ) : (
                  tableRowList.map((item, index) => {
                    if (item.delete) return <></>;
                    else
                      return (
                        <TableRow data={item} key={index} index={index} setData={setTableRowList} />
                      );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={24}>조회 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="d-flex align-items-center justify-content-end mt-3">
            <button className="btn btn-outline-primary mx-1" onClick={btn_add}>
              추가
            </button>
            <button className="btn btn-outline-primary mx-1" onClick={btn_save}>
              저장
            </button>
            <button className="btn btn-outline-primary ms-1" onClick={onHide}>
              취소
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default ModalEnterTransactionDetails;
