import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai/index";
import {
  cardList_atom,
  newData_atom,
  searchedParam_atom,
  searchParam_atom,
  searchParamInit,
} from "../../../data/atom/dataManage/cardInquiry/cardInquiry_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import CardInquiryRow from "./cardInquiry/cardInquiryRow";
import { useGetCardUserList } from "../../../api/code/useGetCardUserList";
import { useGetAccountList } from "../../../api/code/useGetAccountList";
import moment from "moment/moment";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import { decrypt } from "../../../util/decrypt/decrypt";
import usePrintTab from "../../../components/modal/usePrintTab";
import { useVirtualizer } from "@tanstack/react-virtual";
import useUserIdQWithout2272 from "../../../components/array/useUserIdQWithout2272";

export const CardInquiry = () => {
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [searchedParam, setSearchedParam] = useAtom(searchedParam_atom);
  const [cardList, setCardList] = useAtom(cardList_atom);
  const [newData, setNewData] = useAtom(newData_atom);
  const { addTabClosedEvent } = useRecentTabEvent();
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();
  const getCardUserList = useGetCardUserList();
  const getAccountList = useGetAccountList();
  const userIdQWithout2272 = useUserIdQWithout2272();
  const searchCard = useMutation(data => axios.get("/api/data/card/search", { params: data }), {
    onSuccess: res => {
      const data = res.data?.result;

      setNewData({});
      setSearchedParam(searchParam);
      //setCardList(data.sort((a, b) => a.account - b.account));
      setCardList(data);
    },
  });
  const targetRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: cardList.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const printCard = useMutation(
    data => axios.get("/api/data/card/print", { params: data, responseType: "blob" }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `법인카드_사용내역_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const saveCard = useMutation(data => axios.post("/api/data/card/save", data), {
    onSuccess: res => {
      searchCard.mutate(searchParam);
    },
  });

  const onChangeParamEventHandler = (key, value) => {
    setSearchParam({ ...searchParam, [key]: value });
  };

  const btnSearch = () => {
    if (searchParam.cardNo.trim() === "") {
      alert("카드번호를 입력해주세요.");
    } else if (searchParam.currDate === "" || searchParam.currDateBack === "") {
      alert("검색기간을 입력해주세요.");
    } else {
      searchCard.mutate(searchParam);
    }
  };
  const btnPrint = () => {
    if (cardList.length === 0) {
      alert("출력할 사용내역이 없습니다.");
    } else {
      printCard.mutate(searchParam);
    }
  };
  const btnAdd = () => {
    setNewData({
      account: "",
      cardName: "",
      cardNo: cardList.length !== 0 ? searchedParam?.cardNo : "",
      currDate: moment().format("YYYY-MM-DD HH:mm"),
      inputDate: moment().format("YYYY-MM-DD"),
      inputId: decrypt("userInfo").loginId,
      modDate: "",
      modId: "",
      page: 0,
      priceWon: 0,
      remark: "",
      saupno: "",
      seqNo: "",
      storeName: "",
      userId: "",
      newFlag: true,
    });
    targetRef.current.scrollTo(0, 0);
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      let filtered = cardList.filter(el => el.cardNo != null); // 소계,누계 삭제
      if (filtered.length === 0 && !newData.newFlag) {
        alert("저장할 내역이 없습니다.");
      } else if (
        filtered.filter(el => el.cardNo.trim() === "" || el.cardNo.length !== 16).length !== 0
      ) {
        alert("카드번호를 입력해주세요.");
      } else if (filtered.filter(el => el.currDate.trim() === "").length !== 0) {
        alert("사용일자를 입력해주세요.");
      } else if (filtered.filter(el => el.userId === "").length !== 0) {
        alert("사용자를 입력해주세요.");
      } else if (filtered.filter(el => el.account === "").length !== 0) {
        alert("계정과목을 입력해주세요.");
      } else if (filtered.filter(el => el.priceWon === 0).length !== 0) {
        alert("영수금액을 입력해주세요.");
      } else {
        let list = filtered.map(el => {
          return {
            ...el,
            cardNo: el.cardNo.replace(/-/g, ""),
            currDate: moment(el.currDate).format("YYYY-MM-DD HH:mm"),
            modId: decrypt("userInfo").loginId,
          };
        });

        if (newData.newFlag) {
          list = [{ ...newData, newCheck: newData.newFlag }, ...list];
        }

        saveCard.mutate(
          list.map(e => ({ ...e, currDate: moment(e.currDate).format("YYYY-MM-DD HH:mm") }))
        );
      }
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam(searchParamInit);
      setCardList([]);
      setNewData({});
    });
  }, []);

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">검색</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">카드번호</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input1"
                    value={searchParam.cardNo}
                    onChange={e => onChangeParamEventHandler("cardNo", e.target.value)}
                    onKeyDown={pressEnter}
                  />
                </div>
                <div className="filter1-field filter1-field2">
                  <label className="filter1-label">검색기간</label>
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.currDate}
                    onChange={e => onChangeParamEventHandler("currDate", e.target.value)}
                    onKeyDown={pressEnter}
                    min={"1990-01-01"}
                    max={"2099-12-31"}
                  />
                </div>
                <div className="txt_daterange">~</div>
                <div className="filter1-field filter1-field2 px-0">
                  <input
                    type="date"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.currDateBack}
                    onChange={e => onChangeParamEventHandler("currDateBack", e.target.value)}
                    onKeyDown={pressEnter}
                    min={"1990-01-01"}
                    max={"2099-12-31"}
                  />
                </div>
                <div className="filter1-field filter1-field1 ps-5">
                  <label className="filter1-label">카드명 : {cardList?.[0]?.cardName}</label>
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub mt-14px">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end mb-10px">
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btnPrint}
              >
                출력
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary"
                onClick={btnAdd}
                disabled={newData.newFlag}
              >
                추가
              </button>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary m-0"
                onClick={btnSave}
              >
                저장
              </button>
            </div>
            <div className="table-container table-sticky h-333" ref={targetRef}>
              <table
                className="table table-bordered table-responsive my-table"
                style={{
                  marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                  marginBottom: `${
                    rowVirtualizer.getTotalSize() -
                    (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                    rowVirtualizer.getVirtualItems().length * 30
                  }px`,
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "4.9%" }}>No</th>
                    <th style={{ width: "14.04%" }}>카드번호</th>
                    <th style={{ width: "12.64%" }}>사용일자</th>
                    <th style={{ width: "6.32%" }}>사용자</th>
                    <th style={{ width: "8.42%" }}>계정과목</th>
                    <th style={{ width: "12.64%" }}>영수금액(원)</th>
                    <th style={{ width: "10.53%" }}>비고</th>
                    <th style={{ width: "9.8%" }}>가맹점사업번호</th>
                    <th style={{ width: "8.42%" }}>가맹점명(사용처)</th>
                    <th style={{ width: "7%" }}>입력자</th>
                    <th>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  {newData.newFlag !== undefined && targetRef.current?.scrollTop < 30 && (
                    <CardInquiryRow
                      data={newData}
                      index={-1}
                      getCardUserList={userIdQWithout2272}
                      getAccountList={getAccountList?.data ?? []}
                      searchCard={searchCard}
                      isNew={true}
                    />
                  )}
                  {cardList.length !== 0 ? (
                    rowVirtualizer.getVirtualItems().map((el, idx) => {
                      if (
                        (cardList[idx].cardNo !== null && el.index < cardList.length - 2) ||
                        cardList[el.index].newFlag
                      ) {
                        return (
                          <CardInquiryRow
                            data={cardList[el.index]}
                            index={el.index}
                            getCardUserList={getCardUserList?.data ?? []}
                            getAccountList={getAccountList?.data ?? []}
                            searchCard={searchCard}
                            isNew={false}
                          />
                        );
                      } else if (el.index < cardList.length - 1) {
                        return (
                          <tr className="bg-lightyellow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-center">소 계</td>
                            <td className="text-end">
                              {Number(cardList[el.index].priceWon).toLocaleString()}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr className="bg-lightblue">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-center">누 계</td>
                            <td className="text-end">
                              {Number(cardList[el.index].priceWon).toLocaleString()}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>검색된 결과가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
