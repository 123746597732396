import React from "react";

export default function ModalGreenFeeTableRowHeader() {
  return (
    <>
      <tr>
        <th style={{ width: "3%" }} rowSpan={2}>
          No.
        </th>
        <th style={{ width: "3%" }} rowSpan={2}>
          삭제
        </th>
        <th style={{ width: "6%" }} rowSpan={2}>
          확인일자
        </th>
        <th style={{ width: "10%" }} colSpan={2}>
          정회원
        </th>
        <th style={{ width: "10%" }} colSpan={2}>
          가족회원
        </th>
        <th style={{ width: "10%" }} colSpan={2}>
          주중회원
        </th>
        <th style={{ width: "10%" }} colSpan={2}>
          주중가족
        </th>
        <th style={{ width: "10%" }} colSpan={2}>
          비회원
        </th>
        <th style={{ width: "10%" }} colSpan={2}>
          우대회원
        </th>
        <th style={{ width: "10%" }} colSpan={2}>
          우대가족
        </th>
        <th rowSpan={2}>기타회원</th>
      </tr>
      <tr>
        <th>주중</th>
        <th>주말</th>
        <th>주중</th>
        <th>주말</th>
        <th>주중</th>
        <th>주말</th>
        <th>주중</th>
        <th>주말</th>
        <th>주중</th>
        <th>주말</th>
        <th>주중</th>
        <th>주말</th>
        <th>주중</th>
        <th>주말</th>
      </tr>
    </>
  );
}
