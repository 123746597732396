import { useAtom } from "jotai/index";
import { registeredDeliveryHistory_searchData_atom } from "../../../../../../data/atom/workManage/postManage/registeredDeliveryHistory_atom";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";

export const RegisteredDeliveryHistoryFilterPanelCustomer = () => {
  const [searchData, setSearchData] = useAtom(registeredDeliveryHistory_searchData_atom);

  const queryClient = useQueryClient();
  const getJisaGubunAllQ1 = queryClient.getQueryData(["getJisaGubunAllQ1"]);

  const onChangeEventHandler = (key, value) => {
    if (key === "jisa") {
      setSearchData({ ...searchData, [key]: value, allDb: value === "" });
    } else {
      setSearchData({ ...searchData, [key]: value });
    }
  };

  return (
    <>
      <div className="filter1-field filter1-field1">
        <label className="filter1-label">검색 지사</label>
        <select
          className="form-select filter1-select1"
          value={searchData.jisa}
          onChange={e => onChangeEventHandler("jisa", e.target.value)}
        >
          <option value="">전체</option>
          {getJisaGubunAllQ1?.map(el => (
            <option value={el.key}>{el.value}</option>
          ))}
        </select>
      </div>
      <div className="filter1-field filter1-field2">
        <label className="filter1-label">내용물</label>
        <input
          type="text"
          className="form-control filter-input filter1-input2"
          value={searchData.keyword}
          onChange={e => onChangeEventHandler("keyword", e.target.value)}
          placeholder="업체명or이름or내용물"
        />
      </div>
    </>
  );
};
