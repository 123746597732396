import { atom } from "jotai";

export const searchParamInit = {
  select: "COMP",
  keyword: "",
  filterChk: false,
  page: 1,
};

// 고객검색해서 나온 결과를 클릭한 로우
export const transactionHistoryClickedRow_atom = atom(null);

// 거래내역 조회팝업에서 변경한 로우들
export const transactionHistoryInquiryPopupEditedRow_atom = atom({});

// 검색 결과 목록
export const transactionHistoryResult_atom = atom([]);

// 검색 결과 목록
export const transactionHistorySearchParam_atom = atom({ ...searchParamInit });
