import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom } from "jotai/index";
import {
  deliveryMessage_searchData_atom,
  deliveryMessage_searchDataInit_atom,
  deliveryMessage_selectRow_atom,
  deliveryMessage_selectRowInit_atom,
  deliveryMessage_subSearchData_atom,
  deliveryMessage_subSearchDataInit_atom,
  deliveryMessage_subSearchedTableList_atom,
  deliveryMessage_subSelectedTableData_atom,
  deliveryMessage_subSelectedTableDataInit_atom,
} from "../../../data/atom/workManage/deliveryMessage/deliveryMessage_atom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useGetUserIdQ } from "../../../api/code/useGetUserIdQ";
import { useGetEtcMgQ } from "../../../api/code/useGetEtcMgQ";
import { decrypt } from "../../../util/decrypt/decrypt";
import { useGetTeamList } from "../../../api/code/useGetTeamList";
import { useVirtualizer } from "@tanstack/react-virtual";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";
import useUserIdQWithout2272 from "../../../components/array/useUserIdQWithout2272";

export const DeliveryMessage = () => {
  const { addTabClosedEvent } = useRecentTabEvent();

  const [searchData, setSearchData] = useAtom(deliveryMessage_searchData_atom);
  const [searchedTableList, setSearchedTableList] = useState([]);

  const [subSearchData, setSubSearchData] = useAtom(deliveryMessage_subSearchData_atom);
  const [subSearchDataTemp, setSubSearchDataTemp] = useState(
    deliveryMessage_subSearchDataInit_atom
  );
  const [subSearchedTableList, setSubSearchedTableList] = useAtom(
    deliveryMessage_subSearchedTableList_atom
  );

  // 우측 선택된 전달사항 정보
  const [subSelectedTableData, setSubSelectedTableData] = useAtom(
    deliveryMessage_subSelectedTableData_atom
  );

  // 선택 칸
  const [selectedData, setSelectedData] = useAtom(deliveryMessage_selectRow_atom);

  // 답변상태인지 체크
  const [isAnswer, setIsAnswer] = useState(false);

  const getUserIdQ = useGetUserIdQ();
  const getTeamList = useGetTeamList();
  const getEtcMgQ = useGetEtcMgQ();
  const userIdQWithout2272 = useUserIdQWithout2272();

  // 전달사항 좌측 목록 조회
  const infoSearch = useQuery(
    [],
    () => axios.get("/api/work/info/search", { params: { keyword: searchData.keyword } }),
    {
      onSuccess: data => {
        const resData = data.data.result;
        setSearchedTableList(resData);

        // const curLst = resData?.filter(
        //   el => moment(el.currDate).format("YYYY-MM-DD") === selectedData?.searchType1
        // );
        //
        // if (curLst.length === 0) {
        //   setSubSearchedTableList([]);
        //   setSubSelectedTableData({
        //     currDate: "",
        //     gubun: "",
        //     ilNo: "",
        //     ilNoSeq: "",
        //     ilNoTotal: "",
        //     receiveGubun: "",
        //     receiveId: "",
        //     receiveName: "",
        //     remark: "",
        //     sendDate: "",
        //     title: "",
        //     userid: "",
        //     viewCheck: "",
        //   });
        //   setSelectedData({ searchType1: "", searchType2: "", searchType3: "" });
        //
        //   if (resData.length > 0) {
        //     infoGetList.mutate({
        //       fromDate: moment(resData[0].currDate).format("YYYY-MM-DD"),
        //       keyword: searchData.keyword,
        //     });
        //
        //     setSubSearchData({
        //       ...subSearchData,
        //       fromDate: moment(resData[0].currDate).format("YYYY-MM-DD"),
        //     });
        //   }
        // }
      },
    }
  );

  // 전달사항 클릭 목록 조회
  const infoGetList = useMutation(data => axios.get("/api/work/info/getList", { params: data }), {
    onSuccess: (res, variables) => {
      // if (selectedData.searchType2 === "") {
      //   setSelectedData({ searchType1: variables.fromDate, searchType2: res.data.result[0].ilNo });
      // } else {
      //   // setSelectedData({ ...selectedData, searchType1: variables.fromDate });
      //   setSelectedData({ searchType1: variables.fromDate, searchType2: res.data.result[0].ilNo });
      // }

      if (res.data.result.length !== 0) {
        setSubSelectedTableData({
          ...res.data.result[0],
          receiveName: getTeamList.data?.find(el => el.value === res.data.result[0].receiveName)
            ?.key,
        });
        setSelectedData({
          searchType1: variables.fromDate,
          searchType2: res.data.result[0].ilNo,
          searchType3: res.data.result[0].ilNoSeq,
        });
      } else {
        setSubSelectedTableData({
          currDate: "",
          gubun: "",
          ilNo: "",
          ilNoSeq: "",
          ilNoTotal: "",
          receiveGubun: "",
          receiveId: "",
          receiveName: "",
          remark: "",
          sendDate: "",
          title: "",
          userid: "",
          viewCheck: "",
        });
      }

      setSubSearchedTableList(res.data.result);
      setSubSearchData(variables);
      setSubSearchDataTemp(variables);
    },
  });

  // 전달사항 저장/수정
  const infoSave = useMutation(data => axios.post("/api/work/info/save", data), {
    onSuccess: data => {
      infoSearch.refetch();
      infoGetList.mutate({
        fromDate: subSearchData.fromDate,
        keyword: subSearchData.keyword,
      });

      setIsAnswer(false);
    },
  });

  // 전달사항 삭제
  const infoDelete = useMutation(data => axios.post("/api/work/info/delete", data), {
    onSuccess: data => {
      infoGetList.mutate({
        fromDate: subSearchData.fromDate,
        keyword: subSearchData.keyword,
      });
      btn_search();
    },
  });

  // 발행완료 자동답변
  const replay = useMutation(data => axios.post("/api/work/info/reply", data), {
    onSuccess: res => {
      infoSearch.refetch();
      infoGetList.mutate({
        fromDate: subSearchData.fromDate,
        keyword: subSearchData.keyword,
      });

      setIsAnswer(false);
    },
  });

  // 탭 초기화
  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchData(deliveryMessage_searchDataInit_atom);
      setSubSearchData([]);
      setSubSearchData(deliveryMessage_subSearchDataInit_atom);
      setSubSearchedTableList([]);
      setSubSelectedTableData(deliveryMessage_subSelectedTableDataInit_atom);
      setSelectedData(deliveryMessage_selectRowInit_atom);
    });
  }, []);

  const btn_search = () => {
    setSelectedData({ searchType1: "", searchType2: "", searchType3: "" });
    infoSearch.refetch();
  };

  const btn_main_tableRowClick = data => {
    setIsAnswer(false);

    const temp = {
      fromDate: moment(data.currDate).format("YYYY-MM-DD"),
      keyword: searchData.keyword,
    };

    // setSubSearchData(temp);
    // setSubSearchDataTemp(temp);

    infoGetList.mutate(temp);
  };

  const btn_sub_search = () => {
    infoGetList.mutate(subSearchDataTemp);
  };

  const btn_sub_tableRowClick = data => {
    if (isAnswer) {
      alert("수정중입니다");
      return;
    }

    setSelectedData({ ...selectedData, searchType2: data.ilNo, searchType3: data.ilNoSeq });
    if (data.receiveGubun === "2") {
      const receiveCodeData = getUserIdQ.data?.reduce((acc, item) => {
        if (item.value === data?.receiveName) {
          acc.push(item.key);
        }
        return acc;
      }, []);
      setSubSelectedTableData({ ...data, receiveName: receiveCodeData[0] });
    } else if (data.receiveGubun === "7") {
      const receiveCodeData = getTeamList.data?.reduce((acc, item) => {
        if (item.value === data?.receiveName) {
          acc.push(item.key);
        }
        return acc;
      }, []);

      setSubSelectedTableData({ ...data, receiveName: receiveCodeData[0] });
    } else {
      setSubSelectedTableData(data);
    }
    setIsAnswer(false);
    handleResizeHeight();
  };

  const btn_sub_delete = data => {
    if (isAnswer) {
      alert("수정중입니다");
      return;
    }

    if (data.userid === decrypt("userInfo").loginId || decrypt("userInfo").userPermit === "99") {
      if (window.confirm("삭제하시겠습니까?")) {
        infoDelete.mutate({
          currDate: moment(data.currDate).format("YYYY-MM-DD"),
          ilNo: data.ilNo,
          ilNoTotal: data.ilNoTotal,
          userid: data.userid,
        });
      }
    } else {
      alert("삭제 권한이 없습니다.");
    }
  };

  const btn_sub_autoAnswer = () => {
    if (window.confirm("발행완료 자동답변을 하시겠습니까?")) {
      replay.mutate({
        currDate: moment(subSelectedTableData.currDate).format("YYYY-MM-DD"),
        gubun: subSelectedTableData.gubun,
        ilNo: subSelectedTableData.ilNo,
        ilNoSeq: "0",
        // ilNoTotal: subSelectedTableData.ilNoTotal,
        // receiveGubun: subSelectedTableData.receiveGubun,
        // receiveId: subSelectedTableData.receiveName,
        // receiveName: subSelectedTableData.receiveName,
        remark: subSelectedTableData.remark,
        // sendDate: moment(subSelectedTableData.sendDate).format("YYYY-MM-DD"),
        title: subSelectedTableData.title,
        userid: subSelectedTableData.userid,
        sendDate: moment(subSelectedTableData.sendDate).format("YYYY-MM-DD HH:mm"),
        // viewCheck: subSelectedTableData.viewCheck,
      });
    }
  };
  // 전달사항 추가
  const btn_sub_add = () => {
    setSubSelectedTableData({
      currDate: moment().format("YYYY-MM-DD"),
      gubun: "2",
      // ilNo: Math.max(...subSearchedTableList.map(el => el.ilNo)) + 1,
      ilNo: "",
      // ilNoSeq: 1,
      ilNoSeq: "",
      ilNoTotal: 0,
      receiveGubun: getEtcMgQ.data[0].key,
      receiveId: "",
      receiveName: "",
      remark: "",
      sendDate: moment().format("YYYY-MM-DD HH:mm"),
      title: "",
      userid: decrypt("userInfo").loginId,
      viewCheck: "",
    });

    setIsAnswer(true);
  };

  const btn_sub_answer = () => {
    setSubSelectedTableData({
      ...subSelectedTableData,
      ilNoSeq: "", // 답변 SEQ 없이 보내기
      remark:
        ">> 메시지 원문입니다<<\n\r\n" +
        subSelectedTableData.remark +
        "\r\n==========================================\r\n\r\n",
      sendDate: moment().format("YYYY-MM-DD HH:mm"),
      title: "[답변] " + subSelectedTableData.title,
      userid: decrypt("userInfo").loginId,
      viewCheck: "",
      receiveGubun: "2",
      receiveName: subSelectedTableData.userid,
    });

    setIsAnswer(true);
  };

  const btn_sub_register = () => {
    if (
      subSelectedTableData.userid !== decrypt("userInfo").loginId &&
      decrypt("userInfo").userPermit !== "99"
    ) {
      alert("수정 권한이 없습니다.");
      return;
    }
    if (window.confirm("저장하시겠습니까?")) {
      if (subSelectedTableData.title === "") {
        alert("제목을 입력해주세요.");
        return;
      }

      if (subSelectedTableData.remark === "") {
        alert("내용을 입력해주세요.");
        return;
      }

      infoSave.mutate({
        currDate: moment(subSelectedTableData.currDate).format("YYYY-MM-DD"),
        gubun: subSelectedTableData.gubun,
        ilNo: subSelectedTableData.ilNo,
        ilNoSeq: subSelectedTableData.ilNoSeq,
        ilNoTotal: subSelectedTableData.ilNoTotal,
        receiveGubun: subSelectedTableData.receiveGubun,
        receiveId: subSelectedTableData.receiveName,
        receiveName: subSelectedTableData.receiveName,
        remark: subSelectedTableData.remark,
        sendDate: moment(subSelectedTableData.sendDate).format("YYYY-MM-DD HH:mm"),
        title: subSelectedTableData.title,
        userid: subSelectedTableData.userid,
        viewCheck: subSelectedTableData.viewCheck,
      });
    }
  };

  const btn_sub_cancel = () => {
    if (window.confirm("수정을 취소하시겠습니까?")) {
      infoGetList.mutate({
        fromDate: moment(subSearchData.fromDate).format("YYYY-MM-DD"),
        keyword: subSearchData.keyword,
      });

      setIsAnswer(false);
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btn_search();
    }
  };

  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: searchedTableList?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  const handleResizeHeight = useCallback(() => {
    let textarea = document.querySelector(`.textareaHeight`);
    // textarea.style.height = "auto";
    // textarea.style.height = textarea.scrollHeight + "px";
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="row mt-3 gx-3">
          <div className="col-5">
            <div className="filter1_container">
              <div className="filter_header_row">
                <div className="filter-title">전달사항 관리</div>
              </div>
              <div className="filter_fields_container">
                <div className="filter_fields_row" onKeyDown={pressEnter}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="filter1-field filter1-field1">
                      <input
                        type="checkbox"
                        id="chk-3"
                        className="form-check-input"
                        name="checkbox-option"
                        checked={searchData.able}
                        onChange={e => {
                          if (e.target.checked) {
                            setSearchData({ ...searchData, able: true });
                          } else {
                            setSearchData({ ...searchData, able: false, keyword: "" });
                          }
                        }}
                      />
                      <label className="filter1-label" htmlFor="chk-3">
                        검색어
                      </label>
                      <input
                        type="text"
                        className="form-control filter-input filter1-input2"
                        value={searchData.keyword}
                        onChange={e => {
                          setSearchData({ ...searchData, keyword: e.target.value });
                        }}
                        disabled={!searchData.able}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                    onClick={btn_search}
                  >
                    <img
                      className="me-2"
                      src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                      alt=""
                    />
                    검색
                  </button>
                </div>
              </div>
            </div>
            <div className="card card-main">
              <div className="card-body">
                <div className="table-container table-sticky h-633" ref={targetRef}>
                  <table
                    className="table table-bordered table-responsive my-table"
                    style={{
                      marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                      marginBottom: `${
                        rowVirtualizer.getTotalSize() -
                        (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                        rowVirtualizer.getVirtualItems().length * 30
                      }px`,
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "12%" }}>No.</th>
                        <th>전달일자</th>
                        <th style={{ width: "18%" }}>건수</th>
                        <th style={{ width: "18%" }}>긴급</th>
                        <th style={{ width: "18%" }}>일반</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchedTableList.length !== 0 ? (
                        rowVirtualizer.getVirtualItems()?.map((virtualRow, index) => (
                          <tr
                            className={`cursor-pointer ${
                              searchedTableList[virtualRow.index]?.currDate &&
                              moment(searchedTableList[virtualRow.index].currDate).format(
                                "YYYY-MM-DD"
                              ) === selectedData.searchType1 &&
                              "bg-blue"
                            }`}
                            onClick={() => {
                              setSelectedData({ ...selectedData, searchType2: "" });

                              btn_main_tableRowClick(searchedTableList[virtualRow.index]);
                            }}
                          >
                            <td>{virtualRow.index + 1}</td>
                            <td>
                              {searchedTableList[virtualRow.index]?.currDate
                                ? moment(searchedTableList[virtualRow.index].currDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </td>
                            <td>{searchedTableList[virtualRow.index].cnt}</td>
                            <td>{searchedTableList[virtualRow.index].sum1}</td>
                            <td>{searchedTableList[virtualRow.index].sum2}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5}>검색결과가 없습니다.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-7">
            <div className="card card-main">
              <div className="card-body">
                <div className="filter1_container">
                  <div className="filter_fields_container">
                    <div className="filter_fields_row">
                      <div className="filter1-field filter1-field1">
                        <label className="filter1-label">기준일자</label>
                        <input
                          type="date"
                          max="9999-12-31"
                          className="form-control filter-input filter1-input2"
                          value={subSearchDataTemp.fromDate}
                          onChange={e => {
                            setSubSearchDataTemp({
                              ...subSearchDataTemp,
                              fromDate: e.target.value,
                            });
                          }}
                        />
                        <a
                          className="btn-refresh ms-8px"
                          href={void 0}
                          onClick={btn_sub_search}
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ height: "252px" }}>
                  <div className="table-container table-sticky h-252">
                    <table className="table table-bordered table-responsive my-table my-0">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }} colSpan={2}>
                            No.
                          </th>
                          <th style={{ width: "5%" }} colSpan={2}>
                            게시자
                          </th>
                          <th style={{ width: "6%" }}>전달일시</th>
                          <th style={{ width: "6%" }} colSpan={2}>
                            전달 대상
                          </th>
                          <th style={{ width: "15%" }}>제목</th>
                          <th style={{ width: "5%" }}>삭제</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subSearchedTableList.length !== 0 ? (
                          subSearchedTableList.reduce((acc, cur, idx, arr) => {
                            if (cur?.ilNo !== arr[idx - 1]?.ilNo) {
                              return [
                                ...acc,
                                <tr
                                  className={`cursor-pointer ${
                                    cur.gubun === "1" &&
                                    !(
                                      cur.ilNo === selectedData.searchType2 &&
                                      cur.ilNoSeq === selectedData.searchType3
                                    )
                                      ? "bg-only-red"
                                      : cur.gubun === "3" &&
                                          !(
                                            cur.ilNo === selectedData.searchType2 &&
                                            cur.ilNoSeq === selectedData.searchType3
                                          )
                                        ? "bg-only-red2"
                                        : cur.ilNo === selectedData.searchType2 &&
                                            cur.ilNoSeq === selectedData.searchType3
                                          ? "bg-lightblue"
                                          : ""
                                  }`}
                                  onClick={() => btn_sub_tableRowClick(cur)}
                                >
                                  <td rowSpan={arr.filter(el => el.ilNo == cur.ilNo).length}>
                                    {cur.ilNo}
                                  </td>
                                  <td>{cur.ilNoSeq}</td>
                                  <td>
                                    {cur.gubun === "1"
                                      ? "긴급"
                                      : cur.gubun === "2"
                                        ? "일반"
                                        : cur.gubun === "3" && "공지"}
                                  </td>
                                  <td>
                                    {getUserIdQ.data?.find(el2 => el2.key === cur.userid)?.value}
                                  </td>
                                  <td>
                                    {cur?.sendDate
                                      ? moment(cur.sendDate).format("YYYY-MM-DD HH:mm")
                                      : ""}
                                  </td>
                                  <td>
                                    {
                                      getEtcMgQ.data?.find(el2 => el2.key === cur.receiveGubun)
                                        ?.value
                                    }
                                  </td>
                                  <td>{cur.receiveName}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={cur.title}
                                      readOnly={true}
                                    />
                                  </td>
                                  <td className="cursor-pointer" onClick={e => e.stopPropagation()}>
                                    {(decrypt("userInfo").userPermit === "99" ||
                                      cur.userid === decrypt("userInfo").loginId) && (
                                      <img
                                        src={process.env.PUBLIC_URL + "/images/delete.png"}
                                        alt=""
                                        onClick={() => btn_sub_delete(cur)}
                                      />
                                    )}
                                  </td>
                                </tr>,
                              ];
                            } else {
                              return [
                                ...acc,
                                <tr
                                  className={`cursor-pointer ${
                                    cur.gubun === "1" ? "bg-only-red" : ""
                                  } ${
                                    cur.ilNo === selectedData.searchType2 &&
                                    cur.ilNoSeq === selectedData.searchType3 &&
                                    "bg-lightblue"
                                  }`}
                                  onClick={() => btn_sub_tableRowClick(cur)}
                                >
                                  {/* <td>{cur.ilNo}</td> */}
                                  <td>{cur.ilNoSeq}</td>
                                  <td>
                                    {cur.gubun === "1"
                                      ? "긴급"
                                      : cur.gubun === "2"
                                        ? "일반"
                                        : cur.gubun === "3" && "공지"}
                                  </td>
                                  <td>
                                    {getUserIdQ.data?.find(el2 => el2.key === cur.userid)?.value}
                                  </td>
                                  <td>
                                    {cur?.sendDate ? moment(cur.sendDate).format("YYYY-MM-DD") : ""}
                                  </td>
                                  <td>
                                    {
                                      getEtcMgQ.data?.find(el2 => el2.key === cur.receiveGubun)
                                        ?.value
                                    }
                                  </td>
                                  <td>{cur.receiveName}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={cur.title}
                                      readOnly={true}
                                    />
                                  </td>
                                  <td className="cursor-pointer" onClick={e => e.stopPropagation()}>
                                    {(decrypt("userInfo").userPermit === "99" ||
                                      cur.userid === decrypt("userInfo").loginId) && (
                                      <img
                                        src={process.env.PUBLIC_URL + "/images/delete.png"}
                                        alt=""
                                        onClick={() => btn_sub_delete(cur)}
                                      />
                                    )}
                                  </td>
                                </tr>,
                              ];
                            }
                          }, [])
                        ) : (
                          <tr>
                            <td colSpan={9}>검색결과가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-end mt-5 mb-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={btn_sub_autoAnswer}
                    disabled={subSearchedTableList.length === 0}
                  >
                    발행완료 자동답변
                  </button>
                  <button
                    className="btn btn-outline-primary ms-2"
                    onClick={btn_sub_add}
                    // disabled={subSelectedTableData.ilNo === ""}
                  >
                    추가
                  </button>
                  <button
                    className="btn btn-outline-primary ms-2"
                    onClick={btn_sub_answer}
                    disabled={subSearchedTableList.length === 0 || isAnswer}
                  >
                    답변
                  </button>
                  <button
                    className="btn btn-outline-primary ms-2"
                    onClick={btn_sub_register}
                    // disabled={subSearchedTableList.length === 0}
                  >
                    저장
                  </button>
                  <button
                    className="btn btn-outline-primary ms-2"
                    onClick={btn_sub_cancel}
                    disabled={subSearchedTableList.length === 0}
                  >
                    취소
                  </button>
                </div>

                <table className="table table-bordered table-responsive my-table my-0">
                  <thead>
                    <tr>
                      <th style={{ width: "8%" }} colSpan={2}>
                        No.
                      </th>
                      <th style={{ width: "35%" }} colSpan={2}>
                        게시자
                      </th>
                      <th style={{ width: "22%" }}>전달일시</th>
                      <th style={{ width: "35%" }} colSpan={2}>
                        전달대상
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{subSelectedTableData.ilNo}</td>
                      <td>{subSelectedTableData.ilNoSeq}</td>
                      <td>
                        <select
                          className="form-select table-select"
                          value={subSelectedTableData?.gubun}
                          onChange={e => {
                            setSubSelectedTableData({
                              ...subSelectedTableData,
                              gubun: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled={true} selected={true}></option>
                          <option value="1">긴급</option>
                          <option value="2">일반</option>
                          <option value="3">공지</option>
                        </select>
                      </td>
                      <td style={{ width: "17.5%" }}>
                        {getUserIdQ.data?.find(el => el.key === subSelectedTableData.userid)?.value}
                      </td>
                      <td>
                        {subSelectedTableData?.sendDate
                          ? moment(subSelectedTableData.sendDate).format("YYYY-MM-DD HH:mm")
                          : ""}
                      </td>
                      <td>
                        <select
                          className="form-select table-select"
                          value={subSelectedTableData?.receiveGubun}
                          onChange={e => {
                            if (e.target.value === "2") {
                              setSubSelectedTableData({
                                ...subSelectedTableData,
                                receiveGubun: e.target.value,
                                receiveName: getUserIdQ.data[0].key,
                              });
                            } else if (e.target.value === "7") {
                              setSubSelectedTableData({
                                ...subSelectedTableData,
                                receiveGubun: e.target.value,
                                receiveName: getTeamList.data[0].key,
                              });
                            } else {
                              setSubSelectedTableData({
                                ...subSelectedTableData,
                                receiveGubun: e.target.value,
                                receiveName: "",
                              });
                            }
                          }}
                        >
                          <option value="" disabled={true}></option>
                          {getEtcMgQ.data?.map(el => (
                            <option value={el.key}>{el.value}</option>
                          ))}
                        </select>
                      </td>
                      <td style={{ width: "17.5%" }}>
                        <select
                          className="form-select table-select"
                          value={subSelectedTableData?.receiveName}
                          onChange={e => {
                            setSubSelectedTableData({
                              ...subSelectedTableData,
                              receiveName: e.target.value,
                            });
                          }}
                          disabled={
                            !(
                              subSelectedTableData.receiveGubun === "2" ||
                              subSelectedTableData.receiveGubun === "7"
                            )
                          }
                        >
                          {subSelectedTableData.receiveGubun === "2" &&
                            userIdQWithout2272.map(el => (
                              <option value={el.key}>{el.value}</option>
                            ))}
                          {subSelectedTableData.receiveGubun === "7" &&
                            getTeamList.data?.map(el => <option value={el.key}>{el.value}</option>)}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="mt-3">
                  <div className="row">
                    <div className="col-1 text-center">
                      <label className="form-label m-0">제목</label>
                    </div>
                    <div className="col-11">
                      <input
                        type="text"
                        className="form-control w-100"
                        value={subSelectedTableData?.title}
                        onChange={e => {
                          setSubSelectedTableData({
                            ...subSelectedTableData,
                            title: e.target.value,
                          });
                        }}
                        readOnly={subSelectedTableData.userid !== decrypt("userInfo").loginId}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-1 text-center">
                      <label className="form-label m-0">내용</label>
                    </div>
                    <div className="col-11">
                      <textarea
                        className="form-control w-100 textareaHeight"
                        style={{ maxHeight: "400px" }}
                        rows={10}
                        noresize
                        value={subSelectedTableData?.remark}
                        onChange={e => {
                          setSubSelectedTableData({
                            ...subSelectedTableData,
                            remark: e.target.value,
                          });
                          handleResizeHeight();
                        }}
                        readOnly={subSelectedTableData.userid !== decrypt("userInfo").loginId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
