import Modal from "../../../../../components/modal/Modal";
import React, { useState } from "react";
import { useGetPartner } from "../../../../../api/code/useGetPartner";

/**
 * 담당검색 테이블 모달
 */
export function WebOrderModal2(props) {
  const { data = [], show, onHide = () => {}, onProc = () => {} } = props;

  const [activeRow, setActiveRow] = useState(-1);
  const getPartner = useGetPartner(); // 담당딜러

  return (
    <Modal
      title="검색결과"
      show={show}
      onHide={() => {
        onHide();
        setActiveRow(-1);
      }}
    >
      <div className={"table-container"}>
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th>관리번호</th>
              <th>성명</th>
              <th>직위</th>
              <th>담당딜러</th>
              <th>지사</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((i, idx) => (
                <tr
                  onDoubleClick={() => onProc(i)}
                  onClick={() => setActiveRow(idx)}
                  className={`${activeRow === idx && "bg-blue"}`}
                >
                  <td>
                    {i?.CUST_ID ? `${i.CUST_ID.substring(0, 4)}-${i.CUST_ID.substring(4)}` : ""}
                  </td>
                  <td>{i?.KNAME ? i.KNAME : ""}</td>
                  <td>{i?.POSITION ? i.POSITION : ""}</td>
                  <td>
                    {getPartner?.data
                      ? getPartner?.data.find(el => el.key === i.PARTNER)?.value ?? i.PARTNER
                      : i.PARTNER}
                  </td>
                  <td>
                    {getPartner?.data
                      ? getPartner?.data.find(el => el.key === i.PARTNER)?.jisaName
                      : ""}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  검색 내용이 없습니다
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
