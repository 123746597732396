import React from "react";

export const TableHeaderGM = () => {
  return (
    <tr>
      <th style={{ width: "8%" }}>연번</th>
      <th style={{ width: "3%" }}>선택</th>
      <th style={{ width: "15%" }}>골프장명</th>
      <th style={{ width: "6%" }}>소재지</th>
      <th style={{ width: "4%" }}>코드</th>
      <th>회원권명</th>
      <th>국세청코드</th>
      <th style={{ width: "10%" }}>회원권분류</th>
      <th style={{ width: "8%" }}>
        기준시가
        <br />
        (85이전)
      </th>
      <th style={{ width: "12%" }}>분류</th>
      <th style={{ width: "5%" }}>삭제</th>
    </tr>
  );
};
