import React, { useState } from "react";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { useGetAcquisition } from "../../../../api/code/useGetAcquisition";

export const TaxCalcuationRequset = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState({
    codename: "",
    gubun: "2",
    issueDate: "",
    priceTot: 0,
    rcvFlag: "",
    remark: "",
    seqNo: mainData.seqNo,
  });

  const getAcquisition = useGetAcquisition();

  // 세금계산서 요청
  const customerRequestTax = useMutation(
    data => axios.post("/api/work/customer/bottom/requestTax", data),
    {
      onSuccess: res => {
        if (res.data.code === "0000") {
          alert(res.data.msg);
          setSubData({
            codename: "",
            gubun: "",
            issueDate: "",
            priceTot: 0,
            rcvFlag: "",
            remark: "",
            seqNo: mainData.seqNo,
          });
        }
      },
      onError: err => {},
    }
  );

  const btn_request = () => {
    if (window.confirm("세금계산서 요청을 하시겠습니까?")) {
      if (subData.gubun === "") {
        alert("구분을 선택해주세요.");
        return;
      }

      if (subData.issueDate === "") {
        alert("발행일자를 선택해주세요.");
        return;
      }

      if (subData.priceTot === "") {
        alert("합계금액을 입력해주세요.");
        return;
      }

      if (subData.rcvFlag === "") {
        alert("영수구분을 선택해주세요.");
        return;
      }

      if (subData.codename === "") {
        alert("회원권명을 선택해주세요.");
        return;
      }

      customerRequestTax.mutate(subData);
    }
  };

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">세금계산서요청</div>
        <div className="d-flex"></div>
      </div>
      <div className="table-container table-sticky h-443 mt-10">
        <table className="table table-bordered table-responsive my-table1">
          <tbody>
            <tr>
              <th>관리번호</th>
              <td colSpan={3}>{mainData.seqNo.replace(/(.{4})/, "$1-")}</td>
            </tr>
            <tr>
              <th style={{ width: "8.052%" }}>업체명</th>
              <td style={{ width: "25.281%" }}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={mainData.company}
                  readOnly={true}
                />
              </td>
              <th style={{ width: "8.052%" }}>구분</th>
              <td style={{ width: "25.281%" }}>
                <select
                  className="form-select table-select"
                  value={subData.gubun}
                  onChange={e => {
                    setSubData({
                      ...subData,
                      gubun: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled={true} selected={true}>
                    구분 선택
                  </option>
                  <option value="1">중개수수료</option>
                  <option value="2">용역수수료</option>
                  <option value="S">중개수수료+용역수수료</option>
                </select>
              </td>
            </tr>
            <tr>
              <th style={{ width: "8.052%" }}>발행일자</th>
              <td style={{ width: "25.281%" }}>
                <input
                  type="date"
                  max="9999-12-31"
                  className="form-control table-input"
                  value={subData?.issueDate}
                  onChange={e => {
                    setSubData({
                      ...subData,
                      issueDate: e.target.value,
                    });
                  }}
                />
              </td>
              <th style={{ width: "8.052%" }}>합계금액</th>
              <td style={{ width: "25.281%" }}>
                <input
                  type="text"
                  className="form-control table-input text-end"
                  value={subData.priceTot.toLocaleString()}
                  onChange={e => {
                    setSubData({
                      ...subData,
                      priceTot: +e.target.value.replace(/,/g, "").replace(/[^0-9]/g, ""),
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th style={{ width: "8.052%" }}>영수구분</th>
              <td style={{ width: "25.281%" }}>
                <select
                  className="form-select table-select"
                  value={subData.rcvFlag}
                  onChange={e => {
                    setSubData({
                      ...subData,
                      rcvFlag: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled={true} selected={true}>
                    영수구분 선택
                  </option>
                  <option value="Y">영수</option>
                  <option value="C">청구</option>
                </select>
              </td>
              <th style={{ width: "8.052%" }}>회원권명</th>
              <td style={{ width: "25.281%" }}>
                {/*<select
                  className="form-select table-select"
                  value={subData.codename}
                  onChange={e => {
                    setSubData({
                      ...subData,
                      codename: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled={true} selected={true}>
                    회원권명 선택
                  </option>
                  {getAcquisition.data?.map(el => (
                    <option value={el.key}>{el.value}</option>
                  ))}
                </select>*/}
                {/*<MembershipItemSelect
                  gubun={"1"}
                  data={subData?.codename}
                  keyName={"codename"}
                  onChangeEventHandler={(key, value) => {
                    setSubData({
                      ...subData,
                      codename: value,
                    });
                  }}
                />*/}
                <input
                  type="text"
                  className="form-control table-input"
                  value={subData.codename}
                  onChange={e => {
                    setSubData({
                      ...subData,
                      codename: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th style={{ width: "8.052%" }}>비고</th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form-control table-input"
                  value={subData.remark}
                  onChange={e => {
                    setSubData({
                      ...subData,
                      remark: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <button className="btn btn-outline-primary btn-tbl-add mb-2" onClick={btn_request}>
            요청
          </button>
        </div>
      </div>
    </div>
  );
};
