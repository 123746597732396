import React, { useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useVirtualizer } from "@tanstack/react-virtual";
import SearchResultRow from "./searchResultRow";
import ModalRegister from "./modal/ModalRegister";
import { TableHeaderGM } from "./tableHeader/tableHeaderGM";
import { TableHeaderGC } from "./tableHeader/tableHeaderGC";
import { TableHeaderCM } from "./tableHeader/tableHeaderCM";
import { TableHeaderSM } from "./tableHeader/tableHeaderSM";
import { useAtom, useSetAtom } from "jotai/index";
import {
  addRowIndex_atom,
  editedRowList_atom,
  membershipItemList_atom,
  membershipItemListLength_atom,
  searchParam_atom,
  searchTypeFlag_atom,
} from "../../../data/atom/dataManage/membershipItem/membershipItem_atom";

const searchResultTable = props => {
  const virtualTableRef = useRef(null);
  const { scrollRef, refetchFromFirstPage } = props;
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [searchTypeFlag, setSearchTypeFlag] = useAtom(searchTypeFlag_atom);
  const [membershipItemList, setMembershipItemList] = useAtom(membershipItemList_atom);
  const setMembershipItemListLength = useSetAtom(membershipItemListLength_atom);
  const [editedRowList, setEditedRowList] = useAtom(editedRowList_atom);
  const [addRowIndex, setAddRowIndex] = useAtom(addRowIndex_atom);
  const [registerModalShow, setRegisterModalShow] = useState(false);

  const updateMembershipItem = useMutation(
    data => axios.post("/api/data/memberManage/saveETCCODEGM", data),
    {
      onSuccess: res => {
        setMembershipItemList([]);

        refetchFromFirstPage();
      },
    }
  );

  const saveGcCode = useMutation(data => axios.post("/api/data/memberManage/saveETCCODE", data), {
    onSuccess: res => {},
  });
  const saveGmCode = useMutation(data => axios.post("/api/data/memberManage/saveETCCODE2", data), {
    onSuccess: res => {},
  });

  const rowVirtualizer = useVirtualizer({
    count: membershipItemList?.length,
    getScrollElement: () => virtualTableRef.current,
    estimateSize: () => 30,
    overscan: 10,
  });

  const btnAddRow = () => {
    setMembershipItemList([
      {
        checkYn: false,
        clazz: "",
        code: "",
        codename: "",
        custId: "",
        gcCode: "",
        gubun1: "",
        idx: -1 - addRowIndex,
        membershipType: "",
        remark: "",
        rowCount: "",
        searchType: searchParam.searchType,
        value1: "",
        value2: "",
        newFlag: true,
      },
      ...membershipItemList,
    ]);
    setAddRowIndex(prev => {
      return Number(prev + 1);
    });

    virtualTableRef.current.scrollTo(0, 0);
  };

  const btnSave = async () => {
    if (confirm("저장하시겠습니까?")) {
      if (editedRowList.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else {
        if (editedRowList.filter(el => el.value1 === "").length !== 0) {
          switch (searchTypeFlag) {
            case "1":
              alert("골프장명을 입력해주세요.");
              break;
            case "3":
            case "6":
              alert("콘도명을 입력해주세요.");
              break;
            case "4":
            case "7":
              alert("스포츠장명을 입력해주세요.");
              break;
          }
        } else if (editedRowList.filter(el => el.codename.trim() === "").length !== 0) {
          alert("회원권명을 입력해주세요.");
        } else if (editedRowList.filter(el => el.gubun1 === "").length !== 0) {
          alert("회원권 분류를 입력해주세요.");
        } else {
          await setMembershipItemListLength(0);
          updateMembershipItem.mutate(editedRowList);
        }
      }
    }
  };

  const btnGcCode = () => {
    if (confirm("추가하시겠습니까?")) {
      if (editedRowList.filter(el => el.checkYn).length === 0) {
        alert("선택된 항목이 없습니다.");
      } else {
        saveGcCode.mutate(editedRowList.filter(el => el.checkYn));
      }
    }
  };

  const btnGmCode = () => {
    if (confirm("추가하시겠습니까?")) {
      if (editedRowList.filter(el => el.checkYn).length === 0) {
        alert("선택된 항목이 없습니다.");
      } else {
        saveGmCode.mutate(editedRowList.filter(el => el.checkYn));
      }
    }
  };

  const btnRegister = () => {
    setRegisterModalShow(true);
  };

  return (
    <>
      <ModalRegister
        show={registerModalShow}
        onHide={() => setRegisterModalShow(false)}
        refetchFromFirstPage={refetchFromFirstPage}
      />
      <div className="d-flex align-items-center justify-content-between">
        <div className="tab1-row1-count">
          검색 결과 :&nbsp;
          <b style={{ color: "#0b4da2" }}>
            {membershipItemList.length !== 0
              ? membershipItemList[membershipItemList.length - 1].totalSum
              : "0"}
          </b>
          건
        </div>
        <div className="d-flex align-items-center w-50 justify-content-end">
          {searchTypeFlag !== "2" ? (
            <button
              className="btn btn-outline-primary ms-8px"
              onClick={btnAddRow}
              disabled={membershipItemList.filter(el => el.newFlag).length !== 0}
            >
              추가
            </button>
          ) : (
            <></>
          )}
          <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
            저장
          </button>
        </div>
      </div>
      <div
        className="table-container table-sticky mt-10px h-528"
        ref={virtualTableRef}
        style={{ overflow: "auto" }}
      >
        <table
          className={`table table-bordered table-responsive my-table ${
            membershipItemList.length === 0 ? "mt-0" : ""
          }`}
          style={{
            marginBottom: `${
              rowVirtualizer.getTotalSize() -
              rowVirtualizer.getVirtualItems().length * 30 -
              (rowVirtualizer.range?.startIndex ?? 0) * 30
            }px`,
            marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
          }}
        >
          <thead>
            {searchTypeFlag === "1" || searchTypeFlag === "5" ? (
              <TableHeaderGM />
            ) : searchTypeFlag === "2" ? (
              <TableHeaderGC />
            ) : searchTypeFlag === "3" || searchTypeFlag === "6" ? (
              <TableHeaderCM />
            ) : searchTypeFlag === "4" || searchTypeFlag === "7" ? (
              <TableHeaderSM />
            ) : (
              <></>
            )}
          </thead>
          <tbody>
            {membershipItemList.length !== 0 ? (
              rowVirtualizer.getVirtualItems()?.map(el => {
                if (el.index !== membershipItemList.length - 1) {
                  return (
                    <SearchResultRow
                      key={el.key}
                      data={membershipItemList[el.index]}
                      index={el.index}
                      refetchFromFirstPage={refetchFromFirstPage}
                    />
                  );
                } else {
                  return (
                    <>
                      <div ref={scrollRef} />
                      <SearchResultRow
                        key={el.key}
                        data={membershipItemList[el.index]}
                        index={el.index}
                        refetchFromFirstPage={refetchFromFirstPage}
                      />
                    </>
                  );
                }
              })
            ) : (
              <tr>
                <td colSpan={11}>검색된 결과가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="filter-buttons flex-center-end mt-2">
        <button
          className="btn btn-outline-dark my-btn-outline-dark"
          onClick={btnGcCode}
          disabled={searchTypeFlag !== "1"}
        >
          ETCCODE : 국세청코드’GC’ 추가
        </button>
        <button
          className="btn btn-outline-dark my-btn-outline-dark"
          onClick={btnGmCode}
          disabled={searchTypeFlag !== "1"}
        >
          ETCCODE2 : 국세청코드‘GM’ 추가
        </button>
        <button className="btn btn-outline-dark my-btn-outline-dark" onClick={btnRegister}>
          ETCCODE2 미존재 코드 가져오기
        </button>
      </div>
    </>
  );
};

export default React.memo(searchResultTable);
