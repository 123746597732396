import { atom } from "jotai";

export const searchParamInit = {
  delCheck: "N",
  keyword: "",
  workUser: "0",
  page: 1,
};
export const searchParam_atom = atom(searchParamInit);
export const textList_atom = atom([]);
export const daposUserManage_modifyData = atom([]);
export const daposUserManage_addData = atom(null);
export const daposUserManage_createMode = atom(false);
export const daposUserManage_backupData = atom([]);
