import React, { useEffect, useState } from "react";
import Select from "react-select-virtualized";

const modalRegisterRow = props => {
  const { key, data, index, setModalEditedRowList } = props;
  const [currentData, setCurrentData] = useState({ ...data });

  const gubun1Select = [
    { value: "", label: "" },
    { value: "11", label: "저가대" },
    { value: "22", label: "중가대" },
    { value: "33", label: "고가대" },
    { value: "34", label: "최고가대" },
  ];
  const membershipTypeSelect = [
    { value: "", label: "" },
    { value: "11", label: "일반회원권(특별 VIP포함)" },
    { value: "21", label: "여자 회원권" },
    { value: "31", label: "주중 회원권" },
    { value: "41", label: "무기명회원권" },
  ];

  useEffect(() => {
    setCurrentData({ ...data });
  }, [data]);

  const onChangeModalEventHandler = (key, value) => {
    const newData = { ...currentData, [key]: value };
    setCurrentData(newData);

    setModalEditedRowList(prev => {
      let flagIdx = -1;
      prev = prev.map((el, idx) => {
        if (el.idx === newData.idx) {
          flagIdx = idx;
          return { ...newData };
        } else return el;
      });
      if (flagIdx === -1) prev.push({ ...newData });

      return prev;
    });
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{currentData.code}</td>
      <td>{currentData.codename}</td>
      <td>
        <div className="flex-center-center">
          <input
            type="checkbox"
            onChange={e => onChangeModalEventHandler("check", e.target.checked)}
          />
        </div>
      </td>
      <td>
        <Select
          className="select-virtual"
          options={gubun1Select}
          value={gubun1Select?.find(option => option.value === currentData?.gubun1)}
          onChange={e => onChangeModalEventHandler("gubun1", e?.value)}
        />
      </td>
      <td>
        <Select
          className="select-virtual"
          options={membershipTypeSelect}
          value={membershipTypeSelect?.find(option => option.value === currentData?.membershipType)}
          onChange={e => onChangeModalEventHandler("membershipType", e?.value)}
        />
      </td>
    </tr>
  );
};

export default React.memo(modalRegisterRow);
