import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../util/axios/axios";
import { DaumPostcode } from "../../components/daumPostcode/daumPostcode";

export const EditProfile = ({ state, setState }) => {
  const [mainData, setMainData] = useState([]);

  const [isEditPassword, setIsEditPassword] = useState(false);

  const checkLogin = useMutation(() => axios.get("/api/auth/checkLogin"), {
    onSuccess: res => {
      setMainData({ ...res.data.result, password: "" });
    },
  });

  const updateInfo = useMutation(data => axios.post("/api/auth/updateInfo", data), {
    onSuccess: res => {
      setIsEditPassword(false);
      checkLogin.mutate();
    },
    onError: err => {},
  });

  useEffect(() => {
    checkLogin.mutate();
  }, []);

  const btn_onClick_modify = () => {
    if (window.confirm("수정하시겠습니까?")) {
      updateInfo.mutate({ type: "U", ...mainData });
    }
  };

  const btn_onClick_password = () => {
    if (isEditPassword) {
      updateInfo.mutate({ type: "P", password: mainData.password });
    } else {
      setIsEditPassword(true);
    }
  };

  return (
    <Modal show={true} centered>
      <Modal.Header>
        <Modal.Title>개인정보 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className="f-medium f-15 mb-3">개인정보</h3>
        <div>
          <table className="table table-bordered table-responsive my-table1 m-0 mt-2">
            <tbody>
              <tr>
                <th style={{ width: "25%" }}>사원번호</th>
                <td colSpan={2}>{mainData.sawonId}</td>
              </tr>
              <tr>
                <th>사용자 명</th>
                <td colSpan={2}>{mainData.userName}</td>
              </tr>
              <tr>
                <th>휴대폰</th>
                <td colSpan={2}>
                  <input
                    type="text"
                    className="form-control"
                    value={mainData.remark}
                    onChange={e => {
                      setMainData({ ...mainData, remark: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th rowSpan={2}>주소</th>
                <td style={{ width: "40%" }}>
                  <div className="flex-center-between">
                    <input
                      type="text"
                      className="form-control"
                      value={mainData.rzipcode}
                      readOnly={true}
                    />
                    {/*<button className="btn btn-outline-primary ms-3px">찾기</button>*/}
                    <DaumPostcode
                      onCompleteCallback={data => {
                        setMainData({
                          ...mainData,
                          rzipcode: data.zonecode,
                          zipcode: data.zonecode,
                          rbdcode: data.bdCode,
                          addresTxt: data.fullAddr,
                          addres: "",
                        });
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={mainData.addresTxt}
                    readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <input
                    type="text"
                    className="form-control"
                    value={mainData.addres}
                    onChange={e => {
                      setMainData({ ...mainData, addres: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>내선번호1</th>
                <td colSpan={2}>
                  <input
                    type="text"
                    className="form-control"
                    value={mainData.ext1}
                    onChange={e => {
                      setMainData({ ...mainData, ext1: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>내선번호2</th>
                <td colSpan={2}>
                  <input
                    type="text"
                    className="form-control"
                    value={mainData.ext2}
                    onChange={e => {
                      setMainData({ ...mainData, ext2: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>내선번호3</th>
                <td colSpan={2}>
                  <input
                    type="text"
                    className="form-control"
                    value={mainData.ext3}
                    onChange={e => {
                      setMainData({ ...mainData, ext3: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>사용자 ID</th>
                <td colSpan={2}>{mainData.userId}</td>
              </tr>
              <tr>
                <th>비밀번호</th>
                <td colSpan={2}>
                  <div className="flex-center-between">
                    <input
                      type="password"
                      className="form-control"
                      value={mainData.password}
                      onChange={e => {
                        setMainData({ ...mainData, password: e.target.value });
                      }}
                      readOnly={!isEditPassword}
                    />
                    <button
                      className="btn btn-outline-primary ms-3px"
                      onClick={btn_onClick_password}
                    >
                      {isEditPassword ? "완료" : "변경"}
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>부재중 대결</th>
                <td colSpan={2}>
                  <div className="flex-center-between">
                    {/*<input type="date" className="form-control" />*/}
                    {/*<span className="txt_daterange mx-3px">~</span>*/}
                    {/*<input type="date" className="form-control" />*/}
                    {/*<button className="btn btn-outline-primary ms-3px">설정</button>*/}
                    <input
                      type="checkbox"
                      checked={mainData.absenceYn === "Y"}
                      onChange={e => {
                        setMainData({
                          ...mainData,
                          absenceYn: e.target.checked ? "Y" : "N",
                        });
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={btn_onClick_modify}>
          수정
        </button>
        <button className="btn btn-outline-primary" onClick={() => setState(false)}>
          닫기
        </button>
      </Modal.Footer>
    </Modal>
  );
};
