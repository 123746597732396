import { atom } from "jotai";

export const SearchParamInit = {
  jisa: "99",
  jongmok1: "GM",
  jongmok2: "",
  keyword: "",
  page: 1,
  fromDate: null,
  toDate: null,
  year: 1,
  tableLimit: "5",
  autoPreview: true, // 우측 보존이미지 자동보기
};

export const previewImgModalInit = { data: null, isOpen: false, index: -1 };

// 고객검색해서 나온 결과
export const documentManageSearchResult_atom = atom([]);

// 고객검색해서 나온 결과를 클릭한 로우
export const documentManageClickedRow_atom = atom(null);

// 검색 파라미터
export const documentManageSearchParam_atom = atom({ ...SearchParamInit });
export const documentManageEtccodeDw_atom = atom({});
export const docImgArr_atom = atom(Array(24).fill(""));
export const previewImgModal_atom = atom(previewImgModalInit);
