import { atom } from "jotai";
import moment from "moment";
import { decrypt } from "../../../../util/decrypt/decrypt";

export const SMStransferHistory_searchDataInit_atom = {
  fromDate: moment().subtract(2, "month").format("YYYY-MM-DD"),
  // fromDate: "2023-01-01",
  toDate: moment().format("YYYY-MM-DD"),
  // toDate: "2023-05-30",
  jisaGubun: "",
  li_gubun: "9",
  li_send_gubun: "9",
  rb: "mine",
  // sendName: decrypt("userInfo")?.userPermit === "99" ? "00" : decrypt("userInfo")?.loginId,
  sendName: "",
  spamText: "",
  page: 1,
};

export const SMStransferHistory_searchData_atom = atom(SMStransferHistory_searchDataInit_atom);

export const SMStransferHistory_searchedTableList_atom = atom([]);

export const SMStransferHistory_searchedTableTotalCount_atom = atom(0);

export const SMStransferHistory_deleteList_atom = atom([]);
