import React, { useEffect, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtom } from "jotai/index";
import {
  carDetail_atom,
  carDriveInfo_atom,
  clickedRow_atom,
} from "../../../../data/atom/dataManage/carManage/driveInput_atom";
import DriveInputListRow from "./component/driveInputListRow";

const DriveInputList = props => {
  const { searchDriveInfo, drveUserList } = props;
  const targetRef = useRef();
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [carDetail, setCarDetail] = useAtom(carDetail_atom);
  const [driveInfo, setDriveInfo] = useAtom(carDriveInfo_atom);

  useEffect(() => {
    if (clickedRow.carrNumb !== undefined) {
      searchDriveInfo.mutate(clickedRow);
    }
  }, [clickedRow]);

  const rowVirtualizer = useVirtualizer({
    count: driveInfo.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub mt-4">
      <div className="card-body">
        <div
          className="table-container table-sticky h-633"
          style={{ overflow: "auto" }}
          ref={targetRef}
        >
          <table
            className="table table-bordered table-responsivex my-table"
            style={{
              width: "150vw",
              marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
              marginBottom: `${
                rowVirtualizer.getTotalSize() -
                (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                rowVirtualizer.getVirtualItems().length * 30
              }px`,
            }}
          >
            <thead>
              <tr>
                <th rowSpan={2} style={{ width: "3%" }}>
                  연번
                </th>
                <th rowSpan={2} style={{ width: "5%" }}>
                  차량번호
                </th>
                <th rowSpan={2} style={{ width: "6%" }}>
                  출발일시
                </th>
                <th rowSpan={2} style={{ width: "6%" }}>
                  복귀일시
                </th>
                <th rowSpan={2} style={{ width: "4%" }}>
                  운행자
                </th>
                <th rowSpan={2} style={{ width: "4%" }}>
                  운행구분
                </th>
                <th rowSpan={2} style={{ width: "16%" }}>
                  운행목적
                </th>

                <th rowSpan={2} style={{ width: "3.28%" }}>
                  출발지
                </th>
                <th rowSpan={2} style={{ width: "10%" }}>
                  목적지
                </th>
                <th colSpan={2} style={{ width: "8.21%" }}>
                  적산거리계
                </th>
                <th rowSpan={2} style={{ width: "5.64%" }}>
                  주행거리(km)
                </th>
                <th colSpan={2} style={{ width: "9.95%" }}>
                  통행료
                </th>
                <th colSpan={2} style={{ width: "9.95%" }}>
                  하이패스
                </th>
                <th rowSpan={2}>비고</th>
              </tr>
              <tr>
                <th style={{ width: "4.105%" }}>출발전</th>
                <th>도착후</th>
                <th style={{ width: "4.975%" }}>현 금</th>
                <th>하이패스</th>
                <th style={{ width: "4.975%" }}>충전금액</th>
                <th>잔 액</th>
              </tr>
            </thead>
            <tbody>
              {driveInfo.length !== 0 ? (
                rowVirtualizer.getVirtualItems().map(el => {
                  return (
                    <DriveInputListRow
                      data={driveInfo[el.index]}
                      index={el.index}
                      drveUserList={drveUserList}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={17}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DriveInputList);
