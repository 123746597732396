import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import moment from "moment/moment";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import { regex_only_number } from "../../../../util/validation";
import { decrypt } from "../../../../util/decrypt/decrypt";

export const BusinessRegistration = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);

  const [subData, setSubData] = useState([{}]);

  const [currentSubData, setCurrentSubData] = useState({
    caddres: "",
    caddresTxt: "",
    company: "",
    corpNo: "",
    currDate: "",
    czipcode: "",
    inDate: "",
    jongmok: "",
    kname: "",
    modDate: "",
    modid: "",
    number: 0,
    openDate: "",
    permit: "",
    rcAddres: "",
    rcAddresTxt: "",
    rcAreaCode: "",
    rcBdcode: "",
    rcZipcode: "",
    remark: "",
    saupno: "",
    sekumDamdang: "",
    sekumDirectcall: "",
    sekumEmail: "",
    sekumHandphon: "",
    seqNo: mainData.seqNo,
    upte: "",
    userid: decrypt("userInfo").loginId,
  });
  // const [currentSubDataIndex, setCurrentSubDataIndex] = useState(0);

  // 사업자등록증 조회
  const customerGetBizInfo = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getBizInfo", {
        params: {
          seqNo: data,
        },
      }),
    {
      onSuccess: res => {
        if (res.data.result.length !== 0) {
          setCurrentSubData({
            ...res.data.result[0],
            inDate: moment(res.data.result[0].inDate).format("YYYY-MM-DD"),
            openDate: res.data.result[0].openDate
              ? moment(res.data.result[0].openDate).format("YYYY-MM-DD")
              : "",
            currDate: res.data.result[0].currDate
              ? moment(res.data.result[0].currDate).format("YYYY-MM-DD")
              : "",
          });
        } else {
          setCurrentSubData({
            caddres: "",
            caddresTxt: "",
            company: "",
            corpNo: "",
            currDate: "",
            czipcode: "",
            inDate: "",
            jongmok: "",
            kname: "",
            modDate: "",
            modid: "",
            number: 0,
            openDate: "",
            permit: "",
            rcAddres: "",
            rcAddresTxt: "",
            rcAreaCode: "",
            rcBdcode: "",
            rcZipcode: "",
            remark: "",
            saupno: "",
            sekumDamdang: "",
            sekumDirectcall: "",
            sekumEmail: "",
            sekumHandphon: "",
            seqNo: mainData.seqNo,
            upte: "",
            userid: decrypt("userInfo").loginId,
          });
        }

        setSubData(res.data.result);
      },
    }
  );

  // 사업자등록증 저장/수정
  const customerSaveBizInfo = useMutation(
    data => axios.post("/api/work/customer/bottom/saveBizInfo", data),
    {
      onSuccess: res => {
        // alert("저장되었습니다.");
        customerGetBizInfo.mutate(mainData.seqNo);
      },
      onError: err => { },
    }
  );

  // 사업자등록증 삭제
  const customerDeleteBizInfo = useMutation(
    data => axios.post("/api/work/customer/bottom/deleteBizInfo", data),
    {
      onSuccess: res => {
        // alert("삭제되었습니다.");
        customerGetBizInfo.mutate(mainData.seqNo);
      },
      onError: err => { },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetBizInfo.mutate(mainData.seqNo);
  }, [mainData.seqNo]);

  const tableRowClick = (data, idx) => {
    setCurrentSubData({
      ...data,
      inDate: data.inDate ? moment(data.inDate).format("YYYY-MM-DD") : "",
      openDate: data.openDate ? moment(data.openDate).format("YYYY-MM-DD") : "",
      currDate: data.currDate ? moment(data.currDate).format("YYYY-MM-DD") : "",
    });
  };

  const btn_save = () => {
    if (window.confirm("추가하시겠습니까?")) {
      if (subData.length >= 3) {
        alert("사업자등록증은 3개까지만 등록 가능합니다.");
        return;
      }

      setCurrentSubData({
        caddres: "",
        caddresTxt: "",
        company: "",
        corpNo: "",
        currDate: "",
        czipcode: "",
        inDate: "",
        jongmok: "",
        kname: "",
        modDate: "",
        modid: "",
        number: subData.length + 1,
        openDate: "",
        permit: "",
        rcAddres: "",
        rcAddresTxt: "",
        rcAreaCode: "",
        rcBdcode: "",
        rcZipcode: "",
        remark: "",
        saupno: "",
        sekumDamdang: "",
        sekumDirectcall: "",
        sekumEmail: "",
        sekumHandphon: "",
        seqNo: mainData.seqNo,
        upte: "",
        userid: decrypt("userInfo").loginId,
      });
    }
  };

  const btn_register = () => {


    if (currentSubData.number > Math.max(...subData.map(el => +el.number))) {
      if (window.confirm("사업자등록증을 저장하시겠습니까?")) {
        customerSaveBizInfo.mutate({
          caddres: currentSubData.caddres,
          caddresTxt: currentSubData.caddresTxt,
          company: currentSubData.company,
          corpNo: currentSubData.corpNo,
          currDate:
            currentSubData.currDate !== ""
              ? moment(currentSubData.currDate).format("YYYY-MM-DD")
              : null,
          czipcode: currentSubData.czipcode,
          // inDate: null,
          jongmok: currentSubData.jongmok,
          kname: currentSubData.kname,
          // modDate: null,
          // modid: null,
          number: subData.length + 1,
          openDate:
            currentSubData.openDate !== ""
              ? moment(currentSubData.openDate).format("YYYY-MM-DD")
              : null,
          permit: currentSubData.permit,
          rcAddres: currentSubData.rcAddres,
          rcAddresTxt: currentSubData.rcAddresTxt,
          rcAreaCode: currentSubData.rcAreaCode,
          rcBdcode: currentSubData.rcBdcode,
          rcZipcode: currentSubData.rcZipcode,
          remark: currentSubData.remark,
          saupno: currentSubData.saupno,
          sekumDamdang: currentSubData.sekumDamdang,
          sekumDirectcall: currentSubData.sekumDirectcall,
          sekumEmail: currentSubData.sekumEmail,
          sekumHandphon: currentSubData.sekumHandphon,
          seqNo: mainData.seqNo,
          upte: currentSubData.upte,
          userid: decrypt("userInfo").loginId,
        });
      }
    } else {
      if (window.confirm("사업자등록증을 수정하시겠습니까?")) {
        customerSaveBizInfo.mutate({
          caddres: currentSubData.caddres,
          caddresTxt: currentSubData.caddresTxt,
          company: currentSubData.company,
          corpNo: currentSubData.corpNo,
          currDate:
            currentSubData.currDate !== ""
              ? moment(currentSubData.currDate).format("YYYY-MM-DD")
              : null,
          czipcode: currentSubData.czipcode,
          // inDate: null,
          jongmok: currentSubData.jongmok,
          kname: currentSubData.kname,
          // modDate: null,
          // modid: null,
          number: currentSubData.number,
          openDate:
            currentSubData.openDate !== ""
              ? moment(currentSubData.openDate).format("YYYY-MM-DD")
              : null,
          permit: currentSubData.permit,
          rcAddres: currentSubData.rcAddres,
          rcAddresTxt: currentSubData.rcAddresTxt,
          rcAreaCode: currentSubData.rcAreaCode,
          rcBdcode: currentSubData.rcBdcode,
          rcZipcode: currentSubData.rcZipcode,
          remark: currentSubData.remark,
          saupno: currentSubData.saupno,
          sekumDamdang: currentSubData.sekumDamdang,
          sekumDirectcall: currentSubData.sekumDirectcall,
          sekumEmail: currentSubData.sekumEmail,
          sekumHandphon: currentSubData.sekumHandphon,
          seqNo: mainData.seqNo,
          upte: currentSubData.upte,
          userid: decrypt("userInfo").loginId,
        });
      }
    }
  };

  const btn_cancel = () => {
    if (window.confirm("취소하시겠습니까?")) {
      customerGetBizInfo.mutate(mainData.seqNo);
    }
  };

  const btn_delete = data => {
    if (window.confirm("삭제하시겠습니까?")) {
      customerDeleteBizInfo.mutate({
        number: currentSubData.number,
        seqNo: currentSubData.seqNo,
      });
    }
  };


  return (
    <div className="tab2-content-container">
      <div className="d-flex" style={{ gap: "14px" }}>
        <div className="w-50">
          <div className="d-flex align-items-center justify-content-between">
            <div className="table-tabs-sub d-flex justify-content-between w-100 m-0">
              <div>
                사업자등록증 (<span>{subData.length ?? "0"}</span>건)
              </div>
              <button className="btn btn-outline-primary btn-tbl-add" onClick={btn_save}>
                추가
              </button>
            </div>
          </div>

          <div className="table-container table-sticky h-443 mt-10">
            <table className="table table-bordered table-responsive my-table mt-0">
              <colgroup>
                <col width="5%" />
                <col width="20%" />
                <col width="20%" />
                <col width="5%" />
              </colgroup>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>사업자번호</th>
                  <th>법인명</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {subData.length !== 0 ? (
                  subData?.map((el, idx) => {
                    return (
                      <tr
                        className={`cursor-pointer ${currentSubData?.number === el?.number && "bg-blue"
                          }`}
                        onClick={() => tableRowClick(el, idx)}
                      >
                        <td>
                          <div>{idx + 1}</div>
                        </td>
                        <td>{el.saupno}</td>
                        <td>{el.company}</td>
                        <td onClick={() => btn_delete(el)}>
                          <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center">
                      검색된 사업자등록증 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="w-100">
          <table className="table table-bordered table-responsive my-table1">
            <tbody>
              <tr>
                <th colSpan={8}>사업자등록증 내용</th>
              </tr>
              <tr>
                <th>관리번호</th>
                <td colSpan={3}>{mainData.seqNo.replace(/(.{4})/, "$1-")}</td>
              </tr>
              <tr>
                <th style={{ width: "8%" }}>사업자 번호</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.saupno}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, saupno: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>법인명</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.company}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, company: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>대표이사</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.kname}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, kname: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>개업년월</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="date"
                    className="form-control table-input"
                    value={currentSubData.openDate}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, openDate: e.target.value });
                    }}
                    max="9999-12-31"
                  />
                </td>
              </tr>
              <tr>
                <th style={{ width: "8%" }}>법인 등록번호</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.corpNo}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, corpNo: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>업테</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.upte}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, upte: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>종목</th>
                <td colSpan={3}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.jongmok}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, jongmok: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ width: "8%" }}>사업자 소재지(도로명)</th>
                <td className="pe-0 border-end-0">
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control table-input"
                      value={currentSubData.rcAreaCode}
                      readOnly={true}
                    />
                    <DaumPostcode
                      className="btn btn-outline-primary ms-3px"
                      onCompleteCallback={data => {
                        // setSubData({
                        //   ...subData,
                        //   rcZipcode: data.zonecode,
                        //   rcAddres: data.fullAddr,
                        // });
                        setCurrentSubData({
                          ...currentSubData,
                          rcAreaCode: data.zonecode,
                          rcAddresTxt: data.fullAddr,
                          rcBdcode: data.bdCode,
                        });
                      }}
                    />
                  </div>
                </td>
                <td className="ps-0 border-start-0" colSpan={6}>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control table-input ms-3px"
                      value={currentSubData.rcAddresTxt}
                      readOnly={true}
                    />
                    <input
                      type="text"
                      className="form-control table-input ms-3px"
                      value={currentSubData.rcAddres}
                      onChange={e => {
                        setCurrentSubData({ ...currentSubData, rcAddres: e.target.value });
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th colSpan={8}>세금계산서 발행 관련</th>
              </tr>
              <tr>
                <th style={{ width: "8%" }}>담당자</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.sekumDamdang}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, sekumDamdang: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>휴대폰</th>
                <td style={{ width: "15%" }}>
                  {/*<div className="d-flex">*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control table-input col3-dash"*/}
                  {/*    value={currentSubData.sekumDirectcall1}*/}
                  {/*    onChange={e => {*/}
                  {/*      // setSubData({ ...subData, sekumDirectcall1: e.target.value });*/}

                  {/*      setCurrentSubData({ ...currentSubData, sekumDirectcall1: e.target.value });*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*  <span className="txt_dash">-</span>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control table-input col3-dash"*/}
                  {/*    value={currentSubData.sekumDirectcall2}*/}
                  {/*    onChange={e => {*/}
                  {/*      // setSubData({ ...subData, sekumDirectcall2: e.target.value });*/}

                  {/*      setCurrentSubData({ ...currentSubData, sekumDirectcall2: e.target.value });*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*  <span className="txt_dash">-</span>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control table-input col3-dash"*/}
                  {/*    value={currentSubData.sekumDirectcall3}*/}
                  {/*    onChange={e => {*/}
                  {/*      // setSubData({ ...subData, sekumDirectcall3: e.target.value });*/}

                  {/*      setCurrentSubData({ ...currentSubData, sekumDirectcall3: e.target.value });*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</div>*/}

                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.sekumDirectcall}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, sekumDirectcall: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>직통</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.sekumHandphon}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, sekumHandphon: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>메일주소</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.sekumEmail}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, sekumEmail: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ width: "8%" }}>교부사유</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.remark}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, remark: e.target.value });
                    }}
                  />
                </td>
                <th style={{ width: "8%" }}>교부일자</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="date"
                    className="form-control table-input"
                    value={currentSubData.currDate}
                    onChange={e => {
                      setCurrentSubData({ ...currentSubData, currDate: e.target.value });
                    }}
                    max="9999-12-31"
                  />
                </td>
                <th style={{ width: "8%" }}>최초입력</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={currentSubData.userid}
                    // onChange={e => {
                    //   setCurrentSubData({ ...currentSubData, userid: e.target.value });
                    // }}
                    readOnly={true}
                  />
                </td>
                <th style={{ width: "8%" }}>최종수정</th>
                <td style={{ width: "15%" }}>
                  <input
                    type="text"
                    className="form-control table-input"
                    value={
                      decrypt("userInfo").userName +
                      "/" +
                      decrypt("userInfo").loginId +
                      "/" +
                      moment().format("YYYY-MM-DD")
                    }
                    readOnly={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-end" style={{ gap: "8px" }}>
            <button className="btn btn-outline-primary btn-tbl-add mb-2" onClick={btn_register}>
              저장
            </button>
            <button className="btn btn-outline-primary btn-tbl-add mb-2" onClick={btn_cancel}>
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
