import { atom } from "jotai";
import moment from "moment";

export const individualPerformance_searchDataInit_atom = {
  fromDate: null,
  toDate: null,
  job: "",
  outCheck: "Y",
  searchType: "1",
  userId: "",
  isUserIdEnabled: false,
  vatCheck: "Y",
  weekGubn: null,
  yearMnth: moment().format("YYYY-MM"),
  year: moment().format("YYYY"),
};

export const individualPerformance_searchData_atom = atom(
  individualPerformance_searchDataInit_atom
);

export const individualPerformance_searchedTableListInit_atom = {
  result: [],
  resultSum: [],
  total: {},
  tableColumns: [],
  tableRows: [],
};

export const individualPerformance_searchedTableList_atom = atom(
  individualPerformance_searchedTableListInit_atom
);

export const individualPerformance_currentTable_atom = atom("1");

export const individualPerformance_filteredSawonList_atom = atom([]);
export const individualPerformance_filteredJisaGubun_atom = atom([]);
