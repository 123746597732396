import React, { useEffect, useState } from "react";
import { axios } from "../../../../../util/axios/axios";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useAtom } from "jotai";
import {
  PersonExperiTab_Data,
  PersonExperiTab_addData,
  PersonExperiTab_backupData,
  PersonExperiTab_createMode,
  PersonExperiTab_modifyData,
  PersonExperiTab_sawonId,
} from "../../../../../data/atom/personManage/personMasterManage/PersonExperiTab_atom";
import { decrypt } from "../../../../../util/decrypt/decrypt";

export default function PersonExperiTab({ sawonId, userId }) {
  const defaultURL = "";
  const initialModifyData = {
    company: "",
    inDate: moment(new Date()),
    modDate: "",
    modId: "",
    outDate: moment(new Date()),
    position: "",
    sawonId: sawonId,
    seqNo: 0,
    subject: "",
    userid: userId,
  };

  const [createMode, setCreateMode] = useAtom(PersonExperiTab_createMode);
  const [data, setData] = useAtom(PersonExperiTab_Data);
  const [modifyData, setModifyData] = useAtom(PersonExperiTab_modifyData);
  const [addData, setAddData] = useAtom(PersonExperiTab_addData);
  const [backupData, setBackupData] = useAtom(PersonExperiTab_backupData);
  const [saveSawonId, setSaveSawonId] = useAtom(PersonExperiTab_sawonId);

  // API - 경력사항 조회
  const { mutate: mutate_personExperi, isLoading: getLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getExperi", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        const data = result?.map(item => {
          return {
            ...item,
            inDate: item?.inDate ? moment(item?.inDate).format("YYYY-MM-DD") : "",
            outDate: item?.outDate ? moment(item?.outDate).format("YYYY-MM-DD") : "",
          };
        });

        setData(data);
        setBackupData(data);
        setModifyData([]);
        setCreateMode(false);
        setAddData(null);
      },
    }
  );

  // API - 경력사항 생성/수정
  const { mutate: mutate_personExperiEdit, isLoading: modifyLoading } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/saveExperi", data),
    {
      onSuccess: res => {
        mutate_personExperi(sawonId);
        setCreateMode(false);
        setAddData(null);
        setModifyData([]);
      },
    }
  );

  // API - 경력사항 삭제
  const { mutate: mutate_personExperiDelete } = useMutation(
    data => axios.post(defaultURL + "/api/emp/empMasterMng/bottom/deleteExperi", data),
    {
      onSuccess: res => {
        mutate_personExperi(sawonId);
      },
    }
  );

  useEffect(() => {
    setSaveSawonId(sawonId);
    if (saveSawonId !== sawonId) {
      mutate_personExperi(sawonId);
    }
  }, []);

  const changeHandler = (e, targetData) => {
    const { name, value } = e.target;

    const newDataList = data.map(item => {
      if (item.seqNo === targetData.seqNo) {
        return { ...item, [name]: value };
      } else {
        return { ...item };
      }
    });
    setData(newDataList);

    // api 통신 데이터
    const isData = element => {
      return element.seqNo === targetData.seqNo;
    };

    const idx = modifyData?.findIndex(isData);
    if (idx === -1) {
      setModifyData([...modifyData, { ...targetData, [name]: value }]);
    } else {
      const newModifyData = modifyData.map(item => {
        if (item.seqNo === targetData.seqNo) {
          return { ...item, [name]: value };
        } else {
          return { ...item };
        }
      });
      setModifyData(newModifyData);
    }
  };

  return (
    <>
      <div className="px-20px py-15px">
        <div className={`table-container table-sticky h-183`}>
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>연번</th>
                <th style={{ width: "8%" }}>입사일자</th>
                <th style={{ width: "8%" }}>퇴사일자</th>
                <th style={{ width: "8%" }}>회사명</th>
                <th style={{ width: "8%" }}>직위</th>
                <th style={{ width: "8%" }}>담당업무</th>
                {decrypt("userInfo").userPermit === "99" && <th style={{ width: "3%" }}>삭제</th>}
              </tr>
            </thead>
            <tbody>
              {createMode && (
                <tr>
                  <td></td>
                  <td>
                    <input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, inDate: e.target.value });
                      }}
                      value={addData.inDate}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      max={"9999-12-31"}
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, outDate: e.target.value });
                      }}
                      value={addData.outDate}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, company: e.target.value });
                      }}
                      value={addData.company}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, position: e.target.value });
                      }}
                      value={addData.position}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control table-input"
                      onChange={e => {
                        setAddData({ ...addData, subject: e.target.value });
                      }}
                      value={addData.subject}
                    />
                  </td>
                  {decrypt("userInfo").userPermit === "99" && <td></td>}
                </tr>
              )}
              {data.length > 0
                ? data.map((experiData, index) => (
                    <tr className="cursor-pointer" onClick={() => {}}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          value={experiData.inDate}
                          name="inDate"
                          onChange={e => changeHandler(e, experiData)}
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          value={experiData.outDate}
                          name="outDate"
                          onChange={e => changeHandler(e, experiData)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={experiData.company}
                          name="company"
                          onChange={e => changeHandler(e, experiData)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={experiData.position}
                          name="position"
                          onChange={e => changeHandler(e, experiData)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={experiData.subject}
                          name="subject"
                          onChange={e => changeHandler(e, experiData)}
                        />
                      </td>
                      {decrypt("userInfo").userPermit === "99" && (
                        <td
                          className="cursor-pointer"
                          onClick={() => {
                            const result = window.confirm("해당 기록을 삭제하시겠습니까?");
                            if (result) {
                              mutate_personExperiDelete({
                                sawonId: sawonId,
                                seqNo: experiData.seqNo,
                              });
                            } else {
                            }
                          }}
                        >
                          <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
                        </td>
                      )}
                    </tr>
                  ))
                : !createMode && (
                    <tr className="cursor-pointer" onClick={() => {}}>
                      <td colSpan={decrypt("userInfo").userPermit === "99" ? 8 : 7}>
                        조회된 데이터가 없습니다.
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>

        <div className="d-flex align-items-center justify-content-end mt-10px">
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              if (!createMode) {
                setCreateMode(true);
                setAddData(initialModifyData);
              }
            }}
          >
            추가
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              const lastModifyData = modifyData.map(item => {
                return {
                  ...item,
                  inDate: item?.inDate === "" ? null : item?.inDate,
                  outDate: item?.outDate === "" ? null : item?.outDate,
                };
              });
              if (addData) {
                mutate_personExperiEdit([
                  ...lastModifyData,
                  {
                    ...addData,
                    inDate: addData?.inDate === "" ? null : addData?.inDate,
                    outDate: addData?.outDate === "" ? null : addData?.outDate,
                  },
                ]);
              } else {
                if (lastModifyData.length > 0) {
                  mutate_personExperiEdit(lastModifyData);
                }
              }
            }}
          >
            저장
          </button>
          <button
            className="btn btn-outline-primary tab1-row1-btn"
            onClick={() => {
              setCreateMode(false);
              setModifyData([]);
              setData(backupData);
              alert("변경 사항이 취소되었습니다.");
            }}
          >
            취소
          </button>
        </div>
      </div>
    </>
  );
}
