import { atom } from "jotai";

export const searchParamInit = {
  carNumberKeyword: "",
  carrJisa: "00",
  ownGubun: "A",
  oilGubun: "00",
  carName: "",
  check: "N",
  page: 1,
  init: false,
};
export const tableRowLimit_atom = atom("5");
export const clickedRow_atom = atom({});
export const isRegisterMode_atom = atom(false);
export const searchParam_atom = atom({ ...searchParamInit });
export const carList_atom = atom([]);
export const carDetail_atom = atom({});
export const carDriveInfo_atom = atom([]);
export const carDriveInfoEditedRowList_atom = atom([]);
