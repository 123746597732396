import { atom } from "jotai";
import moment from "moment";

export const annualLeaveManage_seasrchOption = atom({
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  jisaGubn: "999", // 지사구분 셀렉트 박스 - 999 ->전체
  kname: "",
  toDate: moment(new Date()).format("YYYY-MM-DD"),
  outCheck: "Y",
});

export const annualLeaveManage_seasrchData = atom([]);
export const annualLeaveManage_detail = atom({});
export const annualLeaveManage_selectedDetailIdx = atom("");
