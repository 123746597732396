import InputYdInput from "../component/InputYdInput";
import React, { useMemo } from "react";
import { useAtom } from "jotai/index";
import {
  currentRow_atom,
  defaultCondition_atom,
  finalTax_atom,
  juminPrintParam_atom,
  medoja_atom,
  mesuja_atom,
  ocrNapbuPrintParam_atom,
  ocrPrintParam_atom,
  payback_atom,
  result_atom,
  resultListInit,
  ydDivide_atom,
  ydInput_atom,
  ydPrintParam_atom,
} from "../../../../data/atom/workManage/transferTax/transferTax_atom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import moment from "moment/moment";

export default function YdInput() {
  const [currentRow, setCurrentRow] = useAtom(currentRow_atom);
  const [defaultCondition, setDefaultCondition] = useAtom(defaultCondition_atom);
  const [ydInput, setYdInput] = useAtom(ydInput_atom);
  const [ydDivide, setYdDivide] = useAtom(ydDivide_atom);
  const [medoja, setMedoja] = useAtom(medoja_atom);
  const [mesuja, setMesuja] = useAtom(mesuja_atom);
  const [result, setResult] = useAtom(result_atom);
  const [payback, setPayback] = useAtom(payback_atom);
  const [finalTax, setFinalTax] = useAtom(finalTax_atom);
  const [ydPrintParam, setYdPrintParam] = useAtom(ydPrintParam_atom);
  const [ocrPrintParam, setOcrPrintParam] = useAtom(ocrPrintParam_atom);
  const [ocrNapbuPrintParam, setOcrNapbuPrintParam] = useAtom(ocrNapbuPrintParam_atom);
  const [juminPrintParam, setJuminPrintParam] = useAtom(juminPrintParam_atom);

  const queryClient = useQueryClient();
  const getTaxRate = queryClient.getQueryData(["getTaxRate"]);
  const getSemusu = queryClient.getQueryData(["getSemusu"]);
  const getGolfList = queryClient.getQueryData(["getGolfList3"]);
  const getCondoadmin = queryClient.getQueryData(["getCondoadmin"]);
  const getSportMemberadmin = queryClient.getQueryData(["getSportMemberadmin"]);
  const getGolfListOption = useMemo(
    () =>
      getGolfList?.map(el => {
        return <option value={el.code}>{el.codename}</option>;
      }),
    [getGolfList]
  );
  const getCondoadminOption = useMemo(
    () =>
      getCondoadmin?.map(el => {
        return <option value={el.key}>{el.value}</option>;
      }),
    [getCondoadmin]
  );
  const getSportMemberadminOption = useMemo(
    () =>
      getSportMemberadmin?.map(el => {
        return <option value={el.key}>{el.value}</option>;
      }),
    [getSportMemberadmin]
  );
  const calcTax = useMutation(
    data => axios.post("/api/work/transferTax/calcTax", data),

    {
      onSuccess: (res, variables) => {
        const data = res.data.result;

        applyCalcResult(data);
      },
      onError: (err, variables) => {
        const error = err.response.data;

        if (error.code === "-5") {
          if (confirm(error.message)) {
            calcTax.mutate({ ...variables, mulgaCalc: true });
          }
        }
      },
    }
  );
  const applyCalcResult = data => {
    const yangdoSumResult = data.transferSodukAmntSum;
    const gwasePyojunResult = Number(data.taxBaseSum?.replace(/,/g, ""));
    const seyulResult = Number(data.lastTaxSum?.replace("%", "").replace(/,/g, ""));
    const sanchulCostResult = Number(data.sanchulSum?.replace(/,/g, ""));
    const nujinCostResult = Number(data.taxAppendSum?.replace("만원", "").replace(/,/g, ""));
    const gasanCostResult = data.gasanEarly + data.gasanNapbu;
    let napbuCostResult = Number(data.lastSanTaxSum?.replace(/,/g, ""));
    const gammyunCostResult = data.ivn_b11;
    const tenGongjeResult = data.tenPercent;
    const calcReasonTextResult = data.calcReasonText;
    setResult({
      ...result,
      resultList: result.resultList.map((el, idx) => {
        if (idx < defaultCondition.yangdo) {
          return {
            needCost: data?.list?.[idx]?.expenseAmnt,
            ydChaik: data?.list?.[idx]?.transferAmnt,
          };
        } else {
          return resultListInit;
        }
      }),
      yangdoSum: yangdoSumResult,
      gwasePyojun: gwasePyojunResult,
      seyul: seyulResult,
      sanchulCost: sanchulCostResult,
      nujinCost: nujinCostResult,
      gasanCost: gasanCostResult,
      napbuCost: napbuCostResult,
      gammyunCost: gammyunCostResult,
      tenGongje: tenGongjeResult,
      calcReasonText: calcReasonTextResult,
    });

    const yangdoFinal = Number(data.yandoTaxSum?.replace(/,/g, ""));
    const gasanCostFinal = data.gasanEarly + data.gasanNapbu;
    const juminFinal = Number(data.juminTaxSum?.replace(/,/g, ""));
    const taxSumFinal = Number(data.sumSanTaxJumTaxSum?.replace(/,/g, ""));
    const napbuEndDateFinal = data.napbuMagamDate;
    const overtimeFinal = Number(data.napbuOverSum?.replace("일", ""));
    setFinalTax({
      ...finalTax,
      yangdo: yangdoFinal,
      gasan: gasanCostFinal,
      jumin: juminFinal,
      taxSum: taxSumFinal,
      napbuEndDate: napbuEndDateFinal,
      overtime: overtimeFinal,
    });

    if (napbuCostResult < 10000000) {
      setYdDivide({
        flag: false,
        input1: 0,
        input2: 0,
      });
    }

    const ivnB16 =
      sanchulCostResult - medoja.numTo - tenGongjeResult - medoja.num2 - medoja.num1 < 0
        ? 0
        : sanchulCostResult - medoja.numTo - tenGongjeResult - medoja.num2 - medoja.num1;

    napbuCostResult = ivnB16 > 0 ? napbuCostResult : 0;
    setYdPrintParam({
      buygName: mesuja?.[0]?.kname,
      buygName2: mesuja?.[1]?.kname,
      company: getSemusu?.find(el => el.key === medoja.semusu)?.company,
      custName: medoja?.kname,
      ivnB10: sanchulCostResult,
      ivnB11: medoja.numTo,
      ivnB13: medoja.num2,
      ivnB14: tenGongjeResult,
      ivnB1411: data.gasanEarly, //ivn_b1411,
      ivnB1412: data.gasanNapbu, //ivn_b1412,
      ivnB141Tot: gasanCostResult,
      ivnB15: medoja.num1,
      ivnB18: napbuCostResult,
      ivnB5Tot: yangdoSumResult,
      ivnB5TotPre: medoja.numFrom,
      ivnB7Tot: defaultCondition.gongje,
      ivnB8Tot: gwasePyojunResult,
      jusoAddr: medoja?.jusoTxt,
      list: [
        ...ydInput.filter(
          el => el.tradeGubun !== "" && el.objectName !== "" && el.transferDate !== ""
        ),
      ],
      // list: [...ydInput],
      lnB32:
        ivnB16 <= 0 && medoja.num1 > 0
          ? Math.abs(
              sanchulCostResult -
                medoja.numTo -
                tenGongjeResult -
                medoja.num2 +
                gasanCostResult -
                medoja.num1
            )
          : 0,
      lnJumin10: juminFinal,
      lnWonValue: Number(((napbuCostResult * 0.1) / 10)?.toFixed(0) * 10),
      lsB9: seyulResult,
      mulgaCalc: false,
      notice1: defaultCondition.singo === "A" ? "1" : "0",
      notice2: defaultCondition.singo === "B" ? "1" : "0",
      notice3: defaultCondition.singo === "C" ? "1" : "0",
      notice4: defaultCondition.singo === "D" ? "1" : "0",
      regtIdno: mesuja?.[0]?.juminno,
      regtIdno2: mesuja?.[1]?.juminno,
      regtNumb: medoja?.juminno,
      reportDate: finalTax?.sinDate,
      isSetPrintParam: true,
    });

    setOcrPrintParam({
      address1: medoja.jusoTxt,
      address2: medoja.juso,
      asCompany: getSemusu?.find(el => el?.custId === medoja.semusu)?.company,
      asCompanyCode: getSemusu?.find(el => el?.custId === medoja.semusu)?.gubunCode,
      asDate1: moment(napbuEndDateFinal).format("YYYYMMDD"),
      asDate2: moment(napbuEndDateFinal).format("YYYYMMDD"),
      asJuminNo: medoja.juminno,
      asKname: medoja.kname,
      asRegistNo: getSemusu?.find(el => el?.custId === medoja.semusu)?.registNo,
      asTel: "",
      divide: ydDivide.flag ? "Y" : "N",
      gasanTax1: gasanCostResult,
      gasanTax2: gasanCostResult,
      gubun:
        defaultCondition.singo === "A"
          ? "1"
          : defaultCondition.singo === "B"
          ? "2"
          : defaultCondition.singo === "C"
          ? "3"
          : defaultCondition.singo === "D"
          ? "4"
          : "",
      taxSum1: ydDivide.flag
        ? ydDivide.input1 + gasanCostResult
        : napbuCostResult - gasanCostResult + gasanCostResult,
      taxSum2: ydDivide.flag ? ydDivide.input2 + gasanCostResult : 0,
      yangdoDate: moment(ydInput?.[0]?.transferDate).format("YYYYMMDD"),
      yangdoTax1: ydDivide.flag ? ydDivide.input1 : napbuCostResult - gasanCostResult,
      yangdoTax2: ydDivide.flag ? ydDivide.input2 : 0,
      isSetPrintParam: true,
    });

    setOcrNapbuPrintParam({
      asCompany: getSemusu?.find(el => el?.[0]?.custId === medoja.semusu)?.company,
      asDate: moment(napbuEndDateFinal).format("YYYYMMDD"),
      asDate2: moment(napbuEndDateFinal).format("YYYYMMDD"),
      asDept: currentRow.jisa,
      asDivideFlag: ydDivide.flag ? "Y" : "N",
      asExpectDate: defaultCondition.napbu ? moment(defaultCondition.napbu).format("YYYYMMDD") : "",
      asKname: medoja.kname,
      asSysDate: moment().format("YYYYMMDD"),
      asYuncheil: overtimeFinal,
      isSetPrintParam: true,
    });

    setJuminPrintParam({
      clazz: currentRow.clazz,
      asAddres: `${medoja.jusoTxt} ${medoja.juso}`,
      asDate: moment(napbuEndDateFinal).format("YYYYMMDD"),
      asJisa: currentRow.jisa,
      asJuminNo: medoja.juminno?.replace(/^(\d{6})(\d{7})$/g, "$1-$2"),
      asKname: medoja.kname,
      asYangdoDate: moment(ydInput?.[0]?.transferDate).format("YYYYMMDD"),
      asYangdoMulgunCnt: defaultCondition.yangdo,
      asYangdoseSingoil: moment().format("YYYYMMDD"),
      buygName: mesuja?.[0]?.kname,
      buygName2: mesuja?.[1]?.kname,
      code: ydInput[0].objectName,
      ivnB18: napbuCostResult,
      ivnB8Tot: gwasePyojunResult,
      lnWonValue: Number(((napbuCostResult * 0.1) / 10)?.toFixed(0) * 10),
      notice1: defaultCondition.singo === "A" ? "1" : "0",
      notice2: defaultCondition.singo === "B" ? "1" : "0",
      notice3: defaultCondition.singo === "C" ? "1" : "0",
      notice4: defaultCondition.singo === "D" ? "1" : "0",
      regtIdno:
        mesuja?.[0]?.juminno.length === 13
          ? mesuja?.[0]?.juminno?.replace(/^(\d{6})(\d{7})$/g, "$1-$2")
          : mesuja?.[0]?.juminno?.replace(/^(\d{3})(\d{2})(\d{5})$/g, "$1-$2-$3"),
      regtIdno2:
        mesuja?.[1]?.juminno.length === 13
          ? mesuja?.[1]?.juminno?.replace(/^(\d{6})(\d{7})$/g, "$1-$2")
          : mesuja?.[1]?.juminno?.replace(/^(\d{3})(\d{2})(\d{5})$/g, "$1-$2-$3"),
      zipCode: medoja?.zipcode,
      isSetPrintParam: true,
    });
  };

  const btnCalculate = () => {
    if (ydInput.filter(el => el.tradeGubun !== "").length !== defaultCondition.yangdo) {
      alert("거래구분을 선택해주세요.");
    } else if (
      ydInput.filter(el => el.objectName !== "" || ["1", "4", "5", "9"].includes(el.tradeGubun)) // 실거래
        .length !== defaultCondition.yangdo
    ) {
      alert("회원권명을 선택해주세요.");
    } else if (ydInput.filter(el => el.transferDate !== "").length !== defaultCondition.yangdo) {
      alert("양도일자를 입력해주세요.");
    } else if (
      ydInput.filter(
        el =>
          (el.acquireAmnt !== 0 && !["3", "7"].includes(el.tradeGubun)) || // 환산실거래가 아닌경우에는 취득가액 제대로 적은 경우만 통과
          ["3", "7"].includes(el.tradeGubun) // 환산실거래는 통과
      ).length !== defaultCondition.yangdo
    ) {
      alert("취득가액을 입력해주세요.");
    } else if (defaultCondition.sungsilCheck && defaultCondition.sungsil === "") {
      alert("납부 불성실 유형을 선택해주세요.");
    } else {
      calcTax.mutate({
        gubunDate: defaultCondition.singo === "D" ?? false, //신고구분이 기한 후 일때 true
        ivn_b11: Number(medoja.numTo), //감면세액
        ivn_b13: Number(medoja.num2), //원천징수
        ivn_b15: Number(medoja.num1), //기신고금액
        ivn_b7_1: defaultCondition.gongje,
        ivn_b7_1_pre: Number(medoja.numFrom), //기신고양도금액
        napbu: defaultCondition.sungsil === "A" ? "1" : defaultCondition.sungsil === "B" ? "2" : "", //gubunDate가 true일 때 납부 불성실 유무 1: 무신고, 2: 과소신고
        napbuCheck: defaultCondition.gasanCheck, //납부 기한 후 양도세 산출 시에는 계산 후에 반드시 체크
        napbuDate: defaultCondition.napbu, //납부예정일
        list: ydInput.slice(0, defaultCondition.yangdo),
        mulgaCalc: false,
      });
    }
  };
  const pressEnter = e => {
    if (e.key === "Enter") {
      btnCalculate();
    }
  };

  return (
    <div className="card card-main w-100">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title m-0">양도내역 입력</h5>
        <button className="btn btn-outline-primary" onClick={btnCalculate}>
          계산
        </button>
      </div>

      <div className="card-body ">
        <p className="p_quot text-end mb-10px">단위 : 원</p>
        <div className="table-container table-sticky" onKeyDown={pressEnter}>
          <table className="table table-bordered table-responsive my-table mt-0">
            <thead>
              <tr>
                <th style={{ minWidth: "88px", maxWidth: "88px" }}>구분</th>
                <th style={{ width: "17%" }}>1건</th>
                <th style={{ width: "17%" }}>2건</th>
                <th style={{ width: "17%" }}>3건</th>
                <th style={{ width: "17%" }}>4건</th>
                <th style={{ width: "17%" }}>5건</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>거래구분</td>
                <InputYdInput
                  type="select"
                  keyName="tradeGubun"
                  data={ydInput}
                  setData={setYdInput}
                >
                  <option value="1">실거래</option>
                  <option value="2">분양실거래</option>
                  <option value="6">분양실거래(증여)</option>
                  <option value="3">환산실거래</option>
                  <option value="7">환산실거래(증여)</option>
                  <option value="4">실거래(상속)</option>
                  <option value="5">실거래(증여)</option>
                  <option value="9">실거래(반환)</option>
                  <option value="8">공매(경매)</option>
                  <option value="" hidden={true} />
                </InputYdInput>
              </tr>
              <tr>
                <td>회원권명</td>
                <InputYdInput
                  type="select"
                  keyName="objectName"
                  data={ydInput}
                  setData={setYdInput}
                >
                  {currentRow?.clazz === "GM" && getGolfListOption}
                  {currentRow?.clazz === "CM" && getCondoadminOption}
                  {currentRow?.clazz === "SM" && getSportMemberadminOption}
                  {!["GM", "CM", "SM"].includes(currentRow?.clazz) && getGolfListOption}
                  <option value="" hidden={true} />
                </InputYdInput>
              </tr>
              <tr>
                <td>양도일자</td>
                <InputYdInput
                  type="date"
                  keyName="transferDate"
                  data={ydInput}
                  setData={setYdInput}
                />
              </tr>
              <tr>
                <td>양도가액</td>
                <InputYdInput
                  type="text"
                  keyName="transferAmnt"
                  data={ydInput}
                  setData={setYdInput}
                />
              </tr>
              <tr>
                <td>취득일자</td>
                <InputYdInput
                  type="date"
                  keyName="acquireDate"
                  data={ydInput}
                  setData={setYdInput}
                />
              </tr>
              <tr>
                <td>취득가액</td>
                <InputYdInput
                  type="text"
                  keyName="acquireAmnt"
                  data={ydInput}
                  setData={setYdInput}
                />
              </tr>
              <tr>
                <td>필요경비</td>
                <InputYdInput
                  type="text"
                  keyName="expenseAmnt"
                  data={ydInput}
                  setData={setYdInput}
                />
              </tr>
              <tr>
                <td>필요경비내역</td>
                <InputYdInput
                  type="text"
                  keyName="expenseItem"
                  data={ydInput}
                  setData={setYdInput}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
