import React, { useEffect, useRef, useState } from "react";
import { decrypt } from "../../../../util/decrypt/decrypt";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";

// tabs
import PersonalRecordTab from "./personMasterTabs/PersonalRecordTab";
import PersonMoveTab from "./personMasterTabs/PersonMoveTab";
import PersonSchoolTab from "./personMasterTabs/PersonSchoolTab";
import PersonFamilyTab from "./personMasterTabs/PersonFamilyTab";
import PersonExperiTab from "./personMasterTabs/PersonExperiTab";
import PersonLicenseTab from "./personMasterTabs/PersonLicenseTab";
import PersonPunishTab from "./personMasterTabs/PersonPunishTab";
import PersonBenefiTab from "./personMasterTabs/PersonBenefiTab";
import PersonYunchaTab from "./personMasterTabs/PersonYunchaTab";

import { useGetWork } from "../../../../api/code/useGetWork";
import { useAtom } from "jotai";
import {
  personMasterManage_backupData,
  personMasterManage_bottomTabSelect,
  personMasterManage_memberData,
  personMasterManage_memberId,
  personMasterManage_printOption,
  personMasterManage_searchOption,
  personMasterManage_searchResult,
  personMasterManage_split_data,
  personMasterManage_table_row,
} from "../../../../data/atom/personManage/personMasterManage/personMasterManage_atom";
import { useGetHouse } from "../../../../api/code/useGetHouse";
import { useGetHouseGubun } from "../../../../api/code/useGetHouseGubun";
import { useGetSedaeRelation } from "../../../../api/code/useGetSedaeRelation";
import useRecentTabEvent from "../../../../components/layout/useRecentTabEvent";

import usePersonMasterTabInitialize from "../../../../util/initializeAtomHook/usePersonMasterTabInitialize";
import usePrintTab from "../../../../components/modal/usePrintTab";

export const PersonMasterManage = () => {
  const profileImgRef = useRef();

  useGetWork();

  const defaultURL = "";
  // 검색어가 셀렉트박스인 search option key list
  const searchOptionSelectKeyList = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "10",
    "13",
    "15",
    "18",
    "20",
  ];
  const searchOptionDateList = ["5", "15", "18", "20"];
  const initialModifyData = {
    addres1: "",
    addres2: "",
    addres3: "",
    addres4: "",
    addresA: "",
    addresB: "",
    addresC: "",
    addresD: "",
    authName: "",
    authority: "",
    birthMmdd: "",
    bohoonCheck: "N",
    carCheck: "N",
    cellular: "",
    cellular2: "",
    cname: "",
    delYn: "",
    dept: "",
    duzonId: "",
    eduCheck: "",
    eduLast: "",
    eduName: "",
    email: "",
    emailPassword: "",
    ename: "",
    ext1: "",
    ext2: "",
    ext3: "",
    house: "",
    houseGubun: "",
    inChDate: moment(new Date()),
    inCheck: "",
    inDate: moment(new Date()),
    inGubun: "1",
    job: "",
    jobName: "",
    juminNo: "",
    kname: "",
    lunaCheck: "1",
    mailIssue: "N",
    modDate: moment(new Date()),
    modId: "",
    outDate: moment(new Date()),
    outDateEnd: moment(new Date()),
    outDateStart: moment(new Date()),
    partJob: "",
    perMail: "",
    raddres1: "",
    raddres2: "",
    raddres3: "",
    raddres4: "",
    rank: "",
    rareaCode1: "",
    rareaCode2: "",
    rareaCode3: "",
    rareaCode4: "",
    rbdcode1: "",
    rbdcode2: "",
    rbdcode3: "",
    rbdcode4: "",
    reDate: moment(new Date()),
    remark: "",
    rzipcode1: "",
    rzipcode2: "",
    rzipcode3: "",
    rzipcode4: "",
    sawonId: "",
    sedaejuName: "",
    sedaejuRela: "",
    seqNo1: "",
    seqNo2: "",
    seqNo3: "",
    seqNo4: "",
    sexCheck: "F",
    tel1: "",
    tel2: "",
    tel3: "",
    tel4: "",
    upDate: moment(new Date()),
    userid: "",
    weddingCheck: "N",
    weddingDate: moment(new Date()),
    work1: "",
    zipcode1: "",
    zipcode2: "",
    zipcode3: "",
    zipcode4: "",
  };

  const [searchListNumber, setSearchListNumber] = useAtom(personMasterManage_table_row); // 테이블 row 갯수
  const [searchResult, setSearchResult] = useAtom(personMasterManage_searchResult); // 검색 결과
  const [personDetail, setPersonDetail] = useAtom(personMasterManage_backupData); // 직원 상세 정보
  const [selectedSubCustomerData, setSelectedSubCustomerData] = useAtom(
    personMasterManage_bottomTabSelect
  );
  const [selectPersonId, setSelectPersonId] = useAtom(personMasterManage_memberId);
  const [modifyData, setModifyData] = useAtom(personMasterManage_memberData);
  const [searchOption, setSearchOption] = useAtom(personMasterManage_searchOption);
  const [splitData, setSplitData] = useAtom(personMasterManage_split_data);
  const [printOption, setPrintOption] = useAtom(personMasterManage_printOption);
  const [personBottomTabData, setPersonBottomTabData] = useState([]); // 호출한 하단 탭 데이터
  // print data
  const [showPreview, setShowPreview] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const openPdf = usePrintTab();

  const { addTabClosedEvent } = useRecentTabEvent();
  const { initialize } = usePersonMasterTabInitialize();
  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchListNumber("5");
      setSearchResult([]); // 검색결과
      setPersonDetail({});
      setPrintOption(0);
      setSelectedSubCustomerData("");
      setSelectPersonId("");
      setModifyData({ ...initialModifyData });
      setSearchOption({
        keyWord: "",
        monthInfo: moment(new Date()).format("MM"),
        searchType: "1",
        yearInfo: moment(new Date()).format("YYYY"),
        dateInfo: moment(new Date()).format("YYYY-MM"),
      });
      initialize();
      setSplitData({
        tel1_1: "",
        tel1_2: "",
        tel1_3: "",
        tel2_1: "",
        tel2_2: "",
        tel2_3: "",
        tel3_1: "",
        tel3_2: "",
        tel3_3: "",
        tel4_1: "",
        tel4_2: "",
        tel4_3: "",
        mobile2_1: "",
        mobile2_2: "",
        mobile2_3: "",
        personID1: "",
        personID2: "",
      });
    });
  }, []);

  const pressEnter = e => {
    if (e.key === "Enter") {
      setPersonDetail({});
      setModifyData({});
      setSelectPersonId("");
      mutate_personSearch({
        keyWord: searchOption.keyWord,
        monthInfo: searchOption.monthInfo,
        searchType: searchOption.searchType,
        yearInfo: searchOption.yearInfo,
      });
    }
  };

  // API - 셀렉트박스
  const getHouse = useGetHouse();
  const getHouseGubun = useGetHouseGubun();
  const getSedaeRelation = useGetSedaeRelation();

  // API- select key value => 검색 타입(searchCondition) 셀렉트박스 가져오기
  const { data: searchType } = useQuery(
    ["searchType"],
    async () =>
      await axios
        .get("/api/emp/empMasterMng/selectKeyValue", {
          params: {
            searchType: "searchCondition",
          },
        })
        .then(res => {
          const result = res?.data?.result;
          if (searchOption.searchType === "") {
            setSearchOption({ ...searchOption, searchType: result[0].key });
          } else {
            if (searchOptionSelectKeyList.indexOf(searchOption.searchType) !== -1) {
              personSearchSubKeyValue.mutate({
                searchType: searchOption.searchType,
              });
            }
          }
          return result;
        })
  );

  // API - select sub key value
  const personSearchSubKeyValue = useMutation(
    data =>
      axios
        .get(defaultURL + "/api/emp/empMasterMng/selectKeyValue", {
          params: data,
        })
        .then(res => {
          return res?.data?.result;
        }),
    {
      onSuccess: res => {
        const result = res;
        if (searchOption.keyWord === "") {
          setSearchOption({ ...searchOption, keyWord: result[0].key });
        }
      },
    }
  );

  // API - search
  const { mutate: mutate_personSearch, isLoading: searchLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/search", {
        params: data,
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        setSearchResult(result);

        if (result.length === 0) {
          alert("조회된 직원이 없습니다.");
        }
      },
    }
  );

  // API - 인사기록 카드 (개인별 출력)
  const empByPriv = useMutation(
    data =>
      axios.get("/api/emp/empMasterMng/print/empByPriv", {
        responseType: "blob",
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `인사기록 카드(개인별)_${moment().format("YY_MM_DD_hh_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  // API - 부서/직위별 최종진급대장
  // API - 계열사 및 지사별 임직원 현황표
  const lastRankByDepRank = useMutation(
    data =>
      axios.get("/api/emp/empMasterMng/print/lastRankByDepRank", {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `부서/직위별 최종진급대장_${moment().format("YY_MM_DD_HH_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  // API - 계열사 및 지사별 임직원 현황표
  const BranchSummary = useMutation(
    data =>
      axios.get("/api/emp/empMasterMng/print/BranchSummary", {
        responseType: "blob",
      }),
    {
      onSuccess: res => {
        if (res?.data) {
          /*setPdfData({
            data: res.data,
            name: `계열사 및 지사별 임직원 현황표_${moment().format("YY_MM_DD_HH_mm")}.pdf`,
          });
          setShowPreview(true);*/
          openPdf(res?.data);
        }
      },
    }
  );

  const splitDataFunction = originObj => {
    setSplitData({
      personID1: originObj?.juminNo?.slice(0, 6) ? originObj?.juminNo?.slice(0, 6) : "",
      personID2: originObj?.juminNo?.slice(6) ? originObj?.juminNo?.slice(6) : "",
    });
  };

  // API - search detail
  const { mutate: mutate_personSearchDetail, isLoading: detailLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/getDetail", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: (res, paylaod) => {
        setSelectPersonId(paylaod);
        const data = res.data.result;
        const result = {
          ...data,
          outDateStart: data.outDateStart ? moment(data.outDateStart).format("YYYY-MM-DD") : "",
          outDateEnd: data.outDateEnd ? moment(data.outDateEnd).format("YYYY-MM-DD") : "",
          reDate: data.reDate ? moment(data.reDate).format("YYYY-MM-DD") : "",
          weddingDate: data.weddingDate ? moment(data.weddingDate).format("YYYY-MM-DD") : "",
          inChDate: data.inChDate ? moment(data.inChDate).format("YYYY-MM-DD") : "",
        };
        if (result) {
          setPersonDetail(result);
          setModifyData(result);
          splitDataFunction(result);
        }
      },
    }
  );

  // API - 신상기록 조회
  const { mutate: mutate_personRecordDetail, isLoading: recordLoading } = useMutation(
    data =>
      axios.get(defaultURL + "/api/emp/empMasterMng/bottom/getInfo", {
        params: {
          sawonId: data,
        },
      }),
    {
      onSuccess: res => {
        const result = res.data.result;
        setPersonBottomTabData(result);
      },
    }
  );

  // API - 개인 정보 수정
  const { mutate: mutate_personDetailUpdate } = useMutation(
    data =>
      axios.post(defaultURL + "/api/emp/empMasterMng/save", {
        ...data,
        ...data,
        outDateStart: data.outDateStart === "" ? null : data.outDateStart,
        outDateEnd: data.outDateEnd === "" ? null : data.outDateEnd,
        reDate: data.reDate === "" ? null : data.reDate,
        weddingDate: data.weddingDate === "" ? null : data.weddingDate,
        inChDate: data.inChDate === "" ? null : data.inChDate,
      }),
    {
      onSuccess: res => {
        // setUpdateMode(false);
        // setModifyData(initialModifyData);
        mutate_personSearchDetail(personDetail?.sawonId);
        mutate_personSearch({
          keyWord: searchOption.keyWord,
          monthInfo: searchOption.monthInfo,
          searchType: searchOption.searchType,
          yearInfo: searchOption.yearInfo,
        });
      },
    }
  );

  // API - 사원 제명
  const { mutate: mutate_personDetailDelete } = useMutation(
    data =>
      axios.post(defaultURL + "/api/emp/empMasterMng/delete", {
        sawonId: data,
      }),
    {
      onSuccess: res => {
        setPersonDetail("");
        mutate_personSearch({
          keyWord: searchOption.keyWord,
          monthInfo: searchOption.monthInfo,
          searchType: searchOption.searchType,
          yearInfo: searchOption.yearInfo,
        });
        setSelectPersonId("");
        // sessionStorage.removeItem("personMasterSawonId");
      },
    }
  );

  // 프로필 사진 업로드
  const empMasterMngUploadfile = useMutation(
    data => axios.post("/api/emp/empMasterMng/uploadfile", data),
    {
      onSuccess: res => {
        res.result.msg;
        setModifyData(prev => ({ ...prev, filename: res.result }));
        alert("업로드 되었습니다.");
      },
    }
  );

  const btn_profile_img = e => {
    const formData = new FormData();

    formData.append("files", e.target.files[0]);
    formData.append("sawonId", modifyData.sawonId);

    empMasterMngUploadfile.mutate(formData);

    e.target.value = "";
  };

  return (
    <>
      {/*<PrintModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        width="100%"
        minWidth="475px"
        pdfData={pdfData}
        strClass={"modal-xxl"}
        size={"xl"}
      />*/}
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">인사마스터 관리</div>
            <div className="filter-buttons">
              <div className="filter1-field">
                <label className="filter1-label">출력대상</label>
                <select
                  className="form-select table-filter-select w-200px"
                  onChange={e => {
                    setPrintOption(e.target.value);
                  }}
                  value={printOption}
                >
                  <option value={0}>부서/직위별 최종진급대장</option>
                  <option value={1}>계열사 및 지사별 임직원 현황표</option>
                </select>
                <button
                  className="btn btn-outline-primary btn-filter-outline-primary me-0 ms-8px"
                  onClick={() => {
                    if (printOption === "1") {
                      BranchSummary.mutate();
                    } else {
                      lastRankByDepRank.mutate();
                    }
                  }}
                >
                  출력
                </button>
              </div>
            </div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row" onKeyDown={pressEnter}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색조건</label>
                  <select
                    className="form-select table-filter-select"
                    onChange={e => {
                      setSearchOption({
                        ...searchOption,
                        searchType: e.target.value,
                        keyWord: "",
                        yearInfo: moment(new Date()).format("YYYY"),
                        dateInfo: moment(new Date()).format("YYYY-MM"),
                        monthInfo: moment(new Date()).format("MM"),
                      });
                      if (searchOptionSelectKeyList.indexOf(e.target.value) !== -1) {
                        personSearchSubKeyValue.mutate({
                          searchType: e.target.value,
                        });
                      }
                    }}
                    value={searchOption.searchType}
                  >
                    {searchType?.map((data, index) => {
                      return (
                        <option key={index} value={data.key}>
                          {data.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* 검색 필드 - 날짜 */}
                {searchOptionDateList.indexOf(searchOption.searchType) !== -1 && (
                  <div className="filter1-field filter1-field2">
                    <input
                      type="month"
                      className="form-control filter-input filter1-input2"
                      value={searchOption.dateInfo}
                      max={"9999-12"}
                      onChange={e => {
                        const date = e.target.value;
                        setSearchOption({
                          ...searchOption,
                          dateInfo: date,
                          yearInfo: date.slice(0, 4),
                          monthInfo: date.slice(5),
                        });
                      }}
                    />
                  </div>
                )}
                <div className="filter1-field filter1-field2">
                  {/* 검색 필드 - 입력칸 or 셀렉트박스 */}
                  {searchOptionSelectKeyList.indexOf(searchOption.searchType) === -1 ? (
                    <input
                      type="text"
                      className="form-control filter-input filter1-input2 me-2"
                      onChange={e => {
                        if (searchOption.searchType === "9" || searchOption.searchType === "9") {
                          setSearchOption({
                            ...searchOption,
                            keyWord: e.target.value.replace(/[^0-9]/g, ""),
                          });
                        } else if (searchOption.searchType === "11") {
                          setSearchOption({
                            ...searchOption,
                            keyWord: e.target.value.replace(/[^0-9]/g, ""),
                          });
                        } else {
                          setSearchOption({ ...searchOption, keyWord: e.target.value });
                        }
                      }}
                      value={searchOption.keyWord}
                    />
                  ) : (
                    <select
                      className="form-select me-2"
                      style={{ width: "150px", height: "25px" }}
                      onChange={e => setSearchOption({ ...searchOption, keyWord: e.target.value })}
                      value={searchOption.keyWord}
                    >
                      {personSearchSubKeyValue?.data?.map((data, index) => {
                        return (
                          <option key={index} value={data.key}>
                            {data.value}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary me-20px"
                onClick={() => {
                  setPersonDetail({});
                  setModifyData({});
                  setSelectPersonId("");
                  mutate_personSearch({
                    keyWord: searchOption.keyWord,
                    monthInfo: searchOption.monthInfo,
                    searchType: searchOption.searchType,
                    yearInfo: searchOption.yearInfo,
                  });
                }}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
        </div>

        <div className="card card-sub">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end mb-10px">
              <div className="d-flex align-items-center">
                <select
                  className="form-select table-filter-select"
                  value={searchListNumber}
                  onChange={e => {
                    setSearchListNumber(e.target.value);
                  }}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                </select>
              </div>
            </div>

            <div
              className={`table-container table-sticky
                ${searchListNumber === "5" && "h-183"}
                ${searchListNumber === "10" && "h-333"}
                ${searchListNumber === "20" && "h-633"}`}
            >
              <table className="table table-bordered table-responsive my-table mt-0">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>연번</th>
                    <th style={{ width: "33%" }}>사원번호</th>
                    <th style={{ width: "33%" }}>이름</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResult.length > 0 ? (
                    searchResult.map((data, index) => {
                      return (
                        <tr
                          key={data?.sawonId}
                          role="button"
                          className={`${
                            (data?.work1 === "22" || data?.work1 === "72") && "bg-red"
                          }  ${data?.sawonId === selectPersonId && "bg-blue"}`}
                          onClick={() => {
                            mutate_personSearchDetail(data?.sawonId);
                            mutate_personRecordDetail(data?.sawonId);
                            setSelectedSubCustomerData("신상기록");
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{data?.sawonId.replace(/(.{6})/g, "$1-")}</td>
                          <td>{data?.kname}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="cursor-pointer">
                      <td colSpan={3}>
                        <div>조회된 직원이 없습니다.</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <PersonalCardPrint data={printData} ref={printRef} /> */}

        {selectPersonId && (
          <>
            <div className="card card-sub">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="card-title  m-0">개인 정보</h5>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-outline-primary tab1-row1-btn"
                      onClick={() => {
                        empByPriv.mutate(selectPersonId);
                      }}
                    >
                      출력
                    </button>
                    {decrypt("userInfo").userPermit === "99" && (
                      <button
                        className="btn btn-outline-danger tab1-row1-btn"
                        onClick={() => {
                          const result = window.confirm("해당 기록을 삭제하시겠습니까?");
                          if (result) {
                            mutate_personDetailDelete(personDetail.sawonId);
                          } else {
                          }
                        }}
                      >
                        삭제
                      </button>
                    )}
                    <button
                      className="btn btn-outline-primary tab1-row1-btn"
                      onClick={() => {
                        mutate_personDetailUpdate({
                          ...modifyData,
                          juminNo: splitData?.personID1 + splitData?.personID2,
                        });
                      }}
                    >
                      저장
                    </button>
                    <button
                      className="btn btn-outline-primary tab1-row1-btn"
                      onClick={() => {
                        setModifyData(personDetail);
                        splitDataFunction(personDetail);
                        alert("변경 사항이 취소되었습니다.");
                      }}
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-responsive my-table1">
                  <tbody>
                    <tr>
                      <th style={{ width: "8.052%" }}>
                        <span className="person-master-star">*</span>이름
                      </th>
                      <td style={{ width: "10.281%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.kname}
                          onChange={e => {
                            setModifyData({ ...modifyData, kname: e.target.value });
                          }}
                        />
                      </td>
                      <th style={{ width: "8.052%" }}>한자</th>
                      <td style={{ width: "10.281%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.cname}
                          onChange={e => {
                            setModifyData({ ...modifyData, cname: e.target.value });
                          }}
                        />
                      </td>
                      <th style={{ width: "8.052%" }}>영문</th>
                      <td style={{ width: "10.281%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.ename}
                          onChange={e => {
                            setModifyData({ ...modifyData, ename: e.target.value });
                          }}
                        />
                      </td>
                      <th style={{ width: "8.052%" }}>
                        <span className="person-master-star">*</span>입사구분
                      </th>
                      <td style={{ width: "10.281%" }}>
                        <select
                          className="form-select"
                          value={modifyData?.inGubun}
                          onChange={e => {
                            setModifyData({ ...modifyData, inGubun: e.target.value });
                          }}
                        >
                          <option value="1">정규-신입</option>
                          <option value="2">경력</option>
                          <option value="3">재입사</option>
                          <option value="5">계약-신입</option>
                          <option value="6">계약-경력</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "8.052%" }}>
                        <span className="person-master-star">*</span>주민번호
                      </th>
                      <td style={{ width: "10.281%" }}>
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            maxLength={6}
                            className="form-control table-input"
                            value={splitData?.personID1}
                            onChange={e => {
                              setSplitData({
                                ...splitData,
                                personID1: e.target.value.replace(/[^0-9]/g, ""),
                              });
                            }}
                          />
                          <span>-</span>
                          <input
                            maxLength={7}
                            type="text"
                            className="form-control table-input"
                            value={splitData?.personID2}
                            onChange={e => {
                              setSplitData({
                                ...splitData,
                                personID2: e.target.value.replace(/[^0-9]/g, ""),
                              });
                            }}
                          />
                        </div>
                      </td>
                      <th style={{ width: "8.052%" }}>성별</th>
                      <td style={{ width: "10.281%" }}>
                        <select
                          className="form-select"
                          value={modifyData?.sexCheck}
                          onChange={e => {
                            setModifyData({ ...modifyData, sexCheck: e.target.value });
                          }}
                        >
                          <option value="M">남자</option>
                          <option value="W">여자</option>
                        </select>
                      </td>
                      <th style={{ width: "8.052%" }}>휴대폰1</th>
                      <td style={{ width: "10.281%" }}>
                        <div>
                          <span>{personDetail?.cellular}</span>
                        </div>
                      </td>
                      <th style={{ width: "8.052%" }}>휴대폰2</th>
                      <td style={{ width: "10.281%" }}>
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            className="form-control table-input"
                            value={modifyData.cellular2}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                cellular2: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "8.052%" }} rowSpan={2}>
                        <span className="person-master-star">*</span>현거주지
                      </th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="person-master-post-box">
                          <div className="d-flex align-items-center w-100">
                            <input
                              type="text"
                              className="form-control table-input person-master-post-input"
                              value={modifyData?.rareaCode2}
                            />
                            <DaumPostcode
                              className="btn btn-outline-primary mx-3px h-25px"
                              onCompleteCallback={data =>
                                setModifyData({
                                  ...modifyData,
                                  rareaCode2: data.zonecode,
                                  rbdcode2: data.bdCode,
                                  addresB: data.fullAddr,
                                })
                              }
                            />
                            <input
                              type="text"
                              className="form-control table-input person-master-post-detail"
                              value={modifyData?.addresB}
                            />
                          </div>
                        </div>
                      </td>
                      <th style={{ width: "8.052%" }} rowSpan={2}>
                        주민등록 주소
                      </th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="person-master-post-box">
                          <div className="d-flex align-items-center w-100">
                            <input
                              type="text"
                              className="form-control table-input person-master-post-input"
                              value={modifyData?.rareaCode3}
                            />
                            <DaumPostcode
                              className="btn btn-outline-primary mx-3px h-25px"
                              onCompleteCallback={data =>
                                setModifyData({
                                  ...modifyData,
                                  rareaCode3: data.zonecode,
                                  rbdcode3: data.bdCode,
                                  addresC: data.fullAddr,
                                })
                              }
                            />
                            <input
                              type="text"
                              className="form-control table-input person-master-post-detail"
                              value={modifyData?.addresC}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        {/* 현 거주지 상세주소 =2번 */}
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.raddres2}
                          onChange={e => {
                            setModifyData({ ...modifyData, raddres2: e.target.value });
                          }}
                        />
                      </td>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        {/* 주민등록 상세주소 = 3번 */}
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.raddres3}
                          onChange={e => {
                            setModifyData({ ...modifyData, raddres3: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      {/* 현주소 전화번호 = 2번 */}
                      <th style={{ width: "8.052%" }}>전화번호</th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            className="form-control table-input"
                            value={modifyData.tel2}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                tel2: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                      {/* 주민등록 주소 전화번호 = 3번 */}
                      <th style={{ width: "8.052%" }}>전화번호</th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            className="form-control table-input"
                            value={modifyData.tel3}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                tel3: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "8.052%" }} rowSpan={2}>
                        부모 주소
                      </th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="person-master-post-box">
                          <div className="d-flex align-items-center w-100">
                            <input
                              type="text"
                              className="form-control table-input person-master-post-input"
                              value={modifyData?.rareaCode1}
                            />
                            <DaumPostcode
                              className="btn btn-outline-primary mx-3px h-25px"
                              onCompleteCallback={data =>
                                setModifyData({
                                  ...modifyData,
                                  rareaCode1: data.zonecode,
                                  rbdcode1: data.bdCode,
                                  addresA: data.fullAddr,
                                })
                              }
                            />
                            <input
                              type="text"
                              className="form-control table-input person-master-post-detail"
                              value={modifyData?.addresA}
                            />
                          </div>
                        </div>
                      </td>
                      <th style={{ width: "8.052%" }} rowSpan={2}>
                        본적지 주소
                      </th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="person-master-post-box">
                          <div className="d-flex align-items-center w-100">
                            <input
                              type="text"
                              className="form-control table-input person-master-post-input"
                              value={modifyData?.rareaCode4}
                            />
                            <DaumPostcode
                              className="btn btn-outline-primary mx-3px h-25px"
                              onCompleteCallback={data =>
                                setModifyData({
                                  ...modifyData,
                                  rareaCode4: data.zonecode,
                                  rbdcode4: data.bdCode,
                                  addresD: data.fullAddr,
                                })
                              }
                            />
                            <input
                              type="text"
                              className="form-control table-input person-master-post-detail"
                              value={modifyData?.addresD}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        {/* 부모 상세 주소 = 1번 */}
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.raddres1}
                          onChange={e => {
                            setModifyData({ ...modifyData, raddres1: e.target.value });
                          }}
                        />
                      </td>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        {/* 본적지 상세 주소 = 4번 */}
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.raddres4}
                          onChange={e => {
                            setModifyData({ ...modifyData, raddres4: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      {/* 부모 주소 전화번호 = 1번 */}
                      <th style={{ width: "8.052%" }}>전화번호</th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            className="form-control table-input"
                            value={modifyData?.tel1}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                tel1: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                      {/* 본적지 주소 전화번호 = 4번*/}
                      <th style={{ width: "8.052%" }}>전화번호</th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            className="form-control table-input"
                            value={modifyData?.tel4}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                tel4: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "8.052%" }}>프로필 사진</th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <div className="d-flex justify-content-between">
                          <input
                            type="text"
                            className="form-control table-input cursor-pointer"
                            value={modifyData?.filename}
                            readOnly={true}
                            onClick={() => profileImgRef.current.click()}
                          />
                          <input
                            type="file"
                            ref={profileImgRef}
                            onChange={btn_profile_img}
                            hidden={true}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-bordered table-responsive my-table1">
                  <tbody>
                    <tr>
                      <th style={{ width: "8.052%" }}>
                        <span className="person-master-star">*</span>구분
                      </th>
                      <td style={{ width: "10.281%" }}>
                        <div>{personDetail?.work1}</div>
                        {/* <input
                            type="text"
                            className="form-control table-input"
                            value={personDetail?.work1}
                          /> */}
                      </td>
                      <th style={{ width: "8.052%" }}>입사일자</th>
                      <td style={{ width: "10.281%" }}>
                        <div>
                          {personDetail?.inDate
                            ? moment(personDetail?.inDate).format("YYYY-MM-DD")
                            : ""}
                        </div>
                      </td>
                      <th style={{ width: "8.052%" }}>최종진급</th>
                      <td style={{ width: "10.281%" }}>
                        <div>
                          {personDetail?.upDate
                            ? moment(personDetail?.upDate).format("YYYY-MM-DD")
                            : ""}
                        </div>
                        {/* <input
                            type="date"
                            className="form-control table-input"
                            value={moment(personDetail?.upDate).format("YYYY-MM-DD")}
                          /> */}
                      </td>
                      <th style={{ width: "8.052%" }}>전환일자</th>
                      <td style={{ width: "10.281%" }}>
                        <div>
                          {
                            <input
                              type="date"
                              max={"9999-12-31"}
                              className="form-control table-input"
                              value={modifyData?.inChDate}
                              onChange={e => {
                                setModifyData({
                                  ...modifyData,
                                  inChDate: e.target.value,
                                });
                              }}
                            />
                          }
                        </div>
                      </td>
                      <th style={{ width: "8.052%" }}>입사경로</th>
                      <td style={{ width: "10.281%" }}>
                        {/* <div>{personDetail?.inCheck}</div> */}
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.inCheck}
                          onChange={e => {
                            setModifyData({
                              ...modifyData,
                              inCheck: e.target.value,
                            });
                          }}
                        />{" "}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "8.052%" }}>소속</th>
                      <td style={{ width: "10.281%" }}>
                        <div>{personDetail?.dept}</div>
                      </td>
                      <th style={{ width: "8.052%" }}>부서</th>
                      <td style={{ width: "10.281%" }}>
                        <div>{personDetail?.job}</div>
                      </td>
                      <th style={{ width: "8.052%" }}>직책</th>
                      <td style={{ width: "10.281%" }}>
                        <div>{personDetail?.partJob}</div>
                      </td>
                      <th style={{ width: "8.052%" }}>직위</th>
                      <td style={{ width: "10.281%" }}>
                        <div>{personDetail?.authority}</div>
                      </td>
                      <th style={{ width: "8.052%" }}>다포스ID</th>
                      <td style={{ width: "10.281%" }}>
                        <div>{personDetail?.userid}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-bordered table-responsive my-table1">
                  <tbody>
                    <tr>
                      <th style={{ width: "7.052%" }} rowSpan={2}>
                        <span className="person-master-star">*</span>세대주명
                      </th>
                      <td style={{ width: "8.052%" }} rowSpan={2}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.sedaejuName}
                          onChange={e => {
                            setModifyData({ ...modifyData, sedaejuName: e.target.value });
                          }}
                        />
                      </td>
                      <th style={{ width: "7.052%" }} rowSpan={2}>
                        {/* 세대주 관계 */}
                        관계
                      </th>
                      <td style={{ width: "8.052%" }} rowSpan={2}>
                        <select
                          className="form-select"
                          value={modifyData?.sedaejuRela}
                          onChange={e => {
                            setModifyData({ ...modifyData, sedaejuRela: e.target.value });
                          }}
                        >
                          {getSedaeRelation?.data?.map((data, index) => {
                            return (
                              <option key={index} value={data.key}>
                                {data.value}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <th style={{ width: "7.052%" }} rowSpan={2}>
                        주거형태
                      </th>
                      <td style={{ width: "10.281%" }} rowSpan={2}>
                        <div className="d-flex">
                          <select
                            className="form-select me-3px"
                            value={modifyData?.houseGubun}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                houseGubun: e.target.value,
                              });
                            }}
                          >
                            {getHouseGubun?.data?.map((data, index) => {
                              return (
                                <option key={index} value={data.key}>
                                  {data.value}
                                </option>
                              );
                            })}
                          </select>

                          <select
                            className="form-select"
                            value={modifyData?.house}
                            onChange={e => {
                              setModifyData({ ...modifyData, house: e.target.value });
                            }}
                          >
                            {getHouse?.data?.map((data, index) => {
                              return (
                                <option key={index} value={data.key}>
                                  {data.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                      <th style={{ width: "7.052%" }} rowSpan={2}>
                        내선
                      </th>
                      <td className="text-center td_middle_for_personMaster">1</td>
                      <td className="text-center td_middle_for_personMaster">2</td>
                      <td className="text-center td_middle_for_personMaster">3</td>
                    </tr>
                    <tr>
                      <td style={{ width: "6.281%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.ext1}
                          onChange={e => {
                            setModifyData({
                              ...modifyData,
                              ext1: e.target.value.replace(/[^0-9-]/g, ""),
                            });
                          }}
                        />
                      </td>
                      <td style={{ width: "6.281%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.ext2}
                          onChange={e => {
                            setModifyData({
                              ...modifyData,
                              ext2: e.target.value.replace(/[^0-9-]/g, ""),
                            });
                          }}
                        />
                      </td>
                      <td style={{ width: "6.281%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.ext3}
                          onChange={e => {
                            setModifyData({
                              ...modifyData,
                              ext3: e.target.value.replace(/[^0-9-]/g, ""),
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "7.052%" }}>최종학력</th>
                      <td style={{ width: "9.281%" }}>
                        <div style={{ display: "flex" }}>
                          <div className="d-flex">
                            <div>{personDetail?.eduCheck}</div>
                            <div style={{ marginLeft: "10px" }}>{personDetail?.eduName}</div>
                          </div>
                        </div>
                      </td>
                      <th style={{ width: "7.052%" }}>생일</th>
                      {/* 양력 - 1, 음력 - 2 */}
                      <td style={{ width: "6.281%" }}>
                        <div className="d-flex">
                          <select
                            className="form-select me-3px"
                            value={modifyData?.lunaCheck}
                            style={{ width: "45%" }}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                lunaCheck: e.target.value,
                              });
                            }}
                          >
                            <option value="1">양력</option>
                            <option value="2">음력</option>
                          </select>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={modifyData?.birthMmdd?.replace(
                              /\B(?<!\.\d*)(?=(\d{2})+(?!\d))/g,
                              "/"
                            )}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                birthMmdd: e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*)\./g, "$1"),
                              });
                            }}
                            maxLength={5}
                          />
                        </div>
                      </td>
                      <th style={{ width: "7.052%" }}>
                        <span className="person-master-star">*</span>결혼
                      </th>
                      <td style={{ width: "10.281%" }}>
                        <div className="d-flex">
                          <select
                            className="form-select"
                            value={modifyData?.weddingCheck}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                weddingCheck: e.target.value,
                              });
                            }}
                          >
                            <option value="Y">기혼</option>
                            <option value="N">미혼</option>
                          </select>
                          <input
                            type="date"
                            max={"9999-12-31"}
                            className="form-control table-input ms-3px"
                            value={modifyData?.weddingDate}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                weddingDate: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                      <th style={{ width: "7.052%" }}>보훈</th>
                      <td style={{ width: "10.281%" }} colSpan={3}>
                        <select
                          className="form-select"
                          value={modifyData?.bohoonCheck}
                          onChange={e => {
                            setModifyData({ ...modifyData, bohoonCheck: e.target.value });
                          }}
                        >
                          <option value="Y">YES</option>
                          <option value="N">NO</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-bordered table-responsive my-table1 mb-0">
                  <tbody>
                    <tr>
                      <th style={{ width: "8.052%" }}>회사메일</th>
                      <td style={{ width: "5.281%" }} colSpan={2}>
                        <div style={{ display: "flex" }}>
                          <input
                            type="text"
                            className="form-control table-input"
                            value={modifyData?.email}
                            onChange={e => {
                              setModifyData({ ...modifyData, email: e.target.value });
                            }}
                          />
                          <span className="mx-3px">@</span>
                          <span>dagolf.co.kr</span>
                        </div>
                      </td>
                      <th style={{ width: "8.052%" }}>비밀번호</th>
                      <td style={{ width: "10.281%" }}>
                        <div className="d-flex justify-content-center ">
                          <span>{"*".repeat(personDetail?.emailPassword?.length)}</span>
                        </div>
                      </td>
                      <td style={{ width: "10.281%" }}>
                        <select
                          className="form-select"
                          value={modifyData?.mailIssue}
                          onChange={e => {
                            setModifyData({ ...modifyData, mailIssue: e.target.value });
                          }}
                        >
                          <option value="Y">발급</option>
                          <option value="N">미발급</option>
                        </select>
                      </td>
                      <th style={{ width: "8.052%" }}>개인메일</th>
                      <td style={{ width: "10.281%" }} className="text-center">
                        <div>{"*".repeat(personDetail?.perMail?.length)}</div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "8.052%" }}>자기차량</th>
                      <td style={{ width: "5.281%" }}>
                        <select
                          className="form-select"
                          value={modifyData?.carCheck}
                          onChange={e => {
                            setModifyData({ ...modifyData, carCheck: e.target.value });
                          }}
                        >
                          <option value="Y">有</option>
                          <option value="N">無</option>
                        </select>
                      </td>
                      <th style={{ width: "8.052%" }}>퇴사일자</th>
                      <td style={{ width: "10.281%" }}>
                        <span>
                          {personDetail?.outDate &&
                            moment(personDetail?.outDate).format("YYYY-MM-DD")}
                        </span>
                      </td>
                      <th style={{ width: "8.052%" }}>재입사일</th>
                      <td style={{ width: "10.281%" }}>
                        <input
                          type="date"
                          max={"9999-12-31"}
                          className="form-control table-input"
                          value={modifyData?.reDate}
                          onChange={e => {
                            setModifyData({ ...modifyData, reDate: e.target.value });
                          }}
                        />
                      </td>
                      <th style={{ width: "8.052%" }}>휴직 무단결근</th>
                      <td style={{ width: "10.281%" }}>
                        <div style={{ display: "flex" }}>
                          <input
                            type="date"
                            max={"9999-12-31"}
                            className="form-control table-input"
                            value={modifyData?.outDateStart}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                outDateStart: e.target.value,
                              });
                            }}
                          />
                          <span className="mx-3px">~</span>
                          <input
                            type="date"
                            max={"9999-12-31"}
                            min={modifyData?.outDateStart}
                            className="form-control table-input"
                            value={modifyData?.outDateEnd}
                            onChange={e => {
                              setModifyData({
                                ...modifyData,
                                outDateEnd: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "8.052%" }}>비고</th>
                      <td style={{ width: "5.281%" }} colSpan={5}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.remark}
                          onChange={e => {
                            setModifyData({ ...modifyData, remark: e.target.value });
                          }}
                        />
                      </td>
                      <th style={{ width: "8.052%" }}>더존 고유번호</th>
                      <td style={{ width: "10.281%" }}>
                        <input
                          type="text"
                          className="form-control table-input"
                          value={modifyData?.duzonId}
                          onChange={e => {
                            setModifyData({ ...modifyData, duzonId: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {/* 탭 버튼*/}
        {/* 고객정보 부정보 */}
        {selectPersonId && (
          <Tabs
            className="tab-blocks"
            id="controlled-tab-example2"
            activeKey={selectedSubCustomerData}
            onSelect={k => setSelectedSubCustomerData(k)}
          >
            <Tab eventKey="신상기록" title="신상기록">
              {selectedSubCustomerData === "신상기록" && (
                <PersonalRecordTab
                  data={personBottomTabData}
                  mutate_personRecordDetail={mutate_personRecordDetail}
                  sawonId={selectPersonId}
                  userId={personDetail.userId}
                />
              )}
            </Tab>
            <Tab eventKey="인사발령" title="인사발령">
              {selectedSubCustomerData === "인사발령" && (
                <PersonMoveTab
                  sawonId={selectPersonId}
                  userId={personDetail.userId}
                  mutate_personSearch={mutate_personSearch}
                  searchOption={searchOption}
                  mutate_personSearchDetail={mutate_personSearchDetail}
                />
              )}
            </Tab>
            <Tab eventKey="학력사항" title="학력사항">
              {selectedSubCustomerData === "학력사항" && (
                <PersonSchoolTab
                  sawonId={selectPersonId}
                  userId={personDetail.userId}
                  mutate_personSearchDetail={mutate_personSearchDetail}
                />
              )}
            </Tab>
            <Tab eventKey="가족사항" title="가족사항">
              {selectedSubCustomerData === "가족사항" && (
                <PersonFamilyTab sawonId={selectPersonId} userId={personDetail.userId} />
              )}
            </Tab>
            <Tab eventKey="경력사항" title="경력사항">
              {selectedSubCustomerData === "경력사항" && (
                <PersonExperiTab sawonId={selectPersonId} userId={personDetail.userId} />
              )}
            </Tab>
            <Tab eventKey="자격/면허증" title="자격/면허증">
              {selectedSubCustomerData === "자격/면허증" && (
                <PersonLicenseTab sawonId={selectPersonId} userId={personDetail.userId} />
              )}
            </Tab>
            <Tab eventKey="상벌사항" title="상벌사항">
              {selectedSubCustomerData === "상벌사항" && (
                <PersonPunishTab sawonId={selectPersonId} userId={personDetail.userId} />
              )}
            </Tab>
            <Tab eventKey="상조회" title="상조회">
              {selectedSubCustomerData === "상조회" && (
                <PersonBenefiTab sawonId={selectPersonId} userId={personDetail.userId} />
              )}
            </Tab>
            <Tab eventKey="연차사용" title="연차사용">
              {selectedSubCustomerData === "연차사용" && (
                <PersonYunchaTab sawonId={selectPersonId} userId={personDetail.userId} />
              )}
            </Tab>
          </Tabs>
        )}
      </div>
    </>
  );
};
