import Modal from "../../../../components/modal/Modal";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import moment from "moment";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";

const convertGubnCode = code => {
  switch (code) {
    case "M":
      return "골프장정보";
    case "B":
      return "부킹정보";
    case "G":
      return "그린피정보";
    case "C":
      return "코스정보";
    default:
      return "";
  }
};

const convertSendCode = code => {
  switch (code) {
    case "N":
      return "미 이 관";
    case "Y":
      return "이관완료";
    default:
      return "";
  }
};

/**
 * 골프장 웹이관 모달
 */
export function MigrateGolfModal(props) {
  const { data = [], show, onHide = () => {}, onProc = () => {}, size } = props;

  const [haveData, setHaveData] = useState([]);
  const userIdQ = useGetUserIdQ();

  // 웹이관 API
  const migGolf = useMutation(() => axios.post("/api/work/manageGolf/migGolf", haveData), {
    onSuccess: res => {
      setHaveData([]);
      onHide();
      alert("이관이 완료되었습니다.");
    },
  });

  const convertUserId = userId => {
    if (userIdQ.data) {
      return userIdQ.data.find(el => el.key === userId)?.value ?? userId;
    }
    return userId;
  };

  const checkAll = () => {
    if (haveData.length === data.length) {
      setHaveData([]);
    } else setHaveData(data.map(i => i));
  };

  const onClickMigration = () => {
    if (haveData.length === 0) alert("이관할 데이터를 선택해주세요.");
    else {
      migGolf.mutate();
    }
  };

  return (
    <Modal
      title="검색결과"
      show={show}
      onHide={() => {
        onHide();
        setHaveData([]);
      }}
      footer={
        <button className="btn btn-primary h-25px" onClick={onClickMigration}>
          정보이관
        </button>
      }
      size={size}
    >
      <div className={"table-container"}>
        <table className="table table-bordered table-responsive my-table mt-0">
          <thead>
            <tr>
              <th>
                <input
                  className="form-check-input"
                  type={"checkbox"}
                  checked={haveData.length === data.length}
                  onChange={checkAll}
                />
              </th>
              <th>골프장명</th>
              <th>분류</th>
              <th>이관여부</th>
              <th>수정자</th>
              <th>수정일시</th>
              <th>이관자</th>
              <th>이관일시</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((i, idx) => (
                <tr>
                  <td>
                    <input
                      className="form-check-input"
                      type={"checkbox"}
                      checked={haveData.filter(fil => fil.cust_id === i.cust_id).length > 0}
                      onChange={() => {
                        if (haveData.filter(fil => fil.cust_id === i.cust_id).length > 0) {
                          setHaveData(haveData.filter(fil => fil.cust_id !== i.cust_id));
                        } else {
                          setHaveData([...haveData, i]);
                        }
                      }}
                    />
                  </td>
                  <td>{i?.company ? i.company : ""}</td>
                  <td>{i?.gubn_code ? convertGubnCode(i.gubn_code) : ""}</td>
                  <td>{i?.send_code ? convertSendCode(i.send_code) : ""}</td>
                  <td>{i?.mod_id ? convertUserId(i.mod_id) : ""}</td>
                  <td>{i?.mod_date ? moment(i.mod_date).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                  <td>{i?.send_id ? convertUserId(i.send_id) : ""}</td>
                  <td>{i?.send_date ? moment(i.send_date).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  검색 내용이 없습니다
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
