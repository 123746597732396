import { atom } from "jotai";
import moment from "moment";

export const individualPerformance_searchOption = atom({
  again: "",
  yymmdd: moment(new Date()).format("YYYY-MM-DD"),
});

export const individualPerformance_searchList = atom([]);
export const individualPerformance_unregisterList = atom([]);
export const individualPerformance_backup = atom([]);
export const individualPerformance_modify = atom([]);
export const individualPerformance_addData = atom([]);
export const individualPerformance_finalAdd = atom([]);
export const individualPerformance_etcAmt = atom({
  fromDate: "",
  toDate: "",
});
export const isShowEtc = atom(false);
export const individualPerformance_selectMemberId = atom("");
export const individualPerformance_individualPerformance = atom(null);
