import { useAtom } from "jotai/index";
import { queueTab_atom } from "../../data/atom/queueTab_atom";

const useTabQueue = () => {
  const tabKey = `${
    window.location.pathname.startsWith(process.env.PUBLIC_URL)
      ? window.location.pathname.replace(process.env.PUBLIC_URL, "")
      : window.location.pathname
  }/queueTab`;
  const [tabAtom, setTabAtom] = useAtom(queueTab_atom);

  const hasItem = (seqNo, custId) => {
    if (tabAtom[tabKey]) {
      let found = tabAtom[tabKey].data.filter(item => {
        if (String(seqNo) === String(item.seqNo) && String(custId) === String(item.custId)) {
          return true;
        } else return false;
      });

      if (found.length > 0) return true;
      else return false;
    }
  };

  const getLength = () => {
    if (tabAtom[tabKey]) {
      return tabAtom[tabKey].data.length;
    } else return 0;
  };

  return {
    hasItem: hasItem,
    length: getLength,
  };
};

export default useTabQueue;
