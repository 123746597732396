import React from "react";

export default function InputFilter(props) {
  const { keyName, data, setData, type, size, disabled = false, children } = props;

  const onChangeEventHandler = value => {
    setData({ ...data, [keyName]: value });
  };

  return (
    <>
      {type === "text" && (
        <>
          <input
            type="text"
            className="form-control filter-input filter1-input2"
            value={data?.[keyName]}
            disabled={disabled}
            onChange={e => onChangeEventHandler(e.target.value)}
          />
        </>
      )}
      {type === "select" &&
        (size === "l" ? (
          <>
            <select
              className="form-select filter1-select1"
              value={data?.[keyName]}
              disabled={disabled}
              onChange={e => onChangeEventHandler(e.target.value)}
            >
              {children}
            </select>
          </>
        ) : size === "m" ? (
          <>
            <select
              className="form-select"
              value={data?.[keyName]}
              disabled={disabled}
              onChange={e => onChangeEventHandler(e.target.value)}
            >
              {children}
            </select>
          </>
        ) : (
          <></>
        ))}
      {type === "date" && (
        <>
          <input
            type="date"
            className="form-control filter-input filter1-input2"
            value={data?.[keyName]}
            disabled={disabled}
            onChange={e => onChangeEventHandler(e.target.value)}
            style={{ minWidth: "120px" }}
            max="9999-12-31"
          />
        </>
      )}
    </>
  );
}

React.memo(InputFilter);
