import React, { useEffect } from "react";
import moment from "moment/moment";
import { useGetCondoadmin } from "../../../api/code/useGetCondoadmin";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useAtom, useSetAtom } from "jotai";
import {
  condoMemberList_atom,
  currentListType_atom,
  editedRowList_atom,
  searchedParam_atom,
  searchParam_atom,
  searchParamInit,
} from "../../../data/atom/dataManage/condoPrice/condoPrice_atom";
import SearchResultTable from "./searchResultTable";
import useRecentTabEvent from "../../../components/layout/useRecentTabEvent";

export const CondoPrice = () => {
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [searchedParam, setSearchedParam] = useAtom(searchedParam_atom);
  const setCondoMemberList = useSetAtom(condoMemberList_atom);
  const setEditedRowList = useSetAtom(editedRowList_atom);
  const setCurrentListType = useSetAtom(currentListType_atom);
  const { addTabClosedEvent } = useRecentTabEvent();

  const condoList = useGetCondoadmin();
  const searchCondoMember = useMutation(
    data =>
      axios.get("/api/data/condoMember/search", {
        params: data,
      }),
    {
      onSuccess: res => {
        const list = [];
        res.data.result.map(el => {
          list.push({ ...el, originalGubun11: el.gubun11, originalSise11: el.sise11 });
        });

        setSearchedParam(searchParam);
        setCurrentListType(true);
        setEditedRowList([]);
        setCondoMemberList(list);
      },
    }
  );

  const btnSearch = () => {
    if (searchParam.date === "Invalid date") {
      alert("기준일을 입력해주세요.");
      return;
    }
    searchCondoMember.mutate(searchParam);
  };

  const onChangeParamEventHandler = (key, value) => {
    if (key === "searchType") {
      if (value === "4") {
        setSearchParam({
          ...searchParam,
          [key]: value,
          codeSelect: condoList.data[0].key,
          date: moment().format("YYYY-MM-DD"),
        });
      } else {
        setSearchParam({ ...searchParam, [key]: value, codeSelect: "" });
      }
    } else if (key === "keyword") {
      if (checkByte(value) < 80) {
        setSearchParam({ ...searchParam, [key]: value });
      }
    } else {
      setSearchParam({ ...searchParam, [key]: value });
    }
  };

  const pressEnter = e => {
    if (e.key === "Enter") {
      btnSearch();
    }
  };

  const checkByte = str => {
    let currentByte = 0;
    for (let i = 0; i < str.length; i++) {
      if (new RegExp(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/).test(str[i])) {
        currentByte += 3;
      } else {
        currentByte += 1;
      }
    }
    return currentByte;
  };

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParam(searchParamInit);
      setCondoMemberList([]);
      setEditedRowList([]);
      setCurrentListType(false);
    });
  }, []);

  return (
    <>
      <div className="content-inner">
        <div className="filter1_container">
          <div className="filter_header_row">
            <div className="filter-title">콘도 검색 조건</div>
          </div>
          <div className="filter_fields_container">
            <div className="filter_fields_row">
              <div
                className="d-flex align-items-center justify-content-between"
                onKeyDown={pressEnter}
              >
                <div className="filter1-field filter1-field1">
                  <label className="filter1-label">검색 조건</label>
                  <select
                    className="form-select filter1-select1"
                    value={searchParam.searchType}
                    onChange={e => onChangeParamEventHandler("searchType", e.target.value)}
                  >
                    <option value="1">회원권명</option>
                    <option value="2">체인콘도</option>
                    <option value="3">스키장콘도</option>
                    <option value="4">관리자</option>
                  </select>
                </div>
                {searchParam.searchType !== "4" ? (
                  <>
                    <div className="filter1-field filter1-field2">
                      <label className="filter1-label">기준일</label>
                      <input
                        type="date"
                        className="form-control filter-input filter1-input2"
                        value={searchParam.date}
                        onChange={e => {
                          onChangeParamEventHandler("date", e.target.value);
                        }}
                        max="9999-12-31"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="filter1-field filter1-field2">
                      <label className="filter1-label">콘도 선택</label>
                      <select
                        className="form-select filter1-select1"
                        value={searchParam.codeSelect}
                        onChange={e => {
                          onChangeParamEventHandler("codeSelect", e.target.value);
                        }}
                      >
                        {condoList.data ? (
                          condoList.data.map(el => {
                            return <option value={el.key}>{el.value}</option>;
                          })
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                  </>
                )}
                <div className="filter1-field filter1-field3">
                  <label className="filter1-label">검색어</label>
                  <input
                    type="text"
                    className="form-control filter-input filter1-input2"
                    value={searchParam.keyword}
                    onChange={e => {
                      onChangeParamEventHandler("keyword", e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
                onClick={btnSearch}
              >
                <img
                  className="me-2"
                  src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                  alt=""
                />
                검색
              </button>
            </div>
          </div>
          <SearchResultTable searchCondoMember={searchCondoMember} />
        </div>
      </div>
    </>
  );
};
