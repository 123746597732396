import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_DECRYPTED_KEY;

export const decrypt = name => {
  const data = sessionStorage.getItem(name);

  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else return {};
};
