import React, { useEffect, useState } from "react";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import { SmsHistoryModal } from "./modal/smsHistoryModal";

export const SMSHistory = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({ data: null, isOpen: false });

  // SMS전송내역 조회
  const customerGetSmsHistory = useMutation(
    () =>
      axios.get("/api/work/customer/bottom/getSmsHistory", {
        params: { rcvSeqno: mainData.seqNo },
      }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  useEffect(() => {
    mainData.custId && customerGetSmsHistory.mutate({ seqNo: mainData.seqNo });
  }, [mainData.custId]);

  const btn_rowClick = data => {
    setIsModalOpen({ data: data, isOpen: true });
  };

  return (
    <>
      {isModalOpen.isOpen && (
        <SmsHistoryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}

      <div className="tab2-content-container">
        <div className="d-flex align-items-center justify-content-between">
          <div className="table-tabs-sub m-0">
            SMS 전송내역 (<span>{subData.length ?? "0"}</span>건)
          </div>
        </div>
        <div className="table-container table-sticky h-528 mt-15px">
          <table className="table table-bordered table-responsive my-table mt-0">
            <colgroup>
              <col width="3%" />
              <col width="3%" />
              <col width="7%" />
              <col width="5%" />
              <col width="5%" />
              <col width="5%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>구분</th>
                <th>전송일시</th>
                <th>전송자</th>
                <th>수신번호</th>
                <th>전송결과</th>
                <th>전송내용</th>
              </tr>
            </thead>
            <tbody>
              {subData?.length !== 0 ? (
                subData?.map((el, index) => {
                  return (
                    <tr className="cursor-pointer" onClick={() => btn_rowClick(el)}>
                      <td>{subData?.length - index}</td>
                      <td>{el?.sendType ?? ""}</td>
                      <td>
                        {el?.sendDate ? moment(el?.sendDate).format("YYYY-MM-DD HH:mm:ss") : ""}
                      </td>
                      <td>{el?.userName ?? ""}</td>
                      <td>{el?.rcvPhoneNumber ?? ""}</td>
                      <td>{el?.resultsMsg ?? ""}</td>
                      {/*<td style={{ textOverflow: "ellipsis" }}>{el?.msg ?? ""}</td>*/}
                      <td>
                        <input
                          type="text"
                          className="w-100"
                          value={el.msg}
                          readOnly={true}
                          style={{ textOverflow: "ellipsis" }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    검색된 SMS 전송내역 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
