import React from "react";
import Tabs from "react-bootstrap/Tabs";
import SearchResultListRight1 from "./searchResultListRight1";
import SearchResultListRight2 from "./searchResultListRight2";
import Tab from "react-bootstrap/Tab";

const searchResultListRight = () => {
  const [key1b, setKey1b] = React.useState("회원증정보");

  return (
    <>
      <div className="col-right">
        <div className="tabs-sidebar-wrapper w-100">
          <Tabs className="tab-blocks" activeKey={key1b} onSelect={k => setKey1b(k)}>
            <Tab eventKey="회원증정보" title={<>회원증정보</>}>
              <SearchResultListRight1 />
            </Tab>
            <Tab eventKey="사업자등록증" title={<>사업자등록증</>}>
              <SearchResultListRight2 />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default searchResultListRight;
