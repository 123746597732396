import React from "react";
import Select from "react-select-virtualized";
import { useGetPartner } from "../../api/code/useGetPartner";
import { useGetEmptyPartner } from "../../api/code/useGetEmptyPartner";
import { decrypt } from "../../util/decrypt/decrypt";
import { useGetMemberNm } from "../../api/code/useGetMemberNm";
import { useGetEtcCmQ } from "../../api/code/useGetEtcCmQ";
import { useGetEtcSmQ } from "../../api/code/useGetEtcSmQ";

export default function MembershipItemSelect(props) {
  const { gubun, data, keyName, onChangeEventHandler, isDisabled } = props;

  const getMemberNm = useGetMemberNm(); // 골프
  const getEtcCmQ = useGetEtcCmQ(); // 콘도
  const getEtcSmQ = useGetEtcSmQ(); // 헬스

  const golfData = getMemberNm.data?.map(el => {
    return { label: el.codename, value: el.code, value1: el.value1, class: el.class };
  });
  const condoData = getEtcCmQ.data?.map(el => {
    return { label: el.value, value: el.key };
  });
  const sportsData = getEtcSmQ.data?.map(el => {
    return { label: el.value, value: el.key };
  });

  return (
    <>
      {gubun === "1" && (
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          options={golfData ?? []}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          value={golfData?.find(option => option.value === data) ?? {}}
          onChange={e => onChangeEventHandler([keyName], e?.value)}
          isDisabled={isDisabled}
        />
      )}
      {gubun === "2" && (
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          options={condoData}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          value={condoData?.find(option => option.value === data)}
          onChange={e => onChangeEventHandler([keyName], e?.value)}
          isDisabled={isDisabled}
        />
      )}
      {gubun === "3" && (
        <Select
          className="select-virtual text-left"
          menuPlacement={"auto"}
          menuPosition={"fixed"}
          options={sportsData}
          styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
          value={sportsData?.find(option => option.value === data)}
          onChange={e => onChangeEventHandler([keyName], e?.value)}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
}
