import { atom } from "jotai";
import moment from "moment";

export const approvalInbox_searchOption = atom({
  confmResultCode: "0", // 결재상태
  drafter: "",
  drftType: "0", // 결재 구분
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: moment(new Date()).format("YYYY-MM-DD"),
});
export const approvalInbox_searchData = atom([]);
export const approvalInbox_table_row = atom("5");
export const approvalInbox_detailData = atom(null);
export const approvalInbox_approveContent = atom({
  confmOpinion: "",
  confmOrdr: "",
  drftSn: "",
  drftType: "",
});
export const approvalInbox_drftsn = atom({
  drftType: "",
  drftSn: "",
});
export const approvalInbox_approvalLineList = atom({});
