import React from "react";

export const TableHeaderSM = () => {
  return (
    <tr>
      <th style={{ width: "8%" }}>연번</th>
      <th style={{ width: "3%" }}>선택</th>
      <th style={{ width: "15%" }}>스포츠장명</th>
      <th style={{ width: "4%" }}>코드</th>
      <th>회원권명</th>
      <th style={{ width: "10%" }}>정렬순서</th>
      <th style={{ width: "10%" }}>회원권분류</th>
      <th style={{ width: "10%" }}>구분</th>
      <th>비고</th>
      <th style={{ width: "5%" }}>삭제</th>
    </tr>
  );
};
