import React from "react";
import { useAtom } from "jotai/index";
import { cardList_atom } from "../../../../data/atom/dataManage/cardInquiry/cardManager_atom";
import moment from "moment/moment";

const CardManagerRow = props => {
  const { key, data, index, getCorpGubnList, getBankGubnList, getJisaGubnList } = props;
  const [cardList, setCardList] = useAtom(cardList_atom);

  const onChangeEventHandler = (key, value, idx) => {
    if (key === "cardNo" || key === "passWord" || key === "validThru") {
      value = value.replace(/[^0-9]/g, "").replace(/-/g, "");
    } else if (key === "ordrSeq1" || key === "ordrSeq2" || key === "payDay") {
      value = Number(value.replace(/[^0-9]/g, "").replace(/-/g, ""));
    }

    const list = cardList.map((el, idx2) => {
      if (idx === idx2) {
        if (el.newFlag) {
          return { ...el, [key]: value };
        } else {
          return { ...el, [key]: value, updateCheck: "Y" };
        }
      } else return el;
    });

    setCardList(list);
  };

  return (
    <>
      <tr className={data.originalOutDate !== null ? "bg-red" : ""}>
        <td>{index + 1}</td>
        <td>
          <select
            className="form-select text-left"
            value={data.corpGubn}
            onChange={e => onChangeEventHandler("corpGubn", e.target.value, index)}
          >
            {getCorpGubnList.data?.length !== 0 ? (
              getCorpGubnList.data?.map(el => {
                return <option value={el.key}>{el.value}</option>;
              })
            ) : (
              <></>
            )}
            <option value="" hidden={true}></option>
          </select>
        </td>
        <td>
          <select
            className="form-select text-left"
            value={data.gubun}
            onChange={e => onChangeEventHandler("gubun", e.target.value, index)}
          >
            {getBankGubnList.data?.length !== 0 ? (
              getBankGubnList.data?.map(el => {
                return <option value={el.key}>{el.value}</option>;
              })
            ) : (
              <></>
            )}
            <option value="" hidden={true}></option>
          </select>
        </td>
        <td>
          <select
            className="form-select text-left"
            value={data.jisa}
            onChange={e => onChangeEventHandler("jisa", e.target.value, index)}
          >
            {getJisaGubnList.data?.length !== 0 ? (
              getJisaGubnList.data?.map(el => {
                return <option value={el.key}>{el.value}</option>;
              })
            ) : (
              <></>
            )}
            <option value="" hidden={true}></option>
          </select>
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input text-left"
            value={data.cardNo?.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`)}
            onChange={e => onChangeEventHandler("cardNo", e.target.value, index)}
            maxLength={19}
          />
        </td>
        <td>
          <input
            type="password"
            className="form-control table-input text-center"
            value={data.passWord}
            onChange={e => onChangeEventHandler("passWord", e.target.value, index)}
            style={{ borderColor: "#C5C5C5" }}
          />
        </td>
        <td>
          <select
            className="form-select text-left"
            value={data.kind}
            onChange={e => onChangeEventHandler("kind", e.target.value, index)}
          >
            <option value="1">개인</option>
            <option value="2">공용</option>
            <option value="3">우편전용</option>
            <option value="" hidden={true}></option>
          </select>
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input text-left"
            value={data.kname}
            onChange={e => onChangeEventHandler("kname", e.target.value, index)}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input text-center"
            value={data.ordrSeq1}
            onChange={e => onChangeEventHandler("ordrSeq1", e.target.value, index)}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input text-center"
            value={data.ordrSeq2}
            onChange={e => onChangeEventHandler("ordrSeq2", e.target.value, index)}
          />
        </td>
        <td>
          <input
            type="month"
            className="form-control table-input w-135px"
            value={data.validThru ? moment(data.validThru).format("YYYY-MM") : ""}
            onChange={e => onChangeEventHandler("validThru", e.target.value, index)}
            max={"9999-12"}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input text-center"
            value={data.payDay}
            onChange={e => onChangeEventHandler("payDay", e.target.value, index)}
          />
        </td>
        <td>
          <select
            className="form-select"
            value={data.userCheck}
            onChange={e => onChangeEventHandler("userCheck", e.target.value, index)}
          >
            <option value="B">본사보관</option>
            <option value="J">지사보관</option>
            <option value="P">개인지급</option>
            <option value="" hidden={true}></option>
          </select>
        </td>
        <td>
          <input
            type="date"
            className="form-control table-input w-135px"
            value={data.openDate}
            onChange={e => onChangeEventHandler("openDate", e.target.value, index)}
            max={"9999-12-31"}
          />
        </td>
        <td>
          <input
            type="date"
            className="form-control table-input w-135px"
            value={data.outDate}
            onChange={e => onChangeEventHandler("outDate", e.target.value, index)}
            max={"9999-12-31"}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input w-135px"
            value={data.remark}
            onChange={e => onChangeEventHandler("remark", e.target.value, index)}
          />
        </td>
      </tr>
    </>
  );
};

export default React.memo(CardManagerRow);
