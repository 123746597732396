import React, { useEffect, useState } from "react";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import { useGetAcquisition } from "../../../../api/code/useGetAcquisition";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import { useGetJisaGubunAllQ1 } from "../../../../api/code/useGetJisaGubunAllQ1";
import { useGetMemberNm } from "../../../../api/code/useGetMemberNm";
import { useGetEtcCmQ } from "../../../../api/code/useGetEtcCmQ";
import { useGetEtcSmQ } from "../../../../api/code/useGetEtcSmQ";

export const TransactionHistory = () => {
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  const getAcquisition = useGetAcquisition();
  const getUserIdQ = useGetUserIdQ();
  const getJisaGubunAllQ1 = useGetJisaGubunAllQ1();

  const getMemberNm = useGetMemberNm(); // 골프
  const getEtcCmQ = useGetEtcCmQ(); // 콘도
  const getEtcSmQ = useGetEtcSmQ(); // 헬스

  // 거래내역 조회
  const customerGetTransactionHistory = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getTransactionHistory", {
        params: {
          custId: data,
        },
      }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetTransactionHistory.mutate(mainData.seqNo);
  }, [mainData.seqNo]);

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">
          거래내역 (<span>{subData.length ?? "0"}</span>건)
        </div>
      </div>
      <div className="overflow-auto table-container table-sticky h-528 mt-15px">
        <table
          className="table table-bordered table-responsive my-table mt-0"
          style={{ width: `${subData.length !== 0 ? "2200px" : ""}` }}
        >
          <colgroup>
            <col width="3%" />
            <col width="4%" />
            <col width="3%" />
            <col width="4%" />
            <col width="3%" />
            <col width="4%" />
            <col width="4%" />
            <col width="4%" />
            <col width="4%" />
            <col width="4%" />
            <col width="4%" />
            <col width="5%" />
            <col width="3%" />
            <col width="5%" />
            <col width="4%" />
            <col width="4%" />
            <col width="4%" />
            <col width="4%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>일자</th>
              <th>거래분류</th>
              <th>구분1</th>
              <th>구분2</th>
              <th>회원권명</th>
              <th>회원증 No.</th>
              <th>서류금액</th>
              <th>매매금액</th>
              <th>UP DOWN</th>
              <th>수수료</th>
              <th>거래 딜러</th>
              <th>名 상이</th>
              <th>기타(샵명, 회원증명)</th>
              <th>소속지사</th>
              <th>세무신고</th>
              <th>신고일자</th>
              <th>고객등기</th>
              <th>등기 발송일</th>
              <th>납부세액(원)</th>
              <th>주민세(원)</th>
            </tr>
          </thead>
          <tbody>
            {subData.length !== 0 ? (
              subData?.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>{subData.length - index}</td>
                    <td>{el?.custDate ? moment(el.custDate).format("YYYY-MM-DD") : ""}</td>
                    <td>
                      {el?.tradGubn === "3" ? "개인" : el?.tradGubn === "4" ? "법인" : "타샵"}
                    </td>
                    <td>
                      {el?.saleGu === "1"
                        ? "실거래"
                        : el?.saleGu === "2"
                        ? "분양"
                        : el?.saleGu === "3"
                        ? "증여"
                        : el?.saleGu === "4"
                        ? "상속"
                        : el?.saleGu === "5" && "명의변경"}
                      {"-"}
                      {el?.jisaGerae === "Y"
                        ? "타지사"
                        : el?.jisaGerae === "N"
                        ? "지사內"
                        : el?.jisaGerae === "S" && "샵거래"}
                    </td>
                    <td>
                      {el?.gubun2 === "1"
                        ? "골프"
                        : el?.gubun2 === "2"
                        ? "콘도"
                        : el?.gubun2 === "3" && "헬스"}
                      {"("}
                      {el?.gubun1 === "1" ? "양도" : el?.gubun1 === "2" && "양수"}
                      {")"}
                    </td>
                    <td>
                      {el?.gubun2 === "1" &&
                        getMemberNm.data?.find(el2 => el2.code === el?.roomNo)?.codename}
                      {el?.gubun2 === "2" &&
                        getEtcCmQ.data?.find(el2 => el2.key === el?.roomNo)?.value}
                      {el?.gubun2 === "3" &&
                        getEtcSmQ.data?.find(el2 => el2.key === el?.roomNo)?.value}
                    </td>

                    <td>{el?.memberNo}</td>
                    <td className="text-end">{(+el?.yangdoWon2).toLocaleString()}</td>
                    <td className="text-end">{(+el?.yangdoWon1).toLocaleString()}</td>
                    <td>{el?.downYn}</td>
                    <td className="text-end">{(+el?.commission).toLocaleString()}</td>
                    <td>{getUserIdQ.data?.find(el2 => el2.key === el?.partner)?.value}</td>
                    <td>{el?.differCheck}</td>
                    <td>{el?.remark}</td>
                    <td>{getJisaGubunAllQ1.data?.find(el2 => el2.key === el?.jisa)?.value}</td>
                    <td>
                      {el?.taxTarget === "Y" || el?.taxTarget === "N"
                        ? "세무신고대상"
                        : el?.taxTarget === "S"
                        ? "완료"
                        : "관청발송"}
                    </td>
                    <td>{el?.sinDate ? moment(el.sinDate).format("YYYY-MM-DD") : ""}</td>
                    <td>
                      {el?.yangdoTaxTarget === "Y"
                        ? "대상"
                        : el?.yangdoTaxTarget === "N"
                        ? "비대상"
                        : el?.yangdoTaxTarget === "H"
                        ? "발송대기"
                        : el?.yangdoTaxTarget === "S" && "완료"}
                    </td>
                    <td>{el?.jiroSendDate ? moment(el.jiroSendDate).format("YYYY-MM-DD") : ""}</td>
                    <td className="text-end">{(+el?.taxWon).toLocaleString()}</td>
                    <td className="text-end">{(+el?.taxJumin).toLocaleString()}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={21} className="text-center">
                  검색된 거래내역 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
