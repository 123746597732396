import React from "react";

const ModalRow = ({ data, index }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{data.cviewCheck}</td>
      <td>{data.adminCheck}</td>
      <td>{data.siseDate}</td>
      <td>{data.gubun === "2" ? "*" : <>&nbsp;&nbsp;</>}</td>
      <td className={`${data.gubun === "2" && "td_pink"}`}>
        {Number(data.csiseD).toLocaleString("en-US")}
      </td>
      <td>{Number(data.csiseS).toLocaleString("en-US")}</td>
      <td>{Number(data.diff1).toLocaleString("en-US")}</td>
      <td>{Number(data.diff2).toLocaleString("en-US")}</td>
      <td>{data.remark}</td>
    </tr>
  );
};

export default React.memo(ModalRow);
