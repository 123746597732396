import React, { useEffect } from "react";
import { useGetJisaGubun } from "../../../../../api/code/useGetJisaGubun";
import SearchPanel from "./searchPanel";
import SearchResultTable from "./searchResultTable";
import { useSetAtom } from "jotai/index";
import {
  searchParam_atom,
  searchParamInit,
  searchResult_atom,
} from "../../../../../data/atom/workManage/departmentManage/databaseDistributionStatus/newDbStatus";
import useRecentTabEvent from "../../../../../components/layout/useRecentTabEvent";
import { useLocation } from "react-router-dom";
import { decrypt } from "../../../../../util/decrypt/decrypt";

const NewDbStatus = () => {
  const setSearchParamAtom = useSetAtom(searchParam_atom);
  const setSearchResult = useSetAtom(searchResult_atom);
  const { addTabClosedEvent } = useRecentTabEvent();
  const location = useLocation();
  useGetJisaGubun();

  useEffect(() => {
    addTabClosedEvent(location.pathname, () => {
      setSearchParamAtom({ ...searchParamInit });
      setSearchResult([]);
    });
  }, []);

  return (
    <div className="new-db-status-container ">
      <div className="customer-info-change-title">신규DB등록현황</div>
      <SearchPanel />
      <div className="new-db-btn-container">
        {/*<div className="btn btn-outline-primary btn-filter-outline-primary">
          개인별 등록건수 집계
        </div>
        <div className="btn btn-outline-primary btn-filter-outline-primary">팀별 등록건수 집계</div>*/}
      </div>
      <SearchResultTable />
    </div>
  );
};

export default NewDbStatus;
