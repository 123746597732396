import lockAuth from "../../util/lockAuth";
import { decrypt } from "../../util/decrypt/decrypt";
import { useGetUserIdQ } from "../../api/code/useGetUserIdQ";

/**
 * lockAuth 값이 readonlyLevel 값보다 작거나 같으면 readonly
 */
export function isReadonly(readonlyLevel) {
  const currentLoginAccountPermitLevel = Number(lockAuth());

  if (typeof readonlyLevel === "string") {
    readonlyLevel = Number(readonlyLevel);
  }

  return currentLoginAccountPermitLevel <= readonlyLevel;
}

/**
 * rangeLevel = 1 : 전체 데이터<br/>
 * rangeLevel = 0 : 로그인 된 유저의 지사에 소속된 데이터만 표시<br/>
 * rangeLevel = -1 : 조회 중인 데이터 및 로그인 된 유저 데이터만 표시<br/>
 * rangeLevel = -2 : 조회 중인 데이터의 값만 표시
 */
export function getFilteredData(rangeLevel, locked = 1, data) {
  const userInfo = decrypt("userInfo");
  const getUserIdQ = useGetUserIdQ();

  if (typeof rangeLevel === "string") {
    rangeLevel = Number(rangeLevel);
  }

  let partnerData = getUserIdQ?.data?.map(el => {
    if (el.work1 === "22") {
      return {
        label: el.key,
        value: el.key,
        jisa: el.jisa,
        jisaName: el.jisaName,
        authority: el.authority,
        work1: el.work1,
      };
    } else {
      return {
        label: el.value,
        value: el.key,
        jisa: el.jisa,
        jisaName: el.jisaName,
        authority: el.authority,
        work1: el.work1,
      };
    }
  });

  if (partnerData) {
    // work1이 11, 71인 것만 filtering
    partnerData = partnerData.filter(el => el.work1 === "11" || el.work1 === "71");

    // partnerData에 data.partner가 없으면 data.partner를 추가
    if (data.partner && !partnerData.find(el => el.value === data.partner)) {
      partnerData.push({
        label: data.partner,
        value: data.partner,
        jisa: userInfo.jisa,
        jisaName: userInfo.jisaName,
        authority: userInfo.authority,
        work1: "11",
      });
    }

    if (locked === 1 && rangeLevel < 1) {
      // 조회 중인 데이터의 value
      partnerData = partnerData.filter(el => el.value === data.partner);
    } else if (rangeLevel === 0) {
      partnerData = partnerData.filter(el => el.jisa === userInfo.jisa);
    } else if (rangeLevel === -1) {
      // 조회 중인 데이터의 value와 로그인 된 유저 자신만
      partnerData = partnerData.filter(
        el => el.value === userInfo.loginId || el.value === data.partner
      );
    } else if (rangeLevel === -2) {
      // 조회 중인 데이터의 value
      partnerData = partnerData.filter(el => el.value === data.partner);
    }

    return partnerData;
  } else return [];
}
