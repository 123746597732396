import React from "react";
import { useAtom } from "jotai/index";
import {
  cardList_atom,
  editedRowList_atom,
  newData_atom,
  searchParam_atom,
} from "../../../../data/atom/dataManage/cardInquiry/cardMaster_atom";
import moment from "moment/moment";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";

const CardMasterRow = props => {
  const { data, index, getCardSelectList, getCardUserList, getAccountList, searchCard, isNew } =
    props;
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [cardList, setCardList] = useAtom(cardList_atom);
  const [editedRowList, setEditedRowList] = useAtom(editedRowList_atom);
  const [newData, setNewData] = useAtom(newData_atom);

  const deleteCard = useMutation(data => axios.post("/api/data/card/delete", data), {
    onSuccess: res => {
      searchCard.mutate(searchParam);
    },
  });

  const onChangeEventHandler = (key, value, index2) => {
    if (isNew) {
      if (key === "saupno") {
        value = value.replace(/[^0-9]/g, "");
      } else if (key === "priceWon") {
        value = Number(String(value).replace(/[^0-9]/g, ""));
      } else if (key === "cardNo") {
        setNewData({
          ...newData,
          [key]: value,
          cardName: getCardSelectList?.data?.find(el2 => value === el2.cardNo)?.kname,
        });
        return;
      } else if (key === "userId") {
        setNewData({
          ...newData,
          [key]: value.value,
          userName: value.options[value.selectedIndex].text,
        });
        return;
      } else if (key === "account") {
        setNewData({
          ...newData,
          [key]: value.value,
          accountName: value.options[value.selectedIndex].text,
        });
        return;
      }
      setNewData({ ...newData, [key]: value });
    } else {
      if (key === "saupno") {
        value = value.replace(/[^0-9]/g, "");
      } else if (key === "priceWon") {
        value = Number(String(value).replace(/[^0-9]/g, ""));
      }
      const list = cardList
        .map((el, idx) => {
          if (idx === index) {
            if (key === "cardNo") {
              return {
                ...el,
                [key]: value,
                cardName: getCardSelectList?.data?.find(el2 => value === el2.cardNo)?.kname,
              };
            } else if (key === "userId") {
              return {
                ...el,
                [key]: value.value,
                userName: value.options[value.selectedIndex].text,
              };
            } else if (key === "account") {
              return {
                ...el,
                [key]: value.value,
                accountName: value.options[value.selectedIndex].text,
              };
            } else {
              return { ...el, [key]: value };
            }
          } else {
            return el;
          }
        })
        .sort((a, b) => a.account - b.account);

      setEditedRowList(prev => {
        let flag = -2;
        prev = prev?.map((el, idx) => {
          if (el.idx === index2) {
            flag = el.idx;
            if (key === "cardNo") {
              return {
                ...el,
                [key]: value,
                cardName: getCardSelectList?.data?.find(el2 => value === el2.cardNo)?.kname,
              };
            } else if (key === "userId") {
              return {
                ...el,
                [key]: value.value,
                userName: value.options[value.selectedIndex].text,
              };
            } else if (key === "account") {
              return {
                ...el,
                [key]: value.value,
                accountName: value.options[value.selectedIndex].text,
              };
            } else {
              return { ...el, [key]: value };
            }
          } else return el;
        });
        if (flag === -2) prev.push({ ...data, [key]: value });

        return prev;
      });

      setCardList(list);
    }
  };

  const btnDelete = (deleteEl, idx) => {
    if (isNew) {
      setNewData({});
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteCard.mutate([deleteEl]);
      }
    }
  };

  return (
    <>
      <tr>
        <td>{index === -1 ? "" : index + 1}</td>
        <td>
          {/*<input*/}
          {/*  type="text"*/}
          {/*  className="form-control table-input"*/}
          {/*  value={data.cardNo.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`)}*/}
          {/*  onChange={e => onChangeEventHandler("cardNo", e.target.value)}*/}
          {/*  maxLength={19}*/}
          {/*/>*/}
          <select
            className="form-select table-select"
            value={data.cardNo}
            onChange={e => onChangeEventHandler("cardNo", e.target.value, data.idx)}
          >
            {getCardSelectList?.data?.map(el => {
              return (
                <option
                  style={
                    el.outDate !== null
                      ? { backgroundColor: "red", textDecoration: "line-through" }
                      : {}
                  }
                  value={el.cardNo}
                >
                  {el.cardNo.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`)}
                </option>
              );
            })}
            <option value={data.cardNo} hidden={true}></option>
          </select>
        </td>
        <td>
          <input
            type="datetime-local"
            className="form-control filter-input"
            value={moment(data.currDate).format("YYYY-MM-DD HH:mm:ss")}
            onChange={e => onChangeEventHandler("currDate", e.target.value, data.idx)}
            min={"1900-01-01"}
            max={"2099-12-31"}
            style={{ borderColor: "#c5c5c5" }}
          />
        </td>
        <td>
          <select
            className="form-select table-select"
            value={data.userId}
            onChange={e => onChangeEventHandler("userId", e.target, data.idx)}
          >
            {getCardUserList?.data?.map(el => {
              return <option value={el.key}>{el.value}</option>;
            })}
            <option value={data.userId} hidden={true}></option>
          </select>
        </td>
        <td>
          <select
            className="form-select table-select"
            value={data.account}
            onChange={e => onChangeEventHandler("account", e.target, data.idx)}
          >
            {getAccountList?.data?.map(el => {
              return <option value={el.key}>{el.value}</option>;
            })}
            <option value={data.account} hidden={true}></option>
          </select>
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input text-center"
            value={data.priceWon?.toLocaleString()}
            onChange={e =>
              onChangeEventHandler("priceWon", Number(e.target.value.replace(/,/g, "")), data.idx)
            }
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input"
            value={data.remark}
            onChange={e => onChangeEventHandler("remark", e.target.value, data.idx)}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input text-center"
            value={data.saupno?.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)}
            onChange={e => onChangeEventHandler("saupno", e.target.value, data.idx)}
            maxLength={12}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control table-input"
            value={data.storeName}
            onChange={e => onChangeEventHandler("storeName", e.target.value, data.idx)}
          />
        </td>
        <td>
          {data.inputId ? getCardUserList?.data?.find(el => el.key === data.inputId)?.value : ""}
        </td>
        <td className="text-center cursor-pointer" onClick={() => btnDelete(data, index)}>
          <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
        </td>
      </tr>
    </>
  );
};

export default React.memo(CardMasterRow);
