import React from "react";
import { useAtom } from "jotai/index";
import { result_atom } from "../../../../data/atom/workManage/transferTax/transferTax_atom";

export default function Result() {
  const [result, setResult] = useAtom(result_atom);

  return (
    <div className="card card-main w-100">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title  m-0"> 계산 결과</h5>
      </div>
      <div className="card-body">
        <table className="table table-bordered table-responsive my-table m-0">
          <thead>
            <tr>
              <th style={{ width: "13%" }}>구분</th>
              <th style={{ width: "13%" }}>1건</th>
              <th style={{ width: "13%" }}>2건</th>
              <th style={{ width: "13%" }}>3건</th>
              <th style={{ width: "13%" }}>4건</th>
              <th style={{ width: "13%" }}>5건</th>
              <th style={{ width: "13%" }}>합계</th>
            </tr>
          </thead>
          <tbody className="work-tax-table-body">
            <tr>
              <td>필요경비</td>
              {result.resultList.map(el => (
                <td className="text-end">{Number(el.needCost)?.toLocaleString()}</td>
              ))}
              <td className="text-end">
                {result.resultList
                  .reduce((acc, cur) => {
                    return acc + cur.needCost;
                  }, 0)
                  ?.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td>양도차익</td>
              {result.resultList.map(el => (
                <td className="text-end">{el.ydChaik?.toLocaleString()}</td>
              ))}
              <td className="text-end">
                {result.resultList
                  .reduce((acc, cur) => {
                    return acc + cur.ydChaik;
                  }, 0)
                  ?.toLocaleString()}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered table-responsive my-table1 mb-0 mt-10px">
          <tbody>
            <tr>
              <th style={{ width: "16.66%" }}>양도소득</th>
              <td className="text-end" style={{ width: "16.66%" }}>
                {result.yangdoSum?.toLocaleString()}
              </td>
              <th style={{ width: "16.66%" }}>과세표준</th>
              <td className="text-end" style={{ width: "16.66%" }}>
                {result.gwasePyojun?.toLocaleString()}
              </td>
              <th style={{ width: "16.66%" }}>세율</th>
              <td className="text-end" style={{ width: "16.66%" }}>
                {result.seyul?.toLocaleString()} %
              </td>
            </tr>
            <tr>
              <th>산출세액</th>
              <td className="text-end">{result.sanchulCost?.toLocaleString()}</td>
              <th>감면세액</th>
              <td className="text-end">{result.gammyunCost?.toLocaleString()}</td>
              <th>누진공제금액</th>
              <td className="text-end">{result.nujinCost?.toLocaleString()}만원</td>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#0000FF", color: "#FFFFFF" }}>10% 공제</th>
              <td className="text-end" style={{ width: "13.1%" }}>
                {result.tenGongje?.toLocaleString()}
              </td>
              <th style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}>가산세</th>
              <td className="text-end">{result.gasanCost?.toLocaleString()}</td>
              <th>납부할 세액</th>
              <td className="text-end">{result.napbuCost?.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
