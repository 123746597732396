import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai/index";
import { customerManage_mainData_atom } from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { axios } from "../../../../util/axios/axios";
import moment from "moment";

export const OldCall = () => {
  const defaultURL = process.env.PUBLIC_URL;

  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);
  const [subData, setSubData] = useState([]);

  // old통화 조회
  const customerGetOldCall = useMutation(
    data =>
      axios.get("/api/work/customer/bottom/getOldCall", {
        params: {
          custId: data,
        },
      }),
    {
      onSuccess: res => {
        setSubData(res.data.result);
      },
    }
  );

  useEffect(() => {
    mainData.seqNo && customerGetOldCall.mutate(mainData.seqNo);
  }, [mainData.seqNo]);

  return (
    <div className="tab2-content-container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="table-tabs-sub m-0">
          Old 통화 (<span>{subData.length ?? "0"}</span>건)
        </div>
      </div>
      <div className="table-container table-sticky h-443 mt-10">
        <table className="table table-bordered table-responsive my-table mt-0">
          <colgroup>
            <col width="5%" />
            <col width="15%" />
            <col width="15%" />
            <col width="40%" />
          </colgroup>
          <thead>
            <tr>
              <th>연번</th>
              <th>통화일자</th>
              <th>통화자</th>
              <th>통화내용</th>
            </tr>
          </thead>
          <tbody>
            {subData.length !== 0 ? (
              subData?.map((el, index) => {
                return (
                  <tr>
                    <td>
                      <div>{subData.length - index}</div>
                    </td>
                    <td>{el?.talkDate ? moment(el.talkDate).format("YYYY-MM-DD") : ""}</td>
                    <td>{el?.talkName}</td>
                    <td>
                      <input
                        className="form-control text-start"
                        type="text"
                        value={el?.remark}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  검색된 Old 통화 내역이 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
