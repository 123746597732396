import React, { useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { numberFixedFormat } from "../../../../util/numberFixedFormat";

export const Eight = ({ data }) => {
  const targetRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: data.tableRows?.length,
    getScrollElement: () => targetRef.current,
    estimateSize: () => 30,
  });

  return (
    <div className="card card-sub">
      <div className="card-body">
        <div className="overflow-auto table-container table-sticky h-633" ref={targetRef}>
          <table
            className="table table-bordered table-responsive my-table mt-0"
            style={
              {
                // width: `${data.tableRows?.length !== 0 ? "1800px" : ""} `,
                // marginTop: `${(rowVirtualizer.range?.startIndex ?? 0) * 30}px`,
                // marginBottom: `${
                //   rowVirtualizer.getTotalSize() -
                //   (rowVirtualizer.range?.startIndex ?? 0) * 30 -
                //   rowVirtualizer.getVirtualItems().length * 30
                // }px`,
              }
            }
          >
            <thead>
              <tr>
                <th rowSpan={3}>소속</th>
                <th rowSpan={3}>성명</th>
                <th rowSpan={3}>직위</th>
                <th rowSpan={3}>SMS전송</th>
                <th rowSpan={3}>LMS전송</th>
                <th rowSpan={3}>통화건수</th>
                <th rowSpan={3}>통화고객</th>
                <th colSpan={9}>주문내역</th>
                <th colSpan={2}>실적</th>
              </tr>
              <tr>
                <th colSpan={3}>골프</th>
                <th colSpan={3}>콘도</th>
                <th colSpan={3}>헬스</th>
                <th rowSpan={2}>건수</th>
                <th rowSpan={2}>금액</th>
              </tr>
              <tr>
                <th>매도</th>
                <th>매수</th>
                <th>분양</th>
                <th>매도</th>
                <th>매수</th>
                <th>분양</th>
                <th>매도</th>
                <th>매수</th>
                <th>분양</th>
              </tr>
            </thead>
            <tbody>
              {data.result?.length !== 0 ? (
                data.result?.reduce((acc, cur, idx, arr) => {
                  // 소속 합치기
                  if (cur?.jobName !== arr[idx - 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr>
                        <td rowSpan={arr.filter(el => el.jobName === cur.jobName).length}>
                          {cur.jobName}
                        </td>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.smsCnt}</td>
                        <td>{cur.lmsSnt}</td>
                        <td>{cur.talkCnt}</td>
                        <td>{cur.talkCustCnt}</td>
                        <td>{cur.gubn11}</td>
                        <td>{cur.gubn12}</td>
                        <td>{cur.gubn1S}</td>
                        <td>{cur.gubn21}</td>
                        <td>{cur.gubn22}</td>
                        <td>{cur.gubn2S}</td>
                        <td>{cur.gubn31}</td>
                        <td>{cur.gubn32}</td>
                        <td>{cur.gubn3S}</td>
                        <td>{cur.exchCnt}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt)}</td>
                      </tr>,
                    ];
                  } else {
                    acc = [
                      ...acc,
                      <tr>
                        <td>{cur.userName}</td>
                        <td>{cur.authName}</td>
                        <td>{cur.smsCnt}</td>
                        <td>{cur.lmsSnt}</td>
                        <td>{cur.talkCnt}</td>
                        <td>{cur.talkCustCnt}</td>
                        <td>{cur.gubn11}</td>
                        <td>{cur.gubn12}</td>
                        <td>{cur.gubn1S}</td>
                        <td>{cur.gubn21}</td>
                        <td>{cur.gubn22}</td>
                        <td>{cur.gubn2S}</td>
                        <td>{cur.gubn31}</td>
                        <td>{cur.gubn32}</td>
                        <td>{cur.gubn3S}</td>
                        <td>{cur.exchCnt}</td>
                        <td className="text-end">{numberFixedFormat(cur.rsltAmnt)}</td>
                      </tr>,
                    ];
                  }

                  // 소속 총계
                  if (cur?.jobName !== arr[idx + 1]?.jobName) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightgrey">
                        <td colSpan={3}>{cur.jobName} 계</td>
                        <td>{data.resultSum[cur.jobName].smsCnt}</td>
                        <td>{data.resultSum[cur.jobName].lmsSnt}</td>
                        <td>{data.resultSum[cur.jobName].talkCnt}</td>
                        <td>{data.resultSum[cur.jobName].talkCustCnt}</td>
                        <td>{data.resultSum[cur.jobName].gubn11}</td>
                        <td>{data.resultSum[cur.jobName].gubn12}</td>
                        <td>{data.resultSum[cur.jobName].gubn1S}</td>
                        <td>{data.resultSum[cur.jobName].gubn21}</td>
                        <td>{data.resultSum[cur.jobName].gubn22}</td>
                        <td>{data.resultSum[cur.jobName].gubn2S}</td>
                        <td>{data.resultSum[cur.jobName].gubn31}</td>
                        <td>{data.resultSum[cur.jobName].gubn32}</td>
                        <td>{data.resultSum[cur.jobName].gubn3S}</td>
                        <td>{data.resultSum[cur.jobName].exchCnt}</td>
                        <td className="text-end">
                          {numberFixedFormat(data.resultSum[cur.jobName].rsltAmnt)}
                        </td>
                      </tr>,
                    ];
                  }

                  // 전체 총계
                  if (arr.length === idx + 1) {
                    acc = [
                      ...acc,
                      <tr className="bg-lightyellow">
                        <td colSpan={3}>총계</td>
                        <td>{data.total.smsCnt}</td>
                        <td>{data.total.lmsSnt}</td>
                        <td>{data.total.talkCnt}</td>
                        <td>{data.total.talkCustCnt}</td>
                        <td>{data.total.gubn11}</td>
                        <td>{data.total.gubn12}</td>
                        <td>{data.total.gubn1S}</td>
                        <td>{data.total.gubn21}</td>
                        <td>{data.total.gubn22}</td>
                        <td>{data.total.gubn2S}</td>
                        <td>{data.total.gubn31}</td>
                        <td>{data.total.gubn32}</td>
                        <td>{data.total.gubn3S}</td>
                        <td>{data.total.exchCnt}</td>
                        <td className="text-end">{numberFixedFormat(data.total.rsltAmnt)}</td>
                      </tr>,
                    ];
                  }

                  return acc;
                }, [])
              ) : (
                <tr>
                  <td colSpan={29}>검색결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
