import EmpModal from "../../../../../components/modal/Modal";
import moment from "moment/moment";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";

export const CallReservationModal = ({
  isCallReservationModalOpen,
  setIsCallReservationModalOpen,
}) => {
  const [reservationData, setReservationData] = useState({
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HH:mm"),
  });

  const customerBookCall = useMutation(data => axios.post("/api/work/customer/bookCall", data), {
    onSuccess: res => {
      // setIsCallReservationModalOpen({ data: null, isOpen: false });
      alert("통화예약이 완료되었습니다.");
      setIsCallReservationModalOpen({
        data: null,
        isOpen: false,
      });
    },
  });

  const btn_reservation = () => {
    if (window.confirm("통화예약을 하시겠습니까?")) {
      customerBookCall.mutate({
        cellular: isCallReservationModalOpen.data.cellular,
        ctel: isCallReservationModalOpen.data.ctel,
        dateTime: reservationData.date + " " + reservationData.time + ":00",
        htel: isCallReservationModalOpen.data.htel,
        ownerCTel: isCallReservationModalOpen.data.ownerCTel,
        ownerCelTel: isCallReservationModalOpen.data.ownerCelTel,
        seqNo: isCallReservationModalOpen.data.seqNo,
      });
    }
  };

  return (
    <EmpModal
      show={isCallReservationModalOpen.isOpen}
      width="100%"
      // minWidth="475px"
      title="통화예약"
      onHide={() => setIsCallReservationModalOpen({ data: null, isOpen: false })}
    // strClass="modal-xl"
    // size="xl"
    >
      <div className="d-flex justify-content-center" style={{ gap: "20px" }}>
        <label>
          날짜
          <input
            type="date"
            max="9999-12-31"
            className="form-control filter-input filter1-input2"
            value={reservationData.date}
            onChange={e => {
              setReservationData({
                ...reservationData,
                date: moment(e.target.value).format("YYYY-MM-DD"),
              });
            }}
          />
        </label>
        <label>
          시간
          <input
            type="time"
            className="form-control filter-input filter1-input2"
            value={reservationData.time}
            onChange={e => {
              setReservationData({
                ...reservationData,
                time: e.target.value,
              });
            }}
          />
        </label>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button
          className="btn btn-outline-primary btn-filter-outline-primary"
          onClick={btn_reservation}
        >
          예약
        </button>
      </div>
    </EmpModal>
  );
};
