import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select-virtualized";
import useGMCMSMSelect from "./useGMCMSMSelect";

export default function MembershipSelect(props) {
  const { clazz = "GM", data, onChangeCallBack } = props;

  const GMCMSM = useGMCMSMSelect();

  const selectArr = useMemo(() => {
    return GMCMSM.returnArr.map(item => ({
      label: item.value,
      value: item.key,
    }));
  }, [GMCMSM.returnArr]);

  const [target, setTarget] = useState({});

  useEffect(() => {
    GMCMSM.changeClazz(clazz);
  }, [clazz]);

  useEffect(() => {
    setTarget(selectArr.find(e => e.value === data) ?? {});
  }, [data]);

  return (
    <>
      <Select
        className="select-virtual text-left"
        menuPlacement={"auto"}
        menuPosition={"fixed"}
        options={selectArr}
        styles={{ menuPortal: base => ({ ...base, zIndex: 3 }) }}
        value={target}
        onChange={e => {
          setTarget(e);
          if (onChangeCallBack) onChangeCallBack(e);
        }}
      />
    </>
  );
}
