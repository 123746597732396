import Axios from "axios";
import { decrypt } from "../decrypt/decrypt";

export const axios = Axios.create();

axios.interceptors.request.use(request => {
  const accessToken = decrypt("userInfo").accessToken;

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    delete request.headers.Authorization;
  }

  request.url = process.env.PUBLIC_URL + request.url;

  return request;
});

axios.interceptors.response.use(
  response => {
    let pathSplit = response.config.url.split("/");
    let lastUrlPath = pathSplit[pathSplit.length - 1].toLowerCase();
    if (lastUrlPath.startsWith("save") || lastUrlPath.startsWith("update")) {
      if (response.data.code === "0000") alert("저장 되었습니다.");
    } else if (lastUrlPath.startsWith("delete")) alert("삭제 되었습니다.");
    return response;
  },
  error => {
    // code가 -5로 오는 경우는 프론트에서 confirm 으로 예/아니오 선택해서 다시호출
    if (error.response?.data?.code === "-5") {
      return Promise.reject(error);
    }

    if (error.response?.data?.result) {
      let msgBuilder = "";
      for (var msg in error.response.data.result)
        msgBuilder += error.response.data.result[msg] + "\n";
      alert(msgBuilder);
    } else if (error.response?.data instanceof Blob) {
    } else if (error.response?.data?.code === "-7") {
      alert(error.response.data.message);
      sessionStorage.clear();
      window.location.href = process.env.PUBLIC_URL + "/";
      throw error;
    } else if (error.response?.data?.code !== "-5" && error.response?.data?.code !== "-6") {
      alert(error.response.data.message);
    } else {
      let pathSplit = error.config.url.split("/");
      let lastUrlPath = pathSplit[pathSplit.length - 1].toLowerCase();
      if (lastUrlPath.startsWith("save") || lastUrlPath.startsWith("update")) {
        alert("저장이 실패하였습니다.");
      }
      // else if (
      //   error.response.status === 500 ||
      //   error.response.status === 404 ||
      //   error.response.status === 400
      // ) {
      //   alert("서버 오류");
      // }
    }

    return Promise.reject(error);
  }
);
