import React, { useRef } from "react";
import ModalTransactionHistoryInquiry from "../modal/inquiry/ModalTransactionHistoryInquiry";
import ModalEnterTransactionDetails from "../modal/input/ModalEnterTransactionDetails";
import { useAtom } from "jotai/index";
import {
  transactionHistoryResult_atom,
  transactionHistorySearchParam_atom,
} from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";

const searchPanel = props => {
  const { API } = props;
  const [resData, setResData] = useAtom(transactionHistoryResult_atom);
  const [searchParam, setSearchParam] = useAtom(transactionHistorySearchParam_atom);
  const inputModalRef = useRef(null);
  const inquiryModalRef = useRef(null);

  // 검색조건 셀렉트 변경 이벤트
  const onSelectChangeEvent = (key, value) => {
    setSearchParam({ ...searchParam, [key]: value });
  };

  // 찾기 버튼 이벤트
  const btn_search = () => {
    API();
    setSearchParam(prev => ({ ...prev, filterChk: false }));
  };

  // 검색어 인풋 엔터 이벤트
  const btn_searchInput = e => {
    if (e.key === "Enter") btn_search();
  };

  // 결과내 재검색 필터 체크 이벤트
  const filterCheckedEvent = checked => {
    if (resData.length === 0) {
      btn_search();
      return;
    }
    setSearchParam(prev => ({ ...prev, filterChk: false }));
    let filteredArr;
    if (checked) {
      let filteredKey = prompt("검색된 결과 내에서 지역명으로 재검색");
      if (filteredKey) {
        filteredArr = resData.filter(item => {
          if (item.addres !== null) return item.addres.includes(filteredKey);
        });
        setResData(filteredArr);
        setSearchParam(prev => ({ ...prev, filterChk: true }));
      }
    } else {
      btn_search();
    }
  };

  return (
    <>
      <ModalTransactionHistoryInquiry ref={inquiryModalRef} />
      <ModalEnterTransactionDetails ref={inputModalRef} />
      <div className="filter1_container">
        <div className="filter_header_row">
          <div className="filter-title">고객검색</div>
          <div className="filter-buttons">
            <button
              className="btn btn-outline-dark my-btn-outline-dark"
              onClick={e => inputModalRef.current.showModal()}
            >
              거래내역 입력
            </button>
            <button
              className="btn btn-outline-dark my-btn-outline-dark"
              onClick={e => inquiryModalRef.current.showModal()}
            >
              거래내역 조회
            </button>
          </div>
        </div>
        <div className="filter_fields_container">
          <div className="filter_fields_row">
            <div className="d-flex align-items-center justify-content-between">
              <div className="filter1-field filter1-field1">
                <label className="filter1-label">검색조건</label>
                <select
                  className="form-select filter1-select1"
                  value={searchParam.select && searchParam.select}
                  onChange={e => onSelectChangeEvent("select", e.target.value)}
                >
                  <option value="COMP">업체명</option>
                  <option value="NAME">이름</option>
                  <option value="CTEL">회사전화</option>
                  {/*<option value="CFAX">회사팩스</option>*/}
                  <option value="MNG">관리번호</option>
                  <option value="JUMIN">주민번호</option>
                  <option value="HOLD">보유회원권</option>
                  <option value="CELL">휴대폰</option>
                  <option value="MEMNO">회원증No.</option>
                  {/*<option value="LOC">지역별</option>*/}
                  <option value="COMPNO">사업자번호</option>
                </select>
              </div>
              <div className="filter1-field filter1-field2">
                <label className="filter1-label">검색어</label>
                <input
                  type="text"
                  className="form-control filter-input filter1-input2"
                  onChange={e => onSelectChangeEvent("keyword", e.target.value)}
                  value={searchParam.keyword && searchParam.keyword}
                  onKeyDown={btn_searchInput}
                />
              </div>
              <div className="filter1-field filter1-field3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={searchParam.filterChk}
                  onChange={e => filterCheckedEvent(e.target.checked)}
                />
                <label
                  className="filter1-label"
                  htmlFor="chk-1"
                  onClick={() => filterCheckedEvent(!searchParam.filterChk)}
                >
                  {searchParam.filterChk ? "처음상태" : "결과 內 재검색"}
                </label>
              </div>
            </div>
            <button
              className="btn btn-outline-primary btn-filter-outline-primary  me-20px"
              onClick={btn_search}
            >
              <img
                className="me-2"
                src={process.env.PUBLIC_URL + "/images/search_icon.png"}
                alt=""
              />
              검색
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(searchPanel);
